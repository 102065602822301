import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  loginError: boolean = false;
  restLoading = false;

  constructor(private formBuilder: FormBuilder,
    private api: ApiService,
    private auth: AuthService,
    public router: Router) {
    this.buildForm();
  }

  ngOnInit(): void {
    if (this.auth.getToken()) {
      this.router.navigate(['/backoffice']);
    }
  }

  private buildForm() {

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

  }

  save(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      const value = this.form.value;
      const body = {
        device: 'web',
        username: value.email,
        scope: 'xpert-platform-jtsFasd52',
        password: value.password
      };

      console.log(body);
      this.restLoading = true;

      this.api.authentication(body).subscribe(
        result => {
          console.log('resultado login 1: ', result);
          console.log(result['token']);
          console.log(result['datos']['estado']);
          // this.appService.usuario.estadoCuenta = result['datos']['estado'];

          this.auth.setToken(result['token']);

          this.api.getUsuarioParameters({ scope: 'xpert-platform-jtsFasd52' }).subscribe(
            resultParams => {
              this.restLoading = false;
              console.log('resultado login 2: ', resultParams)

              if (resultParams['identificador']) {
                this.auth.setIdentificador(resultParams['identificador']);
              }

              // this.snackBar.open('Ingreso exitoso!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
              this.auth.setNombres(result['datos']['nombres']);
              this.auth.setApellidos(result['datos']['apellidos']);

              this.auth.setEmail(result['datos']['email']);
              this.auth.setAuth();

              console.log('akisss')
              console.log(resultParams['empresa'])

              if (resultParams['empresa']) {
                console.log(resultParams['empresa']['estadoTienda'])
                if (resultParams['empresa']['estadoTienda'] === 'habilitado') {
                  this.auth.setHabilitacionTienda('true');
                  // this.appService.usuario.habilitacionTienda = true;
                } else if (resultParams['empresa']['estadoTienda'] === 'solicitud') {
                  this.auth.setHabilitacionTienda('solicitud');
                  // this.appService.usuario.habilitacionTienda = false;

                } else {
                  console.log('akiii noooo')
                  this.auth.setHabilitacionTienda('false');
                  // this.appService.usuario.habilitacionTienda = false;
                }
              } else {
                this.auth.setHabilitacionTienda('false');
                // this.appService.usuario.habilitacionTienda = false;
              }

              // if (resultParams['empresa']) {
              //   console.log(resultParams['empresa']['estadoTienda'])
              //   if (resultParams['empresa']['estadoTienda'] === 'habilitado') {
              //     this.auth.setHabilitacionTienda('true');
              //     // this.appService.usuario.habilitacionTienda = true;
              //   } else if (resultParams['empresa']['estadoTienda'] === 'solicitud') {
              //     this.auth.setHabilitacionTienda('solicitud');
              //     // this.appService.usuario.habilitacionTienda = false;

              //   } else {
              //     console.log('akiii noooo')
              //     this.auth.setHabilitacionTienda('false');
              //     // this.appService.usuario.habilitacionTienda = false;
              //   }
              // } else {
              //   this.auth.setHabilitacionTienda('false');
              //   // this.appService.usuario.habilitacionTienda = false;
              // }

              if (resultParams['bot']) {
                if (resultParams['bot']['estadoBot'] === 'habilitado') {
                  this.auth.setHabilitacionBot('true');
                  // this.appService.usuario.habilitacionBot = true;
                } else if (resultParams['bot']['estadoBot'] === 'solicitud') {
                  this.auth.setHabilitacionBot('solicitud');
                  // this.appService.usuario.habilitacionBot = false;
                } else {
                  this.auth.setHabilitacionBot('false');
                  // this.appService.usuario.habilitacionBot = false;
                }
              } else {
                this.auth.setHabilitacionBot('false');
                // this.appService.usuario.habilitacionBot = false;
              }

              if (result['datos']['estado'] === 'cambio contraseña') {
                // this.router.navigate(['account/informacion']);
                console.log('estado es cambio de contraseña')
              } else {
                this.router.navigate(['/backoffice']);
              }
              // } else this.router.navigate(['/']);

              // this.snackBar.open('actualizado', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });

              return;

              // this.snackBar.open('Ingreso exitoso!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
              // this.auth.setNombres(result['datos']['nombres'])

              // this.auth.setUsername(result['datos']['email']);
              // this.auth.setAuth();

              // console.log('akisss')

              // if(resultParams['identificadorEmpresa']){
              //   console.log('identificadorEmpresa',resultParams['identificadorEmpresa'])
              //   this.auth.setIdentificadorEmpresa(resultParams['identificadorEmpresa'])

              //   if (resultParams['empresa']['estadoTienda'] === 'habilitado'){
              //     this.auth.setHabilitacionTienda('true');
              //     this.appService.usuario.habilitacionTienda = true;
              //   } else {
              //     this.auth.setHabilitacionTienda('false');
              //     this.appService.usuario.habilitacionTienda = false;
              //   }

              //   if (resultParams['empresa']['estadoBot'] === 'habilitado'){
              //     this.auth.setHabilitacionBot('true');
              //     this.appService.usuario.habilitacionBot = true;
              //   } else {
              //     this.auth.setHabilitacionBot('false');
              //     this.appService.usuario.habilitacionBot = false;
              //   }

              //   window.location.reload();
              //   return ;
              // } else {
              //   this.auth.setHabilitacionBot('false');
              //   this.auth.setHabilitacionTienda('false');
              //   this.appService.usuario.habilitacionBot = false;
              //   this.appService.usuario.habilitacionTienda = false;


              // }


              // if (result['datos']['estado']==='cambio contraseña') {
              //   this.router.navigate(['account/informacion']);
              // } else {
              //   window.location.reload();
              // }
              // } else this.router.navigate(['/']);





            }, error => {
              this.restLoading = false;
              this.auth.delAuth();
              // this.appService.usuario.autenticado = false;
              // this.appService.usuario.usuarioID = null;
              // this.appService.usuario.username = '';
              // this.appService.usuario.habilitacionTienda = false;
              this.auth.clearParameters();

              this.auth.setHabilitacionBot('false');
              this.auth.setHabilitacionTienda('false');

              // this.auth.setToken('null')
              // this.preloaderLoginForm = false; 
              // this.snackBar.open('error desconocido!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            }
          )







          // setTimeout(function(){this.router.navigate(['/products'])}, 5000);
          // this.address = document.location.href;
          // this.router.navigateByUrl('/search', {skipLocationChange: true}).then(() =>
          // this.router.navigate(['/']));
        },
        error => {
          this.restLoading = false;
          console.log(error)
          // this.preloaderLoginForm = false;
          // this.snackBar.open('Contraseña incorrecta!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          this.loginError = true;
        }
      )

    } else {
      this.form.markAllAsTouched();
    }
  }


}

import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor, HttpParams,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
// import {RequestOptions, ResponseContentType} from '@angular/http';
import { environment } from '../../environments/environment';





@Injectable()
export class ApiPagosService {

    authUrl = 'https://pruebas2.egm-robotics.com';
    baseUrl = environment.urlApi;
    baseChatbotWhatsappUrl = this.baseUrl + '/ushops/chatbot';

    tokenPayphone = environment.tokenPayphone

    constructor(private http: HttpClient, private auth: AuthService) { }


    getAuthorization() {
        return (`Bearer ${this.auth.getToken()}`);
    }


    ///////////////////// control de documentos ///////////////////////////////
    pagosPayphonePrepare(forms: any) {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.tokenPayphone}` })
        };

        return this.http.post(`${this.baseUrl}/ushops/salesxpertPagos/payment/pagosPayphonePrepare`, forms, options)
            .pipe(catchError(this.handleError));
    }


    ///////////////////// control de documentos ///////////////////////////////
    payphonePrepare(forms: any) {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.tokenPayphone}` })
        };

        return this.http.post(`https://pay.payphonetodoesposible.com/api/button/Prepare`, forms, options)
            .pipe(catchError(this.handleError));
    }

    obtenerDatosFacturacion(forms: any) {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.tokenPayphone}` })
        };

        return this.http.patch(`${this.baseUrl}/ushops/salesxpertPagos/payment/obtenerDatosFacturacion`, forms, options)
            .pipe(catchError(this.handleError));
    }

    guardarDatosFacturacion(forms: any) {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.tokenPayphone}` })
        };

        return this.http.patch(`${this.baseUrl}/ushops/salesxpertPagos/payment/actualizarDatosFacturacion`, forms, options)
            .pipe(catchError(this.handleError));
    }

    obtenerDatosSuscripcion(forms: any) {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.tokenPayphone}` })
        };

        return this.http.patch(`${this.baseUrl}/ushops/salesxpertPagos/payment/obtenerDatosSuscripcion`, forms, options)
            .pipe(catchError(this.handleError));
    }

    guardarDatosSuscripcion(forms: any) {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.tokenPayphone}` })
        };

        return this.http.patch(`${this.baseUrl}/ushops/salesxpertPagos/payment/actualizarDatosSuscripcion`, forms, options)
            .pipe(catchError(this.handleError));
    }

    obtenerSuscripcion(forms: any) {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseUrl}/ushops/salesxpertPagos/payment/obtenerSuscripcion`, forms, options)
            .pipe(catchError(this.handleError));
    }

    

    ///////////////////// control de documentos ///////////////////////////////
    obtenerCodigosPaises(forms: any) {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.tokenPayphone}` })
        };

        return this.http.get(`${this.baseUrl}/ushops/salesxpertPagos/payment/obtenerCodigosPaises`, options)
            .pipe(catchError(this.handleError));
    }


    uploadImageS3(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseUrl}/ushops/salesxpertPagos/payment/subirImagenS3`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    uploadFileS3(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseUrl}/ushops/salesxpertPagos/payment/subirArchivoS3`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse) {
        // console.log('error desconocido: ', error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            // console.error('error:', error.error);
        } else {
            if (error.status === 401) {
                console.error('no autorizado:');
                localStorage.removeItem('jwt');
            }
        }
        return throwError(error);
        // return throwError(new Error(error.statusText));
        //      return new throwError('OK');
    }



}

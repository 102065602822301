<app-navbar [links_navbar]="links"></app-navbar>
<div *ngIf="!agendado" class="main-section row">
    <div class="rigth-side col-12 col-lg-8">
        <div class="backround-black-fade"></div>
        <div class="rigth-side-title">
            Selecciona un día
        </div>
        <div class="rigth-side-content row">
                <div class="information-container col-12 col-lg-4">
                    <img src="https://s3.us-west-2.amazonaws.com/img.ushops/whatsapp-chatbot-imagenes/1728529542697WhatsApp%20Image%202024-10-09%20at%2013.33.49.jpeg" 
                     alt="assets/img/icono_ushops.png" alt="icono_ushops" class="info-image">
                                         <!-- <img src="https://s3.us-west-2.amazonaws.com/img.ushops/whatsapp-chatbot-imagenes/172687557548319948568-c705-4fdf-a2b1-88a80ebbf044.jfif"  -->

                    <pre class="descripcion-negocio">{{descripcionNegocio}}</pre>
                </div>

                <div class="calendar-container col-12 col-lg-8">
                    <div class="calendario">
                        <header>
                            <button class="month-button" (click)="cambiarMes(-1)">&lt;</button>
                            <div class="titulo">
                                <h2 [style.color]="'#FFFFFF'"> {{ actualMonth | titlecase }}</h2>
                            </div>
                            <button class="month-button" (click)="cambiarMes(1)">&gt;</button>
                        </header>
                        <section class="encabezado-dias">
                            <div class="dia-nombre" *ngFor="let dayName of dayNames" [style.color]="'#FFFFFF'">
                                {{ dayName }}
                            </div>
                        </section>
                        <section>
                            <div *ngFor="let day of monthDays" [ngClass]="day !== ' '? 'cursor-pointer dia': 'dia'"
                                (click)="seleccionarFecha(day)" [style.color]="'#FFFFFF'">
                                <div
                                    [ngClass]="getDateByDay(day) === selectedDate? 'selected-date-calendar': (getDateByDay(day) === actualDateString ? 'actual-date-calendar': (hasEvents(day) ? 'event-date-calendar' : ''))">
                                    {{ day }}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
    </div>
    <div *ngIf="!estadoUsuario" class="left-side col-12 col-lg-4">
        <div class="backround-black-fade"></div>
        <div class="left-side-title">
            Selecciona un horario
        </div>
        <!-- <ul *ngIf="listHours.length != 0" class="hours-list">

                <li *ngFor="let hour of listHours; let i = index" [for]="'hour-' + i" 
                [class.disabled]="hour.reservado" 
                [ngStyle]="{'opacity': hour.reservado ? 0.5 : 1}">
                <input type="radio" name="hour-radio" [id]="'hour-' + i" 
                        (change)="onHourChange(hour.value, hour.id)"
                       [disabled]="hour.reservado">
                <label [for]="'hour-' + i">{{hour.value}} {{(hour.reservado)? 'Reservado' :''}}</label>
                </li>

        </ul> -->
        <!-- <ul *ngIf="listHours.length != 0" class="hours-list">
            <li *ngFor="let hour of listHours; let i = index" 
                [class.disabled]="hour.reservado"
                [ngClass]="{'selected': isItemSelected(i)}"
                [ngStyle]="{'opacity': hour.reservado ? 0.5 : 1}">
                
              <input type="radio" name="hour-radio" [id]="'hour-' + i" 
                     (change)="onHourChange(hour.value, hour.id, i)"
                     [disabled]="hour.reservado || i === listHours.length - 1">
                     
              <label [for]="'hour-' + i">{{hour.value}} {{hour.reservado ? 'Reservado' : ''}}</label>
            </li>
          </ul> -->
      
          <!-- <ul *ngIf="listHours.length != 0" class="hours-list">
            <li *ngFor="let hour of listHours; let i = index" 
                [class.disabled]="hour.reservado"
                [ngClass]="{'selected': isItemSelected(i)}"
                [ngStyle]="{'opacity': hour.reservado ? 0.5 : 1}">
                
              <input type="radio" name="hour-radio" [id]="'hour-' + i" 
                     (change)="onHourChange(hour.value, hour.id, i)"
                     [disabled]="hour.reservado || (ndc >= 2 && i === listHours.length - 1)">
                     
              <label [for]="'hour-' + i">{{hour.value}} {{hour.reservado ? 'Reservado' : ''}}</label>
            </li>
          </ul> -->

          <ul *ngIf="listHours.length != 0" class="hours-list">
            <li *ngFor="let hour of listHours; let i = index" 
                [class.disabled]="isItemDisabled(i, hour)"
                [ngClass]="{'selected': isItemSelected(i)}"
                [ngStyle]="{'opacity': hour.reservado ? 0.5 : 1}">
                
              <input type="radio" name="hour-radio" [id]="'hour-' + i" 
                     (change)="onHourChange(hour.value, hour.id, i)"
                     [disabled]="isItemDisabled(i, hour)">
                     
              <label [for]="'hour-' + i">{{hour.value}} {{hour.reservado ? 'Reservado' : ''}}</label>
            </li>
          </ul>

          
        <div *ngIf="listHours.length == 0">
            <div class="texto-horarios-disponibles">
                No existen horarios disponibles para este día
            </div>
        </div>
        <button *ngIf="listHours.length != 0"  [disabled]="!this.fechaSeleccionada || !this.selectedHour" type="button" class="confirm-button" (click)="openEmailModal()">Confirmar</button>

    </div>
    <div *ngIf="estadoUsuario && estadoUsuario.length" class="left-side col-12 col-lg-4">
        <!-- <div  > -->
            <div class="backround-black-fade"></div>
            <div class="left-side-title">
                Yá tienes agendada una cita para este usuario
            </div>
            <div *ngFor="let item of estadoUsuario">
                <strong>Día seleccionado:</strong> {{ item.fechaReserva | date: 'yyyy-MM-dd' : 'UTC' }}<br>
                <strong>Hora seleccionada:</strong> {{ item.fechaReserva | date: 'HH:mm' : 'UTC' }}<br><br><br>
            </div>
           
            
        <!-- </div> -->
    </div>
    

</div>


<div *ngIf="agendado" class="main-section row">
    <div class="rigth-side col-12 col-lg-12">
        <div class="backround-black-fade"></div>
        <div class="rigth-side-title">
            Agendado Correctamente
        </div>
        <div class="information-container2 col-12 col-lg-4">
            <img src="https://s3.us-west-2.amazonaws.com/img.ushops/whatsapp-chatbot-imagenes/1728529542697WhatsApp%20Image%202024-10-09%20at%2013.33.49.jpeg" 
             alt="assets/img/icono_ushops.png" alt="icono_ushops" class="info-image">
        </div>
        <div class=" col-12 col-lg-6">
            <pre class="descripcion-negocio2">{{confirmacionAgendamiento}}</pre>
        </div>

    </div>
</div>



<!-- Call to action Section -->
<!-- <section class="call-to-action-section">
    <div class="call-to-action-container">
        <div class="subtitles" style="text-align: center; color: white; padding-top: 3rem;">
            Comienza a trabajar con XpertAssistant
        </div>
        <div class="call-to-action-button paragraphs">
            <a [routerLink]="'/signup-email'">Inicia ahora</a>
        </div>
    </div>
</section> -->

<!-- Footer -->
<app-footer [links_footer]="links"></app-footer>
<!-- Footer -->

<app-modal-animation [modalID]="'email-modal'" [modalClass]="'md-effect-1 width-medium'">
    <h3>Agendar cita</h3>
    <div class="body-modal">
        <div class="col-sm-12">
            <br><br>
            <strong>Día seleccionado:</strong> {{ fechaSeleccionada | date: 'yyyy-MM-dd'}}<br>
            <strong>Hora seleccionada:</strong> {{ selectedHour }}
            <!-- <strong>Hora seleccionada:</strong> {{ selectedHour }} -->
            <br><br>
            <div  class="row" style="margin: 0 0.5em;">
                <div class="row">
                    <label class="col-12 col-lg-3 label-title" for="telefono">Teléfono</label>
                    <input class="col-12 col-lg-9" type="text" name="telefono" id="telefono" [disabled]="adu" class="form-control" [(ngModel)]="telefonoFormData"/>
                </div>
            </div>
            <div class="row" style="margin: 0 0.5em;">
                <div class="row">
                    <label class="col-12 col-lg-3 label-title" for="email">Email</label>
                    <input class="col-12 col-lg-9" type="text" name="email" id="email" class="form-control" [(ngModel)]="emailFormData"/>
                </div>
            </div>
            <div class="row" style="margin: 0 0.5em;">
                <div class="row">
                    <label class="col-12 col-lg-3 label-title"  for="nombre">Nombre</label>
                    <input class="col-12 col-lg-9" type="text" name="nombre" id="nombre" class="form-control" [(ngModel)]="nombreFormData"/>
                </div>
            </div>
            <div class="row" style="margin: 0 0.5em;">
                <div class="row">
                    <label class="col-12 col-lg-3 label-title"  for="apellido">Apellido</label>
                    <input class="col-12 col-lg-9" type="text" name="apellido" id="apellido" class="form-control" [(ngModel)]="apellidoFormData"/>
                </div>
            </div>
            <div class="row" style="margin: 0 0.5em;">
                <div class="row">
                    <label class="col-12 col-lg-5 label-title"  for="nPersonas">Número de personas</label>
                    <input class="col-12 col-lg-9" type="number" name="nPersonas" id="nPersonas" class="form-control" [(ngModel)]="nPersonasFormData"/>
                </div>
            </div>                        
            <div class="row" style="margin: 0 0.5em;">
                <div class="row">
                    <label class="col-12 col-lg-5 label-title" for="pago">Forma de pago</label>
                    <div class="col-12 col-lg-9 radio-group">
                      <div *ngFor="let opcion of opcionesPago" class="radio-option">
                        <input type="radio" id="{{opcion}}" name="pago" [(ngModel)]="pagoFormData" [value]="opcion">
                        <label for="{{opcion}}">{{opcion}}</label>
                      </div>
                    </div>
                  </div>
            </div>    
            <div class="row" style="margin: 0 0.5em;">
                <label class="col-12 col-lg-5 label-title" for="adicional">¿Vienes con tu perrito?</label>
                <div class="col-12 col-lg-9 radio-group">
                  <div *ngFor="let opcion of opcionesPerrito" class="radio-option">
                    <input type="radio" id="{{opcion}}" name="adicional" [(ngModel)]="adicionalFormData" [value]="opcion">
                    <label for="{{opcion}}">{{opcion}}</label>
                  </div>
                </div>
              </div>
        </div>

        <div class="flex-between" style="margin-top: 1em;">
            <!-- <button type="button" class="btn btn-inverse ripple light footer-btn btn-danger"
                (click)="closeModal('email-modal')">
                Cancelar
            </button> -->
            <button type="submit" class="btn btn-inverse ripple light  btn-plus " (click)="saveSchedule()">
                Agendar
            </button>
        </div>
    </div>
</app-modal-animation>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WhatsappAdminUsersService } from 'src/app/services/whatsapp-admin-users.service';

@Component({
  selector: 'app-whatsapp-admin-users',
  templateUrl: './whatsapp-admin-users.component.html',
  styleUrls: ['./whatsapp-admin-users.component.scss']
})
export class WhatsappAdminUsersComponent implements OnInit {

  //Paginador de campañas
  currentPage = 1; // Página actual
  maxVisiblePages = 10; // Número máximo de páginas visibles
  pageSize = 30; // Tamaño de página
  visiblePages: number[] = []; // Páginas visibles en el paginador
  totalPages: number[]; // Total de páginas

  selectedFilter1: any = 'all';
  selectedFilter2: any = 'all';

  filter1Options: any = [
    { value: 'all', label: 'Todos' },
    { value: 'option1', label: 'Opción 1' },
    { value: 'option2', label: 'Opción 2' },
    { value: 'option3', label: 'Opción 3' },
    { value: 'option4', label: 'Opción 4' },
  ];

  filter2Options: any = [
    { value: 'all', label: 'Todos' },
    { value: 'option5', label: 'Opción 5' },
    { value: 'option6', label: 'Opción 6' },
    { value: 'option7', label: 'Opción 7' },
    { value: 'option8', label: 'Opción 8' },
  ];

  usersData = [
    // {
    //   "pushName": "Javy",
    //   "from": "593995851291",
    //   "date": "2023-10-19T02:52:36.465Z",
    //   "type": "user",
    //   "messagesCount": 10
    // },
    // {
    //   "pushName": "Javy2",
    //   "from": "593995851242",
    //   "date": "2023-09-19T02:52:36.465Z",
    //   "type": "user",
    //   "messagesCount": 5
    // },
  ];

  constructor(private router: Router, private apiService: WhatsappAdminUsersService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.refreshUsersHistory();
  }

  refreshUsersHistory() {
    let body: any = {
      type: "user",
      summary: true
    };

    this.apiService.getUsersHistoryWhatsapp(body).subscribe(
      result => {
        this.usersData = result;
        console.log('mensajes: ', this.usersData);
        this.updatePagination();
      },
      error => {
        console.log('Error al obtener historial: ', error);
        this.toastr.error('Ha ocurrido un error al obtener el historial de mensajes.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al obtener las campañas', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    );
  }

  getPaginatedItems(): any[] {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.usersData.slice(startIndex, endIndex);
  }

  setPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.updatePagination();
  }

  updatePagination() {
    let totalItems = this.usersData.length;
    const totalNumPages = Math.ceil(totalItems / this.pageSize);
    this.totalPages = Array.from({ length: totalNumPages }, (_, index) => index + 1);

    // Lógica para determinar las páginas visibles en el paginador
    const middlePage = Math.ceil(this.maxVisiblePages / 2);
    let startPage = this.currentPage - middlePage + 1;
    let endPage = this.currentPage + middlePage - 1;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(this.maxVisiblePages, totalNumPages);
    }

    if (endPage > totalNumPages) {
      endPage = totalNumPages;
      startPage = Math.max(1, endPage - this.maxVisiblePages + 1);
    }

    this.visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => index + startPage);
  }

  redirectHistory(number: string) {
    const queryParams = { num: number };
    const query = new URLSearchParams(queryParams).toString();

    window.open(`/backoffice/whatsapp-user-chat?${query}`, '_blank');
    // this.router.navigate(['/account/whatsappUserChat'], { queryParams: { "num": number } });
  }

}

<div *ngIf="client">
    <div class="header">
        <button type="button" class="header-button" (click)="openClientModal()">Editar cliente</button>
        <button type="button" class="header-button" (click)="redirectChat()">Ir a chat</button>
    </div>

    <div class="client-notes-container">
        <div class="notes-header">
            <h4>Notas del cliente</h4>
            <img src="assets/icons/add-icon.png" alt="add-icon" class="header-icon" ngbTooltip="Crear nota"
                placement="top" (click)="openNoteModal()">
        </div>
        <div class="notes-container">
            <div *ngFor="let note of notes" class="note">
                <div class="note-header">
                    <div class="note-title">
                        <span *ngIf="!note.isEditable">{{note.titulo}}</span>
                        <input *ngIf="note.isEditable" type="text" [(ngModel)]="note.titulo" class="note-input">
                    </div>
                    <div class="note-icons">
                        <div *ngIf="!note.isEditable" ngbDropdown class="d-inline-block">
                            <button type="button" class="btn-default btn-mini dropdown-toggle b-none txt-muted ripple"
                                id="dropdownBasic1" ngbDropdownToggle>
                                <img src="assets/icons/show-more-icon.png" alt="show-more-icon" class="show-more-icon"
                                    class="note-icon">
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                <button ngbDropdownItem (click)="editNote(note)">Editar</button>
                                <button ngbDropdownItem (click)="deleteNote(note)">Eliminar</button>
                            </div>
                        </div>
                        <img *ngIf="note.isEditable" src="assets/icons/save-icon.png" alt="save-icon"
                            (click)="saveEditedNote(note)" class="note-icon ml-3" ngbTooltip="Guardar cambios"
                            placement="top">
                        <img *ngIf="note.isEditable" src="assets/icons/cancel-icon.png" alt="cancel-icon"
                            (click)="cancelEditedNote(note)" class="note-icon ml-3" ngbTooltip="Cancelar edición"
                            placement="top">
                    </div>
                </div>
                <div class="note-body">
                    <div class="note-description">
                        <span *ngIf="!note.isEditable">{{note.descripcion}}</span>
                        <textarea *ngIf="note.isEditable" name="note-textarea" [(ngModel)]="note.descripcion"
                            class="note-textarea"></textarea>
                    </div>
                    <img *ngIf="note.urlImagen" [src]="note.urlImagen" alt="note-image" class="note-image">
                    <div class="note-footer">
                        <div class="note-date">{{note.fecha | date: 'HH:mm - dd/MM/yyyy'}}</div>
                        <div *ngIf="note.transicion?.estadoVerificacion === client.proceso"
                            class="card-buttons-container">
                            <button *ngIf="note.transicion?.confirmacion" class="btn btn-success ripple light"
                                (click)="changeProcessKanban('confirmacion', note)">{{note.transicion.confirmacion}}</button>
                            <button *ngIf="note.transicion?.denegacion" class="btn btn-danger ripple light"
                                (click)="changeProcessKanban('denegacion', note)">{{note.transicion.denegacion}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!client" class="no-info">
    <h4>Sin información del cliente</h4>
</div>

<app-modal-animation [modalID]="'client-modal'" [modalClass]="'md-effect-1'">
    <h3>Editar información de cliente</h3>
    <div *ngIf="client">
        <form [formGroup]="formClient" (ngSubmit)="updateClient()">
            <div class="row data-container" style="margin: 0 0.5em;">
                <div class="form-item">
                    <label for="nombre">Nombre</label>
                    <input type="text" name="nombre" id="nombre" [(ngModel)]="client.nombre"
                        [ngModelOptions]="{standalone: true}" formControlName="nombre" />
                </div>
                <div class="form-item">
                    <label for="telefono">Teléfono</label>
                    <input type="text" name="telefono" id="telefono" [(ngModel)]="client.telefono"
                        [ngModelOptions]="{standalone: true}" formControlName="telefono" />
                </div>
                <div class="form-item">
                    <label for="email">Correo electrónico</label>
                    <input type="text" name="email" id="email" [(ngModel)]="client.email"
                        [ngModelOptions]="{standalone: true}" formControlName="email" />
                </div>
                <div class="form-item">
                    <label for="direccion">Dirección</label>
                    <input type="text" name="direccion" id="direccion" [(ngModel)]="client.direccion"
                        [ngModelOptions]="{standalone: true}" formControlName="direccion" />
                </div>
                <!-- <div class="form-item">
                    <label for="pais">País</label>
                    <input type="text" name="pais" id="pais">
                </div> -->
                <div class="form-item">
                    <label for="ciudad">Ciudad</label>
                    <input type="text" name="ciudad" id="ciudad" [(ngModel)]="client.ciudad"
                        [ngModelOptions]="{standalone: true}" formControlName="ciudad" />
                </div>
                <div class="form-item">
                    <label for="empresa">Empresa</label>
                    <input type="text" name="empresa" id="empresa" [(ngModel)]="client.empresa"
                        [ngModelOptions]="{standalone: true}" formControlName="empresa" />
                </div>
                <div class="form-item">
                    <label for="cargo">Cargo</label>
                    <input type="text" name="cargo" id="cargo" [(ngModel)]="client.cargo"
                        [ngModelOptions]="{standalone: true}" formControlName="cargo" />
                </div>
                <!-- <div class="form-item">
                    <label for="website">Sitio web</label>
                    <input type="text" name="website" id="website">
                </div>
                <div class="form-item">
                    <label for="producto-interes">Producto de interes</label>
                    <input type="text" name="producto-interes" id="producto-interes">
                </div>
                <div class="form-item">
                    <label for="precio-producto">Precio producto</label>
                    <input type="text" name="precio-producto" id="precio-producto">
                </div>
                <div class="form-item">
                    <label for="campania">Campaña</label>
                    <input type="text" name="campania" id="campania">
                </div> -->
            </div>

            <div class="flex-between" style="margin-top: 1em;">
                <button type="button" class="btn btn-danger ripple light footer-btn" style='width: 20%;'
                    (click)="closeModal('client-modal')">
                    Cancelar
                </button>
                <button type="submit" class="btn btn-success ripple light footer-btn" style='width: 20%;'>
                    Guardar
                </button>
            </div>
        </form>
    </div>
</app-modal-animation>

<app-modal-animation [modalID]="'note-modal'" [modalClass]="'md-effect-1'">
    <h3>Crear nueva nota para el cliente {{client?.nombre}}</h3>
    <div>
        <form [formGroup]="formNote" (ngSubmit)="createNote()">
            <div class="col-sm-12">

                <div class="row" style="margin: 0 0.5em;">
                    <div class="form-item">
                        <label for="titulo">Título</label>
                        <input type="text" name="titulo" id="titulo" formControlName="titulo" class="form-control" />
                    </div>
                    <div class="form-item">
                        <label for="telefono">Descripción</label>
                        <textarea name="description" id="description" formControlName="descripcion"
                            class="form-control"></textarea>
                    </div>
                </div>
            </div>

            <div class="flex-between" style="margin-top: 1em;">
                <button type="button" class="btn btn-inverse ripple light footer-btn btn-danger"
                    (click)="closeModal('note-modal')">
                    Cancelar
                </button>
                <button type="submit" class="btn btn-inverse ripple light footer-btn btn-plus">
                    Crear
                </button>
            </div>
        </form>
    </div>
</app-modal-animation>
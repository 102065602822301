<div class="filter-container">
    <div class="filter-item">
        <label>Filtro 1</label>
        <select name="status" [(ngModel)]="selectedFilter1">
            <option *ngFor="let option of filter1Options; index as i" value="{{option.value}}">{{option.label}}</option>
        </select>
    </div>
    <div class="filter-item">
        <label>Filtro 2</label>
        <select name="status" [(ngModel)]="selectedFilter2">
            <option *ngFor="let option of filter2Options; index as i" value="{{option.value}}">{{option.label}}</option>
        </select>
    </div>
    <div class="filter-item">
        <label>Filtro 3</label>
        <select name="status" [(ngModel)]="selectedFilter3">
            <option *ngFor="let option of filter3Options; index as i" value="{{option.value}}">{{option.label}}</option>
        </select>
    </div>
</div>
<div class="charts-container">
    <div echarts [initOpts]="initOpts" [options]="optionsPie" class="demo-chart"></div>
    <div echarts [initOpts]="initOpts" [options]="optionsBar" class="demo-chart"></div>
</div>
<section>
    <div class="signup-container">
        <a class="logotipo" [routerLink]="'/home'">
            <img src="assets/img/icons/logotipo.svg" alt="">
        </a>
        <div class="titulo">
            Código de verificación
        </div>
        <div class="subtitulo">
            Envíamos un código a {{email_received}}, revisa tu correo
        </div>
        <form [formGroup]="form" novalidate (ngSubmit)="save($event)">
            <input type="text" placeholder="Introduce el código" formControlName="code">
            <div *ngIf="form.get('code').errors && form.get('code').touched">
                <p *ngIf="form.get('code').hasError('required')" class="error">
                    Ingresa el código
                </p>
                <p *ngIf="form.get('code').hasError('minlength') || form.get('code').hasError('maxlength')" class="error">
                    Código incorrecto, ingresa el código nuevamente.
                </p>
            </div>
            <div class="verify">
                <p>¿No recibiste el código?</p> <a href="">Vuelve a enviar</a>
            </div>
            <div class="crear-cuenta">
                <button type="submit">Continuar</button>
            </div>
        </form>
    </div>
</section>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTransferService } from '../services/data-transfer.service';

@Component({
  selector: 'app-signup-code',
  templateUrl: './signup-code.component.html',
  styleUrls: ['./signup-code.component.scss']
})
export class SignupCodeComponent implements OnInit {

  form: FormGroup;
  email_received: string;

  constructor(private formBuilder: FormBuilder, private router: Router, private data: DataTransferService) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.data.currentData.subscribe(message => this.email_received = message);
  }

  private buildForm() {
    
    this.form = this.formBuilder.group({
      code: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
    });
    
  }

  save(event: Event) {
    event.preventDefault();
      if(this.form.valid) {
      const value = this.form.value;
      console.group(value);
      this.nextpage();
    } else {
      this.form.markAllAsTouched();
    }
  }

  nextpage() {
    this.router.navigate(['signup-password']);
  }

}

import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';


@Component({
  selector: 'app-signup-password',
  templateUrl: './signup-password.component.html',
  styleUrls: ['./signup-password.component.scss']
})
export class SignupPasswordComponent implements OnInit {

  password: HTMLElement;
  passwordverify: HTMLElement;
  flag1: boolean = false;
  flag2: boolean = false;
  form: FormGroup;
  differentPasswords: boolean = false;

  e = null;
  cdg = null;
  tmp = null;
  idc = null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId) {
    this.buildForm();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.password = document.getElementById('password');
      this.passwordverify = document.getElementById('passwordverify');
    }


    this._activatedRoute.queryParams.subscribe(params => {
      console.log(params);

      if(params['e']){
        this.e = params['e'];
      }

      if(params['cdg']){
        this.cdg = params['cdg'];
      }
      if(params['idc']){
        this.idc = params['idc'];
      }
      if(params['tmp']){
        this.tmp = params['tmp'];
      }
      

      console.log(this.e, this.cdg, this.tmp)
      

    });



  }

  showpassword() {
    if (this.flag1 === true) {
      this.password.setAttribute('type', 'password');
      this.flag1 = false;
    } else {
      this.password.setAttribute('type', 'text');
      this.flag1 = true;
    }
  }

  showpasswordverify() {
    if (this.flag1 === true) {
      this.passwordverify.setAttribute('type', 'password');
      this.flag1 = false;
    } else {
      this.passwordverify.setAttribute('type', 'text');
      this.flag1 = true;
    }
  }

  private buildForm() {

    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordcontrol: ['', [Validators.required, Validators.minLength(8)]]
    });

  }

  save(event: Event) {

    if (this.form.value.password !== this.form.value.passwordcontrol) {
      this.differentPasswords = true;
      return;
    }

    this.differentPasswords = false;

    event.preventDefault();
    if (this.form.valid) {
      const value = this.form.value;
      console.group(value);


      let body = {
        password: this.form.value.password
      }

      let query = '?e='+ this.e+'&cdg='+this.cdg+'&idc='+this.idc+ '&tmp='+ this.tmp;

      console.log(body, this.e, this.cdg)
        
        this.api.validateAccount(body, query).subscribe(
          result=> {

            console.log('cuenta validada', result)

            // this.toastr.success('cuenta validada', 'Exito', {
            //   timeOut: 3000,
            //   positionClass: 'toast-bottom-right',
            // });

            this.router.navigate(['/']);


          }, error => {
            console.log(error)
            // this.toastr.error(error.error.mensaje, 'Error', {
            //   timeOut: 3000,
            //   positionClass: 'toast-bottom-right',
            // });


        }
      )
    // }






    } else {
      this.form.markAllAsTouched();
    }
  }

}

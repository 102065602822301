import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';


@Injectable({
    providedIn: 'root',
})
export class ModulesGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        
        if(this.authService.getHabilitacionSystem()=='true'){
            return true;
            
          } else {

            return this.router.createUrlTree(['/backoffice/payment']);
          }
        
        
        
        // if (this.authService.getToken()) {
        //     return true;
        // } else {
        //     return this.router.createUrlTree(['/login']);
        // }
    }
}
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MassMessagingService } from 'src/app/services/mass-messaging.service';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mass-messaging',
  templateUrl: './mass-messaging.component.html',
  styleUrls: ['./mass-messaging.component.scss']
})
export class MassMessagingComponent implements OnInit {
  @ViewChild('excelInput') excelInput: ElementRef;

  //Paginador de campañas
  currentPage = 1; // Página actual
  maxVisiblePages = 10; // Número máximo de páginas visibles
  pageSize = 30; // Tamaño de página
  visiblePages: number[] = []; // Páginas visibles en el paginador
  totalPages: number[]; // Total de páginas

  //Paginador tabla en modal excel
  page = 1;
  pageSizeTable = 30;
  lastPage: number;
  collectionSize: number;

  isCreate: boolean = true;
  campaigns: any = [];
  messages: any = [];
  selectedStatus: any = 'activo';
  selectedCampaign: any;
  newCampaignName: any;
  excelName: any;
  selectedTray: any;

  excelData: any = [];

  isDragOver = false;
  dropZoneConfig: any = {
    url: '', // URL de carga, puedes configurarla según tu backend
    maxFilesize: 500, // Tamaño máximo de archivo en MB
  };

  status: any = [
    { label: 'Activo', value: 'activo', disabled: false },
    { label: 'Inactivo', value: 'inactivo', disabled: false }
  ];

  constructor(private apiService: MassMessagingService, private elementRef: ElementRef, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.refreshCampaigns();
  }

  openMessagesModal(campaign: any) {
    this.selectedCampaign = campaign;
    this.messages = [];

    console.log(this.selectedCampaign)

    this.refreshCampaignMessages();
    document.querySelector('#' + 'mensajesModal').classList.add('md-show');
  }

  openExcelModal(campaign: any) {
    // console.log('this.excelInput', this.excelInput);

    this.selectedCampaign = campaign;
    console.log(campaign)

    let body: any = {
      identificadorCampaign: campaign.identificadorCampaign
    };

    this.apiService.getBandejasCampaign(body).subscribe(
      result => {
        console.log('bandejas: ', body, result);



        if (this.excelInput) this.excelInput.nativeElement.value = '';

        if (result && result.length > 0) {
          this.selectedTray = result[0];
          // this.selectedTray.editCampaing = 'edited'

          this.excelData = this.selectedTray.usuarios;
          this.collectionSize = this.excelData.length;
          this.lastPage = Math.ceil(
            this.collectionSize / this.pageSize
          );
        } else {
          this.cleanExcelData();
        }

        this.excelName = '';
        document.querySelector('#' + 'excelModal').classList.add('md-show');
      },
      error => {
        console.log('Error al obtener bandeja: ', error);
        this.toastr.error('Ha ocurrido un error al obtener la bandeja de la campaña seleccionada.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al obtener la bandeja de la campaña seleccionada', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        this.messages = [{ message: '', imagen: null, isDragOver: false }];
      }
    );
  }

  openCampaignModal(isCreate: any, campaign: any = undefined) {
    this.selectedCampaign = campaign;
    if (campaign && campaign.status) {
      this.selectedStatus = campaign.status;
    } else {
      this.selectedStatus = 'activo';
    }
    this.isCreate = isCreate;
    document.querySelector('#' + 'campaignModal').classList.add('md-show');
  }

  addMessage() {
    this.messages.push({ message: '', imagen: null });
    // this.selectedNode.data.keywords.push('');
  }

  removeMessage(index: number) {
    this.messages[index].isDelete = true;
    // this.selectedNode.data.keywords.splice(index, 1);
  }

  openFileSelector() {
    document.getElementById('excel-input').click();
  }

  onExcelFileChange(event: any): void {
    const file = event.target.files[0];
    // console.log('archivo: ', file);
    this.excelName = file.name
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const rawData = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      let finalData = rawData.map((item: any) => {
        const newItem: any = {};

        newItem.id = generarCadenaAleatoriaNDigitos(20);


        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            if (key.toLowerCase() === 'nombre' || key.toLowerCase() === 'apellido' || key.toLowerCase() === 'numero') {
              newItem[key.toLowerCase()] = item[key];
            }
          }
        }
        return newItem;
      });

      for (let i = 0; i < finalData.length; i++) {
        if (!finalData[i].hasOwnProperty('numero')) {
          finalData.splice(i, 1);
        }

      }

      console.log(finalData);
      this.excelData = finalData;
      console.log(this.selectedTray)
      this.selectedTray.editCampaing = 'edited'

      this.collectionSize = this.excelData.length;
      this.lastPage = Math.ceil(
        this.collectionSize / this.pageSize
      );
    };

    reader.readAsBinaryString(file);
  }

  onFileSelected(event: any, index: number, rawFile: any = undefined) {

    let file: any;

    if (!rawFile) {
      file = event.target.files[0];
    } else {
      file = rawFile;
    }

    console.log('file: ', file);
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgElement: any = document.getElementById('message-image-' + index);
        console.log('elemento imagen: ', imgElement);
        if (imgElement) {

          let formData: FormData = new FormData();
          formData.append('image', file);
          formData.append('type', file.type);

          this.apiService.uploadImageS3(formData).subscribe(
            result => {
              console.log('imagen subida a s3: ', result.url);
              this.resetFile(index);
              this.messages[index].imagen = result.url;
              imgElement.src = e.target.result;
              this.toastr.success('Se ha subido la imagen exitosamente.', 'Operacion exitosa', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Se ha subido la imagen exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
            },
            error => {
              console.log('Error al subir la imagen a s3: ', error);
              this.toastr.error('Ha ocurrido un error al subir la imagen.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al subir la imagen', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            }
          );
        }
      };
      reader.readAsDataURL(file);
    } else if (file && file.type.startsWith('video/')) {
      console.log('entra a video');

      let formData: FormData = new FormData();
      formData.append('video', file);
      formData.append('type', file.type);

      this.apiService.uploadVideoS3(formData).subscribe(
        result => {
          console.log('video subido a s3: ', result.url);
          this.resetFile(index);
          this.messages[index].video = result.url;
          setTimeout(() => {
            let videoPlayer = this.elementRef.nativeElement.querySelector('#videoPlayer' + index);

            videoPlayer.src = result.url;
            videoPlayer.load();
            this.toastr.success('Se ha subido el video exitosamente.', 'Operacion exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Se ha subido el video exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          }, 1000);
        },
        error => {
          console.log('Error al subir el video a s3: ', error);
          this.toastr.error('Ha ocurrido un error al subir el video.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al subir el video', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      );
    } else if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'doc' || fileExtension === 'docx'
        || fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'xlsm'
        || fileExtension === 'pdf') {
        let formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('type', file.type);

        this.apiService.uploadFileS3(formData).subscribe(
          result => {
            console.log('documento subido a s3: ', result.url);
            this.resetFile(index);
            this.messages[index].archivo = result.url;
          },
          error => {
            console.log('Error al subir el archivo a s3: ', error);
            this.toastr.error('Ha ocurrido un error al subir el documento.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al subir el documento', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          }
        );
      }
    }
  }

  openFile(url: any) {
    window.open(url, '_blank');
  }

  getFileName(url: string) {
    return decodeURIComponent(url.split('/')[url.split('/').length - 1]);
  }

  resetFile(index: number) {
    this.messages[index].imagen = null;
    this.messages[index].video = null;
    this.messages[index].archivo = null;
  }

  cleanExcelData() {
    this.selectedTray = {};
    this.collectionSize = 0;
    this.lastPage = 0;
    this.excelData = [];
    if (this.selectedTray) {
      this.selectedTray.editCampaing = 'edited'
    }
    // else{
    //   this.selectedTray = {}
    //   this.selectedTray.editCampaing = 'edited'

    // }

    console.log('this.selectedTray', this.selectedTray)

  }

  saveExcelData() {
    Swal.fire({
      title: '¿Está seguro de guardar?',
      text: 'Se guardaran los datos de los contactos',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        // Swal.fire('', 'Cancelado con éxito', 'error');
      } else {

        let body: any = {
          identificadorCampaign: this.selectedCampaign.identificadorCampaign,
          usuarios: this.excelData,

        }

        console.log('save excel ', this.selectedTray)

        if (this.selectedTray.identificadorBandeja) {
          body.identificadorBandeja = this.selectedTray.identificadorBandeja;
          body.editCampaing = 'completed'
          this.selectedTray.editCampaing = 'completed'

          console.log('body: ', body);

          this.apiService.updateBandejaCampaign(body).subscribe(
            result => {
              console.log('bandeja actualizada exitosamente');
              Swal.fire('', 'Campaña actualizada exitosamente', 'success');
              this.refreshCampaigns();
            },
            error => {
              console.log('Error al actualizar bandeja: ', error);
              Swal.fire('', 'Ha ocurrido un error al enviar la campaña', 'error');
            }
          );
        } else {
          body.editCampaing = 'completed'
          this.selectedTray.editCampaing = 'completed'

          console.log('body: ', body);

          this.apiService.createBandejaCampaign(body).subscribe(
            result => {
              console.log('bandeja creada exitosamente');
              Swal.fire('', 'Campaña actualizada exitosamente', 'success');
              this.refreshCampaigns();
            },
            error => {
              console.log('Error al crear bandeja: ', error);
              Swal.fire('', 'Ha ocurrido un error al enviar la campaña', 'error');
            }
          );
        }
      }
    });
  }

  sendCampaign() {
    Swal.fire({
      title: '¿Está seguro de enviar la campaña?',
      text: 'Se enviará la camapaña a los contactos importados',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        // Swal.fire('', 'Cancelado con éxito', 'error');
      } else {
        let body: any = {
          identificadorCampaign: this.selectedCampaign.identificadorCampaign,
          enviado: 'activado'
        }

        console.log('body: ', body);

        this.apiService.updateCampaignWhatsapp(body).subscribe(
          result => {
            console.log('campaña actualizada exitosamente');
            this.toastr.success('Se ha actualizado la campaña exitosamente.', 'Operacion exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Se ha actualizado la campaña exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
            this.selectedCampaign.enviado = 'activado';
            this.refreshCampaigns();
          },
          error => {
            console.log('Error al actualizar campaña: ', error);
            this.toastr.error('Ha ocurrido un error al actualizar la campaña.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al actualizar la campaña', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          }
        );
      }
    });
  }

  revertSendStatus() {

    Swal.fire({
      title: '¿Está seguro de cancelar el envio de la campaña?',
      text: 'Se cancelara la operacion para enviar la camapaña a los contactos',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        // Swal.fire('', 'Cancelado con éxito', 'error');
      } else {
        let body: any = {
          identificadorCampaign: this.selectedCampaign.identificadorCampaign,
          enviado: 'desactivado'
        }

        console.log('body: ', body);

        this.apiService.updateCampaignWhatsapp(body).subscribe(
          result => {
            console.log('campaña actualizada exitosamente');
            this.toastr.success('Se ha actualizado la campaña exitosamente.', 'Operacion exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Se ha actualizado la campaña exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
            this.selectedCampaign.enviado = 'desactivado';
            this.refreshCampaigns();
          },
          error => {
            console.log('Error al actualizar campaña: ', error);
            this.toastr.error('Ha ocurrido un error al actualizar la campaña.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al actualizar la campaña', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          }
        );
      }
    });
  }

  createUpdateCampaign() {

    let body: any = {
      nombre: this.isCreate ? this.newCampaignName : this.selectedCampaign.nombre,
      status: this.selectedStatus
    }

    console.log('body: ', body);

    if (this.isCreate) {
      this.apiService.createCampaignWhatsapp(body).subscribe(
        result => {
          console.log('campaña creada exitosamente');
          this.toastr.success('Se ha creado la campaña exitosamente.', 'Operacion exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Se ha creado la campaña exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          this.newCampaignName = undefined;
          this.closeModal('campaignModal');
          this.refreshCampaigns();
        },
        error => {
          console.log('Error al crear campañas: ', error);
          this.toastr.error('Ha ocurrido un error al crear la campaña.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al crear la campaña', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      );
    } else {
      body.identificadorCampaign = this.selectedCampaign.identificadorCampaign;
      this.apiService.updateCampaignWhatsapp(body).subscribe(
        result => {
          console.log('campaña actualizada exitosamente');
          this.toastr.success('Se ha creado la campaña exitosamente.', 'Operacion exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Se ha actualizado la campaña exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          this.selectedCampaign = undefined;
          this.closeModal('campaignModal');
          this.refreshCampaigns();
        },
        error => {
          console.log('Error al actualizar campañas: ', error);
          this.toastr.error('Ha ocurrido un error al actualizar la campaña.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al actualizar la campaña', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      );
    }
  }

  deleteCampaign(campaign: any) {
    let body: any = {
      identificadorCampaign: campaign.identificadorCampaign
    }

    this.apiService.deleteCampaignWhatsapp(body).subscribe(
      result => {
        console.log('campaña eliminada exitosamente');
        this.toastr.success('Se ha eliminado la campaña exitosamente.', 'Operacion exitosa', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Se ha eliminado la campaña exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
        this.refreshCampaigns();
      },
      error => {
        console.log('Error al eliminar campañas: ', error);
        this.toastr.error('Ha ocurrido un error al eliminar la campaña.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al eliminar la campaña', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    );

  }

  sendMessageData() {

    let body: any;

    // for (let i = 0; i < this.messages.length; i++) {
    //   this.messages[i].key = '';
    //   this.messages[i].number = '';
    // }

    let promiseResult = Promise.all(this.messages.map(async (message) => {
      return new Promise((resolve, reject) => {

        if ((message.message === '' || !message.message) && (!message.imagen && !message.video && !message.archivo)) {
          return resolve(true);
        }

        if (message.imagen === '' || !message.imagen) {
          message.imagen = null;
        }

        if (message.video === '' || !message.video) {
          message.video = null;
        }

        if (message.archivo === '' || !message.archivo) {
          message.archivo = null;
        }

        if (message.isDelete && message.identificadorCampaign && message.identificadorMensaje) {
          body = {
            "identificadorCampaign": message.identificadorCampaign,
            "identificadorMensaje": message.identificadorMensaje,
          }


          this.apiService.deleteMensajeCampaigns(body).subscribe(
            result => {
              console.log('mensaje eliminado exitosamente');
              // this.toastr.success('Se ha actualizado la campaña exitosamente.', 'Operacion exitosa',  {
              //   timeOut: 3000,
              //   positionClass: 'toast-bottom-right',
              // });
              // this.snackBar.open('Se ha actualizado el template exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
              return resolve(result);
            },
            error => {
              console.log('Error al eliminar mensaje: ', error);
              this.toastr.error('Ha ocurrido un error al actualizar el template.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al actualizar el template', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
              return reject(error);
            }
          );
        }


        if (message.identificadorCampaign && message.identificadorMensaje) {

          body = {
            "identificadorCampaign": message.identificadorCampaign,
            "identificadorMensaje": message.identificadorMensaje,
            "key": message.key,
            "number": message.number,
            "message": message.message,
            "imagen": message.imagen,
            "video": message.video,
            "archivo": message.archivo
          }

          this.apiService.updateMensajeCampaigns(body).subscribe(
            result => {

              let bodyTray: any = {}

              bodyTray.identificadorCampaign = message.identificadorCampaign;
              bodyTray.editCampaing = 'edited'


              console.log('body: ', bodyTray);

              this.apiService.updateBandejaCampaign(bodyTray).subscribe(
                result => {
                  console.log('mensaje actualizado exitosamente');

                },
                error => {
                  console.log('Error al actualizar bandeja: ', error);
                  Swal.fire('', 'Ha ocurrido un error al actualizar la campaña', 'error');
                }
              );

              // this.toastr.success('Se ha actualizado el template exitosamente.', 'Operacion exitosa',  {
              //   timeOut: 3000,
              //   positionClass: 'toast-bottom-right',
              // });
              // this.snackBar.open('Se ha actualizado el template exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
              return resolve(result);
            },
            error => {
              console.log('Error al actualizar mensaje: ', error);
              this.toastr.error('Ha ocurrido un error al actualizar el template.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al actualizar el template', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
              return reject(error);
            }
          );
        } else {

          body = {
            "identificadorCampaign": this.selectedCampaign.identificadorCampaign,
            "key": message.key,
            "number": message.number,
            "message": message.message,
            "imagen": message.imagen,
            "video": message.video,
            "archivo": message.archivo
          }

          console.log('mensaje nuevo: ', body);

          this.apiService.createMensajeCampaigns(body).subscribe(
            result => {


              let bodyTray: any = {}

              bodyTray.identificadorCampaign = body.identificadorCampaign;
              bodyTray.editCampaing = 'edited'


              console.log('body: ', bodyTray);

              this.apiService.updateBandejaCampaign(bodyTray).subscribe(
                result => {
                  console.log('mensaje actualizado exitosamente');

                },
                error => {
                  console.log('Error al actualizar bandeja: ', error);
                  Swal.fire('', 'Ha ocurrido un error al actualizar la campaña', 'error');
                }
              );


              // console.log('mensaje creado exitosamente');
              // this.toastr.success('Se ha actualizado el mensaje exitosamente.', 'Operacion exitosa',  {
              //   timeOut: 3000,
              //   positionClass: 'toast-bottom-right',
              // });
              // this.snackBar.open('Se ha actualizado el mensaje exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
              return resolve(result);
            },
            error => {
              console.log('Error al actualizar mensaje: ', error);
              this.toastr.error('Ha ocurrido un error al actualizar el template.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al actualizar el template', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
              return reject(error);
            }
          );

        }
      });
    })).then(
      result => {
        console.log('todos los mensajes creados/actualizados con exito ', result);
        this.selectedCampaign = undefined;
        this.toastr.success('Se ha actualizado el mensaje exitosamente.', 'Operacion exitosa', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        this.closeModal('mensajesModal');
        this.refreshCampaigns();
      },
      error => {
        console.log('error al finalizar poroceso de creacion/actualizacion de mensajes: ', error);
        this.toastr.error('Ha ocurrido un error al enviar los datos de los mensajes.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al enviar los datos de los mensjaes', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    );

    console.log('datos mensajes: ', this.messages);
  }

  refreshCampaigns() {
    let body: any = {};
    console.log('refreshCampaigns')
    this.apiService.getCampaignsWhatsapp(body).subscribe(
      result => {
        this.campaigns = result;

        console.log('refreshCampaigns', result)

        // for (let i = 0; i < 20; i++) {
        //   this.templates.push({
        //     "_id": "650381f8412914062eae876a",
        //     "nombre": "test 3",
        //     "status": "activo",
        //     "identificadorTemplate": "TBDT4HZ",
        //     "deleteStatus": 0
        //   });
        // }

        console.log('campañas: ', this.campaigns);

        this.updatePagination();
      },
      error => {
        console.log('Error al obtener campañas: ', error);
        this.toastr.error('Ha ocurrido un error al obtener las campañas.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al obtener las campañas', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    );
  }

  refreshCampaignMessages() {
    let body: any = {
      identificadorCampaign: this.selectedCampaign.identificadorCampaign
    };

    this.apiService.getMensajesCampaigns(body).subscribe(
      result => {
        console.log('mensajes: ', result);
        this.messages = result;
        //aqui
        if (this.messages.length === 0) {
          this.messages.push({ message: '', imagen: null });
        } else {
          for (let i = 0; i < this.messages.length; i++) {
            if (this.messages[i].video) {
              setTimeout(() => {
                let videoPlayer = this.elementRef.nativeElement.querySelector('#videoPlayer' + i);
                videoPlayer.src = this.messages[i].video;
                videoPlayer.load();
              }, 1000);
            }

            this.messages[i].isDragOver = false;
          }
        }
      },
      error => {
        console.log('Error al obtener mensajes: ', error);
        this.toastr.error('Ha ocurrido un error al obtener los mensajes de la campaña.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al obtener los mensajes de la campaña', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        this.messages = [{ message: '', imagen: null, isDragOver: false }];
      }
    );
  }

  refreshCampaignTray() {

    let body: any = {
      identificadorCampaign: this.selectedCampaign.identificadorCampaign
    };

    this.apiService.getBandejasCampaign(body).subscribe(
      result => {

        console.log('bandejas: ', body, result);

        if (this.excelInput) this.excelInput.nativeElement.value = '';

        if (result && result.length > 0) {
          this.selectedTray = result[0];
          this.excelData = this.selectedTray.usuarios;
          this.collectionSize = this.excelData.length;
          this.lastPage = Math.ceil(
            this.collectionSize / this.pageSize
          );
        } else {
          this.cleanExcelData();
        }

        this.excelName = '';
      },
      error => {
        console.log('Error al obtener bandeja: ', error);
        this.toastr.error('Ha ocurrido un error al obtener la bandeja de la campaña seleccionada.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al obtener la bandeja de la campaña seleccionada', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        this.messages = [{ message: '', imagen: null, isDragOver: false }];
      }
    );
  }

  onDragOver(event: DragEvent, index: number) {
    event.preventDefault();
    event.stopPropagation();
    this.messages[index].isDragOver = true;
  }

  onDragLeave(event: DragEvent, index: number) {
    event.preventDefault();
    event.stopPropagation();
    this.messages[index].isDragOver = false;
  }

  onDrop(event: DragEvent, index: number) {
    event.preventDefault();
    event.stopPropagation();

    this.messages[index].isDragOver = false;

    const files = event.dataTransfer?.files;
    if (files) {
      this.onDragAndDropFileChange(files, index);
    }
  }

  onDragAndDropFileChange(files: FileList, index: number) {
    // console.log('Archivos cargados:', files);
    this.resetFile(index);

    setTimeout(() => {
      this.onFileSelected(null, index, files[0]);
    }, 500);
  }

  getPaginatedItems(): any[] {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.campaigns.slice(startIndex, endIndex);
  }

  setPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.updatePagination();
  }

  updatePagination() {
    let totalItems = this.campaigns.length;
    const totalNumPages = Math.ceil(totalItems / this.pageSize);
    this.totalPages = Array.from({ length: totalNumPages }, (_, index) => index + 1);

    // Lógica para determinar las páginas visibles en el paginador
    const middlePage = Math.ceil(this.maxVisiblePages / 2);
    let startPage = this.currentPage - middlePage + 1;
    let endPage = this.currentPage + middlePage - 1;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(this.maxVisiblePages, totalNumPages);
    }

    if (endPage > totalNumPages) {
      endPage = totalNumPages;
      startPage = Math.max(1, endPage - this.maxVisiblePages + 1);
    }

    this.visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => index + startPage);
  }

  closeModal(name: string) {
    document.querySelector('#' + name).classList.remove('md-show');
  }

}

function generarCadenaAleatoriaNDigitos(n) {
  const caracteresPermitidos = "1234567890"
  if (n <= 0) {
    throw new Error("N debe ser un número positivo.");
  }

  const longitudCaracteres = caracteresPermitidos.length;
  let cadenaAleatoria = '';

  for (let i = 0; i < n; i++) {
    const indiceAleatorio = Math.floor(Math.random() * longitudCaracteres);
    cadenaAleatoria += caracteresPermitidos.charAt(indiceAleatorio);
  }

  return cadenaAleatoria;
}

import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class WhatsappAdminUsersService {

    ipPublica = environment.urlApi;
    // baseChatbotWhatsappUrl = this.ipPublica + '/ushops/chatbot'
    baseChatbotWhatsappUrl = this.ipPublica + '/ushops/xpertAssistant'
    
    constructor(private http: HttpClient, private auth: AuthService) {
    }

    getUsersHistoryWhatsapp(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerHistorialWhatsapp`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    uploadImageS3(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirImagenRespuesta`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    uploadVideoS3(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirVideoS3`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    uploadFileS3(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirArchivoS3`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse) {
        console.log('error desconocido: ', error);
        if (error.error instanceof ErrorEvent) {
        } else {
            if (error.status === 401) {
                console.error('no autorizado:');
                localStorage.removeItem('jwt');
            }
        }
        return throwError(new Error(error.statusText));
    }

}
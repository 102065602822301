<app-navbar [links_navbar]="links"></app-navbar>

<section class="main-section" (scroll)="scrollfunction($event)">
    <div class="main-landing">
        <div class="main-image">
            <app-portada></app-portada>
            <!-- <img src="assets/img/home/imagen-princ.svg" alt=""> -->
        </div>
        <div class="main-title">
            XpertAssistant.
        </div>
        <div class="main-subtitle">
            Tu Asistente Virtual te ayuda con la gestión de los clientes por ti.
        </div>
        <div class="main-call-to-action">
            <div class="btn-group btn-group-toggle">
                <label class="btn-primary" ngbButtonLabel [routerLink]="'/signup-email'">
                    <input type="checkbox" ngbButton> Comienza ahora
                </label>
            </div>
        </div>
        <!-- <img src="assets/img/home/carrito.svg" id="carrito"> 
        <img src="assets/img/home/element1.svg" id="element1" > -->
    </div>
</section>

<!-- Benefits -->
<section class="benefits-section">
    <div class="subtitles">
        ¿Por qué un Asistente Virtual?
    </div>
    <div class="benefits-container row">
        <div class="benefits-image col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <img src="assets/img/home/benefits/asistente1.jpg">
        </div>
        <div class="benefits-items col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row">
            <div class="benefits-item col-6" *ngFor="let item of benefits">
                <div class="benefits-pic">
                    <img src={{item.image}}>
                </div>
                <div class="paragraphs" style="text-align: center;">
                    {{item.description}}
                </div>
            </div>
        </div> 
    </div>
</section>

<!-- Video -->
<!-- <section class="video-section">
    <div class="video-container row">
        <div class="video-description col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="subtitles">
                Descubre uShops
            </div>
            <div class="subtitles-2" style="margin-top: 1rem;">
                Tu tienda en línea
            </div>
            <div class="paragraphs" style="width: 75%; margin-top: 1rem;">
                uShops es una plataforma digital que te permite  vender tus productos a través de internet. Crea tu catálogo de productos en nuestro marketplace, gestiona la venta, el pago y la entrega en un solo lugar. 
            </div>
            <div class="video-button paragraphs">
                <a href="">Conoce uShops</a>
            </div>
        </div>  
        <div class="video col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <iframe width="100%" height="315px" src="https://www.youtube.com/embed/89T6IKfGX7E" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</section> -->

<!-- Mobile Section -->
<!-- <section class="mobileapp-section">
    <div class="mobileapp-container row">
        <div class="mobileapp-info col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
            <div class="subtitles" style="color: white; width: 80%;">
                Sigue vendiendo desde cualquier lugar
            </div>
            <div class="paragraphs" style="color: white;">
                Descarga la aplicación móvil de uShops en tu celular. Maneja tu negocio donde quiera que vayas.
            </div>
            <div class="mobileapp-buttons">
                <div class="google-play">
                    <img src="assets/img/home/googleplay.svg">
                </div>
                
                <div class="app-store">
                    <img src="assets/img/home/appstore.svg">
                </div>
               
            </div>
        </div>
        <div class="mobileapp-image col-12 col-sm-12 col-md-3 col-lg-5 col-xl-5">
            <img src="assets/img/home/mobile.svg">
        </div>
    </div>
</section> -->

<!-- Competence Section -->
<section class="competence-section">
    <div class="subtitles" style="width: 50%;">
        <span style="color:#F4982C">Tipos de Asistentes Virtuales</span>
    </div>
    <div class="subtitles-2" style="width: 50%; margin-top: 1rem;">
        Estamos creando una nueva forma de atención a tus clientes.
    </div>
    <div class="competence-items row">
        <div class="competence-item col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" *ngFor="let item of competence">
            <div class="competence-item-container">
                <div class="competence-item-image">
                    <img src={{item.image}}>
                </div>
                <div class="paragraphs" style="font-weight: bold; text-align: center;">
                    {{item.title}}
                </div>
                <br>
                <div class="paragraphs" style="text-align: center;">
                    {{item.description}}
                </div>
                <br>
                <div class="paragraphs" style="text-align: center;">
                    <span *ngIf="item.state == 'ACTIVO'" style="color: var(--naranja)"><strong>{{item.state}}</strong></span>
                    <span *ngIf="item.state == 'Próximamente'" style="color: var(--naranja)">{{item.state}}</span>
                    
                </div>
            </div>
        </div> 
    </div>
</section>

<!-- Recognition Section -->
<!-- <section class="recognition-section">
    <div class="subtitles"> 
        Reconocimientos
    </div>
    <div class="subtitles-2">
        Trabajamos siempre para ser tu mejor opción
    </div>
    <div class="recognition-container">
        <div class="recognition-item-container">
            <div class="recognition-item">
                <div class="medal">
                    <img src="assets/img/home/recognition/medal.svg">
                </div>
                <div class="recognition subtitles-2">
                    Primer Lugar
                </div>
                <div class="recognition-description paragraphs">
                    Bootcamp <span class="paragraphs">"reTHOS 2019"</span> organizado en Cuenca por la Universidad Politécnica Salesiana
                </div>
                <div class="recognition-image">
                    <img src="assets/img/home/recognition/recognition1.png">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- Allies Section -->
<!-- <section class="allies-section">
    <div class="allies-container">
        <div class="subtitles">
            Nuestros socios
        </div>
        <div class="subtitles-2">
            Nosotros trabajamos con los mejores, conócelos
        </div>
        <div class="ally-container row">
            <div class="ally-item-container col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" *ngFor="let item of allies">
                <div class="ally-item">
                    <div class="ally-image">
                        <img src={{item.image}}>
                    </div>
                    <div class="paragraphs" style="text-align: center; color: #004D4D; font-weight: bolder;">
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- Plan Section -->
<section class="plans-section">
    <div class="subtitles">
        Elige el plan que más se adapte a tu negocio
    </div>
    <div class="subtitles-2">
        Conoce los planes que tenemos para ti y elige el que más se adapte a tu negocio
    </div>
    <div class="subtitles-3">
        <div class="linea-naranja"></div>
        <strong>Whatsapp Marketing</strong>
    </div>

    <div class="plans-items row">
        <div class="center col-12">
            <p class="plan subtitles-2">{{ obtenerTextoEstado() }}</p>
            <input type="checkbox" [(ngModel)]="estadoInterruptor" id="miInterruptor">
            <label for="miInterruptor"></label>

        </div>
        <div class="plan-item-container col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" *ngFor="let item of plansChatbot; let i = index">
            <div class="plan-item" id={{item.id}}>
                <div class="plan-recomendado" *ngIf="item.recomendado, else elseBlock">
                    RECOMENDADO
         
                </div>
                <ng-template #elseBlock> <br> </ng-template>
                <div class="plan subtitles-2">
                    {{item.plan}}
                </div>
                <div class="plan-price-container">
                    <div *ngIf="estadoInterruptor" class="plan-price-value" style="color: #014258;">
                        ${{item.value*12}}
                    </div>
                    <div *ngIf="!estadoInterruptor" class="plan-price-value" style="color: #014258;">
                        ${{item.value2}}
                    </div>

                    <div *ngIf="estadoInterruptor" class="plan-price-mensual">
                        VALOR MENSUAL: ${{item.period}}
                    </div>
                    <div class="plan-normal-value">
                        Precio Normal ${{item.normalValue}}
                    </div>
                </div>
                <div class="plan-benefits">

                    <!-- /// plan chatbot   -->
                    <!-- <div class="plan-benefit-description paragraphs subtitle">
                        <br>
                        <strong>Chatbot</strong>
                    </div>
                    <div class="plan-benefit" *ngFor="let benefit of item.benefits">
                        <div *ngIf="!benefit" class="plan-benefit-check">
                            <br>
                        </div>
                        <div *ngIf="benefit" class="plan-benefit-check">
                            <img src="assets/img/icons/check_plans.svg" >
                        </div>
                        <div class="plan-benefit-description paragraphs">
                            {{benefit}}
                        </div>
                    </div> -->

                    <!-- /// plan benefitsMensajeria   -->
                    <div class="plan-benefit-description paragraphs subtitle">
                        <br>
                        <strong>Mensajeria Masiva</strong>
                    </div>
                    <div class="plan-benefit" *ngFor="let benefit of item.benefitsMensajeria">
                        <div *ngIf="!benefit" class="plan-benefit-check">
                            <br>
                        </div>
                        <div *ngIf="benefit" class="plan-benefit-check">
                            <img src="assets/img/icons/check_plans.svg" >
                        </div>
                        <div class="plan-benefit-description paragraphs">
                            {{benefit}}
                        </div>
                    </div>

                    <!-- /// plan benefitsAi   -->
                    <!-- <div class="plan-benefit-description paragraphs subtitle">
                        <br>
                        <strong>Inteligencia Artificial</strong>
                    </div>
                    <div class="plan-benefit" *ngFor="let benefit of item.benefitsAi">
                        <div *ngIf="!benefit" class="plan-benefit-check">
                            <br>
                        </div>
                        <div *ngIf="benefit" class="plan-benefit-check">
                            <img src="assets/img/icons/check_plans.svg" >
                        </div>
                        <div class="plan-benefit-description paragraphs">
                            {{benefit}}
                        </div>
                    </div> -->

                    <!-- /// plan benefitsuShops   -->
                    <!-- <div class="plan-benefit-description paragraphs subtitle">
                        <br>
                        <strong>uShops</strong>
                    </div>
                    <div class="plan-benefit" *ngFor="let benefit of item.benefitsuShops">
                        <div *ngIf="!benefit" class="plan-benefit-check">
                            <br>
                        </div>
                        <div *ngIf="benefit" class="plan-benefit-check">
                            <img src="assets/img/icons/check_plans.svg" >
                        </div>
                        <div class="plan-benefit-description paragraphs">
                            {{benefit}}
                        </div>
                    </div> -->


                    <!-- /// plan benefitsAdicionales   -->
                    <div   class="plan-benefit-description paragraphs subtitle">
                        <br>
                        <strong>Adicionales</strong>
                    </div>
                    <div class="plan-benefit" *ngFor="let benefit of item.benefitsAdicionales">
                        <div *ngIf="!benefit" class="plan-benefit-check">
                            <br>
                        </div>
                        <div *ngIf="benefit" class="plan-benefit-check">
                            <img src="assets/img/icons/check_plans.svg" >
                        </div>
                        <div class="plan-benefit-description paragraphs">
                            {{benefit}}
                        </div>
                    </div>

                </div>
                <a class="plan-button whatsapp-color"  href={{item.action}} target="_blank">
                    <a class="paragraphs">Info  <i class="fab fa-whatsapp"></i></a>
                </a>
                <!-- <div *ngIf="isLoggedIn" class="button-comprar"> -->
                    <a *ngIf="isLoggedIn" class="plan-button" [routerLink]="[item.actionBuy]" [queryParams]="{plan: item.plan}" >
                        <a  class="paragraphs">Comprar</a>
                    </a>
                <!-- </div> -->
                <!-- <div  class="button-comprar"> -->
                    <a *ngIf="!isLoggedIn" class="plan-button" [routerLink]="['/login']" [queryParams]="{plan: item.plan}" >
                        <a  class="paragraphs">Comprar</a>
                    </a>
                <!-- </div> -->
            </div>
        </div>
    </div>
    <!-- TuComponente.component.html -->

    

  
</section>

<!-- Call to action Section -->
<section class="call-to-action-section">
    <div class="call-to-action-container">
        <div class="subtitles" style="text-align: center; color: white; padding-top: 3rem;">
            Comienza a trabajar con XpertAssistant
        </div>
        <div class="call-to-action-button paragraphs">
            <a [routerLink]="'/signup-email'">Inicia ahora</a>
        </div>
    </div>
</section>

<!-- Faqs and Form Section -->
<section class="faqs-section">
    <div class="faqs-container">
        <div class="subtitles" style="text-align: center;">
            ¿Quieres saber más?
        </div>
        <div class="faqs-form-container row">
            <div class="form-container col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="form">
                    <div class="subtitles-2">
                        Envíanos un mensaje
                    </div>
                    <form [formGroup]="form" novalidate (ngSubmit)="save($event)">
                        <label for="name" class="paragraphs" style="font-weight: bold;">Nombre</label>
                        <input type="text" id="name" name="name" placeholder="Introduce tu nombre" formControlName="nombre">
                        <div *ngIf="form.get('nombre').errors  && form.get('nombre').touched">
                            <p *ngIf="form.get('nombre').hasError('required')" class="paragraphs error">
                                Es un campo requerido
                            </p>
                        </div>
                        <label for="email" class="paragraphs" style="font-weight: bold;">Correo Electrónico</label>
                        <input type="email" id="email" name="email" placeholder="Escribe tu correo electrónico" formControlName="email">
                        <div *ngIf="form.get('email').errors  && form.get('email').touched">
                            <p *ngIf="form.get('email').hasError('required')" class="paragraphs error">
                                Es un campo requerido
                            </p>
                            <p *ngIf="form.get('email').hasError('email')" class="paragraphs error">
                                Email incorrecto
                            </p>
                        </div>
                        <label for="phone" class="paragraphs" style="font-weight: bold;">Teléfono</label>
                        <input type="text" id="phone" name="phone" placeholder="Escribe tu número de teléfono (con código del país)" formControlName="telefono">
                        <div *ngIf="form.get('telefono').errors  && form.get('telefono').touched">
                            <p *ngIf="form.get('telefono').hasError('required')" class="paragraphs error">
                                Es un campo requerido
                            </p>
                        </div>

                        <label for="message" class="paragraphs" style="font-weight: bold;">Mensaje</label>
                        <textarea rows="2" type="text" id="message" name="message" placeholder="Escribe un mensaje" formControlName="mensaje"></textarea>
                        <div class="limit-text paragraphs">
                            {{form.get('mensaje').value.length}}/100
                        </div>
                        <div *ngIf="form.get('mensaje').errors  && form.get('mensaje').touched">
                            <p *ngIf="form.get('mensaje').hasError('required')" class="paragraphs error">
                                Es un campo requerido
                            </p>
                            <p *ngIf="form.get('mensaje').hasError('maxlength')" class="paragraphs error">
                                Puedes escribir hasta 100 caracteres
                            </p>
                        </div>
                        <button type="submit">Enviar</button>
                    </form>
                    <p *ngFor="let alert of alerts" class="alert">
                        <ngb-alert [type]="alert.type" (close)="close(alert)">{{ alert.message }}</ngb-alert>
                    </p>
                </div>  
            </div>
            <div class="faqs-container col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="subtitles-2">
                    Preguntas Frecuentes
                </div>
                <div class="faq">
                    <p>
                        <button type="button" class="btn btn-outline-primary paragraphs" (click)="isCollapsed1 = !isCollapsed1"
                                [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample">
                            {{faqs[0].question}}
                            <img src="assets/img/icons/button_click.svg">
                        </button>
                    </p>
                    <div id="collapseExample" [ngbCollapse]="isCollapsed1">
                        <div class="card">
                            <div class="card-body">
                                {{faqs[0].answer}}
                            </div>
                        </div>
                    </div>
                    <p>
                        <button type="button" class="btn btn-outline-primary paragraphs" (click)="isCollapsed2 = !isCollapsed2"
                                [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseExample">
                            {{faqs[1].question}}
                            <img src="assets/img/icons/button_click.svg">
                        </button>
                    </p>
                    <div id="collapseExample" [ngbCollapse]="isCollapsed2">
                        <div class="card">
                            <div class="card-body">
                                {{faqs[1].answer}}
                            </div>
                        </div>
                    </div>
                    <p>
                        <button type="button" class="btn btn-outline-primary paragraphs" (click)="isCollapsed3 = !isCollapsed3"
                                [attr.aria-expanded]="!isCollapsed3" aria-controls="collapseExample">
                            {{faqs[2].question}}
                            <img src="assets/img/icons/button_click.svg">
                        </button>
                    </p>
                    <div id="collapseExample" [ngbCollapse]="isCollapsed3">
                        <div class="card">
                            <div class="card-body">
                                {{faqs[2].answer}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Footer -->
<app-footer [links_footer]="links"></app-footer>


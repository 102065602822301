<app-navbar [links_navbar]="links"></app-navbar>

<section class="header-section">
    <div class="header-container row">
        <div class="header-info col-12 col-sm-12 col-md-6 col-lg6 col-xl-6">
            <div class="title">
                Nosotros
            </div>
            <div class="subtitle" style="margin-top: 2rem;">
                Acerca de nosotros
            </div>
            <div class="paragraph" style="margin-top: 1rem;">
                Somos un equipo de jóvenes emprendedores ecuatorianos, que cree firmemente en el desarrollo tecnológico colaborativo como fuente de progreso y prosperidad.
            </div>
        </div>
        <div class="header-image col-12 col-sm-12 col-md-6 col-lg6 col-xl-6">
            <img src="assets/img/icons/logotipo.svg">
        </div>
    </div>  
</section>

<section class="main-section">
    <div class="mision-vision">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <img src="assets/img/quienes-somos/mision.svg">
                <h3 class="subtitle" style="margin-top: 1rem;">Misión</h3>
                <p class="paragraph" style="margin-top: 1rem;">Conectar a comercios con usuarios de una manera efectiva, eficiente y colaborativa, mediante el uso de la tecnología.</p>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <img src="assets/img/quienes-somos/vision.svg">
                <h3 class="subtitle" style="margin-top: 1rem;">Visión</h3>
                <p class="paragraph" style="margin-top: 1rem;">Ser la mejor startup a nivel mundial en comercio electrónico usando la innovación como eje central, brindando el mejor servicio y experiencia posible, generando confianza en nuestros clientes.</p>
            </div>
        </div>
    </div>
    <div class="team">
        <div class="subtitle" style="margin-top: 2rem;">
            Nuestro equipo
        </div>
        <div class="team-container row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" *ngFor="let item of team">
                <div class="team-item-container">
                    <div class="team-item-pic">
                        <img src={{item.image}} alt="">
                    </div>
                    <div class="team-item-name">
                        {{item.name}}
                    </div>
                    <div class="team-item-bussiness">
                        {{item.bussiness}}
                    </div>
                    <div class="team-item-quote">
                        {{item.quote}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer [links_footer]="links"></app-footer>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
// import "rxjs/Rx";
import { catchError } from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {User} from './User'
// import { from } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SignUpService {
  dominio: string = 'http://52.42.103.199:30000/users';
  postpage: string = 'http://52.42.103.199:30000/signup';

  ipPublica = 'https://pruebas.ushops.tech';
  baseUser = this.ipPublica + '/api' + '/egm-servicios';
  basePay = this.ipPublica + '/pay-egm';



  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient) { }


  sendEmail(params: any): Observable<any>{
    return this.http.patch(`${this.baseUser}/contacto`, params,{}).pipe(catchError( this.handleError));
  }

  prefijo(): Observable<any>{
    return this.http.patch(`${this.basePay}/prefijos`, {},{}).pipe(catchError( this.handleError));
  }

  userData(params: any): Observable<any>{
    return this.http.patch(`${this.basePay}/usuario`, params,{}).pipe(catchError( this.handleError));
  }

  confirmPayment(params: any): Observable<any>{
    return this.http.patch(`${this.basePay}/solicitudPago`, params,{}).pipe(catchError( this.handleError));
  }

  getUser(){
    return this.http.get<User[]>(this.dominio)
    // .map((res: any) => res);
  }
  postUser(newUser: User){
    console.log(newUser);
    return this.http.post(this.postpage, newUser, this.httpOptions)
    .pipe(catchError(this.handleError));
  }
  deleteUser(id: string){
    return this.http.delete(this.dominio+'/'+id)
    //  .map((res: any) => res);
  }



  handleError(error: HttpErrorResponse) {
    console.log('error desconocido: ', error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('error:', error.error);
    } else {
      if (error.status === 401) {
        console.error('no autorizado:');
        localStorage.removeItem('jwt');
      }
    }
    return throwError(new Error(error.statusText));
//      return new throwError('OK');
  }

}

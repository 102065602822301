<section>
    <div class="signup-container">
        <a class="logotipo" [routerLink]="'/home'">
            <img src="assets/img/icons/logotipo.svg" alt="">
        </a>
        <div class="titulo">
            ¡Tu cuenta ha sido creada!
        </div>
        <div class="subtitulo">
            Crea tu contraseña
        </div>
        <form [formGroup]="form" novalidate (ngSubmit)="save($event)">
            <div class="password">
                <input type="password" placeholder="Escribe una contraseña" id="password" formControlName="password">
                <img src="assets/img/icons/visible.svg" (click)="showpassword()">
            </div>
            <div *ngIf="form.get('password').errors && form.get('password').touched">
                <p *ngIf="form.get('password').hasError('required')" class="error">
                    Ingresa la contraseña
                </p>
                <p *ngIf="form.get('password').hasError('minlength')" class="error">
                    La contraseña debe tener 8 caracteres o más
                </p>
            </div>
            <div class="password">
                <input type="password" placeholder="Confirma la contraseña" id="passwordverify"
                    formControlName="passwordcontrol">
                <img src="assets/img/icons/visible.svg" (click)="showpasswordverify()">
            </div>
            <div *ngIf="form.get('passwordcontrol').errors && form.get('passwordcontrol').touched">
                <p *ngIf="form.get('passwordcontrol').hasError('required')" class="error">
                    Repite la contraseña
                </p>
                <p *ngIf="form.get('passwordcontrol').hasError('minlength')" class="error">
                    La contraseña debe tener 8 caracteres o más
                </p>
            </div>
            <p *ngIf="differentPasswords === true" class="error">
                Las contraseñas no coinciden
            </p>
            <!-- <div class="verify">
                <p>La contraseña debe tener letras y números</p>
            </div> -->
            <div class="crear-cuenta">
                <button type="submit">Crear contraseña</button>
            </div>
        </form>
    </div>
</section>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  nombreUsuario: string = '';
  apellidoUsuario: string = '';
  isCollapsedUser = false;
  isCollapsedCompany = false;

  quota: any;

  passwordForm = new FormGroup({
    actualPass: new FormControl('', [Validators.required, Validators.minLength(8)]),
    newPass: new FormControl('', [Validators.required, Validators.minLength(8)]),
    repeatPass: new FormControl('', [Validators.required, Validators.minLength(8)])
  })

  constructor(private auth: AuthService,
    private profileService: ProfileService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.nombreUsuario = this.auth.getNombres();
    this.apellidoUsuario = this.auth.getApellidos();
    this.getQuotas();
    this.toggleContent('user');
  }

  toggleContent(value: string) {

    let buttons = document.getElementsByClassName('profile-button');

    if (buttons) {
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].classList.remove('btn-primary');
        buttons[i].classList.add('btn-outline-primary');
      }
    }

    let element = document.getElementById(value);

    if (element) {
      element.classList.remove('btn-outline-primary');
      element.classList.add('btn-primary');
    }

    switch (value) {
      case 'user':
        this.isCollapsedCompany = true;
        this.isCollapsedUser = false;
        break;
      case 'company':
        this.isCollapsedUser = true;
        this.isCollapsedCompany = false;
        break;
      default:
        break;
    }
  }

  getQuotas() {
    let body: any = {};
    console.log('refreshCampaigns')
    this.profileService.obtenerUnaQuota(body).subscribe(
      result => {
        this.quota = result;
        // console.log('quota', result);
      },
      error => {
        console.log('Error al obtener campañas: ', error);
        this.toastr.error('Ha ocurrido un error al obtener las campañas.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    );
  }

  changePassword() {
    console.log('valores password: ', this.passwordForm.value);

    if (this.passwordForm.invalid) {
      this.toastr.error('Existen errores en el formulario para cambiar la contraseña.', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    if (this.passwordForm.value.newPass !== this.passwordForm.value.repeatPass) {
      this.toastr.error('Las contraseñas no son iguales.', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
      return;
    }
  }

}

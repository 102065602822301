<app-navbar></app-navbar>

<section class="header-section">
    <div class="background-img">
        <div class="title">
            ¡Hola! Cómo podemos ayudarte
        </div>
        <div class="searcher">
            <div class="subject-container">
                <select name="subject" id="subject">
                    <option value="vendedor">Vendedor</option>
                    <option value="comprador">Comprador</option>
                </select>
            </div>
            <div class="search">
                <input type="text">
                <img src="assets/img/icons/lupa.svg">
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>
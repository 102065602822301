import { Component, OnInit } from '@angular/core';
import { DataTransferService } from '../services/data-transfer.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  links: Array<Object>;

  team: Array<Object> = [
    {
      image: 'assets/img/quienes-somos/team1.png',
      name: 'Javier Chicaiza',
      bussiness: 'CEO uShops',
      quote: '“Desarrollador tecnológico, emprendedor y futuro empresario. Apasionado por el aprendizaje y el conocimiento”'
    },
    {
      image: 'assets/img/quienes-somos/team2.png',
      name: 'María Sol Soria',
      bussiness: 'COO uShops',
      quote: '“Líder principal del equipo de desarrollo, emprendedora, creativa y apasionada por la música y la tecnología”'
    },
    {
      image: 'assets/img/quienes-somos/team3.png',
      name: 'Johana Quiñónez',
      bussiness: 'Diseñadora UX/UI de interfaces',
      quote: '“Diseñadora de interfaces digitales. Me gusta aprender y convertir productos en toda una experiencia para el usuario”'
    },
    {
      image: 'assets/img/quienes-somos/team4.png',
      name: 'Jefferson Antamba',
      bussiness: 'Ingeniero en frontend',
      quote: '“Desarrollador web en el área de Frontend. Apasionado por la tecnología, el aprendizaje y la investigación”'
    }, 
    {
      image: 'assets/img/quienes-somos/team5.png',
      name: 'Daniel Córdova',
      bussiness: 'Ingeniero en frontend ',
      quote: '“Desarrollador web en el área de Frontend y Backend. Especializado en robótica y automatización Industrial”'
    }, 
    {
      image: 'assets/img/quienes-somos/team6.png',
      name: 'Andrés Cóndor',
      bussiness: 'Ingeniero en aplicaciones móviles',
      quote: '“Desarrollador de aplicaciones móviles, autodidacta y perseverante.  Interesado en la automatización y tecnología.”'
    }
  ]

  constructor(private data: DataTransferService) { }

  ngOnInit(): void {
    this.data.currentData.subscribe(message => this.links = message);
  }

}

import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SharedComponentsService {

    public pushMessage = new Subject<any>();

    constructor() { }

    CallComponentMethod(message: any) {
        this.pushMessage.next(message);
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            // console.error('error:', error.error);
        } else {
            if (error.status === 401) {
                console.error('no autorizado:');
                localStorage.removeItem('jwt');
            }
        }
        return throwError(error);
    }
}
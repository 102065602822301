<app-navbar [links_navbar]="links"></app-navbar>

<section  class="header-section">
    <div class="header-container row">
        <div class="header-info col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="title">
                ¿Cómo empezar a vender en uShops?
            </div>
            <div class="subtitle">
                Guía para principiantes
            </div>
            <div class="paragraph">
                Sigue los pasos que te presentamos a continuación y comienza a vender tus productos en línea y a generar más ingresos para tu negocio.
            </div>
        </div>
        <div class="header-image col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <img src="assets/img/guide/guideimage.svg">
        </div>
    </div>
</section>

<section class="main-section">
    <div class="main-container" (scroll)="scrollfunction($event)">
        <div class="linea">
            <img src="assets/img/guide/point.svg" id="point">
            <div class="slider">   
            </div>
        </div>
        <div class={{item.style1}} *ngFor="let item of pasos, let i = index">
            <div class="index">
                0{{i+1}}
            </div>
            <div class="subtitle">
                {{item.step}}
            </div>
            <div class="paragraph">
                {{item.description}}
            </div>
        </div>
        <div class={{item.style2}} *ngFor="let item of pasos">
            <img src={{item.image}}>
        </div>
    </div>
</section>

<app-footer [links_footer]="links"></app-footer>
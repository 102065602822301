import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { DataTransferService } from '../services/data-transfer.service';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import { AuthService } from '../services/auth.service';

interface Alert {
  type: string;
  message: string;
}

const ALERTS: Alert[] = [{
    type: 'success',
    message: 'La solicitud ha sido enviada exitosamente, muy pronto nos contactaremos contigo',
}];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: []
})
export class HomeComponent implements OnInit {
  estadoInterruptor = false;    
   
  isLoggedIn: boolean = false;


  element1: HTMLElement;

  benefits: Array<any> = [
    {
      image: 'assets/img/home/benefits/benefits1.svg',
      description: 'Mejor conexión con tus clientes.'
    }, {
      image: 'assets/img/home/benefits/benefits2.svg',
      description: 'Vende incluso sin estar tu al pendiente.'
    }, {
      image: 'assets/img/home/benefits/benefits3.svg',
      description: 'Mayor eficiencia en las ventas.'
    }, {
      image: 'assets/img/home/benefits/benefits4.svg',
      description: 'Tu negocio en contacto a toda hora.'
    }, {
      image: 'assets/img/home/benefits/benefits5.svg',
      description: 'Sin limitaciones geográficas.'
    }, {
      image: 'assets/img/home/benefits/benefits6.svg',
      description: 'Porque todos están en línea.'
    }
  ];

  competence: Array<any> = [
    {
      title: 'SalesXpert',
      description: 'Asistente de ventas, te permite realizar la conversion con tus clientes de manera automática.',
      image: 'assets/img/home/competence/competence1.svg',
      state: 'ACTIVO'
    }, {
      title: 'MeetPlanner',
      description: 'Te permite agendar citas de manera autómatica, solicitando información importante a tu cliente.',
      image: 'assets/img/home/competence/competence2.svg',
      state: 'Próximamente'
    }, {
      title: 'CustomerSupport ',
      description: 'Da soporte técnico a tus clientes de manera automatizada, entregando información de sus productos ya adquiridos',
      image: 'assets/img/home/competence/competence3.svg',
      state: 'Próximamente'
    }
  ];

  allies: Array<any> = [
    {
      image: 'assets/img/home/allies/ally1.svg',
      name: 'Start ups',
    }, {
      image: 'assets/img/home/allies/ally2.svg',
      name: 'EGM Robotics',
    }, {
      image: 'assets/img/home/allies/ally3.svg',
      name: 'Click gas',
    }
  ];

  recognitions: Array<Object> = [
    {
      image: 'assets/img/home/recognition/recognition1.png',
      recognition: 'Primer Lugar',
      description: 'Bootcamp "reTHOS 2019" organizado en Cuenca por la Universidad Politécnica Salesiana'
    }
  ];

  plansChatbot: any = [
    {
      recomendado: false,
      plan: 'PLAN BÁSICO',
      value: 15,
      value2: 20,
      normalValue: 25,
      period: 15,
      benefits: [
        '1 usuario',
        'Cero costo de creación',
        'Chatbot SI',
        'Respuestas automaticas 500',
      ],
      benefitsMensajeria: [
        'Vinculación mediante QR',
        '1 500 mensajes',

        'Adjunto de imágenes',
//        'Adjunto de videos',
        'Adjunto de documentos',
        'Automatización de envios en línea',
        'Registro automatizado de clientes',
      ],
      benefitsAi: [
        'Asistente Virtual con IA NO',
      ],
      benefitsuShops: [
        'Acceso limitado al ecommerce uShops'
      ],
      benefitsAdicionales: [
//        'API de conexión',
        'Respaldo de datos',
        
        'Chat en línea',
        'Analítica de información básica',

      ],
      id: 'basico',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20BÁSICO,%20¿me%20puedes%20ayudar?',
      actionBuy: '/backoffice/payment',
    },
    {
      recomendado: true,
      plan: 'PLAN MEDIUM',
      value: 25,
      value2: 32,
      normalValue: 45,
      period: 25,
      benefits: [
        '3 usuarios',
        'Cero costo de creación',
        'Chatbot SI',
        'Respuestas automaticas 3000',
      ],
      benefitsMensajeria: [

        'Vinculación mediante QR',
        '5 000 mensajes',

        'Adjunto de imágenes',
        'Adjunto de videos',
        'Adjunto de documentos',
        'Automatización de envios en línea',
        'Registro automatizado de clientes',
      ],
      benefitsAi: [
        'Asistente Virtual con IA NO',
      ],
      benefitsuShops: [
        'Acceso moderado al ecommerce uShops'
      ],
      benefitsAdicionales: [
        'API de conexión',
        'Respaldo de datos',
        
        'Chat en línea',
        'Analítica de información básica',


      ],
      id: 'basico',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20MEDIUM,%20¿me%20puedes%20ayudar?',
      actionBuy: '/backoffice/payment',
    },

    {
      recomendado: false,
      plan: 'PLAN FULL',
      value: 45,
      value2: 60,
      normalValue: 75,
      period: 45,
      benefits: [
        '3 usuarios',
        'Cero costo de creación',
        'Chatbot SI',
        'Respuestas automaticas 3000',
      ],
      benefitsMensajeria: [

        'Vinculación mediante QR',
        '20 000 mensajes',

        'Adjunto de imágenes',
        'Adjunto de videos',
        'Adjunto de documentos',
        'Automatización de envios en línea',
        'Registro automatizado de clientes',
      ],
      benefitsAi: [
        'Asistente Virtual con IA NO',
      ],
      benefitsuShops: [
        'Acceso moderado al ecommerce uShops'
      ],
      benefitsAdicionales: [
        'API de conexión',
        'Respaldo de datos',
        
        'Chat en línea',
        'Analítica de información básica',


      ],
      id: 'full',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20FULL,%20¿me%20puedes%20ayudar?',
      actionBuy: '/backoffice/payment',
    },
  ]

  plans: Array<any> = [
    
    {
      recomendado: false,
      plan: 'PLAN STARTER',
      value: 35,
      value2: 42,
      normalValue: 'Precio Normal $50',
      period: 'VALOR MENSUAL',
      benefits: [
        '1 usuario',
        'Chatbot SI',
        'Respuestas automaticas 1200',

      ],
      benefitsMensajeria: [
        '800 Mensajes',
      ],
      benefitsAi: [
        'Asistente Virtual SI',
        'Cantidad de conversaciones automatizadas 30',
      ],
      benefitsuShops: [
        'Acceso moderado al ecommerce uShops',
      ],
      benefitsAdicionales: [
        'API de conexión',
        'Respaldo de datos',
        'Creación de catálogo de clientes',
        'Chat en línea',

        // 'Analítica de información media'
        // 'Soporte técnico medio'
         
      ],
      id: 'starter',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20STARTER,%20¿me%20puedes%20ayudar?',
      actionBuy: '/backoffice/payment',
    },
    {
      recomendado: true,
      plan: 'PLAN PREMIUM',
      value: '$65',
      value2: '$78',
      normalValue: 'Precio Normal $85',
      period: 'VALOR MENSUAL',
      benefits: [
        '1 usuario',
        'Chatbot SI',
        'Respuestas automaticas 4000',
      ],

      benefitsMensajeria: [
        '3000 Mensajes',
      ],
      benefitsAi: [
        'Asistente Virtual SI',
        'Cantidad de conversaciones automatizadas 100',
        'Analítica de información moderada',
      ],
      benefitsuShops: [
        'Acceso avanzado al ecommerce uShops',
      ],
      benefitsAdicionales: [
        'API de conexión',
        'Respaldo de datos',
        'Creación de catálogo de clientes',
        'Chat en línea',

        // 'Analítica de información avanzada'

        // 'Soporte técnico avanzado'        
      ],
      id: 'premium',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20PREMIUM,%20¿me%20puedes%20ayudar?',
      actionBuy: '/backoffice/payment',
    },
    {
      recomendado: false,
      plan: 'PLAN ENTERPRISE',
      value: '$95',
      value2: '$110',
      normalValue: 'Precio Normal  $125',
      period: 'VALOR MENSUAL',
      benefits: [
        '2 usuarios',
        'Chatbot SI',
        'Asistente Virtual SI',
        'Respuestas automaticas 10000',





      ],
      benefitsMensajeria: [
        '8000 Mensajes',
      ],
      benefitsAi: [
        'Asistente Virtual SI',
        'Cantidad de conversaciones automatizadas 200',
        'Analítica de información completa',
      ],
      benefitsuShops: [
        'Acceso completo al ecommerce uShops',
      ],
      benefitsAdicionales: [
        'API de conexión',
        // 'Soporte técnico full'     
        'Respaldo de datos',
        'Creación de catálogo de clientes',
        'Chat en línea',

        // 'Analítica de información avanzada'

      ],

      id: 'golden',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20ENTERPRISE,%20¿me%20puedes%20ayudar?',
      actionBuy: '/backoffice/payment',
    },
  ];

  isCollapsed1: boolean = true;
  isCollapsed2: boolean = true;
  isCollapsed3: boolean = true;

  faqs: Array<any> = [
    {
      question: '¿Cómo creo mi asistente virtual?',
      answer: 'Puedes registrarte en nuestra plataforma y contunuar los pasos desecritos en la página.',
    }, 
    {
      question: '¿Qué es XpertAssistant?',
      answer: 'XpertAssistant es el Asistente Virtual dedicado a ventas, este te permite responder de manera automatica a tus clientes.',
    },
    {
      question: '¿soy emprendedor, puedo adquirir mi AsistenteVirtual?',
      answer: 'Si, uShops te permite que como emprendedor accedas a tu Asistente de Ventas.',
    }
  ];

  links: Array<Object> = [
    {
      title: 'Quienes somos',
      subtitles: [
        {
          subtitle: 'Nosotros',
          link: 'about-us'
        }, {
          subtitle: 'Compromiso con la ciudadania',
          link: 'home'
        }, {
          subtitle: 'Galería',
          link: 'home'
        }, {
          subtitle: 'Contactos',
          link: 'contact'
        }
      ]
    }, {
      title: 'Cómo funciona',
      subtitles: [
        {
          subtitle: 'Guía para principiantes',
          link: 'home'
        }, {
          subtitle: 'Servicios',
          link: 'home'
        }, {
          subtitle: 'Marketing para tu negocio',
          link: 'home'
        }, {
          subtitle: 'Trabaja con nosotros',
          link: 'home'
        }
      ]
    }, {
      title: 'Precios',
      subtitles: [
        {
          subtitle: 'Planes',
          link: 'home'
        }, {
          subtitle: 'Otros costos',
          link: 'home'
        }
      ]
    }, {
      title: 'Ayuda',
      subtitles: [
        {
          subtitle: 'Servicio al cliente',
          link: 'home'
        }, {
          subtitle: 'Preguntas Frecuentes',
          link: 'home'
        }
      ]
    }
  ];

  message: Array<Object>;

  form: FormGroup;

  alerts: Alert[];

  constructor(
    private data: DataTransferService, 
    private formBuilder: FormBuilder, 
    private enviar: ApiService,
    private title: Title,
    private seo: SeoService,
    private auth: AuthService,

    @Inject(PLATFORM_ID) private platformId) {
    this.buildForm();
  }

  @HostListener('document:scroll', ['$event']) scrollfunction(event) {
    let aux3 = event.path[1].scrollY;
    var aux = 1.5*aux3;
    //console.log(window.innerHeight, window.innerWidth);
    
    if(aux <= (window.innerHeight/2)) {
      aux=window.innerHeight/2;
    }
    
    if(aux >= (0.9*window.innerHeight)) {
      aux=0.9*window.innerHeight;
    }

    //console.log(aux.toString() + 'px');
    this.element1.style.top = aux.toString() + 'px';
    var aux2 = ((aux-window.innerHeight/2)/(0.9*window.innerHeight-window.innerHeight/2));
    //console.log(aux2);
    this.element1.style.opacity = aux2.toString();
  }

  @HostListener('window:load', ['$event']) onPageLoad(event: Event) {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById('free').style.backgroundColor = '#F8FAFA';
      document.getElementById('basico').style.backgroundColor = '#F8FAFA';
      document.getElementById('premium').style.backgroundColor = '#D0FDFD';
      document.getElementById('golden').style.backgroundColor = '#F8FAFA';
    }
  }

  ngOnInit(): void {

    this.isLoggedIn = this.auth.getToken() ? true : false;
    
    let t: string = 'XpertAssistant';
    this.title.setTitle(t);
    this.seo.generateTag({
      title: 'uShops - Inicio',
      description: 'Crea tu tienda en línea',
      slug: 'home'
    })
    
    if (isPlatformBrowser(this.platformId)) {
      this.element1 = document.getElementById('element1');
    }
    this.data.currentData.subscribe(message => this.message = message);
    this.data.changeData(this.links);
  }

  private buildForm() {
    
    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mensaje: ['', [Validators.required, Validators.maxLength(100)]]
    });
    
  }

  save(event: Event) {
    event.preventDefault();
      if(this.form.valid) {
      const value = this.form.value;
      console.group(value);

      const body = {
        "key": "YnGZfn8wffwCyNhFJQM7",
        "tipo": "formulario",
        nombre: this.form.get('nombre').value,
        email: this.form.get('email').value,
        telefono: this.form.get('telefono').value,
        mensaje: this.form.get('mensaje').value,
      }



      this.enviar.formulariocontacto(body).subscribe(
        result => {
          this.reset();
        }, error => {
          alert('Ocurrió un evento inesperado. Intenta de nuevo')
        }
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  reset() {
    this.alerts = Array.from(ALERTS);

    setTimeout(()=>{

    this.form.reset();

    }, 1500)


  }
  obtenerTextoEstado(): string {
    return this.estadoInterruptor ? 'Pago Anual' : 'Pago Mensual';
  }
}



/*

submitForm() {
  // document.getElementById('mensaje-correo').style.display = 'block'
  // return;
  console.log('FORMULARIO', this.form1)
  const body = {
    "key": "dsjkfhsh8u843hm7f8h438d5m834m7d5qmh4d8",
    "tipo": "formulario",
    nombre: this.form1.get('name').value,
    email: this.form1.get('email').value,
    telefono: this.form1.get('phone').value,
    mensaje: this.form1.get('message').value,
  }


  if (this.form1.valid) {
    this.api.submitForm(body).subscribe(
      result => {
        console.log('CORREO ENVIADO', result)
        document.getElementById('mensaje-correo').style.display = 'block'
      },
      error => {
        console.log('error', error)
      }
    )
  }
}

*/

<app-navbar [links_navbar]="links"></app-navbar>

<section class="header-section">
    <div class="header-container">
        <div class="title">
            uShops siempre contigo
        </div>
        <div class="subtitle">
            Contáctate con nosotros
        </div>
        <div class="paragraph">
            Sigue los pasos que te presentamos a continuación y comienza a vender tus productos en línea y a generar más ingresos para tu negocio.
        </div>
    </div>
</section>

<section class="main-section">
    <div class="subtitle">
        Información de contacto
    </div>
    <div class="contact-container row">
        <div class="contact-item col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" *ngFor="let item of contact">
            <div class="contact-image">
                <img src={{item.image}}>
            </div>
            <div class="subtitle-2">
                {{item.title}}
            </div>
            <div class="paragraph">
                {{item.info}}
            </div>
        </div>
    </div>
    <div class="subtitle">
        Envíanos un mensaje
    </div>
    <div class="form-container row">
        <div class="form col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="form-box">
                <div class="subtitle-2">
                    Formulario de contacto
                </div>
                <form [formGroup]="form" novalidate (ngSubmit)="save($event)">
                    <label for="name" class="paragraphs" style="font-weight: bold;">Nombre</label>
                    <input type="text" id="name" name="name" placeholder="Introduce tu nombre" formControlName="nombre">
                    <div *ngIf="form.get('nombre').errors  && form.get('nombre').touched">
                        <p *ngIf="form.get('nombre').hasError('required')" class="paragraphs error">
                            Es un campo requerido
                        </p>
                    </div>
                    <label for="email" class="paragraphs" style="font-weight: bold;">Correo Electrónico</label>
                    <input type="email" id="email" name="email" placeholder="Escribe tu correo electrónico" formControlName="email">
                    <div *ngIf="form.get('email').errors  && form.get('email').touched">
                        <p *ngIf="form.get('email').hasError('required')" class="paragraphs error">
                            Es un campo requerido
                        </p>
                        <p *ngIf="form.get('email').hasError('email')" class="paragraphs error">
                            Email incorrecto
                        </p>
                    </div>
                    <label for="phone" class="paragraphs" style="font-weight: bold;">Teléfono</label>
                    <input type="text" id="phone" name="phone" placeholder="Escribe tu número de teléfono" formControlName="telefono">
                    <label for="message" class="paragraphs" style="font-weight: bold;">Mensaje</label>
                    <textarea rows="2" type="text" id="message" name="message" placeholder="Escribe un mensaje" formControlName="mensaje"></textarea>
                    <div class="limit-text paragraphs">
                        {{form.get('mensaje').value.length}}/100
                    </div>
                    <div *ngIf="form.get('mensaje').errors  && form.get('mensaje').touched">
                        <p *ngIf="form.get('mensaje').hasError('required')" class="paragraphs error">
                            Es un campo requerido
                        </p>
                        <p *ngIf="form.get('mensaje').hasError('maxlength')" class="paragraphs error">
                            Puedes escribir hasta 100 caracteres
                        </p>
                    </div>
                    <button type="submit">Enviar</button>
                </form>
                <p *ngFor="let alert of alerts" class="alert">
                    <ngb-alert [type]="alert.type" (close)="close(alert)">{{ alert.message }}</ngb-alert>
                </p>
            </div>
        </div>
        <div class="social col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="subtitle-2">
                Síguenos en nuestras redes sociales
            </div>
            <div class="social-icons">
                <a href="https://web.facebook.com/uShopsMarket" target="_blank"><img src="assets/img/contact/facebook.svg"></a>
                <!--
                <a href=""><img src="assets/img/contact/twitter.svg"></a>
                -->
                <a href="https://www.instagram.com/ushops.market/" target="_blank"><img src="assets/img/contact/instagram.svg"></a>
                <a href="https://wa.me/593990269366?text=Estoy%20interesado%20en%20registrarme%20en%20uShops,%20¿me%20puedes%20ayudar?" target="_blank"><img src="assets/img/contact/whatsapp.svg"></a>
            </div>
        </div>
    </div>
</section>

<app-footer [links_footer]="links"></app-footer>

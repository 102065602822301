import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format,
} from 'date-fns';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ScheduleService } from 'src/app/services/schedule.service';

interface Film {
  id: number;
  title: string;
  release_date: string;
}

function getTimezoneOffsetString(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const hoursOffset = String(
    Math.floor(Math.abs(timezoneOffset / 60))
  ).padStart(2, '0');
  const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
  const direction = timezoneOffset > 0 ? '-' : '+';

  return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
}


@Component({
  selector: 'app-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss']
})
export class EventsCalendarComponent implements OnInit {

  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();

  events$: Observable<CalendarEvent<any>[]>;

  activeDayIsOpen: boolean = false;

  colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
    orange: {
        primary: '#FFA500',  // Color primario naranja
        secondary: '#FFDAB9', // Color secundario naranja claro (peach puff)
    },
};

  constructor(private http: HttpClient, private apiScheduler: ScheduleService) { }

  ngOnInit(): void {
    // this.fetchEvents();
    this.obtenerCitas();

  }



  obtenerCitas() {
    let body = {};
  
    this.apiScheduler.obtenerCitasAgendadas(body).pipe(
      map((result: any[]) => {
        console.log('Resultado recibido:', result);
  
        // Verificamos si el resultado contiene citas
        if (result && result.length > 0) {
          // Mapeamos las citas al formato que necesita el calendario
          return result.map((item: any) => ({
            // title: `${item.nombre} ${item.apellido} | ${new Date(item.fechaReserva).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
            title: `${item.nombre} ${item.apellido} | ${new Date(item.fechaReserva).getUTCHours().toString().padStart(2, '0')}:${new Date(item.fechaReserva).getUTCMinutes().toString().padStart(2, '0')}`,

            start: new Date(item.fechaReserva),
            color: this.colors.orange,  // Verifica que `this.colors.yellow` esté bien definido
            allDay: true,
            meta: {
              telefono: item.telefono,
              email: item.email,
              nPersonas: item.nPersonas
            }
          }));
        } else {
          console.warn('No se encontraron citas.');
          return []; // Retornamos un arreglo vacío si no hay citas
        }
      })
    ).subscribe(
      eventos => {
        // Asignamos el resultado procesado a `events$` como un Observable
        this.events$ = of(eventos);
        console.log('Eventos asignados a events$:', eventos);
      },
      error => {
        console.error('Error al cargar los datos de citas:', error);
      }
    );
  }


  // fetchEvents(): void {
  //   const getStart: any = {
  //     month: startOfMonth,
  //     week: startOfWeek,
  //     day: startOfDay,
  //   }[this.view];

  //   const getEnd: any = {
  //     month: endOfMonth,
  //     week: endOfWeek,
  //     day: endOfDay,
  //   }[this.view];

  //   const params = new HttpParams()
  //     .set(
  //       'primary_release_date.gte',
  //       format(getStart(this.viewDate), 'yyyy-MM-dd')
  //     )
  //     .set(
  //       'primary_release_date.lte',
  //       format(getEnd(this.viewDate), 'yyyy-MM-dd')
  //     )
  //     .set('api_key', '0ec33936a68018857d727958dca1424f');

  //   this.events$ = this.http
  //     .get('https://api.themoviedb.org/3/discover/movie', { params })
  //     .pipe(
  //       map(({ results }: { results: Film[] }) => {
  //         console.log(results)
  //         let resultado = results.map((film: Film) => {
  //           return {
  //             title: film.title,
  //             start: new Date(
  //               film.release_date + getTimezoneOffsetString(this.viewDate)
  //             ),
  //             color: this.colors.yellow,
  //             allDay: true,
  //             meta: {
  //               film,
  //             },
  //           };
  //         });
  //         console.log(resultado)
  //         return resultado
  //       })
  //     );
  // }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ film: Film }>[];
  }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  eventClicked(event): void {
    const url = `${window.location.origin}/backoffice/client-info?tlf=${event.meta.telefono}`;
    console.log(event, url)
    window.open(url, '_blank');
  }
}

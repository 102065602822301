<div class="header-buttons-container">
    <button id="user" type="button" class="btn btn-outline-primary ms-2 profile-button" (click)="toggleContent('user')"
        aria-controls="collapse">
        Usuario
    </button>
    <button id="company" type="button" class="btn btn-outline-primary ms-2 profile-button"
        (click)="toggleContent('company')" aria-controls="collapse">
        Empresa
    </button>
</div>

<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedUser">
    <div class="user-content">
        <div class="card left-side">
            <img src="assets/icons/user-avatar-icon.png" alt="user-avatar-icon" class="user-image">
            <p class="user-name">{{nombreUsuario}} {{apellidoUsuario}}</p>
        </div>
        <div class="right-side">
            <div class="card change-password-container">
                <form class="form-box" [formGroup]="passwordForm" autocomplete="off" (submit)="changePassword()">
                    <h4>Cambiar contraseña</h4>
                    <div class="item-container">
                        <label for="actualPass">Contraseña actual: </label>
                        <input id="actualPass" name="actualPass" type="password" class="form-control password-input"
                            formControlName="actualPass" autocomplete="off">
                        <label
                            *ngIf="!passwordForm.controls['actualPass'].valid && passwordForm.controls['actualPass'].touched"
                            class="mensaje-error">
                            Error la contraseña debe tener como mínimo 8 caracteres
                        </label>
                    </div>
                    <div class="item-container">
                        <label for="newPass">Nueva contraseña: </label>
                        <input id="newPass" name="newPass" type="password" class="form-control password-input"
                            formControlName="newPass" autocomplete="off">
                        <label
                            *ngIf="!passwordForm.controls['newPass'].valid && passwordForm.controls['newPass'].touched"
                            class="mensaje-error">Error la contraseña debe tener como mínimo 8 caracteres
                        </label>
                    </div>
                    <div class="item-container">
                        <label for="repeatPass">Repetir contraseña: </label>
                        <input id="repeatPass" name="repeatPass" type="password" class="form-control password-input"
                            formControlName="repeatPass" autocomplete="off">
                        <label
                            *ngIf="!passwordForm.controls['repeatPass'].valid && passwordForm.controls['repeatPass'].touched"
                            class="mensaje-error">Error la contraseña debe tener como mínimo 8 caracteres
                        </label>
                    </div>
                    <div class="footer-buttons">
                        <button type="submit" class="btn btn-primary">Cambiar contraseña</button>
                    </div>
                </form>
            </div>
            <div class="card quotas-container">
                <h4>Informacion de Cuotas</h4>
                <div class="quotas-item">
                    <span>Limite de mensajes:</span>
                    <span class="ml-2">{{quota?.limit_messages}}</span>
                </div>
                <div class="quotas-item">
                    <span>Mensajes enviados:</span>
                    <span class="ml-2">{{quota?.sent_messages}}</span>
                </div>
                <div class="quotas-item">
                    <span>Limite de respuestas:</span>
                    <span class="ml-2">{{quota?.limit_responses}}</span>
                </div>
                <div class="quotas-item">
                    <span>Respuestas enviadas:</span>
                    <span class="ml-2">{{quota?.sent_responses}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedCompany">
    <div class="company-content">
        <div class="card">
            <div class="card-header">
                <h5>Credenciales</h5>
            </div>
            <div class="card-body">
                <div class="separation-line"></div>
                <div class="credentials-row">
                    <span class="credentials-title">General</span>
                    <span class="credentials-content"></span>
                    <span class="credentials-action"><img src="assets/icons/refresh-icon.png" alt="refresh-icon"
                            class="refresh-icon"></span>
                </div>
                <div class="separation-line"></div>
            </div>
        </div>
    </div>
    
</div>
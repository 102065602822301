<app-sidebar *ngIf="backofficeUrl && backofficeActivate" [isSidebarOpen]="isSidebarOpen"
  (closeSidebar)="closeSidebar()"></app-sidebar>

<nav class="navbar navbar-expand-lg navbar-dark bg-light">

  <img *ngIf="backofficeUrl" src="assets/icons/sidebar-icon.png" alt="sidebar-icon" class="sidebar-icon"
    (click)="openSidebar()">

  <span class="navbar-brand" [routerLink]="'/home'">
    <img src="assets/img/logos/navbar.png" alt="">
  </span>

  <button class="navbar-toggler" type="button" aria-controls="navbarContent" [attr.aria-expanded]="!collapsed"
    aria-label="Toggle navigation" (click)="collapsed = !collapsed">
    <span class="navbar-toggler-icon">
    </span>
  </button>

  <div class="collapse navbar-collapse" [class.collapse]="collapsed" id="navbarContent">
    <!-- Left -->
    <ul *ngIf="!isLoggedIn && urlActual == '/home'" class="navbar-nav mr-auto">
      <li class="nav-item" ngbDropdown>
        <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown1" role="button">
          Trabaja con nosotros
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
          <a ngbDropdownItem (click)="$event.preventDefault()" [routerLink]="'/seller'">Distribuidores</a>
          <a ngbDropdownItem (click)="$event.preventDefault()" [routerLink]="'/agency'">Agencias</a>


        </div>
      </li>
      <!-- <li class="nav-item" ngbDropdown>
        <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown1" role="button">
          Quienes somos
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
          <a ngbDropdownItem (click)="$event.preventDefault()" [routerLink]="'/about-us'">Nosotros</a>
          
          <a ngbDropdownItem (click)="$event.preventDefault()">Compromiso con la ciudadania</a>
         
          
          <a ngbDropdownItem (click)="$event.preventDefault()">Galería</a>
         
          <a ngbDropdownItem (click)="$event.preventDefault()" [routerLink]="'/contact'">Contactos</a>
        </div>
      </li>
      <li class="nav-item" ngbDropdown>
        <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown1" role="button">
          Cómo funciona
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
          <a ngbDropdownItem (click)="$event.preventDefault()" [routerLink]="'/guide'">Guía para principiantes</a>
          <a ngbDropdownItem (click)="$event.preventDefault()" [routerLink]="'/services'">Servicios</a>
          
          <a ngbDropdownItem (click)="$event.preventDefault()">Marketing para tu negocio</a>
         
          
          <a ngbDropdownItem (click)="$event.preventDefault()" [routerLink]="'/jobs'">Trabaja con nosotros</a>
         
        </div>
      </li>
      <li class="nav-item" ngbDropdown>
        <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown1" role="button">
          Precios
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
          <a ngbDropdownItem (click)="$event.preventDefault()" [routerLink]="'/plans'">Planes</a>
          
          <a ngbDropdownItem (click)="$event.preventDefault()">Otros costos</a>
         
        </div>
      </li>
      
      <li class="nav-item" ngbDropdown>
        <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown1" role="button">
          Ayuda
        </a>

        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
          <a ngbDropdownItem (click)="$event.preventDefault()" [routerLink]="'/help'">Servicio al cliente</a>
          
          <a ngbDropdownItem (click)="$event.preventDefault()" [routerLink]="'/help'">Preguntas frecuentes</a>
          
        </div>
      </li>  -->

    </ul>
    <!-- Right -->
    <ul class="navbar-nav ml-auto" style="align-items: center;">
      <li *ngIf="isLoggedIn && isBackoffice() && suscription" ngbDropdown display="dynamic" placement="bottom-end">
        <div class="plan-text">{{suscription}}</div>
      </li>
      <li *ngIf="isLoggedIn && isBackoffice()" class="nav-item" ngbDropdown display="dynamic" placement="bottom-end">
        <div class="nav-link user-dropdown" tabindex="0" ngbDropdownToggle id="navbarDropdown3" role="button">
          <img src="assets/icons/user-avatar-icon.png" alt="user-avatar-icon" class="avatar">
          <span class="username">{{ username }}</span>
        </div>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
          <a ngbDropdownItem (click)="redirect('/backoffice/profile')">Perfil</a>
          <a ngbDropdownItem (click)="redirect('/backoffice/settings')">Configuraciones</a>
        </div>
      </li>
      <li *ngIf="!isLoggedIn" class="nav-item login">
        <a routerLink="/login" class="nav-link" (click)="isMenuCollapsed = true" id="sign-up">Ingresar</a>
      </li>
      <li *ngIf="isLoggedIn && isBackoffice()" class="nav-item login" (click)="logout()">
        <a class="nav-link" id="sign-up" (click)="isMenuCollapsed = true">Cerrar sesión</a>
      </li>
      <li *ngIf="isLoggedIn && !isBackoffice()" class="nav-item login">
        <a routerLink="/backoffice" class="nav-link" id="sign-up" (click)="isMenuCollapsed = true">Ir a
          administración</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="https://www.ushops.tech/" (click)="isMenuCollapsed = true" id="sign-up">Conoce más de
          uShops<img src="assets/img/icons/carritoicon.svg"></a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="'/signup-email'" (click)="isMenuCollapsed = true" id="login">Iniciar sesión</a>
      </li> -->
    </ul>
  </div>
</nav>
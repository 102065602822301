import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DragulaService } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { ClientsService } from 'src/app/services/clients.service';
import { KanbanService } from 'src/app/services/kanban.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-kanban-ventas',
  templateUrl: './kanban-ventas.component.html',
  styleUrls: ['./kanban-ventas.component.scss']
})
export class KanbanVentasComponent implements OnInit, OnDestroy {

  container_name = 'dragula_states'
  activeTab = 'data-tab';
  subs = new Subscription();
  private isDragging = false;
  private startX: number;
  private scrollX: number;
  private scrollSpeed = 10;
  dropSubscription: Subscription;
  companyData: any;

  clients: any = [];
  selectedClient: any;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      const offsetX = event.clientX - this.startX;
      const container = document.querySelector('.kanban-container');
      if (container) {
        const containerWidth = container.clientWidth;
        const scrollableThreshold = containerWidth * 0.2; // % del espacio final de cada lado

        if (event.clientX < scrollableThreshold) {
          container.scrollLeft -= this.scrollSpeed;
        } else if (event.clientX > containerWidth - scrollableThreshold) {
          container.scrollLeft += this.scrollSpeed;
        }
      }
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.stopDragging();
  }

  selectedFilterTemp: any = 'all';
  selectedFilterImp: any = 'all';
  selectedFilter3: any = 'all';

  filterTempOptions: any = [
    { value: 'all', label: 'Todos' },
    { value: 'lastWeek', label: 'Última semana' },
    { value: 'last3Days', label: 'Últimos 3 días' },
    { value: 'lastMonth', label: 'Último mes' }
  ];

  filterImpOptions: any = [
    { value: 'all', label: 'Todos' },
    { value: 'favorites', label: 'Favoritos' }
  ];

  filter3Options: any = [
    { value: 'all', label: 'Todos' },
    { value: 'option5', label: 'Opción 5' },
    { value: 'option6', label: 'Opción 6' },
    { value: 'option7', label: 'Opción 7' },
    { value: 'option8', label: 'Opción 8' },
  ];

  kanbanData: any = [];

  constructor(
    private dragulaService: DragulaService,
    private router: Router,
    private clientsService: ClientsService,
    private kanbanService: KanbanService,
    private toastr: ToastrService) {

    if (!this.dragulaService.find(this.container_name)) {
      this.dragulaService.setOptions(this.container_name, {
        moves: function (el: any, container: any, handle: any): any {
          if (el.classList.contains('not-draggable')) {
            return false;
          }

          return true;
        }
      });
    }
  }

  ngOnInit(): void {

    this.getCompanyData().then(
      result => {
        this.getClients();
      }, error => {
        console.log('error: ', error);
      }
    )

    this.createDropSubscription();
    this.setActiveTab(this.activeTab);
  }

  ngOnDestroy() {
    this.dropSubscription.unsubscribe();
  }

  setEditMode(item: any) {
    if (item.editable) {
      item.editable = !item.editable;
    } else {
      item.editable = true;
    }
  }

  redirectClient(client: any) {
    const queryParams = { id: client._id };
    this.router.navigate(['/backoffice/client-info'], { queryParams: queryParams });
  }

  setClientValues(client: any) {
    // console.log('client', client);
    this.selectedClient = client;
  }

  //Funciones para controlar el desplazamiento del scroll horizontal al hacer drag de un elemento
  startDragging(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.clientX;
    const container = document.querySelector('.kanban-container');
    if (container) {
      this.scrollX = container.scrollLeft;
    }
  }

  stopDragging() {
    this.isDragging = false;
  }

  scrollContainer(offsetX: number) {
    const container = document.querySelector('.kanban-container');
    if (container) {
      let minDistance = 200;
      let speed = 10;
      if (offsetX >= minDistance) container.scrollLeft = this.scrollX + offsetX;
      if (offsetX <= -minDistance) container.scrollLeft = this.scrollX + offsetX;
    }
  }

  createDropSubscription() {
    this.dropSubscription = this.dragulaService.drop.subscribe((value) => {
      // console.log('value drop: ', value);

      // console.log('client id: ', value[1].id);
      // console.log('id contenedor dropeado: ', value[2].id);

      let body: any = {
        _id: value[1].id ? value[1].id : undefined,
        proceso: value[2].id ? value[2].id : undefined
      };

      if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
        this.clientsService.actualizarCliente(body).subscribe({
          next: (result: any) => {
            // console.log('result', result);
            this.toastr.success('Se ha realizado el cambio de proceso correctamente.', 'Operación exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
          },
          error: (error) => {
            console.log('Error al cambiar proceso de cliente: ', error);
            this.toastr.error('Ha ocurrido un error al cambiar el cliente de proceso.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          },
          // complete: () => console.info('complete')
        });
      } else {
        this.clientsService.actualizarClienteSentinel(body).subscribe({
          next: (result: any) => {
            // console.log('result', result);
            this.toastr.success('Se ha realizado el cambio de proceso correctamente.', 'Operación exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
          },
          error: (error) => {
            console.log('Error al cambiar proceso de cliente: ', error);
            this.toastr.error('Ha ocurrido un error al cambiar el cliente de proceso.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          },
          // complete: () => console.info('complete')
        });
      }

    });
  }

  setActiveTab(id: any) {
    let selectedElement = document.getElementById(id);
    let elements = document.getElementsByClassName('tab');

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('active');
    }

    selectedElement.classList.add('active');
    this.activeTab = id;
  }

  addKanbanBlock() {
    this.kanbanData.datosKanban.push({
      "id": "",
      "title": "",
      "clients": [],
      "editable": true
    })
  }

  saveKanbanData(item) {

    console.log(item)

    if (item.title === '') {
//      if (item.title === '' || item.id === '') {
        this.toastr.error('No se puede guardar un bloque sin titulo.', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    let body: any = {};

    this.kanbanService.obtenerDatosKanban(body).subscribe({
      next: (result: any) => {
        // console.log('result kanban', result);
        const index = result.datosKanban.findIndex(obj => obj.id === item.id);
        delete item['editable'];

        if (index !== -1) {
          result.datosKanban[index].title = item.title;
//          result.datosKanban[index].id = item.title.toLowerCase().replace(/\s+/g, '_');
        } else {
          if(item.id === ''){
            item.id = item.title.toLowerCase().replace(/\s+/g, '_');

          }
          result.datosKanban.push(item);
        }

        body = {
          _id: this.kanbanData._id,
          datosKanban: result.datosKanban
        }
        console.log(body)

        this.kanbanService.actualizarDatosKanban(body).subscribe({
          next: (result2: any) => {
            item.editable = false;
            this.toastr.success('Se ha actualizado la información del kanban.', 'Operación exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
          },
          error: (error) => {
            console.log('Error al actualizar datos de kanban: ', error);
            this.toastr.error('Ha ocurrido un error al actualizar los datos.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          },
          // complete: () => console.info('complete')
        });
      },
      error: (error) => {
        console.log('Error al obtener datos de kanban: ', error);
        this.toastr.error('Ha ocurrido un error al actualizar los datos.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      },
      // complete: () => console.info('complete')
    });
  }

  deleteKanbanItem(item) {
    Swal.fire({
      title: '¿Está seguro de eliminar el elemento seleccionado?',
      text: 'Los clientes de este elemento se transferiran a LEADS',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        // Swal.fire('', 'Cancelado con éxito', 'error');
      } else {
        let body: any = {};

        this.kanbanService.obtenerDatosKanban(body).subscribe({
          next: (result: any) => {
            // console.log('result kanban', result);
            const index = result.datosKanban.findIndex(obj => obj.id === item.id);

            if (index !== -1) {
              result.datosKanban.splice(index, 1);

              body = {
                _id: this.kanbanData._id,
                datosKanban: result.datosKanban
              }

              this.kanbanService.actualizarDatosKanban(body).subscribe({
                next: (result2: any) => {

                  for (let i = 0; i < this.kanbanData.datosKanban[index].clients.length; i++) {
                    this.kanbanData.datosKanban[index].clients[i].proceso = null;
                    this.kanbanData.datosKanban[0].clients.push(this.kanbanData.datosKanban[index].clients[i]);
                  }

                  this.kanbanData.datosKanban.splice(index, 1);
                  this.toastr.success('Se ha eliminado el elemento seleccionado.', 'Operación exitosa', {
                    timeOut: 3000,
                    positionClass: 'toast-bottom-right',
                  });
                },
                error: (error) => {
                  console.log('Error al obtener datos de kanban: ', error);
                  this.toastr.error('Ha ocurrido un error al eliminar los datos.', 'Error', {
                    timeOut: 3000,
                    positionClass: 'toast-bottom-right',
                  });
                  // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
                },
                // complete: () => console.info('complete')
              });
            } else {
              this.kanbanData.datosKanban = this.kanbanData.datosKanban.filter(x => x.id !== item.id && x.id !== '');
            }
          },
          error: (error) => {
            console.log('Error al obtener datos de kanban: ', error);
            this.toastr.error('Ha ocurrido un error al actualizar los datos.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          },
          // complete: () => console.info('complete')
        });
      }
    });
  }

  getKanbanData() {

    let body: any = {};

    console.log('body kanban: ', body);

    this.kanbanService.obtenerDatosKanban(body).subscribe({
      next: (result: any) => {
        console.log('result kanban', result);
        if (result && result.datosKanban) {
          this.kanbanData = result;
          this.sortClients();
        } else {
          this.kanbanService.crearDatosKanban({}).subscribe({
            next: (result: any) => {
              console.log('kanban creado', result);
              this.getKanbanData();
            },
            error: (error) => {
              console.log('Error al obtener datos de kanban: ', error);
              this.toastr.error('Ha ocurrido un error al obtener la información.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
            },
            // complete: () => console.info('complete')
          });
        }
      },
      error: (error) => {
        console.log('Error al cambiar proceso de cliente: ', error);
        this.toastr.error('Ha ocurrido un error al cambiar el cliente de proceso.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      },
      // complete: () => console.info('complete')
    });

    // this.kanbanData.datosKanban = [
    //   {
    //     id: 'leads', //el id que se coloque aqui se le asignara al cliente en la base como proceso
    //     title: 'LEADS',
    //     clients: []
    //   },
    //   {
    //     id: 'seguimiento',
    //     title: 'SEGUIMIENTO',
    //     clients: []
    //   },
    //   {
    //     id: 'oportunidad',
    //     title: 'OPORTUNIDAD',
    //     clients: []
    //   },
    //   {
    //     id: 'conversion',
    //     title: 'CONVERSION',
    //     clients: []
    //   },
    //   {
    //     id: 'fidelizacion',
    //     title: 'FIDELIZACION',
    //     clients: []
    //   }
    // ]
  }

  getClients() {

    let body: any = {};

    body.mode = 'all'

    if (this.selectedFilterTemp) body.filterTemp = this.selectedFilterTemp;
    if (this.selectedFilterImp) body.filterImp = this.selectedFilterImp;

    console.log('body: ', body);

    if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
      this.clientsService.obtenerListaClientes(body).subscribe({
        next: (result: any) => {
          this.clients = result;
          this.getKanbanData();
        },
        error: (error) => {
          console.log('Error al cargar clientes: ', error);
          this.toastr.error('Ha ocurrido un error al cargar la lista de clientes.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      })
    } else {
      this.clientsService.obtenerClientesSentinel(body).subscribe({
        next: (result: any) => {
          this.clients = result;
          this.getKanbanData();
        },
        error: (error) => {
          console.log('Error al cargar clientes: ', error);
          this.toastr.error('Ha ocurrido un error al cargar la lista de clientes.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      })
    }
  }

  sortClients() {
    let kanbanIds: string[] = [];

    for (let i = 0; i < this.kanbanData.datosKanban.length; i++) {
      kanbanIds.push(this.kanbanData.datosKanban[i].id);
    }

    for (let i = 0; i < this.clients.length; i++) {
      this.clients[i].draggable = true;

      if (!kanbanIds.includes(this.clients[i].proceso)) {
        this.clients[i].proceso = null;
      }

      if (this.clients[i].proceso) {
        for (let j = 0; j < this.kanbanData.datosKanban.length; j++) {
          if (this.clients[i].proceso === this.kanbanData.datosKanban[j].id) {
            this.kanbanData.datosKanban[j].clients.push(this.clients[i]);
          }
        }
      } else {
        this.kanbanData.datosKanban[0].clients.push(this.clients[i]);
      }
    }
  }

  getCompanyData() {
    let body: any = {};

    return new Promise((resolve, reject) => {
      this.clientsService.obtenerDatosBot(body).subscribe({
        next: (result: any) => {
          this.companyData = result;
          console.log('this.companyData', this.companyData);
          return resolve(result);
        },
        error: (error) => {
          console.log('Error al actualizar cliente: ', error);
          this.toastr.error('Ha ocurrido un error al obtener informacion de sentinel.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          return reject(error);
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      });
    });
  }

  updateClient() {

    if (!this.selectedClient) return;

    let body: any = {
      _id: this.selectedClient._id,
      // nombre: this.selectedClient.nombre,
      notaImportante: this.selectedClient.notaImportante || ""
    };

    if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
      this.clientsService.actualizarCliente(body).subscribe({
        next: (result: any) => {
          // console.log('result', result);
          this.getClients();
          this.toastr.success('Se ha actualizado la información del cliente.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        },
        error: (error) => {
          console.log('Error al actualizar cliente: ', error);
          this.toastr.error('Ha ocurrido un error y no se ha logrado guardar la información del cliente.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      });
    } else {
      this.clientsService.actualizarClienteSentinel(body).subscribe({
        next: (result: any) => {
          // console.log('result', result);
          this.getClients();
          this.toastr.success('Se ha actualizado la información del cliente.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        },
        error: (error) => {
          console.log('Error al actualizar cliente: ', error);
          this.toastr.error('Ha ocurrido un error y no se ha logrado guardar la información del cliente.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      });
    }


  }

  setFavorite(client: any) {
    let body: any = {
      _id: client._id,
      favorito: client.favorito ? false : true
    };

    if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
      this.clientsService.actualizarCliente(body).subscribe({
        next: (result: any) => {
          // console.log('result', result);
          this.getClients();
          this.toastr.success('Se ha actualizado el cliente.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        },
        error: (error) => {
          console.log('Error al cambiar proceso de cliente: ', error);
          this.toastr.error('Ha ocurrido un error al cambiar el estado de favorito del cliente .', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      });
    } else {
      this.clientsService.actualizarClienteSentinel(body).subscribe({
        next: (result: any) => {
          // console.log('result', result);
          this.getClients();
          this.toastr.success('Se ha actualizado la información del cliente.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        },
        error: (error) => {
          console.log('Error al actualizar cliente: ', error);
          this.toastr.error('Ha ocurrido un error y no se ha logrado guardar la información del cliente.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      });
    }
  }
}

<div class="content">
    <div class="filters-container">
        <button *ngIf="clients && clients.length > 0" class="btn btn-primary" (click)="downloadExcel()">Descargar excel</button>
        <div class="search-bar">
            <input [(ngModel)]="searchInput" type="text" class="form-control" placeholder="Buscar..." (keyup.enter)="getClients()">
            <img src="assets/icons/search-icon.png" alt="search-icon" class="custom-icon" ngbTooltip="Buscar"
                placement="top" (click)="getClients()">
        </div>
    </div>
    <div class="table-container">
        <div class="mat-table users-table">
            <div class="mat-header-row">
                <div class="mat-header-cell flex-center">Nombre</div>
                <div class="mat-header-cell flex-center">Teléfono</div>
                <div class="mat-header-cell flex-center">Email</div>
                <div class="mat-header-cell flex-center">Acción</div>
            </div>
            <div *ngFor="let item of getPaginatedItems()" class="mat-row">
                <div class="mat-cell flex-center">{{item.nombre}}</div>
                <div class="mat-cell flex-center">{{item.telefono}}</div>
                <div class="mat-cell flex-center">{{item.email}}</div>
                <div class="mat-cell flex-center">
                    <div class="p-1 actions flex-center">
                        <!-- <button mat-mini-fab color="primary" matTooltip="View">
                            <mat-icon>remove_red_eye</mat-icon>
                        </button> -->
                        <div class="btn-invoice" (click)="redirectClient(item)" ngbTooltip="Ir a edición"
                            placement="top">
                            <img src="assets/icons/send-icon-black.png" alt="send-icon-black" class="custom-icon">
                        </div>
                        <div class="btn-invoice" (click)="openClientModal(item)" ngbTooltip="Mas información"
                            placement="top">
                            <img src="assets/icons/eye-icon-black.png" alt="eye-icon-black" class="custom-icon">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="clients && clients.length > 0" class="row paginator-container">
            <ul class="pagination">
                <li class="page-item" [class.disabled]="currentPage === 1">
                    <a class="page-link" (click)="setPage(1)">Primera</a>
                </li>
                <li class="page-item" [class.disabled]="currentPage === 1">
                    <a class="page-link" (click)="setPage(currentPage - 1)">Anterior</a>
                </li>
                <li class="page-item" *ngFor="let page of visiblePages" [class.active]="currentPage === page">
                    <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                </li>
                <li class="page-item" [class.disabled]="currentPage === totalPages?.length">
                    <a class="page-link" (click)="setPage(currentPage + 1)">Siguiente</a>
                </li>
                <li class="page-item" [class.disabled]="currentPage === totalPages?.length">
                    <a class="page-link" (click)="setPage(totalPages.length)">Última</a>
                </li>
            </ul>
        </div>
    </div>
</div>



<app-modal-animation [modalID]="'client-modal'" [modalClass]="'md-effect-1'">
    <h3>Datos de cliente</h3>
    <div>
        <div class="row client-info-container" style="margin: 0 0.5em;">
            <ng-container *ngIf="selectedClient">
                <div class="client-item">
                    <label for="nombre"><strong>Nombre</strong></label>
                    <input id="nombre" type="text" [(ngModel)]="selectedClient.nombre" disabled="true">
                </div>
                <div class="client-item">
                    <label for="tipo"><strong>Tipo</strong></label>
                    <input id="tipo" type="text" [(ngModel)]="selectedClient.tipo" disabled="true">
                </div>
                <div class="client-item">
                    <label for="empresa"><strong>Empresa</strong></label>
                    <input id="empresa" type="text" [(ngModel)]="selectedClient.empresa" disabled="true">
                </div>
                <div class="client-item">
                    <label for="cargo"><strong>Cargo</strong></label>
                    <input id="cargo" type="text" [(ngModel)]="selectedClient.cargo" disabled="true">
                </div>
                <div class="client-item">
                    <label for="cedula"><strong>Cédula</strong></label>
                    <input id="cedula" type="text" [(ngModel)]="selectedClient.cedula_ruc" disabled="true">
                </div>
                <div class="client-item">
                    <label for="ciudad"><strong>Ciudad</strong></label>
                    <input id="ciudad" type="text" [(ngModel)]="selectedClient.ciudad" disabled="true">
                </div>
                <div class="client-item">
                    <label for="direccion"><strong>Dirección</strong></label>
                    <input id="direccion" type="text" [(ngModel)]="selectedClient.direccion" disabled="true">
                </div>
                <div class="client-item">
                    <label for="telefono"><strong>Teléfono</strong></label>
                    <input id="telefono" type="text" [(ngModel)]="selectedClient.telefono" disabled="true">
                </div>
                <div class="client-item">
                    <label for="email"><strong>Email</strong></label>
                    <input id="email" type="text" [(ngModel)]="selectedClient.email" disabled="true">
                </div>
                <div class="client-item">
                    <label for="descripcion"><strong>Descripción</strong></label>
                    <textarea id="descripcion" [(ngModel)]="selectedClient.descripcion" disabled="true"></textarea>
                </div>
            </ng-container>
        </div>

        <div class="flex-center" style="margin-top: 1em;">
            <button type="button" class="btn btn-danger ripple light footer-btn" style='width: 20%;'
                (click)="closeModal('client-modal')">
                Cerrar
            </button>
        </div>
    </div>
</app-modal-animation>
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, ObservedValueOf } from 'rxjs';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { io } from "socket.io-client";
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SocketService {

    socket: any;
    token: any;

    constructor(private authService: AuthService) {
        this.token = this.authService.getToken();
    }

    conection() {
        // this.socket = io('https://chat.egm-robotics.com/', {
        if (this.token && !this.socket) {
            this.socket = io(environment.socketsUrl, {
                // path: '/api/chat',
                extraHeaders: {
                    'tk': this.token
                },
                // query:'tk='+ this.JWTtoken,
                withCredentials: true,
                autoConnect: true
            });
        }
    }

    listen(eventName: string) {
        if (this.token && this.socket) {
            return new Observable((subscriber) => {
                this.socket.on(eventName, (data: any) => {
                    subscriber.next(data);
                });
            });
        }
    }

    emitir(eventName: string, data: any) {
        if (this.token && this.socket) {
            this.socket.emit(eventName, data);
        }
    }

    handleError(error: HttpErrorResponse) {
        // console.log('error desconocido: ', error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('error:', error.error);
        } else {
            if (error.status === 401) {
                console.error('no autorizado:');
                // localStorage.removeItem('jwt');
            }
        }
        return throwError(error.error);
    }

}
<div *ngIf="restLoading" class="overlay">
    <app-loading-spinner></app-loading-spinner>
</div>
<div id="containerFluid" class="container-fluid h-50">
    <div class="row justify-content-center container-chat">
        <!-- <div class="col-sm-12 col-md-4 col-xl-4 chat">
            <div class="card mb-sm-3 mb-md-0 contacts_card">
                <div class="card-header contact-header">
                    <h2>Mensajes Recientes</h2>
                    <mat-icon class="add-icon">library_add</mat-icon>
                </div>
                <div class="card-body contacts_body">
                    <div class="input-group search-container">
                        <input type="text" placeholder="Buscar" class="form-control search">
                        <div class="input-group-prepend">
                            <span class="input-group-text search_btn"><mat-icon class="search-icon">search</mat-icon></span>
                        </div>
                    </div>
                    <div class="input-group chat-buttons-container">
                        <button class="chat-button is-active" id="todos">Todos</button>
                        <button class="chat-button" id="sinLeer">Sin leer</button>
                    </div>
                    <ul class="contacts">
                        <li *ngFor="let contact of contactList" [id]="contact.user_id" class="contact-container"
                            (click)="changeContactChat(contact.user_id)">
                            <div class="d-flex bd-highlight">
                                <div class="img_cont">
                                    <img src="assets/images/avatar-blank.jpg" class="rounded-circle user_img">
                                </div>
                                <div class="contact-info">
                                    <div class="contact_name_info">
                                        <span>{{ contact.contact_name }}</span>
                                        <p class="contact_last_message"
                                            [innerHTML]="contact.contact_last_message | safeHTML"></p>
                                    </div>
                                    <div class="contact_extra_info">
                                        <p style="margin-bottom: 0px;">{{contact.contact_fecha_parsed }}</p>
                                        <div class="message-count-icon" *ngIf="contact.contact_not_read > 0">
                                            <span>{{contact.contact_not_read}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->

        <!-- <div class="col-sm-12 col-md-8 col-xl-8 chat" *ngIf="contact_selected == null"> -->
        <div class="col-sm-12 col-md-12 col-xl-12 chat" *ngIf="contact_selected == null">
            <div class="card empty-contact">
            </div>
        </div>

        <!-- <div class="col-sm-12 col-md-8 col-xl-8 chat" *ngIf="contact_selected != null"> -->
        <div class="col-sm-12 col-md-12 col-xl-12 chat" *ngIf="contact_selected != null">
            <div class="card">
                <div class="card-header msg_head chat-header">
                    <div class="d-flex bd-highlight chat-header-info">
                        <!-- Aqui colocar el link al perfil del usuario, en la etiqueta a -->
                        <a>
                            <img [src]="user_image || 'assets/icons/user-avatar-icon.png'"
                                class="rounded-circle principal_img">
                        </a>
                        <div class="contact_title_info">
                            <span>{{contact_selected.contact_name}}</span>
                            <p style="font-size: 16px; padding-top: 5px;">{{estado_conexion_contacto}}</p>
                        </div>
                    </div>
                    <button type="button" class="refresh-button" (click)="refreshUserHistory()">Refrescar</button>
                    <!-- <div class="chat-header-icons">
              <i class="material-icons">drive_folder_upload</i>
              <i><img src="assets/img/BookIcon.png" alt="Book Icon"></i>
            </div> -->
                </div>
                <div id="chatBody" class="card-body msg_card_body" #scrollBody> 
                    <div *ngIf="actualMessageCount < chatHistory.mensajesCount" class="show-more"
                        (click)="showMoreMessages()">Mostrar mas mensajes</div>
                    <div *ngFor="let chat of chatHistory">
                        <div [id]="chat.fecha" class="d-flex justify-content-start mb-4" *ngIf="chat.tipo === 'user' || chat.tipo === 'client'">
                            <div class="img_cont_msg">
                                <img [src]="user_image || 'assets/icons/user-avatar-icon.png'"
                                    class="rounded-circle user_img_msg">
                            </div>
                            <div class="msg_cotainer">
                                <div class="msg_title"><strong>{{contact_selected.contact_name}}</strong></div>
                                <div *ngIf="chat.archivo" class="file-data-container">
                                    <ng-container *ngIf="chat.tipoArchivo === 'general'">
                                        <a [href]="chat.archivo" target="_blank" [download]="chat.nombreArchivo"
                                            class="file-name-message">
                                            <img src="assets/icons/attach-icon.png" alt="attach-icon" width="30" height="30">
                                            <span><strong>{{chat.nombreArchivo}}</strong></span>
                                            <img *ngIf="!chat.archivoEvent" src="assets/icons/download-icon.png" alt="download-icon" width="30" height="30">
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="chat.tipoArchivo === 'video'">
                                        <div class="file-name-message">
                                            <video [src]="chat.archivo" controls class="chat-video"></video>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="chat.tipoArchivo === 'imagen'">
                                        <a [href]="chat.archivo" target="_blank" class="file-name-message">
                                            <img [src]="chat.archivo" alt="img" class="chat-image">
                                        </a>
                                    </ng-container>
                                </div>
                                <!-- <div *ngIf="chat.url" class="file-data-container">
                                    <ng-container *ngIf="!chat.imagen">
                                        <a [href]="chat.url" [download]="chat.nombreArchivo" target="_blank"
                                            class="file-name-message">
                                            <i class="material-icons">description</i>
                                            <span>{{chat.nombreArchivo}}</span>
                                            <i class="material-icons download-icon">download_for_offline</i>
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="chat.imagen && chat.imagen === false">
                                        <a [href]="chat.url" [download]="chat.nombreArchivo" target="_blank"
                                            class="file-name-message">
                                            <i class="material-icons">description</i>
                                            <span>{{chat.nombreArchivo}}</span>
                                            <i class="material-icons download-icon">download_for_offline</i>
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="chat.imagen && chat.imagen === true">
                                        <a [href]="chat.url" target="_blank" class="file-name-message">
                                            <img [src]="chat.url" alt="img" width="300" height="300">
                                        </a>
                                    </ng-container>
                                </div> -->
                                <!-- <div style="white-space:pre-wrap;" [innerHTML]="chat.mensaje | safeHTML"></div> -->
                                <div style="white-space:pre-wrap; text-align: justify;" [innerHTML]="chat.mensaje">
                                </div>
                                <span class="msg_time">{{chat.fecha | date: 'HH:mm:ss - dd/MM/yyyy'}}</span>
                            </div>
                        </div>
                        <div [id]="chat.fecha" class="d-flex justify-content-end mb-4" *ngIf="chat.tipo !== 'user' && chat.tipo !==  'client'">
                            <div class="msg_cotainer_send">
                                <!-- <div class="msg_title_send">
                                    <strong>{{user_name}}</strong>
                                    <img *ngIf="chat.estado == 0" src="assets/img/SingleCheckIcon.png"
                                        alt="checkIcon" title="Enviado">
                                    <img *ngIf="chat.estado == 1" src="assets/img/DoubleCheckIcon.png"
                                        alt="checkIcon" title="Recibido">
                                    <img *ngIf="chat.estado == 2" src="assets/img/TripleCheckIcon.png"
                                        alt="checkIcon" title="Visto">
                                </div> -->
                                <div *ngIf="chat.archivo" class="file-data-container">
                                    <ng-container *ngIf="chat.tipoArchivo === 'general'">
                                        <a [href]="chat.archivo" target="_blank" [download]="chat.nombreArchivo"
                                            class="file-name-message-send">
                                            <img src="assets/icons/attach-icon.png" alt="attach-icon" width="30" height="30">
                                            <span><strong>{{chat.nombreArchivo}}</strong></span>
                                            <img *ngIf="!chat.archivoEvent" src="assets/icons/download-icon.png" alt="download-icon" width="30" height="30">
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="chat.tipoArchivo === 'video'">
                                        <div class="file-name-message-send">
                                            <video [src]="chat.archivo" controls class="chat-video"></video>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="chat.tipoArchivo === 'imagen'">
                                        <a [href]="chat.archivo" target="_blank" class="file-name-message-send">
                                            <img [src]="chat.archivo" alt="img" class="chat-image">
                                        </a>
                                    </ng-container>
                                </div>
                                <!-- <div *ngIf="chat.url" class="file-data-container">
                                    <ng-container *ngIf="!chat.imagen">
                                        <a [href]="chat.url" [download]="chat.nombreArchivo" target="_blank"
                                            class="file-name-message-send">
                                            <i class="material-icons">description</i>
                                            <span>{{chat.nombreArchivo}}</span>
                                            <i class="material-icons download-icon">download_for_offline</i>
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="chat.imagen && chat.imagen === false">
                                        <a [href]="chat.url" [download]="chat.nombreArchivo" target="_blank"
                                            class="file-name-message-send">
                                            <i class="material-icons">description</i>
                                            <span>{{chat.nombreArchivo}}</span>
                                            <i class="material-icons download-icon">download_for_offline</i>
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="chat.imagen && chat.imagen === true">
                                        <a [href]="chat.url" class="file-name-message-send" target="_blank">
                                            <img [src]="chat.url" alt="img" width="300" height="300">
                                        </a>
                                    </ng-container>
                                </div> -->
                                <!-- <div style="white-space:pre-wrap;" [innerHTML]="chat.mensaje | safeHTML"></div> -->
                                <div style="white-space:pre-wrap; text-align: justify;" [innerHTML]="chat.mensaje">
                                </div>
                                <span class="msg_time_send">{{chat.fecha | date: 'HH:mm:ss - dd/MM/yyyy'}}</span>
                            </div>
                            <div class="img_cont_msg">
                                <img src="assets/icons/chatbot-icon.png" class="rounded-circle user_img_msg">
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedFileOptions" class="file_container">
                    <div class="file-container">
                        <img src="assets/icons/file-icon.png" alt="file-icon" width="20" height="20">
                        <span class="file-name">{{selectedFileOptions.name}}</span>
                    </div>
                    <div class="close-container close" aria-label="Close" (click)="clearFile()">
                        <img src="assets/icons/close-icon.png" alt="close-icon" width="25">
                    </div>
                </div>
                <div class="card-footer">
                    <div class="input-group">
                        <textarea name="message" class="form-control type_msg" placeholder="Escribir nuevo mensaje"
                            [(ngModel)]="messageContent" (keyup.enter)="sendMessage()"></textarea>
                        <!-- <div class="input-group-append">
                            <span class="input-group-text emoticon_btn"><i class="material-icons"><img src="assets//img//EmoticonIcon.png" alt="Emoticon Icon"></i></span>
                        </div> -->
                        <!-- <div class="input-group-append image-upload">
                            <label for="file-input" class="attach_btn">
                                <img src="assets/icons/attach-icon.png"/>
                            </label>
                            <input id="file-input" type="file" style="display: none;"
                                (change)="onFileSelected($event)" />
                        </div> -->
                        <div class="input-group-append">
                            <span class="input-group-text send_btn" (click)="sendMessage()"><img
                                    src="assets/icons/send-icon.png" alt="send-icon" width="20" height="20"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
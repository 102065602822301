import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { GuideComponent } from './guide/guide.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';
import { JobsComponent } from './jobs/jobs.component';
import { LoginComponent } from './login/login.component';
import { PlansComponent } from './plans/plans.component';
import { SignupCodeComponent } from './signup-code/signup-code.component';
import { SignupEmailComponent } from './signup-email/signup-email.component';
import { SignupPasswordComponent } from './signup-password/signup-password.component';
import { UshopsServicesComponent } from './ushops-services/ushops-services.component';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { AgencyComponent } from './agency/agency.component';
import { SellerComponent } from './seller/seller.component';
import { ScheduleAppointmentComponent } from './backoffice/schedule-appointment/schedule-appointment.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'signup-email',
    component: SignupEmailComponent,
  },
  {

    path: 'signup-code',
    component: SignupCodeComponent
  },
  {
    // path: 'signup-password',
    path: 'basic-validate',
    component: SignupPasswordComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'guide',
    component: GuideComponent
  },
  {
    path: 'jobs',
    component: JobsComponent
  },
  {
    path: 'services',
    component: UshopsServicesComponent
  }, 
  {
    path: 'agency',
    component: AgencyComponent
  },
  {
    path: 'seller',
    component: SellerComponent
  },
  
  {
    path: 'plans',
    component: PlansComponent
  },

  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: 'agendamiento-cita',
    component: ScheduleAppointmentComponent,

  },
  {
    path: 'backoffice',
    component: BackofficeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule)
      }
    ]
  },
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

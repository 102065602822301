import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from '../navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { ModalAnimationComponent } from './modal-animation/modal-animation.component';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { NgbDropdownModule, NgbModule, NgbNavModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DragulaModule } from 'ng2-dragula';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { FooterComponent } from '../footer/footer.component';
import { WidgetsModule } from '../widgets/widgets.module';

defineLocale('es', esLocale);
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    SidebarComponent,
    NavbarComponent,
    ModalAnimationComponent,
    LoadingSpinnerComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    NgxQrcodeStylingModule,
    NgbPaginationModule,
    NgbDropdownModule,
    NgbModule,
    NgbCollapseModule,
    NgbNavModule,
    DropzoneModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgCircleProgressModule.forRoot()
  ],
  exports: [
    FooterComponent,
    SidebarComponent,
    NavbarComponent,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    ModalAnimationComponent,
    NgxQrcodeStylingModule,
    NgCircleProgressModule,
    LoadingSpinnerComponent,
    NgbPaginationModule,
    NgbDropdownModule,
    NgbCollapseModule,
    DropzoneModule,
    NgbNavModule,
    DragulaModule,
    BsDatepickerModule,
    TimepickerModule,
    WidgetsModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es' }]
})
export class SharedModule { }

import { Component, OnInit } from '@angular/core';
import { DataTransferService } from '../services/data-transfer.service';

@Component({
  selector: 'app-ushops-services',
  templateUrl: './ushops-services.component.html',
  styleUrls: ['./ushops-services.component.scss']
})
export class UshopsServicesComponent implements OnInit {

  links: Array<Object>;
  services: Array<Object> = [
    {
      image: 'assets/img/services/service1.png',
      name: 'Delivery',
      description: 'Con uShops tienes la posibilidad de ofrecer a tus clientes un servicio de entrega a domicilio.'
    },
    {
      image: 'assets/img/services/service2.png',
      name: 'Pago con tarjetas',
      description: 'Próximamente, ofrece a tus clientes la opción de pago con tarjeta de crédito o débito, de una forma fácil segura y rápida.'
    },
    {
      image: 'assets/img/services/service3.png',
      name: 'Marketing para tu negocio',
      description: 'Permite que tus productos y ofertas aparezcan en nuestras secciones de anuncios en la plataforma.'
    }
  ];

  constructor(private data: DataTransferService) { }

  ngOnInit(): void {
    this.data.currentData.subscribe(message => this.links = message);
  }

}

import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class ClientsService {

    ipPublica = environment.urlApi;
    baseXpertUrl = this.ipPublica + '/ushops/salesxpert'
    // baseChatbotUrl = this.ipPublica + '/ushops/chatbot'
    baseChatbotUrl = this.ipPublica + '/ushops/xpertAssistant'
    baseBotWhatsappUrl = this.ipPublica + '/whatsapp'
    
    constructor(private http: HttpClient, private auth: AuthService) {
    }

    obtenerDatosBot(parameters): Observable<any> {
        const options = {
            //token quemado de IMS, se deberia cambiar de algun modo
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotUrl}/obtenerDatosBot`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    obtenerClientesSentinel(parameters): Observable<any> {
        const options = {
            //token quemado de IMS, se deberia cambiar de algun modo
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseXpertUrl}/obtenerClientesSentinel`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    obtenerUnClienteSentinel(parameters): Observable<any> {
        const options = {
            //token quemado de IMS, se deberia cambiar de algun modo
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseXpertUrl}/obtenerUnClienteSentinel`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    actualizarClienteSentinel(parameters): Observable<any> {
        const options = {
            //token quemado de IMS, se deberia cambiar de algun modo
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseXpertUrl}/actualizarClienteSentinel`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    obtenerListaClientes(parameters): Observable<any> {
        const options = {
            //token quemado de IMS, se deberia cambiar de algun modo
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotUrl}/obtenerClientesIMS`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    obtenerUnCliente(parameters): Observable<any> {
        const options = {
            //token quemado de IMS, se deberia cambiar de algun modo
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotUrl}/obtenerUnClienteIMS`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    actualizarCliente(parameters): Observable<any> {
        const options = {
            //token quemado de IMS, se deberia cambiar de algun modo
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotUrl}/actualizarClienteIMS`, parameters, options)
            .pipe(catchError(this.handleError));
    }





    handleError(error: HttpErrorResponse) {
        console.log('error desconocido: ', error);
        if (error.error instanceof ErrorEvent) {
        } else {
            if (error.status === 401) {
                console.error('no autorizado:');
                localStorage.removeItem('jwt');
            }
        }
        return throwError(new Error(error.statusText));
    }

}
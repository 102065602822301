<div class="whatsapp-confing-container">
    <button class="btn btn-primary" (click)="openCampaignModal(true)">Nueva campaña</button>
</div>

<div class="card templates-container">
    <div class="card-block">
        <div class="row">
            <div class="col-sm-12 col-lg-4 col-md-6" *ngFor="let campaign of getPaginatedItems()">
                <div class="card user-card">
                    <div class="card-header card_menu_title">
                        <a class="template-name">
                            <h5 (click)="openMessagesModal(campaign)">{{campaign.nombre}}</h5>
                        </a>
                        <div class="icons-container">

                            <img src="assets/icons/time-icon.png" alt="time-icon" class="time-icon"
                                ngbTooltip="Programar horario" placement="top" (click)="openScheduleModal(campaign)">

                            <div class="eye-icon" (click)="openExcelModal(campaign)" ngbTooltip="Enviar a contactos"
                                placement="top">
                                <!-- <mat-icon matListIcon class="mat-icon-sm">remove_red_eye</mat-icon> -->
                                <img *ngIf="campaign.enviado !== 'completo' && campaign.enviado !== 'activado'"
                                    src="assets/icons/eye-icon-black.png" alt="eye-icon-black" width="18" height="18">
                                <img *ngIf="campaign.enviado === 'completo'" src="assets/icons/eye-icon-orange.png"
                                    alt="eye-icon-orange" width="18" height="18">
                                <img *ngIf="campaign.enviado === 'activado'" src="assets/icons/play-icon.png"
                                    alt="play-icon" width="18" height="18">
                            </div>

                            <div ngbDropdown class="d-inline-block dropdown-secondary dropdown">
                                <button type="button"
                                    class="btn-default btn-mini dropdown-toggle b-none txt-muted ripple"
                                    style="background-color: #FFFF;" id="dropdownBasic1" ngbDropdownToggle>
                                    <img src="assets/icons/show-more-icon.png" alt="show-more-icon"
                                        class="show-more-icon" class="show-more-icon">
                                </button>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                    <button ngbDropdownItem (click)="openCampaignModal(false, campaign)">
                                        Editar Campaña
                                    </button>
                                    <button ngbDropdownItem (click)="deleteCampaign(campaign)">
                                        Eliminar Campaña
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-block template_card_block">
                        <div class="template-image-container" (click)="openMessagesModal(campaign)">
                            <figure *ngIf="campaign?.imagen; else blockDefecto" class="html_prev">
                                <img [src]='campaign.imagen' class="img-fluid img_prev">
                            </figure>
                            <ng-template #blockDefecto>
                                <figure class="html_prev">
                                    <img src="assets/img/image-not-found.png" class="img-fluid img_prev">
                                </figure>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="campaigns && campaigns.length > 0" class="row paginator-container">
                <ul class="pagination">
                    <li class="page-item" [class.disabled]="currentPage === 1">
                        <a class="page-link" (click)="setPage(1)">Primera</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === 1">
                        <a class="page-link" (click)="setPage(currentPage - 1)">Anterior</a>
                    </li>
                    <li class="page-item" *ngFor="let page of visiblePages" [class.active]="currentPage === page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === totalPages?.length">
                        <a class="page-link" (click)="setPage(currentPage + 1)">Siguiente</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === totalPages?.length">
                        <a class="page-link" (click)="setPage(totalPages.length)">Última</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<app-modal-animation [modalID]="'mensajesModal'" [modalClass]="'md-effect-1'">
    <h3>Campaña: {{selectedCampaign?.nombre}}</h3>
    <div>
        <div class="row" style="margin: 0 0.5em;">
            <div class="modal-header-container">
                <div class="element-title">Mensajes: </div>
                <button placement="right" ngbTooltip="Adicionar" type="button"
                    class="btn btn-inverse ripple light btn-plus" (click)="addMessage()">+</button>
            </div>
            <div class="messages-container">
                <ng-container *ngFor="let message of messages; index as i">
                    <ng-container *ngIf="!message.isDelete">
                        <div class="message-input-container">
                            <textarea [id]="'message-input-' + i" type="text" class="edit-modal-textarea"
                                [(ngModel)]="messages[i].message"></textarea>
                            <button placement="right" ngbTooltip="Eliminar" type="button"
                                class="btn btn-inverse ripple light btn-delete" (click)="removeMessage(i)">-</button>
                        </div>
                        <div class="files-uploaders">
                            <div *ngIf="!messages[i].video && !messages[i].archivo" class="file-container">
                                <img id="message-image-{{i}}"
                                    [src]="messages[i].imagen || 'assets/icons/upload-image-icon.png'"
                                    [ngClass]="messages[i].imagen? 'filled-file': 'default-file'"
                                    alt="upload-image-icon" (click)="fileInputImage.click()">
                                <div *ngIf="messages[i].imagen" class="icon-overlay" (click)="resetFile(i)"></div>
                                <input type="file" accept="image/*" #fileInputImage (change)="onFileSelected($event, i)"
                                    style="display: none" />
                                <div class="subtitle" (click)="fileInputImage.click()">Subir Imagen</div>
                            </div>
                            <div *ngIf="!messages[i].imagen && !messages[i].archivo" class="file-container">
                                <img *ngIf="!messages[i].video" id="message-video-{{i}}"
                                    [src]="'assets/icons/upload-video-icon.png'" class="default-file"
                                    alt="upload-video-icon" (click)="fileInputVideo.click()">
                                <video *ngIf="messages[i].video" controls id="videoPlayer{{i}}"
                                    class="uploaded-video-file"></video>
                                <div *ngIf="messages[i].video" class="icon-overlay" (click)="resetFile(i)"></div>
                                <input type="file" accept="video/*" #fileInputVideo (change)="onFileSelected($event, i)"
                                    style="display: none" />
                                <div class="subtitle" (click)="fileInputVideo.click()" style="margin-top: 10px;">Subir
                                    Video</div>
                            </div>
                            <div *ngIf="!messages[i].imagen && !messages[i].video" class="file-container">
                                <img *ngIf="!messages[i].archivo" id="message-file-{{i}}"
                                    [src]="'assets/icons/upload-file-icon.png'" class="default-file"
                                    alt="upload-file-icon" (click)="fileInputDocument.click()">
                                <img *ngIf="messages[i].archivo" id="message-file-{{i}}"
                                    [src]="'assets/icons/uploaded-file-icon.png'" class="filled-file"
                                    alt="upload-file-icon" (click)="openFile(messages[i].archivo)">
                                <div *ngIf="messages[i].archivo" class="icon-overlay" (click)="resetFile(i)"></div>
                                <input type="file" accept=".xlsx, .xls, .xlsm .doc, .docx, .pdf" #fileInputDocument
                                    (change)="onFileSelected($event, i)" style="display: none" />
                                <div *ngIf="!messages[i].archivo" class="subtitle" style="margin-top: 10px;"
                                    (click)="fileInputDocument.click()">
                                    Subir Documento</div>
                                <div *ngIf="messages[i].archivo" class="subtitle" style="margin-top: 10px;"
                                    (click)="fileInputDocument.click()">
                                    {{getFileName(messages[i].archivo)}}</div>
                            </div>
                        </div>

                    </ng-container>
                </ng-container>
            </div>
        </div>


        <div class="flex-evenly" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('mensajesModal')">
                Cancelar
            </button>
            <button type="submit" (click)="sendMessageData()" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#056867; width: 20%; margin-right: 15px;'>
                Guardar
            </button>
        </div>
    </div>
    <!--<button (click)="closeMyModal($event)" aria-label="Close" class="md-close-btn"><i class="icofont icofont-ui-close"></i></button>-->
</app-modal-animation>

<app-modal-animation [modalID]="'campaignModal'" [modalClass]="'md-effect-1'">
    <h3 *ngIf="isCreate">Crear campaña</h3>
    <h3 *ngIf="!isCreate">Editar campaña</h3>
    <div>
        <div class="row" style="margin: 0 0.5em;">
            <div class="col-lg-12 template-input-container">
                <div class="element-title">Nombre: </div>
                <input *ngIf="!isCreate && selectedCampaign" [id]="'template-input'" class="template-input" type="text"
                    [(ngModel)]="selectedCampaign.nombre">
                <input *ngIf="isCreate" [id]="'template-input'" class="template-input" type="text"
                    [(ngModel)]="newCampaignName">
            </div>
            <!-- <mat-form-field appearance="legacy" class="col-lg-12 template-input-container">
                <mat-label>Estatus</mat-label>
                <mat-select name="status" [(ngModel)]="selectedStatus">
                    <mat-option *ngFor="let option of status; index as i"
                        value="{{option.value}}">{{option.label}}</mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>

        <div class="flex-evenly" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('campaignModal')">
                Cancelar
            </button>
            <button type="submit" (click)="createUpdateCampaign()" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#056867; width: 20%; margin-right: 15px;'>
                Guardar
            </button>
        </div>

    </div>
    <!--<button (click)="closeMyModal($event)" aria-label="Close" class="md-close-btn"><i class="icofont icofont-ui-close"></i></button>-->
</app-modal-animation>

<app-modal-animation [modalID]="'excelModal'" [modalClass]="'md-effect-1'">
    <h3>Contactos</h3>
    <div>
        <div class="row" style="margin: 0 0.5em;">
            <div class="excel-header">
                <div *ngIf="selectedCampaign && (selectedCampaign.enviado === undefined || selectedCampaign.enviado === 'desactivado' || selectedCampaign.enviado === 'completo')"
                    class="excel-buttons-container">
                    <div class="left-excel-buttons">
                        <button (click)="openFileSelector()" class="btn btn-primary">Importar excel</button>
                        <button (click)="importClients()" class="btn btn-primary">Importar clientes</button>
                        <input #excelInput id="excel-input" type="file" class="excel-input"
                            (change)="onExcelFileChange($event)" accept=".xlsx, .xls, .xlsm">

                        <button (click)="cleanExcelData()" class="btn btn-primary">Limpiar tabla</button>
                        <button [disabled]="selectedTray?.editCampaing === 'completed'" (click)="saveExcelData()"
                            class="btn btn-primary">Guardar</button>
                    </div>
                    <div class="right-excel-buttons">
                        <button class="btn btn-primary campaign-button"
                            (click)="refreshCampaignTray()">Actualizar</button>
                        <button [disabled]="selectedTray?.editCampaing !== 'completed'"
                            class="btn btn-primary campaign-button" (click)="sendCampaign()">Enviar
                            Campaña</button>
                    </div>
                </div>
                <div *ngIf="selectedCampaign && selectedCampaign.enviado == 'activado'" class="stop-button-container">
                    <button class="campaign-button" style="margin: 0 20px;" (click)="refreshCampaignTray()"
                        class="btn btn-primary">Actualizar</button>

                    <img src="assets/icons/stop-button.png" alt="stop-button" class="stop-button"
                        (click)="revertSendStatus()">
                </div>
                <h6 *ngIf="excelName && excelName !== ''" class="excel-input-name">{{'Importado de: ' + excelName}}</h6>
            </div>
            <div class="table-container">
                <table class="table" style="text-align: center;">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Número</th>
                            <th>Enviado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of excelData | slice: (page-1) * pageSizeTable : page * pageSizeTable; let i = index">
                            <td>
                                <img *ngIf="!item.readOnly" src="assets/icons/delete-icon.png" alt="delete-icon"
                                    class="delete-icon" ngbTooltip="Eliminar" placement="top"
                                    (click)="removeTableItem(i)">
                                <input *ngIf="!item.readOnly" type="text" [(ngModel)]="item.nombre"
                                    (keyup)="enableSave()">
                                <span *ngIf="item.readOnly">{{item.nombre}}</span>
                            </td>
                            <td>
                                <input *ngIf="!item.readOnly" type="text" [(ngModel)]="item.apellido"
                                    (keyup)="enableSave()">
                                <span *ngIf="item.readOnly">{{item.apellido}}</span>
                            </td>
                            <td>
                                <input *ngIf="!item.readOnly" type="text" [(ngModel)]="item.numero"
                                    (keyup)="enableSave()" (keydown)="validarInputNumerico($event, item)">
                                <span *ngIf="item.readOnly">{{item.numero}}</span>
                            </td>
                            <td>
                                <img *ngIf="!item.status || item.status === false" src="assets/icons/check_black.png"
                                    alt="check_black" width="30" height="30">
                                <img *ngIf="item.status && item.status === true" src="assets/icons/check_green.png"
                                    alt="check_black" width="30" height="30">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [boundaryLinks]="true"
                [pageSize]="pageSizeTable" class="d-flex justify-content-center"></ngb-pagination>
        </div>

        <div class="flex-evenly" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('excelModal')">
                Cerrar
            </button>
            <!-- <button type="submit" (click)="createUpdateTemplate()" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#056867; width: 20%; margin-right: 15px;'>
                Guardar
            </button> -->
        </div>

    </div>
    <!--<button (click)="closeMyModal($event)" aria-label="Close" class="md-close-btn"><i class="icofont icofont-ui-close"></i></button>-->
</app-modal-animation>

<app-modal-animation [modalID]="'scheduleModal'" [modalClass]="'md-effect-1'">
    <h3>Programar horario de envío</h3>
    <div>
        <div class="date-container">
            <div class="row" style="margin: 0 0.5em;">
                <div class="col-md-6">
                    <h5>Selecciona la fecha</h5>
                    <input type="text" class="date-input form-control mb-2" bsDatepicker [(ngModel)]="selectedDate">
                    <div class="full-width-calendar">
                        <bs-datepicker-inline [bsConfig]="{minMode: 'day'}"
                            [(bsValue)]="selectedDate"></bs-datepicker-inline>
                    </div>
                </div>
                <div class="col-md-6">
                    <h5>Selecciona la hora</h5>
                    <timepicker [(ngModel)]="selectedTime" [showSpinners]="true"></timepicker>
                </div>
            </div>
            <div class="row mt-4 ml-2">
                <div *ngIf="selectedCampaign?.fechaEnvioProgramada" class="col-md-12">
                    <p>
                        Fecha y hora seleccionadas:
                        {{ selectedDate | date: 'fullDate' }} - {{ selectedTime | date: 'shortTime' }}
                    </p>
                </div>
            </div>
        </div>

        <div class="flex-evenly" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('scheduleModal')">
                Cancelar
            </button>
            <button type="submit" class="btn btn-inverse ripple light footer-btn" (click)="updateCampaignSchedule()"
                style='background-color:#056867; width: 20%; margin-right: 15px;'>
                Guardar
            </button>
        </div>

    </div>
    <!--<button (click)="closeMyModal($event)" aria-label="Close" class="md-close-btn"><i class="icofont icofont-ui-close"></i></button>-->
</app-modal-animation>
import { Component, HostListener, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';


@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss']
})
export class SellerComponent implements OnInit {

  info: Array<Object> = [
    {
      image: 'assets/img/plans/planinfo1.svg',
      name: 'Ingresos adicionales',
      description: 'Obtén un ingreso adicional de manera rápida y efectiva.',
    },
    {
      image: 'assets/img/plans/planinfo2.svg',
      name: 'Soporte constante',
      description: 'Nos encargamos de todo, y te asesoramos eficientemente.',
    },
    {
      image: 'assets/img/plans/planinfo3.svg',
      name: 'Mantente actualizado al 4.0',
      description: 'Aumenta tu conocimiento con la tecnologia 4.0.',
    },
  ];

  plans: Array<Object> = [
    {
      recomendado: false,
      plan: 'PLAN FREE',
      value: '$0',
      period: 'MENSUAL',
      benefits: [
        'Cero costo de publicación',
        'Máxima cantidad de productos 10',
        'Máxima cantidad de ventas 15',
        'Visualización de cantidad de likes   '
      ],
      id: 'free',
      action: 'https://wa.me/593990269366?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20FREE,%20¿me%20puedes%20ayudar?'
    },
    {
      recomendado: false,
      plan: 'PLAN MI NEGOCIO',
      value: '$20',
      period: 'MENSUAL',
      benefits: [
        'Todo el plan free',
        'Máxima cantidad de productos 20',
        'Ventas ilimitadas',
        'Personalización de la imagen de la tienda'
      ],
      id: 'negocio',
      action: 'https://wa.me/593990269366?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20MI%20NEGOCIO,%20¿me%20puedes%20ayudar?'
    },
    {
      recomendado: true,
      plan: 'PLAN PREMIUM',
      value: '$35',
      period: 'MENSUAL',
      benefits: [
        'Todo el plan mi negocio',
        'Máxima cantidad de productos 50',
        'Publicidad de tus productos',
        'Mayor personalización de la tienda'
      ],
      id: 'premium',
      action: 'https://wa.me/593990269366?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20PREMIUM,%20¿me%20puedes%20ayudar?'
    },
    {
      recomendado: false,
      plan: 'PLAN GOLDEN',
      value: '$50',
      period: 'MENSUAL',
      benefits: [
        'Todo el plan premium',
        'Máxima cantidad de productos 150',
        'Publicidad periodicamente'
      ],
      id: 'golden',
      action: 'https://wa.me/593990269366?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20GOLDEN,%20¿me%20puedes%20ayudar?'
    },
  ];

  @HostListener('window:load', ['$event']) onPageLoad(event: Event) {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById('free').style.backgroundColor = '#F8FAFA';
      document.getElementById('negocio').style.backgroundColor = '#F8FAFA';
      document.getElementById('premium').style.backgroundColor = '#D0FDFD';
      document.getElementById('golden').style.backgroundColor = '#F8FAFA';
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId) { }

  ngOnInit(): void {
  }

}

import { Component, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { SocketService } from '../services/socket.service';
import { SharedComponentsService } from '../services/sharedComponents.service';
// import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isSidebarOpen = false;
  backofficeUrl = false;
  backofficeActivate = false;

  isLoggedIn: boolean = false;
  username: string = 'Sin información';
  suscription: string;

  @Input() links_navbar: Array<Object>;

  isMenuCollapsed: boolean = true;
  collapsed = true;

  urlActual = ''
  constructor(private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private socketsService: SocketService,
    private sharedService: SharedComponentsService,
    private api: ApiService,
    private toastr: ToastrService,
    private auth: AuthService) { }

  ngOnInit(): void {
    this.isLoggedIn = this.auth.getToken() ? true : false;
    this.backofficeActivate = (this.auth.getHabilitacionSystem() == 'true' )? true : false;

    this.suscription = this.auth.getSuscriptionName();

    if (this.isLoggedIn) {
      this.username = this.auth.getNombres();
    }

    // this.socketsService.conection();

    // this.socketsService.socket.on('respuestaChatUsuario', (datos: any) => {
    //   this.sharedService.CallComponentMethod(datos);
    //   // if (!this.router.url.includes('whatsappUserChat')) this.showToastAlert(datos);
    // });
    this.urlActual =this.router.url ;
    console.log('url actual: ', this.urlActual);
    this.backofficeUrl = this.router.url.includes('backoffice');
  }

  // showToastAlert(datos: any) {
  //   if (datos) {
  //     let mensaje = (datos.body.length > 50 ? datos.body.substring(0, 50) + '...' : datos.body) || 'Sin información';
  //     let opt = {
  //       title: 'Mensaje recibido de ' + datos.pushName,
  //       msg: mensaje,
  //       timeout: 5000,
  //       theme: 'bootstrap',
  //       position: 'bottom-right',
  //       type: 'info'
  //     };
  //     this.api.addToast(opt);
  //     // this.snackBar.open('Mensaje recibido de ' + datos.pushName + ': ' + mensaje, '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
  //   }
  // }

  openSidebar(): void {
    this.isSidebarOpen = true;
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    this.handleClickOutside(event);
  }

  logout() {
    this.auth.clear();
    location.reload();
  }

  redirect(url: string){
    this.router.navigate([url])
  }

  isBackoffice() {
    return this.router.url.includes('backoffice');
  }

  handleClickOutside(event: Event) {
    // Verifica si el clic ocurrió fuera del contenedor
    if (!this.el.nativeElement.contains(event.target)) {
      // Tu lógica aquí, por ejemplo, llamar a una función
      this.closeSidebar();
    }
  }
}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiPagosService } from 'src/app/services/api-pagos.service';
import { SignUpService } from 'src/app/services/sing-up/sign-up.service';
import { environment } from 'src/environments/environment';
import Stepper from 'bs-stepper';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})

export class PaymentComponent implements OnInit {

  @ViewChild('receitpFileInput') fileInput: any;

  URL_PAYMENT = environment.URL_PAYMENT
  URL_PAYMENT_CANCELLATION = environment.URL_PAYMENT_CANCELLATION

  private stepper: Stepper;
  currentStep: number = 0;

  estadoInterruptor = true;
  prefijos: any;
  ventanaPagado = false;
  amount: any;
  amountWithTax: any;
  tax: any;
  selectedPaymentMethod: string = '';
  comprobante: any;
  isDragOver = false;
  receiptDetail: any;

  paymentInfo = new FormGroup({
    // email: new FormControl('', [Validators.required, Validators.email]),
    // nombres: new FormControl('', [Validators.required]),
    // apellidos: new FormControl('', [Validators.required]),
    valor: new FormControl('', [Validators.required]),
    // prefijo: new FormControl('', [Validators.required]),
    reference: new FormControl('', []),
    modePayment: new FormControl('', []),
    // phone: new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    // prefijo: new FormControl('', [Validators.required]),
    // number: new FormControl('', [Validators.maxLength(11),Validators.minLength(9), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    // date: new FormControl('', [Validators.required]),
    // cvv: new FormControl('', [Validators.maxLength(10),Validators.minLength(7), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    // user: new UntypedFormControl('', [Validators.required]),
    // mount: new UntypedFormControl('', [Validators.required]),
    // id: new UntypedFormControl('', [Validators.maxLength(10), Validators.minLength(7), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    // email: new UntypedFormControl('', [Validators.required, Validators.email]),
  })

  billInfo = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    nombre: new FormControl('', [Validators.required]),
    direccion: new FormControl('', [Validators.required]),
    telefono: new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
    ruc_ci: new FormControl('', [Validators.required, Validators.pattern(/^\d{10,13}$/), Validators.minLength(10), Validators.maxLength(13)]),
    prefijo: new FormControl('', [Validators.required]),
    pago: new FormControl('', [])
  })

  plansChatbot: any = [
    {
      recomendado: false,
      plan: 'PLAN BÁSICO',
      value: 15,
      value2: 20,
      normalValue: 25,
      period: 15,
      benefits: [
        '1 usuario',
        'Cero costo de creación',
        'Chatbot SI',
        'Respuestas automaticas 500',
      ],
      benefitsMensajeria: [
        'Vinculación mediante QR',
        '1 500 mensajes',

        'Adjunto de imágenes',
        'Adjunto de videos',
        'Adjunto de documentos',
        'Automatización de envios en línea',
        'Registro automatizado de clientes',
      ],
      benefitsAi: [
        'Asistente Virtual con IA NO',
      ],
      benefitsuShops: [
        'Acceso limitado al ecommerce uShops'
      ],
      benefitsAdicionales: [
        'API de conexión',
        'Respaldo de datos',
        
        'Chat en línea',
        'Analítica de información básica',

      ],
      id: 'basico',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20BÁSICO,%20¿me%20puedes%20ayudar?',
      actionBuy: '/backoffice/payment',
    },
    {
      recomendado: true,
      plan: 'PLAN MEDIUM',
      value: 25,
      value2: 32,
      normalValue: 45,
      period: 25,
      benefits: [
        '3 usuarios',
        'Cero costo de creación',
        'Chatbot SI',
        'Respuestas automaticas 3000',
      ],
      benefitsMensajeria: [

        'Vinculación mediante QR',
        '5 000 mensajes',

        'Adjunto de imágenes',
        'Adjunto de videos',
        'Adjunto de documentos',
        'Automatización de envios en línea',
        'Registro automatizado de clientes',
      ],
      benefitsAi: [
        'Asistente Virtual con IA NO',
      ],
      benefitsuShops: [
        'Acceso moderado al ecommerce uShops'
      ],
      benefitsAdicionales: [
        'API de conexión',
        'Respaldo de datos',
        
        'Chat en línea',
        'Analítica de información básica',


      ],
      id: 'basico',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20MEDIUM,%20¿me%20puedes%20ayudar?',
      actionBuy: '/backoffice/payment',
    },

    {
      recomendado: false,
      plan: 'PLAN FULL',
      value: 45,
      value2: 60,
      normalValue: 75,
      period: 45,
      benefits: [
        '3 usuarios',
        'Cero costo de creación',
        'Chatbot SI',
        'Respuestas automaticas 3000',
      ],
      benefitsMensajeria: [

        'Vinculación mediante QR',
        '20 000 mensajes',

        'Adjunto de imágenes',
        'Adjunto de videos',
        'Adjunto de documentos',
        'Automatización de envios en línea',
        'Registro automatizado de clientes',
      ],
      benefitsAi: [
        'Asistente Virtual con IA NO',
      ],
      benefitsuShops: [
        'Acceso moderado al ecommerce uShops'
      ],
      benefitsAdicionales: [
        'API de conexión',
        'Respaldo de datos',
        
        'Chat en línea',
        'Analítica de información básica',


      ],
      id: 'full',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20FULL,%20¿me%20puedes%20ayudar?',
      actionBuy: '/backoffice/payment',
    },
  ]

  plans: Array<any> = [

    {
      recomendado: false,
      plan: 'PLAN STARTER',
      value: '$35',
      value2: '$42',
      normalValue: 'Precio Normal $50',
      period: 'VALOR MENSUAL',
      benefits: [
        '1 usuario',
        'Chatbot SI',
        'Respuestas automaticas 1200',

      ],
      benefitsMensajeria: [
        '800 Mensajes',
      ],
      benefitsAi: [
        'Asistente Virtual SI',
        'Cantidad de conversaciones automatizadas 30',
      ],
      benefitsuShops: [
        'Acceso moderado al ecommerce uShops',
      ],
      benefitsAdicionales: [
        'API de conexión',
        'Respaldo de datos',
        'Creación de catálogo de clientes',
        'Chat en línea',

        // 'Analítica de información media'
        // 'Soporte técnico medio'

      ],
      id: 'starter',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20STARTER,%20¿me%20puedes%20ayudar?'
    },
    {
      recomendado: true,
      plan: 'PLAN PREMIUM',
      value: '$65',
      value2: '$78',
      normalValue: 'Precio Normal $85',
      period: 'VALOR MENSUAL',
      benefits: [
        '1 usuario',
        'Chatbot SI',
        'Respuestas automaticas 4000',
      ],

      benefitsMensajeria: [
        '3000 Mensajes',
      ],
      benefitsAi: [
        'Asistente Virtual SI',
        'Cantidad de conversaciones automatizadas 100',
        'Analítica de información moderada',
      ],
      benefitsuShops: [
        'Acceso avanzado al ecommerce uShops',
      ],
      benefitsAdicionales: [
        'API de conexión',
        'Respaldo de datos',
        'Creación de catálogo de clientes',
        'Chat en línea',

        // 'Analítica de información avanzada'

        // 'Soporte técnico avanzado'        
      ],
      id: 'premium',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20PREMIUM,%20¿me%20puedes%20ayudar?'
    },
    {
      recomendado: false,
      plan: 'PLAN ENTERPRISE',
      value: '$95',
      value2: '$110',
      normalValue: 'Precio Normal  $125',
      period: 'VALOR MENSUAL',
      benefits: [
        '2 usuarios',
        'Chatbot SI',
        'Asistente Virtual SI',
        'Respuestas automaticas 10000',
      ],
      benefitsMensajeria: [
        '8000 Mensajes',
      ],
      benefitsAi: [
        'Asistente Virtual SI',
        'Cantidad de conversaciones automatizadas 200',
        'Analítica de información completa',
      ],
      benefitsuShops: [
        'Acceso completo al ecommerce uShops',
      ],
      benefitsAdicionales: [
        'API de conexión',
        // 'Soporte técnico full'     
        'Respaldo de datos',
        'Creación de catálogo de clientes',
        'Chat en línea',

        // 'Analítica de información avanzada'

      ],

      id: 'golden',
      action: 'https://wa.me/593983267863?text=Estoy%20interesado/a%20en%20adquirir%20el%20plan%20ENTERPRISE,%20¿me%20puedes%20ayudar?'
    },
  ];

  plan = ''

  constructor(private route: ActivatedRoute,
    private apiPagos: ApiPagosService,
    private api: SignUpService,
    private toastr: ToastrService,
    private router: Router,
    private auth: AuthService) {

    this.route.queryParams.subscribe(params => {
      // Leer los valores de los queryParams y asignarlos a las variables del componente
      this.plan = params['plan'];

    });
  }

  ngOnInit(): void {

    if (this.plan) {
      let objetoBuscado1 = this.plansChatbot.find(objeto => objeto.plan === this.plan);
      let objetoBuscado2 = this.plans.find(objeto => objeto.plan === this.plan);

      if (objetoBuscado1) {

        console.log('encontrado1', objetoBuscado1)
        this.setPaymentSubscription(objetoBuscado1)
      } else if (objetoBuscado2) {
        console.log('encontrado2', objetoBuscado2)
        this.setPaymentSubscription(objetoBuscado2)
      } else {


      }
    }

    setTimeout(() => {
      if (this.auth.getHabilitacionSystem() == 'true') {
        this.router.navigate(['/backoffice', 'chatbot-configuration'])
      }
    }, 1000)


    this.selectedPaymentMethod = 'bank-transfer';


    this.getBillData();
    this.getSubscriptionData();

    var stepperEl = document.querySelector('#stepper');
    this.stepper = new Stepper(stepperEl, {
      linear: false,
      animation: true
    });

    stepperEl.addEventListener('show.bs-stepper', function (event: any) {
      // You can call prevent to stop the rendering of your step
      // event.preventDefault()

      console.log('step: ', event.detail.indexStep);
      this.currentStep = event.detail.indexStep;
    })

    stepperEl.addEventListener('shown.bs-stepper', function (event) {
      console.log('step shown');
    })

    this.route.queryParams.subscribe((params: any) => {
      console.log('parametros: ', params)

      if (params.id && params.clientTransactionId) {

        this.ventanaPagado = true;

        let body = {
          id: params.id,
          clientTransactionId: params.clientTransactionId
        }
      }
    });

    this.apiPagos.obtenerCodigosPaises({}).subscribe(
      (result: any) => {
        console.log(result)
        this.prefijos = result.mensaje
      }, error => {
        alert(error)
      }
    )
  }

  // ngAfterViewInit(): void {
  //   if (!this.ventanaPagado) this.openSuscriptionsModal();
  // }

  nextStep() {
    this.stepper.next();
  }

  previousStep() {
    this.stepper.previous();
  }

  goToStep(num: number) {
    this.stepper.to(num);
  }

  onSubmit() {
    return false;
  }

  selectPref(event: any) {
    //  let aux = document.getElementById('prefijo');
    this.billInfo.controls['prefijo'].setValue(event.value);
    //  console.log('seleccionado', aux);
    console.log('seleccion', event.value);
    //  console.log('NGMODEL', this.selectedPrefijo);
  }

  openBankTransferModal() {
    document.querySelector('#bank-transfer-modal').classList.add('md-show');
  }

  openSuscriptionsModal() {
    document.querySelector('#plans-modal').classList.add('md-show');
  }

  obtenerTextoEstado(): string {
    return this.estadoInterruptor ? 'Pago Anual' : 'Pago Mensual';
  }

  saveBillData() {

    // if (!this.billInfo.valid) {
    //   this.toastr.error('El formulario no es valido.', 'Error', {
    //     timeOut: 3000,
    //     positionClass: 'toast-bottom-right',
    //   });
    //   return;
    // }

    let identificador = this.auth.getIdentificador();

    if (!identificador) {
      console.log('no existe identificador');
      this.toastr.error('No se ha encontrado al usuario, porfavor vuelva a loguearse.', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    let body: any = {
      identificador: identificador,
      email: this.billInfo.controls['email'].value,
      nombre: this.billInfo.controls['nombre'].value,
      telefono: this.billInfo.controls['telefono'].value?.toString(),
      prefijo: this.billInfo.controls['prefijo'].value?.toString(),
      direccion: this.billInfo.controls['direccion'].value?.toString(),
      ruc_ci: this.billInfo.controls['ruc_ci'].value?.toString()
    }

    console.log('body', body);

    this.apiPagos.guardarDatosFacturacion(body).subscribe({
      next: (result: any) => {
        console.log(result);
        this.getBillData();
        this.nextStep();
      },
      error: (error: any) => {
        console.error('Error: ', error);
        this.toastr.error('Ha ocurrido un error al guardar los datos de facturación.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      },
      // complete: () => console.info('complete')
    })
  }

  sendPayment() {
    console.log(this.paymentInfo)
    // console.log(this.paymentInfo.controls['email'].valid)

    console.log(this.paymentInfo.valid)

    let body: any = {
      identificadorUsuario: this.auth.getIdentificador(),
      email: this.billInfo.controls['email'].value,
      nombre: this.billInfo.controls['nombre'].value,
      // apellidos: this.paymentInfo.controls['apellidos'].value,
      phone: this.billInfo.controls['telefono'].value?.toString(),
      prefijo: this.billInfo.controls['prefijo'].value?.toString(),
      amount: Number(this.paymentInfo.controls['valor'].value),
      reference: this.paymentInfo.controls['reference'].value,
    }

    if (isNaN(body.amount)) {
      return alert('error en numero');
    }

    body.amount = redondearDosDecimales(body.amount) //Number((body.amount).toFixed(2))
    body.amountWithTax = redondearDosDecimales(body.amount / 1.15)    //Number((body.amount/1.12).toFixed(2))
    body.tax = redondearDosDecimales(body.amountWithTax * 0.15)    //Number((body.amountWithTax*0.12).toFixed(2))

    body.amount = body.tax + body.amountWithTax
    console.log(body)

    this.apiPagos.pagosPayphonePrepare(body).subscribe(
      (result: any) => {
        console.log(result)
        let identificadorTransaccion = result.identificadorTransaccion;

        let bodySuscripcion = {
          identificador: this.auth.getIdentificador(),
          identificadorTransaccion: identificadorTransaccion
        }
        console.log('guardarDatosSuscripcion', bodySuscripcion)

        this.apiPagos.guardarDatosSuscripcion(bodySuscripcion).subscribe({
          next: (result: any) => {
            console.log(result);

            let bodyPagos = {
              "responseUrl": this.URL_PAYMENT,
              cancellationUrl: this.URL_PAYMENT_CANCELLATION,
              "amount": Number((body.amount * 100).toFixed(0)),
              "amountWithTax": Number((body.amountWithTax * 100).toFixed(0)),
              "tax": Number((body.tax * 100).toFixed(0)),
              "clientTransactionId": identificadorTransaccion,
              reference: body.reference,
              // phoneNumber: body.phone,
              // "countryCode": "593",
              email: body.email,
            }


            console.log('bodyPagos', bodyPagos)

            this.apiPagos.payphonePrepare(bodyPagos).subscribe(
              (result: any) => {
                console.log('result: ', result);
                window.open(result.payWithCard, '_self');
              }, error => {
                alert(error)
              }
            )



          },
          error: (error: any) => {
            console.error('Error: ', error);
            this.toastr.error('Ha ocurrido un error al guardar los datos', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
          },
          // complete: () => console.info('complete')
        })











      }, error => {
        alert(error)
      }
    )
  }

  changeTab(idTab) {
    let tabsHeader = document.getElementsByClassName('nav-link');

    if (tabsHeader && tabsHeader.length > 0) {
      for (let i = 0; i < tabsHeader.length; i++) {
        tabsHeader[i].classList.remove('active');
      }
    }

    let tabHeaderActive = document.getElementById(idTab);
    if (tabHeaderActive) tabHeaderActive.classList.add('active');

    let tabsContent = document.getElementsByClassName('tab-pane');

    if (tabsContent && tabsContent.length > 0) {
      for (let i = 0; i < tabsContent.length; i++) {
        tabsContent[i].classList.remove('show');
        tabsContent[i].classList.remove('active');
      }
    }

    let tabContentActive = document.getElementById(idTab + '-content');
    if (tabContentActive) tabContentActive.classList.add('show');
    if (tabContentActive) tabContentActive.classList.add('active');
  }

  // getUserInfo() {
  //   const body = {
  //      numero: this.paymentInfo.get('phone').value,
  //      prefijo: this.paymentInfo.get('prefijo').value,
  //   }
  //   var validateUser = document.getElementById('validateUser');
  //   var continueButton = document.getElementById('continue');
  //   console.log('BODY', body);
  //   this.api.userData(body).subscribe(
  //      result => {
  //         console.log('USUARIO ENCONTRADO');
  //         this.paymentInfo.controls['id'].setValue(result.cedula);
  //         this.paymentInfo.controls['user'].setValue(result.nombre);
  //         this.paymentInfo.controls['email'].setValue(result.email);
  //         this.userInfo = false;
  //         validateUser.style.display = 'none';
  //         continueButton.style.display = 'block';
  //         // $('#exampleModal2').modal();
  //      },
  //      error => {
  //        console.log(error)
  //      //   swal("Algo salió mal!", "Algo salió mal, por favor revisa la información y trata nuevamente", "success");
  //      }
  //    )
  // }

  getBillData() {

    let identificador = this.auth.getIdentificador();

    if (!identificador) {
      console.log('no existe identificador');
      this.toastr.error('No se han cargado los datos de facturación debido a que no se encuentra el usuario actual', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    let body: any = {
      identificador: identificador,
      type: 'user'
    }

    this.apiPagos.obtenerDatosFacturacion(body).subscribe({
      next: (result: any) => {
        // console.log(result);
        if (identificador) {

          if (!this.billInfo.value.email || this.billInfo.value.email === '') {
            this.billInfo.get('email').setValue(this.auth.getEmail() || '');
          } else {
            this.billInfo.get('email').setValue(result.email);
          }

          this.billInfo.get('nombre').setValue(result.nombre);
          this.billInfo.get('telefono').setValue(result.telefono);
          this.billInfo.get('prefijo').setValue(result.prefijo);
          this.billInfo.get('ruc_ci').setValue(result.ruc_ci);
          this.billInfo.get('direccion').setValue(result.direccion);
          this.billInfo.get('pago').setValue(result.pago);

          this.receiptDetail = result.detalleComprobante;

          // console.log('pago', this.billInfo.value.pago);

          if (result.urlComprobante && result.tipoComprobante && result.nombreComprobante) {
            this.comprobante = {
              url: result.urlComprobante,
              tipo: result.tipoComprobante,
              nombre: result.nombreComprobante,
            };
          }

        }
      },
      error: (error: any) => {
        this.toastr.error('Ha ocurrido un error al obtener los datos de facturación.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      },
      // complete: () => console.info('complete')
    })
  }

  getSubscriptionData() {

    let identificador = this.auth.getIdentificador();

    if (!identificador) {
      console.log('no existe identificador');
      this.toastr.error('No se han cargado los datos de suscripción debido a que no se encuentra el usuario actual', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    let body: any = {
      identificador: identificador
    }

    this.apiPagos.obtenerDatosSuscripcion(body).subscribe({
      next: (result: any) => {
        console.log('datos suscripcion: ', result);
        if (identificador) {
          this.amount = redondearDosDecimales(parseFloat(result.subtotal))
          this.amountWithTax = redondearDosDecimales(parseFloat(result.total))
          this.tax = redondearDosDecimales(parseFloat(result.iva))

          this.paymentInfo.get('valor').setValue(this.amountWithTax);
          this.paymentInfo.get('reference').setValue(result.suscripcion);
          this.paymentInfo.get('modePayment').setValue(result.modePayment);


        }
      },
      error: (error: any) => {
        this.toastr.error('Ha ocurrido un error al obtener los datos de suscripción.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      },
      // complete: () => console.info('complete')
    })
  }

  continuePayment() {

    console.log(this.selectedPaymentMethod)
    if (this.selectedPaymentMethod === 'credit-card') {
      this.sendPayment();
    } else if (this.selectedPaymentMethod === 'bank-transfer') {
      this.openBankTransferModal();
    } else {
      this.toastr.error('No se reconoce el metodo de pago seleccionado.', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
    }
  }

  setPaymentSubscription(item: any) {

    let identificador = this.auth.getIdentificador();

    if (!identificador) {
      console.log('no existe identificador');
      this.toastr.error('No se ha encontrado al usuario, porfavor vuelva a loguearse.', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    console.log('item: ', item);
    let valor = this.estadoInterruptor ? parseFloat(item.value)*12 : parseFloat(item.value2);

    this.amount = redondearDosDecimales(valor / 1.15)
    this.tax = redondearDosDecimales(this.amount * 0.15)
    this.amountWithTax = redondearDosDecimales(valor)

    this.paymentInfo.get('valor').setValue(this.amountWithTax);
    this.paymentInfo.get('reference').setValue(item.plan);
    this.paymentInfo.get('modePayment').setValue((this.estadoInterruptor ? ' ANUAL' : ' MENSUAL'));




    let body: any = {
      identificador: identificador,
      suscripcion: this.paymentInfo.value.reference,
      modePayment: this.paymentInfo.value.modePayment,
      subtotal: this.amount.toString(),
      iva: this.tax.toString(),
      total: this.amountWithTax.toString()
    }

    console.log('body', body);

    this.apiPagos.guardarDatosSuscripcion(body).subscribe({
      next: (result: any) => {
        console.log(result);
        this.getSubscriptionData();
        document.querySelector('#plans-modal').classList.remove('md-show');
      },
      error: (error: any) => {
        console.error('Error: ', error);
        this.toastr.error('Ha ocurrido un error al guardar los datos de suscripción.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      },
      // complete: () => console.info('complete')
    })
  }

  openReceiptInput() {
    this.fileInput.nativeElement.click();
  }

  onReceiptFileSelected(event: any = null, document: any = null) {

    let file: File;

    if (event !== null) {
      file = event.target.files[0];
    } else if (document !== null) {
      file = document;
    } else {
      this.toastr.error('Ha ocurrido un error al detectar el archivo, vuelva a intentarlo.', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
      return;
    }


    console.log('file: ', file);
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        let formData: FormData = new FormData();
        formData.append('image', file);
        formData.append('type', file.type);

        this.apiPagos.uploadImageS3(formData).subscribe({
          next: (result: any) => {

            console.log('imagen subida a s3: ', result.url);
            let identificador = this.auth.getIdentificador();

            if (!identificador) {
              console.log('no existe identificador');
              this.toastr.error('No se ha encontrado al usuario, porfavor vuelva a loguearse.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              return;
            }

            let body: any = {
              identificador: identificador,
              urlComprobante: result.url,
              tipoComprobante: 'image',
              nombreComprobante: file.name,
              detalleComprobante: this.receiptDetail
            }

            console.log('body', body);

            this.apiPagos.guardarDatosFacturacion(body).subscribe({
              next: (result: any) => {
                this.getBillData();
              },
              error: (error: any) => {
                console.error('Error: ', error);
                this.toastr.error('Ha ocurrido un error al guardar el comprobante.', 'Error', {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                });
              },
              // complete: () => console.info('complete')
            })

            this.toastr.success('Se ha subido la imagen exitosamente.', 'Operacion exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Se ha subido la imagen exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          },
          error: (error: any) => {
            console.log('Error al subir la imagen a s3: ', error);
            this.toastr.error('Ha ocurrido un error al subir la imagen.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al subir la imagen', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          },
          // complete: () => console.info('complete')
        });
      };
      reader.readAsDataURL(file);
    } else if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (file.type.startsWith('video/') || fileExtension === 'doc' || fileExtension === 'docx'
        || fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'xlsm'
        || fileExtension === 'pdf') {
        let formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('type', file.type);

        this.apiPagos.uploadFileS3(formData).subscribe({
          next: (result: any) => {
            console.log('documento subido a s3: ', result.url);
            let identificador = this.auth.getIdentificador();

            if (!identificador) {
              console.log('no existe identificador');
              this.toastr.error('No se ha encontrado al usuario, porfavor vuelva a loguearse.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              return;
            }

            let body: any = {
              identificador: identificador,
              urlComprobante: result.url,
              tipoComprobante: 'file',
              nombreComprobante: file.name
            }

            console.log('body', body);

            this.apiPagos.guardarDatosFacturacion(body).subscribe({
              next: (result: any) => {
                this.getBillData();
              },
              error: (error: any) => {
                console.error('Error: ', error);
                this.toastr.error('Ha ocurrido un error al guardar el comprobante.', 'Error', {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                });
              },
              // complete: () => console.info('complete')
            })
          },
          error: (error: any) => {
            console.log('Error al subir el archivo a s3: ', error);
            this.toastr.error('Ha ocurrido un error al subir el documento.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al subir el documento', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          },
          // complete: () => console.info('complete')
        });
      } else {
        this.toastr.error('Solo se admiten imagenes, videos y archivos tipo word, excel o pdf.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    }
  }

  setReceiptDetail() {
    let identificador = this.auth.getIdentificador();

    let body: any = {
      identificador: identificador,
      detalleComprobante: this.receiptDetail
    }

    console.log('body', body);

    this.apiPagos.guardarDatosFacturacion(body).subscribe({
      next: (result: any) => {
        this.toastr.success('Se ha actualizado el detalle exitosamente.', 'Operacion exitosa', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        this.getBillData();
      },
      error: (error: any) => {
        console.error('Error: ', error);
        this.toastr.error('Ha ocurrido un error al actualizar el detalle.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      },
      // complete: () => console.info('complete')
    })
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isDragOver = false;

    const files = event.dataTransfer?.files;
    if (files) {
      this.onDragAndDropFileChange(files);
    }
  }

  onDragAndDropFileChange(files: FileList) {
    console.log('Archivos cargados:', files);
    this.onReceiptFileSelected(null, files[0]);
  }

  redirectToReceipt(url: string) {
    window.open(url, '_blank');
  }

  setPaymentMethod(id: any) {
    //    this.selectedPaymentMethod = id;
  }

  Prefijos() {
    this.api.prefijo().subscribe({
      next: (result: any) => {
        console.log('prefijos: ', result);
        this.prefijos = result;
      },
      error: (error: any) => {
        console.log(error)
      },
      // complete: () => console.info('complete')
    })
  }

  closeModal(name: string) {
    document.querySelector('#' + name).classList.remove('md-show');
  }
}

function redondearDosDecimales(num: any) {
  return Math.round(num * 100) / 100;
}
import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-portada',
  templateUrl: './portada.component.html',
  styleUrls: ['./portada.component.scss'],
  animations: [
    // trigger('ManoTrigger', [
    //   state('gira1', style({
        
    //     transform: 'rotate(0)',
    //   })),
    //   state('gira2', style({
    //     transform: 'rotate(-10deg)'
    //   })),
    //   state('saludando', style({
    //     transform: 'rotate(-90deg)'
    //   })),
    //   transition('gira1 <=> gira2', [
    //     // style({transform: 'rotate(-360deg)'}),
    //     animate('600ms ease-in')
    //   ]),
    //   transition('gira1 => saludando', [
    //     style({transform: 'rotate(0)'}),
    //     animate('1s ease-in')
    //   ]),    
    //   transition('gira2 => saludando', [
    //     style({transform: 'rotate(0)'}),
    //     animate('1s ease-in')
    //   ]),    
    // ]),
    trigger('LogoWhatssTrigger', [
      state('idle', style({
        transform: 'scale(1)',
      })),
      state('saludando', style({
        transform: 'scale(1.25)'
      })),
      transition('idle <=> saludando', [
        animate('1s ease-in')
      ]),
    ]),
    trigger('logo2Trigger', [
      state('idle', style({
        opacity: 0,
      })),
      state('llegando', style({
        opacity: 1,
      })),
      transition('idle => llegando', [
        animate('0.8s ease-in')
      ]),
    ]),

    // trigger('TelefonoTrigger', [
    //   state('idle', style({
    //     transform: 'rotate(0deg)',
    //   })),
    //   state('moviendo', style({
    //     transform: 'rotate(-10deg)'
    //   })),
    //   transition('idle <=> moviendo', [
    //     animate('0.8s ease-in')
    //   ]),
    // ]),

    trigger('TelefonoTrigger', [
      state('idle', style({
        transform: 'scale(1)',
      })),
      state('moviendo', style({
        transform: 'scale(1.1)'
      })),
      transition('idle <=> moviendo', [
        animate('0.8s ease-in')
      ]),
    ]),

    trigger('ManoTrigger', [
      state('idle', style({
        transform: 'translateY(0)',
      })),
      state('moviendo', style({
        transform: 'translateY(-10%)'
      })),
      transition('idle <=> moviendo', [
        animate('0.8s ease-in')
      ]),
    ]),

    trigger('mensajeTrigger', [
      state('idle', style({
        transform: 'translateX(0)',
      })),
      state('moviendo', style({
        transform: 'translateX(10%)'
      })),
      transition('idle <=> moviendo', [
        animate('0.8s ease-in')
      ]),
    ]),

    
    trigger('mensaje2Trigger', [
      state('idle', style({
        opacity: 0,
      })),
      state('llegando', style({
        opacity: 1,
      })),
      transition('idle => llegando', [
        animate('0.8s ease-in')
      ]),
    ]),
    // trigger('iconosMensajesTrigger', [
    //   state('idle', style({
    //     opacity: 0,
    //   })),
    //   state('llegando', style({
    //     opacity: 1
    //   })),
    //   transition('idle => llegando', [
    //     animate('0.8s ease-in')
    //   ]),
    // ]),
    // trigger('baseTrigger', [
    //   state('idle', style({
    //     opacity: 0,
    //   })),
    //   state('llegando', style({
    //     opacity: 1
    //   })),
    //   transition('idle <=> llegando', [
    //     animate('1s ease-in')
    //   ]),
    // ]),
    // trigger('conectoresTrigger', [
    //   state('idle', style({
    //     opacity: 0,
    //   })),
    //   state('llegando', style({
    //     opacity: 1
    //   })),
    //   transition('idle <=> llegando', [
    //     animate('150ms ease-in')
    //   ]),
    // ]),
    
  ]
})
export class PortadaComponent implements OnInit {
  isEstadoMano = 'gira1';
  isEstadoLogoWhatss = 'idle';
  isEstadoLogo2 = 'idle';
  isEstadoTelefono = 'idle';
  isEstadoMensaje = 'idle';
  isEstadoMensaje2 = 'idle';
  isEstadoBase = 'llegando';
  isEstadoConectores = 'llegando';

  isEstadoMEnsaje11 = 'llegando';
  isEstadoMEnsaje12 = 'llegando';
  isEstadoMEnsaje13 = 'llegando';
  isEstadoMEnsaje14 = 'llegando';
  isEstadoMEnsaje15 = 'llegando';

  toggleSaludandoMano() {
    // this.isEstadoMano = 'gira2';
    this.isEstadoMano = 'moviendo';
    setTimeout(() => this.isEstadoMano = 'idle', 1000);
    // setTimeout(() => this.isEstadoMano = 'gira1', 600);
    // setTimeout(() => this.isEstadoMano = 'gira2', 2*600);
    // setTimeout(() => this.isEstadoMano = 'gira1', 3*600);
    // setTimeout(() => this.isEstadoMano = 'gira2', 4*600);
    // setTimeout(() => this.isEstadoMano = 'gira1', 5*600);
  }

  toggleMano() {
    // this.isEstadoMano = 'gira2';
    // setTimeout(() => this.isEstadoMano = 'gira1', 600);
    setTimeout(() => this.isEstadoMano = 'saludando',100);
  }
  togglelogoWhatss() {
    this.isEstadoLogoWhatss = 'saludando';
    setTimeout(() => this.isEstadoLogoWhatss = 'idle', 1000);
    // setTimeout(() => this.isEstadoLogoWhatss = 'saludando', 2000);
    // setTimeout(() => this.isEstadoLogoWhatss = 'idle', 3000);

    console.log('togglelogoWhatss')
  }
  toggleTelefono() {
    this.isEstadoTelefono = 'moviendo';
    setTimeout(() => this.isEstadoTelefono = 'idle', 1000);
  }
  toggleIcono2() {
    this.isEstadoMensaje = 'moviendo';
    setTimeout(() => this.isEstadoMensaje = 'idle', 1000);
  }
  togglePantalla() {
    setTimeout(() => this.isEstadoMEnsaje11 = 'idle', 100);
    setTimeout(() => this.isEstadoMEnsaje11 = 'llegando', 2000);

    setTimeout(() => this.isEstadoMEnsaje12 = 'idle', 150);
    setTimeout(() => this.isEstadoMEnsaje12 = 'llegando', 2200);

    setTimeout(() => this.isEstadoMEnsaje13 = 'idle', 80);
    setTimeout(() => this.isEstadoMEnsaje13 = 'llegando', 2300);

    setTimeout(() => this.isEstadoMEnsaje14 = 'idle', 125);
    setTimeout(() => this.isEstadoMEnsaje14 = 'llegando', 1800);

    setTimeout(() => this.isEstadoMEnsaje15 = 'idle', 250);
    setTimeout(() => this.isEstadoMEnsaje15 = 'llegando', 3000);
  }

  toggleMensaje2() {
    setTimeout(() => this.isEstadoMensaje2 = 'idle', 600);
    setTimeout(() => this.isEstadoMensaje2 = 'llegando', 1500);

  }

  toggleLogo2() {
    setTimeout(() => this.isEstadoLogo2 = 'idle', 600);
    setTimeout(() => this.isEstadoLogo2 = 'llegando', 1500);

  }
  

  toggleBase() {
    this.isEstadoBase = 'idle';
    setTimeout(() => this.isEstadoBase = 'llegando', 1000);
  }
  toggleConectores() {
    // for(let i=1; i<=2; i++){
      // var randomnumber = Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
      setTimeout(() => this.isEstadoConectores = 'idle', 300);
      setTimeout(() => this.isEstadoConectores = 'llegando', 500);
      setTimeout(() => this.isEstadoConectores = 'idle', 800);
      setTimeout(() => this.isEstadoConectores = 'llegando', 1100);
    // }

  }


  clickEvent(event: any){
    console.log(event);

    if(event==='mano'){
      this.toggleMano();
    }
    if(event==='logoWhatss'){
      this.togglelogoWhatss();
    }
    if(event==='telefono'){
      this.toggleTelefono();
    }
    if(event==='mensaje'){
      this.toggleIcono2();
    }
    if(event==='mensaje2'){
      this.toggleMensaje2();
    }
    if(event==='logo2'){
      this.toggleLogo2();
    }    
    
    if(event==='base'){
      this.toggleBase();
    }
    if(event==='conectores'){
      this.toggleConectores();
    }
  }


  constructor() { }

  ngOnInit(): void {

    setTimeout(() => {
      this.toggleSaludandoMano();
      this.togglelogoWhatss();
      this.toggleTelefono();
      this.toggleIcono2();
      this.toggleMensaje2();
      this.toggleLogo2();
      
      this.toggleBase();
      this.toggleConectores();
    }, 500);
    setTimeout(() => {
      this.toggleSaludandoMano();
      this.togglelogoWhatss();
      this.toggleTelefono();
      this.toggleIcono2();
      this.toggleMensaje2();
      this.toggleLogo2();
      this.toggleBase();
      this.toggleConectores();
    }, 5000);

    for (let i=1 ; i<50 ; i++){
      setTimeout(() => {
        this.toggleSaludandoMano();
        this.togglelogoWhatss();
        this.toggleTelefono();
        this.toggleIcono2();
        this.toggleMensaje2();
        this.toggleLogo2();
        this.toggleBase();
        this.toggleConectores();
      }, i*5000);
    }


    
  }

}

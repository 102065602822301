<div class="content">
    <div class="calendar-container">
        <mwl-utils-calendar-header [(view)]="view" [(viewDate)]="viewDate" (viewDateChange)="obtenerCitas()"
            (viewChange)="obtenerCitas()">
        </mwl-utils-calendar-header>

        <ng-template #loading>
            <div class="text-center">
                <i class="fas fa-spin fa-spinner fa-5x"></i> <br />
                Cargando eventos...
            </div>
        </ng-template>

        <div *ngIf="events$ | async; else loading; let events">
            <div [ngSwitch]="view">
                <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events"
                    [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
                    (eventClicked)="eventClicked($event.event)">
                </mwl-calendar-month-view>
                <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events"
                    (eventClicked)="eventClicked($event.event)">
                </mwl-calendar-week-view>
                <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events"
                    (eventClicked)="eventClicked($event.event)">
                </mwl-calendar-day-view>
            </div>
        </div>
    </div>
    <!-- <div class="item-info-container">
        <label for="nombre">Nombre</label>
        <input id="nombre" type="text">

        <label for="descripcion">Descripción</label>
        <textarea name="descripcion" id="descripcion"></textarea>

        <label for="dato1">Dato 1</label>
        <textarea name="dato1" id="dato1"></textarea>
    </div> -->
</div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlApi: 'https://pruebas2.egm-robotics.com',
  socketsUrl: 'http://pruebas2.egm-robotics.com:45511',
  URL_PAYMENT : 'https://pruebas2.egm-robotics.com//ushops/salesxpertPagos/payment/pagosPayphoneConfirm',
  URL_PAYMENT_CANCELLATION : 'http://localhost:4200',
  tokenPayphone : 'zFwjxP5DqEaHAffYLQEja--jbrg2J-ceVc0otLz6_mBidRASElrRx8TvrzYvOkka1dO8CxZlfKsOTzlXAzIXiRllaFQRK_pqT-oEEzZwpXgrm15Z6BG-geV_NFVoKvZw4HUI33wOrt6Fv1nmvKAN7We2BQl6SujQup6apzqVsXrPJH2XrExyoOMwjdrCXae34PVbWk_-8edYEoSRi296hGT0NzHt_jkvyaKBs05BMy_9eAC5afdgmfuqbCQXaFeympsh7bn98az0MGn9tPBNaXIQIqZSx7vKyXhcrjS6ZXC01jYmNisq5kquTSvCWIf7Hfuguw',
  socketsAdmin: 'https://test-connection-xpert.egm-robotics.com'


  // urlApi: 'https://api.egm-robotics.com',
  // socketsUrl: 'http://api.egm-robotics.com:45511',
  // URL_PAYMENT : 'https://api.egm-robotics.com/ushops/salesxpertPagos/payment/pagosPayphoneConfirm',
  // URL_PAYMENT_CANCELLATION : 'https://x-assistant.ushops.tech/home',
  // tokenPayphone : 'dnkqKs3d7fTYofFd63bm_It2O5R-ygtYF7TncGyn7g9n4N-QfVnHwzi85hxbug2Mb5gdgYZW4pRxrKJB7WyfJK3vx_e825larXwdYEHfiE9Wy_A7bwz_gW-ggi-bcz5ecw4u6fMKCvu7VJl_cvyCIzqnIpnwpxBnh-9bEWI2ELlp24RuzhWyOez36pLNSn4djWZwbbwr-4BcEIJTMVWm5p1ungyVAcg_euRnteAPEJRFdEFedi2NkZ8PaZsA7zZ_fDBr6kVEvRFghMDXi02UFxx9X3XLVdVjr4PtEH80Ny3tWuBDqkda0JN5bR8JzQkpikrrpQ',
  // socketsAdmin: 'https://api.egm-robotics.com'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ChatbotConfigurationService } from 'src/app/services/chatbot-configuration.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  //Paginador
  currentPage = 1; // Página actual
  maxVisiblePages = 10; // Número máximo de páginas visibles
  pageSize = 30; // Tamaño de página
  visiblePages: number[] = []; // Páginas visibles en el paginador
  totalPages: number[]; // Total de páginas

  isCollapsedCredentials = false;
  isCollapsedIntegration = false;
  xauth = '';

  botData: any;
  selectedIntegration: any = 'credentials';
  integrations: any = [
    // { id: 'xAssistant', nombre: 'USHOPS', imagen: 'assets/img/logos/ushops-logo.jpg' },
    { id: 'ims', nombre: 'IMS', imagen: 'assets/img/logos/ims-logo.png' },
    { id: 'empresaXAssistant', nombre: 'BOT XPERT ASSISTANT', imagen: 'assets/img/logos/ushops-logo.jpg' },
  ];

  constructor(private apiService: ChatbotConfigurationService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getBotData();
    this.getXpertBotData().then(
      result => {
        if (!this.botData) this.integrations = this.integrations.filter(x => x.id !== 'empresaXAssistant');
      }, error => {
        this.integrations = this.integrations.filter(x => x.id !== 'empresaXAssistant');
      }
    )
    this.toggleContent('credentials');
    this.reloadKeys();
  }

  openIntegrationModal(integration: any) {
    this.selectedIntegration = integration;
    console.log(this.selectedIntegration);
    document.querySelector('#' + 'integrationModal').classList.add('md-show');
  }

  toggleContent(value: string) {

    let buttons = document.getElementsByClassName('setting-button');

    if (buttons) {
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].classList.remove('btn-primary');
        buttons[i].classList.add('btn-outline-primary');
      }
    }

    let element = document.getElementById(value);

    if (element) {
      element.classList.remove('btn-outline-primary');
      element.classList.add('btn-primary');
    }

    switch (value) {
      case 'credentials':
        this.isCollapsedIntegration = true;
        this.isCollapsedCredentials = false;
        break;
      case 'integration':
        this.isCollapsedCredentials = true;
        this.isCollapsedIntegration = false;
        break;
      default:
        break;
    }
  }

  getPaginatedItems(): any[] {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.integrations.slice(startIndex, endIndex);
  }

  setPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.updatePagination();
  }

  setKeys() {
    console.log('selected integration: ', this.selectedIntegration);

    let body: any = {
      xAssistant: this.selectedIntegration.id === 'xAssistant' ? { key: this.selectedIntegration.key, keyMaster: this.selectedIntegration.keyMaster } : undefined,
      ims: this.selectedIntegration.id === 'ims' ? { key: this.selectedIntegration.key, keyMaster: this.selectedIntegration.keyMaster } : undefined,
    };

    if (this.selectedIntegration.id === 'empresaXAssistant') {

      console.log('this.botData.configuracion: ', this.botData.configuracion);

      body = {
        configuracion: this.botData.configuracion
      }

      body.configuracion.keyIMSContact = this.selectedIntegration.keyIMSContact;
      body.configuracion.keyIMSGeneral = this.selectedIntegration.keyIMSGeneral;

      console.log('body: ', body);

      this.apiService.updateSentinelData(body).subscribe({
        next: (result: any) => {
          console.log('integracion actualizada');
          this.closeModal('integrationModal');
          this.toastr.success('Se han actualizado las credenciales de la integración seleccionada.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          this.getBotData();
          this.getXpertBotData();
        },
        error: (error: any) => {
          console.log('Error al actualziar credenciales: ', error);
          this.toastr.error('Ha ocurrido un error al actualizar las credenciales.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        },
        // complete: () => console.log('complete') 
      });
    } else {

      console.log('body: ', body);

      this.apiService.updateBotData(body).subscribe({
        next: (result: any) => {
          console.log('integracion actualizada');
          this.closeModal('integrationModal');
          this.toastr.success('Se han actualizado las credenciales de la integración seleccionada.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          this.getBotData();
          this.getXpertBotData();
        },
        error: (error: any) => {
          console.log('Error al actualziar credenciales: ', error);
          this.toastr.error('Ha ocurrido un error al actualizar las credenciales.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        },
        // complete: () => console.log('complete') 
      });
    }
  }

  getXpertBotData() {
    let body: any = {};

    return new Promise((resolve, reject) => {
      this.apiService.getSentinelData(body).subscribe({
        next: (result: any) => {
          console.log('datos bot: ', result);
          if (result.configuracion) {
            this.botData = result;
            for (let i = 0; i < this.integrations.length; i++) {
              if (this.integrations[i].id === 'empresaXAssistant') {
                this.integrations[i].keyIMSContact = this.botData.configuracion.keyIMSContact || '';
                this.integrations[i].keyIMSGeneral = this.botData.configuracion.keyIMSGeneral || '';
              }
            }
          }
          return resolve(result);
        },
        error: (error: any) => {
          console.log('Error al obtener credenciales: ', error);
          this.toastr.error('Ha ocurrido un error al obtener las credenciales de integración.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          return reject(error);
          // this.snackBar.open('Ha ocurrido un error al obtener las campañas', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      })
    })
  }

  getBotData() {
    let body: any = {};

    this.apiService.getBotData(body).subscribe({
      next: (result: any) => {
        console.log('datos bot: ', result);
        this.botData = result;
        for (let i = 0; i < this.integrations.length; i++) {
          if (this.integrations[i].id === 'xAssistant') {
            this.integrations[i].keyMaster = this.botData.xAssistant.keyMaster || '';
            this.integrations[i].key = this.botData.xAssistant.key || '';
          }

          if (this.integrations[i].id === 'ims') {
            this.integrations[i].keyMaster = this.botData.ims.keyMaster || '';
            this.integrations[i].key = this.botData.ims.key || '';
          }
        }
        this.updatePagination();
      },
      error: (error: any) => {
        console.log('Error al obtener credenciales: ', error);
        this.toastr.error('Ha ocurrido un error al obtener las credenciales de integración.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al obtener las campañas', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      },
      // complete: () => console.info('complete')
    })
  }

  updatePagination() {
    let totalItems = this.integrations.length;
    const totalNumPages = Math.ceil(totalItems / this.pageSize);
    this.totalPages = Array.from({ length: totalNumPages }, (_, index) => index + 1);

    // Lógica para determinar las páginas visibles en el paginador
    const middlePage = Math.ceil(this.maxVisiblePages / 2);
    let startPage = this.currentPage - middlePage + 1;
    let endPage = this.currentPage + middlePage - 1;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(this.maxVisiblePages, totalNumPages);
    }

    if (endPage > totalNumPages) {
      endPage = totalNumPages;
      startPage = Math.max(1, endPage - this.maxVisiblePages + 1);
    }

    this.visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => index + startPage);
  }

  closeModal(name: string) {
    document.querySelector('#' + name).classList.remove('md-show');
  }


  reloadKeys() {
    this.apiService.getKeyGeneral({}).subscribe({
      next: (result: any) => {
        this.xauth = result['xAssistant']['key'];
      },
      error: (error: any) => {
        console.log(error)
      },
      complete: () => console.info('complete')
    })
  }
}

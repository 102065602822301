import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class WhatsappCampaignService {

    ipPublica = environment.urlApi;
    baseChatbotWhatsappUrl = this.ipPublica + '/ushops/xpertAssistant'

    constructor(private http: HttpClient, private auth: AuthService) {
    }

    getBandejasCampaign(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerBandejasCampaign`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    uploadImageS3(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirImagenRespuesta`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    uploadVideoS3(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirVideoS3`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    uploadFileS3(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirArchivoS3`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    createBandejaCampaign(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/crearBandejaCampaign`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    updateBandejaCampaign(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarBandejaCampaign`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    deleteBandejaCampaign(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/eliminarBandejaCampaign`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    createUpdateBandejaCampaign(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/crearActualizarBandejaCampaign`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    getCampaignsWhatsapp(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerCampaignsWhatsapp`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    createCampaignWhatsapp(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/crearCampaignWhatsapp`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    updateCampaignWhatsapp(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarCampaignWhatsapp`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    deleteCampaignWhatsapp(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/eliminarCampaignWhatsapp`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    createMensajeCampaigns(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/crearMensajeCampaign`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    updateMensajeCampaigns(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarMensajeCampaign`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    deleteMensajeCampaigns(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/eliminarMensajeCampaign`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    getMensajesCampaigns(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerMensajesCampaign`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse) {
        console.log('error desconocido: ', error);
        if (error.error instanceof ErrorEvent) {
        } else {
            if (error.status === 401) {
                console.error('no autorizado:');
                localStorage.removeItem('jwt');
            }
        }
        return throwError(new Error(error.statusText));
    }
}
<app-navbar></app-navbar>

<section class="header-section">
    <div class="header-container row">
        <div class="header-info col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
            <div class="title">
                Comienza a vender en uShops
            </div>
            <div class="subtitle">
                Regístrate gratis y vende sin comisiones
            </div>
            <div class="call-to-action paragraph">
                <a [routerLink]="'/signup-email'">Comienza ahora</a>
            </div>
        </div>
        <div class="header-image col-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
            <img src="assets/img/plans/plansimage.svg">
        </div>
    </div>
</section>

<section class="main-section">
    <div class="main-info">
        <div class="subtitle">
            ¿Por qué pagar por un plan?
        </div>
        <div class="info-container row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" *ngFor="let item of info">
                <div class="info-image" style="margin-top: 1rem;">
                    <img src={{item.image}}>
                </div>
                <div class="info-name subtitles-2" style="margin-top: 1rem;">
                    {{item.name}}
                </div>
                <div class="info-description paragraph" style="margin-top: 1rem;">
                    {{item.description}}
                </div>
            </div>
        </div>
    </div>
    <div class="main-plans">
        <div class="subtitle">
            Elige el plan ideal para ti
        </div>
        <div class="plans-items row">
            <div class="plan-item-container col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3" *ngFor="let item of plans; let i = index">
                <div class="plan-item" id={{item.id}}>
                    <div class="plan-recomendado" *ngIf="item.recomendado, else elseBlock">
                        RECOMENDADO
                    </div>
                    <ng-template #elseBlock> <br> </ng-template>
                    <div class="plan subtitles-2">
                        {{item.plan}}
                    </div>
                    <div class="plan-price-container">
                        <div class="plan-price-value" style="color: #006666;">
                            {{item.value}}
                        </div>
                        <div class="plan-price-mensual">
                            {{item.period}}
                        </div>
                    </div>
                    <div class="plan-benefits">
                        <div class="plan-benefit" *ngFor="let benefit of item.benefits">
                            <div class="plan-benefit-check">
                                <img src="assets/img/icons/check_plans.svg">
                            </div>
                            <div class="plan-benefit-description paragraphs">
                                {{benefit}}
                            </div>
                        </div>
                    </div>
                    <div class="plan-button">
                        <a href={{item.action}} target="_blank" class="paragraphs">Conectarse</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>

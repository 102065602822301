import { Component, OnInit } from '@angular/core';
import { DataTransferService } from '../services/data-transfer.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  links: Array<Object>;

  services: Array<Object> = [
    {
      image: 'assets/img/jobs/service1.png',
      name: 'Delivery',
      description: 'uShops te brinda la posibilidad de ofrecer a tus clientes un servicio de entrega a domicilio.'
    }, {
      image: 'assets/img/jobs/service2.png',
      name: 'Pago con tarjetas',
      description: 'Ofrece a tus clientes la opcion de pago con tarjeta de crédito o débito. De una forma fácil segura y rápida.'
    }, {
      image: 'assets/img/jobs/service3.png',
      name: 'Marketing para tu negocio',
      description: 'Permite que tus productos y ofertas aparezcan en nuestras secciones de anuncios en la plataforma. '
    }
  ];

  constructor(private data: DataTransferService) { }

  ngOnInit(): void {
    this.data.currentData.subscribe(message => this.links = message);
  }

}

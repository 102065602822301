import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  selectedFilter1: any = 'all';
  selectedFilter2: any = 'all';
  selectedFilter3: any = 'all';

  filter1Options: any = [
    { value: 'all', label: 'Todos' },
    { value: 'option1', label: 'Opción 1' },
    { value: 'option2', label: 'Opción 2' },
    { value: 'option3', label: 'Opción 3' },
    { value: 'option4', label: 'Opción 4' },
  ];

  filter2Options: any = [
    { value: 'all', label: 'Todos' },
    { value: 'option5', label: 'Opción 5' },
    { value: 'option6', label: 'Opción 6' },
    { value: 'option7', label: 'Opción 7' },
    { value: 'option8', label: 'Opción 8' },
  ];

  filter3Options: any = [
    { value: 'all', label: 'Todos' },
    { value: 'option9', label: 'Opción 9' },
    { value: 'option10', label: 'Opción 10' },
    { value: 'option11', label: 'Opción 11' },
    { value: 'option12', label: 'Opción 12' },
  ];

  initOpts = {
    renderer: 'svg'
  };

  optionsPie: EChartsOption = {
    title: {
      text: 'Ejemplo de Gráfico de Pastel',
    },
    series: [
      {
        type: 'pie',
        data: [
          { name: 'Manzanas', value: 30 },
          { name: 'Plátanos', value: 20 },
          { name: 'Naranjas', value: 15 }
        ],
      },
    ],
    tooltip: {
      formatter: '{b}: {c} ({d}%)',
    }
  };

  optionsBar: EChartsOption = {
    title: {
      text: 'Ejemplo de Gráfico de Barras',
    },
    color: ['#3398DB'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Contadores',
        type: 'bar',
        barWidth: '60%',
        data: [10, 52, 200, 334, 390, 330, 220],
      },
    ],
  };

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="header-container">
    <!-- <div class="filter-container">
        <mat-form-field class="filter-item" appearance="legacy">
            <mat-label>Filtro 1</mat-label>
            <mat-select name="status" [(ngModel)]="selectedFilter1">
                <mat-option *ngFor="let option of filter1Options; index as i"
                    value="{{option.value}}">{{option.label}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="filter-item" appearance="legacy">
            <mat-label>Filtro 2</mat-label>
            <mat-select name="status" [(ngModel)]="selectedFilter2">
                <mat-option *ngFor="let option of filter2Options; index as i"
                    value="{{option.value}}">{{option.label}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="search-bar-container">
        <div class="search-form" fxLayout="row" id="form-search">
            <input id="search-bar" type="text" placeholder="Búsqueda..." fxFlex>
            <button id="search-bar-button" type="button" class="search-btn mat-elevation-z0 text-muted"
                fxLayoutAlign="end center" fxLayout="row">
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </div> -->
</div>

<div class="table-container">
    <div class="mat-table users-table">
        <div class="mat-header-row">
            <div class="mat-header-cell flex-center">Nombre</div>
            <div class="mat-header-cell flex-center">Número celular</div>
            <div class="mat-header-cell flex-center">Cantidad de mensajes</div>
            <div class="mat-header-cell flex-center">Último mensaje</div>
            <div class="mat-header-cell flex-center">Acción</div>
        </div>
        <div *ngFor="let item of getPaginatedItems()" class="mat-row">
            <div class="mat-cell flex-center">{{item.emisor}}</div>
            <div class="mat-cell flex-center">{{item._id}}</div>
            <div class="mat-cell flex-center">{{item.mensajesCount || item.mensajes?.length}}</div>
            <div class="mat-cell flex-center">{{item.fechaUltimoMensaje | date: 'dd/MM/yyyy - HH:mm'}}</div>
            <div class="mat-cell flex-center">
                <div class="p-1 actions flex-center">
                    <!-- <button mat-mini-fab color="primary" matTooltip="View">
                        <mat-icon>remove_red_eye</mat-icon>
                    </button> -->
                    <div class="btn-invoice" (click)="redirectHistory(item._id)" ngbTooltip="Ver mensajes" placement="top">
                        <img src="assets/icons/history-icon.png" alt="history-icon" class="history-icon">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="usersData && usersData.length > 0" class="row paginator-container">
        <ul class="pagination">
            <li class="page-item" [class.disabled]="currentPage === 1">
                <a class="page-link" (click)="setPage(1)">Primera</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage === 1">
                <a class="page-link" (click)="setPage(currentPage - 1)">Anterior</a>
            </li>
            <li class="page-item" *ngFor="let page of visiblePages" [class.active]="currentPage === page">
                <a class="page-link" (click)="setPage(page)">{{ page }}</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage === totalPages?.length">
                <a class="page-link" (click)="setPage(currentPage + 1)">Siguiente</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage === totalPages?.length">
                <a class="page-link" (click)="setPage(totalPages.length)">Última</a>
            </li>
        </ul>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackofficeComponent } from './backoffice.component';
import { BackofficeRoutingModule } from './backoffice-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ChatbotConfigurationComponent } from './chatbot-configuration/chatbot-configuration.component';
import { MassMessagingComponent } from './mass-messaging/mass-messaging.component';
import { WhatsappAdminUsersComponent } from './whatsapp-admin-users/whatsapp-admin-users.component';
import { WhatsappUserChatComponent } from './whatsapp-user-chat/whatsapp-user-chat.component';
import { WhatsappCampaignComponent } from './whatsapp-campaign/whatsapp-campaign.component';
import { SettingsComponent } from './settings/settings.component';
import { PaymentComponent } from './payment/payment.component';
import { KanbanVentasComponent } from './kanban-ventas/kanban-ventas.component';
import { EventsCalendarComponent } from './events-calendar/events-calendar.component';
import { ClientInfoComponent } from './client-info/client-info.component';
import { AdminClientsComponent } from './admin-clients/admin-clients.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarHeaderComponent } from './events-calendar/calendar-header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ProfileComponent } from './profile/profile.component';
import { ScheduleAppointmentComponent } from './schedule-appointment/schedule-appointment.component';

@NgModule({
  declarations: [
    BackofficeComponent,
    ChatbotConfigurationComponent,
    MassMessagingComponent,
    WhatsappAdminUsersComponent,
    WhatsappUserChatComponent,
    WhatsappCampaignComponent,
    SettingsComponent,
    PaymentComponent,
    KanbanVentasComponent,
    EventsCalendarComponent,
    ClientInfoComponent,
    AdminClientsComponent,
    CalendarHeaderComponent,
    DashboardComponent,
    ProfileComponent,
    ScheduleAppointmentComponent
  ],
  imports: [
    CommonModule,
    BackofficeRoutingModule,
    SharedModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    })
  ]
})
export class BackofficeModule { }

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { DataTransferService } from '../services/data-transfer.service';

interface Alert {
  type: string;
  message: string;
}

const ALERTS: Alert[] = [{
    type: 'success',
    message: 'El formulario ha sido enviado con éxito',
}];

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  links: Array<Object>;

  contact: Array<Object> = [
    {
      image: 'assets/img/contact/location.svg',
      title: 'Visítanos',
      info: 'Quito- Ecuador'
    }, {
      image: 'assets/img/contact/phone.svg',
      title: 'Llámanos',
      info: '0990269366'
    }, {
      image: 'assets/img/contact/email.svg',
      title: 'Escríbenos',
      info: 'servicios@egm-robotics.com'
    }
  ];

  form: FormGroup;

  alerts: Alert[];

  constructor(private data: DataTransferService, private formBuilder: FormBuilder, private enviar: ApiService) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.data.currentData.subscribe(message => this.links = message);
  }

  private buildForm() {
    
    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      telefono: ['', []],
      email: ['', [Validators.required, Validators.email]],
      mensaje: ['', [Validators.required, Validators.maxLength(100)]]
    });
    
  }

  save(event: Event) {
    event.preventDefault();
      if(this.form.valid) {
      const value = this.form.value;
      console.group(value);
      this.enviar.formulariocontacto(value).subscribe(
        result => {
          this.reset();
        }, error => {
          alert('Ocurrió un error inespearado. Intenta de nuevo')
        }
      )
    } else {
      this.form.markAllAsTouched();
    }
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  reset() {
    this.alerts = Array.from(ALERTS);
  }


}

<footer>
    <div class="footer-container row">
        <div class="footer-icons col-12 row">
            <div class="footer-icons-main col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row">
                <a [routerLink]="'/home'" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <img src="assets/img/icons/logo_ushops.svg">
                </a>
                <a href="https://www.egm-robotics.com/home" target="_blank" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <img src="assets/img/icons/egm_logo.svg">
                </a>
            </div>
            <div class="footer-icons-app col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row">
                <!-- <p>Próximamente en</p> 
                <a style="margin-left: 1rem;">
                    <img src="assets/img/home/googleplay.svg">
                </a> -->
                <!--
                <a href="" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <img src="assets/img/home/appstore.svg">
                </a>
                -->
            </div>
        </div>
        <!--
        <div class="footer-nav col-12 row">
            <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div class="footer-nav-link">
                    <a href="">Quienes somos</a>
                </div>
                <div class="footer-nav-sublink">
                    <div class="sublink">
                        <a [routerLink]="['/about.us']">Nosotros</a>
                        <a [routerLink]="['/home']">Compromiso con la ciudadania</a>
                        <a [routerLink]="['/home']">Galería</a>
                        <a [routerLink]="['/home']">Contactos</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div class="footer-nav-link">
                    <a href="">Cómo funciona</a>
                </div>
                <div class="footer-nav-sublink">
                    <div class="sublink">
                        <a [routerLink]="['/home']">Guía para principiantes</a>
                        <a [routerLink]="['/home']">Servicios</a>
                        <a [routerLink]="['/home']">Marketing para tu negocio</a>
                        <a [routerLink]="['/home']">Trabaja con nosotros</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div class="footer-nav-link">
                    <a href="">Precios</a>
                </div>
                <div class="footer-nav-sublink">
                    <div class="sublink">
                        <a [routerLink]="['/home']">Planes</a>
                        <a [routerLink]="['/home']">Otros costos</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div class="footer-nav-link">
                    <a href="">Ayuda</a>
                </div>
                <div class="footer-nav-sublink">
                    <div class="sublink">
                        <a [routerLink]="['/home']">Servicio al cliente </a>
                        <a [routerLink]="['/home']">Preguntas frecuentes</a>
                    </div>
                </div>
            </div>
        </div>
        -->
        <div class="footer-social col-12">
            <a href="https://web.facebook.com/uShopsMarket" target="_blank">
                <img src="assets/img/icons/facebook.svg">
            </a>
            <!--
            <a href="">
                <img src="assets/img/icons/twitter.svg">
            </a>
            -->
            <a href="https://www.instagram.com/ushops.market/" target="_blank">
                <img src="assets/img/icons/instagram.svg">
            </a>
            <a href="https://wa.me/593990269366?text=Estoy%20interesado%20en%20registrarme%20en%20uShops,%20¿me%20puedes%20ayudar?" target="_blank">
                <img src="assets/img/icons/whatsapp.svg">
            </a>
        </div>
        <!--
        <div class="footer-terms col-12">
            <a href="">Términos de uso</a> - <a href="">Política de privacidad</a>
        </div>
        -->
        <div class="footer-EGM col-12">
           Un producto más de uShops
        </div>
    </div>
</footer>

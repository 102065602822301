import { Component, HostListener, OnInit } from '@angular/core';
import { DataTransferService } from '../services/data-transfer.service';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {

  links: Array<Object>;
  pasos: Array<Object> =[
    {
      step: 'Regístrate',
      description: 'Para completar tu registro asegúrate de tener tu número cédula de identificación y número de teléfono.',
      image: 'assets/img/guide/step1.svg',
      style1: 'paso1-info',
      style2: 'paso1-img'
    }, {
      step: 'Accede al portal de seller',
      description: 'Administra tu cuenta de ventas, agrega información de productos y realiza actualizaciones de inventario', // y  administra los pagos.',
      image: 'assets/img/guide/step2.svg',
      style1: 'paso2-info',
      style2: 'paso2-img'
    }, {
      step: 'Configura los detalles de tus productos',
      description: 'Agrega un título, imágenes, características, variaciones (colores, tamaños, etc.), descripción y ofertas. ',
      image: 'assets/img/guide/step3.svg',
      style1: 'paso3-info',
      style2: 'paso3-img'
    }, {
      step: 'Entrega los productos',
      description: 'Para que los compradores obtengan sus productos, puedes hacer la entrega tú mismo manteniendo tu propio inventario de productos, o deja que uShops se encargue de todo el proceso (empaque y envío) con nuestra logística.',
      image: 'assets/img/guide/step4.svg',
      style1: 'paso4-info',
      style2: 'paso4-img'
    }, {
      step: 'Revisa las métricas de rendimiento',
      description: 'Analiza tus ventas con la tasa de pedidos defectuosos, tasa de cancelación de compra, tasa de envío tardío y las opiniones de tus usuarios.',
      image: 'assets/img/guide/step5.svg',
      style1: 'paso5-info',
      style2: 'paso5-img'
    }
  ];
  point: HTMLElement;

  @HostListener('document:scroll', ['$event']) scrollfunction(event) {
    let scroll:number = event.path[1].scrollY - 25;
    //console.log(scroll);
    //console.log((innerHeight/1000));
    //console.log('Scroll: ', scroll);
    if(scroll >= 0.75*document.body.scrollHeight) {
      scroll = 0.75*document.body.scrollHeight;
    }
    //console.log('Top: ', this.point.style.top);
    //console.log(document.body.scrollHeight);
    this.point.style.top = (0.75*scroll).toString() + 'px'
  }

  constructor(private data: DataTransferService) { }

  ngOnInit(): void {
    this.data.currentData.subscribe(message => this.links = message);
    this.point = document.getElementById('point');
  }

}

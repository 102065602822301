import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedComponentsService } from 'src/app/services/sharedComponents.service';
import { SocketService } from 'src/app/services/socket.service';
import { WhatsappAdminUsersService } from 'src/app/services/whatsapp-admin-users.service';

@Component({
  selector: 'app-whatsapp-user-chat',
  templateUrl: './whatsapp-user-chat.component.html',
  styleUrls: ['./whatsapp-user-chat.component.scss']
})
export class WhatsappUserChatComponent implements OnInit {

  @ViewChild('scrollBody') scrollContainer: ElementRef | any;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: WhatsappAdminUsersService,
    private socketsService: SocketService,
    private toastr: ToastrService,
    private sharedService: SharedComponentsService) { }

  restLoading = false;
  userNumber: any;
  messageContent: any = '';
  pageSize: number = 25; //Numero de mensajes a cargar al inicio y por cada vez que se haga clic en mostrar mas mensajes
  actualMessageCount: number = 0;
  selectedFileOptions: any;

  chatHistory: any = [];
  contactList: any = [];
  estado_conexion_contacto = 'Conectado';
  contact_selected: any = { contact_name: '' };
  user_image: any;

  ngOnInit(): void {
    this.sharedService.pushMessage.subscribe((datos) => {
      if (datos.from === this.userNumber) {

        let chat: any = {
          tipo: datos.type,
          mensaje: datos.body || datos.answer,
          fecha: datos.date
        }

        if (datos.options && datos.options.media) {
          let fileExtension = datos.options.media.split('.').pop().toLowerCase();
          chat.archivo = datos.options.media;
          chat.nombreArchivo = decodeURIComponent(datos.options.media.split('/').pop());
          // console.log('file extension: ', fileExtension);
          if (fileExtension) {
            if (fileExtension.includes('png') || fileExtension.includes('jpg') || fileExtension.includes('jpeg')) {
              chat.tipoArchivo = 'imagen';
            } else if (fileExtension.includes('mp4') || fileExtension.includes('avi') || fileExtension.includes('mov') || fileExtension.includes('webm')) {
              chat.tipoArchivo = 'video';
            } else {
              chat.tipoArchivo = 'general';
            }
          }
        }

        chat.mensaje = chat.mensaje.replace(
          /(\bhttps?:\/\/\S+\b)/g,
          '<a href="$1" target="_blank" class="link-container">$1</a>'
        );

        this.chatHistory.push(chat);
        setTimeout(() => {
          this.scrollIntoView(this.chatHistory.length > 0 ? this.chatHistory[this.chatHistory.length - 1].fecha : null);
        }, 100);
      }
    });
    this.actualMessageCount = this.pageSize;
    this.activatedRoute.queryParams.subscribe(params => {
      this.userNumber = params['num'];
      console.log('Numero del usuario:', this.userNumber);
      this.refreshUserHistory().then(
        (result: any) => {
          setTimeout(() => {
            this.scrollIntoView(this.chatHistory.length > 0 ? this.chatHistory[this.chatHistory.length - 1].fecha : null);
          }, 100);
        }
      )

      if (!this.userNumber) {
        this.router.navigate(['/account/whatsappAdminUsers']);
      }
    });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    console.log('file: ', file);
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        // const imgElement: any = document.getElementById('block-image-' + index);
        // console.log('elemento imagen: ', imgElement);
        // if (imgElement) {

        let formData: FormData = new FormData();
        formData.append('image', file);
        formData.append('type', file.type);

        this.apiService.uploadImageS3(formData).subscribe(
          result => {
            console.log('imagen subida a s3: ', result.url);
            console.log('Se ha subido la imagen a s3.');
            this.selectedFileOptions = { media: result.url, type: file.type, name: file.name, typeName: 'imagen' };

            // console.log('answers: ', this.answers);
            // imgElement.src = e.target.result;
            this.toastr.success('Se ha subido la imagen exitosamente.', 'Operacion exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Se ha subido la imagen exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          },
          error => {
            console.log('Error al subir la imagen a s3: ', error);
            this.toastr.error('Ha ocurrido un error al subir la imagen.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al subir la imagen', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
          }
        );
        // }
      };
      reader.readAsDataURL(file);
    } else if (file && file.type.startsWith('video/')) {
      console.log('entra a video');

      let formData: FormData = new FormData();
      formData.append('video', file);
      formData.append('type', file.type);

      this.apiService.uploadVideoS3(formData).subscribe(
        result => {
          console.log('video subido a s3: ', result.url);
          this.selectedFileOptions = { media: result.url, type: file.type, name: file.name, typeName: 'video' };
          // setTimeout(() => {
          //   let videoPlayer = this.elementRef.nativeElement.querySelector('#videoPlayer' + index);

          //   videoPlayer.src = result.url;
          //   videoPlayer.load();
          //   // this.snackBar.open('Se ha subido la imagen exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          // }, 1000);
        },
        error => {
          console.log('Error al subir el video a s3: ', error);
          this.toastr.error('Ha ocurrido un error al subir el video.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al subir el video', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      );
    } else if (file) {
      // const fileExtension = file.name.split('.').pop().toLowerCase();
      // if (fileExtension === 'doc' || fileExtension === 'docx'
      //   || fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'xlsm'
      //   || fileExtension === 'pdf') {
      let formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('type', file.type);

      this.apiService.uploadFileS3(formData).subscribe(
        result => {
          console.log('documento subido a s3: ', result.url);
          this.selectedFileOptions = { media: result.url, type: file.type, name: file.name, typeName: 'general' };
        },
        error => {
          console.log('Error al subir el archivo a s3: ', error);
          this.toastr.error('Ha ocurrido un error al subir el documento.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al subir el documento', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      );
      // }
    }
  }

  clearFile() {
    this.selectedFileOptions = undefined;
  }

  showMoreMessages() {
    let scrollId = this.chatHistory.length > 0 ? this.chatHistory[0].fecha : null;
    this.actualMessageCount = this.actualMessageCount + this.pageSize;
    this.refreshUserHistory().then(
      (result: any) => {
        setTimeout(() => {
          this.scrollIntoView(scrollId);
        }, 50);
      }
    )
  }

  scrollIntoView(id: string) {
    if (id) {
      const elementoObjetivo = document.getElementById(id);

      if (this.scrollContainer.nativeElement && elementoObjetivo) {
        const containerOffset = this.scrollContainer.nativeElement.offsetTop;
        const objetivoOffset = elementoObjetivo.offsetTop;
        const scrollTopValue = objetivoOffset - containerOffset;

        this.scrollContainer.nativeElement.scrollTop = scrollTopValue;
      }
    }
  }

  sendMessage() {

    let data: any = {
      numeroUsuario: this.userNumber,
      nombre: this.contact_selected.contact_name || 'Sin información de nombre',
      mensaje: this.messageContent
    }

    if (this.selectedFileOptions) {
      data.archivo = { media: this.selectedFileOptions.media, type: this.selectedFileOptions.type }
    }

    this.socketsService.emitir('mensajeChatUsuario', data);

    let chat: any = {
      "tipo": "assistant",
      "mensaje": this.messageContent,
      "fecha": new Date().toISOString()
    }

    if (this.selectedFileOptions) {
      chat.archivo = this.selectedFileOptions.media,
        chat.tipoArchivo = this.selectedFileOptions.typeName,
        chat.nombreArchivo = this.selectedFileOptions.nombre
    }

    this.chatHistory.push(chat);
    this.clearFile();
    setTimeout(() => {
      this.scrollIntoView(this.chatHistory.length > 0 ? this.chatHistory[this.chatHistory.length - 1].fecha : null);
    }, 100);

    this.messageContent = '';
  }

  refreshUserHistory() {
    this.restLoading = true;
    let body: any = {
      from: this.userNumber,
      pageNumber: 1,
      pageSize: this.actualMessageCount
    };

    return new Promise((resolve, reject) => {
      this.apiService.getUsersHistoryWhatsapp(body).subscribe(
        result => {
          this.restLoading = false;
          // this.usersData = result;
          this.user_image = 'assets/icons/user-avatar-icon.png';
          this.contact_selected.contact_name = result[0].emisor || 'Nombre desconocido';
          // console.log('result historial: ', result);

          let fileExtension: string = '';
          let chat: any;
          this.chatHistory = [];

          for (let i = 0; i < result[0].mensajes.length; i++) {

            chat = {
              tipo: result[0].mensajes[i].tipo,
              mensaje: result[0].mensajes[i].mensaje || result[0].mensajes[i].respuesta,
              fecha: result[0].mensajes[i].fecha
            }

            if (chat.mensaje !== '' && chat.mensaje.includes('_event_')) {
              // console.log('mensaje: ', chat.mensaje);
              chat.tipoArchivo = 'general';
              chat.archivo = "/";
              chat.archivoEvent = true;

              if (chat.mensaje.includes('_event_location')) {
                chat.nombreArchivo = 'Ubicación enviada';
              } else if (chat.mensaje.includes('_event_voice_note')) {
                chat.nombreArchivo = 'Nota de voz enviada';
              } else {
                chat.nombreArchivo = 'Archivo enviado';
              }

              chat.mensaje = "";
            }

            if (result[0].mensajes[i].options && result[0].mensajes[i].options.media) {
              fileExtension = result[0].mensajes[i].options.media.split('.').pop().toLowerCase();
              chat.archivo = result[0].mensajes[i].options.media;
              chat.nombreArchivo = decodeURIComponent(result[0].mensajes[i].options.media.split('/').pop());
              // console.log('file extension: ', fileExtension);
              if (fileExtension) {
                if (fileExtension.includes('png') || fileExtension.includes('jpg') || fileExtension.includes('jpeg')) {
                  chat.tipoArchivo = 'imagen';
                } else if (fileExtension.includes('mp4') || fileExtension.includes('avi') || fileExtension.includes('mov') || fileExtension.includes('webm')) {
                  chat.tipoArchivo = 'video';
                } else {
                  chat.tipoArchivo = 'general';
                }
              }
            }

            chat.mensaje = chat.mensaje.replace(
              /(\bhttps?:\/\/\S+\b)/g,
              '<a href="$1" target="_blank" class="link-container">$1</a>'
            );

            this.chatHistory.push(chat)
          }

          this.chatHistory.mensajesCount = result[0].mensajesCount;

          console.log('mensajes: ', result);
          console.log('chatHistory: ', this.chatHistory);

          return resolve(this.chatHistory);
        },
        error => {
          this.restLoading = false;
          console.log('Error al obtener campañas: ', error);
          this.toastr.error('Ha ocurrido un error al obtener el historial de mensajes.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al obtener las campañas', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          return reject(error);
        }
      );
    })
  }
}

<app-navbar [links_navbar]="links"></app-navbar>

<section class="header-section">
    <div class="header-background">
        <div class="header-container">
            <div class="title">
                Vende con nosotros
            </div>
            <div class="subtitle">
                únete al equipo uShops
            </div>
            <div class="paragraph">
                Sigue los pasos que te presentamos a continuación y comienza a vender tus productos en línea y a generar más ingresos para tu negocio.
            </div>
        </div>
    </div>
</section>

<section class="main-section">
    <div class="subtitle">
        Descubre los servicios que uShops tiene para ti 
    </div>
    <div class="main-container row">
        <div class="job-container col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" *ngFor="let item of services">
            <div class="job-item">
                <div class="job-item-img">
                    <img src={{item.image}}>
                </div>
                <div class="subtitle-2">
                    {{item.name}}
                </div>
                <div class="paragraph">
                    {{item.description}}
                </div>
                <div class="job-item-action">
                    <a href="">Más información</a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer [links_footer]="links"></app-footer>

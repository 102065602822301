<div class="container">
    <div id="stepper" class="bs-stepper">
        <div class="bs-stepper-header">
            <div class="step" data-target="#test-l-1">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">1</span>
                    <span class="bs-stepper-label">Suscripción</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-2">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">2</span>
                    <span class="bs-stepper-label">Datos de facturación</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-3">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">3</span>
                    <span class="bs-stepper-label">Pago</span>
                </button>
            </div>
        </div>
        <div class="bs-stepper-content">
            <form (ngSubmit)="onSubmit()">
                <div id="test-l-1" class="content">
                    <h5 class="step-title">Suscripción</h5>
                    <div class="payments-container">
                        <button *ngIf="!ventanaPagado" type="button" class="btn btn-primary suscription-button"
                            (click)="openSuscriptionsModal()">Seleccionar Suscripción</button>

                        <div *ngIf="amount && !ventanaPagado" class="card taxes-container">
                            <p><strong>Referencia: </strong> {{paymentInfo.value.reference}}</p>
                            <p><strong>Modo Pago: </strong> {{paymentInfo.value.modePayment}}</p>
                            <p><strong>Valor sin impuestos: </strong> ${{amount}}</p>
                            <p><strong>IVA (15%): </strong> ${{tax}}</p>
                            <p><strong>Total a pagar: </strong> ${{amountWithTax}}</p>
                        </div>

                        <button *ngIf="amount && !ventanaPagado" type="button" class="btn btn-primary mt-5 button-next"
                            (click)="nextStep()" [disabled]="!amountWithTax">Continuar</button>

                        <!-- <div *ngIf="!ventanaPagado" class="payment-form-container">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">DATOS DE SUSCRIPCIÓN</h5>
                                    </div>
                                    <div class="modal-body">
                                        <form class="form-box" [formGroup]="paymentInfo" autocomplete="off">
                                            <div class="input-box">
                                                <label for="card">Valor a cancelar</label>
                                                <div class="input-box">
                                                    <input type="number" name="valor" id="valor" required
                                                        formControlName="valor" placeholder="Seleccionar la suscripción"
                                                        autocomplete="off" disabled>
                                                </div>
                                                <label
                                                    *ngIf="!paymentInfo.controls['valor'].valid && paymentInfo.controls['valor'].touched"
                                                    class="mensaje-error">Error debe ser un valor válido</label>
                                            </div>

                                            <div class="input-box">
                                                <label for="date">Referencia</label>
                                                <input type="text" name="ref" id="" formControlName="reference"
                                                    placeholder="Seleccionar la suscripción" disabled>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" id="continue" (click)="nextStep()"
                                            [disabled]="!this.paymentInfo.valid">CONTINUAR</button>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div *ngIf="ventanaPagado">

                            <div class="imagen-pago">
                                <!-- <img src="assets/img/adicionales/creditCard.jfif" alt="" > -->

                            </div>

                            <div class="pago-completo">

                                <div class="right-section">
                                    <div class="services-container">
                                        <div class="individual-service">
                                            <h5>PAGO CONFIRMADO</h5>
                                            <h6>Tu pago ha sido efectuado exitosamente, recibirás confirmaciones por
                                                diferentes medios</h6>
                                            <span>EGM ROBOTICS siempre innovando</span>
                                            <!-- <div class="img-container">
                                      <img src="http://localhost:4200/assets/img/egm/ilustracion-concepto.png" alt="">
                                    </div> -->
                                            <div [routerLink]="['/home']">
                                                <p class="boton-confirm">
                                                    <a>Aceptar</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <button (click)="next()" class="btn btn-primary">Next</button> -->
                </div>
                <div id="test-l-2" class="content">
                    <form class="form-box" [formGroup]="billInfo" autocomplete="off" (submit)="saveBillData()">
                        <h5 class="step-title">Datos de facturación</h5>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Nombre</label>
                            <input type="text" class="form-control" id="exampleInputEmail1"
                                placeholder="Ingresar nombre" required formControlName="nombre" autocomplete="off" />
                            <label *ngIf="!billInfo.controls['nombre'].valid && billInfo.controls['nombre'].touched"
                                class="mensaje-error">Error debe ser un nombre válido</label>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Número de teléfono</label>
                            <div class="phone-number-container">
                                <select name="prefijo" id="prefijo" (change)="selectPref($event.target)"
                                    [(ngModel)]=" billInfo.value.prefijo" [ngModelOptions]="{standalone: true}">
                                    <option selected="true" disabled="disabled">Seleccionar</option>
                                    <option [value]="item?.prefixNumber" *ngFor="let item of prefijos">
                                        <span>{{item?.prefixNumber}}</span>
                                        {{item?.iso}}
                                    </option>
                                </select>
                                <input type="text" name="phone" id="phone" required formControlName="telefono"
                                    class="form-control" placeholder="Número de teléfono">
                            </div>
                            <label *ngIf="!billInfo.controls['telefono'].valid && billInfo.controls['telefono'].touched"
                                class="mensaje-error">Error debe ser un teléfono válido</label>
                            <label
                                *ngIf="!billInfo.controls['prefijo'].valid && (billInfo.controls['prefijo'].touched || billInfo.controls['telefono'].touched)"
                                class="mensaje-error">Error debe ser un prefijo válido</label>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Dirección</label>
                            <input type="text" class="form-control" id="exampleInputEmail1"
                                placeholder="Ingresar dirección" required formControlName="direccion"
                                autocomplete="off" />
                            <label
                                *ngIf="!billInfo.controls['direccion'].valid && billInfo.controls['direccion'].touched"
                                class="mensaje-error">Error debe ser una direccion válida</label>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Correo electrónico</label>
                            <input type="email" class="form-control" id="exampleInputEmail1"
                                placeholder="Ingresar email" required formControlName="email" autocomplete="off" />
                            <label *ngIf="!billInfo.controls['email'].valid && billInfo.controls['email'].touched"
                                class="mensaje-error">Error debe ser una dirección de correo electronico válida</label>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">RUC / Cédula</label>
                            <input type="text" class="form-control" id="exampleInputEmail1"
                                placeholder="Ingresar RUC o cédula" required formControlName="ruc_ci"
                                autocomplete="off" />
                            <label *ngIf="!billInfo.controls['ruc_ci'].valid && billInfo.controls['ruc_ci'].touched"
                                class="mensaje-error">Error debe ser un RUC O cédula válido</label>
                        </div>
                        <button type="submit" class="btn btn-primary mt-5 button-next"
                            [disabled]="!this.billInfo.valid">Continuar</button>
                    </form>
                </div>
                <div id="test-l-3" class="content">
                    <h5 class="step-title">Checkout</h5>
                    <div class="payment-info-item">
                        <div class="item-number">
                            <strong>1</strong>
                        </div>
                        <div class="item-title">
                            <strong>Datos de suscripción</strong>
                        </div>
                        <div class="item-description">
                            <p>Suscripcion seleccionada: {{paymentInfo.value.reference}}</p>
                            <p>Valor de la suscripción: ${{amount}}</p>
                            <p>IVA (12%): ${{tax}}</p>
                            <p>Total a pagar: ${{paymentInfo.value.valor}}</p>
                        </div>
                    </div>
                    <div class="payment-info-item">
                        <div class="item-number">
                            <strong>2</strong>
                        </div>
                        <div class="item-title">
                            <strong>Datos de facturación</strong>
                        </div>
                        <div class="item-description">
                            <p>Nombre: {{billInfo.value.nombre}}</p>
                            <p>Correo electrónico: {{billInfo.value.email}}</p>
                            <p>Dirección: {{billInfo.value.direccion}}</p>
                            <p>Teléfono: {{billInfo.value.telefono}}</p>
                            <p>RUC / Cédula: {{billInfo.value.ruc_ci}}</p>
                        </div>
                    </div>
                    <div class="payment-info-item">
                        <div class="item-number">
                            <strong>3</strong>
                        </div>
                        <div class="item-title">
                            <strong>Metodo de pago</strong>
                        </div>
                        <div class="item-description">
                            <div class="method-radio-item">
                                <input type="radio" name="payment-method" id="bank-transfer" value="bank-transfer"
                                    (click)="setPaymentMethod('bank-transfer')" [(ngModel)]="selectedPaymentMethod">
                                <!-- [checked]="selectedPaymentMethod === 'bank-transfer'" -->
                                <label for="bank-transfer">Transferencia bancaria</label>
                            </div>
                            <div class="method-radio-item">
                                <input type="radio" name="payment-method" id="credit-card" value="credit-card"
                                    (click)="setPaymentMethod('credit-card')" [(ngModel)]="selectedPaymentMethod">
                                <!-- [checked]="selectedPaymentMethod === 'credit-card'" -->

                                <label for="credit-card">Tarjeta de crédito</label>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mt-5 redirect-payment-button"
                        (click)="continuePayment()">Realizar pago</button>
                </div>
            </form>
        </div>
    </div>
</div>


<app-modal-animation [modalID]="'plans-modal'" [modalClass]="'md-effect-1'">
    <h3>Seleccionar Plan</h3>
    <div>
        <div class="row flex-center" style="margin: 0 0.5em;">
            <section class="plans-section">
                <!-- <div class="subtitles">
                    Elige el plan que más se adapte a tu negocio
                </div>
                <div class="subtitles-2">
                    Conoce los planes que tenemos para ti y elige el que más se adapte a tu negocio
                </div> -->
                <div class="plans-items">
                    <div class="center col-12">
                        <p class="plan subtitles-2">{{ obtenerTextoEstado() }}</p>
                        <input type="checkbox" [(ngModel)]="estadoInterruptor" id="miInterruptor">
                        <label for="miInterruptor"></label>
                    </div>
                </div>

                <ul class="nav nav-tabs" id="tab" role="tablist">
                    <li class="nav-item cursor-pointer">
                        <a class="nav-link active" id="chatbot-tab" data-toggle="tab" (click)="changeTab('chatbot-tab')"
                            role="tab" aria-controls="chatbot-tab" aria-selected="true">
                            <strong>Whatsapp Marketing</strong></a>
                    </li>
                    <!-- <li class="nav-item cursor-pointer">
                        <a class="nav-link" id="ia-tab" data-toggle="tab" (click)="changeTab('ia-tab')" role="tab"
                            aria-controls="ia-tab" aria-selected="false">Planes con <strong>INTELIGENCIA
                                ARTIFICIAL</strong></a>
                    </li> -->
                </ul>
                <div class="tab-content" id="tabContent">
                    <div class="tab-pane fade show active" id="chatbot-tab-content" role="tabpanel"
                        aria-labelledby="chatbot-tab">
                        <div class="plans-items row">
                            <div class="plan-item-container col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                                *ngFor="let item of plansChatbot; let i = index">
                                <div class="plan-item" id={{item.id}}>
                                    <div class="plan-recomendado" *ngIf="item.recomendado, else elseBlock">
                                        RECOMENDADO
                                    </div>
                                    <ng-template #elseBlock> <br> </ng-template>
                                    <div class="plan subtitles-2">
                                        {{item.plan}}
                                    </div>
                                    <div class="plan-price-container">
                                        <div *ngIf="estadoInterruptor" class="plan-price-value" style="color: #014258;">
                                            ${{item.value*12}}
                                        </div>
                                        <div *ngIf="!estadoInterruptor" class="plan-price-value" style="color: #014258;">
                                            ${{item.value2}}
                                        </div>
                    
                                        <div *ngIf="estadoInterruptor" class="plan-price-mensual">
                                            VALOR MENSUAL: ${{item.period}}
                                        </div>
                                        <div class="plan-normal-value">
                                            Precio Normal ${{item.normalValue}}
                                        </div>
                                    </div>
                                    <div class="plan-benefits">

                                        <!-- <div class="plan-benefit-description paragraphs subtitle">
                                            <br>
                                            <strong>Chatbot</strong>
                                        </div>
                                        <div class="plan-benefit" *ngFor="let benefit of item.benefits">
                                            <div *ngIf="!benefit" class="plan-benefit-check">
                                                <br>
                                            </div>
                                            <div *ngIf="benefit" class="plan-benefit-check">
                                                <img src="assets/img/icons/check_plans.svg">
                                            </div>
                                            <div class="plan-benefit-description paragraphs">
                                                {{benefit}}
                                            </div>
                                        </div>
        
                                        <div class="plan-benefit-description paragraphs subtitle">
                                            <br>
                                            <strong>Mensajeria Masiva</strong>
                                        </div>
                                        <div class="plan-benefit" *ngFor="let benefit of item.benefitsMensajeria">
                                            <div *ngIf="!benefit" class="plan-benefit-check">
                                                <br>
                                            </div>
                                            <div *ngIf="benefit" class="plan-benefit-check">
                                                <img src="assets/img/icons/check_plans.svg">
                                            </div>
                                            <div class="plan-benefit-description paragraphs">
                                                {{benefit}}
                                            </div>
                                        </div>
        
                                        <div class="plan-benefit-description paragraphs subtitle">
                                            <br>
                                            <strong>Inteligencia Artificial</strong>
                                        </div>
                                        <div class="plan-benefit" *ngFor="let benefit of item.benefitsAi">
                                            <div *ngIf="!benefit" class="plan-benefit-check">
                                                <br>
                                            </div>
                                            <div *ngIf="benefit" class="plan-benefit-check">
                                                <img src="assets/img/icons/check_plans.svg">
                                            </div>
                                            <div class="plan-benefit-description paragraphs">
                                                {{benefit}}
                                            </div>
                                        </div>
        
                                        <div class="plan-benefit-description paragraphs subtitle">
                                            <br>
                                            <strong>uShops</strong>
                                        </div>
                                        <div class="plan-benefit" *ngFor="let benefit of item.benefitsuShops">
                                            <div *ngIf="!benefit" class="plan-benefit-check">
                                                <br>
                                            </div>
                                            <div *ngIf="benefit" class="plan-benefit-check">
                                                <img src="assets/img/icons/check_plans.svg">
                                            </div>
                                            <div class="plan-benefit-description paragraphs">
                                                {{benefit}}
                                            </div>
                                        </div>
        
                                        <div class="plan-benefit-description paragraphs subtitle">
                                            <br>
                                            <strong>Adicionales</strong>
                                        </div>
                                        <div class="plan-benefit" *ngFor="let benefit of item.benefitsAdicionales">
                                            <div *ngIf="!benefit" class="plan-benefit-check">
                                                <br>
                                            </div>
                                            <div *ngIf="benefit" class="plan-benefit-check">
                                                <img src="assets/img/icons/check_plans.svg">
                                            </div>
                                            <div class="plan-benefit-description paragraphs">
                                                {{benefit}}
                                            </div>
                                        </div> -->

                                    </div>
                                    <div class="plan-button" (click)="setPaymentSubscription(item)">
                                        <a class="paragraphs">Seleccionar</a>
                                    </div>
                                    <a routerLink="/home" target="_blank" class="cursor-pointer">
                                        <span class="paragraphs">Mas información</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- TuComponente.component.html -->
                    </div>
                    <div class="tab-pane fade" id="ia-tab-content" role="tabpanel" aria-labelledby="profile-tab">
                        <div class="plans-items row">
                            <div class="plan-item-container col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                                *ngFor="let item of plans; let i = index">
                                <div class="plan-item" id={{item.id}}>
                                    <div class="plan-recomendado" *ngIf="item.recomendado, else elseBlock">
                                        RECOMENDADO
                                    </div>
                                    <ng-template #elseBlock> <br> </ng-template>
                                    <div class="plan subtitles-2">
                                        {{item.plan}}
                                    </div>
                                    <div class="plan-price-container">
                                        <div *ngIf="estadoInterruptor" class="plan-price-value" style="color: #014258;">
                                            {{item.value}}
                                        </div>
                                        <div *ngIf="!estadoInterruptor" class="plan-price-value"
                                            style="color: #014258;">
                                            {{item.value2}}
                                        </div>
                                        <div class="plan-normal-value">
                                            {{item.normalValue}}
                                        </div>
                                        <div class="plan-price-mensual">
                                            {{item.period}}
                                        </div>
                                    </div>
                                    <div class="plan-benefits">

                                        <!-- <div class="plan-benefit-description paragraphs subtitle">
                                            <br>
                                            <strong>Chatbot</strong>
                                        </div>
                                        <div class="plan-benefit" *ngFor="let benefit of item.benefits">
                                            <div *ngIf="!benefit" class="plan-benefit-check">
                                                <br>
                                            </div>
                                            <div *ngIf="benefit" class="plan-benefit-check">
                                                <img src="assets/img/icons/check_plans.svg">
                                            </div>
                                            <div class="plan-benefit-description paragraphs">
                                                {{benefit}}
                                            </div>
                                        </div>
        
                                        <div class="plan-benefit-description paragraphs subtitle">
                                            <br>
                                            <strong>Mensajeria Masiva</strong>
                                        </div>
                                        <div class="plan-benefit" *ngFor="let benefit of item.benefitsMensajeria">
                                            <div *ngIf="!benefit" class="plan-benefit-check">
                                                <br>
                                            </div>
                                            <div *ngIf="benefit" class="plan-benefit-check">
                                                <img src="assets/img/icons/check_plans.svg">
                                            </div>
                                            <div class="plan-benefit-description paragraphs">
                                                {{benefit}}
                                            </div>
                                        </div>
        
                                        <div class="plan-benefit-description paragraphs subtitle">
                                            <br>
                                            <strong>Inteligencia Artificial</strong>
                                        </div>
                                        <div class="plan-benefit" *ngFor="let benefit of item.benefitsAi">
                                            <div *ngIf="!benefit" class="plan-benefit-check">
                                                <br>
                                            </div>
                                            <div *ngIf="benefit" class="plan-benefit-check">
                                                <img src="assets/img/icons/check_plans.svg">
                                            </div>
                                            <div class="plan-benefit-description paragraphs">
                                                {{benefit}}
                                            </div>
                                        </div>
        
                                        <div class="plan-benefit-description paragraphs subtitle">
                                            <br>
                                            <strong>uShops</strong>
                                        </div>
                                        <div class="plan-benefit" *ngFor="let benefit of item.benefitsuShops">
                                            <div *ngIf="!benefit" class="plan-benefit-check">
                                                <br>
                                            </div>
                                            <div *ngIf="benefit" class="plan-benefit-check">
                                                <img src="assets/img/icons/check_plans.svg">
                                            </div>
                                            <div class="plan-benefit-description paragraphs">
                                                {{benefit}}
                                            </div>
                                        </div>
        
                                        <div class="plan-benefit-description paragraphs subtitle">
                                            <br>
                                            <strong>Adicionales</strong>
                                        </div>
                                        <div class="plan-benefit" *ngFor="let benefit of item.benefitsAdicionales">
                                            <div *ngIf="!benefit" class="plan-benefit-check">
                                                <br>
                                            </div>
                                            <div *ngIf="benefit" class="plan-benefit-check">
                                                <img src="assets/img/icons/check_plans.svg">
                                            </div>
                                            <div class="plan-benefit-description paragraphs">
                                                {{benefit}}
                                            </div>
                                        </div> -->

                                    </div>
                                    <div class="plan-button" (click)="setPaymentSubscription(item)">
                                        <a class="paragraphs">Seleccionar</a>
                                    </div>
                                    <a routerLink="/home" target="_blank" class="cursor-pointer">
                                        <span class="paragraphs">Mas información</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- <div class="flex-center" style="margin-top: 1em;">
            <button type="button" class="btn btn-danger ripple light footer-btn" style='width: 20%;'
                (click)="closeModal('plans-modal')">
                Cerrar
            </button>
        </div> -->
    </div>
</app-modal-animation>

<app-modal-animation [modalID]="'bank-transfer-modal'" [modalClass]="'md-effect-1'">
    <h3>Datos para transferencia bancaria</h3>
    <div (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)"
        [class.drag-over]="isDragOver" class="dropzone">
        <div *ngIf="!isDragOver">
            <div class="row flex-center container-payment" style="margin: 0 0.5em;">
                <div class="col-sm-12 bank-data-container text-center">
                    <p><strong>Cuenta Pro Pyme:</strong> 27059014666</p>
                    <p><strong>USHOPS S.A.S. </strong></p>
                    <p><strong>RUC: </strong>1793209314001</p>
                    <p><strong>Email: </strong>servicios@egm-robotics.com</p>
                    <p><strong>Telefono: </strong>(593) 99 026 9366</p>
                </div>
            </div>

            <div *ngIf="comprobante" class="receipt-container">
                <span style="margin-bottom: 1em;"><strong>Sube AQUÍ el archivo de comprobante de
                        transferencia:</strong></span>
                <img *ngIf="comprobante.tipo === 'image'" [src]="comprobante.url" alt="receipt-image"
                    class="receipt-image" (click)="redirectToReceipt(comprobante.url)">
                <img *ngIf="comprobante.tipo === 'file'" src="assets/icons/uploaded-file-icon.png" alt="receipt-image"
                    class="receipt-image" (click)="redirectToReceipt(comprobante.url)">
                <p class="receipt-name" (click)="redirectToReceipt(comprobante.url)">{{comprobante.nombre}}</p>
            </div>
        </div>
        <div *ngIf="comprobante && !isDragOver">
            <p><i><strong>El comprobante se ha subido correctamente, nos pondremos en contacto contigo y te
                        habilitaremos el sistema de inmediato</strong></i></p>
        </div>

        <div *ngIf="!isDragOver && this.billInfo.value.pago && this.billInfo.value.pago === 'pendiente'"
            class="details-container">
            <label for="receipt-details"><strong>Detalle:</strong></label>
            <textarea name="receipt-details" id="receipt-details" [(ngModel)]="receiptDetail"
                (blur)="setReceiptDetail()"></textarea>
        </div>

        <div *ngIf="isDragOver" class="flex-center">
            <p><strong>Arrastre aqui el archivo de comprobante</strong></p>
        </div>
    </div>




    <input type="file" #receitpFileInput style="display: none" (change)="onReceiptFileSelected($event)" />
    <button type="button" class="btn btn-success ripple light footer-btn" style='width: 30%;'
        (click)="openReceiptInput()">
        {{comprobante ? 'Subir otro comprobante': 'Subir comprobante'}}
    </button>
    <div class="flex-between">

        <button type="button" class="btn btn-danger ripple light footer-btn" style='width: 30%;'
            (click)="closeModal('bank-transfer-modal')">
            SIGUIENTE
        </button>
    </div>
</app-modal-animation>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SocketIoService {

    socketsAdmin = environment.socketsAdmin


    private socket: Socket;
    private xpertVersion = this.auth.getVersionSystem()

    constructor(private auth: AuthService) {
        

        console.log('token socket: ', this.auth.getToken());

        if(this.xpertVersion == 'v2'){

            console.log('conexion sockets')

            this.socket = io(this.socketsAdmin, {
                path: '/comm/socket.io',  // Ajustar la ruta al nuevo path del servidor
                extraHeaders: {
                    'jwt': this.auth.getToken()  // Mantener el token JWT
                }
            });
    
            // Manejar la conexión exitosa
            this.socket.on('connect', () => {
                console.log('Conexión exitosa al servidor de Socket.IO');
            });
    
            // Manejar errores de conexión
            this.socket.on('connect_error', (error: any) => {
                console.error('Error en la conexión:', error);
            });
    
            // Manejar desconexión
            this.socket.on('disconnect', (reason: string) => {
                console.log('Desconectado del servidor:', reason);
            });




        } else {
            console.log('conexion incumplida')

        }

        
    }

    // Método para escuchar eventos
    listen(eventName: string) {
        return new Observable((subscriber) => {
            this.socket.on(eventName, (data: any) => {
                subscriber.next(data);
            });
        });
    }

    // Método para emitir eventos
    emit(eventName: string, data: any) {
        this.socket.emit(eventName, data);
    }
}
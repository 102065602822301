<div class="filter-container">
  <!-- <div class="filter-item">
    <label>Temporalidad</label>
    <select name="status" [(ngModel)]="selectedFilterTemp" (change)="getClients()">
      <option *ngFor="let option of filterTempOptions; index as i" value="{{option.value}}">{{option.label}}</option>
    </select>
  </div> -->
  <div class="filter-item">
    <label>Importancia</label>
    <select name="status" [(ngModel)]="selectedFilterImp" (change)="getClients()">
      <option *ngFor="let option of filterImpOptions; index as i" value="{{option.value}}">{{option.label}}</option>
    </select>
  </div>
  <!-- <div class="filter-item">
    <label>Filtro 3</label>
    <select name="status" [(ngModel)]="selectedFilter3" (change)="getClients()">
      <option *ngFor="let option of filter3Options; index as i" value="{{option.value}}">{{option.label}}</option>
    </select>
  </div> -->
</div>
<!-- <div class="search-bar-container">
  <div class="search-form" fxLayout="row" id="form-search">
    <input id="search-bar" type="text" placeholder="Búsqueda..." fxFlex>
    <button id="search-bar-button" type="button" class="search-btn mat-elevation-z0 text-muted"
      fxLayoutAlign="end center" fxLayout="row">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div> -->

<div class="content">
  <div class="kanban-container" (mousedown)="startDragging($event)">
    <div *ngFor="let item of kanbanData.datosKanban" class="kanban-item">
      <div [ngClass]="item.id !== 'leads' ? 'item-header': 'item-header-leads'">
        <div *ngIf="item.id !== 'leads'"> </div>
        <h6 *ngIf="!item.editable" class="header-title">{{item.title}}</h6>
        <input *ngIf="item.editable" type="text" [(ngModel)]="item.title" class="header-title"
          (keyup.enter)="saveKanbanData(item)">
        <div *ngIf="item.id !== 'leads'" class="icons-container">
          <img src="assets/icons/delete-icon.png" alt="delete-icon" class="header-icon" ngbTooltip="Eliminar"
            placement="top" (click)="deleteKanbanItem(item)">
          <img *ngIf="!item.editable" src="assets/icons/edit-icon.png" alt="edit-icon" class="header-icon"
            ngbTooltip="Editar" placement="top" (click)="setEditMode(item)">
          <img *ngIf="item.editable" src="assets/icons/save-icon.png" alt="save-icon" class="header-icon"
            ngbTooltip="Guardar" placement="top" (click)="saveKanbanData(item)">
        </div>
      </div>
      <div [id]="item.id" class="dragula-container" [dragula]="container_name">
        <div *ngFor="let client of item.clients" [id]="client._id" class="client-card"
          [ngClass]="client.draggable ? '': 'not-draggable'" (click)="setClientValues(client)">
          <div class="client-header">
            <div class="client-title" (click)="redirectClient(client)">
              {{client.nombre || 'Sin nombre'}}
            </div>
            <img
              [src]="client.favorito === true ? 'assets/icons/favorite-icon-on.png': 'assets/icons/favorite-icon-off.png'"
              alt="favorite-icon" class="favorite-icon" ngbTooltip="Favorito" placement="top"
              (click)="setFavorite(client)">
          </div>

          <div class="client-description">
            {{client.notaImportante || ''}}
          </div>
        </div>
      </div>
    </div>
    <img *ngIf="kanbanData" src="assets/icons/add-icon.png" alt="add-icon" class="add-icon" ngbTooltip="Agregar bloque"
      placement="top" (click)="addKanbanBlock()">
  </div>
  <div class="item-info-container">
    <div class="tabs-container">
      <button id="data-tab" type="button" class="tab" (click)="setActiveTab('data-tab')">Datos</button>
      <button id="info-tab" type="button" class="tab" (click)="setActiveTab('info-tab')">Más información</button>
    </div>

    <ng-container *ngIf="activeTab === 'data-tab' && selectedClient">
      <label for="nombre">Nombre</label>
      <input id="nombre" type="text" [ngModel]="selectedClient.nombre" (blur)="updateClient()" disabled>

      <label for="descripcion">Nota importante</label>
      <textarea name="descripcion" id="descripcion" [(ngModel)]="selectedClient.notaImportante"
        (blur)="updateClient()"></textarea>
    </ng-container>

    <ng-container *ngIf="activeTab === 'info-tab' && selectedClient">
      <div class="client-info-container">
        <label for="phone">Telefono</label>
        <input name="phone" id="phone" [ngModel]="selectedClient.telefono" disabled />
      </div>
      <!-- <div class="client-info-container">
        <label for="email">Correo</label>
        <input name="email" id="email" [ngModel]="selectedClient.email" disabled />
      </div> -->
      <div class="client-info-container">
        <label for="country">País</label>
        <input name="country" id="country" [ngModel]="selectedClient.pais" disabled />
      </div>
      <!-- <div class="client-info-container">
        <label for="city">Ciudad</label>
        <input name="city" id="city" [ngModel]="selectedClient.ciudad" disabled />
      </div> -->
      <!-- <div class="client-info-container">
        <label for="address">Dirección</label>
        <input name="address" id="address" [ngModel]="selectedClient.direccion" disabled />
      </div> -->
      <!-- <div class="client-info-container">
        <label for="website">Sitio web</label>
        <input name="website" id="website" [ngModel]="selectedClient.website" disabled />
      </div> -->
      <!-- <div class="client-info-container">
        <label for="interest-product">Producto de interes</label>
        <input name="interest-product" id="interest-product" [ngModel]="selectedClient.productoInteres" disabled />
      </div> -->
      <!-- <div class="client-info-container">
        <label for="price-product">Precio producto</label>
        <input name="price-product" id="price-product" [ngModel]="selectedClient.precioProducto" disabled />
      </div> -->
      <!-- <div class="client-info-container">
        <label for="mid-interaction">Media interacción</label>
        <input name="mid-interaction" id="mid-interaction" [ngModel]="selectedClient.mediaInteraccion" disabled />
      </div> -->
      <!-- <div class="client-info-container">
        <label for="message-interaction">Mensaje primera interacción</label>
        <input name="message-interaction" id="message-interaction" [ngModel]="selectedClient.mensajeInteraccion"
          disabled />
      </div> -->
      <div class="client-info-container">
        <label for="date-interaction">Fecha primera interacción</label>
        <input name="date-interaction" id="date-interaction" [ngModel]="selectedClient.fechaInteraccion" disabled />
      </div>
      <!-- <div class="client-info-container">
        <label for="campaign">Campaña</label>
        <input name="campaign" id="campaign" [ngModel]="selectedClient.campania" disabled />
      </div> -->
      <div class="client-info-container">
        <label for="date-conversion">Fecha último mensaje</label>
        <input name="date-conversion" id="date-conversion" [ngModel]="selectedClient.fechaUltimoMensaje" disabled />
      </div>
    </ng-container>

    <ng-container *ngIf="!selectedClient">
      <h5 class="select-client-note">Haga click en la tarjeta de un cliente para ver su información</h5>
    </ng-container>
  </div>
</div>
<div *ngIf="restLoading" class="overlay">
    <app-loading-spinner></app-loading-spinner>
</div>
<section>
    <div class="signup-container">
        <a class="logotipo" [routerLink]="'/home'">
            <img src="assets/img/icons/logotipo.svg" alt="">
        </a>
        <div class="titulo">
            Ingresa a tu cuenta
        </div>
        <form [formGroup]="form" novalidate (ngSubmit)="save($event)" autocomplete="off">
            <input type="email" placeholder="Correo Electrónico" formControlName="email" autocomplete="off">
            <div *ngIf="form.get('email').errors && form.get('email').touched">
                <p *ngIf="form.get('email').hasError('required')" class="error">
                    Correo electrónico necesario.
                </p>
                <p *ngIf="form.get('email').hasError('email')" class="error">
                    Error, ingresa un correo electrónico válido.
                </p>
            </div>
            <input type="password" placeholder="Contraseña" formControlName="password" autocomplete="off">
            <div *ngIf="form.get('password').errors && form.get('password').touched">
                <p *ngIf="form.get('password').hasError('required')" class="error">
                    Contraseña necesaria.
                </p>
            </div>
            <div *ngIf="loginError" class="error" id="error-ci">
                Usuario o contraseña incorrectos, intenta de nuevo.
            </div>
            <!-- <div class="verify">
                <a>¿Olvidaste tu contraseña?</a>
            </div> -->
 
            <div class="crear-cuenta">
                <button type="submit">Iniciar Sesión</button>
            </div>
 
            <div class="verify">
                <span>¿no tienes cuenta?  <a class="regitro" [routerLink]="['/signup-email']">Registrate</a></span>
            </div>

        </form>
    </div>
</section>


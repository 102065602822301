<app-navbar [links_navbar]="links"></app-navbar>

<section  class="header-section">
    <div class="header-container">
        <div class="title" style="margin-top: 1rem; width: 50%;">
            Conoce nuestros servicios adicionales 
        </div>
        <div class="paragraph" style="margin-top: 1rem; width: 50%;">
            Mejora tus oportunidades de ventas, ofreciendo a tus clientes una experiencia de compra superior. 
        </div>
    </div>
</section>

<section class="main-section">
    <div class="subtitle">
        Sácale provecho a estos servicios 
    </div>
    <div class="main-container">
        <div class="services-container row">
            <div class="service col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngFor="let item of services">
                <div class="service-img">
                    <img src={{item.image}}>
                </div>
                <div class="service-name subtitles-2" style="margin-top: 1rem;">
                    {{item.name}}
                </div>
                <div class="service-description paragraph" style="margin-top: 1rem;">
                    {{item.description}}
                </div>
                <div class="service-button paragraph">
                    <a>Próximamente</a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer [links_footer]="links"></app-footer>

<div *ngIf="restLoading" class="overlay">
    <app-loading-spinner></app-loading-spinner>
</div>
<div class="chatbot-whatsapp-container">

    <div class="card-block" style="padding: 10px;">
        <h5 *ngIf="isLoading">Cargando información...</h5>

        <div class="header-buttons-container">
            <ng-container *ngIf="isLoading === false">
                <button class="btn btn-primary header-button" (click)="openQRModal()">Código
                    QR</button>
                <button class="btn btn-primary header-button" (click)="openConfigBotModal()">Configuración
                    Bot</button>
                <!-- <button class="btn btn-primary header-button" (click)="changeVersion()">Cambiar version: {{xpertVersion}}</button> -->
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="isLoading === false">
        <div *ngIf="xpertVersion === 'v1'" class="card templates-container">
            <div class="card-block">
                <div class="row">
                    <div class="col-sm-12 col-lg-4 col-md-6" *ngFor="let function of getPaginatedItems()">
                        <div class="card user-card">
                            <div class="template_card_block">
                                <div class="template-image-container" (click)="openCompanyModal(function.tipoFuncion)">
                                    <figure *ngIf="function?.imagen; else blockDefecto" class="html_prev">
                                        <img [src]='function.imagen' class="img-fluid img_prev">
                                    </figure>
                                    <ng-template #blockDefecto>
                                        <figure class="html_prev">
                                            <img src="assets/img/image-not-found.png" class="img-fluid img_prev">
                                        </figure>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="card-header card_menu_title">
                                <a class="template-name">
                                    <h5 (click)="openCompanyModal(function.tipoFuncion)">{{function.nombre}}</h5>
                                </a>
                                <div class="icons-container">
                                    <!-- <mat-slide-toggle [(ngModel)]="function.isChecked" [ngModelOptions]="{standalone: true}"
                                        color="primary" (change)="changeState($event, function)"></mat-slide-toggle> -->
                                    <!-- <div class="dropdown-secondary dropdown" ngbDropdown>
                                        <button ngbDropdownToggle
                                            class="btn-default btn-mini dropdown-toggle b-none txt-muted ripple"
                                            style="background-color: #FFFF;" type="button" id="dropdown1" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            <mat-icon matListIcon class="mat-icon-sm">more_vert</mat-icon>
                                        </button>
                                        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdown1"
                                            data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                                            <a class="dropdown-item ripple" (click)="openCampaignModal(false, campaign)"><i
                                                    class="icofont icofont-ui-copy"></i>
                                                Editar Campaña
                                            </a>
                                            <a class="dropdown-item ripple" (click)="deleteCampaign(campaign)"><i
                                                    class="icofont icofont-close-line"></i>
                                                Eliminar Campaña
                                            </a>
                                        </div>
                                    </div>
                                    <div class="eye-icon">
                                    </div> -->
                                </div>
                            </div>
                            <div class="card-description">
                                <span>{{function.descripcion}}</span>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="functions && functions.length > 0" class="row paginator-container">
                        <ul class="pagination">
                            <li class="page-item" [class.disabled]="currentPage === 1">
                                <a class="page-link" (click)="setPage(1)">Primera</a>
                            </li>
                            <li class="page-item" [class.disabled]="currentPage === 1">
                                <a class="page-link" (click)="setPage(currentPage - 1)">Anterior</a>
                            </li>
                            <li class="page-item" *ngFor="let page of visiblePages"
                                [class.active]="currentPage === page">
                                <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                            </li>
                            <li class="page-item" [class.disabled]="currentPage === totalPages?.length">
                                <a class="page-link" (click)="setPage(currentPage + 1)">Siguiente</a>
                            </li>
                            <li class="page-item" [class.disabled]="currentPage === totalPages?.length">
                                <a class="page-link" (click)="setPage(totalPages.length)">Última</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="xpertVersion === 'v2'" class="version-2-container">
            <div class="left-side">
                <div class="card stages-container">
                    <div class="card-block">
                        <h5>Información de etapas</h5>
                        <div class="stages">
                            <button *ngFor="let stage of stageChatbotData" type="button" class="stage-info-button"
                                [ngbTooltip]="stage.instruccion" placement="top">{{stage.estado_actual}}</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="card chat-container">
                    <div class="card-block">
                        <div class="chat-inputs-container">
                            <h5><strong>Etapa actual: </strong>{{currentStage}}</h5>
                            <div class="assitant-response">
                                <h6><strong>Chatbot</strong></h6>
                                <pre class="typewriter custom-pre">{{displayChatbotText}}</pre>
                            </div>
                            <div class="user-input">
                                <h6><strong>Usuario</strong></h6>
                                <textarea name="user-response" id="user-response"
                                    [(ngModel)]="chatgptInputMessage"></textarea>
                            </div>
                            <div class="chatbot-buttons-container">
                                <img *ngIf="isRecording === false" src="assets/icons/mic-inactive-icon.png"
                                    alt="mic-inactive-icon" class="chatbot-icon" ngbTooltip="Hablar por micrófono"
                                    placement="top" (click)="startRecording()">
                                <img *ngIf="isRecording === true" src="assets/icons/mic-active-icon.png"
                                    alt="mic-active-icon" class="chatbot-icon" ngbTooltip="Terminar grabación"
                                    placement="top" (click)="stopRecording()">
                                <button type="button" class="user-response-btn"
                                    (click)="sendChatGPTMessage()">Submit</button>
                                <button type="button" class="user-response-btn"
                                    style="margin: 0 10px; width: fit-content;" (click)="sendTestNotification()">Test
                                    notificacion asistente</button>

                            </div>
                            <audio *ngIf="audioUrl" [src]="audioUrl" controls
                                style="margin: 20px; width: 100%;"></audio>
                        </div>
                    </div>
                </div> -->
                <button type="button" class="stage-info-button" (click)="openModal('informacion-general-modal')"
                [ngbTooltip]="'nueva etapa'" placement="top">Información general</button>
                

                <div class="card state-table-container">
                    <div class="table-container">
                        <div class="mat-table data-table">
                            <div class="mat-header-row">
                                <!-- <div class="mat-header-cell flex-center">Número etapa</div> -->
                                <div class="mat-header-cell flex-center">Etapa</div>
                                <div class="mat-header-cell flex-center">Instrucción</div>
                                <div class="mat-header-cell flex-center">Instrucción Adicional</div>
                                <div class="mat-header-cell flex-center">Instrucción de Requerimiento</div>
                                <div class="mat-header-cell flex-center">Solicitud de información</div>
                                <div class="mat-header-cell flex-center">Transiciones</div>
                                <div class="mat-header-cell flex-center">Adjuntos</div>
                            </div>

                            <!-- {
                                "_id": "66eb4fa521ec61045350c701",
                                "estado_actual": "leads",
                                "parametros_solicitados": {},
                                "parametros_solicitados_completos": false,
                                "parametros_entregados": {},
                                "transiciones": [
                                    {
                                        "nuevo_estado": "Entrega de informacion del set",
                                        "actividad": "Interactuar",
                                        "target": "assistant",
                                        "condicion": "sin condiciones"
                                    }
                                ],
                                "identificadorEmpresa": "U65490445DBOT",
                                "adjuntos": {},
                                "instruccionDeUsuario": "",
                                "instruccionRequerimiento": "debe siempre pasar a la siguiente etapa, ya no debes volver a saludar",
                                "datosEmpresa": "",
                                "numero_etapa": 1,
                                "editable": {
                                    "instruccion": false,
                                    "ejemplo": false,
                                    "objetivo": false,
                                    "solicitudInfo": false
                                }
                            } -->

                            <div *ngFor="let item of stateData; let i = index" >
                                <div *ngIf="item.estado_actual != 'leadsm'" class="mat-row" [ngClass]="{ 'block-noUsed ': !uniqueStates.includes(item.estado_actual) }">
                                    <!-- <div class="mat-cell flex-center">{{item.numero_etapa}}</div> -->
                                    <div class="mat-cell flex-center estado" [ngClass]="{ 'text-noUsed ': (!uniqueStates.includes(item.estado_actual)&& item.estado_actual != 'leads') }">
                                        {{item.estado_actual}}
                                        <button *ngIf=" item.estado_actual != 'leads'" class="open-modal-btn" (click)="removeProcess(item)">Eliminar estado
                                            </button>
                                    </div>
                                    <div class="mat-cell flex-center">
                                        <span *ngIf="item.editable?.instruccionDeUsuario === false">{{item["instruccionDeUsuario"]}}</span>
                                        <img *ngIf="item.editable?.instruccionDeUsuario === false && item.estado_actual != 'leads'" src="assets/icons/edit-icon.png"
                                            alt="edit-icon" class="edit-icon" ngbTooltip="Editar" placement="top"
                                            (click)="toggleEditableColumn(item, 'instruccionDeUsuario')">
                                        <textarea *ngIf="item.editable?.instruccionDeUsuario === true " name="editable-column"
                                            class="editable-column" [(ngModel)]="item['instruccionDeUsuario']"></textarea>
                                        <img *ngIf="item.editable?.instruccionDeUsuario === true" src="assets/icons/cancel-icon.png"
                                            alt="cancel-icon" class="edit-icon" ngbTooltip="Cancelar" placement="top"
                                            (click)="cancelColumnUpdate(item, 'instruccionDeUsuario')">
                                        <img *ngIf="item.editable?.instruccionDeUsuario === true " src="assets/icons/save-icon.png"
                                            alt="save-icon" class="edit-icon" ngbTooltip="Guardar" placement="top"
                                            (click)="saveColumnUpdate(item, 'instruccionDeUsuario')">
                                    </div>
                                    <div class="mat-cell flex-center">
                                        <span *ngIf="item.editable?.instruccionAdicional === false">{{item.instruccionAdicional}}</span>
                                        <img *ngIf="item.editable?.instruccionAdicional === false && item.estado_actual != 'leads'" src="assets/icons/edit-icon.png"
                                            alt="edit-icon" class="edit-icon" ngbTooltip="Editar" placement="top"
                                            (click)="toggleEditableColumn(item, 'instruccionAdicional')">
                                        <textarea *ngIf="item.editable?.instruccionAdicional === true" name="editable-column"
                                            class="editable-column" [(ngModel)]="item['instruccionAdicional']"></textarea>
                                        <img *ngIf="item.editable?.instruccionAdicional === true" src="assets/icons/cancel-icon.png"
                                            alt="cancel-icon" class="edit-icon" ngbTooltip="Cancelar" placement="top"
                                            (click)="cancelColumnUpdate(item, 'instruccionAdicional')">
                                        <img *ngIf="item.editable?.instruccionAdicional === true" src="assets/icons/save-icon.png"
                                            alt="save-icon" class="edit-icon" ngbTooltip="Guardar" placement="top"
                                            (click)="saveColumnUpdate(item, 'instruccionAdicional')">
                                    </div>
                                    <div class="mat-cell flex-center">
                                        <span *ngIf="item.editable?.instruccionRequerimiento === false">{{item.instruccionRequerimiento}}</span>
                                        <img *ngIf="item.editable?.instruccionRequerimiento === false && item.estado_actual != 'leads'" src="assets/icons/edit-icon.png"
                                            alt="edit-icon" class="edit-icon" ngbTooltip="Editar" placement="top"
                                            (click)="toggleEditableColumn(item, 'instruccionRequerimiento')">
                                        <textarea *ngIf="item.editable?.instruccionRequerimiento === true" name="editable-column"
                                            class="editable-column" [(ngModel)]="item['instruccionRequerimiento']"></textarea>
                                        <img *ngIf="item.editable?.instruccionRequerimiento === true" src="assets/icons/cancel-icon.png"
                                            alt="cancel-icon" class="edit-icon" ngbTooltip="Cancelar" placement="top"
                                            (click)="cancelColumnUpdate(item, 'instruccionRequerimiento')">
                                        <img *ngIf="item.editable?.instruccionRequerimiento === true" src="assets/icons/save-icon.png"
                                            alt="save-icon" class="edit-icon" ngbTooltip="Guardar" placement="top"
                                            (click)="saveColumnUpdate(item, 'instruccionRequerimiento')">
                                    </div>
                                    <div class="mat-cell flex-center">
                                        <pre *ngIf="item.editable?.solicitudInfo === false" class="custom-pre">
                                            <!-- <ul>
                                                <li *ngFor="let key of objectKeys(item['parametros_solicitados'])">
                                                    <strong>{{ key }}</strong>: {{ item['parametros_solicitados'][key] }}
                                                </li>
                                            </ul> -->
                                            {{ item['parametros_solicitados']  }}
                                        </pre>
                                        
                                        <img *ngIf="item.editable?.solicitudInfo === false && item.estado_actual != 'leads'" src="assets/icons/edit-icon.png"
                                            alt="edit-icon" class="edit-icon" ngbTooltip="Editar" placement="top"
                                            (click)="toggleEditableColumn(item, 'solicitudInfo')">
                                        <textarea *ngIf="item.editable?.solicitudInfo === true" name="editable-column"
                                            class="editable-column"
                                            [(ngModel)]="item['parametros_solicitados']"></textarea>
                                        <img *ngIf="item.editable?.solicitudInfo === true" src="assets/icons/cancel-icon.png"
                                            alt="cancel-icon" class="edit-icon" ngbTooltip="Cancelar" placement="top"
                                            (click)="cancelColumnUpdate(item, 'solicitudInfo')">
                                        <img *ngIf="item.editable?.solicitudInfo === true" src="assets/icons/save-icon.png"
                                            alt="save-icon" class="edit-icon" ngbTooltip="Guardar" placement="top"
                                            (click)="saveColumnUpdate(item, 'solicitudInfo')">
                                    </div>
                                    <div class="mat-cell flex-center list-transicion">
                                        <ul *ngIf="item['transiciones'] && item['transiciones'].length > 0">
                                            <li *ngFor="let transicion of item['transiciones']">
                                                <strong>{{ transicion['nuevo_estado'] }}</strong> - {{ transicion['target'] }}
                                            </li>
                                        </ul>
                                        <button class="open-modal-btn" (click)="openTransitionModal(item)">Editar
                                            configuración</button>
                                    </div>
                                    <div  class="mat-cell flex-center">
                                        <button *ngIf=" item.estado_actual != 'leads'" class="open-modal-btn" (click)="openStateModal(item)">Editar
                                            adicionales</button>
                                    </div>
                                    <!-- <div class="mat-cell flex-center">
                                        <div class="p-1 actions flex-center">
                                            <button mat-mini-fab color="primary" matTooltip="View">
                                                <mat-icon>remove_red_eye</mat-icon>
                                            </button>
                                            <div class="btn-invoice" ngbTooltip="Ir a edición" placement="top">
                                                <img src="assets/icons/send-icon-black.png" alt="send-icon-black"
                                                    class="custom-icon">
                                            </div>
                                            <div class="btn-invoice" ngbTooltip="Mas información" placement="top">
                                                <img src="assets/icons/eye-icon-black.png" alt="eye-icon-black"
                                                    class="custom-icon">
                                            </div>
                                        </div>
                                    </div> -->

                                </div>

                            </div>
                        </div>
                        <!-- <div *ngIf="stateData && stateData.length > 0" class="row paginator-container">
                            <ul class="pagination">
                                <li class="page-item" [class.disabled]="currentPageStates === 1">
                                    <a class="page-link" (click)="setPageStates(1)">Primera</a>
                                </li>
                                <li class="page-item" [class.disabled]="currentPageStates === 1">
                                    <a class="page-link" (click)="setPageStates(currentPageStates - 1)">Anterior</a>
                                </li>
                                <li class="page-item" *ngFor="let page of visiblePagesStates"
                                    [class.active]="currentPageStates === page">
                                    <a class="page-link" (click)="setPageStates(page)">{{ page }}</a>
                                </li>
                                <li class="page-item" [class.disabled]="currentPageStates === totalPagesStates?.length">
                                    <a class="page-link" (click)="setPageStates(currentPageStates + 1)">Siguiente</a>
                                </li>
                                <li class="page-item" [class.disabled]="currentPageStates === totalPagesStates?.length">
                                    <a class="page-link" (click)="setPageStates(totalPagesStates.length)">Última</a>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                    <div class="container-button-add">
                        <div class="element-title">agregar: </div>
                        <button placement="right" ngbTooltip="Adicionar" type="button"
                            class="btn btn-inverse ripple light btn-plus" (click)="openModal('addProcess')">+</button>
                    </div>
                </div>
            </div>

            <!-- <div *ngIf="showAssistant === true" class="card right-side">
                <div class="card-block h-fill">
                    <div class="row">
                        <div class="assistant-header">
                            <div>
                                <img *ngIf="hasNewNotifications === false" src="assets/icons/bell-off-icon.png"
                                    alt="bell-off-icon" class="assistant-icon">
                                <img *ngIf="hasNewNotifications === true" src="assets/icons/bell-on-icon.png"
                                    alt="bell-on-icon" class="assistant-icon">
                            </div>
                            <h5 class="text-center">Asistente virtual</h5>
                            <button type="button" class="minimize-assistant" (click)="toggleAssistant()">-</button>
                        </div>
                        <div class="assitant-response">
                            <h6><strong>Chatbot</strong></h6>
                            <pre>Indicame la primera etapa 2</pre>
                        </div>
                        <div class="user-input">
                            <h6><strong>Usuario</strong></h6>
                            <textarea name="user-response" id="user-response"></textarea>
                        </div>
                        <button type="button" class="user-response-btn">Submit</button>
                    </div>
                </div>
            </div> -->

            <!-- <div *ngIf="showAssistant === false" class="minimized-assistant">
                <div class="assistant-header">
                    <div>
                        <img *ngIf="hasNewNotifications === false" src="assets/icons/bell-off-icon.png"
                            alt="bell-off-icon" class="assistant-icon">
                        <img *ngIf="hasNewNotifications === true" src="assets/icons/bell-on-icon.png" alt="bell-on-icon"
                            class="assistant-icon">
                    </div>
                    <h5 class="text-center">Asistente virtual</h5>
                    <button type="button" class="maximize-assistant" (click)="toggleAssistant()">+</button>
                </div>
            </div> -->

        </div>

    </ng-container>

</div>


<app-modal-animation [modalID]="'addProcess'" [modalClass]="'md-effect-1'">
    <h3>Nuevo proceso: </h3>
    <div>
        <div class="row" style="margin: 0 0.5em;">
            <!-- <div class="modal-header-container">
                <div class="element-title">Mensajes: </div>
                <button placement="right" ngbTooltip="Adicionar" type="button"
                    class="btn btn-inverse ripple light btn-plus" (click)="addStateItems()">+</button>
            </div> -->
            <div class="messages-container">
                <ul class="lista-elementos">
                    <li>
                        <label for="nuevoEstado">Tipo de acción:</label>
                        <select id="nuevoEstado" [(ngModel)]="nuevoProceso">
                            <option *ngFor="let estado of procesos" >{{estado}}</option>
                        </select>
                    </li>
                    <li *ngIf="nuevoProceso">
                        <label for="nombreEstado">Nuevo Estado:</label>
                        <input id="nombreEstado" type="text"  [(ngModel)]="nombreEstado"
                        />
                    </li>
                </ul>


            </div>
        </div>


        <div class="flex-evenly" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('addProcess')">
                Cancelar
            </button>
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#056867; width: 20%; margin-right: 15px;' (click)="createProcess()">
                Crear
            </button>
        </div>
    </div>
    <!--<button (click)="closeMyModal($event)" aria-label="Close" class="md-close-btn"><i class="icofont icofont-ui-close"></i></button>-->
</app-modal-animation>





<app-modal-animation [modalID]="'adicionalesModal'" [modalClass]="'md-effect-1'">
    <h3>Estado: {{selectedState?.estado_actual}}</h3>
    <div>
        <div class="row" style="margin: 0 0.5em;">
            <!-- <div class="modal-header-container">
                <div class="element-title">Mensajes: </div>
                <button placement="right" ngbTooltip="Adicionar" type="button"
                    class="btn btn-inverse ripple light btn-plus" (click)="addStateItems()">+</button>
            </div> -->
            <div class="messages-container">
                <!-- <ng-container *ngFor="let message of selectedState?.adjuntos; let i = index"> -->
                    <!-- <ng-container *ngIf="!message.isDelete"> -->
                        <div *ngIf="selectedState?.adjuntos" class="message-input-container">
                            <textarea [id]="'message-input-'" type="text" class="edit-modal-textarea"
                                [(ngModel)]="selectedState?.adjuntos.texto"></textarea>
                            <!-- <button placement="right" ngbTooltip="Eliminar" type="button"
                                class="btn btn-inverse ripple light btn-delete" (click)="removeStateItems(3)">-</button> -->
                        </div>
                        <div class="files-uploaders">
                            <div *ngIf="!selectedState?.adjuntos.video && !selectedState?.adjuntos.archivo" class="file-container">
                                <img id="state-image-{{selectedState?._id}}"
                                    [src]="selectedState?.adjuntos.imagen || 'assets/icons/upload-image-icon.png'"
                                    [ngClass]="selectedState?.adjuntos.imagen? 'filled-file': 'default-file'"
                                    alt="upload-image-icon" (click)="fileInputImage.click()">
                                <div *ngIf="selectedState?.adjuntos.imagen" class="icon-overlay" (click)="resetFileState('imagen')">
                                </div>
                                <input type="file" accept="image/*" #fileInputImage
                                    (change)="onFileSelectedState($event, selectedState)" style="display: none" />
                                <div class="subtitle" (click)="fileInputImage.click()">Subir Imagen</div>
                            </div>
                            <div *ngIf="!selectedState?.adjuntos.imagen && !selectedState?.adjuntos.archivo" class="file-container">
                                <img *ngIf="!selectedState?.adjuntos.video" id="state-video-{{i}}"
                                    [src]="'assets/icons/upload-video-icon.png'" class="default-file"
                                    alt="upload-video-icon" (click)="fileInputVideo.click()">
                                <video *ngIf="selectedState?.adjuntos.video" controls id="videoPlayerState{{selectedState?._id}}"
                                    class="uploaded-video-file"></video>
                                <div *ngIf="selectedState?.adjuntos.video" class="icon-overlay" (click)="resetFileState('video')"></div>
                                <input type="file" accept="video/*" #fileInputVideo
                                    (change)="onFileSelectedState($event, selectedState)" style="display: none" />
                                <div class="subtitle" (click)="fileInputVideo.click()" style="margin-top: 10px;">Subir
                                    Video</div>
                            </div>
                            <div *ngIf="!selectedState?.adjuntos.imagen && !selectedState?.adjuntos.video" class="file-container">
                                <img *ngIf="!selectedState?.adjuntos.archivo" id="state-file-{{i}}"
                                    [src]="'assets/icons/upload-file-icon.png'" class="default-file"
                                    alt="upload-file-icon" (click)="fileInputDocument.click()">
                                <img *ngIf="selectedState?.adjuntos.archivo" id="state-file-{{i}}"
                                    [src]="'assets/icons/uploaded-file-icon.png'" class="filled-file"
                                    alt="upload-file-icon" (click)="openFile(selectedState?.adjuntos.archivo)">
                                <div *ngIf="selectedState?.adjuntos.archivo" class="icon-overlay" (click)="resetFileState('archivo')">
                                </div>
                                <input type="file" accept=".xlsx, .xls, .xlsm .doc, .docx, .pdf" #fileInputDocument
                                    (change)="onFileSelectedState($event, selectedState)" style="display: none" />
                                <div *ngIf="!selectedState?.adjuntos.archivo" class="subtitle" style="margin-top: 10px;"
                                    (click)="fileInputDocument.click()">
                                    Subir Documento</div>
                                <div *ngIf="selectedState?.adjuntos.archivo" class="subtitle" style="margin-top: 10px;"
                                    (click)="fileInputDocument.click()">
                                    {{getFileName(selectedState?.adjuntos.archivo)}}</div>
                            </div>
                        </div>
                    <!-- </ng-container> -->
                <!-- </ng-container> -->
            </div>
        </div>


        <div class="flex-evenly" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('adicionalesModal')">
                Cancelar
            </button>
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#056867; width: 20%; margin-right: 15px;' (click)="updateAditionalStateData(selectedState)">
                Guardar
            </button>
        </div>
    </div>
    <!--<button (click)="closeMyModal($event)" aria-label="Close" class="md-close-btn"><i class="icofont icofont-ui-close"></i></button>-->
</app-modal-animation>


<app-modal-animation [modalID]="'Transiciones'" [modalClass]="'md-effect-1'">
    <h3>Estado: {{selectedState?.estado_actual}}</h3>
    <div>
        <div class="row" style="margin: 0 0.5em;">
            <div class="modal-header-container">
                <div class="element-title">Opciones: </div>
                <button placement="right" ngbTooltip="Adicionar" type="button"
                    class="btn btn-inverse ripple light btn-plus" (click)="addTransitionItems(selectedState)">+</button>
            </div>
            <div class="messages-container">
                <!-- <ng-container *ngFor="let message of selectedState?.adjuntos; let i = index"> -->
                    <!-- <ng-container *ngIf="!message.isDelete"> -->
                        <div *ngIf="selectedState?.transiciones" class="message-input-container">
 
                            <!-- Tabla para organizar los selectores -->
                            <table class="transiciones-table">
                                <tr *ngFor="let item of selectedState?.transiciones">
                                    <!-- Selector para "Nuevo Estado" -->
                                    <td>
                                        <label for="nuevoEstado">Nuevo Estado:</label>
                                        <select id="nuevoEstado" [(ngModel)]="item.nuevo_estado">
                                            <option *ngFor="let estado of estados" [value]="estado">{{estado}}</option>
                                        </select>
                                    </td>

                                    <!-- Selector para "Actividad" -->
                                    <td>
                                        <label for="actividad">Actividad:</label>
                                        <select id="actividad" [(ngModel)]="item.actividad">
                                            <option *ngFor="let actividad of actividades" [value]="actividad">{{actividad}}</option>
                                        </select>
                                    </td>

                                    <!-- Selector para "Target" -->
                                    <td>
                                        <label for="target">Target:</label>
                                        <select id="target" [(ngModel)]="item.target">
                                            <option *ngFor="let target of targets" [value]="target">{{target}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <button placement="right" ngbTooltip="Eliminar" type="button"
                                            class="btn btn-inverse ripple light btn-delete" (click)="removeTransitionItems(selectedState, item)">-</button>
                                    </td>

                                    <!-- Condición (campo de texto o selector según prefieras) -->
                                    <!-- <td>
                                        <label for="condicion">Condición:</label>
                                        <input id="condicion" type="text" [(ngModel)]="selectedState?.transiciones[0].condicion" />
                                    </td> -->
                                </tr>
                            </table>





                        </div>
                        <!-- <div class="files-uploaders"> -->
                            <!-- <div *ngIf="!selectedState?.adjuntos.video && !selectedState?.adjuntos.archivo" class="file-container">
                                <img id="state-image-{{i}}"
                                    [src]="stateItems[i].imagen || 'assets/icons/upload-image-icon.png'"
                                    [ngClass]="stateItems[i].imagen? 'filled-file': 'default-file'"
                                    alt="upload-image-icon" (click)="fileInputImage.click()">
                                <div *ngIf="stateItems[i].imagen" class="icon-overlay" (click)="resetFileState(i)">
                                </div>
                                <input type="file" accept="image/*" #fileInputImage
                                    (change)="onFileSelectedState($event, i)" style="display: none" />
                                <div class="subtitle" (click)="fileInputImage.click()">Subir Imagen</div>
                            </div> -->
                            <!-- <div *ngIf="!selectedState?.adjuntos.imagen && !selectedState?.adjuntos.archivo" class="file-container">
                                <img *ngIf="!stateItems[i].video" id="state-video-{{i}}"
                                    [src]="'assets/icons/upload-video-icon.png'" class="default-file"
                                    alt="upload-video-icon" (click)="fileInputVideo.click()">
                                <video *ngIf="stateItems[i].video" controls id="videoPlayerState{{i}}"
                                    class="uploaded-video-file"></video>
                                <div *ngIf="stateItems[i].video" class="icon-overlay" (click)="resetFileState(i)"></div>
                                <input type="file" accept="video/*" #fileInputVideo
                                    (change)="onFileSelectedState($event, i)" style="display: none" />
                                <div class="subtitle" (click)="fileInputVideo.click()" style="margin-top: 10px;">Subir
                                    Video</div>
                            </div> -->
                            <!-- <div *ngIf="!selectedState?.adjuntos.imagen && !selectedState?.adjuntos.video" class="file-container">
                                <img *ngIf="!stateItems[i].archivo" id="state-file-{{i}}"
                                    [src]="'assets/icons/upload-file-icon.png'" class="default-file"
                                    alt="upload-file-icon" (click)="fileInputDocument.click()">
                                <img *ngIf="stateItems[i].archivo" id="state-file-{{i}}"
                                    [src]="'assets/icons/uploaded-file-icon.png'" class="filled-file"
                                    alt="upload-file-icon" (click)="openFile(stateItems[i].archivo)">
                                <div *ngIf="stateItems[i].archivo" class="icon-overlay" (click)="resetFileState(i)">
                                </div>
                                <input type="file" accept=".xlsx, .xls, .xlsm .doc, .docx, .pdf" #fileInputDocument
                                    (change)="onFileSelectedState($event, i)" style="display: none" />
                                <div *ngIf="!stateItems[i].archivo" class="subtitle" style="margin-top: 10px;"
                                    (click)="fileInputDocument.click()">
                                    Subir Documento</div>
                                <div *ngIf="stateItems[i].archivo" class="subtitle" style="margin-top: 10px;"
                                    (click)="fileInputDocument.click()">
                                    {{getFileName(stateItems[i].archivo)}}</div> -->
                            <!-- </div> -->
                        <!-- </div> -->
                    <!-- </ng-container> -->
                <!-- </ng-container> -->
            </div>
        </div>


        <div class="flex-evenly" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('adicionalesModal')">
                Cancelar
            </button>
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#056867; width: 20%; margin-right: 15px;' (click)="updateAditionalTransitionData(selectedState)">
                Guardar
            </button>
        </div>
    </div>
    <!--<button (click)="closeMyModal($event)" aria-label="Close" class="md-close-btn"><i class="icofont icofont-ui-close"></i></button>-->
</app-modal-animation>



<app-modal-animation [modalID]="'qr-modal-content'" [modalClass]="'md-effect-1'">
    <h3>Código QR</h3>
    <div>
        <div class="row flex-center" style="margin: 0 0.5em;">
            <ng-container *ngIf="qrConnection === false && qrData && qrData !== 'cargando'">
                <div class="qr-circle-container">
                    <circle-progress [percent]="piePercentValue" [outerStrokeColor]="'#fdb900'"
                        [innerStrokeColor]="'#f9edc8'" [outerStrokeWidth]="32" [innerStrokeWidth]="16"
                        [animation]="true" [animationDuration]="pieAnimationDuration" [showTitle]="false" [units]="'%'"
                        [radius]="120" [showBackground]="false" [showTitle]="false" [showSubtitle]="false"
                        [showUnits]="false" [renderOnClick]="false">
                    </circle-progress>
                    <ngx-qrcode-styling [template]="'sky'" [image]="'assets/img/icono_ushops.png'" [width]="300"
                        [height]="300" [data]="qrData">
                    </ngx-qrcode-styling>
                </div>
            </ng-container>
            <ng-container *ngIf="qrData && qrData === 'cargando'">
                <h4>Cargando código QR</h4>
            </ng-container>
            <ng-container *ngIf="qrData && qrData !== 'cargando' &&  qrConnection === true">
                <h4>El usuario ya se encuentra conectado</h4>
            </ng-container>
            <ng-container *ngIf="!qrData">
                <h4>Ha ocurrido un error al cargar el codigo QR</h4>
            </ng-container>
        </div>

        <div class="flex-between" style="margin-top: 1em;">
            <button type="button" class="btn btn-danger ripple light footer-btn" style='width: 20%;'
                (click)="closeModal('qr-modal-content')">
                Cerrar
            </button>
            <button type="button" class="btn btn-inverse ripple light footer-btn btn-plus" style='width: 20%;'
                (click)="logoutQR()">
                Desconectarse
            </button>
        </div>
    </div>
</app-modal-animation>

<app-modal-animation [modalID]="'config-bot-modal'" [modalClass]="'md-effect-1'">
    <h3>Configuración del Asistente</h3>
    <div>
        <div class="row" style="margin: 0 0.5em;" class="body-configuration">
            <div class="config-container col-lg-6">
                <div class="config-bot-item">
                    <label class="col-form-label"><strong>Nombre: </strong></label>
                    <input type="text" class="form-control" autocomplete="nope" [(ngModel)]="botConfiguration.nombre"
                        [ngModelOptions]="{standalone: true}" (keyup.enter)="guardarConfiguracionBot()"
                        (blur)="guardarConfiguracionBot()">
                </div>
                <div class="config-bot-item">
                    <label class="col-form-label"><strong>Personalidad definida: </strong></label>
                    <input type="text" class="form-control" autocomplete="nope" [(ngModel)]="botConfiguration.personalidad"
                        [ngModelOptions]="{standalone: true}" (keyup.enter)="guardarConfiguracionBot()"
                        (blur)="guardarConfiguracionBot()">
                </div>
                <!-- <div class="config-bot-item"> -->
                    <!-- <label class="col-form-label"><strong>Personalidad: </strong></label>
                    <select class="form-select" aria-label="Default select example" (change)="guardarConfiguracionBot()"
                        [(ngModel)]="botConfiguration.personalidad" [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let option of botConfigPersonalities; index as i" value="{{option.value}}"
                            [selected]="botConfiguration.personalidad === option.value">{{option.label}}</option>
                    </select> -->
                    <!-- <mat-form-field appearance="legacy" class="bot-config-selector">
                        <mat-label>Seleccionar</mat-label>
                        <mat-select name="personalidad" [(ngModel)]="botConfiguration.personalidad"
                            [ngModelOptions]="{standalone: true}">

                            <mat-option *ngFor="let option of botConfigPersonalities; index as i"
                                value="{{option.value}}"
                                (click)="guardarConfiguracionBot()">{{option.label}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                <!-- </div> -->
                <div class="config-bot-item">
                    <label class="col-form-label"><strong>Instrucciones: </strong></label>
                    <textarea type="text" class="form-control textarea-min-size" autocomplete="nope" [(ngModel)]="botConfiguration.instrucciones"
                        [ngModelOptions]="{standalone: true}" (keyup.enter)="guardarConfiguracionBot()" (blur)="guardarConfiguracionBot()">
                        </textarea>
                </div>
                <!-- <div class="config-bot-item">
                    <label class="col-form-label"><strong>Tipo: </strong></label>
                    <mat-form-field appearance="legacy" class="bot-config-selector">
                        <mat-select name="tipo" [(ngModel)]="botConfiguration.tipo" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let option of botConfigTypes; index as i"
                                value="{{option.value}}" (click)="guardarConfiguracionBot()">{{option.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->

                <!-- <div class="config-bot-item">
                    <label class="col-form-label"><strong>Idioma: </strong></label>
                    <mat-form-field appearance="legacy" class="bot-config-selector">
                        <mat-select name="personalidad" [(ngModel)]="botConfiguration.idioma" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let option of botConfigLanguage; index as i"
                                value="{{option.value}}" (click)="guardarConfiguracionBot()">{{option.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <!-- <div class="config-bot-item">
                    <label class="col-form-label"><strong>Key: </strong></label>
                    <input type="text" class="form-control" autocomplete="nope" [(ngModel)]="botConfiguration.key"
                        [ngModelOptions]="{standalone: true}" (keyup.enter)="guardarConfiguracionBot()" (blur)="guardarConfiguracionBot()">
                </div> -->
            </div>
        </div>

        <div class="flex-center" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('config-bot-modal')">
                Cerrar
            </button>
            <!-- <button type="button" class="btn btn-inverse ripple light footer-btn btn-plus" style='width: 20%;'
                (click)="guardarConfiguracionBot()">
                Guardar
            </button> -->
        </div>
    </div>
</app-modal-animation>

<app-modal-animation [modalID]="'informacion-general-modal'" [modalClass]="'md-effect-1'">
    <h3>Información general del negocio</h3>
    <div>
        <div class="row" style="margin: 0 0.5em;" class="body-configuration">
            <div class="config-container col-12 col-lg-6">
                <div class="config-bot-item">
                    <label class="col-form-label"><strong>Información general: </strong></label>
                    <textarea type="text" class="form-control text-info-general" autocomplete="nope" [(ngModel)]="informacionGeneral"
                        [ngModelOptions]="{standalone: true}" 
                        (blur)="guardarInformacionGeneral()"> </textarea>
                </div>
                <div class="config-bot-item">
                    <!-- <label class="col-form-label"><strong>Personalidad: </strong></label>
                    <select class="form-select" aria-label="Default select example" (change)="guardarConfiguracionBot()"
                        [(ngModel)]="botConfiguration.personalidad" [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let option of botConfigPersonalities; index as i" value="{{option.value}}"
                            [selected]="botConfiguration.personalidad === option.value">{{option.label}}</option>
                    </select> -->
                    <!-- <mat-form-field appearance="legacy" class="bot-config-selector">
                        <mat-label>Seleccionar</mat-label>
                        <mat-select name="personalidad" [(ngModel)]="botConfiguration.personalidad"
                            [ngModelOptions]="{standalone: true}">

                            <mat-option *ngFor="let option of botConfigPersonalities; index as i"
                                value="{{option.value}}"
                                (click)="guardarConfiguracionBot()">{{option.label}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                </div>
                <!-- <div class="config-bot-item">
                    <label class="col-form-label"><strong>Instrucciones: </strong></label>
                    <textarea type="text" class="form-control textarea-min-size" autocomplete="nope" [(ngModel)]="botConfiguration.instrucciones"
                        [ngModelOptions]="{standalone: true}" (keyup.enter)="guardarConfiguracionBot()" (blur)="guardarConfiguracionBot()">
                        </textarea>
                </div> -->
                <!-- <div class="config-bot-item">
                    <label class="col-form-label"><strong>Tipo: </strong></label>
                    <mat-form-field appearance="legacy" class="bot-config-selector">
                        <mat-select name="tipo" [(ngModel)]="botConfiguration.tipo" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let option of botConfigTypes; index as i"
                                value="{{option.value}}" (click)="guardarConfiguracionBot()">{{option.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->

                <!-- <div class="config-bot-item">
                    <label class="col-form-label"><strong>Idioma: </strong></label>
                    <mat-form-field appearance="legacy" class="bot-config-selector">
                        <mat-select name="personalidad" [(ngModel)]="botConfiguration.idioma" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let option of botConfigLanguage; index as i"
                                value="{{option.value}}" (click)="guardarConfiguracionBot()">{{option.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <!-- <div class="config-bot-item">
                    <label class="col-form-label"><strong>Key: </strong></label>
                    <input type="text" class="form-control" autocomplete="nope" [(ngModel)]="botConfiguration.key"
                        [ngModelOptions]="{standalone: true}" (keyup.enter)="guardarConfiguracionBot()" (blur)="guardarConfiguracionBot()">
                </div> -->
            </div>
        </div>

        <div class="flex-center" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('config-bot-modal')">
                Cerrar
            </button>
            <!-- <button type="button" class="btn btn-inverse ripple light footer-btn btn-plus" style='width: 20%;'
                (click)="guardarConfiguracionBot()">
                Guardar
            </button> -->
        </div>
    </div>
</app-modal-animation>

<app-modal-animation [modalID]="'company-data-modal'" [modalClass]="'md-effect-1'">
    <h3>Información de la empresa</h3>
    <div>
        <div class="col-sm-12">
            <div class="row" style="margin: 0 0.5em;">
                <button type="button" class="btn btn-inverse ripple light btn-plus" (click)="addParameter()">Agregar
                    campo</button>
                <div *ngFor="let parameter of companyParameters; index as i" class="col-sm-12 form-element">
                    <div class="parameter-container">
                        <label class="col-form-label"><strong>Palabra clave: (max 20 char)</strong></label>
                        <input type="text" class="form-control" autocomplete="nope" appAlphaNumeric maxlength="20"
                            [(ngModel)]="parameter.parametro" [ngModelOptions]="{standalone: true}">
                        <button placement="top" ngbTooltip="Eliminar" type="button"
                            class="btn btn-inverse ripple light btn-delete" (click)="removeParameter(i)">-</button>
                    </div>
                    <div class="message-container">
                        <textarea name="parameter-value" id="parameter-value-{{i}}"
                            class="form-control parameter-text-area" [(ngModel)]="parameter.mensaje[0].texto"
                            (ngModelChange)="parameterChaged(i)" [ngModelOptions]="{standalone: true}"></textarea>
                        <div class="files-uploaders-company">
                            <div *ngIf="!checkIsVideoCompany(i) && !checkIsFileCompany(i)" class="file-container">
                                <img *ngIf="!checkIsImageCompany(i)" id="company-message-image-{{i}}"
                                    [src]="'assets/icons/upload-image-icon.png'" class="default-file"
                                    alt="upload-image-icon" (click)="fileInputImageCompany.click()">
                                <img *ngIf="checkIsImageCompany(i)" id="company-message-{{i}}"
                                    [src]="parameter.mensaje[0].options.media" class="filled-file"
                                    alt="upload-image-icon" (click)="fileInputImageCompany.click()">
                                <div *ngIf="checkIsImageCompany(i)" class="icon-overlay" (click)="resetFileCompany(i)">
                                </div>
                                <input type="file" accept="image/*" #fileInputImageCompany
                                    (change)="onFileSelectedCompany($event, i)" style="display: none" />
                                <div class="subtitle" (click)="fileInputImageCompany.click()">Subir Imagen</div>
                            </div>
                            <div *ngIf="!checkIsImageCompany(i) && !checkIsFileCompany(i)" class="file-container">
                                <img *ngIf="!checkIsVideoCompany(i)" id="block-video-{{i}}"
                                    [src]="'assets/icons/upload-video-icon.png'" class="default-file"
                                    alt="upload-video-icon" (click)="fileInputVideoCompany.click()">
                                <video *ngIf="checkIsVideoCompany(i)" controls id="videoPlayerCompany{{i}}"
                                    class="uploaded-video-file"></video>
                                <div *ngIf="checkIsVideoCompany(i)" class="icon-overlay" (click)="resetFileCompany(i)">
                                </div>
                                <input type="file" accept="video/*" #fileInputVideoCompany
                                    (change)="onFileSelectedCompany($event, i)" style="display: none" />
                                <div class="subtitle" (click)="fileInputVideoCompany.click()" style="margin-top: 10px;">
                                    Subir
                                    Video</div>
                            </div>
                            <div *ngIf="!checkIsImageCompany(i) && !checkIsVideoCompany(i)" class="file-container">
                                <img *ngIf="!checkIsFileCompany(i)" id="message-file-{{i}}"
                                    [src]="'assets/icons/upload-file-icon.png'" class="default-file"
                                    alt="upload-file-icon" (click)="fileInputDocumentCompany.click()">
                                <img *ngIf="checkIsFileCompany(i)" id="message-file-{{i}}"
                                    src="assets/icons/uploaded-file-icon.png" class="filled-file"
                                    alt="uploaded-file-icon" (click)="openFile(parameter.mensaje[0].options.media)">
                                <div *ngIf="checkIsFileCompany(i)" class="icon-overlay" (click)="resetFileCompany(i)">
                                </div>
                                <input type="file" accept=".xlsx, .xls, .xlsm .doc, .docx, .pdf"
                                    #fileInputDocumentCompany (change)="onFileSelectedCompany($event, i)"
                                    style="display: none" />
                                <div *ngIf="!checkIsFileCompany(i)" class="subtitle" style="margin-top: 10px;"
                                    (click)="fileInputDocumentCompany.click()">
                                    Subir Documento</div>
                                <div *ngIf="checkIsFileCompany(i)" class="subtitle" style="margin-top: 10px;"
                                    (click)="fileInputDocumentCompany.click()">
                                    {{getFileName(parameter.mensaje[0].options.media)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-between" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('company-data-modal')">
                Cerrar
            </button>
            <button type="button" class="btn btn-inverse ripple light footer-btn btn-plus" style='width: 20%;'
                (click)="guardarDatosEmpresa()">
                Guardar
            </button>
        </div>
    </div>
</app-modal-animation>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatbotConfigurationComponent } from './chatbot-configuration/chatbot-configuration.component';
import { MassMessagingComponent } from './mass-messaging/mass-messaging.component';
import { WhatsappAdminUsersComponent } from './whatsapp-admin-users/whatsapp-admin-users.component';
import { WhatsappUserChatComponent } from './whatsapp-user-chat/whatsapp-user-chat.component';
import { WhatsappCampaignComponent } from './whatsapp-campaign/whatsapp-campaign.component';
import { SettingsComponent } from './settings/settings.component';
import { PaymentComponent } from './payment/payment.component';
import { KanbanVentasComponent } from './kanban-ventas/kanban-ventas.component';
import { EventsCalendarComponent } from './events-calendar/events-calendar.component';
import { ClientInfoComponent } from './client-info/client-info.component';
import { AdminClientsComponent } from './admin-clients/admin-clients.component';
import { ModulesGuard } from '../shared/guards/modules.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { ScheduleAppointmentComponent } from './schedule-appointment/schedule-appointment.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/backoffice/chatbot-configuration',
        pathMatch: 'full'
    },
    {
        path: 'kanban-ventas',
        component: KanbanVentasComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'admin-clients',
        component: AdminClientsComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'client-info',
        component: ClientInfoComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'events-calendar',
        component: EventsCalendarComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'chatbot-configuration',
        component: ChatbotConfigurationComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'mass-messaging',
        component: MassMessagingComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'admin-users',
        component: WhatsappAdminUsersComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'whatsapp-user-chat',
        component: WhatsappUserChatComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'whatsapp-campaign',
        component: WhatsappCampaignComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [ModulesGuard]
    },
    {
        path: 'payment',
        component: PaymentComponent
    },
    // { path: '**', redirectTo: '/home' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BackofficeRoutingModule { }

<div *ngIf="restLoading" class="overlay">
    <app-loading-spinner></app-loading-spinner>
</div>
<section>
    <div class="signup-container">
        <a class="logotipo" [routerLink]="'/home'">
            <img src="assets/img/logos/logoXpert.png" alt="">
        </a>
        <div class="titulo">
            ¡Bienvenido a uShops!
        </div>
        <div class="subtitulo">
            Regístrate y se parte del mundo de la Inteligencia Artificial
        </div>
        <form [formGroup]="form" novalidate (ngSubmit)="save($event)">
            <input type="text" placeholder="Nombre" formControlName="nombre">
            <div *ngIf="form.get('nombre').errors  && form.get('nombre').touched">
                <p *ngIf="form.get('nombre').hasError('required')" class="error">
                    Es un campo requerido
                </p>
            </div>
            <input type="text" placeholder="Apellido" formControlName="apellido">
            <div *ngIf="form.get('apellido').errors  && form.get('apellido').touched">
                <p *ngIf="form.get('apellido').hasError('required')" class="error">
                    Es un campo requerido
                </p>
            </div>

            <input type="text" placeholder="crea tu uName" formControlName="uname">
            <div *ngIf="form.get('uname').errors  && form.get('uname').touched">
                <p *ngIf="form.get('uname').hasError('required')" class="error">
                    Es un campo requerido
                </p>
            </div>

            <!-- <input type="text" placeholder="Cédula de identidad" formControlName="cedula">
            <div *ngIf="form.get('cedula').errors  && form.get('cedula').touched">
                <p *ngIf="form.get('cedula').hasError('required')" class="error">
                    Es un campo requerido
                </p>
                <p *ngIf="form.get('cedula').hasError('maxlength') || form.get('cedula').hasError('minlength')" class="error">
                    Ingresa un número de cédula válido.
                </p>
            </div> -->
            <input type="email" placeholder="Correo electrónico" formControlName="email">
            <div *ngIf="form.get('email').errors  && form.get('email').touched">
                <p *ngIf="form.get('email').hasError('required')" class="error">
                    Es un campo requerido.
                </p>
                <p *ngIf="form.get('email').hasError('email')" class="error">
                    Incorrecto, ingresa un correo electrónico válido.
                </p>
            </div>
            <!-- <input type="text" placeholder="Teléfono (con código del país)" formControlName="telefono">
            <div *ngIf="form.get('telefono').errors  && form.get('telefono').touched">
                <p *ngIf="form.get('telefono').hasError('required')" class="error">
                    Es un campo requerido.
                </p>
                <p *ngIf="form.get('telefono').hasError('maxlength')" class="error">
                    Ingresa un número de teléfono válido
                </p>
            </div> -->
            <!-- <input type="text" placeholder="Cuéntanos de tu negocio" formControlName="mensaje">
            <div *ngIf="form.get('mensaje').errors  && form.get('mensaje').touched">
                <p *ngIf="form.get('mensaje').hasError('required')" class="error">
                    Es un campo requerido.
                </p>
            </div> -->
            <!--
            <div class="verify">
                <input type="checkbox"> <p>Acepto las condiciones de uso y política de privacidad.</p>
            </div>
            -->
            <div class="crear-cuenta">
                <button type="submit">Registro</button>
            </div>
            <div class="verify">
                <span>¿Tienes cuenta?  <a class="login" [routerLink]="['/login']">Accede</a></span>
            </div>

        </form>
    </div>
    <p *ngFor="let alert of alerts" class="alert">
        <ngb-alert [type]="alert.type" (close)="close(alert)">{{ alert.message }}</ngb-alert>
    </p>
</section>


<app-modal-animation [modalID]="'register-success-modal'" [modalClass]="'md-effect-1'" [backDrop]="true">
    <h3>Registro exitoso!</h3>
    <div>
        <div class="row" style="margin: 0 0.5em;" class="register-success-body">
            El código de verificacion ha sido enviado a tu enviado a tu email!
        </div>

        <div class="flex-center" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('register-success-modal')">
                Cerrar
            </button>
            <!-- <button type="button" class="btn btn-inverse ripple light footer-btn btn-plus" style='width: 20%;'
                (click)="guardarConfiguracionBot()">
                Guardar
            </button> -->
        </div>
    </div>
</app-modal-animation>

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ApiPagosService } from '../services/api-pagos.service';
import { SocketIoService } from '../services/socket-io.service';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss']
})
export class BackofficeComponent implements OnInit {

  private xpertVersion = this.auth.getVersionSystem()
  
  constructor(
    private auth: AuthService,
    private router: Router,
    private apiPagos: ApiPagosService,
    private api: ApiService,
    private socketService: SocketIoService,
    private sharedService: SharedDataService
  ) { }

  ngOnInit(): void {

    console.log('reloadddddddddddddddddddddddddddddddddddddddddddddddd');


    if(this.xpertVersion == 'v2'){

        this.socketService.listen('receivedData').subscribe((data: any) => {
          // console.log('Mensaje recibido:', data);
          this.sharedService.changeData({
            event: 'receivedData',
            data: data
          });
        });

        console.log('solicitando datos')
        this.socketService.emit('getData', { "requirement": "tablaInstrucciones" });
    }


    // let body = {


    // }

    // this.apiPagos.obtenerSuscripcion(body).subscribe(
    //   (result:[any])=>{

    //     console.log(result);

    //     if(result.length && result[0].estadoSuscripcion == "procesado"){

    //       this.auth.setHabilitacionSystem('true')
    //     } else {
    //       this.auth.setHabilitacionSystem('false')
    //     }





    //   }, error=>{

    //     this.auth.setHabilitacionSystem('false')

    //   }
    // )


    this.api.getUsuarioParameters({ scope: 'xpert-platform-jtsFasd52' }).subscribe(
      resultParams => {
        // this.restLoading = false;
        console.log('resultado login 5: ', resultParams);

        if (resultParams.suscripcion) this.auth.setSuscriptionName(resultParams.suscripcion);

        // if (resultParams['identificador']) {
        //   this.auth.setIdentificador(resultParams['identificador']);
        // }

        // this.snackBar.open('Ingreso exitoso!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
        // this.auth.setNombres(result['datos']['nombres']);
        // this.auth.setApellidos(result['datos']['apellidos']);

        // this.auth.setEmail(result['datos']['email']);
        // this.auth.setAuth();

        console.log('akisss')
        console.log(resultParams['empresa'])


        if (resultParams.estadoSuscripcion == "procesado") {
          this.auth.setHabilitacionSystem('true')
        } else {
          this.auth.setHabilitacionSystem('false')
        }

        if (!resultParams.version) {
          this.auth.setVersionSystem('v1')
        } else {
          this.auth.setVersionSystem(resultParams.version)
        }

        // if (resultParams['empresa']) {
        //   console.log(resultParams['empresa']['estadoTienda'])
        //   if (resultParams['empresa']['estadoTienda'] === 'habilitado') {
        //     this.auth.setHabilitacionTienda('true');
        //     // this.appService.usuario.habilitacionTienda = true;
        //   } else if (resultParams['empresa']['estadoTienda'] === 'solicitud') {
        //     this.auth.setHabilitacionTienda('solicitud');
        //     // this.appService.usuario.habilitacionTienda = false;

        //   } else {
        //     console.log('akiii noooo')
        //     this.auth.setHabilitacionTienda('false');
        //     // this.appService.usuario.habilitacionTienda = false;
        //   }
        // } else {
        //   this.auth.setHabilitacionTienda('false');
        //   // this.appService.usuario.habilitacionTienda = false;
        // }



        // if (resultParams['bot']) {
        //   if (resultParams['bot']['estadoBot'] === 'habilitado') {
        //     this.auth.setHabilitacionBot('true');
        //     // this.appService.usuario.habilitacionBot = true;
        //   } else if (resultParams['bot']['estadoBot'] === 'solicitud') {
        //     this.auth.setHabilitacionBot('solicitud');
        //     // this.appService.usuario.habilitacionBot = false;
        //   } else {
        //     this.auth.setHabilitacionBot('false');
        //     // this.appService.usuario.habilitacionBot = false;
        //   }
        // } else {
        //   this.auth.setHabilitacionBot('false');
        //   // this.appService.usuario.habilitacionBot = false;
        // }


        return;





      }, error => {
        // this.restLoading = false;
        this.auth.delAuth();
        // this.appService.usuario.autenticado = false;
        // this.appService.usuario.usuarioID = null;
        // this.appService.usuario.username = '';
        // this.appService.usuario.habilitacionTienda = false;
        this.auth.clearParameters();

        this.auth.setHabilitacionBot('false');
        this.auth.setHabilitacionTienda('false');

        // this.auth.setToken('null')
        // this.preloaderLoginForm = false; 
        // this.snackBar.open('error desconocido!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    )



  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientsService } from 'src/app/services/clients.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-admin-clients',
  templateUrl: './admin-clients.component.html',
  styleUrls: ['./admin-clients.component.scss']
})

export class AdminClientsComponent implements OnInit {

  //Paginador
  currentPage = 1; // Página actual
  maxVisiblePages = 10; // Número máximo de páginas visibles
  pageSize = 30; // Tamaño de página
  visiblePages: number[] = []; // Páginas visibles en el paginador
  totalPages: number[]; // Total de páginas

  selectedClient: any;
  clients: any = [];
  companyData: any;

  searchInput: string;

  constructor(
    private toastr: ToastrService,
    private apiService: ClientsService,
    private router: Router) { }

  ngOnInit(): void {
    this.getCompanyData().then(
      result => {
        this.getClients();
      }, error => {
        console.log('error: ', error);
      }
    )
  }

  openClientModal(item: any) {
    this.selectedClient = item;
    document.querySelector('#client-modal').classList.add('md-show');
  }

  downloadExcel() {
    let filename = 'Clientes', data: any = [];

    for (let i = 0; i < this.clients.length; i++) {
      data.push({ nombre: this.clients[i].nombre, numero: this.clients[i].telefono })
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, filename);
  }

  private saveAsExcelFile(buffer: any, filename: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, `${filename}_export_${new Date().getTime()}.xlsx`);
  }

  getClients() {
    let body: any = {};
    body.mode = 'all'

    if (this.searchInput) body.searchText = this.searchInput;

    if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
      this.apiService.obtenerListaClientes(body).subscribe({
        next: (result: any) => {
          console.log('clientes: ', result);
          this.clients = result;
          this.updatePagination();
        },
        error: (error) => {
          console.log('Error al cargar clientes: ', error);
          this.toastr.error('Ha ocurrido un error al cargar la lista de clientes.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      })
    } else {
      this.apiService.obtenerClientesSentinel(body).subscribe({
        next: (result: any) => {
          console.log('clientes: ', result);
          this.clients = result;
          this.updatePagination();
        },
        error: (error) => {
          console.log('Error al cargar clientes: ', error);
          this.toastr.error('Ha ocurrido un error al cargar la lista de clientes.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      })
    }

  }

  getCompanyData() {
    let body: any = {};

    return new Promise((resolve, reject) => {
      this.apiService.obtenerDatosBot(body).subscribe({
        next: (result: any) => {
          // console.log('result', result);
          this.companyData = result;
          return resolve(result);
        },
        error: (error) => {
          console.log('Error al actualizar cliente: ', error);
          this.toastr.error('Ha ocurrido un error al obtener informacion de sentinel.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          return reject(error);
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      });
    });
  }

  getPaginatedItems(): any[] {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.clients.slice(startIndex, endIndex);
  }

  setPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.updatePagination();
  }

  updatePagination() {
    let totalItems = this.clients.length;
    const totalNumPages = Math.ceil(totalItems / this.pageSize);
    this.totalPages = Array.from({ length: totalNumPages }, (_, index) => index + 1);

    // Lógica para determinar las páginas visibles en el paginador
    const middlePage = Math.ceil(this.maxVisiblePages / 2);
    let startPage = this.currentPage - middlePage + 1;
    let endPage = this.currentPage + middlePage - 1;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(this.maxVisiblePages, totalNumPages);
    }

    if (endPage > totalNumPages) {
      endPage = totalNumPages;
      startPage = Math.max(1, endPage - this.maxVisiblePages + 1);
    }

    this.visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => index + startPage);
  }

  redirectClient(item) {
    this.router.navigate(['/backoffice/client-info'], { queryParams: { id: item._id } });
  }

  closeModal(name: string) {
    document.querySelector('#' + name).classList.remove('md-show');
  }

}

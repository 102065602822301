





<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1088 953.6" style="enable-background:new 0 0 1088 953.6;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#9ABEA4;}
	.st1{fill:#BB9253;}
	.st2{fill:#BFB075;}
	.st3{fill:#E39C48;}
	.st4{fill:#D29649;}
	.st5{fill:#D6A159;}
	.st6{fill:#3DC5DA;}
	.st7{fill:#2EA6B7;}
	.st8{fill:#E19653;}
	.st9{fill:#E39556;}
	.st10{fill:#E2A556;}
	.st11{fill:#DA9A59;}
	.st12{fill:#E69D53;}
	.st13{fill:#D99966;}
	.st14{fill:#E0A351;}
	.st15{fill:#D9964B;}
	.st16{fill:#E4A655;}
	.st17{fill:#E2A555;}
	.st18{fill:#E89A55;}
	.st19{fill:#E2A359;}
	.st20{fill:#E79A50;}
	.st21{fill:#DF9C5E;}
	.st22{fill:#EF9E48;}
	.st23{fill:#E1B470;}
	.st24{fill:#E1A857;}
	.st25{fill:#ED984D;}
	.st26{fill:#E29952;}
	.st27{fill:#E8B870;}
	.st28{fill:#B4969C;}
	.st29{fill:#C5967F;}
	.st30{fill:#F7F3E9;}
	.st31{fill:#FAF0D9;}
	.st32{fill:#B89C9D;}
	.st33{fill:#F8C898;}
	.st34{fill:#DAA780;}
	.st35{fill:#B89EA7;}
	.st36{fill:#F9F4ED;}
	.st37{fill:#DDC0B6;}
	.st38{fill:#B9A1AF;}
	.st39{fill:#FADFC1;}
	.st40{fill:#B29BA5;}
	.st41{fill:#B69AA6;}
	.st42{fill:#B698A0;}
	.st43{fill:#B59EAB;}
	.st44{fill:#B39CA8;}
	.st45{fill:#F6F8F7;}
	.st46{fill:#BAA6B1;}
	.st47{fill:#B59DA1;}
	.st48{fill:#B999AB;}
	.st49{fill:#DECBCA;}
	.st50{fill:#FDE5D2;}
	.st51{fill:#BBA2AC;}
	.st52{fill:#B7A1AC;}
	.st53{fill:#AF9EB6;}
	.st54{fill:#D0C2C9;}
	.st55{fill:#C2A9B5;}
	.st56{fill:#F8EBEB;}
	.st57{fill:#D7C2C4;}
	.st58{fill:#BBA3AD;}
	.st59{fill:#BA9DA7;}
	.st60{fill:#C69EA0;}
	.st61{fill:#A24E3C;}
	.st62{fill:#D3A4A1;}
	.st63{fill:#AB9BAD;}
	.st64{fill:#9EA9C0;}
	.st65{fill:#174D75;}
	.st66{fill:#163C65;}
	.st67{fill:#263659;}
	.st68{fill:#99B4CA;}
	.st69{fill:#97B0D5;}
	.st70{fill:#093249;}
	.st71{fill:#98B5D3;}
	.st72{fill:#AEB6C7;}
	.st73{fill:#C14F32;}
	.st74{fill:#BFA6B3;}
	.st75{fill:#B9ACB3;}
	.st76{fill:#BFA4A9;}
	.st77{fill:#EDDCDC;}
	.st78{fill:#BFA6B1;}
	.st79{fill:#BCA5B0;}
	.st80{fill:#CD9A9C;}
	.st81{fill:#D5A6A7;}
	.st82{fill:#B3ABBD;}
	.st83{fill:#A2A7BE;}
	.st84{fill:#B1A0AF;}
	.st85{fill:#A4B2D8;}
	.st86{fill:#1E5387;}
	.st87{fill:#22568F;}
	.st88{fill:#2B6092;}
	.st89{fill:#9BB4CF;}
	.st90{fill:#B6AEBA;}
	.st91{fill:#343F55;}
	.st92{fill:#AFA1B8;}
	.st93{fill:#B54A2E;}
	.st94{fill:#A55141;}
	.st95{fill:#99B9EA;}
	.st96{fill:#9FB8CE;}
	.st97{fill:#1A5D75;}
	.st98{fill:#0C4790;}
	.st99{fill:#18437F;}
	.st100{fill:#2D4370;}
	.st101{fill:#A75446;}
	.st102{fill:#C55837;}
	.st103{fill:#2E3A55;}
	.st104{fill:#32486B;}
	.st105{fill:#B84E38;}
	.st106{fill:#BE4F2D;}
	.st107{fill:#C74B1C;}
	.st108{fill:#274573;}
	.st109{fill:#F1EFE8;}
	.st110{fill:#FBF6E1;}
	.st111{fill:#597C8B;}
	.st112{fill:#E4A650;}
	.st113{fill:#C2A062;}
	.st114{fill:#E7B576;}
	.st115{fill:#BCA66A;}
	.st116{fill:#DC9544;}
	.st117{fill:#CA9659;}
	.st118{fill:#E3A857;}
	.st119{fill:#FCEFD0;}
	.st120{fill:#E6A853;}
	.st121{fill:#C89857;}
	.st122{fill:#FBEDCE;}
	.st123{fill:#B49359;}
	.st124{fill:#E6A15C;}
	.st125{fill:#DABAB6;}
	.st126{fill:#CFBFBE;}
	.st127{fill:#A87D4F;}
	.st128{fill:#DC9D50;}
	.st129{fill:#98BEA1;}
	.st130{fill:#F09741;}
	.st131{fill:#C9B164;}
	.st132{fill:#597590;}
	.st133{fill:#E3A65B;}
	.st134{fill:#E6A350;}
	.st135{fill:#BA9158;}
	.st136{fill:#E0A456;}
	.st137{fill:#D39B53;}
	.st138{fill:#B78655;}
	.st139{fill:#E5B89D;}
	.st140{fill:#C18E4D;}
	.st141{fill:#D09752;}
	.st142{fill:#EF9B4B;}
	.st143{fill:#E2AC56;}
	.st144{fill:#E1AC5D;}
	.st145{fill:#D49D53;}
	.st146{fill:#909574;}
	.st147{fill:#E5AB63;}
	.st148{fill:#CAA464;}
	.st149{fill:#E3B66B;}
	.st150{fill:#E6AB5B;}
	.st151{fill:#DCA35A;}
	.st152{fill:#D59747;}
	.st153{fill:#E4B177;}
	.st154{fill:#BB9459;}
	.st155{fill:#C69C51;}
	.st156{fill:#DEA350;}
	.st157{fill:#E8A256;}
	.st158{fill:#E8AE83;}
	.st159{fill:#D2BE7D;}
	.st160{fill:#E3A64C;}
	.st161{fill:#B9955E;}
	.st162{fill:#EEB05A;}
	.st163{fill:#E5A24F;}
	.st164{fill:#C2A761;}
	.st165{fill:#D49B4E;}
	.st166{fill:#E49847;}
	.st167{fill:#C29A4F;}
	.st168{fill:#E8AB52;}
	.st169{fill:#E7B05E;}
	.st170{fill:#DE9D56;}
	.st171{fill:#DCA345;}
	.st172{fill:#ECA04D;}
	.st173{fill:#F1EDE7;}
	.st174{fill:#3D7BA7;}
	.st175{fill:#3D91BC;}
	.st176{fill:#3688AD;}
	.st177{fill:#F9F9F4;}
	.st178{fill:#FAF4D9;}
	.st179{fill:#178CD2;}
	.st180{fill:#1388CF;}
	.st181{fill:#177FC0;}
	.st182{fill:#3671A5;}
	.st183{fill:#1E78BB;}
	.st184{fill:#FCEFD1;}
	.st185{fill:#FBEDCC;}
	.st186{fill:#278BC2;}
	.st187{fill:#4697AC;}
	.st188{fill:#28C1DB;}
	.st189{fill:#40D8E2;}
	.st190{fill:#31C3DF;}
	.st191{fill:#417CA1;}
	.st192{fill:#FBF7F0;}
	.st193{fill:#4F7C95;}
	.st194{fill:#3481B9;}
	.st195{fill:#3995B4;}
	.st196{fill:#CEBDC5;}
	.st197{fill:#D1BFC7;}
	.st198{fill:#D9C0C4;}
	.st199{fill:#2891CF;}
	.st200{fill:#074585;}
	.st201{fill:#2B85B7;}
	.st202{fill:#F9E7C1;}
	.st203{fill:#519CB2;}
	.st204{fill:#ABC8DA;}
	.st205{fill:#C6C5D5;}
	.st206{fill:#A5DEEE;}
	.st207{fill:#355F87;}
	.st208{fill:#38465B;}
	.st209{fill:#23B0ED;}
	.st210{fill:#296DA1;}
	.st211{fill:#D5C3CA;}
	.st212{fill:#FAF2EC;}
	.st213{fill:#D7C0C5;}
	.st214{fill:#D6C4CB;}
	.st215{fill:#D8C2C6;}
	.st216{fill:#1784CB;}
	.st217{fill:#1D85CF;}
	.st218{fill:#1086C7;}
	.st219{fill:#2B9AD6;}
	.st220{fill:#1A77C0;}
	.st221{fill:#176FB5;}
	.st222{fill:#116CA6;}
	.st223{fill:#1A8DD3;}
	.st224{fill:#1089D3;}
	.st225{fill:#156FA7;}
	.st226{fill:#1695D4;}
	.st227{fill:#0763AA;}
	.st228{fill:#2970BE;}
	.st229{fill:#67DEF9;}
	.st230{fill:#1D70B8;}
	.st231{fill:#1F9CCF;}
	.st232{fill:#178BD0;}
	.st233{fill:#1479C3;}
	.st234{fill:#DBC0C3;}
	.st235{fill:#DEBEBD;}
	.st236{fill:#D9BBBA;}
	.st237{fill:#F3FAFA;}
	.st238{fill:#1291D9;}
	.st239{fill:#179AD1;}
	.st240{fill:#4AC8F0;}
	.st241{fill:#CEC0C9;}
	.st242{fill:#CCBDC7;}
	.st243{fill:#CABDC4;}
	.st244{fill:#B9B8CB;}
	.st245{fill:#D8B9A9;}
	.st246{fill:#D0C2CB;}
	.st247{fill:#044784;}
	.st248{fill:#2A4B71;}
	.st249{fill:#226D9C;}
	.st250{fill:#2186AB;}
	.st251{fill:#27567B;}
	.st252{fill:#255A8B;}
	.st253{fill:#286098;}
	.st254{fill:#233850;}
	.st255{fill:#BDB5BC;}
	.st256{fill:#1AC4F8;}
	.st257{fill:#23BBF5;}
	.st258{fill:#25BCED;}
	.st259{fill:#0372AB;}
	.st260{fill:#1978B9;}
	.st261{fill:#D3C5CD;}
	.st262{fill:#D4BBBF;}
	.st263{fill:#D2C2C9;}
	.st264{fill:#D1BFC6;}
	.st265{fill:#09548B;}
	.st266{fill:#0A66AF;}
	.st267{fill:#0965A8;}
	.st268{fill:#1D94D9;}
	.st269{fill:#1696DD;}
	.st270{fill:#077FC9;}
	.st271{fill:#025598;}
	.st272{fill:#0E8CE1;}
	.st273{fill:#1CA5DC;}
	.st274{fill:#0DAAE9;}
	.st275{fill:#0FA0E1;}
	.st276{fill:#50E5FD;}
	.st277{fill:#1669B0;}
	.st278{fill:#1788D5;}
	.st279{fill:#1A80CA;}
	.st280{fill:#2C98C6;}
	.st281{fill:#2698CC;}
	.st282{fill:#2682B3;}
	.st283{fill:#53D1F5;}
	.st284{fill:#2592B5;}
	.st285{fill:#116CB0;}
	.st286{fill:#1579BE;}
	.st287{fill:#1787CD;}
	.st288{fill:#41B3EE;}
	.st289{fill:#0F89D1;}
	.st290{fill:#198ED0;}
	.st291{fill:#1481CA;}
	.st292{fill:#146FBB;}
	.st293{fill:#379CE1;}
	.st294{fill:#CDB8BB;}
	.st295{fill:#45C2F1;}
	.st296{fill:#C7BBC5;}
	.st297{fill:#CBBAC1;}
	.st298{fill:#B0B5CB;}
	.st299{fill:#154A72;}
	.st300{fill:#095294;}
	.st301{fill:#1E6C9C;}
	.st302{fill:#1F5479;}
	.st303{fill:#B0B3C3;}
	.st304{fill:#1D659D;}
	.st305{fill:#1F3C58;}
	.st306{fill:#263C51;}
	.st307{fill:#243A50;}
	.st308{fill:#1A4B71;}
	.st309{fill:#246AAC;}
	.st310{fill:#32D0F8;}
	.st311{fill:#20CFFB;}
	.st312{fill:#138AD1;}
	.st313{fill:#015B96;}
	.st314{fill:#19A5E2;}
	.st315{fill:#0369B0;}
	.st316{fill:#50D0F7;}
	.st317{fill:#54D6FB;}
	.st318{fill:#68EBFD;}
	.st319{fill:#1971B4;}
	.st320{fill:#1C91D1;}
	.st321{fill:#1485C9;}
	.st322{fill:#249DD4;}
	.st323{fill:#51E1FB;}
	.st324{fill:#035AA2;}
	.st325{fill:#1FBEF2;}
	.st326{fill:#1489D4;}
	.st327{fill:#13A0E4;}
	.st328{fill:#167DC7;}
	.st329{fill:#1C76BD;}
	.st330{fill:#1DAFF1;}
	.st331{fill:#023C83;}
	.st332{fill:#1262AC;}
	.st333{fill:#5CECFD;}
	.st334{fill:#149DD1;}
	.st335{fill:#0E86C0;}
	.st336{fill:#60E4FB;}
	.st337{fill:#1A86C7;}
	.st338{fill:#024589;}
	.st339{fill:#C6B7BD;}
	.st340{fill:#C1B9C3;}
	.st341{fill:#194976;}
	.st342{fill:#0B74A9;}
	.st343{fill:#08528F;}
	.st344{fill:#1780CD;}
	.st345{fill:#157AC8;}
	.st346{fill:#1072C4;}
	.st347{fill:#1166B8;}
	.st348{fill:#1A60A9;}
	.st349{fill:#0F7FB8;}
	.st350{fill:#E8F3F3;}
	.st351{fill:#F9ECDB;}
	.st352{fill:#F1F5F8;}
	.st353{fill:#CF804B;}
	.st354{fill:#F6DAC3;}
	.st355{fill:#E7A063;}
	.st356{fill:#E99966;}
	.st357{fill:#C78B6C;}
	.st358{fill:#DB9553;}
	.st359{fill:#D89253;}
	.st360{fill:#DC924E;}
	.st361{fill:#DC9653;}
	.st362{fill:#DE9859;}
	.st363{fill:#DE985E;}
	.st364{fill:#E59A63;}
	.st365{fill:#DA9356;}
	.st366{fill:#E48F54;}
	.st367{fill:#E4945A;}
	.st368{fill:#DE8B4E;}
	.st369{fill:#E69E6F;}
	.st370{fill:#E59048;}
	.st371{fill:#EE8A42;}
	.st372{fill:#DF9A5E;}
	.st373{fill:#E59C65;}
	.st374{fill:#E79C64;}
	.st375{fill:#DC8B46;}
	.st376{fill:#E39156;}
	.st377{fill:#E8984D;}
	.st378{fill:#EEAA73;}
	.st379{fill:#E3A667;}
	.st380{fill:#E5975B;}
	.st381{fill:#E39D5B;}
	.st382{fill:#D6F1E3;}
	.st383{fill:#E0E9F1;}
	.st384{fill:#4DF1AA;}
	.st385{fill:#E7DACD;}
	.st386{fill:#B4F9DF;}
	.st387{fill:#DBE7E1;}
	.st388{fill:#EEF2FA;}
	.st389{fill:#F1F2E1;}
	.st390{fill:#E4F8F8;}
	.st391{fill:#948699;}
	.st392{fill:#956B60;}
	.st393{fill:#EAE0D6;}
	.st394{fill:#9BA0B7;}
	.st395{fill:#8889A1;}
	.st396{fill:#ACB1C4;}
	.st397{fill:#DAC3BE;}
	.st398{fill:#F3E3D4;}
	.st399{fill:#F8D5BA;}
	.st400{fill:#F4D3B9;}
	.st401{fill:#9893A0;}
	.st402{fill:#12AD6C;}
	.st403{fill:#09D26C;}
	.st404{fill:#DFD9DD;}
	.st405{fill:#CED8E3;}
	.st406{fill:#BBCBDB;}
	.st407{fill:#B4BECE;}
	.st408{fill:#46B488;}
	.st409{fill:#5BF0AA;}
	.st410{fill:#C5DFD6;}
	.st411{fill:#D1E2EC;}
	.st412{fill:#55EAAC;}
	.st413{fill:#319E6A;}
	.st414{fill:#BFDFCD;}
	.st415{fill:#5DC6A2;}
	.st416{fill:#898299;}
	.st417{fill:#897683;}
	.st418{fill:#DFE8DF;}
	.st419{fill:#ACEDDB;}
	.st420{fill:#E6E5E3;}
	.st421{fill:#11E578;}
	.st422{fill:#0CC66F;}
	.st423{fill:#11E473;}
	.st424{fill:#18E37C;}
	.st425{fill:#019F54;}
	.st426{fill:#13E47B;}
	.st427{fill:#19B06C;}
	.st428{fill:#B4F1E3;}
	.st429{fill:#18BA64;}
	.st430{fill:#2FB867;}
	.st431{fill:#0B8658;}
	.st432{fill:#837C93;}
	.st433{fill:#16CA6A;}
	.st434{fill:#17E26D;}
	.st435{fill:#17E06C;}
	.st436{fill:#17E56A;}
	.st437{fill:#27D98F;}
	.st438{fill:#17DD74;}
	.st439{fill:#2AD57A;}
	.st440{fill:#12E46B;}
	.st441{fill:#0BEA64;}
	.st442{fill:#11CE6E;}
	.st443{fill:#13DF59;}
	.st444{fill:#16DD61;}
	.st445{fill:#0CDA56;}
	.st446{fill:#A9F8DD;}
	.st447{fill:#CFEFF5;}
	.st448{fill:#C8F7EA;}
	.st449{fill:#DE8D57;}
	.st450{fill:#BCC2D2;}
	.st451{fill:#2E678A;}
	.st452{fill:#6E3738;}
	.st453{fill:#C16E5C;}
	.st454{fill:#D36D4C;}
	.st455{fill:#E2DCDC;}
	.st456{fill:#A2523B;}
	.st457{fill:#DA8956;}
	.st458{fill:#D16B52;}
	.st459{fill:#B88759;}
	.st460{fill:#865347;}
	.st461{fill:#E9AE7C;}
	.st462{fill:#D2BFC6;}
	.st463{fill:#674844;}
	.st464{fill:#AD5835;}
	.st465{fill:#A0533C;}
	.st466{fill:#B8C0CE;}
	.st467{fill:#C3775F;}
	.st468{fill:#C4915E;}
	.st469{fill:#DC9160;}
	.st470{fill:#8A381C;}
	.st471{fill:#D17244;}
	.st472{fill:#B75E3E;}
	.st473{fill:#A05646;}
	.st474{fill:#D9C7C6;}
	.st475{fill:#DF834E;}
	.st476{fill:#DA7E46;}
	.st477{fill:#894838;}
	.st478{fill:#C67252;}
	.st479{fill:#9B4840;}
	.st480{fill:#A63F1C;}
	.st481{fill:#DF784B;}
	.st482{fill:#DD9256;}
	.st483{fill:#E18C52;}
	.st484{fill:#E38D5B;}
	.st485{fill:#D46A48;}
	.st486{fill:#E17747;}
	.st487{fill:#E17C4D;}
	.st488{fill:#9A4F39;}
	.st489{fill:#604A3A;}
	.st490{fill:#BF8B5A;}
	.st491{fill:#D38D51;}
	.st492{fill:#3E2529;}
	.st493{fill:#D57143;}
	.st494{fill:#B96F5F;}
	.st495{fill:#803B3B;}
	.st496{fill:#B88B5A;}
	.st497{fill:#F76C28;}
	.st498{fill:#B18961;}
	.st499{fill:#A76353;}
	.st500{fill:#96443C;}
	.st501{fill:#DA8040;}
	.st502{fill:#EB6832;}
	.st503{fill:#CF8764;}
	.st504{fill:#C66B4D;}
	.st505{fill:#D36125;}
	.st506{fill:#B15F43;}
	.st507{fill:#D97248;}
	.st508{fill:#EFAB7E;}
	.st509{fill:#E49752;}
	.st510{fill:#E49E64;}
	.st511{fill:#B8604C;}
	.st512{fill:#D49055;}
	.st513{fill:#B46651;}
	.st514{fill:#D3814B;}
	.st515{fill:#C26C53;}
	.st516{fill:#D78752;}
	.st517{fill:#B86945;}
	.st518{fill:#C7755D;}
	.st519{fill:#E38C49;}
	.st520{fill:#E46D36;}
	.st521{fill:#D7823A;}
	.st522{fill:#E18B63;}
	.st523{fill:#DF9354;}
	.st524{fill:#B78155;}
	.st525{fill:#C16D55;}
	.st526{fill:#E99056;}
	.st527{fill:#FE641C;}
	.st528{fill:#C87454;}
	.st529{fill:#FE6220;}
	.st530{fill:#FE6A1D;}
	.st531{fill:#E96C35;}
	.st532{fill:#C36C4F;}
	.st533{fill:#B86B5A;}
	.st534{fill:#FE7124;}
	.st535{fill:#E17241;}
	.st536{fill:#F5A056;}
	.st537{fill:#EEE8E9;}
	.st538{fill:#6D524F;}
	.st539{fill:#704F4B;}
	.st540{fill:#373134;}
	.st541{fill:#7E655A;}
	.st542{fill:#785B54;}
	.st543{fill:#825E49;}
	.st544{fill:#725758;}
	.st545{fill:#BEC7DA;}
	.st546{fill:#BFC6D3;}
	.st547{fill:#143C56;}
	.st548{fill:#B7C7DC;}
	.st549{fill:#16445D;}
	.st550{fill:#D0C3D0;}
	.st551{fill:#AFBFC9;}
	.st552{fill:#0D70B2;}
	.st553{fill:#0970B1;}
	.st554{fill:#0865A2;}
	.st555{fill:#165685;}
	.st556{fill:#0E74AA;}
	.st557{fill:#1470A8;}
	.st558{fill:#507186;}
	.st559{fill:#CCBAC3;}
	.st560{fill:#323C5A;}
	.st561{fill:#963A31;}
	.st562{fill:#222D42;}
	.st563{fill:#424258;}
	.st564{fill:#524258;}
	.st565{fill:#B97671;}
	.st566{fill:#CC8174;}
	.st567{fill:#AD787B;}
	.st568{fill:#753836;}
	.st569{fill:#C57768;}
	.st570{fill:#2B2C3C;}
	.st571{fill:#B87E7F;}
	.st572{fill:#CCD5E3;}
	.st573{fill:#D5DDE7;}
	.st574{fill:#D1D5E3;}
	.st575{fill:#9499AF;}
	.st576{fill:#D5D2D7;}
	.st577{fill:#9C91A4;}
	.st578{fill:#8E555A;}
	.st579{fill:#311A23;}
	.st580{fill:#A1513C;}
	.st581{fill:#522224;}
	.st582{fill:#CECBD5;}
	.st583{fill:#775653;}
	.st584{fill:#6A5657;}
	.st585{fill:#A83D30;}
	.st586{fill:#D49488;}
	.st587{fill:#C8715C;}
	.st588{fill:#D6846E;}
	.st589{fill:#851E0A;}
	.st590{fill:#0B3F61;}
	.st591{fill:#704B49;}
	.st592{fill:#B3C7DC;}
	.st593{fill:#0D3553;}
	.st594{fill:#BCC0CE;}
	.st595{fill:#642928;}
	.st596{fill:#5B3436;}
	.st597{fill:#CED3E1;}
	.st598{fill:#D3D6E3;}
	.st599{fill:#C9CDDC;}
	.st600{fill:#C5CBE5;}
	.st601{fill:#845154;}
	.st602{fill:#8E4835;}
	.st603{fill:#9E828C;}
	.st604{fill:#7D7583;}
	.st605{fill:#3A1B18;}
	.st606{fill:#AA7D78;}
	.st607{fill:#487388;}
	.st608{fill:#263940;}
	.st609{fill:#3F7997;}
	.st610{fill:#906B65;}
	.st611{fill:#B17778;}
	.st612{fill:#B98579;}
	.st613{fill:#7A4F58;}
	.st614{fill:#301F18;}
	.st615{fill:#C3CADC;}
	.st616{fill:#7E7F9D;}
	.st617{fill:#636A88;}
	.st618{fill:#AC8682;}
	.st619{fill:#C4816C;}
	.st620{fill:#6C1E1D;}
	.st621{fill:#694755;}
	.st622{fill:#AE4E32;}
	.st623{fill:#5B281F;}
	.st624{fill:#476C76;}
	.st625{fill:#0C2D45;}
	.st626{fill:#0A71B6;}
	.st627{fill:#406B84;}
	.st628{fill:#C1C2CD;}
	.st629{fill:#3C3645;}
	.st630{fill:#797389;}
	.st631{fill:#7D5F69;}
	.st632{fill:#B8756A;}
	.st633{fill:#767F89;}
	.st634{fill:#71737D;}
	.st635{fill:#ECF1F7;}
	.st636{fill:#EFF5FA;}
	.st637{fill:#E9ECF7;}
	.st638{fill:#E7EBF3;}
	.st639{fill:#E1EFF8;}
	.st640{fill:#E3ECF3;}
	.st641{fill:#E6E9F5;}
	.st642{fill:#C7C9D6;}
	.st643{fill:#7A8397;}
	.st644{fill:#768396;}
	.st645{fill:#77879A;}
	.st646{fill:#12394C;}
	.st647{fill:#BAC6DB;}
	.st648{fill:#727F95;}
	.st649{fill:#A1A6B5;}
	.st650{fill:#153D51;}
	.st651{fill:#1B3F5F;}
	.st652{fill:#16547C;}
	.st653{fill:#116EAF;}
	.st654{fill:#044C83;}
	.st655{fill:#084D7C;}
	.st656{fill:#084979;}
	.st657{fill:#CDCCD7;}
	.st658{fill:#E0D5DA;}
	.st659{fill:#BDB6C5;}
	.st660{fill:#C18F92;}
	.st661{fill:#AF97AC;}
	.st662{fill:#AD8085;}
	.st663{fill:#D5C9D5;}
	.st664{fill:#925252;}
	.st665{fill:#DB947F;}
	.st666{fill:#B58890;}
	.st667{fill:#C49196;}
	.st668{fill:#A27D85;}
	.st669{fill:#5F4757;}
	.st670{fill:#C3847B;}
	.st671{fill:#CF8372;}
	.st672{fill:#524657;}
	.st673{fill:#615974;}
	.st674{fill:#373D4E;}
	.st675{fill:#AB8B94;}
	.st676{fill:#B0858D;}
	.st677{fill:#BE898A;}
	.st678{fill:#818A9E;}
	.st679{fill:#E4E4F0;}
	.st680{fill:#E0E1EC;}
	.st681{fill:#D9DEF1;}
	.st682{fill:#895563;}
	.st683{fill:#AA5E5B;}
	.st684{fill:#93524E;}
	.st685{fill:#AB5540;}
	.st686{fill:#9A555D;}
	.st687{fill:#6A2218;}
	.st688{fill:#A7B2C3;}
	.st689{fill:#894A48;}
	.st690{fill:#465460;}
	.st691{fill:#A04D40;}
	.st692{fill:#73798B;}
	.st693{fill:#DDDDE9;}
	.st694{fill:#D7D9E7;}
	.st695{fill:#C8857E;}
	.st696{fill:#C07B76;}
	.st697{fill:#083353;}
	.st698{fill:#05365A;}
	.st699{fill:#163251;}
	.st700{fill:#CDD1DF;}
	.st701{fill:#D7DBE4;}
	.st702{fill:#D2D5E1;}
	.st703{fill:#CED6E6;}
	.st704{fill:#C4D1E1;}
	.st705{fill:#808E9D;}
	.st706{fill:#939CAB;}
	.st707{fill:#E1D3D9;}
	.st708{fill:#9B545B;}
	.st709{fill:#908EA2;}
	.st710{fill:#93899C;}
	.st711{fill:#858BA4;}
	.st712{fill:#A28996;}
	.st713{fill:#3C323E;}
	.st714{fill:#26222E;}
	.st715{fill:#1476B1;}
	.st716{fill:#2480B7;}
	.st717{fill:#C8E1EC;}
	.st718{fill:#143D5B;}
	.st719{fill:#24547A;}
	.st720{fill:#14405C;}
	.st721{fill:#064978;}
	.st722{fill:#A38189;}
	.st723{fill:#BC7871;}
	.st724{fill:#998692;}
	.st725{fill:#C18C86;}
	.st726{fill:#E4DDE8;}
	.st727{fill:#989EB1;}
	.st728{fill:#A2A8BB;}
	.st729{fill:#9796AC;}
	.st730{fill:#88879E;}
	.st731{fill:#AFAEC1;}
	.st732{fill:#2C2D43;}
	.st733{fill:#9F93A1;}
	.st734{fill:#928F9F;}
	.st735{fill:#5E3935;}
	.st736{fill:#AC7879;}
	.st737{fill:#878197;}
	.st738{fill:#927987;}
	.st739{fill:#6D6883;}
	.st740{fill:#E5E8F3;}
	.st741{fill:#EAF0F5;}
	.st742{fill:#0C304C;}
	.st743{fill:#0B3553;}
	.st744{fill:#D1E7F0;}
	.st745{fill:#D9EAF3;}
	.st746{fill:#C6DDEC;}
	.st747{fill:#B4ECF9;}
	.st748{fill:#C6CEDF;}
	.st749{fill:#C1CEE0;}
	.st750{fill:#C3D6E8;}
	.st751{fill:#0E3852;}
	.st752{fill:#043355;}
	.st753{fill:#09385A;}
	.st754{fill:#C6DDEE;}
	.st755{fill:#0F3858;}
	.st756{fill:#E3E6E9;}
	.st757{fill:#D5D5E1;}
	.st758{fill:#9C93B4;}
	.st759{fill:#C7C1CF;}
	.st760{fill:#B17F87;}
	.st761{fill:#A58A96;}
	.st762{fill:#A591A5;}
	.st763{fill:#CCCFDC;}
	.st764{fill:#C3C8DC;}
	.st765{fill:#DEDBE3;}
	.st766{fill:#E5A688;}
	.st767{fill:#B798A5;}
	.st768{fill:#CF9E9F;}
	.st769{fill:#CE9790;}
	.st770{fill:#E1CED8;}
	.st771{fill:#C4AEB9;}
	.st772{fill:#AA939F;}
	.st773{fill:#B78F99;}
	.st774{fill:#E1E5F0;}
	.st775{fill:#DEE7F1;}
	.st776{fill:#C9F3EA;}
	.st777{fill:#D8F0F5;}
	.st778{fill:#DFE9F2;}
	.st779{fill:#BEECF5;}
	.st780{fill:#C9E9F6;}
	.st781{fill:#DAECF0;}
	.st782{fill:#CFEAF1;}
	.st783{fill:#CEEBF9;}
	.st784{fill:#C2E4F8;}
	.st785{fill:#8D3E2B;}
	.st786{fill:#A8595A;}
	.st787{fill:#B0B9CF;}
	.st788{fill:#AAA5B6;}
	.st789{fill:#BCB0BE;}
	.st790{fill:#A15E5E;}
	.st791{fill:#74646F;}
	.st792{fill:#AC5547;}
	.st793{fill:#C4E6F3;}
	.st794{fill:#DFDFF1;}
	.st795{fill:#D5E3F5;}
	.st796{fill:#3C81A1;}
	.st797{fill:#C9E3F1;}
	.st798{fill:#DBD9E8;}
	.st799{fill:#DBE3F0;}
	.st800{fill:#D6E6E5;}
	.st801{fill:#C8DBEF;}
	.st802{fill:#CCE1EA;}
	.st803{fill:#B9DFF4;}
	.st804{fill:#C7DBED;}
	.st805{fill:#769DB1;}
	.st806{fill:#DDF1F9;}
	.st807{fill:#DDD9E3;}
	.st808{fill:#DBD0DC;}
	.st809{fill:#7A525C;}
	.st810{fill:#905652;}
	.st811{fill:#754447;}
	.st812{fill:#9396AC;}
	.st813{fill:#9C92A4;}
	.st814{fill:#989DB4;}
	.st815{fill:#4A3F4C;}
	.st816{fill:#A38D98;}
	.st817{fill:#CF897A;}
	.st818{fill:#645052;}
	.st819{fill:#7B5757;}
	.st820{fill:#F0BFBD;}
	.st821{fill:#958A98;}
	.st822{fill:#D39684;}
	.st823{fill:#BF9B9D;}
	.st824{fill:#9391A0;}
	.st825{fill:#9CAFBB;}
	.st826{fill:#E8EDF5;}
	.st827{fill:#E6EAF1;}
	.st828{fill:#E1DFE8;}
	.st829{fill:#E2E3F0;}
	.st830{fill:#9496A7;}
	.st831{fill:#A69AA9;}
	.st832{fill:#A69EAD;}
	.st833{fill:#BCACBD;}
	.st834{fill:#BBBCCE;}
	.st835{fill:#B3A9BB;}
	.st836{fill:#57444F;}
	.st837{fill:#A5808C;}
	.st838{fill:#987F93;}
	.st839{fill:#93869A;}
	.st840{fill:#9690A8;}
	.st841{fill:#988FA0;}
	.st842{fill:#E2E9F1;}
	.st843{fill:#C1EEFB;}
	.st844{fill:#DDEBF5;}
	.st845{fill:#BFEDFA;}
	.st846{fill:#DEF7F8;}
	.st847{fill:#D3E7F5;}
	.st848{fill:#E8EFF8;}
	.st849{fill:#DAE6F1;}
	.st850{fill:#E7EFFC;}
	.st851{fill:#E4ECF7;}
	.st852{fill:#CFF4F8;}
	.st853{fill:#D4F1FA;}
	.st854{fill:#E3F3F2;}
	.st855{fill:#E4F7F7;}
	.st856{fill:#143B56;}
	.st857{fill:#0E3956;}
	.st858{fill:#1CB5F2;}
	.st859{fill:#CBF1F7;}
	.st860{fill:#2BAEC8;}
	.st861{fill:#1C3454;}
	.st862{fill:#BEB5C8;}
	.st863{fill:#DEE3EB;}
	.st864{fill:#DAE0F0;}
	.st865{fill:#BCEAF6;}
	.st866{fill:#D5D0DA;}
	.st867{fill:#CBBDC8;}
	.st868{fill:#E6935D;}
	.st869{fill:#F4C5AA;}
	.st870{fill:#E0B0AB;}
	.st871{fill:#B1ADBF;}
	.st872{fill:#A59FB2;}
	.st873{fill:#C5B1BE;}
	.st874{fill:#D8AFAF;}
	.st875{fill:#C6A2AB;}
	.st876{fill:#B29BAB;}
	.st877{fill:#27ABCA;}
	.st878{fill:#CEF0F6;}
	.st879{fill:#C7EDF7;}
	.st880{fill:#CFE4EA;}
	.st881{fill:#8BB093;}
	.st882{fill:#22A2B7;}
	.st883{fill:#2EAFCB;}
	.st884{fill:#E9ECF3;}
	.st885{fill:#2FA6D5;}
	.st886{fill:#ABF5D9;}
	.st887{fill:#47A8C9;}
	.st888{fill:#2FD08C;}
	.st889{fill:#31CE8E;}
	.st890{fill:#54D4A8;}
	.st891{fill:#2D9FB2;}
	.st892{fill:#33A9B2;}
	.st893{fill:#EDE4F6;}
	.st894{fill:#C0F1F6;}
	.st895{fill:#47A7B9;}
	.st896{fill:#21AEDD;}
	.st897{fill:#2BB6E0;}
	.st898{fill:#3FA6DD;}
	.st899{fill:#37A9C8;}
	.st900{fill:#3BBDDA;}
	.st901{fill:#3FB5D2;}
	.st902{fill:#E0E4EA;}
	.st903{fill:#459CCC;}
	.st904{fill:#BCC7DB;}
	.st905{fill:#C7D4E3;}
	.st906{fill:#CBBCC8;}
	.st907{fill:#D6B0A9;}
	.st908{fill:#694A56;}
	.st909{fill:#CCC7CE;}
	.st910{fill:#DAD8E2;}
	.st911{fill:#31A3CA;}
	.st912{fill:#DCE1E8;}
	.st913{fill:#2696B9;}
	.st914{fill:#1F9ABD;}
	.st915{fill:#3EAACA;}
	.st916{fill:#4392B2;}
	.st917{fill:#3A98CE;}
	.st918{fill:#38A0D0;}
	.st919{fill:#558DB0;}
	.st920{fill:#408AA7;}
	.st921{fill:#D3DFED;}
	.st922{fill:#DFDFE9;}
	.st923{fill:#EBC661;}
	.st924{fill:#CACFE2;}
	.st925{fill:#C9E2EE;}
	.st926{fill:#C6C18A;}
	.st927{fill:#75AFA6;}
	.st928{fill:#4B8CA7;}
	.st929{fill:#629EB7;}
	.st930{fill:#3F789B;}
	.st931{fill:#36A4DB;}
	.st932{fill:#358CBA;}
	.st933{fill:#2D98CC;}
	.st934{fill:#4D8EB5;}
	.st935{fill:#A8B0C5;}
	.st936{fill:#A0A6BC;}
	.st937{fill:#916467;}
	.st938{fill:#CDC3C5;}
	.st939{fill:#CDD4DE;}
	.st940{fill:#E8D7D3;}
	.st941{fill:#DCA994;}
	.st942{fill:#C9F4F8;}
	.st943{fill:#EFF8F6;}
	.st944{fill:#E7EAF4;}
	.st945{fill:#E0ECF3;}
	.st946{fill:#DFEAF4;}
	.st947{fill:#D3F4F9;}
	.st948{fill:#D5E0EB;}
	.st949{fill:#B0A2B4;}
	.st950{fill:#BFB5C8;}
	.st951{fill:#16DA75;}
	.st952{fill:#14D971;}
	.st953{fill:#BBF7DE;}
	.st954{fill:#2CAADF;}
	.st955{fill:#C2EEFB;}
	.st956{fill:#D8E9F7;}
	.st957{fill:#2BBADF;}
	.st958{fill:#2FBCCF;}
	.st959{fill:#2DB1D5;}
	.st960{fill:#E7F7F5;}
	.st961{fill:#3ABBDA;}
	.st962{fill:#31B2DC;}
	.st963{fill:#38B7DF;}
	.st964{fill:#BBF0FA;}
	.st965{fill:#56B0C5;}
	.st966{fill:#40BBCB;}
	.st967{fill:#43A6C5;}
	.st968{fill:#3FAFBA;}
	.st969{fill:#CCF2FC;}
	.st970{fill:#DCEDF2;}
	.st971{fill:#B1F0FA;}
	.st972{fill:#E2F5F5;}
	.st973{fill:#22AFD4;}
	.st974{fill:#B4EFF7;}
	.st975{fill:#44BEDE;}
	.st976{fill:#1BC1F5;}
	.st977{fill:#15B9ED;}
	.st978{fill:#25D6FD;}
	.st979{fill:#1FC4EE;}
	.st980{fill:#E5E6EB;}
	.st981{fill:#EFE5E0;}
	.st982{fill:#E8DBD8;}
	.st983{fill:#DBC0C2;}
	.st984{fill:#B0BCD2;}
	.st985{fill:#D1C4D1;}
	.st986{fill:#59BAC4;}
	.st987{fill:#67B2CB;}
	.st988{fill:#5EB4D0;}
	.st989{fill:#D7DEE9;}
	.st990{fill:#25D992;}
	.st991{fill:#BCC7DD;}
	.st992{fill:#7ADCC8;}
	.st993{fill:#1BD97E;}
	.st994{fill:#BCE8F5;}
	.st995{fill:#C3E8F4;}
	.st996{fill:#C8E5EF;}
	.st997{fill:#D2CFDC;}
	.st998{fill:#E4CFD0;}
	.st999{fill:#E3C0BE;}
	.st1000{fill:#6BB9C6;}
	.st1001{fill:#AFCED1;}
	.st1002{fill:#D3EAEE;}
	.st1003{fill:#B3EBE4;}
	.st1004{fill:#E6E4EC;}
	.st1005{fill:#34B7E0;}
	.st1006{fill:#2EBFE0;}
	.st1007{fill:#45B7D4;}
	.st1008{fill:#2CA9CB;}
	.st1009{fill:#42BDD7;}
	.st1010{fill:#3AACC9;}
	.st1011{fill:#49B6D2;}
	.st1012{fill:#D7EBFA;}
	.st1013{fill:#E6F5F6;}
	.st1014{fill:#40B0D2;}
	.st1015{fill:#31BEE7;}
	.st1016{fill:#44BCDC;}
	.st1017{fill:#E7D9E1;}
	.st1018{fill:#B6F7E3;}
	.st1019{fill:#8CE5D7;}
	.st1020{fill:#2BD58A;}
	.st1021{fill:#3AB3DA;}
	.st1022{fill:#36B3E3;}
	.st1023{fill:#2BBCE2;}
	.st1024{fill:#26B6E7;}
	.st1025{fill:#49BADB;}
	.st1026{fill:#31B5DB;}
	.st1027{fill:#4EB6D9;}
	.st1028{fill:#CDF5FC;}
	.st1029{fill:#12C8EF;}
	.st1030{fill:#15C2EF;}
	.st1031{fill:#0EC2ED;}
	.st1032{fill:#2FDBFD;}
	.st1033{fill:#37E4FD;}
	.st1034{fill:#38D6FB;}
	.st1035{fill:#16BFF7;}
	.st1036{fill:#1EB6F5;}
	.st1037{fill:#36DBFE;}
	.st1038{fill:#D0CFDD;}
	.st1039{fill:#BDEEFA;}
	.st1040{fill:#94BFD1;}
	.st1041{fill:#3ABCEA;}
	.st1042{fill:#F6ECDD;}
	.st1043{fill:#E6DDE3;}
	.st1044{fill:#E9E1E0;}
	.st1045{fill:#D18D4B;}
	.st1046{fill:#D18A5B;}
	.st1047{fill:#EDE1DC;}
	.st1048{fill:#EDD8D9;}
	.st1049{fill:#D89059;}
	.st1050{fill:#F8EAD9;}
	.st1051{fill:#D5C3B6;}
	.st1052{fill:#DF935A;}
	.st1053{fill:#F2D7C4;}
	.st1054{fill:#F9D4BC;}
	.st1055{fill:#E09D54;}
	.st1056{fill:#BC7452;}
	.st1057{fill:#E0A371;}
	.st1058{fill:#E79456;}
	.st1059{fill:#E79A6C;}
	.st1060{fill:#DB9258;}
	.st1061{fill:#DEA365;}
	.st1062{fill:#CD8340;}
	.st1063{fill:#DFA15C;}
	.st1064{fill:#D37F49;}
	.st1065{fill:#E3A46B;}
	.st1066{fill:#DA9352;}
	.st1067{fill:#DE9653;}
	.st1068{fill:#D89E56;}
	.st1069{fill:#DC9F5A;}
	.st1070{fill:#D7945D;}
	.st1071{fill:#E3A66E;}
	.st1072{fill:#DB9753;}
	.st1073{fill:#D97D45;}
	.st1074{fill:#E28C4A;}
	.st1075{fill:#D8924C;}
	.st1076{fill:#E0985B;}
	.st1077{fill:#DFA566;}
	.st1078{fill:#EAA361;}
	.st1079{fill:#D9944E;}
	.st1080{fill:#E19358;}
	.st1081{fill:#DB9B5C;}
	.st1082{fill:#E6A55D;}
	.st1083{fill:#D9914B;}
	.st1084{fill:#E6AC7F;}
	.st1085{fill:#DB934F;}
	.st1086{fill:#D58F48;}
	.st1087{fill:#E4A768;}
	.st1088{fill:#DB9345;}
	.st1089{fill:#E8A167;}
	.st1090{fill:#E19C61;}
	.st1091{fill:#F8BB80;}
	.st1092{fill:#DEA175;}
	.st1093{fill:#DE9851;}
	.st1094{fill:#DF9C59;}
	.st1095{fill:#DD9D5F;}
	.st1096{fill:#D8944C;}
	.st1097{fill:#DF9B57;}
	.st1098{fill:#E0985F;}
	.st1099{fill:#E7985A;}
	.st1100{fill:#DC8753;}
	.st1101{fill:#E79B58;}
	.st1102{fill:#DA984F;}
	.st1103{fill:#DF9356;}
	.st1104{fill:#DF9A5D;}
	.st1105{fill:#E19D5D;}
	.st1106{fill:#D89E5F;}
	.st1107{fill:#DD914C;}
	.st1108{fill:#DC9D61;}
	.st1109{fill:#E3A061;}
	.st1110{fill:#E3974F;}
	.st1111{fill:#ECA65A;}
	.st1112{fill:#DA9755;}
	.st1113{fill:#E0A562;}
	.st1114{fill:#DD9B53;}
	.st1115{fill:#D89648;}
	.st1116{fill:#DCA05E;}
	.st1117{fill:#DB9244;}
	.st1118{fill:#EBAB70;}
	.st1119{fill:#EAA765;}
	.st1120{fill:#E7A56B;}
	.st1121{fill:#D59645;}
	.st1122{fill:#E2A05F;}
	.st1123{fill:#DFA158;}
	.st1124{fill:#E59B56;}
	.st1125{fill:#D69042;}
	.st1126{fill:#ECF2F7;}
	.st1127{fill:#F5F9F8;}
	.st1128{fill:#EFF4F7;}
	.st1129{fill:#EEF6F8;}
	.st1130{fill:#EBE7E5;}
	.st1131{fill:#E3E2ED;}
	.st1132{fill:#E3DDE5;}
	.st1133{fill:#E8E5EA;}
	.st1134{fill:#E8E6E9;}
	.st1135{fill:#E8EEF6;}
	.st1136{fill:#A48174;}
	.st1137{fill:#E6E2E6;}
	.st1138{fill:#A5B0C0;}
	.st1139{fill:#9B9CAF;}
	.st1140{fill:#E5F0F8;}
	.st1141{fill:#FAE2C8;}
	.st1142{fill:#ABB9C7;}
	.st1143{fill:#BDCFDE;}
	.st1144{fill:#B3C2CF;}
	.st1145{fill:#C8D4E3;}
	.st1146{fill:#B7C9D7;}
	.st1147{fill:#EBDCE1;}
	.st1148{fill:#9F6F6B;}
	.st1149{fill:#ECE3DE;}
	.st1150{fill:#F0F7F7;}
	.st1151{fill:#1FBAB3;}
	.st1152{fill:#1FBAB1;}
	.st1153{fill:#189A81;}
	.st1154{fill:#2FBCBA;}
	.st1155{fill:#D4F7F7;}
	.st1156{fill:#C9F7F7;}
	.st1157{fill:#1D9284;}
	.st1158{fill:#BDF6F6;}
	.st1159{fill:#B9EFEF;}
	.st1160{fill:#39BAB3;}
	.st1161{fill:#3A968A;}
	.st1162{fill:#98B7C3;}
	.st1163{fill:#8BBDC0;}
	.st1164{fill:#C9EAF4;}
	.st1165{fill:#B9EFF2;}
	.st1166{fill:#B5E4E3;}
	.st1167{fill:#CEF1F8;}
	.st1168{fill:#EAF3F9;}
	.st1169{fill:#B7B0B8;}
	.st1170{fill:#9FA8BA;}
	.st1171{fill:#96858D;}
	.st1172{fill:#987C7E;}
	.st1173{fill:#BFF4F0;}
	.st1174{fill:#9090A1;}
	.st1175{fill:#8B7D8F;}
	.st1176{fill:#8F8C9A;}
	.st1177{fill:#A7DBE6;}
	.st1178{fill:#9BC3D3;}
	.st1179{fill:#979DAE;}
	.st1180{fill:#9CA3B4;}
	.st1181{fill:#DDE9F3;}
	.st1182{fill:#E5EAF1;}
	.st1183{fill:#CCDEE7;}
	.st1184{fill:#D9E0E8;}
	.st1185{fill:#D5E4EC;}
	.st1186{fill:#15F0D3;}
	.st1187{fill:#1DF7E1;}
	.st1188{fill:#0CD7CF;}
	.st1189{fill:#31BDB7;}
	.st1190{fill:#1BF1D5;}
	.st1191{fill:#1BEABA;}
	.st1192{fill:#10DEDB;}
	.st1193{fill:#1CC393;}
	.st1194{fill:#29EAAB;}
	.st1195{fill:#079271;}
	.st1196{fill:#028866;}
	.st1197{fill:#11876B;}
	.st1198{fill:#359C80;}
	.st1199{fill:#3E8C8B;}
	.st1200{fill:#12AB6E;}
	.st1201{fill:#1EBB74;}
	.st1202{fill:#2FA78E;}
	.st1203{fill:#5DAD9C;}
	.st1204{fill:#49AA9B;}
	.st1205{fill:#0F9C64;}
	.st1206{fill:#1DAF8C;}
	.st1207{fill:#2A997C;}
	.st1208{fill:#9EE7EC;}
	.st1209{fill:#14A87E;}
	.st1210{fill:#1EA67F;}
	.st1211{fill:#19AC85;}
	.st1212{fill:#19D9A8;}
	.st1213{fill:#1FD4B3;}
	.st1214{fill:#9F9DAB;}
	.st1215{fill:#9C98A5;}
	.st1216{fill:#9498AC;}
	.st1217{fill:#9493A6;}
	.st1218{fill:#23B58F;}
	.st1219{fill:#52A9A1;}
	.st1220{fill:#10F2A2;}
	.st1221{fill:#14EEAE;}
	.st1222{fill:#16F1B9;}
	.st1223{fill:#14F0AE;}
	.st1224{fill:#17EFB0;}
	.st1225{fill:#16F1AA;}
	.st1226{fill:#16F197;}
	.st1227{fill:#15F0A2;}
	.st1228{fill:#18EF9D;}
	.st1229{fill:#11D57A;}
	.st1230{fill:#21BA6E;}
	.st1231{fill:#22B277;}
	.st1232{fill:#06D45E;}
	.st1233{fill:#0CAF6A;}
	.st1234{fill:#14CC6D;}
	.st1235{fill:#0BCB58;}
	.st1236{fill:#10D361;}
	.st1237{fill:#21D060;}
	.st1238{fill:#21C27F;}
	.st1239{fill:#08CF7D;}
	.st1240{fill:#18CC64;}
	.st1241{fill:#14CF70;}
	.st1242{fill:#0CEC8B;}
	.st1243{fill:#12EF99;}
	.st1244{fill:#15EF9B;}
	.st1245{fill:#14F29B;}
	.st1246{fill:#11EF8B;}
	.st1247{fill:#14EF91;}
	.st1248{fill:#0EED83;}
	.st1249{fill:#16EE98;}
	.st1250{fill:#09E472;}
	.st1251{fill:#09DA65;}
	.st1252{fill:#0CE87D;}
	.st1253{fill:#0AD661;}
	.st1254{fill:#02DE69;}
	.st1255{fill:#0AD25E;}
	.st1256{fill:#02DA63;}
	.st1257{fill:#06D864;}
	.st1258{fill:#08CF5C;}
	.st1259{fill:#0FCF5D;}
	.st1260{fill:#17D35D;}
	.st1261{fill:#04E574;}
	.st1262{fill:#06E879;}
	.st1263{fill:#08EA7C;}
	.st1264{fill:#07E16D;}
	.st1265{fill:#14D15C;}
	.st1266{fill:#0DEC84;}
	.st1267{fill:#12ED91;}
	.st1268{fill:#11EE8A;}
	.st1269{fill:#1AEC96;}
	.st1270{fill:#0CF28F;}
	.st1271{fill:#0AF089;}
	.st1272{fill:#12F397;}
	.st1273{fill:#0FF394;}
	.st1274{fill:#09ED82;}
	.st1275{fill:#12F293;}
	.st1276{fill:#10F08F;}
	.st1277{fill:#16E88F;}
	.st1278{fill:#28E9A0;}
	.st1279{fill:#35E6A7;}
	.st1280{fill:#09E878;}
	.st1281{fill:#11DD6C;}
	.st1282{fill:#03E36E;}
	.st1283{fill:#23D276;}
	.st1284{fill:#B6F9DB;}
	.st1285{fill:#D0F9F1;}
	.st1286{fill:#0DE97E;}
	.st1287{fill:#1EEA93;}
	.st1288{fill:#07DC6A;}
	.st1289{fill:#17EB8A;}
	.st1290{fill:#25E89A;}
	.st1291{fill:#0DED86;}
	.st1292{fill:#0EEB81;}
	.st1293{fill:#0FE77D;}
	.st1294{fill:#14E380;}
	.st1295{fill:#BFFBE6;}
	.st1296{fill:#C1FEF2;}
	.st1297{fill:#AAF7E0;}
	.st1298{fill:#24D98A;}
	.st1299{fill:#04E46F;}
	.st1300{fill:#0FDF76;}
	.st1301{fill:#0AE06D;}
	.st1302{fill:#E5F5F2;}
	.st1303{fill:#C4F8E2;}
	.st1304{fill:#EBEEF5;}
	.st1305{fill:#CBF9ED;}
	.st1306{fill:#B0F8DC;}
	.st1307{fill:#13E278;}
	.st1308{fill:#32D08E;}
	.st1309{fill:#44D191;}
	.st1310{fill:#F2F6F8;}
	.st1311{fill:#BEF8DE;}
	.st1312{fill:#C6FCF3;}
	.st1313{fill:#EEF2F8;}
	.st1314{fill:#F6F2EF;}
	.st1315{fill:#D9884C;}
	.st1316{fill:#EECFB9;}
	.st1317{fill:#E1A458;}
	.st1318{fill:#E5AC62;}
	.st1319{fill:#EBAD63;}
	.st1320{fill:#C37B4C;}
	.st1321{fill:#FAE8C6;}
	.st1322{fill:#F9E3CE;}
	.st1323{fill:#D2843B;}
	.st1324{fill:#DA9B68;}
	.st1325{fill:#DBA95A;}
	.st1326{fill:#C6B9AA;}
	.st1327{fill:#AF5A3E;}
	.st1328{fill:#FDB34A;}
	.st1329{fill:#F9ECCE;}
	.st1330{fill:#FBE7BF;}
	.st1331{fill:#D58A41;}
	.st1332{fill:#C36138;}
	.st1333{fill:#C96D5C;}
	.st1334{fill:#BD7043;}
	.st1335{fill:#FCE9C2;}
	.st1336{fill:#FAECD8;}
	.st1337{fill:#F4B148;}
	.st1338{fill:#D99E62;}
	.st1339{fill:#EDA656;}
	.st1340{fill:#BEAF72;}
	.st1341{fill:#E5A142;}
	.st1342{fill:#E6A95A;}
	.st1343{fill:#E8C97D;}
	.st1344{fill:#E39F4B;}
	.st1345{fill:#903C31;}
	.st1346{fill:#AD4B3F;}
	.st1347{fill:#E3A558;}
	.st1348{fill:#E7AD5D;}
	.st1349{fill:#F4C05B;}
	.st1350{fill:#E0A154;}
	.st1351{fill:#E7B77A;}
	.st1352{fill:#E7AF5A;}
	.st1353{fill:#E2A85D;}
	.st1354{fill:#CCB094;}
	.st1355{fill:#F2AD4E;}
	.st1356{fill:#E7AE67;}
	.st1357{fill:#FCCDA8;}
	.st1358{fill:#AA402F;}
	.st1359{fill:#E4A458;}
	.st1360{fill:#F4BEA3;}
	.st1361{fill:#E8AD5F;}
	.st1362{fill:#E76D47;}
	.st1363{fill:#D18572;}
	.st1364{fill:#EDCC70;}
	.st1365{fill:#E39E51;}
	.st1366{fill:#CB8D58;}
	.st1367{fill:#525044;}
	.st1368{fill:#D0944F;}
	.st1369{fill:#C56E42;}
	.st1370{fill:#E2A75D;}
	.st1371{fill:#DEA655;}
	.st1372{fill:#EFAD5A;}
	.st1373{fill:#E3A261;}
	.st1374{fill:#D4672B;}
	.st1375{fill:#E8A74D;}
	.st1376{fill:#F1C26C;}
	.st1377{fill:#DA9753;}
	.st1378{fill:#F5C65B;}
	.st1379{fill:#E4A659;}
	.st1380{fill:#EED4C0;}
	.st1381{fill:#E4964B;}
	.st1382{fill:#D8A44F;}
	.st1383{fill:#EA9A42;}
	.st1384{fill:#E4A455;}
	.st1385{fill:#EDAB5A;}
	.st1386{fill:#E7AE60;}
	.st1387{fill:#CA955C;}
	.st1388{fill:#E2AF59;}
	.st1389{fill:#ED9D45;}
	.st1390{fill:#F0AC4C;}
	.st1391{fill:#96432F;}
	.st1392{fill:#EBB87C;}
	.st1393{fill:#EAA85D;}
	.st1394{fill:#EAAF61;}
	.st1395{fill:#D4AA57;}
	.st1396{fill:#E79F6C;}
	.st1397{fill:#DFAC51;}
	.st1398{fill:#D9955E;}
	.st1399{fill:#E7AE59;}
	.st1400{fill:#F39846;}
	.st1401{fill:#DA9F3E;}
	.st1402{fill:#E0A54F;}
	.st1403{fill:#D17848;}
	.st1404{fill:#E4A74F;}
	.st1405{fill:#B76457;}
	.st1406{fill:#DBAE55;}
	.st1407{fill:#EEB66C;}
	.st1408{fill:#C78F68;}
	.st1409{fill:#ECBC6B;}
	.st1410{fill:#CC9B59;}
	.st1411{fill:#F0BC6E;}
	.st1412{fill:#E5B157;}
	.st1413{fill:#E0AB4E;}
	.st1414{fill:#E7A757;}
	.st1415{fill:#E39648;}
	.st1416{fill:#D9A25D;}
	.st1417{fill:#E6A752;}
	.st1418{fill:#F0964C;}
	.st1419{fill:#EBB15E;}
	.st1420{fill:#E6B26C;}
	.st1421{fill:#AA6B15;}
	.st1422{fill:#DEA345;}
	.st1423{fill:#E49540;}
	.st1424{fill:#E8B65D;}
	.st1425{fill:#EABA46;}
	.st1426{fill:#E89F46;}
	.st1427{fill:#E6B056;}
	.st1428{fill:#E0A950;}
	.st1429{fill:#E0BB6E;}
	.st1430{fill:#E1B154;}
	.st1431{fill:#E49954;}
	.st1432{fill:#EBB478;}
	.st1433{fill:#E3AE5A;}
	.st1434{fill:#EBA648;}
	.st1435{fill:#E7B463;}
	.st1436{fill:#E9AE5E;}
	.st1437{fill:#E5AF76;}
	.st1438{fill:#E8E4EA;}
	.st1439{fill:#FAE3CB;}
	.st1440{fill:#F1C38E;}
	.st1441{fill:#E26F34;}
	.st1442{fill:#B17F82;}
	.st1443{fill:#B07F6D;}
	.st1444{fill:#B7786A;}
	.st1445{fill:#5B1910;}
	.st1446{fill:#9E776F;}
	.st1447{fill:#977F7A;}
	.st1448{fill:#9D8C93;}
	.st1449{fill:#978487;}
	.st1450{fill:#A6948D;}
	.st1451{fill:#B09192;}
	.st1452{fill:#917C72;}
	.st1453{fill:#9F9DB0;}
	.st1454{fill:#B1B5C7;}
	.st1455{fill:#9D96A9;}
	.st1456{fill:#F6E2D9;}
	.st1457{fill:#F8DCCE;}
	.st1458{fill:#E4D9D7;}
	.st1459{fill:#F5EEEB;}
	.st1460{fill:#E9DFDD;}
	.st1461{fill:#D08842;}
	.st1462{fill:#E2A562;}
	.st1463{fill:#BE7F80;}
	.st1464{fill:#BE7954;}
	.st1465{fill:#C8796D;}
	.st1466{fill:#F4E3E5;}
	.st1467{fill:#EFEAE3;}
	.st1468{fill:#E0C670;}
	.st1469{fill:#F3DDDF;}
	.st1470{fill:#312E33;}
	.st1471{fill:#493630;}
	.st1472{fill:#1A437A;}
	.st1473{fill:#215394;}
	.st1474{fill:#B2A0AC;}
	.st1475{fill:#947C75;}
	.st1476{fill:#531D24;}
	.st1477{fill:#4875A1;}
	.st1478{fill:#6D1D0C;}
	.st1479{fill:#FAEFDF;}
	.st1480{fill:#CCDEE1;}
	.st1481{fill:#F7F9F9;}
	.st1482{fill:#F1ECED;}
	.st1483{fill:#2A484D;}
	.st1484{fill:#D4C1AA;}
	.st1485{fill:#1C2D31;}
	.st1486{fill:#282D2F;}
	.st1487{fill:#F6F4F6;}
	.st1488{fill:#695E58;}
	.st1489{fill:#233946;}
	.st1490{fill:#DB9F97;}
	.st1491{fill:#23110C;}
	.st1492{fill:#351307;}
	.st1493{fill:#3C1006;}
	.st1494{fill:#627269;}
	.st1495{fill:#F8AB45;}
	.st1496{fill:#FABE59;}
	.st1497{fill:#EEC67F;}
	.st1498{fill:#E9A23E;}
	.st1499{fill:#EFEEEF;}
	.st1500{fill:#FDDEA6;}
	.st1501{fill:#F5E4D6;}
	.st1502{fill:#C28F5B;}
	.st1503{fill:#A1705D;}
	.st1504{fill:#7C593B;}
	.st1505{fill:#714744;}
	.st1506{fill:#B96753;}
	.st1507{fill:#B07F89;}
	.st1508{fill:#C25836;}
	.st1509{fill:#C7807D;}
	.st1510{fill:#F7F9F7;}
	.st1511{fill:#E9EFF5;}
	.st1512{fill:#E2E7EF;}
	.st1513{fill:#D0D6E4;}
	.st1514{fill:#EFFAEF;}
	.st1515{fill:#F5F2EB;}
	.st1516{fill:#ECC190;}
	.st1517{fill:#CEA68D;}
	.st1518{fill:#B09DA9;}
	.st1519{fill:#132D55;}
	.st1520{fill:#EDE2DE;}
	.st1521{fill:#E1B16F;}
	.st1522{fill:#E4A35A;}
	.st1523{fill:#48DFEC;}
	.st1524{fill:#E5EFEC;}
	.st1525{fill:#F8EFE0;}
	.st1526{fill:#C4C17F;}
	.st1527{fill:#28A7BC;}
	.st1528{fill:#327E8A;}
	.st1529{fill:#DEB264;}
	.st1530{fill:#CA9B61;}
	.st1531{fill:#D7A24E;}
	.st1532{fill:#ECB767;}
	.st1533{fill:#F7D695;}
	.st1534{fill:#794041;}
	.st1535{fill:#894548;}
	.st1536{fill:#92515B;}
	.st1537{fill:#A1575A;}
	.st1538{fill:#B6AFB9;}
	.st1539{fill:#B3C4D5;}
	.st1540{fill:#F6B055;}
	.st1541{fill:#F9DA7D;}
	.st1542{fill:#F9F8F7;}
	.st1543{fill:#D0DBD5;}
	.st1544{fill:#374349;}
	.st1545{fill:#5C4C3C;}
	.st1546{fill:#775930;}
	.st1547{fill:#FAF3D8;}
	.st1548{fill:#F3F0EF;}
	.st1549{fill:#FAE5C2;}
	.st1550{fill:#A19894;}
	.st1551{fill:#DCECED;}
	.st1552{fill:#E6BCBB;}
	.st1553{fill:#C18A8F;}
	.st1554{fill:#C5969D;}
	.st1555{fill:#D1B2AA;}
	.st1556{fill:#B97A80;}
	.st1557{fill:#CBD1AB;}
	.st1558{fill:#897172;}
	.st1559{fill:#BC8863;}
	.st1560{fill:#E1E0E7;}
	.st1561{fill:#A05535;}
	.st1562{fill:#C1A773;}
	.st1563{fill:#D4D4DE;}
	.st1564{fill:#D3A072;}
	.st1565{fill:#433C48;}
	.st1566{fill:#DEE2E9;}
	.st1567{fill:#C2C4D6;}
	.st1568{fill:#CDD3E0;}
	.st1569{fill:#CACBDB;}
	.st1570{fill:#738485;}
	.st1571{fill:#90A3B9;}
	.st1572{fill:#A8A6B8;}
	.st1573{fill:#172334;}
	.st1574{fill:#AC9CA1;}
	.st1575{fill:#767F75;}
	.st1576{fill:#C47A78;}
	.st1577{fill:#B46F76;}
	.st1578{fill:#A9656C;}
	.st1579{fill:#443C48;}
	.st1580{fill:#DBDCE7;}
	.st1581{fill:#ED9E61;}
	.st1582{fill:#EEB375;}
	.st1583{fill:#E3D4D9;}
	.st1584{fill:#E8DCD3;}
	.st1585{fill:#F47933;}
	.st1586{fill:#F3853A;}
	.st1587{fill:#393E54;}
	.st1588{fill:#D98D59;}
	.st1589{fill:#876A69;}
	.st1590{fill:#8F554E;}
	.st1591{fill:#743231;}
	.st1592{fill:#AA8F97;}
	.st1593{fill:#645072;}
	.st1594{fill:#AA909F;}
	.st1595{fill:#B9939C;}
	.st1596{fill:#20191F;}
	.st1597{fill:#AB684E;}
	.st1598{fill:#A98283;}
	.st1599{fill:#9F7C83;}
	.st1600{fill:#9F8293;}
	.st1601{fill:#9B8EA2;}
	.st1602{fill:#3E1207;}
	.st1603{fill:#908597;}
	.st1604{fill:#45362E;}
	.st1605{fill:#988284;}
	.st1606{fill:#A896A0;}
	.st1607{fill:#BEC3E4;}
	.st1608{fill:#939AB1;}
	.st1609{fill:#A4B0D3;}
	.st1610{fill:#B09BA0;}
	.st1611{fill:#C39AA6;}
	.st1612{fill:#ADA7B7;}
	.st1613{fill:#C6C6D1;}
	.st1614{fill:#BEB8C5;}
	.st1615{fill:#F0D9D3;}
	.st1616{fill:#E1DDD4;}
	.st1617{fill:#BECFE7;}
	.st1618{fill:#EFD8DB;}
	.st1619{fill:#F2D1D2;}
	.st1620{fill:#1A6084;}
	.st1621{fill:#0F3548;}
	.st1622{fill:#215270;}
	.st1623{fill:#D6D2D9;}
	.st1624{fill:#9A8B7C;}
	.st1625{fill:#3A629C;}
	.st1626{fill:#B68088;}
	.st1627{fill:#B68891;}
	.st1628{fill:#CA8281;}
	.st1629{fill:#C2898C;}
	.st1630{fill:#EDE1E5;}
	.st1631{fill:#F3D68C;}
	.st1632{fill:#D1AA61;}
	.st1633{fill:#F4D76F;}
	.st1634{fill:#2E5579;}
	.st1635{fill:#344963;}
	.st1636{fill:#634857;}
	.st1637{fill:#223C5C;}
	.st1638{fill:#0B3351;}
	.st1639{fill:#25242C;}
	.st1640{fill:#0E498F;}
	.st1641{fill:#1E2042;}
	.st1642{fill:#7DB6D3;}
	.st1643{fill:#153762;}
	.st1644{fill:#6AA5CE;}
	.st1645{fill:#4C8EC1;}
	.st1646{fill:#4F87AE;}
	.st1647{fill:#ADABC4;}
	.st1648{fill:#EDF2F2;}
	.st1649{fill:#ACCBCE;}
	.st1650{fill:#CAECFA;}
	.st1651{fill:#EAE3E3;}
	.st1652{fill:#8D7C75;}
	.st1653{fill:#5E98B0;}
	.st1654{fill:#73A5D9;}
	.st1655{fill:#7A99A1;}
	.st1656{fill:#C2C3BF;}
	.st1657{fill:#5E99AF;}
	.st1658{fill:#445D6A;}
	.st1659{fill:#152121;}
	.st1660{fill:#324F53;}
	.st1661{fill:#2A3B41;}
	.st1662{fill:#156590;}
	.st1663{fill:#0E2A3D;}
	.st1664{fill:#0B4A81;}
	.st1665{fill:#E6C279;}
	.st1666{fill:#083B61;}
	.st1667{fill:#4682A1;}
	.st1668{fill:#1C262F;}
	.st1669{fill:#1C262B;}
	.st1670{fill:#E7E2BD;}
	.st1671{fill:#3F5C5F;}
	.st1672{fill:#E5DAB1;}
	.st1673{fill:#D8B2BB;}
	.st1674{fill:#E7DBDE;}
	.st1675{fill:#F8C368;}
	.st1676{fill:#ECDCDF;}
	.st1677{fill:#B39A60;}
	.st1678{fill:#E5CFD5;}
	.st1679{fill:#D6B6A0;}
	.st1680{fill:#A88E70;}
	.st1681{fill:#BF8644;}
	.st1682{fill:#DD9047;}
	.st1683{fill:#DD745D;}
	.st1684{fill:#D07664;}
	.st1685{fill:#AD6E74;}
	.st1686{fill:#C36154;}
	.st1687{fill:#713741;}
	.st1688{fill:#684E60;}
	.st1689{fill:#7C4C5C;}
	.st1690{fill:#A98498;}
	.st1691{fill:#A0808C;}
	.st1692{fill:#B64F32;}
	.st1693{fill:#AC563D;}
	.st1694{fill:#B48F9E;}
	.st1695{fill:#B27341;}
	.st1696{fill:#D5D4DA;}
	.st1697{fill:#884B32;}
	.st1698{fill:#B2BDD9;}
	.st1699{fill:#E7C5B0;}
	.st1700{fill:#A6A3B3;}
	.st1701{fill:#9F98AB;}
	.st1702{fill:#94A4BD;}
	.st1703{fill:#8797AF;}
	.st1704{fill:#1E2C54;}
	.st1705{fill:#0C3D7E;}
	.st1706{fill:#214066;}
	.st1707{fill:#212E49;}
	.st1708{fill:#586A82;}
	.st1709{fill:#48586F;}
	.st1710{fill:#E2CDD1;}
	.st1711{fill:#4C82B1;}
	.st1712{fill:#313D56;}
	.st1713{fill:#3F3429;}
	.st1714{fill:#CCF0F8;}
	.st1715{fill:#0BB7D3;}
	.st1716{fill:#BFDFE7;}
	.st1717{fill:#E7E8F0;}
	.st1718{fill:#DFBE80;}
	.st1719{fill:#FBCE79;}
	.st1720{fill:#F0AD4D;}
	.st1721{fill:#BF6721;}
	.st1722{fill:#C8782A;}
	.st1723{fill:#DD8344;}
	.st1724{fill:#974341;}
	.st1725{fill:#A36055;}
	.st1726{fill:#994647;}
	.st1727{fill:#8C4851;}
	.st1728{fill:#964C50;}
	.st1729{fill:#A3565C;}
	.st1730{fill:#9A5E6A;}
	.st1731{fill:#289ED0;}
	.st1732{fill:#A6EBFA;}
	.st1733{fill:#F4F3F5;}
	.st1734{fill:#F8EDE9;}
	.st1735{fill:#363D36;}
	.st1736{fill:#E4D6BA;}
	.st1737{fill:#E1DCC7;}
	.st1738{fill:#80C1E8;}
	.st1739{fill:#50A6D2;}
	.st1740{fill:#596F86;}
	.st1741{fill:#F8FAF9;}
	.st1742{fill:#F1F0EF;}
	.st1743{fill:#EDEFF3;}
	.st1744{fill:#E7E8ED;}
	.st1745{fill:#CFC7D2;}
	.st1746{fill:#B64D34;}
	.st1747{fill:#BE8D55;}
	.st1748{fill:#6DBCDC;}
	.st1749{fill:#C49096;}
	.st1750{fill:#BE8389;}
	.st1751{fill:#CE8277;}
	.st1752{fill:#C5A0AB;}
	.st1753{fill:#DD9C8C;}
	.st1754{fill:#B7BACC;}
	.st1755{fill:#ADAEBF;}
	.st1756{fill:#D06E69;}
	.st1757{fill:#E1D0AC;}
	.st1758{fill:#77BACC;}
	.st1759{fill:#585D7C;}
	.st1760{fill:#486A97;}
	.st1761{fill:#0C66A1;}
	.st1762{fill:#074F7D;}
	.st1763{fill:#BEACB0;}
	.st1764{fill:#C8BDCA;}
	.st1765{fill:#28231C;}
	.st1766{fill:#D4D9E2;}
	.st1767{fill:#BFBECC;}
	.st1768{fill:#AEAFB5;}
	.st1769{fill:#CDC9CE;}
	.st1770{fill:#1072B7;}
	.st1771{fill:#244363;}
	.st1772{fill:#B2838A;}
	.st1773{fill:#9FA1AF;}
	.st1774{fill:#B4ABB9;}
	.st1775{fill:#AAA3B2;}
	.st1776{fill:#13202A;}
	.st1777{fill:#8E8FA4;}
	.st1778{fill:#A39EAE;}
	.st1779{fill:#E2C8C5;}
	.st1780{fill:#417A95;}
	.st1781{fill:#B46265;}
	.st1782{fill:#AFA4B1;}
	.st1783{fill:#2A3F77;}
	.st1784{fill:#3E4374;}
	.st1785{fill:#4E424D;}
	.st1786{fill:#CFCEDC;}
	.st1787{fill:#BC5029;}
	.st1788{fill:#B2BBD0;}
	.st1789{fill:#E4BAAF;}
	.st1790{fill:#283D69;}
	.st1791{fill:#3C2C22;}
	.st1792{fill:#C4C3CC;}
	.st1793{fill:#5568A0;}
	.st1794{fill:#8989B1;}
	.st1795{fill:#B39DAA;}
	.st1796{fill:#DEAD9B;}
	.st1797{fill:#BAA7B2;}
	.st1798{fill:#BCA0AC;}
	.st1799{fill:#B99AA3;}
	.st1800{fill:#212126;}
	.st1801{fill:#132733;}
	.st1802{fill:#C28053;}
	.st1803{fill:#B18F93;}
	.st1804{fill:#BC8577;}
	.st1805{fill:#B5A0A7;}
	.st1806{fill:#220E02;}
	.st1807{fill:#C68556;}
	.st1808{fill:#4A5D7C;}
	.st1809{fill:#4F4E60;}
	.st1810{fill:#664E44;}
	.st1811{fill:#BAA3AE;}
	.st1812{fill:#CAC4CE;}
	.st1813{fill:#323D54;}
	.st1814{fill:#3B466A;}
	.st1815{fill:#CCABB0;}
	.st1816{fill:#DEC7C0;}
	.st1817{fill:#EDCFC9;}
	.st1818{fill:#F0C7B0;}
	.st1819{fill:#E6E5F0;}
	.st1820{fill:#B0C9D1;}
	.st1821{fill:#E7CCCE;}
	.st1822{fill:#83ABCB;}
	.st1823{fill:#E7DAE3;}
	.st1824{fill:#E6CED3;}
	.st1825{fill:#E5D5DD;}
	.st1826{fill:#EDC7C3;}
	.st1827{fill:#E8C9CC;}
	.st1828{fill:#0E405F;}
	.st1829{fill:#155582;}
	.st1830{fill:#26281C;}
	.st1831{fill:#1E272A;}
	.st1832{fill:#14282F;}
	.st1833{fill:#C2BBC3;}
	.st1834{fill:#B4B8C0;}
	.st1835{fill:#BAB1A9;}
	.st1836{fill:#BC8F95;}
	.st1837{fill:#CA8F8D;}
	.st1838{fill:#CF8983;}
	.st1839{fill:#CF8276;}
	.st1840{fill:#C99897;}
	.st1841{fill:#E0D8E2;}
	.st1842{fill:#1D3D57;}
	.st1843{fill:#224263;}
	.st1844{fill:#302A31;}
	.st1845{fill:#D47B4D;}
	.st1846{fill:#653425;}
	.st1847{fill:#021F41;}
	.st1848{fill:#40778D;}
	.st1849{fill:#2A5487;}
	.st1850{fill:#72A3BF;}
	.st1851{fill:#746C7A;}
	.st1852{fill:#083960;}
	.st1853{fill:#C56E3D;}
	.st1854{fill:#AE7149;}
	.st1855{fill:#11437E;}
	.st1856{fill:#0D3C6D;}
	.st1857{fill:#2A5E8B;}
	.st1858{fill:#DCE9EC;}
	.st1859{fill:#B1B6B5;}
	.st1860{fill:#E5DAD2;}
	.st1861{fill:#BCABA1;}
	.st1862{fill:#213B4C;}
	.st1863{fill:#4E6275;}
	.st1864{fill:#1C374B;}
	.st1865{fill:#363026;}
	.st1866{fill:#453E3D;}
	.st1867{fill:#1E4A6F;}
	.st1868{fill:#234E77;}
	.st1869{fill:#23A8DA;}
	.st1870{fill:#135B92;}
	.st1871{fill:#E4D0D5;}
	.st1872{fill:#EAE3E6;}
	.st1873{fill:#EFE9E9;}
	.st1874{fill:#282F36;}
	.st1875{fill:#DDC3CD;}
	.st1876{fill:#322514;}
	.st1877{fill:#F6D4A1;}
	.st1878{fill:#DFDBE5;}
	.st1879{fill:#946761;}
	.st1880{fill:#B36634;}
	.st1881{fill:#942903;}
	.st1882{fill:#E17D67;}
	.st1883{fill:#DF6B4D;}
	.st1884{fill:#DC7767;}
	.st1885{fill:#E07455;}
	.st1886{fill:#D97C6E;}
	.st1887{fill:#B3868A;}
	.st1888{fill:#D8877E;}
	.st1889{fill:#B28DA2;}
	.st1890{fill:#BF5E46;}
	.st1891{fill:#5F1C21;}
	.st1892{fill:#5C1E23;}
	.st1893{fill:#816A77;}
	.st1894{fill:#A78F9E;}
	.st1895{fill:#897789;}
	.st1896{fill:#9F4941;}
	.st1897{fill:#B295A0;}
	.st1898{fill:#F3D3C3;}
	.st1899{fill:#D27644;}
	.st1900{fill:#9B442E;}
	.st1901{fill:#254376;}
	.st1902{fill:#1859A3;}
	.st1903{fill:#A8ACBD;}
	.st1904{fill:#AEA0B1;}
	.st1905{fill:#7A89A2;}
	.st1906{fill:#998CA2;}
	.st1907{fill:#6A4848;}
	.st1908{fill:#4B4957;}
	.st1909{fill:#17273C;}
	.st1910{fill:#CABECA;}
	.st1911{fill:#9FA4B2;}
	.st1912{fill:#D5BDC2;}
	.st1913{fill:#D8ACB9;}
	.st1914{fill:#CFB7CF;}
	.st1915{fill:#1789CE;}
	.st1916{fill:#1A8CDB;}
	.st1917{fill:#B392A1;}
	.st1918{fill:#A96556;}
	.st1919{fill:#94706E;}
	.st1920{fill:#F2C98C;}
	.st1921{fill:#E0CBA7;}
	.st1922{fill:#AE3F3D;}
	.st1923{fill:#B74327;}
	.st1924{fill:#9F4129;}
	.st1925{fill:#962409;}
	.st1926{fill:#CF6645;}
	.st1927{fill:#AA4B4B;}
	.st1928{fill:#C05754;}
	.st1929{fill:#1A9AD8;}
	.st1930{fill:#3EA3D1;}
	.st1931{fill:#D8CCC1;}
	.st1932{fill:#36392C;}
	.st1933{fill:#625E5F;}
	.st1934{fill:#3E474B;}
	.st1935{fill:#243E53;}
	.st1936{fill:#DCC8A4;}
	.st1937{fill:#1C4659;}
	.st1938{fill:#50C7F3;}
	.st1939{fill:#40B8F0;}
	.st1940{fill:#2B3434;}
	.st1941{fill:#CAAFA1;}
	.st1942{fill:#A05645;}
	.st1943{fill:#D38C78;}
	.st1944{fill:#D86D4B;}
	.st1945{fill:#CB795B;}
	.st1946{fill:#D3AFB8;}
	.st1947{fill:#9C6148;}
	.st1948{fill:#C7BFCC;}
	.st1949{fill:#BCAFBB;}
	.st1950{fill:#F7D5A4;}
	.st1951{fill:#46536B;}
	.st1952{fill:#454A56;}
	.st1953{fill:#16385C;}
	.st1954{fill:#C1AFBC;}
	.st1955{fill:#B99FAB;}
	.st1956{fill:#C09799;}
	.st1957{fill:#CBA69A;}
	.st1958{fill:#6C4530;}
	.st1959{fill:#102739;}
	.st1960{fill:#637692;}
	.st1961{fill:#263851;}
	.st1962{fill:#3C556B;}
	.st1963{fill:#7F8A9F;}
	.st1964{fill:#192026;}
	.st1965{fill:#A49BAC;}
	.st1966{fill:#9C959F;}
	.st1967{fill:#9F9AAE;}
	.st1968{fill:#B4ABBB;}
	.st1969{fill:#F7DCCE;}
	.st1970{fill:#CC7463;}
	.st1971{fill:#D9795E;}
	.st1972{fill:#8C4139;}
	.st1973{fill:#19344C;}
	.st1974{fill:#A55243;}
	.st1975{fill:#824D4E;}
	.st1976{fill:#D9A4AB;}
	.st1977{fill:#1D4266;}
	.st1978{fill:#153D8D;}
	.st1979{fill:#2352A6;}
	.st1980{fill:#2A406C;}
	.st1981{fill:#325C9E;}
	.st1982{fill:#0A3061;}
	.st1983{fill:#F6D0B9;}
	.st1984{fill:#C0B2BF;}
	.st1985{fill:#BCAEB9;}
	.st1986{fill:#B296A0;}
	.st1987{fill:#E0A682;}
	.st1988{fill:#86512A;}
	.st1989{fill:#48495E;}
	.st1990{fill:#57434A;}
	.st1991{fill:#F3AF4D;}
	.st1992{fill:#AA7A3B;}
	.st1993{fill:#C2B3A4;}
	.st1994{fill:#BBB1A8;}
	.st1995{fill:#A37A59;}
	.st1996{fill:#E9C7C1;}
	.st1997{fill:#EEB9A8;}
	.st1998{fill:#E09C44;}
	.st1999{fill:#52688D;}
	.st2000{fill:#E9E0E5;}
	.st2001{fill:#E8C0BD;}
	.st2002{fill:#DACED8;}
	.st2003{fill:#D8C5CD;}
	.st2004{fill:#DFC1C6;}
	.st2005{fill:#E7C0BD;}
	.st2006{fill:#F2C1B8;}
	.st2007{fill:#303C45;}
	.st2008{fill:#E0AB7D;}
	.st2009{fill:#54473F;}
	.st2010{fill:#BC98A1;}
	.st2011{fill:#C2979C;}
	.st2012{fill:#C6A2A7;}
	.st2013{fill:#DD8170;}
	.st2014{fill:#2D2033;}
	.st2015{fill:#C89D9F;}
	.st2016{fill:#1A354A;}
	.st2017{fill:#A58E91;}
	.st2018{fill:#6D3937;}
	.st2019{fill:#E78E4A;}
	.st2020{fill:#443C80;}
	.st2021{fill:#CF9163;}
	.st2022{fill:#765F7F;}
	.st2023{fill:#D99B7F;}
	.st2024{fill:#E49971;}
	.st2025{fill:#C67F5D;}
	.st2026{fill:#EB7A1D;}
	.st2027{fill:#575252;}
	.st2028{fill:#523E4E;}
	.st2029{fill:#152642;}
	.st2030{fill:#AF7365;}
	.st2031{fill:#99706A;}
	.st2032{fill:#2F355A;}
	.st2033{fill:#CC8662;}
	.st2034{fill:#A28984;}
	.st2035{fill:#153D93;}
	.st2036{fill:#B6D3EF;}
	.st2037{fill:#022B57;}
	.st2038{fill:#24597B;}
	.st2039{fill:#787476;}
	.st2040{fill:#439AD7;}
	.st2041{fill:#59A7F3;}
	.st2042{fill:#075285;}
	.st2043{fill:#4D84A8;}
	.st2044{fill:#7FB5FC;}
	.st2045{fill:#041822;}
	.st2046{fill:#2E6185;}
	.st2047{fill:#202830;}
	.st2048{fill:#1E1614;}
	.st2049{fill:#44668F;}
	.st2050{fill:#2B403B;}
	.st2051{fill:#D8CFA9;}
	.st2052{fill:#E2C8CA;}
	.st2053{fill:#BA6248;}
	.st2054{fill:#E3A49E;}
	.st2055{fill:#E39988;}
	.st2056{fill:#EDECF7;}
	.st2057{fill:#F3B66B;}
	.st2058{fill:#E0A65D;}
	.st2059{fill:#F4AA5A;}
	.st2060{fill:#DABC8B;}
	.st2061{fill:#D3CBD1;}
	.st2062{fill:#D7D2DC;}
	.st2063{fill:#DF8370;}
	.st2064{fill:#B02C0B;}
	.st2065{fill:#CC4E2E;}
	.st2066{fill:#7A7883;}
	.st2067{fill:#BE9EA3;}
	.st2068{fill:#B88B85;}
	.st2069{fill:#BC8271;}
	.st2070{fill:#613532;}
	.st2071{fill:#924C3E;}
	.st2072{fill:#823A2F;}
	.st2073{fill:#BA9FAA;}
	.st2074{fill:#1E3D4F;}
	.st2075{fill:#0346A0;}
	.st2076{fill:#AFB0C0;}
	.st2077{fill:#BCA9BB;}
	.st2078{fill:#86534D;}
	.st2079{fill:#D09BA3;}
	.st2080{fill:#98A0BD;}
	.st2081{fill:#B0B2CE;}
	.st2082{fill:#CE5740;}
	.st2083{fill:#E46A3A;}
	.st2084{fill:#E06A3D;}
	.st2085{fill:#A42403;}
	.st2086{fill:#1D477A;}
	.st2087{fill:#1F3F63;}
	.st2088{fill:#0D4975;}
	.st2089{fill:#8AB4C6;}
	.st2090{fill:#22649F;}
	.st2091{fill:#D6B184;}
	.st2092{fill:#234159;}
	.st2093{fill:#308FC0;}
	.st2094{fill:#6A4A3A;}
	.st2095{fill:#4E3628;}
	.st2096{fill:#D35B2D;}
	.st2097{fill:#DA8865;}
	.st2098{fill:#E2A68B;}
	.st2099{fill:#CEA48F;}
	.st2100{fill:#CEC9D6;}
	.st2101{fill:#CFB1AE;}
	.st2102{fill:#B9B4C4;}
	.st2103{fill:#BAC0D9;}
	.st2104{fill:#C69957;}
	.st2105{fill:#EAC584;}
	.st2106{fill:#112539;}
	.st2107{fill:#102D48;}
	.st2108{fill:#3A3946;}
	.st2109{fill:#5F5763;}
	.st2110{fill:#2F2F39;}
	.st2111{fill:#0C2E58;}
	.st2112{fill:#B49971;}
	.st2113{fill:#406488;}
	.st2114{fill:#355D82;}
	.st2115{fill:#335269;}
	.st2116{fill:#B4AEBD;}
	.st2117{fill:#05284E;}
	.st2118{fill:#485158;}
	.st2119{fill:#56677F;}
	.st2120{fill:#152132;}
	.st2121{fill:#271919;}
	.st2122{fill:#B2979F;}
	.st2123{fill:#C3B6C4;}
	.st2124{fill:#D67156;}
	.st2125{fill:#892614;}
	.st2126{fill:#E27151;}
	.st2127{fill:#9F3324;}
	.st2128{fill:#9E4E48;}
	.st2129{fill:#5C3D38;}
	.st2130{fill:#0E3FA5;}
	.st2131{fill:#F69B3C;}
	.st2132{fill:#CE8833;}
	.st2133{fill:#A57641;}
	.st2134{fill:#CA8029;}
	.st2135{fill:#EBBEB2;}
	.st2136{fill:#E5B4AE;}
	.st2137{fill:#E6AFA7;}
	.st2138{fill:#EAAA97;}
	.st2139{fill:#61686D;}
	.st2140{fill:#3C4655;}
	.st2141{fill:#465055;}
	.st2142{fill:#6C6165;}
	.st2143{fill:#EBD3D6;}
	.st2144{fill:#DFB6B5;}
	.st2145{fill:#D2C8D2;}
	.st2146{fill:#CDC0CB;}
	.st2147{fill:#D5B8BE;}
	.st2148{fill:#DFBABB;}
	.st2149{fill:#EBBAB1;}
	.st2150{fill:#ECCC81;}
	.st2151{fill:#EDAC97;}
	.st2152{fill:#F4AD5A;}
	.st2153{fill:#EF9767;}
	.st2154{fill:#C2A3AA;}
	.st2155{fill:#C4A7AD;}
	.st2156{fill:#1D253F;}
	.st2157{fill:#C5AEB8;}
	.st2158{fill:#C19BA1;}
	.st2159{fill:#97160E;}
	.st2160{fill:#C68B88;}
	.st2161{fill:#C38078;}
	.st2162{fill:#503E6B;}
	.st2163{fill:#B48CA0;}
	.st2164{fill:#AE8FA1;}
	.st2165{fill:#5F3537;}
	.st2166{fill:#5E5668;}
	.st2167{fill:#977B75;}
	.st2168{fill:#807DB0;}
	.st2169{fill:#A19EA7;}
	.st2170{fill:#8193B0;}
	.st2171{fill:#F6D2A2;}
	.st2172{fill:#E0C897;}
	.st2173{fill:#D6B388;}
	.st2174{fill:#D7BFC6;}
	.st2175{fill:#DDB1AE;}
	.st2176{fill:#D4B7BC;}
	.st2177{fill:#A99BA9;}
	.st2178{fill:#864D3E;}
	.st2179{fill:#711D0A;}
	.st2180{fill:#641807;}
	.st2181{fill:#CBA5A8;}
	.st2182{fill:#D59794;}
	.st2183{fill:#E28A70;}
	.st2184{fill:#D59082;}
	.st2185{fill:#F5B659;}
	.st2186{fill:#AB9C8A;}
	.st2187{fill:#9EC6EE;}
	.st2188{fill:#C3C3D2;}
	.st2189{fill:#E8AC5E;}
	.st2190{fill:#C6BAC7;}
	.st2191{fill:#E28E7C;}
	.st2192{fill:#BC340E;}
	.st2193{fill:#E66428;}
	.st2194{fill:#D85026;}
	.st2195{fill:#DF918D;}
	.st2196{fill:#857577;}
	.st2197{fill:#543B49;}
	.st2198{fill:#D4ACB1;}
	.st2199{fill:#9598A1;}
	.st2200{fill:#8E90A0;}
	.st2201{fill:#CE8971;}
	.st2202{fill:#938595;}
	.st2203{fill:#9F8F9F;}
	.st2204{fill:#A94A37;}
	.st2205{fill:#88422D;}
	.st2206{fill:#3F0D0B;}
	.st2207{fill:#A77267;}
	.st2208{fill:#763124;}
	.st2209{fill:#B1A3B0;}
	.st2210{fill:#BDA4AF;}
	.st2211{fill:#A02D09;}
	.st2212{fill:#71C5FA;}
	.st2213{fill:#0670B5;}
	.st2214{fill:#A8CADC;}
	.st2215{fill:#732906;}
	.st2216{fill:#964E1F;}
	.st2217{fill:#D4D2DC;}
	.st2218{fill:#444D5C;}
	.st2219{fill:#35374C;}
	.st2220{fill:#C0B0BA;}
	.st2221{fill:#CCCBDB;}
	.st2222{fill:#BEBACC;}
	.st2223{fill:#EDD1BB;}
	.st2224{fill:#A79087;}
	.st2225{fill:#203043;}
	.st2226{fill:#9A4232;}
	.st2227{fill:#3A222C;}
	.st2228{fill:#5C2A2F;}
	.st2229{fill:#733C46;}
	.st2230{fill:#195885;}
	.st2231{fill:#153B59;}
	.st2232{fill:#4D5B73;}
	.st2233{fill:#63564D;}
	.st2234{fill:#34363C;}
	.st2235{fill:#563D2A;}
	.st2236{fill:#2E5C72;}
	.st2237{fill:#B2A4B5;}
	.st2238{fill:#BDA9B5;}
	.st2239{fill:#AB9CAB;}
	.st2240{fill:#A297A4;}
	.st2241{fill:#908893;}
	.st2242{fill:#41303F;}
	.st2243{fill:#3F1A1B;}
	.st2244{fill:#A01E08;}
	.st2245{fill:#A22A12;}
	.st2246{fill:#B3230B;}
	.st2247{fill:#BF583E;}
	.st2248{fill:#F77A2E;}
	.st2249{fill:#E77E3E;}
	.st2250{fill:#C2500C;}
	.st2251{fill:#AE5B13;}
	.st2252{fill:#E79A83;}
	.st2253{fill:#E2ACAF;}
	.st2254{fill:#E0A7A2;}
	.st2255{fill:#DE9C98;}
	.st2256{fill:#F5E3B1;}
	.st2257{fill:#EBD8BC;}
	.st2258{fill:#CFBCBF;}
	.st2259{fill:#E7C2C2;}
	.st2260{fill:#C6B9C5;}
	.st2261{fill:#BDAEBA;}
	.st2262{fill:#C7B5C0;}
	.st2263{fill:#CAB1B9;}
	.st2264{fill:#D0AEB2;}
	.st2265{fill:#DFB3B0;}
	.st2266{fill:#E5ABA5;}
	.st2267{fill:#F2BF67;}
	.st2268{fill:#E69E8A;}
	.st2269{fill:#E68668;}
	.st2270{fill:#DF9D49;}
	.st2271{fill:#5A2413;}
	.st2272{fill:#E5AA71;}
	.st2273{fill:#E59479;}
	.st2274{fill:#C3AAB3;}
	.st2275{fill:#BDA7B0;}
	.st2276{fill:#D0A0A2;}
	.st2277{fill:#CAA6AA;}
	.st2278{fill:#CE9595;}
	.st2279{fill:#D57665;}
	.st2280{fill:#C02B12;}
	.st2281{fill:#E03D1E;}
	.st2282{fill:#BF2B0E;}
	.st2283{fill:#D68679;}
	.st2284{fill:#D58F86;}
	.st2285{fill:#AF8566;}
	.st2286{fill:#F8E1BB;}
	.st2287{fill:#9E8F8B;}
	.st2288{fill:#E59C4A;}
	.st2289{fill:#D98F41;}
	.st2290{fill:#CBAFB5;}
	.st2291{fill:#B8AAB5;}
	.st2292{fill:#958B99;}
	.st2293{fill:#908CA3;}
	.st2294{fill:#5B5867;}
	.st2295{fill:#BA857C;}
	.st2296{fill:#1D2D44;}
	.st2297{fill:#DC7A5B;}
	.st2298{fill:#D0856F;}
	.st2299{fill:#95381E;}
	.st2300{fill:#DB8872;}
	.st2301{fill:#C36D59;}
	.st2302{fill:#C29EA2;}
	.st2303{fill:#BEA8AF;}
	.st2304{fill:#C09292;}
	.st2305{fill:#CB6147;}
	.st2306{fill:#F3BA47;}
	.st2307{fill:#B6B9C7;}
	.st2308{fill:#DFE2EF;}
	.st2309{fill:#F4E2D7;}
	.st2310{fill:#B0AEC2;}
	.st2311{fill:#B63703;}
	.st2312{fill:#CAB7BB;}
	.st2313{fill:#B4502F;}
	.st2314{fill:#724D4B;}
	.st2315{fill:#85798B;}
	.st2316{fill:#8D5252;}
	.st2317{fill:#7A6F81;}
	.st2318{fill:#521D15;}
	.st2319{fill:#38182A;}
	.st2320{fill:#4A3238;}
	.st2321{fill:#3E1B1A;}
	.st2322{fill:#AD4D1B;}
	.st2323{fill:#557A94;}
	.st2324{fill:#191932;}
	.st2325{fill:#24709B;}
	.st2326{fill:#2A7AAF;}
	.st2327{fill:#312B2F;}
	.st2328{fill:#C3B6BF;}
	.st2329{fill:#152334;}
	.st2330{fill:#CEB6BC;}
	.st2331{fill:#96909B;}
	.st2332{fill:#113659;}
	.st2333{fill:#D0AEB4;}
	.st2334{fill:#504F5D;}
	.st2335{fill:#203042;}
	.st2336{fill:#352E3A;}
	.st2337{fill:#7D6855;}
	.st2338{fill:#D9DAE2;}
	.st2339{fill:#A6A5B9;}
	.st2340{fill:#C6AA92;}
	.st2341{fill:#4D160E;}
	.st2342{fill:#401C13;}
	.st2343{fill:#261521;}
	.st2344{fill:#883A31;}
	.st2345{fill:#1E5D8D;}
	.st2346{fill:#124C75;}
	.st2347{fill:#141D29;}
	.st2348{fill:#1E4A77;}
	.st2349{fill:#62656D;}
	.st2350{fill:#B79EAC;}
	.st2351{fill:#C9A2A8;}
	.st2352{fill:#AF92A2;}
	.st2353{fill:#E26034;}
	.st2354{fill:#E57D37;}
	.st2355{fill:#CD7F29;}
	.st2356{fill:#C67044;}
	.st2357{fill:#975D41;}
	.st2358{fill:#BFB2BF;}
	.st2359{fill:#C2B1BC;}
	.st2360{fill:#C9ABB3;}
	.st2361{fill:#D8AAAA;}
	.st2362{fill:#DB9E9C;}
	.st2363{fill:#DA968E;}
	.st2364{fill:#E06E37;}
	.st2365{fill:#D37B49;}
	.st2366{fill:#E86C45;}
	.st2367{fill:#451D10;}
	.st2368{fill:#DF5F1A;}
	.st2369{fill:#D2965E;}
	.st2370{fill:#CC2C04;}
	.st2371{fill:#F14A24;}
	.st2372{fill:#A55042;}
	.st2373{fill:#A23F3A;}
	.st2374{fill:#2F212C;}
	.st2375{fill:#2C4B69;}
	.st2376{fill:#28475F;}
	.st2377{fill:#A79198;}
	.st2378{fill:#9A8691;}
	.st2379{fill:#0C2C4D;}
	.st2380{fill:#09182C;}
	.st2381{fill:#B4A1A9;}
	.st2382{fill:#B6979D;}
	.st2383{fill:#74423D;}
	.st2384{fill:#D36943;}
	.st2385{fill:#6A687E;}
	.st2386{fill:#6D6679;}
	.st2387{fill:#783637;}
	.st2388{fill:#6A4748;}
	.st2389{fill:#170F17;}
	.st2390{fill:#615C74;}
	.st2391{fill:#7B4342;}
	.st2392{fill:#85402A;}
	.st2393{fill:#613638;}
	.st2394{fill:#23181B;}
	.st2395{fill:#1A4161;}
	.st2396{fill:#605E66;}
	.st2397{fill:#3D303B;}
	.st2398{fill:#9F9CB0;}
	.st2399{fill:#A899A8;}
	.st2400{fill:#276193;}
	.st2401{fill:#CE948E;}
	.st2402{fill:#BD8782;}
	.st2403{fill:#311A18;}
	.st2404{fill:#4374A2;}
	.st2405{fill:#3D697F;}
	.st2406{fill:#354D6C;}
	.st2407{fill:#9E7D67;}
	.st2408{fill:#C5A47E;}
	.st2409{fill:#42688E;}
	.st2410{fill:#3C1F1D;}
	.st2411{fill:#1C3954;}
	.st2412{fill:#42383C;}
	.st2413{fill:#A78991;}
	.st2414{fill:#B58E8E;}
	.st2415{fill:#DB8250;}
	.st2416{fill:#D83807;}
	.st2417{fill:#1F130F;}
	.st2418{fill:#521421;}
	.st2419{fill:#3078B6;}
	.st2420{fill:#155995;}
	.st2421{fill:#226393;}
	.st2422{fill:#12416F;}
	.st2423{fill:#20233E;}
	.st2424{fill:#45272E;}
	.st2425{fill:#261A25;}
	.st2426{fill:#37475C;}
	.st2427{fill:#64525D;}
	.st2428{fill:#4C536C;}
	.st2429{fill:#9A4730;}
	.st2430{fill:#484A55;}
	.st2431{fill:#0B4988;}
	.st2432{fill:#934A2D;}
	.st2433{fill:#8F4F4A;}
	.st2434{fill:#6A4436;}
	.st2435{fill:#2672AA;}
	.st2436{fill:#202E3D;}
	.st2437{fill:#351925;}
	.st2438{fill:#A36065;}
	.st2439{fill:#342E56;}
	.st2440{fill:#FBAF49;}
	.st2441{fill:#D59F61;}
	.st2442{fill:#F8C767;}
	.st2443{fill:#D26C3F;}
	.st2444{fill:#7D3E3B;}
	.st2445{fill:#C27A54;}
	.st2446{fill:#DC996A;}
	.st2447{fill:#DD924A;}
	.st2448{fill:#B27D5C;}
	.st2449{fill:#D3A664;}
	.st2450{fill:#9B4624;}
	.st2451{fill:#BD4418;}
	.st2452{fill:#D83E1A;}
	.st2453{fill:#C1A872;}
	.st2454{fill:#F9D7AA;}
	.st2455{fill:#BBB17E;}
	.st2456{fill:#BEA068;}
	.st2457{fill:#CFA367;}
	.st2458{fill:#98E8D5;}
	.st2459{fill:#EE7532;}
	.st2460{fill:#C88E5E;}
	.st2461{fill:#3C96C8;}
	.st2462{fill:#B16E50;}
	.st2463{fill:#4D3C43;}
	.st2464{fill:#C5AC75;}
	.st2465{fill:#D25B31;}
	.st2466{fill:#994943;}
	.st2467{fill:#C14A0A;}
	.st2468{fill:#972B12;}
	.st2469{fill:#DD9B4D;}
	.st2470{fill:#B7AC61;}
	.st2471{fill:#AA7E61;}
	.st2472{fill:#78BABB;}
	.st2473{fill:#B44713;}
	.st2474{fill:#B24C18;}
	.st2475{fill:#CFA755;}
	.st2476{fill:#5EB8BE;}
	.st2477{fill:#3CACD1;}
	.st2478{fill:#BFA65B;}
	.st2479{fill:#8C6B69;}
	.st2480{fill:#BBAF65;}
	.st2481{fill:#82989F;}
	.st2482{fill:#F29D41;}
	.st2483{fill:#CE8654;}
	.st2484{fill:#4FC4CE;}
	.st2485{fill:#7EE0B4;}
	.st2486{fill:#54AEC8;}
	.st2487{fill:#5E8AAB;}
	.st2488{fill:#457AA8;}
	.st2489{fill:#A1987D;}
	.st2490{fill:#E2BF75;}
	.st2491{fill:#5DA7C0;}
	.st2492{fill:#EB9C41;}
	.st2493{fill:#838287;}
	.st2494{fill:#955556;}
	.st2495{fill:#B3AE6B;}
	.st2496{fill:#C05725;}
	.st2497{fill:#B0400F;}
	.st2498{fill:#C0B06D;}
	.st2499{fill:#BF9365;}
	.st2500{fill:#D0AA5C;}
	.st2501{fill:#D59E4F;}
	.st2502{fill:#9D735F;}
	.st2503{fill:#B89069;}
	.st2504{fill:#D0A662;}
	.st2505{fill:#CFA86B;}
	.st2506{fill:#C36B26;}
	.st2507{fill:#975B56;}
	.st2508{fill:#BFAB60;}
	.st2509{fill:#BFA462;}
	.st2510{fill:#DDA37E;}
	.st2511{fill:#CD935B;}
	.st2512{fill:#B4AB61;}
	.st2513{fill:#B57959;}
	.st2514{fill:#CE490F;}
	.st2515{fill:#B88C59;}
	.st2516{fill:#BC8257;}
	.st2517{fill:#23629E;}
	.st2518{fill:#AA6B54;}
	.st2519{fill:#C36B3D;}
	.st2520{fill:#A4504C;}
	.st2521{fill:#91514E;}
	.st2522{fill:#8D585B;}
	.st2523{fill:#C18A5F;}
	.st2524{fill:#C57849;}
	.st2525{fill:#D0A163;}
	.st2526{fill:#E3A862;}
	.st2527{fill:#B9AF60;}
	.st2528{fill:#B4B468;}
	.st2529{fill:#AEA06E;}
	.st2530{fill:#C1A25F;}
	.st2531{fill:#A14B41;}
	.st2532{fill:#AB5549;}
	.st2533{fill:#CDA862;}
	.st2534{fill:#9A544B;}
	.st2535{fill:#9A534B;}
	.st2536{fill:#9C8875;}
	.st2537{fill:#D79853;}
	.st2538{fill:#D5A355;}
	.st2539{fill:#D06120;}
	.st2540{fill:#AB594D;}
	.st2541{fill:#984D3E;}
	.st2542{fill:#ACA775;}
	.st2543{fill:#E99E50;}
	.st2544{fill:#E79A52;}
	.st2545{fill:#D5A658;}
	.st2546{fill:#F6BA55;}
	.st2547{fill:#CC9B57;}
	.st2548{fill:#BB752E;}
	.st2549{fill:#9B5143;}
	.st2550{fill:#ACAA72;}
	.st2551{fill:#C78F53;}
	.st2552{fill:#D5A45E;}
	.st2553{fill:#DEA252;}
	.st2554{fill:#CD9E60;}
	.st2555{fill:#C4AB56;}
	.st2556{fill:#D3A360;}
	.st2557{fill:#D7A054;}
	.st2558{fill:#74606F;}
	.st2559{fill:#A64743;}
	.st2560{fill:#D24509;}
	.st2561{fill:#E7A140;}
	.st2562{fill:#E09F58;}
	.st2563{fill:#CB9655;}
	.st2564{fill:#C59F62;}
	.st2565{fill:#CEAB56;}
	.st2566{fill:#D3A63D;}
	.st2567{fill:#D7A341;}
	.st2568{fill:#CF914B;}
	.st2569{fill:#FEA740;}
	.st2570{fill:#949F8A;}
	.st2571{fill:#E6AA62;}
	.st2572{fill:#E5A146;}
	.st2573{fill:#E7A249;}
	.st2574{fill:#CE995B;}
	.st2575{fill:#D2A651;}
	.st2576{fill:#E8A150;}
	.st2577{fill:#F8B24C;}
	.st2578{fill:#C3AF71;}
	.st2579{fill:#B7B263;}
	.st2580{fill:#B24638;}
	.st2581{fill:#BBA759;}
	.st2582{fill:#C3A347;}
	.st2583{fill:#F2B368;}
	.st2584{fill:#D6A751;}
	.st2585{fill:#AF4412;}
	.st2586{fill:#D3A34C;}
	.st2587{fill:#EB9543;}
	.st2588{fill:#D0A853;}
	.st2589{fill:#E69B3F;}
	.st2590{fill:#D99F41;}
	.st2591{fill:#B2483F;}
	.st2592{fill:#EA9D44;}
	.st2593{fill:#153F78;}
	.st2594{fill:#A96B74;}
	.st2595{fill:#A6696C;}
	.st2596{fill:#AA727B;}
	.st2597{fill:#9F5955;}
	.st2598{fill:#173570;}
	.st2599{fill:#1C365F;}
	.st2600{fill:#313C50;}
	.st2601{fill:#6C8EB1;}
	.st2602{fill:#243F6D;}
	.st2603{fill:#F8C35E;}
	.st2604{fill:#A2DAE0;}
	.st2605{fill:#1679A6;}
	.st2606{fill:#136087;}
	.st2607{fill:#5AC5D1;}
	.st2608{fill:#204A54;}
	.st2609{fill:#AAAF84;}
	.st2610{fill:#FAF0B0;}
	.st2611{fill:#FCE89E;}
	.st2612{fill:#F9E083;}
	.st2613{fill:#C15C44;}
	.st2614{fill:#C3593B;}
	.st2615{fill:#E99155;}
	.st2616{fill:#AA3F17;}
	.st2617{fill:#C35533;}
	.st2618{fill:#0E4C99;}
	.st2619{fill:#2B4C77;}
	.st2620{fill:#3C4464;}
	.st2621{fill:#44333A;}
	.st2622{fill:#3F353E;}
	.st2623{fill:#4C2C35;}
	.st2624{fill:#6EB5C4;}
	.st2625{fill:#624A4B;}
	.st2626{fill:#59392E;}
	.st2627{fill:#8F5344;}
	.st2628{fill:#2A3C50;}
	.st2629{fill:#A47A6C;}
	.st2630{fill:#596174;}
	.st2631{fill:#144C92;}
	.st2632{fill:#16569B;}
	.st2633{fill:#535873;}
	.st2634{fill:#5E616E;}
	.st2635{fill:#606575;}
	.st2636{fill:#82D0DA;}
	.st2637{fill:#3D0D05;}
	.st2638{fill:#371107;}
	.st2639{fill:#A1573E;}
	.st2640{fill:#A6482A;}
	.st2641{fill:#DE3A16;}
	.st2642{fill:#C13A26;}
	.st2643{fill:#B33525;}
	.st2644{fill:#9E2D23;}
	.st2645{fill:#AB3928;}
	.st2646{fill:#B13C29;}
	.st2647{fill:#A13425;}
	.st2648{fill:#B76652;}
	.st2649{fill:#FBE8D8;}
	.st2650{fill:#6ADFDF;}
	.st2651{fill:#629FB0;}
	.st2652{fill:#81B0BF;}
	.st2653{fill:#ABAD9D;}
	.st2654{fill:#C9A873;}
	.st2655{fill:#52E2E8;}
	.st2656{fill:#2F5278;}
	.st2657{fill:#3E5B78;}
	.st2658{fill:#18AEF2;}
	.st2659{fill:#1EAFF1;}
	.st2660{fill:#4590C2;}
	.st2661{fill:#21ABEF;}
	.st2662{fill:#224369;}
	.st2663{fill:#30334F;}
	.st2664{fill:#4A342E;}
	.st2665{fill:#7AD5CF;}
	.st2666{fill:#62C2E2;}
	.st2667{fill:#731D04;}
	.st2668{fill:#5E2017;}
	.st2669{fill:#790E04;}
	.st2670{fill:#3580B9;}
	.st2671{fill:#E3AD67;}
	.st2672{fill:#7A473F;}
	.st2673{fill:#883E1C;}
	.st2674{fill:#3FCFD6;}
	.st2675{fill:#0E67BA;}
	.st2676{fill:#1B5FA3;}
	.st2677{fill:#29C4F8;}
	.st2678{fill:#3DCDF0;}
	.st2679{fill:#2DBFF6;}
	.st2680{fill:#B46140;}
	.st2681{fill:#74E4D8;}
	.st2682{fill:#9ED7B6;}
	.st2683{fill:#1BC4F9;}
	.st2684{fill:#1DC9F9;}
	.st2685{fill:#3CC6DF;}
	.st2686{fill:#11B6F7;}
	.st2687{fill:#15BAFA;}
	.st2688{fill:#18BEF7;}
	.st2689{fill:#61BFC0;}
	.st2690{fill:#46EEE4;}
	.st2691{fill:#2BBAF6;}
	.st2692{fill:#25B6F4;}
	.st2693{fill:#5B97B7;}
	.st2694{fill:#28B9F5;}
	.st2695{fill:#F4C166;}
	.st2696{fill:#81616C;}
	.st2697{fill:#22D0F8;}
	.st2698{fill:#20CDF9;}
	.st2699{fill:#32E4E3;}
	.st2700{fill:#48FBFA;}
	.st2701{fill:#35BAE9;}
	.st2702{fill:#1FB4F3;}
	.st2703{fill:#0B8AE1;}
	.st2704{fill:#0B85DD;}
	.st2705{fill:#0B76D4;}
	.st2706{fill:#0F90E1;}
	.st2707{fill:#296094;}
	.st2708{fill:#0C7CDA;}
	.st2709{fill:#FAFBF8;}
	.st2710{fill:#CF9C51;}
	.st2711{fill:#FAF5E3;}
	.st2712{fill:#4C86B1;}
	.st2713{fill:#488AB8;}
	.st2714{fill:#44DCE3;}
	.st2715{fill:#954640;}
	.st2716{fill:#A55336;}
	.st2717{fill:#4AEAE2;}
	.st2718{fill:#727B90;}
	.st2719{fill:#6D758E;}
	.st2720{fill:#935D3C;}
	.st2721{fill:#796161;}
	.st2722{fill:#A25957;}
	.st2723{fill:#AC5742;}
	.st2724{fill:#597899;}
	.st2725{fill:#E8F1ED;}
	.st2726{fill:#D0D7D4;}
	.st2727{fill:#0F4A8E;}
	.st2728{fill:#AE8DA0;}
	.st2729{fill:#C1D8E9;}
	.st2730{fill:#123E73;}
	.st2731{fill:#133F79;}
	.st2732{fill:#133B75;}
	.st2733{fill:#183D76;}
	.st2734{fill:#133A79;}
	.st2735{fill:#0B4083;}
	.st2736{fill:#123A70;}
	.st2737{fill:#09B3E3;}
	.st2738{fill:#47E3EE;}
	.st2739{fill:#4BE4F2;}
	.st2740{fill:#0690D1;}
	.st2741{fill:#2DB9DE;}
	.st2742{fill:#33C3F0;}
	.st2743{fill:#26D6F0;}
	.st2744{fill:#0D5D96;}
	.st2745{fill:#F7F7C5;}
	.st2746{fill:#FAF5BF;}
	.st2747{fill:#722E08;}
	.st2748{fill:#F8E6AB;}
	.st2749{fill:#2F3662;}
	.st2750{fill:#CA5E30;}
	.st2751{fill:#A15141;}
	.st2752{fill:#F5C26B;}
	.st2753{fill:#593E51;}
	.st2754{fill:#F1AE60;}
	.st2755{fill:#DDBC7D;}
	.st2756{fill:#095FBA;}
	.st2757{fill:#0954A7;}
	.st2758{fill:#176EB5;}
	.st2759{fill:#1981D1;}
	.st2760{fill:#19324A;}
	.st2761{fill:#2D3D5C;}
	.st2762{fill:#3D181A;}
	.st2763{fill:#E1EDF5;}
	.st2764{fill:#2AD4F8;}
	.st2765{fill:#BBF9FC;}
	.st2766{fill:#4CCAE5;}
	.st2767{fill:#25354F;}
	.st2768{fill:#5F423F;}
	.st2769{fill:#97614A;}
	.st2770{fill:#192D48;}
	.st2771{fill:#144683;}
	.st2772{fill:#0A59AD;}
	.st2773{fill:#0965C3;}
	.st2774{fill:#2C213B;}
	.st2775{fill:#CD5C2E;}
	.st2776{fill:#B36754;}
	.st2777{fill:#C1623F;}
	.st2778{fill:#C06A4F;}
	.st2779{fill:#B7745F;}
	.st2780{fill:#4D1C1A;}
	.st2781{fill:#C2765C;}
	.st2782{fill:#231415;}
	.st2783{fill:#E2752C;}
	.st2784{fill:#F75115;}
	.st2785{fill:#D05325;}
	.st2786{fill:#EF4414;}
	.st2787{fill:#D12A11;}
	.st2788{fill:#BF321F;}
	.st2789{fill:#D43A1B;}
	.st2790{fill:#A1311F;}
	.st2791{fill:#812F24;}
	.st2792{fill:#CD3124;}
	.st2793{fill:#912C24;}
	.st2794{fill:#A3545A;}
	.st2795{fill:#4D2123;}
	.st2796{fill:#C27453;}
	.st2797{fill:#4A242A;}
	.st2798{fill:#F6F2EE;}
	.st2799{fill:#0EA0ED;}
	.st2800{fill:#10ABF5;}
	.st2801{fill:#42B0EB;}
	.st2802{fill:#6DAAD2;}
	.st2803{fill:#18759A;}
	.st2804{fill:#21EAFD;}
	.st2805{fill:#0E70B9;}
	.st2806{fill:#30E9F4;}
	.st2807{fill:#C79760;}
	.st2808{fill:#0C56A0;}
	.st2809{fill:#145FA8;}
	.st2810{fill:#11488C;}
	.st2811{fill:#556F99;}
	.st2812{fill:#1CABF5;}
	.st2813{fill:#1DADF2;}
	.st2814{fill:#14ABF3;}
	.st2815{fill:#1BB4E8;}
	.st2816{fill:#1DAFF9;}
	.st2817{fill:#39D8F5;}
	.st2818{fill:#39EEF9;}
	.st2819{fill:#40BFF5;}
	.st2820{fill:#EB9251;}
	.st2821{fill:#E18A3B;}
	.st2822{fill:#F6A46E;}
	.st2823{fill:#C68554;}
	.st2824{fill:#1EAAEF;}
	.st2825{fill:#241D26;}
	.st2826{fill:#333043;}
	.st2827{fill:#F1B66F;}
	.st2828{fill:#19D9F9;}
	.st2829{fill:#18E4F7;}
	.st2830{fill:#41FCFD;}
	.st2831{fill:#19EBF5;}
	.st2832{fill:#1EDDF4;}
	.st2833{fill:#096AC7;}
	.st2834{fill:#2164A4;}
	.st2835{fill:#1E60A2;}
	.st2836{fill:#26CAFA;}
	.st2837{fill:#27CEFA;}
	.st2838{fill:#C0F2F9;}
	.st2839{fill:#36DFFB;}
	.st2840{fill:#43DBEA;}
	.st2841{fill:#37F0F1;}
	.st2842{fill:#12CEF1;}
	.st2843{fill:#0FB0F2;}
	.st2844{fill:#09C5EE;}
	.st2845{fill:#28BFF7;}
	.st2846{fill:#25C2F5;}
	.st2847{fill:#34ADE8;}
	.st2848{fill:#28B4F2;}
	.st2849{fill:#045FA5;}
	.st2850{fill:#22D3ED;}
	.st2851{fill:#19BEE9;}
	.st2852{fill:#28485E;}
	.st2853{fill:#546881;}
	.st2854{fill:#A1626E;}
	.st2855{fill:#626B84;}
	.st2856{fill:#A15A5F;}
	.st2857{fill:#6F7181;}
	.st2858{fill:#25D5F9;}
	.st2859{fill:#20DAEE;}
	.st2860{fill:#20D4F0;}
	.st2861{fill:#2EEEF2;}
	.st2862{fill:#15516F;}
	.st2863{fill:#696345;}
	.st2864{fill:#3BCDAE;}
	.st2865{fill:#AAAD68;}
	.st2866{fill:#4AC9A8;}
	.st2867{fill:#27E5E6;}
	.st2868{fill:#4EAFDF;}
	.st2869{fill:#36BEFA;}
	.st2870{fill:#28B9EC;}
	.st2871{fill:#4BB9E8;}
	.st2872{fill:#17BBE6;}
	.st2873{fill:#15B6E2;}
	.st2874{fill:#0D97E8;}
	.st2875{fill:#25CBEC;}
	.st2876{fill:#0597F1;}
	.st2877{fill:#0CA6E0;}
	.st2878{fill:#0F76CB;}
	.st2879{fill:#0B6ECE;}
	.st2880{fill:#095CBA;}
	.st2881{fill:#0A57B5;}
	.st2882{fill:#0762C3;}
	.st2883{fill:#0F50A9;}
	.st2884{fill:#FCFCFC;}
	.st2885{fill:#F9FBFD;}
	.st2886{fill:#FBFBF6;}
	.st2887{fill:#1C519A;}
	.st2888{fill:#FAFAF7;}
	.st2889{fill:#E3DFC0;}
	.st2890{fill:#1BA6EF;}
	.st2891{fill:#2EECF6;}
	.st2892{fill:#2DE7F6;}
	.st2893{fill:#27E0F8;}
	.st2894{fill:#3BDAE6;}
	.st2895{fill:#72829C;}
	.st2896{fill:#6C7C97;}
	.st2897{fill:#667189;}
	.st2898{fill:#66768F;}
	.st2899{fill:#0766C0;}
	.st2900{fill:#0A5FBA;}
	.st2901{fill:#DFEBF2;}
	.st2902{fill:#E2FAFB;}
	.st2903{fill:#F7F0FA;}
	.st2904{fill:#BBB7CA;}
	.st2905{fill:#057ACF;}
	.st2906{fill:#2478B0;}
	.st2907{fill:#EDFAFA;}
	.st2908{fill:#CAF2FA;}
	.st2909{fill:#0E407E;}
	.st2910{fill:#1B6FD0;}
	.st2911{fill:#0A7CBD;}
	.st2912{fill:#1C79D1;}
	.st2913{fill:#1ED6FA;}
	.st2914{fill:#1455A8;}
	.st2915{fill:#35DCF9;}
	.st2916{fill:#07597F;}
	.st2917{fill:#F8F9CE;}
	.st2918{fill:#8C6622;}
	.st2919{fill:#D1CBB0;}
	.st2920{fill:#80480D;}
	.st2921{fill:#0D4392;}
	.st2922{fill:#045EAB;}
	.st2923{fill:#055297;}
	.st2924{fill:#2CC5ED;}
	.st2925{fill:#12418B;}
	.st2926{fill:#3F243B;}
	.st2927{fill:#472D54;}
	.st2928{fill:#FCD58C;}
	.st2929{fill:#D27337;}
	.st2930{fill:#A83C30;}
	.st2931{fill:#383048;}
	.st2932{fill:#54C4F5;}
	.st2933{fill:#0859B3;}
	.st2934{fill:#0764C2;}
	.st2935{fill:#086BC9;}
	.st2936{fill:#0872CD;}
	.st2937{fill:#0F86DA;}
	.st2938{fill:#0979D1;}
	.st2939{fill:#1B89E1;}
	.st2940{fill:#1193E5;}
	.st2941{fill:#112B48;}
	.st2942{fill:#103052;}
	.st2943{fill:#0E2F4D;}
	.st2944{fill:#1D3F72;}
	.st2945{fill:#E8EFF9;}
	.st2946{fill:#F6D7D6;}
	.st2947{fill:#4FDBE7;}
	.st2948{fill:#ECF4F9;}
	.st2949{fill:#D1FCFB;}
	.st2950{fill:#46DCE6;}
	.st2951{fill:#193458;}
	.st2952{fill:#32415A;}
	.st2953{fill:#A66556;}
	.st2954{fill:#514854;}
	.st2955{fill:#0D4799;}
	.st2956{fill:#0C4DA1;}
	.st2957{fill:#391B1D;}
	.st2958{fill:#221A31;}
	.st2959{fill:#EA6B28;}
	.st2960{fill:#F26114;}
	.st2961{fill:#39283A;}
	.st2962{fill:#EF5816;}
	.st2963{fill:#471515;}
	.st2964{fill:#381A20;}
	.st2965{fill:#DB5929;}
	.st2966{fill:#102264;}
	.st2967{fill:#E85717;}
	.st2968{fill:#C55735;}
	.st2969{fill:#252946;}
	.st2970{fill:#FA8929;}
	.st2971{fill:#F8ECA5;}
	.st2972{fill:#FAAB4E;}
	.st2973{fill:#FAD17C;}
	.st2974{fill:#FADF9F;}
	.st2975{fill:#FABF62;}
	.st2976{fill:#FC7821;}
	.st2977{fill:#FD691C;}
	.st2978{fill:#FA6014;}
	.st2979{fill:#F55621;}
	.st2980{fill:#D73714;}
	.st2981{fill:#B51D03;}
	.st2982{fill:#D6461D;}
	.st2983{fill:#622736;}
	.st2984{fill:#E54318;}
	.st2985{fill:#8F4E54;}
	.st2986{fill:#95331A;}
	.st2987{fill:#58201F;}
	.st2988{fill:#3A2035;}
	.st2989{fill:#642935;}
	.st2990{fill:#65150D;}
	.st2991{fill:#A33527;}
	.st2992{fill:#8E5E63;}
	.st2993{fill:#F5F6F3;}
	.st2994{fill:#CD7B4E;}
	.st2995{fill:#0C365E;}
	.st2996{fill:#391D18;}
	.st2997{fill:#334568;}
	.st2998{fill:#0CA9F0;}
	.st2999{fill:#3BADEF;}
	.st3000{fill:#3CB7F4;}
	.st3001{fill:#2AB4EF;}
	.st3002{fill:#31CBFC;}
	.st3003{fill:#17BEF1;}
	.st3004{fill:#0E55AC;}
	.st3005{fill:#0856AB;}
	.st3006{fill:#083667;}
	.st3007{fill:#1AB5F8;}
	.st3008{fill:#27A6DA;}
	.st3009{fill:#33A2CE;}
	.st3010{fill:#17B2F2;}
	.st3011{fill:#15ADDE;}
	.st3012{fill:#4FE6FB;}
	.st3013{fill:#38DFFA;}
	.st3014{fill:#19B3E0;}
	.st3015{fill:#36E6F9;}
	.st3016{fill:#14B5DE;}
	.st3017{fill:#32CDFB;}
	.st3018{fill:#2AD8FC;}
	.st3019{fill:#2FD3FA;}
	.st3020{fill:#31E6FB;}
	.st3021{fill:#26EEF8;}
	.st3022{fill:#26F5FA;}
	.st3023{fill:#35BAF7;}
	.st3024{fill:#37C2F8;}
	.st3025{fill:#1FB3F4;}
	.st3026{fill:#24B2EE;}
	.st3027{fill:#1FADE9;}
	.st3028{fill:#36A9DC;}
	.st3029{fill:#1CE2F4;}
	.st3030{fill:#33F0F4;}
	.st3031{fill:#086ECB;}
	.st3032{fill:#0A70C7;}
	.st3033{fill:#28C8F2;}
	.st3034{fill:#20C9FA;}
	.st3035{fill:#2AC2F7;}
	.st3036{fill:#E69639;}
	.st3037{fill:#3A453B;}
	.st3038{fill:#3A4E5C;}
	.st3039{fill:#2F6B76;}
	.st3040{fill:#1C5157;}
	.st3041{fill:#375E62;}
	.st3042{fill:#3FE3FD;}
	.st3043{fill:#D8E4EE;}
	.st3044{fill:#34C8E8;}
	.st3045{fill:#23CFEF;}
	.st3046{fill:#C2EDF9;}
	.st3047{fill:#D0EAF1;}
	.st3048{fill:#23DBF3;}
	.st3049{fill:#1DD7E9;}
	.st3050{fill:#0AB9EA;}
	.st3051{fill:#34DCF4;}
	.st3052{fill:#24BFF7;}
	.st3053{fill:#37DAFD;}
	.st3054{fill:#14C5F3;}
	.st3055{fill:#1BC2F7;}
	.st3056{fill:#29CAF3;}
	.st3057{fill:#04588A;}
	.st3058{fill:#E7923C;}
	.st3059{fill:#1E2E46;}
	.st3060{fill:#094F8C;}
	.st3061{fill:#445545;}
	.st3062{fill:#2C3438;}
	.st3063{fill:#414C47;}
	.st3064{fill:#191A1E;}
	.st3065{fill:#015684;}
	.st3066{fill:#1DBCE1;}
	.st3067{fill:#31EAFC;}
	.st3068{fill:#1FD7F1;}
	.st3069{fill:#1C3E52;}
	.st3070{fill:#28313E;}
	.st3071{fill:#224459;}
	.st3072{fill:#5F708A;}
	.st3073{fill:#311A24;}
	.st3074{fill:#906973;}
	.st3075{fill:#441E26;}
	.st3076{fill:#30F4FC;}
	.st3077{fill:#49FAFC;}
	.st3078{fill:#2AE9E8;}
	.st3079{fill:#5BDFBF;}
	.st3080{fill:#32E8E8;}
	.st3081{fill:#2A4772;}
	.st3082{fill:#374D65;}
	.st3083{fill:#204D81;}
	.st3084{fill:#DD9F4F;}
	.st3085{fill:#5CCEB3;}
	.st3086{fill:#86B47C;}
	.st3087{fill:#58554F;}
	.st3088{fill:#434C47;}
	.st3089{fill:#2A434D;}
	.st3090{fill:#192030;}
	.st3091{fill:#30C2EA;}
	.st3092{fill:#86BCD6;}
	.st3093{fill:#066FB9;}
	.st3094{fill:#5DDFF5;}
	.st3095{fill:#0B68C9;}
	.st3096{fill:#0D62BF;}
	.st3097{fill:#0466B3;}
	.st3098{fill:#0A54AE;}
	.st3099{fill:#F6F9F8;}
	.st3100{fill:#FBFAF7;}
	.st3101{fill:#FBFBF8;}
	.st3102{fill:#F9F2E0;}
	.st3103{fill:#F2EFEF;}
	.st3104{fill:#F2DD92;}
	.st3105{fill:#D7C582;}
	.st3106{fill:#D8B768;}
	.st3107{fill:#E6F4F7;}
	.st3108{fill:#CBA95F;}
	.st3109{fill:#E0DAC2;}
	.st3110{fill:#FAE2B1;}
	.st3111{fill:#DDD7C3;}
	.st3112{fill:#FDF3E9;}
	.st3113{fill:#E3B066;}
	.st3114{fill:#E7B567;}
	.st3115{fill:#DEDAC8;}
	.st3116{fill:#EBE1DA;}
	.st3117{fill:#DDDACE;}
	.st3118{fill:#1F5093;}
	.st3119{fill:#2AEEF6;}
	.st3120{fill:#34E6F9;}
	.st3121{fill:#7788A3;}
	.st3122{fill:#2D495F;}
	.st3123{fill:#39546C;}
	.st3124{fill:#065BB5;}
	.st3125{fill:#EEFAFB;}
	.st3126{fill:#2EBCE1;}
	.st3127{fill:#27BEDD;}
	.st3128{fill:#C2F7FE;}
	.st3129{fill:#CDCBD8;}
	.st3130{fill:#92DBF2;}
	.st3131{fill:#15B3E2;}
	.st3132{fill:#1B9CC9;}
	.st3133{fill:#DDE2EB;}
	.st3134{fill:#D6E1ED;}
	.st3135{fill:#DDE2ED;}
	.st3136{fill:#0E9BD7;}
	.st3137{fill:#26ACD6;}
	.st3138{fill:#B4F7F9;}
	.st3139{fill:#CFDEE5;}
	.st3140{fill:#BDF0F7;}
	.st3141{fill:#2DB4D4;}
	.st3142{fill:#A8EFF9;}
	.st3143{fill:#1F83DD;}
	.st3144{fill:#1CB1F6;}
	.st3145{fill:#1A528E;}
	.st3146{fill:#074792;}
	.st3147{fill:#17C4EE;}
	.st3148{fill:#0B50A8;}
	.st3149{fill:#3BC8EC;}
	.st3150{fill:#0E4B98;}
	.st3151{fill:#3ACBE8;}
	.st3152{fill:#EB9E6F;}
	.st3153{fill:#CA8A6A;}
	.st3154{fill:#DBA39A;}
	.st3155{fill:#DF9F80;}
	.st3156{fill:#EDB898;}
	.st3157{fill:#F7EB95;}
	.st3158{fill:#E07E3A;}
	.st3159{fill:#CF8F6B;}
	.st3160{fill:#E7933B;}
	.st3161{fill:#769BB5;}
	.st3162{fill:#C0542A;}
	.st3163{fill:#08456B;}
	.st3164{fill:#8D4612;}
	.st3165{fill:#0971CE;}
	.st3166{fill:#0977D2;}
	.st3167{fill:#0D94E2;}
	.st3168{fill:#0B8CDE;}
	.st3169{fill:#0984D9;}
	.st3170{fill:#0A7ED6;}
	.st3171{fill:#1E8FE6;}
	.st3172{fill:#2189E0;}
	.st3173{fill:#169DE9;}
	.st3174{fill:#1995E8;}
	.st3175{fill:#1098E4;}
	.st3176{fill:#092D4A;}
	.st3177{fill:#0D3050;}
	.st3178{fill:#112B47;}
	.st3179{fill:#182D4E;}
	.st3180{fill:#0B3355;}
	.st3181{fill:#08395D;}
	.st3182{fill:#A4AEC2;}
	.st3183{fill:#ABB3C9;}
	.st3184{fill:#9CA9C8;}
	.st3185{fill:#CE9386;}
	.st3186{fill:#A496A4;}
	.st3187{fill:#39E7F5;}
	.st3188{fill:#F0F7FA;}
	.st3189{fill:#B8BDD0;}
	.st3190{fill:#2D6E8B;}
	.st3191{fill:#DFFAFC;}
	.st3192{fill:#297292;}
	.st3193{fill:#1EDCFC;}
	.st3194{fill:#23E9FC;}
	.st3195{fill:#17CFF0;}
	.st3196{fill:#2A3759;}
	.st3197{fill:#414860;}
	.st3198{fill:#A66A48;}
	.st3199{fill:#D17D5C;}
	.st3200{fill:#DB6D2E;}
	.st3201{fill:#132F63;}
	.st3202{fill:#103363;}
	.st3203{fill:#1B2F5C;}
	.st3204{fill:#2C2C40;}
	.st3205{fill:#D9753A;}
	.st3206{fill:#183054;}
	.st3207{fill:#392623;}
	.st3208{fill:#163158;}
	.st3209{fill:#F7611A;}
	.st3210{fill:#E96B25;}
	.st3211{fill:#9C3510;}
	.st3212{fill:#801F06;}
	.st3213{fill:#FCA539;}
	.st3214{fill:#8C0B02;}
	.st3215{fill:#9D1702;}
	.st3216{fill:#FBEFC6;}
	.st3217{fill:#F9EDB6;}
	.st3218{fill:#AA5618;}
	.st3219{fill:#9C4C0D;}
	.st3220{fill:#F8D28E;}
	.st3221{fill:#F5EFBF;}
	.st3222{fill:#E44C1C;}
	.st3223{fill:#331E4A;}
	.st3224{fill:#E23D13;}
	.st3225{fill:#69282A;}
	.st3226{fill:#8A372D;}
	.st3227{fill:#E85A22;}
	.st3228{fill:#E4541E;}
	.st3229{fill:#F65B16;}
	.st3230{fill:#FB8928;}
	.st3231{fill:#CD4D26;}
	.st3232{fill:#4D1F26;}
	.st3233{fill:#521E0C;}
	.st3234{fill:#642E3F;}
	.st3235{fill:#F09C48;}
	.st3236{fill:#552635;}
	.st3237{fill:#48160A;}
	.st3238{fill:#FA4612;}
	.st3239{fill:#F45213;}
	.st3240{fill:#A64122;}
	.st3241{fill:#E86620;}
	.st3242{fill:#28162C;}
	.st3243{fill:#171A3A;}
	.st3244{fill:#34283A;}
	.st3245{fill:#C78852;}
	.st3246{fill:#07385E;}
	.st3247{fill:#083A63;}
	.st3248{fill:#07345A;}
	.st3249{fill:#293544;}
	.st3250{fill:#0F3369;}
	.st3251{fill:#42230E;}
	.st3252{fill:#DB9656;}
	.st3253{fill:#36ABEF;}
	.st3254{fill:#34B0F3;}
	.st3255{fill:#31AAEF;}
	.st3256{fill:#22AFF1;}
	.st3257{fill:#24CFFD;}
	.st3258{fill:#0BBEF1;}
	.st3259{fill:#7D8FAA;}
	.st3260{fill:#8A9DB8;}
	.st3261{fill:#8295B0;}
	.st3262{fill:#13375A;}
	.st3263{fill:#1884CB;}
	.st3264{fill:#1FADD7;}
	.st3265{fill:#1ACCE5;}
	.st3266{fill:#064D72;}
	.st3267{fill:#1CB6F9;}
	.st3268{fill:#19B9F2;}
	.st3269{fill:#21B3F3;}
	.st3270{fill:#37D6FD;}
	.st3271{fill:#17B1E9;}
	.st3272{fill:#B7EFFC;}
	.st3273{fill:#B4F1FB;}
	.st3274{fill:#A7F6FD;}
	.st3275{fill:#2987A8;}
	.st3276{fill:#B2EFFC;}
	.st3277{fill:#63AEC1;}
	.st3278{fill:#16B1E4;}
	.st3279{fill:#21B0DF;}
	.st3280{fill:#07445F;}
	.st3281{fill:#34C0E7;}
	.st3282{fill:#235363;}
	.st3283{fill:#3C5F67;}
	.st3284{fill:#21D0EB;}
	.st3285{fill:#025B79;}
	.st3286{fill:#09505C;}
	.st3287{fill:#15CAEE;}
	.st3288{fill:#2FC7FB;}
	.st3289{fill:#2ACFFD;}
	.st3290{fill:#23D5FC;}
	.st3291{fill:#27DDFD;}
	.st3292{fill:#22E3FD;}
	.st3293{fill:#2EC2FA;}
	.st3294{fill:#2FB9F6;}
	.st3295{fill:#BCF6FD;}
	.st3296{fill:#AAF2FC;}
	.st3297{fill:#ADF5FC;}
	.st3298{fill:#90EAF9;}
	.st3299{fill:#9C4A41;}
	.st3300{fill:#0A74CE;}
	.st3301{fill:#F59526;}
	.st3302{fill:#F39320;}
	.st3303{fill:#E2A361;}
	.st3304{fill:#1AC8EA;}
	.st3305{fill:#16C2DE;}
	.st3306{fill:#13C5E5;}
	.st3307{fill:#DDE3F2;}
	.st3308{fill:#1DC1ED;}
	.st3309{fill:#2BBCE8;}
	.st3310{fill:#C2EAF3;}
	.st3311{fill:#94CFE1;}
	.st3312{fill:#8FCCC7;}
	.st3313{fill:#06597A;}
	.st3314{fill:#1EBEF5;}
	.st3315{fill:#1CBCFA;}
	.st3316{fill:#21B2E8;}
	.st3317{fill:#1BDFF9;}
	.st3318{fill:#30B5E7;}
	.st3319{fill:#22B5F2;}
	.st3320{fill:#20BAF6;}
	.st3321{fill:#1FB8EC;}
	.st3322{fill:#19B9E7;}
	.st3323{fill:#16BDEF;}
	.st3324{fill:#1AB9EF;}
	.st3325{fill:#2FD7FD;}
	.st3326{fill:#24B9EB;}
	.st3327{fill:#43EBFC;}
	.st3328{fill:#2BC4ED;}
	.st3329{fill:#0DBEE3;}
	.st3330{fill:#10BCDF;}
	.st3331{fill:#50BAC7;}
	.st3332{fill:#202961;}
	.st3333{fill:#D19A6E;}
	.st3334{fill:#EAA572;}
	.st3335{fill:#EE9715;}
	.st3336{fill:#DF954D;}
	.st3337{fill:#3F2C25;}
	.st3338{fill:#01628C;}
	.st3339{fill:#A8B888;}
	.st3340{fill:#1A3A4E;}
	.st3341{fill:#2A3E55;}
	.st3342{fill:#4D4758;}
	.st3343{fill:#1F3145;}
	.st3344{fill:#6C5864;}
	.st3345{fill:#9D5D5E;}
	.st3346{fill:#9C6166;}
	.st3347{fill:#A76566;}
	.st3348{fill:#1F060A;}
	.st3349{fill:#A6BA6E;}
	.st3350{fill:#7BCCA6;}
	.st3351{fill:#B0B66B;}
	.st3352{fill:#B9B55E;}
	.st3353{fill:#DBAC61;}
	.st3354{fill:#C8AF6E;}
	.st3355{fill:#A6BC7A;}
	.st3356{fill:#C4B04D;}
	.st3357{fill:#C0B25D;}
	.st3358{fill:#F6F4F4;}
	.st3359{fill:#ECEBEF;}
	.st3360{fill:#EFF1F2;}
	.st3361{fill:#E5A054;}
	.st3362{fill:#C4A956;}
	.st3363{fill:#F4E2B4;}
	.st3364{fill:#BCB25A;}
	.st3365{fill:#DF9D5C;}
	.st3366{fill:#CDAE57;}
	.st3367{fill:#EECAB7;}
	.st3368{fill:#E69753;}
	.st3369{fill:#B5B36E;}
	.st3370{fill:#E5CFCC;}
	.st3371{fill:#C5AD51;}
	.st3372{fill:#D79C61;}
	.st3373{fill:#E9A949;}
	.st3374{fill:#D69D4F;}
	.st3375{fill:#D1984C;}
	.st3376{fill:#DEA561;}
	.st3377{fill:#BEAD5E;}
	.st3378{fill:#E9AA6E;}
	.st3379{fill:#FAE2B3;}
	.st3380{fill:#E9A151;}
	.st3381{fill:#D89248;}
	.st3382{fill:#DCA053;}
	.st3383{fill:#E4A664;}
	.st3384{fill:#DD9D5C;}
	.st3385{fill:#D6A359;}
	.st3386{fill:#ECA871;}
	.st3387{fill:#DF9F54;}
	.st3388{fill:#C8A84F;}
	.st3389{fill:#C7A954;}
	.st3390{fill:#D2994E;}
	.st3391{fill:#E29E53;}
	.st3392{fill:#DC9649;}
	.st3393{fill:#D8A658;}
	.st3394{fill:#E1A65D;}
	.st3395{fill:#D3AA51;}
	.st3396{fill:#585D61;}
	.st3397{fill:#75B3D4;}
	.st3398{fill:#5ADCFA;}
	.st3399{fill:#97B5CB;}
	.st3400{fill:#A1BBCE;}
	.st3401{fill:#0F3546;}
	.st3402{fill:#174660;}
	.st3403{fill:#614537;}
	.st3404{fill:#F0E1D5;}
	.st3405{fill:#644434;}
	.st3406{fill:#EEDFD8;}
	.st3407{fill:#D8CBC6;}
	.st3408{fill:#745741;}
	.st3409{fill:#624132;}
	.st3410{fill:#7E5957;}
	.st3411{fill:#D6BD71;}
	.st3412{fill:#DBB66F;}
	.st3413{fill:#CCA667;}
	.st3414{fill:#C7995C;}
	.st3415{fill:#EEF3F9;}
	.st3416{fill:#7F695B;}
	.st3417{fill:#795D4A;}
	.st3418{fill:#6F5144;}
	.st3419{fill:#837368;}
	.st3420{fill:#E8F7F1;}
	.st3421{fill:#B2905F;}
	.st3422{fill:#9F7E55;}
	.st3423{fill:#AB9A6E;}
	.st3424{fill:#ECCE86;}
	.st3425{fill:#EDDA9C;}
	.st3426{fill:#B2985B;}
	.st3427{fill:#F2CA7F;}
	.st3428{fill:#F5E5D4;}
	.st3429{fill:#E3C26E;}
	.st3430{fill:#FAE8C4;}
	.st3431{fill:#475F77;}
	.st3432{fill:#536A84;}
	.st3433{fill:#3B4C65;}
	.st3434{fill:#3F2A38;}
	.st3435{fill:#4A5871;}
	.st3436{fill:#21B8DE;}
	.st3437{fill:#ABF3FA;}
	.st3438{fill:#ACF3FC;}
	.st3439{fill:#17BFEC;}
	.st3440{fill:#47CAEA;}
	.st3441{fill:#BFF6FB;}
	.st3442{fill:#BDF4FA;}
	.st3443{fill:#B3F2FB;}
	.st3444{fill:#06A9E9;}
	.st3445{fill:#08A3E7;}
	.st3446{fill:#19ABDB;}
	.st3447{fill:#19A8DC;}
	.st3448{fill:#1B94E5;}
	.st3449{fill:#1ABCFB;}
	.st3450{fill:#169EE8;}
	.st3451{fill:#70AFCD;}
	.st3452{fill:#63A2B5;}
	.st3453{fill:#0D4E70;}
	.st3454{fill:#84A8C0;}
	.st3455{fill:#7DA3BD;}
	.st3456{fill:#829DB1;}
	.st3457{fill:#F3CF79;}
	.st3458{fill:#D5651A;}
	.st3459{fill:#D85A1D;}
	.st3460{fill:#F19A44;}
	.st3461{fill:#D26B2E;}
	.st3462{fill:#FCAC6D;}
	.st3463{fill:#D79766;}
	.st3464{fill:#B88262;}
	.st3465{fill:#7D88A6;}
	.st3466{fill:#7E6B6C;}
	.st3467{fill:#AE6C50;}
	.st3468{fill:#7C4A41;}
	.st3469{fill:#482427;}
	.st3470{fill:#4C190F;}
	.st3471{fill:#111932;}
	.st3472{fill:#0E9DE5;}
	.st3473{fill:#0D8EDE;}
	.st3474{fill:#0B8DDF;}
	.st3475{fill:#0C87DC;}
	.st3476{fill:#0D85D9;}
	.st3477{fill:#2392E6;}
	.st3478{fill:#2095E8;}
	.st3479{fill:#238DE4;}
	.st3480{fill:#1A9BE9;}
	.st3481{fill:#1AA1EB;}
	.st3482{fill:#15A5EB;}
	.st3483{fill:#092B46;}
	.st3484{fill:#09314E;}
	.st3485{fill:#072F4E;}
	.st3486{fill:#0B314F;}
	.st3487{fill:#063153;}
	.st3488{fill:#063254;}
	.st3489{fill:#063353;}
	.st3490{fill:#08375A;}
	.st3491{fill:#0B2C47;}
	.st3492{fill:#074A71;}
	.st3493{fill:#0B365D;}
	.st3494{fill:#073354;}
	.st3495{fill:#053E66;}
	.st3496{fill:#0D2740;}
	.st3497{fill:#12263E;}
	.st3498{fill:#172740;}
	.st3499{fill:#06446B;}
	.st3500{fill:#053D65;}
	.st3501{fill:#075076;}
	.st3502{fill:#74849B;}
	.st3503{fill:#A2A4B8;}
	.st3504{fill:#58718E;}
	.st3505{fill:#1F4A74;}
	.st3506{fill:#405D68;}
	.st3507{fill:#194161;}
	.st3508{fill:#21E0FC;}
	.st3509{fill:#25EAFC;}
	.st3510{fill:#32EFFE;}
	.st3511{fill:#22294A;}
	.st3512{fill:#675763;}
	.st3513{fill:#B0623A;}
	.st3514{fill:#A75E40;}
	.st3515{fill:#E3DFEA;}
	.st3516{fill:#E4E8EC;}
	.st3517{fill:#CE7E53;}
	.st3518{fill:#092D5A;}
	.st3519{fill:#0D3164;}
	.st3520{fill:#F38530;}
	.st3521{fill:#F3AD54;}
	.st3522{fill:#F69239;}
	.st3523{fill:#FBC46E;}
	.st3524{fill:#823B13;}
	.st3525{fill:#A05321;}
	.st3526{fill:#EDA353;}
	.st3527{fill:#F9C56A;}
	.st3528{fill:#2E1223;}
	.st3529{fill:#182156;}
	.st3530{fill:#30394E;}
	.st3531{fill:#F46A1D;}
	.st3532{fill:#F06428;}
	.st3533{fill:#E27E37;}
	.st3534{fill:#FBBC69;}
	.st3535{fill:#FDAB4E;}
	.st3536{fill:#FDBA5E;}
	.st3537{fill:#FDCF82;}
	.st3538{fill:#FB7A1E;}
	.st3539{fill:#FC6B15;}
	.st3540{fill:#FB9F43;}
	.st3541{fill:#FD9836;}
	.st3542{fill:#422220;}
	.st3543{fill:#D14D23;}
	.st3544{fill:#4D2B2F;}
	.st3545{fill:#735254;}
	.st3546{fill:#6C2D28;}
	.st3547{fill:#CE6839;}
	.st3548{fill:#053455;}
	.st3549{fill:#073B61;}
	.st3550{fill:#063658;}
	.st3551{fill:#0A3A5A;}
	.st3552{fill:#043659;}
	.st3553{fill:#094C76;}
	.st3554{fill:#093F68;}
	.st3555{fill:#0C416A;}
	.st3556{fill:#12366E;}
	.st3557{fill:#2C3A24;}
	.st3558{fill:#E9BE6A;}
	.st3559{fill:#E8A968;}
	.st3560{fill:#3E2510;}
	.st3561{fill:#DCB567;}
	.st3562{fill:#503B12;}
	.st3563{fill:#2FADF1;}
	.st3564{fill:#2DB1F2;}
	.st3565{fill:#2CA6EE;}
	.st3566{fill:#27A1EE;}
	.st3567{fill:#1EC5FC;}
	.st3568{fill:#17AAF0;}
	.st3569{fill:#07B1EB;}
	.st3570{fill:#96AAC2;}
	.st3571{fill:#9EB1C8;}
	.st3572{fill:#91A3BD;}
	.st3573{fill:#5E728B;}
	.st3574{fill:#657D94;}
	.st3575{fill:#8CA2C0;}
	.st3576{fill:#C3D5E5;}
	.st3577{fill:#CCDDEB;}
	.st3578{fill:#BDCFE2;}
	.st3579{fill:#AFC0D5;}
	.st3580{fill:#CEE2F2;}
	.st3581{fill:#B2C7DD;}
	.st3582{fill:#98B5D1;}
	.st3583{fill:#1A3757;}
	.st3584{fill:#15A6E5;}
	.st3585{fill:#14C6F2;}
	.st3586{fill:#16CDF3;}
	.st3587{fill:#17D8F6;}
	.st3588{fill:#13BAEB;}
	.st3589{fill:#17E1F9;}
	.st3590{fill:#1DB6D1;}
	.st3591{fill:#1DB5ED;}
	.st3592{fill:#2CD4FD;}
	.st3593{fill:#36D8FD;}
	.st3594{fill:#19A8DA;}
	.st3595{fill:#1B9EC8;}
	.st3596{fill:#44BEE0;}
	.st3597{fill:#1FB5E8;}
	.st3598{fill:#14B0EC;}
	.st3599{fill:#30A7D7;}
	.st3600{fill:#E7B06D;}
	.st3601{fill:#1ABDED;}
	.st3602{fill:#61EAFB;}
	.st3603{fill:#807468;}
	.st3604{fill:#CBB18A;}
	.st3605{fill:#37B2D7;}
	.st3606{fill:#EA9045;}
	.st3607{fill:#27A9C5;}
	.st3608{fill:#3491AC;}
	.st3609{fill:#226A80;}
	.st3610{fill:#102E2D;}
	.st3611{fill:#28C6FB;}
	.st3612{fill:#26CCFB;}
	.st3613{fill:#20C9F6;}
	.st3614{fill:#25DBFD;}
	.st3615{fill:#28C0F8;}
	.st3616{fill:#29B9F5;}
	.st3617{fill:#2FA9D2;}
	.st3618{fill:#1AB2DC;}
	.st3619{fill:#E4A052;}
	.st3620{fill:#544243;}
	.st3621{fill:#09BBE5;}
	.st3622{fill:#C0C9D8;}
	.st3623{fill:#A8C1D8;}
	.st3624{fill:#DACFD4;}
	.st3625{fill:#C6C8CB;}
	.st3626{fill:#18CAE9;}
	.st3627{fill:#32E4F7;}
	.st3628{fill:#035987;}
	.st3629{fill:#0D789E;}
	.st3630{fill:#06668A;}
	.st3631{fill:#23CEE3;}
	.st3632{fill:#34CCEF;}
	.st3633{fill:#34ADD5;}
	.st3634{fill:#31CDED;}
	.st3635{fill:#306A8D;}
	.st3636{fill:#A0B5BD;}
	.st3637{fill:#307289;}
	.st3638{fill:#2AB4E6;}
	.st3639{fill:#30CBF1;}
	.st3640{fill:#1ACBF1;}
	.st3641{fill:#0CB8EF;}
	.st3642{fill:#EDBC55;}
	.st3643{fill:#3A1E19;}
	.st3644{fill:#B46560;}
	.st3645{fill:#AA6D6F;}
	.st3646{fill:#9F676B;}
	.st3647{fill:#BC6A63;}
	.st3648{fill:#C26E67;}
	.st3649{fill:#AD8F59;}
	.st3650{fill:#A29167;}
	.st3651{fill:#AC9973;}
	.st3652{fill:#8F7E56;}
	.st3653{fill:#E6E4EE;}
	.st3654{fill:#F3D9C5;}
	.st3655{fill:#E1DFEA;}
	.st3656{fill:#957C48;}
	.st3657{fill:#9B9066;}
	.st3658{fill:#A47E57;}
	.st3659{fill:#E0DDE0;}
	.st3660{fill:#F3BE9E;}
	.st3661{fill:#D7D8E5;}
	.st3662{fill:#D2D1DE;}
	.st3663{fill:#82AEC8;}
	.st3664{fill:#262621;}
	.st3665{fill:#AFC1D1;}
	.st3666{fill:#40342A;}
	.st3667{fill:#B7A793;}
	.st3668{fill:#AE8763;}
	.st3669{fill:#F7F1EA;}
	.st3670{fill:#5B3C30;}
	.st3671{fill:#683D3B;}
	.st3672{fill:#F0F5F9;}
	.st3673{fill:#E1BB7B;}
	.st3674{fill:#E9E9D7;}
	.st3675{fill:#4C5444;}
	.st3676{fill:#F6EEE5;}
	.st3677{fill:#DCE9F3;}
	.st3678{fill:#D2A052;}
	.st3679{fill:#E1B65F;}
	.st3680{fill:#576875;}
	.st3681{fill:#D8AA59;}
	.st3682{fill:#51657D;}
	.st3683{fill:#543E48;}
	.st3684{fill:#B07B81;}
	.st3685{fill:#AD8990;}
	.st3686{fill:#58637C;}
	.st3687{fill:#655E70;}
	.st3688{fill:#1AB0DC;}
	.st3689{fill:#0BB5E5;}
	.st3690{fill:#0B9ADB;}
	.st3691{fill:#06ADF0;}
	.st3692{fill:#19A0EB;}
	.st3693{fill:#2297E8;}
	.st3694{fill:#6B92A2;}
	.st3695{fill:#243E4C;}
	.st3696{fill:#232D2E;}
	.st3697{fill:#7CA8C3;}
	.st3698{fill:#80A8C4;}
	.st3699{fill:#74ACC8;}
	.st3700{fill:#5C595C;}
	.st3701{fill:#D9907A;}
	.st3702{fill:#E9AB6D;}
	.st3703{fill:#E19B58;}
	.st3704{fill:#C17446;}
	.st3705{fill:#6F89AE;}
	.st3706{fill:#3E3750;}
	.st3707{fill:#D08850;}
	.st3708{fill:#7D98B2;}
	.st3709{fill:#B05E3F;}
	.st3710{fill:#A4593D;}
	.st3711{fill:#9E4B31;}
	.st3712{fill:#10A5E9;}
	.st3713{fill:#10A6E7;}
	.st3714{fill:#0F9DE3;}
	.st3715{fill:#1F9EEA;}
	.st3716{fill:#1EA2ED;}
	.st3717{fill:#19A6ED;}
	.st3718{fill:#10ABEB;}
	.st3719{fill:#15ACEF;}
	.st3720{fill:#0C2C45;}
	.st3721{fill:#0B2D47;}
	.st3722{fill:#083658;}
	.st3723{fill:#054162;}
	.st3724{fill:#163042;}
	.st3725{fill:#075B7D;}
	.st3726{fill:#063F5F;}
	.st3727{fill:#074771;}
	.st3728{fill:#045B7A;}
	.st3729{fill:#086A85;}
	.st3730{fill:#03708F;}
	.st3731{fill:#056285;}
	.st3732{fill:#07587F;}
	.st3733{fill:#15283F;}
	.st3734{fill:#221C22;}
	.st3735{fill:#2D1E16;}
	.st3736{fill:#201D35;}
	.st3737{fill:#047596;}
	.st3738{fill:#0B5484;}
	.st3739{fill:#055776;}
	.st3740{fill:#025F8D;}
	.st3741{fill:#066283;}
	.st3742{fill:#52ECFA;}
	.st3743{fill:#EAEBF4;}
	.st3744{fill:#D7E4F5;}
	.st3745{fill:#79A4C7;}
	.st3746{fill:#0A4B80;}
	.st3747{fill:#025B98;}
	.st3748{fill:#38DFFB;}
	.st3749{fill:#2CD2F9;}
	.st3750{fill:#20DCFA;}
	.st3751{fill:#22E5F8;}
	.st3752{fill:#E7C1C1;}
	.st3753{fill:#A98783;}
	.st3754{fill:#BC9D9A;}
	.st3755{fill:#DAB5B6;}
	.st3756{fill:#CFA393;}
	.st3757{fill:#C17B5A;}
	.st3758{fill:#E7996C;}
	.st3759{fill:#8B7D99;}
	.st3760{fill:#735D7A;}
	.st3761{fill:#03264B;}
	.st3762{fill:#196095;}
	.st3763{fill:#215C8D;}
	.st3764{fill:#FBCF8C;}
	.st3765{fill:#FBB447;}
	.st3766{fill:#F07429;}
	.st3767{fill:#E06531;}
	.st3768{fill:#F6C784;}
	.st3769{fill:#FAD49A;}
	.st3770{fill:#FCE2A8;}
	.st3771{fill:#C76C37;}
	.st3772{fill:#FAE59A;}
	.st3773{fill:#D57440;}
	.st3774{fill:#2D2738;}
	.st3775{fill:#C35734;}
	.st3776{fill:#967F8D;}
	.st3777{fill:#976B74;}
	.st3778{fill:#73362E;}
	.st3779{fill:#05395D;}
	.st3780{fill:#083758;}
	.st3781{fill:#094469;}
	.st3782{fill:#0D4066;}
	.st3783{fill:#2E3631;}
	.st3784{fill:#3AE3F8;}
	.st3785{fill:#05678E;}
	.st3786{fill:#0B6F8E;}
	.st3787{fill:#16426C;}
	.st3788{fill:#28ABF1;}
	.st3789{fill:#27B2F4;}
	.st3790{fill:#2098ED;}
	.st3791{fill:#18A2EA;}
	.st3792{fill:#1DABEE;}
	.st3793{fill:#034080;}
	.st3794{fill:#1DA0E7;}
	.st3795{fill:#0AA6EC;}
	.st3796{fill:#0E9CED;}
	.st3797{fill:#A7B9CE;}
	.st3798{fill:#798BA2;}
	.st3799{fill:#6F7891;}
	.st3800{fill:#5C6A82;}
	.st3801{fill:#748199;}
	.st3802{fill:#B9CADD;}
	.st3803{fill:#B0B7C8;}
	.st3804{fill:#A5B2C3;}
	.st3805{fill:#A4A9BA;}
	.st3806{fill:#D6E3F1;}
	.st3807{fill:#D2E0EE;}
	.st3808{fill:#8896AC;}
	.st3809{fill:#DBE7F3;}
	.st3810{fill:#18CFF7;}
	.st3811{fill:#143651;}
	.st3812{fill:#094E7F;}
	.st3813{fill:#10AFE9;}
	.st3814{fill:#18BDF4;}
	.st3815{fill:#18C5F5;}
	.st3816{fill:#13BEF0;}
	.st3817{fill:#18CAF7;}
	.st3818{fill:#16D4F8;}
	.st3819{fill:#17DBF8;}
	.st3820{fill:#1CE7F8;}
	.st3821{fill:#17E7FB;}
	.st3822{fill:#19E2F4;}
	.st3823{fill:#4FE5FC;}
	.st3824{fill:#3FD2F7;}
	.st3825{fill:#46CFED;}
	.st3826{fill:#11B7EF;}
	.st3827{fill:#26ADE9;}
	.st3828{fill:#C6F1F8;}
	.st3829{fill:#19ABDA;}
	.st3830{fill:#1BB2F3;}
	.st3831{fill:#3AD3F8;}
	.st3832{fill:#0EA9D0;}
	.st3833{fill:#19B3EB;}
	.st3834{fill:#0C9FC0;}
	.st3835{fill:#098AA7;}
	.st3836{fill:#22D6FB;}
	.st3837{fill:#1CB8F1;}
	.st3838{fill:#BFECF8;}
	.st3839{fill:#C7CFDD;}
	.st3840{fill:#B6C4D5;}
	.st3841{fill:#453425;}
	.st3842{fill:#4D391D;}
	.st3843{fill:#10BCE9;}
	.st3844{fill:#89A1AA;}
	.st3845{fill:#226978;}
	.st3846{fill:#88A5AD;}
	.st3847{fill:#A1B7BC;}
	.st3848{fill:#39D2E4;}
	.st3849{fill:#2C7883;}
	.st3850{fill:#2F8895;}
	.st3851{fill:#3ED3E4;}
	.st3852{fill:#3ECFE4;}
	.st3853{fill:#56D7F3;}
	.st3854{fill:#139BD1;}
	.st3855{fill:#058AB0;}
	.st3856{fill:#4F758A;}
	.st3857{fill:#658386;}
	.st3858{fill:#51768A;}
	.st3859{fill:#A1A0A2;}
	.st3860{fill:#4E676C;}
	.st3861{fill:#7C8C8A;}
	.st3862{fill:#4B7489;}
	.st3863{fill:#567D92;}
	.st3864{fill:#8899AA;}
	.st3865{fill:#387A9A;}
	.st3866{fill:#376A83;}
	.st3867{fill:#4F6C87;}
	.st3868{fill:#39232C;}
	.st3869{fill:#B67E82;}
	.st3870{fill:#B56C69;}
	.st3871{fill:#B57270;}
	.st3872{fill:#AC797B;}
	.st3873{fill:#A17A82;}
	.st3874{fill:#BD6F68;}
	.st3875{fill:#C67671;}
	.st3876{fill:#C2726C;}
	.st3877{fill:#A28257;}
	.st3878{fill:#A3804D;}
	.st3879{fill:#967747;}
	.st3880{fill:#816637;}
	.st3881{fill:#6C4C2B;}
	.st3882{fill:#88A0A1;}
	.st3883{fill:#799EAB;}
	.st3884{fill:#BC804C;}
	.st3885{fill:#BD8A5C;}
	.st3886{fill:#B9783C;}
	.st3887{fill:#573832;}
	.st3888{fill:#7D5C46;}
	.st3889{fill:#825B32;}
	.st3890{fill:#EBF2F7;}
	.st3891{fill:#E8D4B6;}
	.st3892{fill:#B39968;}
	.st3893{fill:#E0EAF1;}
	.st3894{fill:#E5EDF3;}
	.st3895{fill:#D4DEEA;}
	.st3896{fill:#DEE6EF;}
	.st3897{fill:#E6F0F6;}
	.st3898{fill:#E0ECF4;}
	.st3899{fill:#CC9449;}
	.st3900{fill:#637D95;}
	.st3901{fill:#567188;}
	.st3902{fill:#D4E4F1;}
	.st3903{fill:#B8838B;}
	.st3904{fill:#624D58;}
	.st3905{fill:#BD7478;}
	.st3906{fill:#BA7972;}
	.st3907{fill:#2E2638;}
	.st3908{fill:#9A7078;}
	.st3909{fill:#1C94E3;}
	.st3910{fill:#1268BA;}
	.st3911{fill:#3E3D34;}
	.st3912{fill:#6E6158;}
	.st3913{fill:#85ADC5;}
	.st3914{fill:#899EB0;}
	.st3915{fill:#70A5C1;}
	.st3916{fill:#064363;}
	.st3917{fill:#07304F;}
	.st3918{fill:#134C64;}
	.st3919{fill:#81A7C3;}
	.st3920{fill:#EFB266;}
	.st3921{fill:#E7A65D;}
	.st3922{fill:#142D45;}
	.st3923{fill:#3C2425;}
	.st3924{fill:#1A9CE6;}
	.st3925{fill:#1CA8EF;}
	.st3926{fill:#17A4E9;}
	.st3927{fill:#11B5ED;}
	.st3928{fill:#13B1EF;}
	.st3929{fill:#1BADF1;}
	.st3930{fill:#18B3F2;}
	.st3931{fill:#05486E;}
	.st3932{fill:#08618A;}
	.st3933{fill:#086A84;}
	.st3934{fill:#036989;}
	.st3935{fill:#73EDF7;}
	.st3936{fill:#4EE0F2;}
	.st3937{fill:#087895;}
	.st3938{fill:#08557A;}
	.st3939{fill:#43EEF9;}
	.st3940{fill:#0782A2;}
	.st3941{fill:#33F4FA;}
	.st3942{fill:#49EDF6;}
	.st3943{fill:#3DF7FA;}
	.st3944{fill:#3DE7F4;}
	.st3945{fill:#4BF6F9;}
	.st3946{fill:#D89B67;}
	.st3947{fill:#E1B584;}
	.st3948{fill:#B69265;}
	.st3949{fill:#DF864E;}
	.st3950{fill:#D45B23;}
	.st3951{fill:#E37033;}
	.st3952{fill:#E1783F;}
	.st3953{fill:#DB876D;}
	.st3954{fill:#C77454;}
	.st3955{fill:#CE7756;}
	.st3956{fill:#EDA18A;}
	.st3957{fill:#E4AC9B;}
	.st3958{fill:#25E7F1;}
	.st3959{fill:#4EEEFC;}
	.st3960{fill:#0A7A94;}
	.st3961{fill:#0B9EA5;}
	.st3962{fill:#2AD7EC;}
	.st3963{fill:#4FCCE7;}
	.st3964{fill:#A0FBFC;}
	.st3965{fill:#79EEF4;}
	.st3966{fill:#2693AC;}
	.st3967{fill:#1FE7F5;}
	.st3968{fill:#37DAE7;}
	.st3969{fill:#EFF2F7;}
	.st3970{fill:#ADC9E0;}
	.st3971{fill:#2C6290;}
	.st3972{fill:#7A9FBC;}
	.st3973{fill:#134E82;}
	.st3974{fill:#084988;}
	.st3975{fill:#025399;}
	.st3976{fill:#27CBF9;}
	.st3977{fill:#23BBF4;}
	.st3978{fill:#D27A53;}
	.st3979{fill:#F8BDA5;}
	.st3980{fill:#C17A53;}
	.st3981{fill:#BC7649;}
	.st3982{fill:#C4827B;}
	.st3983{fill:#2B3546;}
	.st3984{fill:#D97340;}
	.st3985{fill:#85534C;}
	.st3986{fill:#3E4157;}
	.st3987{fill:#56392B;}
	.st3988{fill:#7B6F6E;}
	.st3989{fill:#13619F;}
	.st3990{fill:#0D5E93;}
	.st3991{fill:#FBECBE;}
	.st3992{fill:#F9E4A9;}
	.st3993{fill:#DE844F;}
	.st3994{fill:#FBD48C;}
	.st3995{fill:#A58592;}
	.st3996{fill:#9F919E;}
	.st3997{fill:#938FA0;}
	.st3998{fill:#7C4E44;}
	.st3999{fill:#06537C;}
	.st4000{fill:#38E5E8;}
	.st4001{fill:#62EDF6;}
	.st4002{fill:#7DF7F9;}
	.st4003{fill:#4EDFF4;}
	.st4004{fill:#18B8FC;}
	.st4005{fill:#20C4FC;}
	.st4006{fill:#24D0FD;}
	.st4007{fill:#04356F;}
	.st4008{fill:#053470;}
	.st4009{fill:#0F386E;}
	.st4010{fill:#10305C;}
	.st4011{fill:#0BB4F4;}
	.st4012{fill:#05559C;}
	.st4013{fill:#828AA2;}
	.st4014{fill:#707390;}
	.st4015{fill:#97A4B6;}
	.st4016{fill:#BABBCB;}
	.st4017{fill:#B1B2C3;}
	.st4018{fill:#A9B0C0;}
	.st4019{fill:#9299AD;}
	.st4020{fill:#D2D4E0;}
	.st4021{fill:#1CC2E4;}
	.st4022{fill:#E7F0F9;}
	.st4023{fill:#107985;}
	.st4024{fill:#298A9C;}
	.st4025{fill:#D5E6F0;}
	.st4026{fill:#C9C9D7;}
	.st4027{fill:#C0C4D3;}
	.st4028{fill:#17D5F7;}
	.st4029{fill:#1AD2F3;}
	.st4030{fill:#14C9F4;}
	.st4031{fill:#0FC4F1;}
	.st4032{fill:#19B9F4;}
	.st4033{fill:#13B8F1;}
	.st4034{fill:#12B7EF;}
	.st4035{fill:#16B4E0;}
	.st4036{fill:#37CEF2;}
	.st4037{fill:#A4F0FB;}
	.st4038{fill:#B2F0FA;}
	.st4039{fill:#1DD1FB;}
	.st4040{fill:#CBD4DC;}
	.st4041{fill:#C58448;}
	.st4042{fill:#21617A;}
	.st4043{fill:#308889;}
	.st4044{fill:#9DACBE;}
	.st4045{fill:#C7827E;}
	.st4046{fill:#BF7873;}
	.st4047{fill:#BA7A7A;}
	.st4048{fill:#B98184;}
	.st4049{fill:#AE8488;}
	.st4050{fill:#C37E79;}
	.st4051{fill:#D07A7A;}
	.st4052{fill:#635445;}
	.st4053{fill:#A06E3E;}
	.st4054{fill:#D1AF87;}
	.st4055{fill:#979BA3;}
	.st4056{fill:#A3ADAF;}
	.st4057{fill:#90A8B5;}
	.st4058{fill:#CBD6E2;}
	.st4059{fill:#C1CCDB;}
	.st4060{fill:#C6D0DD;}
	.st4061{fill:#BAC0CF;}
	.st4062{fill:#C0C6D6;}
	.st4063{fill:#C8CFD1;}
	.st4064{fill:#D0E0ED;}
	.st4065{fill:#CEDAE7;}
	.st4066{fill:#D5E3F0;}
	.st4067{fill:#3C6A9A;}
	.st4068{fill:#D8E7F1;}
	.st4069{fill:#C9DCEA;}
	.st4070{fill:#8893AC;}
	.st4071{fill:#CA7D82;}
	.st4072{fill:#9EA4B7;}
	.st4073{fill:#C97A79;}
	.st4074{fill:#492B2B;}
	.st4075{fill:#EA8B6C;}
	.st4076{fill:#1B85DA;}
	.st4077{fill:#1886D4;}
	.st4078{fill:#0363A5;}
	.st4079{fill:#89B3C7;}
	.st4080{fill:#83B4D1;}
	.st4081{fill:#7AAAC8;}
	.st4082{fill:#89BDD6;}
	.st4083{fill:#759DB8;}
	.st4084{fill:#A2C9DF;}
	.st4085{fill:#174663;}
	.st4086{fill:#5E3C31;}
	.st4087{fill:#E29A58;}
	.st4088{fill:#7F8D9E;}
	.st4089{fill:#839BAD;}
	.st4090{fill:#1398E1;}
	.st4091{fill:#10A3E4;}
	.st4092{fill:#066E8F;}
	.st4093{fill:#087890;}
	.st4094{fill:#078597;}
	.st4095{fill:#32DBEF;}
	.st4096{fill:#A2FAFC;}
	.st4097{fill:#2EE1F1;}
	.st4098{fill:#31E8F8;}
	.st4099{fill:#3DDDF4;}
	.st4100{fill:#3FE2F6;}
	.st4101{fill:#2FDCEA;}
	.st4102{fill:#27E2F0;}
	.st4103{fill:#5EFBFC;}
	.st4104{fill:#12A6BA;}
	.st4105{fill:#F7B792;}
	.st4106{fill:#F4C2AD;}
	.st4107{fill:#E9C9AB;}
	.st4108{fill:#B8A9AB;}
	.st4109{fill:#E6CEB2;}
	.st4110{fill:#EBC4AA;}
	.st4111{fill:#E9D0B0;}
	.st4112{fill:#EFA084;}
	.st4113{fill:#F3A887;}
	.st4114{fill:#F9BC9A;}
	.st4115{fill:#DD6835;}
	.st4116{fill:#22E4F4;}
	.st4117{fill:#17D5E6;}
	.st4118{fill:#47F4F7;}
	.st4119{fill:#68FAFB;}
	.st4120{fill:#68FDFD;}
	.st4121{fill:#8CFAFE;}
	.st4122{fill:#1CE8F8;}
	.st4123{fill:#16E2F2;}
	.st4124{fill:#F2F6FA;}
	.st4125{fill:#CFDEEB;}
	.st4126{fill:#67484E;}
	.st4127{fill:#F4F0EA;}
	.st4128{fill:#336598;}
	.st4129{fill:#246292;}
	.st4130{fill:#1C568F;}
	.st4131{fill:#12427E;}
	.st4132{fill:#12BEF6;}
	.st4133{fill:#17C9FA;}
	.st4134{fill:#623A29;}
	.st4135{fill:#B87131;}
	.st4136{fill:#D98332;}
	.st4137{fill:#F8C777;}
	.st4138{fill:#744E38;}
	.st4139{fill:#FCEDB4;}
	.st4140{fill:#AC909A;}
	.st4141{fill:#B08C93;}
	.st4142{fill:#A596A0;}
	.st4143{fill:#9D99A9;}
	.st4144{fill:#9C9FB1;}
	.st4145{fill:#96A3B5;}
	.st4146{fill:#1EA8E7;}
	.st4147{fill:#2EB0EB;}
	.st4148{fill:#22A3E0;}
	.st4149{fill:#3CB9E6;}
	.st4150{fill:#053C7C;}
	.st4151{fill:#23A5E1;}
	.st4152{fill:#1C8FCA;}
	.st4153{fill:#4597CF;}
	.st4154{fill:#2F2849;}
	.st4155{fill:#352841;}
	.st4156{fill:#8A8FA6;}
	.st4157{fill:#9B9FB2;}
	.st4158{fill:#C4C3D2;}
	.st4159{fill:#BDC4D7;}
	.st4160{fill:#B5BCCD;}
	.st4161{fill:#DBDDE6;}
	.st4162{fill:#D6D8E3;}
	.st4163{fill:#938D9B;}
	.st4164{fill:#767383;}
	.st4165{fill:#DBDCE5;}
	.st4166{fill:#0474A5;}
	.st4167{fill:#688D99;}
	.st4168{fill:#0A5B65;}
	.st4169{fill:#015D7F;}
	.st4170{fill:#C68687;}
	.st4171{fill:#B7898D;}
	.st4172{fill:#C18C8A;}
	.st4173{fill:#B9AD97;}
	.st4174{fill:#B5B5A3;}
	.st4175{fill:#37668E;}
	.st4176{fill:#98ACB2;}
	.st4177{fill:#C4D7E6;}
	.st4178{fill:#C1D2E2;}
	.st4179{fill:#BCCFDF;}
	.st4180{fill:#B8CBDB;}
	.st4181{fill:#B9C6D6;}
	.st4182{fill:#B3C1D1;}
	.st4183{fill:#B2BACC;}
	.st4184{fill:#B8B7C9;}
	.st4185{fill:#C1D8E8;}
	.st4186{fill:#1076C6;}
	.st4187{fill:#34D5F9;}
	.st4188{fill:#013E81;}
	.st4189{fill:#98BED5;}
	.st4190{fill:#92B8CF;}
	.st4191{fill:#88B0C8;}
	.st4192{fill:#80A8BF;}
	.st4193{fill:#A2C3D8;}
	.st4194{fill:#ABC9DD;}
	.st4195{fill:#B2CFE4;}
	.st4196{fill:#163D5B;}
	.st4197{fill:#8BA6BB;}
	.st4198{fill:#7BF4F9;}
	.st4199{fill:#159FAB;}
	.st4200{fill:#53F8FA;}
	.st4201{fill:#44F6F9;}
	.st4202{fill:#2DDEE6;}
	.st4203{fill:#77F9FB;}
	.st4204{fill:#55FDFE;}
	.st4205{fill:#30DAE5;}
	.st4206{fill:#22E3F2;}
	.st4207{fill:#23E9F5;}
	.st4208{fill:#26E2F5;}
	.st4209{fill:#BA9DA0;}
	.st4210{fill:#B6A6B1;}
	.st4211{fill:#B7AEBB;}
	.st4212{fill:#30E4EB;}
	.st4213{fill:#E4ECF2;}
	.st4214{fill:#CCBEC2;}
	.st4215{fill:#4B2223;}
	.st4216{fill:#EBBF76;}
	.st4217{fill:#E5AA5B;}
	.st4218{fill:#F9E8D4;}
	.st4219{fill:#B47B62;}
	.st4220{fill:#953022;}
	.st4221{fill:#A46242;}
	.st4222{fill:#875249;}
	.st4223{fill:#F9A33A;}
	.st4224{fill:#E49A50;}
	.st4225{fill:#BAA4AB;}
	.st4226{fill:#B2959C;}
	.st4227{fill:#B99094;}
	.st4228{fill:#AC9CA5;}
	.st4229{fill:#A49FAD;}
	.st4230{fill:#9EAABB;}
	.st4231{fill:#A5A5B5;}
	.st4232{fill:#93ABC0;}
	.st4233{fill:#1FCAF9;}
	.st4234{fill:#28CAF6;}
	.st4235{fill:#1DD1FA;}
	.st4236{fill:#2CC8FC;}
	.st4237{fill:#7ABEC7;}
	.st4238{fill:#992A1F;}
	.st4239{fill:#C45D38;}
	.st4240{fill:#D2E3EE;}
	.st4241{fill:#BDC1CF;}
	.st4242{fill:#A4A7C5;}
	.st4243{fill:#E4E0ED;}
	.st4244{fill:#ACB6C4;}
	.st4245{fill:#B6C4D9;}
	.st4246{fill:#C9C9D6;}
	.st4247{fill:#AFB6C8;}
	.st4248{fill:#A49DAF;}
	.st4249{fill:#4EE2F4;}
	.st4250{fill:#245674;}
	.st4251{fill:#BAD3E4;}
	.st4252{fill:#B3CDDF;}
	.st4253{fill:#B1C7D8;}
	.st4254{fill:#ADC1D2;}
	.st4255{fill:#A5B9CA;}
	.st4256{fill:#ACB5C6;}
	.st4257{fill:#B2B4C4;}
	.st4258{fill:#B5DBF4;}
	.st4259{fill:#1ED8FB;}
	.st4260{fill:#1EDCF9;}
	.st4261{fill:#25DFFA;}
	.st4262{fill:#A1BED3;}
	.st4263{fill:#9BBACF;}
	.st4264{fill:#90B3CB;}
	.st4265{fill:#96B3C8;}
	.st4266{fill:#8BADC4;}
	.st4267{fill:#AAC5D7;}
	.st4268{fill:#34ECF2;}
	.st4269{fill:#14E1EF;}
	.st4270{fill:#1DE4F9;}
	.st4271{fill:#ACA8B4;}
	.st4272{fill:#ADA2AB;}
	.st4273{fill:#ADAEBD;}
	.st4274{fill:#CECCD0;}
	.st4275{fill:#83747B;}
	.st4276{fill:#DDD1D2;}
	.st4277{fill:#A15F54;}
	.st4278{fill:#F8A836;}
	.st4279{fill:#F19245;}
	.st4280{fill:#F5A33E;}
	.st4281{fill:#A16119;}
	.st4282{fill:#EAB02B;}
	.st4283{fill:#F9A430;}
	.st4284{fill:#FAA02E;}
	.st4285{fill:#FA9A2B;}
	.st4286{fill:#FA9426;}
	.st4287{fill:#E4BEA3;}
	.st4288{fill:#F78023;}
	.st4289{fill:#CE4C1D;}
	.st4290{fill:#CF3E18;}
	.st4291{fill:#F89831;}
	.st4292{fill:#793F42;}
	.st4293{fill:#F09C47;}
	.st4294{fill:#FB9F30;}
	.st4295{fill:#FAA333;}
	.st4296{fill:#A4B0C1;}
	.st4297{fill:#9CB0C3;}
	.st4298{fill:#A7ABBC;}
	.st4299{fill:#25DAF8;}
	.st4300{fill:#79C6CF;}
	.st4301{fill:#1CD3F8;}
	.st4302{fill:#D18B48;}
	.st4303{fill:#CF7C4C;}
	.st4304{fill:#A93224;}
	.st4305{fill:#F86916;}
	.st4306{fill:#E8EFF6;}
	.st4307{fill:#D7DFEA;}
	.st4308{fill:#57D6EB;}
	.st4309{fill:#8D8198;}
	.st4310{fill:#33394F;}
	.st4311{fill:#A7BED0;}
	.st4312{fill:#9FB5C8;}
	.st4313{fill:#C2F8FD;}
	.st4314{fill:#56DDE9;}
	.st4315{fill:#57E3E6;}
	.st4316{fill:#60E6ED;}
	.st4317{fill:#735D60;}
	.st4318{fill:#876357;}
	.st4319{fill:#9D6148;}
	.st4320{fill:#86480D;}
	.st4321{fill:#FA9B30;}
	.st4322{fill:#FA942D;}
	.st4323{fill:#EF8C33;}
	.st4324{fill:#FC9C26;}
	.st4325{fill:#7E3507;}
	.st4326{fill:#8B4309;}
	.st4327{fill:#F19B32;}
	.st4328{fill:#FA8B21;}
	.st4329{fill:#F88C28;}
	.st4330{fill:#FA801A;}
	.st4331{fill:#FB9B26;}
	.st4332{fill:#3A3041;}
	.st4333{fill:#505F63;}
	.st4334{fill:#FA8122;}
	.st4335{fill:#F8791C;}
	.st4336{fill:#3C323C;}
</style>
<g>
	<path class="st0" d="M380.7,271.6c0.1,0.3,0.2,0.6,0.2,1c2.5,4.9,1.7,7.2-3.5,9.4c-0.5-0.2-0.8-0.6-0.8-1.1c0.1-0.5,0.2-1,0.5-1.4
		c1.2-1.9,2.3-3.8,1-6c-1.4-2.4-3.8-1.3-5.9-1.3c-0.5-0.1-0.9-0.2-1.3-0.5c-0.5-1.2,0.4-1.7,1.1-2.4c1.5-0.6,2.9-1.6,4.7-1.2
		C378,269.2,380.1,269.5,380.7,271.6z"/>
	<path class="st1" d="M371.9,269.6c-0.3,0.6-0.6,1.3-0.8,1.9c-1.4,5,0.8,8.1,5.1,10.2c0,0.5-0.1,1-0.5,1.3c-1.9,0.5-3.5,0.2-4.9-1.3
		c-4.2-3.1-4.2-8.4,0-12.1C371.2,269.1,371.6,269.1,371.9,269.6z"/>
	<path class="st2" d="M375.9,282.5c0-0.3,0-0.6,0-0.9c0.2-0.4,0.5-0.5,0.9-0.3c5.3-1.1,5.4-4.6,4.1-8.8c3,1.1,2.4,3.9,1.9,5.8
		c-0.7,2.5-2.6,4.5-5.7,4.2C376.6,282.9,376.3,282.9,375.9,282.5z"/>
	<path class="st3" d="M375.9,282.5c0.4,0,0.8,0,1.2,0c-2.6,3.3-4.5,1.6-6.2-1C372.4,282.4,374.1,282.7,375.9,282.5z"/>
	<path class="st4" d="M371.9,269.6c-0.4,0-0.7,0-1.1,0c2.1-1.9,4.4-3,7.1-1c-0.4,0.4-0.8,0.4-1.2,0.1
		C375.2,268.9,373.6,269.2,371.9,269.6z"/>
	<path class="st5" d="M376.8,268.6c0.4,0,0.8-0.1,1.2-0.1c2,0,3.2,0.8,2.7,3.1C379.4,270.6,378.1,269.6,376.8,268.6z"/>
	<path class="st6" d="M371.9,271.8c2.7-1.8,5.5-2.1,7.2,0.9c1.8,3,1.1,6-2.2,7.9C372.3,280.8,370.2,277.2,371.9,271.8z"/>
	<path class="st7" d="M371.9,271.8c0.5,3.6,0.2,7.6,5,8.7c0,0.3-0.1,0.5-0.2,0.8c-0.3,0-0.6,0.2-0.9,0.3c-6.8-1.6-7.8-3.9-4.8-10.2
		C371.4,271.6,371.7,271.7,371.9,271.8z"/>
</g>
<g id="mensaje2" [@mensaje2Trigger]="isEstadoMensaje2" (click)="clickEvent('mensaje2')">
	<path class="st8" d="M821,422.1c-3.2-5.6-5.8-11.6-5.9-18.1c-0.2-37.4-0.1-74.8-0.1-112.2c0-4.5,2.1-7.5,6-9.5
		c0.6,1.6-0.2,3-0.7,4.4c-1.5,2.3-1.8,4.9-1.8,7.6c-0.1,33.7,0.1,67.4,0.2,101.1c0,6.7,1.5,13.2,2.5,19.7
		C821,417.4,822.5,419.8,821,422.1z"/>
	<path class="st9" d="M821,422.1c-0.3-2-0.6-4.1-0.9-6.1c1.5-0.5,2.1,0.8,2.6,1.7c5,10.1,13.8,16.2,22.8,22
		c3.7,2.4,7.5,4.7,11.1,7.5c1.2,1.7,4.2,2,3.4,5c-0.7,0.4-1.4,0.3-2-0.2c-4.4-2.7-9-5.1-13.1-8.1c-5.6-4.1-11.8-7.2-16.9-12.1
		C824.9,428.8,822.7,425.7,821,422.1z"/>
	<path class="st10" d="M874,300.1c1.6-0.1,3.3-0.2,4.7,0.9c8.9,6.9,18.4,12.9,28.1,18.7c0.5,0.3,0.8,1,1.1,1.5
		c0.1,0.9-0.4,1.3-1.2,1.4c-5.1-1.3-9.1-4.7-13.3-7.6c-3.2-2.1-6.4-4.2-9.7-6.2c-3.6-3-8.8-4-10.9-8.8
		C873.2,299.7,873.6,299.7,874,300.1z"/>
	<path class="st11" d="M890.8,472.9c-6.6-3.4-12.8-7.6-18.9-11.9c-0.4-0.4-0.5-0.8-0.1-1.3c3-1.3,4.7,1.2,7,2.2
		c1.9,0.9,3.6,2.2,5.5,3c5.3,3.4,10.9,6.3,15.8,10.3c1.5,1.3,3.1,2.5,3.6,4.6c0.1,0.8,0,1.5-0.5,2.1c-2.7-0.1-3-3.4-5.4-3.9
		c-1-0.6-1.9-1.3-2.9-1.9C893.7,474.8,892.1,474,890.8,472.9z"/>
	<path class="st12" d="M939.9,447.8c0,8.1,0,16.3-0.1,24.4c0,1.6,0.5,3.4-1,4.7c-1.9-1.9-1.3-4.3-1.4-6.6
		c-0.4-9.7,0.7-19.5-0.6-29.2c-0.7-5.5-0.2-11.1-0.4-16.7c0.1-2.1-0.3-4.3,0.9-6.3c0.4-0.5,1-0.6,1.6-0.3c2.2,2-0.2,3.9-0.1,5.8
		c0.3,5.5,0.3,11.2,1.2,16.6C940.5,443,940.9,445.3,939.9,447.8z"/>
	<path class="st13" d="M902.9,481.9c0-0.7,0-1.4,0-2c2.7,0,2.7,2.4,3.8,4c0.6,1,1.1,2.1,1.6,3.1c3.6,7.1,3.6,7.1,10.5,3.3
		c4.1-0.6,7.1-3.6,10.9-4.9c0.5-0.1,1-0.1,1.5,0c1.1,0.5,1.6,1.2,0.7,2.3c-5.5,2.3-9.9,6.6-15.8,8.2c-6.6,0.9-6.9,0.9-9.6-4.8
		c-1.2-2.4-2.9-4.5-3.8-7C902.4,483.3,902.3,482.6,902.9,481.9z"/>
	<path class="st14" d="M874,300.1c-0.4,0-0.7,0-1.1,0c-1.2,0.5-2.3,0-3.3-0.6c-9.2-5.6-18.1-11.6-27.3-17.3
		c-0.7-0.4-1.2-1.1-1.5-1.9c-0.1-0.5,0-1,0.3-1.4c8.7,3.4,16.3,8.7,23.9,13.9C868.1,294.9,872.3,296.1,874,300.1z"/>
	<path class="st15" d="M939,417.9c-0.4,0-0.7,0-1.1,0c-1.1-0.9-1.4-2.3-1.4-3.6c0.1-19.9-0.5-39.8,0.3-59.7c0.2-0.6,0.6-1.2,1.1-1.6
		c1.9,1.4,0.9,3.4,0.9,5.1C939,378,939,398,939,417.9z"/>
	<path class="st16" d="M907,322.1c0.3-0.3,0.6-0.6,0.9-0.9c6.2,2.3,10.9,7.1,16.7,10.1c1.6,0.8,4,1,3.5,3.8c-1.2,0.8-2.3,0.3-3.5,0
		c-5.6-3-10.5-7-16.1-10.1C907.8,324.1,906.4,323.6,907,322.1z"/>
	<path class="st17" d="M937.9,353c0,0.4,0,0.7,0,1.1c-0.5,0.2-0.9,0.2-1.3-0.1c-2-4.6-4.2-9.2-6.4-13.8c0-0.8,0.2-1.5,0.8-2
		C934.4,339.9,938.4,348.2,937.9,353z"/>
	<path class="st18" d="M903,483.9c1.3,2.1,3,4,3.8,6.2c1.6,4.6,4.5,6.6,9.3,5.8c-6.1,2.2-7.4,1.7-10-3.4c-1.1-2.2-2.1-4.3-3.2-6.5
		C902.4,485.3,902.4,484.6,903,483.9z"/>
	<path class="st19" d="M841,279c0,0.4,0,0.7,0,1.1c-3.9,1-7.8-1.4-11.8-0.2c-0.5-0.1-1-0.4-1.3-0.8C833,275.8,837.2,275.7,841,279z"
		/>
	<path class="st20" d="M857.9,451.9c0.7,0,1.4,0,2.1-0.1c4.8-0.9,6.8,2.8,9.2,5.8c0.1,0.5,0,1-0.3,1.4
		C865.2,456.7,861.1,455.1,857.9,451.9z"/>
	<path class="st21" d="M931.9,487.7c0-0.8-0.2-1.4-0.9-1.8c-0.5-0.8,0-1.2,0.6-1.7c1.8-0.8,3-2.5,4.6-3.7c0.4-0.2,0.9-0.2,1.3-0.1
		c0.2,0.1,0.4,0.3,0.5,0.4c0,0.7,0,1.4,0,2.2C936.6,485.5,934.1,486.3,931.9,487.7z"/>
	<path class="st22" d="M903,483.9c0,0.7,0,1.4-0.1,2.1c-1.4-2.9-2.8-5.8-5.1-8.1c2.8-0.1,3.5,2.6,5.1,4
		C902.9,482.6,902.9,483.2,903,483.9z"/>
	<path class="st23" d="M930.9,338.2c0,0.6,0,1.2,0,1.8c-3.4-0.6-5.2-2.7-6.1-5.9c1.1,0.3,2.2,0.6,3.3,1
		C929.8,335.4,929.6,337.5,930.9,338.2z"/>
	<path class="st24" d="M820,284.9c0.3-0.9,0.6-1.8,1-2.6c1.1-1.1,1.8-3,4-2.1c0.3,0.3,0.4,0.7,0.3,1.1c-0.5,1-1.2,1.7-2,2.5
		c-0.8,0.7-1.6,1.5-2.7,1.5C820.4,285.2,820.2,285.1,820,284.9z"/>
	<path class="st25" d="M890.8,472.9c2.1,0,3.3,1.3,4.1,3.1C893.2,475.5,891.1,475.4,890.8,472.9z"/>
	<path class="st26" d="M868.9,459c0-0.4,0-0.8,0-1.2c2-0.9,3.1-0.3,3,2c0,0.4-0.1,0.8-0.1,1.2C870.9,460.4,869.9,459.7,868.9,459z"
		/>
	<path class="st27" d="M825,281.1c0-0.3,0-0.6,0-0.9c0.9-0.5,1.7-1.4,2.9-1.2c0.4,0.1,0.9,0.1,1.3,0.2c-0.1,1.4-1.1,1.9-2.2,2.4
		C826.3,281.8,825.6,281.7,825,281.1z"/>
	<path class="st28" d="M856,447.9c-9-6.3-18.8-11.4-26.9-19.2c-3.9-3.8-5.8-8.7-9-12.8c-1.7-6.4-2.9-12.9-2.9-19.5
		c-0.1-34.3,0-68.7-0.1-103c0-3.4,0.7-6.2,3.1-8.5c0,0,0,0.4,0,0.4c2.3,1.2,0.8,2.7,0.2,4.1c-1.7,5.1-0.4,10.4-0.8,15.5
		c-1,22.2,2.3,44.3,3,66.4c0.2,4.5,0.1,8.9,0,13.4c0,5.7,0,5.7,5.6,6.1c1.6,0.1,2.9,0.4,3.7,2c1.6,3.2,0.4,11-2.2,13.8
		c7.8-3.9,11.1-3.7,14,0.7c-0.2,1.7-1,2.9-2.8,3.2c-1.6,0.2-2.6-1-4-1.7c1.1,1.6-0.1,3.4,1,4.8c0.4,2.1,0.9,4.3-1.3,5.9
		c-0.2,0.2-0.5,0.4-0.8,0.5c-0.8,0.3-1.5-0.8-2.3,0c-0.2,0.3-0.1,0.7,0.2,0.8c0.9,0.3,1.5-0.5,2.2-0.9c0.4-0.3,0.8-0.4,1.2-0.3
		c0.7,2,3.1,2.2,4.2,3.7c-1,4,1.5,4.8,4.6,5.2c6.7,0.9,7.3,1.6,6.2,8.1c-0.5,3-0.1,5.1,2.7,6.6c1.6,0.9,2.7,2.4,2.3,4.5
		C856.8,448.3,856.4,448.3,856,447.9z"/>
	<path class="st21" d="M937.9,440.9c1.6,1.5,0.9,3.4,1,5.2c0.1,10.3,0,20.5,0,30.8c-0.1,1.4,0.4,2.9-1,4c0,0-0.1,0-0.1,0
		c-1.4-1.5-1.4-3.3-1.4-5.1c0-9.9,0-19.8,0-29.7C936.5,444.2,936.4,442.3,937.9,440.9z"/>
	<path class="st29" d="M856,447.9c0.3,0,0.7,0,1-0.1c1.6-1.2,2.8-0.1,4,0.7c7,4.4,13.8,9.1,20.8,13.4c1,0.7,1.8,1.5,2.4,2.6
		c0.1,0.5,0.1,0.9-0.3,1.3c-4.4-1.2-7.4-5.1-11.9-6.1c-0.6-1.3-1.6-2-3-2c-3-2-5.2-5.1-9-6C858.7,450.6,857.3,449.3,856,447.9z"/>
	<path class="st30" d="M826.9,281c0.8-0.6,1.6-1.1,2.3-1.7c4.1-2.1,7.9-0.7,11.8,0.8c10.6,6.7,21.2,13.4,31.9,20.1
		c3.7,2.6,7.4,5.2,11.2,7.7c0.5,0.6,0.6,1.3,0.2,2c-2.1,1.2-4.4,0-5.4-1.1c-4.1-4.3-10.3-5.2-14.5-8.8c-3.8-3.2-8.6-4.8-12.1-8.4
		c-2.9-2.9-6.8-4.1-10.3-5.9c-2.8-1.4-5.1,0.8-7.7,0.9c-0.5,0-1,0-1.4-0.1c-2.2-1-4.8-1.2-6.2-3.5
		C826.3,282.3,826.4,281.6,826.9,281z"/>
	<path class="st31" d="M883.9,310.1c0-0.7,0.1-1.5,0.1-2.2c7.6,4.7,15.3,9.5,22.9,14.2c0.7,0.6,1.4,1.3,2.1,1.9
		c-0.3,0.4-0.7,0.7-1.2,0.9c-5.4,1.4-7.4-3.7-11.1-5.8c-2-1.5-4.3-2.4-6.2-4c-0.5-0.4-1-0.8-1.5-1.3c-1.1-1.1-2.5-1.5-3.9-1.9
		C884.5,311.6,883.6,311.3,883.9,310.1z"/>
	<path class="st32" d="M883.9,465.9c0-0.3,0-0.7,0-1c2.5-1.5,4.3,0.4,6.2,1.5c1.6,0.8,3,2,4.6,2.7c2.2,0.9,3.5,3,5.5,4.1
		c2.3,1.2,4,3,5.4,5.2c3.9,0.1,7.3,2.9,11.7,1.3c2.8-1,6,1.6,7.4,3.7c1.1,1.7-1.4,4-3.6,5c-2.4,0.2-5.4-2.5-7.2,1.4
		c1.5,0.9,3.2-0.6,4.5,0.5c0.2,0.2,0.3,0.3,0.5,0.5c-1.1,0.9-2.3,1.9-3.4,2.8c-2.5,2-4.3,1.5-5.9-1.3c-2.3-4.1-3.7-8.7-6.7-12.5
		C898,473.3,890.2,470.7,883.9,465.9z"/>
	<path class="st33" d="M937,354c0.3,0,0.6,0.1,0.9,0.1c0,21.3,0.1,42.6,0.1,63.9c0,2-0.1,4-0.1,5.9c-0.6,0.3-1.1,0.2-1.5-0.4
		c-1-2-0.7-4.2-0.8-6.3c-0.1-15,0.1-30-0.1-45c-0.2-4.4-0.2-8.8,0.1-13.2C935.6,357.2,935.4,355.3,937,354z"/>
	<path class="st34" d="M936.9,424c0.3,0,0.6-0.1,0.9-0.1c0,5.7,0.1,11.4,0.1,17c0,13.3,0,26.6,0,39.9c-0.3,0-0.7,0-1,0
		c-1.6-1.6-1.3-3.7-1.3-5.6c0-15.9-0.1-31.8,0-47.7C935.6,426.2,935.8,424.9,936.9,424z"/>
	<path class="st35" d="M899.9,473.9c-2.7-0.1-3.2-2.8-5-4c-0.5-1.2,0.2-1.8,1.1-2.3c1.4-0.4,3,0.2,4.8-0.9c-1.7-1.7-3-3.7-5.1-5
		c-2.6-1.5-3.2-3.4-0.5-5.4c1.7-1.2,0.6-2.3,0.3-3.5c1.5-2.9,0-6.1,1.2-9c0.7-0.5,1.7-0.7,2.2-0.1c2.5,3.5,4.5,2.2,6.4-0.5
		c4.8-0.3,7.2,3.7,10.7,5.8c1.8,1.1,2.7,3.7,5.7,2.6c1.3-0.5,1.9,1,2.2,2.1c0.6,2,1,4.1,1.6,6.3c0.6-1.3,0.3-3.3,2.2-3.4
		c2-0.2,2,1.8,2.5,3.1c1.4,3.9,2.8,7.7,3.7,11.8c0.5,2.7,0.4,5.4,0.3,8.1c0,2.1-0.3,4.1-2.4,5.2c-0.3,0.4-0.6,0.7-0.9,1.1
		c-0.3,0-0.7,0-1,0c-3.2,0.3-5.2,4.2-8.8,3.3c-0.4-0.4-0.5-0.8-0.2-1.3c1.1-1.4,2.3-2.8,3.7-4.4c-1.8-0.2-3.4-0.2-4.4-1.8
		c-0.3-0.5-1.4-1.3-1.4-1.2c-3.8,3.8-7.8-1.2-11.6,0.3c-2.3,0.9-1.9-0.7-2.3-1.8c-1.2-2,0.4-3,1.6-4c1.3-1.1,2.6-2.1,2.4-4.3
		c-2.2-0.6-3.6,0.6-4.8,2.2C902.9,474.2,901.7,475,899.9,473.9z"/>
	<path class="st32" d="M920.9,488.9c3.5,0.4,5.4-3.7,9-3c-3.5,2.1-6.4,5.4-11,5c0,0-0.1-0.1-0.1-0.1
		C918.6,489.2,919.8,489,920.9,488.9z"/>
	<path class="st36" d="M937,354c0,6-0.1,12-0.1,18c-1.2,6.5-0.1,13-0.6,19.5c-2.4,1.5-2.3-0.9-2.5-1.9c-0.4-2-0.8-4-1.8-5.9
		c-1.3-6.3,0.2-12.7-0.9-19c-0.2-1.1-0.3-2.1,0-3.2c2.8-5.9,1.3-13.3-2.4-18.5c-4.5-6.3-11.7-9.1-17.2-14.1
		c-1.5-1.4-3.9-2.1-3.5-4.9c0.4,0,0.8-0.1,1.1-0.1c5.3,3.4,10.5,6.7,15.8,10.1c2,2,4.1,3.9,6.1,5.9C933.7,344.3,936.2,348.8,937,354
		z"/>
	<path class="st37" d="M931.9,484.8c1-1.5,1.1-3.2,1.1-4.9c1.6-1.6,1.4-3.6,1.4-5.6c0-10.5,0-21,0.1-31.4c0-3.9-0.2-7.8,0.1-11.7
		c0.1-1.6-0.3-3.8,2.3-4.2c0,18,0,35.9,0,53.9C935.3,482.3,934.7,484.9,931.9,484.8z"/>
	<path class="st38" d="M827,283.1c1.9,1.1,4.4,1.1,6,2.9c5.6,3.2,9.6,8.1,13.7,12.9c1.2,1.4-0.5,5.4-2.5,5.8c-0.9,0.2-2,0-2.8,0.3
		c2.1,0.3,5.8-1,5.8,2.3c0.1,4.4-4.6,3.1-7.4,4.3c-0.8,1.7,1.9,2.2,1.5,3.8c-0.1,0.5-0.3,0.8-0.7,1.1c-3.1,0.4-3.7,2.6-3.9,5.2
		c-0.5,5.5,3.8,6.7,7.5,8.6c0.6,0.4,1.1,0.9,1.3,1.6c0.6,1.6-0.7,2.8-0.8,4.6c3.5-1.9,6.3-2,8.1,2.2c1.2,2.9,5.2,1.8,7.2,3.8
		c0.3,0.4,0.6,0.7,0.8,1.1c0.9,1.5,2.7,1.2,4,1.8c1.4,0.7,2.9,1.4,2.5,3.2c-0.3,1.7-1.8,2.3-3.4,2.5c-3.9,0.5-7.1-1.2-9.9-3.7
		c-1.2-1-1.9-2.7-3.8-2.2c-0.4,1.1,0.2,1.5,0.7,1.9c4.2,3.3,4.2,3.3,0.5,7.5c-1.1,1.3-1.1,2.7-1.3,4.2c-0.4,2.2-1.4,4.1-3.1,5.5
		c-3.5-0.4-3.4-3-3.4-5.6c0-3.3,0.9-6.7-1.3-9.6c-1.6-1.8-3.2-3.8-4.1-6c0.4-3.7-2-6.9-2.2-10.5c-0.1-1.2-0.9-2.4-1.9-2.7
		c-5.4-1.8-4.5-6.2-4.6-10.2c0-1.3,0.3-2.6,0.3-4c0-1.5,0.4-3.3-1-4.2c-1.8-1.1-2.2,1.3-3.4,2c-3.6,2.2-4-1-4.3-3
		c-1.1-7.4-1.9-14.8-1-22.2c0.5-0.9,0.8-1.9,0-2.8c1-0.7,1.9-1.4,2.9-2.1C824.3,282.2,825.6,282,827,283.1z"/>
	<path class="st39" d="M827,283.1c-1.4,0-2.7,0.1-4.1,0.1c0.7-0.7,1.4-1.3,2.1-2c0.6-0.1,1.2-0.1,1.9-0.2
		C826.9,281.7,827,282.4,827,283.1z"/>
	<path class="st40" d="M882,462.9c-7.2-3.2-13.2-8.3-19.9-12.3c-1.7-1-3-2.6-5.1-2.8c-0.1-0.2-0.1-0.3-0.2-0.4
		c-4.7-3.9-8.2-8.2-5.3-15c0.5-1.1-1.2-2.5-3-2.5c-1.6,0-3.3,0-4.9-0.3c-4.9-1.1-5.3-2.3-2.5-6.6c-1.7-0.5-3.1-1.4-4-3
		c-0.3,0-0.7,0-1,0l0,0c1.4-2.2,0.9-4.6,1-7c0.4-1.6,1.4-2.6,3-3c1.6,0.2,2-1.4,3-2c1.3-1.6,3.3-0.8,5-1.3c-0.3-0.1-0.5,0.1-0.9,0.1
		c-2.6-0.2-5.6-0.9-5.3-3.8c0.3-3,3.6-2.2,5.8-2c2,0.2,3.9,0.6,5.8,1.1c1.8,0.3,2.5,3.2,4.9,2.2c1,4.9-3.5,4-5.7,5.5
		c-1.6,0.6-3.1,1.2-4.8,1.5c-1.5,0.3-2.9,0.7-4.3,1.3c-1.1,0.5-2,1.3-2.7,2.3c-1,1.9,0.4,3,1.5,4.2c0.5,0.4,1,0.7,1.6,1
		c1.5,0.4,3-0.2,4.4-0.5c1.4-0.3,2.8-0.3,3.2,1.4c0.4,1.9,0.9,3.3,3.3,2c2-1.1,3.2-2.3,2.4-4.8c-0.4-1.2-2.5-2.5-0.3-3.7
		c1.5-0.8,3.1,0.2,3.9,1.4c2.6,3.7,7.8,3,10.6,6.3c2,2.4,3.4,5.4,5.9,7.5c1.6,2.5,3.4,4.8,5.4,7c0.9,1.4,1.9,2.7,2.3,4.3
		c0.3,1.1,0.4,2.2,1,3.2c1.3,2-0.2,3.8-1.8,4.4c-2.3,0.8-1.9-1.5-2.3-2.8c-0.4-1.4-0.5-3-2.1-4c-0.5,2,1.8,3.2,1.3,5.2
		c-0.6,2.5-4.2,0.2-4.3,3c0,0.7-0.1,1.3-0.5,1.9c-3.6,1.8-2.8,3.3,0.1,5C878.7,458.4,882.1,459,882,462.9z"/>
	<path class="st41" d="M839,343c1.3,1.7,2.7,3.3,4,5c0.4,0.9-0.2,1.2-0.9,1.5c-0.2,0-0.3,0-0.5,0c-5.5-2.1-5.5-2.1-4.8,4.1
		c0.7,3.4-2.8,5.4-2.6,8.6c-0.1,2.3,2.1,2.2,3.3,3.1c2.1,1.5,3.7,3.3,0.4,5.1c-2.3,1.3-1.7,2.7-0.4,3.9c1.7,1.5,3,3.5,4.8,4.9
		c1.9,1.4,2.2,3.2,0.8,5.1c-4.2,1.7-7.6,1.3-10.2-3c-1.3-2.2-3.2-4.2-5.9-5.2c-1.4,4.5-1.8,9.1-4,13.2c-1.9-0.9-0.9-2.5-1.9-3.3
		c0.4-13.1-0.2-26.3-1.4-39.2c-1.4-14.3-1.2-28.4-1.6-42.7c2.7,0.3,2.1,2.6,2.2,4.2c0.3,3.3,0.2,6.6,0.1,9.9
		c-0.2,5.8,0.3,11.6,2.2,17.1c0.1,0-0.1-0.2-0.2-0.6c-0.4-2.9-1.8-6.7,2.2-7.5c4.2-0.8,4.8,3.9,4.7,6.1c-0.1,5,5,9.4,1,14.7
		c1.8-1.2,2-3,2.7-4.4C834.8,339.9,835.9,339.8,839,343z"/>
	<path class="st42" d="M821,386c0.5,0.6,0.9,1.3,1.8,2.5c0-5.4,3.2-9.7,2-15.9c4.7,3.3,8.2,5.7,10.5,9.9c1.6,3,5.1,0.8,7.7,1.4
		c0.9,4.4,4.5,7.5,5.5,11.7c0.4,1.7,1.7,2.3,3.2,2.7c1.3,0.3,2.6,0.5,3.8,1.2c1,0.6,2.1,1.3,1.7,2.7c-0.6,2-2.1,0.8-3.3,0.7
		c-2.9-0.3-5.9-0.7-8.8-1c-0.9-0.1-2.2-0.4-2.5,0.9c-0.4,1.6,1.1,1.7,2.1,2.2c2,0.9,4.1,1,7.5,0.9c-3.8,1.6-6.5,2.2-9.3,2
		c-1.5-3.2-4.2-3.3-7-2.4c-2.8,0.8-5.5,1.8-8.7,1.6c3.2-2.7,4.9-9.6,3.6-13.4c-0.7-2-2.3-1.7-3.6-1.6
		C821.1,392.7,821.1,392.7,821,386z"/>
	<path class="st43" d="M839,343c-2.9-2.1-4.6-1.4-5.2,2.2c0,0.3-0.2,0.6-0.4,0.9c-1,1.9-0.9,5.9-3.4,5c-2.1-0.8-1.5-4.8-0.2-7.1
		c1.1-2.2,1.9-3.6-1.4-4.1c-1-0.1-1.7-1.3-1.1-1.8c3.1-2.9-0.3-5.4-0.3-8c0-1.2-0.2-2.5-1.9-2.3c-1.3,0.2-2.1,1-2,2.4
		c0,1.8-0.4,3.7,0.9,5.3c1,1.2,1.6,2.5,0.1,4.4c-2.8-4.7-5-9.2-5-14.6c0-5.5,0-11,0-16.5c0-1.6,0.5-3.4-1-4.7c0-3.8,0-7.6,0-11.4
		c0-1.8-0.1-3.7,2-4.6c2.1,3.2,0.8,6.8,1,10.2c0.3,5.1,0.9,10.1,1.9,15c2,0.1,2-1.4,2.6-2.2c0.8-1.2,1.1-3.2,3.1-2.4
		c1.5,0.6,2.2,1.9,2.3,3.5c0.5,4.1-1,8.2-0.1,12.3c0.4,1.7-0.2,3.2,2.7,3.6c1.5,0.2,6.3,2.1,3.4,6.4c-0.3,0.4-0.6,0.7-0.2,1.2
		C839,338.6,839,338.6,839,343z"/>
	<path class="st42" d="M836,419.9c0,1.6-0.2,3.1-2.4,2.9c-1.1-0.1-2-0.5-2.2-1.6c-0.3-1.5,0.9-2.7,1.8-3.3
		C834.9,417,834.8,419.7,836,419.9C836,419.9,836,419.9,836,419.9z"/>
	<path class="st42" d="M840,410c-0.6,1.3-1.6,2.3-3,3c-2.5-1,0.5-3.5-1.3-4.7C837.6,407.7,838.8,408.7,840,410z"/>
	<path class="st42" d="M837,420c1.9,0.2,2.7,1.9,4,3C838.2,423.8,836.7,423.1,837,420z"/>
	<path class="st44" d="M882,462.9c-1.6-3.7-5.7-4-8.4-6.3c-0.8-0.7-2.8-1.1-1.4-2.9c1-1.3,1.3-3.7,3.8-1.8
		c7.3,3.6,13.5,8.8,19.6,14.2c0.5,0.5,0.7,1.1,0.4,1.8c-0.3,0.7-0.7,1.3-1,2c-4.4-0.1-6.6-4.9-11-5
		C883.3,464.2,882.6,463.6,882,462.9z"/>
	<path class="st45" d="M907.9,324.1c4.9,5.2,11.2,8.5,16.8,12.8c6.6,5.1,9.4,12.4,9.8,20.5c0.1,1.4-1,2.8-2.4,3.6
		c-2.6-2.6-3-6-3.7-9.3c0.3-3.2-0.5-5.8-4.3-6.2c-0.7,0-1.4-0.2-2-0.4c-5.1-3.6-9.9-7.6-15.5-10.4c-11.6-7.7-23.3-15.2-35.2-22.3
		c-10.4-7.5-22-13.4-31.7-21.8c-1.8-1.6-4.7-1.8-5.6-4.5c7.5-4.7,12.9,0.5,18.3,4.2c2.6,1.7,4.8,3.6,7.8,4.9
		c3.2,1.4,5.7,4.2,8.8,6.1c2.3,1.4,5.6,0.6,6.9,2.9c1.9,3.3,5.3,4.2,8.1,6c0.4,0.8,1.2,1,2,1c0.6,2.1,2.9,0.8,4,2
		c0.3,0.3,0.7,0.7,1,1c1.2,2.5,3.7,3,6,4c2.3,0.9,4,2.5,5.6,4.5C903.8,324.2,906,324.1,907.9,324.1z"/>
	<path class="st46" d="M834,286c9.5,6.3,19,12.7,28.6,18.8c3.2,2,5.5,5.3,9.3,6.4c-0.3,3.9,0.3,8.1-5.4,10.2
		c5.3,2.2,7.3,5.5,6.2,10.5c-0.6,2.7,3.2,4.9,1.4,7.9c-0.8,1.7,0,2.9,1,4.1c0.6,1.2,0.8,2.3-0.2,3.4c-3.2,0.6-4.8-1-5.3-4
		c-0.2-1.5-0.6-2.8-1.4-4c-2.6-9.3-10.5-12.9-18.2-16.8c-3.3-1.7-5.1-5.6-9.1-6.5c0.3-2.1-2.3-0.8-2.6-2.3c-0.2-1.6-2.3-4,1.7-3.5
		c0.5,0.1,1,0.1,1.5,0c1.9-0.3,4.9-0.4,4.9-2.3c0-2.9-3.1-1.2-4.8-1.6c-1.3-0.4-3,0.6-4.4-1.2c3.2-1.6,8.1,0,8.6-4.7
		c-4.2-4.6-8.5-9.4-12.9-14.2C833.4,286,833.7,286,834,286z"/>
	<path class="st30" d="M889.9,313.1c-1.1,0-2.3,0.1-3.4,0c-0.7-0.1-1.7,1.1-2-0.2c-0.1-0.5,0.9-1.2,1.4-1.8
		C887.5,311.2,888.9,311.8,889.9,313.1z"/>
	<path class="st30" d="M896.9,318.1c-2.7-0.2-5.4-0.5-6-4C892.9,315.4,894.9,316.7,896.9,318.1z"/>
	<path class="st47" d="M899.9,473.9c3.7,1,2.5-4.8,6.1-4.3c1.5,0.2,3.3-3.2,4.5-0.7c1.1,2.3,0.1,5.3-2.5,6.7c-1.5,0.8-3,1.2-3.1,3.3
		C903.3,477.2,901.6,475.6,899.9,473.9z"/>
	<path class="st48" d="M920.9,488.9c-0.7,0.7-1.3,1.3-2,2c-1,0.1-2,0.2-3,0.2c-1.3,0-2.2,2.7-3.7,0.7c-0.6-0.8-0.3-2,0.4-2.8
		c1.2-1.3,2.1-3.3,4.3-2.9c1.4,0.3,2.7,1.2,4.1,1.8C920.9,488.2,920.9,488.5,920.9,488.9z"/>
	<path class="st49" d="M936.8,427c-2,5.2-0.4,10.7-1,16c-1.1,1.1,0.1,2.9-1.4,4.2c-2.5-1.5-1.6-4.3-2.6-6.3
		c-0.2-0.8-0.3-1.7-0.4-2.6c-0.1-4.7,0-9.4,0-14.1c0.1-1.4,0.1-2.9,1-4.1c1.4-0.7,1.7,0.2,1.8,1.3c0.1,2.7,0.1,5.4,0,7.7
		c0.1,1.1,1.1,0.1,0.5,0.5c-0.2,0.1-0.3,0-0.3-0.4c0.2-3.7-1.1-7.5,0.7-11.1c0.5-0.4,1-0.4,1.5-0.2c0.1,2,0.1,4.1,0.2,6.1
		C936.9,425,936.9,426,936.8,427z"/>
	<path class="st50" d="M936.8,417.9c-0.3,0-0.6,0-0.9,0c-2.4-2.8-1.3-6.2-1.4-9.4c-0.1-2.8,0-5.5,0-8.3c0.2-2.8-0.8-5.8,1.4-8.2
		c0.6-6.6-1.2-13.4,1-20C936.8,387.3,936.8,402.6,936.8,417.9z"/>
	<path class="st51" d="M932.8,438.9c0,0.7,0.1,1.3,0.1,2c0.6,0.8,0.4,1.6,0.2,2.5c0.5,7.5-0.4,15,0.8,22.4c0.2,1.8,0.5,3.6-1.1,5.1
		c-1.6-4.4-3.1-8.8-4.8-13.5c-2.7,1.5,0.3,5.2-2.7,6.9c-1-4-2-7.9-2.9-11.8c-2.1-0.1-0.9,2.4-2.3,2.7c-4.1-5-9-8.9-15.1-11.3
		c-2.5,0.2-1.8-2.3-2.8-3.3c-0.3-0.4-0.5-0.8-0.7-1.2c-1.8-2.4-3.7-4.6-4.3-7.7c-0.5-2.4-2.4-3.9-4.3-5.2c-1.9-1.3-4-2.4-5.5-4.2
		c-2.4-2.9-6.5-4.9-2.2-9.8c1.9-2.1,1.1-6.4,0.8-9.7c-0.2-3.1,0.1-6,2.6-8.2c5.7-2.8,6.9-2.4,9.7,3c1.7,3.2,2.3,6.7,7.9,5.3
		c3-0.7,7.8,1.2,9.3,5.8c-0.1,0.8-0.4,1.3-1.2,1.6c-1.7-0.1-2.8-1.6-4.4-2.2c-1.7-0.6-3.2-0.9-4.1,1.2c-0.7,1.4-0.7,2.9-0.6,4.4
		c-0.1,2.9,1.9,4.3,4.1,5.6c1.2,0.7,2.4,1.4,3.4,2.4c3,4.8,7.6,7.8,12.9,9.7c0.3-0.5-0.1-1.7,0.1-2.8c0.3-1.6,0-3.9,2.2-3.9
		c2.5,0,2.1,2.5,2.3,4.2c0.3,2.1-0.4,4.3,1,6.2C932,436.3,931.9,437.8,932.8,438.9z"/>
	<path class="st52" d="M902.6,440.3c1.9,0.5,0.7,2.8,2.3,3.7c-0.3,1.8-0.7,3.8-2.4,4.4c-2,0.6-2.2-1.9-3.2-2.9
		c-0.7-0.7-1.2-1.4-2.3-1.5c-5.6-2.5-9.3-6.6-11-12.6c1.2-5.6,1-5.9-4.2-5.9c-1.3-0.8-2.6-2-2.5-3.5c0.3-3.7-2.1-3.9-4.7-4.1
		c-3.3-2.3-3.4-6.7-6-9.5c-0.2-0.5-0.3-0.9-0.4-1.4c0-2.7,1.6-4.6,3.2-6.5c2.9-3.4,5.9-6.7,8.1-10.7c0.8-1,1.9-1.5,3.2-1.5
		c0.3,0,0.7,0,1,0c6.3-1,6.7-0.3,5.4,6.8c-3.6,4.7-1,10.2-1.9,15.3c-0.5,2.8-0.1,5.6-4.3,6.4C891.6,423.2,899.1,430,902.6,440.3
		c-0.6,0.8-0.6,0.8-0.3,0.4c0.2-0.3,0.4-0.5,0.6-0.7C902.8,439.8,902.7,439.9,902.6,440.3C902.6,440.4,902.5,440.5,902.6,440.3z"/>
	<path class="st47" d="M895.9,467.9c-5.9-2.9-8.7-9.9-15.7-11.6c-1.6-0.4-3.9-1.9-4.3-4.4c0-0.7,0-1.3,0-2c3.9-1.8,7.5-1.8,10.3,1.9
		c1.4,1.9,2.7,2,4.5,1c1-0.6,2.1-0.8,3.3-0.8c0.7,0,1.4,0.3,1.9,0.9c1.5,1.6,4.2,3.2-0.4,4.4c-1.2,0.3-2,2.3-0.8,2.6
		c3.9,0.8,4.5,5.5,8.3,6.4C901.1,468.9,898.8,469.7,895.9,467.9z"/>
	<path class="st53" d="M895.9,452.9c-0.7,0-1.3,0-2,0c-0.4-0.3-0.6-0.7-0.7-1.1c0-0.4,0-0.9,0.1-1.3c1.1-1.8,1.2-3.6-0.4-5.2
		c-6-8-12.8-15.3-18.7-23.3c-0.3-0.7-0.4-1.4-0.5-2.2c0-1.1,0.3-2,1.1-2.8c1.3,0.8,2-0.3,3-0.7c1.5-0.5,3.2-0.5,2.4,2
		c-1,2.7,0.1,4.7,1.7,6.7c2.4,1.4,4.3,3.2,5,6c-0.5,3.7,3,5.1,4.6,7.7c1.3,2.1,3.6,3.6,5.4,5.3C897.2,447,899.2,450.2,895.9,452.9z"
		/>
	<path class="st54" d="M932.8,443c0-0.7,0.1-1.4,0.1-2c1.7,1.3,0.5,3.4,1.3,5.1c0.6-1.1,1.1-2.1,1.7-3.1c0,10.6,0,21.1,0,31.7
		c0,2.3,0.5,4.9-2.9,5.3c0-3-0.1-6-0.1-9c0-1.7,0-3.4,0-5C932.1,458.2,932.1,450.6,932.8,443z"/>
	<path class="st55" d="M922.9,344.3c0.4,0.2,0.7,0.5,1.1,0.7c2.6,1.2,4.3,3.2,4.8,6c1.1,3.3,2.1,6.6,3.2,9.9c0,1,0,2-0.1,2.9
		c0.8,2.6,0.3,5.2,0.3,7.8c-1.3,6.2,0.4,12.5-0.3,18.8c-0.1,0.5-0.3,1-0.6,1.4c-5.3,2.6-4.7,1.8-8.7-2.1c-3.6-3.5-5.4-7.7-7.4-11.9
		c-1.2-2.5-4.5-1-4.8-3.8c3.4-4.3,3-8.8,1-13.4c-0.7-3-4.1-4.7-4.1-8.1c3-2.8,1.4-7.3,3.7-10.4c3.2-1.7,5.9,0.4,8.6,1.3
		C920.9,343.9,921.6,345.1,922.9,344.3z"/>
	<path class="st56" d="M935.9,392c0,2.7,0,5.3,0,8c-1.3,2.5,0.2,5.4-1.1,8.3c-2.6-1.5-2.1-4.1-3-5.9c-0.2-6.2-0.8-12.4,1.2-18.4
		C936.6,385.7,933.5,389.8,935.9,392z"/>
	<path class="st57" d="M933,384c0,6.3,0,12.7,0,19c0.6,1.7,0.7,3.5-0.1,5.2c-0.7,0.1-1.2-0.1-1.6-0.7c-1.2-5.2-1.2-10.4,0.1-15.7
		c0.4-6.6-0.6-13.2,0.5-19.8c0-2.7,0.1-5.4,0.1-8.1C934,370.5,932.3,377.3,933,384z"/>
	<path class="st58" d="M869,338c1.3,1,2.1,2.4,1.9,3.9c-0.5,3.4,1.7,4.2,4.1,5.1c2.4,3.1,4.7,5.9,5.9,10c1.8,6.3,6.6,6.7,10.9,1.7
		c1.3-1.5,2.5-3.3,4.8-1.4c1.2,3.9,1.2,7.8-0.3,11.6c-0.6,1-1.4,1.6-2.5,2c-2.4,0.1-4.6,1.4-6.9,1.6c-0.3,0.1-0.4-0.7-0.8-0.3
		c-0.5,0.4,0-0.4,0.2,0.3c-0.5,1.1,1.1,1.1,1,1.9c-0.9,2.4-3,3.4-4.9,4.6c-1.8,0.6-2.8,2-3.8,3.5c-0.7,1-1.4,2.3-3.1,1.5
		c-1.1-0.9-1.6-2.5-3.4-2.6c-6.4-0.6-11-3.9-13.8-9.5c-1.1-2.1-3.1-2.1-4.9-2.8c-2.5-1-6.6-0.4-6.3-5c0.3-3,2.9-5.5,2.1-8.9
		c-0.2-0.8,0.3-1.6,1.2-1.9c1.3-0.4,3.4-0.3,3.3-2.3c-0.1-2-2.2-2.2-3.7-2.8c-1.3-0.5-2.8-0.8-2.4-2.5c0.2-1.2,1.2-2,2.4-2.3
		c1.5-0.5,3.3-0.3,3.8,1.3c0.9,2.7,3.5,3.2,5.4,4.6c1.5,1.1,3.1,0.8,4.7,0.9c1.2,0,2.5-0.2,2.7-1.6c0.2-1.5-1.4-1.5-2.3-2.2
		c-1.3-1-4.3,1.4-4.2-2.2C864.2,343.9,867.5,342.3,869,338z"/>
	<path class="st59" d="M847,364c1.9,2.9,4.9,3.9,8.1,4c2.2,0,3.2,0.6,4.2,3c2.3,5.4,6.8,8.9,12.8,8.9c3.6,0,2.9,2.5,3.9,4.1
		c-0.3,1.8-1.2,3.1-2.9,3.7c-1.3,0.3-2.5-0.5-3.8-0.8c-0.1,1.9-0.5,3.5-0.9,5.1c-0.3,0.6-0.7,1.2-1.3,1.6c-2.3,1.3-4.8,2.4-4.9,5.6
		c-1.1,1.2-3,1.8-2.8,4c-0.2,0.7-0.6,1.2-1.4,0.9c-2,2.4-2.8-0.1-4-1c0.8-0.3,2.3-0.4,2.4-0.9c0.3-1.2-1.1-1.5-1.9-1.8
		c-1.6-0.5-3.2-0.7-4.8-1c-1.6-0.4-2.8-1.1-2.6-3c0.2-2.3-0.5-3.6-2.9-4.9c-2.4-1.3-0.7-4.9-1.1-7.4c0.5-4.3-3.6-6-5.9-7.6
		c-2.7-2-1.9-3.9-2-6c-0.1-3,1.6-1.2,2.8-1c0.9,0.2,1-0.7,0.9-1.4c-0.3-1.6-1.7-2.1-3-2.1c-2.9,0-4.2-0.9-2.8-3.9
		c2.3-1.7,4.4,1,7.1,0.3c0.2-1.4-1.5-2.8-0.7-4.3c2.5-2.5,1.6-6,2.5-9c0.4-0.3,0.7-0.7,1.1-1c3.2,3.1,2,7.1,1.8,10.6
		C844.7,361.1,844.8,362.8,847,364z"/>
	<path class="st60" d="M869,338c-0.5,6.3-2.6,7.7-9,6c0-0.3,0-0.7,0-1c-0.3-0.3-0.3-0.5-0.3-0.8c0.1-0.3,0.1-0.4,0.2-0.4
		c2.1-1.1,7.5,1.4,5.2-4.6c-2.6-6.7-8.8-9.1-14.4-12.1c-1.1-0.4-2.1-0.9-3-1.7c-1.5-3.1-6.6-2.8-6.7-7.2c0,0,0.1-0.2,0.1-0.2
		c1.9-0.7,4.3-0.4,5,1c2.2,4.8,7.2,5.6,10.9,7.8C862.7,328,867,331.7,869,338z"/>
	<path class="st61" d="M840.9,316.2c1.5,3.2,6.3,3,7.3,6.8c-1.8,1.5-3-0.1-4.4-0.8c-1.4-0.7-2.4-2.4-4.7-1.6
		c1.9,3.3,4.6,5.6,7.5,7.7c1.8,1.3,3.6,2.4,4,4.8c-0.1,0.5-0.2,0.9-0.6,1.3c-2.5,0.9-3.6-0.9-5-2.4c-0.3-0.3-0.6-0.6-0.9-0.9
		c-3.6-2.2-7.6-4-8.2-8.9C835.6,318.4,835.6,315.1,840.9,316.2z"/>
	<path class="st58" d="M845,331.9c2.1-0.2,2.9,2.3,4.9,2.3c4.8,0.6,7.9,3.5,10.1,7.5c0,0.4-0.1,0.9-0.1,1.3
		c-3.6-1.2-8.2-0.7-9.3-6.1c-0.3-1.5-2.5-1-3.8-0.1c-1.1,0.8-2.3,1.7-3.5,0.6c-1.6-1.5,1.1-1.9,1.1-3.2
		C844.4,333.5,844.8,332.7,845,331.9z"/>
	<path class="st62" d="M836,322.1c1.7,4,5.6,5.9,8.2,8.9C838.6,330.1,835.1,326.3,836,322.1z"/>
	<path class="st63" d="M870.9,423c-4.2-3.2-10.6-2.6-13.5-8.4c1.4,2.8,2.8,5.5,0.9,7.7c-1.4,1.7-4.2,3.2-6.2,3.2
		c-2-0.1-1.1-3.3-1.7-5.1c-2.5,0.1-4.8,2.5-7.4,0.7c5-5.4,11-8.6,18.6-8.3c1.3,0.1,2.5,0.3,3.5,1.1c1.3,1.3,2.4,2.7,4,3.6
		c1.4,1.2,2.9,2.2,3.1,4.3C872.1,422.4,871.7,422.8,870.9,423z"/>
	<path class="st44" d="M893.9,451.9c0,0.3,0,0.7,0,1c-1.2,0.7-2.6,0.5-3.7,1.9c-1.1,1.4-4.2,0.2-4.3-0.3c-1-6.8-6.3-3.9-9.9-4.5
		c-1.1-0.8-1.3-2-0.6-3.1c0.7-1.1,1.4,0,2,0.5c0.9,0.7,1.9,0.7,2.7-0.2c1-1.1-0.2-1.7-0.8-2.5c-1-1.4-2.7-2.7-1.5-4.8
		c0.4-0.6,1.2-0.8,1.7-0.3c1.3,1.4,3.6,2.1,3.5,4.5c-0.1,1.3,0,2.5,0,3.9c5-2.1,0-4.9,1-7.1c1.3-0.6,1.9,0.6,2.8,1.1
		c0.9,0.7,1.6,1.5,2,2.5c0.5,1.4,1,2.8,2,3.9C891.8,449.6,892.9,450.8,893.9,451.9z"/>
	<path class="st64" d="M862.1,413.9c-7.6-1.1-12.9,4.2-19.1,7.1c-0.3-0.3-0.7-0.7-1-1c-0.3-0.2-0.4-0.5-0.4-0.7c0-0.3,0-0.4,0.1-0.4
		c0.6-0.7,1.3-1.2,2-1.7c1.4-1.2,2.8-2.2,4.4-3c3.2-1.3,6.3-2.8,9.7-3.7c1.3-0.2,2.4,0.1,3.4,1C861.9,412,862.4,412.8,862.1,413.9z"
		/>
	<path class="st65" d="M858,411c-3.1,1.9-6.6,2.9-10,4c-1.8,0.2-3.7,0.5-5.3-0.9c-0.4-0.8-0.4-1.5,0.2-2.2c1.1-1.2,2.7-1.2,4.1-1.7
		c2.2,0.6,4.2-0.2,6.3-0.8C855.3,408.9,857,408.8,858,411z"/>
	<path class="st66" d="M858,411c-3.5-2.4-7.4,1.9-10.9-0.8c1.6-0.4,3.3-0.8,4.9-1.3c4.1-0.2,6.8-2.4,8.4-6.2
		c1.1,0.1,1.7,0.7,1.9,1.8c-0.7,2.2,0.2,4.5,0.1,6.7c-0.3,0.7-0.8,1.2-1.6,0.8C859.9,411.8,859,411.4,858,411z"/>
	<path class="st63" d="M871,427.9c-2.7,1.2-5.2,1.3-6.7-1.7c-0.5-1.1-0.5-2.3,0.5-3.2c1.2-1,2.4-0.2,3.1,0.6
		C869,424.8,869.9,426.5,871,427.9L871,427.9z"/>
	<path class="st67" d="M861,403c-2,3.5-3.8,7.2-9,6c1.6-2.1,6.3-0.6,6-5c0.2-0.4,0.4-0.8,0.6-1.1c0.4-1.7,1.2-2.7,3.2-2.2
		c0.7,0.7,0.4,1.3-0.1,2C861.4,402.7,861.2,402.9,861,403z"/>
	<path class="st68" d="M870.9,423c0.2-0.3,0.5-0.7,0.7-1c1.3,0,2.1,0.8,2.8,1.7c0.9,2,3.3,3.2,2.9,5.9c-0.2,0.2-0.3,0.3-0.5,0.5
		C873.3,429,872.7,425.5,870.9,423z"/>
	<path class="st69" d="M881.9,437c-2.6-1.7-4.4-3.8-5-7c0,0,0.2-0.1,0.2-0.1c4,0.6,5.5,3.5,6.1,7C882.7,437.3,882.3,437.3,881.9,437
		z"/>
	<path class="st70" d="M843,411.9c0,0.7,0,1.3-0.1,2c0.1,1.4,1.7,2.4,1,4c-0.4,0.8-1.1,0.8-1.8,0.7c-2.1-1-3.2-2.3-1.9-4.7
		C841.1,413.2,841.3,411.6,843,411.9z"/>
	<path class="st71" d="M881.9,437c0.3,0,0.7,0,1,0c3.6,0.6,4.1,2.9,3.4,5.9c-0.8-0.6-1.6-1.2-2.4-1.9
		C883.4,439.6,881.9,438.7,881.9,437z"/>
	<path class="st72" d="M840.2,414c0.2,1.7,1,3.2,1.9,4.7c0,0.4-0.1,0.9-0.1,1.3C839.8,418.4,837.5,416.9,840.2,414z"/>
	<path class="st63" d="M871,427.9c1.6,1.5,1.1,3,0.2,5.1C869.7,430.9,871.5,429.4,871,427.9C871,427.9,871,427.9,871,427.9z"/>
	<path class="st73" d="M841.9,349c0.6,3.2,2.8,6.7-1.9,8.8c-2.3-0.3-3.2-2.1-4-3.9c0-0.2,0-0.3-0.1-0.5c-0.9-2.3-2.3-5.1-0.2-6.8
		C838.4,344.5,839.9,348.1,841.9,349z"/>
	<path class="st38" d="M836.1,354c1.3,1.3,2.7,2.6,4,3.9c0,0.7,0.1,1.3,0.1,2c0.2,1.5,4,1.3,2.1,3.5c-1.3,1.5-3.7,0.8-5.6,0
		c-1.2-0.5-2.5-0.9-3.7-1.3C832.1,358.6,836.2,357.1,836.1,354z"/>
	<path class="st74" d="M895.9,357c-1.3,0-2.1,0.7-2.9,1.7c-4.8,6.8-4.9,6.9-11.1,1.6c-4-3.4-5.1-8.6-7-13.2c0-1.1,0.2-2.3-1-3
		c-0.5-1.3,0.2-2.9-1-4c0.2-1.7,1.4-3.4-0.9-4.7c-1.5-0.8-1.2-2.6-0.7-4.1c1.6-4.4-0.2-7-4.5-8.2c-1-0.3-2.2-0.3-2.3-1.5
		c-0.1-1.1,1.1-1.6,2.1-1.9c2.8-0.8,5.1-1.7,2.5-5.2c-0.5-0.7-0.5-2.4,1.7-1.7c1.8,0.6,0.8-1.1,1.1-1.7c11.8,7.4,23.8,14.4,35,22.6
		c2.1,2.5,5.2,4.4,4.9,8.3c-0.3,1.9-1.1,3.8-0.9,5.7c0.3,2.7-1,4.2-3.1,5.3c-0.1,0.1-0.2,0.4-0.2,0.4
		C903.3,353.2,899.6,354.9,895.9,357z"/>
	<path class="st75" d="M911.9,342c-1.6-2.8-3.3-5.5-4.9-8.3c6.1,2.4,10.6,6.9,15.9,10.5c-1.2,0.4-7-0.6-8.7-1.4
		C913.5,342.5,912.7,342.3,911.9,342z"/>
	<path class="st36" d="M928.8,351.1c-1.6-2-3.2-4-4.8-6c1.2-0.2,1.7-2.2,3.4-1.4C925.4,346.6,932.7,347.7,928.8,351.1z"/>
	<path class="st58" d="M873,340c3.3,0.6,1.5,2.4,1,4C872.5,343,872.1,341.7,873,340z"/>
	<path class="st74" d="M867.3,311.2c-0.5,0.4-0.9,1-1.4,1.1c-0.6,0.2-0.8-0.5-0.5-0.8c0.4-0.5,1-0.8,1.5-1.1
		C867,310.6,867.1,310.8,867.3,311.2z"/>
	<path class="st76" d="M931.9,392c0,5.3,0,10.6,0,16c2.3,3.4,1.4,7.1,1,10.6c-0.2,1.8-0.9,3.5-0.1,5.3c0,5,0,10,0,15
		c-1.3-0.6-1.9-1.6-1.9-3c-2-4.7-1.1-9.8-1.5-14.6c-0.6-7.9-0.2-15.8-0.1-23.8c0-2,0.1-4,1.6-5.6C931.2,391.5,931.6,391.5,931.9,392
		z"/>
	<path class="st77" d="M932.8,408c0.1-1.7,0.1-3.4,0.2-5.1c1.5,0.9,0.5,2.7,1.5,4.1c1.3-2.3-0.6-5.1,1.5-7.1c0,6,0,12,0,18
		c-2.1,4.1-0.1,8.7-1.5,13.3c-1.5-3.9,0.7-7.9-1.5-11.3C932,416,932.3,412,932.8,408z"/>
	<path class="st78" d="M932.8,408c0.1,4,0.1,7.9,0.2,11.9c0,1.3-0.1,2.7-0.1,4c-1.9-5.2-0.4-10.7-0.9-16
		C932.2,408,932.5,408,932.8,408z"/>
	<path class="st78" d="M930.9,392c0,14.6,0,29.3,0,43.9c-2.5-0.3-1.9-2.3-2-3.8c-0.2-2.2,0.5-4.5-1-7.3c-2,3.8-0.2,7.4-1.9,11.5
		c-2.5-4.2-5-7.5-9.9-6.6c-0.3,0-1.1-1-1.1-1.4c0.5-3-1.5-4.5-3.2-6.3c2.8-3.6,2.5-7.8,2-12c0.3-0.3,0.7-0.7,1-1
		c2.2-0.7,2.3,2,3.8,2.5c1.1-2.2-0.4-3.9-1.1-5.6c-0.9-2,0.6-3.4,1.2-4.9c-2.4-1.6-4.4-1-6.7,0.1c-4.2,1.9-5.8,1-6.4-3.6
		c-0.3-2.1-1.1-3.6-2.6-5.1c-2.1-2.1-1.9-4.9-0.8-7.5c1-2.4,3.3-1.7,5.5-1.2c-2-3-3.5-5.8-0.1-8.6c1-0.5,1.9-1.6,3.2-1.1
		c4.9,1.7,7.9,4.8,8.1,10.3c0,1.2,1,1.6,2.1,1.7c1.2,0.2,2.5,0.7,2.6,1.9C924.1,392.9,927.5,392.5,930.9,392z"/>
	<path class="st79" d="M908.9,376c-3.1,1.8-3.1,4-0.2,5.8c1.2,0.8,2.6,0.9,1.8,2.7c-0.7,1.4-2,1.8-3.3,0.7c-1.3-1.1-2.9-2-3.7-0.3
		c-1,2-1.5,5.7,0.3,6.3c3.9,1.5,3.1,4.1,3.1,6.8c0,2.9,1.5,3.4,3.9,2.5c5.2-2,5.2-2,12.4,0.5c-5.5,1.3-5.9,3.9-2.7,7.5
		c0.5,0.6,0.4,2,0.2,2.8c-0.4,1.2-0.7,3-2.2,3c-1.4,0.1-1.5-1.5-1.6-2.7c-0.1-1.3-0.8-2.1-2-2.6c-3.5-3.1-6.6-7.2-12.2-3.6
		c-1.7,1.1-2.7-0.6-3.4-1.9c-0.7-1.2-1.3-2.7-1.7-4c-1.5-4.7-4-6.5-8.8-4.5c0.7-2.3-3.4-3.9-0.5-6.4c-2.1-1-3.5,2.5-5.5,0.4
		c0.8-4.9,3.6-8.6,7.7-11.1c3.8-2.3,8.3-3.5,12.6-4.6C905.6,372.6,908.3,372.5,908.9,376z"/>
	<path class="st80" d="M913.9,410c3,6.3,2.6,8.9-2,12c-1-0.7-2-1.3-3-2c0.1-0.2,0.3-0.4,0.4-0.5c2.5-0.9,4.1-2.4,3-5.2
		c-1.3-3.2-3.6-5.1-7.4-4c-0.3-0.3-0.4-0.5-0.3-0.8c0.1-0.3,0.2-0.4,0.3-0.4C907,404.9,909.8,405.2,913.9,410z"/>
	<path class="st35" d="M932.8,443c0,7.6,0,15.3,0,22.9C931.3,458.2,931.3,450.6,932.8,443z"/>
	<path class="st80" d="M908.9,420c-3-0.9-5-2.6-5-6c0.7-0.6,0.3-1.7,1.1-2.2c2,0.2,1.8,2.2,2.5,3.4c0.3,0.5,0.6,1.1,0.9,1.6
		c0.5,0.9,1,1.9,0.7,3L908.9,420z"/>
	<path class="st81" d="M905,411.8c-0.1,0.8-0.2,1.7-1.1,2.1c0.3-1.7-0.7-3.6,1-4.9c0,0.4,0.1,0.7,0.1,1.1
		C905.5,410.7,905.5,411.2,905,411.8z"/>
	<path class="st82" d="M908.9,376c-1.6-1.7-3.4-2.3-5.8-1.6c-8.6,2.3-16.4,5.7-20.2,14.6c-1,0.3-2,0.7-3,1c-0.3-0.4-0.5-0.8-0.5-1.3
		c4.1-8.3,11.1-13.4,19.2-17.4c0.9-0.4,1.7-0.8,2.7-1c1-0.1,1.9,0.3,2.8,0.6c3.9,1.2,6.3-0.5,6.4-4.6c0.1-1.8-0.9-3.5-0.1-5.3
		c0.6-0.9,1.4-1.4,2.5-1c0.2,1.1,0.2,2.3,0.7,3.3c2.1,4.4,1.2,7.9-2.7,10.7C910.3,374.7,909.6,375.3,908.9,376z"/>
	<path class="st83" d="M879.9,388.9c0,0.4,0,0.7,0,1.1c-0.8,4.7-4.8,7.2-7.4,10.7c-1.4,2-3.3,3.6-3.6,6.2c-2.2,0.2-2-1.6-2.4-2.9
		c-0.1-1,0.4-1.9,0.9-2.7C871.2,396.8,874.5,391.8,879.9,388.9z"/>
	<path class="st51" d="M886.9,430.9c-1.7-2-3.3-4-5-6c1.7,0.9,3.5-2.1,5-0.2C888.5,426.7,888.6,428.9,886.9,430.9z"/>
	<path class="st83" d="M875,417c0,1,0,2,0,3c-4.8-1.6-5.7-5.9-6.9-10c0.1-0.8,0.3-1.5,1-2C872.7,409.8,871.8,414.7,875,417z"/>
	<path class="st84" d="M903,439.8c-0.4,1.6,0.6,3.3-1.5,5.1C901,442.6,900.1,440.9,903,439.8z"/>
	<path class="st85" d="M869,408c0,0.8-0.1,1.5-0.1,2.3c-1.2,0.5-2-0.1-2.6-1.1c-0.4-1-0.5-2.1-0.3-3.2c0.2-0.8,0.5-1.5,1.3-2
		c0.6,1,1.2,2,1.8,3C869,407.3,869,407.6,869,408z"/>
	<path class="st86" d="M886.3,442.8c-0.1-2.6-1.8-4.2-3.4-5.9c-1.6-2.6-3-5.4-5.8-7.1c-0.1-2.4-3.2-3.3-3.2-5.8
		c-0.4-1.2,0.1-1.9,1.2-2.4c6.8,7.3,13,15.1,18.8,23.3c-1.1,1.5-2.2,2.9-4.3,1.7c-0.8-0.5-1.3-1.2-1.6-2.1
		C887.4,444,886.9,443.4,886.3,442.8z"/>
	<path class="st87" d="M875.1,421.7c-0.4,0.8-0.8,1.6-1.2,2.4c-0.7-0.7-1.5-1.5-2.2-2.2c-1-1.3-1.9-2.6-2.8-3.9
		c-1.1-1.2-2.6-2.1-3.8-3.3c-0.8-0.9-1.8-0.7-2.8-0.9c-0.4-0.6-0.9-1.2-1.3-1.7c0.4-0.4,0.9-0.8,1.3-1.1c1.3-1.3,2.8-2.1,4.7-2
		c0.7,0.4,1.4,0.9,2.1,1.3c1.3,3.7,3.6,6.8,6.1,9.7C875,420.6,875.1,421.1,875.1,421.7z"/>
	<path class="st88" d="M889.8,446c1.7,0.8,2.8-0.6,4.2-1c2,2,2.8,4,0,6c-1.8,0.5-2.3-1.2-3.3-1.9C890,448.1,889,447.3,889.8,446z"/>
	<path class="st89" d="M891.2,448.8c0.9,0.7,1.8,1.4,2.7,2.1c0,0.3,0,0.7,0,1c-2,0-3.1-1.4-4-3C890.3,448.5,890.7,448.5,891.2,448.8
		z"/>
	<path class="st90" d="M912.9,360c-0.7,0.4-1.5,0.7-2.2,1.1c-1.9-0.1-2.5-1.9-3.8-2.7c-5.3-3.2-6.6-2.5-7.6,3.5
		c-0.4,2.8,0.6,6.1-2.9,7.7c-0.5,0.1-0.9,0-1.4-0.2c1.5-4,0.7-8.3,0.9-12.4c3-4.3,7-5.8,12-4C909.6,355.4,911.2,357.7,912.9,360z"/>
	<path class="st78" d="M931.9,392c-0.3,0-0.7,0-1,0c0.6-6.6-1.2-13.4,1-19.9C931.9,378.7,931.9,385.3,931.9,392z"/>
	<path class="st91" d="M895.1,369.4c0.3-0.1,0.6-0.1,1-0.1c1.9,0.1,3,1,3.2,2.9c-8,3.8-14.4,9.3-19.3,16.8c-4,4.3-8,8.7-12,13
		c-1.9,1.2-2.8-0.1-3.6-1.6c-0.5-4.3,3.2-5.9,5.4-8.4c4.2-4.6,8-9.5,12.4-14c1.5-1.4,3.1-2.7,4.6-4.1c1.2-3,4.2-3,6.6-3.9
		C893.9,369.9,894.5,369.6,895.1,369.4z"/>
	<path class="st92" d="M882.1,378.1c-4.1,6.3-9.1,11.8-14.1,17.3c-1.2,1.3-2.2,2.9-3.2,4.4c-0.6,1.2-1.4,1.9-2.9,1.4
		c-1.7-0.5-2.5,0.6-3.3,1.8c-1.4-3.5,1.5-3.3,3.4-3.9c1.4-2.2,2.8-4.5,5-6c0.3-0.3,0.7-0.7,1-1c0.6-2.7,2.5-4.2,5-5c1-1,2-2,3-3
		C878.2,382.2,878.1,378,882.1,378.1z"/>
	<path class="st74" d="M893.3,370.1c-2.2,1.3-4.4,2.6-6.6,3.9c-1.9,0-1.1-1.1-0.8-2l0,0C888.5,371.6,890.4,369.1,893.3,370.1z"/>
	<path class="st74" d="M885.9,372c-1.2-0.2-2.6-0.3-2.5-2.2C885.1,369.7,885.8,370.5,885.9,372C886,372,885.9,372,885.9,372z"/>
	<path class="st58" d="M873,387c-1.7,1.7-3.3,3.3-5,5c-1.6-1.9,1.1-4.1-0.6-6.1c-0.4-0.5,0.8-1.7,1.9-0.9
		C870.4,385.7,871.3,387,873,387z"/>
	<path class="st58" d="M867,393c-1.4,2.2-2,5.1-5,6C860.2,394.1,864.2,394.1,867,393z"/>
	<path class="st93" d="M850,333.1c-2.3-4.2-7.4-5.2-10.1-9c-1.2-1.6-3.3-2.9-2.1-4.9c1.6-2.5,3.3-0.1,5,0.7c1.9,0.8,2.9,3.4,5.4,3.1
		c0.9,0.4,1.9,0.8,2.8,1.2c0.7,0.7,1,1.6,0.9,2.6c-0.4,1.1-0.5,2,0.8,2.9c1.3,0.9,2.6,2.2,0.9,4C852.2,334.2,851.1,334,850,333.1z"
		/>
	<path class="st94" d="M850,333.1c1.3,0,2.7,0,4,0c1.2,0.6,2,1.8,3.3,2.4c1.5,0.8,2.7,2.1,4.2,3.1c1.8,1.5,3,0.6,4-1.1
		c0.2-0.1,0.4-0.3,0.5-0.4c1.5,1.7,1.5,3.6,0,5.1c-1.9,1.9-3.9,0-5.8-0.5c-3.2-2.7-6.4-5.5-10.1-7.5C850,333.8,850,333.4,850,333.1z
		"/>
	<path class="st94" d="M851,327.1c0-1,0-1.9,0-2.9c6.1,3,12.8,5.3,15,12.9c0,0-0.1,0-0.1,0c-0.4,0.3-0.8,0.4-1.3,0.3
		c-2.1-0.7-3.1-2.6-4.5-4C858.1,329.8,853.3,330.2,851,327.1z"/>
	<path class="st95" d="M865,414.8c2.3-0.1,3.2,1.4,3.8,3.3C866.8,417.8,865.7,416.5,865,414.8z"/>
	<path class="st96" d="M891.2,448.8c-0.4,0-0.8,0.1-1.2,0.1c-1.3-1.1-2-2.6-1.9-4.3c0.6,0.5,1.2,0.9,1.7,1.4
		C890.2,446.9,890.7,447.9,891.2,448.8z"/>
	<path class="st97" d="M844,417.9c-0.8-1.2-3.2-2-1-4c1.8,0,3.5,0.1,5.1,1.1C846.7,416,845.3,416.9,844,417.9z"/>
	<path class="st98" d="M866.8,409c-1.1,1.7-2.7,2.2-4.7,2c-0.6-2-3.3-3.9-0.2-6.1c1.3-2.1,2.6-1.1,3.9,0c0.3,0.3,0.6,0.7,0.8,1.1
		C866.7,407,866.8,408,866.8,409z"/>
	<path class="st99" d="M866,405.9c-1.3-0.5-2.3-2.2-4-1c-0.3-0.7-0.6-1.3-1-2c0,0,0,0,0,0C865.4,401,866.1,401.5,866,405.9z"/>
	<path class="st100" d="M866,405.9c0.1-4-2.4-3.5-5-2.9c0.3-0.6,0.6-1.3,0.9-1.9c1-0.5,1.9-0.9,2.9-1.4c0.6,1.4,1.5,2.3,3.1,2.3
		c-0.2,0.7-0.5,1.3-0.7,2c-0.2,0.7-0.3,1.3-0.5,2C866.4,406,866.2,405.9,866,405.9z"/>
	<path class="st101" d="M905,411.8c0-0.6,0-1.1,0-1.7c1.6-1.8,3.5-2.6,5.4-0.6c2.2,2.2,4.2,4.7,3.4,8.2c-0.7,2.7-2.7,2.4-4.7,2
		c-0.5-0.9-1-1.8-1.5-2.7c1.3-0.9,5.1-0.4,2-3.6c-0.5-0.5-1.1-0.1-1.4,0.6c-0.2,0.6-0.4,1.2-0.8,1.7c-0.2,0.1-0.3,0.2-0.5,0.4
		C906.2,414.6,905.6,413.2,905,411.8z"/>
	<path class="st102" d="M906.9,413.9c0.1-1.5-0.1-3.4,2.3-2.7c2.4,0.7,3.1,3.1,3.2,5.2c0.2,2.1-1.7,1.4-2.9,1.2
		c-0.6-0.1-1.3-0.4-1.9-0.6c-0.3-0.3-0.5-0.7-0.8-1c0,0,0.2-0.2,0.2-0.2c0.2-0.9,1.7-0.9,1.5-2C908.5,413.7,907.5,414.3,906.9,413.9
		z"/>
	<path class="st103" d="M899.2,372.2c-1.1-1-2.1-1.9-3.2-2.9c1.7-0.8,2.2-2.2,2-4c-0.1-1.5-0.2-3,0.1-4.4c0.6-2.1,0.3-4.9,3.1-5.7
		c2.8-0.7,5.1,0.3,6.7,2.7c-0.4,1.5-0.4,1.5-2.6,1c-0.4-0.1-0.8-0.2-1.2-0.3c-1.6-0.2-2,0.9-2.5,2.1c-0.7,2.8,1.1,5.6,0.1,8.4
		c-0.3,0.7-1.1,1.2-0.5,2.1C900.6,371.5,899.9,371.8,899.2,372.2z"/>
	<path class="st104" d="M904.9,358c1-0.3,2.1,1.3,3-0.1c0.9,1,1.8,2.1,2.7,3.1c0.1,0.3,0.1,0.6,0.2,1c1.2,3.4,1.7,6.8-1.1,9.7
		c-2.9,2.9-5.8,0.3-8.6-0.5c-0.6-0.3-1.2-0.5-0.3-1.2c7.7-2.3,7.7-2.3,4.1-10.1C904.7,359.3,904.6,358.7,904.9,358z"/>
	<path class="st105" d="M851,327.1c3.7,1.3,8,1.8,10,6c-1,1.1-3.4,0.3-3.7,2.5c-0.1,0.2-0.2,0.3-0.4,0.5c-1.9-0.1-2.9-1.1-3-3
		c-0.7-1.5-1.4-2.7-3.2-3.3C848.2,328.9,850.7,328,851,327.1z"/>
	<path class="st105" d="M865,337c0.3,0,0.6,0,0.9,0.1c0.1,1.6,0,3.1-1.8,3.7c-1.6,0.5-2.5-0.5-3.2-1.7c0.2-0.1,0.3-0.3,0.5-0.4
		C862.7,338.3,863.7,337.5,865,337z"/>
	<path class="st105" d="M861,339.1c-1.9-0.2-2.7-1.9-4-3l0,0C860.1,334.7,861.2,336,861,339.1L861,339.1z"/>
	<path class="st106" d="M861,339c-0.7-1.8-1.6-3.4-4-3c-0.4-1-1.5-2.2-0.6-3.2c1.5-1.7,3,0.5,4.6,0.2c1.3,1.3,2.7,2.7,4,4
		C864.4,339.2,863,339.8,861,339z"/>
	<path class="st107" d="M906.9,413.9c3.6-3.5,2.5,1.3,3.8,1.8c-1.4,0.5-2.5-0.9-3.7,0.1C907,415.2,907,414.6,906.9,413.9z"/>
	<path class="st108" d="M904.9,358c0.8,1.8,2.8,2.8,3.2,5c0.3,1.8,2.9,3.1,0.9,5.3c-1.8,2-3.9,3-6.6,1.8c-0.4-0.2-1-0.1-1.5-0.1
		c1.2-3.3-2.4-6.7,0-10C902,358.9,902.7,356.8,904.9,358L904.9,358z"/>
	<path class="st104" d="M904.9,358c-1.7-0.1-2.6,1.5-4,2C901.5,357.7,902.2,355.9,904.9,358z"/>
</g>
<g id="mensaje" [@mensajeTrigger]="isEstadoMensaje" (click)="clickEvent('mensaje')">
	<path class="st109" d="M1015.2,345.5c-0.3,0.3-0.5,0.6-0.8,0.9c-1.1,2-2,4.1-4.5,4.7c-2,1-2.4-0.5-2.9-1.9
		c-0.5-11.9-0.1-23.8-0.2-35.7c0-8.6,0-17.1,0-25.7c0.1-7.2,0-14.5,0-21.7c0-2,0.2-3.9,0.9-5.8c1.4-3.9,2.6-7.8,1.7-12
		c0.2-2.4,1.5-3.9,3.8-3.8c2.3,0.2,1.7,2.5,2,4c1.1,5.3-0.9,10.6,0,15.9c-0.2,21,0.2,41.9-0.2,62.9
		C1014.8,333.3,1013.4,339.5,1015.2,345.5z"/>
	<path class="st110" d="M877.2,92.6c0.5,0.9,1.5,1.1,2.4,1.3c0.9,0.7,2.1,0.8,3.1,1.2c1.5,0.9,3.3,1.3,5,1.8
		c1.6,0.8,3.5,1.2,4.7,2.8l-0.1-0.1c1.3,1.1,3.2,1.4,4.3,2.9c2.3,1.9,5,3.5,7,5.8c2,1.5,3.7,3.2,5.1,5.1c2.4,2.6,4.9,5.1,7.3,7.8
		c2.5,3.2,6,2,9.3,2c1.9,0.9,4,0.9,5.8,1.9c1.6,0.4,2.8,1.7,4.3,2.2c1.8,1.8,5.1,2,5.4,5.2c-0.1,0.5-0.2,0.9-0.6,1.3
		c-1.2,0.6-2.4,0.3-3.2-0.5c-2.7-3-6.6-3.5-10-4.9c-1.6-0.6-3.3-1.4-4.8-1.1c-6.5,1.2-9.6-3.1-13.1-7.1c-6-6.8-12.5-12.8-20.7-17.3
		c-5.2-2.9-10.4-5.4-15.8-7.6c-1.2-0.5-2.7-0.8-2.5-2.6c0.9-1,2.1-0.9,3.3-0.8C874.7,92.2,875.9,92.7,877.2,92.6z"/>
	<path class="st111" d="M833.5,233.4c-1.5-1.1-3.3-1.8-4.2-3.7c-0.3-0.4-0.3-0.9-0.1-1.3c2.2-1.1,3.7,0.6,5.4,1.4
		c5.9,3.2,10.9,7.9,16.8,11c5.7,4,11.2,8.1,17,11.8c0.5,0.4,1.1,0.8,1.6,1.3c3.3,2.2,6.5,4.6,9.8,7c1.3,1.5,3.8,2,3.7,4.6
		c-0.3,0.3-0.7,0.6-1.2,0.7c-1.8,0.1-2.9-1.3-4.2-2.2c-3-1.8-5.6-4.4-8.9-5.8c-0.6-0.4-1.1-0.8-1.6-1.3c-4.1-2.9-8-6.1-12.5-8.4
		c-0.2,0-0.4,0-0.6-0.1c-4.3-3.9-10-5.9-14.2-9.8l0.1,0C837.9,237.1,835.5,235.6,833.5,233.4z"/>
	<path class="st112" d="M966.3,162.4c3,1.3,4.2,5.2,4.4,6.9c0.9,8.1,5,13.8,10.8,19c6.7,5.9,11.8,13.2,15.8,21.2
		c0.5,0.9,0.6,1.9,0.3,2.9c-2.1,1.2-2.5-0.8-3.1-1.8c-1.3-2.1-2.5-4.1-3.7-6.2c-0.9-2.1-2.7-3.6-4-5.4c-1.2-2-2.9-3.5-4.4-5.3
		c-7.9-5.6-12.9-13-14.5-22.6c-0.3-1.9-1.8-3.6-2.4-5.4C965.1,164.4,964,162.8,966.3,162.4z"/>
	<path class="st113" d="M817.3,220.6c-2.1-3.5-1.6-7.3-1.3-11.1c0.2-1.9,0.2-3.9,0.4-5.8c-0.8-9.4-0.8-18.7,0-28.1
		c0-2.6,0-5.2,0-7.8c-1-4.4-0.2-8.9-0.5-13.4c-0.1-1.6,0.7-3.3-0.7-4.7c-1.2-3.3-3.4-6.2-3.8-9.9c-0.2-0.3-0.3-0.6-0.3-0.8
		c0.1-0.3,0.1-0.4,0.2-0.4c0.5-0.2,0.9-0.2,1.4,0c1.3,0.9,2,2.2,2.7,3.6c0.8,1.5,1.2,3,1.7,4.6c1.7,2.7,1.7,5.6,1.7,8.6
		c0,18.2,0,36.4,0,54.6c0,2.3-0.1,4.6,0.3,6.9c0.4,1.2,0.4,2.4-0.2,3.6C818.4,220.8,817.9,221,817.3,220.6z"/>
	<path class="st114" d="M1015.2,345.5c-2.3-3-1.6-6.8-1.5-9.9c0.5-12,0.5-24,0.5-36c0-12,0-23.9,0-35.9c0.7-0.5,1.2-0.3,1.3,0.6
		c0.1,23.2,0,46.5,0,69.7c0,1.1,0.3,2.3-0.5,3.4C1015.2,340.1,1015.2,342.8,1015.2,345.5z"/>
	<path class="st115" d="M850.1,61.7c-1,3.3-1.7,6.7-6.2,7.1c-4.7,0.4-8.5-0.8-10.4-5.6c-0.7-1.8-2.7-3-2.2-5.3
		c-1.2-1.9,0.6-4.6-1.7-6.1c0.9-2.2,1.3-4.7,3.6-6.1c0.7,0.4,1,1.1,1.1,1.9c-0.7,4.3-0.9,8.5,1.7,12.3c1.8,3,3.5,6,7.7,5.2
		c1.1-0.4,2-1,2.6-2c0.2-0.4,0.5-0.7,0.7-1.1c0.5-0.7,1-1.4,1.9-1.6C849.7,60.5,849.9,61.1,850.1,61.7z"/>
	<path class="st116" d="M1015.2,337.3c0-24.6-0.1-49.3-0.1-73.9c-0.7-1.9-1.1-3.7-0.2-5.7c0.4-0.4,0.9-0.5,1.5-0.2
		c0,24.9-0.1,49.9-0.2,74.8C1016.1,334,1017.2,336,1015.2,337.3z"/>
	<path class="st117" d="M921.2,296.6c-3.6-1.8-6.5-4.9-11.3-4.2c-4,0.6-9.6-5.1-10.6-9.7c-0.4-1,0.2-1.3,0.9-1.5
		c0.7,0,1.4,0.2,1.8,0.7c3.2,5.7,8.1,7.7,14.3,7.1c2.4,2.5,6.6,3.5,7,7.9C922.5,297,921.8,297,921.2,296.6z"/>
	<path class="st118" d="M999.2,215.6c3.3,3.7,4.2,8.5,5.3,13c0.9,3.4,2.6,5.5,5.4,6.9c3.9,1.9,6.3,4.8,7,9.1
		c-0.4,0.3-0.8,0.5-1.3,0.4c-3.9-3.7-8.1-7.1-12.2-10.6c-2.4-5.6-4.1-11.4-5-17.5C998.5,216.4,998.8,215.9,999.2,215.6z"/>
	<path class="st119" d="M966.3,162.4c-1.6,1.4,0.2,2.3,0.6,3.4c0.8,2.2,2.1,4.2,2.5,6.5c1.5,8.3,5.7,14.6,12.7,19.2
		c0.6,0.4,0.8,1.4,1.2,2.1c-0.2,0.1-0.4,0.3-0.6,0.4c-6.9-1.2-10.4-6.4-13.2-12c-2.7-5.4-2.9-11.9-6.4-17.1
		c-0.3-0.4-0.4-0.9-0.6-1.4c-0.1-0.8,0.1-1.5,0.6-2.1c1-0.6,1.9-0.3,2.8,0.1C966.2,161.8,966.2,162.1,966.3,162.4z"/>
	<path class="st120" d="M940.3,132.5c-1.7-1.6-3.4-3.1-5.1-4.7c-0.4-0.2-0.5-0.4-0.3-0.6c0.1-0.2,0.3-0.3,0.4-0.3
		c9.8,5.1,17.6,12.1,23.1,21.7c-0.3,0.3-0.8,0.5-1.3,0.6C950.9,144.3,945.7,138.3,940.3,132.5z"/>
	<path class="st121" d="M951.1,317.5c-4.5-1.9-7.3-6-11.8-8.2c-4.4-2.2-8.1-5.7-12.1-8.7c-0.2-0.3-0.3-0.6-0.3-0.9
		c0.1-0.3,0.1-0.4,0.2-0.4c2.4-1.2,4,0.5,5.8,1.7c6.2,4.3,12.1,9,18.6,12.9c1.1,1,2.4,2,1.8,3.8C952.5,318,951.8,318,951.1,317.5z"
		/>
	<path class="st122" d="M874.2,92.8c-1.3,0-2.6,0-3.9,0c-5.4,0.8-10.8,1.8-15.8,4.4c-2.2,0.8-4.3,1.4-6.2-0.5
		c2.9-3.2,6.6-5,10.8-5.9c4-0.7,8-1,12,0.3C872,91.8,874.3,90.3,874.2,92.8z"/>
	<path class="st123" d="M991.1,345.5c-5.6-4-11.3-7.9-16.9-11.9c-0.5-0.7-1.6-1.2-1-2.4c0.6-0.4,1.3-0.5,2-0.4
		c5,2.1,8.7,6.1,13.2,8.9c1.6,1.4,3.8,2.1,4.4,4.4c0,0.5-0.1,1-0.4,1.4C991.9,345.9,991.5,345.9,991.1,345.5z"/>
	<path class="st124" d="M822.3,122.7c1.2-2.8,2-5.8,6.5-4c3.3,1.3,3-2.2,3.5-4.2c1.1-3.9,1.6-8,4.9-10.8c0.4-0.3,0.8-0.4,1.2-0.2
		c0.5,0.6,0.6,1.2,0.5,1.9c-0.1,0.4-0.3,0.9-0.5,1.3c-1.5,2.2-2.7,4.7-3.4,7.3c-0.7,2.2-1,4.5-1.8,6.7c-0.9,1.9-2.5,2.2-4.2,1.5
		c-2.1-0.9-3.8-0.2-5.5,0.9C823.1,123.1,822.7,123,822.3,122.7z"/>
	<path class="st125" d="M844.4,97.8c0.3-0.1,0.6-0.1,0.9-0.2c3,0.1,1.2,2.9,2.3,4c0.4,1.5,0.9,3.2-1.3,3.4c-4.9,0.3-5.6,3.7-6,7.5
		c-1,2.2-2.6,2.5-4.6,1.6c-1.6-3.6,1.8-5.6,2.7-8.4l0,0C839.7,102.6,842.6,100.6,844.4,97.8z"/>
	<path class="st126" d="M846.4,101.7c-2-0.9,1-3.2-1.1-4.1c0.7-1.2,2-0.9,3.1-1c2-0.1,4.2,0.8,6-0.8c3.1-0.8,6.1-1.2,8.9,0.7
		c0.6,1.6-0.7,1.9-1.7,2.3c-4.2,0.4-8.5,0.1-12,3.2C848.6,102.8,847.4,102.5,846.4,101.7z"/>
	<path class="st127" d="M882.4,265.8c0.3-0.1,0.6-0.1,0.9-0.2c6.6,0.9,10.1,6.1,14.4,10.2c1.3,1.2,2.7,2.5,1.9,4.7
		c-2.1,1.1-2.3-1.2-3.4-1.9c-2-1.9-4-3.9-6-5.8C887.5,270.5,883.3,270,882.4,265.8z"/>
	<path class="st128" d="M967.2,328.6c-5.2-1.8-9.3-5.2-13-9c0.5-0.6,1.2-0.8,2-0.8c5,1.3,8.8,4.2,11.6,8.5
		C967.7,327.7,967.6,328.2,967.2,328.6z"/>
	<path class="st129" d="M833.6,47.8c-0.1-0.7-0.2-1.4-0.4-2.1c0.8-0.6,1.5-1.1,2.3-1.7c2-1.3,3.8-0.5,5.7,0.3c2.6,2,6.2,3.2,6.6,7.2
		c-2.4,1.4-3.5-0.8-4.8-1.9c-2.3-1.9-4.6-3-7.5-1.3C834.8,48.4,834.1,48.4,833.6,47.8z"/>
	<path class="st130" d="M816.4,175.6c0,9.4,0,18.7,0,28.1c-2.2-5.7-0.7-11.7-0.9-17.5C815.4,182.7,814.2,178.9,816.4,175.6z"/>
	<path class="st131" d="M847.4,51.8c-0.5-3.7-3.9-4.7-6-7c0.3-0.4,0.7-0.4,1.1-0.1c4.5,1.8,7,5.3,7.9,10c0.7,2,0.7,4.1,0,6.1
		c0,0.3-0.1,0.6-0.3,0.9c-0.3-0.4-0.6-0.7-0.9-1.1C847.8,57.9,847,54.9,847.4,51.8z"/>
	<path class="st132" d="M818.5,220.6c-0.1-0.9-0.2-1.9-0.2-2.8c0.1-0.1,0.3-0.3,0.4-0.4c0.4,0,0.8,0.1,1.1,0.3
		c0.6,0.6,1,1.3,1.6,1.8c1.1,1.2,2.3,2.3,3.5,3.5c1,1.1,2,2.1,3.2,2.8c0.8,0.7,1.7,1.3,1.4,2.6c0,0.4-0.1,0.9-0.1,1.3
		c-1-0.4-2-0.9-2.9-1.3C823.4,226.2,819.5,224.8,818.5,220.6z"/>
	<path class="st133" d="M1016,244.6c0.3,0,0.7,0,1-0.1c3.2,4.5,1.1,8.7-0.7,13c-0.3,0-0.7,0-1,0
		C1014.5,253.2,1014.8,248.9,1016,244.6z"/>
	<path class="st134" d="M925,123.8c-3.5,0.4-7.3,2.1-9.8-2.1c-0.5-1-1.7-1.8-1-3.2c0.7-0.2,1.5-0.4,2.2-0.6c0.4,6.2,5.1,4.4,8.8,4.8
		c0.8,0.1,1.6,0,1.9,1C926.5,124.3,925.8,124.2,925,123.8z"/>
	<path class="st135" d="M855.1,248.5c5.6,0.8,9,5.1,13.2,8.1c-0.7,0.6-1.4,0.5-2.2,0C862.2,254.2,858.4,251.7,855.1,248.5z"/>
	<path class="st136" d="M892.3,99.7c-2,0.3-3.2-1.7-5-2c-1.6-1-3.5-1.1-5-2.1c-1.2-0.1-2.2-0.5-2.7-1.7
		C884.7,94,889.5,94.7,892.3,99.7z"/>
	<path class="st137" d="M840.3,238.6c4.9,3,10.7,4.8,14.2,9.8C849.4,245.7,843.9,243.6,840.3,238.6z"/>
	<path class="st138" d="M882.4,265.8c1.8,3.3,5.7,4.1,7.9,6.9c-4.9-1.3-8.7-4.2-12-8.1c-0.3-0.3-0.3-0.5-0.2-0.8
		c0.1-0.2,0.2-0.4,0.3-0.4C879.7,264.3,881,265,882.4,265.8z"/>
	<path class="st139" d="M844.4,97.8c0.4,4.4-3.8,5.5-6,7.9c0-0.7,0-1.3,0.1-2c-0.1-1.5,1-2.3,2-3.1
		C841.3,99.1,842.2,97.6,844.4,97.8z"/>
	<path class="st140" d="M878.3,263.5c0,0.4,0,0.8-0.1,1.2c-2.7-1.7-5.3-3.3-8-5c-0.5-0.7-1.6-1.1-0.9-2.2
		C872.9,258.6,875.5,261.3,878.3,263.5z"/>
	<path class="st141" d="M818.5,220.6c2.6,2.6,5.3,5.3,7.9,7.9c-4.2-1.2-7.5-3.6-9.1-7.8C817.7,220.6,818.1,220.6,818.5,220.6z"/>
	<path class="st142" d="M815.2,149.7c2.6,4,0.6,8.5,1.2,12.7c0.2,1.8,0,3.6,0,5.4c-2.1-3.9-0.5-8.2-0.9-12.3
		C815.2,153.6,815.3,151.6,815.2,149.7z"/>
	<path class="st143" d="M966.1,161.5c-0.9,0-1.8,0-2.7-0.1c-0.7-0.3-1-0.9-1.2-1.6c-0.4-1.7-1.5-3.2-1.6-5.1
		c0.1-0.5,0.3-0.8,0.6-1.2C965.3,154.7,964.3,158.9,966.1,161.5z"/>
	<path class="st144" d="M908.2,113.7c-1.7-1.7-3.3-3.3-5-5c-0.5-0.4-0.4-0.8,0.1-1.2c3.1,1,5.2,3,6.1,6.1
		C909.1,114.2,908.7,114.2,908.2,113.7z"/>
	<path class="st145" d="M967.2,328.6c0-0.4,0-0.8,0.1-1.2c3.3-0.8,5.2,0.7,6,3.9c0.3,0.8,0.6,1.5,0.9,2.3
		C971.3,332.7,969.3,330.5,967.2,328.6z"/>
	<path class="st146" d="M992.2,345.4c0-0.3,0-0.7,0-1c4-1,5.6,2.5,8.1,4.4c0.2,0.4,0.3,0.9,0.3,1.4c-0.2,0.7-0.7,1.2-1.5,1.3
		c-0.6-0.4-1.3-0.8-1.9-1.2C995.7,348.4,993.1,347.8,992.2,345.4z"/>
	<path class="st147" d="M903.3,107.6c0,0.4-0.1,0.8-0.1,1.2c-2.4-2-4.7-4-7.1-6c0.4-0.5,0.8-0.6,1.2-0.1
		C900.1,103.4,902.2,105,903.3,107.6z"/>
	<path class="st148" d="M999.1,351.5c0.3-0.3,0.6-0.6,0.9-0.9c1.9-0.3,3.5,0.7,5.2,1.2c0.8,0.4,1.6,0.8,2.1,1.7
		C1003.9,355.5,1001.3,354.2,999.1,351.5z"/>
	<path class="st149" d="M908.2,113.7c0.4,0,0.8,0,1.2-0.1c2,1.3,3.5,2.9,4.9,4.8c0.3,1.1,0.6,2.1,1,3.2
		C912.9,119.1,910.6,116.4,908.2,113.7z"/>
	<path class="st150" d="M961.2,153.6c0,0.4,0,0.7,0,1.1c-0.3,0.3-0.8,0.5-1.2,0.4c-0.7-0.2-1.2-0.7-1.6-1.3
		c-0.6-1.7-1.9-3.2-1.3-5.1c0.4,0,0.8-0.1,1.3-0.1C961.2,149.2,961.2,151.4,961.2,153.6z"/>
	<path class="st151" d="M871.3,91.4c-4-1-8.2-0.3-12.2-0.6c4.1-1.6,8.2-1.7,12.2,0C871.3,90.9,871.3,91.2,871.3,91.4z"/>
	<path class="st152" d="M927.4,299.4c0,0.4-0.1,0.9-0.1,1.3c-2.7-0.4-3.8-3-6.1-4.1c0.7,0,1.5-0.1,2.2-0.1
		C926.1,295.5,927.1,296.9,927.4,299.4z"/>
	<path class="st153" d="M822.3,122.7c0.3,0,0.6,0,1,0c0.3,0.3,0.5,0.7,0.4,1.1c-0.4,1-1.2,1.8-1.9,2.6c-1.3,1.1-2.3,2.7-4.2,2.9
		c-0.5,0-0.8-0.2-1.2-0.5C817.9,126.2,819.9,124.3,822.3,122.7z"/>
	<path class="st154" d="M833.5,233.4c2.7,1.2,5,2.9,6.8,5.2C837.2,238.1,834.8,236.4,833.5,233.4z"/>
	<path class="st155" d="M992.2,345.4c1.8,1.5,4.1,2.4,4.9,4.9c-2.6-0.8-4.5-2.5-6.1-4.8C991.4,345.5,991.8,345.5,992.2,345.4z"/>
	<path class="st156" d="M842.4,44.7c-0.4,0-0.7,0-1.1,0.1c-1.9-0.4-3.8-1.2-5.9-0.8C838.1,41.9,840.4,42,842.4,44.7z"/>
	<path class="st157" d="M871.3,91.4c0-0.2,0-0.4,0-0.6c2.2-0.1,4.3,0,5.9,1.9c-1,0.4-1.9,1.5-3,0.1
		C873.6,91.6,872.2,91.9,871.3,91.4z"/>
	<path class="st158" d="M816.4,128.7c0.4,0,0.7,0,1.1,0c0.3,0.4,0.4,0.9,0.4,1.4c-0.8,2.1-2.3,3.2-4.6,2.7c-0.4-0.5-0.4-0.9,0.1-1.3
		C814,130.2,815.2,129.4,816.4,128.7z"/>
	<path class="st159" d="M1007.3,353.5c-0.7-0.2-1.4-0.4-2.1-0.5c-0.4-1.4,0.9-1.7,1.6-2.4c0.4-0.2,0.8-0.2,1.2,0
		c0.7,1,1.4,0.1,2.1,0.1c0.4,0.2,0.5,0.4,0.4,0.6c-0.1,0.2-0.3,0.3-0.4,0.3C1009.7,352.9,1008.8,353.6,1007.3,353.5z"/>
	<path class="st160" d="M999.2,215.6c0,0.4,0,0.8-0.1,1.2c-2.4-0.6-2.6-2.2-1.9-4.2c0-1,0-2,0-3.1
		C999.1,211.1,999.3,213.3,999.2,215.6z"/>
	<path class="st161" d="M813.4,131.5c0.1,0.4,0.2,0.8,0.3,1.3c0.2,0.4,0.3,0.9,0.3,1.4c-0.4,1.6-0.1,3.4-1.6,4.5c-0.3,0-0.6,0-0.9,0
		c-0.8-1.7-0.7-3.4,0-5.1C812,132.9,812.7,132.2,813.4,131.5z"/>
	<path class="st162" d="M1010.2,351.5c0-0.3,0-0.6,0-0.9c1.4-1.4,2.8-2.8,4.3-4.2C1014.1,349.1,1013.3,351.3,1010.2,351.5z"/>
	<path class="st163" d="M897.4,102.7c-0.4,0-0.8,0.1-1.2,0.1c-1.3-1-3.4-1-3.9-3.1C894.8,99.3,896.3,100.7,897.4,102.7z"/>
	<path class="st164" d="M829.6,51.8c3.2,1.3,1.2,4.1,1.7,6.1C829.4,56.3,831.1,53.6,829.6,51.8z"/>
	<path class="st165" d="M956.2,319.5c-0.7,0-1.4,0-2.1,0c-1-0.7-2-1.3-3-2c0.7,0,1.5-0.1,2.2-0.1
		C955.4,316.6,956.3,317.2,956.2,319.5z"/>
	<path class="st166" d="M869.4,257.4c0.3,0.7,0.6,1.5,0.9,2.2c-2.1-0.1-3.1-1.7-4.2-3.1c0.7,0,1.4,0,2.2,0
		C868.7,256.9,869,257.1,869.4,257.4z"/>
	<path class="st167" d="M896.2,278.5c1.1,0.7,2.1,1.3,3.2,2c0.8,0,1.4,0.1,0.8,1.1c-0.3,0.4-0.6,0.7-0.9,1.1
		C897.8,281.6,896.3,280.6,896.2,278.5z"/>
	<path class="st168" d="M935.3,126.9c0,0.3,0,0.6,0,0.9c-1.5-0.4-2.9-0.9-4-2.1c-0.4-0.2-0.5-0.4-0.4-0.6c0.1-0.2,0.2-0.3,0.4-0.3
		C933.3,124.1,933.8,126.5,935.3,126.9z"/>
	<path class="st169" d="M931.3,124.9c0,0.3,0,0.6,0,0.8c-2.1-0.6-4.1-1.3-6.2-1.9c0.7,0,1.4-0.1,2.2-0.1
		C928.7,123.6,930.1,123.8,931.3,124.9z"/>
	<path class="st170" d="M811.4,133.6c0,1.7,0,3.4,0,5.1c0,0.4,0,0.7,0,1.1C809.9,137.7,809.9,135.6,811.4,133.6z"/>
	<path class="st171" d="M850.4,60.8c0-2,0-4.1,0-6.1C851.9,56.7,851.9,58.8,850.4,60.8z"/>
	<path class="st172" d="M840.4,100.6c-0.7,1-1.3,2.1-2,3.1c-0.4,0-0.8,0-1.1-0.1C837.3,101.7,838.4,100.7,840.4,100.6z"/>
	<path class="st173" d="M1016,244.6c0.9,4.4-0.8,8.6-0.7,12.9c-0.1,1.9-0.2,3.9-0.2,5.8c-0.3,0.1-0.5,0.2-0.8,0.3
		c-1.5-6,1.3-12.2-1-18.5c-1.5,0.3-3,0.9-3,3.2c-0.2,0.1-0.3,0.3-0.5,0.4c-0.4,0-0.9-0.1-1.2-0.3c-1.1-0.9-1.9-1.8-1.6-3.3
		c1.5-4.1-2.6-6.4-3.3-9.8c0.1-0.5,0.3-0.8,0.7-1.1C1008.9,237,1013.1,240.1,1016,244.6z"/>
	<path class="st174" d="M1009.2,259.5c-1.7,9.3-0.2,18.7-1,28c-0.6,0.3-1.1,0.2-1.5-0.3c-1.2-5.8-1.9-11.6-1.9-17.5
		c0-5.1-0.1-10.2-0.4-15.3c0.1-1.5,0.8-2.5,2.2-3.1c1.3,0.4,2.8-2,4.1,0.1C1010.1,254.1,1009.7,256.8,1009.2,259.5z"/>
	<path class="st175" d="M1008,350.5c-0.2,0-0.4,0.1-0.7,0.2c-0.2-0.2-0.5-0.4-0.7-0.6c-1-1.6-1-3.5-1-5.3c0.3-8.4,0-16.9,0.1-25.4
		c0-1.8-0.2-3.7,0.8-5.4c0.4-0.6,0.9-0.8,1.6-0.6C1008.4,325.8,1008.9,338.2,1008,350.5z"/>
	<path class="st176" d="M1008.2,313.5c-0.3,0-0.6,0-1,0c-1.1-0.9-1.3-2.3-1.4-3.6c-0.1-6.3-0.1-12.5,0-18.8c0-1.3,0.2-2.6,1.3-3.6
		c0.3,0,0.7,0,1,0C1008.2,296.1,1008.2,304.8,1008.2,313.5z"/>
	<path class="st173" d="M1009.2,259.5c-0.7-3,1-5.8,0.7-8.8c-0.5-0.8-0.3-1.5,0.2-2.1c0,0,0.1-0.1,0.1-0.1
		C1012.4,252.4,1010.2,255.9,1009.2,259.5z"/>
	<path class="st177" d="M863.3,96.8c-3-0.3-6-0.7-9-1c5.2-1.7,10-5.5,16-3c4.7,2,9.8,3.3,14,6c4.8,3,10,5.5,14.5,9.4
		c6,5.1,10.8,11,16.1,16.6c1.1,1.2,2.6,2.1,4.5,1.5c2.9-0.9,5.4,0.2,7.9,1.5c-0.1,0.2-0.3,0.4-0.4,0.5c-2.4,2-5.3,0.8-7.9,1.4
		c-1.6,1.4-2.3,3.9-5.1,3.7c-4.1-1.6-6.2-5.3-8.8-8.5c-1.4-1.7-3-3.1-4.5-4.6c-9.1-8.3-18.3-16.6-30.4-20.4
		c-1.5-0.6-3.1-0.8-4.6-1.3C864.6,98.2,863.9,97.5,863.3,96.8z"/>
	<path class="st177" d="M927.3,127.7c5.1,0.3,8.6,4,12.9,6c7.9,4.9,13.4,12.1,18.3,19.8c3,5.8,3,5.8-2.1,11.4c-2.1-0.5-3.1-2.4-4-4
		c-2.9-4.9-6.6-9.3-10.4-13.5c-0.7-0.9-1.4-1.7-2.3-2.4c-4.1-4.6-9.2-8-14.1-11.8C925.3,131.1,924.8,129,927.3,127.7L927.3,127.7z"
		/>
	<path class="st178" d="M958.2,153.7c-3.6-4.2-7.5-8.3-10.7-12.8c-2.1-2.9-5.1-4.6-7.3-7.2c0-0.4,0.1-0.8,0.1-1.2
		c6.8,4.1,11.4,10.6,16.8,16.2c0.6,1.7,2.2,3,2,5C958.8,154.2,958.5,154.2,958.2,153.7z"/>
	<path class="st122" d="M882.3,95.6c1.9,0.1,3.7,0.6,5,2.1C885.3,97.8,883.6,97.3,882.3,95.6z"/>
	<path class="st179" d="M824.3,223.7c-1.6-1.1-3-2.4-3.9-4.2c-1.3-2.5,1-3.1,2.4-3.9c3.5-2,6.9-4.2,10.4-6.3c0.5-0.1,1,0,1.4,0.2
		c0.5,0.6,0.6,1.3,0.5,2c-1,1.9,0.2,2.5,1.7,3.6c1.7,1.2,1.4-0.6,2.1-0.9c0.6-0.2,1.2-0.3,1.9-0.1c3.9,2.6,7.3,5.4,7.9,10.4
		c0.7,5.6,5,9,7.9,13.3c1.2,1.7,4.8,2.8,2.8,5.4c-2.2,3-4.7,0.3-6.9-0.9c-0.4-0.2-0.8-0.5-1.3-0.8c-7.4-2.7-12.6-8.9-19.6-12.2
		c-0.7-0.3-1.5-0.7-2.2-1c-0.7-0.6-1.3-1.3-2-1.9C826.6,225.4,825.7,224.2,824.3,223.7z"/>
	<path class="st180" d="M851.3,241.6c1.7,0.6,3.4,1.3,5.1,1.8c0.9,0.3,2.1,0.4,2.6-0.8c0.5-1.2-0.6-1.5-1.3-2.2
		c-4.6-4.5-9.3-9-10.3-15.9c1.7-0.8,2.8,0.4,3.9,1.3c3.1,2.3,5.6,5.5,9.6,6.4c0.1,0.4,0.2,0.9,0.1,1.3c-0.4,2.7,2.3,2.6,3.4,3.8
		c0.4,0.5,0.9,1.2,0.8,1.8c-1,4.6,1.3,7.6,4.8,10c1.1,0.8,2.2,1.7,1.5,3.3c-0.7,1.4-1.7,2-3.2,1.1
		C862.2,250.2,856.5,246.2,851.3,241.6z"/>
	<path class="st181" d="M868.3,253.6c1.6-0.3,4-1.2,1.8-2.7c-4.5-3.1-8.1-6-5.9-12.1c0.1-0.3-0.5-0.8-0.8-1.1c-0.9-1.4-4.3-0.9-3-4
		l0,0c1.8-1.4-1.1-2.8,0-4.2c0.4-0.3,0.8-0.4,1.3-0.5c2,0,3.2,1.5,3.4,3c0.4,3.6,3,5.3,5.3,7.3c1.2,1.1,2.7,1.8,3.7,3.2
		c0.3,0.4,0.5,0.9,0.6,1.3c0.7,4.9,5.8,7.6,6.5,12.6c0.2,1.4,1.7,3,0.2,4.1c-1.6,1.3-3-0.4-4.3-1.3c-2.5-1.6-4.7-3.5-7.5-4.5
		C869,254.2,868.7,253.9,868.3,253.6z"/>
	<path class="st182" d="M900.1,281.6c-0.3-0.4-0.5-0.7-0.8-1.1c-3.6-6.8-9.9-10.9-16.1-14.9c-0.6-2.1-3.3-2-4-4c3.1-2,5.2,1.9,8,1.3
		c3.1,2.7,5.1,6.4,8.4,8.8c2.8,2.4,5,5.2,7.1,8.1c0.8,1,1.6,2.1,2.4,3.1c1.2,1.3,2.2,2.7,3.6,3.7c1.7,0.9,3.5-0.4,5.3,0.3
		c1.1,0.3,2.3,0.6,3.2,1.4c0.2,0.4,0.3,0.9,0.1,1.4c-0.4,0.3-0.8,0.3-1.3,0C908.3,292,904,287.1,900.1,281.6z"/>
	<path class="st183" d="M887.3,263.5c-3.3,1.9-5-2.8-8-2c-3.3-2.3-6.6-4.7-9.9-7c4.7-0.3,7,4.5,11.2,5.3c0.2-6.6-6.6-10-7.3-16.3
		c4.8,0.2,5.5,4.7,7.9,7.5C884.1,254.8,887.3,258.4,887.3,263.5z"/>
	<path class="st184" d="M997.2,212.5c0.6,1.4,1.3,2.8,1.9,4.2c1.7,5.9,3.5,11.7,5.2,17.6c0,0-0.2,0.4-0.2,0.4
		c-2.6,0.2-3.6-1.6-3.9-3.6c-1.5-8.9-5-17-10-24.5c-0.6-0.8-0.8-1.8-0.4-2.8c0.4-0.5,0.8-0.5,1.3-0.2
		C994,206,994.8,209.8,997.2,212.5z"/>
	<path class="st185" d="M991.2,203.6c-0.4,0-0.7,0-1.1,0c-2.6-0.2-3.1-2.2-3.5-4.2c0.1-0.5,0.4-0.8,0.8-1
		C989,199.9,990.8,201.3,991.2,203.6z"/>
	<path class="st185" d="M983.4,193.5c1.8,1.2,3.3,2.8,4.1,4.9c0,0-0.4,0.3-0.4,0.3c-3.1-0.3-5.3-1.3-4-5L983.4,193.5z"/>
	<path class="st132" d="M818.2,217.7c-1.2-1.7-0.7-3.5-0.7-5.3c-0.1-18.1-0.1-36.2-0.2-54.3c0-4.3,0.4-8.6-1.8-12.5
		c2.5-0.8,3.3,0.8,4,2.7c2,15.3,1.5,30.7,1.1,46c-0.2,6.1-0.9,12.1,0,18.2c-0.2,1.8-0.8,3.5-2,4.9
		C818.5,217.5,818.3,217.6,818.2,217.7z"/>
	<path class="st186" d="M818.4,147.7c-0.6-1.2-1.5-2-2.9-2.1c-0.4-1.3-0.8-2.5-1.3-3.8c-0.1-2.1-2.6-4-0.5-6.3
		c1.5-0.2,2.3,0.8,3.2,1.7c2.6,5.2,7.1,9.4,8.2,15.4c-0.2,1.3-0.6,2.4-1.7,3.3c-1.8,0.4-2.8-0.7-3.7-2
		C819,151.9,819.4,149.6,818.4,147.7z"/>
	<path class="st187" d="M814.4,135.7c-2.2,2,0.8,4.1-0.1,6.1c-0.7-1-1.3-2.1-2-3.1c0.3-1.6-0.7-3.6,1.3-4.9
		C814.3,134.2,814.8,134.7,814.4,135.7z"/>
	<path class="st188" d="M833.6,47.8c0.6,0,1.1-0.1,1.7-0.1c1.6-0.9,2.9-0.1,3.9,1c1.6,1.7,3.4,3,5.4,4.2c3.1,1.9,3.4,5,2.5,8.2
		c-5.4,4-6.9,3.9-11.8-0.6C832,56.7,831.6,52.4,833.6,47.8z"/>
	<path class="st189" d="M846.3,61.8c0.4-4.2,0-7.8-5.2-9.2c-2.4-0.7-2.6-4.7-5.9-4.9c2.4-3.2,4.9-1.6,7.3,0c1.7,1.2,3.2,2.8,4.8,4.2
		c1.8,2.7,2,5.7,1.9,8.9c-0.6,0.7-1.3,1.4-1.9,2.1C846.8,62.6,846.5,62.3,846.3,61.8z"/>
	<path class="st190" d="M846.3,61.8c0.3,0.3,0.7,0.7,1,1c0,0,0,0,0,0c-1,1-2,2-3,2.9c-5.4,2.4-7.2-1.4-9-5.2
		c0.1-0.3,0.2-0.6,0.3-0.9C838.4,64.2,842.5,62.3,846.3,61.8z"/>
	<path class="st189" d="M844.3,65.7c0.1-1.9,1.1-2.8,3-2.9C847.2,64.6,846.3,65.7,844.3,65.7z"/>
	<path class="st191" d="M916.2,289.6c0.4-0.1,0.8-0.1,1.2-0.2c0.4-0.2,0.8-0.3,1.2-0.1c1.8,1.1,3.1,3,5.3,3.5
		c3.9,1.5,6.5,5,10.1,6.9c4.8,2.9,9.1,6.5,13.8,9.7c1.9,1.3,4.7,2.1,4.5,5.3c-0.4,0.3-0.9,0.3-1.3,0c-6.7-3.3-12.3-8.3-18.5-12.4
		c-1.7-1.1-3.1-2.5-5.1-2.9c-0.9-1.5-2.2-2.6-4-2.9C921,294.1,918.6,291.9,916.2,289.6z"/>
	<path class="st192" d="M983.1,193.7c-0.2,2.9,2.4,3.5,4,5c0.4,2,1.8,3.4,3.1,4.9c4.2,7.3,8.5,14.5,10.5,22.8
		c0.7,2.9-0.2,6.6,3.5,8.2c0.9,3.3,4.9,5.1,4.4,9.3c-0.1,1-0.3,1.4-1.2,1.5c-2,1.5-3.3-0.3-4.7-1.1c-6.2-3.8-6.8-10.7-9.1-16.6
		c-2.9-7.3-6.8-14-11.2-20.4c-2.4-4-5.7-7.3-8.8-10.8c-9.1-7.3-11.1-18.8-16.6-28.3c-0.6-1.1-1.2-2.4-0.5-3.7c1.4-1-0.9-4.3,2.8-4.1
		c0.7,0,1.4-2.1,0.3-3.3c-1-1-1.1-2.3-1.1-3.6c0.3,0,0.6,0,0.9,0c0.3,0.4,0.7,0.7,1,1.1c1.4,1.4,1.8,3.4,3,4.9
		c0.1,0.6,0.1,1.2,0.2,1.7c0,0.7-0.1,1.5-0.1,2.2c-1.1,0.5-2.6,0.3-3,2c1.8,0.5,1.9-1.6,3-2c3.1,2.1,4.1,5.7,4.7,8.9
		c1.4,7.1,4.4,13,9.6,18C979.2,192.2,981.3,192.5,983.1,193.7z"/>
	<path class="st193" d="M951,314.6c0.4-0.1,0.9-0.1,1.3-0.2c1.1-1,2.2-0.4,3.1,0.2c6.1,4.1,12.3,8.1,18.1,12.6
		c0.5,0.5,0.9,1.1,1.2,1.7c0.5,0.8,1,1.6,0.5,2.5c-0.6-0.1-1.3-0.1-1.9-0.2c-1.6-1.9-3.7-3.2-6-3.9c-3.3-3.1-6.8-6.1-11-7.9
		c-0.6-1.2-1.6-1.9-2.9-2C952.5,316.5,951.8,315.5,951,314.6z"/>
	<path class="st194" d="M975.2,331.5c-0.3-0.7-0.7-1.3-1-2c4-0.8,5.5,3.1,8.5,4.3c2.1,2.7,6.5,3,7.5,6.9c-0.7,0.4-1.4,0.3-2-0.1
		C983.3,338.3,979.6,334.5,975.2,331.5z"/>
	<path class="st195" d="M988.1,340.5c0.7,0,1.4,0,2.1-0.1c4.6,1.3,8.3,3.9,11.2,7.7c0.1,0.5,0.1,0.9-0.1,1.4c-0.4,0.3-0.8,0.3-1.3,0
		c-2.7-1.5-4.7-4.3-7.8-5.1C990.9,343.1,989.5,341.8,988.1,340.5z"/>
	<path class="st196" d="M823.3,123.7c0-0.3,0-0.7,0-1c1.6-2.5,3.5-3.4,6.1-1.4c1,0.7,2.2,0.7,3-0.5c0.3-0.3,0.6-0.6,0.9-1
		c1.5-1.6,0.6-3.8,1.6-5.6c0.2-0.2,0.3-0.3,0.5-0.5c1.6-0.2,3.7,0.7,4-2c2.7,0.3,1.8,2.2,1.7,3.7c-0.2,1.7-2.2,3.9,0.1,4.9
		c2.5,1.2,4.3,4.6,7.9,3.2c1.1-0.4,2.3-0.5,3.4-0.6c1.4-0.1,2.7-0.1,3.4,1.4c0.7,1.5,0.3,2.9-0.9,4.1c-1.5,1.5-3.8,2.7-2.5,5.5
		c0.7,0.1,1,0,1.1-0.4c0.9-2,0.8-5,4.2-4.5c2.9,0.4,2.5,3.1,3,5.1c1,3.8,3.2,6.5,7.3,7.3c1.5,0.3,3,0.3,4,1.7
		c0.7,1.4,0.8,2.9,0.8,4.4c-0.1,3,1,4.7,4.1,5c2.9,0.3,3.7,2.4,3.9,5c0.1,2.5,0.1,5,0.1,7.5c0,6.4,0,6.4,6,8.1
		c2.4,3-0.2,5.9-0.2,8.8c-2.1,1.6-4.5,1.5-6.5,0.2c-4-2.7-9.7-3.6-11-9.8c-0.4-1.7-2.5-5.3-6.3-2.1c-2.1,1.7-4.1,0.2-4.6-2.4
		c-0.4-1.8-0.2-3.6-0.3-5.4c-0.4-6.1-2.2-7.2-7.8-5c-0.8,0.3-1.6,0.5-2.4,0.4c-0.8-0.2-1.7-0.5-2.1-1.2c-3-6.6-8.7-11.6-10.9-18.6
		c-1.2-3.7-3.4-6.8-5.3-10C828.4,124.9,826,124.1,823.3,123.7z"/>
	<path class="st139" d="M838.3,105.7c-0.1,3-2.4,5.2-3,8c0,0,0,0,0,0c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2-0.1-0.3-0.3-0.5-0.4
		C834.7,110.5,835.7,107.7,838.3,105.7z"/>
	<path class="st139" d="M833.4,120.7c-0.3,0-0.6,0-0.9,0c0.1-2.5,0.7-4.9,1.9-7c0,0,0.1,0.1,0.1,0.1
		C835.2,116.2,835.2,118.6,833.4,120.7z"/>
	<path class="st197" d="M871.3,142.7c-8.5,0-10.3-1.6-12-10.6c-0.2-1.3-0.5-2.3-2-2.3c-1.5,0-1.8,1.1-2,2.3c-0.2,1.6,0.9,4-1.9,4.4
		c-1.5,0.2-2.7-0.6-2.9-2.1c-0.3-2.1-0.7-4.4,1.7-5.7c1.5-0.8,3.9-1.5,2.7-3.9c-1.1-2.3-3-0.4-4.4-0.1c-3.5,0.7-6.9,1.6-8.7-2.8
		c-6.5,0.6-3.4-3.3-2.5-5.7c0.6-1.6,1.5-3-0.1-4.5c-0.4-4.8,1.3-7.7,6.5-7.7c0.8,0,1.3,0,1.1-0.9c-0.1-0.5-0.4-0.9-0.6-1.4
		c4.8-2.1,9.2-5.5,15-4c2.7,1.9,4.9,4.3,6.7,7.1c0,1.1-0.3,1.9-1.4,2.2c-3.2-1.2-5.1-1.4-4.2,3.4c1,5.6,1.5,11.4,2,17.1
		c0.4,4.1,1.7,7.4,6.1,8.5c1,0.3,1.5,1.2,1.8,2.2C872.6,139.9,872.7,141.4,871.3,142.7z"/>
	<path class="st198" d="M867.3,104.7c-2.9-1.5-4.5-4.2-6-7c0.8,0,1.5-0.2,2-0.9c0.8-0.1,1.6,0.1,2.1,0.8c1.5,1,3,2,5,1.2
		c12.6,3.6,22,11.9,31,20.9c1,1.5,0.2,2.2-1.1,2.3c-1.8,0.2-3.8,0.6-4.9-1.4c-2.7-5.1-7-6.8-12.5-6.5c-2,0.1-3.6-1.2-4.1-3
		c-1.3-4.7-4.3-6.3-8.9-5.2C868.6,106.2,867.9,105.6,867.3,104.7z"/>
	<path class="st199" d="M833.4,209.6c-2.2,3.6-6.4,4.5-9.5,6.8c-1.3,0.9-3.3,1-3.4,3.1c-0.8-0.4-0.9-1.2-1-2
		c-0.5-2.4,1.7-3.1,2.8-4.5c3.9-1,6.3-5.5,11-4.7c0.3,0.3,0.4,0.5,0.3,0.8C833.5,209.5,833.5,209.6,833.4,209.6z"/>
	<path class="st200" d="M822.5,213.8c-0.1,1.9-2.4,2.2-3,3.8c-0.3,0-0.6,0-0.8-0.1c-0.4-1.4,0.1-2.7,0.9-3.8c0.8-1,1.1-3,3.2-1.8
		C823,212.5,823,213.1,822.5,213.8z"/>
	<path class="st201" d="M824.3,223.7c2-0.1,2.9,1,3.1,2.8C826,226,825.1,224.9,824.3,223.7z"/>
	<path class="st202" d="M963.2,159.7c-2-1-2.5-2.9-3-4.9c0.3,0,0.7-0.1,1-0.1C961.7,156.4,963.3,157.7,963.2,159.7z"/>
	<path class="st203" d="M1000.1,349.5c0.4,0,0.8-0.1,1.2-0.1c0.5-0.5,1.1-0.6,1.8-0.5c1.3,0.6,2.7,0.9,4,1.3c0,0,0.2,0.4,0.2,0.4
		c-0.1,1.3-1.2,1.8-2.2,2.3c-1.7-0.8-3.5-1.6-5.2-2.4C1000,350.2,1000,349.9,1000.1,349.5z"/>
	<path class="st204" d="M817.6,129.7c-0.1-0.3-0.1-0.6-0.1-0.9c1.3-1,2.6-2,3.9-3c2.2-1.5,4.3-2.8,6.2,0.4c-0.4,1.9-2.3,2.1-3.5,3.1
		c-0.7,0.5-1.5,0.8-2.4,1C820.3,130,818.8,130.9,817.6,129.7z"/>
	<path class="st205" d="M827.2,127.1c-1.3-3.1-3.7-1.8-5.8-1.3c0.3-1,1-1.6,1.9-2.1c3.7-2.2,5.5,0.3,7.1,3c0.4,1.5-0.7,1.5-1.6,1.7
		C828,128.2,827.4,127.9,827.2,127.1z"/>
	<path class="st206" d="M817.6,129.7c1.3,0.4,2.6-1.8,3.8,0c0.8,2.3-1,4.2-0.9,6.5c-0.2,0.8-0.5,1.5-1.2,2c-1.1,0.5-2.1,0.3-3-0.4
		c-0.6-0.7-1.3-1.3-1.9-2c-0.3-0.6-0.5-1.2-0.8-1.8c0-0.3,0-0.7,0-1C815.1,131.9,816.6,131.1,817.6,129.7z"/>
	<path class="st207" d="M1010.2,248.6c-0.1,0.7-0.1,1.4-0.2,2.1c-0.8,0.7-1.5,1.9-2.8,0.9c-5.2,0.3-10.1,1.7-15.1,3
		c-1.3,0.2-2.5,0.2-3.7-0.4c-2.1-1.4-1.7-2.9-0.1-4.4c1.3-1.2,2.9-2.1,4.2-3.3c1.5-1.1,3.3-1.4,5.1-1.9c4.7-1,8.2,1.7,11.8,3.9
		C1009.7,248.5,1009.9,248.5,1010.2,248.6z"/>
	<path class="st208" d="M1009.5,248.5c-4-1.2-7.8-3.5-12.3-2.9c-0.5,0.3-0.9,0.4-1.5,0.1c-1.9-0.7-4.4,0.9-5.8-1.7
		c0.1-0.6,0.4-1,1-1.3c4.2-1.4,8.3-0.7,12.3,0.6c1.5,0.4,2.1,2.6,4.1,2.1C1008,246.5,1008.7,247.5,1009.5,248.5z"/>
	<path class="st209" d="M1007.1,350.2c-1.5,1.4-2.9,1.3-3.9-0.6c-1.7-2.2-1.6-4.8-1.9-7.4c-0.1-1.4-1-2.8,0.1-4.1
		c2.2-0.6,2.8,0.7,3,2.6c0.8-2.8,0.3-5.4-0.4-8.1c-0.8-14.6,0-29.2-0.1-43.7c0-7-0.4-13.9-1-20.8c0.7-4-1.2-7.6-1.5-11.4
		c-0.1-1.3-2.1-0.1-2.3-1.5c1.6-2.4,3.7-2.9,6.2-1.7c0.8,2.5,1.2,5.1,1,7.8c-0.5,8.8,1,17.5,1,26.2c0,8.7,0,17.3,0.1,26
		C1007.2,325.7,1007.1,338,1007.1,350.2z"/>
	<path class="st210" d="M1005.2,253.6c-1.9,0.8-4.3,0.5-6,2c-1,1.6-2.6,2-4.2,2.3c-7.5,0.7-14.6,3.9-22.2,4.5
		c-1.8,0.1-3.6,0.7-5.2-0.5c-0.6-3.4,2.2-2.9,4-3.5c6.7-2.2,13.8-3,20.6-4.8c3.7-2.3,8.4-1.4,12.2-3.5c1.4-0.8,1.8,1.1,2.7,1.6
		C1006.5,252.2,1005.8,252.9,1005.2,253.6z"/>
	<path class="st211" d="M867.3,104.7c0.8,0.1,1.7,0.5,2.3,0.2c4.7-1.6,8.6,0.4,9.7,5c0.7,2.7,1.5,3.1,4.8,2.1
		c1.7-0.5,10.5,4.5,11.3,6.5c1.2,3,2.6,3.3,5.9,1.1c5.2,3.5,7.7,9.6,12.9,13.1c1.5,1.1,2.9,2.1,2.2,4.3c-4.7,4.1-9.5,4.2-12.9,0.3
		c-0.3-2.7-1.2-3.9-4.5-4.6c-7.5-1.6-13.7-5.7-16.7-13.3c-0.9-2.3-2.1-3-4.4-2.8c-3.2,0.2-6.1-1.2-9.3-1.7c-5.2-0.8-6-4.1-2.4-8.3
		C867.1,106.3,867.3,105.5,867.3,104.7z"/>
	<path class="st212" d="M927.3,127.7c-1.4,1.5-1.5,3.2-1,5c-3.5,1.1-5.5-1.6-7.8-3.4c-0.1-0.2-0.1-0.5-0.2-0.7
		C921.2,128.4,924.2,128,927.3,127.7z"/>
	<path class="st212" d="M916.4,136.6c-0.7-1.3-2.1-2.2-2.2-3.9c2.5-0.2,2.1-3.3,4.1-4.1c0,0,0.1,0.1,0.1,0.1
		c1.2,1.8,1.5,3.8,1.1,5.9C918.9,136.1,918.5,137.7,916.4,136.6z"/>
	<path class="st197" d="M870.3,98.8c0.3,0.9,0.7,2.1-0.9,1.7c-1.7-0.4-3.4-1-4.1-2.9C867.1,97.6,868.8,97.8,870.3,98.8z"/>
	<path class="st213" d="M956.3,164.7c3.5,7.1,7.3,14.1,10,21.6c0.9,2.6,2.8,4.4,4.8,6.2c1.4,1.2,3,2.2,3.3,4.2
		c-2.3,2.2-3.8-0.9-5.7-1.1c-0.9,0.8-0.5,1.6,0.2,2.1c2.8,1.7,3.8,3.3,0.7,5.9c-1.7,1.4-1.4,4-0.5,6c0.7,1.6,2.6,2.7,0.9,4.8
		c-1.9,2.3-3.7,4.6-7.2,4.5c-2.8-0.1-5.7-0.2-7.2,3c-1.5,1-3.3,1.2-4.7,0.3c-1.5-1-2.6-1.2-4.2-0.1c-1.3,0.9-3,0.4-3.9-1
		c-1-1.4-2.4-2.2-3.7-3.3c-1.3-4.4,1.7-4.9,4.8-5.4c2.2-0.3,5.3,0.7,5.7-3c-0.9-6,4.5-9.8,5.1-15.3c2.7-1,3.2-2.5,1.8-5.2
		c-1.8-3.4-2.5-7.1-2.5-11c0.1-6-4.2-10.3-6.5-15.4c-0.1-0.1-0.3-0.2-0.3-0.4c-1.8-4.8-4.1-9.3-7.6-13.2c-0.7-0.8-1-2.4,0-3.2
		c1.2-1.1,2,0.2,2.9,1C948.5,151.5,951.9,158.4,956.3,164.7z"/>
	<path class="st214" d="M942.4,146.6c-0.7,0.4-3.2-2.5-2.1,0.9c0.7,2.2,1.7,4.9,4.7,5.4c0.6,0.1,1.6,0.6,1.6,0.9
		c-0.5,10.5,11,17.1,9,28.3c-0.6,3.3,2.4,7.3,3.8,11c-1.1,0.9-2.7,0.2-4.1,0.4c-5.7,2.6-8.1,1.6-10.4-3.9c-1.5-3.6-3.8-7-3.1-11.2
		c-0.2-3.9-1.3-7.6-0.5-11.7c0.6-3.2-1.2-6.1-4.3-7.7c-2.4-1.3-3.3-3.2-2.2-5.8c1.3-1.7,0.9-3.7,1.4-5.6c0.5-2.1,1.1-4.4,4.2-3.1
		C941.6,144.7,942.2,145.4,942.4,146.6z"/>
	<path class="st215" d="M940.3,144.7c-3,0.2-3,2.4-3.1,4.7c-0.1,1.6,0.8,3.9-2,4.3c-0.5-0.4-0.8-1-0.9-1.7c-1-6.6-4-10.2-10.3-10.9
		c-4.2-0.5-5.7-2.5-5.6-6.3c0-2-0.1-4-0.1-6c2.6,1.3,5.3,2.7,7.9,4C931.5,136,936.6,139.5,940.3,144.7z"/>
	<path class="st216" d="M860.3,229.6c-0.2,1.3,4.1,2.7,0,4c0-0.2-0.1-0.5-0.1-0.5c-6.3,0.1-7.8-6.9-12.9-8.5c-0.1-4.9-4.7-6.6-7-10
		c2-2.5,4.7-1.1,7.1-1.1c1.7,0.3,3.1,1.1,4.1,2.6c2.2,3.3,4.4,6.6,7.6,9C860.6,226.1,860.7,227.9,860.3,229.6z"/>
	<path class="st217" d="M847.4,214.6c-2.3,0.2-4.7-0.9-7,0c-3,0.5,0.3,3.3-2,4.4c-1.1-1.9-2.8-3-5.2-3.9c-3.4-1.3,0.9-2.2,1.2-3.5
		c3.7-3,7.7-4.2,12.4-2.6C848.9,210.6,848.8,212.6,847.4,214.6z"/>
	<path class="st218" d="M846.4,209.6c-4.3-0.9-8,1.4-12,2c0-0.6,0-1.3,0-1.9c3.1-2.5,6.1-5.4,10.5-5.4
		C847.2,205.6,848,207.2,846.4,209.6z"/>
	<path class="st219" d="M844.3,204.6c-3.3,1.6-6.1,4.4-9.9,5.1c-0.3,0-0.7,0-1,0c0-0.3,0-0.7,0-1c-0.3-2.7,2-3,3.6-4
		c2.1-0.5,3.9-2,6.3-1.7C844,203.2,844.6,203.6,844.3,204.6z"/>
	<path class="st216" d="M860.3,233.6c-0.1,2.1,2.6,2.2,3,4C861.5,236.9,858.1,237.4,860.3,233.6z"/>
	<path class="st220" d="M844.3,204.6c-0.3-0.4-0.7-0.7-1-1.1c1-3.1,3.1-3.8,6-3c1.5,2.8,3.1,5.6,3.8,8.8c0.6,5,3.3,9,6.2,12.8
		c1.8,2.4,3.4,4.5,2,7.6c-0.3,0-0.7,0-1,0c-3.9-5.4-7.7-10.8-13-15c-0.3-1.7,0.7-3.6-1-5C846.7,207.5,845.9,205.9,844.3,204.6z"/>
	<path class="st221" d="M861.3,229.6c-0.6-5.8-6.1-9-7.6-14.4c-0.5-1.9-3.2-3.2-1.4-5.6c1.9-0.6,2.7,0.8,3.7,1.9
		c3.9,8.4,10.6,15.3,14.4,23.8c0.2,2.7,5.2,3.6,2.9,7.3c-4.7-2.3-8.2-5.6-9.7-10.8C863.3,230.6,862.8,229.6,861.3,229.6z"/>
	<path class="st222" d="M873.3,242.6c0.7-3.5-4.3-3.7-4-7c0.6-0.6,1.3-0.8,2.1-0.7c5.1,3.8,8.5,8.9,10.7,14.8
		c-0.2,1-0.3,2.1-1.8,1.9c-2.2-2.7-3.6-6.2-7-8C873.3,243.2,873.3,242.9,873.3,242.6z"/>
	<path class="st223" d="M917.2,288.4c-1.1,0-2.2,0.2-3-0.8c0.1-0.2,0.2-0.4,0.3-0.6c1.4-1.3,3.4-2,3.7-4.2c1-2.6,3.6-3.6,5.6-5.1
		c1.7-0.9,3.2-2.3,5.3-1.5c0.4,3.1,1.8,3.7,5,3.4c3-0.3,6.3-2.7,9.3,0.4c-0.5,1.7-2.1,2.1-3.5,2.4c-5.5,1.4-10.7,3.5-16.1,5.1
		C921.6,287.9,919.5,288.8,917.2,288.4z"/>
	<path class="st224" d="M924.3,278.5c-1.3,2-4.5,2.1-5,5c-0.9,0.9-2.1,1.4-3.1,1c-3-1.1-5.4,0.3-7.8,1.5c-1.5-0.5-2.2-2.3-3.9-2.5
		c-0.7-1-1.4-2-2.1-3c-1.1-2.2,0.2-3,2-3.7c5.7,2,11.5,2,17.3,0.8C922.6,277.4,923.5,277.7,924.3,278.5z"/>
	<path class="st225" d="M880.3,251.6c0.8-0.5,1-1.2,1-2.1c0.4-0.2,0.8-0.2,1.2,0c0.7,0.6,1.1,1.4,1.5,2.2c0.9,2.1,2.3,3.8,3.7,5.6
		c1.2,2.3,2.5,4.5,4.1,6.7c0.4,0.5,0.8,1.1,1.1,1.6c0.8,1.6,1.7,3.1,2.6,4.6c0.3,0.6,0.3,1.3,0.1,2c-0.2,0.2-0.3,0.3-0.5,0.5
		c-3.3-2.4-6.1-5.3-7.9-9C885.2,259.4,883.3,255.2,880.3,251.6z"/>
	<path class="st226" d="M904.3,277.5c-1.3,0.6-2,1.5-2,3c-2.4-2.7-4.7-5.3-7.1-8c0,0,0.2,0,0.2,0c1.6-1.2,2.9-2.8,4.8-3.5
		c2.8,0.4,3.9,2.3,4.4,4.8C904.3,275,904.8,276.3,904.3,277.5z"/>
	<path class="st227" d="M917.2,288.4c1.8-1.2,3.8-1.9,6.1-1.9c0.6,0.6,1.7,0.2,2.2,1.1c0.1,0.7-0.3,1.3-0.7,1.7
		c-1,0.9-2.1,1.7-3.5,1.8c-1.1-0.2-2-1-2.9-1.5c-0.3,0-0.6-0.1-0.9-0.2C917.3,289.1,917.3,288.7,917.2,288.4z"/>
	<path class="st223" d="M904.4,283.5c1.9-0.4,2.8,1,3.9,2c0.6,0.4,0.6,1,0.4,1.6c-0.1,0.1-0.3,0.3-0.4,0.4
		C907,286.2,905.7,284.9,904.4,283.5z"/>
	<path class="st223" d="M914.2,287.6c-2-0.7-4,2.1-6,0c0,0,0.1-0.1,0.1-0.1C910.3,286.1,912.3,283.6,914.2,287.6L914.2,287.6z"/>
	<path class="st228" d="M822.3,211.5c-1,0.7-1.9,1.4-2.9,2.1c-2.1-11.7,0.3-23.4,0-35.2c-0.2-10.3-0.8-20.5-1.1-30.8
		c1.4,1.8,1.9,3.8,2,6c1,1.7,2.4,3.2,3.4,4.9c2.4,4,3.4,7.9,0,11.9c-1,1.2-1.1,2.7-1.1,4.2c-1.1,6.8-1,13.6-0.9,20.5
		c0.2,4.9-0.6,9.9,0.9,14.8C822.8,210.5,822.7,211,822.3,211.5z"/>
	<path class="st229" d="M816.3,137.7c1.1,0,2.2-0.1,3.4-0.1c3.8,2.2,4.8,6.4,7.3,9.6c0.8,1.6,1.5,3.2,2.6,4.6
		c0.3,0.4,0.5,0.7,0.7,1.1c1.1,2.2,2.2,4.4,3.6,6.4c0.5,0.8,0.9,1.6,1.4,2.4c2.7,5.2,6.3,9.7,9.3,14.6c1.2,1.9,2.8,3.7,2.4,6.3
		c-0.3,0.7-0.8,1.2-1.5,1.4c-2.2-0.2-2.9-2-3.8-3.5c-3.4-5.7-7.2-11-10.8-16.5c-1.8-4-5.5-7-6.4-11.5
		C821.7,147.6,819,142.6,816.3,137.7z"/>
	<path class="st230" d="M821.4,173.6c0-0.7-0.3-1.6,0.1-1.9c6.1-5.9,1.9-11.2-0.8-16.6c-0.2-0.4-0.2-1-0.3-1.4c1,0.7,2,1.3,3,2
		c3,2.2,3.9,5.5,5.2,8.7c0.1,0.5,0.2,1,0.2,1.5c0.8,4.3,3.5,7.8,5.6,11.5c1.7,3.1,3.3,6.3,0.3,9.5c-2.3,0.9-3.3-0.6-4.2-2.3
		c-1-2.1-1.9-4.2-3.8-5.7C824.6,177.4,823.6,175,821.4,173.6z"/>
	<path class="st231" d="M827.5,164.7c-1.4-3-2.8-6-4.1-9c0.4-1,0.7-2.1,1.1-3.1c2.6,3.4,5.5,6.8,7,10.9c0.5,1,0.6,1.9-0.6,2.5
		C829.4,166.6,828.4,165.6,827.5,164.7z"/>
	<path class="st232" d="M973.3,328.5c-7-4.7-13.6-9.9-21-14c-5.8-5.3-12.7-9.3-19-14c-0.2-1.5,0.8-1.4,1.8-1.5
		c3.8,0.4,6,3.5,8.9,5.5c1.2-1.2-0.6-2.3,0.2-3.3c1.9-2.9,4.4-2.7,6.8-1c3.7,2.7,7.8,2,11.9,2c3.9,0.1,2.5-4.2,4.7-5.7
		c1.8,0.4,1.8,2.5,2.9,3.6c1.3-0.6,1.5-2.3,2.8-2.8c1.6-0.1,2.3,1.2,2.9,2.3c3.3,5.9,6.7,11.7,10.2,17.6c0.6,1,0.9,2.1,0.6,3.3
		c-0.2,0.7-0.7,1.3-1.4,1.6c-3.1,0.4-5.9-0.3-8-2.8c-2.3-2.6-4.2-2.4-5.5,1c-0.5,1.3-1.4,2.1-3.3,2.1c2.4,1.3,4.4,1.8,6.5,2.5
		c1.1,0.6,2.2,1.1,2.2,2.6C976.3,328.7,974.8,328.6,973.3,328.5z"/>
	<path class="st233" d="M935.2,299.5c-0.8,0-1.5,0.2-1.9,0.9c-3.3-2.3-6.7-4.6-10-6.9c-0.8-0.9-2.6-1.1-2-3c1-0.7,2-1.3,3-2
		c5.6-1.1,11.1-2.5,16.8-2.8c4.8,0,9.4-1.7,14.3-1.6c3,0.1,3.4-3.5,5.9-4.1c1.3,0.2,2.3,1.4,3.7,1.2c2.4,1.8,3.5,4.3,3.9,7.3
		c-0.2,0.8-0.6,1.3-1.4,1.5c-6.8,2.6-14,0.8-21,1.6c-5.2,0.5-9.7,1.8-13.7,5C933.5,297.6,935.6,297.5,935.2,299.5z"/>
	<path class="st182" d="M921.2,290.5c0.7,1,1.4,2,2,3c-2.5-0.2-3.7-2.1-5-3.9C919.3,289.9,920.3,290.2,921.2,290.5z"/>
	<path class="st234" d="M955.2,221.6c-0.5-3.5,1.4-4,4.4-4.1c2.7-0.1,5.9,1.5,7.8-2.5c0.9-1.9,2.5-2.5,0-4.6c-2.2-1.9,0.2-8,3.2-9.7
		c-0.5-1.7-2.4-0.7-3.3-1.7c-1.6-1.7-1.7-3.8-0.3-5.4c1.5-1.7,2.8,0,3.5,1.1c1,1.4,2,2.1,3.7,1.9c3.7,2.7,6.2,6.4,8.8,10
		c-4.1,2.9-5,6.8-4.3,11.7c0.5,3.5-0.2,7.3-0.3,10.9c-0.2,4.8-0.8,5-5,2.5c-0.7-0.4-1.5-0.7-2.4-0.7c-1.3,0-2.6,0.5-3.8,1
		c-1.1,0.4-2.2,0.7-3.4,0.5C961.2,232.2,955.3,224.7,955.2,221.6z"/>
	<path class="st235" d="M971.2,229.6c3.2,0.4,3.2,0.4,6.1,4.2c0-6.5,0.3-11.9-0.1-17.3c-0.3-4.8-0.5-9.2,5.9-9.9
		c4.4,6.6,8.9,13.2,11.1,21c-0.1,2.5,1.9,5.4-1.3,7.4c-0.2,0.4-0.4,0.9-0.6,1.3c-1.7,2.9-4.5,3.2-7.3,3c-2.6-0.2-5.3-0.3-7.9-0.3
		c-0.8,0-1.7,0-2.5,0c-3-0.3-6.5-0.6-7.5-3.7C966.1,232.3,969.9,231.6,971.2,229.6z"/>
	<path class="st236" d="M977.2,237.6c2.6,0.1,5.2,0.2,7.8,0.5c3.2,0.4,5.7-0.5,7.2-3.5c0.2,0.2,0.3,0.4,0.4,0.5
		c0-0.1-0.2-0.3-0.4-0.5c0.6-1.7,4-3.3,0.2-5.4c-0.6-0.4,0.7-1.5,1.8-1.6c1.7,2.3,2.1,5.1,3,7.7c1.2,3.3,2.9,6.2,6,8.1
		c-4.3,0.1-8.5,0.2-12.8,0.3c-4.8,1.8-9.7,2.7-14.8,2.2c-1,0-2-0.2-3-0.4c-2.5-1.8-5.1,1.9-7.6-0.1c-0.5-3.7,2.5-4.1,4.8-4.7
		C972.4,240,975.1,239.7,977.2,237.6z"/>
	<path class="st237" d="M991.3,208.9c1.5,1.7,2.8,3.7,2.8,6.3c0,0.9-0.8,2.1-1.3,1.7c-2.4-1.3-3-3.8-3.5-6.2
		C989.1,209.7,989.6,208.7,991.3,208.9z"/>
	<path class="st177" d="M963.2,163.7c-0.1,0.8,0,1.9-0.5,2.3c-0.9,0.8-2,2.5-3.2,1.7c-1.8-1.3-0.3-3,0.4-4.4
		C961.4,160.5,962.2,163.2,963.2,163.7C963.2,163.7,963.2,163.7,963.2,163.7z"/>
	<path class="st238" d="M975.2,325.5c-3.2,0.4-5.7-1.1-8.4-3.6c2.2-0.6,5.1-0.2,4.1-4c-0.4-1.4,6.1-2.8,6.5-1.2
		c1.1,4.3,4.8,3.8,7.7,4.8c1.3,0.5,2.8,0.5,3.8,1.7c1.3,1.2,2.1,2.6,2.3,4.4c0.2,3.4-1.3,5.4-4.8,5.6c-1.5,0.1-2.8,0.9-4.3,1.2
		c-2.7-1.7-5.3-3.3-8-5c-0.3-0.3-0.6-0.7-0.9-1c1.2-0.7,2.5-1.1,3.9-1c0.6-1,2.7-1.6,1.2-3.1C977,322.9,976.3,325,975.2,325.5z"/>
	<path class="st239" d="M982.2,334.4c0.8-1.3,1.1-3.4,3.3-2.7c4.1,1.2,4.7-1.2,4.7-4.3c2.5,0,2.6,2.4,3.7,3.8c1.5,3.2,3.6,6,5.1,9.2
		c0.8,2.6,3.6,4.8,2,8c-3.6-2.7-7.2-5.3-10.9-8C987.5,338.5,984.3,337.2,982.2,334.4z"/>
	<path class="st240" d="M1001.1,348.4c-0.1-3-2.6-5-2.9-7.9c-0.5-0.9-0.6-1.9-0.2-3c4-0.7,2.7,3.4,4.4,4.9c0.3,2.4,0.6,4.8,0.9,7.1
		c-0.6-0.1-1.2-0.2-1.9-0.3C1001.2,349.1,1001.1,348.7,1001.1,348.4z"/>
	<path class="st241" d="M871.3,142.7c0-1.7,0-3.3,0-5c2.3-1.7,4.2-0.3,5.8,1c3.6,3.1,7.8,5,12.3,6.3c4.4,1.2,6.5,3.8,6.2,8.3
		c-0.4,4.7,2.1,6.5,6.4,7c4,0.5,7.6,1.7,11.1,4c3.1,2.1,5.4,3.8,5,8.1c-0.3,2.9,2.2,4.2,4.9,4.6c1.6,0.2,3.4,0.1,4.9,0.8
		c2.9,1.3,3.8,3.5,2.5,6.5c-3.5,8.5-3.5,8.5,3.7,13.6c0.5,0.4,1,0.9,1.4,1.4c0.3,1.3-0.3,2.1-1.2,2.8c-2,1-4.2,0.8-6.4,0.8
		c-4.8,0.2-9.5,0.4-10.9-5.8c-0.1-0.3-0.3-0.4-0.3-0.4c-6.1-1.6-6.1-7.9-9.1-11.8c-3-3.9-5.8-8.5-11-10.4c-1.5-0.6-2.2-1.9-3.1-3.1
		c-2.8-3.7-3.1-3.6-5,0.6c-0.3,0.6-0.5,1.2-1.1,1.6c-9.1-0.1-7.9,1.1-8-7.8c0-2.1-0.2-4.3,0-6.4c0.4-3.9-0.4-6.5-5.2-5.8
		c-2.1,0.3-2.9-1-2.8-3C871.4,148,871.3,145.3,871.3,142.7z"/>
	<path class="st242" d="M887.3,173.6c0-1.3-0.1-2.7,0.2-4c0.4-1.5,1.5-2.8,2.9-3.2c1.8-0.6,1.8,1.5,2.6,2.4c2.1,2.1,4.7,3.8,6.6,5.6
		c4.6,4.3,10.7,8.3,11.6,15.5c0.3,2.3,0.5,3.8,3.3,3.8c2,0,4,1.6,4.1,3.1c0.5,6.3,5,4.6,8.7,4.8c0.3,0.4,0.9,0.9,0.7,1.2
		c-2.5,4.4-1.9,9.6-4,14c-0.6,1.3-1.9,1.8-3.2,2c-2.5,0.3-5,0.3-7.5,0c-5.2-1.4-10.2-3.7-15.7-4.1c-1.4-0.1-2.8-0.8-3-2.2
		c-0.5-3.6-2.5-6.8-2.1-10.6c0.8-1.9-0.1-2.8-2-3c-3.7-0.3-4.6-2.8-4.6-5.9c0.1-3.8-0.5-7.6,0.3-11.4
		C885.5,178.8,886.4,176.2,887.3,173.6z"/>
	<path class="st243" d="M886.3,181.6c2,3.2,0.9,6.7,0.9,10c0,4,0.3,6.9,5.4,6c1.2-0.2,2.3,0.1,2.5,1.6c0.2,1.4-0.5,2.1-1.9,2.3
		c-3,0.6-6.1,0.5-9.1,1.1c-2.5,0.5-4.5-0.6-5.4-3.3c-0.6-1.9-1.8-3.2-3.9-3.3c-0.5-0.1-0.9-0.2-1.3-0.5c-3.6-2.2-3.9-7.4-8.4-8.8
		c-4-5.8-7.9-11.7-11.6-17.7c-1.5-2.4-3.8-4.1-5.2-6.6c-1-1.8-2.8-3.5-1.2-5.9c1.2,0,2.1-0.4,3.2-1c5.5-3.3,8.3-1.7,8.8,4.8
		c0.2,2.1,0,4.3,0,6.5c0,1.2,0.2,2.4,1.7,2.6c1.1,0.1,1.8-0.4,2.2-1.5c1.5-3.6,2-2.6,4.4,0c3.7,3.9,5,10,11.4,12
		C881.4,180.6,883.8,181.4,886.3,181.6z"/>
	<path class="st244" d="M847.4,156.7c0.6,4.8,4.6,7.5,7,11.1c4,6.2,8,12.5,11.9,18.8c3.3,2.5,4.3,6.9,8,9v0
		c2.4,4.7,6.8,8.6,6.5,14.6c-0.2,0.5-0.6,0.8-1.1,0.9c-2.1,0-2.8-1.8-4-2.9c-2.6-3.1-5-6.4-6.7-10.2c-5.5-9.5-11.3-18.8-17.4-27.9
		c-8.3-12.5-16.4-25-22.8-38.6c-0.6-1.2-1.4-2.3-0.7-3.7c0.9-0.1,1.8-0.2,2.3-1.1c3.9,5.4,5.4,12.3,9.2,17.5
		C842.5,148.3,844.8,152.5,847.4,156.7z"/>
	<path class="st245" d="M833.4,120.7c0.6-2.3,1.3-4.5,1-6.9c0.3,0,0.6,0,1,0C834.7,116,837,119.2,833.4,120.7z"/>
	<path class="st246" d="M935.3,199.6c-1.8-0.8-3.6-1.5-5.4-2.3c-3.9-1.7-5.3-8.7-2.2-11.5c1.7-1.5,2.6-3.1,1.9-5
		c-0.7-1.9-2.7-2.1-4.7-2.2c-7.4-0.3-8.5-1.5-8.1-8.8c0.1-1.2-0.1-1.9-1.1-2.4c-4.5-2.1-8.5-5.2-13.8-5.8c-7.4-0.8-7.9-1.2-7.4-8.5
		c0.5-6.3-4.3-6.9-7.7-7.5c-4.6-0.8-7.3-4.1-10.9-6.1c-1.5-0.8-2.7-1.9-4.5-1.7c-7.4-3.1-9.2-7.7-8.7-16.7c0.2-3.6-1.3-7.7-1.4-11.6
		c0-1.5-3-3.1-0.3-4.5c2.1-1.1,4.1-0.3,5.4,1.9c-2,6-1.8,6.4,4.2,7.1c3.5,0.4,6.8,3,10.6,1c0.4-0.2,1.7,0.5,1.7,0.6
		c-1,4.4,4.3,5.8,4.6,9.5c0,0.6,0.3,1.8,0.4,1.8c4.3,0.1,7.2,4.5,10.9,4.6c5.2,0.1,6.7,1.6,5.7,6.3c0.3,3.6,0.7,7.1,5.6,7.1
		c0.3,0,0.8,0.2,0.9,0.4c1.3,5.1,6.3,6.8,9.2,10.4c1.7,2.1,3.4,3.6,5.5,5.2c3.5,2.8,6.3,6.4,10,9.2c2.4,1.8,1.1,5.6,1.3,8.6
		c0.2,5.2,0.1,10.3,0.1,15.5C936.8,196,936.9,198.1,935.3,199.6z"/>
	<path class="st247" d="M822.3,211.5c0-0.3,0.1-0.6,0.1-0.9c0.3-0.4,0.6-0.7,1-0.9c2.1-0.8,3.9-2.2,5.9-3.3c1.8-0.8,3.6-1.6,5.5-2.1
		c1-0.3,2.4-0.7,2.5,1.2c-1.2,1.1-3.3,1.2-3.9,3.1c-4.1,0.7-6.6,4.8-10.9,5.2C822.4,213,822.4,212.3,822.3,211.5z"/>
	<path class="st248" d="M828.1,127.8c11.2,23.1,26.4,43.7,39.7,65.5c0.9,1.4,1.6,2.9,2.4,4.3c-0.5,0.9-1.1,1.6-2.3,1
		c-6.4-6.7-10-15.3-15.3-22.8c-1.2-1.7-2.2-3.7-3.8-5.1c-0.5-0.5-0.9-1-1.2-1.6c-0.7-1.7-1.9-2.9-3.3-4c-1.7-2.1-3.6-4.2-4.4-6.9
		c-3.3-4-4.6-9.1-7-13.5c-3-5.5-5.8-11.1-9.6-16.2c1-1.2,2.3-1.7,3.9-1.5C827.5,127.3,827.8,127.6,828.1,127.8z"/>
	<path class="st249" d="M823.3,128.5c4.1,2,6.1,5.6,7.7,9.6c2.8,6.8,6.5,13.2,10.2,19.6c-0.8,1.9-2.4,0.9-3.7,1.1
		c-2.1-1.8-2.6-4.5-4.2-6.6c-4.9-5.3-8-12.1-13.8-16.6c-0.7-2.4,1.2-3.9,1.8-5.9C822,129.3,822.7,128.9,823.3,128.5z"/>
	<path class="st250" d="M819.6,135.6c4.8,1.7,8,5.5,10.1,9.6c1.3,2.5,3.1,4.4,4.7,6.5c0.8,1.7,0.9,3-1.3,3.5
		c-1.7-0.1-2.5-1.6-3.8-2.5l0,0c-1.1-1.6-1.6-3.7-3.1-5c-2.2-3.4-4.4-6.8-6.6-10.2C819.6,136.9,819.6,136.2,819.6,135.6z"/>
	<path class="st251" d="M996.1,245.5c0.3,0,0.7,0,1,0.1c-0.9,1.6-3.1,0.3-4,2c-9.4,5.8-20.4,6.4-30.6,9.6
		c-7.4,2.3-14.9,4.3-22.5,6.1c-3.6,1.3-7.2,1.2-10.7-0.3c-0.4-0.4-0.6-0.8-0.4-1.4c1.3-1.5,3.2-1.2,4.8-1.8c1.5-0.5,3.2-0.3,4.6-1.2
		c0.4-0.2,0.8-0.4,1.3-0.5c2.9,0.1,5.6-0.7,8.3-1.7c12.7-3.9,25.4-7.6,38.4-10.4C989.6,245.3,992.8,244.5,996.1,245.5z"/>
	<path class="st252" d="M940.2,262.5c2.1-2.2,5.2-1.9,7.7-2.5c8.3-1.9,16.2-5.3,24.6-6.7c7.1-1.2,13.8-3.6,20.7-5.8
		c-1.1,2.5-6.1,1.7-5,6c-0.7,0.8-1.6,1.2-2.6,1.3c-2.6,0.4-5.3,2-7.7,1.4c-2.8-0.6-3.1,2.3-5.1,2.2c-2.6-0.1-5.4,0.2-7.8,0.8
		c-5.2,1.3-10.1,3.9-15.6,4.3C946.2,264.7,942.9,265.8,940.2,262.5z"/>
	<path class="st253" d="M949.2,262.5c12.9-3.5,25.8-6.9,38.9-9c1.3,0,2.7,0,4,0c-1.9,2.9-5.1,2.3-7.9,3c-5.8,1.3-11.7,2.3-17.1,5
		c-0.2,0.2-0.4,0.4-0.6,0.6c-3.2,1.1-6.5,1.6-9.9,1.8C954.2,263.7,951.4,264.6,949.2,262.5z"/>
	<path class="st254" d="M996.1,245.5c-9.9,0.9-19.2,4.3-28.8,6.5c-6.7,1.6-13.1,4.4-20,5.5c-1.2,0.6-2.1,0.6-2.3-1
		c1.5-2.9,4.7-2.5,7.2-3.5c2.6-1,5.3-1.4,7.9-2.4c2.5-0.6,5-1.3,7.4-2.1c5.6-1.8,11.4-2.6,17.1-4.1c1.8-0.5,3.5-0.6,5.3-0.1
		C991.9,245.1,994.3,243.7,996.1,245.5z"/>
	<path class="st255" d="M989.9,244.4c-6.1,0.2-11.5,2.9-17.3,4.2c-1.8,0.4-3.5,0.8-5.3,1.1c-0.4-2.1,1-3.4,2.4-4.6
		c1.8-1,3.9,1.1,5.6-0.5c5.2,1.2,10.1-1.9,15.2-0.9C990.3,243.9,990.1,244.2,989.9,244.4z"/>
	<path class="st256" d="M1004.2,266.5c2,22.6,0.6,45.3,1,67.9c0.5,1.1,1,2.3-0.4,3c-1,0.5-1.9-0.4-2.5-1.1c-1.7-1.9-2.6-4.4-2.9-6.8
		c-0.8-6.5-1.3-13.1,0.2-19.7c2.7,0.2,2.5-1.6,2.6-3.5c0.7-8.5,0.4-17,0-25.5c-0.5-2.1-1.3-4.2-0.9-6.5
		C1002.7,271.9,1000.2,268.1,1004.2,266.5z"/>
	<path class="st257" d="M1000.2,309.5c0.6,7.8-1.5,15.9,1.7,23.5c0.4,1,0.7,2.4,1.5,3c2.7,2.1,1.3-0.8,1.8-1.6
		c2.2,2.5,0.4,4.8-0.3,7.2c-1.6-0.8-1.4-3.2-3.5-3.2c-2-0.9-2.5-2.7-3.2-4.5c-0.2-0.9-0.3-1.7-0.4-2.6c-1.8-13.7-1-27.5-1-41.2
		c0-2.1-0.4-4.3,1-6.3c3.4-0.5,2.5,2.4,2.7,3.9c0.5,3.3-0.4,6.6,0.7,9.9C1001.6,301.6,1002.7,305.7,1000.2,309.5z"/>
	<path class="st258" d="M1004.2,266.5c-2.4,2.2,0.4,5.7-2,8c-1.8-1.7-1.1-4-1.3-6.1c-0.5-4-1.6-4.7-6-3.5c-1.7,0.8-3.1,1.8-4.6,2.8
		c-0.5,0.1-0.9,0-1.3-0.2c-0.9-1.1-1.3-2.4-1.1-3.9c0.6-1,1.7-1.3,2.5-2.1c0.8-0.8,1.6-1.5,2.6-1.9c0.9-0.4,1.9-0.8,1.8-2.1
		c0-0.4,0.1-0.7,0.4-1c1.3-0.3,2.7-0.7,4-1c2.7-0.9,3.2,1.5,3.7,3C1003.7,261.1,1004.6,263.7,1004.2,266.5z"/>
	<path class="st259" d="M999.2,334.4c0.7,1.4,1.4,2.7,2.1,4.1c0.3,1.4,0.6,2.7,0.9,4.1c-2.1-1.1-1.8-4.1-4-5.2
		c-0.6-0.5-0.6-1.1-0.5-1.8C998.1,334.9,998.5,334.5,999.2,334.4z"/>
	<path class="st260" d="M995.2,256.6c0,0.3,0,0.5-0.1,0.8c-0.9,2-3,2-4.6,2.8c-0.4,0.2-0.9,0.3-1.4,0.4c-0.5,0.1-0.9,0.2-1.4,0.3
		c-2.4,0.3-4.6,1.4-7,1.9c-3.9,0.6-7.6,1.8-11.3,3c-1.9,0.6-3.7,1.3-5.7,0.5c-0.5-0.2-0.7-0.6-0.8-1.1c0.7-2.1,3.2-1.9,4.3-3.5
		c0,0,0,0,0,0c6-0.6,12-1.1,17.8-3.2C988.2,257.1,991.8,257.1,995.2,256.6z"/>
	<path class="st261" d="M935.3,177.6c0.9-5-0.5-8.7-5.8-9.9c-1.6-0.4-2.2-1.3-2.2-2.8c0-0.2,0-0.3,0-0.5c-0.8-4.4-6.6-2.1-7.5-5.1
		c-1-3.2-0.8-6.3-5.4-5.7c-3,0.4-3-1.8-3-3.9c0-2,0.2-3.8-2.9-4.1c-5.8-0.5-6.3-1.8-4.1-7.9c4.3,3.3,8.1-0.2,12.1-1.1
		c1.3,0,1.9-0.7,2-1.9c0.8,3.7,2.8,5.2,6.9,5.1c6-0.1,10,6.1,9.9,13.8c0.1,1.7-0.2,3.2,2.2,4.2c4.1,1.6,6.9,6.3,4.5,12.5
		c-1.1,2.8,2.4,4.6,1.2,7.3C940.6,179.5,937.9,179.5,935.3,177.6z"/>
	<path class="st262" d="M977.2,237.6c-0.4,3-2.3,3.8-5.1,4.1c-2.7,0.3-6.1-0.1-6.9,3.9c-5.9,2.8-12,1.5-17.9,0.3
		c-5-1-9.6-3.6-14.9-3.4c-1,0-1.8-0.7-2.4-1.6c-0.4-4.4,0.2-8.9-3.5-12.6c-1.5-1.5-0.7-4.4,0-6.6c3.7-5.1,7.6-6.3,12.8-4
		c1.4,0.2,3.1-0.6,3.8,1.3c2.2,5.5,3.5,1.7,5.5-1c1,3.8,3.7,3.9,6.7,3.7c0,1.9,1.3,2.9,2.7,3.8c0.6,0.3,1.2,0.5,1.7,0.9
		c5.1,3.9,5.1,3.9,11.6,3.2c0.7,3-1.9,3.7-3.9,4.9C970.3,237.6,973.7,237.7,977.2,237.6z"/>
	<path class="st263" d="M935.3,177.6c2.7,0,5.3,0,8,0c-1.7,5.5,2.8,9.5,4,14.3c0.9,3.4,5.2,1.1,8,1.7c-0.2,3.2,1.1,6.7-3.3,8.8
		c-2.2,1.1,0.5,5.1-1.7,7.2c-0.9,0.9-2.3,1.5-3.1,0.9c-3.9-3.5-9.9-4-12.9-8.9c0.3-0.7,0.7-1.3,1-2
		C935.3,192.3,935.3,185,935.3,177.6z"/>
	<path class="st264" d="M934.3,201.6c3.3,3.4,9.3,2.5,11.8,7.5c0.6,1.2,2.8,0.4,4.2,0.5c2,4.7-1.6,3.7-4,4c-2.9,0.4-7.1-1.6-7,4
		c-4.6-0.5-8.7,0.6-12,4c-1.8,1.5-3.9,1.7-5.7,0.3c-1.5-1.2-1.3-2.8-0.3-4.3c0.5-0.2,1.2-0.2,1.3-0.5c2.1-5,0.9-11,4.7-15.5
		C929.6,201.6,931.9,201.6,934.3,201.6z"/>
	<path class="st265" d="M837.3,205.5c-3.1-1.1-5.2,1.7-7.9,2.1c-0.4-0.3-0.5-0.7-0.6-1.2c0.2-1.5,1.2-2.5,2.3-3.3
		c1.3-0.9,2.6-1.8,4.1-2.4c3.1-0.9,5.2-3.2,7.3-5.5c0.8-0.8,1.4-1.8,2.7-1.9c1.2,0.3,1.9,1.1,2.6,2c0.8,1.5,2.3,2.8,1.9,4.8
		c-0.1,0.2-0.3,0.3-0.4,0.5c-2.3,0.5-4.3,1.4-6,2.9C841.1,203.7,839.6,205.7,837.3,205.5z"/>
	<path class="st266" d="M855.4,211.6c-1-0.7-2-1.3-3-2c-0.7-3.1-3.8-5.4-3-9c0,0,0,0,0,0c4.9,1.9,6.2,6.3,7.2,10.8
		C856.2,211.8,855.8,211.9,855.4,211.6z"/>
	<path class="st267" d="M855.4,211.6c0.3,0,0.6,0,1,0c4.1,2.1,5,6.7,7.9,9.6c2.2,3.1,4.4,6.1,6.2,9.4c0.8,1.5,2.2,3,1,4.9
		c-0.7,0-1.4,0-2.1,0c-3.1-6.5-7.7-12.1-11.5-18.1C856.8,215.6,855.6,213.8,855.4,211.6z"/>
	<path class="st268" d="M871.4,235.6c-1.6-5.3-5.6-9.2-8.1-14c-0.7-1.3-1.1-2.7-1.5-4.1c-0.7-2.2,0.4-3.5,2.8-3.4
		c1.7,0.4,2.5,1.6,2.8,3.1c0.6,4.8,2.6,8.7,6.4,11.7c1.6,1.2,1.3,3.8,3.6,4.4c0.4,0.3,0.6,0.7,0.8,1.2c0.9,3.8,2.5,7.1,4.8,10.2
		c1.3,1.7,2.3,3.7-0.8,4.8c-0.3,0-0.6-0.1-0.9-0.1C878.4,244.5,875.2,239.8,871.4,235.6z"/>
	<path class="st269" d="M963.2,265.5c4.9,0.1,9-2.8,13.7-3.4c1.4-0.2,2.9-0.4,4.3-0.6c0.7,1.9-1.9,1.9-1.9,3.4
		c1.3,1.1,3.5,1,4.2,2.9c-0.6,2.1-2.7,1.8-4.1,2.8c-5.4,1.6-10.9,3-16.2,4.8c-5,1.7-10.1,2.8-15.2,4.3c-1.6,0.4-3.2,1.1-4.7-0.2
		c-4.1,0.6-8.4,0.5-12.4,1.9c-0.9,0.3-1.6,1.2-2.6,0.4c-1.1-1,0.1-1.7,0.4-2.5c0.3-0.9,0.4-1.9,0.6-2.8c1.3-3.1,4.3-3.3,6.9-4
		c4.1-1.1,8.2-2,12.1-3.8C953.2,267.8,957.9,265,963.2,265.5z"/>
	<path class="st270" d="M948.3,269.6c-2.9,2.8-6.8,2.8-10.4,3.8c-3,0.8-5.9,1.7-8.7,3.2c-1.7,0.7-3.3,1.3-5,2
		c-2.8,0.3-5.6,0.3-8.3,1.1c-4.4,1.2-8-0.5-11.7-2.1c-0.3-1-3.8-2.2,0.1-3.1c0.4-0.2,0.8-0.3,1.3-0.2c2.5,0.9,4.9,2.4,7.7,2.1
		c0.9,0,1.8,0,2.8-0.1c2.2,0.1,4-1.2,6-1.7c2.3-0.4,4.7-0.8,6.7-2.1c2.7-1.3,5.6-1.6,8.4-2.1c0.5-0.1,0.9-0.1,1.4-0.1
		c1.7,0,3.1-0.9,4.6-1.4c0.7-0.2,1.4-0.3,2.2-0.3C946.6,268.5,947.6,268.7,948.3,269.6z"/>
	<path class="st271" d="M943.3,279.6c6.6-1.7,13.2-3.5,19.8-5.2c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.4,0.1,0.8,0,1.3
		c-0.4,1.9-1.5,3-3.5,3c-0.4,0.1-0.9,0.1-1.3,0.1c-6,1.9-12.1,3-18,5.3c-5.1,1.2-10,3.6-15.5,3c-0.8,0-1.6-0.2-2-1
		C930,284.2,936.6,281.9,943.3,279.6z"/>
	<path class="st272" d="M914.3,287.5c-2-2.2-4,0.1-6,0c0-0.7,0-1.3,0-2c2.9-5.1,7.3-1.2,11-2C919,286.5,916.6,287,914.3,287.5z"/>
	<path class="st273" d="M882.3,249.6c1.8-1.7,0.8-2.8-0.5-4.5c-2.7-3.3-4.7-7-4.4-11.5c0.4-0.3,0.9-0.3,1.4,0
		c4.6,6,8.9,12.1,12.8,18.6c0.8,1.4,2,2.7,1,4.5c-2.6,1.3-3.8-1.3-5.5-2.2c-1.2-1-2.6-1.8-3.8-2.8
		C882.4,251.2,882.3,250.4,882.3,249.6z"/>
	<path class="st274" d="M900.4,269.5c-1.2,1.7-2.2,3.7-4.9,3c-0.1-0.6-0.1-1.3-0.2-1.9c-1.1-1.6-1.6-3.7-3.1-5
		c-0.3-0.3-0.6-0.7-0.9-1c-1-2.9,0.8-5,2.1-7.3c2.8,0.6,3,3.4,4.6,5C899,264.7,901.8,266.4,900.4,269.5z"/>
	<path class="st275" d="M893.3,257.7c0.4,2.6-1.8,4.5-2,6.9c-1.7-2.1-3.5-4.1-4.1-6.9c-0.6-1.4-1.2-2.8,0.1-4.1c1.7,1,3.3,2,5,3
		C893.2,256.4,893.7,256.7,893.3,257.7z"/>
	<path class="st268" d="M887.3,253.6c0,1.4-0.1,2.7-0.1,4.1c-2.2-1.4-3.4-3.6-4-6.1C885,251.4,886.5,251.7,887.3,253.6z"/>
	<path class="st275" d="M892.2,265.6c2.2,0.9,2.8,2.9,3.1,5C893.4,269.5,892.7,267.6,892.2,265.6z"/>
	<path class="st276" d="M900.4,269.5c-0.2-2.7-2.7-4.3-3.2-6.9c0.5-2.2-1.4-3.5-2.1-5.2c-0.6-1.4-2.2-2.6-0.3-4.2
		c5.5,2.6,12.8,15,12,20.5c-0.1,0.8-0.7,1-1.5,0.8c-0.3,0-0.6-0.1-0.8-0.1C903.3,272.6,902.4,270.6,900.4,269.5z"/>
	<path class="st277" d="M925.3,287.5c5.3-1.3,10.6-2.7,16-4c1.8-0.8,3.7-1.3,5.6-0.5c1.3,1.5,0,1.9-1,2.3c-1.5,0.6-3.2,0.3-4.6,1.2
		c-5.6,1-11.1,3.1-17,2C924.6,288.2,924.9,287.9,925.3,287.5z"/>
	<path class="st278" d="M831.4,203.7c-0.7,0.9-1.4,1.9-2.1,2.8c-1.8,1.6-4,2.7-6,4c-0.3,0-0.6,0-0.9,0.1c-3-1.5-1.9-4.3-2-6.6
		c-0.1-2.8,0-5.6,0-8.4c1.3-2.2,0.9-4.7,1-7.1c0.1-2.5,0.9-4,3.4-1.7c1.2,1.1,2.9,1.5,4.3,2.2c1.7,1.5,1.9,3.5,1.5,5.5
		c-0.6,2.9-2.3,5.9,1.8,7.7C832.7,202.3,831.9,203.2,831.4,203.7z"/>
	<path class="st279" d="M829.4,189.6c-2.8,0.4-5.2-0.1-6.5-4.2c-1,4.2,0.5,7.8-2.5,10.2c0-7.3-0.4-14.7,1-22c3.9-1.5,3.4,3.6,6,4
		c2.3,3.5,3.1,7.8,6.2,10.9c0.6,0.6,0.4,1.5-0.1,2.3C831.7,191.8,830.5,190.8,829.4,189.6z"/>
	<path class="st280" d="M846.5,182.5c-2.5-5.8-6.6-10.6-9.8-15.9c-0.9-1.6-2.1-2.9-2.4-4.8c2.7-1.3,3.4,1.1,4.5,2.5
		c2.6,3.7,4.9,7.5,8,10.8c0.7,0.7,1.2,1.5,1.6,2.4c3.5,5.6,6.7,11.3,10.6,16.6c1.3,1.7,2.3,3.6,3,5.7c0.2,1,0.2,1.9-0.4,2.8
		c-2.3,0.9-2.7-1.3-3.8-2.4c-1.7-3.2-4.3-5.9-6.4-8.9C850.2,188.1,847,186,846.5,182.5z"/>
	<path class="st281" d="M827.5,164.7c1.4-0.2,3.6,2.2,4-1.2c5.3,6.2,9,13.6,13.8,20.2c0.4,0.3,0.6,0.7,0.6,1.2
		c-0.1,0.5-0.4,0.8-0.9,1c-4.3-1.1-5.7-5.8-9.5-7.6c-3.6-3.6-5.4-8.5-8.1-12.8C827.4,165.3,827.4,165,827.5,164.7z"/>
	<path class="st282" d="M846.4,175.6c-4-2.2-5.4-6.6-8.1-10c-1.2-1.5-1.5-3.7-4-3.9c-0.7-0.5-1-1.1-1-1.9c-0.5-1.7-2.2-3.1-0.9-5.1
		c2-0.1,2-1.5,2-3c1.3,2.3,2.7,4.6,4,7c4.6,3.6,5.5,9.6,9.3,13.8C848.6,173.5,847.9,175,846.4,175.6z"/>
	<path class="st283" d="M845.3,184.7c0-0.3,0-0.7-0.1-1c0.4-0.4,0.8-0.8,1.2-1.2c2.3,2.5,4.4,5.1,5.9,8.1c-0.6,0.6-0.2,1.9-1.3,2.3
		C847.1,191.6,846.4,188,845.3,184.7z"/>
	<path class="st284" d="M832.4,154.7c0.3,1.7,0.6,3.4,0.9,5.1c-2.4-1.7-3.2-4.4-4-7C830.8,152.6,831.8,153.3,832.4,154.7z"/>
	<path class="st284" d="M826.2,147.7c2.3,0.9,2.8,2.9,3.1,5C827.5,151.6,826.8,149.7,826.2,147.7z"/>
	<path class="st285" d="M827.4,165.6c4.2,3.1,4.9,8.8,8.9,12.1c3.2,4.9,6.5,9.7,9.3,14.7c0.1,0.5-0.1,0.9-0.4,1.3
		c-0.6,0.6-1.2,1.3-1.9,1.9c-1.3,0.9-2.7,1-3.3-0.5c-1.3-3.3-4.5-5.2-5.7-8.5c1.4-3.4,0.4-6.3-1.5-9.2
		C830.5,173.7,827.8,170.1,827.4,165.6z"/>
	<path class="st286" d="M834.4,186.6c3,1.8,5.3,4.4,6.4,7.7c0.6,1.7,1.6,1.1,2.6,1.3c-2,2.9-4.3,5.4-8,6c-0.2-2.8,1.3-3.9,3.3-4
		c-4.7-1.2-6-2.8-5.3-6.9c-1.8-4.4-5.3-8-6-13c2.1,1.6,3.3,3.9,4,6.3C831.8,185.6,832.7,186.5,834.4,186.6z"/>
	<path class="st287" d="M967.2,289.5c0.3-0.3,0.7-0.7,1-1c2.5-0.1,2.6,2.4,3.9,3.7c0.8,1.7,2.6,3.1,1.2,5.3c0,2-2.2,3.3-1.1,6.1
		c-3-1.6-3.9-3.7-4.3-6.6c-1.5,2.4,0.5,5.8-2,6.1c-4.7,0.6-9.9,1.8-14.3-1.3c-2.5-1.7-4.9-2.2-7.4-0.3c-2.6-1.2-6.6-1-5.7-5.7
		c1.4-1.3,3.2-2.5,4.9-2.2c5.9,1.1,11.4-1.8,17.2-1.4C963.2,292.3,965.4,291.4,967.2,289.5z"/>
	<path class="st288" d="M973.2,297.4c-0.7-1.6-1.4-3.3-2.1-4.9c0.2-2.4,1.6-0.9,2.5-0.8c2.5,2.1,3.6,5,5.3,7.6
		c4.9,10.1,11.5,19.2,16.1,29.4c0.3,0.9,0.7,1.9,0.1,2.8c-0.7,0.4-1.4,0.4-2-0.2c-1-1.4-1.9-2.7-2.9-4.1c0.1-1.7-1.4-2.6-2.1-3.9
		c-0.6-1-1.5-1.9-1.9-3.1C982,312.7,977.6,305.1,973.2,297.4z"/>
	<path class="st289" d="M938.3,296.5c1.1,2.7,4.9,2.3,6,5c0.4,1.4,4.2,1.5,1.8,3.9c-1.8,1.9-3.7,0.4-4.4-1.2
		c-1.3-2.9-5.1-2.1-6.4-4.7c-1.3-0.7-2.7-1.3-4-2C933.1,294.3,934.5,294.1,938.3,296.5z"/>
	<path class="st290" d="M975.2,325.5c0.1-1.6-0.1-3.8,2.2-3.5c1.7,0.2,3.4,1.9,3.5,3.7c0.1,2.7-2.3,1.5-3.7,1.8
		C976.5,326.8,975.9,326.1,975.2,325.5z"/>
	<path class="st239" d="M986.3,320.3c1.1,0.7,1.9,1.7,1.9,3.1c-1-0.7-2-1.3-3-2C985.6,321.1,985.9,320.7,986.3,320.3z"/>
	<path class="st291" d="M938.3,296.5c-2.4-0.2-5.1-2.6-7,1c-1.1-0.9-3.2-1.6-0.3-2.9c6.9-3.1,14.2-5,21.7-4.7
		c4.9,0.2,9.7-0.3,14.6-0.3c0.4,2.9-0.7,4.1-3.9,4.1c-4-0.1-8.2-1-11.9,1.7c-0.7,0.5-2.4,0.4-3.3-0.1
		C944.5,293.3,941.5,295.8,938.3,296.5z"/>
	<path class="st292" d="M941.3,286.5c1.3-2.3,4.3-1.3,6-3c3.6-2.5,7.8-3.8,12-5c0.3,0,0.7,0,1,0c0.8,0.3,1.7,0.4,2.2,1.4
		c-0.1,0.2-0.1,0.4-0.2,0.6C956.8,288.1,948.6,285.6,941.3,286.5z"/>
	<path class="st293" d="M973.3,292.5c-0.7-0.1-1.4-1.1-2.1,0c-1-1.3-2-2.7-2.9-4c-1.3-2.3-2.6-4.7-4-7c-0.9-0.5-1.2-1.4-1.6-2.3
		c0-1.1,0.3-1.9,1.6-1.9C968.9,281.4,972.9,285.9,973.3,292.5z"/>
	<path class="st292" d="M963.3,279.5c0.3,0.7,0.7,1.3,1,2c-0.2,0.7-0.1,1.9-1.2,1.5c-1.1-0.3-0.7-1.6-0.8-2.4c0,0,0,0,0,0
		C962.5,280.1,962.6,279.5,963.3,279.5z"/>
	<path class="st235" d="M973.8,221.8c0.9,0.1,1.3,0.7,1.3,1.7c0,1.1-0.3,2-1.6,1.8c-1-0.1-1.2-1-1.2-1.8
		C972.3,222.5,972.7,221.9,973.8,221.8z"/>
	<path class="st294" d="M930.3,240.6c11.7,1,22.8,7,35,5c2.4,0.2,4.2-3.9,7-1c-0.5,0.7-1.5,0.3-2,1c-1,1.4-2,2.7-3,4.1
		c-2.1,1.2-4.3,1.8-6.7,2c-3.9,0.7-7.7,2.2-11.8,2.3c-1.6-0.1-3.3-0.2-4.9,0.2c-0.5,0.1-0.9,0.1-1.4,0.1c-3.3-3.5-8.2-4.4-11.9-7.1
		c-1.5-1.1-3.2-2.3-3.6-4.5C927.6,241.2,928.4,240.1,930.3,240.6z"/>
	<path class="st262" d="M970.2,245.6c0.4-0.8,1.2-1,2-1c1,0,2,0,3,0C974.1,247.6,971.7,244.5,970.2,245.6z"/>
	<path class="st235" d="M992.2,234.6c1.1,0.8,1.7,1.8,1.1,3.5C991.6,237.1,992.4,235.7,992.2,234.6L992.2,234.6z"/>
	<path class="st295" d="M993.1,331.5c0.6,0,1.3,0,1.9,0c2.2,0.5,3,2,2.9,4.1c0.1,0.6,0.2,1.2,0.2,1.8c0,1.1-0.1,2.2-0.1,3.2
		C996.4,337.6,994,335,993.1,331.5z"/>
	<path class="st296" d="M880.3,210.6c-0.4-5.6-5.7-9.3-6-15c3.2-2.5,4.5,0.1,5.1,2.4c1.1,4,4.2,4.7,6.7,3.3c2.9-1.8,4.8-0.2,7.2,0.4
		c-0.2,3.6,2.9,6.6,2.2,10.4c-0.2,1.1,1.1,1.5,2.2,1.5c5.6-0.1,10.5,2.3,15.6,4c0.7,4.3-2.5,7.9-2.1,12.1c-1,2.4-0.1,5.1-1.3,7.5
		c-1.9,1.9-3.4,0.7-3.5-1.1c-0.4-5.8-6.2-7.9-8.2-12.5c-0.5-1.1-1.7-2-3.2-0.8c-1.4,1.1-3.1,0.8-4.8,0.5
		C885.3,220.3,883.2,215.2,880.3,210.6z"/>
	<path class="st297" d="M910.3,229.6c-0.8-4.4,1.3-8.2,3-12c2.7,0,5.3,0,8,0c-0.6,5.3,3.3,3.8,6,4c0.3,3.3-1,6.6,3.3,9.2
		c2,1.2,1.2,6.5-0.3,9.8c-1,0.7-2,1.3-3,2c-5.4,0.7-10.6,1.5-15-3.6C909.6,235.8,910.8,232.7,910.3,229.6z"/>
	<path class="st296" d="M874.3,195.6c-4.8-1.1-6.1-5.3-8-9C871.3,187.6,871.5,192.8,874.3,195.6z"/>
	<path class="st298" d="M880.3,210.6c4.7,2.9,5.7,8.8,10,12c5,3,5.9,8.8,9.2,13c2.2,2.8,3.2,6.4,6.7,8.3c1.7,0.9,2.4,2.7,1.6,4.7
		c-0.6,0.9-1.3,1.4-2.4,1.5c-3.4-1.4-5-4.5-7-7.3c-2.7-3.9-5.3-7.9-7.9-11.8c-3.3-5.1-6.5-10.4-9.9-15.5c-1-1.4-2.5-2.8-1.3-4.9
		C879.6,210.6,880,210.6,880.3,210.6z"/>
	<path class="st299" d="M879.2,210.6c4.1,6.6,8.1,13.2,12.2,19.8c-1.5,3.4-3-0.2-4.5,0.1c-5-5.3-8.7-11.4-11.7-17.9
		c-0.1-1.6-0.2-3.1,0.6-4.6c0.2-0.2,0.4-0.3,0.6-0.5C877.4,208.5,878.3,209.6,879.2,210.6z"/>
	<path class="st299" d="M868.3,198.6c0.6-0.3,1.2-0.6,1.9-0.9c2.1,3.3,4.1,6.5,6.2,9.8c0,0-0.2,0.2-0.2,0.2
		c-0.7,0.8-0.5,2.4-2.1,2.5c-1.3-0.1-2.1-1-2.8-2c-0.9-3.4-4.9-5.4-4.3-9.5C867.5,198.3,867.9,198.3,868.3,198.6z"/>
	<path class="st300" d="M823.3,210.6c1.6-2,3.6-3.3,6-4c0,0.3,0,0.7,0,1C827.4,208.7,825.8,210.5,823.3,210.6z"/>
	<path class="st301" d="M867.3,198.6c1.6,3,3.3,6,4.9,9c-0.3,0.4-0.8,0.5-1.3,0.5c-4.7-2.5-6.5-7.7-9.9-11.4
		c-5.3-5.8-8.3-13.4-13.7-19.1c-0.3-0.7-0.7-1.3-1-2c0.9-1.4,1.2-3-0.2-4c-4.6-3.2-4.9-8.9-7.8-13c1-0.3,1.9-0.6,2.9-1
		c0.3,3,2.7,4.7,4.1,7c0.9,1.4,1.9,2.8,2.9,4.1c0.3,0.3,0.6,0.6,1,1c4.8,8.3,9.7,16.5,15.3,24.2
		C865.7,195.4,866.9,196.8,867.3,198.6z"/>
	<path class="st302" d="M867.3,198.6c-4.9-4.9-8.3-11-11.8-16.8c-2.3-3.9-6.2-7-6.3-12.1c3.6,2.2,4.6,6.3,6.9,9.5
		c4.4,6.2,8.2,12.9,12.2,19.5C868,198.6,867.7,198.6,867.3,198.6z"/>
	<path class="st302" d="M848.3,168.7c-2.2-0.4-2.6-2.2-2.9-4.1C847,165.6,848.3,166.6,848.3,168.7z"/>
	<path class="st303" d="M948.3,252.6c4.2,1.3,8.1-1.4,12.3-0.9c-5.1,1.7-10.2,3.3-15.3,5c-1.6,1.7-3.8,1.8-5.9,1.9
		c-0.2,0.1-0.3,0.3-0.1,0.5c-3.4,1-6.9,1-10.3,2c-3.1,1.3-6.2,0.8-9.2-0.4c-1.6-0.8-3.3-1.3-4.7-2.3c-0.9-0.8-2.1-1.3-1.7-2.9
		c1.3-1.7,3.4-2.4,4.7-0.9c2.3,2.5,5,1.9,7.7,1.8c5.7,0.8,11.2-0.3,16.7-1.6c1.2-0.3,0.3-1.1,0.8-1.3l0,0
		C944.7,252.2,946.8,254,948.3,252.6z"/>
	<path class="st304" d="M949.2,262.5c2.7,0,5.3,0,8,0c0.3,1.8-1,2.6-2.2,3.5c-6,1.8-12.2,1-18.3,0.6c-4.4-0.3-8.9-0.6-13.4-0.8
		c-2.2-0.1-4.4-0.4-5.3-2.8c0.2-0.6,0.6-1,1.2-1.3c3.3-0.1,6.8-0.9,9.9,0.8c3.7,0,7.3,0,11,0C943.2,263.7,946.2,263.7,949.2,262.5z"
		/>
	<path class="st305" d="M919.3,259.3c3,1.7,6.4,0.7,9.6,1.2c1.8-0.5,3.6-0.8,5.4,0.2c-1.5,1.5-3.4,0.7-5.1,1c-3,0.7-6.1,0.5-9.1,0.1
		c-0.5-0.2-0.9-0.5-1.2-1C918.9,260.2,919,259.7,919.3,259.3z"/>
	<path class="st306" d="M934.4,260.6c-1.8-0.1-3.6-0.1-5.4-0.2c3.4-0.8,6.7-2.1,10.3-1.4C938.2,261.4,936,260.3,934.4,260.6z"/>
	<path class="st307" d="M939.3,258.6c1.7-1.5,3.9-1.4,5.9-1.9c0.5,0.8,1.2,0.9,2.1,1C944.8,258.8,942.2,260.3,939.3,258.6z"/>
	<path class="st308" d="M919.3,260.5c3.2,1.8,6.7,0.7,10,1.1c0,0.3,0,0.6,0,0.9c-3.3,0-6.6,0-10,0c-0.3-0.2-0.5-0.6-0.6-1
		C918.8,261.2,919,260.8,919.3,260.5z"/>
	<path class="st309" d="M955.2,264.5c0.7-0.7,1.3-1.3,2-2c3.4,0.1,6.6-1.6,10-1c-0.1,2.6-1.9,3.1-4,3
		C960.6,265.7,957.9,266.7,955.2,264.5z"/>
	<path class="st310" d="M994.2,264.5c1.1-2.3,3.2-2.4,5.1-1.7c2.1,0.7,2.9,2.5,2.9,4.7c-0.1,2.3,0,4.7,0,7c0.3,2,0.7,4,1,6
		c-0.1,5.8,1.2,11.9-3,17c-2-2.6-0.6-5.6-1-8.5c-0.3-1.9,0.6-3.9-0.9-5.6c-2.4-4.4-2.1-9.6-4.3-14
		C994.1,267.8,995.5,266.3,994.2,264.5z"/>
	<path class="st311" d="M1000.2,297.5c2.7-5.4,2.2-11.3,3-17c0.9,0.7,1.1,1.7,1,2.7c-1.4,8.3,0.9,16.6-0.7,25
		c-0.7,3.7-2,1.3-3.2,1.3C1000.2,305.5,1000.2,301.5,1000.2,297.5z"/>
	<path class="st312" d="M994.1,268.6c3.5,4.4,3.8,9.7,4.1,14.9c0,12.2-0.1,24.5,0,36.7c0,4.1,1.5,8.1,0.9,12.3c-4,0.8-2.8-3-4.1-4.7
		c-1-3.3-2.6-6.4-3.1-9.9c-0.4-2.6-1.2-5,1.7-6.4c1.2-0.6,0.4-1.3,0.4-2c-0.4-7.3-0.3-14.6,1.3-21.8c0.4-1.9-0.2-3.6-0.6-5.4
		c0.2-3.9-0.2-7.8-1.9-11.4c-0.1-0.5-0.1-0.9-0.1-1.4C992.9,268.9,993.2,268.3,994.1,268.6z"/>
	<path class="st313" d="M996.2,327.5c0.7,1.8,0.4,4.3,3,5c0,0.6,0.1,1.2,0.1,1.9c-0.4,0.4-0.8,0.8-1.2,1.2c-1-1.4-2-2.7-2.9-4.1
		c-0.2-0.7-0.4-1.4-0.7-2.1c0.2-0.7,0-1.6,0.8-2.2C995.6,327,995.9,327.1,996.2,327.5z"/>
	<path class="st314" d="M983.3,267.6c-0.3-0.1-0.7,0-0.9-0.2c-1.7-1.1-5-0.1-5.5-2.3c-0.5-2.6,3.5-1.5,4.3-3.6
		c2.3-0.9,4.5-2.2,7.1-1.8c1.1,1.3,0.9,2.6-0.1,3.8c0.7,1.2,1.1,2.5,1,3.9C987.3,268.4,985.3,268.7,983.3,267.6z"/>
	<path class="st315" d="M983.3,267.6c2-0.1,4-0.1,5.9-0.2c0.3,0.1,0.7,0.1,1,0c1.7-0.2,2.3,0.7,2.4,2.2c-0.8,2.4-2.9,2.2-4.8,2.5
		c-1.9-0.5-3.7-1.3-5.7-1c-1.1-0.2-2.2-0.5-2.9-1.5C980.6,268.9,981.9,268.2,983.3,267.6z"/>
	<path class="st316" d="M992.4,269.3c-0.7-0.6-1.4-1.3-2.1-1.9c0.3-2.4,2.2-2.5,4-2.9c3.1,1.4,3.5,2.8-0.1,4.1
		c-0.3,0.3-0.7,0.5-1,0.8C992.8,270.1,992.6,270.1,992.4,269.3z"/>
	<path class="st317" d="M990.2,259.5c1.6-0.7,3.3-1.4,4.9-2.2c0.5,0.3,1.4,0.8,1.4,0.9c-0.5,1.5-1.7,2.2-3.3,2.3
		C992.3,259.9,990.9,260.7,990.2,259.5z"/>
	<path class="st318" d="M990.2,259.5c1.1,0.1,2.3-0.2,3,1c-0.7,0.7-1.3,1.3-2,2c-3.2,0.9-2.5-1-2-2.8
		C989.5,259.6,989.8,259.5,990.2,259.5z"/>
	<path class="st310" d="M989.2,259.8c-0.1,1.5,0,2.8,2,2.8c-0.7,1.2-1.9,1-3,1c0-1.3,0-2.5,0.1-3.8
		C988.6,259.7,988.9,259.7,989.2,259.8z"/>
	<path class="st319" d="M955.2,264.5c2.7,0,5.3,0,8,0c0,0.3,0,0.7,0,1c-4.8,1.9-9.8,3.3-14.9,4c-1,0-2,0-3,0
		c-0.5-0.5,0.2-1.3,0.2-1.3c-7.2,1.8-14.2-0.4-21.3-0.6c-1.9-0.5-3.9-0.8-5.8-1.6c-1.6-0.7-3.6-1.2-3.2-3.7c1.1-0.7,2.1-0.7,3,0.2
		c0.9,1.1,2.1,1.8,3.4,1.8c10.7,0,21.3,1.9,31.9,1C954.1,265.4,954.7,264.9,955.2,264.5z"/>
	<path class="st320" d="M864.3,214.6c-4.3,1.8,0.4,4.8-1,7c-3-2.9-4.7-6.6-7-10c-2-3.8-3.6-8-7-11c0.2-2-2-3-2-4.9
		c0.1-2.9,0.5-3,4.6-0.9c0.4,0.3,0.6,0.7,0.8,1.1c0.4,4,2.8,6.6,6,8.7c0.4,0.3,0.7,0.6,1.1,0.9C861.1,208.7,864.9,210.5,864.3,214.6
		z"/>
	<path class="st321" d="M833.4,190.6c0,4.1,3.1,5.2,6.1,6.3c-1.4,1.5-2.7,3.1-4.1,4.6c-1.4,0.6-2.2,2.1-4,2.1
		c0.9-4.4-2.4-0.5-3.2-1.7c-0.5-4.2,2.3-8.1,1.2-12.4C830.7,190,832,190.3,833.4,190.6z"/>
	<path class="st322" d="M852.4,195.6c-1.7,1-3.5-3.2-5.1,0.1c-0.7-0.7-1.4-1.3-2.1-2c0-0.4,0.1-0.7,0.1-1.1c-0.2-2.3-2.1-4.6,0-7
		c0-0.3,0-0.6,0-1c2.4,2.4,3.1,6,6,8C852.4,193.4,852.9,194.3,852.4,195.6z"/>
	<path class="st323" d="M864.3,214.6c-1-3.4-4.6-5.3-5.1-9c0.4-2.1-2.9-3.7-0.9-6.1c1,1,2,2,3,3c5.5,4.7,8.7,11.2,12.9,16.9
		c3.2,4.4,5.8,9.3,8.7,13.9c0.6,1.3,1.3,2.6,0.6,4.1c-3.6,1.3-2.9-3.4-5.4-3.7c-0.3,0-0.6-0.1-0.9-0.1c0,0,0,0,0,0
		c-4.2-5-8.3-10.1-10.9-16.1C866.3,216.1,865.5,215.2,864.3,214.6z"/>
	<path class="st273" d="M866.4,217.5c4.5,4.7,7.5,10.6,10.9,16.1c-3.9,1.4-4.6-3.4-5.4-4.5C869.4,225.9,865.5,222.6,866.4,217.5z"/>
	<path class="st324" d="M979.2,269.5c1.1,0.1,2.3-0.2,3,1c-2.9,3.3-7.6,2.8-11,5.1c-1.3,0.4-2.6,1.1-3.9,1.3c-1.7-0.2-2.8-1.3-4-2.3
		c0,0-0.1-0.2-0.1-0.2C968.3,272,973.8,271.1,979.2,269.5z"/>
	<path class="st325" d="M883.3,237.6c-0.3-1.3-0.7-2.7-1-4c3-0.5,3.9,2,5.3,3.7c4.5,5.5,7.2,12.3,13.3,16.4c3.3,2.2,5,5.8,7.3,8.8
		c1.4,2.8,3.1,5.3,4.5,8.1c1,2.1,2.5,4.4,0.7,6.9c-3.1,0.2-5.7-0.9-8-3c1.5-3-0.4-6.2-1.5-8.1c-2.7-4.5-4.6-10-9.5-13
		c-1.3,0.4-1.9-0.5-2.3-1.3c-2.5-4.3-5.2-8.5-8.3-12.4C883.2,239.2,882.9,238.4,883.3,237.6z"/>
	<path class="st326" d="M929.3,273.5c-2.1,1.7-4.5,2.1-7,2c-6.4-4.1-10.8-10-15.2-16c2.8-2.9,4.3,0.1,6.2,1.4
		c1.3,2.9,3.6,4.6,6.6,5.4c3.1,0.9,5.5,2.9,8.3,4.2C929.3,271.1,930.4,271.9,929.3,273.5z"/>
	<path class="st327" d="M907.5,259.2c4.9,5.4,9.9,10.9,14.8,16.3c-1.9,0.9-3.7,2.3-6,2c-2-5.6-6.2-9.8-9-14.9c-3-2.1-3.8-6.1-7-8
		c0.3-0.4,0.7-0.6,1.1-0.7c0.7-0.1,1.4,0,2,0.2C905.2,255.5,907.2,256.6,907.5,259.2z"/>
	<path class="st328" d="M929.3,273.5c-2.6-3.5-6.8-4.6-10.3-6.6c-2.6-1.5-5.3-2.6-6.6-5.5c1.4-0.9,2.3-0.3,3,1.1
		c2.1,3.4,6.2,2.2,9,4c3.5,0.8,6.9,1.8,10.5,2c1.9,0.1,3.7,0.3,3.5,2.9C935.3,272.2,932.3,272.9,929.3,273.5z"/>
	<path class="st329" d="M938.2,271.5c-0.8-1.8-2.3-2.1-4-2c-3.7,0.4-6.9-1.2-9.9-3c8.4-0.1,16.7,2.5,25.2,0c-0.4,3-3.5,1.2-4.2,3
		c-0.7,0-1.3,0-2,0c-1.3,0.7-2.9,0.9-4,2C938.9,271.5,938.6,271.5,938.2,271.5z"/>
	<path class="st330" d="M907.3,262.6c3.9,3.5,6.4,8.1,9.5,12.2c0.7,1-0.2,1.8-0.5,2.7c-1,0-2,0-3,0.1
		C913.4,271.7,908.4,267.9,907.3,262.6z"/>
	<path class="st328" d="M939.3,271.5c0.8-1.8,2.3-2.1,4-2C942.2,270.8,940.8,271.4,939.3,271.5z"/>
	<path class="st331" d="M963.3,274.6c1.9-0.6,2.8,0.8,3.9,1.9c1.9,0.6,2.5,2.5,3.6,3.9c0.9,1.8,2,3.6,3.2,5.3
		c1.6,4.3,5.3,7.7,5.9,12.5c-0.3,1-0.6,2.1-2.1,1.6c-1.5-2.4-3.1-4.8-4.6-7.2c-2.1-5.6-5.7-10.1-9.1-14.9c-0.6-0.5-1.5-1-1-2
		C963.2,275.3,963.2,274.9,963.3,274.6z"/>
	<path class="st332" d="M959.2,278.5c-3.8,2.2-7.8,3.8-12,5c-2,0-4,0-6,0C947,280.8,952.9,279,959.2,278.5z"/>
	<path class="st332" d="M963.2,275.6c0.3,0.7,0.7,1.4,1,2c-0.8,0.4-0.9,1.1-0.9,1.9c-0.3,0.3-0.7,0.7-1,1c-0.7-0.7-1.3-1.3-2-2
		C961.9,278.3,962.1,276.5,963.2,275.6z"/>
	<path class="st333" d="M883.3,237.6c4.4,4.8,7.5,10.6,11,16c0.4,2.6,2.4,4.2,3.6,6.3c0.6,1.1,1.7,2.4-0.8,2.7c-2.2-1-2-3.7-3.9-5
		c-0.3-0.4-0.6-0.7-0.9-1.1c-2.5-6.5-7.4-11.5-11-17.4c-1.1-1.8-2.8-3.3-3.1-5.5c0.3-0.4-0.1-2,1.2-0.8
		C880.9,234.3,881.3,236.6,883.3,237.6z"/>
	<path class="st334" d="M901.4,254.5c-0.4,0-0.7,0-1.1,0.1c-6.7-3.9-9.2-11.3-13.9-16.9c-1.3-1.5-2.3-3.2-4.1-4.1
		c-7-10.4-13.9-20.7-20.9-31.1c0-1,0-1.9,0-2.9c0.8-0.6,1.7-0.7,2.2,0.1c2.5,3.9,6,7.1,8.3,11.1c4,7.2,9,13.8,14.2,20.2
		c4.2,6.7,8.8,13.2,14.1,19.1C901.2,251.3,902.6,252.6,901.4,254.5z"/>
	<path class="st335" d="M885.3,231.6c-5.7-5.8-10.2-12.4-14.1-19.5c-2.6-4.8-6.2-8.7-9.9-12.5c-4.8-7.2-10.1-14.2-14-22
		c2.3-1.2,2.3,1,3,2.1c6.3,9.8,13.4,19.1,20.9,27.9c4.9,7.5,10.4,14.7,14.5,22.6C885.8,230.8,885.6,231.2,885.3,231.6z"/>
	<path class="st336" d="M858.4,199.5c0.1,2.1,1.6,3.5,2.4,5.3c0.9,2-0.8,0.9-1.5,0.8c-0.3,0-0.5-0.1-0.8-0.1c-2.5-3-5.1-6-6.1-10
		c0,0,0,0,0,0c-0.4-1-0.7-1.9-1.1-2.9c0.1-0.8,0.2-1.6,1.1-2.1C854.4,193.6,857.5,195.8,858.4,199.5z"/>
	<path class="st337" d="M845.3,185.7c-0.9,2.3,2.2,4.6,0,7c-4.4-4.1-7-9.4-9-15C839.3,180.3,842.3,183,845.3,185.7z"/>
	<path class="st338" d="M977.9,299.7c1-0.4,1.3-1.3,1.4-2.3c0.4-0.2,0.8-0.2,1.2,0c0.7,0.6,1.1,1.4,1.5,2.1c0.4,0.8,0.9,1.6,1.3,2.4
		c4.6,8.2,9.6,16.3,12,25.5c-0.3,0.7-0.5,1.3-0.8,2C988.9,319.5,983.4,309.6,977.9,299.7z"/>
	<path class="st339" d="M943.2,253.6c-0.1,0.7,2.5,1.2-0.1,2.1c-5.9,2-11.7,2.9-17.9,1.9c-1-0.4-2-1-2.1-1.9c-0.5-3.6-2.5-5.1-6-5.1
		c-0.8,0-2-0.5-2.1-1.2c-0.3-4.8-5.2-7.2-5.8-11.8c0.4-2.7-1-5.5,1-8c0.3,0.7,0.9,1.4,1,2.2c0.7,6.9,3.9,9.7,10.9,9.8
		c1.7,0,3.7-0.7,5.2,1C931.3,248.2,938.9,248.4,943.2,253.6z"/>
	<path class="st339" d="M948.3,252.6c0.1,0.7,1.1,1.9-0.9,1.6c-1.4-0.2-2.8-0.4-4.1-0.6C944.7,251.9,946.6,252.8,948.3,252.6z"/>
	<path class="st340" d="M909.3,237.6c2,3.4,6.8,5,6.2,10.2c-0.2,1.7,1.6,1.8,2.9,1.7c3.2-0.2,6-0.2,5.3,4.5c-0.2,1.1,1,2.4,1.6,3.5
		c-2.6,0.4-5.1,1-7.1-1.9c-1.2-1.8-3.3-0.2-5,0c-0.2-0.1-0.4-0.2-0.7-0.2c-2-2.1-4.6-3.7-5.3-6.8c-0.2-1,0-2.4-0.6-2.8
		c-7.2-5.2-10.4-13-14.2-20.5c-0.5-1.1-2.5-1-2.1-2.7c1.5-0.9,4.3,1.1,4.7-2.5c0.1-1.1,2.1,0,2.7,1c2.9,5.3,10.2,7.7,9.8,15.1
		C907.4,237.1,908.4,237.4,909.3,237.6z"/>
	<path class="st341" d="M919.3,260.5c0,0.3,0,0.7,0,1c-3.3,1.4-5.7-1-8.1-2.4c-2.9-1.6-5.2-4.1-8.1-5.7
		c-6.1-5.5-10.3-12.3-14.5-19.2c-0.6-1.5-1.9-2.9-1.1-4.7c1.6-0.7,2.4,2.4,4.1,0.8c4.9,6.2,8,13.7,14,19.1c3.6,0.8,5.1,4.3,7.9,6.1
		c0,0,0,0,0,0c0.7,0.7,1.3,1.3,2,2C916,259.4,918.2,259.1,919.3,260.5L919.3,260.5z"/>
	<path class="st303" d="M913.2,255.6c-3.2-1.2-5.4-3.9-7.9-6.1c0.6-0.3,1.3-0.6,1.9-0.9C909.6,250.7,912,252.6,913.2,255.6z"/>
	<path class="st342" d="M887.3,229.6c0.6,1.3,1.3,2.7,1.9,4c-3.1,1.3-2.4-2.3-3.9-3c-4.4-6.7-8.9-13.4-13.2-20.2
		c-0.5-0.8-0.6-1.8-0.8-2.7c0.3,0,0.6,0,0.9,0c0.7,0.7,1.4,1.3,2.1,2c1.1,0.7,0.9,2.3,2,3C879.6,218.5,883,224.3,887.3,229.6z"/>
	<path class="st302" d="M876.3,212.6c-2-0.1-2-1.5-2-3c0.6-0.6,1.3-1.3,1.9-1.9C876.3,209.3,876.3,211,876.3,212.6z"/>
	<path class="st308" d="M919.3,260.5c-1.6-0.7-4.1-0.1-4.1-2.9c1.4,0.6,2.8,1.1,4.2,1.7C919.3,259.7,919.3,260.1,919.3,260.5z"/>
	<path class="st343" d="M903.3,252.6c3.8,1.1,6,4.4,9.1,6.4c2,1.3,4.2,2.7,6.8,2.6c0,0.3,0,0.7,0,1c-0.4,0-0.7,0-1.1,0.1
		c-1,0-2,0-3-0.1c-1-0.4-2-0.7-3-1.1c-1.5-1-2.6-2.9-4.9-2.2c-1.4-1.5-2.8-3.1-4.3-4.6C902.7,253.9,902.7,253.2,903.3,252.6z"/>
	<path class="st344" d="M981.1,299.6c-0.3-0.7-0.7-1.3-1-2c-0.7-2.8-1.5-5.7-1.8-8.6c-0.3-2.9,0.8-4.7,4-3.4
		c1.9,0.8,2.7,0.1,4.2-1.1c2.3-2,5-4.5,8.2-1.1c0.1-0.6,0-1.3,0.5-1.8c1.5,2.1,1.9,4.2,1.3,6.9c-1.3,5.8-1.5,11.8-1.4,17.8
		c0,2.4,0.7,4.8-2,7.2c-2.3,2.1,0.5,6,1.1,9c0.4,1.7,1.9,3.1,1.9,5.1c-0.3,0-0.6-0.1-0.9-0.1c-3.5-9.1-9.2-17-13.1-25.9
		C981.1,301.3,983.7,299.1,981.1,299.6z"/>
	<path class="st345" d="M995.2,281.5c0,1,0,2,0,3.3c-1.4-2-6.8-2-7.7-0.1c-1.4,3.2-2.5,5.4-5.7,1.6c-0.3-0.4-1.9-0.3-2.3,0.1
		c-0.4,0.4-0.3,1.6-0.1,2.3c0.6,2.9,2.4,5.7,0.7,8.8c-0.3,0-0.6-0.1-0.9-0.1c-2-3.9-4.9-7.4-6.1-11.8c-0.5-1-0.9-1.9-0.7-3.1
		c0.7-2,2.3-2.8,4.2-3c6.7-0.9,13-2.3,16.6-9C995.8,273.9,997,277.4,995.2,281.5z"/>
	<path class="st346" d="M993.2,270.5c-1.5,8.3-8.2,9-14.6,9.9c-2,0.3-3.9,0.4-5.4,2c-3.2-1.4-0.6-3.5-0.3-5.1c0.3-2.5,2.6-3.3,4.9-3
		c3.6,0.4,6.7-0.5,9.5-2.9c1.5-1.3,3.9-0.4,5.2-2.2c0.3,0,0.5,0,0.8,0C993.2,269.8,993.2,270.2,993.2,270.5z"/>
	<path class="st347" d="M987.2,271.5c-0.7,4.3-4.1,4.4-7.2,4.3c-6.4-0.1-6.6-0.1-6.8,6.7c0,1,0,2.1-0.1,3.1c-1.8-1.2-2.5-3-3-5
		c0-2-1.4-4,0-6c4-1.3,8-2.7,12-4C984.1,269.5,986,269,987.2,271.5z"/>
	<path class="st322" d="M852.4,195.6c2.9,2.8,4.6,6.3,6.1,10C855.1,203.1,850.8,201.1,852.4,195.6z"/>
	<path class="st348" d="M970.2,274.5c0,2,2.3,4,0,6c-1.7-0.8-2.2-2.6-3-4.1C968.2,275.8,969.2,275.2,970.2,274.5z"/>
	<path class="st349" d="M903.3,252.6c0,0.7-0.1,1.4-0.1,2.1c-0.6,0-1.2-0.1-1.8-0.1c-1.1-3.7-4.3-5.8-6.5-8.6
		c-3.7-4.5-6.9-9.2-9.5-14.3c0-0.3,0-0.7,0-1c1.7,0.5,1.8,3.1,3.9,3C893.8,240,897,247.5,903.3,252.6z"/>
	<path class="st345" d="M981.1,299.6c1.7-0.1-0.1-3.4,1.7-2.4c2.8,1.5-0.3,2.9-0.7,4.4C981.8,300.9,981.5,300.2,981.1,299.6z"/>
</g>
<g id="minilogo" [@logo2Trigger]="isEstadoLogo2" (click)="clickEvent('logo2')"> 
	<path class="st350" d="M327.6,447.5c0.5-0.4,0.9-0.8,1.4-1.2c1.2-0.7,2.3-1.7,3.9-1.3c2.4,0.9,4.7,1.9,1.8,4.7
		c-0.9,0.9-1.1,1.5,0,2.3c0.9,0.6,2.5,0.7,2.1,2.3c-0.4,1.6-2,1.4-3.3,1.6c-0.5,0-0.9,0.2-1.3,0.3c2.1-0.1,4.3-1,6.5-1.2
		c0.7,0.3,1.2,0.8,1.4,1.6c-1,2.8-3.6,3.2-6,3.9c-3,0.3-5,2.2-7.2,3.9c-1.7,1.4-3.4,3.2-6,2.1c-1.8-3.4-2.6-7.1-3.8-10.7
		c0.1-1.5,0.8-2.6,1.8-3.6c0.3-0.3,0.6-0.7,0.9-1c1.3-0.8,3-1,4-2.2C325,448.1,326.5,448.2,327.6,447.5z"/>
	<path class="st351" d="M373.9,490.5c0,0.3,0,0.5-0.1,0.8c-1.1,0.7-1.5,3.6-3.7,1.2c0.3-12.6,0.2-25.2,0.2-37.8
		c0-3.3-0.1-6.7-2.4-9.4c-5.5-3.6-11.1-6.3-17.9-5.2c-2.9,0.8-5.6,2.2-8.4,3.2c-0.6,0.3-1.3,0.6-2,0.9c-1.7,0.1-3.7,2.2-4.9-0.8
		c0.3-0.3,0.6-0.7,1-1c3.5-2.2,7.1-4.2,11.2-5l-0.1,0c5-2,10.1-2.2,15.1,0l-0.1,0c5.7,1.8,10.9,4.2,12.2,11
		c0.6,11.9,0.1,23.8,0.3,35.7C374.4,486.1,374.5,488.3,373.9,490.5z"/>
	<path class="st352" d="M334.8,443.4c1.7,1.8,3.4-0.7,5.1,0c0.6,0.1,0.9,0.8,1.6,0.6c-0.5-0.4-0.9-0.9-0.6-1.6c3-0.9,5.7-2.9,9-3
		c1.6-0.3,1.1,0.6,0.7,1.2c-0.7,0.9-1.3,1.9-1.6,3c-0.6,1.3-0.8,2.7-0.3,4.1c0.1,0.6,0.1,1.3-0.1,1.9c-1.2,2.5-4.7,2.1-5.9,4.6
		c-1.1,0.9-2.2,1.7-3.7,1.2c-2.5,1.4-5.6,0.7-8,2.4c-0.9,0.6-2.1,0.4-2.4-0.8c-0.5-1.9,1.2-1.5,2.2-1.6c1.7-0.2,3.7,0.5,5.5-1.1
		c-0.6-1.3-2-0.6-2.8-1.1c-2-1.1-2.3-3.5-0.5-4c6-1.8-0.2-2.7-0.1-4l-0.4-0.3l0.2-0.4C333.4,444.1,334.1,443.7,334.8,443.4z"/>
	<path class="st353" d="M294.2,546.2c-1.5-1.6-1.1-3.7-1.2-5.6c0-14.1-0.1-28.1-0.1-42.2c-0.7-3.1-0.7-6.1,0-9.2
		c-0.4-3.7-1.5-7.6,1-11c2.2,2.5,1.1,5.5,1.4,8.3c0.2,18.2-0.4,36.3,0.3,54.5c0.5,1.7,1.3,3.3,0.7,5.1
		C295.7,546.8,295,546.8,294.2,546.2z"/>
	<path class="st354" d="M370.9,493.3c1-0.6,1.4-2.2,3-2c0,0.4-0.1,0.8-0.1,1.1c-0.6,2.3-1.9,4.3-1.9,6.8c-0.8,1.8-1.3,3.8-2.9,5.1
		c-1.1,1.7-0.9,3.9-2.7,5.2c-1.6,1-2.1,2.9-3.2,4.3c-1,1.5-1.6,3.7-4.2,2.7c-0.7-2.7,1.7-4.4,2.3-6.6c2.8-4.5,5.7-8.9,7.1-14.1
		C368.7,494.6,369,493,370.9,493.3z"/>
	<path class="st355" d="M374,485.3c0-9.9,0-19.8-0.1-29.7c0-2.8-0.4-5.6-0.6-8.4c0.1-0.5,0.3-0.8,0.8-1.1c2.3,3,1.6,7,1.3,9.9
		c-0.9,9.8,0.3,19.5-0.6,29.2C374.5,485.7,374.2,485.7,374,485.3z"/>
	<path class="st356" d="M374,446.2c-0.3,0.4-0.5,0.7-0.8,1.1c-1.9-5.4-7.3-7-11.3-9.9C369,437.4,373,440.3,374,446.2z"/>
	<path class="st357" d="M294,486.3c0-2.7,0-5.4,0-8.2c0.4-3.1,0.8-6.2,3.9-7.9c0.4-0.4,0.8-0.4,1.2-0.1c0.6,1.2,0,2.2-0.3,3.3
		c-0.4,0.8-0.7,1.6-1,2.5c-0.6,1.8-0.4,3.6-0.9,5.4c-0.6,1.6-0.4,3.4-1.5,4.9C294.9,486.6,294.5,486.6,294,486.3z"/>
	<path class="st358" d="M319,537.2c-3.5,5.6-8.9,7.8-15,9c-0.1-0.2-0.1-0.4-0.2-0.6c2.6-3.6,7.5-3.9,10.3-7.2
		c1.1-0.9,2.2-1.7,3.6-1.8C318.2,536.7,318.7,536.9,319,537.2z"/>
	<path class="st359" d="M362,437.4c-5-0.9-10.1-1.1-15.1,0C351.2,434.5,358.9,434.5,362,437.4z"/>
	<path class="st360" d="M347,437.3c-3.6,2-7,4.3-11.2,5C338.5,438.4,342.4,437,347,437.3z"/>
	<path class="st361" d="M320.3,451.4c-0.1,0.2-0.3,0.5-0.4,0.8c-3,2-5.2,5.4-9.3,5.5c-0.2-0.1-0.4-0.3-0.6-0.4
		c2.1-3.2,4.8-5.6,8.9-6.1C319.4,450.8,319.9,450.9,320.3,451.4z"/>
	<path class="st362" d="M359,516.5c2.1-0.1,2.6-2.1,3.9-3.2c0.4-0.4,0.9-0.4,1.3,0c-1.3,3-3.4,5.3-6.2,7c-0.3,0.3-0.7,0.4-1.1,0.2
		c-0.7-1.5,0.4-2.3,1.1-3.4C358.2,516.8,358.6,516.6,359,516.5z"/>
	<path class="st363" d="M298.9,470.3c-0.4,0-0.7,0-1.1,0c1.7-3.5,3.8-6.8,7.1-9.1c0.3,0.3,0.6,0.7,0.6,1.2c0,1-0.5,1.7-1,2.5
		C302.7,466.7,301.9,469.6,298.9,470.3z"/>
	<path class="st364" d="M352,525.4c-1.6,2.6-3.6,4.6-7.1,3.8c0-0.2-0.1-0.5-0.1-0.7c1.9-2.4,4-4.4,7.3-4.4
		C352.4,524.6,352.3,525,352,525.4z"/>
	<path class="st365" d="M352,525.4c0-0.4,0-0.7,0-1.1c-0.2-2.7,1.7-3.7,3.7-4.6c0.5,0,0.9,0.2,1.3,0.5c0.4,0,0.7,0,1.1,0
		C356.3,522.3,355,524.9,352,525.4z"/>
	<path class="st366" d="M294.2,546.2c0.6,0,1.2,0,1.7,0c0.6-0.6,1.4-0.4,2.1-0.5c0.4,0,0.7,0.1,1.1,0.3c0.3,0.3,0.4,0.6,0.5,1
		c0,0.4-0.2,0.7-0.5,1C296.3,550.4,295.2,548.3,294.2,546.2z"/>
	<path class="st367" d="M371.9,499.2c-0.8-2.7,0.1-4.8,1.9-6.8C374.5,495.1,373.1,497.1,371.9,499.2z"/>
	<path class="st368" d="M365.8,509.2c1-1.6,2.1-3.3,3.1-4.9c-0.3,2.4,0.5,5.3-3,6.1C365.4,510,365.4,509.6,365.8,509.2z"/>
	<path class="st369" d="M344.9,529.2c-0.9,2.2-2.8,2.7-4.8,3.1c-0.8,0.5-1.5,0.5-2.3,0.1C339.2,529,341.7,528.2,344.9,529.2
		L344.9,529.2z"/>
	<path class="st370" d="M336,533.2c-3,1.7-6.1,1.5-9.1,0c1-1.2,2.4-1.2,3.7-1.3c0.5,0,1.1,0,1.6,0C333.6,532,335,532.1,336,533.2z"
		/>
	<path class="st371" d="M292.9,489.2c0,3.1,0,6.1,0,9.2C291.4,495.3,291.4,492.3,292.9,489.2z"/>
	<path class="st372" d="M305,462.4c0-0.4-0.1-0.8-0.1-1.2c1.4-1.6,2.3-3.9,5.1-3.8c0,0,0-0.1,0-0.1C310,460.6,308.2,462.1,305,462.4
		z"/>
	<path class="st373" d="M336,533.2c-1.4,0-2.8-0.1-4.2-0.1c1.5-3,3.6-2.3,5.9-1c0.8,0.1,1.6,0.1,2.4,0.2
		C339.1,534,337.4,533,336,533.2z"/>
	<path class="st374" d="M327.6,447.5c-0.9,1.1-2.2,1.6-3.6,1.9c-0.4,0.3-0.8,0.3-1.2-0.1C323.3,445.7,326,448,327.6,447.5z"/>
	<path class="st373" d="M331.1,533.1c-1.4,0-2.8,0.1-4.2,0.1c-0.3,0-0.5,0-0.8,0c-0.9,0.5-1.7,0.7-2.3-0.3
		C326.3,530.1,328.7,529.8,331.1,533.1z"/>
	<path class="st375" d="M374,485.3c0.3,0,0.5,0,0.8,0c-0.1,1.7,1,3.7-0.8,5.2C372.8,488.7,374.2,487,374,485.3z"/>
	<path class="st376" d="M323.8,533.1c0.8,0,1.5,0.1,2.3,0.1c-1.1,1.3-2.5,1.8-4.1,2.1c-0.3-0.4-0.5-0.8-0.5-1.3
		C322,532.9,322.7,532.5,323.8,533.1z"/>
	<path class="st377" d="M299,548c0-0.3,0-0.5,0-0.8c1.4-1.5,3-2.6,5-0.9c0,0,0,0,0,0C302.7,547.8,301,548.5,299,548z"/>
	<path class="st378" d="M365.8,509.2c0,0.4,0.1,0.8,0.1,1.2c-0.6,1-1.2,1.9-1.8,2.9c-0.4,0-0.9,0-1.3,0
		C363.6,511.7,364.2,510.1,365.8,509.2z"/>
	<path class="st379" d="M322.9,449.3c0.4,0,0.8,0.1,1.2,0.1c-1,1.1-2.3,1.9-3.8,2.1c-0.5-0.1-0.9-0.1-1.4-0.2
		C319.9,450,321.2,449.3,322.9,449.3z"/>
	<path class="st380" d="M322,534.3c0,0.3,0,0.7,0,1c-0.5,1.4-1.5,2-3,1.9c0,0-0.5,0-0.5,0l-0.5,0C318.1,534.6,319.7,533.9,322,534.3
		z"/>
	<path class="st381" d="M332.7,444.5c0.1,0.2,0.1,0.5,0.1,0.7c-1.3,0.3-2.4,1.4-3.8,1.1C329.6,444.4,331,444.2,332.7,444.5z"/>
	<path class="st382" d="M321,466.3c3.2-0.3,4.9-2.9,7.3-4.5c1.7-1.1,3.2-2.9,5.6-2.5c0.7,1.1,0,1.8-0.6,2.5c-5,3-9.2,6.7-12.2,11.8
		c-0.2,0.4-0.4,0.9-0.6,1.3c-2.8,4.2-4.7,8.8-6.6,13.4c-0.8,2.6-0.3,5.2-0.3,7.8c0,5.6,0,11.2,0.1,16.8c-0.2,2.9-0.1,5.8-0.1,8.7
		c0.1,1.4-0.1,2.8-0.5,4.2c-1.8,2.7-2,6-3.8,8.7c-0.5,0.2-1,0.3-1.6,0.1c-2.7-2.3-1.7-4.9-0.3-7.2c1.5-2.5,1.6-4.9,0.5-7.6
		c-1.2-2.8-1.5-5.9-0.9-8.8c1.4-7.3-0.6-14.5-0.3-21.8c-0.1-0.5-0.2-1-0.1-1.5c0.3-2.1,1.2-4.2,1.8-6.2c0.4-1.1,0.7-2.2,1.2-3.2
		c0.9-1.5,1.8-3.1,3.3-4.2c0.4-0.2,0.8-0.2,1.2-0.2c1.5,0.5,2-0.5,2.5-1.6c0.5-0.9,1.3-1.7,1.9-2.5c0.8-1,1.3-2.2,2.2-3.1
		C320.6,466.5,320.8,466.4,321,466.3z"/>
	<path class="st383" d="M319,470.3c-0.7,0.7-1.3,1.3-2,2c-0.8-0.4-1.2-1.1-1.4-1.9c0.3-1.3-0.8-1.9-1.3-2.8c0.9-3.2-1.3-5.2-3-7.5
		c0.9-3.5,3.2-5,6.7-4.8c1.7,3.5,3.3,7,3,11c0,0,0,0,0,0C319.8,467.4,318.8,468.5,319,470.3z"/>
	<path class="st384" d="M332.8,461c0.3-0.6,0.7-1.2,1-1.7c2.3-0.5,4.2-1.6,6-3c0.9-0.9,2.1-1.3,3.1-2c3-1.4,5.9-3,8.9-4.5
		c1.9-1,3.9-2.1,6.1-0.7c0.6,2.1-1.1,2.6-2.3,3.2c-7.6,4-14.9,8.4-22.3,12.6c-1.3,0.7-2.6,1.5-4.2,0.7
		C328.2,462.3,332.2,463.1,332.8,461z"/>
	<path class="st385" d="M317.9,455.3c-2.6,1-5.3,1.7-6,5c-1.6,1.5-3.5,2.6-4.8,4.4c-1,0.7-2,1.2-3.3,0.8c-0.3-0.3-0.3-0.5-0.3-0.8
		c0.1-0.3,0.2-0.4,0.3-0.4c0.4-0.6,0.7-1.2,1.1-1.9c1.8-1.5,3.9-2.9,5-5.1c3.8-0.7,6.1-4.5,9.9-5.1
		C319.2,453.2,318.6,454.3,317.9,455.3z"/>
	<path class="st383" d="M321.8,457.4c1.6,1.7,1.4,3,0.4,4.6C320.6,460.7,320.8,459.3,321.8,457.4z"/>
	<path class="st386" d="M342.8,455.3c-1,0.3-2,0.7-3,1c-0.3-0.3-0.5-0.6-0.8-1c1-0.7,2-1.3,3-2c0.6-0.6,1.3-0.6,2-0.3
		C344.6,454.3,343.6,454.8,342.8,455.3z"/>
	<path class="st387" d="M370.9,493.3c-2.7,5.8-4.1,12.3-9,17c-0.5,0.3-1,0.3-1.3-0.2c-0.7-1.9,0.6-3,1.5-4.4
		c2.3-3.5,3.7-7.2,3.6-11.5c1.8-13.4,0.5-26.8,0.8-40.2c0-1.6-0.8-2.8-1.8-4c-0.6-0.9-1.1-1.9-1.1-3c0.1-0.5,0.3-0.9,0.8-1.1
		c1.3-0.2,2.5-0.8,3.6-1.6c2.9,1.3,4.1,3.6,4,6.8c-0.1,11.4,0,22.9-0.1,34.3C371.8,488,371.2,490.7,370.9,493.3z"/>
	<path class="st388" d="M367.9,444.3c-0.6,1.2-0.2,4-3,1.9c-2.7-1.7-5.2-0.5-7.9,0.3c-3,0.9-5.6,3.5-9.1,2.8c0-0.7,0-1.3,0-2
		c-0.8-1.3,2.2-2.7,0-4c0.2-1.6,1.1-2.8,2-4c4.9-1.9,9.3,0,13.7,1.8C365.2,441.7,366.5,443.2,367.9,444.3z"/>
	<path class="st389" d="M341,442.3c0.7,0.4,1.4,0.8,2.1,1.2c1,0.5,1.8,1,1.5,2.2c-0.1,0.4-0.5,0.9-0.9,1.1c-1.3,0.6-1.6-0.7-2.2-1.4
		c-0.5-0.6-1-1.3-1.4-2C340.3,443,340.6,442.7,341,442.3z"/>
	<path class="st390" d="M347.9,449.3c4.1-2,8.2-3.8,12.6-5.2c2.5-0.8,3.6,0,4.4,2.1c-0.3,0-0.7,0.1-1,0.1c-1.6,1.6-3.8,1-5.7,1.8
		c-3.4,0.2-6.1,1.9-8.9,3.5c-1.6,0.9-3,2.6-5.2,1.9c-0.7,0-1.4-0.1-2.1-0.1C343.2,450.9,346.4,451.3,347.9,449.3z"/>
	<path class="st390" d="M347.9,443.3c1.2,0.1,2.5-0.3,3.6,0.7c-0.6,2-2.8,1.8-3.6,3.3C346.1,446,347.4,444.7,347.9,443.3z"/>
	<path class="st391" d="M298,546.2c-0.7,1.2-1.4,0.1-2.1,0.1c-0.3-1.3-0.6-2.7-1-4c-0.9-3-0.2-6.1-0.4-9.1c0.3-1.3,0.4-2.7,1-3.9
		c2.5-2.3,3.8-6.3,8.3-5.3c2.3,1.5,1.3,3.1,0,4.5c-1.3,1.5-1.8,3.5-2.9,5C298.1,537.3,298.7,541.8,298,546.2z"/>
	<path class="st392" d="M295,532.2c0,3.3,0,6.6-0.1,9.9c-1.5-1.5-0.9-3.4-0.9-5.2c-0.1-16.9,0-33.8,0-50.6c0.3,0,0.6,0,0.9,0
		c0.8,0.7,1.2,1.6,1.3,2.6c0.3,12,0.1,24.1,0.1,36.1C296.4,527.5,295.5,529.8,295,532.2z"/>
	<path class="st393" d="M361,510.3c0.3,0,0.6,0,1,0c-0.1,2.5-2.1,4.1-2.9,6.2c0,0.3-0.1,0.5-0.2,0.7c-0.9,0.7-1.7,1.4-2.8,1.6
		c-0.5,0-0.9-0.2-1.3-0.5c-0.2-0.4-0.4-0.8-0.3-1.3c0.1-0.7,0.5-1.3,1-1.8C357.4,513.7,358.1,510.9,361,510.3z"/>
	<path class="st394" d="M307,487.3c0,0.3,0,0.7,0.1,1c0.8,9.1,0.5,18.1,0.3,27.2c-0.1,2.6,2.1,5.6-0.8,7.9c-1.1-0.2-1.5-1.1-1.8-2.1
		c-0.7-7.6-0.2-15.3-0.3-22.9c0.3-2.4,0-4.9,0.5-7.7c-3.2,0.2-5.5-1.4-7.4-3.6c-1.4-1.7-2.4-3.5-1.6-5.8c0.3-2-0.6-4.2,1-6
		c2.8,2.5,5.4,5.4,8.8,7.3C307.6,483.7,308,485.4,307,487.3z"/>
	<path class="st395" d="M296,481.3c0.5,3.4,2.6,6.7,5.3,7.2c5.9,1.1,4.7,5,4.7,8.7c-6,1-6,0.9-10-7.1c-0.3-0.6-0.4-1.3-0.9-1.8
		c0-0.7-0.1-1.3-0.1-2C295.3,484.6,294.4,482.7,296,481.3z"/>
	<path class="st396" d="M307,487.3c0-1.9-0.6-3.1-2.6-4.2c-3.2-1.7-6.7-3.6-7.5-7.8c0-0.8,0.2-1.6,1-2c0.4-0.3,0.8-0.5,1.3-0.3
		c2.2,1.3,4.3,2.7,6.7,3.8c1.8,0.8,3,2.4,3.1,4.5C309,483.5,308.4,485.6,307,487.3z"/>
	<path class="st397" d="M299,473.3c-0.4,0-0.7,0-1.1,0c0.3-1,0.7-2,1-3c1.7-2,3.3-4.1,5-6.1c0,0.4,0.1,0.7,0.1,1.1
		c0.5,0.9,0.1,1.7-0.2,2.5c-0.4,0.8-0.9,1.5-1.4,2.2C301.2,471,301.4,473.5,299,473.3z"/>
	<path class="st398" d="M355,518.3c0.3,0,0.7,0,1,0c0.6,0.6,0.5,1.3-0.1,1.8c-1.3,1.4-2.6,2.8-3.9,4.2c-2.4,1.6-5,2.8-7,5
		c-2.7,0.3-4.9,1.8-7.2,2.8c-2,0.3-4.1-0.2-5.9,1c0,0-0.4,0-0.4,0s-0.4,0.1-0.4,0.1c-2.4-1-4.8-1.8-7.3,0c-0.6,0.4-1.2,0.8-1.8,1.1
		c-1.3,1-2.7,2-4,3c-1.1,0.6-2.2,1.3-3.2,1.9c-0.7,0.6-1.3,0.6-2,0c-0.2-1.2,0.4-2,1.1-2.7c5-4.7,10.7-6.8,17.9-6.8
		c7.2,0,13.4-4.3,19-8.8C352.2,519.9,353.1,518.4,355,518.3z"/>
	<path class="st399" d="M313,539.2c0.6,0,1.2,0,1.8,0c-2.8,3.6-7.7,4-10.8,7.1c-1.7,0.3-3.3,0.6-5,0.9c0-0.3,0-0.6-0.1-0.9
		c-0.4-0.8,0.1-1.3,0.7-1.8c2.3-1.2,4.7-2,7.1-3C308.9,541,310.4,538.6,313,539.2z"/>
	<path class="st400" d="M355.9,520.1c0-0.6,0.1-1.2,0.1-1.8c0.9-0.4,1.9-0.7,2.8-1.1c-0.6,1-1.3,2-1.9,3
		C356.6,520.2,356.2,520.2,355.9,520.1z"/>
	<path class="st401" d="M299.9,545.1c-0.3,0.4-0.7,0.8-1,1.1c-0.3,0-0.7,0-1-0.1c-2.6-7.4,1.1-13,5.2-18.5c0.8-1.1,1.8-2,0.8-3.4
		c-0.4-1.7-0.2-3,2-3c0,0.7,0.1,1.4,0.1,2.1c0.7,0.6,1,1.4,0.9,2.2C305.5,532.3,304.3,539.3,299.9,545.1z"/>
	<path class="st402" d="M312,526.2c0-1.3,0.1-2.5,0.1-3.8c0.7-3.1,0.7-6.2,0-9.3c0-5.9,0-11.7,0-17.6c1.1-2.6-0.4-5.6,0.9-8.2
		c1.9-4.6,3.1-9.5,6.9-13.1c1.2,1,1.2,2.4,0.9,3.8c-0.9,2.6-2,5-3.1,7.5c-2.6,7.6-1.3,15.5-1,23.2c0.2,4.6,0.8,9.3,0.4,13.9
		c-0.3,3.4-0.7,7.1-5.3,7.8C310.8,528.9,311.2,527.6,312,526.2z"/>
	<path class="st403" d="M320,478.3c0-1.4,0-2.7-0.1-4.1c0-0.3,0.1-0.6,0.1-0.8c2.8-2.9,4.7-6.7,8.9-8c10-4.6,19.3-10.6,28.9-16
		c0.4-0.3,0.9-0.4,1.4-0.3c1.7,0.9,3.8,0.9,5.2,2.4c0.6,1.2,0.6,2.4,0,3.6c-0.3,0.4-0.7,0.6-1.2,0.6c-2.7-2.1-5.3-1.7-8.2-0.1
		c-6.1,3.5-12.4,6.8-18.4,10.4c-6.1,3.2-10.8,8.1-16,12.5C320.4,478.4,320.2,478.3,320,478.3z"/>
	<path class="st404" d="M299.9,545.1c1.8-6,3.7-11.9,5.5-17.9c0.4-1.2,0.4-2.6,0.6-3.9c1.5-1.7,0.9-3.8,0.9-5.7
		c0.1-9.8,0.1-19.5,0.2-29.3c1,6.5,1.9,12.9,1.2,19.5c-0.2,2-0.3,4-0.3,5.9c-0.1,5.8,4.8,11.8-0.9,17.4c-0.6,0.6-0.2,2.3,0.9,3.1
		c0.5,0.5,0.8,1.1,0.8,1.8c0,2.2-1.4,4-1.8,6.1C304.8,543.6,302.5,544.8,299.9,545.1z"/>
	<path class="st405" d="M303,467.3c0.3-0.7,0.7-1.3,1-2c1-0.3,2-0.7,3-1c3.5-0.4,5.9,1.6,8,4c1.8,0,1.5,0.9,1,2c0.4,1.9-0.6,3-2,4
		l-0.5,0l-0.5,0C309.4,472.3,304.9,471.7,303,467.3z"/>
	<path class="st406" d="M303,467.3c3.4,1.4,5.5,5.3,9.8,5.1c0,0,0.1,1.2,0.2,1.9c-0.8,1.5-1.2,3.3-3,4c-3.6-2.2-8-3.7-8-9
		C302.3,468.6,302.7,468,303,467.3z"/>
	<path class="st407" d="M302,469.3c1.2,4.3,6,5.4,8,9c-0.1,1.1,0.2,2.3-1,3c-1.8-4.5-8-3.6-10-7.9
		C300.4,472.3,299.9,469.9,302,469.3z"/>
	<path class="st408" d="M312,526.2c0,1.3,0,2.7,0.1,4c1.7,1.2,3.7-2.1,5.2,0.4c-0.5,2.9-2.9,3.6-5,4.8c-1.3,0.2-3,1.5-3.3-1.2
		C309.3,531.3,309.4,528.3,312,526.2z"/>
	<path class="st409" d="M329,465.3c-3,2.7-6,5.3-8.9,8c2.8-5.6,7.1-9.7,12.8-12.3C332.6,463.4,329.5,463.2,329,465.3z"/>
	<path class="st350" d="M314,474.3c-0.1-1.7,1.4-2.6,2-4c0.8,0.4,1,1.2,1,2c-0.1,1.4,0.1,3.1-1.7,3.6
		C314.2,476.1,314.1,475,314,474.3z"/>
	<path class="st410" d="M312.1,513.1c0.9,3.1,1,6.2,0,9.3C312.1,519.3,312.1,516.2,312.1,513.1z"/>
	<path class="st350" d="M319,470.3c-2.1-2.7-0.3-3.5,2-4C320.8,467.9,320.3,469.3,319,470.3z"/>
	<path class="st410" d="M313,487.3c-0.1,2.7,0.1,5.5-0.9,8.2C312.2,492.8,311,489.9,313,487.3z"/>
	<path class="st410" d="M308.9,534.2c0.8,1.6,2,0.6,3.1,0.4c0.3,0.3,0.5,0.6,0.5,1c-0.1,0.2-0.1,0.4-0.2,0.6c-0.3,0.3-0.6,0.5-1,0.7
		c-0.8,0.2-1.6,0.2-2.4,0c-0.4-0.2-0.7-0.4-0.9-0.7c0-0.7,0-1.3,0-2C308.3,534.2,308.6,534.2,308.9,534.2z"/>
	<path class="st411" d="M315,468.3c-2.7-1.3-5.3-2.7-8-4c0.4-2.9,2.2-4.1,5-4C317.1,461.4,315.5,465.1,315,468.3z"/>
	<path class="st412" d="M359,449.4c-0.4,0-0.7-0.1-1.1-0.1c-5.8,0-9.6,5.2-15.1,5.9c0.4-0.6,0.8-1.2,1.3-1.8
		c4.8-1.5,8.4-5.8,13.6-6.2c1.1-0.6,2-0.5,2.5,0.7C360.3,448.9,360,449.5,359,449.4z"/>
	<path class="st413" d="M364.8,448.4c2,1.5,3.1,3.4,3.1,5.8c0,13.7,1.4,27.4-1.1,41c-1,1,0.2,3.2-2,3.6c-0.5,0-1-0.2-1.4-0.6
		c-1.1-2.2,0-4.1,0.7-6.1c0.3-0.8,0.7-1.5,1-2.2c0.6-11.6,0.3-23.3,0.2-34.9c-0.5-1.4-0.5-3.1-1.6-4.2c-0.8-0.7-1.4-1.4-0.9-2.6
		C363.6,447.7,364.2,447.8,364.8,448.4z"/>
	<path class="st414" d="M364.8,498.3c0.7-1,1.4-2,2.1-3.1c0.6,6-3.8,10.1-5.9,15.1c-1.7,1.9-2.7,4.5-5,5.9c-0.6-0.5-0.9-1.2-0.9-2
		C357.4,508.4,361.6,503.7,364.8,498.3z"/>
	<path class="st415" d="M364.8,448.4c-0.6,0-1.2,0-1.9,0.1c-1,0.8-1.9,0.8-2.9,0c-0.5-0.9-1.4-1.1-2.3-1.2c1.8-1.7,4-0.8,6.1-0.9
		C364.2,447.1,364.5,447.7,364.8,448.4z"/>
	<path class="st416" d="M306,521.2c-0.7,1-1.3,2-2,3c-4-0.4-4.5,4.7-8,5c-1-2.8-0.3-5.6-0.5-8.4c1.5-5.3,3.6-10.5,3.2-16.3
		c-0.3-4.1-3.5-7.1-3.1-11.3c-0.1-1.3-0.5-2.7,0.3-4c0.1,0.1,0.3,0.2,0.4,0.3c2.6,3.2,3.8,8.4,9.6,7.7
		C306,505.2,306,513.2,306,521.2z"/>
	<path class="st417" d="M296,520.2c0,3,0,6,0,9c0,1.1,0.2,2.3-1,3c0-14.6,0-29.3,0-43.9c0.3,0.3,0.6,0.7,1,1c0,1.3,0,2.7,0,4
		C297.8,502.3,298.8,511.3,296,520.2z"/>
	<path class="st418" d="M312,536.2c0-0.3,0-0.6,0-0.8c2.4-1.7,4.9-3.3,6.9-5.6c2.4-2.7,5.3-4,9.3-3.1c5.7,1.3,11.2-0.4,16.2-3.3
		c0.4-0.3,0.8-0.5,1.2-0.7c2.2-1,4.3-2.1,5.7-4.2c1-0.9,2.1-1.9,3.6-1.2c0,0.3,0,0.7,0,1c-7.1,7.9-15.6,13.1-26.5,12.9
		c-3.9-0.1-7,0.9-9.9,3.3c-1.3,1.1-2.4,2.9-4.5,2.8C313.1,537.2,312.4,537,312,536.2z"/>
	<path class="st419" d="M354.9,517.2c-1,0.7-2,1.3-3,2c-1.8,1.6-4,2.7-5.9,4l0,0c-2.4,1.3-4.6,3.2-7.7,2.4c-1.2-1.8,0.6-2.2,1.5-2.7
		c1.8-1,3.6-2.1,5.4-3.1c2-1,3.8-2.2,5.5-3.6c0.3-0.3,0.7-0.6,1-0.9c1.2-0.9,2.3-2.3,4.1-1.4c0,0.7,0.1,1.5,0.1,2.2
		C355.6,516.5,355.3,516.9,354.9,517.2z"/>
	<path class="st420" d="M312,536.2c0.8,0.1,1.6,0.2,2,1.1c-0.3,0.7-0.7,1.3-1,2c-1.9,1.2-3.6,2.9-6,3c-3-2.6,1.5-3.9,1-6
		c0.3,0,0.7,0.1,1,0.1C310,537,311,537.3,312,536.2L312,536.2z"/>
	<path class="st421" d="M317,484.3c1.2-1.9,1.1-4.5,3-6c0,0,0,0.1,0,0.1c0.6,0.4,1.5,0.3,1.8-0.2c2.9-4.5,7-4,11.3-2.8
		c1.9,0.5,3.5,1.4,5.4,1.9c2.8,1,6.2,1,7.1,4.8c0.1,1.1-0.4,1.9-1,2.7c-4.8,3.6-5.4,3.6-7.9-0.1c-1-1.5-1.7-3.4-4.1-2.9
		c-2.4,0.5-2.8,2.6-3.1,4.6c-0.2,1.1,0.3,2.3-0.6,3.3c-2.2,1-4.4,1.5-6.8,1.9c-0.8-0.4-0.9-1.4-1.6-1.9c-2.3,2.3-0.2,5.2-1.1,7.6
		c-0.4,0.7-1,1.2-1.8,1.3c-0.6,0-1-0.3-1.3-0.8C315.2,493.2,315.7,488.7,317,484.3z"/>
	<path class="st422" d="M317,484.3c0,4.7,0,9.3,0,14c0.9,1,1.3,2.3,1.3,3.6c0.1,8.9-0.1,17.8,0.7,26.7c-0.4,0.9-0.7,1.8-1.9,1.7
		c-1.7-0.9-3.4,3.6-5,0c3.3-2.6,4.2-6.1,4-10.1c-0.5-9.5-1.3-18.9-1-28.4C315,489,315.2,486.5,317,484.3z"/>
	<path class="st423" d="M337.9,478.3c-1.7-0.7-3.3-1.3-5-2c-2.4-2.6-3.4-5.1,0.4-7.5c1.2-0.7,1.7-2.3,2.5-3.5
		c6.7-4.6,14-8.2,21.1-12c2.6-1.4,4.8-1.1,5.8,2c-2.1,3-1,6.1-0.4,9.1c0.6,3.4,1.6,6.8,2.5,10.1c0.4,4.7,0.3,9.3,0.1,14
		c-0.2,1.4-0.8,2.7-1.8,3.7c-1,0.5-1.5,0-2.1-0.7c-0.4-0.7-0.7-1.5-0.8-2.3c-1-7.1-0.2-14.1-0.6-20.1c-0.3,0.6-1.6,1.4-3.5,0.8
		c-2.9-3-6.4-3.9-10.4-3.1c-3.7,0.7-6.7,1.9-5.9,6.7C340.3,475.4,339.6,477.1,337.9,478.3z"/>
	<path class="st424" d="M335.9,465.3c-0.6,2.2,0.4,4.7-3.5,5.7c-2.5,0.6,1.6,3.3,0.5,5.3c-3.9-0.6-7.9-1.8-10.7,2.7
		c-0.5,0.7-2.1,1.4-2.3-0.7C324.5,473,329.7,468.5,335.9,465.3z"/>
	<path class="st425" d="M365.9,491.3c-0.3,0.3-0.7,0.7-1,1c-1-1-0.4-2.3-0.5-3.5c0.4-10.3-0.8-20.6-1.3-30.9c-0.1-1,0.1-1.8,0.7-2.6
		c0-1.3,0-2.7,0-4c-0.2-0.3-0.3-0.5-0.2-0.7c0.1-0.2,0.2-0.3,0.3-0.3c0.7,1,1.3,2,2,3C366.7,465.9,366.7,478.6,365.9,491.3z"/>
	<path class="st426" d="M363.9,455.3c0,0.6,0.1,1.3,0.1,1.9c0.7,5.3,0.8,10.7,0,16c-2.4-5.1-3-10.7-3.6-16.2
		c-0.3-2.5,0.8-2.5,2.6-1.8C363.2,455.3,363.6,455.3,363.9,455.3z"/>
	<path class="st427" d="M363.9,450.3c0,0.3-0.1,0.7-0.1,1c-1.9-0.1-3.9,0.3-4.9-1.9c0.4-0.3,0.7-0.6,1.1-0.9c1,0,1.9,0,2.9,0
		C363.3,449.1,363.6,449.7,363.9,450.3z"/>
	<path class="st428" d="M317,530.3c0.3-0.4,0.6-0.8,0.9-1.2c2-3.3,4.8-5.2,8.8-5.1c0.7,0,1.5,0.1,2.2,0.3c2.3,0.8,4.6,0.6,7,0.1
		c0.8,0,1.4,0.1,2,0.7c3,0.7,5.2-1.7,8-2c-5.1,4.8-11.5,5.9-17.8,4.6c-3.5-0.7-5.6,0.2-7.9,2.2c-2.4,2.2-4.8,4.5-8.2,5.2
		c0-0.2,0-0.5,0-0.7C313.6,533.1,316,532.5,317,530.3z"/>
	<path class="st418" d="M311.9,536.2c-0.9,3.1-1.9,1.5-2.9,0.1C310,536.3,311,536.3,311.9,536.2z"/>
	<path class="st429" d="M364.9,489.3c0,1,0,2,0,3.1c-0.3,2-0.7,4-1,6c-0.9,3.6-3,6.7-4.8,9.9c-0.8,1.3-1.9,2.4-3.5,0.8
		c-0.2-0.4-0.3-0.9-0.3-1.4c0.9-2.5,2.9-4.5,3.4-7.3c0.3-1.3,0.7-2.5,1.3-3.7c0.2-0.4,0.4-0.8,0.6-1.2c0.6-1.2,1.2-2.4,2.3-3.2
		c0.3-1,0.7-2,1-3C364.2,488.8,364.5,488.8,364.9,489.3z"/>
	<path class="st430" d="M355.9,508.3c2.5,1.9,2.7-0.7,3.3-1.9c1.5-2.7,2.3-5.8,4.6-8.1c0.3,0,0.6,0,0.9,0c-1.4,6.2-5.8,10.6-9,15.7
		c-1.3,0.8-2.6,1.6-3.8,2.3c-1.6-3.1,2-4.6,2.2-7.2C354.6,508.5,355.1,508,355.9,508.3z"/>
	<path class="st431" d="M365.9,491.3c0-12.7,0-25.3,0-38c1,0.8,1,2,1,3.2c0,10.5,0,21.1,0,31.6C366.9,489.2,367,490.4,365.9,491.3z"
		/>
	<path class="st432" d="M296,520.2c0-9,0-18,0-27c1.2,4.1,4.6,7.2,4.2,12.2C299.8,510.9,299.5,515.9,296,520.2z"/>
	<path class="st428" d="M346,523.2c1.4-2.1,3.5-3.3,5.9-4C350.7,521.7,348.6,522.8,346,523.2z"/>
	<path class="st433" d="M354.9,509.3c0.8,3.1-3.1,4.2-3,7c0,0,0,0,0,0c-2.1,1.3-4.3,2.2-5.9,4.1c-2.2,2.4-5.5,3.1-8.1,4.8
		c-0.7,0-1.3,0-2,0.1c-2.4,0.7-4.8,0.6-7.2,0.1c-0.6-0.1-1.3-0.3-1.9-0.4c-1-0.4-3-0.2-1.5-2.2c4-1.6,8.5-0.1,12.5-2.1
		c1.7-0.9,3.7-1.4,5.2-2.7c4.3-2.1,7.8-5.3,10.6-9.1C354.1,508.8,354.6,508.9,354.9,509.3z"/>
	<path class="st430" d="M346.1,520.4c1-2.7,3.3-3.7,5.9-4.1C350.4,518.3,348.5,519.7,346.1,520.4z"/>
	<path class="st434" d="M337.9,478.3c0.5-2.4,1.7-4.7,0.4-7.3c-0.3-0.5-0.4-1.5-0.1-1.9c2.6-2.9,6-4,9.8-3.9c3.2,0.1,7-0.8,7.8,4
		c-0.4,4,0.7,8.2-1.2,12.1c-0.8,1.6,0.8,3.1,0.9,4.7c0.2,2.7,2.4,6.3-2.3,7.6c-0.3-0.2-0.7-0.4-0.7-0.6c0-5.5-3.2-8.5-7.7-10.7
		C343.8,479,340.3,479.6,337.9,478.3z"/>
	<path class="st435" d="M318,498.3c0.3-0.3,0.7-0.7,1-1c1.2-1.4,1.4-3.6,3.5-4.2c3,2.5,2.1,6.7,4.3,9.5c-1.2,6.6-0.6,13.6-4.8,19.5
		c-2.3,0.4-4.5,0.4-4.5-2.8C317.7,512.3,316.9,505.3,318,498.3z"/>
	<path class="st436" d="M326,502.3c-3.4-2.1-1.6-6.5-4-9c-0.5-0.7-0.5-1.3,0-2c1.8-2.6,4.5-1.9,7-2c1.2,0.1,2,1,2.9,1.7
		c2.4,2.8,6.3,3.6,8.4,6.7c0,0.5-0.2,0.9-0.7,1.1c-3.8,1.4-7.3-1.9-11.1-0.7C327,499.1,327.3,501.2,326,502.3z"/>
	<path class="st437" d="M330.9,491.3c-0.6-0.7-1.3-1.3-1.9-2c-1.7-2.7-0.7-5.1,0.8-7.4c1.7-2.4,5.9-2.4,6.9,0.3
		c1.6,4.6,3.9,4.8,7.3,2.1c1.4-0.8,2.7-1.3,4.1,0c-0.3,2.6-3.7,2.5-4.3,4.8c-0.3,0.4-0.7,0.7-1,1c-1.5,0.9-3.1,1.4-4.9,1
		c-2.7-1-3.8-2.9-3.3-5.7c-0.2-2.7-1.4-2.7-2.8-0.8c-0.8,1-0.5,2.4-0.3,3.7c0.1,0.4,0.2,0.9,0.2,1.3
		C331.6,490.2,331.5,490.9,330.9,491.3z"/>
	<path class="st438" d="M318,498.3c0,6.1,0.3,12.1-0.1,18.2c-0.2,4.1,1.4,5.3,5.1,4.8c1.7-0.4,1.8,0.8,2,2c0.6,0.6,1.3,1.1,1.9,1.7
		c-3.8-0.3-5.9,2.8-8.9,4.2c-1.8-10.2-0.7-20.6-1-30.8C317.3,498.3,317.6,498.3,318,498.3z"/>
	<path class="st439" d="M347.9,485.4c-1-1.6-2.6-0.9-4-1.1c0.3-0.7,0.7-1.3,1-2c7.2,1.2,9.4,4.3,8,11c0,0.3,0,0.5,0,0.8
		c-1.7,3.8-4.5,6.2-8.8,6.3c-1.7,0.1-3.8,0.5-4.4-2.1l0.3-0.2c0.2-0.3,0.5-0.5,0.8-0.6C349.4,494.7,350.1,493.6,347.9,485.4z"/>
	<path class="st438" d="M322,491.3c0,0.7,0,1.3,0,2c0.4,2.4-1.2,3.3-3,4c-1.4-3.9-1.8-7.6,2.1-10.5C322.6,488,321.7,489.8,322,491.3
		z"/>
	<path class="st440" d="M352.9,493.3c3.5-2.5,2.5-5.8,0.8-8.4c-1.2-1.9-1.2-2.8-0.1-4.6c1.3-2.1,1.8-4.6,0.7-7.1
		c-0.7-1.6-1-3.3,1.5-3.8c2,0.5,3.3-1.1,5.3-2.6c-1.3,6.3,1.4,11.7-0.2,17.3c-0.5,1.7-0.1,3.6,0.9,5.3c-5.8,1.3-5.8,1.3-1,7
		c-0.3,1.3-0.7,2.7-1,4c-1.3,2.1-2.8,1.9-4.3,0.3C354.9,498.1,352.7,496.1,352.9,493.3L352.9,493.3z"/>
	<path class="st441" d="M360.9,496.3c-0.4-0.2-0.9-0.5-1.3-0.7c-2.7-1-4.7-2.5-3.3-5.8c1.5-3.7,3.7-0.4,5.6-0.5c0,0.7,0,1.3,0,2
		C361.4,492.9,362,494.7,360.9,496.3L360.9,496.3z"/>
	<path class="st442" d="M364.9,489.3c-0.3,0-0.6,0-1,0c0-5.3,0-10.7,0-16c0-5.3,0-10.7,0-16c1.8,2.4,0.9,5.1,0.9,7.7
		C365,473,364.9,481.2,364.9,489.3z"/>
	<path class="st440" d="M360.9,496.3c0.2-1.7-0.7-3.6,1-5c0.3,0.3,0.6,0.7,1,1C362.8,493.9,362.2,495.2,360.9,496.3z"/>
	<path class="st438" d="M328.8,525.3c2.4,0,4.8,0,7.2-0.1C333.6,526.6,331.2,526.7,328.8,525.3z"/>
	<path class="st443" d="M352.9,493.3c1.4,2.2,3.7,3.9,3,7c1.6,2.3,1.4,4.6,0,7c0,0.3,0,0.7,0,1c-0.3,0.3-0.7,0.7-1,1
		c-0.3,0-0.7,0-1,0c-0.8-0.5-2-0.2-2.5-1.2c-0.6-1.7-0.9-3.7-3.5-2.9c-8.8,2.7-14.7-1.7-20-7.9c0.6-1.1,1.1-1.8,2.6-1
		c2.9,1.5,6.1,1.8,9.2,2.1C346.1,501.6,349.3,497,352.9,493.3z"/>
	<path class="st444" d="M355.9,507.2c0-2.3,0-4.6,0-7c1.3,0.9,2.6,0.9,4,0C359.2,503,358.4,505.6,355.9,507.2z"/>
	<path class="st444" d="M328,497.3c2.7,0.4,5.3,2.2,6.5,4c3.4,5.1,8.1,3.4,12.1,2.7c4.7-0.8,5.7-0.6,5.4,4.3c0.6,2.1-1,3.1-2.4,3.9
		c-2.5,1.5-4.3,3.6-5.6,6.1c-2.9,4-7.4,4.3-11.6,4.9c-2.4,0.4-4.9,0.1-7.4,0.1c-0.7-0.7-1.3-1.3-2-2c-0.6-4.7,1-9.1,1.9-13.5
		c0.3-1.7,1-3.6,1.1-5.5C326.4,500.5,324.8,497.9,328,497.3z"/>
	<path class="st445" d="M343.9,518.2c0.1-3,1.3-5.2,4.1-6.5c1.6-0.7,3.1-1.8,3.9-3.5c0.8,0,1.6,0.2,2,1
		C352.1,513.9,348.4,516.5,343.9,518.2z"/>
	<path class="st446" d="M347.9,485.4c1.5,2.1,4.4,3.7,2.5,7.1c-2.3,4.1-5.5,6.4-10.3,5.6c-3.3-2-6.9-3.6-9.2-6.8
		c0-0.6-0.1-1.2-0.1-1.9c1.9-0.8,2.5,1,3.6,1.8c2.1,1.6,4.2,3.4,7,3.6c2.5,0.2,5-0.1,5.5-2.9c0.6-3.4-2.9-1.7-4.1-2.9
		C343.1,486,345,485,347.9,485.4z"/>
	<path class="st447" d="M342.8,489.1c1.5,0.1,2.6-2.8,4.3-0.9c1.1,1.2,2.3,2.7,1.5,4.4c-0.8,1.8-2.2,3.3-4.4,3.6
		c-6,0.8-9.7-3.1-13.4-6.8c0,0,0,0,0,0c-0.4-0.7-0.4-1.4-0.2-2.2c0.6-2,1.8-3.1,3.9-2.7c0.5,0.2,0.9,0.4,1.3,0.8
		c-1.3,2.5,0.4,3.8,2.1,5.1C339.9,491.3,341.5,490.6,342.8,489.1L342.8,489.1z"/>
	<path class="st446" d="M335.9,485.3c-0.3,0-0.6,0-0.9-0.1c-2.4-1.4-3.4,0-4,2c-0.1,0.7-0.1,1.4-0.2,2.1c-1.3-1.2-0.8-2.7-0.8-4.1
		c0-1.9,1.1-3.1,3-3.3C335.3,481.7,336.1,483.2,335.9,485.3z"/>
	<path class="st446" d="M342.8,489.1c-1,2.6-2.7,2.9-4.8,1.3C339.6,490,341.2,489.5,342.8,489.1z"/>
	<path class="st448" d="M331,487.3c0-3.3,1.4-3.9,4-2C333.1,484.9,332.1,486.2,331,487.3z"/>
</g>
<g id="telefono" [@TelefonoTrigger]="isEstadoTelefono" (click)="clickEvent('telefono')">
	<path class="st449" d="M243.1,417.2c4.6,3.6,4.1,9.2,4.8,13.9c1.7,12.3,4.4,24.4,6.3,36.6c1,6.2,2.3,12.3,3.2,18.5
		c0.7,4.9,2.5,9.8,3.2,14.9c1.7,10.5,3.6,21,4.6,31.7c0.5,5.8,2.6,11.5,3.2,17.2c0.7,6.5,2.7,12.4,5.7,18.1
		c-0.3,0.4-0.8,0.7-1.3,0.9c-1.3,0.3-2.5,0.3-3.7-0.5c-1.9-2.8-0.8-6.2-1.8-9.2c-0.5-1.9-0.3-3.8-0.9-5.7c-0.2-0.5-0.3-0.9-0.4-1.4
		c-0.5-4.9-1.7-9.7-2.7-14.5c-0.1-0.5-0.2-1-0.3-1.4c-0.2-1.7-0.2-3.4-0.7-5c-0.2-0.7-0.3-1.4-0.4-2.2c-0.2-1.9,0.1-3.9-0.8-5.7
		c-0.4-1-0.8-1.9-1.1-2.9c-1.4-11.1-3.6-22.1-5.9-33.1c-0.7-4-1.3-8-2.2-12c-0.2-3.8-0.9-7.5-1.9-11.2c-2.3-11.3-3.7-22.7-5.8-34.1
		c-0.5-3.8-2-7.4-2.6-11.2C241.8,418,242.3,417.5,243.1,417.2z"/>
	<path class="st450" d="M210.1,844.8c-0.9,2.3-2.9,3.1-4.9,4c-2.5,0.8-4.3,2.7-6.8,3.5c-0.7,0.1-1.3,0.2-2,0.4c-1.3,0-2.5,0-3.5,0.9
		c-1.2,0.7-2.4,1.3-3.8,0.6c-0.5-0.4,0.2,0.1-0.3-0.3c-4.6-3.7-4.6-3.7,0.4-7.5c1.1-0.6,2.5-0.5,3.7-1.3c-5.2-2.6-5.6-4.3-1.3-8.6
		c2.5-2.6,4.9-6.3,9.5-3.5c1.1,0.9,2.3,1.7,3.1,2.8c0.2,0.8-0.2,1.3-0.7,1.8c-0.8,0.6-1.5,1.2-2.2,1.9c-0.3,0.3-0.7,0.6-0.9,1
		c0.6,0.3,1.3-0.1,1.9,0.3c2.1,4.5,4.1,0.2,6.2,0c2.6-1.6,5.1-3.3,8.2-3.6c1,0,1.9,0.4,2.5,1.2c0.2,0.5,0.2,0.9-0.1,1.3
		c-0.4,0.4-0.7,0.7-1.1,1.1C215.5,842.4,212.9,843.9,210.1,844.8z"/>
	<path class="st451" d="M117.1,457.2c0.3-0.3,0.6-0.6,1-1c1.1-1.5,2.7-2.2,4.4-2.8c0.8-0.4,1.7-0.8,2.5-1.2c1.8-0.6,3.5-1.3,5-2.4
		c2.2-1.7,5-2.2,7.3-3.8c0.7-0.5,1.4-1.1,2.2-1.6c1-1.2,2.6-0.8,3.7-1.6c3.1-1.4,5.9-3.3,9-4.8c2.6-1.6,5.2-3.2,7.8-4.8
		c3.6-1.1,6.4-3.8,10.1-4.8c0.8-0.1,1.5,0.1,2.1,0.6c-0.6,4.4-5.1,3.8-7.5,5.9c-3.7,1.8-7.1,4.2-10.8,6.1c-1.4,0.8-2.8,1.5-4.1,2.3
		c-2.9,1.8-6,3.1-8.9,4.7c-1.4,0.8-2.7,1.6-4.2,2.3c-3.1,1.5-5.8,3.7-9,4.9c-3.4,1.7-6.8,3.3-9.9,5.7c-2,1.5-4.3,2.5-6.3,4
		c-2.7,1.7-5.9,2.2-8.5,4c-1.4,0.6-2.8,1.2-4.2,1.9c-1,0.5-2,0.9-2.9,1.5c-1.2,0.7-2.2,1.6-3.5,2.2c-1.4,0.6-2.8,1-4.1-0.1
		c-0.4-1.7,0.9-2.4,2-3C99.3,467.1,107.9,461.5,117.1,457.2z"/>
	<path class="st452" d="M139.3,855.8c-0.1-0.3-0.2-0.5-0.2-0.8c-2-2.3-2.2-5.3-2.8-8.1c-2.9-6.6-3.3-13.6-3.2-20.7
		c-0.9-3.4,1.4-3.2,3.6-3.2c3.1,0.8,4.8,3,4.8,6.1c-0.2,12.3,3.5,10.6,11.9,12.4c0.8,0.2,1.6,0.3,2.4,0.7c0.5,0.3,0.7,0.7,0.7,1.3
		c-0.6,1.3-1.8,1.8-3.1,2.1c-5.3-0.2-10.4-1-15-4.4c0,2.5,0.7,4.4,1.5,6.2c0.6,2,1,4,1.9,5.9c0.2,0.7,0.2,1.4,0,2.1
		c-0.3,0.4-0.6,0.7-1.1,0.8C140.1,856.4,139.7,856.2,139.3,855.8z"/>
	<path class="st453" d="M107.3,736c-0.1-0.6-0.1-1.3-0.2-1.9c-1.3-4.3-2-8.8-4.2-12.9c-1.1-1.2-2.1-2.4-1.1-4.1
		c1-0.9,1.9-0.2,2.7,0.4c2.1,3.4,4.3,6.7,5.3,11.7c0.4-7.6,6-7.3,10.2-8.7c2,0.4,3.7,1,4.6,3.1c0,0.9-0.3,1.7-1.1,2
		c-3.6,1.2-6.1,3.4-7.5,7.1c-0.7,1.9-2.8,2.6-4.4,3.8C110,737.9,108.7,737.1,107.3,736z"/>
	<path class="st454" d="M122.1,780.9c-3.7-0.9-7.3-2.6-11-2.7c-7.9-0.1-11.7-5-14.8-11.1c-0.5-0.7-0.6-1.5-0.1-2.2
		c1.7-0.4,2.4,0.7,3.2,1.9c2.3,4,5.8,6.3,10.3,7.1c6.5,1.1,13,2.8,19.8,2.2c3.7-0.3,5.4,1.9,5.3,5.6c-3.1,1.3-6.3,0.8-9.5,0.8
		C124,782.2,122.8,781.9,122.1,780.9z"/>
	<path class="st455" d="M309,663.4c-0.3,0.2-0.6,0.4-0.9,0.6c-0.7,0.6-1.2,1.6-2.5,1.2c-1.4-0.9-1.8-2.3-1.7-3.9
		c0.1-0.5,0.2-0.9,0.4-1.4c1.3-2.3,3.6-4.6-0.5-6.7c-1.2-0.6-1.2-2.5-0.4-3.6c2.4-3.1,0.1-4.7-1.7-6.5c-1.1-1-2.4-1.6-3.9-1.4
		c-0.6,0-1.2-0.1-1.8-0.4c-1.6-1.8-3.1,0.1-4.6,0.3c-0.4,0.2-0.9,0.3-1.3,0.4c-2.4,0.4-4.7,1.5-7.3,0.7c-0.5-0.2-0.9-0.6-1.1-1.1
		c0-0.6,0.2-1,0.8-1.3c3.4-0.3,6.5-2,9.9-2.8c3.6-0.9,6.8-0.7,9.8,1.4c0.3,0,0.6,0,0.9,0c1.1,0.9,2.9,0.9,3.5,2.5
		c4.5,4.4,4.1,10,3.9,15.6C309.6,659.2,309,661.2,309,663.4z"/>
	<path class="st456" d="M90,614.1c-2.1-9.3-5.9-18.3-5.9-28c-0.3-1.3-1.2-2.8,1.2-3.1c2.4,2,2.5,4.9,3.1,7.6
		c1.1,4.6,1.6,9.3,2.1,14.1c0,0.6-0.2,1.4,0.2,1.9c3.4,4.5,3.8,9.8,4.2,15.1c0.4,1.2,0.7,2.5,1.1,3.7c0.4,2.1,0.7,4.2-0.4,6.1
		c-2.2,1.7-1.7-0.8-2.3-1.5c-1.2-1.4-1.2-3.1-1.2-4.8C91.1,621.6,91.6,617.7,90,614.1z"/>
	<path class="st457" d="M305,669.1c0.2,3.9-3.3,5.2-5.6,6.8c-2.5,1.7-4,4.1-6.2,6c-3.2,2.8-0.1,6.7,0.4,10.1
		c1,7.7,3.3,15.3,3.2,23.2c-0.5,0.2-1,0.1-1.4-0.3c-2.1-2.9-1.9-6.4-2.7-9.6c-0.1-1.7,0-3.5-0.8-5.2c-1.3-3.4-1.4-7-2.2-10.6
		c-0.1-1.7,0-3.5-0.5-5.2c-0.2-1-0.2-1.9,0.1-2.9c2.1-3.8,6-5.6,9.1-8.3c1.6-1.4,3.2-2.9,4.6-4.5c0.5-0.5,1.1-0.9,1.9-0.7
		c0.3,0.3,0.4,0.5,0.3,0.8C305.2,668.9,305.1,669.1,305,669.1z"/>
	<path class="st458" d="M137.1,823.9c-1.7,0.2-3.9-0.6-4,2.3c-0.2-2.4-4-1.5-4-4.1c-0.4-0.5-0.4-0.9,0-1.4
		c5.6-1.7,11.5-2.3,17.3-2.8c0.7,0,1.6,0.4,1.9,0.2c4.4-3.1,4.3-0.1,4.2,3.1c-0.4,0.7-0.9,1.2-1.6,1.5c-1.6,0.7-3.3,1-5,1.4
		c-1.5,0.2-3,0.2-4.5,0.3C140,824.1,138.6,824.3,137.1,823.9z"/>
	<path class="st459" d="M117.1,457.2c-7.7,5.7-16.6,9.5-24.9,14.2c-1.4,0.8-3.2,1.1-4,2.8c-0.9,2.3-3.2,3.5-4.5,5.4
		c-1.1,0.8-2.1,1.5-3.4,2c-0.4,0.1-0.9,0-1.3-0.3c1.9-5.4,6.7-8.2,11-10.9c7.3-4.6,15.2-8.2,22.7-12.4
		C114.3,457.3,115.7,457.5,117.1,457.2z"/>
	<path class="st460" d="M296.1,714.8c0.2,0.1,0.5,0.2,0.7,0.3c2.8,9.6,4.5,19.5,5.9,29.4c0.7,4.8,3,9.4,2.1,14.5
		c-2.8-0.5-2.8-2.9-3.2-5c-1.2-6.3-1.9-12.8-3.5-19c-0.6-5.5-1.9-10.8-3-16.2C294.8,717.3,294.8,715.9,296.1,714.8z"/>
	<path class="st461" d="M277.9,573.2c2.1,7.5,4.5,15,5.2,22.9c0.9,1.6,0.3,3.3,0.5,5c-0.1,1.1,0.2,2.3-0.4,3.3
		c-2.2,0.1-3-1.4-3.1-3.1c-0.7-8.5-3-16.6-4.7-24.9c-0.2-1-0.1-2,0.4-2.9C276.3,572.6,277,572.6,277.9,573.2z"/>
	<path class="st462" d="M270.1,811.8c-0.4,0.4-0.8,0.8-1.2,1.1c-1.2,1-2.9,1.2-3.8,2.7c-1.6,0.9-3,2.2-4.9,2.2
		c-1.3,0.6-2.3,1.8-3.9,1.4c-0.4-0.3-0.7-0.7-0.8-1.2c0-1.4,1-2.1,1.9-2.9c0.6-0.4,1.2-0.8,1.8-1.1c4.2-1.6,7.7-4.2,11.7-6.2
		c3.9-3.9,9.1-5.3,13.9-7.3c1.3-0.5,2.5-0.9,3.8-0.2c0.7,0.6,0.6,1.2-0.1,1.8c-1.8,1.1-3.4,2.4-5.4,3.2c-2.2,0.6-3.8,2.5-6.1,2.7
		C274.7,809.3,272.7,811.2,270.1,811.8z"/>
	<path class="st463" d="M304.2,761c0,0.6,0,1.2-0.1,1.8c0.1,1.8,1.4,3.8-0.8,5.3c-2.8-3.1-1.7-7.3-3.3-10.8c-0.9-2.2-1.4-4.6-1.4-7
		c0-1.8,0.1-3.6-0.5-5.3c-0.2-0.5-0.2-1-0.3-1.5c-0.2-1.6-0.2-3.2-0.2-4.8c0.1-1.4,0.1-2.8,1.3-3.8c2.6,6.3,2.6,13.1,3.9,19.6
		c0.3,1.7,0.7,3.2,1.9,4.4C304.6,759.7,304.4,760.4,304.2,761z"/>
	<path class="st464" d="M82.2,572.3c-3.4-7.7-3-16.2-4.9-24.2c-0.7-3.2-1.5-6.4-1.1-9.7c-0.7-1.4-0.6-2.8-0.1-4.2
		c2.9,0.3,2.9,2.9,3.1,4.8c0.6,5.7,2,11.3,2.4,17c0,1.7-0.2,3.4,0.1,5.1c0.1,0.4,0.2,0.9,0.3,1.3c0.3,1.5,0.3,3.1,0.8,4.6
		c0.2,0.4,0.3,0.9,0.3,1.3C83.4,569.8,83.5,571.2,82.2,572.3z"/>
	<path class="st465" d="M190.3,856.9c-2.3,5-7.7,5.4-11.7,7.7c-2.5,1.4-4.9,4.6-8.6,3.1c-0.1-0.8,0.4-1.2,1-1.6
		c1-0.5,2.3-0.6,2.1-2.3c5.3-2.7,10-6.6,16-7.7C189.6,856.3,190,856.5,190.3,856.9z"/>
	<path class="st466" d="M235.1,831.7c-1,0.4-2,0.8-3.1,1.2c-0.1-0.2-0.3-0.4-0.4-0.5c-0.4-2.9,2.6-3.5,3.7-5.3
		c2.7-2.2,5.7-3.8,8.5-5.8c1.3-0.5,2.6-1.1,4.1-0.6c0.6,0.5,0.8,1.2,0.8,1.9c0,1.1-0.8,1.7-1.6,2.2c-0.4,0.4-0.9,0.7-1.3,1.1
		c-1,0.9-2.4,1.1-3.6,1.5C240,829,237.8,830.7,235.1,831.7z"/>
	<path class="st467" d="M100.1,714.1c-1.2-0.8-2.4-1.5-2.7-3.1c-0.2-4.4-2.3-8.4-1.7-12.9c0.3-2.4,0.7-4.5,2.5-6.1
		c0-0.3,0.1-0.6,0.2-0.9c1.3-0.9,2.5-1.9,3-3.6c0.3-0.7,0.8-1.2,1.4-1.6c0.2-0.1,0.5-0.1,0.7-0.2c0.5,0.1,0.9,0.3,1.2,0.7
		c0.4,1,0.4,1.9,0,2.9c-0.2,0.4-0.4,0.9-0.8,1.2c-5.6,4.4-6.5,9.9-3.9,16.2c0.4,2.1,1,4.1,1.6,6.1c0.1,0.5,0,0.9-0.2,1.4
		C100.9,714.4,100.5,714.4,100.1,714.1z"/>
	<path class="st468" d="M243.1,417.2c-0.3,0.4-0.6,0.7-0.9,1.1c-3-1.4-4-5.2-7.3-6.3c-0.6-0.4-1.1-0.7-1.7-1.1
		c-2.6-1.8-6.2-2-8.4-4.5c-0.1-0.5-0.1-0.9,0.2-1.4c0.7-0.3,1.4-0.3,2.1,0.2c5.9,0.8,10.2,4.9,15.1,7.8
		C243.7,414,242.9,415.8,243.1,417.2z"/>
	<path class="st469" d="M302.1,639.1c-7-2.6-13.3,1-19.9,2.1c-0.5-0.2-0.9-0.5-1.1-1c-1-2.2-1.8-4.4-0.6-6.7
		c0.2-0.2,0.4-0.3,0.6-0.5c1.9,5.8,5.8,6.3,10.7,4c2.4-0.7,4.8-0.7,7.2,0C300.6,637,302.2,636.8,302.1,639.1z"/>
	<path class="st470" d="M122.1,780.9c1.1,0,2.1,0.1,3.2,0.1c0.1,3.7,1.5,7.3,1,11.1c-0.7,0.4-1.5,0.4-2.1-0.1
		C120.7,788.8,120,785.1,122.1,780.9z"/>
	<path class="st471" d="M99.2,685.2c-4.1-2.6-5.7-6.4-6-11c-1.3-3.8-1.7-7.7-1.4-11.7c0.3-0.6,0.7-0.8,1.3-0.7
		c2.4,3.4,1.8,7.6,2.9,11.3c0.9,3.2,1.8,6.3,3.8,9C100.2,683.3,100.1,684.4,99.2,685.2z"/>
	<path class="st472" d="M76.3,534.2c0,1.4-0.1,2.8-0.1,4.2c-2.7-6.7-2.7-14-4-21c-0.8-1.3-0.5-2.6-0.3-3.9c1.5-0.8,1.8,0.5,2.3,1.4
		c0.3,1.3,0.6,2.6,0.9,3.8c0.4,3,1.5,6,1.4,9.1c0,1.7,0,3.4,0.2,5.1C76.8,533.3,76.6,533.8,76.3,534.2z"/>
	<path class="st473" d="M82.2,572.3c0-1.7,0-3.4,0-5.1c1-0.1,1.7,0.4,1.8,1.2c1.1,4.8,3.3,9.6,1.1,14.7c-1.1,0.7-0.9,1.9-1.1,2.9
		C81.8,581.7,82.4,577,82.2,572.3z"/>
	<path class="st474" d="M219.1,839.8c0-0.3,0-0.6,0-0.9c2.2-4.7,8-7.5,12.9-6.1c0,0,0,0.2,0,0.2C228.1,835.9,224.7,840,219.1,839.8z
		"/>
	<path class="st475" d="M93.4,630c0.6,0.7,1.2,1.4,1.9,2c0.2,0.2,0.3,0.4,0.5,0.6c0.3,0.7,0.3,1.5,0,2.2c-2.8,3.6-4,7.5-2.6,12.1
		c0.5,1.6,0.4,3.3-0.5,4.8c-0.4,0.5-0.8,0.6-1.3,0.3c-1.2-6.2-2.8-12.4,1.4-18.2C93.4,632.9,93.2,631.3,93.4,630z"/>
	<path class="st476" d="M72.3,513.2c0,1.4,0,2.7,0,4.1c-2.9-7.4-3.7-14.8-0.1-22.2c0.2,0.2,0.4,0.4,0.6,0.6
		C73.4,501.6,73.1,507.4,72.3,513.2z"/>
	<path class="st477" d="M303.9,787.9c0.1,0.3,0.1,0.7,0.2,1c1.4,4.1-3.8,4.2-4.3,7.1c-0.4,1.4-1.8,1.3-2.7,2l-0.1-0.1
		c-0.8,0.8-1.7,2-2.5,0c0.1-2.6,2.5-3.8,3.7-5.7c1.4-1.3,1.1-4.1,3.7-4.6c0.6-0.9,0.2,3,1.2,0.2
		C303.1,787.7,303.6,787.8,303.9,787.9z"/>
	<path class="st478" d="M97.2,744.9c2.5-3.2,6.2-3.7,9.9-4c1.4-0.6,2.6-1.8,4.3-1.1c0.5,0.7,0.6,1.4,0.5,2.2
		c-0.4,1.1-1.2,1.6-2.3,1.9c-0.7,0.2-1.5,0.2-2.2,0.2c-2.7-0.4-5,1.3-7.6,1.7c-0.7,0-1.5-0.1-2.1-0.5
		C97.5,745.3,97.3,745.1,97.2,744.9z"/>
	<path class="st479" d="M162.3,867.9c-4.4,1.1-12.8-1.7-15.2-5c0.5-0.6,1.2-0.9,2-1c3.4-0.1,5.9,2.4,9,3.2
		C159.6,865.8,161.3,866.3,162.3,867.9z"/>
	<path class="st480" d="M99.2,685.2c0-1,0.1-2.1,0.1-3.1c0.2-0.5,0.4-0.9,0.7-1.3c1.5-1.6,2.1-4.8,5.5-3c1,1.7,0.1,3.3-0.1,5
		c-0.3,1.2-0.9,2.2-1.8,3.1l-0.3,0.4c-1.1,0.4-2.2,0.6-3.1-0.2C99.8,685.8,99.5,685.5,99.2,685.2z"/>
	<path class="st481" d="M96.2,764.9c0,0.7,0,1.4-0.1,2.2c-3.5-5.4-5.9-10.9-2-17.2c0.2,0.2,0.4,0.4,0.6,0.5c1.7,3.2,0,6.8,1.5,10
		C96.9,761.9,97.5,763.4,96.2,764.9z"/>
	<path class="st482" d="M193.1,414.5c5.4-3.2,10.7-6.7,16.9-8.3c0.1,0.2,0.2,0.4,0.3,0.6c-0.3,1.2-1.4,1.7-2.3,2.4
		c-3.8,2.5-8.1,4.1-11.9,6.6c-1,0.3-1.9,0.4-2.8-0.1c-0.3-0.2-0.4-0.5-0.4-0.8C193,414.7,193,414.5,193.1,414.5z"/>
	<path class="st483" d="M227.1,405.3c-0.7,0-1.4,0-2.1,0.1c-0.1,0.1-0.3,0.3-0.4,0.3c-4.9-0.5-9.6,0.2-14.4,0.9c0,0-0.2-0.3-0.2-0.3
		C215.6,401.1,221,400.9,227.1,405.3z"/>
	<path class="st484" d="M309.9,657.8c0.8-6.1-0.5-11.6-4.2-16.5c0.3-0.5,0.7-0.6,1.2-0.2c5.5,5.2,4.9,11.5,3.1,18
		c-0.3-0.3-0.4-0.5-0.3-0.8C309.7,658,309.8,657.8,309.9,657.8z"/>
	<path class="st485" d="M119.1,817c-4.1-4.4-4.3-9.6-3-15.1c0.5-0.3,1-0.3,1.5,0.2c1.3,4.7,1.4,9.5,2.1,14.3
		C119.5,816.6,119.3,816.8,119.1,817z"/>
	<path class="st486" d="M91.4,652.1c0.3,0,0.5,0,0.8,0c0.4,0.3,0.8,0.8,0.9,1.3c0.3,0.8,0.4,1.6,0.4,2.4c0,2.2,0.4,4.4-1.2,6.2
		c-0.7,4.1,0.9,8.1,0.9,12.2C89.4,667.1,91.8,659.5,91.4,652.1z"/>
	<path class="st487" d="M98.2,691.9c-2.6,4.7-2.5,9.6-1,14.6c0.4,1.4,1.9,2.9,0.2,4.5c-2.1-4.5-2.5-9.3-2.2-14.3
		C95.4,694.6,95.4,692.4,98.2,691.9z"/>
	<path class="st488" d="M294.9,797.8c0.6,1.2,1.4,0.2,2.1,0.1c-1.9,3.6-4.9,5.4-9,5.1c-0.3-0.2-0.4-0.5-0.3-0.7
		c0.1-0.2,0.2-0.4,0.3-0.4c0.4-0.4,0.7-0.7,1.1-1.1C290.5,799,292.2,797.4,294.9,797.8z"/>
	<path class="st489" d="M79.1,481.4c0.4-0.1,0.8-0.2,1.2-0.3c1.3,1.9-0.2,3.1-1.1,4.5c-1.6,1.7-2,4.3-3.9,5.9
		c-0.5,0.1-0.9,0-1.3-0.3c-0.2-0.7-0.2-1.4,0.4-2C74.8,485.9,76.8,483.5,79.1,481.4z"/>
	<path class="st490" d="M170.1,429.3c-3.6,0.7-6,4.6-10.2,4c3.1-3,7-4.8,11.1-6c0.4-0.4,0.8-0.4,1.2-0.1
		C172.7,429.1,170.8,428.5,170.1,429.3z"/>
	<path class="st491" d="M176,424.3c3.4-2.5,7-4.7,11-6c1-0.6,1.8-0.6,2.3,0.6c-0.1,0.5-0.3,0.9-0.7,1.1c-1.4,0.7-2.9,1.2-4.2,2.1
		c-1.7,1.1-3.4,2.1-5.3,2.8c-0.7,0.2-1.4,0.3-2.1,0.1C176.7,425,176.3,424.7,176,424.3z"/>
	<path class="st492" d="M170.9,866.7c-0.3,0.3-0.6,0.6-0.9,1c-0.2,0.1-0.4,0.2-0.7,0.2c-2.6,1.1-4.9,0.3-7.1-1.3
		c-0.3-0.4-0.5-0.9-0.5-1.4c0.3-1.1,1.1-1.6,2.2-1.8c1.2,0,2.4,0.4,3.5,0.7C168.7,864.9,170.9,864.3,170.9,866.7z"/>
	<path class="st493" d="M103.2,721c2.9,3.9,3.5,8.5,4,13.2c-2.2-3.7-3.1-7.9-4-12c-0.3-0.3-0.4-0.5-0.3-0.8
		C103,721.1,103.1,721,103.2,721z"/>
	<path class="st494" d="M190.3,856.9c-0.5-0.1-0.9-0.2-1.4-0.3c0.5-2.6,3.7-2.1,4.9-3.9c0.6-0.7,1.7-0.2,2.4-0.8c0.7,0,1.3,0,2,0
		c0.4,0.2,0.5,0.4,0.4,0.6c-0.1,0.2-0.2,0.3-0.3,0.3C196.4,855.7,193.6,856.8,190.3,856.9z"/>
	<path class="st495" d="M149.2,862.8c-0.7,0-1.4,0-2.1,0.1c-1-0.6-2-1.2-2.9-1.9c-1.6-1.5-4.1-2.3-4-5.1c0.3,0,0.7,0,1,0
		c3-0.5,4.1,2.5,6.3,3.4C148.5,860.3,149.8,861.1,149.2,862.8z"/>
	<path class="st496" d="M137.2,446.2c-1.5,2.7-4.4,3.1-6.9,4.2c-0.8,0.5-1.5,0.5-2.2-0.1C130.7,448,133.3,445.6,137.2,446.2z"/>
	<path class="st497" d="M106.1,779.2c2.8,0.7,4.9,1.2,7.1,1.8c1.1,0.3,1.1,1.2,0.9,1.9c-0.5,1.4-1.5,0.2-1.8,0
		C110.6,781.3,107.1,783.1,106.1,779.2z"/>
	<path class="st498" d="M152.1,438.2c-2.4,2.7-5.5,4.2-8.9,5.2c-0.3-0.2-0.4-0.5-0.3-0.7c0.1-0.3,0.2-0.4,0.3-0.4
		C146,440.5,148.5,438,152.1,438.2z"/>
	<path class="st499" d="M124.1,792c0.7,0,1.4,0,2.1,0c0.4,0.3,0.6,0.7,0.7,1.2c-0.2,3.6-3,4.7-5.5,6c-0.5,0-0.9-0.1-1.3-0.4
		c-0.4-0.7-0.4-1.3,0.1-2C121.6,795.3,124.4,794.9,124.1,792z"/>
	<path class="st500" d="M162.2,865.9c2.2,1.3,4.6,2.1,7.1,2c-2.3,1.6-4.7,1.1-7,0c-1.6-0.2-3.1-0.7-4.1-2.1
		C159.5,864.6,160.8,864.8,162.2,865.9z"/>
	<path class="st501" d="M282.8,621.1c-0.7,4-2.8,7.7-1.7,11.9c0,0-0.3,0-0.3,0c-3.5-1.7-1.6-4.8-2-7.3c0.5-1.8,0.7-3.7,2.5-4.9
		C281.9,620.7,282.4,620.8,282.8,621.1z"/>
	<path class="st485" d="M129.2,820.9c0,0.4,0,0.8,0,1.1c-3.9-0.6-8.5,0.3-10-5.1c0,0,0.1-0.1,0.1-0.1c0.4-0.3,0.8-0.4,1.2-0.2
		c0.6,0.3,1.2,0.6,1.7,1C124.2,819.3,127.4,818.7,129.2,820.9z"/>
	<path class="st502" d="M111,740.1c-1.2,0.7-2.5,1.1-4,0.9c1.5-1.6,1.3-3.2,0.3-4.9c1.3,0,2.6,1,3.8-0.1c0.4,0.3,0.7,0.8,0.9,1.3
		C112.2,738.3,112.1,739.4,111,740.1z"/>
	<path class="st503" d="M210.1,844.8c2.4-1.8,4.5-4.1,7.9-3.9C216.4,844.3,213.6,845.2,210.1,844.8z"/>
	<path class="st504" d="M235.1,831.7c1.7-2.3,4.4-3.3,6.9-4.5c0,0.2,0,0.4,0,0.5C240.5,830.5,238.4,832.1,235.1,831.7z"/>
	<path class="st505" d="M90,614.1c2.5,3.4,2.3,7.3,2.2,11.1C90.1,621.8,90.3,617.9,90,614.1z"/>
	<path class="st506" d="M277,807.8c1.9-1.2,3.7-2.5,6-3c1.1-0.4,1.3,0.1,1.1,1.1c-2.5,0.5-4,3.5-7,2.8c-0.4-0.2-0.5-0.4-0.4-0.6
		C276.8,808,276.9,807.8,277,807.8z"/>
	<path class="st507" d="M103.2,721c0,0.4,0,0.8-0.1,1.1c-3.6-1.7-2.8-5.1-3.1-8.1c0.4,0,0.7,0,1.1,0c1.1,0.7,1.7,1.6,1,3
		C102.1,718.5,102.5,719.7,103.2,721z"/>
	<path class="st495" d="M136.3,846.9c1.4,2.5,2.2,5.3,2.8,8.1C135.7,853.1,136.1,850,136.3,846.9z"/>
	<path class="st508" d="M283,604c0-1,0-2,0-3c0.3-0.4,0.5-0.4,0.7,0c0,4,0,8.1,0,12.1c-0.7,0.2-1.1-0.1-1.5-0.7
		C281.4,609.6,281,606.7,283,604z"/>
	<path class="st509" d="M189.1,419c-0.7-0.2-1.4-0.5-2.1-0.7c1.2-2.6,4.5-1.9,6.1-3.8c0,0.3,0,0.7,0.1,1c0.3,1-0.2,1.7-0.8,2.5
		C191.4,418.6,190.7,420.4,189.1,419z"/>
	<path class="st510" d="M277.9,573.2c-0.6,0-1.3,0-1.9,0c-2-1-3.3-2.5-3-5c0.4,0,0.7,0,1.1,0C276.3,569.1,278.1,570.3,277.9,573.2z"
		/>
	<path class="st511" d="M284.1,806c-0.4-0.4-0.7-0.7-1.1-1.1c1.4-1.5,3.3-2.1,5.1-2.8c0,0.4,0,0.7,0,1.1
		C287.9,805.7,285.6,805.4,284.1,806z"/>
	<path class="st479" d="M140.2,855.9c1.3,1.7,2.6,3.4,4,5.1c-3.4-0.1-4.6-2.2-4.8-5.2C139.6,855.8,139.9,855.9,140.2,855.9z"/>
	<path class="st512" d="M176,424.3c0.5,0.1,0.9,0.1,1.4,0.2c-0.6,2.9-2.3,4-5.2,2.9c-0.4,0-0.8-0.1-1.1-0.1
		C172.1,425.3,174,424.8,176,424.3z"/>
	<path class="st513" d="M277,807.8c0,0.3,0,0.6,0,0.9c-2.1,1.5-3.9,3.6-6.9,3.1C272.2,810.1,274.4,808.7,277,807.8z"/>
	<path class="st514" d="M283.1,613.2c0.2,0,0.5,0,0.7,0c0.4,2.7,0.4,5.4-1,7.9c-0.3,0-0.6,0-0.9-0.1
		C280.2,618.1,281.1,615.6,283.1,613.2z"/>
	<path class="st515" d="M198.1,852.7c0-0.3,0-0.6,0-0.9c2.4-0.9,4.2-3.3,7.1-3C203,850.4,201.2,852.7,198.1,852.7z"/>
	<path class="st516" d="M305,669.1c0-0.3,0-0.6,0-1c-1-1.5-0.4-2.3,1.1-2.8c0.7-0.4,1.4-0.8,2.1-1.2
		C308.2,666.4,307.2,668.1,305,669.1z"/>
	<path class="st517" d="M247.2,824.8c0.3-0.5,0.7-1,1-1.5c0.4-1.4,1.5-1.3,2.6-1.2c0.5,0.1,1,0.4,1.3,0.8
		C251.2,825.4,249.1,824.9,247.2,824.8z"/>
	<path class="st518" d="M256.1,819.2c1.4-0.4,2.7-0.9,4.1-1.3c-1.1,2-3,2.8-5.2,3c-0.4,0.4-0.7,0.4-1.1,0.1
		C253.7,819.3,254.8,819.1,256.1,819.2z"/>
	<path class="st476" d="M74.3,489.2c0,0.7,0,1.4,0,2.1c1,2.1,0.3,3.4-1.8,4.2c-0.1-0.1-0.3-0.2-0.4-0.3
		C72.3,493,72.3,490.7,74.3,489.2z"/>
	<path class="st519" d="M122.4,453.5c-1.1,1.5-2.5,2.4-4.4,2.8C118.3,453.5,120.1,453.1,122.4,453.5z"/>
	<path class="st478" d="M94.2,749.9c0.2-2.2,0.1-4.4,3-5c0,0,0.1,0.1,0.1,0.1C97.6,747.5,97.2,749.5,94.2,749.9L94.2,749.9z"/>
	<path class="st520" d="M297.1,798c0.5-1.2,1.8-1.4,2.7-2c0,1.1,0.4,2.7-0.1,3.2C298.7,800.1,298.2,798,297.1,798z"/>
	<path class="st521" d="M299.1,637.1c-2.4,0-4.8,0-7.2,0C294.3,635.5,296.7,635.5,299.1,637.1z"/>
	<path class="st522" d="M309.9,657.8c0,0.4,0.1,0.9,0.1,1.3c-0.2,1.4,0.7,3.2-1,4.3C307.2,661.2,309.2,659.6,309.9,657.8z"/>
	<path class="st523" d="M143.1,442.2c0,0.4,0,0.8,0,1.1c-1,1.2-2.5,0.9-3.8,1.2C140,442.8,141.6,442.6,143.1,442.2z"/>
	<path class="st487" d="M102.2,688.1c-0.8,1.5-1.9,2.8-3.8,3c0.7-1,1.3-2,2-3C101,687.5,101.6,687.4,102.2,688.1z"/>
	<path class="st524" d="M128.1,450.3c0.7,0,1.5,0.1,2.2,0.1c-1.5,1.4-3.2,2.4-5.3,1.9C126,451.6,127.1,451,128.1,450.3z"/>
	<path class="st525" d="M265.2,815.7c0.4-2.1,2-2.5,3.8-2.7C268.6,815.1,266.4,814.7,265.2,815.7z"/>
	<path class="st526" d="M306.9,641.1c-0.4,0.1-0.8,0.1-1.2,0.2c-1.3-0.2-2.4-0.7-2.6-2.1C304.7,639,306.4,638.9,306.9,641.1z"/>
	<path class="st527" d="M115.8,820.7c-2.4-1.7-1.5-2.6,0-3.6C116.3,818.4,116.4,819.5,115.8,820.7z"/>
	<path class="st528" d="M242,827.8c0-0.2,0-0.4,0-0.6c1.1-1,2.4-1.5,3.9-1.4C245.5,828.2,243.6,827.8,242,827.8z"/>
	<path class="st529" d="M130.8,840.1c-0.9-2.3-0.7-3.9,0-5.5C130.8,836.1,130.8,837.6,130.8,840.1z"/>
	<path class="st530" d="M101.2,778.7c0.6,0.6,1.2,0.9,1.4,1.4c0.4,1.1-0.7,0.7-1.1,0.7c-0.6,0.1-1.4-0.1-1.2-1
		C100.4,779.5,100.8,779.2,101.2,778.7z"/>
	<path class="st531" d="M117.2,801.9c-0.4,0-0.7,0-1.1,0c-1-2.2,0.1-2.9,2-3.1c0.3,0.3,0.4,0.7,0.4,1.1
		C118.3,800.7,118,801.5,117.2,801.9z"/>
	<path class="st532" d="M254.1,820.9c0.3,0,0.7,0,1,0c-0.5,1.4-1.4,2.1-3,2c-0.4,0-0.7,0-1.1,0C251,820.7,252.3,820.4,254.1,820.9z"
		/>
	<path class="st533" d="M118.2,799.9c0-0.4,0-0.7-0.1-1.1c-0.1-1.5,0.7-2,2-2c0,0.7,0,1.4,0,2.1c-0.1,0.2-0.1,0.3-0.2,0.5
		c-0.4,0.4-0.8,0.7-1.4,0.7C118.5,800.1,118.3,800,118.2,799.9z"/>
	<path class="st534" d="M87.1,733.7c1.3-0.7,2.2-1.3,3.2-1.8C90.6,734.1,89.4,734.1,87.1,733.7z"/>
	<path class="st535" d="M102.2,688.1c-0.6,0-1.2,0-1.8,0c-0.1-0.7-0.2-1.3-0.3-2c1,0.1,2.1,0.2,3.1,0.2
		C102.9,686.9,102.5,687.5,102.2,688.1z"/>
	<path class="st536" d="M283.8,601.1c-0.2,0-0.5,0-0.7,0c0-1.7,0-3.4,0-5.1C284.8,597.5,283.8,599.4,283.8,601.1z"/>
	<path class="st537" d="M283.1,613.2c-0.4,2.6-0.8,5.2-1.2,7.9c-0.6,1.4-1.2,2.8-1.8,4.2c-0.6,0.3-1.1,0.1-1.5-0.4
		c-1.3-6.2-2.7-12.4-3.9-18.7c-0.9-10.2-3.3-20.1-5.3-30.1c-0.4-2.1-0.6-4.2-0.8-6.4c0-0.6,0.2-1.1,0.6-1.5c1.3,0,2.6,0.1,3.9,0.1
		c1,1.7,2,3.3,3,5c1.8,9.4,4.9,18.5,5.1,28.2c0,1.3,0.7,2.2,1.9,2.6C283.1,607.1,283.1,610.2,283.1,613.2z"/>
	<path class="st538" d="M245.1,429.3c2.6,6.9,2.5,14.3,4,21.5c1,4.4,1.4,9,2,13.5c-0.2,0.1-0.4,0.2-0.7,0.4c-0.5-0.1-1-0.3-1.4-0.6
		c-1.7-2.1-1.1-4.9-2.1-7.3c-1.5-6.7-2.4-13.5-3.9-20.2c-0.4-1.9-0.5-3.8,0.1-5.7C243.6,430,244.2,429.5,245.1,429.3z"/>
	<path class="st539" d="M255.1,487.1c2.9,7.9,3.2,16.2,5,24.3c0.7,3.2,0.8,6.5,1.2,9.7c-0.5,0.3-1,0.4-1.5,0.2
		c-3.2-4-2.4-8.9-3.5-13.4c-0.2-0.5-0.3-1-0.4-1.6c-0.6-5.1-2.5-10.1-2.2-15.3C253.8,489.6,253.8,488.2,255.1,487.1z"/>
	<path class="st540" d="M245.1,429.3c-0.5,0.4-0.9,0.8-1.4,1.3c-0.8-0.1-1.3-0.7-1.6-1.4c-0.9-3.3-2.1-6.4-3.2-9.6
		c-0.2-1.7-0.9-3.1-2.3-4.2c-1.2-1-2.8-2-1.5-4c4,0.6,5.5,3.8,7.1,7C243.9,421.8,245,425.4,245.1,429.3z"/>
	<path class="st541" d="M269.1,568.1c0,0.4,0.1,0.7,0.1,1.1c-0.5,0.3-1,0.3-1.5,0c-1-1-1.3-2.4-1.7-3.7c-0.6-3.8-1.2-7.7-1.9-11.5
		c-0.4-1.9-0.7-3.9,0.6-5.6c1.3-0.7,1.6,0.1,1.8,1.2c0.2,1.2-0.4,2.6,0.6,3.6l0,0c1.2,1.8-0.5,4.2,1.1,6
		C269.5,562.1,269.3,565.1,269.1,568.1z"/>
	<path class="st542" d="M260.1,521.2c0.4,0,0.8,0,1.2-0.1c0.7,0.4,0.9,1.1,0.8,1.9c1.1,1.9-0.4,4.3,0.9,6.2c0,0.6,0,1.2,0.1,1.8
		c1,1.6-0.3,3.6,1,5.2c0.1,0.3,0.1,0.5,0,0.8c0.3,1.3,1.3,2.9-1.1,3.3C260,534.3,257.9,528.1,260.1,521.2z"/>
	<path class="st543" d="M263.1,540.2c1.1-0.8,0.8-2.1,1-3.2c1.6,5.3,2.9,10.6,3,16.2c-2-1,0.1-4.1-2.5-4.8c-0.6,0-1-0.3-1.3-0.8
		C262.8,545.2,261.2,542.8,263.1,540.2z"/>
	<path class="st544" d="M255.1,487.1c-0.1,1.5-0.2,3-0.2,4.5c-0.6,0-1.1-0.2-1.4-0.7c-1.1-5-3.5-9.9-1.9-15.3
		c0.4-0.5,0.9-0.6,1.4-0.3C254.6,479.1,255.1,483.1,255.1,487.1z"/>
	<path class="st538" d="M253,475.3c-0.3,0-0.7,0-1,0c-2.6-1.3-2.1-3.8-2.3-6.1c0-1.7,0-3.5,1.3-4.9c0,0,0.1-0.1,0.1-0.1
		C252.5,467.8,253.7,471.4,253,475.3z"/>
	<path class="st543" d="M263.1,529.2c-1.8-1.8-0.7-4.1-0.9-6.2C263.5,525,262.9,527.2,263.1,529.2z"/>
	<path class="st543" d="M268.2,559.2c-2-1.7-0.8-4-1.1-6C268.7,554.9,267.8,557.2,268.2,559.2z"/>
	<path class="st543" d="M264.1,536.3c-1.7-1.5-0.9-3.4-1-5.2C264.4,532.6,264,534.5,264.1,536.3z"/>
	<path class="st545" d="M294.9,797.8c-1.9,1-3.9,2.1-5.8,3.1c-5.4,1.6-11.1,2.6-15.5,6.8c-0.7,0.6-1.7,0.8-2.6,1.2
		c-0.4-3,1.8-4.2,3.7-5.8c-3.1-1-6.1-2.2-9-3.9c-3.6-2.2-6.7-1.4-9.5,1.9c-1.1,1.4-2.1,3.1-3.8,3.8c-2.2,0.9,0.5,1.4,0,2.1
		c-0.3,1.7-1.4,2.5-3,2.5c-1.3-0.1-2.5-0.5-3.8-0.4c-2.9,0.3-5.4,1.1-4.6,4.9c-0.2,0.4-0.5,0.8-0.8,1.2c-1,0.8-2,1.2-3.3,1.1
		c-1-1.1-1.8-0.7-3.2-0.2c-3.7,1.4-6.9,5-11.4,3.3c-0.1,0-0.2-0.2-0.3,0c-0.8,2.9-0.9,2.8-1.7,3.6c-1.1,1.1-3.1,1.4-3,3.6
		c-0.2,0.4-0.5,0.7-1,0.9c-0.7,0,0.2,0.1-0.5,0.1c-3.5,0-3.4,0-5.8,2.2c-1.2,1.1-2.8,2.1-4.5,1.4c-2.7-1.1-3.8,0.3-4.5,2.6
		c-3.6-1.1-10.9,3.6-11.5,7.3c-0.2,1.6,0.8,1.5,1.8,1.7c1.7,0.2,4.4-0.4,4.2,2.1c-0.3,2.7-3.1,1.6-4.9,1.9c-0.5,0.1-1,0-1.5,0
		c-4.9-2.5-8.5-6.6-12-10.8c-0.9-3,2.2-4.3,2.9-6.6c0.2-0.4,0.5-0.8,0.7-1.2c6.7-5.5,15-8.5,22.2-13.1c1.5-1,3.2-1.8,4.7-2.8
		c2.3-1.2,4.7-2.3,6.9-3.6c5.7-3.2,11.4-6.5,17-9.7c1.6-0.9,3.1-1.9,4.9-2.1c3,1.3,3.4-1.8,4.9-3c2.4-1.5,4.6-3.3,7.6-3.6
		c5,0.3,9.9-0.5,14.8-1.7c3.2-0.8,5.7-0.2,6.6,3.3c0.8,3.3,3.8,2.7,6.2,3.7c-2.8-2.3-3.3-10-1.1-12.2c1.2-1.2,2.1-0.3,3.1,0.3
		c1.9,1.4,2.2,3.6,2.8,5.7c1.2,4.4,4,5.1,7.2,1.9c0.2-0.2,0.4-0.5,0.7-0.8c4.9-5.2,7.2-4.6,9.4,2.2
		C297.5,794.5,296.2,796.2,294.9,797.8z"/>
	<path class="st546" d="M177.1,835.9c0.8-0.1,2.3-0.3,2.3-0.3c0.8,5.3,5.8,6.6,9.1,9.4c0.4,0.4,0.4,1.2,0.6,1.8
		c0,2.2-2.2,2.2-3.1,3.3c0,2.2,4.5,0.4,3.1,3.7c0.2,0.5,0.3,1.1-0.1,1.4c-4.8,3-9.6,6.1-15.3,7.4c-3.6-3.5-7.7-2.5-11.7-1.1
		c-1.3-0.4-1.9-1.4-2.4-2.6c-0.2-0.7-0.3-1.4-0.3-2.1c0.2-1,0.5-2.2,1.7-1.9c1.5,0.3,2.1,0.1,2.1-1.5c0.1-4.6,3.6-8.6,2.8-13.5
		C170.6,841.3,173.2,836.7,177.1,835.9z"/>
	<path class="st547" d="M239.9,814.8c0.1-0.2,0.2-0.5,0.2-0.7c0.8-0.9,1.7-1.6,3-1.6c2,1,4.7,1.2,5.2,4.1c-0.7,2.3-2.4,3.9-4.1,5.3
		c-2.1,2.9-5.7,3.5-8.2,5.8c-6.2,3.1-11.9,7.2-18.5,9.6c-0.6,0-1.3-0.1-1.8-0.6c-4.5-3.7-5.1-3.6-8.4,1.2c-0.5,0.6-1.8,0.6-1.7,1.3
		c0.1,0.7,1.2,0.6,2,0.8c0.8,0.3,1.8,0.5,1.5,1.7c-1.3,0.7-2.5,1.7-3.8,2.1c-1.2,0.4-2.1,2-3.5,1.1c-1.8-1.2,0.6-2.7,0.1-4
		c-0.9-1.8,0.4-2.9,1.3-4.1c0.2-0.3,0.5-0.7,0.7-1c1.7-5.3,7.2-5.6,11-8.2c0.6-0.4,1.6-0.2,2.3-0.8l0,0c4.1-4.1,9.1-6.6,14.5-8.4
		C235.7,820.3,237.8,817.5,239.9,814.8z"/>
	<path class="st548" d="M217.1,826.9c-4,3.5-9.8,4.6-13.2,9c-1.4-0.1-2.2-0.8-2.7-2c-1.1-3.4-1.6-6.4,3.6-3.9
		c2.5,1.2,3.9-0.5,5.6-1.6c-0.4-1.4-3.5-1-2.3-3c0.7-1.2,1.9,0.2,3,0.8C212.6,827.1,215,826.7,217.1,826.9z"/>
	<path class="st549" d="M207.1,837.9c-1.3-4.1,2.5-3.8,4.4-5.1c2.1-1.6,2.7,0.6,3.7,1.7c0.7,0.8,1.5,1.7,2.2,2.5
		c0.2,0.4,0.1,0.7-0.3,0.9c-2.7,1.3-5.3,2.6-8,3.9c-0.4-0.8-1.2-0.9-2-0.9C206.2,839.9,206.3,838.9,207.1,837.9z"/>
	<path class="st550" d="M173.1,861.9c5.5-2.4,11.5-3.7,16-8c1.3-0.3,2.7-0.7,4-1c0.4-0.3,0.7-0.3,1,0.1c-1.2,2-3.8,2.1-5.3,3.8
		c-5.3,2.7-10.6,5.4-15.9,8.1c-0.5,0.7-1.2,0.8-2,0.5c-0.9-0.6-2.4-0.9-1.7-2.3C170,861.4,171.7,861.8,173.1,861.9z"/>
	<path class="st551" d="M217.1,837.9c0.1-0.3,0.2-0.6,0.3-0.9c4.6-2.5,9.3-4.8,13.7-7.6c1.7-1.1,3.2-1.3,4.9-1.6
		c-0.6,2.3-4.1,2.1-4,4.9c-5.1,0.3-9.2,3-12.9,6.1C218.4,838.6,217.7,838.2,217.1,837.9z"/>
	<path class="st548" d="M203.1,836.9c-0.4,1.4-0.9,2.7-1.3,4.1c-1.3,0.6-2.2,2.7-4.5,1.1C199.5,840.5,200.3,837.7,203.1,836.9z"/>
	<path class="st546" d="M194.2,852.9c-0.3,0-0.7,0-1-0.1c0.3-2.6,1.7-1.5,3-0.9C195.7,852.7,194.9,852.8,194.2,852.9z"/>
	<path class="st552" d="M127.2,454.4c3-1.7,6-3.3,9-5c1.5,1.5,1.3,3.3,0.9,5.2c-2,6.4-8,8.7-12.6,12.3c-1.4,1.1-3.2,1.7-4.3,3.2
		c-3.3,3-7.3,5-11.1,7.2c-1.5,0.7-2.9,1.5-4.4,2.1c-3.5-1-7-2-8.6-5.8c0.2-2.6,3.9-1.1,4.2-3.6c0.7-0.6,1.3-1.1,2.3-1.1
		c2.1-0.2,4.3-1.3,6.2-1.2c3.2,0.1,2.3-1.9,2.6-3.4c1.3-2.3,4-2.6,6-3.9C120.9,459,124,456.5,127.2,454.4z"/>
	<path class="st553" d="M136.2,455.3c0-2,0-4,0-5.9c1.3-0.7,2.7-1.4,4-2c2.8-0.7,5.5-1.4,8.2-2.7c1.5-0.7,0.2-1.7,0.8-2.2
		c1.3-0.7,2.6-1.4,4-2c4.4-0.6,8-2.7,11-6c2.7-1.7,5.4-3.3,8.1-5c0.6-1.2,1.5-1.8,2.8-1.3c-0.7,4.4,3.3,5.9,5.3,8.6
		c-1.2,2.5-3.7,3-6,3.8c-6.1,1.2-11,5.1-16.5,7.7c-2.5,1.2-4.9,2.7-7.4,3.8c-2,0.6-4.1,1-6.2,0.6c-1.9-0.9-2.3,0.4-2.6,1.7
		c-1.2,0.8-0.5,2.5-1.6,3.4C137.9,458.1,136.9,456.9,136.2,455.3z"/>
	<path class="st554" d="M96.2,473.3c2.7,1.6,3.9,5.6,8,5c0.5,4.3-3.4,4-5.7,5.4c-1.9-0.5-3.5-1.8-5.1-2.8c-4-2.4-4.1-3.6-1.1-7.6
		c1-0.7,2-1.3,2.9-2C95.9,471.8,96.2,472.5,96.2,473.3z"/>
	<path class="st555" d="M92.3,473.4c-2,3.5-1.3,5.9,2.8,7c1.5,0.4,2.4,1.5,3.1,2.9c1,0.5,1.5,1.1,1.2,2.3c-0.8,1.3-1.7,2.4-3.5,1.8
		c-1.9-3.9-5.3-5.5-9.5-5.7c-1.3-0.1-2.6-0.1-3.5-1.2c-0.2-0.5-0.1-0.9,0.3-1.2c0.9-2.4,2.7-3.9,5-5C89.7,474.5,91,473.9,92.3,473.4
		z"/>
	<path class="st556" d="M111.2,464.2c0.8,1.4,1.5,2.9,2.7,5.1c-4.3-2.9-7.7,2.3-11.6,0c-0.3-0.2-0.5-0.4-0.3-0.7
		c0.1-0.2,0.2-0.3,0.3-0.3C104.7,465.8,108.2,465.6,111.2,464.2z"/>
	<path class="st557" d="M164.1,434.3c-1.8,5.4-5.8,6.8-11,6C156.8,438.3,160,435.5,164.1,434.3z"/>
	<path class="st557" d="M149.2,442.4c0.4,0.9,0.8,1.7,1.2,2.6c-2.3,0-3.6,2.4-5.8,2.6c-1.4,0.1-2.9-0.1-4.4-0.2
		C143.2,445.7,146.2,444,149.2,442.4z"/>
	<path class="st557" d="M127.2,454.4c-3.1,2.4-5.1,6.6-10,5.9C119.9,457.2,123.7,456.1,127.2,454.4z"/>
	<path class="st558" d="M175.1,428.3c-1,0.4-1.9,0.7-2.9,1.1c-0.7,0-1.4,0-2.1-0.1c0.7-0.6,1.4-1.3,2.1-1.9c2-0.5,3.8-1.3,5.2-2.9
		c0.6-0.1,1.1-0.2,1.6-0.3c0.4,0.3,0.6,0.8,0.6,1.3C178.9,427.6,177.2,428.3,175.1,428.3z"/>
	<path class="st556" d="M96.2,473.3c-0.8-0.4-1-1.1-1-1.9c1-0.4,2-0.7,3-1.1c0.7-0.6,1.3-0.6,2,0c0.5,0.6,1.5,1.2,1.4,1.7
		c-0.2,1.4-1.2,0.3-1.8,0.3C98.5,472.2,97.4,472.8,96.2,473.3z"/>
	<path class="st557" d="M100.2,470.3c-0.7,0-1.3,0-2,0c1-1.2,2.4-1.8,4-2c0,0.3,0,0.7,0,1C101.6,469.6,100.9,470,100.2,470.3z"/>
	<path class="st559" d="M156.2,843.8c-0.1-0.3-0.3-0.6-0.4-1c-1.1-0.8-1.8-1.8-2.2-3.1c-1-5.3-2.4-10.5-2.3-16
		c2-3.8,6.8-4.4,9.3-7.7c1.4-1.8,3.4-3.2,2.7-5.9c-0.1-0.5-0.2-0.9-0.2-1.4c-0.1-1-0.1-1.9,0.1-2.9c0.4-1.4,0.7-2.9,1.8-4
		c1.6-0.4,2.2,0.9,3,1.8c1.9,2.7,4,5.4,2.9,9c-1.3,4.4-6.1,6.4-7.6,10.7c-1.7,4.4,0.7,8.7,0.2,13.1c-0.4,4-1.6,7-5.8,8
		C157.1,844.4,156.6,844.2,156.2,843.8z"/>
	<path class="st560" d="M139.2,847.9c-2.5-2.2-2.1-5-1.8-8.4c4.8,3.6,10.4,4.3,15.8,5.4c1.7,1.2,2.1,2.9,1.8,4.9
		c-1,3.9-3.9,4.7-7.5,4.7C142.8,854.5,140.1,852.3,139.2,847.9z"/>
	<path class="st561" d="M154.8,840c0.3,0.9,0.7,1.9,1,2.8c-1,0-2,0.3-3,0c-9.7-2.3-13.1,0.5-12.6-13.3c0.1-2.8-1.3-4.2-3.1-5.7
		c1.3-0.6,2.7-1.1,4.2-0.8c1.3,0.7,2.8,0,4,0.6c2.1,1.3,2.5,3.3,2.4,5.5c-0.1,3.2,1.7,6,2.9,8.8C151.3,839.5,152.7,841,154.8,840z"
		/>
	<path class="st562" d="M154.1,849.9c-0.3-1.7-0.7-3.3-1-5c1-0.4,2.1-0.8,3.1-1.2c0.2,0.1,0.4,0.2,0.7,0.2c3,3.9,2.3,8.6,3.2,13
		c0,0.6,0.1,1.2,0.1,1.8c0.6,1.5,1.2,3-0.4,4.3c-0.8,0.2-1.6,0-2.2-0.5c-1.3-1.3-2.2-2.9-2.6-4.7
		C154.7,855.2,153.2,852.7,154.1,849.9z"/>
	<path class="st563" d="M154.1,849.9c0.4,2.7,2.1,5.1,2,8c-5.7,1.3-10.8,0.6-15-4c-1.9-1.6-2.1-3.7-2-6c3.7,5.2,7.9,6.5,12.5,4.6
		C153.3,851.9,152.9,850.3,154.1,849.9z"/>
	<path class="st564" d="M141.2,853.9c4.6,2.8,9.9,2.9,15,4c-0.1,1.7,1.5,2.6,2,4c-4.1,1.8-7.6,0.1-11-2c-2.8-0.1-3.7-3.1-6-4
		C141.2,855.2,141.2,854.5,141.2,853.9z"/>
	<path class="st565" d="M120.2,721c-1.3,0.5-0.6,3.4-3.8,2.2c-2.8-1-5.3,1.5-5.2,5.1c0,1.2,0.2,3-1.6,2.9c-1.4-0.1-1.4-1.7-1.3-2.8
		c0.2-4.1-3.8-6.5-4-10.4c8.4,0.7,16.1-2.3,24-4.2c1.3-0.1,2.7,0,3.8,0.8c1,1.8-0.5,2.5-1.5,3C127.2,719.1,124,721,120.2,721z"/>
	<path class="st566" d="M120.2,721c3.4-3.1,8.7-2.7,12-6c0.2-1.3,1.1-2.1,2.3-2.6c2.3-0.1,4.6-0.1,6.9-0.4c0.7,0.1,1.4,0.3,1.9,0.8
		c-1.4,3.9-4.3,6.7-7.1,9.5c-0.6,1-1,2.1-2,2.8c-2.4-0.5-4.8-1.4-7.4-0.4c-1,0.1-2,0-2.7-0.9C122.9,723,121.5,722,120.2,721z"/>
	<path class="st567" d="M128.2,715c-6.9,1.4-13.3,5.2-20.7,4c-1.1-0.2-2.2-0.6-3.3-1c-0.7-0.3-1.3-0.7-2-1c-0.3-1-0.7-2-1-3
		c0-0.3,0-0.7,0-1c0.2-0.1,0.5-0.3,0.7-0.4c5.7,3,11.6,2.1,17.5,1c1.6,0,3.2-0.5,4.7-0.6C125.7,713,127.8,712.3,128.2,715z"/>
	<path class="st568" d="M124.2,724c1,0,2,0,2.9-0.1c2.2-0.7,3.9-1.2,3.3,2.5c-0.3,2,0.1,4.2-0.7,6.2c-2.5,1.6-2.3-0.9-2.7-2
		c-1.2-3.3-2.9-3-5.3-1.3c-2.7,2-4.2,5.1-6.8,7.2c-1,0.6-2.1,1.3-3.3,0.4c-0.2-0.4-0.4-0.7-0.5-1.1c2.2-2.1,4.4-4.1,5.5-7.3
		C117.8,725.6,121.6,725.5,124.2,724z"/>
	<path class="st569" d="M135.2,780.5c-4.2-5.9-10.5-1.8-15.7-3.4c-6-1.9-12.8-0.8-17.9-5.4c-1.4-1.3-3.3-2.3-3.3-4.6
		c7.1,2.6,14.6,3.3,22.1,4c6.7,0.3,13.1,2.9,19.8,2.7c2-0.1,3.9,0.4,5.5,1.7c4.4,1.5,2.8,3.8,0.8,6.2c-3.4,0.5-6.9-0.7-10.3-0.1
		C135.6,781.4,135.3,781,135.2,780.5z"/>
	<path class="st570" d="M135.2,780.5c0.3,0.2,0.6,0.3,0.9,0.3c1.4-0.4,2.8-0.6,4.1,0.3c0.7,4.2,0.8,8.2-3.2,11
		c-3,1.4-6.1,2.5-9.5,1.7c-0.5-0.2-0.9-0.5-1.2-0.9c0-0.3-0.1-0.6-0.2-0.8c-1.4-3.6-3.1-7.1-0.9-11
		C128.6,780.8,131.9,780.6,135.2,780.5z"/>
	<path class="st571" d="M120.2,772c-5,0.6-9.8-1.1-14.7-1.2c-2.9-0.1-5.1-2.1-7.3-3.8c-0.7-0.7-1.3-1.3-2-2c0.1-1.4-0.4-2.7-1-4
		c-0.5-0.5-0.4-0.9,0.1-1.3c1.2-0.1,2,0.4,2.7,1.2c1.8,3.1,4,5,8.1,5.2c5.3,0.2,10.7,0.7,16,2.1c1.2,0.6,2.4,1.2,2.3,2.8
		C123.3,772.5,121.9,772.9,120.2,772z"/>
	<path class="st572" d="M283,642.2c2.7-0.3,5.4-0.7,8-1c3.4,2,9.3,1.5,8,8.1c-1.1,5.5-0.1,10.7,2.5,15.7c0.4,0.9,0.5,1.9,0.4,2.9
		c-1.3,3.3-3.6,4.1-6,1.6c-2.3-2.3-3.7-2.3-5.1,0.6c-0.7,1.4-1.8,1.9-3.2,0.8c-2.1-3-2-6.6-2.9-9.9c-0.5-4.6-2-9-2.6-13.6
		C281.9,645.5,281.6,643.7,283,642.2z"/>
	<path class="st573" d="M298,649.1c-0.1-2.4,0.9-5.4-3.7-4.4c-0.8,0.2-2.2-2.3-3.3-3.6c0,0,0.1-0.1,0.1-0.1c1.6-0.6,3.3-1.1,4.9,0
		c0.7,0,1.3,0,2,0c1.4,0.1,2.8,0.3,4,1c2.1,2.4,7.5,3.8,2.2,8.2c-0.6,0.5-0.4,2,0.4,2.7c3.9,3.4,3.9,3.4,0.3,7.1
		C302.5,656.5,299.7,653.2,298,649.1z"/>
	<path class="st574" d="M298,649.1c3.3,3,6.7,6,7,11c0,0.3,0,0.7,0,1c-1.6,1.1-2,3.3-4,4C297.6,662.2,296.1,654.6,298,649.1z"/>
	<path class="st575" d="M283,642.2c0,6.5,2.5,12.6,2.9,19c-0.9,0.9,0.2,2.4-1,3.1c-2.6-0.5-1.7-3-2.5-4.5c-0.1-0.4-0.3-0.9-0.3-1.4
		c-0.3-1.8-0.3-3.7-0.9-5.5c-0.4-1.6-0.5-3.3-1-4.9c-0.2-1-0.2-2,0-3c0.4-1.2,0.7-2.5,1.9-3.2C282.4,642,282.7,642.1,283,642.2z"/>
	<path class="st576" d="M301,665.1c1-1.6,0.8-4.5,4-4c-0.2,1.5,0.4,2.9,1.1,4.2c-0.4,0.9-0.7,1.9-1.1,2.8c-0.4,0.3-0.9,0.6-1.3,0.9
		c-1.2,0.5-2-0.1-2.7-0.9C301,667.1,301,666.1,301,665.1z"/>
	<path class="st572" d="M296,641.1c-1.6,0-3.3,0-4.9,0c1-1.3,2.6-1.4,4-1.8C296.8,638.9,296.3,640.2,296,641.1z"/>
	<path class="st577" d="M282.2,641.9c-0.2,1.1,0.1,2.4-1.1,3.2c-1.8-1.3-2.3-3-1.3-5c0.8-0.5,1.5-0.5,2.2,0.1
		c0.1,0.4,0.2,0.7,0.3,1.1C282.2,641.5,282.2,641.7,282.2,641.9z"/>
	<path class="st572" d="M302,642.1c-1.4-0.1-3,0.5-4-1C299.8,639.4,300.8,641.1,302,642.1z"/>
	<path class="st578" d="M85.2,583.2c-1-5.3-0.6-10.9-3-16c0,0,0,0,0,0c-0.9-1.4-0.3-3-0.5-4.6c0.2-1.4,0.8-2.5,2.2-2.9
		c0.5,0,1,0.1,1.4,0.4c1.5,3.6,2.6,7.3,6.4,9.3c0.9,3.7,3.5,7.1,1.9,11.2c-1,0.5-3.2,0-1.9,2.3c1.2,2.2,0.7,4.3,0.2,6.7
		c-0.9,3.7-1.3,7.6,1.1,11c1.3,1.8,0.4,3.9,1.1,5.8c0.2,0.9,0.5,1.8,0.2,2.8c-0.3,0.4-0.8,0.5-1.2,0.6c-2.6-0.4-2.8-2.9-4-4.6
		C88.8,597.6,87.2,590.4,85.2,583.2z"/>
	<path class="st579" d="M95.2,632.1c0-2.3,0-4.6-0.1-6.9c-0.8-1.6-0.3-3.5-1.3-5c-0.2-1-0.2-2,0-3c0.5-1,1.3-1.5,2.4-1.2
		c1.8,4.2,4,8.2,6.6,12c0.1,0.8,0,1.5-0.4,2.2c-0.7,0.8-1.7,1-2.6,1.3C98.4,631.7,97,633.5,95.2,632.1L95.2,632.1z"/>
	<path class="st580" d="M94.4,617.1c0,1,0,2-0.1,3c0.4,0.7,0.5,1.4-0.2,2c-0.3-0.7-0.9-1.5-0.9-2.2c0.3-4.4-0.3-8.5-3.5-12
		c-0.6-0.6-0.4-1.9-0.6-2.8c2,0.7,2,3.5,4.1,4.1c2.2,1.2,4.8-1.2,6.9,0.7c0.5,1,0,1.7-0.5,2.5C98.3,614.4,95.4,614.7,94.4,617.1z"/>
	<path class="st581" d="M94.2,622.1c0.1-0.7,0.1-1.3,0.2-2c1.4,1.5,0.7,3.3,0.9,5C94.1,624.4,94.4,623.2,94.2,622.1z"/>
	<path class="st582" d="M301,668.1c0.9,0.3,1.8,0.6,2.7,0.9c-3.3,5.4-9.1,8-13.6,12.1c-0.2-0.1-0.4-0.2-0.6-0.4
		c-1.9-2.6-2-5.6-2.1-8.7c0.2-0.5,0.5-0.8,0.9-1c1.4-0.2,1.7-1.2,1.8-2.5c0.2-2,1.7-2.7,3.3-3.1c1.8-0.5,1.9,1.4,2.5,2.4
		C297.6,670.6,299.2,670.9,301,668.1z"/>
	<path class="st583" d="M296.1,714.8c0,1.4-0.1,2.8-0.1,4.2c0.7,1,0.6,2.1,0.1,3.2c-2.9-0.5-2.3-3.2-2.9-5c-0.6-1.7-0.6-3.5-1.1-5.2
		c-1.2-6.6-2.3-13.3-3.5-19.9c-0.3-1.8-0.9-3.5-0.2-5.3c0.2-0.5,0.6-0.9,1.2-1c1.8,0.5,0.4,2.5,1.6,3.3c0.7,3.6,1.4,7.2,2.1,10.8
		c1,1.6-0.3,3.7,1,5.2C294.7,708.3,295.4,711.6,296.1,714.8z"/>
	<path class="st460" d="M291,689.1c-1.2-0.7-1-1.9-1.1-3c-0.6-0.8-0.5-1.5,0.2-2.2C291.4,685.4,290.9,687.3,291,689.1z"/>
	<path class="st460" d="M294.1,705.1c-1.7-1.5-0.9-3.4-1-5.2C294.4,701.4,294,703.3,294.1,705.1z"/>
	<path class="st584" d="M290.1,683.9c-0.1,0.7-0.1,1.4-0.2,2.2c-0.3,0-0.6,0-0.9,0c-0.4-0.3-0.7-0.8-0.9-1.3
		c-0.1-1.6-0.7-3.4,1.8-3.7c0,0,0.2,0.1,0.2,0.1C290.1,682,290.1,683,290.1,683.9z"/>
	<path class="st585" d="M154.8,840c-1.7,2.3-4.1,1.2-4.9-0.2c-1.7-3.1-3.3-6.5-3.4-9.9c-0.1-2.3-0.5-4-1.4-5.9
		c0.2-0.4,0.5-0.8,0.9-1c1.5-1,3.1-1.2,4.8-0.9c0.5,0.1,0.8,0.4,0.9,0.9C152.8,828.6,153.8,834.3,154.8,840z"/>
	<path class="st586" d="M151.8,823c-0.3-0.3-0.6-0.6-0.9-0.9c0.4-0.4,0.8-0.8,1.1-1.2c2-1.6,3.5-3.6,4.3-6.1
		c0.5-1.1,0.8-2.2,1.6-3.1c1.9-1,3.7-2.4,6.1-1.6c2.3,2.8-0.1,4.5-1.7,6.1C159.4,819.1,156,821.6,151.8,823z"/>
	<path class="st587" d="M129.2,820.9c-2.8-0.8-6-0.1-8-3c0.9-0.9,2-1,3.2-0.8c5,0.9,9.9,0.3,14.7-1.2c2.5-0.5,5-0.6,7.5-0.1
		c1.6,0.7,2.4,1.6,0.6,3C141.2,819.3,135.3,821.2,129.2,820.9z"/>
	<path class="st588" d="M147.2,818.9c0.1-0.7,1.1-1.3,0-2c0.4-3,3.3-2.6,5.1-3.7c1.9-0.3,3.7,0,4.9,1.7c-0.6,2.9-1.2,5.8-5.1,5.9
		C152.1,816,149.3,818.4,147.2,818.9z"/>
	<path class="st589" d="M146.1,822.9c-0.3,0.3-0.6,0.7-0.9,1c-1.3-0.2-2.8,0.4-3.8-0.9C142.9,823,144.5,823,146.1,822.9z"/>
	<path class="st590" d="M83.2,479.2c0,0.4,0,0.7,0.1,1.1c0.3,2.6,2.5,2.7,4.3,3.4c2.7,1,5.4,2,5.2,5.6c-0.7,2.9-1.9,4.4-5.1,2.3
		c-2.2-1.4-5.2-0.8-7.5-2.2c-0.4-0.2-0.8-0.5-1.2-0.8c-0.7-1-1.3-2.1-0.7-3.3c0.6-1.4,2.2-2.3,2-4.1
		C80.9,479.9,81.9,479.3,83.2,479.2z"/>
	<path class="st591" d="M295.8,722.3c0.1-1.1,0.1-2.2,0.2-3.4c1.8,5.2,2.9,10.5,3,16c0,1.4-0.1,2.8-0.1,4.2c-0.6,0.1-1,0-1.4-0.5
		c-1.8-3.7-1.4-7.8-2.4-11.7C294.8,725.4,294.6,723.7,295.8,722.3z"/>
	<path class="st592" d="M252.1,807c-0.3-0.4-1-0.8-0.9-1.1c1-7.6,10.2-11.7,16.2-7c2.7,2.1,6.6,1.4,8.7,4.4c-2,1.6-3.6,3.5-5,5.7
		c-3.9,2.2-7.2,5.5-11.9,6c-0.4-2.4,1.9-2.8,3.1-3.9c1.5-1.3,3.4-2.1,3.9-4.3c0.5-5.1-2.8-3.4-5.4-2.9c-1.7,0.6-3.2,1.7-4.7,2.7
		C254.8,807.2,253.5,808.1,252.1,807z"/>
	<path class="st593" d="M252.1,807c1.2-0.4,2.4-0.8,3.6-1.3c1.7-0.9,3.5,0.3,5.3-0.3c1.1-0.4,3.2,1.8,3.1-1.3c2.2-0.1,3.9,0.2,3.1,3
		c-0.6,4.7-6.2,4.3-8.1,7.8c-0.3,0.3-0.7,0.7-1,1c-2.8,0.9-5,2.9-7.5,4.5c-0.8,0.5-1.6,1.1-2.6,0.4c-1.3,0.4-2.6,0.8-3.8,1.1
		c0.2-2.5,2.4-3.5,3.8-5.1c0.6-0.6,1-1.4,1.5-2.1c1.2-2.6,2.3-5.4,6.5-3.8c-3.6-1.5-5.2,1.8-7.9,2.3c-1.2-0.1-1.6-0.9-1.6-2
		c0.4-1.3,1.2-2.1,2.5-2.3C250.5,809,251.5,808.3,252.1,807z"/>
	<path class="st594" d="M248.1,820.9c0.5-0.4,1.1-0.7,1.6-1.1c2.2-0.3,3.7-3,6.3-1.9c0,0.4,0.1,0.9,0.1,1.3c-0.7,0.6-1.4,1.2-2,1.7
		c-1,0.7-2,1.3-3,2c-0.9,0.1-1.9,0.3-2.8,0.4C248.1,822.5,248.1,821.7,248.1,820.9z"/>
	<path class="st551" d="M256,817.9c-2.3,0.1-3.9,2.3-6.3,1.9c2.7-1.6,5-4,8.4-3.8C257.4,816.6,256.7,817.2,256,817.9z"/>
	<path class="st595" d="M303.2,768c1.4-1.5,0.4-3.5,0.9-5.1c2.1,7.3,2.9,14.6,0.8,22c-1.5,0.2-1.6-0.9-1.9-1.9c-0.2-1-0.1-2,0.2-3
		c0.8-2.7,0.8-5.5,0.2-8.2c-0.3-0.9-0.6-1.7-0.7-2.6C302.6,768.6,302.8,768.3,303.2,768z"/>
	<path class="st596" d="M303.8,782.6c0.4,0.8,0.7,1.5,1.1,2.3c-0.3,1-0.7,2-1,3.1c-0.7-0.4-1.4,3.2-2,0
		C300.5,785.4,301.3,783.7,303.8,782.6z"/>
	<path class="st597" d="M300.1,750c-0.1,2.3,0.1,4.6,1,6.7c-0.6,5.1-5.2,5.7-8.6,7.1c-2.9,1.2-6,1.6-8,4.3c-0.7,0.9-1.8,1.8-3,1.5
		c-5.9-1.1-8,2.4-9,7.3c-1,1.2-2.3,1.7-3.8,2c-2.8,0.5-6,0-7.6,3.2c-0.4,0.2-0.9,0.3-1.3,0.3c-4.6-0.2-7.8,2.9-11.5,4.9
		c-0.8,0.4,0.5,1.4,0,2.2c-0.5,0.9-1.4,1.2-2.3,1.3c-1.8-0.2-3.6-0.4-4.1-2.8c-0.1-0.3-1.6-3.4-3-0.2c-1,2.1-3.2,2.4-5.2,2.3
		c-5.8-0.5-10.7,1.6-15.5,4.4c-4,2.3-8.1,3-11.8-1c-2.9,6.4-9.6,4.2-14.2,6.7c-2.6-3.3,1.2-5.6,1.4-8.5c0.7-1.6,2.1-2.2,3.6-2.1
		c3.8,0.1,7.5-0.7,11.2-1.2c2.1-0.3,3.7,0.7,5.5,1.9c-0.8-2.9-0.8-5.3,1.5-7.3c1.3-1.2,0.7-2.7,0.4-4.2c0.1-0.5,0.3-1.2,0.7-1.3
		c7.6-1.8,12.6-8.5,20.1-10.5c1.3-0.3,1.8-1.4,2.6-2.1c1.6-1.3,3.3-1.3,5.1-0.2c1.2,3.3,3.4,4.2,6.6,3c5.1-1.9,11.1-1.7,14.6-7.3
		c1.4-2.3,4.4-3.6,7.5-2.9c6.2,1.3,10.7-1.8,14.5-6c2.2-2.4,4.8-4.1,7-6.4c2.2-2.2,3.1-0.6,4.1,1C299.9,747.2,299,749,300.1,750z"/>
	<path class="st598" d="M298,747c-1.1,1,0.4-3.4-2.5-1c-3.4,2.8-6.3,6.2-9.8,9c-4,3.3-8.2,4.7-13.3,3.7c-3.8-0.7-6.3,1.9-7.8,4.7
		c-1.1,2.1-2.6,3.4-4.4,3.3c-3.9-0.1-7.5,1.6-10.9,2.7c-5.5,1.7-5.1-1.1-5.2-4.4c2.9-3.3,6.5-5.7,10.3-7.5c3.5-1.6,6.8-3.4,9.5-6.1
		c1.3-0.7,2.8-0.7,3.9-2c4.5-5.4,11.1-7.9,16.1-12.8c2.4-2.4,4.8-5.4,9-5.3c2.1,0,1.5-3.1,3-4.2c1.4,3.7,1.6,7.7,2.1,11.6
		c0.9,1.7-0.1,3.6,0.7,5.3c0.1,0.3,0.1,0.7,0.2,1C299,745.8,298.7,746.5,298,747z"/>
	<path class="st599" d="M272,776.9c0.1-7,1.7-8.4,8.8-8.1c0.6,0,1.7,0,1.9-0.3c3.3-6.5,10.8-4.9,15.7-8.3c1.1-0.8,1.8-2.2,2.7-3.4
		c1.5,3.6,1.6,7.5,2.1,11.2c-0.2,0.3-0.2,0.6-0.2,0.9c0.5,2.7,2.1,5.3-0.2,8c-2.4-0.5-1.6-3.1-3.2-4.4c-0.7,2.5,1.1,4.5,0.8,6.7
		c-0.3,2.1-1,4.2-3.1,6c-2.4-2.6-2.4-5.6-2.1-9.2c-4.8,6.2-10.5,7.7-17.4,4.8c-2.1-0.9-5.1,1.4-6.6-1.8
		C271.3,778.1,271.5,777.4,272,776.9z"/>
	<path class="st600" d="M298,747c0-0.8,0.2-1.6,1-2c1.5,1.4,1,3.3,1.1,5.1C299.4,749,298.7,748,298,747z"/>
	<path class="st600" d="M298.8,744c-1.4-1.6-0.7-3.5-0.7-5.3c0.3,0.1,0.5,0.3,0.8,0.5C298.9,740.8,298.8,742.4,298.8,744z"/>
	<path class="st601" d="M84.2,560.2c-0.7,0.7-1.3,1.3-2,2c-0.3,0.5-0.7,0.5-1,0l0,0c-1.1-1.5,0.2-3.5-1-5c-1.3-7.7-1.2-15.6-4-23
		c0-0.3,0-0.6,0-0.9c-1.2-1.5,0.2-3.5-1-5.1c-0.3-3.3-0.7-6.6-1-9.9c1.9-0.5,2.6,1,3.3,2.2c0.9,1.5,0.7,3.3,0.6,5
		c2.6-0.3,2.9,2.1,4.2,3.4c1.5,1.4,0.7,3.8,2,5.8c0.9-2.1,0-3.7-0.5-5.3c-1.1-3.3,1.1-5.3,3.1-7.2c1.8-1.7,3.8-1.7,5.7,0.2
		c0.6,0.9,0.8,1.9,0.9,2.9c-0.1,1.5,0.1,3.5-1.5,3.8c-2,0.3-1.3-2-1.8-3.1c0.1,5.4,2.5,10.5,2.5,15.9c0,2.6,0.1,5.2,0.4,7.8
		c0.7,4.8-0.8,7.2-5.5,8C85.7,558,85.3,559.3,84.2,560.2z"/>
	<path class="st473" d="M81.3,562.2c0.3,0,0.7,0,1,0c0,1.7,0,3.3,0,5C80.6,565.8,81.5,563.9,81.3,562.2z"/>
	<path class="st473" d="M80.3,557.2c1.6,1.4,0.7,3.3,1,5C79.6,560.8,80.5,558.9,80.3,557.2z"/>
	<path class="st602" d="M171.1,864.7c0.6,0,1.3,0,1.9,0c0.4,0.2,0.8,0.3,1.2,0.5c-0.7,1.4-1.9,1.6-3.3,1.5c-0.8-1.5-2.7-0.3-3.6-1.6
		C168.4,863.6,169.8,864.4,171.1,864.7z"/>
	<path class="st603" d="M99.2,707c-3.6-8.9-2.9-11.7,5-18c0.4-0.4,0.8-0.4,1.2,0c0.4,1.3-0.1,2.3-0.9,3.3c-2.3,2.4-5.7,4.2-4.3,8.4
		c0.6,4.6,2.9,6.6,7.8,6c5.3-0.7,10.5,1.2,15.9,0.5c2,0.7,4.2,1,5.5,3c-0.8,2.4-3,2.4-4.9,2.7c-7.4,0.5-14.8,1.4-22.2-0.5
		C99.4,711.7,100.4,708.8,99.2,707z"/>
	<path class="st604" d="M105.2,689.1c-0.4,0-0.7,0-1.1,0c0.1-0.9,0.1-1.8,0.2-2.7c0.2-0.5,0.5-0.9,1-1.2c3.5-1.5,7.1-2.1,10.9-2.3
		c1.1,0.1,2.1,0.3,2.9,1.2C117,687.2,109.5,689.9,105.2,689.1z"/>
	<path class="st605" d="M105.3,685.8c-0.3,0.2-0.7,0.3-1,0.5c-0.3-0.1-0.6-0.3-0.9-0.4c0.3-1,0.5-1.9,0.8-2.9
		c1.2-0.9,0.7-3.5,3.2-3.1c0.3,0.4,0.5,0.8,0.7,1.3C108.5,683.5,107.2,684.9,105.3,685.8z"/>
	<path class="st606" d="M99.2,707c1.4,1.3,1.6,3.2,2,5c0.3,0.2,0.4,0.4,0.3,0.6c-0.1,0.2-0.2,0.3-0.3,0.3c0,0,0,0,0,0
		C99.3,711.5,99.2,709.3,99.2,707z"/>
	<path class="st607" d="M210.2,406.5c4.7-2.9,9.7-3.1,14.8-1.1c0.1,0.3,0.1,0.6,0.1,0.9c-0.3,0.4-0.7,0.7-1.2,0.9
		c-1,0.3-2,0.3-3,0.2c-2.7-0.7-5.1,0.1-7.5,1.1c-1.8,0.7-3.5,1.8-5.6,1c-0.3-0.4-0.3-0.9,0-1.3C208.8,407.7,209.5,407.1,210.2,406.5
		z"/>
	<path class="st608" d="M235.1,411.4c-0.1,1.8,1.1,2.5,2.5,3.3c1.8,1.1,2.8,2.7,2.4,4.9c-2.3,0.3-3.4-1.9-5.3-2.4
		c-2.1-1.1-4.3,0.3-6.4-0.4c-0.4-0.3-0.6-0.7-0.6-1.2c0.2-0.9,0.9-1.5,1.7-1.9c1.6-0.6,2.9-1.7,4-2.9c0.2-0.1,0.4-0.2,0.6-0.3
		C234.4,410.8,234.8,411.1,235.1,411.4z"/>
	<path class="st609" d="M224,406.5c0.4-0.1,0.8-0.1,1.2-0.2c3,1.3,6.3,1.9,8.9,4.2c0,0-0.1,0.3-0.1,0.3c-0.8,0.9-1.7,1.5-3,1.4
		c-0.5-0.1-0.9-0.2-1.3-0.4C228,409.8,224.6,409.6,224,406.5z"/>
	<path class="st610" d="M282,640.2c-0.6,0-1.2-0.1-1.8-0.1c-2.9-2.5-2.4-6-2.6-9.3c0.2-2.1-0.4-4.4,1.6-6c0.3,0.1,0.6,0.3,0.9,0.5
		c0.4,2.6-0.8,5.3,0.8,7.8C281.2,635.4,281.6,637.8,282,640.2z"/>
	<path class="st611" d="M92.3,655.1c0-0.7,0-1.4,0-2.1c0.4-0.6,0.6-1.3,1.5-1.2c0.4,0.2,0.8,0.6,1,1c0.5,1.1,0.6,2.3,0.6,3.5
		c0.1,1.3-0.1,2.6,0.2,4.4c7.3-0.6,14.6-1.2,21.7-3.1c2.8-0.8,5.7-1.7,8.7-0.8c0.3,0.4,0.5,0.8,0.5,1.4c-1.9,3.1-5.7,2.9-8.5,4.6
		c-1,0.6-2.2,0.8-3.3,1c-9,1.2-13.3,6.6-13.7,15.4c-0.1,1.1-0.3,2.1-1,2.9c-0.3,0-0.6,0-0.9,0.1c-1.7-0.7-3.1-1.7-3.4-3.8
		c-0.9-5.4-3.1-10.6-2.6-16.2C92,659.9,93.4,657.4,92.3,655.1z"/>
	<path class="st612" d="M92.3,655.1c1.7,2.1,0.5,4.6,0.9,7c-0.3,0-0.6,0-0.9-0.1C92.3,659.7,92.3,657.4,92.3,655.1z"/>
	<path class="st613" d="M92,522.2c-5.3-0.5-8.1,3.1-6.3,7.7c0.5,1.2,0.4,2.6,0.5,3.9c0,1.4,0.3,3.3-1.9,3.3c-2.2,0-2.2-1.9-1.9-3.2
		c0.7-3.1-1.8-4.7-3-6.8c-0.4-0.6-0.7-0.4-1.8,1.8c-0.6-4,0.8-8.2-3.2-10.5c-1.1-0.8-1-1.9-1-3.1c0.6-0.5,1.4-0.7,2.2-0.5
		c5.1,2.7,10.5,4.5,16.3,5c0.7,0.5,1,1.1,0.8,2C92.4,521.8,92.2,522,92,522.2z"/>
	<path class="st614" d="M75.2,515.3c-0.7,0-1.3,0-2-0.1c-0.3-0.7-0.7-1.3-1-2c-1.4-5.9-0.7-11.8,0.3-17.7c0.6-1.4,1.2-2.8,1.8-4.2
		c0.3-0.1,0.6-0.1,0.9-0.1c1.6,1.9,0.3,3.8,0,5.7c-0.1,0.5-0.3,1-0.4,1.5C73.9,504.1,75.2,509.7,75.2,515.3z"/>
	<path class="st464" d="M75.3,528.2c1.6,1.4,0.8,3.4,1,5.1C74.6,531.8,75.5,529.9,75.3,528.2z"/>
	<path class="st612" d="M93.3,652.1c-0.3,0.3-0.6,0.7-0.9,1c-0.1-0.3-0.1-0.6-0.1-1c-0.1-2,0-4-0.5-5.9c-1.3-4.8,0-8.8,3.5-12.1
		c0.8-1,1.5-1.9,3-1.2c0.8,1.7-0.5,2.6-1.3,3.7c-1.6,1.4-2.7,3.1-2.4,5.3c0,1.3,0.1,2.5,0.1,3.8C94.4,647.9,95,650.2,93.3,652.1z"/>
	<path class="st456" d="M98.1,633.1c-1,0.3-1.9,0.6-2.9,1c0-0.6,0-1.3,0-1.9c1.8,0.3,2.6-1.7,4.3-1.8c0.7,0.4,2.2,0.3,1.7,1.6
		C100.7,633.2,99.6,633.9,98.1,633.1z"/>
	<path class="st615" d="M272,778.8c8.9,1.5,18.2,5.2,24.7-5.6c-0.8,4.1-0.2,7.4,0.6,10.7c4.3-4.4-1-9.6,1.2-14.4
		c3.5,0.8,3.7,3.6,3.4,7.3c2-3.1,0.2-5.6,1.1-7.9c0.3,0.7,0.7,1.3,1,2c0.7,3,0.6,6,0,9c-0.1,0.9-0.2,1.8-0.3,2.6
		c-1,1.6-2.1,3.2-1.9,5.3c-1,1.7-2.1,3.3-3.1,5c-4.5-5.2-4.6-5.2-9.6,0.4c-1.6,1.7-3.5,2.1-5.4,1.5c-2.4-0.7-3.7-2.8-3.7-5.3
		c0-2.8-0.9-4.8-4.5-6.2c2.1,4.3-4.6,9.8,3.5,12.1c-2.6,1.5-5.3,1.3-8,0c-1.3-0.6-3.2-0.3-1.9-3.2c0.7-1.6-1.3-3.3-3.9-2.6
		c-4.6,1.2-9.2,2-14,2.1c-0.9,0-1.6,0-2.2-0.8c0.9-3.3,4.2-3.6,6.5-5.2c4.6-1.7,8.4-5.1,13-6.8C269.7,778.9,270.8,777.9,272,778.8z"
		/>
	<path class="st616" d="M107.1,743.1c0.7,0,1.4,0,2,0c3.5,1.2,7,0.2,10.6,0.5c2.1,0.2,4.3-0.3,6.2,0.9c1.2,2.4-0.9,2.4-2.1,2.7
		c-3.2,0.8-6.3,1.8-9.5,2.6c-4-0.9-8.8,0.4-11.6-4.1C103.2,743.2,105.6,743.8,107.1,743.1z"/>
	<path class="st617" d="M126.2,745c-4.4,0-8.8,0-13.2,0c-1.6,0-3.4,0.4-3.9-1.9c0.7-0.3,1.4-0.7,2.2-1c0.6-0.6,1.4-0.9,2.3-1
		c5.9,0.1,11.7,1.6,17.6,1.5c1.9,0.3,4.3-0.4,5.2,2.3c-0.6,0.8-1.4,1.1-2.3,1.3C131.4,746.2,128.6,747,126.2,745z"/>
	<path class="st618" d="M107.1,743.1c-1.3,0.7-2.6,1.3-3.9,2c-0.7,0.9-1.5,1.8-2.4,2.6c-0.8,0.9-1.4,2-2.1,3
		c-0.9,1.2-1.2,2.9-2.8,3.6c-0.5,0-0.9-0.2-1.2-0.6c0-3.6,1.5-6.5,4.4-8.7C101.9,744.7,104.1,742.3,107.1,743.1z"/>
	<path class="st619" d="M99.2,745c-1.8,2.8-4.1,5.4-4,9c1.5,2,1.7,4,0,6c0,0.3,0,0.7,0,1c-2.1-3.5-0.5-7.3-0.9-11c1-1.7,2-3.3,3-5
		C97.9,745,98.6,745,99.2,745z"/>
	<path class="st620" d="M113.2,741.9c-0.7,0.1-1.3,0.1-2,0.2c-0.1-0.7-0.2-1.3-0.3-2c0.2-1,0.4-2,0.6-3c0.8-0.3,1.7-0.7,2.5-1
		c0.6,0.5,0.8,1.2,0.8,2C114.8,739.5,114.4,740.8,113.2,741.9z"/>
	<path class="st621" d="M147.2,859.9c3.7,0.7,7.3,1.3,11,2c0.3,0.3,0.7,0.7,1,1c1.1,0.5,2.4,0.7,2.9,2c0,0.3,0.1,0.7,0.1,1
		c-1.3,0-2.7,0-4,0c-3.5,0.5-5.7-3-9-3C148.5,861.8,147.8,860.9,147.2,859.9z"/>
	<path class="st622" d="M100.2,682c0-1,0-2,0.1-3c1.1-2.6,1.8-5.3,2.8-7.9c1.5-3.8,3.9-5.1,7.8-3.7c1.9,2.7-1,4-1.6,6
		c-1.5,1.4-1.1,4.3-3.9,4.7C101.9,677.2,102.5,681.6,100.2,682z"/>
	<path class="st623" d="M105.3,678.1c1.5-1.4,1.1-3.9,3-5.1c1-0.2,1.4,0.4,1.7,1.2c0.1,2.4-0.2,4.6-2.7,5.9c-1,1-2,2-3,3
		C104.6,681.4,104.9,679.7,105.3,678.1z"/>
	<path class="st624" d="M208,408.2c0,0.4,0,0.7,0.1,1.1c-2.4,4.4-7.1,5.1-11.2,6.6c-0.5-0.1-0.8-0.4-1-0.9
		C199.4,411.9,203.9,410.4,208,408.2z"/>
	<path class="st624" d="M191.9,417.2c0.4-0.5,0.8-1.1,1.3-1.6c0.9-0.2,1.8-0.3,2.7-0.5c0.2,0.2,0.3,0.3,0.5,0.5
		c0.2,0.5,0.1,1-0.3,1.5C194.7,418.2,193.4,418.7,191.9,417.2z"/>
	<path class="st533" d="M117.2,801.9c0-0.8,0.2-1.6,1-2c0,0,0,0,0,0c0.9,0.8,1.1,1.8,1.2,3c-0.4,4.1,0.8,7.9,1.7,11.8
		c-0.1,0.9-0.3,1.6-1,2.2c-0.3,0-0.6,0-0.9,0C117.2,812,116.9,807,117.2,801.9z"/>
	<path class="st625" d="M74.2,497.3c0.3-2,0.7-4,1-6.1c0.5-2.3,1.3-4.3,3-5.9c0.3,1,0.6,2,1,3c-0.3,2.3-1.6,4.3-2,6.5
		C76.9,496.2,76.3,497.7,74.2,497.3z"/>
	<path class="st626" d="M191.9,417.2c1.6,0.6,2.9-0.2,4.2-0.8c2.1-1.3,4.5-1.7,6.7-3.1c4-2.5,9.2-0.4,13.9-0.7
		c0.6,0,1.4,0.4,1.4,1.2c0.1,1.5-1,2.5-2.2,3c-2.3,1-4.7,1.6-6,4.1c-8.7,6-18.2,10.5-27.4,15.4c-0.7,0.4-1.6,0.5-2.3,0
		c-3,0.6-3.4-2.6-5.3-3.6c-3.6-1.8,1.7-2.9,0.3-4.5c1.3-0.9,2.7-1.9,4-2.8c2-1.9,4.6-3,6.9-4.4c0.6-0.6,1.3-1.2,2.1-1.5
		c0.3-0.2,0.6-0.4,0.9-0.5C190.1,418.4,191,417.8,191.9,417.2z"/>
	<path class="st627" d="M187.1,421.4c-2.6,1.5-4.9,3.4-7.9,4.1c0-0.4-0.1-0.9-0.1-1.3c1.7-0.9,3.4-1.8,5.2-2.8
		C185.1,420.7,186.1,420.6,187.1,421.4z"/>
	<path class="st624" d="M187.1,421.4c-1,0-1.9,0-2.9,0c1.1-1.2,2.3-2,4-1.9C187.8,420.1,187.5,420.7,187.1,421.4z"/>
	<path class="st628" d="M171.1,864.7c-1.3,0.1-2.6,0.3-3.8,0.5c-1.1-0.4-2.2-0.8-3.2-1.3c-1.2-0.7-2.4-1.3-2.1-3
		c5.1-3.4,8.5-3.1,11.1,1C174.1,864,167.9,861,171.1,864.7z"/>
	<path class="st629" d="M162,860.9c0.7,1,1.4,2,2.1,3c-0.7,0.3-1.4,0.6-2,1c-1.4,0-2.3-0.8-2.9-2c1.5-1.1,0.9-2.7,1.1-4.1
		C160.8,859.5,161.4,860.2,162,860.9z"/>
	<path class="st630" d="M120.2,798.9c0.3,0,0.7,0,1,0c1.1-0.8,2.3-0.9,3.6-1.1c7,1.3,12.2-2.7,17.6-6c1.1-0.7,1.8-2,3.3-2.1
		c2,3.4,1.3,5.1-2.6,7.7c-4.5,2.8-9.3,4.9-14.1,7c-3.3-0.5-7.5,1.6-9.5-3.1C119.4,800.4,119.7,799.6,120.2,798.9L120.2,798.9z"/>
	<path class="st631" d="M125.2,798.9c-1.3,0-2.7,0-4,0c1.3-2.4,5-2.7,5.2-6c0.3,0.1,0.6,0.2,0.9,0.3c0.6,0.6,1.3,1.2,1.3,2.1
		C128.4,797.4,126.6,797.9,125.2,798.9z"/>
	<path class="st632" d="M120.2,816.9c0-0.7,0-1.3,0-2c4.7,1,9.4,0.5,14.1,0.2c1.6,0.6,3.6,0.3,4.9,1.8c-5.8,3.2-11.9,2-18,1
		C120.9,817.6,120.5,817.2,120.2,816.9z"/>
	<path class="st499" d="M120.2,798.9c0,0.7,0,1.3,0,2c-0.7,0.6-0.3,1.6-0.9,2.2c-0.4,0.2-0.8,0.1-1.1-0.2c0-1,0-2,0-3
		C118.9,799.6,119.6,799.3,120.2,798.9z"/>
	<path class="st633" d="M268.1,569.1c0.4,0,0.8,0,1.1,0.1c1.9,9.5,3.8,19.1,5.7,28.6c0.5,2.7,0.8,5.5,1.2,8.3
		c-1.2,0.8,0.2,2.7-1.5,3.2c-3.1-2.6-2.2-6.6-3.3-9.8c-0.6-1.9-0.4-3.9-1-5.7c-0.2-0.5-0.3-1-0.3-1.5c-0.2-1.8-0.2-3.6-0.8-5.3
		c-0.3-1.1-0.4-2.1-0.4-3.2c0-3.4-1-6.7-1.4-10.1C267.1,572,266.7,570.4,268.1,569.1z"/>
	<path class="st634" d="M274.8,609.5c0.3-1.2,0-2.6,1.2-3.3c0.9,6.2,3.3,12.2,3.1,18.7c-0.1,2.2-0.2,4.5-0.4,6.7
		c-2.3-0.9-2.2-3.2-2.6-5c-0.8-3.7-1.2-7.6-2.1-11.3C273.7,613.2,273.4,611.2,274.8,609.5z"/>
	<path class="st635" d="M244,436.4c2.2,6.8,3.2,13.8,4,20.8c0.8,2.5,0.4,5,0.5,7.6c-0.1,0.3-0.3,0.8-0.6,0.8
		c-6.5,0.4-7.1,5.2-7.6,10.1c-0.1,0.7,0,1.4-0.3,2c-2.4,4.5-6.6,7.7-9.5,11.9c-1.9,2.9-6.8,0.6-7.7-3.2c-0.6-2.2-1.4-3.6-3.8-3.8
		c-1-0.1-2.2-0.2-2.8-0.9c-4.1-5-9.8-4.1-15.2-4c-5.3,0.1-10.6-1.1-15.9,0.5c-2.1,0.7-4.5,0.1-5.5-2.2c-1.1-2.4-1.3-5,0.6-7
		c2.6-2.8,5.7-5,8.5-7.4c1.3-1.1,2.8-1.1,3.9,0.4c1.2,1.6,2.7,2.1,4.3,1c1.9-1.3,1.7-3.1,0.8-4.9c-0.7-1.4-1.3-2.7,0.2-4
		c2.9-3.2,7.6-4.5,9.5-9.1c0.6-1.5,2.9-2.7,5.1-1.3c0.9,2-0.3,3.5-1.3,5c-1.3,2-1.5,3.4,1.3,4.5c3.8,1.5,3.8,2.8,0.8,5.4
		c-0.5,0.4-1.1,0.8-1.6,1.2c-0.9,0.7-2,1.4-1.7,2.8c0.3,1.5,1.7,1.4,2.8,1.5c2.2,0.1,4.3,0.1,6.5,0c1.9,0,2.9-0.8,3.5-2.8
		c1.4-5,2.7-5.7,7.7-4.8c5.6,1,5.6,1,5.7-4.6c0-0.9,0-1.9,0.6-2.3c2.9-2,2.2-5.4,3.2-8c0.4-0.3,0.9-0.3,1.4-0.1c1.5,1.3,1,2.8,1,3.7
		C243.1,442.6,241.2,439,244,436.4z"/>
	<path class="st636" d="M241.1,441.3c-0.7,3.2,0.8,7.3-3.7,9c-0.2,0.1-0.2,1.7,0.2,2.3c1.2,1.4,2.3,2.9,0.2,4.1
		c-1.8,1.1-3.2,3.5-6.4,1.3c-3.8-2.6-6.9-0.5-7.3,4.2c-0.2,2.4-1.2,3.4-3.4,3c-4.2-0.8-9.3,2.4-12.4-1.9c-1.9-2.6,1.4-4,3.5-5.3
		c1.1-0.7,2.9-1,2.7-2.6c-0.3-1.8-2.1-1-3.3-1.2c-3.5-0.5-3.5-2.4-2.1-5c1-2,3.5-3.2,3-6c-1-3.5,1-8.7,5-11.1
		c4.2-2.5,8.3-5.2,12.8-7.3c0.5-0.2,1.4,0.1,1.8-0.6c0.9-1.8,1.2-4.5,4.3-3.3c2.8,1.1,5.3,2.6,6.1,5.9c0.2,0.8,0.3,1.6,0.8,2.3
		c0.3,0.4,0.6,0.8,0.8,1.2c0.1,2,0.2,3.9,0.3,5.9c-0.4,3.3-0.8,6.6-1.3,10.3C242.1,444.6,241.6,443,241.1,441.3
		c0.8-0.8,0.4-1.7,0.4-2.6c-0.3,0.7,0.4,1.5-0.1,2.3C241.2,441.1,241.1,441.2,241.1,441.3z"/>
	<path class="st637" d="M248,465.3c0-2.7,0-5.4,0-8.1c1.6,2.1,0.1,5,2,7.1c1.2,1.6-0.1,3.6,0.9,5.2c0,2-0.4,4,1,5.8
		c0.7,5.3,1.4,10.5,2.1,15.8c0.7,1.1,0.5,2.2,0.1,3.3c-0.3,0.9-0.7,2-2,1.8c-1.3-0.3-2.2-1.4-1.7-2.6c1.8-4.2-1.2-7.7-1.4-11.5
		c-0.2-1.2-0.5-1.6-1.9-0.8c-2.6,1.6-5.5,2.2-7-1.4c-1.5-3.7,0.2-7.3,3.9-7.8C248.9,471.5,247.1,467.8,248,465.3z"/>
	<path class="st544" d="M251,469.5c-1-1.6-1-3.4-0.9-5.2c0.3,0,0.7,0,1,0C251,466.1,251,467.8,251,469.5z"/>
	<path class="st638" d="M250,482.3c0.3,3.9,5.1,7.3,1.2,11.6c-0.1,0.2,0.4,1.3,0.9,1.5c0.8,0.4,1.1-0.4,1.3-1
		c0.3-1.1,0.5-2.2,0.7-3.3c0.2,0.2,0.5,0.3,0.7,0.5c0.8,5.2,1.5,10.4,2.3,15.6c-1.5,1.1-0.7,2.6-0.8,4c0.2-1.3-0.8-2.9,0.8-4
		c1,4.7,2,9.4,3,14.1c0.3,6.5,1.9,12.7,3,19c0.3,2.7,0.6,5.4,0.9,8c0.1,4.4,0.5,8.7,2.1,12.9c0.7,1.7,0.7,3.3-1.4,4
		c-2,0.7-3-0.6-3.8-2.1c-0.5-1.1-0.6-2.2-0.7-3.4c-0.3-4.2-2.2-6-6.4-4.5c-0.7,0.1-1.5,0-2.2-0.2c-7.3-1.7-8.4-4.2-4.5-10.8
		c1.6-2.6,2-4.9-0.1-7.3c-2-2.2-2.2-4.7-1.2-7.4c1.4-4,0.6-8.3,0.9-12.4c0.2-1.7-0.1-4.2,2.5-4.1c2.4,0,2.8,2.1,2.6,4.1
		c-0.2,2.7,1.5,5.1,1.2,7.8c-0.3,2.5,0.4,4.8,1.8,6.9c-1.6-2.7-2.1-5.6-1.8-8.6c0.5-5.7-1.3-11.3-2.9-16.5c-1-3.3-3.5-6-2.1-9.9
		c0.9-1.9,0.1-3.8,0-5.7C247.8,488,246.5,484.6,250,482.3z"/>
	<path class="st639" d="M242.9,429.3c-1.8-1.7-2-4.4-4.6-5.8c-2.4-1.2-3.9-3.4-5.1,1c-0.7,2.6-4,2.7-5.9,3.7
		c-5.1,2.8-10.6,4.6-13.8,10.1c-1,1.7-1.5,3.2-1.4,5c-2.1,1.3-4,2-5.2,4.9c-1,2.6-5.1,4.1-7.8,6.1c-0.2-0.1-0.4-0.2-0.6-0.3
		c-1-0.9-2.1-1.6-1.5-3.2c0.8-1.4,2.2-2.2,3.7-2.6c1.9-0.4,2.9-1.7,3.5-3.4c0.3-0.9,0.7-1.7,1.1-2.6c1.5-3,2.1-6.2,2.6-9.4
		c0.4-2.5,0.9-4.9,3.7-5.9c0.8-0.1,1.4,0.2,1.8,0.9c0.2,1.2-0.4,2.2-0.8,3.3c0.9-0.3,1.2-1.4,2.1-1.9c0.4-0.2,0.8-0.4,1.2-0.5
		c4.4-2,8.9-4,12.3-7.7c3.6,0.2,4.1-4.3,7.2-4.8c2,0.4,3,2.3,4.6,3.3C241.4,422.7,242.9,425.8,242.9,429.3z"/>
	<path class="st640" d="M252,554.2c0.7,0,1.3,0,2,0c3.7,1.2,5.5,4,6.5,7.5c1,3.2,2.6,5.4,6.4,4.6c1.1,0.7,0.7,1.9,1.1,2.8
		c-0.5,5.1,2.1,9.9,1.8,15c-1.3,0.1-2.5,0.1-3.7,1.1c-1.4,1.1-3.2,1.3-4.4-0.2c-1.3-1.7-0.5-3.3,0.8-4.8c2.4-2.7,2.4-2.8,1.5-5.9
		c-1.9,1.3-1.7,3.5-2.4,5.2c-1.3,2.9-2,6.4-6,6.9c-1.6-1.4-1-3-0.4-4.6c1.9-4.6,1.4-9.5,1.7-14.2c0.2-3.8-1.2-7.1-4.4-9.5
		c-1.2-0.9-2.5-1.8-1.9-3.7C251.1,553.8,251.6,553.8,252,554.2z"/>
	<path class="st641" d="M267,566.3c-0.2,2.3-1.1,2.9-3.2,1.4c-2.5-1.8-3.2-4.5-4.6-6.9c-1.4-2.4-3.4-4.4-5.2-6.6
		c5.5-1.9,5.7-1.6,7.1,3.8c0.4,1.6,0.1,3.2,0.7,4.7c0.5,1.1,0.9,2.3,2.5,1.7c1.5-0.6,1.7-1.4,1-3.1c-1.8-4.2-3.1-8.5-1.3-13.1
		c0.2,0.1,0.4,0.1,0.6,0.2C265.3,554.4,267.1,560.2,267,566.3z"/>
	<path class="st642" d="M177.1,835.9c-1.3,2.4-4.2,2.9-5.5,5.7c-0.8,1.6-3.5-1.5-5.5-1.7c-4.4-4.5-3.8-10.2-3.6-15.8
		c4.5,1.8,8.7,0.4,13.2-0.5c4.6-0.9,5.6,0.8,4.5,4.3c-1.1,1.2,0.1,1.4,0.8,1.8C180.6,832.3,178.1,833.6,177.1,835.9z"/>
	<path class="st548" d="M232.1,819.1c-5,2.6-10,5.2-15,7.8c-1-1-1.7-1.7,0.1-2.8c1.7-1.1,3-2.9,5.3-3.7c-1-0.4-1.7-0.7-3.7-1.4
		c7.8,0.8,12.8-3.1,18.4-5.7c1.4,0.7-1.7,1.8-0.1,2.6C236.8,819.2,234.2,818.8,232.1,819.1z"/>
	<path class="st643" d="M208.2,813.2c-8.6,6-18.2,10.2-27.1,15.7c-1,0.4-1.2-0.1-1.2-0.9c1.7-3,5-4,7.7-5.7c1.3-0.9,2.7-1.7,4.2-2.4
		c4-1.8,7.5-4.3,11.2-6.6c1.7-0.7,3.3-2.2,5.4-1.3C208.6,812.4,208.5,812.8,208.2,813.2z"/>
	<path class="st644" d="M236.8,797.8c-7.3,4-14.5,7.9-21.8,11.9c-0.7-0.2-1.2-0.5-1.2-1.4c0.5-1.4,1.9-1.9,3-2.7
		c4.2-2.9,9.1-4.5,13.2-7.5c2.2-0.9,4.2-2.8,7-1.5C237.4,797.1,237.4,797.5,236.8,797.8z"/>
	<path class="st592" d="M249.1,808.9c-0.7,0.7-1.3,1.4-2,2.1c-1,1.4-2,2.6-4,2c-1,0.4-2,0.7-2.9,1.1c1.1-2-0.5-2.9-2.5-4.5
		C242,810.1,245.1,805.9,249.1,808.9z"/>
	<path class="st645" d="M256,786.8c-2.3,1.4-4.6,2.8-6.9,4.2c-2.3,1.2-4.7,2.5-7,3.7c-1.8,0.5-1.4-0.9-1.6-1.8c0.9-3,3.8-2,5.8-3
		c0.6-0.3,1.3-0.6,1.9-1c1.8-2.1,3.8-3.7,6.7-3.8C255.6,785.5,256.2,785.9,256,786.8z"/>
	<path class="st646" d="M232.1,819.1c1.7-1.1,3.3-2.1,5-3.2c1-0.4,1.9-0.7,2.9-1.1C238,820.8,236.7,821.5,232.1,819.1z"/>
	<path class="st647" d="M240.9,792.9c0.2,0.7,0,1.7,1.1,1.9c-0.9,2.4-1.9,4.8-5.2,3.1c0.1-0.3,0.2-0.7,0.3-1
		C236.1,793.3,237.2,791.8,240.9,792.9z"/>
	<path class="st648" d="M214,808.9c0.3,0.3,0.7,0.5,1,0.8c-2.3,1.2-4.5,2.3-6.8,3.5c0-0.4-0.1-0.8-0.1-1.2
		C209.4,809.7,210.9,807.8,214,808.9z"/>
	<path class="st649" d="M180.2,828c0.3,0.3,0.6,0.6,0.9,0.9c0,0.3,0,0.6,0,0.9c-1.1,0.3-2.1,0.6-3.7,1c0.8-1.2,1.3-2,1.7-2.8
		C179.5,827.6,179.9,827.6,180.2,828z"/>
	<path class="st628" d="M163.2,823.9c-1,4.5,0.1,8.8,2,12.8c0.5,1.1,1,2,1,3.2c0.5,3.5,2.4,7.3-1.7,10.2c-0.4,0.3-0.3,1.6-0.2,2.4
		c0.3,2.8,1.4,6.4-3.6,3.3c-0.2,0.4-0.3,0.9-0.5,1.3c-2.3-4.1-2.9-8.5-3.2-13c5.5-3.2,6.7-6.1,5-13.9c-0.7-2.9-0.5-5.5,0.3-8.2
		C163.8,821.9,163.8,822.7,163.2,823.9z"/>
	<path class="st650" d="M243.1,813c1.2-0.9,2.4-1.9,4-2c0,0.8,0.2,1.5,1,1.9c3.7-1,1.8,1.7,2,3c-0.4,0.8-1.2,1-2,1
		C247.3,814.4,243.9,815.4,243.1,813z"/>
	<path class="st651" d="M207.1,837.9c0,1,0,2,0,3c-0.9,0.1-2.2,1.9-2.6-0.2C204.2,839.1,204.7,837.4,207.1,837.9z"/>
	<path class="st652" d="M99.2,485.2c-0.3-0.7-0.7-1.3-1-2c1.8-1.9,4.8-2.3,6-5c1.4-0.6,2.7-1.3,4.1-1.9c5.8-1.4,10.4-5.2,15.3-8.4
		c0.4-0.2,0.9-0.4,1.4-0.5c1.6,0,2.9,0.4,2.8,2.4c-1,2.3-4.1,2.6-5.1,5.1c-0.8,1.3-2,1.8-3.4,2c-5.8,1-10.6,4-15.5,7
		C102.2,484.5,101,486,99.2,485.2z"/>
	<path class="st653" d="M136.2,455.3c1.3,0.7,2.7,1.3,4,2c0.6-0.6,1.3-0.6,2-0.2c0.3,2.5-1.9,2.8-3.4,3.6c-4.8,2.7-9.7,5.1-14.6,7.5
		l0,0c-1.6,0.7-3,2.2-5,1.1c2.8-3.5,7.3-4.6,10.3-7.9c1.7-1.8,5.7-1.6,4.3-5.7C134.6,455.6,135.4,455.5,136.2,455.3z"/>
	<path class="st654" d="M119.2,469.4c1.7-0.4,3.3-0.7,5-1.1c-3.8,4.5-9.6,5.8-14.3,8.8c-0.3,0.2-1.1-0.5-1.6-0.8
		C111.9,474,115.6,471.7,119.2,469.4z"/>
	<path class="st655" d="M124.2,468.3c2.4-4.1,7.3-4.2,10.7-6.8c2.2-1.7,5.2-2.1,7.3-4.2c1.5-3.5,4.7-4.6,8-5.5
		c8.3-3.6,15.6-9,24-12.4c2-1,4-2,5.9-3c9.7-5.3,19.3-10.6,29-16c0.8-0.8,1.7-1.5,3-1.3c0.9,0.4,1.4,1.2,1.7,2.1
		c0.1,0.5,0.1,1,0.1,1.4c-0.1,0.5-0.3,0.9-0.5,1.3c-1.3,1.8-3.2,2.8-5.3,3.5c-9,3.5-16.6,9.7-25.4,13.6c-0.8,0.4-1.7,0.8-2.6,1.2
		c-7.6,4.2-15.6,7.8-22.7,12.8c-8.2,4.2-16.1,9-24.2,13.5c-1.8,1-3.5,2.7-5.9,1.7C127.1,468.2,125.6,468.3,124.2,468.3z"/>
	<path class="st557" d="M174.2,439.4c-7.9,4.6-15.3,9.9-24,12.9c-0.3-0.2-0.5-0.4-0.3-0.6c0.1-0.2,0.2-0.3,0.3-0.3
		C157.9,446.9,165.3,441.7,174.2,439.4z"/>
	<path class="st552" d="M150.2,451.3c0,0.3,0,0.6,0,1c-2.7,1.7-5.3,3.4-8,5c-0.7,0-1.3,0-2,0c0.4-1-1.6-2.8,1-3c1-1,2-2,3-3
		C146.2,452.4,148.2,450.9,150.2,451.3z"/>
	<path class="st557" d="M144.2,451.3c0.2,2.2-0.8,3.2-3,3C140.7,451.8,140.6,449.7,144.2,451.3z"/>
	<path class="st656" d="M92.2,489.3c-0.7-4.7-4.9-4.4-8-5.4c-2.9-1-2.3-2-0.9-3.6c2.6,0.3,5.1,0.2,7.7,1c3.2,0.9,5.2,2.5,5.2,6
		c1,3.9-2.2,3.4-4.3,4C91.7,490.6,91.7,489.9,92.2,489.3z"/>
	<path class="st590" d="M92.2,491.3c0.9-1.8,4.3-1.1,4-4c1-0.7,2-1.4,3-2c1.4-0.5,2.3-2.1,4-2c0.7,0.2,1.2,0.6,1.4,1.4
		c-0.6,2.7-2.6,4.2-4.9,5.3c-4.1,2-4.8,6.2-6.6,9.7c-0.6,1.3-0.5,2.8-0.6,4.2c-0.1,0.8-0.2,1.6-0.4,2.3c-0.6,1.8-0.2,3.7-0.6,5.4
		c-0.2,1.1-0.3,2.6-1.8,2.5c-1.4-0.1-1.5-1.6-1.6-2.7c-0.2-1.5-0.1-3-0.1-4.5c0-0.6,0-1.1,0.1-1.7C89.1,500.5,88.9,495.3,92.2,491.3
		z"/>
	<path class="st657" d="M163.2,823.9c0-0.8-0.2-1.6-1-2c2.7-3,5.3-6,8-9c1.5-0.9,1.5-2.8,2.6-4c1-1,1.8-3,3.8-1.5
		c0.4,0.6,0.6,1.2,0.6,1.8c0.8,6,0.8,6,6.9,6.4c5.5,0.4,6.5,2.2,4,7.2c-2.6,1.7-5.3,3.4-7.9,5.1c-0.3,0-0.7,0-1,0
		c-0.1-1.1,0.6-1.8,1.3-2.6c-3.8-0.7-7.4-1.2-10.6,1.6c-2,1.7-3.6,1.7-4.2-1.4C165.4,824.8,164,824.4,163.2,823.9z"/>
	<path class="st658" d="M176.1,806.9c-3,1-2.1,6-6,6c0.8-3.6-1.6-6.1-3-9c-0.6-0.8-0.5-1.8-0.5-2.8c0.3-0.7,0.8-1.2,1.5-1.5
		c2.8,0,4.9,1.7,7.2,2.9c1.2,1,1.9,2.3,1.3,3.9C176.5,806.5,176.3,806.7,176.1,806.9z"/>
	<path class="st659" d="M165.1,801.9c0.1,1.4-0.3,2.7-1,4c-3-0.4-1.9-3.2-2.8-4.8c-0.2-5-1.8-9.6-5-13.5c0.9-0.7,1.9-0.9,3-1
		c1.9,0.1,4-0.3,5.2,1.8C163.6,793,166.3,797.4,165.1,801.9z"/>
	<path class="st660" d="M164,809.9c-1.9,0.9-3.6,2.2-5.9,2c-1.5,1.2-2.8,0.7-4.2-0.3c-0.8-0.7-1.3-1.6-1.1-2.7
		c0.3-0.4,0.6-0.8,1.1-1c2.4-1,4.9-0.1,7.4-0.2c1,0.1,1.9,0.5,2.7,1.2C164.1,809.2,164,809.6,164,809.9z"/>
	<path class="st657" d="M165.1,801.9c-0.8-4.3-3.7-8.4-1-13c2.6,3.6,2.3,7.9,3,12c0,1,0,2,0,3C166.5,803.2,165.8,802.6,165.1,801.9z
		"/>
	<path class="st661" d="M164.1,808.9c-1,0-1.9,0-2.9,0c-1-1.1-1.2-2.3-1-3.7c0.3-1.6,0.7-3.1,2-4.2c1.9,1.2,0.1,3.8,2,4.9
		C164.1,806.9,164.1,807.9,164.1,808.9z"/>
	<path class="st662" d="M125.2,712c1.3-0.7,2.7-1.3,4-2c0.8-0.9,1.7-0.9,2.8-0.6c1.5,0.7,2.3,1.9,2.2,3.6c-0.4,1-1,1.6-2,2
		c-1.3,0-2.7,0-4,0c-3-2.7-6,0.8-9,0C119.9,711.5,123.5,713.6,125.2,712z"/>
	<path class="st663" d="M133.9,725.1c0.3-1.1,0.1-2.3,1.3-3c1.8-1.2,3.6-2,5.8-1.9c0.5,0,1,0.2,1.5,0.3c4.1,4.7,4.2,10.5,4.2,16.3
		c-1.4,1.3-2.9,2.5-3.2,4.8c-0.2,1.6-0.9,3.2-2.6,4c-1.7,0.4-3.1-0.2-4.2-1.4c-0.3-0.5-0.6-0.9-0.8-1.4
		C134.9,736.8,132.3,731.2,133.9,725.1z"/>
	<path class="st664" d="M133.9,725.1c1,6,2,11.9,3.1,17.9c-3.4,1.6-5.6-0.5-7.5-2.8c-1.8-2.2-2.3-4.7-0.3-7.1c0-2.3-0.5-4.7,0.2-6.8
		c1.1-3.1-1-2.1-2.3-2.3C129.7,722.5,132.1,722.3,133.9,725.1z"/>
	<path class="st665" d="M141.2,720.9c-2,0.4-3.9,1.4-6,1.1c1.6-3.9,5.7-5.6,8-9c0.8-0.9,1.8-0.9,2.8-0.7c2.1,0.8,3,1.9,1.4,4.1
		C145.8,718.6,144.1,720.6,141.2,720.9z"/>
	<path class="st666" d="M134.2,713c-0.7-1-1.3-2-2-3c0.1-3.3,2.5-3.6,4.9-3.8c1.4,0.2,2.5,0.7,3.5,1.7c0.9,1.6,1.9,3.2,0.6,5.1
		C138.9,714,136.5,714,134.2,713z"/>
	<path class="st667" d="M141.2,713c-0.3-1.7-0.7-3.3-1-5c1-1.1,2.3-1.1,3.6-0.7c1.8,1.2,3,2.7,2.8,5c-0.1,0.2-0.3,0.4-0.4,0.6
		c-1,0-2,0-3,0C142.5,713,141.8,713,141.2,713z"/>
	<path class="st668" d="M125.2,712c-2,1-4,2-6,3c-2.3,0.3-4.5,0.8-6.8,1c-4.1,0.4-8.1,0.3-11.2-3c0-0.3,0-0.7,0-1
		C109.2,712,117.2,712,125.2,712z"/>
	<path class="st669" d="M129.2,733c0.1,6.8,2.1,9.4,7.8,9.9c0.1,0.3,0.3,0.7,0.4,1c0.2,0.4,0.2,0.8-0.1,1.1c-0.4,0.1-0.8,0-1-0.4
		c-1.5-1.3-3.4-0.5-5.1-0.8c-3-1.3-6.3-1.5-9.5-2c-2.9-0.5-5.9-0.8-7.4-3.9c0-0.7,0-1.3,0-2c2.6-2.4,4.1-5.9,7.2-7.9
		c4.3-2.8,5.7-2.4,6.8,2.8C128.4,731.6,128.9,732.3,129.2,733z"/>
	<path class="st670" d="M120.2,772c1.5,0.2,2.7-0.3,4-1c1.6-1.6,3.8-1.7,5.6-1.2c7.3,2.1,15.1,1.7,22.4,3.9c1.1,0.5,2.3,0.8,2.1,2.4
		c-2.4,2.6-5.5-0.2-8.1,0.9c-2.6-2-5.7-1.7-8.8-2.1C131.6,774.2,125.9,773,120.2,772z"/>
	<path class="st671" d="M146.2,776.9c2.4-2,5.3-0.6,8-1c2.8-0.2,4.4,0.8,4.4,3.8c-1.1,1.5-2.9,1.7-4.4,2.3c-0.9,0.3-1.8,0.5-2.7,0.6
		c-1.4,0-2.6-0.4-3.9-1.1c-0.2-0.2-0.3-0.3-0.5-0.5c0.3-0.9-1.6-1.6,0.2-2.8C148.8,777.2,146.8,777.2,146.2,776.9z"/>
	<path class="st672" d="M140.2,782c-1-1.5-2.7-0.7-4-1.2c3.7-1.6,7.3-0.3,10.9,0.3c0,0,0.1,0,0.1,0c1.6,1.9,2.2,4.1,1.6,6.6
		c-0.6,1.2-1.6,1.9-3,1.7C142.1,788.3,140.5,785.6,140.2,782z"/>
	<path class="st673" d="M128.2,795c-0.7-0.4-1-1-0.9-1.8c3.4-0.3,6.7-0.9,9.9-2.2c1.8-0.2,3.3-2.6,5.3-0.7
		C140.2,795.3,133.8,797.4,128.2,795z"/>
	<path class="st674" d="M142.2,790c-1.7,0.3-3.3,0.6-5,0.9c3.4-2.2,1.4-6.2,3-8.9c1.7,2.3,3.3,4.6,5,6.9c-0.1,0.2-0.2,0.4-0.3,0.6
		C144.1,790.3,143.3,791,142.2,790z"/>
	<path class="st675" d="M95.3,759.9c0-2,0-4,0-6c0.3,0,0.7,0,1,0c4.2,0.4,6.7,3.3,9.2,6.3c4.3,1.8,8.9,2,13.3,3.3
		c1.7,0.5,3.6,0.4,4.7,2.2c0.2,0.5,0.1,1-0.1,1.5c-4.1,2.2-8-0.7-12.1-0.4c-2.7,0.2-5.5-1-8.4-0.8c-3.6,0.3-3.1-4-5.5-5
		C96.6,760.6,95.9,760.3,95.3,759.9z"/>
	<path class="st676" d="M97.2,761c1.1-0.6,3.1-4,2.7,0.8c-0.2,2.1,0.7,3.3,2.7,3.2c7-0.3,13.6,2.2,20.6,2c2.1-0.3,4.6-1.4,4.8,2.3
		c-2,1.1-3.9,0.4-5.8-0.3c-6.8,0.1-13.3-1.8-20.1-1.8C99.2,767.2,98.1,763.7,97.2,761z"/>
	<path class="st677" d="M122.2,769c2,0,4,0,6,0c0.9-0.9,2.1-1.3,3.1-0.9c5.9,2,12.4,0.9,18.3,3c1.5,0.9,3,1.8,2.5,3.9
		c-9.3-1.5-18.5-3.6-27.9-4C123.5,770.3,122.9,769.6,122.2,769z"/>
	<path class="st678" d="M284.9,664.2c0.2-1.1-0.1-2.3,1-3.1c0.8,3.3,1.5,6.6,2.3,10c0,0-0.4,0-0.4,0c-0.5,0.9-0.2,1.9-0.5,2.8
		c-0.4,0.4-0.8,0.5-1.3,0.4c-0.4-0.3-0.7-0.7-0.9-1.2C284.7,670.1,282.8,667.2,284.9,664.2z"/>
	<path class="st679" d="M275,615.2c2.4,5.2,1.7,11.1,3.8,16.3c0.4,2.9,0.9,5.7,1.3,8.6c0.3,1.7,0.6,3.3,0.9,5c0,1,0,1.9,0,2.9
		c0.5,1.8,1,3.3-1.1,4.7c-4.9,3.4-5.9,7.2-3.7,12.7c2.2,5.6,0.4,8.7-5.6,9c-3,0.2-5,0.8-5.7,4.1c-0.7,3.5-3.5,4.1-6.6,3.6
		c-1.5-0.2-2.7-1.2-4.1-1.7c-6.7-2.5-12.7,1.5-12.8,8.6c0,4.5,0.2,9-0.9,13.4c-0.4,1.6-0.3,3.8-2.3,4c-2.6,0.2-2.3-2.3-2.5-4
		c-0.1-1.6,0.7-3.7-2.4-3.1c-0.9,0.2-1.6-0.8-2.1-1.7c-1.5-5.3-1.7-5.6-7.2-4.4c-4.4,0.9-8.9,1-13.1,2.5c-1.6,0.5-3,0.3-3.6-1.6
		c-0.5-1.6,0.1-2.9,1.7-3.7c1.6-0.8,3.4-1.8,5.1-1.8c6.7-0.1,8.8-4.7,10.5-9.9c0-0.7,0-1.4-0.1-2.1c-0.2-0.7-0.5-1.5-1.1-1.5
		c-7-0.3-13.8-4.4-20.9-0.7c-2.4,1.3-4.8,2.4-7.4,3c-2.5,0.1-4.2,1.7-6.2,3c-2.1,1.4-4.3,1.1-5.8-0.9c-1.5-2,0.3-3.3,1.6-4.6
		c4.5-4.4,4.4-4.4,12-5.2c-1.4-1.3-2.9-1-4.2-1.4c-1.6-0.6-2.2-1.4-1.2-3c3.7-3.5,9.5-3.9,12.5-8.4c0.8-0.9,1.6-1.8,3-1.6
		c1,0.5,1.5,1.2,1.4,2.3c0,0.9-0.9,2.1,0.3,2.7c0.9,0.5,1.5-0.4,1.9-1.1c1.7-3.1,7-4.1,5-9.2c1.4-4.7,1.8-9.4-0.4-14
		c-0.5-1-0.9-1.8,0.1-2.7c0.8-0.7,1.9-0.7,2.8-0.3c2.9,1.2,5.8,1.3,8.8,1.2c2.5-0.1,4.7-1,5.6-3.5c1.3-3.6,4-4.7,7.4-4.5
		c4.4,0.3,7.9-0.7,9.5-5.4c0.4-1.3,1.7-2,3-2.5c2.1-0.8,3.6-2.3,4.5-4.4c1.5-3.6,4.4-5,8.1-4.4c4,0.7,3.6,4.2,4,7.1
		c0.1,1.1-0.2,2.4,1.2,3.4c2.2-2.1-0.2-5.6,2-7.5C274.4,612.1,273.6,614.6,275,615.2z"/>
	<path class="st680" d="M237.1,701c0.2,1.4-0.4,3,1.3,5.3c0.7-3.8,1.3-6.8,1.6-9.8c0.3-2.5-0.2-5,0.1-7.5c0.4-2.9-1.9-6.9,2.1-8.4
		c4.6-1.7,9.2-4.8,14.4-0.6c3.5,2.8,7.1,1.1,7.2-3.1c0.1-3.2,1.3-3.9,4.2-3.9c9.2-0.1,9.6-1,5.5-9c-0.9-1.7-1-3.7-0.2-5.3
		c1.1-2,2.8-3.6,2.8-6.2c0-1.2,1.3-1.5,2.3-1.3c3.4,0.8,2.3-1.7,2.6-3.2c1.2,1.5,0.8,3.3,1,5c1.2,1.9-0.4,4.2,1,6.1l0,0
		c0.4,2.9,1,5.9,0.3,8.6c-0.8,3.2,0.5,4.8,2.7,6.5l0,0c1,1.3,1,3.9-0.1,3.7c-2.4-0.5-0.9,1.2-1.4,1.6c-0.5,1.6-2.4,2.5-2.7,4.1
		c-0.3,1.3-2.2,3.3,1.5,3.8c2.6,0.3,2.6,6.2-0.1,7.1c-2.7,0.8-2.6,2.4-2.7,4.4c-0.1,3-1.4,5.8-2.9,8.4c-2.7,4.8-6.4,7.9-12.4,7.3
		c-4.1-0.4-7.8-0.5-8.6,5.4c-0.4,3.3-4.5,3.3-6.6,1.8c-2.3-1.7-3-0.9-4.2,1.1c1-0.1,1.9,0.3,2.7,0.8c1,0.7,2,1.4,1.7,2.8
		c-0.2,1.4-1.5,1.7-2.7,1.8c-2.8,0.2-5.6,0.7-8.3-0.6c-0.3-3.4-1.7-6.5-3.5-9.4c-0.1-3.8-2.8-3.9-5.6-3.9c-2-0.3-3.3-1.3-3.2-3.5
		c0.2-2.8,4.1-2.6,4.4-5.3c0.2-1.4,0.2-2.8,1-4.1C234.2,703.5,235.5,699.3,237.1,701z"/>
	<path class="st681" d="M286.1,674c-4.2-0.3-5-2.5-3.9-6.4c0.8-2.8,0.6-5.8,0.9-8.7c1.6,1.4,0.3,3.8,1.8,5.2
		C285.3,667.5,285.7,670.8,286.1,674z"/>
	<path class="st681" d="M283.1,659.1c-2-1.8-0.8-4-1-6.1C283.4,654.8,282.9,657,283.1,659.1z"/>
	<path class="st682" d="M93.2,607.1c-1.2-6.9-5.7-13.5-1.5-20.8c0.4-0.7-1.1-2.3-1.4-3.5c-0.2-0.9,0.1-1.9,0.2-2.8
		c0.9,0.4,1.7,0.8,2.6,1.2c0.2,0.2,0.4,0.4,0.6,0.6c1.1,2.2,0.5,4.7,1.5,7c0.3,4.2,0.9,8.4,1.8,12.6C97.6,604.1,97.3,607,93.2,607.1
		z"/>
	<path class="st683" d="M93.2,581.2c0.7-4.2-2.3-7.8-2-11.9c0-1.1,0.3-2,1.4-2.3c1.1,0.3,1.4,1.3,1.7,2.3c0.2,1.2,0.2,2.5,0.3,3.7
		c0.1,1.8,1.2,3.4,1.7,5.1c1.9,7.8,2.6,15.9,4.8,23.7c0.7,2.4,2,6.1-2.8,6.7c-2.8-4.5-1.8-9.7-2.9-14.5c-0.6-2.6-0.6-5.5-1.3-8.2
		C93.3,584.3,94.3,582.6,93.2,581.2L93.2,581.2z"/>
	<path class="st684" d="M84.2,560.2c0.3-2.1-0.9-4.8,3.8-3.7c3.4,0.8,4.6-3.3,3.9-6.4c-0.6-2.6-0.6-5.2-0.7-7.9
		c2.2-0.1,2.1,1.8,2.8,3.1c0.6,2.8,0.4,5.6,1.4,8.3c0.6,1.8-2.4,3.5-0.2,5.6c0.3,0.3-0.7,1.4-1.3,1.9c-2,1.1-4-0.8-6,0
		c-1,0.1-1.9-0.2-2.6-0.9C84.9,560.2,84.6,560.2,84.2,560.2z"/>
	<path class="st685" d="M85.2,560.2c1,0,2,0,3,0c5,1.5,5.5,2.4,4,7c0,0.8-0.2,1.6-1,2.1C85.3,568.8,85.8,564.2,85.2,560.2z"/>
	<path class="st686" d="M94.2,585.2c3.1,5,1.6,10.9,2.9,16.2c0.5,2.2,0.6,4.5,1.1,6.7c0.4,0.5,0.3,1-0.2,1.4
		c-1.3,0.1-2.6,0.8-3.8-0.4c-0.3-0.7-0.7-1.3-1-2c2.7-1.4,3.5-3.5,2.4-6.5c-1.5-4-1.5-8.3-1.4-12.5
		C93.5,587.2,93.6,586.2,94.2,585.2z"/>
	<path class="st687" d="M94.2,609.1c1.3,0,2.6-0.1,4-0.1c1-0.3,2.2-1,2.9,0.5c0.3,0.5-0.4,0.7-0.9,0.7c-2.2-1.2-5,2.6-6.9-1
		C93.6,609.2,93.9,609.2,94.2,609.1z"/>
	<path class="st688" d="M98.1,633.1c0.7-0.8,3.5-0.5,1.4-2.7c0.9-0.1,1.9-0.2,2.8-0.2c0.4-0.3,0.9-0.3,1.3-0.1
		c6.8,1.8,13.5,4,20.8,4c5.3,0,9.8,2.7,13.2,6.9c0,0.9-0.5,1.4-1.3,1.7c-1.4,0.2-2.6-0.2-3.9-0.7c-2.2-1.6-4.5-2.6-7.3-2.7
		c-7.8-0.3-15.6-2.6-23.5-1.3c-2,0.3-4-0.1-5.4-1.8C96.9,635.1,97.5,634.1,98.1,633.1z"/>
	<path class="st689" d="M102.4,628.2c-3.5-3.3-6.1-7-6.2-12c0-2.8,3-1.9,4.1-3.4c3.7,0.8,4,4.2,5.2,6.9c0.6,1.8,0.9,3.6,0.5,5.4
		C105.2,626.7,105.2,629.1,102.4,628.2z"/>
	<path class="st690" d="M102.4,628.2c2.1,0,1.5-2.4,2.8-3.1c2.5,0,4.9-1.6,7.5-0.4c0.6,1.7-0.1,2.9-1.3,4c-2.6,1.3-5.1,2.5-8.1,1.6
		c-0.3,0.1-0.6,0-0.9-0.2C102.3,629.5,102.4,628.8,102.4,628.2z"/>
	<path class="st581" d="M100.1,613.2c0,2.8-3.2,1.3-3.9,3c-0.6,0.3-1.2,0.7-1.8,1c-0.1-3.2,2.7-3.7,4.7-4.9c0.2,0,0.4,0,0.5,0.1
		C99.9,612.5,100.1,612.8,100.1,613.2z"/>
	<path class="st691" d="M100.2,610.2c0.3-2-1.5-0.6-2-1.2c0-0.3,0-0.6,0-0.9c3.6-1.8,2-4.7,1.3-7.2c-2.1-7.8-3.3-15.7-4.3-23.7
		c-0.7-1.3-0.8-2.9-2-4c0-1.3,0-2.7,0-4c0.4-0.7,0.9-1.4,1.9-1c3,5.5,2.9,11.8,4.2,17.7c0.5,2.4,0.9,4.9,1.8,7.2
		c2.2,4.6,2.1,9.5,2.2,14.4c0,2.5,0,5.7-4.2,4.7c0,0-0.1,0-0.1,0C99.5,611.6,99.8,610.9,100.2,610.2z"/>
	<path class="st692" d="M286.8,674.4c0.3-1.1,0-2.4,1-3.3c0.7,3.3,1.4,6.6,2.1,9.9c-1.4,1.1-0.6,2.8-1,4.2c-3.1-1.1-1.8-4.1-2.6-6.1
		C286.6,677.5,286.3,675.9,286.8,674.4z"/>
	<path class="st693" d="M287.1,678.9c0.6,2.1,1.2,4.2,1.8,6.3c-0.1,0.3,0,0.6,0.2,0.9c1.4,8.6,2.7,17.1,4.1,25.7
		c-3.4,2.9-3.6,0-4.1-1.3c1.3,10.7-1,21.7-9.8,29.9c-4.5,4.2-9.1,8.9-15.5,10.7c-3.1,0.2-6.7-1.3-8.7,2.8c-0.4,0.7-1.5,0.5-2.3,0.4
		c-3.4-0.4-5.3,0.9-5.8,4.5c-0.4,2.2-2,3.5-4.4,3.1c-2.6-0.4-2.1-2.5-2-4.3c-0.8-4-3.3-3.4-5.9-2.2c-2,0.9-3.9,2.6-6.4,1
		c-1.1-2.7,0.9-3.9,2.7-4.9c2.5-1.3,4.7-3,6.7-4.9c1.4-1.1,0.9-2.6,0.8-4c-2.8-2.4-6.7-1.5-9.7-3.5c-1.2-0.8-2.4-1.5-3-2.9
		c0-1.4,0.8-2.1,2.1-2.4c5,0.5,8.3-2,10.6-6.2c0.2-0.1,0.4-0.3,0.6-0.4c3.4,0,6.9,0,10.7,0c-2.3-3.1-2.3-3.1-5.5-2.6
		c-0.9,0.1-1.6,0.5-2-0.6c-0.2-0.7,0.2-1.5,0.8-1.6c3.9-0.6,4.1-3.4,3.6-6.5c2.4,0,2.3,1.6,2.3,2.8c0.1,2.8,2.1,2.4,3.9,2.5
		c2,0.1,3.3-0.6,3.1-2.8c-0.6-5.4,2.8-5.3,6.6-5.2c9.6,0.1,13.7-3,16.3-11.9c0.5-1.6,0.4-3.2,0.2-4.9c-0.2-2-0.5-4.6,3.3-3.1
		c1.6,0.6,1.9-0.2,1.1-5.3c-1.7-0.1-1.3,2.3-3.5,2.3c0-2.2,0.1-4.4,0-6.6c-0.1-2.8,3.1-2.7,4-4.5
		C285.1,679.5,286.1,679.1,287.1,678.9z"/>
	<path class="st694" d="M264,750c0.7,0,1.4,0.1,2-0.2c13.8-6.4,23.3-22.7,22.1-37.9c-0.2-2.1-0.5-4.3,1.2-6c0,0,3.8,5.9,3.8,5.9
		c0.9,3.5,1.8,7.1,2.6,10.6c0.1,1.6,0.1,3.2,0.2,4.8c0.4,3.7-0.1,5.8-5,6.2c-3.1,0.3-6.6,3.4-8.8,6.2c-4,5.1-11.2,5.8-14.4,11.8
		c-0.6,1.1-2.5,0.6-3.8,0.6C263.5,751.3,263.4,750.6,264,750z"/>
	<path class="st695" d="M154.2,810.9c1.2,0.7,2.5,1.1,4,1c0,1.1,0.2,2.3-1,3c-1.7-0.3-3.3-0.7-5-1c-1-0.1-2-0.2-2.2-1.5
		C150.9,810.6,152.2,809.7,154.2,810.9z"/>
	<path class="st696" d="M150.2,812.9c0.8,0,1.6,0.2,2,1c-1.1,1.9-3.7,1.4-5,3c-2.7,0-5.3,0-8,0c-1.7-0.3-3.6,0.7-5-1
		c-0.2-1.6,1.1-1.8,2.1-2.2C140.9,813.9,145.4,810.8,150.2,812.9z"/>
	<path class="st697" d="M89.2,505.3c0,0.3,0,0.7,0,1c-1,0.9,0.1,2.6-1.3,3.4c-8.9,0-14.4-4.9-13.7-12.4c0,0,0,0,0,0
		c1.9-0.8,1.9-2.4,2.1-4.1c0.2-2,1-3.8,2.9-4.9c0.3,0,0.7,0,1,0c2.4,2,4.2,4.4,4.4,7.5C84.9,499.7,89,501.5,89.2,505.3z"/>
	<path class="st698" d="M89.2,505.3c-5.8-4.2-5.8-11.4-9-17c2.7,2.4,7-0.6,9.6,3.2c1,1.4,1-2,2.3-2.2c0,0.7,0,1.3,0,2
		C91.2,495.9,90.2,500.6,89.2,505.3z"/>
	<path class="st699" d="M267.1,807.2c-0.3-2.7-2.4-2.2-4.1-2.3c-1.9,1-2.8,0.2-2.9-1.7c2.2-1.3,4.6-2.5,6.8-0.7
		C268.2,803.6,269.2,805.5,267.1,807.2z"/>
	<path class="st547" d="M260.1,803.2c0.6,1.2,1.6,1.8,2.9,1.7c0.9,0.6,1.8,1.2,2.7,1.8c-3.4-0.7-6.9,0.7-10-0.9
		C256.9,804.4,258.5,803.8,260.1,803.2z"/>
	<path class="st547" d="M250,815.9c-1-0.8,0.3-3.1-2-3c2.8-2.8,6-4.2,10.6-2.6C255.3,812.1,250.7,811.2,250,815.9z"/>
	<path class="st463" d="M304.1,779.9c0-3,0-6,0-9C305.6,773.9,305.5,776.9,304.1,779.9z"/>
	<path class="st700" d="M188.1,822.9c-0.2,0-0.5,0-0.5,0c0.2-5.9,0.2-6.1-6.6-5.9c-4,0.2-5.4-1.2-5-5.1c0.2-1.6,0-3.3,0-5l0,0
		c5-3.2,9.1-8.2,16-7c3.3-1.3,5.6-5,10.2-3.3c1.8,0.7,1.6-1.9,1.8-3.3c0.1-1,0.3-2,1.5-2.2c0.9-0.1,1.8,0,2.1,0.9
		c1.8,5.2,5.8,3.5,8.4,2c5.8-3.3,11.6-6,18.5-5.1c1.2,0.2,4.2,0.6,2.1-2.7c-0.4-0.6,0.6-1.8,1.5-2.3c0.6-0.3,1.4-0.5,1.6,0.4
		c0.6,1.9,1.4,3,3.6,2c0.5-0.2,1.2,0.2,0.7,0.7c-3.4,3.8,1.3,2.1,2,3c-1.3,1.8-3.9,1.3-5.3,2.9c-2.8-0.1-3.6,1.6-3.9,3.9
		c-2.7-0.5-4.5,1.8-6.9,2.2c-5.2,1.2-9.4,4.1-13.3,7.7c-1,0.7-2,1.5-3,2.2c-2,1-3.9,2.1-5.9,3.1c-1.6,0.7-3.3,1.5-4.9,2.2
		c-5.7-2.5-10.9,1.3-11.2,6.6C190.7,821.4,189.4,822.2,188.1,822.9z"/>
	<path class="st701" d="M264,750c0,0.7,0,1.3,0,2c-1.5,2.7-3.4,4.7-6.7,5.4c-5.2,1.1-8,6.6-13.3,7.6c-1.9,0.2-3.5-0.6-4.9,2.4
		c-1.1,2.5-5.9,0.9-8.3,3.6c-3.9,4.3-9.6,5.7-14.8,8c-7.4,9.2-16,11.6-25.4,6.9c-2.5-4.5-2.8-9.4-2.1-14.4c0.2-1.6,0.2-2.8-0.5-4.2
		c-0.8-1.4-1-2.9,0.4-4c1.4-1.2,2.8-0.6,4,0.4c0.8,1.1,0.9,2.3,1,3.5c0.4,3.9,2.3,3.8,5.1,2.1c2.8-1.7,5.2-4,8.7-4.3
		c1.9,0.3,2.6,1.3,1.8,3.1c-1.8,2.6-4.5,3.8-7.3,4.9c-2.1,0.8-3.6,2.2-5.2,3.7c-1.5,1.4-1.7,2.8,0.3,3.9c3.7,1.3,6.2-1.3,9-2.8
		c1.4-0.8,2.5-2.2,3.9-3c7.2-4.9,15.2-8.5,22.8-12.6c3.6-1,5.4-5.1,9.5-5.2c0.5,1.3-2.3,3.4,0.8,3.9c2.3,0.3,3.4-1.4,3.1-3.4
		c-0.6-4.1,1.8-4.7,5-4.6c2.2,0.1,3.9,0.2,5.4-2.7C258,747,261.4,751,264,750z"/>
	<path class="st702" d="M192.1,799.9c-6,0.8-10.2,5.8-16,7c0.1-1.5-0.3-2.7-1-4c0.2-3.1,2.5-5.8,1.5-9.1c0.1-0.5,0.2-0.9,0.6-1.3
		c0.4-0.3,0.8-0.5,1.3-0.6c1.4-0.3,2.9-0.3,4.3,0c1.8,0.9,2.9-0.2,4.3-1.3c1.5-1.2,2.8-2.7,4.9-3c1.6,1,1.5,2.8,2.1,4.2
		c0.3,1,1.4,2.1-0.1,2.9C192,796.1,192.1,798,192.1,799.9z"/>
	<path class="st703" d="M194.1,791.9c-1.8-0.8-2.1-2.3-2-4c-0.6-0.3-1.2-0.6-1.5-1.2c-0.2-0.7-0.1-1.3,0.5-1.8
		c8,4,14.9,1.7,21.2-3.8c1.1-1,2-2.2,3.7-2.2c3.5,1.3,2.1,3.3,0.5,4.8c-1.8,1.6-3.3,2.8-1.2,5.2c0.9,1,1.2,2.7-0.1,3.5
		c-1.4,0.9-2.4-0.3-3.2-1.5c-0.4-0.6-1.3-1.7-1.7-1.6C205,790.8,199.3,789.7,194.1,791.9z"/>
	<path class="st704" d="M255,785.9c-2.3,1-4.6,2.1-6.9,3.1c-0.4-1.4-1.9,0.3-2.4-0.8c2.7-3.9,8-4,11.1-7.4c1.7-1.8,2.3,0.9,3.5,1.2
		C259.9,785.3,257,784.9,255,785.9z"/>
	<path class="st705" d="M255,785.9c1.8-1.3,3.5-2.5,5.3-3.8c0.3,0,0.5-0.1,0.7-0.2c1.6-2.5,4.3-2.8,6.9-3.5c0.8,0.1,1.4,0.4,1.1,1.4
		c-4.3,2.3-8.7,4.6-13,6.9C255.7,786.5,255.3,786.2,255,785.9z"/>
	<path class="st704" d="M267.9,778.8c-2.3,1-4.6,2.1-6.9,3.1c-1.2-2.2,0.4-3,2-3.3c1.7-0.4,3.5-0.4,5.2-0.6c0.3,0.3,0.3,0.5,0.1,0.7
		C268.2,778.8,268.1,778.8,267.9,778.8z"/>
	<path class="st706" d="M267.9,778.8c0.1-0.3,0.2-0.5,0.3-0.8c1.3-0.4,2.6-0.8,3.9-1.2c0,0.7,0,1.3-0.1,2c-1,0.4-2,0.7-3,1.1
		C268.6,779.5,268.3,779.2,267.9,778.8z"/>
	<path class="st707" d="M92.3,525.1c-0.1-1-0.2-2-0.2-2.9c0,0,0.4,0,0.4,0c0.8-1.3,0.8-3.7,3.1-3.2c1.8,0.4,1.3,2.4,1.7,3.8
		c1.7,7,3.9,13.9,4.8,21.1c0.3,2.5,0.8,4.9,0.3,7.4c-1,3.9,1.5,7.3,1.5,11.1c0.1,5.2,0.8,10.5,1.8,15.6c0.4,3.2,2.5,6.6-0.9,9.5
		c-3.2-1.4-2.3-4.7-3.4-7.2c-0.3-2.6-1.1-5.2-1.4-7.9c-0.2-3.5-1.7-6.7-2-10.1c-0.9-4.7-1.4-9.6-2.6-14.2
		C93.7,540.4,92.3,532.9,92.3,525.1z"/>
	<path class="st708" d="M92.3,525.1c2,7.5,3.1,15.1,4.1,22.7c0.3,0.8,0.6,1.7-0.2,2.2c-0.6,0.4-1.2-0.1-1.5-0.8
		c-0.6-1.3-0.1-3-1.4-4.1c-0.7-1-1.3-2-2-3c0.2-6-3.4-11.7-1.7-18c2,0.6,1.2,2.8,2.1,4C92.7,527.3,92,526.2,92.3,525.1z"/>
	<path class="st709" d="M99.2,700c-1.1-4.4,1.4-6.5,5-8c2.5-1.9,5.1-1.5,7.7-0.1c3.6,2.8,6.7,6.2,10.6,8.7c1.1,1.4,3.4,2.2,2.6,4.7
		c-4.5,1.7-9.1,0.7-13.6,0.6c-1.5,0-2.9-0.4-4.4-0.4c-5.4-0.1-5.6-0.5-4-6.2C101.7,699.5,100.6,700.9,99.2,700z"/>
	<path class="st710" d="M99.2,700c2.6,0,2.9-4.8,5-3.4c2.1,1.4-0.3,4-1.1,6c-0.7,1.9,1,1.8,2,1.8c6.7,0.2,13.4,0.4,20,0.6
		c1.7-1.6,3.6-1.4,5.6-0.5c1.2,1.8-0.3,2.1-1.4,2.6c-1.6,0.7-3.4,1-5.2,0.9c-7.3,2.1-14.5-0.8-21.9-0.1
		C100.3,708.2,99.1,703.2,99.2,700z"/>
	<path class="st711" d="M112.2,693c-2.5-1.5-5.3-0.8-8-1c0.3-1,0.7-2,1-2.9c4.7-1.6,9.3-3.3,14-4.9c0.2-0.2,0.3-0.4,0.5-0.5
		c1-0.4,1.9-0.4,2.6,0.5c-0.2,2.3-1.8,3.2-3.7,3.9C115.4,688.2,114.8,691.9,112.2,693z"/>
	<path class="st712" d="M124.2,708c1.6-2.7,5-1.1,7-3c2.1-0.8,4-0.4,5.9,0.6c0.4,0.4,0.4,0.9,0.1,1.4c-2.1,0.3-3.9,1-5,3
		c-1,0-2,0-3,0C127.2,710.1,125.5,709.6,124.2,708z"/>
	<path class="st713" d="M107.2,680.1c0.7-2,1.4-4,2.1-6c0.4-0.3,0.9-0.3,1.3,0c1.9,3.3,3.4,7,7.8,7.9c0.7,0.1,1.1,1.1,0.7,2
		c0,0,0.1,0.2,0.1,0.2c-1-0.1-2.1-0.1-3.1-0.2c-2.9-1.3-6.8,0.8-8.9-2.9C107.2,680.8,107.2,680.4,107.2,680.1z"/>
	<path class="st714" d="M107.2,681.1c2.8,1.5,6.2,1,8.9,2.9c-3.6,0.6-7.2,1.2-10.8,1.9C106.3,684.4,107.6,683.1,107.2,681.1z"/>
	<path class="st715" d="M212.1,419.3c-1,0.3-2,0.7-3,1c0.5-4.8,6.4-2.9,8.1-6.5c-3-1.6-7.1,1.8-7.8,1c-2.8-3.4-5-1.5-7.5-0.7
		c0.4,1.8,2.4,0.6,2.7,2.5c-2.7,2.4-5.6-1.9-8.4-0.3c0-0.3,0-0.5,0.1-0.8c4-1.9,8.2-3.6,11.8-6.2c4.4-0.8,7.9-5.2,13-3
		c2.7,1,5.3,2,5.3,5.5C222.3,415.8,217.7,418.6,212.1,419.3z"/>
	<path class="st716" d="M226.2,411.4c-1.8-1.6-2.9-3.9-5.1-5.1c1,0,2,0.1,3,0.1c1.8,1.8,4.7,2.2,6,4.6
		C228.9,412.6,227.6,412.5,226.2,411.4z"/>
	<path class="st717" d="M235.4,416.2c-2.1,2.2-2.9,6.2-7.3,5.2c-3.7,3.1-8.2,5-12,8c-0.3,0-0.7,0-1,0c-1.1,0.5-1.6-0.1-2-1
		c-0.4-0.4-0.8-0.7-1.3-1.1c-0.1-2,1.6-2.8,2.8-3.9c4.6-2.2,8.2-6.3,13.6-6.6C230.6,416.2,232.8,414.2,235.4,416.2z"/>
	<path class="st718" d="M228.2,416.7c-4.4,2.5-8.8,5-13.2,7.4c-0.6-0.3-1.6,0-2-0.9c0-0.3,0.1-0.6,0.1-0.9c4.4-3.5,8.6-7.5,14.9-7.1
		C228.1,415.8,228.1,416.3,228.2,416.7z"/>
	<path class="st719" d="M228.1,415.3c-5.4,1.4-9.7,5.3-14.9,7.1c0-0.4,0-0.7,0-1.1c0.5-0.8,0.3-2.1,1.4-2.5c5-1.6,9-5.5,14.4-6.1
		c0.8,0.2,1.4,0.5,1.2,1.5C229.4,414.7,228.7,415,228.1,415.3z"/>
	<path class="st720" d="M230,414.3c-0.3-0.3-0.6-0.6-0.9-1c-0.5-1.9,1.6-1.1,2.1-2c0.9-0.2,1.9-0.3,2.8-0.5
		C233.6,413,232.2,414.2,230,414.3z"/>
	<path class="st721" d="M231.1,411.3c-0.7,0.7-1.4,1.4-2.1,2c-5.4,2.6-11.3,4-16,8c-0.3-0.7-0.7-1.3-1-2c4.7-2.6,9.4-5.3,14.1-7.9
		c1.3-0.1,2.6-0.2,3.9-0.3C230.4,411.2,230.8,411.2,231.1,411.3z"/>
	<path class="st722" d="M126.2,657.1c-8.6,1.8-17.3,3.6-25.9,5.2c-6,1.1-6,1-6-6.2c9.1-0.3,18.1-1.7,27.2-2.5
		c2.2-0.5,4.5-1.3,6.7,0.1C129.2,655.8,128.6,657,126.2,657.1z"/>
	<path class="st723" d="M111.1,668c-3.8-0.4-6.3,0.5-7.6,4.7c-0.7,2.1-1.2,4.8-3.3,6.3c0.2-10.2,3-13.7,12.6-16
		c4.7-1.1,9-3.1,13.3-4.9c0.9-0.3,1.3,0.2,1.5,1c0,1.5-1,2.5-2.2,2.9c-3.2,1-1.8,2.5-0.7,4.1c0,0.5-0.2,0.9-0.5,1.3
		c-2,1-4.2-0.4-6.1,0.6c-1.6,0.4-3.2,0.4-4.9,0.4C112.5,668.3,111.8,668.1,111.1,668z"/>
	<path class="st724" d="M122.2,655.1c-7.5-0.6-14.7,2.6-22.2,2c-2-0.2-4,0.1-5.8-1c0-1.3,0-2.7,0-4c0.2-0.1,0.4-0.3,0.6-0.4
		c8.1-0.7,16.1,0.6,24.2-0.1c1.3-0.1,2.6,0.3,3.5,1.4C122.8,653.8,122.7,654.5,122.2,655.1z"/>
	<path class="st725" d="M127.2,659.1c-0.3-0.3-0.7-0.7-1-1c0-0.3,0-0.7,0-1c1.3-0.6,2-1.5,2-3c2.3-1.9,5.1-1.6,7.7-2
		c1.4,0.1,2.7,0.5,3.5,1.8c-0.9,4.2-4.5,4.6-7.7,5.6C130.1,659.8,128.6,660.4,127.2,659.1z"/>
	<path class="st724" d="M93.3,652.1c0-2.3,0-4.6,0-7c0.5-0.3,1-0.3,1.4,0.2c0.5,0.7,0.7,1.4,0.8,2.3c0.1,1.6,0.1,3.3-1.2,4.5
		c0,0,0,0,0,0C93.9,652.1,93.6,652.1,93.3,652.1z"/>
	<path class="st625" d="M74.2,497.3c0.6,8.8,7.6,10,14,12c1.4,1.6-0.6,3.9,1.5,5.7c2.3-3.1,0-6.7,1.5-9.7c0-0.7,0-1.3,0-2
		c0.6-0.3,1-0.2,1.4,0.3c1.1,1.8,0.8,3.8,0.9,5.7c-0.2,3.2,0.4,6.5-0.5,9.7c-0.2,0.5-0.6,0.9-1.1,1.2c-6.3,0.9-11.9-1-16.8-4.9
		C72.3,509.4,72.6,503.4,74.2,497.3z"/>
	<path class="st726" d="M101.2,551.2c-1.3-7.5-0.8-15.3-4.5-22.5c-1.3-2.6-1.1-5.9-1.6-9.2c-1,0.9-1.9,1.8-2.8,2.7
		c-0.1-0.7-0.2-1.4-0.4-2.1c0,0,0.2,0,0.2,0c1-0.9,0.2-2.2,0.8-3.2c0.8-1.2,1.8-2.2,3.4-2.2c2.8,2,4.2,5,5.4,8
		c0.4,1.1,0.7,2.2,1.1,3.3c0.4,0.9,0.8,1.7,0.9,2.7c-0.5,2.7,0.5,5.3,0.1,8c-0.7,2.6,0.9,4.8,1.2,7.2c0.4,3.1,1,6.2-0.6,9.1
		c-0.4,0.4-0.9,0.5-1.5,0.3c-0.7-1.7-0.4-3.4-0.3-4.6c-0.7,1.1,0.5,3-0.5,4.5C100.5,553.3,100.5,552.4,101.2,551.2z"/>
	<path class="st727" d="M94.2,645.1c-0.3,0-0.6,0-1,0c0-1.3,0-2.7,0-4c1.5-2.9,4-3.5,7.1-3.5c6.9-0.2,13.9,0.8,20.8,0.6
		c2.7-0.1,5,1.7,7.6,2.1c1.1,0.6,1.9,1.4,1.8,2.8c-0.7,1.1-1.7,1.6-2.9,1.7c-8.1-0.1-16.2-1.4-24.4-1.5c-2-0.6-3.7-0.4-5.2,1.3
		C97,645.8,95.7,646.3,94.2,645.1z"/>
	<path class="st728" d="M129.2,641.1c-8.8-3.5-18.2-1-27.3-2.6c-3.4-0.6-5.9,1.4-8.7,2.6c-0.3-2.5,0.9-4,3-5c7.7,0,15.5,0.1,23.1,1
		c4.8,0.5,9.9,0.2,13.8,4C132.1,643,132.1,643,129.2,641.1z"/>
	<path class="st729" d="M126.2,745c2.7,0,5.3,0.1,8,0.1c-1.8,3.7-5.9,1.7-8.5,3.6c-3.9,0.9-5.8,5-9.7,6c-2.4,0.6-4.5,0-5.9-2.2
		c-0.6-2,0.9-2.4,2.3-2.8c0.6-0.2,1.3-0.3,1.8-0.8C117.7,746.2,122.4,747.1,126.2,745z"/>
	<path class="st730" d="M114.2,749c-0.3,0.3-0.7,0.7-1,1c-2.8,0-5.3,1.5-8.1,1.1c-2.3-0.3-5.7,0.2-4.9-4.1c0.7-1.1,1.9-1.5,3-1.9
		C106,748.7,110.7,747.2,114.2,749z"/>
	<path class="st731" d="M125.2,748c2.5-2.7,6.3-1.1,9-2.9c0.7-0.1,1.4-0.2,2-0.3c0.3,0.1,0.6,0.1,0.9,0c0.7,2.1-1.2,2.3-2.4,3
		c-3.2,0.7-4.1,2.3-1.9,5c0.6,0.7,1.4,1.3,1.6,2.3c-0.2,1.1-1.1,1.5-2,1.7c-3.8,0.6-5.9-3-9.2-3.9
		C121.3,750.4,122.6,748.9,125.2,748z"/>
	<path class="st732" d="M114.2,738c3.7,2.5,8.1,2.7,12.2,3.7c1.7,0.4,3.8,0.1,4.6,2.3c-6-0.2-12-0.5-17.8-2
		C113,740.4,113.6,739.2,114.2,738z"/>
	<path class="st733" d="M110.2,752c1.8,1.3,3.6,2.4,6,2c1.5,1.5,1.3,3.4,1.1,5.3c-0.7,2.7-2.8,2.5-5,2.4c-2.7-0.2-5.3-1.9-8-0.6
		c-0.8-0.1-1.7-0.3-1.8-1.3c-0.3-2.7-1.3-4.2-4.4-3.4c-1.3,0.3-1.6-1.2-1.7-2.4c0.2-1.6,0.7-3,2-4
		C102.1,751.3,106.2,751.4,110.2,752z"/>
	<path class="st734" d="M110.2,752c-2.7,1.6-5.5,0.9-8.2,0.3c-1.3-0.3-2.5-1.5-3.7-2.2c0.7-1,1.3-2,2-3c1.2,3.6,4.3,3.4,6.8,2.7
		c2.3-0.6,4.1-0.1,6.1,0.3C112.2,750.6,111.2,751.3,110.2,752z"/>
	<path class="st735" d="M111.1,668c0.4-0.2,0.8-0.4,1.2-0.6c1.6,0.8,3.9-0.9,5.1,1.4c-0.5,1.2-1.7,1.4-2.7,2c-1.8,0.7-2.5,3-4.6,3.4
		c-0.3,0-0.6,0-1,0c-0.3-0.3-0.7-0.7-1-1C109.2,671.4,110.2,669.7,111.1,668z"/>
	<path class="st736" d="M136.2,814.9c-0.8,0-1.6,0.2-2,1c-4.7,0.8-9.6,2.7-14-1c-1.7-3.8-2.4-7.8-2-11.9c0.3,0,0.6,0,1,0
		c0.5,0.4,1,1,1,1.6c0.1,5,3,7.2,7.7,7.4c2.4,1,4.9-0.8,7.2,0.4C136,813,136.6,813.7,136.2,814.9z"/>
	<path class="st737" d="M145.2,788.9c1.1,0.1,2-0.4,2.9-1.1c1.2-0.1,2.4,0,3.4,0.7c2.5,3.6-0.5,5.6-2.4,7.9
		c-0.9,0.8-1.7,1.6-2.7,2.3c-4.6,1.4-7.7,5.6-12.2,7.1c-1.7,0.6-3.4,1.4-5.1,0.2c-0.4-0.8-0.4-1.5,0.2-2.1c0.1-1.5,0.8-3,2.2-2.8
		c5.1,0.7,7.6-4.1,11.7-5.5c1.8-0.6,4.6-2.7,2.1-5.7c-0.3-0.2-0.4-0.4-0.3-0.6c0.1-0.2,0.2-0.3,0.3-0.3L145.2,788.9z"/>
	<path class="st738" d="M129.2,803.9c0,0.7,0,1.3,0,2c1.2,1.5,0.4,3.5,1.3,5c0,1.5-1,1.9-2.3,2c-7.8-0.2-8.9-1.4-9-10
		c0-0.8,0.2-1.6,1-2c0.3,2.1,1.8,2,3.3,2C125.5,802.9,127.4,802.8,129.2,803.9z"/>
	<path class="st739" d="M145.2,789c0,0.3,0,0.6,0,0.9c-5.3,6-11.5,10-20,9c1-1.3,2-2.6,3-4c5.2-0.1,10.6,0.2,14-4.9
		C143.2,789.7,144.2,789.4,145.2,789z"/>
	<path class="st740" d="M275,615.2c0,0.2,0,0.4-0.1,0.5c-0.2,0.2-0.4,0.4-0.6,0.6c-0.7-1.1-0.5-2.6-2.1-3.5c-0.1,2,0.3,3.9,0.3,5.8
		c0,1.7,0.2,3.1-1.7,3.3c-2.1,0.1-3.3-1.3-3.6-3.4c-0.1-1.3,1.2-2.5,0.5-3.8c-0.9-1.6,0.1-4.8-3.4-4.6c-2.7,0.2-5.5,0.4-6.2,3.7
		c-0.7,3.1-2.5,4.9-5.6,5.4c-1.3,0.2-2.1,1-2.3,2.3c-0.8,4.9-4,6-8.3,5.7c-3.9-0.3-7.3-0.6-8.5,4.8c-0.9,4.2-8.8,3.8-14.4,2.3
		c-1-0.3-1.8-1.5-3.1-0.6c3,3.8,3.1,12.1,0.3,16.5c-1.3-1-1.6-2.6-1.4-4c0.5-3.3-0.8-5.6-3.1-7.6c-0.2-0.4-0.3-0.9-0.4-1.3
		c-0.2-1.8,1.8-3.6,0.1-5.4c-0.5-1.6-0.9-3.2-2.9-3.5c-3.2-0.4-4.6-2.6-5.8-5.2c-0.2-0.7-0.1-1.4,0.2-2c3.4-5.2,9.7-7,13.8-11.5
		c2.5-2.7,6.6-2.4,9.1-5.1c7.1-3,13.2-7.7,20-11.1c1.6-0.8,3.2-1.7,5-1.9c1.4,0.1,3.7-0.5,3.2,1.7c-0.5,2.4-0.3,5.5-3.5,6.6
		c-1,0.4-2.2,0-3.9,0.2c3.6,1,6.1-0.6,8.7-0.9c5.3-0.6,9-2.3,9.8-8c0-0.3,0.6-0.6,0.6-0.8c0-1.4,2,1.8,1.5-0.7
		c-0.2-1.1-0.1-2.7,1.5-2.5c1,0.1,1.3,1.5,1.1,2.5c-0.6,3.4-0.1,6.8,0.5,9.7c0.5-1-0.5-2.3-0.8-3.7c-0.2-1.1-0.9-2.8,1.4-2.7
		c1.2,5.5,2.4,10.9,3.7,16.4C274.9,611.4,275,613.3,275,615.2z"/>
	<path class="st641" d="M271.2,593.1c-2.5,2.8,1.6,6-0.8,9.1c-0.8-4.7-1.9-9.2-1.6-14.1c0.1,3.1-3,4.1-3.8,6.6
		c-0.3,0.9-1,1.7-0.7,2.8c1,4.1-0.5,4.2-3.5,2.2c-1.3-0.8-2.8-0.7-4.3,0c-5.4,2.7-5.4,2.6-12,0.9c1.2-0.8,2.4-2.2,2.9-2
		c4.8,1.8,4.1-2.9,5.4-4.7c2-2.7-1.9-0.4-1.9-1.8c-0.8-1.9,0.5-3.3,1.2-4.8c1-1.2,2-2.4,3.7-1.3c3.5-2.9,4.6-6.9,5.8-11.1
		c0.3-1.1,0.6-3.6,2.4-2.9c1.7,0.6,3.4,2.2,2.5,4.7c-0.6,1.5-0.9,3.1-2.7,3.9c-1.8,0.8-2.7,2.3-1.1,3.8c1.3,1.2,2.8,0.4,3.6-1.2
		c2.1-4.2,2.5-0.1,3.5,0.7c0.1,0.9,0.1,1.8,0.2,2.7c0.9,1.7-0.3,3.7,0.9,5.3C271.2,592.5,271.2,592.8,271.2,593.1z"/>
	<path class="st740" d="M271,592.2c-1.7-1.5-0.8-3.5-0.9-5.3C271.2,588.5,271,590.3,271,592.2z"/>
	<path class="st741" d="M250,482.3c-1.5,3.3-1.3,6.6-0.5,10c0.4,1.7,1.1,3.5-0.5,5c-1.6-1.2-1.5-3.3-2.3-4.9
		c-0.6-1.1-1.5-1.8-2.8-1.7c-1.4,0.1-2.2,0.9-2.3,2.2c-0.3,3.1-1.7,5.3-4.8,6.2c-0.8,0.3-1.2,1.2-1.3,2c-0.6,5.8-4.9,7.1-9.6,7.4
		c-3.4,0.2-6.4,0.5-9,3.2c-2.4,2.4-4.5,1.2-5.5-1.9c-1.2-0.5-0.3-2.7-2.5-2.9c0.2,1.6,2,1.7,2.4,2.7c0.3,0.8,0.2,1.4-0.4,2
		c-1.4,0.9-2.9,0.8-4.4,0.5c-4-1.6-7.9-3.5-13.3-3.6c2.3,1.9,4.1,1.2,5.7,1.5c1.6,0.3,3.8-0.4,4.4,2.1c-0.4,1.6-1.6,2.7-3.1,3
		c-4.5,1-8,3.8-11.9,5.8c-4.8-0.4-2.3-3.6-2.5-6.1c0.6,7.9-5.6,7.9-10.5,9.3c-1.4,0.4-2.9,0.3-4.6,0.9c1.5,2,4.9-1.1,5.5,2.3
		c-1.4,2.3-3.5,3.6-6.2,3.8c-3.1-0.4-6-1-8.1-3.7c-1.5-2-4.4-0.6-6.4-1.7c-5.3-2.4-10.7-0.7-16.1-0.7c-1.6,0-1.8,1.7-1.9,3.1
		c-0.1,2,0,4-0.3,6c-0.4,2.6-1.8,4.2-4.6,4.4c-2.5,0.2-4.6-0.7-5.5-3.3c-0.2-0.5-0.3-1-0.4-1.4c-0.4-1.3-0.8-2.6-2.5-2.7
		c-1.9-0.2-2.4,1.2-3,2.7c-2.5,5.7-5.3,6.1-10.1,2c-4-3.4-4.7-9-8.7-12.4c-1.2-1-0.4-3.2,0.1-4.8c0.6-2,0.4-3.6-1.9-4.3
		c-3.1-2.9-1.8-6.5-1.7-9.9c0.1-2.2,0.4-4.3,0.9-6.4c5.6-5.6,10-12.6,19.1-13.7c1.4-0.2,2.6-2.7,4.7-0.9c0.4,0.3,0.6,0.7,0.9,1.1
		c0.5,0.7-0.5,2.8,1.7,1.9c0.4,0.1,0.8,0.3,1.1,0.4c1.1,0.6,2.3,0.5,3.5,0.3c0.4,0,0.9,0,1.3,0c2.7,0.2,4.9-1.2,6.8-2.7
		c4.3-3.4,9.2-5.1,14.3-6.6c1.5-0.4,2.9-1,4-2c6.5-5.9,14.6-8.9,22.3-12.6c2-1,2.8-2.7,4.1-4.1c3.8-1.4,7.5-3.1,11.2-4.7
		c1.5-0.6,2.7-1.1,4,0.1c0,0,0,0,0,0c-1.2,1-1,1.6,0.1,2.9c1.8,2.5,1.8,5.7-1.2,7.3c-2.2,1.2-5.6,2.4-6.4-2.1
		c-0.3-1.6-1.3-0.9-1.7-0.5c-3,2.9-6,5.7-9.3,8.4c-1.2,1-0.9,3.9,0.3,5.6c0.9,1.5,2.7,1.5,4.1,1c8.5-2.7,17.1,0.1,25.6-0.8
		c1.6-0.2,2.9,0.6,3.9,1.8c0.7,0.7,1.2,2.1,1.9,2.2c4.8,0.8,8.8,1.9,8.5,8.3c-0.1,1.2,3.3,0.8,5,0.6c1.8-0.3,2.3-1.8,2.2-3.4
		c-0.1-2.6,0.1-4.5,3.5-4.6c2.2-0.1,3.3-1.9,3.5-4.2c0.1-1.6,1-3.3,0.8-4.9c-0.8-6.6,3.1-7.4,8.2-6.8c2.2,6.2,1.7,7.3-4.1,8
		c-1.7,0.2-3,0.6-2.8,2.6c0.2,1.8-1.2,4.3,1.4,5.2c1.6,0.6,3.6,0.7,4.8-1.4c0.5-0.9,0.6-2.3,2.2-2.6
		C250.6,478.7,249.8,480.6,250,482.3z"/>
	<path class="st635" d="M241.1,441.3c-0.1-0.6-0.3-1.2-0.4-1.9c-0.2-0.8-1.1-1.8,0.1-2.5c0.4-0.2,1-0.2,1.4,0c1.5,0.7,0.1,1.6,0,2.3
		C242,440,241.4,440.7,241.1,441.3C241.1,441.3,241.1,441.3,241.1,441.3z"/>
	<path class="st637" d="M211.1,509.3c2.6,3.1,4.9,3.5,6.9-0.6c0.9-1.9,2.8-1.4,4.4-1.4c3.5,0.1,6.9,0.1,9.9-2
		c0.9-0.6,1.7-1.1,1.6-2.2c-0.2-3.4-0.2-6.3,4.7-6.1c1.9,0,1.5-2.4,1.3-3.7c-0.2-2.6,0.7-4.4,3.4-4.3c2.2,0.1,4.7,0,4.9,3.5
		c0.1,1.6,0.8,3.2,0.7,4.9c0,2.7-3.1,6.5,2.7,7c0.1,0,0.3,0.6,0.3,0.9c-1.3,4.9,2.6,9.1,2,13.9c-0.7,5.9,1.1,11.7,1.4,17.6
		c-2.1-2.4-3-5.9-2.9-8.1c0.1-4.3-3.7-7.9-1.7-12.3c0.4-1-0.1-2.1-1.3-2.3c-1.5-0.3-1.4,1-1.5,1.9c-0.4,5.6,1,11.3-1.8,16.7
		c-0.6,1.3,0.6,2.7,1.9,3.7c2.8,2.2,3,4.8,1,7.7c-4.9,7.2-4.8,7.5,2.8,10.4c-0.4,0-0.7,0.1-1.1,0.1c-0.8,1.6-2.1,2-3.7,1.7
		c-4.3-1.5-8.9-0.8-13.3-1.5c-2.2-0.3-2,1.2-1.6,2.6c0.6,2.2,1.1,4.3-1.5,5.6c-1.8,0.5-2.6-0.8-3.8-1.8c-4.2-3.4-7.4-3.1-10.9,1.1
		c-4.3,2.8-9.3,1-13.9,1.9c-1.4,0.3-2.1-1.4-1.9-2.9c0.8-2.7,3.2-3.6,5.4-4.7c2.2-1.1,2.5-3.1,2.5-4.9c0-2.4-2.5-1.5-3.8-2.2
		c-2.7-1.3-1-2.4,0.3-3.3c1.8-1.3,2.5-2.8,1.1-4.8c-0.3-0.3-0.5-0.7-0.8-1.1c-0.7-0.9-1.5-1.7-1.9-2.7c-0.4-1.1-0.8-2.2-0.1-3.4
		c1.6-1.7,3.4-3.1,5-4.8c2.5-2.8,1.9-4-1.8-4.8c-3.3-0.7-3.8-3.1-0.9-4.6c3.6-2,4.9-4.8,5.7-8.3
		C211.1,510.6,211.1,509.9,211.1,509.3L211.1,509.3z"/>
	<path class="st637" d="M253.4,498.4c1.2,2,2.5,4,0.5,6.9C252.6,502.7,253.2,500.5,253.4,498.4z"/>
	<path class="st637" d="M257.1,507.2c0,1.7,0,3.3,0,5C256.4,510.4,253.2,508.6,257.1,507.2C257.1,507.2,257.1,507.2,257.1,507.2z"/>
	<path class="st742" d="M213.1,423.3c0.7,0.3,1.3,0.6,2,0.9c-1.1,1-2.2,2-3.3,3c-2.2,1.4-2.3,3.8-2.7,6c-0.6,3.5-0.7,7.2-3.2,10.1
		c-4.7-0.4-2.7-2.8-1.4-5c-2.9-0.5-4.9,0.1-6.3,3c-1,2.1-4,2-5.8,3.6c-1.6,1.4-3.1,2.8-4,4.7c1.7,2.1,6-1.7,6.1,3.1
		c-0.7,1.9-2.5,2.1-4.1,2.7c-12-1.5-21.9,4.1-31.8,9.6c-2.3,1.3-4.4,3.2-0.1,4.9c0.7,0.3,0.9,0.9,0.9,1.6c-2.1,3.4-5.7,4.6-9.2,5.8
		c-2.1,0-3.8-1-5.6-1.8c-0.4-0.2-0.5-2.3-1.8-0.4c-1.3,2-4.1,1.9-6.3,2.2c-1.6,0.2-3.3-0.1-4.8,0.7c-3.1,1.7-5.3,0.5-7.2-2.1
		c-0.8-0.4-1.6-0.8-2.2-1.5c-0.2-3.4,2.8-3.2,4.7-4.3c8.6-4.8,17.3-9.6,25.9-14.5c1.3-0.8,2.5-1.6,4.1-1.4c7.2-5.1,16.1-7.2,23-13
		c0.4-0.8,1.2-1,2-1c8-2.2,14-8.5,22.3-10.1c1.9-0.4,2.4-2.6,3.7-3.8C209.7,425.3,211.4,424.3,213.1,423.3z"/>
	<path class="st743" d="M194.1,452.2c-0.8-0.6-0.8-3-2.6-0.7c-1.4,1.8-2.4-0.2-3.6-0.8c-2.1-1,0-1.8,0.2-2.6
		c0.5-2.8,3.2-4.4,4.9-5.1c3-1.2,5-2.5,6.1-5.5c0.2-0.5,1.2-1.9,1.8-1.1c2.5,3.3,2.5-0.8,4.1-1.2c-0.4,2,3.8,4.3-1.1,5.8
		c-0.3,0.1,1.2,1.7,2.1,2.3c-0.3,0.7-0.6,1.4-0.8,2.1c-1.3,1.5-2.3,3.3-4.4,3.8c-1.8-0.2-2.4,1.5-3.6,2.2
		C196.3,452.3,195.4,453.1,194.1,452.2z"/>
	<path class="st744" d="M194.1,452.2c1-0.3,2-0.6,3-0.8c0.6,1,2.1,1.4,2,2.9c-2.5-0.5-4.6,1.1-6.4,2c-2.8,1.5-5.5,2.5-8.6,3
		c-0.4,0.4-0.8,0.4-1.2,0c1-3.7,4.5-3.9,7.3-4.9C191.8,454.1,193,453.3,194.1,452.2z"/>
	<path class="st745" d="M216.1,429.3c2.8-4.4,7.3-6.4,12-8c0.1,0.6,0.5,1.8,0.3,1.8C224.2,425,221,428.9,216.1,429.3z"/>
	<path class="st746" d="M213,428.3c0.7,0.3,1.4,0.7,2,1c-0.7,1.8-1.8,3.3-4.2,4.4C212,431.7,210.6,429.3,213,428.3z"/>
	<path class="st744" d="M200.7,449.2c1.5-1.3,2.9-2.5,4.4-3.8C204.7,447.9,203.4,449.3,200.7,449.2z"/>
	<path class="st747" d="M247,555.2c1.4,0.2,2.7-0.2,3.9-0.9c10.6,8.4,8.2,18.8,4.9,29.5c-0.2,0.7,0.1,1.6,0.2,2.3
		c-1.4,0-2.4,0.8-2.9,2c-1,0.3-1.3-0.3-1.5-1.1c-0.1-1.9,1.1-3.3,1.9-4.8c3-5.4,2.7-11.3,2.2-17c-0.5-5.1-4.4-7.6-9.6-6.8
		c-1.3,0.2-2.6,0.7-3.9,0c-0.4-0.4-0.5-0.9-0.5-1.5C242.8,554.6,244.7,554.2,247,555.2z"/>
	<path class="st748" d="M192,820.7c-3-3.8,1.1-5.2,2.7-6.9c2.6-2.9,5.8-2.6,8.5,0.4C199.8,816.9,196,818.9,192,820.7z"/>
	<path class="st749" d="M217,806.7c-0.3-0.9-3-1.6-0.3-2.7c3.5-1.3,7-2.3,9.5-5.5c1.2-1.6,2.9-0.8,4.1,0.5
		C226.2,802.3,221.4,804.1,217,806.7z"/>
	<path class="st750" d="M122.5,474.5c0.9-0.1,1.7-0.1,2.6-0.2c1,2.3,2.1,4.6,3.8,6.5c0.9,2.7,3.8,2.9,5.5,4.5
		c-0.1,2.3-2.1,1.3-3.2,2l0,0c-1.3,0.9-2.7,0-4,0l0,0c-0.6-1.4-2.3-1.6-3-3l0,0c-1.1-3.1-3.6-4.1-6.2-2.6c-4,2.3-4,0-4-2.8
		c1.3-1.7,3.1-2.5,5.2-2.6C120.4,475.9,121.6,475.6,122.5,474.5z"/>
	<path class="st751" d="M119.2,476.2c-1.6,1-3.2,2.1-4.8,3.1c-0.5,1.5-1.8,2-3,2.7c-2.3,0.7-3.9,3.3-6.8,2.4
		c-0.4-0.4-0.9-0.8-1.3-1.2C108,479.7,112.9,476.5,119.2,476.2z"/>
	<path class="st752" d="M208.1,426.3c0,1.9,0.4,4.5-2.2,4.8c-7.7,0.8-13.2,6.8-20.4,8.5c-1.1,0.3-2.2,0.4-3.4,0.6
		C190.7,435.6,198.7,429.6,208.1,426.3z"/>
	<path class="st753" d="M180.1,441.3c-0.2,0.3-0.4,0.5-0.6,0.7c-2.9,2.5-4.4,6.3-9.5,6.5c-4.7,0.2-8,5-12.9,5.7
		C163.9,448.4,172.1,444.9,180.1,441.3z"/>
	<path class="st754" d="M104.5,484.4c2.2-1.1,4.4-2.3,6.6-3.4c3.5,3.1,1.1,4.7-1.5,6.3c-4.7,3-8.7,6.7-10.7,12.1
		c-2.3,4.1-3.5,8.3-2.3,13c0.2,0.9,0.4,1.9-0.4,2.7c-1,0.6-1.4,2.1-2.8,2c-1.3-2.4-0.3-5.1-0.6-7.6c0-1.9,0-3.9,0-5.8
		c-0.1-0.8-0.4-1.5-0.4-2.3c1.9-4.3,2.7-9.2,6.9-12.2C101,487.7,102.8,486.1,104.5,484.4z"/>
	<path class="st755" d="M99.3,489.4c-2,4-4.1,7.9-6.1,11.9c-1.8,0-1.5-0.9-0.9-2C94,495.5,94,490.6,99.3,489.4z"/>
	<path class="st698" d="M91.2,505.3c0,2.8,0.1,5.6,0,8.4c0,1,0.8,2.7-1,2.7c-1.5,0-2.3-1.3-2.3-2.7c0-1.5,0.3-2.9,0.4-4.4
		c0-1.1-0.2-2.3,1-3c0,2.2,0,4.5,0,7.5C91.3,510.7,89,507.5,91.2,505.3z"/>
	<path class="st698" d="M92.2,499.3c0,0.8,0.2,1.5,0.9,2c0,0.7,0,1.3,0,2c-0.3,0.5-0.6,0.5-0.9,0c-0.3,0-0.7,0-1,0
		C91.4,501.9,90.8,500.3,92.2,499.3z"/>
	<path class="st756" d="M177.2,793.9c2.3,3.7-0.1,6.3-2,9c-2.8,0.1-4.7-1.9-7-3c-3.5-2.9-2.6-6.8-2.7-10.6c1.3-2,2.6-3.8,5.5-2.7
		c1.1,1.3,0.6,3.4,2.2,4.4c0.3,0.3,0.6,0.6,0.9,0.8C175,792.7,176,793.4,177.2,793.9z"/>
	<path class="st757" d="M166.2,788.9c-0.3,3.8,0,7.6,2,11c-0.3,0.3-0.7,0.7-1,1c-2.7-3.6-2.3-7.9-3-12c-1.4-1.6-3.3-0.8-4.9-1
		c-2.5-1.1-6.3-1.6-2.9-5.7c1.9-1.2,3.9-1.8,6-0.5c1.2,1.6,1,3-0.2,4.4c1.6,0.3,3.9-1.7,5.3,0.6C168,787.5,167,788.4,166.2,788.9z"
		/>
	<path class="st758" d="M162.2,800.9c0.1,1.4-0.3,2.7-1,4c-0.4,0.3-0.8,0.5-1.3,0.5c-3-1.4-2.4-3.7-1.8-6.2c0.8-3.2,0.6-6-3.8-5.9
		c-0.7-0.2-1.2-0.7-1.6-1.3c-0.4-1.9,0.7-3.1,2.1-4.1c0.5-0.2,0.9-0.2,1.3,0.1C161.8,790.6,162.2,795.7,162.2,800.9z"/>
	<path class="st759" d="M156.1,788c-0.3,0-0.7,0-1,0.1c-2.1,1.4-3.7,0.3-5.3-0.9c-1.1-2.1-0.5-3.8,1.3-5.2c0.7-0.3,1.4-0.6,2-0.9
		c0.7,1,2.3,0.8,2.9,1.9c-1.6,3.2,2,3.3,3,4.9C158.2,787.9,157.1,787.9,156.1,788z"/>
	<path class="st760" d="M136.2,814.9c-0.3-0.7-0.7-1.3-1-2c-0.2-1.6,1.1-2,2.2-2.1c3.8-0.3,7.3-2.1,11-2.9c1.7-0.3,3.4-0.5,4.8,1
		c0.3,0.7,0.7,1.3,1,2c-1.6,0.2-3,0.7-4,2C145.5,813.4,141,815,136.2,814.9z"/>
	<path class="st761" d="M153.1,808.9c-1.7,0-3.3,0-5,0c-1.9,0.7-3.8,0.7-5.7,0.4c-1.1-0.7-1.1-1.8-0.9-2.9c0.4-1.1,1.3-1.6,2.2-2.1
		c2.1-0.5,4-1.5,6.1-1.8c1.2,0.3,2.1,1.1,3,2c1,1.3,2.6,2.4,1.3,4.4C153.8,808.9,153.5,808.9,153.1,808.9z"/>
	<path class="st762" d="M154.2,808.9c0.1-1.7-1.4-2.6-2-3.9c0-0.9,0.2-1.8,1.2-2.3c2.3,0.5,3.9,3.6,6.8,2.2c0.3,0,0.7,0,1,0
		c0,1.3,0,2.6,0,4C158.8,809.4,156.5,808,154.2,808.9z"/>
	<path class="st763" d="M141.2,745c0.6-1.7,1.9-3.6,1.6-5.1c-0.8-3.7,1.6-2.7,3.4-2.9c2-0.5,2.4,0.9,3,2.3c0.1,0.4,0.2,0.9,0.3,1.3
		c0.1,2.3,2.2,3.1,3.5,4.4c0.8,1.1,1.2,2.2,0.1,3.3c-4.1,1.9-8.4,0.2-12.2,0.6c3,0.7,7.1-1.2,9.4,3.1c-4.7,2.3-8.9,0-13.2-1.4
		c-2.4-0.8-2-3.1,0.2-4.4C138.6,745.6,140.2,746.4,141.2,745z"/>
	<path class="st764" d="M141.2,745c0.5,0,1.1-0.1,1.5,0.1c1.3,0.8,0.1,0.7-0.4,0.9c-2,0.9-5.8,0.2-5.6,2.5c0.2,2.3,3.9,2,6.2,2.5
		c2.4,0.5,4.7,1.4,7.3,1c4.4-1.4,7.5,0,11.5,5.1c0.2,1.3-0.3,2.2-1.5,2.7c-1.4,0.5-2.8,0.3-4.1-0.5c-2.7-3.8-7-3.9-10.9-4.9
		c-2.9-0.7-5.9-0.7-8.8-1.5c-3.6-0.9-4.2-2.8-2.2-5.9c1.1-0.6,2.4-0.8,2.9-2.2c0-0.3,0.1-0.6,0.3-0.8
		C138.6,744.3,139.9,744.6,141.2,745z"/>
	<path class="st765" d="M148.2,740c0-1.4-0.7-2.4-2-3c-1-5.4-1.3-10.9-4-15.8c3.6-3.2,8.1-4,12.6-4.7c2.7,0.9,3.2,2,0.5,3.9
		c-2.1,1.5-4.6,2.4-7.1,4c1.9,2,5.7,3.4,0.9,6.2c-1.4,0.8,0.5,3.3,0.7,5.1C150,737.4,151.6,739.7,148.2,740z"/>
	<path class="st766" d="M155.2,717.2c-4.3,1.3-8.7,2.7-13,4c-0.3-0.1-0.7-0.1-1-0.2c1.9-1.7,3.8-3.3,5.5-5.3
		c1.3-1.6-0.2-1.8-0.5-2.6c0,0,0,0,0,0c0.7-0.7,1.6-1,2.5-1.1c1.4-0.1,2.8-0.4,4.2-0.3c0.7,0.2,1.2,0.6,1.6,1.2
		c0.3,1.3,1.2,2.3,1.9,3.3C156.4,716.9,156.1,717.3,155.2,717.2z"/>
	<path class="st767" d="M137.2,707c0-0.3,0-0.7,0-1c-0.3-0.5-0.3-0.9,0.1-1.3c1.1-0.8,2.3-0.8,3.6-0.8c1.3,0.1,2.4,0.5,3.3,1.5
		c0.3,0.4,0.5,0.9,0.5,1.4c0,0.5-0.2,0.9-0.6,1.3c-1.3,0-2.7,0-4,0C139.2,707.7,138.2,707.4,137.2,707z"/>
	<path class="st768" d="M144.2,708c0-0.3,0-0.7,0-1c1.3-1,2.3-0.3,3.3,0.6c1,1.7,2.4,3.2,1.7,5.4c-1,0-2,0-3,0
		C145.5,711.4,144.8,709.7,144.2,708z"/>
	<path class="st769" d="M152.1,774.9c-0.7-1-1.3-2-2-3c-0.3-0.5-0.3-0.9,0.3-1.3c3.2-0.6,6.1,0.8,9.2,1.2c2.7,1,3.8,2.9,3.2,5.8
		c-1.1,1.8-2.4,3-4.7,2.2c-0.4-2.3-2.5-2.7-4-3.9C153.7,775.1,153,775,152.1,774.9z"/>
	<path class="st770" d="M158.1,779.8c1.3-0.7,2.7-1.3,4-2c0.9-0.8,1.8-1.6,3.2-1.1c0.3,0.4,0.5,0.8,0.7,1.3
		c-0.3,2.2-0.3,4.8-3.8,3.9c-2.1-0.4-4,0.7-6,1c-1.4,0-2.9,0.1-3-1.9C154.8,780.6,156.5,780.2,158.1,779.8z"/>
	<path class="st771" d="M151.2,781.9c-0.3,1.6-0.6,3.3-0.9,4.9c-0.3,1,1.8,0.9,0.9,2.1c-1.1,0-2.4,0.1-3.1-1.1
		c-0.3-2.2-0.6-4.5-0.8-6.7C148.6,780.8,149.9,781.3,151.2,781.9z"/>
	<path class="st772" d="M104.2,761c1.1-2.2,2.8-2.4,4.9-1.4c2.3,1,4.6,1.8,7.1,0.4c1.1-0.6,1.9,0,2.9,0.4c1.7,1.1,3.8,1,5.6,1.9
		c1.6,0.8,4.5,1,3.2,4.2c-1.6,0.7-3.2,0.5-4.6-0.5c-4.4-1-8.7-2.3-13.2-2.9C107.8,762.8,106,762.2,104.2,761z"/>
	<path class="st773" d="M123.2,766c1.7,0,3.3,0,5,0c1.5-1.3,3.2-1,4.7-0.4c4.1,1.7,8.3,2,12.6,2.4c1.8,0.6,3.6,1.3,4.7,3
		c0,0.3,0,0.7,0,1c-7.3-1-14.6-2-22-3c-1.3-1.6-3.6-0.7-5-2C123.2,766.6,123.2,766.3,123.2,766z"/>
	<path class="st681" d="M287.1,678.9c-0.4,0.8-0.9,1.7-1.9,1.8c-1.1,0.1-1-0.9-1.2-1.6c-0.9-2.3,3-2.7,2-5.1
		c0.3,0.1,0.5,0.2,0.8,0.4C286.9,675.9,287,677.4,287.1,678.9z"/>
	<path class="st774" d="M226.1,678.1c-1.7,8.2-3.1,9.6-10.6,11.1c-2.1,0.4-4.1,1.3-6.1,2c-1.5,0.5-1.5,1.7-1,2.8
		c0.4,0.9,1.3,0.9,2.1,0.5c4.9-2.5,10.5-2.2,15.7-3.4c5.5-1.2,5.8-0.4,5.9,6.8c-0.2,0.1-0.4,0.3-0.5,0.4c-1.5,1.1-4.3-2.6-5,1.4
		c-0.9,4.2-4.5,5.9-7.7,7.1c-6.6,2.6-11.5,7.6-17.4,11.2c-0.2,0.4-0.5,0.8-0.7,1.1c-0.4,0.6-0.9,1.1-1.4,1.5c-1.9,1.4-4.1,1.9-6.2,3
		c-0.4,0.2-0.9,0.3-1.3,0.4c-2.3-0.2-4.6,3.2-7,0.2c-0.3-4.2-3.4-2.4-4.7-1.5c-3.8,2.5-6.6,1.1-10-0.7c-3.2-1.7-4.3-3.3-1.8-6.3
		c0.6-0.7,1.1-1.6,1.4-2.5c-0.3-0.3-0.4-0.1-0.7,0.2c-0.9,1.4-0.6,4.2-3.1,3.8c-2.4-0.4-1.6-2.9-1.7-4.6c-0.1-0.8,0-1.7,0.1-2.5
		c0.2-1.2,0.6-2.3,1.3-3.3c0.6-0.8,1.4-1.3,2.3-1.6c3.4-3.5,4.2-8.7,1.7-12.4c-1-1.5-2.2-2.9-2.6-4.7c-0.1-2-1.7-2.7-3.1-3.5
		c-1.8-1.1-2.9-2.5-1.4-4.4c1.2-1.6,2.6-1.9,4.7-0.7c9.2,5.3,10.6,8.1,9.2,18.7c-0.1,0.8,0,1.7,0.4,2.9c0.6-2,0.4-4.4,3-4.7
		c2.7-0.3,3.3,1.5,3.7,3.7c0.4,2,2,1.3,3.2,1.2c4.1-0.5,7.6-3.5,11.9-3.3c0.1-4.4,7.8-2.3,6.6-7.9c0.1-1.3,0.7-2.4,1.7-3.2
		C213.9,685.3,220.1,681.9,226.1,678.1z"/>
	<path class="st775" d="M206.1,690c1.1,3.1,1.5,5.7-3.3,5c-0.5-0.1-1.4,0.1-1.4,0.1c0.4,5.9-4.7,2.8-7,4.3c-3.1,2-6.6,2.4-10,3.2
		c-0.6,0.1-0.9,1-1.7,0.6c-1.8-1.7,1.1-6.1-2.5-5.9c-4.2,0.2-0.6,5-3.7,7.7c-0.2-3.4-3-5.3-1.1-9.1c2.7-5.6-1.4-12.4-7.6-13.5
		c-1.1-0.2-0.8-0.7-0.9-1.2c-0.2-1.4-1-2.3-2.3-1.7c-0.8,0.4-1.8,1.3-1.9,2.1c-0.3,1.5,1.3,1.9,2.3,2.1c2.4,0.5,3.4,1.9,3.2,4.2
		c-1.5,1.4-3.1,0.7-4.6,0.1c-1.9-0.8-3.8-0.9-5.8-0.4c-1.2,0.3-2.3,0.6-3.6,0.6c-1.4-0.1-2.2-0.8-2.3-2.3c0.7-1.8,2.7-2.5,3.7-4.1
		c3.2-2.4,6.4-4.7,10.3-5.9c5.7-0.8,9.4-5.3,14.3-7.6c3.3-1.6,6.4-3.4,9.9-4.4c0.8,0,1.4,0.2,2,0.8c-0.3,1-0.7,2.1,1,2
		c1.9-0.1,3.6,0.2,5.3,1c0.6,0.3,1.4,0.4,1.2,1.3c-0.2,0.5-0.7,1-1.2,1.4c-0.2,0.2-0.6,0.3-0.9,0.2c-4.7-0.6-8.7,0.3-11.7,4.3
		c-0.8,1.1-3,1.6-1.8,3.3c1.1,1.5,3.1,2,4.7,0.3c1.8-1.9,3.7-3.4,6.4-2.9c-1.2,3.8-5.5,4.4-7.6,7.3c-0.7,0.9-1.8,1.7-1.2,3
		c0.7,1.4,2,0.6,3.1,0.6c2.3-0.1,3.7-2.1,5.7-2.7c1.2-0.2,2.3-0.1,3.2,0.9c0.6,1.1,0.3,2.2,0.3,3.3c-0.1,0.5-0.3,0.8-0.7,1.1
		c-2,1.1-4,2.2-5.8,3.6c-0.4,0.3-0.7,0.5-1.1,0.8c-1.3,0.8-2.6,1.5-3.3,2.9c3.3,1.4,4,1.2,7.8-2.6c0.5-0.5,1.1-0.9,1.8-1.1
		C200.7,693.4,203.4,691.7,206.1,690z"/>
	<path class="st776" d="M190.1,665.1c-7.5,3.2-14.3,7.8-21.3,11.9c-1,0.6-1.8,0.5-2.7,0.1c-0.5-1.8,1.1-2.2,2-3.1
		c0.4-0.3,0.7-0.5,1.1-0.6c1.5-0.4,2.6-1.3,3.6-2.5c5.7-3.8,11.9-7,17.7-10.6c1.9-0.6,3.7-1.6,5.4-2.8c4.3-2.4,9-4.2,11.6-8.8
		c3.5-5.6,3.3-6.7-1.6-12.8c-0.1-0.5,0.1-1,0.5-1.3c2.8-1.3,4.3,0.5,5.7,2.5c0,0.3,0,0.6,0,1c0.9,2.5,1.6,5,1.1,7.7
		c-0.3,1.4-0.7,2.7-1.2,4c-0.9,2.1-2.1,4.1-4.2,5.2c-0.2,0-0.4,0.1-0.7,0.1c-0.7,0.7-1.3,1.3-2,2
		C200.9,661.2,195.3,662.7,190.1,665.1z"/>
	<path class="st777" d="M198.1,686.1c-0.7-1.2-1.9-1-3-1c-0.4-2,1.6-2.5,2.3-3.8c2.4-2.4,5.4-3.8,8-5.8c0.4-1.1-0.5-1-1.1-1.2
		c-0.8-0.2-1.7-0.4-2.2-1.2c5-1,9.9-4,14.9,0.1c0.9,0.7,2.8,0,4.3-0.1c2.2,0,4.5-0.1,4.7,3c-0.8,0.6-1.6,0.3-2.4,0.2
		c-0.8-0.1-1.6-0.3-2.5-0.4c-1.7-0.3-2.6,0.9-3.7,1.8c-0.7,0.6-1.5,1-2.3,1.4c-2.4,0.4-4.1,3.3-6.9,2.1c-0.4-2.8,2.6-3.6,3.3-5.8
		c-1.1,2-3.7,3-3.3,5.8c0.2,0.4,0.3,0.9,0.2,1.3C206.4,687.9,201.9,686,198.1,686.1z"/>
	<path class="st778" d="M212.1,646.1c0-2.7,0-5.3,0-8c6.7,2.2,2.7,8,4,12c2.3,3,1.7,5.5-1.8,6.7c-1.8,0.6-2.1,1.9-2.7,3.2
		c-0.9,2.1-2.6,2.8-4.4,1.3c-2-1.6-1-3.2,0.9-4.3C211.6,654.2,214.3,651.1,212.1,646.1z"/>
	<path class="st779" d="M223.9,675.2c0.7,0.3,1.4,0.6,2.1,0.9c0,0.7,0,1.3,0,2c-4.9,6-11.4,8.6-19,9c-0.3-0.4-0.3-0.8-0.1-1.3
		c3.3-2.1,7.2-3.3,10.6-5.2C220.1,679.1,222.3,677.5,223.9,675.2z"/>
	<path class="st780" d="M202.1,673.1c1,0,2,0,3.1,0c0.3,0.4,0.3,0.8,0.1,1.2c-1.1,1.2-2.4,2-3.9,2.6c-1.4,0.5-2.8,1.1-4,2
		c-3.2,1.7-6,4-9.8,4.4c-0.2-0.1-0.4-0.3-0.6-0.4c1.7-3.5,5.5-4.5,8.1-6.9C197.4,675.1,199.8,674.1,202.1,673.1z"/>
	<path class="st740" d="M212.1,646.1c0.5-0.1,1.3-0.4,1.4-0.2c2.6,4-0.4,10.1-5.4,11.2c-0.3-0.7-0.7-1.3-1-2c0,0,0,0,0,0
		c2-1.1,3.4-2.7,4-5C210.9,648.6,211.4,647.3,212.1,646.1z"/>
	<path class="st774" d="M237.1,701c-2.7,0-1.5,2.3-1.9,3.6c-1.8-0.7-0.5-3-2.1-3.6c-2.6,1.4-5.1,2.5-6.4-1.5
		c1.2-2.7,3.8-0.2,5.4-1.5c0,0,0,0,0,0c2.1,1.5,2.7-1,4-1.6C237.8,697.7,236.8,699.5,237.1,701z"/>
	<path class="st778" d="M190.1,665.1c4.3-3.9,10.4-4.6,15-8c-2.9,5-8.5,5.6-13,8C191.5,665.1,190.8,665.1,190.1,665.1z"/>
	<path class="st781" d="M228.1,710c-0.2,2.2,0.8,3.2,3,3c0.2,0.3,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.1,0.5c-1.6,2.1-4.1,3.2-6.2,4.6
		c-11.4,5.9-22.6,12.3-33.8,18.7c-2,0.5-4,1.4-5.9,2.2c-2.6,0.6-5.4,0.8-7.5,2.7c-3.4,2.8-7.6,4.2-11.1,6.8
		c-0.4,0.2-0.9,0.4-1.4,0.5c-1.6,0.1-3.1,0.2-3.7-1.8c0-0.8,0.4-1.4,1-1.9c7.4-4.4,14.6-9,22.4-12.9c7.5-3.8,14.6-8.5,22-12.5
		C214,717,220.2,711.7,228.1,710z"/>
	<path class="st782" d="M228.1,710c-10.7,6.4-21.7,12.3-32.5,18.5c-10.8,6.3-22,11.6-32.5,18.4c-0.6-0.9-0.1-1.8,0.2-2.6
		c0.4-0.9,1-1.6,1.7-2.2c9.7-5.8,19.5-11.5,29.5-16.8c1.9-0.6,3.4-2,5.1-3.1c1.4-0.6,2.8-1.2,4.1-2.1c3.6-2.2,7.3-4.4,10.9-6.6
		c0.4-0.2,0.8-0.4,1.2-0.6c1.5-0.5,2.9-1.1,4.3-1.9c3.9-2,7.2-5.1,11.7-5.8c0.5,0.1,0.9,0.4,1.2,0.7
		C232.7,708.9,229.7,708.7,228.1,710z"/>
	<path class="st783" d="M233.1,706c-0.4,0-0.8-0.1-1.2-0.1c-3.2-4.3-5.6,0.2-8.4,0.8c-3.6,2.3-7.6,4-11.2,6.4
		c-0.4,0.3-0.7,0.5-1.1,0.7c-1.8,0.8-3.5,1.9-5.1,3c-1.9,0.7-3.5,2.5-5.8,1.4c-0.1-1.4,0.8-1.8,1.9-2.4c6.5-4.2,12.8-8.9,19.9-12.2
		c2.1-1,3.3-2.6,4.1-4.6c0.3-0.2,0.7-0.2,1,0c1,3.6,4,1.3,6,2C233.1,702.7,233.1,704.4,233.1,706z"/>
	<path class="st693" d="M237.1,718c1.3,2.9,5.8,5,2,9c0,0,0,0,0,0c-2.5,0.1-4.9-0.7-7.4,0.1c-2.6-0.3-4.6-1-3.5-4.3
		C230.8,720.6,233.3,718.1,237.1,718z"/>
	<path class="st784" d="M237.1,718c-2.7,1.7-5.3,3.3-8,5c-0.9,0.7-1.8,0.8-2.7,0.2c-1-1.5,0.2-2.1,1.1-2.9c1.8-0.9,3.5-1.7,4.9-3.1
		c0.9-1,0.7-1.7-0.4-2.3c-0.3-0.2-0.6-0.6-0.8-0.9c0-0.4,0-0.7,0-1.1C234.9,712.5,237.2,713.8,237.1,718z"/>
	<path class="st578" d="M94.2,585.2c0,1,0,2,0,3c-1.9-2.1-0.6-4.7-1-7C94.7,582.2,94.1,583.8,94.2,585.2z"/>
	<path class="st785" d="M94.3,568.2c-0.3,0.3-0.7,0.6-1,1c-0.1-0.8-0.2-1.6-1-2c0.4-3.4-1-5.6-4-7c2-1.2,3.4,0.3,5,1
		c0.2,0.2,0.4,0.3,0.6,0.5C95,563.8,96,565.9,94.3,568.2z"/>
	<path class="st685" d="M93.2,573.2c1.8,0.8,2.1,2.3,2,4C94,576.2,93.4,574.8,93.2,573.2z"/>
	<path class="st786" d="M93.2,545.2c2.1,0.1,2,1.6,2,3.1c0,0.5-0.1,1.5,0.9,0.5c0.2-0.2,0.2-0.6,0.2-0.9c1.7,4.6,2,9.5,2.8,14.4
		c-1.2,3.7,2.5,7.1,0.4,10.8c-2.8,0.3-2.4-2.1-2.7-3.6c-0.7-3-1.1-6.1-3.6-8.3c0,0,0,0,0,0c1.7-2.4,1.1-5.3,0.8-7.7
		C93.6,550.7,93.3,548,93.2,545.2z"/>
	<path class="st787" d="M103.2,630.3c2.6-0.7,5.3-1.5,7.9-2.2c0.4-0.3,0.8-0.4,1.3-0.4c1.6,0.2,3.3,0.2,4.8,1.1
		c4.9,3,10.6,3.2,15.9,4.8c3.4,1,6,2.6,6.9,6.3c0.1,0.8,0,1.5-0.4,2.1c-1.2,0.9-1.8,0.1-2.4-0.8c-6.8-6.4-15.6-5.9-23.9-7
		C110.6,633.8,104.1,631.3,103.2,630.3z"/>
	<path class="st788" d="M129.2,641.1c1.3-0.4,2.7,1.8,4,0c1,0.3,2,0.7,3,1c1.8,1.6,4.1,2.9,3.2,6c-0.4,0.3-0.9,0.4-1.4,0.4
		c-2.5-2-6.9-1.2-7.9-5.4C129.9,642.4,129.5,641.8,129.2,641.1z"/>
	<path class="st789" d="M139.2,648.1c0.1-2.6-3.2-3.4-3-6c0.3-0.3,0.7-0.7,1-1c0.7,0.3,1.3,0.7,2,1c3.7,0.2,3.8,2.9,3.8,5.6
		c-0.2,0.8-0.6,1.4-1.4,1.7C140.4,649.7,139.6,649.2,139.2,648.1z"/>
	<path class="st790" d="M99.2,612.2c4.1-0.6,3.2-3.7,3-6.2c-0.4-4.3-0.3-8.7-1.9-12.8c-0.7-5.3-2.4-10.6-0.2-15.9
		c3.4,0.2,0.9,3.2,2.2,4.4c2.2,9.4,3.8,19,6,28.5c0.3,1.4,0.4,2.9,0.4,4.4c-0.5,2.6,1.2,6.8-4.3,5.5c-0.9-2.6-1.4-5.4-4.2-6.9
		C99.8,612.9,99.5,612.5,99.2,612.2z"/>
	<path class="st791" d="M104.3,620.1c4.9-0.1,2.2-3.9,2.9-6c0.7-0.5,0.2-1.9,1.4-2.2c2.3,1.1,1.6,3.6,2.4,5.4
		c0.2,0.9,0.5,1.7,0.8,2.5c0.4,1.2,1.2,2.4,0.7,3.8c-0.2,0.1-0.3,0.3-0.5,0.4c-2,2.5-4.3,2.3-6.8,1
		C105.4,623.3,105.1,621.7,104.3,620.1z"/>
	<path class="st688" d="M112.4,628.3c-0.4-0.1-0.9-0.1-1.3-0.2c1.1-1.1,1.5-2.5,0.9-4c0,0,0.4,0.1,0.4,0.1c1.8,0,2.6,1.1,3.2,2.6
		C115.5,629.1,113.9,628.7,112.4,628.3z"/>
	<path class="st792" d="M100.2,577.2c0,5.3,0,10.6,0,16c-3.1-7.1-3-15-4.9-22.3c-0.2-0.9-0.1-2-1-2.6c-0.3-2.3-0.7-4.7-1-7
		c3.1,0.2,3.7,2.2,4.3,4.9c0.6,2.3-0.3,5.1,1.7,7c0.4-0.3,0.8-0.3,1.2,0C100.5,574.5,100.4,575.9,100.2,577.2z"/>
	<path class="st793" d="M207.1,765.9c-3.1,1.1-5.8,2.9-8.4,4.9c-3.1,2.3-5.3,1.2-6.6-2.8c0.8-0.6,0.2-2.6,2.1-2.1
		c1.9,4.9,4,1.2,6,0.1c1.8-2,4.3-2.5,6.5-3.6c1.5-0.5,2.9-1.1,4.1-2.2c2.7-2.3,6-3.4,9-4.9c5.2-2.6,10.2-5.4,15-8.5
		c1.1-1.8-0.5-1-1.1-1.1c-0.7,0-1.4-0.1-1.9-0.6c-0.2-0.5-0.3-0.9-0.3-1.4c0.1-0.5,0.4-0.9,0.9-1.2c0.5-0.3,1-0.4,1.5-0.5
		c3.4,0,6.4,0.4,5.1,5.1c-2.9,3.9-8.8,4.2-11,9c-0.1,0.2-0.3,0.4-0.4,0.5c-5.7,1.2-9,6.9-15,7.9C210.9,764.8,208.8,765,207.1,765.9z
		"/>
	<path class="st793" d="M242.1,757c-3,1.9-5.2,5.2-9,6c-0.3-0.4-0.5-0.8-0.5-1.3c0.6-2.6,4.3-2.5,4.9-5.9c-3.4,1.6-6.1,3.4-9.1,4.8
		c-2.7,1.5-5.3,3.2-7.7,5.2c-0.9,0.6-1.5,2-3,1c-1.3-2.8,0.6-4.4,2.6-5.6c2.6-1.7,5-3.7,7.8-5.2c0,0,0,0,0,0c2.3,0.4,4-0.9,6-1.9
		C237.6,752.2,240.8,752.1,242.1,757z"/>
	<path class="st794" d="M233.1,743l-0.5,0l-0.5,0c-8,1.7-9.3,1.1-10.4-5.1c1.1-1.6,2.1-3.1,4.4-1.9c3.7,0.9,6.4,4.5,10.7,3.6
		c0.2,1.4,3.5-0.2,2.4,2.4C237.2,743.4,234.8,741.3,233.1,743z"/>
	<path class="st795" d="M232.1,726c2.5-1,4.9-1.1,7,1c-1.2,5.7-5.2,8.4-10.9,7.3c0.1-0.2,0.1-0.4,0.2-0.7c1.4-1.7,3.7-2.1,5.4-4
		c-3.6-0.2-5.9,4.2-9.6,2.2C224.5,726.9,229.1,727.6,232.1,726z"/>
	<path class="st796" d="M226,735.9c-1.6,0.2-2.9,1.1-3.9,2.2c-1.5,2.2-3.8,3.3-6.4,3.8c-1.7,0-3.2-0.4-3.1-2.6
		c2.8-3.9,7.3-5.2,11.4-6.9c1.4,0.3,2.5-0.8,3.7-0.7c2.2,0.1,1.8,1.2,0.4,2.2c0,0,0.1,0.4,0.1,0.4
		C226.9,734.2,226.6,735.3,226,735.9z"/>
	<path class="st797" d="M233.1,743c1.7-2.5,4-0.8,6-1c0.7,1.7,1.9,3.3,0,5C239.3,742.2,235.9,743.1,233.1,743z"/>
	<path class="st597" d="M234.9,794.2c-2,1.3-3.3,2.3-4.7,1c-0.2-0.2-0.3-1-0.1-1.2C231.5,792.8,232.8,793.9,234.9,794.2z"/>
	<path class="st798" d="M162.1,781.9c2.8-0.5,2.8-2.8,3-5c1-0.5,1.8,0.1,2.6,0.6c1.2,1.2,2.5,2.3,4.2,0.9c2.3-1.9,4.4-3.9,3.5-7.3
		c-0.1-0.4-0.2-0.9-0.2-1.3c0.1-1.7,0.7-3.4,0.2-5.2c0-1,0.3-1.8,1.2-2.3c0.4-0.2,0.8-0.4,1.3-0.5c2.3,0,3.4-2.9,5.9-2.5
		c1.1,0.2,2.4,1.3,2.7-0.9c0.4-1.2,1.2-1.9,2.5-1.9c1.4,0.4,2.5,1.2,3.5,2.3c0.7,1.7,2.3,3.2,1.5,5.3c-0.7,0.4-1.4,0.4-2-0.2
		c-1.2,0.2-2.6-1.3-3.6,0.4c-0.9,1.6,0.3,2.3,1.4,3.2c1,0.8,1.6,2.1,0.9,3.1c-3.2,4.5,0.6,9.1-0.4,13.6c0,0.2,0.4,0.5,0.6,0.7
		c0,0.7,0,1.3,0,2c-3,2.1-4.6-0.5-6.2-1.7c1,3,0.2,4.9-3.5,5.2c-2.6-2.6-5.6-3.3-9-1.9c-0.5-0.1-0.8-0.3-1.2-0.7
		c-2.3-2.6-3.2,1.4-5,1c0-0.8,1.8-2.1-0.5-2.2c-1.4-0.1-2.8,0.2-5.2,0.4C162.2,785.2,162.2,783.6,162.1,781.9z"/>
	<path class="st799" d="M192.1,764c0.7,0,1.3,0,2,0c2.7-3.6,5.6-7,10.1-8.5c-2.6,0.6-5.3-1-7.8,0.5c-1.3,0.6-2.7,1.2-4.2,0.4
		c-0.4-0.3-0.6-0.7-0.7-1.2c0.3-2.2,2.5-2.7,3.8-4c2.6-1.8,5.8-2.6,8.4-4.7c0.8-0.5,1.6-0.9,2.4-1.3c3.2-1.4,6.1-3.6,9.8-3.6
		c0.9,1.8,0.9,1.8-0.9,3.2c2.9,1.1,5.9,2.3,9.1,3.1c0.3,0.4,0.5,0.8,0.5,1.3c-2.4,3.8-6.8,4.9-10.3,7.2c-1.8,1.2-3.9,1.9-5.5,3.2
		c-4.2,2.1-8.1,4.9-12.3,7.1c-0.8,0.1-1.5-0.1-2-0.6c-0.7,0.6-1.5,1.3-2.2,1.9C192.1,766.6,192.1,765.3,192.1,764z"/>
	<path class="st800" d="M228.1,756c-1.6,2.5-3.7,4.5-6.5,5.7c-2.3,1-3.8,2.5-3.5,5.2c-2.3,2.3-5.7-0.3-8.1,1.6
		c-0.7,0.1-1.4-0.1-1.9-0.6c0.5-1.1,0.7-2.1-1-2c2.7-1.8,5.4-3.7,8.8-3.6c1,0,1.6-0.4,2-1.1C220.1,757.2,224.1,756.6,228.1,756z"/>
	<path class="st801" d="M210,767.9c2.4-2.7,5.3-1.1,8-1c0.6-0.7,1.1-1.3,1.7-2c1.9,1.1,3.6,0.8,5.5-0.3c2.1-1.2,4-2.9,6.5-3.3
		c0.5,0,0.9,0.1,1.3,0.4c0,0.4,0.1,0.9,0.1,1.3c-7.7,4.3-15.3,8.6-23,13c-0.3-0.3-0.5-0.7-0.5-1.2c0.1-1,0.7-1.7,1.4-2.4
		c0.7-0.7,2.1-1,1.9-2.3c-0.1-0.6-1-0.3-1.6-0.4c-0.5-0.1-0.9-0.2-1.3-0.5C209.7,768.7,209.7,768.3,210,767.9z"/>
	<path class="st802" d="M210,767.9c0,0.3,0,0.7,0.1,1c0.1,2.4-2.3,2.5-3.5,3.7c-2.8,1.8-5.9,3.2-8.4,5.5c-0.7,0.8-1,1.8-1.3,2.7
		c-0.2,0.4-0.5,0.7-0.8,1c-3.3-3.4-1.4-8,4.3-9.8c2.9-0.9,5.3-2.5,7.7-4.2C208.7,768,209.4,768,210,767.9z"/>
	<path class="st803" d="M196.1,781.9c0-0.4,0-0.7,0.1-1.1c0.7-0.6,1.3-1.3,2.1-1.7c3.6-0.9,6.2-3.9,9.7-5c0.7-0.1,1.4,0,2,0.6
		c0,0.4,0,0.8,0.1,1.2c-2.8,2-5.2,4.3-8.5,5.6C199.5,782.4,198,782.3,196.1,781.9z"/>
	<path class="st701" d="M181.1,789.9c2.7-1,5.5-2,1.6-4.9c-0.9-0.7-1-1.1-0.5-1.9c0.9-1.4,1.6-0.5,2.2,0.4c1.7,2.2,3.9,3.5,6.7,3.5
		c0.3,0.3,0.7,0.7,1,1c-1.5,2.7-4.9,3.3-6.5,6.2c-0.8,1.5-1.5-1-2.5-1.2C182.5,791.9,180.6,791.8,181.1,789.9z"/>
	<path class="st804" d="M181.1,789.9c0.6,1,2.2,1.5,2,3c-1.5,0-3,0-4.6,0c-1-0.2-1.5-2.9-2.9-0.5c-1,0.3-1.8,0.2-2.5-0.6l0,0
		c-0.8-1.2-2.3-2.3-1-4C175.8,785.7,179,785.5,181.1,789.9z"/>
	<path class="st805" d="M176.1,791.9c-0.3-1-2.5-1.2-1.3-2.5c0.9-0.9,2.2-0.2,2.8,0.8c0.5,0.8,0.6,1.8,0.9,2.7
		c-0.5,0-0.9-0.1-1.4-0.1C176.6,792.8,176.3,792.5,176.1,791.9z"/>
	<path class="st806" d="M176.1,791.9c0.4,0.3,0.7,0.6,1.1,0.9c0,0.3,0,0.7,0,1c-1.9,0.4-3.6,0.6-4-2
		C174.1,791.9,175.1,791.9,176.1,791.9z"/>
	<path class="st807" d="M103.2,553.2c0.3,0,0.7,0,1,0c1.6,1,2,2.7,2,4.5c-0.5,2.1-0.8,4,1.6,5.2c3.1,10.9,4.9,22.1,8.1,33
		c1.9,6.3,0.6,13.4,2.2,20c0.2,0.8-0.3,1.6-0.9,2.2c-3.2,0.8-4.8-0.5-4.6-3.8c0-0.7,0.3-1.5,0-2c-6.5-7.5-0.7-18.1-6.6-25.7
		c-0.7-0.9,0.3-2.2,0.2-3.4c-0.1-2,0.3-4.1-2-5.2c-0.7-4.6-1.6-9.1-1.9-13.7c-0.3-3.5,0-6.9-1.8-10.1c-0.6-1-0.4-2.3,0.7-3.1
		c0,0.8,0.2,1.6,1,2C102.6,552.7,102.9,552.7,103.2,553.2z"/>
	<path class="st808" d="M104.2,578.2c2-1.1,3.3-0.6,2.9,1.8c-0.6,3.9,0.6,7.2,1.9,10.8c1.1,3.2,2.5,6.2,1.4,10.2
		c-0.7,2.4-1.5,7.2,3.3,9c1,0.4,0.9,2.6,0.4,3.7c-1.3,2.7,0.6,3.4,2.2,4.4c0.8,0.9,0.4,1.7-0.3,2.4c-2.7,1.2-3.8-1.7-5.7-2.3
		c-0.6-2-1.2-4.1-1.9-6.1c-0.8-0.9-0.6-2.1-1-3.1c-1-6.1-2.6-12-3.4-18.1c-0.2-1.3-0.8-2.6,0.5-3.7
		C106.3,584.1,104.8,581.2,104.2,578.2z"/>
	<path class="st809" d="M104.4,587.2c1.2,7.3,2.4,14.7,3.7,22c-0.2,0.4-0.5,0.8-0.9,1c-2.6-7.9-3.7-16.1-5-24.3
		c-0.2-1.3-0.6-2.5-0.9-3.8c0.7-0.6,0.1-1.8,1.1-2.3C103.1,582.3,103.7,584.7,104.4,587.2z"/>
	<path class="st810" d="M100.2,573.2c-0.3,0-0.6,0-0.9,0c1-3.6-1.9-7.2-0.1-10.9c0.7,3.3,1.3,6.6,2,10
		C100.9,572.7,100.6,573,100.2,573.2z"/>
	<path class="st811" d="M100.2,573.2c0.3-0.3,0.6-0.6,0.9-1c0.4,2.6,0.9,5.1,1.3,7.7c-0.4,0.8-0.7,1.5-1.1,2.3
		c-0.3-1.6,0.6-3.6-1.1-4.9C98.5,575.9,99.7,574.5,100.2,573.2z"/>
	<path class="st812" d="M112.2,693c1.4-2.6,2.2-5.8,6-6c0.5,0.5,1.7,0.4,1.7,0.8c0,6,5,7.9,8.9,10.8c1.1,0.8,2.9,1.8,0.4,3.2
		c-2.5,0.7-4.8,0.7-7-0.9C117.6,700,115.2,696.2,112.2,693z"/>
	<path class="st813" d="M122.2,701c2.7,0,5.3,0,8,0c0.6-0.6,1.3-0.7,2-0.5c1.8,1.3,5,1.2,5,4.5c0,0.3,0,0.7,0,1c-2.1,0-4.1,0.2-6-1
		c-2,0-4,0-6,0C124.8,703.3,122.1,703.2,122.2,701z"/>
	<path class="st814" d="M132.2,701c-0.7,0-1.3,0-2,0c-1.7-1-3.4-2-5-3.1c-3.8-2.7-7.2-5.6-7-10.9c1.9-0.2,2.7-1.9,4-3
		c0.6-0.6,1.3-0.6,2.1-0.3c1,0.9,1.4,2,1.2,3.3c-2.4,4.3-2.1,5.3,2,7.7c1.5,0.9,3.4,1.3,4.5,2.9C132.7,698.7,133.2,699.9,132.2,701z
		"/>
	<path class="st815" d="M124.1,684c-0.7,0-1.3,0.1-2,0.1c-1,0-2.1-0.1-3.1-0.1c-0.4-0.5-0.9-1.4-1.3-1.4c-5.9,0.1-6.6-4.3-7.6-8.5
		c1.2-1.5,1.6-3.7,4-4c0.3,4,2.4,7.2,5.2,9.9c1,0.8,2.2,1,3.4,0.9c0.4,0,0.8-0.1,1.3-0.1c0.9,0,1.8-0.2,2.4,0.7
		C126.9,683.4,125.8,683.9,124.1,684z"/>
	<path class="st816" d="M136.2,653.1c-2.6,0.8-5.3,1.2-8,1c-2.1,0-4.1-0.2-6,1c0-0.7,0-1.3,0-2c-2.9-1.9-2.8-3.5,0.4-4.8
		c2.6-0.6,5.2-0.6,7.8-1c2.2,0.3,4.4,0.2,6.2,1.8C137.4,650.5,137.2,651.9,136.2,653.1z"/>
	<path class="st817" d="M127.2,659.1c1.5,0.1,2.8-0.3,4-1c0.9,1.1,3.3-0.3,3.3,1.9c0,1.8-1.6,1.7-2.8,1.8c1,1.5-0.1,2.3-1.1,3.1
		c-2,1.1-3.9,2.5-6.3,1.3c-0.6-1.8-3.8-0.3-3.5-2.5c0.2-1.7,2.3-2.1,3.9-2.6C125.8,660.8,126.7,660.2,127.2,659.1z"/>
	<path class="st818" d="M123.2,682c-1.4-0.1-3,0.5-4-0.9c-1.3-3.9-2.5-7.8-2-12c-1.2-1.8-3.6-0.2-4.9-1.7c1.9-0.1,3.9-0.2,5.8-0.3
		c2.5-1.1,3.6,0.8,3.8,2.5C122.5,673.7,124,677.7,123.2,682z"/>
	<path class="st819" d="M123.2,682c-1.3-3-2-6.2-2-9.5c0-2.3,0-4.7-3-5.4c2-2,3.9-0.2,5.9,0.2c1.1,0.7,1.3,1.8,1.4,2.9
		c-1.7,3.8,1.1,8.1-1.2,11.9C123.9,682,123.6,682,123.2,682z"/>
	<path class="st820" d="M124.2,670c-0.1-0.9-0.1-1.9-0.2-2.8c0.1-0.3,0.2-0.7,0.2-1c2-0.7,3.9-1.4,5.9-2.1c1.9-0.4,3.6-2.2,5.8-1.3
		c0.4,0.3,0.6,0.8,0.7,1.3c0,0.5-0.2,1-0.6,1.4c-1.1,0.8-2.4,1.2-3.7,1.4c-2.9,0.4-4.9,1.7-5.4,4.8c-0.1,0.8-0.7,1.5-1.6,1.6
		C124,672.6,125.2,670.9,124.2,670z"/>
	<path class="st821" d="M122.2,649.1c-2,1.3-0.9,2.7,0,4c-7.3,0-14.7-0.6-22,0.2c-1.9,0.2-3.9-0.8-5.9-1.2c0-1.7,0-3.3,0-5
		c1.7,0.8,3.3,1.3,5.4,1c6.1-0.9,12.3-0.4,18.4-0.4C119.6,647.8,121.1,647.8,122.2,649.1z"/>
	<path class="st822" d="M136.2,663.1c-2,0.3-4,0.6-6,1c1.3-0.9,1.2-2.5,2.9-3.5c2.1-1.3-1.9-1-1.9-2.4c3.4,0.1,5.6-2,8-4
		c1.2-0.4,1.9,0.2,2.5,1.1c0.5,2.1-0.2,4-1,5.8C139.4,662.3,138.3,663.7,136.2,663.1z"/>
	<path class="st823" d="M141.2,655.1c-0.7-0.3-1.3-0.7-2-1c-1-0.3-2-0.7-3-1c0-1.3,0-2.7,0-4c0.4-0.8,1.2-1,2-1c0.3,0,0.7,0,1,0
		c0.7,0.3,1.3,0.7,2,1c1.3,1.3,1.8,2.8,1.6,4.6C142.5,654.5,141.9,654.9,141.2,655.1z"/>
	<path class="st824" d="M122.2,649.1c-7.9,0.2-15.8-0.7-23.6,0.7c-2.6,0.5-3.6-0.7-4.4-2.7c0-0.7,0-1.3,0-2c1.6,0.1,2.6-0.5,3.3-2.2
		c0.9-2.2,2.8-2.6,4.7-0.9c1.9,2.3,4.5,1.6,6.9,1.6c7.1,0,14.1,0.7,20.8,3c0.5,0.4,0.5,0.9,0.2,1.4
		C127.7,649.6,124.9,648.9,122.2,649.1z"/>
	<path class="st825" d="M93,509c0.1,2.8,0.2,5.5,0.3,8.3c-0.2,1,0.1,2.3-1.1,2.9c0-3.6,0.1-7.2,0.1-10.8l0.2-0.4L93,509z"/>
	<path class="st755" d="M93,509c-0.3,0.1-0.5,0.2-0.7,0.4c0-2,0-4.1,0-6.1c0.3,0,0.6,0,0.9,0C93.1,505.2,93.1,507.1,93,509z"/>
	<path class="st826" d="M101.2,513.3c3.9,0,4.7,1.7,3,5.1c-1.4,2.8-1.9,5.4,2.1,6.5c1.2,0.3,2,1.3,1.8,2.6c-0.5,4.7,3.7,6.4,6.2,8.9
		c2.3,2.3,5.5,0.6,5.7-3.1c0.2-3.4,1.4-4.1,4.5-4.2c3.4,0,3.8,1.7,3.7,4.4c-0.2,2.8,1.4,3.7,4.1,3.7c2.7,0,3.7-1.2,4-3.9
		c0.3-3.3-2-7.7,1.4-9.6c2.6-1.4,6.5-0.7,9.8-0.4c2.9,0.3,6.7-2.8,8.7,1.9c-0.1,0.2-0.2,0.4-0.3,0.6c-2.1,1.4-2.6,3.4-2.1,5.8
		c-0.1,3.6-2.4,5.2-5.6,6.1c2.3,0,5.6-2.5,5.6,2.4c-1.1,2.5-3.2,3.6-5.5,4.5c-2.6,1-5,2.5-6.8,4.7c-0.7,0.9-1.6,1.9-0.9,3.1
		c0.8,1.3,2,0.6,3.1,0.2c1.5-0.6,2.9-1.6,4.7-1.1c1.7,0.8,1.1,1.8,0.1,2.6c-1.1,1-2.4,1.7-3.4,2.9c-5.3,4-11.2,6.9-17.3,9.5
		c-4.2-1.6-6.9-4.9-9.1-8.7c-0.9-1.6-1.5-3.3-3.7-3.5c-3.2-0.3-4-2.6-3.9-5.3c0.1-3.3-0.8-5.6-3.2-8.2c-2.4-2.5-2.3-6.9-3.7-10.4
		c-0.3-0.7-0.3-1.6-0.8-2.3c-0.8-0.4-1-1.2-1-2c-1-2.1-0.3-4.6-1.8-6.5C98.8,517.3,99.7,515.3,101.2,513.3z"/>
	<path class="st827" d="M103.2,528.2c1.9,3.3,5.9,5.6,4.2,10.3c-0.3,0.8,0.3,1.9,1,2c5.1,0.7,3.8,4.5,3.7,7.5
		c-0.1,3.2,0.2,5.7,4.4,5.1c1.2-0.2,2.4,0.1,2.6,1.7c0.6,5.8,5.6,7.9,9.1,11.3c0.6,3.9,3.7,5.7,6.3,8c-1.2,2.3-2.4,4.5-2.1,7.2
		c0,2.8-1.9,5.2,0.8,8.4c1.7,2.1,0.4,6.6,1.3,10c-5.1,3.1-5.5,7.9-5,13.1c0.3,4-2.4,6.2-5,8.6c-1.5,0.9-3,1.5-4.8,1
		c-1.3-0.7-1.7-2-1.8-3.4c-0.6-15.6-4.2-30.8-7.6-45.9c-2.8-12.3-5-24.7-7.1-37C103,533.6,101.9,530.9,103.2,528.2z"/>
	<path class="st828" d="M103.2,536.2c3.4,5,2.2,11.1,3.9,16.5c1.8,5.9,2.9,12.1,3.9,18.2c2.3,14.2,6.9,28.1,7.3,42.6
		c0.1,2.9,0.5,5.8,2,8.5c1.1,1.3,0.3,2.4-0.5,3.4c-1.7,1.5-3.3,0.9-4.9-0.3c-0.5-0.7-0.6-1.4-0.7-2.2c0-1.1,0.4-2,1.1-2.8
		c0.8-0.4,1-1.2,1-2c0.3-0.4,0.9-0.9,0.8-1.1c-3.1-8.5-0.5-17.7-3.8-26.2c-2-5.2-2.7-10.9-4.1-16.4c-1-4.1-2-8.1-3-12.2
		c-1.5-1-0.3-2.7-1-4c-0.3-1.7-0.7-3.3-1-5c0-4.5,0.4-9.1-1.7-13.4C102,538.6,101.9,537.2,103.2,536.2z"/>
	<path class="st829" d="M101.2,513.3c0.8,2-1.6,4,0,6c0.7,1.3,0.7,2.7,0,4c-1.7-2.7-3.3-5.3-5-8c-2-5.7-2.4-11.1,2-16
		c0.9-0.8,1.1-2.6,3-2C101.3,502.6,98.7,507.9,101.2,513.3z"/>
	<path class="st827" d="M101.2,523.2c0-1.3,0-2.7,0-4c1.9,2.1,0.6,4.7,1,7C101,525.5,101.3,524.3,101.2,523.2z"/>
	<path class="st707" d="M103.2,553.2c-0.3,0-0.7,0-1,0c0-1.6,0-3.2,0-4.8c0.3,0,0.7,0,1,0C103.2,550,103.2,551.6,103.2,553.2z"/>
	<path class="st830" d="M130.2,647.1c-8.5-1.3-17-2.6-25.6-2c-2.1,0.1-2.3-1.3-2.3-3c8.7-0.1,17.4,0.2,26,2
		C129.9,644.4,131.4,644.9,130.2,647.1z"/>
	<path class="st831" d="M130.2,647.1c0.2-1.6-1.4-2-2-3c0.4-0.8,1.2-1,2-1c2,2.7,6.4,1.7,8,5c-0.4,0.8-1.2,1-2,1c-2.1,0-4.1,0.2-6-1
		C130.2,647.8,130.2,647.4,130.2,647.1z"/>
	<path class="st832" d="M118.2,761c-0.7-0.3-1.3-0.7-2-1c0-2,0-4,0-6c3.3-1.5,4.7-5.9,9-6c-1.3,1.5-3.3,2.8-1,5
		c0.4,0.4,0.7,0.8,0.9,1.2c0.4,2.5,2.1,4.7,1.4,7.4C123.5,763.6,120.9,761.9,118.2,761z"/>
	<path class="st833" d="M132.2,756c0.8,0,1.6-0.2,2-1c0.4-0.3,0.9-0.4,1.4-0.3c3.8,1.5,7.5,3,11.6,3.9c4.8,1,8.7,3.6,11.4,7.9
		c0.3,0.7,0.3,1.5,0,2.3c-0.3,0.5-0.6,0.8-1.1,1c-2.9,0.7-5.5-0.5-8.3-1c-2.6-1.1-5.2-2.3-6.3-5.2c-1.8-2.9-5-3.3-7.9-4.3
		C133.4,758.7,131.1,758.7,132.2,756z"/>
	<path class="st834" d="M135.2,755l-0.5,0c0,0-0.5,0-0.5,0c-2.3-1-4.5-2.1-4-5.3c0.4-2.4,2-2.7,4-2.7c-1.7,3.4,0.4,4.7,3.1,4.9
		c5.4,0.3,10.5,1.7,15.7,3c2.5,0.6,3.3,1.8,3.2,4.1c-0.3,0.1-0.5,0.2-0.8,0.3C148.6,758.3,141.6,757.8,135.2,755z"/>
	<path class="st835" d="M132.2,756c1.8,3.6,6.2,1.6,8.7,3.9c1.1,1,3.2,1,3.3,3.1c-5.5,2.4-11.2,1.1-15.7-3.5
		c-1.6-1.7-2.8-3.7-4.3-5.4c0-0.3,0-0.7,0-1C127.7,751.8,128.8,757,132.2,756z"/>
	<path class="st836" d="M117.2,669.1c2,3.8,2,7.9,2,12c-3.6-2.8-7.5-5.4-5-11C114.9,668.9,116.1,669.1,117.2,669.1z"/>
	<path class="st837" d="M142.2,808.9c2,0,4,0,6,0c-3.9,2.6-8.7,2.4-13,4c-2.3-1-4.7,2.2-7,0c0.7-0.7,1.3-1.3,2-2
		C133.7,807.5,137.6,806.3,142.2,808.9z"/>
	<path class="st838" d="M142.2,808.9c-4.2-0.7-8.1,0.8-12,2c-4-0.9-0.5-3.4-1-5c1.7-0.3,3.3-0.7,5-1c2.7,0,5.8-3.3,8,1
		C142.2,806.9,142.2,807.9,142.2,808.9z"/>
	<path class="st839" d="M142.2,805.9c-2.5-2-5.3-0.6-8-1c4.2-2,6.9-6.5,12-7c2.4,3.2,0.5,5.2-2,7C143.7,805.7,143,805.9,142.2,805.9
		z"/>
	<path class="st840" d="M151.1,788.9c0.1-0.9-2.2-0.7-0.9-2.1c1.6,0.4,3.3,0.8,4.9,1.2c-0.7,1.3-1.3,2.6-2,3.9
		c-1.2,3.2,1.1,7.6-3.4,9.7c-3.6-1-2.6-3.3-1.6-5.6C149.8,793.9,152.1,792.1,151.1,788.9z"/>
	<path class="st841" d="M148.2,795.9c0.4,1.8-1.2,4.4,2,5c0.6,0.7,0.5,1.3,0,2c-1.8,1.3-3.6,2.4-6,2c1-2.2,2.7-4.3,2-7
		C146.5,797,147.2,796.3,148.2,795.9z"/>
	<path class="st842" d="M203.1,623.1c2.2,1.7,3.4,4.5,7.2,4.3c2.8-0.2,1.4,3,1.8,4.7c-1.8,3.8-5,2.4-7.8,2.1c-1.3-0.2-2.8-0.8-4-1.6
		c-0.8-1.9-0.7-4.7-4.1-3.9c-1.4-0.2-2.8-0.3-3.9-1.2c-0.7-2.6,2.2-2.4,3-3.9c0.6-0.6,1.2-1.3,2-1.8
		C199.7,620.1,200.8,624.3,203.1,623.1z"/>
	<path class="st778" d="M200.1,632.1c3.4-0.7,6,2.9,9.7,1.4c1-0.4,1.5-1.1,2.3-1.4c3.5,1.7-0.8,3.3,0,5c-2-0.5-3.5-2.8-5.9-2
		c-1.1,1.4-3,1.1-4.4,1.8c-1.2,0.6-2.4,1.3-3.6,1.9c-1.3,0.6-2.6,1.1-4,1.4c-2.6-0.2-4.5,2.5-7.2,2c-0.8-4.7,0.8-6.7,5.5-7
		c0.4,0,2.1,1.4,1.4-0.8C195.6,632.7,197.6,631.8,200.1,632.1z"/>
	<path class="st843" d="M252,587.2c0.3,0.3,0.7,0.6,1,1c-0.7,1.3-1.3,2.7-2,4c-8.7,3.5-15.8,10.2-25,13c-0.5-1.1,0.1-1.9,0.7-2.6
		c5.6-5.5,13.5-7.4,19.7-11.9C248.5,589.8,249.4,587.1,252,587.2z"/>
	<path class="st844" d="M217.1,610.2c0.1,3.3-3.1,3.4-4.8,4.2c-3.6,1.7-5.4,5.7-9.2,6.8c-0.1-0.2-0.2-0.4-0.3-0.6
		c0.1-1.1,0.7-2,1.1-2.9c0.4-1,0.8-2,1.7-2.7c0.4-0.3,0.7-0.5,1.1-0.7c3.2-1.1,5.9-3.2,9-4.4C216.3,609.8,216.7,609.9,217.1,610.2z"
		/>
	<path class="st845" d="M217.1,610.2c-0.3,0-0.6,0-1,0c-0.5,0.4-0.9,0.4-1.2-0.1c2.7-4.7,6.9-6.7,12.2-6.8c-0.3,0.7-0.7,1.3-1,2
		C224.3,609,219.8,607.9,217.1,610.2z"/>
	<path class="st844" d="M203.1,623.1c-2,2.2-2.2,2.3-3.2,0.2c-0.7-1.4-1.7-1.1-2.8-1.2c0-0.2-0.1-0.5-0.1-0.7
		c0.4-0.7,1.1-1.1,1.8-1.2c1.6-0.3,3.1-0.3,4.3,1c0,0,0,0,0,0C203.1,621.8,203.1,622.5,203.1,623.1z"/>
	<path class="st740" d="M263.4,593c-1-0.2-1.3-0.9-1.3-1.8c0-0.6,0-1.5,0.9-1.5c1.2,0,1.5,1.2,1.6,2.1
		C264.8,592.6,264.2,593,263.4,593z"/>
	<path class="st846" d="M204.1,537.2c0.7,0.7,1.3,1.3,2,2c-0.5,0.5-1,0.9-1.7,1.1c-1.7,0.5-3.5,0.9-5,1.9c-1.6,0.7-3.1,2.3-5.2,1.1
		c-0.7-2.9,1.5-4,3.6-5.5c-3.3,1.6-5.4,4.3-8.5,5.6c1.6-0.4,3.1-1.7,4.8-0.2c-0.6,3.9-4.3,4-6.7,5.8c-3.2,0.8-5.2,4.6-9.1,3.4
		c-0.8-2.4,0.9-3.5,2.2-5c-3.4,2.7-7.7,3.9-11.2,6.6c-1.2,0.9-2.6,1.5-4.1,0.5c-0.9-2.2,1.4-2.7,2.1-4c2.1-2.3,5.9-2.4,7.7-6.2
		c-5.4,1.2-9.2,4.8-14,5.9c-1,0.1-1.9,0-2.7-0.6c-0.3-3.2,2.7-3.6,4.4-4.2c5.3-1.8,9.9-4.9,15.4-7.9c-3.1,0.7-5,1.8-6.7,3.7
		c-1.9,2.2-4.4,2.8-7.2,1.5c-0.4-0.3-0.6-0.7-0.7-1.2c0-1.4,1-2.1,1.9-2.9c1.3-1,2.7-1.7,4.3-2.3c2-0.5,3.6-1.7,5.3-2.8
		c5.3-3.3,11-5.9,16.4-9c4.9-3.6,10.9-5.4,15.6-9.2c0-1.1-0.8-0.8-1.4-0.7c-5.6,1.8-9.9,6-15.2,8.4c-1.9,0.7-3.6,2.2-5.9,1.9
		c-0.5-0.2-0.8-0.5-1-1c-0.2-3.3,2.9-2.5,4.5-3.6c4-4.5,10.3-4.8,15-8c0.8-0.9,1.7-1.6,3-1c1.7,0,3.3,0,5,0c0.6,3.7,0.6,7.1-4,8.5
		c-1.1,0.3-2.9,0.9-2.4,2.6c0.4,1.4,2,0.9,3,0.9c4-0.1,4.3,2.5,2.7,4.8c-1.8,2.6-3.7,5.5-7.3,6.2
		C202.2,535.6,203.7,536.3,204.1,537.2z"/>
	<path class="st847" d="M131.2,487.3c1-0.7,2-1.4,3-2.1c0.4-2.8,3.2-2.4,4.7-3.7c3.6-2.1,7.1-4.4,11.3-5.3c3.3-1.2,6.3-2.8,9-4.9
		c6.4-5.7,14.4-8.5,21.8-12.5c0.6-0.3,1.3-0.1,1.9,0.3c0.4,0.1,0.9,0.1,1.3,0.2c-0.8,1.4-0.3,3.7-2.7,4.3c-5.7,1.3-9.9,5.9-15.4,7.4
		c-4.3,1.2-5.9,5.8-10.2,6.8c-7.8,1.8-14.4,6.4-21.3,10.3C133,489.1,132.2,488.2,131.2,487.3z"/>
	<path class="st848" d="M153.2,540.2c-0.3-1.4-0.5-3.6-2.6-1.7c-2,1.7-4.4,1.6-4.9-0.2c-0.9-3,2.4-1.7,3.8-2.1
		c2.6-0.8,2.8-3,3.7-4.8c2.2-1.4,1.8-4.1,3-6c0,0,0,0,0,0c3.2,0,6.9-1.6,8.6,3.2c0.4,1.1,3.5,1.2,5.4,1.8c0.2,2.3-2,2.3-3.2,3.2
		c-2.5,1.9-5.4,3.3-7.3,5.9C157.6,540.9,155.6,542.2,153.2,540.2z"/>
	<path class="st849" d="M101.2,497.3c-1,0.7-2,1.3-3,2c1.1-6.2,5.5-9.8,10.5-12.6c2.6-1.5,3.9-2.7,2.4-5.6c1.1-0.6,2.1-1.1,3.2-1.7
		c2.8,0.1-0.1,2,1.1,3c3.1-4.2,6.1-2.8,9.3-0.1c1.6,1.3-0.6,1.4-0.5,2.1c-3.8-1.4-7.2,0.8-9.9,2.4
		C109.5,489.4,105.5,493.6,101.2,497.3z"/>
	<path class="st848" d="M188.1,520.2c-1,1.7-3.9,1.4-4.1,3.9c-1.7,0.8-3.7,0.9-5,2.8c-0.6,0.9-1.9,1.1-3,0.2
		c-1.7-1.7-3.4,0.6-5.1,0.1c-1.1-0.3-2.5,0-2.7-1.5c-0.1-1.3,0.9-2.5,2.1-2.3c4.3,0.7,7.8-1.9,11.7-2.5c1.8-0.3,3.2-0.9,2.3-3.3
		c-0.4-1-0.3-2.3-0.2-3.4c0.1-1.9,1.3-2,2.8-1.4c1.4,0.6,1.5,1.2,0.8,2.6C187,516.8,184.2,518.9,188.1,520.2z"/>
	<path class="st846" d="M176.1,527.2c3,0,4.2-4.9,7.9-3c0,0,0.2,0.3,0.2,0.3c-2.6,4.6-7.7,5.7-11.7,8.3c-2.3,1.2-4.9,2-6.7,4.1
		c-0.5,0.5-1,0.9-1.7,1.1c-1.3,0.4-2.6,0.8-4,0.8c-0.5-0.1-0.9-0.3-1.2-0.6c2.7-4,7.2-5.4,11-8C172.5,530,173.9,527.9,176.1,527.2z"
		/>
	<path class="st850" d="M206.1,511.3c-1,0.3-2,0.7-3,1c-2.7-2-5.7-0.6-8.6-1c-1.3-0.2-3-0.1-2.7-2c0.2-1.5,0.9-2.7,3-2.3
		C198.9,507.8,202.7,508.9,206.1,511.3z"/>
	<path class="st850" d="M211.1,509.3c-1.5-0.4-4.5,0.9-4.1-1.8c0.3-2.5,3.1-2.3,5.4-2C211.9,506.8,211.5,508.1,211.1,509.3
		L211.1,509.3z"/>
	<path class="st849" d="M124.2,484.3c1.3,0.7,3,1,3,3c-1.2-0.6-2.2-0.6-3,1.1C124.2,487,124.2,485.6,124.2,484.3z"/>
	<path class="st847" d="M127.2,487.3c1.3-1,2.7,0,4,0C129.9,488.7,128.5,488.7,127.2,487.3z"/>
	<path class="st851" d="M201.1,560.2c0.1,1.2,0.1,3.5,1.7,2.7c4.3-2.1,9.1,0.7,13.3-1.7c1.3-1,2.7-1.3,4-0.1
		c1.2,1.2,0.9,2.7,0.3,4.1c-0.9,2.1-2.5,3.8-4.4,5.2c-3.6,1.8-7.8,1.8-11.5,4.1c-2.8,1.7-5.2,2.8-4.7,6.5c-0.3,0.3-0.7,0.6-1.1,0.7
		c-2.1-0.1-3.6,0.9-4.5,2.8c-0.4,0.2-0.9,0.2-1.3,0.2c-2.4-1.6-2.1-3.5-0.2-5.9c-1.6,0.6-2.9,0.7-4.1,0.1c-1.2-1.1-2.2-2.3-4-2.4
		c-3-0.1-5.1-1.8-6.6-4.3c3.7-4.2,9.2-5.8,13.6-8.9C194.6,562,197.3,559.2,201.1,560.2z"/>
	<path class="st640" d="M215.1,570.2c1.2-1.4,2.4-2.7,3.4-4.1c2.4-3.3,1.8-4.4-2.4-4.9c-0.3-2.5,1.3-3.6,3.4-3.9
		c3.1-0.4,6.2,0,8.4,2.4c1,1.1,1.5,2.5,3.2,2.5c0.1,0.2,0.1,0.5,0.2,0.7c-4.9,3-9.1,7.1-14.8,8.8C215.7,571.5,215.3,571,215.1,570.2
		z"/>
	<path class="st640" d="M247,555.2c-1.9,0-3.7,0.4-5,2c-3.1,2.9-6.1,6-11,5c0,0,0,0,0,0c1.5-2.1,0.6-4-0.4-5.9
		c-1.2-2.4,0.7-4.1,2.3-3.6c4.2,1.4,8.5,0.4,12.6,1.4C246.1,554.2,246.5,554.8,247,555.2z"/>
	<path class="st852" d="M204,539.3c0.7,0,1.4,0,2.1-0.1c0,0.3,0,0.7,0.1,1c1.4,2.8,0,4.6-2.3,6.1c-4,2.6-8,5.2-12.1,7.7
		c-5.6,3-10.8,7.4-17.9,6.1c-0.7-0.9-0.7-1.8-0.4-2.8c0.9-1.7,1.3-3.7,3.4-4.4c0.9,0.4,1.2,1.3,1.7,2c3.1,1.3,4.7-1.6,7.1-2.4
		c1.8-0.7,3.4-2.1,5.2-3c3.9-2.1,7.8-4.1,11.4-6.7C203.4,541.9,203.5,540.5,204,539.3z"/>
	<path class="st853" d="M201.1,560.2c-3,1.3-6,2.7-9,4c-0.4-0.3-0.5-0.8-0.5-1.2c1.9-3.2,5.4-4.1,8.3-6c1.6-1,3.8-1.4,4.7-3.4
		c-1.3-1.8-3.7,0.3-4.9-1.4c-0.5-3.4,2.3-3.2,4.3-3.9c2,0.5,5.5-1.4,5.7,1.9c0.1,2.6,0.3,6.4-4,7.5
		C204.1,558,202.7,559.3,201.1,560.2z"/>
	<path class="st854" d="M204.1,548.2c-1,1.7-4,1.4-4,4.1c0.1,1.3-0.9,1.6-1.8,1.9c-0.9,0.2-1.9,0.2-2.9,0.3
		c-1.6-0.1-3.4,0.8-4.4-1.3c3.6-2.3,7.1-4.8,10.9-6.9c2.6-1.4,4.2-3.1,4.1-6.1c2.1,1.8,4.1,3.4,0.3,5.8
		C205.7,546.4,203.9,546.5,204.1,548.2z"/>
	<path class="st855" d="M204.1,537.2c-0.6-0.9-3.8-1-1-3C203.4,535.2,203.8,536.2,204.1,537.2z"/>
	<path class="st856" d="M182.8,459.2c-7.9,4.1-15.8,8.1-23.6,12.2c-0.7-2.5-4.8,1.3-4.7-2.5c0.1-3.3,2.4-4.7,5.1-5.8
		c6.3-2.5,11.9-6.2,17.8-9.5c0.4-0.2,0.8-0.5,1.2-0.8c3.2,5.1,8-0.4,11.6,1.6C187.8,456,185.3,457.6,182.8,459.2z"/>
	<path class="st857" d="M150.2,476.3c-3.4,2.4-7,4.4-10.8,6.1c-4,1.9-7.9,1.8-11.5-0.9c-1.5-2.1-2.3-4.5-2.8-7.1
		c2.1,3.6,4.5,3.9,8.3,1.8c2.4-1.3,5.9-0.7,9.5-1c-1.3-0.6-2-1-3-1.4C144.1,472.3,146.7,476.1,150.2,476.3z"/>
	<path class="st858" d="M252,587.2c-1.5,1.5-2.6,3.3-4.8,4.1c-7.9,3-15,7.7-22.4,11.7c-1.5,0.8-2.8,1.7-4.6,1.5
		c-3.1-2.5-0.5-3.4,1.3-4.5c3.4-1.9,7.3-2.8,9.7-6.3c2.6-2.7,6.1-3.8,9.4-5.3c4.1-0.3,7.6-1.3,9.1-5.7c0.5-1.5,1.7-2.4,3.5-2.1
		c1.6,0.5,0.8,1.7,0.8,2.7C253.4,584.5,252.7,585.9,252,587.2z"/>
	<path class="st859" d="M231.1,562.2c4.1-0.7,7.1-3.8,11-5c0,0.4,0.1,0.8,0.1,1.2c-0.1,2-1.9,2.6-3,3.7c-8.8,4.1-16.7,10-25.5,14.2
		c-1.5,0.7-2.8,1.8-4.5,1.8c-0.8,0-1.5-0.2-2.1-0.8c1.2-4.8,5-5.6,9-6.2C221.1,568.2,226.1,565.2,231.1,562.2z"/>
	<path class="st860" d="M238.8,561.1c1.1-0.9,2.2-1.8,3.4-2.7c9.8-3.6,14.7-0.2,14.9,10.5c0.1,5,0,10-2.9,14.3
		c-1.2-0.6-0.2-1.3-0.2-2c-1.2-1.7-0.2-3.4,0.2-5.1c0.9-2.5,0.6-5.2,0.5-7.8c-0.6-3.2-1.2-6.3-4.4-8.1c-0.4-0.2-0.8-0.3-1.2-0.5
		c-2-0.6-3.5,0.5-5.2,1.1C242.2,561.5,240.5,562.7,238.8,561.1z"/>
	<path class="st861" d="M127.8,481.4c3.9-0.5,7.5,2.4,11.5,0.9c-1.3,1.6-3.5,1.8-5.2,2.9C131.2,485.3,128.6,485,127.8,481.4z"/>
	<path class="st806" d="M172.2,787.9c0.3,1.3,0.6,2.7,1,4c-2.1-0.6-3.6-1.5-2-4C171.5,787.9,171.8,787.9,172.2,787.9z"/>
	<path class="st840" d="M160.1,804.9c-3.7,4.1-5-0.1-7-2c-2.3-3.3-2-6.4,1-10c1.2-0.1,2.6-2.6,3.1-1.4c0.9,2.3,4.4,4.1,2.4,7.3
		C158.4,801,157.8,803.1,160.1,804.9z"/>
	<path class="st862" d="M154.2,792.9c-1.3,3.2-3.2,6.4-1,10c0,0.8-0.2,1.6-1,2c-0.7-0.7-1.3-1.4-2-2c0-0.7,0-1.3,0-2
		c2-2.7-0.2-6.8,3-9C153.5,792.2,153.8,792.6,154.2,792.9z"/>
	<path class="st863" d="M165.1,709.9c0.2,2.2,0.3,4.4,0.5,7.1c2.6-2.1,2.3-4.7,3-8.2c1.6,2.9,4.5,4.1,1,7.1c-1.5,1.3-3.4,3.7,0.9,5
		c2.9,0.9,5.1,4.1,8.5,0.6c2.6-2.7,5.6,0,8.4,0.4c0.1,1.6-1.5,1.4-2.3,2.1c-1,2.1-3,2.9-5,3.4c-5.6-1.2-6.5-0.5-6.7,4.7
		c-1.2,1.5-2.7,2.5-4.2,3.6c-3,0.7-5.1,3.2-7.9,4.1c-0.6,0-1.5,0.1-1.8-0.4c-1.7-3-3.3-2-4.9,0c-2.1,0.7-3.3,4.8-6.4,1.7
		c0-0.3,0-0.7,0-1c3.5-2.4-0.4-4.6-0.5-6.9c-0.1-2.9-1.7-5.2,3-4.3c-0.2-2.1-1.5-1.7-2.3-1.9c-1.2-0.4-2.1-1.2-2-2.5
		c0.1-1.6,1.3-2.5,2.7-2.3c3.3,0.5,4.2-3.5,7.2-3.4c-0.3-0.5-0.7-1-1-1.5c0.3-0.4,0.7-0.7,1-1.1C159,713.7,161,710.4,165.1,709.9z"
		/>
	<path class="st864" d="M161.1,757c-3.5-2-6.3-5.5-11-5c-2.9-2.2-7-1.2-9.9-4c4.3,0,8.6,0,12.8,0c0.4,0.2,0.8,0.4,1.1,0.7
		c0.8,0.8,1.7,1.3,2.6,1.9c2.1,1.9,5.3,2.9,5.6,6.4C162,757.3,161.6,757.3,161.1,757z"/>
	<path class="st865" d="M153.2,749c0-0.3,0-0.6-0.1-1c-0.3-1-0.6-2-0.9-3c0.2-0.9,0.9-1.5,1.6-2.1c2.2-1.9,4.5-3.4,7.4-3.9
		c2.5-1.6,4.5-4.3,8-4.1c0.4-0.4,0.8-0.3,1.2,0.1c-0.9,3.4-4.3,4.1-6.6,6c-2.3,1.4-5.1,2.2-6.9,4.9c2.8,0.9,4.7-2.8,7.3-1.1
		c-0.3,0.7-0.6,1.4-0.9,2.2c-0.3,0.3-0.7,0.7-1,1C159.5,751.2,156.4,750.4,153.2,749z"/>
	<path class="st765" d="M154.1,743.9c-0.7,0.4-1.3,0.7-2,1.1c-2.4-0.3-3.7-1.6-4-4c2.8,1.7,3.6-2.5,6-2c2.1-0.9,2.7,0.1,2.2,1.9
		C156,742.1,154.8,742.9,154.1,743.9z"/>
	<path class="st866" d="M161.1,757c0.3,0,0.7,0,1,0c3,1.5,3,4.6,3.5,7.3c0.6,3,2.1,3.3,4.4,2.5c0.6,0.5,0.7,1.2,0.7,1.9
		c-0.4,1.8-1.4,3.4-1.5,5.2c-0.2,1.4-0.6,2.7-1.5,3.8c-0.2,0.1-0.4,0.3-0.6,0.4c-0.7-0.3-1.3-0.7-2-1c0,0-0.1-0.1-0.1-0.1
		c-1.1-1.8-0.4-4.4-2.2-6c-2.3-3.7-3.1-7.7-2.8-12C160.9,758.5,161.1,757.8,161.1,757z"/>
	<path class="st867" d="M160.1,759c2.1,3.7,1.5,8.4,4,12c-2.8,1.8-4.4,0-6-2c0-0.7,0-1.3,0-2c-0.3-2.8-3.6-4.8-2-8c0,0,0,0,0,0
		C157.5,759,158.8,759,160.1,759z"/>
	<path class="st868" d="M165.1,709.9c-3.1,1.8-5,5.4-8.9,6.2c-2.3,0.1-3.5-0.6-2.1-3.1c1.3-5.5,4.6-10.6,3.5-16.6
		c-0.3-1.6-0.8-3.2,0.4-4.7c6.6,0.5,10.6,8.2,8.1,15.3C165.9,708,165.5,709,165.1,709.9z"/>
	<path class="st869" d="M158.3,692.1c1.5,7.5,1.2,14.7-4.1,20.9c-0.3-0.3-0.7-0.7-1-1c-1.7-0.8-2-2.1-1.8-3.8c0.4-1.6,1.8-2.1,2.9-3
		c1.6-1.2,1.7-3,2-4.7c0.8-2.5,0-4.8-0.6-7.2c-0.4-1.5-1.6-3-0.3-4.6C157.9,688.5,158.6,689.9,158.3,692.1z"/>
	<path class="st870" d="M152.1,708c-0.1,1.4,0.3,2.7,1,4c-1.2,0.7-2.5,1.2-4,1c-0.7-1.7-1.3-3.3-2-5c-0.4-0.5-0.4-0.9,0.1-1.3
		C149.3,705.7,150.8,706.4,152.1,708z"/>
	<path class="st871" d="M144.2,706c-1-0.3-2-0.7-3-1c-1.5-1.8-4.2-2.4-4.9-5.1c-0.2-1.1-0.1-2,0.6-2.9c1.5-1.1,3-0.4,4.6,0.1
		c1.3,0.6,2.2,1.5,2.8,2.8C144.9,701.9,146,704,144.2,706z"/>
	<path class="st872" d="M137.2,700c0.6,2.3,4.8,1.6,4,5c-1.3,0-2.7,0-4,0c-0.7-2.5-4.3-1.5-5-4c-0.3-1-0.7-2-1-3
		C133.9,696.6,135.5,698.5,137.2,700z"/>
	<path class="st873" d="M144.2,706c0.4-2.1-0.7-4-1-6c0.4-0.4,0.9-0.6,1.4-0.5c2.3,0.7,3.4,2.3,3.7,4.6c0.1,1.1-0.3,2.1-1,2.9
		c0,0.3,0,0.7,0,1c-1-0.3-2-0.7-3-1C144.2,706.7,144.2,706.3,144.2,706z"/>
	<path class="st874" d="M158.2,768.9c2.2,0,3.5,2.6,6,2c2.7,1.6,1.7,3.8,1,6c-1,0.3-2,0.6-3,0.9c0-2.2-0.2-4.3-3-4.9
		C157.8,771.9,155.4,771.1,158.2,768.9L158.2,768.9z"/>
	<path class="st875" d="M158.1,769c-1.3,1.7,0.5,2.7,1,4c-3-0.7-6-1.3-9-2c-1.6-0.2-3-0.7-4-2c0.4-0.8,1.2-1.1,1.9-1.3
		c0.4-0.1,0.8,0,1.1,0.3C152.2,768.3,155.1,768.6,158.1,769z"/>
	<path class="st876" d="M148.2,768c-0.4,0.8-1.2,1-2,1c-6.4,1.2-11.9-2.2-18-3c-2.3-3.7-7.3-1.9-10-5c2.7-0.4,5.3,2.6,8,0
		c0.5-0.5,1.2-0.9,1.8-0.5C134.3,764,142.1,763.7,148.2,768z"/>
	<path class="st877" d="M163.2,740.1c2.3-1.7,4.7-3.4,7-5.1c6.3-5.8,14.3-8.5,21.8-12.1c0.3-0.1,0.7-0.1,1-0.2
		c1.1,0.5,1.6,1.5,2,2.6c0,0.2,0,0.4,0.1,0.7c-9.9,5.7-19.8,11.4-29.8,17.1C162,744,162.7,742,163.2,740.1z"/>
	<path class="st878" d="M157.1,686.9c4-2.9,7.3,1.4,11,1.1c3.9,5.1,5.5,10.5,2.6,16.6c-0.4,0.9-0.6,2.9-2.6,1.5
		c-0.4-0.3-0.5-0.7-0.5-1.2c0.5-1.9,1.5-3.7,1.1-5.8c-0.6-6.6-5.1-9.4-10.8-11C157.6,687.8,157.3,687.4,157.1,686.9z"/>
	<path class="st877" d="M200.1,723.1c-1.4,1.4-2.8,2.9-5,3c0,0-0.1-0.2-0.1-0.2c-0.1-3,3.1-3.7,4-6c0.3-0.3,0.6-0.5,1-0.8
		c2.6-1.1,5.6-1.5,7.6-3.7c1.2-1,2.6-1.6,4.1-2c1.4-0.4,2.7-0.4,3.7,0.9c-3.1,3.3-7.4,4.6-11.1,7
		C202.8,721.6,201.2,721.9,200.1,723.1z"/>
	<path class="st879" d="M207.3,685.8c-0.1,0.4-0.1,0.9-0.2,1.3c-0.3,1-0.7,2-1,3c-1.4,4.9-5.4,4-9.1,4c-0.2-2.6,2-2.7,3.5-3.6
		c0.7-0.4,1.9-0.8,1.6-1.6c-0.7-1.7-2-0.1-3-0.2c-0.5,0-0.8-0.1-1.2-0.5c-1-0.7,0-1.3,0.1-2c1.1-0.3,2.1-0.9,3.2-1
		c2.7-0.2,5.3-0.2,6.8-3.1c0.3,0.3,0.5,0.6,0.6,1.1C208.7,684.2,208.1,685.1,207.3,685.8z"/>
	<path class="st880" d="M192,723c-6.9,4.6-14.4,8.4-21.8,12.1c-0.4,0-0.7-0.1-1.1-0.1c0.9-1.6,2-2.9,4-3c1.7-2.6,4.3-3.9,7-5
		c1.3-1.6,3.3-2,5-3C187.8,725.9,189.5,722.1,192,723z"/>
	<path class="st881" d="M157.1,686.9c0.3,0.1,0.7,0.3,1,0.4c1.8,2,4.2,3.5,6,5.5c3,3.5,4.6,7.4,4,12c0,0.4,0,0.8,0.1,1.2
		c-0.6,0.3-1.3,0.7-1.9,1c-0.3-6.3-0.9-12.3-8-15c-0.8-1.3-1.2-2.9-3-3.1c-0.4,0-0.7-0.2-1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
		C154.7,686.6,156.1,687.1,157.1,686.9z"/>
	<path class="st882" d="M199,719.8c-0.1,2.8-2.4,4.2-4,6c-1.5-0.4-1.9-1.6-2-3C194.6,721,197.2,721.1,199,719.8z"/>
	<path class="st679" d="M227.1,699c-0.3,0-0.7,0-1,0c-1.2-0.2-2-0.6-0.6-1.6c2.3-1.7,4.4,0.1,6.6,0.6
		C230.7,699.7,228.7,698.8,227.1,699z"/>
	<path class="st883" d="M208.1,716c-2.3,2.2-5.1,2.8-8.1,2.9c0.1-0.2,0.2-0.5,0.2-0.7c1.9-0.8,3.9-1.5,5.8-2.3
		C206.7,715.5,207.4,715.4,208.1,716z"/>
	<path class="st884" d="M152.2,686.1c0.6,0.7,1.3,1.3,1.9,2c0,0,0.1,0.1,0.1,0.1c0.9,1.3,0.9,2.8,1.3,4.2c1.3,4.4,0.5,5.3-4.5,5
		c-2.4-3.2-4.1-6.6-3.3-10.8C149,684.9,150.5,684.7,152.2,686.1z"/>
	<path class="st885" d="M201.2,691.2c-1.2,1.3-3.4,0.9-4.2,2.8c-0.3,0.3-0.6,0.6-1,0.9c-1.2,1-2.5,1.7-4,2c-1.1,0-2.4,0.1-2.8-1.1
		c-0.6-1.5,1.2-2,1.8-2.9l0,0c2.7-0.8,4.6-2.8,7-4.2c0-0.2,0-0.5,0-0.7c0.3,0,0.7,0,1-0.1c1.2-0.5,2.5-0.9,3.2,0.5
		C203,689.7,201.9,690.4,201.2,691.2z"/>
	<path class="st780" d="M198,681.9c-0.2,1.7-2.2,1.9-2.9,3.2c-1.6,1.6-3.5,2.6-5.7,3.2c-2,0.5-3.7,0.8-4.6-1.2c-0.9-2,0.2-3.4,2.2-4
		c0,0,0.2,0.2,0.2,0.2c0.8,2.2,2,2,3.8,1C193.1,682.9,195.1,680.7,198,681.9z"/>
	<path class="st886" d="M169.1,674c-0.4,1.6-2.2,1.8-3,3c-3.5,1.7-6.9,3.6-10,6c-0.7,0.5-1.3,0.6-2.1,0.1c-0.5-1.9,0.8-2.8,1.9-3.8
		c2.8-1.7,5.9-2.9,8.6-4.7C165.9,673.8,167.4,672.8,169.1,674z"/>
	<path class="st878" d="M153.9,682.8c0.8,0.1,1.5,0.2,2.3,0.2c-0.7,1.8-2.5,2.2-4,3c-1.4,0.1-2.9-0.5-4,1c-2.1,0.6-3.4-0.4-4.3-2.1
		c0-0.5,0.1-1,0.6-1.3c2.6-1.3,5.4-0.5,8-1C152.9,682.5,153.4,682.6,153.9,682.8z"/>
	<path class="st879" d="M192.3,696.2c1.1-1,2.5-0.9,3.8-1.2c-1.4,3.3-4.4,4-7.6,4.4c-2.3,0.2-2.7-0.4-2.1-3.1
		C188.4,696.8,190.3,697.1,192.3,696.2z"/>
	<path class="st887" d="M192.3,696.2c-2,1-3.9,4-5.9,0c1.5-1.2,2.5-3.1,4.6-3.2C190.3,694.4,188.5,696.4,192.3,696.2z"/>
	<path class="st887" d="M198,688.7c-1.9,2-3.3,5-7,4.2C192.9,690.8,195.1,689.2,198,688.7z"/>
	<path class="st888" d="M169.1,674c-4.3,2.1-8.6,4.2-12.9,6.3c-1.1-1.4-0.4-2.3,0.7-3.2c2.3-0.8,3.7-2.9,5.9-4
		c4.6-1.1,8.7-3.3,12.1-6.3c3.8-3.3,8.9-4.2,12.6-7.5c1.2-1.1,2.4-0.6,3.6,0.3c0.3,0.4,0.3,0.9,0,1.3c-6,3.6-12,7.2-18,10.9
		C172.1,673.2,170.4,673.3,169.1,674L169.1,674z"/>
	<path class="st889" d="M208.2,649.3c-2.3,5.2-7.3,6.9-11.9,9.1c-0.4-0.2-0.7-0.6-0.8-1.1c1.3-3.3,4.4-4.6,7-6.3
		c1.5-1,3-1.9,4.3-3.1c0.5-0.2,0.9-0.2,1.4,0C208.5,648.4,208.5,648.8,208.2,649.3z"/>
	<path class="st890" d="M208.2,649.3c-0.1-0.4-0.1-0.8-0.2-1.2c-1.4-3.6,0.7-7.9-2.3-11.1c0-0.5,0.2-0.9,0.6-1.1
		C212,639.3,212.6,643.8,208.2,649.3z"/>
	<path class="st778" d="M211.1,650.1c0.1,2.8-1.1,4.6-4,5C208.4,653.4,209.8,651.8,211.1,650.1z"/>
	<path class="st889" d="M191.1,661.1c0-0.3,0-0.6,0-1c1.1-2.1,2.5-3.2,4.9-2.1c0,0,0.3,0.3,0.3,0.3
		C194.9,659.8,193.2,660.9,191.1,661.1z"/>
	<path class="st886" d="M169.1,674c1-1.3,2.5-1.7,3.9-2.1C172.5,673.9,170.9,674.2,169.1,674z"/>
	<path class="st890" d="M201.1,636.1c1.4-1.6,3.3-1,5.1-1c0,0.3,0.1,0.5,0.2,0.7c-0.1,0.1-0.3,0.3-0.4,0.4
		C204.3,637.5,202.7,637.5,201.1,636.1z"/>
	<path class="st891" d="M198,681.9c-2.8,1.4-5.5,2.9-8.3,4.3c-1,0.5-2.2,1.4-3.2,0.5c-1.3-1.3,0.3-2.3,0.7-3.5
		c3.3-1.7,6.6-3.4,9.9-5.1c1.3-0.7,2.8-0.9,4-1.9c1.4-0.6,1.9-2.5,3.8-2.3c1.6-0.4,2.2,0.5,2.1,1.9C204,677.9,201,679.9,198,681.9z"
		/>
	<path class="st892" d="M207.3,685.8c0.3-0.9,0.6-1.8,0.8-2.7c2.1-2.2,4.6-3.6,7.3-4.9c0.6-0.4,1.1-0.8,1.7-1.2c1.3-0.6,2.3-2,4-1.8
		c0.9,0,1.9,0,2.8,0c0.3,1.4,0.1,2.8-1.1,3.6C217.9,681.8,213.3,685.4,207.3,685.8z"/>
	<path class="st893" d="M208.1,681.1c-0.3-0.7,1-5.2,1.8-6.5c0.6-0.9,1.2-1.5,2.4-1.4c0.9,0.1,1.6,0.5,1.7,1.4c0.2,1,0,2-1.2,2.4
		C210.6,677.6,209.2,679.2,208.1,681.1L208.1,681.1z"/>
	<path class="st894" d="M215.4,678.2c-2.4,1.6-4.9,3.2-7.3,4.9c0-0.3,0-0.7,0-1c0-0.3,0-0.6,0-1c0,0,0,0,0,0
		C210.8,680.9,212.4,677.7,215.4,678.2z"/>
	<path class="st894" d="M221.1,675.1c-1.2,1-2.4,1.9-4,1.8C218.3,676.1,218.5,673,221.1,675.1z"/>
	<path class="st895" d="M207,675.8c-0.7-0.6-1.4-1.3-2.1-1.9c0.1-0.3,0.2-0.6,0.3-0.8C207,673.2,208.3,673.6,207,675.8z"/>
	<path class="st895" d="M201.1,676.2c-0.8,1.7-2.3,2-4,1.9C198.2,676.9,199.5,676.2,201.1,676.2z"/>
	<path class="st896" d="M228.1,721c-0.7,0.6-1.4,1.3-2.1,1.9c-0.1,0.2-0.2,0.4-0.3,0.6c-1.1,1.1-2.5,1.6-3.8,2.5
		c-2.2,1.5-4.7,2.4-7,4c-9.7,5-18.8,10.9-28.9,15c-2.4-0.2-4.6-0.7-1.5-3.2c2.1-1.7,4.1-3.7,6.6-4.9c10.9-7,22.3-13.1,33.9-18.8
		c0.4-0.2,0.9-0.4,1.4-0.4c1.5-0.1,3.4-2.2,4.4-0.4C231.9,719.1,229.3,719.8,228.1,721z"/>
	<path class="st897" d="M191.1,736.8c-1.6,3-4.1,5.2-8.2,6.9c1.9,0.2,2.7,0.3,3.4,0.4c-0.5,2.7-3.7,2.4-4.8,4.4
		c-1.7,1.3-3.5,2.5-5.4,3.3c-3.1,0.8-5.6,3-8.9,3.2c-1.9-0.1-3.6-0.9-4-2.9c-0.5-2.3,2-2.1,3.1-3l0,0.1c4.8-2.9,9.8-5.4,14.5-8.4
		c1.4-0.9,3.1-1,4.6-1.6C186.9,737.5,188.9,736.9,191.1,736.8z"/>
	<path class="st782" d="M166.3,749.1c-2,2.1-3.2,4,0.9,5c0.3,0.5,0.2,0.9-0.2,1.2c-1.8,0.9-3.5,0.3-4.4-1.2
		c-1.6-2.5-4.1-2.5-6.4-3.2c-1.4,0-2.5-0.7-3-2c3,0.2,6,0,9-1C163.2,749.5,164.9,748.8,166.3,749.1z"/>
	<path class="st865" d="M181.4,741.1c-4.8,3.1-9.5,6.5-15.2,8c2.7-3.9,7.5-4.5,10.9-7.2C178.5,741.4,179.6,739.6,181.4,741.1z"/>
	<path class="st898" d="M228.1,721c0.8-1,1.5-2.1,2.5-3.4c-2.1,0.1-3.9,0.3-5.7,0.4c2-1.3,4.1-2.6,6.1-3.8c0.3,0.1,0.6,0.2,0.9,0.3
		c0.9,1.1,1.1,2.3,1.2,3.7C231.7,719.4,230.1,720.6,228.1,721z"/>
	<path class="st782" d="M181.4,741.1c-1.4,0.3-2.8,0.6-4.3,0.9c2.1-2.8,5.1-3,8.2-2.9C184,739.8,182.7,740.4,181.4,741.1z"/>
	<path class="st899" d="M223,706c1.7-1,3.4-2,5.1-2.9c3.4-1.8,4.6-0.9,3.7,2.9c-3.9,2.1-7.7,4.1-11.6,6.2c-2.5,0.8-3.1,0.1-1.4-2
		C220.1,708.6,222.2,707.9,223,706z"/>
	<path class="st900" d="M163.2,740.1c0.8,0.9-0.1,3.1,2.2,3c-0.4,0.6-0.9,1.2-1.3,1.7c-2.1,1-4.1,2.1-6.3,2.9
		c-1.1,0.4-2.4,0.2-3.1-1c-0.7-1.3,0.6-1.6,1.3-2.3C158.1,742.5,160.5,741.1,163.2,740.1z"/>
	<path class="st901" d="M223,706c1.7,3.2-1.9,3-2.9,4.4c-0.6,0.8-2.5,1.3,0.1,1.7c-1.2,1.1-2.4,2-4.1,2c-1.5-1.4-3.6,0.3-5.1-1.1
		C214.5,709.8,218.9,708,223,706z"/>
	<path class="st883" d="M200.1,723.1c0.8-1.9,2.4-2.2,4.2-2C203.1,722.2,201.8,723.1,200.1,723.1z"/>
	<path class="st883" d="M211,712.9c1.7,0.4,3.7-0.6,5.1,1.1c0,0-0.4,0.1-0.4,0.1s-0.4,0-0.4,0c-1.4,0-2.9-0.1-4.3-0.1
		c-0.3-0.2-0.5-0.4-0.4-0.7C210.8,713.1,210.9,712.9,211,712.9L211,712.9z"/>
	<path class="st901" d="M211,712.9c0,0.4,0.1,0.7,0.1,1.1c-1,0.7-2,1.3-3,2c-0.7,0-1.4,0-2.1,0C207.2,714.2,209,713.4,211,712.9z"/>
	<path class="st902" d="M232.1,726c-2.1,2.7-6.7,2.2-7.7,6.2c-0.4,0.2-0.9,0.3-1.3,0.5c-8.9,4.3-16.6,11.1-26.2,14
		c-1.4,0.7-2.8,1.5-4.3,1.9c-2,0.6-2.9,4.5-6,1.9c-2.1-1.8-1.7-2.9,0.6-3.9c0.4-0.2,0.8-0.4,1.2-0.6c2.2-0.4,2.5-2.6,3.6-4
		c7.6-4,15.2-8.1,22.7-12.4c2.9-1.8,6.1-2.8,8.9-4.7c0.9-0.7,1.9-1.1,2.6-2c0,0-0.1,0-0.1,0c1,0,2.1,0.1,3.1,0.1
		C227.7,726.3,230.3,725.8,232.1,726z"/>
	<path class="st903" d="M233.1,718.1c-1.3-1-2-2.1-1.2-3.7c1.2-0.5,2.3-0.2,2.7,1.1C235,716.7,234.2,717.5,233.1,718.1z"/>
	<path class="st904" d="M139.2,640.1c-1.1-3.2-3.3-4.9-6.8-5.5c-5-0.8-9.6-2.9-14.7-3.2c-2.6-0.2-0.3-1.5-0.5-2.3
		c7.6-3.4,19.7,0,24.1,6.8c0.5,1.6,1.2,3.1,0.4,4.8C140.4,642,139.9,640.9,139.2,640.1z"/>
	<path class="st905" d="M140.2,637.1c-1-4.8-5.2-5.9-8.8-6.8c-4.6-1.1-9.4-0.9-14.2-1.2c-1.6-0.2-3.4,0.7-4.8-0.9
		c1.3-0.2,2.7-0.1,2.7-2c0.2-0.1,0.5-0.2,0.7-0.3c5.6,0.7,11.1,0.7,16.7,0.9c6.6,0.3,10.2,4.1,9.8,10.3
		C141.7,637.7,140.9,637.7,140.2,637.1z"/>
	<path class="st906" d="M139.2,640.1c0.7,0.3,1.3,0.7,2,1c3.1-0.3,3.3,2.6,4.8,4.3c0.4,0.9,0.7,1.7,0.6,2.7
		c-0.4,0.9-1.2,1.4-2.2,1.6c-1.1,0-1.7-0.6-2.1-1.6c0-2.5-0.7-4.6-3-6C139.2,641.4,139.2,640.8,139.2,640.1z"/>
	<path class="st907" d="M142.2,648.1c0.7,0.3,1.3,0.7,2,1c2.1,2.2,3.7,4.7,3.4,8c-0.7,1.2-1.7,1.8-3.2,1.4c-1.6-1.1-1.9-2.7-2.3-4.4
		c-0.3-1.7-0.7-3.3-1-5C141.5,648.8,141.8,648.4,142.2,648.1z"/>
	<path class="st908" d="M107.2,610.2c0.3-0.3,0.6-0.7,0.9-1c0.1,0.9,0.2,1.9,0.2,2.8c-0.4,0.7-0.7,1.4-1.1,2.1
		C107.2,612.8,107.2,611.5,107.2,610.2z"/>
	<path class="st909" d="M115.2,620.1c0,1.1,0,2.1,0,3.2c-3.1,1.4-2.3-2.7-4.1-3c-0.3-0.7-0.6-1.4-0.9-2.2
		C112.8,616.3,113.1,620.5,115.2,620.1z"/>
	<path class="st910" d="M111.1,620.3c2.5-0.6,1.6,3.5,4.1,3c0,0.6,0,1.2,0,1.8c0.3,0.2,0.5,0.4,0.3,0.7c-0.1,0.2-0.2,0.3-0.3,0.3
		c0,0-0.1,0.1-0.1,0.1c-1.5,0.2-1.8-1.3-2.6-2C112,622.9,111.5,621.6,111.1,620.3z"/>
	<path class="st911" d="M207.9,758.7c5.4-3.2,10.8-6.4,16.2-9.7c2.1-2.4,4.8-3.7,7.9-4.1c0.6-0.1,1.3-0.1,1.9-0.2
		c1.2,0.3,2,1.1,2.4,2.3c-5,3.7-10.5,6.4-16.1,9c-2.5-0.4-4.2,1.2-6.2,2.3c-1.6,0.8-3.2,1.5-5.1,1.2
		C208.5,759.5,208.2,759.2,207.9,758.7z"/>
	<path class="st912" d="M224.1,748c-1.9,0.4-3.5,0.8-5.5-0.5c-1.6-1-3.6-2.9-5.8-1c-1.3-3.5,4.6-1.3,3-4.7c-0.2-0.4-0.1-0.7,0.3-0.8
		c2-0.9,4-1.9,6-2.8c1.7,3.6,3.4,6.9,8.2,3.4c1.2-0.9,1.6,0.4,1.8,1.4c0,0.3,0,0.7,0,1c-0.6,1.1-1.6,1.6-2.7,1.9
		c-1.7,0.3-3.4,0.6-4.7,1.9C224.5,747.8,224.3,747.9,224.1,748z"/>
	<path class="st913" d="M196.1,766c3.4-3.3,7.7-5.1,11.8-7.2c0,0,0.2,0.3,0.2,0.3c0.3,0.3,0.5,0.7,0.4,1.2c-0.3,1-0.9,2-1.3,3
		c-1.7,2-4.9,1.3-6.4,3.6C199.1,767.4,197.2,769.2,196.1,766z"/>
	<path class="st914" d="M208.1,760c0-0.3,0-0.6,0-0.9c3.1-0.6,6.2-1.1,8.7-3.3c1.2-1,2.4-1.2,3.4,0.3c-3,1.7-5.9,3.3-8.9,5
		C209.9,761.6,208.9,761,208.1,760z"/>
	<path class="st915" d="M196.1,766c1.3,1.6,3.1,0.7,4.7,0.9c-0.6,1.3-1.9,1.7-3.1,2.2c-1.1,0.5-2.4,1.6-3.6,0.6
		c-1.2-1,0.1-2.4,0.1-3.6C194.9,766,195.5,766,196.1,766z"/>
	<path class="st797" d="M229.1,745c1-0.3,2-0.7,3-1c0,0.3,0,0.7,0,1c-2.3,2-5.1,3.2-7.9,4.1c0-0.4,0-0.7-0.1-1.1c0,0,0,0,0,0
		C225,745.7,227.2,745.6,229.1,745z"/>
	<path class="st911" d="M208.1,760c1.1,0.4,2.2,0.8,3.2,1.2c-1,1.4-2.5,1.9-4.1,2.1C206.1,761.8,207.2,760.9,208.1,760z"/>
	<path class="st916" d="M236.3,747.1c-1.1-0.4-1.9-1.2-2.4-2.3c1.1-1.1,2.3-1.3,3.3,0C237.9,745.7,237.2,746.5,236.3,747.1z"/>
	<path class="st917" d="M232,761.8c-2.8,1.7-5.7,3.3-8.5,5c-2.2,1.3-3.4,0.6-3.8-1.8c2.7-1.8,5-4.1,8.2-5
		C229.9,759.3,231.5,759.2,232,761.8z"/>
	<path class="st918" d="M232,761.8c-1.1-1.2-2.5-1.7-4.1-1.9c2.7-1.6,5.3-3.3,8.1-4.8c1.1-0.6,2.5-1.7,3.4,0.1
		c0.9,1.6-0.5,2.5-1.5,3.3c-1.5,1.2-3.3,2.2-4.9,3.2C232.7,761.7,232.3,761.8,232,761.8z"/>
	<path class="st919" d="M228,733.9c0-0.6,1.2-1.6-0.5-1.5c-1.1,0.1-2.2,0.5-3.3,0.7c-0.7-0.3-0.7-0.6,0-0.9c0,0,0.1,0.1,0.1,0.1
		c3-1.5,5.9-3,8.9-4.4c0.8-0.4,1.9-1.2,2.6,0c0.6,1,0,2-0.8,2.6C232.9,732,230.9,733.8,228,733.9z"/>
	<path class="st795" d="M224.3,732.2c0,0.3,0,0.6-0.1,0.9c-3.7,2.1-7.4,4.1-11.1,6.2c-1.9,2.7-5,3.8-7.6,5.5c-1.3,0.9-2.9,1.4-4.3,2
		c-1.1,0.5-2.2,1-3.4,1c-1-0.2-2-0.3-1.7-1.8c8.1-2.8,15-8,22.7-11.8C220.5,733.3,222.1,731.9,224.3,732.2z"/>
	<path class="st920" d="M204.8,743.9c2.8-1.6,5.6-3.1,8.3-4.7c0.2,1.9,1.6,1.7,2.9,1.8c-0.1,0.3-0.2,0.6-0.3,0.8
		c-3.2,1.5-6.4,2.9-9.7,4.4C206.1,745.2,206.1,744.2,204.8,743.9z"/>
	<path class="st921" d="M188.1,747c-0.7,0.5-1.5,1.1-2.2,1.6c0.7,0.6,1.4,1.4,2.3,1.8c2,0.9,0.9-0.9,1.3-1.5c0.6-0.9,1.3-2,2.7-0.9
		c0.8,0.6,0.9,1.5,0.7,2.4c-0.1,0.4-0.3,0.8-0.6,1.2c-1.1,1.2-2.6,1.7-3.8,2.6c-3.6,2.7-6.9,5.8-10.3,8.7c-0.3,0-0.6,0-0.8,0
		c-0.5-0.3-1.1-0.4-1.7-0.8c-1.1-1.2-2.9-0.3-4-1.4c-1.2-1.7-2.4-3.5-1.9-5.8c1.5-2.4,4.6-2.3,6.4-4.3c1.6-1,2.9-2.5,5-2.8
		C183.3,746.8,185.4,744.9,188.1,747z"/>
	<path class="st922" d="M196.1,755c3.4-2.8,6.6,0.8,10,0.2c-4.6,2.2-7.6,6.4-12,8.8c-0.9-1.6-2.4-2.9-2-5c-0.9-1.4-0.4-2.2,1.1-2.4
		C194.5,756.4,195.5,756.2,196.1,755z"/>
	<path class="st923" d="M176.2,761.9c0.4,0.4,0.7,0.7,1.1,1.1c-0.9,0.4-1,1.2-1.1,2c0.6,2.6-0.7,4.6-2.7,5.8
		c-1.5,0.9-4.1,2.1-3.5-1.9c0-0.6,0-1.3-0.1-1.9c-0.4-1.7,0.8-2.8,1.7-4C172.9,761.6,174.2,760.5,176.2,761.9z"/>
	<path class="st924" d="M178.1,763c2.3-4.4,6.2-7.2,10.2-9.9c0.6,0.4-1,3.1,1.6,1.5c0.7-0.4,1.5-0.3,2.2,0.3c0,0.4,0.1,0.7,0.1,1.1
		c-0.7,1.3-1.5,2-3,1c-1,0.3-1.6,1-2,1.9c-0.8,1-1.8,1.1-3,1C181.5,759.7,181,763.8,178.1,763z"/>
	<path class="st925" d="M176.2,771c2.2,4.7-1.5,6.7-4.3,9c-2.4,2-3.8,0.3-4.8-2c0,0,0.1-0.2,0.1-0.2c0.7-0.6,1.5-0.9,2.4-1.1
		c1.6-0.2,2.7-1.1,3.5-2.5C174,773.1,174.4,771.4,176.2,771z"/>
	<path class="st926" d="M170,768.9c0.4,2.5,1.8,1.7,3.2,0.9c1.9-1,2.5-2.9,3-4.8c1.9,1.8-0.2,3.4-0.2,5.1c-1.9,2.7-3.8,5.3-7.8,4
		C168.1,772.1,168.5,770.3,170,768.9z"/>
	<path class="st927" d="M168.3,774.1c2.9-0.7,5.4-2.1,7.8-4c0,0.3,0.1,0.6,0.2,0.9c-0.7,1.3-1.4,2.6-2.1,3.9c-1.4,0.9-3,1.6-3.9,3
		c-1,0-1.9-0.1-2.9-0.1C167.6,776.6,167.9,775.4,168.3,774.1z"/>
	<path class="st701" d="M189.1,757c1-0.3,2-0.7,3-1c1.5,0.2,2.8-0.3,4-1c0.2,2.9-4.2,1.1-4,4C190.7,759,189.7,758.3,189.1,757z"/>
	<path class="st701" d="M184.1,760c0.7-1.2,1.9-1,3-1c0.3,0.9,0.8,1.7-0.5,2.2C185.2,761.8,184.6,761,184.1,760z"/>
	<path class="st928" d="M198.1,747c1-0.4,2-0.7,3-1.1c0.9,1,2.5-0.1,3.3,1.2c-2.3,2.5-5.7,3.2-8.5,4.8c-1.4,0.5-3,1.9-3.7-0.8
		l-0.2-0.2C193.2,748.3,195.7,747.7,198.1,747z"/>
	<path class="st929" d="M192.2,751.1c1,1.4,2.4,0.7,3.7,0.8c-0.7,1.7-2.9,1.6-3.9,2.9c-1.2-0.7-5.1,4.2-3.8-1.8
		C189.6,752.4,190.9,751.7,192.2,751.1z"/>
	<path class="st930" d="M204.4,747.1c-0.7-1.6-3.6,3-3.3-1.2c1.2-0.7,2.5-1.4,3.7-2.1c1.8,0,1.9,1,1.4,2.3
		C205.5,746.5,205,746.8,204.4,747.1z"/>
	<path class="st931" d="M210,774.7c-0.6,0.1-1.3,0.1-1.9,0.2c-1.3-0.6-2-1.5-2-3c1.3-1,2.7-2,4-3c0.4,0.1,0.7,0.1,1.1,0.2
		c2.4,1.4,0.7,2.7,0,4.1C210.8,773.7,210.4,774.2,210,774.7z"/>
	<path class="st932" d="M211.2,773.2c-0.2-1.4,0.9-2.7,0-4.1c1.2-0.7,2.9-2.5,3.6-0.4C215.7,771.4,213.1,772.3,211.2,773.2z"/>
	<path class="st933" d="M206.1,772c0.7,1,1.3,2,2,3c-3.1,2.2-6,4.6-9.9,5.3c-0.9-0.5-1.6-1.2-1.2-2.3
		C199.4,775,202.8,773.5,206.1,772z"/>
	<path class="st934" d="M197,777.9c0.4,0.8,0.8,1.6,1.2,2.3c-0.7,0.2-1.4,0.4-2,0.6C196.1,779.8,195.8,778.6,197,777.9z"/>
	<path class="st726" d="M105.2,558.2c1.5,1.1,0.9,2.6,1,4C102.7,561.6,104.7,559.7,105.2,558.2z"/>
	<path class="st935" d="M141.2,698c-1.3-0.3-2.7-0.7-4-1c-6.7-0.2-11.4-4.1-12-10c-0.3-1-0.7-2.1-1-3.1c1-0.7,2.5-1.1,1.8-2.8
		c0.2-0.1,0.4-0.2,0.6-0.3c1.6,0.5,2,1.8,2.4,3.1c0.1,0.4,0.2,0.9,0.2,1.3c2.1,3.9,6.8,4.7,9.4,8C139.9,694.7,142,695.6,141.2,698z"
		/>
	<path class="st936" d="M125.2,687c2.6,5,6.7,8.3,12,10c0,1,0,2,0,3c-2-0.7-4-1.3-6-2c-2.1-1.2-4.3-2.3-6.4-3.7
		C121.1,691.8,121.2,689.9,125.2,687z"/>
	<path class="st937" d="M124.2,670c0.4,1,0.8,2,1.1,3c2,2.5,2.8,5.2,1.1,8.2c0,0-0.5-0.1-0.5-0.1c-0.6,0.3-1.2,0.6-1.8,0.9
		C124.2,678,122.6,674,124.2,670z"/>
	<path class="st938" d="M126.5,681.2c-0.4-2.7-0.7-5.5-1.1-8.2c2.3-7.3,2.3-7.3,10.8-7.9c1,0.8,1.2,1.9,1.3,3.1
		c0.3,4.3-0.4,8.4-1.6,12.5c-2,2.5-4.3,4.3-7.7,3.5C127.6,683.1,127.1,682.2,126.5,681.2z"/>
	<path class="st939" d="M136.2,668.1c0-1,0-2,0-3c0-0.3,0-0.6,0.1-1c2.4-1.4,4.9-2.9,7.9-2.7c2.5,1,1.7,2.9,1.2,4.7
		c-0.2,0.5-0.4,0.9-0.7,1.4C142,669.9,139.1,669.5,136.2,668.1z"/>
	<path class="st940" d="M144.1,662.1c-2.6,0.7-5.3,1.4-7.9,2c0-0.3,0-0.7,0-1c1.3-0.7,2.6-1.4,3.9-2.2c1.1-1.3,2.1-2.7,4.1-2.8
		c1-0.4,1.9-0.8,2.9-1.1c0.2-0.1,0.4-0.2,0.6-0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.5,2.2-1.5,2.9-2.5,4.2
		C145.3,661.6,144.6,661.7,144.1,662.1z"/>
	<path class="st941" d="M144.2,658.2c-0.9,1.7-2.5,2.2-4.1,2.8c0.3-2,0.7-3.9,1-5.9c0.3-0.3,0.7-0.7,1-1
		C144,654.9,144.2,656.5,144.2,658.2z"/>
	<path class="st855" d="M177.2,553.2c-1.5,1-0.9,3.5-3,4c-1.6,0.3-3.4,0-4.7,1.4c-8.5,5.7-17.7,9.8-27,13.8c-2.9,0.1-5,3.5-8.3,1.8
		c-0.2-0.2-0.4-0.6-0.7-0.6c-4.7-0.4-6.8-2.5-5.3-7.4c4.5-4.7,11.3-5.6,16-10c2.5-1.3,4.8-3.1,7.8-3.3c0.4,0.3,0.6,0.7,0.7,1.2
		c0,1.7-1.1,2.7-2.2,3.8c-3,2.1-6.8,1.9-9.8,4.1c-3.1,2.4-7,3.6-10.2,7.7c2.4-0.8,3.8-1.5,5-2.3c2.7-2.3,6-3.6,9.1-5.3
		c2.2-1,4-2.6,6.4-3.2c2.9-0.6,5.5-2.1,8.2-3.4c1.8-0.8,3.5-2.9,5.8-1.4c4.4-0.4,7.1-4.1,11-5.7c2.3-1,4.6-2.2,7.7-3.2
		c-1.5,3.3-4.7,4.2-5.7,7c0,0.2-0.1,0.3-0.1,0.5C177.8,553,177.5,553.1,177.2,553.2z"/>
	<path class="st942" d="M159.2,538.2c0.4,0,0.9,0.1,1.3,0.1c0.7,0.6,1.3,1.4,2.2,1.8c0.6,0.2,1,0.5,1.5,1c0,0.4,0,0.8,0.1,1.1
		c-0.4,0.8-1.2,1.3-2,1.6c-1.5,0.6-3.4,0.3-4.4,2c-1.4,1.7-2.7,3.5-4.4,4.9c-1.6,1.1-3.5,0.9-5.2,1.5c-1.5,0.6-3.1,1.1-4.6,1.9
		c-1.6,0.8-3,1.3-4.4-0.4c-1.5-1.8-0.3-3.2,0.6-4.4c1.7-2.3,3.8-4.2,6.6-5.1c2.5-0.9,4.7-2.3,6.8-3.9
		C155.4,540.3,157.6,540.2,159.2,538.2z"/>
	<path class="st943" d="M148.1,552.2c1.3-1.6,3.1-2,5-2c1.1-0.8,1.8-0.5,2.3,0.7c-0.5,1.6-1.3,2.8-3.3,2.2c-2.8,0.7-5,2.8-8,3
		C144.6,553.9,147.9,554.6,148.1,552.2z"/>
	<path class="st741" d="M156.2,525.2c-0.1,2.4,2.6,6.3-3,6C150.8,527.6,152.5,525.9,156.2,525.2z"/>
	<path class="st944" d="M124.2,621.1c5.2-4.7,3.9-11,4-16.9c0-2,0.7-3.1,2.9-3.1c1.5,0,2.5-0.6,3.2-2c3.1-4.6,3.3-4.7,16.5-7.8
		c-1.9,0.7-3.3,0.6-2.1-1.8c0.6-1.1,1.8-1.9,2.5-3c4.8-3.9,10.3-6.6,16-9c0.8-0.1,1.6,0.1,2.5,0.2c2.4,0.5,5.5,0.8,5.1,4.2
		c-0.4,3.3-3.6,2.1-5.8,2.7c4.3-1,6.7,1,7.9,4.8c0.6,2.7-0.8,3.9-3.2,4.8c-4.4,1.6-4.9,2.6-3.1,6.8c1.1,2.5,2.1,4.9,1.9,7.7
		c-0.1,1.5-0.2,3.3-1.7,3.9c-4.7,1.9-3.4,5.5-2.6,8.8c0.6,2.3,1.3,4.5,0.8,6.8c-0.6,1.8-2.3,1.6-3.7,1.8c-1.8,0.3-3.2,0.9-3.5,2.9
		c-0.3,1.5,0.4,3,0.2,4.5c-0.1,0.4-0.2,0.9-0.4,1.3c-1.1,3.1,0,7.8-5.5,7.7c-2.1-3.1-5.4-5.1-8.2-7.5c-1.9-1.7-3.4-3.5-4.4-5.8
		c-0.2-0.5-0.3-0.9-0.5-1.4c-2-6.9-8.4-6.7-13.7-8.3C127.5,622.9,124.8,624.2,124.2,621.1z"/>
	<path class="st945" d="M152.2,587.2c-0.7,1.6-2.1,2.5-3.5,3.6c1.1,0.3,1.8,0.6,3.6,1.1c-6.8,2-13.2,3-18,7.3
		c-2.6-1.2-1.7-3.6-2-5.6c-0.2-1.6,2.5-4.1-1.8-4.9c-1.1-0.2,0.9-4.8,0.9-7.4c3.3,1.3,6.6-0.8,9.9-0.2c0.3,0.3,0.5,0.7,0.6,1.2
		c-0.1,1.7-1.3,2.5-2.7,3.2c-1.6,0.8-2.6,1.9-2.6,3.8c0,0.9,0.1,1.7,0.3,2.5c2.1,2.3,3.7,0.5,5.5-0.5
		C145.4,589.3,148,586.2,152.2,587.2z"/>
	<path class="st946" d="M124.2,621.1c1.4,1.6,3.4,1.1,5.1,0.9c4.7-0.5,7.9,3.2,12.2,4.2c2,0.5,2.8,3.4,2.7,5.9
		c-7-5.5-15.6-4.1-23.5-5.5c-0.9-0.2-1.5-0.4-1.4-1.5c0.3-1,0.7-2,1-3C121.4,621.4,122.7,621,124.2,621.1z"/>
	<path class="st947" d="M141.2,581.2c-3.3,0.6-6.7,3.9-10,0c-0.2-2.8-0.2-5.6,3-7c3-0.2,5.1-3.1,8.3-2.8c0,2.1-1.7,2.7-3.1,3.6
		c-1.7,1-3.9,1.5-4.6,3.9c1.5,1,2.2-0.3,3.2-0.7c2-1.1,3.8-2.6,6.2-1.2C145.3,579.9,143.3,580.6,141.2,581.2z"/>
	<path class="st948" d="M119.2,625.1c3.4,1.3,6.9,2.3,10.5,1.9c5.7-0.5,10.4,1.3,14.5,5.1c0,0.3,0,0.7,0,1c0.2,3.3,3.8,5.3,3.3,8.8
		c-0.3,0.4-0.8,0.7-1.3,0.7c-3-0.7-4.3-2.5-4-5.5c-2.1-7.4-4.1-9-11.7-9c-5.2,0-10.4,0.1-15.3-2c0-0.3,0-0.7,0-1
		C116.5,625.2,117.9,626.1,119.2,625.1z"/>
	<path class="st949" d="M148.2,768c-7.4-2-14.9-4-22-7c-1.1-2.2-2.4-4.4-2-7c1.3,0,2.4,0.5,3,1.6c3.8,6.8,10.1,7.9,17,7.4
		c0,3.3,3.7,3,5,5C148.8,767.9,148.5,768,148.2,768z"/>
	<path class="st950" d="M156.2,759c0.8,2.6,2.8,4.9,2,8c-4.2-4.4-9.1-7.4-15.4-8.2c-2.6-0.3-5.8-1.1-7.6-3.8
		C142.2,756.4,149.5,755.9,156.2,759z"/>
	<path class="st740" d="M168.1,629.1c-0.2-3.9-1.8-7.4-2.1-11.3c-0.3-3.3-0.7-5,3.6-6.2c3.1-0.9,1.7-5.4,0.5-8.3
		c-0.7-1.7-2.1-2.9-2.1-5c0-3.1-0.1-5.8,4.3-5.2c2.8,0.3,3.9-1.3,3.8-4c0.4-1.7,1.7-2.7,2.8-3.9c1.2-0.7,2.3-2.3,3.9-0.6
		c1,2.7,2.1,5.4,0.9,8.4c-5.3,5.9-5.4,12.9-3.9,20.1c0.2,0.8,0.5,1.6,0.7,2.4c2.4,9,2.5,9.1,11.9,7.4c1.4,0,2.7,0.1,3.7,1.2
		c-0.5,2.1-3,1.6-4,3c0.8,2.3,2.7,0.4,4,1c1.5-0.6,3.2-1.8,4.5-0.2c1.1,1.3,0.1,2.8-0.5,4.2c-2,1-4,2-6,3c-2.5,0.8-5,1-7.6,0.9
		c-1.4-0.1-3.2-0.8-4,0.8c-0.8,1.5,1.1,2.1,1.7,3.2c0.2,0.4,0.4,0.9,0.4,1.4c-0.1,0.5-0.3,0.9-0.6,1.2c-2.2,1-4.6,1.8-6.7,3.1
		c-1.1,0.7-2.5,1.5-3.7,0.5c-1.4-1.1-0.4-2.5,0.1-3.6c1.1-2.5,1.5-5.1,0.8-8.2c-1.5,1.1-1.3,2.7-1.7,3.9c-0.7,2.2-1.1,5.5-3.8,5
		c-3-0.5-7.2-0.6-7.8-5.2h0c0.9-1,2.1-0.9,3.2-0.8c3,0.2,3.7-1.5,3.5-4.1C167.9,631.9,167.3,630.4,168.1,629.1z"/>
	<path class="st844" d="M196.1,628.1c-2.3,1.8-4.8,0.9-7.6,0.7c0.7-2.4,2.4-1.3,3.6-1.7C193.5,627.3,195.1,626.7,196.1,628.1z"/>
	<path class="st844" d="M196.1,624.1c-1.3,0-2.7,0-4,0c-0.3-0.5-0.3-1,0-1.4c1.5-1.3,3.1-2,4.9-0.6c0,0,0,0,0,0
		C197.1,623,196.9,623.7,196.1,624.1z"/>
	<path class="st951" d="M163.1,674.1c-1.9,1-2.6,3.5-5,4c-1.4-1-2.4,0-3.5,0.7c-5,3.3-6.2,2.8-6.9-3.4c-0.1-0.7-0.2-1.3-0.2-2
		c0-2.6,0.6-5,1.6-7.4c0.3-0.7,0.8-1.2,1.4-1.6c1.9-1.4,4.8-1.1,6-3.7c1.5-2.7,4.8-3,6.9-5c0.4-0.3,0.8-0.5,1.2-0.7
		c6.7-3.1,13-6.8,19.3-10.7c3.5-1.7,6.9-3.7,10.5-5.1c1.2-0.4,2.5-0.9,3.7-1.3c1.2,1.5-0.1,2.2-0.9,3.2c-3.7,4.8-8.8,8.3-12.8,12.8
		c-1.2,1.4-2.1,2.5-0.9,4.3c-1.2,4.5-4.4,4.1-7.8,3.3c0.3,0.4-0.4,0.1-0.2,0.2c3.8,3.4,0.6,4.5-1.9,5.4
		C169.6,668.6,165.6,670.1,163.1,674.1z"/>
	<path class="st952" d="M183.1,658.1c-1.2-0.8-3.7-1.1-1.2-3.3c0.6-0.5,1.2-1.1,1.8-1.8c4.7-5.2,10.3-9.4,14.4-15.1
		c1-0.6,2-1.2,3-1.9c1.6,0.1,3.3,0.1,4.9,0.2c4.4,3.3,2.9,7.6,2.1,11.8c-0.3,0-0.6,0-1,0c-2.1,3.2-5.6,3.5-8.9,4.1
		c-5.2,0.1-9.7,1.4-12.4,6.4C184.8,658.9,183.9,658.9,183.1,658.1z"/>
	<path class="st842" d="M184.1,642.1c-0.1-1.1-0.6-1.8-1.6-2.3c-1.6-0.8-3.1-1.8-2-3.8c1-1.7,2.9-2.4,4.8-1.7
		c2.9,1.1,5.9,0.6,8.8,0.7c0.2,0,0.4,0,0.5,0.1c0.2,0.2,0.4,0.4,0.7,0.6c-0.9,0.3-1.8,0.9-2.6,0.8c-4.3-0.5-5.3,2.1-5.5,5.5
		C186.2,643.7,185.2,643.9,184.1,642.1z"/>
	<path class="st953" d="M184.1,642.1c1,0.6,2,0.6,3-0.1c2.3-1.3,4.2-3.5,7.3-2.8c-2.9,2.7-6.6,4.2-10.2,5.9
		c-0.9,0.6-1.9,0.6-2.8,0.3C180.4,642.8,183.2,643.3,184.1,642.1L184.1,642.1z"/>
	<path class="st954" d="M220.1,604.1c7.2-3.8,14.5-7.5,21.6-11.5c1.8-1,3.5-1.7,5.5-1.4c-6.2,4.9-14,7.1-20.2,11.9
		c-4.3,1.9-8.6,3.8-12.2,6.9c-2.7,2.1-5.7,3.7-8.9,5l0,0c-1,0.7-1.8,1.6-3.1,1.8c-0.8,0-1.4-0.1-2-0.7c0.1-2.9,2.7-3.3,4.5-4.6
		c2.8-2,6-3.2,8.9-5.1c1.6-1,3.1-2.1,5.1-2.4C219.6,604.2,219.9,604.1,220.1,604.1z"/>
	<path class="st955" d="M203.1,616.1c1-0.3,2-0.7,3-1c-0.3,1-0.7,2-1,3c-2.1,1.6-4.7,2-7,3c-0.3,0.3-0.7,0.7-1,1
		c-1.7,0.3-3.3,0.7-5,1c-1.1,0.7-2.4,0.8-3.7,1.1c-4.2,0.9-5.7-0.5-5.9-5.6c0.2-0.5,0.6-0.8,1.1-0.9c4.1,3.7,8.5,3.7,13,0.7
		C198.6,617.1,201,617.1,203.1,616.1z"/>
	<path class="st955" d="M206.1,615.1c2.6-2.4,5.6-3.9,8.9-5c0.4,0,0.8,0.1,1.2,0.1C213.2,612.6,210.1,614.9,206.1,615.1z"/>
	<path class="st956" d="M198.1,621.1c2-1.8,4.2-3,7-3c-0.7,1-1.3,2-2,3C201.4,621.1,199.8,621.1,198.1,621.1z"/>
	<path class="st957" d="M181.2,543.7c-1.3,0.3-1.8-0.2-2-1.1c-0.3-1.5,0.3-2.5,1.6-3.3c5.3-3.1,10.6-6.3,15.9-9.4
		c2.6-1.5,5.2-3,7.9-4.2c1.3-0.6,3.1-1.4,4,0.4c0.7,1.5-0.5,2.9-1.8,3.7c-3.7,2.2-7.5,4.3-11.3,6.4c-4.2,2.3-8.4,4.6-12.7,6.9
		C182.2,543.3,181.6,543.5,181.2,543.7z"/>
	<path class="st958" d="M172.1,532.1c4.1-2.5,8.1-5.1,12.2-7.6c1.9-0.8,3.8-1.5,5.7-2.3c3.5-0.9,2.4,1.3,2.1,3
		c-5.6,3.1-11.1,6.1-16.7,9.2c-1.8,0.8-3.7,1.5-5.3,2.7c-1.3,0.7-2.5,1.5-3.8,2.2c-0.8,0.4-1.5,0.4-2.3,0.1
		c-0.5-0.8-0.5-1.5,0.1-2.2c0.4-0.3,0.7-0.6,1.1-1C167.3,534.5,170.1,534.1,172.1,532.1z"/>
	<path class="st959" d="M161.1,549.2c4.2-2.1,8.5-4.3,12.7-6.4c0.9-0.4,1.8-1.1,2.7-0.2c1,1.1,0.3,2.2-0.3,3.1
		c-1.9,2.9-5.5,3.5-8.1,5.4c-3.2,1.8-6,4.1-9.8,4.7c-1.5-0.4-2.8-1.1-3.5-2.5C155.5,549.8,159,550.6,161.1,549.2z"/>
	<path class="st960" d="M155.2,551.2c-0.4-0.8-1.2-0.9-2-0.9c0.8-2.1,2.5-3.5,4-5c1-2.3,3.5-0.8,5-2c0.4-0.8,1.2-1,2-1
		c3.1,0,5.9,0.3,7.9-3.5c0.9-1.8,4.5-2.3,7-3.4c1.3,2.6-0.1,3.5-2.1,4.5c-6.3,3.1-12.6,6.3-18.8,9.4
		C158.1,551.4,156.6,551.2,155.2,551.2z"/>
	<path class="st961" d="M192.1,525.2c-0.9-0.9,0.2-3.2-2.1-3c5.1-3.4,10.2-7,16.1-9c0.9,0.6,2.4,0.4,2.6,1.9
		C204.2,520.1,197.6,521.7,192.1,525.2z"/>
	<path class="st962" d="M203.3,543.5c-3.7,2.8-7.7,5.1-12,6.8c-2.1,0.6-3.8,0.7-4.1-2.1c2.4-1.6,4.8-3.2,7.1-4.9c0,0-0.1,0-0.1,0
		c1.7-0.7,3.4-1.4,5-2.1C201.2,540.8,203.8,539.4,203.3,543.5z"/>
	<path class="st963" d="M187.1,548.1c1,1.4,2.5,1.9,4.1,2.1c-3,2.8-6.9,4.1-10.3,6.4c-1.2,0.8-2.7,1.5-3.7-0.4
		c1.1-0.9,1.8-1.8,1.3-3.3c-0.2-0.2-0.3-0.4-0.4-0.7C181.6,551.9,183.7,548.6,187.1,548.1z"/>
	<path class="st855" d="M194.2,543.3c-2.5-0.6-3.9,1.5-5.9,2.3c-0.6,0.2-1.3,0.6-1.8-0.1c-0.6-0.8-0.3-1.7,0.5-2.1
		c4.5-2,7.7-6.1,13-7.7C199.4,539.7,194.7,539.9,194.2,543.3C194.2,543.3,194.2,543.3,194.2,543.3z"/>
	<path class="st964" d="M158.3,555.4c3.3-1.4,6.6-2.9,9.8-4.3c-0.3,1.7-2.2,1.8-3,3.1c-4.9,1.4-8.7,5.6-14.1,5.9
		c-0.2-1.1,0.3-1.8,1-2.4C154,556.6,155.8,555.1,158.3,555.4z"/>
	<path class="st942" d="M155.2,551.2c1-0.6,2-1.3,3-1.9c1,0,1.9,0,2.9-0.1c-2,1.3-4,2.7-6,4c-0.5,1.4-1.4,1.9-2.8,1.3
		c0-0.4-0.1-0.8-0.1-1.2C153.1,552.5,154.1,551.8,155.2,551.2z"/>
	<path class="st965" d="M203.3,543.5c-0.3-2.6-2.3-2.2-4.1-2.3c1.4-1.3,3.1-1.7,4.8-1.9C205.4,541,205,542.3,203.3,543.5z"/>
	<path class="st966" d="M170.1,537.1c0.8-2.7,3.3-2.4,5.3-2.7C173.9,535.8,172.3,536.9,170.1,537.1z"/>
	<path class="st966" d="M164.1,539.2c0.7,0,1.5,0.1,2.2,0.1c-0.7,0.6-1.5,1.2-2.2,1.8c-0.6,0-1.3,0.1-1.9,0.1
		C161.8,539.6,162.5,538.9,164.1,539.2z"/>
	<path class="st967" d="M208.7,515.1c-0.9-0.6-1.8-1.3-2.6-1.9C208.1,512.4,209.4,512.4,208.7,515.1z"/>
	<path class="st968" d="M164.1,539.2c-0.9,0.4-1.6,1-2,2c-2.6,0.3-2-1.4-1.7-2.9c1.1-0.6,2.2-1.5,3.7-1.2
		C164.1,537.9,164.1,538.5,164.1,539.2z"/>
	<path class="st966" d="M172.1,532.1c-1.5,2.8-4.2,3.4-6.9,4.1C166.8,533.7,169.6,533.2,172.1,532.1z"/>
	<path class="st969" d="M152.2,587.2c-3.7,1.4-7.2,3.3-10.3,5.7c-2.3,1.8-4.8,2.6-6.5-1c2.5-2.1,6-2.4,8.4-4.5
		c6.9-2.8,12.9-7.4,19.5-10.9c1.4-0.6,1.7-1.8,1.9-3.1c0.1-0.4,0.3-0.9,0.4-1.3c0.6-1,1.4-1.8,2.5-2.2c0.5-0.1,0.9,0.1,1.3,0.3
		c0.8,2.6,2.4,2.4,4.3,1.1c1.1-0.8,2.1-1.6,3.4-2.1c2.7-0.7,4.8-2.5,7-4c2.7-0.6,4.8-3.5,8-2.2c0,0.4,0,0.7,0,1.1
		c-4.7,2.7-9.3,5.3-14,8c0,1.4-0.7,2.3-1.9,2.8c-3,1.2-6.4,1.2-9.1,3.1C162.4,581.6,156.6,583.2,152.2,587.2z"/>
	<path class="st970" d="M216.1,571.2c-2.9,2.2-6.7,2.9-9,6c-0.9,2.3-3.1,2.9-4.9,4.2c-0.4,0.2-0.8,0.3-1.3,0.4
		c-0.7,0.1-1.3-0.1-1.8-0.6c1.5-2.8-1.5-3.4-3.2-5.4c7.4,0.1,12.1-6.5,19.2-5.5C215.4,570.5,215.8,570.9,216.1,571.2z"/>
	<path class="st970" d="M188.1,578.2c1.6,0,3.2,0,4.9,0c-0.1,2.3-2.8,4.8,1.1,6c0.3,0.4,0.4,0.8,0.2,1.3c-0.8,1.3-2,1.9-3.3,2.3
		c-0.7,0.1-1.4,0.1-2-0.2c-0.9-2.8-6.1-3.6-3.7-7.9C186,578.7,186.6,577.6,188.1,578.2z"/>
	<path class="st740" d="M188.1,578.2c-1,0.4-1.6,1-2,2c-4.9,0.7-8.2-1.3-10-6c0.7-0.7,1.3-1.3,2-2c2.9,1.2,5,3.9,8.7,3.2
		C188.5,575.1,188.9,576.7,188.1,578.2z"/>
	<path class="st971" d="M194.1,585.1c0-0.3,0-0.6,0-0.9c0,0,0,0,0,0c0.7-1.8,2.1-2.8,4-3c0.3,0,0.7,0,1,0c0.7,0.1,1.4,0.1,2.1,0.2
		c0.3,1-0.3,1.8-1,2.4c-1.3,0.9-2.8,1.6-4.3,2C195.2,585.8,194.5,585.7,194.1,585.1z"/>
	<path class="st970" d="M198.1,581.2c-1.3,1-2.7,2-4,3C193.4,580.3,195.4,580.3,198.1,581.2z"/>
	<path class="st972" d="M169.3,570.3c-0.4,0-0.8-0.1-1.2-0.1c-0.5-1.4,0.9-1.9,1.6-2.8c-2.8-0.2-5.1,2.2-7.8,0.8
		c-0.2-0.4-0.3-0.9-0.3-1.4c0.7-1.8,2.3-2.3,3.9-2.8c2.4-0.5,4.7-1.4,6.4-3.3c0.3-0.3,0.6-0.6,1-0.9c0.4-0.2,0.8-0.1,1.2,0.2
		c4.1-1.5,8.6-1.8,12.3-4.5c1.4-1,3.2-1.6,4.7-2.5c1.3,1,2.7,0,4,0c0.5,2.5-1.7,2.9-3.1,3.8c-2.4,1.4-4.9,2.7-6.9,4.8
		c-1.6,1.6-3.7,2.5-5.5,3.8c-1.3,0.8-2.7,1.3-3.9,2.2c-1,0.8-2,1.7-3.2,2.3C171.4,570.6,170.4,571.2,169.3,570.3z"/>
	<path class="st973" d="M178.6,552.9c0.7,0.6,2.4,0.1,2.2,1.5c-0.3,1.9-2,1.8-3.5,1.8c0-1,0-2-0.1-3
		C177.5,552.7,178,552.6,178.6,552.9z"/>
	<path class="st974" d="M174.1,560.2c-0.4,0-0.7-0.1-1.1-0.1c-0.8-1.5-3.1-1-3.8-2.7c1.6-1.8,3.2-1.6,4.9-0.1
		C174.1,558.2,174.1,559.2,174.1,560.2z"/>
	<path class="st975" d="M178.9,565c1.8-1.2,3.5-2.4,5.3-3.7c4.4-3.2,9.1-5.9,14.2-8.1c0.6-0.3,1.2-0.7,1.8-1c2.3-0.1,5.4-3,6.5-0.2
		c1.2,3-2.7,3.9-4.8,5.2c-3.2,1.9-6.5,3.8-9.8,5.7c-2.9,0.4-4.9,3.1-8,3.1c-1-0.6-2-0.3-3-0.2c-0.4,0-0.8,0-1.3-0.1
		C179.4,565.7,179.1,565.4,178.9,565z"/>
	<path class="st852" d="M198.4,553.3c-4.5,3.1-9.2,5.7-14.2,8.1c1.5-2.7,4.3-3.6,6.8-5.1c1.5-0.9,3.4-1.2,4.2-3.1
		C196.2,553.3,197.3,553.3,198.4,553.3z"/>
	<path class="st976" d="M255.1,576.2c-0.4,1.7-0.7,3.3-1.1,5c-1.2,0-3,0-2.9,1.2c0.5,4.3-3.8,4.5-5.5,6.9c-1.1,1.6-3-0.6-4.6-0.1
		c-1.2-1.3,2.8-2.4,0.1-3.7c-0.8-1.5-3.7-1.2-3.4-3.8c1.4-2.7,4.7-2.8,6.1-4.7c-5.8,3.1-11.3,8-18.4,9.6c-1.5-0.6-4.1,0-4.4-2.2
		c-0.3-1.9,1.6-3,3.2-3.9c1-0.6,2-1.1,2.4-2.3c2.4-3,5.9-4.4,9.2-6.1c3.9-0.9,6.5-4.8,10.7-5c3.3-0.6,0.8-2,0.4-3
		c-0.7-1.4-2.9-2.3-1.8-4.2c1.1-1.8,3.1-1.5,4.9-0.8l0,0c3.1,1.9,4.5,4.9,4.9,8.4C255.5,570.4,256,573.3,255.1,576.2z"/>
	<path class="st977" d="M215.1,607.1c-4.1,3.8-10,4.9-14,9c-3.5,2.5-6.8,5.7-11.8,4.4c-1-1.8,0.7-1.9,1.6-2.5
		c1.5-0.5,2.2-1.2,1.3-2.9c-0.6-1.1-1.4-2.5,0.7-3.1c2.1,1.1,4.1,1.3,5.7-0.9c4.7-3.5,10.2-5.9,15.2-9c4.3-2.3,8.7-4.4,13-6.8
		c1.6-0.9,3.2-2.6,5.3-1.1c-1.8,4.5-6.5,4.7-9.9,6.8c-1.4,0.9-3.3,0.9-2.1,3.2c0,0,0,0,0,0C218.3,605,216.1,605,215.1,607.1z"/>
	<path class="st978" d="M232.1,594.1c-6,3-12,6-18,9c-0.7-0.3-1.3-0.7-1.3-1.6c0.1-0.5,0.3-1.1,0.6-1.3c8.9-5.5,17.6-11.5,27.6-15
		c1.2,0.2,2.3-1.4,3.6-0.2c-0.1,2.4-2.6,2.7-3.6,4.2C238.1,590.8,235.1,592.5,232.1,594.1z"/>
	<path class="st979" d="M250.1,559.2c-1.4,0.2-3.3-0.6-4.1,1c-0.9,1.9,1.7,2,2.3,3.3c0.7,1.6,2.6,2.9,1.4,4.9
		c-0.7,1.2-1.7,0-2.6-0.2c-1.1-1.4-2.3-1.1-3.5-0.2c-2.8,2.2-6.2,3.2-9.2,4.9c-0.8,0.3-1.7,1-2.6,0.2c-1.1-1.7,0.3-3,1.4-5.1
		c-8.9,6-18.1,9.7-26.6,14.8c-2.1,1.3-4.4,2.6-6.7,0.3c0.5-0.6,0.9-1.1,1.4-1.7c0,0,0,0,0,0c2.7-1.3,5-3.3,8-4.1
		c7.3-3.5,14.3-7.7,21.2-11.9c2.7-1.6,5.6-2.9,8.4-4.3c2.2-0.1,4.1-0.8,5.9-2C246.5,558,248.3,557.6,250.1,559.2z"/>
	<path class="st971" d="M209.2,577.2c-2.5,1.7-4.9,3.7-8,4.1c1.5-2,3.8-3,5.9-4.2C207.8,577.2,208.5,577.2,209.2,577.2z"/>
	<path class="st979" d="M254,567.2c0.4-3.5-2.4-5.5-4-8c4.5,0.8,4.5,4.9,5.8,8C255.3,567.8,254.7,567.8,254,567.2z"/>
	<path class="st858" d="M254,567.2c0.6,0,1.2,0,1.9,0c-0.2,3,1,6.1-0.9,9C255.2,573.1,255,570.1,254,567.2z"/>
	<path class="st774" d="M180.1,727c-1.9,2.3-4.3,3.9-7,5c-2.8-2.2-0.6-4.5,0.7-5.7C175.7,724.5,178.2,725.3,180.1,727z"/>
	<path class="st880" d="M154.1,743.9c-1.3-1.7,4.3-3.3,0-5c0.2-2.7,2.3-3.7,4.4-3.8c2.7-0.1,0.1,2.5,1.3,3.3
		c0.4,0.3,0.9,0.3,1.3,0.5C158.8,740.6,156.5,742.3,154.1,743.9z"/>
	<path class="st765" d="M153.7,734.7c-0.8,0-1.2-0.2-1.1-0.8c0.1-0.3,0.7-0.6,1.1-0.7c0.2-0.1,0.5,0.3,0.7,0.5
		C154.2,734,153.9,734.4,153.7,734.7z"/>
	<path class="st980" d="M172.2,763.9c-0.7,1-1.4,2.1-2.2,3.1c-1.6,0.4-1.7,3.3-3.7,2.6c-2-0.6-0.9-2.9-1.5-4.3
		c-1-2.7-1.8-5.6-2.7-8.3c-1.4-2.6-4.3-3.7-6-6c2.8-0.9,5.5-2.1,6.9,2.1c0.5,1.6,2.4,2,4.1,1.8c1-0.6,2-0.8,3,0c0.7,2,1.4,4,2.1,6
		C172.9,762,172.8,762.9,172.2,763.9z"/>
	<path class="st981" d="M151.2,697c3-0.5,5.2-1.3,3.2-5.1c-0.5-1-0.2-2.5-0.2-3.8c0.4,0.3,0.8,0.6,1.1,0.9c1.3,3.9,4,7.6,1.9,12.1
		c-3.2,1.6-5.2,0.1-6.5-2.8C150.7,697.8,150.9,697.4,151.2,697z"/>
	<path class="st982" d="M151.2,698.1c1.4,2.2,3.6,2.7,6,3c0,1.9,0.3,4-2,5c-1.5,0.2-2.9,0.1-4.2-0.7c-0.9-0.9-1.4-2.1-1.7-3.4
		c-0.2-1.3-0.2-2.6,0.6-3.8C150.2,697.7,150.7,697.7,151.2,698.1z"/>
	<path class="st983" d="M151.2,705c1.4-0.1,2.7,0.3,4,1c-1,0.7-2,1.3-3,2c-1.7-0.3-3.3-0.7-5-1c0-1,0-2,0-3
		C148.9,702.7,150,704,151.2,705z"/>
	<path class="st984" d="M141.2,698c-2.6-3.9-6-6.9-10.2-8.9c-1.8-0.9-4-1.3-2.8-4.1c4.8-1.6,10.6,0.5,13.5,5.2
		c1.5,2.3,3.1,4.7,3.4,7.6c0,0.9-0.3,1.6-0.9,2.2c-0.3,0-0.7,0-1,0C142.8,699.1,142.1,698.4,141.2,698z"/>
	<path class="st985" d="M144.2,700c0-0.7,0-1.3,0-2c0.7-0.6,0.2-1.9,1.3-2.3c3.5,0.6,3.4,3.9,4.7,6.3c0.3,1,0.7,2,1,3
		c-1.3-0.3-2.7-0.7-4-1C147.1,702,145.8,700.9,144.2,700z"/>
	<path class="st986" d="M163.2,693c-2-1.6-4.4-2.8-5-5.7c7.1,0.7,11.2,4.5,11.9,11.7c-0.6,0.5-0.3,1.8-1.2,2
		c-1.2,0.3-1.3-0.8-1.5-1.5C166.7,696.9,165.6,694.5,163.2,693z"/>
	<path class="st987" d="M163.2,693c3.3,0.6,4.2,3.3,4.9,5.9c0.8,2.7,1.4,0,2,0.1c0.1,2.2,0.1,4.4-2,5.9
		C166.6,700.9,166.2,696.4,163.2,693z"/>
	<path class="st988" d="M201.2,691.2c1.1-2.3,0.4-3.4-2.1-3.2c1.4-1.1,3-2.9,4.5-0.6C204.9,689.6,203.1,690.6,201.2,691.2z"/>
	<path class="st989" d="M151.2,698.1c-0.3,0-0.7,0-1,0c-2.6-0.1-3.3-2.3-3.9-4.2c-0.6-1.9-1.7-3.4-2.7-5.1c-0.4-1.3-0.5-2.6,0.7-3.7
		c1.3,0.7,2.6,1.3,3.9,2c1,3.3,2,6.7,3,10C151.2,697.4,151.2,697.7,151.2,698.1z"/>
	<path class="st990" d="M148.2,673.1c0.3,0.6,0.9,1.1,0.9,1.7c0.2,3.8-0.2,8.2,5.1,2.7c0.8-0.9,2.8-0.8,4,0.6
		c-1.5,0.1-2,0.9-1.9,2.3c-0.8,0.8-1.6,1.6-2.4,2.5c-0.3,0-0.5,0.1-0.7,0.2c-7.3,2.1-12,0.3-8-12.2c0.3-0.5,0.7-0.9,1.3-1.1
		C148.5,670.1,147.5,672,148.2,673.1z"/>
	<path class="st991" d="M144.2,685.1c0,1.3,0,2.6,0,4c1.3,2.2,3,4.3,1,7c0,0.8-0.2,1.6-1,2c-2.9-7.3-6.8-13.4-16-13c0-0.3,0-0.7,0-1
		c2.3-1,4.6-2,7-3c2.5,1.1,5.2,1.8,7.9,2.2c0.4,0.2,0.8,0.5,1.1,0.9C144.2,684.5,144.2,684.8,144.2,685.1z"/>
	<path class="st992" d="M144.2,684.2c-0.3,0-0.7-0.1-1-0.1c-6-1.6-6.7-4.3-2.8-11c1.9-1.1,3.3-3.1,5.9-2.9c-0.2,1.1-0.4,2.2-0.7,3.4
		c-1.9,8.1-0.6,9.7,7.6,9.6C150.4,685.4,147.1,683.6,144.2,684.2z"/>
	<path class="st993" d="M183.1,658.1c1,0,2,0,3,0c3.1-3.8,7.7-5.2,12-7c3.4,0.2,6.2-1.4,9-3c-1.1,4.4-6.3,4-8.6,7.2
		c-0.7,0.9-2.4,1.2-2.5,2.7c-1.6,0.7-3.3,1.4-4.9,2.1c-1,0.4-1.7-0.6-3.1,0.3c-6.4,4.3-13.1,8.1-19.8,12.1c-1.6,0.9-3.2,1.8-5.1,1.6
		c-1.2-3.2,1.5-4.1,3.3-5c3.2-1.7,6.7-3.1,10-4.5c-0.1-1.9-1.5-1.4-2.2-1.9c-0.9-0.7-2.4-1.1-1.3-2.5c0.8-1.1,1.9-1.2,3-0.3
		C179.1,662.4,181.3,661,183.1,658.1z"/>
	<path class="st994" d="M188.1,747c-2.4,0.3-4.7,0.6-7.1,0.9c0.7-2.7,3.7-2.3,5.3-3.9c9.6-5.1,19.2-10.2,28.7-15.2
		c0.3,0.2,0.4,0.5,0.3,0.8c-0.1,0.3-0.2,0.4-0.3,0.4c-7.7,4.3-15.3,8.7-23,13C190.4,744,189.6,745.8,188.1,747L188.1,747z"/>
	<path class="st995" d="M215.1,730c0-0.4,0-0.8-0.1-1.2c2-1.9,4.4-2.9,7-3.8c0.7-0.5,1.4-0.5,2.1,0
		C221.7,727.7,218.1,728.3,215.1,730z"/>
	<path class="st784" d="M224.1,725c-0.7,0-1.4,0-2.1,0c1.1-1.2,2.4-1.9,4-2.1C225.8,724,225,724.6,224.1,725z"/>
	<path class="st865" d="M170.1,755c-1,0-2,0-3,0c0-0.3,0-0.6,0-0.9c3-1.1,5.9-2.2,8.9-3.4C175.1,753.6,172.1,753.6,170.1,755z"/>
	<path class="st996" d="M198.1,747c-2,1.3-4,2.6-6.1,3.9c0-1,0-1.9,0.1-2.9c1.3-0.7,2.7-1.3,4-2C196.5,746.8,197.3,746.9,198.1,747z
		"/>
	<path class="st996" d="M188.1,747c0.3-2.4,1.6-3.7,4-4C191.7,745.2,190.8,747,188.1,747z"/>
	<path class="st997" d="M142.2,637.1c1,2,1.8,4,4,5c0.5,0.6,0.9,1.3,0.9,2.1c-0.2,1-0.3,2.1-1.9,1.9c-1.4-1.6-2-3.9-4-5
		c0.1-1.4-0.3-2.7-1-4C140.8,637.1,141.5,637.1,142.2,637.1z"/>
	<path class="st998" d="M145.2,646.1c0.8-0.4,1-1.2,1-2c3.2,0.6,4.5,3.5,6.6,5.3c0.6,3.8,0.1,7-4.6,7.6l0-0.1c0.1-3.1-1.3-6-2-8.9
		C145.8,647.4,145.5,646.8,145.2,646.1z"/>
	<path class="st999" d="M146.2,648.1c2.6,2.6,4.6,5.2,2,8.9c-0.3,0-0.7,0-1,0c-0.6-2.8-2.7-5-2.9-7.9
		C144.8,648.8,145.5,648.4,146.2,648.1z"/>
	<path class="st799" d="M229.1,745c-1.7,1-3.3,2-5,3C224.4,744.6,226.8,745,229.1,745z"/>
	<path class="st926" d="M172.2,763.9c0-1,0-1.9,0-2.9c1.6-1,3.3-2,4,0.9C174.4,761.6,173.3,762.8,172.2,763.9z"/>
	<path class="st1000" d="M170.1,778c-0.3-3.1,1.8-3.1,3.9-3C173.2,776.6,172.1,777.8,170.1,778z"/>
	<path class="st1001" d="M141.2,673.1c-3.1,7.7-3,8.2,2,11c-3,0-6.2,0.4-8-3c0.3-4.3,0.7-8.7,1-13c2.6-1,5.5,1.1,8-1
		c0.3,0.3,0.5,0.7,0.5,1.2C144.4,670.5,142.5,671.5,141.2,673.1z"/>
	<path class="st1002" d="M144.1,662.1c0.3-0.3,0.7-0.7,1-1c2.1-1.9,4.2-3.8,6.8-5c5.5-2.5,10.3-6.8,17-5.8c0.8,0.1,1.6,0.2,2.3,0.7
		c-0.9,3.6-5.4,2.6-6.9,5.3l0,0c-2.5,1.5-5.6,1.9-7.8,4.2c-3.8,2.5-8.3,3.5-12.3,5.7C143.8,664.7,146,663.4,144.1,662.1z"/>
	<path class="st1003" d="M144.2,666c3-4,7.8-4.4,11.9-6c0.4,0.3,0.5,0.8,0.5,1.3c-1.2,2.1-3.4,2.4-5.4,3.3c-2.8,0.3-3.1,5.5-7.1,3.5
		c0-0.3,0-0.7,0-1C144.2,666.7,144.2,666.4,144.2,666z"/>
	<path class="st1004" d="M152.2,657.1c-2.3,1.3-4.7,2.7-7,4c0-2.1,2.6-2.2,3-4c3.1-1.8,4.2-4.6,4-8c-1.3-2.6-3.4-4.6-5-7
		c-0.8-3.1-3.5-5.5-3-9c2.6,3.4,5.2,6.7,9.4,8.4c2,0.8,1.5,3.2,2.6,4.6C157.7,650.8,154,653.6,152.2,657.1z"/>
	<path class="st1005" d="M169.2,557.4c1,1.3,4.3-0.7,3.8,2.7c0,0,0,0,0,0c-1.6,2.7-4.6,3.4-7,5c-1.5,0.3-2.8,1-3.9,2.1
		c-2.1,2.4-5.1,3.2-7.7,4.8c-2.4,0.8-4.2,3.1-7,2.9c-1.2-1-2-2.6-3.9-0.7c-1.1,1-2.7,1.9-4.5,1.1c-0.2-0.4-0.2-0.9,0.1-1.3
		c1.1-0.9,2.3-1.7,3.4-2.6C151.6,567,160.8,562.9,169.2,557.4z"/>
	<path class="st1006" d="M145.2,563.1c-3,1.7-5.9,3.4-8.9,5.2c-1.1,0.4-2.3,1-3-0.3c-0.7-1.4,0.6-1.9,1.5-2.6
		c2.1-1.5,4.3-2.9,6.3-4.5c0.8-0.2,1.5-1,2.3-0.9c1.4,0,3-1.9,3.9-0.2C148.4,561.5,145.6,561.8,145.2,563.1z"/>
	<path class="st1007" d="M141.2,561.2c-2.1,1.7-4.4,3.2-6.3,5.2c-2.5,2.5,0.7,1.4,1.4,1.9c-1.7,1.5-4.8,3.2-5.8,1.7
		c-1.6-2.6,1.4-4.1,3.6-5.4c2-1.1,4-2.3,6-3.4C140.5,560.8,140.9,560.8,141.2,561.2z"/>
	<path class="st1008" d="M152.3,554.4c0.9-0.4,1.9-0.9,2.8-1.3c1.1,0.6,2.5,0.9,3.2,2.3c-1.8,1.4-3.7,2.4-5.9,2.9
		c-1.2,0.4-2.2,0.7-2.2-1.1C150.9,556.3,151.6,555.4,152.3,554.4z"/>
	<path class="st1009" d="M150.2,557.2c0.5,0.9,1.3,1,2.2,1.1c-0.5,0.6-0.9,1.2-1.4,1.7c-1.9,1-3.9,2-5.8,3.1c-0.2-1.3,1.3-1.8,1.5-3
		c-2.1-0.6-3.6,1-5.5,1.1c-0.3,0-0.7,0-1,0C142.9,558.4,146.8,558.5,150.2,557.2z"/>
	<path class="st1010" d="M143.1,548.1c2.8-2.6,6.6-3.6,9.8-5.6c0.8-0.5,2-0.9,2.7,0.1c0.7,1-0.1,1.8-0.8,2.6c-2.2,2.3-5.3,3.2-7.9,5
		C144.9,551,143.3,550.8,143.1,548.1z"/>
	<path class="st1011" d="M143.1,548.1c0.9,1.3,2.3,1.8,3.8,2c-0.6,0.5-1.1,1.2-1.8,1.5c-1.5,0.6-3.3,2.8-4.6,0.5
		C139.5,550.2,141.5,549,143.1,548.1z"/>
	<path class="st846" d="M162.1,543.2c-1.3,1.6-3.6,0.7-5,2C157.8,542,160.4,543.7,162.1,543.2z"/>
	<path class="st842" d="M152.2,657.1c-0.1-4.2,3.6-7,4-11c2.7-1.2,4.5-2.9,3.4-6.2c-0.4-1.4,0.5-1.6,1.6-1.8
		c1.4,3.3,5.1,2.8,7.6,4.2c1.9,1,2.3-2.1,2.9-3.7c1-2.3-0.4-7,3.3-6.4c2.6,0.5,0.8,5,1.2,7.7c0.3,2.2-2.2,3.3-2.3,5.9
		c3.8-0.6,6.3-4.8,10.4-3.7c-0.5,1.6-2.3,1.8-3.1,3c-2.3,3.6-6,5-9.9,6c-2.2,0-4.3,0.9-6.7,0.2c-0.9-0.3-4.2-1-2.8,2.5
		c0.1,0.2-0.4,0.7-0.6,1.1C157.2,652.4,155.2,656.9,152.2,657.1z"/>
	<path class="st851" d="M176.1,574.2c2.7,3,5.9,5.3,10,6c-0.5,1.2-1.4,2.6,0.5,3.2c2,0.6,1.8,2.4,2.5,3.8c-0.5,0.6-1.2,0.7-1.9,0.7
		c-2.6-0.2-3.8-2.2-5-4.1c-2.1-0.6-3,0.4-3.1,2.5c-0.7,1.3-1.6,2.3-3,3c-0.9-0.8-5.7-2.6-7.6-3.1c-1.2-0.3-3-0.6-2.8-2.2
		c0.2-1.8,1.8-1.5,3.1-1c0.6,0.2,1.3,0.3,1.9,0.3c1.2-0.1,2.9,0.4,3.2-1.4c0.2-1.6-1-2.4-2.5-2.5c-1.5-0.1-2.9-0.3-4.3-1.1
		C168.5,573.2,173.2,575.7,176.1,574.2z"/>
	<path class="st1012" d="M168.1,629.1c0.7,1.2,1.2,2.5,1,4c-1.2,1.4,0.5,4.6-3,4.6c-3.3,0-5-1.8-6-4.6
		C160.8,627.8,164.7,628.9,168.1,629.1z"/>
	<path class="st842" d="M160.2,633.1c0.3,0.4,0.7,0.7,1,1.1c3.8,4.1,5.7,3.9,8-1.1c-0.2,5.8-0.6,6.1-8,5
		C160.8,636.4,160.5,634.8,160.2,633.1z"/>
	<path class="st1013" d="M141.2,581.2c0.5-1.8,3.2-1.9,3-4.1c0.5-1.5,1.9-1.9,3.2-2.3c2.7-0.1,4.2-2.7,6.7-3.1
		c1.8-0.3,2.9-2.5,5-1.8c1,0.8,1.3,1.9,1.6,3.1c0.1,0.5,0.1,0.9,0,1.4c-0.6,1.9-2.6,2.4-3.9,3.7c-0.5,0.4-1.1,0.8-1.7,1
		c-1.3,0.4-2.6,0.7-3.9,1.1c-3.5,0.1-6.2,4.1-10.1,2C141.2,581.8,141.2,581.5,141.2,581.2z"/>
	<path class="st947" d="M141.2,582.2c3.8,0.3,6.4-3.4,10.2-2.9c0.2,0.5,0.1,0.9-0.1,1.3c-1.8,2.2-4.7,2.9-7,4.3
		c-3.2,1-5.3,4.4-9.1,4.3C134.3,584.4,139.7,584.9,141.2,582.2z"/>
	<path class="st1014" d="M135.3,589.2c2.9-1.8,5.4-4.3,8.9-5.2c0.8,3.5-3,2.8-4,4.4c-0.7,0.4,0.2,1.1-0.2,0.6
		c-0.1-0.1,0-0.2,0.3-0.4c1.4-1.2,2.7-2,4.3-0.3c-2.7,2.1-5.5,3.8-9.1,3.6C135.4,591,135.3,590.1,135.3,589.2z"/>
	<path class="st1015" d="M147.2,575.2c-1,0.6-2,1.2-3.1,1.9c-2.3-0.2-3.9,1.3-5.8,2.2c-1.5,0.3-3.9,1.5-4-0.5
		c-0.1-2.4,2.4-3.4,4.8-3.5c1.7-0.9,3.4-1.7,5.1-2.7c0.9-0.5,1.8-1.7,3-1c1.1,0.7-1.5,1.9,0.1,2.8c0.3,0.2,0.4,0.5,0.3,0.7
		C147.4,575.1,147.3,575.2,147.2,575.2z"/>
	<path class="st1016" d="M139.2,575.2c-1.5,0.9-3.4,1.3-4.3,3.3c1,1.1,2.4,0.4,3.5,0.7c-1.6,0.8-3.4,3.2-4.9,0.8
		c-1.3-2.1,0.8-3.6,2.5-4.6c1-0.6,2.1-1,3.1-1.4C139.1,574.4,139.1,574.8,139.2,575.2z"/>
	<path class="st1017" d="M147.2,642.1c3.1,1.3,5.6,3,5,7c-3.2-0.2-4-3.3-6-5c0-0.7,0-1.3,0-2C146.5,642.1,146.8,642.1,147.2,642.1z"
		/>
	<path class="st956" d="M187.1,587.2c0.7,0,1.3,0,2,0c0.6,0,1.2,0,1.8,0c0.7,1.3,0,2.4-0.8,3.1c-7,6.5-7.2,14.9-6.5,23.5
		c0.1,1.5,0.6,3-0.5,4.3c-0.4,7.6,4.8,5.3,8.9,5.1c0,0.3,0,0.7,0,1c-3.5,0.7-8.1,3-10-0.9c-2.8-5.8-6.6-11.8-3.7-18.9
		c0.4-1,0.8-1.8,0.3-2.8c-1.9-4.5,0.7-6.7,4.4-8.3C184.7,591.3,183.8,587.8,187.1,587.2z"/>
	<path class="st970" d="M187.1,587.2c-1.9,1.6-1.5,4.8-4,6c-0.3-3-0.7-6-1-9c-0.2-0.2-0.3-0.4-0.2-0.7c0.1-0.2,0.2-0.3,0.2-0.3
		C183.8,584.5,185.5,585.8,187.1,587.2z"/>
	<path class="st944" d="M182.1,583.2c0,0.3,0,0.7,0,1c-1,0.7-2,1.3-3,2c-2.6-0.4-1.1-1.8-0.5-2.7
		C179.5,582.1,180.8,581.6,182.1,583.2z"/>
	<path class="st956" d="M177.5,618.2c0.6,1.1,0.9,2.1,0,3C176.8,620.1,177.3,619.1,177.5,618.2z"/>
	<path class="st1018" d="M171.1,651.1c3.3-2,6.6-4,9.9-6c1,0,2.1,0,3.2,0.1c-6.2,4.5-12.9,8.1-19.9,11.1
		C165.1,652.6,169.6,653.8,171.1,651.1z"/>
	<path class="st1019" d="M144.2,668.1c3.1,0,3.9-4.1,7.1-4c0.3,0.3,0.4,0.5,0.2,0.7c-0.1,0.2-0.3,0.3-0.4,0.3
		c-0.3,0.3-0.7,0.6-1,0.9c-0.9,1.4-1.6,3-2.9,4.1c-0.3,0-0.6,0.1-0.9,0.1c-1.5,1.3-2.8,3-5,3C141.1,670.7,143.5,669.9,144.2,668.1z"
		/>
	<path class="st1018" d="M156.1,661c0-0.3,0-0.6,0-0.9c2-2.8,5.1-3.2,8.2-3.8c-1.7,2.6-5.1,2.6-7.2,4.7
		C156.8,661.4,156.5,661.5,156.1,661z"/>
	<path class="st1020" d="M147.1,670c0.1-2,1.6-3,2.9-4.1c-0.6,2.4-1.3,4.8-1.9,7.2C147,672.3,147.2,671.1,147.1,670z"/>
	<path class="st1020" d="M156.1,661c0.3,0,0.6,0,0.9-0.1c0.1,4.3-4,2.8-6,4.1c0.1-0.3,0.1-0.6,0.1-0.9
		C152.8,662.9,154.9,662.6,156.1,661z"/>
	<path class="st951" d="M198.1,651.1c-3.9,2.4-7.4,5.6-12,7c-1.1-4.4,2.9-5.6,5.3-6.4C193.2,651.2,195.8,650.6,198.1,651.1z"/>
	<path class="st1021" d="M189.1,620.1c4.7,0.7,8.1-2.3,12-4c0.7,0,1.3,0,2,0c-3,2.5-6.6,4.2-10.3,5.4c-3.6,1.2-7.2,1.2-8.8-3.3
		c0.3-0.3,0.8-0.5,1.2-0.5C186.8,618,188.4,618.4,189.1,620.1z"/>
	<path class="st858" d="M215.1,607.1c0.3-3.3,2.7-3,5-3C218.8,605.8,216.8,606.1,215.1,607.1z"/>
	<path class="st1022" d="M185.1,618.1c-0.4,0-0.8,0.1-1.2,0.1c-0.3-0.1-0.5-0.1-0.8-0.1c-2.9-11.7-1.2-22.2,7.8-30.9
		c1-0.7,2.1-1.4,3.1-2.1c0.7,0,1.4,0,2-0.1c0.6,2.8-1.9,3.5-3.3,4.9c-1.9,1.5-3.1,3.6-4.3,5.6c-3.4,5.4-3,11.4-2.9,17.3
		C185.6,614.6,186.4,616.5,185.1,618.1z"/>
	<path class="st1023" d="M144.5,588.3c-1.8-0.5-3.5,0.7-5.5,0.9c1.1-2.7,4.4-2.7,5.2-5.2c2.3-1.3,4.7-2.6,7-3.8c1.2-1,2.9-0.9,4.1-2
		c0.4-0.3,0.8-0.6,1.2-1c1.6-0.8,3.3-1.4,4.9-2.2c1.8-0.9,3.7-0.8,2.9,2.3C157.7,581.1,151.5,585.5,144.5,588.3z"/>
	<path class="st1013" d="M162.2,568.2c3.3-0.9,6.4-3.1,10.7-1.9c-1.9,1.5-3.3,2.7-4.7,3.9c-0.4,1-1.1,1.6-2,2
		c-1,0.9-2.1,1.1-3.4,0.8c-1.4-0.5-3.5-0.4-3.1-2.9C160,568.8,160.6,567.8,162.2,568.2z"/>
	<path class="st1024" d="M180.1,565.2c0.3,0,0.7,0,1-0.1c2.4,0.2,1,1.5,0.4,2c-1.3,1.2-3,1.9-4.4,3c-1,0.7-1.9,1.4-2.9,2.1
		c-3.3,0.8-3.1-0.8-2.1-3c1-0.7,1.9-1.4,2.9-2.1C176.7,566.5,178.5,566.1,180.1,565.2z"/>
	<path class="st1025" d="M164.2,577.2c-0.3-2.2-1.6-2.4-3.1-1.3c-1.4,1.1-3,1.3-4.7,1.2c1.3-0.9,2.6-1.9,3.9-2.8
		c1.8-1.3,3.5-2.7,5.8-0.9C165.9,575,166.7,577,164.2,577.2z"/>
	<path class="st1026" d="M177.1,570.2c0.2-2.5,4.3-2.1,4-5c1.7-1.7,2.2-0.1,3,1C182.1,568.1,180,569.9,177.1,570.2z"/>
	<path class="st1027" d="M172.2,569.2c-0.1,1.6,0.2,2.9,2.1,3c-1.7,0.7-3.2,2.5-5.1,1.1c-1.1-0.8,0.1-2.1,0.2-3.1
		C170.3,569.9,171.2,569.6,172.2,569.2z"/>
	<path class="st1028" d="M166,573.5c-2.1-0.9-3.8,0.8-5.8,0.9c0-0.4-0.1-0.8-0.1-1.2c0.5-0.6,1.3-0.7,2-1c1.3,0,2.7,0,4,0
		C166.1,572.6,166.1,573.1,166,573.5z"/>
	<path class="st1029" d="M192.1,589.2c1.2-1.5,3.4-2,4-4.2c1.2-0.7,2.5-1.3,3.7-2c2.2,0.2,3.9-0.1,6-1.4c8.2-5.1,17.3-8.5,25.3-13.9
		c1.1-0.7,2.7-2.2,4-0.6c1.7,2.1-0.9,2.3-1.9,3c-1.2,0.8-1.2,1.9-1.2,3.1c-3.1,4.4-8.3,5.9-12.7,8.4c-5.7,3.3-11.4,6.8-17.2,10.1
		c-1.5,0.8-2.9,1.7-4.7,1.6C195.4,592.4,191.7,593.6,192.1,589.2z"/>
	<path class="st1028" d="M162.2,568.2c-1,0.4-1.6,1-2,2c-0.3,0.5-0.6,0.5-1,0c-1.7,0.7-3.3,1.3-5,2c-0.3-0.2-0.5-0.5-0.4-0.7
		c0.1-0.2,0.2-0.4,0.3-0.4c2.4-1.8,5-3.2,8-4C162.1,567.5,162.2,567.9,162.2,568.2z"/>
	<path class="st974" d="M166,565.1c1.9-2.3,4.6-3.4,7-5C172.4,564.2,168.9,564.1,166,565.1z"/>
	<path class="st1026" d="M180.1,565.2c-1.4,1.6-3.1,2.1-5.1,1.9c1.3-0.7,2.6-1.4,3.8-2.1C179.3,565.1,179.7,565.2,180.1,565.2z"/>
	<path class="st1030" d="M241.1,585.2c-8.3,4.8-16.7,9.7-25,14.5c-1.1,0.7-2.4,1.1-3,2.5c-3.6,3.4-8.6,4.5-12.5,7.5
		c-0.8,0.6-2.4,0.8-1.5,2.4c-2,2.5-3.9,4.1-6,0.1c0.2-0.1,0.3-0.3,0.5-0.4c1.2-0.4,1.6-1.3,1.8-2.5c0.6-2.3,0.6-4.7,2.2-6.7
		c2.6-2.6,6-4.2,9.2-5.7c9.5-4.5,18.7-9.7,28.2-14.4c1-0.5,2-1.4,3.1-0.3C238.1,584.2,242.3,582,241.1,585.2z"/>
	<path class="st1031" d="M227,579.1c-0.2,3.4-5,2-5.2,5.5c0.8,0.7,2.9-0.6,3.2,1.5c-0.3,1.5-1.6,2-2.8,2.6c-7.8,4-15.6,8-23.3,12.2
		c-3.4-0.3-3.7-2.3-2.7-5c5.2-4.9,11.8-7.7,17.7-11.4c2.8-1.7,5.8-3.1,8.5-4.9C224,578.6,225.4,577.8,227,579.1z"/>
	<path class="st1032" d="M199.1,600.2c8.2-5.6,17.3-9.5,26-14c7.4-4,14.8-7.9,22.1-11.9c-2.2,3.9-6,5.5-9.1,7.9
		c-13.3,7-26.6,14-39.9,21C197,601.7,198,600.9,199.1,600.2z"/>
	<path class="st1033" d="M227,579.1c-7.1,2.3-13,7-19.6,10.4c-3.6,1.9-6.9,4.4-10.4,6.6c-2.5,0.7-2.9-0.4-1.9-2.4
		c0.6-1.1,2.1-0.8,2.9-1.5c7-3.9,14.1-7.8,21.1-11.7c4.3-2.4,8.6-4.9,12.9-7.3c0.7-0.3,1.3-0.7,2-1c1.2-0.7,2-0.7,2,1
		C233,575.1,229.5,576.3,227,579.1z"/>
	<path class="st1034" d="M236.1,573.2c-0.4-0.8-1.2-1-2-1c3.6-2,7.1-4.1,10.7-6.1c2.6-1.5,2.1,0.7,2.3,2.1
		C242.6,568.1,240.3,572.9,236.1,573.2z"/>
	<path class="st1035" d="M198.1,592.2c0.1,1.4-5.4,1.4-1,4c-0.3,1.8-1.2,3.9,2,4c-0.3,1-0.7,2-1,3c-0.7,2.3-1.3,4.7-2,7
		c-0.8,1-2.2,1-3,2c0,0,0,0,0,0c-1.1,0.8-0.9,1.7-0.1,2.4c2.5,2.2,1.3,3.5-0.9,4.6c-1.8-1.7-2.3-3.9-2.3-6.3c0-4.3-0.2-8.5-2.8-12.3
		c-1.6-2.3,0.4-4.3,1.1-6.4c1-2,1.8-4,4-5C193,592.4,196.2,591,198.1,592.2z"/>
	<path class="st1036" d="M188.1,594.2c-0.7,3.1-0.8,6,1.7,8.6c1.3,1.3,1.4,3.3,1.3,5.1c-0.3,3.8,0.2,7.5,1,11.2
		c-1.1,0.1-2.3-0.2-3,1c-1.3-0.7-2.7-1.3-4-2C183.8,609.8,182.5,601.6,188.1,594.2z"/>
	<path class="st1037" d="M199.1,612.1c-2.9-0.5-1.8-1.9-0.3-2.7c4.7-2.6,9-5.8,14.3-7.3c0.3,0.3,0.7,0.7,1,1
		C209.1,606.1,204.1,609.1,199.1,612.1z"/>
	<path class="st1038" d="M145.2,696c-0.3-2.3-0.7-4.7-1-7c2.6,0.4,2.6,2.6,3.1,4.5c0.4,1.8,1.7,3.2,2.9,4.5c0,1.3,0,2.7,0,4
		C148.1,700.4,147.6,697.4,145.2,696z"/>
	<path class="st1039" d="M154.1,571.1c0,0.4,0,0.7,0,1.1c-2.4,0.7-4,3.3-6.9,3c0-0.3,0-0.7,0-1C149.8,573.7,151.5,571.3,154.1,571.1
		z"/>
	<path class="st1040" d="M165.4,634c-0.7-0.1-1.1-0.6-1.1-1.3c0-0.7,0.2-1.3,1-1.3c0.9,0,1.4,0.7,1.4,1.6
		C166.8,633.9,166.1,633.9,165.4,634z"/>
	<path class="st1041" d="M155.2,578.2c-0.8,1.9-2.3,2.1-4.1,2c0.1-0.3,0.1-0.6,0.3-0.9C152.6,578.9,153.9,578.5,155.2,578.2z"/>
	<path class="st972" d="M159.2,570.2c0.3,0,0.7,0,1,0c-0.5,1.8,1.6,1.1,2,2c-0.4,0.8-1.2,1-2,1C159,572.5,159.2,571.3,159.2,570.2z"
		/>
	<path class="st977" d="M193.1,612.1c0.1-2,1.5-2,3-2C196.6,613,194.8,612.4,193.1,612.1z"/>
</g>
<g id="logo_x5F_whatss" [@LogoWhatssTrigger]="isEstadoLogoWhatss" (click)="clickEvent('logoWhatss')">
	<path class="st1042" d="M271,75.8c0.3-0.4,0.5-0.7,0.8-1.1c2.9-1,5.2-3,7.8-4.5c2.1-1.1,4.4-1.9,6.3-3.4c0.9-0.1,1.7-0.2,2.2-1.1
		c2.3-0.9,4.5-1.9,6.6-3.1c1.2-1.8,3.6-1.7,5-3.1c1.1-1.1,2.7-1.5,4-2.2c2.1-1.6,4.6-2.5,7.1-3.4c0.4-0.4,0.8-0.8,1.2-1.2
		c1-0.6,2.1-0.9,2.9-1.9c2.3-0.7,4.5-1.3,6.8-2c4.3-1.7,8.6-1.8,12.8,0.4c5.2,1.5,9.6,4.6,14.4,7c1.3,1.6,3.5,1.1,5,2.1
		c1.2,0.2,2.2,0.6,3.1,1.5l-0.1,0c4.2,1.5,6.6,5,8.7,8.6c1.9,2.9,1.9,6.3,2.4,9.6c0.6,2.1,0.4,4.3,0.4,6.4c0,36.2,0,72.4,0,108.5
		c0,2.7,0,5.5,0,8.2c-1,3.3-0.1,6.8-0.7,10.2c-0.6,4.3-0.1,8.7-1.7,12.8c0,1.3-0.7,2.5-1.1,3.7c-0.2,1-0.5,1.9-0.8,2.9
		c-1.6,0.5-2.3-0.5-2.7-1.8c-0.5-4.8,3.1-8.9,2.2-13.8c0-1.6,0-3.3,0.4-4.9c1-4.9,0.4-9.9,0.4-14.9c0-7.2-0.1-14.4,0.1-21.6
		c2.1-28.8,0.5-57.7,1.1-86.5c0.1-4.2-1.1-8.2-1.8-12.2c-0.6-0.9-1-2-1.2-3.1c-1.6-7.1-7.7-9.3-13.4-11.2c-4.1-1.4-7.7-3.7-11.7-5.2
		c-6.4-4.3-12.6-4.9-19.4-0.4c-3.3,2.2-7.2,3.3-10.9,4.9c-2,0.9-3.9,1.9-5.4,3.5c-8.2,3.4-15.5,8.6-23.5,12.5
		c-10.5,5.2-20.5,11.4-30.7,17c-11.7,6.5-23.9,12.2-35.3,19.2c-1.7,1-3.6,1.9-5.4-0.1c-0.2-0.5-0.2-0.9,0.2-1.3
		c0.6-1.2,1.4-2,2.9-1.9c3.3-1.7,6.4-3.9,10.1-4.8c1.4-1.1,2.7-2.1,4.1-3.2c2.3-0.8,4.5-2,6.7-2.8c0.4-0.4,0.8-0.8,1.3-1.1
		c1.1-1.1,2.7-1.2,4-2l-0.1,0c1.1-1,2.7-1.2,3.8-2.2c0.9-0.7,1.9-0.9,2.9-1.2c1.3-1,2.9-1.6,4.3-2.6l-0.1,0c1.1-0.9,2.5-1.3,3.8-1.9
		c2.4-1.4,4.8-2.8,7.1-4.1c1.8-1.3,3.8-2.4,6-2.8c0.3-0.4,0.7-0.8,1-1.2C266.7,77.3,268.8,76.5,271,75.8z"/>
	<path class="st1043" d="M291,303.8c-0.4,0.6-0.7,1.2-1.1,1.8c-1.1,2.8-2.3,5.6-4,8.1c0,0.3,0,0.6,0,0.9c-1.5,2.3-3,4.6-3.9,7.2
		c-0.3,0.2-0.6,0.5-1,0.7c-0.7,1.4-1.3,2.8-1.6,4.3c-1.2,3.4-3.4,6.3-5,9.5c-0.6,1.9-2,3.5-2.4,5.4c-0.7,0.9-1.3,1.8-2,2.7
		c-0.7,1.3-1.3,2.8-1.6,4.2c-0.7,2.2-2.1,3.9-3.1,5.9c-1.4,1.8-2.2,3.9-3.3,5.8c-0.4,0.4-0.8,0.8-1.2,1.2c-3.4,3.1-7.1,2.7-10.8,0.7
		c-3.6-2.3-7-4.9-10.4-7.3c-0.7-0.3-1.1-0.9-1-1.7c1.5-2.7,3.6-1.5,5.6-0.9c2.2,0.6,4.5,1.5,5.9-1.4c0.5-0.9,1.5-1.4,2.5-1.3
		c7.5,0.9,10.4-3.6,11.8-9.9c0.2-0.8,0.5-1.6,0.8-2.3c2.9-3.9,3.9-8.7,6.2-12.9c2.5-4.5,5.2-8.8,8.2-12.9c1.1-1.4,1.7-2.5,1.7-4.3
		c0-1.6,0.2-4,3-2.1c0.1,0.1,0-0.4,0-0.7c0.3-3.1-0.6-6.8,3.8-8c1.6-6.2,9-2.7,11.7-7.2c0.3-0.5,1.6-0.6,1.6-1.1
		c0.2-7.6,5.9-3.5,9.2-4.4c0.6-0.2,1.3,0,1.9,0.3c0.7,0.5,0.6,1.2,0.3,1.9c-0.3,0.4-0.6,0.7-1,1c-2.4,0.8-4.6,2-6.3,3.8
		c-1.4,1.1-3.3,1.4-4.4,2.8c-1.1,1.5-3,1.6-4.2,2.9c-0.7,0.6-1.4,1.2-2.1,1.9C293,299.9,291.9,301.8,291,303.8z"/>
	<path class="st1044" d="M193.2,355.4l-0.4,0.1l-0.4,0c-2.7,0.5-5.1,2-7.9,2.4c-4.1,0.6-8.1,0.2-11.6-2.5c-1.5-0.8-3.1-1.4-4.1-2.9
		c-0.6-1-1.9-1.5-1.7-2.9c0.4-1,1.2-1.5,2.3-1.5c1.2,0.1,2.4,0.5,3.3,1.3c5.4,5.5,12.7,6.1,19.5,1.3c1.5-1,2.8-2.3,4.5-2.9
		c0.5-0.5,0.2,0.3,0.4-0.2c3.2-7.8,12.2-5.5,17.4-10.1c1.6-1.4,7.7,1.6,8.5,3.5c-4.7,1.3-10.1,0.6-13.7,5c-0.8,0.9-1.8,1.5-2.9,2
		c-1.4,0.6-2.7,1.5-4.2,2c-1.4,0.5-2.8,1-4,2C196.4,352.8,194.5,353.7,193.2,355.4z"/>
	<path class="st1045" d="M368,193.6c0-34.9-0.1-69.8-0.2-104.7c0-4.1,0.3-8.2-0.7-12.3c0.2-0.6,0.1-1.4,0.7-1.9c1.3,0.5,1,1.6,1,2.5
		c0,38.8-0.1,77.5-0.2,116.3C368.5,193.6,368.3,193.6,368,193.6z"/>
	<path class="st1046" d="M140.3,320.5c0-37.3,0-74.6,0-111.9c0.7-0.1,1.3,0.2,1.6,0.8c0.8,1.9,0.6,3.9,0.6,5.9c0,32.8,0,65.7,0,98.5
		c0,2,0.2,4-0.6,5.9C141.6,320.3,141.1,320.6,140.3,320.5z"/>
	<path class="st1047" d="M361.9,228.6c0.6,0.7,1.1,1.3,1.7,2c1.3,1.1-0.1,2-0.1,3c-0.4,2.3-1.9,4-2.5,6.2c-0.4,0.6-0.7,1.3-1.1,1.9
		c-0.8,1.2-0.7,2.9-1.9,3.9l0.1-0.1c-0.9,0.9-1,2.3-1.8,3.2c-1.3,1.2-1.6,3-3.1,4.1c-1.5,1.4-2.7,2.9-3.1,5c-1,0.9-2,1.9-2.9,2.8
		c-1,0.5-1.9,0.8-3,0.3c-1.2-1.3-0.7-2.6,0.4-3.5c4.4-4,7.1-9,8.3-14.8c0.5-0.2,1-0.2,1.3,0.2c0.3,0.5,0.6,1.2,0,1.9
		c1.6-4.8,3.7-9.4,5.5-14.1C359.9,229.5,360.4,228.3,361.9,228.6z"/>
	<path class="st1048" d="M343.9,260.5c1,0,2.1,0.1,3.1,0.1c-1.1,2.8-3.6,4.6-5.3,7c-2.7,1.5-4.3,4.3-6.9,5.9l0.1-0.1
		c-1.7,1-3,2.7-4.9,3.4c-0.8,0.5-1.4,1.2-2.3,1.5c-2,0-3.6,2.6-5.9,1.1c-1.2-2.8,1-3.3,2.7-4.2c4.3-2.6,8.9-4.9,12.1-9
		C338.6,263.7,339.1,259.4,343.9,260.5z"/>
	<path class="st1049" d="M141.2,208.6c-0.3,0-0.5,0-0.8,0c-0.4-10.4-0.4-20.7,0.8-31.1c0.3-2.3,1.4-4.3,3-5.9
		c0.4,0.4,0.7,0.8,0.9,1.3c0.5,1.6,0.3,3.3-0.1,4.9c-1.5,3.3-1.4,7-2,10.5c-0.9,4.7-0.2,9.5-0.5,14.2
		C142.4,204.7,143,206.9,141.2,208.6z"/>
	<path class="st1050" d="M189,119.8c0.9-0.1,1.7-0.2,2.2-1.1c2.1-1.1,4.1-2.7,6.5-3.4c0.7-0.1,1.3-0.2,2-0.1c0.4,0.2,0.7,0.5,0.8,1
		c-0.5,2.3-2.7,2.7-4.3,3.8c-0.4,0.2-0.7,0.4-1.1,0.6c-1.6,0.7-2.7,2-4,3.2c-2.5,2.2-5.1,4.1-8.7,3.3c-0.4-0.3-0.6-0.7-0.8-1.2
		c0-1.7,1.5-2.3,2.6-3.3l-0.1,0.1C185.5,121.3,187.6,121.1,189,119.8z"/>
	<path class="st1051" d="M151.2,156.5c0.5-3.7,3.4-6,5.2-9c0.7-0.4,1.3-0.5,2,0c0.4,0.6,0.5,1.3,0.4,2c-0.8,2.4-2.1,4.5-3.7,6.5
		c-0.9,1.2-1.3,2.7-2.2,4c-0.9,1.2-1.5,2.6-2.2,4c-0.8,1.4-1.2,3-2.7,3.9c-0.5,0.1-0.9-0.1-1.3-0.4c-0.6-0.9-0.3-1.9,0-2.8
		C148.3,162,149.5,159.1,151.2,156.5z"/>
	<path class="st1052" d="M172.2,354.4c6.5,4.7,13.3,2.2,20.1,1c-4.7,3.2-10,3.7-15.4,3c-1.9-0.3-3.8-0.9-4.7-2.9
		c-0.3-0.3-0.3-0.5-0.2-0.8C172,354.5,172.1,354.4,172.2,354.4z"/>
	<path class="st1053" d="M208.1,345.5c3.7-5.6,9.4-5.1,15-5.1c1-0.3,1.4,0.3,1.6,1.1c0.1,0.8,0,1.5-0.6,2.1
		c-1.5,0.1-2.8-0.7-4.2-1.2c-0.5,0-1.1,0.1-1.6,0.1c-2.8-0.4-4.6,1.8-7.1,2.4C210.3,345.5,209.4,346.6,208.1,345.5z"/>
	<path class="st1054" d="M322,279.5c2.2,0,3.5-2.6,5.9-2c0.3,2.3-2.3,1.8-3,3.1l0.1,0c-1.6,1.2-3.7,1.6-5.1,3.2
		c-0.4,0.8-1,0.8-1.8,0.8c-2.1,0.7-3.5,3-6.2,2.1c0.3-0.7,0.6-1.4,0.9-2.1c0.1-0.2,0.2-0.4,0.3-0.6c2.3-2.1,4.9-3.6,8-4.4
		C321.5,279.5,321.7,279.5,322,279.5z"/>
	<path class="st1055" d="M348.8,56.8c-4.6-2.4-9.2-4.8-13.9-7.2c-0.3-0.2-0.5-0.3-0.3-0.5c0.1-0.2,0.2-0.2,0.3-0.2
		c5.5,1.5,11,3.3,15,7.8C349.6,57.2,349.2,57.2,348.8,56.8z"/>
	<path class="st1056" d="M167.6,349.3c0.5,0.8,1.1,1.5,1.6,2.3c0.5,1.2,0.7,2.2-1.2,1.9c-2.1-3.3-6.9-2.4-8.9-5.9
		c-0.2-0.3-0.3-0.5-0.3-0.8c0-0.3,0.1-0.4,0.2-0.4c0.6-0.4,1.3-0.5,2-0.4c2,0.6,3.7,1.6,5.6,2.5C167,348.6,167.3,348.9,167.6,349.3z
		"/>
	<path class="st1057" d="M235,352.5c-3.6-1.9-7.8-3-10.1-6.9c0.3-0.4,0.8-0.7,1.3-0.8c4.5,1.1,8.8,2.5,10.2,7.7
		C235.9,352.9,235.5,352.9,235,352.5z"/>
	<path class="st1058" d="M250.1,361.4c3.6,2.1,7.2,1.8,10.8,0.1c-2.8,4.2-8.4,4.2-13.1-0.1C248.6,360.9,249.3,360.8,250.1,361.4z"/>
	<path class="st1059" d="M250.1,361.4c-0.7,0-1.5,0.1-2.2,0.1c-3.7-0.5-5.8-3.2-7.8-6c-0.4-0.2-0.5-0.4-0.4-0.6
		c0.1-0.2,0.2-0.3,0.4-0.3C243.9,356.1,247,358.8,250.1,361.4z"/>
	<path class="st1060" d="M365.2,224.6c0.3-4.3,2.4-8.4,1.9-12.9c0.1-0.2,0.2-0.4,0.3-0.6c0.2,0.1,0.4,0.2,0.5,0.4
		c-0.6,4.6,1.1,9.5-1.1,14C365.9,225.9,365.5,225.4,365.2,224.6z"/>
	<path class="st1061" d="M335,49c0,0.2,0,0.5,0,0.7c-4.3-1.6-8.8-1.3-13.3-0.9C326.2,45.3,330.6,46.1,335,49z"/>
	<path class="st1062" d="M368,193.6c0.2,0,0.5,0,0.7,0c-0.2,6,1.3,12.1-0.9,17.9c0,0,0.1,0,0.1,0c-1-3.3-0.5-6.6-0.3-9.9
		C367.8,199,367.9,196.3,368,193.6z"/>
	<path class="st1063" d="M364.8,68c-2.1-3.3-5-5.8-7.9-8.2c5.1-0.5,6.4,3.4,8.1,6.9C365.4,67.1,365.4,67.6,364.8,68z"/>
	<path class="st1064" d="M148.1,338.6c-4.2-1-4.7-4.8-5.9-8c-0.5-0.6-0.5-1.3-0.2-2.1c1.6-0.7,2.1,0.6,2.8,1.5
		c0.7,2.9,3.2,4.9,3.7,7.9C148.3,338.2,148.2,338.4,148.1,338.6z"/>
	<path class="st1065" d="M160.2,143.7c1-4.4,5-6.2,7.8-9.1c0.4-0.3,0.8-0.4,1.3-0.1c0.2,0.4,0.3,0.9,0.2,1.4c-2.4,3.2-4.8,6.3-8.6,8
		C160.6,143.8,160.4,143.8,160.2,143.7z"/>
	<path class="st1066" d="M274,336.4c0.8-3.7,3.6-6.5,5-9.9c0.3-0.5,0.6-0.5,0.9,0c-0.5,3.9-2.7,7-5,10
		C274.5,336.8,274.3,336.7,274,336.4z"/>
	<path class="st1067" d="M220,103.9c-3.2,1.8-6.2,4.3-10.1,4.8C212.9,106.3,215.6,103.4,220,103.9z"/>
	<path class="st1068" d="M364.8,68c0-0.4,0.1-0.9,0.1-1.3c3.3,1.9,3,5,2.9,8.1c-0.2,0.6-0.4,1.3-0.7,1.9
		C367.3,73.5,365.8,70.8,364.8,68z"/>
	<path class="st1069" d="M280.1,70.8c-2.7,1.4-4.6,4.5-8.2,3.9c1.6-2,3.5-3.4,6-4C278.6,70.2,279.3,70.1,280.1,70.8z"/>
	<path class="st1070" d="M147.2,164.8c0,0.9-0.1,1.9-0.1,2.8c-0.2,2.2-0.1,4.4-2.8,5.2c0-0.4-0.1-0.8-0.1-1.2c-0.5-3.2,0.7-5.9,3-8
		c0.3,0.2,0.4,0.5,0.3,0.8C147.4,164.6,147.3,164.8,147.2,164.8z"/>
	<path class="st1071" d="M300.9,293.4c0.9-1.7,2.7-2,4.1-2.9c1.3-0.6,2.4-2.4,4.2-1c-1.4,3-4.3,3.4-7.1,4
		C301.6,294,301.2,294,300.9,293.4z"/>
	<path class="st1072" d="M173.1,130.7c1.6-2.7,3.4-5.1,7-5c0.1,0.2,0.1,0.4,0.2,0.6c-0.4,1.9-2.2,2.4-3.4,3.6
		c-0.5,0.4-1,0.8-1.5,1.1c-0.6,0.3-1.2,0.3-1.8,0.1C173.5,131,173.3,130.8,173.1,130.7z"/>
	<path class="st1073" d="M159.2,346.4c0,0.4,0,0.8-0.1,1.1c-3.4-0.2-5.5-2.2-7-5c0.2-0.2,0.3-0.3,0.5-0.5
		C155.8,342.1,157.6,344.1,159.2,346.4z"/>
	<path class="st1074" d="M142.2,328.4c0,0.7,0,1.4-0.1,2.1c-1.7-3.2-2.3-6.5-1.8-10.1c0.3,0,0.6,0,0.8,0
		C143.3,322.9,144.2,325.5,142.2,328.4z"/>
	<path class="st1075" d="M282,321.7c-0.2-3.2,1.2-5.6,3.9-7.2C285.3,317.3,284.5,320,282,321.7z"/>
	<path class="st1076" d="M286,313.6c0.7-3,1.4-6,4-8.1C290.5,309.2,288.4,311.5,286,313.6z"/>
	<path class="st1077" d="M310.8,53.9c-1.9,2-4.4,2.8-6.8,3.8c-0.6-0.4-0.6-0.7,0-1.1C305.6,54,308,53.5,310.8,53.9z"/>
	<path class="st1078" d="M312,286.6c2.3-0.1,3.8-2.3,6.2-2.1c-1.9,2.9-5.4,3.2-8.1,4.8C308.3,286.8,311.3,287.4,312,286.6L312,286.6
		z"/>
	<path class="st1079" d="M280.1,70.8c-0.7,0-1.5,0-2.2-0.1c2.1-2.5,5.2-2.9,8-3.9C284.7,69.3,282,69.5,280.1,70.8z"/>
	<path class="st1050" d="M207.1,110.6c0,0.4,0,0.8,0.1,1.1c-0.8,3.9-3.5,4.7-6.9,4l-0.3-0.4c0.2-2.5,2.9-2.2,4.1-3.7
		C205,111.4,206,111,207.1,110.6z"/>
	<path class="st1080" d="M208.1,345.5c1-0.3,2-0.7,3-1c1.1-0.8,1.8-0.8,1.8,0.8c-2.6,0.5-4.1,3.2-6.9,3.3c-0.3-0.3-0.4-0.5-0.3-0.8
		c0.1-0.3,0.1-0.4,0.2-0.4C206.7,346.7,207.4,346.1,208.1,345.5z"/>
	<path class="st1081" d="M147.2,164.8c0-0.4-0.1-0.8-0.1-1.2c-0.1-3.2,2-5.1,4-7.1C150.6,159.6,149,162.2,147.2,164.8z"/>
	<path class="st1082" d="M334.9,273.6c1.8-2.5,3.5-5.2,6.9-5.9C339.9,270.1,338.4,273,334.9,273.6z"/>
	<path class="st1054" d="M312,286.6c-0.4,1.1-2.2,1.2-1.9,2.8c-0.3,0.1-0.7,0.1-1,0.2c-1.4,0.3-2.8,0.7-4.2,1
		C306.5,287.8,308.9,286.4,312,286.6z"/>
	<path class="st1083" d="M265,354.4c1-2,2-3.9,3-5.9c0.3-0.5,0.6-0.5,0.9,0c-0.5,2.8-1.2,5.4-3.9,6.9c-0.3-0.2-0.4-0.4-0.3-0.7
		C264.8,354.5,264.9,354.4,265,354.4z"/>
	<path class="st1084" d="M213,345.3c-0.6-0.3-1.2-0.5-1.8-0.8c2.3-1.1,4.2-3.6,7.2-1.9C216.6,343.5,214.8,344.4,213,345.3z"/>
	<path class="st1085" d="M198.1,115.6c-2.3,1.1-4.2,3.1-7,3c1.9-2,3.7-4.3,7-3.8c0.4,0.2,0.5,0.3,0.4,0.5
		C198.3,115.6,198.2,115.6,198.1,115.6z"/>
	<path class="st1086" d="M361,239.8c-1-2.6,1.5-4.1,2-6.2c0.4-0.3,0.8-0.3,1.1,0C363.8,236.1,363.1,238.3,361,239.8z"/>
	<path class="st1087" d="M295,62.8c-2.1,1.5-4,3.3-6.9,2.9c1.7-1.5,3.3-3.5,6-2.9C294.4,62.4,294.6,62.4,295,62.8z"/>
	<path class="st1088" d="M274,336.4c0.3,0,0.6,0,0.9,0c0.2,2.4-1,4-2.8,5.2C270.8,339.2,273.2,338.1,274,336.4z"/>
	<path class="st1089" d="M193.2,355.4c0.5-2.7,2.8-3.2,4.9-4c0.3-0.3,0.7-0.4,1,0C197.7,353.6,195.5,354.6,193.2,355.4z"/>
	<path class="st1090" d="M291,303.8c-0.3-2.9,1.1-4.6,3.9-5.2C294.1,300.7,293.1,302.6,291,303.8z"/>
	<path class="st1091" d="M350,257.9c-1.2-3,1.4-3.9,2.8-5.5c0.6,0.4,0.6,0.9,0.1,1.4C352,255.2,351.6,256.9,350,257.9z"/>
	<path class="st1092" d="M226.4,345.3c-0.5,0.1-1,0.2-1.5,0.2c-2.2-0.1-3.3-2-4.9-3.1c1.4,0.2,2.8,0.5,4.2,0.7
		C225.6,343.1,226.3,343.9,226.4,345.3z"/>
	<path class="st1093" d="M295,62.8c0,0-0.4,0-0.4,0s-0.4,0-0.4,0c0.9-2.2,2.5-3.4,4.9-3c0.3-0.4,0.6-0.4,0.9,0
		C298.3,60.8,296.6,61.8,295,62.8z"/>
	<path class="st1094" d="M271,75.8c-1.8,1.4-3.6,2.9-6,2.9C266.1,76.1,268.3,75.4,271,75.8z"/>
	<path class="st1095" d="M263.9,80c-1.9,1.2-3.6,2.6-6,2.8C259.2,80.2,261.2,79.3,263.9,80z"/>
	<path class="st1096" d="M230.8,97.9c-1.9,1.6-3.9,3.2-6.7,2.8C226,99,228.1,97.6,230.8,97.9z"/>
	<path class="st1097" d="M198.1,115.6c0-0.2,0-0.5,0-0.7c1.8-1.3,3.2-3.3,5.9-3.1c-0.5,2.1-3.1,2-4.1,3.7
		C199.3,115.5,198.7,115.6,198.1,115.6z"/>
	<path class="st1098" d="M240,354.6c0,0.3,0,0.6,0,0.9c-2.4,0.2-4-1-5-3.1c0.4,0,0.8-0.1,1.2-0.1c0.5-0.5,1.1-0.5,1.7-0.3
		c0.6,0.3,0.7,1,1.1,1.5C239.4,353.9,239.7,354.2,240,354.6z"/>
	<path class="st1099" d="M265,354.4c0,0.3,0,0.7,0,1c-0.1,2.1-1.1,3.8-2.9,5C261.6,357.6,263.9,356.3,265,354.4z"/>
	<path class="st1100" d="M168,353.5c0.8-0.3,1.1-1.1,1.2-1.9c1,0.9,2,1.9,2.9,2.8c0,0.4,0,0.8,0,1.1
		C170.4,355.6,168.8,355.3,168,353.5z"/>
	<path class="st1101" d="M330,276.6c1.5-1.3,2.8-3,5-3c-1.3,1.8-2.4,3.9-5,4.1c-0.3-0.2-0.4-0.5-0.3-0.7
		C329.8,276.7,329.9,276.6,330,276.6z"/>
	<path class="st1102" d="M300,59.8c-0.3,0-0.6,0-0.9,0c0.2-3.2,3.3-2.1,4.9-3.2c0,0.4,0,0.7,0,1.1C302.6,58.4,301.3,59.1,300,59.8z"
		/>
	<path class="st1103" d="M169.2,134.7c-0.4,0-0.8,0-1.2-0.1c1.5-1.6,2.4-4,5.2-3.9c0,0,0,0,0,0C173.4,133.5,171.9,134.7,169.2,134.7
		z"/>
	<path class="st1104" d="M206.1,347.3c0,0.4,0.1,0.8,0.1,1.3c-1.1,1.1-2.3,1.9-4,1.7c-0.4-0.2-0.5-0.4-0.4-0.6
		c0.1-0.2,0.2-0.4,0.3-0.4C203.2,348.3,204.5,347.6,206.1,347.3z"/>
	<path class="st1105" d="M353,253.8c0-0.5-0.1-0.9-0.1-1.4c1-1.3,2-2.7,3-4c0.6,0.4,0.6,0.8,0.1,1.3
		C356.1,251.8,354.3,252.6,353,253.8z"/>
	<path class="st1106" d="M364.1,233.6c-0.4,0-0.8,0-1.1,0c0.2-1,0.4-2,0.6-3c0.2-1,0.4-1.9,0.6-2.9c1.6,0.1,1.2,1,0.7,2
		C364.7,231,365.6,232.6,364.1,233.6z"/>
	<path class="st1107" d="M189,119.8c-0.9,2.3-2.8,2.7-4.9,2.9C184.4,119.6,187.2,120.5,189,119.8z"/>
	<path class="st1108" d="M158.1,147.6c-0.6,0-1.2,0-1.7,0c1.3-1.3,1.3-3.8,3.8-3.8c0,0-0.1,0-0.1,0c0.6,0.5,0.7,1.1,0.6,1.9
		C160.2,146.7,159.4,147.5,158.1,147.6z"/>
	<path class="st1109" d="M330,276.6c0,0.4,0,0.7,0,1.1c-1,2.1-3,2.7-5,3c0.3-1.7,2.3-1.7,3-3.1C328.6,277.2,329.3,276.9,330,276.6z"
		/>
	<path class="st1064" d="M152.1,342.6c-2.8,0.1-4.1-1.2-4-4c0,0,0.1-0.1,0.1-0.1c2.6,0.1,3.8,1.5,4,4L152.1,342.6z"/>
	<path class="st1110" d="M300.9,293.4c0.4,0,0.8,0.1,1.2,0.1c-1,2.2-2.7,3.2-5.1,3.2C297.4,294.7,299.5,294.4,300.9,293.4z"/>
	<path class="st1111" d="M348.8,56.8c0.4,0,0.7,0,1.1,0c2-1.8,2.7,0.7,4,1.2c0.3,0.2,0.4,0.3,0.3,0.5c-0.1,0.2-0.2,0.2-0.3,0.2
		C352.1,58.4,350.3,58,348.8,56.8z"/>
	<path class="st1109" d="M320,283.7c0.9-2.3,3-2.8,5.1-3.2C324.1,282.7,322,283.1,320,283.7z"/>
	<path class="st1112" d="M364.9,229.6c-0.2-0.7-0.5-1.3-0.7-2c0.3-1,0.6-2,1-3c0.5,0.3,1.1,0.6,1.6,0.9
		C365.8,226.7,366.5,228.7,364.9,229.6z"/>
	<path class="st1113" d="M247.1,88.7c-0.8,1.8-2.5,2.2-4.1,2.8c-0.3-0.2-0.4-0.4-0.3-0.6c0.1-0.2,0.2-0.3,0.3-0.3
		C243.7,88.6,245.4,88.6,247.1,88.7z"/>
	<path class="st1114" d="M184.1,122.7c0,1.5-1.6,1.7-2.2,2.8c-0.5,0.9-1.2,0.9-1.9,0.3c0,0,0.1,0.1,0.1,0.1
		C180.9,124,182,122.7,184.1,122.7z"/>
	<path class="st1115" d="M243,90.6c0,0.3,0,0.5,0,0.8c-0.9,0.5-1.8,0.9-2.8,1.4c-0.5,0.3-0.9,0.3-1.3-0.1
		C239.7,90.7,241.3,90.6,243,90.6z"/>
	<path class="st1116" d="M357.9,245.6c0-1.6,0.5-3,1.9-3.9C359.6,243.1,361.2,245.6,357.9,245.6z"/>
	<path class="st1117" d="M356,249.7c0-0.4-0.1-0.8-0.1-1.3c0.3-1.3,0.7-2.4,2.1-2.9C358,247.3,357.9,248.9,356,249.7z"/>
	<path class="st1118" d="M202,349.3c0,0.3,0.1,0.7,0.1,1c-0.9,0.6-1.8,1.4-3.1,1.1c0,0-0.5,0-0.5,0s-0.5,0-0.5,0
		C199.1,350.2,200.5,349.5,202,349.3z"/>
	<path class="st1119" d="M268.9,348.5l-0.5,0c0,0-0.5,0-0.5,0c0.2-1.6,0.7-3,2.1-4C270.1,345.9,270,347.3,268.9,348.5z"/>
	<path class="st1120" d="M279.9,326.5c-0.3,0-0.6,0-0.9,0c0.3-1.6,0.7-3,2.1-4C281.1,323.9,280.9,325.4,279.9,326.5z"/>
	<path class="st1121" d="M236.1,94.7c-0.9,1.5-2.3,2-4,2C232.7,94.6,234.4,94.7,236.1,94.7z"/>
	<path class="st1122" d="M239,92.7c0.4,0,0.8,0.1,1.3,0.1c-1.2,1-2.5,2-4.2,1.9C236.5,93.3,237.4,92.6,239,92.7z"/>
	<path class="st1123" d="M250.8,86.9c-0.9,1.3-2.2,1.9-3.8,1.9C247.6,86.8,249,86.5,250.8,86.9z"/>
	<path class="st1124" d="M353.9,58.7c0-0.2,0-0.5,0-0.7c1.5-0.3,3.1-0.7,3,1.8C355.7,60.1,354.9,59.3,353.9,58.7z"/>
	<path class="st1125" d="M314.9,50.8c0,2.2-1.5,2-2.9,1.9C312.6,51.3,313.4,50.6,314.9,50.8z"/>
	<path class="st1126" d="M200.2,115.8c2.6-0.9,5.3-1.6,6.9-4c3.6,1.4,6-1.5,8.4-3c11.6-7.2,24.4-12.2,35.9-19.5
		c13.3-8.3,28-14.1,40.9-23.2c2.6-1.8,6.3-0.8,8.6-3.4c4.7-0.2,7.7,2,8.2,6.2c0.2,1.8,0.5,3.6,0.1,5.4c-0.4,0.9-1.1,1.1-1.9,1
		c-1.7-0.2-2.4-1.6-3.5-2.7c-0.2-3.4-2-4.5-5.3-3.6c-1.9,0.5-3.7,1.3-5.8,0.7c1.2,0.3,2.2,0.9,3.5,0.5c3.2-1,5.7,0.2,7.8,2.7
		c1.1,1.5,1.6,3.1,0.9,4.9c-10.1,6.9-20.9,12.3-31.6,18.1c-7.8,4.2-15.4,8.9-23.2,13.3c-8.2,4.6-16.5,9.1-24.8,13.5
		c-5.9,2.8-11.9,5.3-17.2,9.2c-1.2,0.9-2.2,2-3.9,2c-0.9-1-1.7-1.6-3.3-0.8c-3.7,1.9-5-0.3-5.8-3.6c-0.7-3.3,0.8-6.6-0.1-9.9
		C196.4,117.9,199.2,118,200.2,115.8z"/>
	<path class="st1127" d="M225.1,121.7c8.3-5.2,17.2-9.1,25.6-14.3c5.5-3.4,11.5-6.2,17.2-9.4c9.8-5.5,19.8-10.7,29.7-16
		c2.5-1.3,3.8-4.9,7.4-4.2c0.6-1,0.3-2.7,2-3c0.7,0,1.3,0,2,0c5.7-4.6,12.5-7.3,18.8-10.8c3.4-1.9,6.3-4.9,10.5-5.2
		c0.3,0,0.7-0.1,1-0.2c-0.6-0.1-1.2,0.1-1.8-0.2c-2.2-1.4-2.9-2.7,0.4-3.6c6.6,2.7,13.2,5.2,19.7,8c3.4,1.5,4.4,5.1,6.2,8
		c-1.1,1-2.5,2,0,3c3.8,2.6,2.7,6.9,2.8,10.2c0.3,26.8,0.2,53.5,0.2,80.3c0,3.1-1.4,6.2-1,9.4c-3.4-1.7-1.3,3.1-4.2,2.7
		c-2.1-4.3-1.7-9.2-0.4-13.9c1.7-6.5,1.7-13,0.1-19.5c-0.4-1.5-0.7-2.9-0.2-4.4c2.8-7.8,1.8-16,3.1-24c1.4-1.5,0.8-3.4,1-5.1
		c-0.3,1.3,0.2,2.7-0.4,4c-0.8,0.8-0.7,1.8-0.7,2.8c0,1.5,0,3.5-1.9,3.4c-1.8-0.1-1.8-2.2-1.8-3.6c0.2-5.1-1.1-10.3,1.1-15.2
		c0.4-0.8,0.3-1.5-0.1-2.3c-1.6-3.5-1.4-7.1-0.7-10.7c1.3-6.4-1.6-11.6-5-16.5c-0.9-1.4-2.2-2.6-4.2-1.7c-1,0.2-2,0.2-2.9-0.2
		c-2.8-2.1-5.9-1-8.8-0.5c-0.9,0.1-1.9,0.1-2.8,0c-6-0.6-9.7,4-14.4,6.3c-3.5,1.7-6.8,3.6-10.3,5.2c-2.1,1.3-4.2,2.5-6,4.3
		c-1.4,1.2-2.8,2.4-4.6,3c-4.3,1.2-8.1,3.1-11.6,5.8c-5.1,3.1-9.9,6.5-15.3,9.1c-8.3,4.3-16.3,9.4-24.8,13.4
		c-4.3,1.3-8.6,2.6-12.2,5.5c-0.7,0.3-1.4,0.5-2.1,0.7c-3.5,1-6.7,2.6-9.7,4.5c-1.4,0.9-3.3,2.6-4.7,0.4c-1.4-2.1,0-3.7,2.1-4.5
		C224.3,122.3,224.7,122,225.1,121.7z"/>
	<path class="st1128" d="M338,54.8c-1.5,2.6-1.5,2.6,3.5,3.5c-11.4,5.1-21.2,12.3-32.5,16.5c-0.3-0.9-1-1.9-0.9-2.8
		c1-5.7-2.9-7.6-7.1-9.2c2.3-4.3,7.1-4.5,10.9-6.3c5.7-2.7,10.9-7.3,18.1-5.8C333,51.4,336.2,51.6,338,54.8z"/>
	<path class="st1129" d="M364.9,113.7c-0.2,6.9,1.3,14.2-1.2,20.8c-1.8,4.7-0.5,8.7,0.2,13c1.3,7.9-2.1,15.3-2,23c0,1.6,0,3.3,0,6
		c1.8-3.2,0.2-6,1.8-8.5c2.2,1.5,0.2,4.4,2.2,5.7c0,7.3,0,14.7,0,22c-1.4,4.9,0.4,10-1,15c0,1.7,0,3.3,0,5c-0.8,0.3-1-0.5-1.5-0.8
		c-0.1,1.1,0.4,2.7-1.3,2.6c-1.1-0.1-1.4-1.7-1.8-2.8c-1.1-2.7-0.8-5.6-0.8-8.4c0-38.1,0-76.2,0-114.3c0-2.5,0.1-5-0.2-7.5
		c-1.5-3.4-1.3-7.5-4-10.5c-1.4-1.6-3.9-2.4-3.3-5.2c1.2-2.4,2.7-1.5,3.8-0.1c4.6,6,8.5,12.2,6.4,20.5c-0.7,2.8-1.3,6.2,1,9
		c0.4,0.5,0.7,1.8,0.4,2.3c-3.6,5.9-0.6,12.3-2,18.7C365.2,118.2,362.9,114.8,364.9,113.7z"/>
	<path class="st1130" d="M360,82.8c1.6,1.7,0.9,3.8,0.9,5.7c0,39.6,0.1,79.2,0,118.8c0,3.2,0.2,6.2,1.7,9.9c0.2-2.7,0.4-4.7,0.6-7
		c1.5,1.7-0.1,4.1,1.7,5.3c0.6,4.7-2,8.7-3,13c-3.2,4.3-3.6,9.8-6.3,14.4c-2.3,3.9-1.8-0.8-2.7-0.4c-0.3-4.2,3.1-7.4,2.9-11.6
		c2-6.1,2.7-12.3,2.7-18.6c-0.1-21,0-41.9-0.1-62.9c0-19.9,0-39.7,0-59.6C358.5,87.4,357.9,84.8,360,82.8z"/>
	<path class="st1130" d="M364.9,210.6c0.6-5-1.2-10.1,1-15C365.4,200.6,367.1,205.7,364.9,210.6z"/>
	<path class="st1129" d="M363.9,73.8c-0.5,0.4-1,0.7-1.5,1.1c-0.6-1.4-1.2-2.8-0.8-4.4c0-0.1,1.5,0.2,2.3,0.3
		C363.9,71.8,363.9,72.8,363.9,73.8z"/>
	<path class="st1047" d="M367.7,201.7c0.1,3.3,0.2,6.6,0.3,9.9c-0.3,0-0.6,0.1-0.9,0.2C367.2,208.4,366.2,205,367.7,201.7z"/>
	<path class="st1131" d="M239,353.5c-0.3-0.3-0.7-0.7-1-1c-0.1-5.5-5.7-4.6-8.5-7.1c-1.5-1.3-3.8-2.3-5.5-3.9c-0.3-0.3-0.7-0.7-1-1
		c-5.8-4-11-0.6-15.9,1.8c-1.9,0.9-3.1,1.9-5.2,0.7c-1.2-0.7-2.9,0.6-2.3,1.3c4.1,4.6-1.2,3.1-2.5,4.1c-0.7,0.5-1.2,0.1-1.6-0.4
		c-0.5-0.7-1-1.5-1.4-2.2c-0.6-1-1.4-1.9-1.2-3.1c1.6-2.8,4.6-3.4,7.2-4.5c4.1-1,7.7-3.3,11-5.6c4.1-2.8,8.5-3.3,13-3.9
		c4.9-0.6,7.9,3.1,11.4,5.3c4.2,2.6,8.3,5.3,12.4,8.2c2.1,1.3,3.9,3,6.1,4.1c0.4,0.2,0.8,0.4,1.1,0.6c1.3,0.5,2.4,0.4,3.1-0.9
		c1-1.4,1.8-2.9,3.1-4.1c1.3-0.9,1.7-2.3,2.1-3.7c0.2-0.4,0.5-0.7,0.9-1c0.7-0.2,1.3-0.2,1.9,0.4c-0.8,4.8-1.4,9.5-6.2,12.3
		c-1.8,1-3.3,2.1-5.5,1.3c-3.8-1.5-4.2,0.7-3.7,3.6C246.6,355.9,243.2,351.2,239,353.5z"/>
	<path class="st1132" d="M266,337.5c-0.6,0-1.3,0-1.9,0c-0.9-1.6,0-2.9,0.6-4.2c7.3-14,14.8-28,21.9-42.1
		c10.5-6.5,21.1-13.1,32.7-17.7c4.7-2.3,10-3.4,14-7.1c1.3-1.2,3.2-2.2,4.6,0.2c-3.3,4.9-8.2,7.6-13.4,9.9c-1.5,0.7-2.5,1.3-2.6,3
		c0,0,0,0,0,0c-4.4-0.9-6,3.3-9,5c0,0,0,0,0,0c-3.3,1.7-6.7,0.4-11-0.1c3.2,3.2,2.8,4.9-1,5.7c-1.2,0.3-2.4,1.2-3.2,2.2
		c-2.2,2.6-4.8,2.7-7.2,1.1c-1.9,1.2,4.3,7.7-3.3,4.1c-0.1,0-1,1.3-1.1,2c-0.3,2.6-1.9,5-1,7.8c-1.9,0.2-0.5-2.3-2-1.9
		c-1.2,0.3-1.3,1.5-0.9,2.5c1.1,2,2.6,3.9-1.6,4.1c-1.3,0-1.2,1.1,0.1,1.8c1.6,0.8,0.5,2-0.4,1.9c-4.6-0.2-4.6,3.9-6.2,6.5
		C271.1,327,269.7,332.8,266,337.5z"/>
	<path class="st1133" d="M169.1,348.4c-0.5,0.3-1,0.5-1.6,0.8c0,0-0.4,0.2-0.4,0.2c-0.3-0.4-0.6-0.8-0.9-1.2c-0.5-1.5-1-3-1.3-4.6
		c-0.1-0.9-0.2-1.8-0.2-2.7c0-47.8,0-95.6,0-143.4c0.1-2.2-0.2-4.5,0.4-6.6c0.2-0.6,0.6-1.1,1.2-1.5c0.7,0,1.2,0.3,1.5,0.9
		c1.6,4.5,0.7,9.2,0.8,13.8c0.1,7.1,0.1,14.2,0,21.4c-0.3,6.2,0.1,12.5,0,18.7c-0.3,3-0.1,6-0.1,9c0.2,7.9-0.1,15.9,0.9,23.8
		c0.8,6.1-1.2,12.2-0.9,18.3c0.3,7.6,0.6,15.2,2.5,22.6c0.7,2.6,0.6,5.3-0.1,7.9c-1.7,6.4,0.1,12.4,1.4,18.5c0.2,0.5,0.3,1,0.3,1.6
		C172.2,347.7,170.8,348.3,169.1,348.4z"/>
	<path class="st1134" d="M172.1,346.5c0-0.3,0-0.7,0-1c1.6,0.9,1.7-1.5,3-1.4c0.9,0.1,1.8,0.4,2.6,0.7c4.1,1.2,8,1,11.8-1
		c1.1-0.6,2.3-1.4,3.6-0.3c0.7,0.7,1.3,1.3,2,2c-0.9,0.7-1.8,0.8-2.3,0.4c0.9,0.3,2.2,0.7,3.3,1.6c0.3,0.3,0.7,0.7,1,1
		c-3.9,4.1-9.1,5.5-14.4,6.6c-4.9,1.1-8.3-0.7-10.6-5.6C172.3,348.5,171.6,347.5,172.1,346.5z"/>
	<path class="st1135" d="M172.1,346.5c0.2,1,3.8,2,0,3c-1.1-0.1-2.3,0.2-3-1C170.1,347.8,171.1,347.1,172.1,346.5z"/>
	<path class="st1136" d="M142.2,328.4c-0.3-2.7-0.7-5.3-1-8c0-37.3,0-74.6,0-111.9c0.6-7-1-14,0.9-21c2,1.8,1.5,4.3,1.5,6.6
		c0.1,16.7,0,33.5,0,50.2c0.1,21.9-0.2,43.8,0.2,65.7c0,2.2,0.1,4.3,0.4,6.4c0.3,1.7,0.3,3.5,0.4,5.2c-0.5,2.9,2,5.6,0.9,8.6
		c-0.4,0.5-0.9,0.5-1.4,0.2C143.5,329.8,142.8,329.1,142.2,328.4z"/>
	<path class="st1137" d="M337.9,266.5c-1.3-0.8-2.7-0.6-3.6,0.4c-3.8,4.4-9.6,5.1-14.4,7.6c-1.3-2.8,1.3-3.2,2.7-4.2
		c3.1-2.1,6.9-3,9.2-6.3c4.8-3.9,9.4-8,13-13.1c2.2-3.2,4.5-6.4,6-10c2.1-2.7,1.9-7,5.9-8.4c-0.2,3.8-2,7-3.9,10.1
		c1.1,6.6-2.9,10.8-6.9,14.9c-0.9,1-2,1.6-2.1,3.1C340.7,261.3,339.2,263.8,337.9,266.5z"/>
	<path class="st1138" d="M166.2,189.7c0,0.3,0,0.6-0.1,0.9c-3,2.5-5.6,0.1-7.3-1.5c-4.4-4.1-9.1-7.3-15-8.6c-0.1-1-0.3-2,0.3-2.9
		c0.1-1.6,0.1-3.2,0.2-4.8c1.3-1.5,1.1-3.9,2.8-5.2c0.4,0,0.7,0,1.1,0c-0.3,4.5,2.3,5.8,6.2,7.4c5,2.1,11.4,3.3,13.2,9.9
		c0.1,0.4,0.1,0.9,0.1,1.3C167.2,187.5,166.3,188.4,166.2,189.7z"/>
	<path class="st1139" d="M144.1,177.6c0,1,0.1,2,0.1,3c0.3,0.5,0.5,1.1,0.5,1.7c-0.6,12.1,0.8,24.2,0.5,36.2
		c-0.1,4.9-0.6,9.9-0.6,14.9c-0.2,3.6,0.6,7.2-0.8,10.7c-0.4,0.6-0.9,0.8-1.5,0.5c-0.1-19-0.1-38-0.2-56.9
		C142.4,184.2,141.7,180.6,144.1,177.6z"/>
	<path class="st1140" d="M195.1,119.7c1.6,0.3,3.2,0.5,1.8,2.7c-1.2,1.8-0.6,3.8-0.6,5.7c0,4.1,1,6,5.3,3.6c1.9-1.1,2.7-0.1,2.5,1.9
		c-2.7,3.5-5.8,6.4-9.9,8.3c-4.8-1.1-7.6-5.4-11.8-7.6c-1.4-1.1-2.6-2.4-3.5-4c-0.2-0.4-0.2-0.9-0.2-1.3c0.9-1.2,1.3-2.9,3.4-2.4
		c3.5-0.3,6.2-2.2,9-4C192.5,121.7,193.9,120.9,195.1,119.7L195.1,119.7z"/>
	<path class="st1141" d="M182.1,126.7c-1.3,0.7-2.3,1.7-3,3c-1,0.9-2,0.9-3.1,0c1.4-1.3,2.7-2.7,4.1-4c0.6-0.1,1.3-0.2,1.9-0.3
		C182,125.8,182.1,126.3,182.1,126.7z"/>
	<path class="st1126" d="M195.1,119.7c-0.3,2.3-2.2,2.6-4,3C192,121.1,193.1,119.8,195.1,119.7z"/>
	<path class="st1142" d="M167.2,185.6c-1.9-1.2-1.6-3.8-3.3-5.7c-0.6,1.4-1,2.3-1.4,3.3c-0.5-0.3-1-0.4-1-0.6
		c-0.3-5.2-4.9-5.8-8.2-6.8c-6.8-2.2-6.9-1.8-5.1-8.2c0.1-1.6,0.7-3,2-4c1.8,1.5,0.7,3.4,0.5,5.2c-0.4,0.5,0.7,0.3,0.1,0.1
		c-0.2-0.1-0.1-0.2,0.1-0.5c1.3-2.4,3.9-2.4,5-1c3.7,4.8,9.8,6.2,13.9,10.4C170.4,180.9,170.8,183.9,167.2,185.6z"/>
	<path class="st1143" d="M154.2,155.7c1.3-2,2.6-4,3.9-6c2-0.4,3.2,0.8,4.1,2.1c2.8,4.2,6.8,6.1,11.6,6.9c1.5,0.2,2.9,0.7,3.8,2.1
		c0.2,4.5-1.3,8.3-5,11.1c-1.6-1.4-1.1-3.9-4.1-5.2c-1.9-0.8-4.1-2.8-6.3-4.1C159.2,160.9,154.2,160.9,154.2,155.7z"/>
	<path class="st1144" d="M169.2,177.7c-4.7-1.8-9.6-3.7-12.6-7.8c-2.4-3.4-4.1-2.5-6.5,0c0-2.4,0-4.3,0-6.3c0.2-1.6,0.7-3,2-3.9
		c1.8,1.2,3.7,2.1,5.7,2.7c3.5,1.1,6.7,2.5,8.3,6.3c0.6,1.5,1.5,3.5,4.2,2.7c1-0.3,1.3,1.1,1.4,2.2
		C171.3,175.2,171.7,177.3,169.2,177.7z"/>
	<path class="st1145" d="M177.1,160.7c-8.3-0.3-14.1-4.9-19-11c0-0.7,0-1.4,0-2.1c0.6-0.7,1.2-1.3,1.8-2c0.4-0.2,0.8-0.5,1.2-0.7
		c3.1-0.7,5.7-0.5,5.9,3.6c0.1,1.5,0.8,1.8,2.3,2.3c4,1.4,8.8,2.2,10.2,7.4C179.3,159.6,178.5,160.3,177.1,160.7z"/>
	<path class="st1146" d="M171.1,173.6c-0.7-2.6-3.9-0.1-4.9-2.5c-2.4-5.5-7.1-7.7-12.7-8.6c-1.5-0.2-1.3-1.7-1.3-2.8
		c0.7-1.3,1.3-2.7,2-4c3.7,3.1,7.2,6.9,12.6,6.6c0.7,0,1.8-0.8,1.6,0.7c-1,6.6,2.8,3.3,5.5,2.3c-0.3,2.4-2.1,4.1-1.7,6.4
		C172.7,172.8,172.9,173.7,171.1,173.6z"/>
	<path class="st1147" d="M224.1,341.4c2.6,0,4.2,4.2,7.3,1.7c0.3,4.8,4.7,3.1,7.1,4.5c2.6,1.5-1.7,3.1-0.5,4.9
		c-0.6,0-1.2-0.1-1.8-0.1c-2.6-3.3-6.1-5.4-9.9-7c-0.7-0.7-1.5-1.4-2.2-2.1C224.1,342.6,224.1,342,224.1,341.4z"/>
	<path class="st1048" d="M313,284.5c0.8-2.2,2.9-3.6,3.8-6c1.1-2.9,3.3,1.2,5.2,1.1C319.2,281.6,316.2,283.3,313,284.5z"/>
	<path class="st1148" d="M144.1,330.5c0.4,0,0.7,0,1.1,0c1.6,0.4,2.4,1.8,3.2,3c2.3,3.6,5.4,6.1,9.2,8c1.1,1.6,3.3,2.3,3.8,4.4
		c-0.1,0.2-0.2,0.4-0.2,0.6c-0.7,0-1.3,0-2,0c-2.7-0.7-4.1-3.6-7-4c-1.3-1.3-2.7-2.7-4-4C146.8,335.8,144.1,333.9,144.1,330.5z"/>
	<path class="st1148" d="M167.1,348.4c0,0.3,0,0.7,0.1,1c-2.3-0.4-4.3-1.4-6-3c0,0,0-0.1,0-0.1C163.9,344.7,165.7,346.1,167.1,348.4
		z"/>
	<path class="st1149" d="M161.2,145.7c-0.4,0-0.9,0-1.3-0.1c0.1-0.6,0.2-1.3,0.2-1.9c3-2.7,5.9-5.4,8.9-8c1.1-0.5,1.9-1.6,3.3-1
		c0.4,0.6,0.5,1.3,0.2,2c-2.4,1.6-4.5,3.6-6.6,5.6C164.5,143.6,162.9,144.7,161.2,145.7z"/>
	<path class="st1141" d="M172.1,134.7c-1,0.3-2.1,0.6-3.1,1c0.1-0.3,0.1-0.6,0.2-0.9c1.5-1.1,3.3-2.1,3.9-4c0.6-0.1,1.3-0.2,1.9-0.3
		c1,1.6-0.3,2.4-1.1,3.5C173.4,134.3,172.8,134.6,172.1,134.7z"/>
	<path class="st1149" d="M173.1,133.7c0.6-1.1,1.3-2.2,1.9-3.3c0.3-0.2,0.7-0.5,1-0.7c1,0,2,0,3.1,0c0,0,0.1,0,0.1,0
		C178.2,132.7,176.6,134.6,173.1,133.7z"/>
	<path class="st1150" d="M194.1,141.7c3.3-2.7,5.9-6.3,10-8c6.2-5.4,13.4-8.9,21-12c-0.5,1.8-4.3,2.6-2.7,4.6c1.7,2,3.5-1.1,5.2-1.9
		c2.7-1.5,5.5-2.7,8.5-3.6c0.5,1.7-0.7,2.6-1.8,3.1c-4.7,2.2-8.7,5.6-13.7,7c-10.4,3.3-18,10.7-26.4,17c-2.2,1.6-4.4,5.1-6.8,0.2
		c0-0.5,0.1-1,0.3-1.4C189.7,144.9,190.6,141.6,194.1,141.7z"/>
	<path class="st1128" d="M304,72.8c-4-2.5-8.8-0.7-13.3-2.4c3.8-2.9,8.3-2.9,12.2-3C306.3,67.3,304.8,70.6,304,72.8L304,72.8z"/>
	<path class="st1128" d="M307,74.8c-0.7,1-1.3,2-2,3c-0.9-1.5-1.1-3.2-1-5c0,0,0,0,0,0C305.4,72.8,306.4,73.4,307,74.8z"/>
	<path class="st1151" d="M250.2,114.8c8.2-4.4,16.4-8.8,24.6-13.2c0.7-0.4,1.4-0.4,2.1-0.2c0.6,2.6-1.7,2.8-3.1,3.7
		c-8.3,5.2-17.2,9.3-25.1,15.1c-0.8,0.6-1.6,1.2-2.4,1.6c-6.5,3.5-13.3,6.6-19.7,10.3c-1.9,1.1-3.9,2.7-6.4,1.8
		c-0.8-1-0.3-1.9,0.1-2.8c5.6-3.6,11.6-6.6,17.5-9.9C241.7,118.7,246.1,117,250.2,114.8z"/>
	<path class="st1152" d="M302,86.7c1.4-0.9,2.7-1.9,4.1-2.8c2.6-1.4,5.3-2.8,7.7-4.5c1.9-0.7,3.7-1.7,5.6-2.6
		c1.9-0.5,3.8-1.1,5.1-2.7c1.9-1,3.6-2.5,5.8-3.1c1.4-0.3,2.9-0.7,4.1-1.6c0.4-0.2,0.8-0.4,1.2-0.5c0.9-0.2,1.8-0.1,2.8-0.1
		c0.7,0.1,1.4,0.4,1.8,1.1c-0.4,2.1-2.6,1.9-3.9,2.8c-8,3.4-15,8.7-23,12c-3.8,0.7-6.7,4.7-11.2,3.3C301.7,87.7,301.6,87.2,302,86.7
		z"/>
	<path class="st1153" d="M351.9,68.7c1.3,1.3,2.6,2.7,4,4c1.6,4.9,5,9.6,2.7,15.2c-1.1,0.2-1.8-0.7-2.5-1.3
		c-0.3-0.3-0.6-0.7-0.8-1.2c-0.6-1.5-0.6-3.2-1.2-4.8c-0.4-4.5-4.6-7-5.7-11.1c0.1-0.5,0.3-0.9,0.7-1.2
		C350,68.5,351,68.6,351.9,68.7z"/>
	<path class="st1154" d="M277,101.7c-0.7,0-1.5-0.1-2.2-0.1c5.1-3,10.1-6,15.2-9c3.8-1.6,7-4.4,11.2-4.9c1,2.7-1,3.5-2.8,4.3
		c-4.2,2-8.1,4.5-12.3,6.4c-1.8,0.9-3.7,1.7-5.4,2.9c-0.6,0.4-1.1,0.7-1.8,1C278.2,102.4,277.5,102.3,277,101.7z"/>
	<path class="st1155" d="M330.7,71.6c-1.9,1.1-3.8,2.2-5.7,3.3c-0.5,0.4-1.1,0.5-1.8,0.2c-0.1-0.2-0.2-0.3-0.4-0.5
		c4.2-3.5,8.1-7.6,14.3-6.7c0.1,1.7-1.6,1.1-2.3,1.8l0,0C333.8,70.9,332.2,71.2,330.7,71.6z"/>
	<path class="st1156" d="M238.1,121.7c-5.7,3.3-11.4,6.5-17.1,9.8c-0.4,0.8-1.1,0.8-1.8,0.5c-0.8-1.2,0.2-1.7,0.9-2.3
		c5.7-2.3,11.2-5.1,16-9c0.7,0,1.3,0,2,0c0.3,0.2,0.3,0.5,0.3,0.7C238.2,121.6,238.1,121.7,238.1,121.7z"/>
	<path class="st1157" d="M349.1,68.4c-0.1,0.4-0.1,0.9-0.2,1.3c-3,1.3-6,1.8-9,0c-0.7,0-1.4,0-2.1-0.1c-0.5-1.9,1.5-1.1,2-1.9
		C343,67.5,346.2,65.2,349.1,68.4z"/>
	<path class="st1158" d="M238.1,121.7c0-0.3,0-0.7,0-1c3-4,7.8-4.5,12.2-5.9C246.5,117.6,242.5,120.1,238.1,121.7z"/>
	<path class="st1159" d="M301,87.8c-4,1-6.9,4.3-11.1,4.8c3.4-3.1,7.4-5.2,12-5.9c-0.1,0.3-0.2,0.6-0.3,0.9
		C301.5,87.7,301.3,87.7,301,87.8z"/>
	<path class="st1155" d="M319.6,77.6c-1.8,0.8-3.6,1.5-5.4,2.3c-0.8,0.4-1.5,0.5-2.2-0.1c3.5-2,6.8-4.5,10.9-5.1
		c0.1,0.1,0.2,0.3,0.3,0.4C322.6,76.8,321,77,319.6,77.6z"/>
	<path class="st1129" d="M365.6,82.8c0,3.9,0,7.7,0,11.6c-0.1,0-0.3,0-0.4,0c0-3.9,0-7.8,0-11.6C365.4,82.8,365.5,82.8,365.6,82.8z"
		/>
	<path class="st1159" d="M312,79.8c0.7,0,1.5,0.1,2.2,0.1c-2.3,2.1-5.1,3.4-8.1,4C307.6,81.9,309.8,80.9,312,79.8z"/>
	<path class="st1160" d="M339.9,67.8c-0.7,0.6-1.4,1.3-2,1.9c-1,0-2,0-3,0c0.8-0.6,1.5-1.2,2.3-1.8C338.1,67.9,339,67.9,339.9,67.8z
		"/>
	<path class="st1129" d="M365,113.7c0-1.6,0-3.2,0-4.8C365.8,110.5,366.4,112.2,365,113.7z"/>
	<path class="st1129" d="M364.8,159.9c0.2-2.8,0.2-4,0.4-6.1C366.1,156,366.2,157.4,364.8,159.9z"/>
	<path class="st1161" d="M358,87.8c1.9-2.5,0.7-5,0-7.5c-0.7-2.5-1.4-5-2-7.5c3.7,2.4,2.7,6.7,4.1,10c0,22.3-0.1,44.6-0.1,66.9
		c-0.6,0.3-1.2,0.1-1.5-0.5c-1.5-3.8-0.6-7.7-0.8-11.6c-0.1-2.8,0.2-5.5-0.8-8.2c-0.6-6.9-0.1-13.8-0.3-20.7c0-5.1,0-10.2,0-15.3
		C356.6,91.4,356.3,89.3,358,87.8z"/>
	<path class="st1162" d="M359,149.8c0.3-0.1,0.6-0.1,0.9-0.1c0,23.3,0.1,46.5,0,69.8c0,4.5-1.8,8.7-3,13c-1.7,3-3.3,6-5,9.1
		c-1.2,0.4-1.8-0.2-2.3-1.2c0.9-3.9,4.4-6.8,4.2-11.1c0.2-1.5,0.8-2.9,1.6-4.2c2-2.7,2.2-5.8,2.1-9c0-19.7,0-39.5,0-59.2
		C357.5,154.4,356.9,151.8,359,149.8z"/>
	<path class="st1163" d="M287,291.5c-2.7,10-9.2,18.2-13.4,27.5c-2.8,6.3-6.3,12.3-9.5,18.4c-0.1,0.3-0.2,0.6-0.2,1
		c-0.6,1.3-0.7,2.9-2,3.9c-1,1.3-2,2.7-3,4c-1.3,2.1-3,1.9-4.8,1l0,0c-0.3-0.2-0.4-0.4-0.4-0.7c0-0.3,0.1-0.4,0.1-0.4
		c1.7-1.2,3.8-2,4.3-4.5c1.3-2.8,2.1-5.9,4.2-8.4c1.2-1.2,2.3-2.5,2.5-4.2c0.3-1.3,0.7-2.5,1.3-3.7c0.4-0.8,0.8-1.6,1.2-2.4
		c0.7-1.4,1.4-2.8,2.2-4.2c2.5-3.6,3.9-7.9,6.5-11.4c3.4-5.5,6.6-11.1,8.1-17.4c0.7-1.1,1.4-2.3,3.1-1.6
		C287.9,289.6,287.8,290.6,287,291.5z"/>
	<path class="st1164" d="M248.1,343.5c-4.3-2.3-8.4-4.9-12.4-7.8c-2.8-2.1-5.8-5.1-8.9-5.4c-4.3-0.4-9-0.5-13.2,2.2
		c-4.2,2.7-8.4,5.7-13.4,7c-0.5-0.5-0.6-1.1-0.4-1.8c0.2-0.4,0.5-0.7,0.9-0.9c1.4-0.6,2.7-1.5,3.7-2.6c5-3.7,10.5-6.1,16.4-8
		c2.5-0.8,4.9-0.5,7.2,0.5c5.6,2.6,10.5,6.3,15.4,10c1.5,1.4,3.5,2.3,4.5,4.2C248.2,341.7,248.7,342.5,248.1,343.5z"/>
	<path class="st1165" d="M200.1,337.5c0,0.6,0,1.3,0,1.9c-2.4,1.1-5.2,1.6-7,4c-5.1,2.6-10.2,5-16,2.1c-0.2-0.3-0.3-0.5-0.3-0.8
		c0.1-0.3,0.1-0.4,0.2-0.4c0.2-0.1,0.4-0.2,0.6-0.3c0.2,0,0.4,0,0.7,0c1.9,0.3,3.8,0.6,5.6-0.5c1.4-0.8,2.8-1.6,4.3-2.1
		C192.4,340.7,195.4,336.5,200.1,337.5z"/>
	<path class="st1166" d="M248.1,343.5c-0.3-0.7-0.6-1.5-0.9-2.2c0.5-0.5,1.2-0.5,1.8-0.4c1,0.3,1.9,0.9,2.6,1.7
		c0.8,0.9,1.9,1.5,2.6,2.5c0.2,0.4,0.2,0.8,0,1.2c0,0.4-0.1,0.7-0.1,1.1C251.8,346.5,249.7,345.4,248.1,343.5z"/>
	<path class="st1044" d="M196.1,347.4c-1.4-0.4-2.7-0.8-4.3-1.3c1.1-1.2,2.3-0.5,3.3-0.7C195.4,346.1,195.8,346.8,196.1,347.4z"/>
	<path class="st1166" d="M254.1,347.4c1.7,0.2,3.4,0.1,4.9-1C257.9,349.7,256.1,348.7,254.1,347.4z"/>
	<path class="st1166" d="M261.9,342.3c0.3-1.5,0.9-2.9,2-3.9C264.1,340.2,263.9,341.7,261.9,342.3z"/>
	<path class="st1167" d="M287,291.5c0-1,0-1.9,0-2.9c1.4-4.4,5.8-4.8,8.9-6.9c8.1-3.9,15.3-9.3,23.8-12.4c3.1-0.7,5.7-2.4,8.4-3.9
		c1.5-0.9,3-2.2,4.9-0.9c-2,4-6.5,4.6-9.7,6.9c-1.3,0.9-3.2,1-3.3,3.1c-9.5,2.5-16.7,9.8-25.9,13C291.5,288.4,290.4,292.1,287,291.5
		z"/>
	<path class="st1168" d="M220.1,129.7c-0.3,0.6-0.7,1.3-1,1.9c-1,3.2-4,4.1-6.5,5.6c-4.2,2.6-8.8,4.5-12.3,8c-0.6,0.7-1.2,1.4-1.9,2
		c-3.2,2.2-5.9,5-8,8.2c-1,1.3-2.3,2.2-3.5,3.2c-8.4,9.9-12.3,21.4-12.3,34.3c0,38.4,0,76.8,0,115.1c0,2.4,0.5,5-1.1,7.2
		c-3.5-0.1-2.6-2.9-2.7-4.7c-0.3-5-0.3-10-0.1-14.9c0.5-10.6,0.1-21.3-0.8-31.8c-1-12.8,0.7-25.8-2.7-38.3c0-12,0-24,0-36
		c-0.8-1-0.8-2,0-3c0.1-0.3,0.1-0.7,0.1-1c1.4-2.5,1.9-5.2,2.1-8c0.6-1.4,1.3-2.7,1.9-4.1c0.9-0.4,1-1.1,1-1.9c2.6-3.2,4-7,5-11
		c0.7-0.7,1.3-1.4,2-2c0.6-1.7,1.4-3.3,2.6-4.7c2.3-1.9,2.5-6.1,6.4-6.3c2,4.2,3.5,0.7,4.9-0.4c7.6-6.2,15.4-12.3,24.3-16.6
		C218.2,130.2,219.2,130,220.1,129.7z"/>
	<path class="st1135" d="M167.2,225.6c3.5,8.1,4.6,16.6,3.1,25.3c-0.1,0.7-0.2,1.3-0.1,2c2.9,20.2,1.6,40.6,2.1,60.8
		c0,0.6,0.5,1.2,0.8,1.7c1.2,0.9,1.4,2.3,1.2,3.6c-1.1,5.7,1,11.6-0.4,17.3c-0.7,2.9,1.2,5.4,1.3,8.1c-0.8,0.7-1.5,2.1-2.9,1
		c-0.7-0.4-1.9-0.8-1.9-1.1c0.1-4.8-2.9-10-1.5-13.8c3.2-8.6-0.7-16.4-1-24.4c-0.3-7.5-1.1-14.9,0.2-22.3c1-5.5-0.9-10.9-0.8-16.3
		c0.1-4.7,0-9.3,0-14c0.8-3,0.8-6,0-9C167.2,238.3,167.2,231.9,167.2,225.6z"/>
	<path class="st1169" d="M166.1,196.6c0,48.6,0,97.3,0,145.9c-2.8-0.7-2-3.1-2.3-4.9c0.3-5.5-0.6-11-0.7-16.5
		c-0.4-2.6-0.3-5.3-0.3-8c1.5-6.1-0.5-12.2-0.2-18.3c0.4-7.1-0.5-14.3,0.5-21.4c1.5-4.2-0.4-8.2-0.4-12.3c0-19.3-0.2-38.6,0.1-57.9
		c0-2.2-0.1-4.3,0.1-6.5c0.1-1.2,0.2-3,1.8-3C166.1,193.8,165.3,195.8,166.1,196.6z"/>
	<path class="st1170" d="M166.1,196.6c-0.5-0.6-0.9-1.3-2-2.7c0,3.4,0,6.1,0,8.7c-0.4,0.3-0.8,0.3-1.3,0c-5.2-5-8.7-12.2-17.1-12.9
		c-1.4-0.1-2.1-1.1-2-2.3c0.1-1.9-0.5-3.9,0.4-5.8c0-0.3,0-0.7,0-1c5.7-1.4,9.4,2.1,12.9,5.5c2.6,2.5,5.2,4.6,9,4.5
		C166.1,192.6,166.1,194.6,166.1,196.6z"/>
	<path class="st1171" d="M165.1,337.5c0.3,1.7-0.5,3.6,1,5c0,0.7,0,1.3,0,2c-1.4,0.4-2.2-0.5-2.6-1.5c-0.5-1.5-1-2.4-2.7-1.6
		c-1.1-1.8-1.9-3.8-3.2-5.5c-1.2-1.6-1.5-3.3,0.5-4.7c2.6-0.3,5.1-0.1,6.2,2.9C164.9,335.1,164.3,336.4,165.1,337.5z"/>
	<path class="st1172" d="M161.2,341.4c0.9-0.4,1.8-2.7,2.8-0.1c0.5,1.3,0.7,2.5,2.1,3.1c0.6,1.3,1.2,2.5,1,4c-1.9-0.9-3.6-2.2-5.9-2
		c-1.3-1.3-2.7-2.6-4-4C158.3,341.3,159.8,341.8,161.2,341.4z"/>
	<path class="st1168" d="M167.2,244.6c1.6,3,1.6,6,0,9C167.2,250.6,167.2,247.6,167.2,244.6z"/>
	<path class="st1169" d="M167.1,186.6c0,1,0,2,0,3c-0.3,0-0.6,0.1-0.9,0.1C164.6,188.1,164.9,187.1,167.1,186.6z"/>
	<path class="st1173" d="M175.1,344.4c-3.9-2.5-2.9-6.6-2.5-9.9c0.8-6.4-0.7-12.8,0.5-19.1c0-41.9-0.2-83.7,0.2-125.6
		c0.1-12.6,4.4-24.2,13.9-33.2c1.4,2.5-0.7,4.1-1.7,5.8c-5.9,9.7-10.3,19.8-8.9,31.5c0,36.9,0,73.8,0,110.7c0,2.7,0.1,5.3-0.2,8
		c-1.2,10.7-1.7,21.4,0.8,32c0,0.3,0,0.6,0,0.8C176.4,345.2,175.7,344.8,175.1,344.4z"/>
	<path class="st1174" d="M164.1,313.5c0,2.7,0,5.3,0,8c-0.2,0.1-0.4,0.2-0.7,0.3c-0.5-0.2-0.7-0.5-0.8-1c-0.1-1.6,0.2-3.1-0.3-5.6
		c0,0.3-0.6,1.3-0.1,1c0.8,1.4,0.3,2.8,0.3,4.2c-1,2.8-1.9,5.5-5.9,3.2c-1.5-0.8-3.5-0.7-5.3-0.1c-4.9,1.6-6.4,0.8-7.6-4.2
		c-0.2-0.6,0-1.3-0.6-1.8c-1.3-1.2-0.9-2.8-0.9-4.2c0-22.9,0-45.8,0-68.7c0.3,0,0.6,0,0.9,0c2.4,2.7,1.1,6.1,1.5,9.2
		c0.1,12.8,0.1,25.5-0.1,38.3c0.4,4.3-1.4,9,1.6,13c0.9-0.4,0.7-1.1,0.9-1.7c0.4-1.1,0.7-2.4,2.3-2.2c1.4,0.2,1.8,1.5,1.9,2.6
		c0.3,2.5,0.6,4.6,3.8,4.7c1.8,0.1,3.2,0,4-2c0.4-1,0.5-3,2.5-1.6c0,1,0.2,1.9,0,2.9c0.8-3.4-2.5-7.2,1.3-10.8
		C164.5,302.7,163.5,308.2,164.1,313.5z"/>
	<path class="st1175" d="M161.2,341.4c-0.5,3.5-2.7,0.5-4,1c-5.8-2.2-9.2-6.8-12-12c-0.3-2.7-2.1-5.1-2-8c5.2,2.7,10.3,5.4,15,9
		c-1.2,1.7-0.8,3.1,0.8,4.3C160.8,337.2,161.4,339.2,161.2,341.4z"/>
	<path class="st1176" d="M158.2,331.5c-6-1.3-10.6-5-15-9c0-1.7,0-3.3-0.1-5c1,1.7,2,3.4,3.1,5c0.8,1.1,2.3,2.3,3.2,0.8
		c2.2-3.5,6.9-1.7,7.4-1.2c3.2,3,3.6-1.1,5.2-1.6c0.6,0.1,0.7,0.7,1,1l0,0c0.9,4.3,0.9,8.6,1,13
		C162.8,332.2,160.1,332.6,158.2,331.5z"/>
	<path class="st1177" d="M333,264.5c-4.8,1-8.1,5.5-13.2,5.9c0-2.7,2.3-3.5,4.2-4.4c2.7-1.3,5.1-3.1,7.3-5.1c3-1.5,5.3-4,8-5.9
		c1.6-1.3,2.8-3,4.7-3.9c0.7-0.2,1.4-0.1,1.9,0.5C342.4,256.7,337.9,260.8,333,264.5z"/>
	<path class="st1178" d="M345.8,251.5c-0.6,0-1.2,0-1.9,0c-0.3-0.3-0.5-0.7-0.5-1.2c0.1-0.7,0.5-1.3,1-1.8c1.6-1.6,2.9-3.4,3.4-5.6
		c0.4-1.1,0.9-2.3,2.3-2.4c0.6,0.3,1.1,0.6,1.7,0.9C350.5,245.2,348.1,248.3,345.8,251.5z"/>
	<path class="st1179" d="M143.2,253.6c0-3,0-6,0-9c0-3.7,0-7.3,0-11c1-1,1.5-3.5,3.7-1.3c-0.2,4.3,0.5,8.7-0.6,13.4
		c1.9-9.5-0.4-18.6,1.1-27.6c1.7-2.5,1.8-5.3,1.3-8.1c-0.2-1-0.9-1.9-0.6-2.9c0.9-2.6-0.1-6.6,2.8-7.5c3-1,2.5,3.2,4.1,4.7
		c1,0.9,1.7,2.1,3,2.7c2-0.2,0.9-3.2,3.1-3.3c2.1,1.9,1.4,4.5,1.5,6.9c-1.2,9.3-1.2,18.5-0.1,27.8c0.5,4.3,0.6,8.6-1.4,12.7
		c-0.3,0.4-0.7,1-1.1,0.9C154.1,250.4,148.6,251.2,143.2,253.6z"/>
	<path class="st1180" d="M146.2,231.6c-1,0.7-2,1.3-3,2c0-4.9,0.4-9.8,0.5-14.7c0.2-10.7-0.3-21.4-0.5-32c0-1.7-0.7-3.7,1-5.1
		c0,1.8-0.1,3.6,0.1,5.4c0.1,1.1,0.2,3,2.3,0.8c0.7-0.7,4.6,0.3,5.7,1.6c2.9,3.4,5.4,6.9,9.9,8.6c1.7,0.7,0.7,3,0.9,4.6
		c-0.7,2.3,1.6,5.5-2,7c0-1.6,0-3.3,0-5.5c-1.7,2-0.4,4-1.5,5.7c-4.2-2-7.5-4.8-8.5-9.9c-0.4,3.4-2.2,6.1-1.4,9.8
		c0.6,2.3,2.2,5.9-1.7,7.7c-0.7-0.5-0.8-1.3-0.9-1.6C146.4,220.9,148.5,226.4,146.2,231.6z"/>
	<path class="st1181" d="M173.1,133.7c2.7-0.3,4-2.7,6-4c1,1.3,2.8,2.1,3,4c1.8,1.4,3.1,3.4,5,4.7c3.3,2.2,3.8,4.8,0.8,7.7
		c-7,2.7-14.9-0.7-16.9-7.2c0.1-0.8,0.5-1.5,1-2.2c0-0.7,0-1.3,0-2C172.5,134.3,172.8,134,173.1,133.7z"/>
	<path class="st1182" d="M188.1,145.7c0.8-2.7,1.8-5.2-2.4-6.5c-2-0.6-3.2-3.2-3.6-5.5c1,0.2,2.3,0,2.8,0.6c2.5,3.2,6.5,4.4,9.2,7.4
		c-2.5,1.1-3.2,4.3-6,5c-0.3-0.2-0.3-0.4-0.3-0.7C187.9,145.8,188,145.7,188.1,145.7z"/>
	<path class="st1183" d="M182.1,154.7c-0.9,1.4-1.2,3.3-3,4c-1.8-3.9-6.1-4.5-9.1-6.7c-0.4-0.3-4-4.2-4.2-4.8c-0.9-2.5-3-1-4.6-1.4
		c0.9-1.7,2.3-3,4-4c1.9-0.5,2,1.7,3.3,2.1c1.9,1.6,7.5-1.2,5.9,4.8c-0.1,0.3,1-0.1,1.5-0.1C181,148.2,182,149.1,182.1,154.7z"/>
	<path class="st1184" d="M167.1,144.7c-0.7-1-1.3-2-2-3c2.3-1.7,3.1-5.5,7-5c0,0.7,0,1.3,0,2C169.5,139.9,170.5,144.1,167.1,144.7z"
		/>
	<path class="st1185" d="M167.1,144.7c1.9-1.8,0.4-6.4,5-6c1.2,3.8,7.8,7.6,13,7.4c1,0,2-0.2,2.9-0.4c0,0.3,0,0.7,0,1
		c0,0.3,0,0.7,0,1c-2.9,1.6-3.1,5.4-6,7c-1-4.4-3.8-5.8-8.1-5c-0.1,0-0.3-0.1-0.5-0.1C175.1,143.1,168.5,147.3,167.1,144.7z"/>
	<path class="st1186" d="M198,146.4c0.4-0.6,0.8-1.2,1.2-1.7c7-3.6,13.2-8.7,20.8-10.9c9.1-3.6,17.1-9.1,26-13
		c2.6,0.9,4.4-3,7.1-1.2c0.2,0.4,0.4,0.9,0.3,1.4c-1.8,3.3-5.3,4-8.2,5.8c-1.8,1.1-3.6,2.2-5.3,3.4c-10.4,4.8-20.9,9.6-30.4,16
		c-0.4,0.3-0.9,0.5-1.3,0.7c-2.9,1.6-5.7,3.1-8.3,5.1c-1.3,1-2.6,2.1-4.3,0.6C194.3,149.7,196.9,148.3,198,146.4z"/>
	<path class="st1187" d="M253.1,119.7c-2.4-0.4-4.2,3.4-7,1c0.6-0.4,1.3-0.7,1.9-1.1c10.6-5.7,20.8-12.1,31.6-17.4
		c2-1.2,4.2-2.1,6.1-3.4c2.3-1.2,4.3-3.4,7.3-2.4c0.3,0.4,0.4,0.9,0.4,1.4c-0.3,1.4-1.2,2.4-2.4,3c-11.3,5.8-21.9,12.9-33.3,18.3
		C256.2,119.9,254.7,121,253.1,119.7z"/>
	<path class="st1188" d="M280,102.7c-10.6,5.8-20.6,12.7-32,16.9c5.5-5.2,12.6-7.9,18.8-11.8c3.3-2.1,6.8-4.1,10.2-6.1
		c0.7,0,1.3,0,2,0C280.1,101.3,280.2,101.8,280,102.7z"/>
	<path class="st1189" d="M220.1,133.7c-6.8,3.8-13.3,8.4-20.8,10.9c4.3-4.9,10.6-6.8,15.8-10.4c1.3-0.9,2.7-1.8,4.1-2.7
		c0.6,0,1.3-0.1,1.9-0.1C220.7,132.2,220.4,133,220.1,133.7z"/>
	<path class="st1190" d="M293,97.7c0-0.3,0-0.7,0-1c2.8-5.5,8.9-6.5,13.5-9.3c2.1-1.3,4.5-2.1,6.5-3.6c7.8-3.7,14.9-8.8,23-12
		c2.2-0.6,4.4-1.1,6.6-1.4c1.6-0.3,3.8-0.5,4.2,2c0.4,2.3-0.6,3.5-2.8,4.2c-2.8,0.8-5.8,1.3-8.6,2.1c-5.7,1.6-10.3,5.3-15.5,7.6
		c-8,3.6-15.2,8.9-23.4,12c-0.7,0.3-1.5,0.3-2.2,0.2C293.8,98.4,293.3,98.1,293,97.7z"/>
	<path class="st1191" d="M335,77.8c2.7-1.7,6-1.3,8.9-2.4c1.2-0.5,2.6-0.5,2.2-2.6c-0.3-1.8-1.7-1.8-2.7-1.8c-2.4,0-4.8,1.3-7.3,0.9
		c0.8-1.6,3.1-0.4,3.9-2c3,0,6,0,9,0c2.5,3.3,6.2,6,6.1,10.8c0.2,1.8,1.4,3.8-0.8,5.2c-0.1-0.1-0.4-0.2-0.4-0.3
		c-1-6.3-5.8-6.1-10.5-6.4C340.6,78.9,337.5,80,335,77.8z"/>
	<path class="st1192" d="M313,83.8c-1.6,3.5-5.5,3.4-8.2,5.2c-3.9,2.6-8.4,4.4-11.8,7.8c-2.6,0.3-4.6,2-7,3
		c-0.2-0.1-0.4-0.2-0.6-0.2c-0.5-0.5-0.8-1-0.4-1.7c4.3-2.3,8.5-4.7,12.9-6.8c1.6-0.7,3-1.2,3.2-3.2c0.2-0.1,0.4-0.2,0.7-0.2
		C306.1,88.2,308.9,84.2,313,83.8z"/>
	<path class="st1160" d="M319.6,77.6c1.2-0.9,2.4-1.7,3.6-2.6c0.6,0,1.2-0.1,1.8-0.1C323.7,76.8,321.9,77.7,319.6,77.6z"/>
	<path class="st1160" d="M330.7,71.6c1.2-1.1,2.6-1.8,4.2-2C334.2,71.7,332.5,71.9,330.7,71.6z"/>
	<path class="st1193" d="M356,85.7c0.3,0,0.6,0.1,0.9,0.1c1.1,7.7,0.2,15.4,0.5,23.1c-0.2,17.2,0.9,34.3,0.5,51.5
		c-0.1,1.9,1.1,4.5-1.9,5.5c-1.8-1.7-1.4-3.9-1.4-6c-0.1-7.1,0-14.1,0-21.2c0-15.5,0-30.9,0-46.4C354.5,90,354.1,87.5,356,85.7z"/>
	<path class="st1194" d="M356,85.7c-0.1,17.6-0.1,35.3-0.2,52.9c-2.8-0.6-2.1-2.9-2.2-4.7c-0.2-4.1-0.1-8.1-0.1-12.2
		c0-7.7,0-15.5,0-23.2c0.3-4-0.5-8.1-0.1-12.1c0.2-0.2,0.4-0.5,0.6-0.7c1.7-1.4,0.7-3.5,1.1-5.2C356.4,82.1,355.8,84,356,85.7z"/>
	<path class="st1195" d="M357,108.7c0-7.7-0.1-15.3-0.1-23c0.8,0.4,1,1.2,1,2c0,7,0,14-0.1,20.9C357.6,109.2,357.3,109.2,357,108.7z
		"/>
	<path class="st1192" d="M280,102.7c-0.3-0.3-0.7-0.7-1-1c0.3-0.3,0.7-0.7,1-1c2-0.5,3.7-2.4,6-1c0,0,0,0,0,0
		C284.3,101.4,282.3,102.3,280,102.7z"/>
	<path class="st1188" d="M286,99.7c-2,0.3-4,0.7-6,1c1.3-1.6,3.3-2,5-3C285.4,98.4,285.7,99.1,286,99.7z"/>
	<path class="st1196" d="M357,108.7c0.3,0,0.6,0,0.9,0c0,7,0,13.9,0,20.9c0.8,1.6,0.4,3.3,0.4,4.9c0,23.7,0.1,47.4,0,71.2
		c0,4.8-0.1,9.6-1,14.3c-1.1,1.2-1.6,0.2-2.2-0.6c-0.9-1.9-0.6-3.9-0.6-5.9c0-14.5,0-28.9,0-43.4c0.1-1.6,0-3.3,1.4-4.5
		c1.6-1.5,0.9-3.4,0.9-5.2C356.9,143.2,356.9,126,357,108.7z"/>
	<path class="st1197" d="M356.9,220.6c2-30.3,0.6-60.6,1.1-90.9c2,6.6,0.1,13.5,1,20.1c-0.1,22.6-0.1,45.2-0.2,67.8
		c-0.9,0.9,0.3,2.6-1.3,3.3C357.3,220.7,357.1,220.6,356.9,220.6z"/>
	<path class="st1198" d="M350.2,240.6c-0.4,1-0.9,2-1.3,2.9c-0.4,0.3-0.9,0.3-1.3-0.1c-0.4-0.6-0.6-1.3-0.6-2.1c0.6-3.4,3-5.9,4.2-9
		c1.1-1.1,1.5-3.1,3.6-2.9C355.4,234.1,351.9,237,350.2,240.6z"/>
	<path class="st1197" d="M354.9,229.6c-1,1-1.9,2.1-2.9,3.1c-0.6-0.5-0.9-1.2-1-2c0-1,0.4-1.9,1-2.8c1.8-1.9,1.6-4.3,2-6.6
		c0.3-1,0.7-1.9,2-1.8c0.3,0.4,0.7,0.7,1,1.1c0,0,0.1,0.1,0.1,0.1c1.1,2,0,3.5-0.9,5.1C355.7,227,355.3,228.3,354.9,229.6z"/>
	<path class="st1199" d="M356,225.7c0.3-1.7,0.6-3.4,0.9-5.1c0.6-1,1.2-2,1.8-3.1C358.6,220.5,359.1,223.7,356,225.7z"/>
	<path class="st1200" d="M295.9,282.5c-2.8,2.3-6.5,3.3-8.9,6.1c-1,0.3-1.5,1-1.9,2c-2.5,5.4-4.7,10.9-7.8,16
		c-0.6,0.8-1.3,1.5-2.5,0.9c-1.4-4.1,2.3-6.7,3.1-10.2c0.8-2.5,1.9-4.9,3.4-7.1c3.4-5,7.6-8.9,13.7-10.3
		C296.8,280.3,296.3,281.4,295.9,282.5z"/>
	<path class="st1201" d="M268.1,323.5c0,0.8-0.2,1.6-0.9,2c-1.6,1.4-1.9,3.4-2.5,5.3c-0.5,0.8-0.9,1.7-1.7,2.2
		c-1.7,1.9-2.5,4.3-3.4,6.6c-1.2,3-3.2,4.7-6.6,3.3c-1.1-0.8-1.7-1.9-1.2-3.3c5.8-3.5,7.9-9.7,11.2-15.1c1.6,0.1,2.5-1.6,4-1.6
		C267.4,323,267.8,323.2,268.1,323.5z"/>
	<path class="st1202" d="M253.1,342.4c3.5,0.4,5.5-1.1,6.3-4.5c0.5-2.2,1.3-4.4,3.6-5.5c0.3,0.2,0.4,0.5,0.4,0.7
		c-0.1,0.3-0.1,0.4-0.2,0.4c-1.4,3-2.7,6-4.1,8.9c-1.4,1.6-2.2,4.2-5.2,3.1c-1-0.6-2.2-0.9-2.6-2.2
		C251.6,342.7,252.3,342.4,253.1,342.4z"/>
	<path class="st1203" d="M277,306.4c2.6-5.3,4.4-11.1,8.1-15.8c-0.9,6.7-4.8,12.2-8.1,17.9C276.4,307.8,276.5,307.1,277,306.4z"/>
	<path class="st1204" d="M277,306.4c0,0.7,0,1.4,0,2c-3,3.3-3.4,8.2-6.8,11.2c-0.2-0.2-0.4-0.4-0.6-0.5c0.1-4.7,0.1-9.4,5.4-11.7
		C275.7,307.1,276.3,306.7,277,306.4z"/>
	<path class="st1203" d="M263.1,333.5c-0.1-0.4-0.1-0.8-0.2-1.1c0.3-0.7,0.7-1.3,1-2c0.6-0.5,0.9-1.5,2-1
		C265.7,331.3,265.3,333.1,263.1,333.5z"/>
	<path class="st1204" d="M253.8,345.6c2.4,0,3.3-2.3,5.2-3.1c-0.4,2.7-1.9,4.2-4.8,3.8C254.1,346.1,253.9,345.8,253.8,345.6z"/>
	<path class="st1204" d="M268.1,323.5c-0.4,0-0.7,0-1.1,0c-1.1-2.8,0.4-3.9,2.9-4.1c0,0,0.3,0.3,0.3,0.3
		C269.8,321.1,269.4,322.5,268.1,323.5z"/>
	<path class="st1204" d="M265.9,329.5c-0.7,0.3-1.3,0.6-2,1c0.6-2,0.9-4,3.2-4.9C266.7,326.8,266.3,328.1,265.9,329.5z"/>
	<path class="st1205" d="M253.1,342.4c-0.4,0.8-1.1,0.9-1.9,1c-0.8-0.5-1.5-1.1-2.3-1.6c-1.5-2.1-3.8-3.1-6.1-4.2
		c-5-3.3-9.9-6.6-14.9-9.9c-0.3-1-2.9-0.4-1.8-2.5c5.4-2.2,9,1.3,12.9,4c1,0.9,2.2,1.6,3.4,2.3c1.2,0.7,2.2,1.6,3.2,2.4
		c1.9,2.1,5.4,2.3,6.3,5.5C252.4,340.5,252.7,341.4,253.1,342.4z"/>
	<path class="st1206" d="M226.1,325.4c0.6,0.7,1.3,1.5,1.9,2.2c-9.5-1.9-16.6,3.3-23.9,7.9c-1.2,0.9-2.7,1.2-4,1.9c0,0,0,0.1,0,0.1
		c-4.2,1.2-7.5,4.5-12,4.9c-0.6-0.5-0.6-1.2-0.4-1.9c0.4-0.9,1.2-1.4,2-1.8c1.2-0.5,2.5-0.9,3.6-1.6c0.4-0.2,0.8-0.4,1.2-0.6
		c3-1.4,5.7-3.3,8.5-5c0.4-0.3,0.8-0.5,1.2-0.6c1.2-0.4,2.4-1,3.5-1.8c0.4-0.2,0.8-0.4,1.2-0.6c4.5-1.8,8.9-4.3,14-4.5
		C224.2,323.9,225.3,324.3,226.1,325.4z"/>
	<path class="st1207" d="M242.9,337.5c2.8,0.3,5.1,1.4,6.1,4.2c-0.6-0.2-1.2-0.4-1.8-0.5C245.7,340,244.3,338.8,242.9,337.5z"/>
	<path class="st1208" d="M200.1,337.4c1.2-1,2.4-1.9,4-1.9C203.3,337.3,201.8,337.6,200.1,337.4z"/>
	<path class="st1209" d="M188.1,340.5c0,0.6,0,1.3,0,1.9c-1.3,0.7-2.6,1.4-3.9,2.1c-2,0.7-4.1,0.6-6.2,0l0,0.1
		c-2.9-3.7-3.7-8-3.4-12.6c0.4-6.3-0.4-12.6,0.4-18.9c-0.1-16.1-0.2-32.2-0.1-48.3c0-24.1,0.2-48.1,0.2-72.2
		c3.1,0.6,2.3,3.2,2.4,5.1c0.2,6.6,0.1,13.1,0.1,19.7c0,31.3,0,62.6,0.2,93.8c0,7.1,0.8,14.2,0.9,21.4c0,3.1,1.7,5.3,3.9,7.2
		C184.2,341.4,186.3,339.6,188.1,340.5z"/>
	<path class="st1210" d="M175,313.1c0,4.3,0.3,8.6,0.1,12.9c-0.3,6.4,0.2,12.7,2.9,18.6c-0.3,0-0.6,0.1-0.9,0.1
		c-1.6-1.7-3.1-3.6-3.1-6C174.2,330.1,173.9,321.6,175,313.1z"/>
	<path class="st1210" d="M178.1,344.5c2.1,0,4.1,0,6.2,0C182.2,345.5,180.1,346.2,178.1,344.5z"/>
	<path class="st1211" d="M295.9,282.5c-1-0.7,0-1.3,0.1-2c1.6-3.2,4.8-4.3,7.6-5.9c6.2-3.7,12.7-6.8,18.9-10.6
		c1.9-0.8,3.7-1.8,5.3-3.1c2-0.9,3.3-3.6,6.1-2.6c0.9,2-1.1,2.3-2,3.2c-2.9,4.6-8.8,5-12.2,8.9c-6.6,2.8-12.7,6.5-18.9,10
		C299.3,281.3,297.6,281.8,295.9,282.5z"/>
	<path class="st1212" d="M176.2,217.6c0-6.8,0-13.6-0.1-20.3c0-1.6,0.6-3.4-1-4.6c-1.4-11.6,3.6-21.3,9.2-30.8
		c1-1.7,2.5-3.1,2.9-5.2c0.8-0.7,1.7-1.5,2.5-2.2c0.4-0.4,0.9-0.4,1.4-0.2c1.4,1.8,0.1,3.1-0.8,4.4c-1.5,2.4-3.1,4.7-4.1,7.4
		c-1.1,4-2.9,7.6-4.4,11.4c-1.4,3.2-1.2,6.7-1.3,10.1c-0.1,1.9,0.6,4.4-2.3,5.2c-0.7-0.8-0.4-1.9-0.6-2.8c1.4,7.7,0.9,15.4,1.5,23.2
		C178.3,214.8,178.8,217.3,176.2,217.6z"/>
	<path class="st1213" d="M191.2,154.7c-0.5-0.1-1-0.1-1.5-0.2c2.3-3.2,4.3-6.7,8.3-8.1c0.3,2.4-2.5,3.8-1.9,6.2
		C194.7,154,193.3,155.1,191.2,154.7z"/>
	<path class="st1214" d="M161.2,209.6c2.3-1.9-0.3-5.1,2-7c0.3,0,0.7,0,1,0c0,20,0.2,39.9-0.1,59.9c0,3.7,2.9,7.4,0.1,11
		c-3.6-1.2-2-4.3-2.3-6.7c-0.5-3.3,1.1-7.1-2.3-9.7c-0.9-2-0.8-3.9,0.6-5.6c2.4-9.2-0.7-18.4-0.1-27.6
		C160.4,219.1,159.9,214.3,161.2,209.6z"/>
	<path class="st1215" d="M160.2,257.5c3.6-1.3,2.8,1.6,3,3.3c0.5,4.2-1.1,8.6,1,12.7c0.5,11.4-1,22.8,0.9,34.1
		c0.3,1.8-0.6,3.9-0.9,5.8c-2.5-4.8,0.1-10.1-1.9-15c0,4.2,0,8.5,0,12.7c-0.4,0-0.7,0-1.1,0.1c0-2.3,0-4.5,0-6.8
		c-1.7-1.7-1.5-3.9-1.5-6c0.1-4.9,0.1-9.9,0-14.8c-0.2-6.4,0.1-12.8-0.9-19.1C158.4,262.2,158.2,259.6,160.2,257.5z"/>
	<path class="st1215" d="M164.1,334.5c-1-4.3-1.1-8.6-1-13c0.3,0,0.7,0,1,0c0.3,5.3,0.6,10.7,0.9,16
		C164,336.7,164.2,335.5,164.1,334.5z"/>
	<path class="st1216" d="M160.2,257.5c-0.2,8.7,1.7,17.3,1,26c-1.1,3.7-0.2,7.5-0.5,11.2c-0.1,1.2,0.2,3.6-1.5,3
		c-3.2-1.3-5.9-0.4-8.9,0.5c-1.9,0.5-2.9-1.1-3.2-2.9c-0.5-2.5-0.3-4.9,0.2-7.3c-1.2,1.9,0.5,5.9-4.1,5.5c0-13.3,0-26.6,0-39.9
		c5.1-5.6,10.9-4.9,17-2C160.2,253.6,160.2,255.6,160.2,257.5z"/>
	<path class="st1217" d="M143.2,293.5c3.2-1.2,2.3-4.3,2.9-6.7c0.4-1.5-0.2-3.2,1-5.5c1.8,4.3,0.7,8,1,11.6c0.1,1.4-0.6,3.2,1,4.1
		c1.8,1,2.3-0.7,3.4-1.7c2.6-2.2,4.8-0.4,6.6,2.2c2.2-5-0.6-9.9,1.9-14.1c0,7,0,14,0,21c-1.1,0.8-1,2-1,3.1c0.1,2.6-1.3,2.6-3.5,2.7
		c-5.9,0.2-7.6-3-6.4-9.3c-3.1,2.4-1.2,5.2-3,6.5C140.8,304.3,143.8,298.4,143.2,293.5z"/>
	<path class="st1215" d="M163.1,321.5c-0.3-0.3-0.7-0.7-1-1c0-1.6,0-3.2,0-4.8c0.3,0,0.7,0,1,0C163.1,317.6,163.1,319.5,163.1,321.5
		z"/>
	<path class="st1218" d="M332,261.5c0.7-1,1.3-2,2-3c1.9-4.6,5.1-8.1,9.5-10.5c0.5,0,1,0,1.4,0.2c0.3,0.3,0.4,0.5,0.3,0.8
		c-0.1,0.3-0.2,0.4-0.3,0.4c-0.3,0.4-0.7,0.7-1,1.1c-0.9,1.9-2.8,3.2-3.9,4.9C337.5,257.8,335.4,260.6,332,261.5z"/>
	<path class="st1219" d="M339.9,255.5c0.3-2.4,2.2-3.6,3.9-4.9c0.1,0.4,0.1,0.7,0.2,1.1C342.8,253.1,341.8,254.7,339.9,255.5z"/>
	<path class="st1219" d="M344.8,249.4c0-0.3,0-0.6,0-0.9c-0.5-2.6,0.8-4.1,3.1-5c0.3,0,0.6,0,1,0C348.3,246,347.2,248.1,344.8,249.4
		z"/>
	<path class="st1139" d="M146.2,231.6c0-7.1,0-14.2,0-21.3c0.5,0,0.9,0,1.4,0c1.1,2.3,0.3,4.9,0.6,7.4c0,9.3,0.2,18.5-2,27.8
		C146.2,240.8,146.2,236.2,146.2,231.6z"/>
	<path class="st1220" d="M208.1,145.7c0.3,0,0.7,0,1-0.1c4.1-2.1,7.6-5.6,12.7-5.2c1.2,2-0.5,3.2-1.3,4.6c-1.2,1.3-2.9,1.5-4.4,2.1
		c-2,0.7-4.7,1.6-5.3,3.4c-1.2,3.7-3.4,4.8-7,4.6c-1.8-0.1-2.3,1.7-2.5,3.2c-0.4,2.5-1.7,4.3-3.7,5.7c-1.9,0.9-3.6,3-5.9,0.8
		c-0.7-1.1-1-2.2-1-3.5c3.4-4.9,7.5-9.1,12.8-12C205.3,148.5,206.4,146.8,208.1,145.7z"/>
	<path class="st1221" d="M208.1,145.7c-1.1,3.4-4.6,4-6.9,6c-3.5,3.2-6.8,6.6-10.1,9.9c-0.4,1.9-0.7,3.7-1.7,5.4
		c-2.2,2-3.5,1.2-4.3-1.4c1-4.2,4.6-7,6-11c1.5-1,2.8-2.4,4.9-2.1C200.5,151,203.6,147.1,208.1,145.7z"/>
	<path class="st1222" d="M293,97.7c0.3,0,0.7,0,1,0c1.7,4.9-2.8,4.2-5,5.6c-0.4,0.3-0.8,0.5-1.1,0.7c-5.8,3.5-11.2,7.7-17.4,10.6
		c-2.4,0.8-4.6,1.9-6.4,3.8c-2.2,1.6-4.5,3.2-7.4,3.5c-1.4,0.1-2.6-0.1-3.6-1.2c0-0.3,0-0.7,0-1C267.1,113.8,280,105.6,293,97.7z"/>
	<path class="st1223" d="M222.1,140.7c-4.3,1.7-8.7,3.3-13,5c2.4-4,6.7-5.4,10.5-6.8c7.1-2.5,12.4-8.9,20.4-9.2
		c0.1,0.2,0.1,0.4,0.2,0.6c-2.2,3-5.9,3.8-8.7,5.9c-0.7,0.4-1.5,0.8-2.2,1.3c-0.9,0.6-1.8,1-2.9,1.3c-1.4,0.2-2.5,1.2-3.8,1.9
		C222.5,140.6,222.3,140.7,222.1,140.7z"/>
	<path class="st1223" d="M253.1,120.7c1.3,0,2.7,0,4,0c0.6,1.2,0.1,2.2-0.5,3.3c-2.4,4.1-11.7,7.3-16.5,5.7c0,0,0,0,0,0
		c1.6-3.5,5.3-4,8.1-5.7C249.8,122.9,251.4,121.8,253.1,120.7z"/>
	<path class="st1224" d="M335,77.8c5.2,0,10.4-0.3,15.5,0.2c5.1,0.5,3,4.9,3.4,7.8c0,0,0,0,0,0c-3-0.7-5.7-2.2-8.5-3.3
		c-5.8-2.2-11.1-1.8-16.2,1.1c-6.5,3.7-13.4,6.5-19.7,10.3c-3.1,1-5.5,3.5-8.5,4.5c-1.6,0.6-3.8,2.6-5-0.7
		c7.3-3.7,14.1-8.4,21.8-11.6C323.6,83.6,328.3,78.8,335,77.8z"/>
	<path class="st1225" d="M296,97.7c2.8,1.4,5.1-0.2,7-1.7c1.9-1.5,4-2.3,6-3.2c1.1,5.9-0.3,8.4-5.7,9.8c-1.6,0.4-3.5,0.3-4.7,1.4
		c-4.1,3.8-7.4,2-10.6-1.2c1.7-2,6.2-0.6,6-5C294.7,97.7,295.3,97.7,296,97.7z"/>
	<path class="st1226" d="M355.9,165.6c0,1.4,0,2.7,0,4.1c-0.5,0.3-1,0.2-1.4-0.2c-0.9-1.4-1.1-3.1-1.6-4.7
		c-0.6-4.3-0.1-8.6-0.4-12.9c0-2,0.1-4.1,0.1-6.1c-0.1-2.6-0.1-5.2,0-7.9c0.2-3.7-0.8-7.2-0.9-10.9c-0.1-2.1-0.3-4.3,1.3-6.1
		c1.1-0.6,1.6,0.2,2.1,0.9c0.5,5.6-1,11.4,0.9,16.9C355.8,147.6,355.9,156.6,355.9,165.6z"/>
	<path class="st1227" d="M288,102.7c1,0.2,2.3,0,2.8,0.6c2.3,2.4,4.2,2.6,6.4-0.2c0.5-0.7,1.7-1.4,2.5-1.3c6.7,0.6,8.9-3.4,9.3-9.1
		c7.7-3.9,15.4-7.9,23.1-11.7c4.2-2.1,8.5-2,12.7,0c3.1,1.5,7.5,0.4,9.1,4.7c1,3.9,1.2,7.9,1,12c-3.1-0.1-3.5-2.7-4.1-4.8
		c-1.6-5.5-6-7.2-10.8-8.2c-3-0.6-4.8,1.6-6.7,3.5c-4.1,4.2-5.5,4.5-10.4,1.9c0.5,4.3-1.1,7.5-4.3,10.1c-1.8,1.4-1.2,4.1-2.6,5.8
		c-3,1.6-6.1,1-9.2-0.2c2.7,4.2,2.2,5.7-2.2,7.1c-8.8,2.8-17.2,6.6-26.6,7.4c-3.5,0.3-6.4,3.8-9.5,6c-4.3,3.1-8.8,5.3-14.3,4.6
		c-0.7,0-1.4,0-2.1-0.1c-4.8-1.3-8.5,1.8-12.5,3.3c-0.7,0.5-1.5,0.9-2.4,1.2c-2.1-0.1-4.1,2.2-6.3,0.3c2.5-2.8,6.3-3.5,9-6
		c6.4-1.7,13.8-1.3,17-9c2.8,0,4.3-2.8,7-3c1.9-1.6,3.8-3,6.3-3.5c4.9-2.5,9.4-6,15.3-6.1c1.8,0,3.6-0.7,1.7-3.1
		C286.9,104.4,287.5,103.4,288,102.7L288,102.7z"/>
	<path class="st1228" d="M316,105.7c-0.3-3.1,0.1-5.8,3.1-7.7c2.4-1.5,3.8-3.7,2.2-6.8c-0.6-1.2-0.1-2.3,1.1-3
		c1-0.6,2.2-0.5,2.7,0.4c2.7,5.1,5.2,1,6.8-0.5c7.8-7.6,17.1-5.3,20.1,5.1c0.5,1.8,1.7,3.2,2.9,4.5c0,8,0,16,0,24
		c-0.3-0.3-0.7-0.7-1-1c-0.6-0.5-0.9-1.2-1.1-1.9c-0.8-5.1-1.3-10.1-1.3-15.3c0.1-4.8-0.6-5.1-5.1-3.8c-3.2,0.9-6.5-0.4-9.7-0.1
		c-1.6,0.2-3.3,0-4.8,0.8c-3.4,0.9-6.5,2.1-9.3,4.3C320.7,106.2,318.5,107.8,316,105.7z"/>
	<path class="st1229" d="M354.9,169.7c0.3,0,0.7,0,1,0c0,16.6,0,33.2,0,49.8c-0.3,0.7-0.6,1.4-1,2.1c-0.8,2.3-0.6,4.8-2,6.9
		c-0.8,0.5-0.9,1.2-1,2c-0.7,0.9-1.3,1.9-2.5,0.6c-1.1-5.3,1.8-9.9,2.3-14.9c1.2-2.6,0.6-5.4,1.3-8c1-3.7,0.3-7.5,0.5-11.3
		c0-7.1,0-14.2,0-21.3C353.6,173.6,353.2,171.4,354.9,169.7z"/>
	<path class="st1230" d="M350,231.6c0.7-0.3,1.3-0.7,2-1c0,0.7,0,1.4,0.1,2.1c-0.4,3.4-3.3,5.6-4.1,8.9c-0.4,0.3-0.9,0.3-1.3-0.1
		c-0.6-0.8-0.7-1.8-0.6-2.8c0.5-2.3,2.6-3.8,3-6.1C349.3,232.2,349.6,231.9,350,231.6z"/>
	<path class="st1231" d="M346.9,241.5c0.3,0,0.7,0,1,0c0,0.7,0,1.3,0,2c-1,1.7-2.1,3.3-3.1,5c0,0-0.5,0-0.5,0l-0.5,0
		C342.5,245.2,343.4,242.8,346.9,241.5z"/>
	<path class="st1230" d="M352.9,228.5c0.3-2.4,0.7-4.8,2-6.9C354.9,224.1,355.3,226.7,352.9,228.5z"/>
	<path class="st1232" d="M282,290.5c-1,2.3-2,4.6-3,7c-2.9,3.2-3.1,8.1-7,10.7c-2.6,0.1-2.9-1.8-3.1-3.6c-0.2-1.8,0-3.6-0.1-6.8
		c-4.1,7.1-10.1,9.5-16.8,11c-4.7,1.1-8.3,4.1-10.8,8.2c-1.8,3.1-4.6,4.7-8,5.3c-2.2-0.4-4.3-1.7-6.7-1.5c-1.6-0.1-3.1,0.2-4.3,1.3
		c-0.4,0.2-0.9,0.4-1.3,0.5c-1.6,0.3-3.3,0.3-4.9,1c-2.2,0.7-4.5,1.6-6.1-1c3.2-4.3,7.4-6.4,12.7-6.1c-1.8,0.1-4.1,0.2-4.3-2
		c-0.3-2.3,2.3-2.4,3.9-2.9c2.1-0.6,4-1.5,5.8-2.7c2.6-1.1,5.2-2.6,8-2.8c5.5-0.4,8.2-3.9,9.9-8.4c1.2-3.1,2.6-5.3,6.1-5.6
		c1.2-0.1,2.5-0.6,2.7-1.9c0.8-5.5,4.7-9.3,7.1-14c1-2,1-4.2,1.1-6.3c0.1-3,1.6-5,4.5-5.8c3.9-1.1,7.6-3.5,12.9-1.9
		c-4-2.3-5.7-2.9-7.3-1.6c-4.4,3.6-8.3,2.3-12.2-0.5c-0.8-0.6-1.7-1-2.7-1.2c-0.7-0.2-1.4-0.5-2-1.1c-0.3-0.5-0.2-1,0-1.4
		c2.7-1.7,5.7-0.5,8.5-0.5c1.3,0.8,2.7,1.4,4.3,0.7c9.9,0.2,18-4.6,26.2-9.2c1.1-0.6,2.3-1.8,3.6-0.8c1.4,1.1,0.5,2.8,0.1,4.1
		c-1.2,3.3-4.1,4.6-6.9,6c-1.7,0.8-3.5,0.9-5.3,1.5c-0.5,1.8,2.1,3.1,0.9,5c-1.2,1.9-3.4,1.9-5.1,2.7c-1.7,0.8-4.3,0.5-4.5,3.2
		c-0.2,2.4,1.3,4.1,3.1,5.3c2.6,1.8,4.9,4,6.4,6.8C287.5,285.4,282.8,286.9,282,290.5z"/>
	<path class="st1233" d="M272,307.5c3.2-2.7,3.3-7.6,7-10c-0.6,3.6-3.8,6.2-4,10c-3.4,3.2-3.7,7.8-5.1,11.9
		c-1.4,1.1-2.8,2.1-2.9,4.1c-1.1,0-1.5,2.2-3,1c-1.2-4.2,2.2-6.5,3.8-9.6C269.2,312.4,269.7,309.4,272,307.5z"/>
	<path class="st1234" d="M282,290.5c-0.2-4,3.6-5.8,5-9c0.4-0.6,1-1.1,1.6-1.4c4.3-2.8,8.9-4.9,14.1-5.4c0.5,0.2,0.9,0.4,1.2,0.8
		c-2.6,1.7-5.3,3.3-7.9,5C290,281.9,286,286.2,282,290.5z"/>
	<path class="st1235" d="M269,315.5c-2.1,2.8-4,5.6-5,9c-0.7,5.2-5.7,8-7,12.9c-0.6,2.4-3,2.1-5,2.1c-2.1-2-5.4-2.3-7-5
		c-0.8-1-1-2.8-3-2c-1-0.7-2-1.3-2.9-2c-0.3-1-1-1.9-0.6-3.1c4.1-4.2,8.4-8.2,12.6-12.4c4.3-4.4,6.7-5.1,12.1-2.3
		C265.1,313.7,268.1,312.5,269,315.5z"/>
	<path class="st1236" d="M239.1,328.5c0.1,0.7,1.1,1.3,0.1,2c-4-2.6-7.8-5.6-13-5c-1-0.3-2-0.6-3-0.9c-1.2,0.8-2.5,0.4-3.7,0.4
		c-1.5-2.2,0.8-2.4,1.7-3.4c0.3,0,0.7,0,1,0c1-1.4,2.8-1.1,4-2c2.6-0.3,5,0.2,7,2C237.2,322,238.1,325.2,239.1,328.5z"/>
	<path class="st1236" d="M242.1,332.4c0.1-0.8,0.4-1.9,1.3-1.3c1.1,0.7,2.7,1.5,1.7,3.4C244.1,333.8,243.1,333.1,242.1,332.4z"/>
	<path class="st1237" d="M188.1,340.5c-1.4,0.1-2.3,2.2-4,1c-5.5-2.8-6.6-5.3-4.8-11.4c2.1-1,4.2-1.9,6.5-1.1
		c1.9,1.5-0.2,2.9-0.3,3.9c7.4-0.9,14.2-3.6,21.2-5.3c0.5,0.1,0.9,0.3,1.3,0.6c0.3,0.4,0.4,0.9,0,1.3l0,0c-1.2,1-2.9,0.9-4,2l0,0
		c-3.4,1.8-6.9,3.5-10,6l0,0c-1.3,0.7-2.8,0.9-4,2C189.5,339.8,188.8,340.1,188.1,340.5z"/>
	<path class="st1238" d="M208.1,329.5c0-0.3,0-0.7,0-1c1.2-4.9,5.7-3.4,8.9-4.7c0.8-0.1,1.5,0.1,2.1,0.7c1.3,0,2.7,0,4,0
		C218.1,326.2,213.1,327.8,208.1,329.5z"/>
	<path class="st1238" d="M194.1,337.5c2.2-3.8,5.9-5.2,10-6C201.3,334.4,197.9,336.3,194.1,337.5z"/>
	<path class="st1238" d="M204.1,331.5c0.8-1.8,2.3-2.1,4-2C207.3,331.3,205.8,331.6,204.1,331.5z"/>
	<path class="st1239" d="M190.1,339.4c0.8-1.8,2.3-2.1,4-2C193.1,338.8,191.7,339.3,190.1,339.4z"/>
	<path class="st1239" d="M179.2,330.5c0.1,4.4,0.4,8.6,4.9,11c-4.6-1-6.8-4-7-9.2c-1.6-38.2-0.8-76.4-1-114.6c1.6-0.9,0.4-3.2,2-4
		c1.5,1.4,1.6,3.3,1.5,5.1c-0.3,8.8,1.3,17.6,1,26.4c0.2,1.5,0.1,3.1,0,4.6c0.1,7.6-0.4,15.2,0.6,22.7c0.8,3.6,0.3,7.2,0.4,10.9
		c-1,8.2-0.6,16.5-0.8,24.7c-0.5,4.5-0.1,9-0.2,13.5C180.2,324.5,181.3,327.7,179.2,330.5z"/>
	<path class="st1240" d="M304,275.5c-0.3,0-0.7,0-1,0c-1.3-3.8,1.9-4.6,4.1-6.1c0.7-0.4,1.8-0.1,0.1-1.2c-1.6-1-0.1-2.7,1.3-3.3
		c2.4-1.1,4.9-1.8,7.6-0.7c2.8,1.7,4.3-2.7,7.1-1.5c0.4,0.6,0.5,1.3-0.1,1.8C317.2,269.3,310.3,271.9,304,275.5z"/>
	<path class="st1241" d="M346.9,241.5c-1.8,2-2.9,4.3-3,7c-4.2,2.5-6.2,7-10,10c-2.5-0.1-3.4,3.2-6,3.1c-0.1-0.2-0.2-0.4-0.3-0.5
		c0-0.4,0.1-0.8,0.3-1.2c0.5-0.9,1.1-1.8,1.7-2.7c0.4-0.5,0.9-0.9,1.5-1.3c1.7-0.7,2.5-2.2,3.5-3.5c1-0.8,2.2-1.3,2.9-2.4
		c1.7-2.2,3-4.7,5.1-6.6c0.2-0.4,0.4-0.7,0.7-1.1c0.4-0.7,0.6-1.6,0.8-2.4c0.3-0.9,0.7-1.6,1.7-1.9c0.5,0,0.9,0.1,1.2,0.5
		C346.9,239.6,346.9,240.6,346.9,241.5z"/>
	<path class="st1241" d="M323,264.6c0-0.7,0-1.3,0-2c0.1-0.2,0.3-0.4,0.4-0.6c1.4-1.1,2.9-1.8,4.6-0.4c0,0,0,0,0,0
		C326.9,263.5,325,264.2,323,264.6z"/>
	<path class="st1242" d="M179.1,245.6c0.2-10.7-1.8-21.3-1-32c-1.6-8.9-0.8-17.8,0-26.8c0,2,0,3.9,0,5.9c0.6,0.6,0.9,1.2,1.1,2
		c0,2.3,0.1,4.7-0.8,7.1c1.3-2.1-0.1-6.2,3-5.9c3,0.3,1.7,4.1,2,6.4c0.3,2.5,0.9,4.9,0.6,7.4c-0.2,1.8,0.5,2.9,2.4,2.9
		c1.6,0.3,3,0.8,4.1,2.1c0,5.1-1.1,10-1.9,15c-0.2,1.6-1.1,3.6-2.6,4c-5.2,1.3-4.6,5.3-4.6,9C181.1,243.9,181.1,245.6,179.1,245.6z"
		/>
	<path class="st1243" d="M178.2,194.6c0-0.7,0-1.3,0-2c2-3,0.7-6.4,0.9-9.6c0.2-2.3,0-4.7,2-6.4c4-1,3.1-6.2,6.6-7.7
		c2.3-0.2,4.6,0,6.9,0.4c1.7,1,3.5,2,2.9,4.5c-3.2,3.6-6.6,7.1-11.2,8.7c-3,1-4.5,2.4-4.4,5.6c0.1,1.8-0.4,3.6-1.2,5.3
		C180.3,194.8,179.5,195.6,178.2,194.6z"/>
	<path class="st1220" d="M188.1,169.7c-1.8,0.7-1.8,2.4-2.2,4c-0.6,2.4-2,3.9-4.8,3c1.3-3.7,2.7-7.3,4-11c1.1,1.2,2.2,2.4,4,1
		C190.6,167.8,190.6,167.8,188.1,169.7z"/>
	<path class="st1244" d="M188.1,169.7c0-1.1,2.2-1.5,1-3c0-1.9-0.3-4,2-5c1.2,0.7,1,1.9,1,3c2.4,3.4,3.3-0.8,5-1
		c1.4-2.1,3.4-3.9,3.1-6.9c-0.2-3,2.3-3,4.5-3.1c1.2,0,3.5,1.2,3.6-0.1c0.6-7.7,7.3-6.6,11.7-8.8c1.2-1.3,3.8-1.4,3.3-4
		c0.4-1.5,1.6-2.2,2.7-3c1-0.3,2-0.7,3-1c0.9-0.4,1.3,0.2,1.6,0.9c0.5,1.8-0.9,3.2-1.3,4.8c-0.5,2.3-2,3.7-4.1,4.7
		c-6.2,3.1-11.3,7.2-14.5,13.6c-1.9,3.6-4.5,7.3-9.7,5.5c0.8,0.8,1.9,1.7,1.2,3c-0.6,1.2-1.8,1.4-3,1.3c-1.8-0.1-3.3-1.8-5.2-1
		C192.1,170.7,190.1,169.9,188.1,169.7z"/>
	<path class="st1245" d="M224.1,140.7c2.6,5.3-1.7,3.7-4,4c-0.1-1.7,1.4-2.6,2-4c0,0,0,0,0,0C222.7,140.1,223.4,140.1,224.1,140.7z"
		/>
	<path class="st1243" d="M197.1,163.7c-1.1,1.6-2,3.5-4.2,3.5c-1.1,0-0.7-1.6-0.8-2.5C194.1,166.1,195.3,163.3,197.1,163.7z"/>
	<path class="st1225" d="M288,102.7c0.1,1-0.2,2.2,0.3,2.9c2,2.6,0.3,4.1-1.8,3.9c-6.3-0.5-11.1,3.1-16.5,5.2
		c-0.3-0.2-0.5-0.4-0.3-0.7c0.1-0.2,0.2-0.3,0.3-0.3C276,110.1,282,106.4,288,102.7z"/>
	<path class="st1223" d="M270,113.7c0,0.3,0,0.7,0,1c-2.2,0.6-3.6,2.8-6,3C264.9,114.6,267.7,114.5,270,113.7z"/>
	<path class="st1245" d="M230.1,137.7c-0.3-0.3-0.7-0.7-1-1c0.4-0.8,1.2-1,2-1c2.2,0.9,3.7-2.4,6-1
		C236.2,139.1,234.9,139.7,230.1,137.7z"/>
	<path class="st1220" d="M224.1,140.7c-0.7,0-1.3,0-2,0c0.9-1.6,2-2.9,4-3C225.4,138.7,224.7,139.7,224.1,140.7z"/>
	<path class="st1246" d="M353.9,137.7c0,2.7,0,5.3,0,8c-1.1,1.1,0.1,2.9-1.1,4c-6.6-2.7-13-5.6-20.2-2.6c-1.1-0.3-2-0.9-2.5-2
		c-1.1-5.2-1.1-10.3,1.2-15.3c7.3-5.5,15.4-3.8,19.5,4.1C351.6,135.4,352.7,136.6,353.9,137.7z"/>
	<path class="st1247" d="M353.9,137.7c-2.5,0.4-3.8-0.6-4.6-3.1c-1.7-5.4-8.9-8.2-14.1-5.6c-1.1,0.5-2.1,0.8-3.3,0.8
		c-0.9-0.7-1.2-1.7-1.5-2.8c-0.6-1.8-0.9-3.7-0.9-5.6c-0.1-2-0.1-3.9,0.2-5.9c1-3.1,3.7-3.7,6-2.5c4.7,2.4,9.7,3.1,14.8,3.7
		c1.4,0.2,2.4,1.1,3.3,2.1c0,0.7,0,1.3,0,2c-1.9,2.9-1.2,6.2-0.7,9.1C353.8,132.5,353.8,135.1,353.9,137.7z"/>
	<path class="st1248" d="M333,146.7c4.4-1.8,8.7-5.5,13.3,0.1c1.7,2.1,3.1-1.6,5.3-1.6c0.2,1.2,0.5,2.3,0.8,3.7c1-1,0.3-2.6,1.6-3.2
		c0,2,0,4,0,6c-1.5,3.3,0.6,7.1-1.2,10.3c-5.7,3.1-10,0.5-14-3.4c-0.8-0.4-1.4-1.5-2.6-0.6c-0.4,0.2-0.8,0.4-1.2,0.5
		c-2.6,0.3-4.4-1.2-6.1-2.8c0-2.1,1.5-3.8,1.3-6C329.9,148.1,331,146.8,333,146.7z"/>
	<path class="st1246" d="M354.9,169.7c0,9.3,0,18.7,0,28c-0.6,0.3-1.2,0.1-1.5-0.5c-0.3-0.5-0.5-1.1-0.6-1.7
		c-0.4-8.3,0-16.5-0.3-24.8c0-1.5,0.1-3,0.8-4.3c0.2-0.2,0.4-0.4,0.6-0.7C355.4,166.7,354.8,168.3,354.9,169.7z"/>
	<path class="st1246" d="M351.9,161.7c2.6-2.9-0.6-7,2-10c0,4.7,0,9.3,0,14c0,0,0,0,0,0c-0.5,0.6-0.4,1.8-1.3,1.9
		c-1.2,0.2-1.5-0.9-1.7-1.8C350.6,164.2,350.9,162.8,351.9,161.7z"/>
	<path class="st1245" d="M316,105.7c2.9,0.6,5.1-1.1,7-2.8c2.6-2.3,5.5-3.3,8.9-3.2c0.1,0.5,0.1,1.2-0.2,1.3
		c-6.1,1.7-6.5,7.5-8.7,12c-0.9,1.6-2.6,2.8-3,4.6c-1,4.9-4.3,5.8-8.5,5.5c-2.5-0.1-5,0.1-7.5,0c-3.6-0.2-6.7,0-7,4.8
		c-0.1,1.9-1.7,2.6-3.5,3.1c-5.2,1.2-10.4,2.3-15.5,3.7c-1.1,0.3-2.4,0.6-3.4,0.2c-6.7-2.9-12.6,0.4-18.7,2.2
		c-3.9-1.7-3.6-4.3-1.8-7.4c5.9,0.8,10.3-1.9,14.9-5.1c4-2.8,7.4-6.5,13.7-6.3c6.2,0.1,12.5-2.7,18.2-5.8c1.1-0.6,2.2-0.7,3.3-0.7
		c1.6,0,3.2-0.1,3.6-2c0.4-2-0.7-2.5-2.6-2.4c-1,0.1-1.2-1-0.9-1.9c0.5-1.2,1.7-2.2,2.7-1.4C309.7,106.6,312.9,105.4,316,105.7z"/>
	<path class="st1249" d="M254,129.7c0.4,2.4-1.1,5.4,2,7c1.5-0.2,3-0.3,4.3,0.9c1.2,2.1,0.5,3.7-1.3,5.2c-2.6,2.2-4.4,4.7-1.3,7.9
		c1,1.1,1,2.4,0,3.6c-1.3,1.5-1.6,3.2-1.1,5.1c1.9,3,3.5,6.1,2.2,9.8c-0.9,1.5-0.7,3-0.2,4.6c0.5,1.8,0.1,3.3-2.1,3.5
		c-2.5-0.5-3.2-2.7-3.1-4.5c0.4-4.1-2.6-7.1-3-10.8c-0.2-1.6-1.6-2.4-3.1-2.9c-0.9-0.2-1.9-0.2-2.8-0.2c-1.5-0.2-3.1-0.1-3.7-2
		c1.4-3,2.5-6.1,1.9-9.5c-0.7-4.1-2.2-5.3-6.4-5c-2.8,0.2-5.6,0.9-8.2-0.8c0.6-1.4,2.1-2.3,2-4c2.7-0.1,5.9,0.8,7-3
		c0.7-0.3,1.3-0.7,2-1c1.5,0,2.9,0.4,4.3,0.6c4,0.6,7.4,0,8.7-4.6C252.7,129.7,253.4,129.7,254,129.7z"/>
	<path class="st1245" d="M252,129.7c-0.4,1.9,1.2,5.3-1.4,5.6c-3.8,0.4-8.2,2.3-11.5-1.6C243.1,131.3,246.9,128.5,252,129.7z"/>
	<path class="st1226" d="M322,111.7c0.4-0.5,0.8-1.1,1.1-1.7c1.6-3.2,6.3-8.6,8.9-10.3c0.1-1.4,1.2-2.5,2.3-1.8
		c3.3,2.2,7.2-1.9,10.3,1.2c0.1,0.1,0.6,0,0.9-0.1c7.5-2.2,7.7-2.2,7.5,5.5c-0.2,4.8,1,9.5,1.1,14.2c-3.6-0.6-7.1-1.3-10.7-1.8
		c-2.4-0.3-4.6-0.6-6.7-2.5c-1.3-1.3-4.4-2-5.6,1.3c-1,0.8-2.3,1-3,0C326.4,113.7,323.9,113.2,322,111.7z"/>
	<path class="st1250" d="M353.9,196.6c0,0.3,0,0.7,0,1c0.8,3.7,0.8,7.3,0,11c-0.2,2.7,0.5,5.5-1,8c-3-0.7-2.3-3.2-2.3-5.1
		c-0.1-2.4-0.4-4.5-2.4-6.1c-2.3-6.4-2.4-12.9-0.7-19.5c4.2-2.7,5.1,0,5.7,3.4C353.9,191.7,352.7,194.3,353.9,196.6z"/>
	<path class="st1251" d="M348.9,205.6c2.5-0.2,2.9,1.2,3.1,3.4c0.1,2.5-1,5.3,0.9,7.6c-1,5-2,10-3,15c0,0.3,0,0.7,0,1
		c-1.6-1.7-1.6-1.7-1.2-4.6c-1.4,3.4,0.1,7.6-2.8,10.6c-0.8,0.4-1,1.2-1,2c-2.3,0-2-1.9-2.1-3.2c0-2.3-0.5-4.7-0.1-7
		c0.2-1.1-2.1-5.7,2.8-3.2c0,0,0.1-0.9,0.2-1.3c0.9-3.8,0.7-7.6,0.7-11.4C346.7,211.3,346.6,208.1,348.9,205.6z"/>
	<path class="st1252" d="M353.9,208.6c0-3.7,0-7.3,0-11c0.3,0,0.6,0,1,0C354.4,201.3,356,205.1,353.9,208.6z"/>
	<path class="st1253" d="M345.9,238.6c0.6-3.6,1.3-7.3,2-11.6c1.7,2.1,0.1,4.4,2,5.6c0.6,2.8-2,4-3,6
		C346.6,238.6,346.3,238.6,345.9,238.6z"/>
	<path class="st1254" d="M256,257.5c0.7,0,1.3,0,2,0c0.3,0.4,0.8,1,0.6,1.3c-3.3,4.8-1.9,11.7-6.7,15.9c-1,0.9-1.4,2.2-1.7,3.5
		c-0.5,2.7-2.2,4.5-4.8,5.1c-6,1.5-9.5,5.7-11.9,11c-1.1,2.5-2.5,4-5.4,4.4c-3.9,0.6-5.1,5-8.5,6.4c-2.8,1-5.9,0.7-8.8,1.4
		c-3,0.7-5.9,1.7-7.7,4.1c-3.4,4.6-8.7,4.1-13.3,5.6c-1.5,0.5-2.9-0.1-3.9-1.4c-0.5-2.6,1-4.4,2.4-6.3c0.8-1.1,1.9-2,2.1-3.4
		c0.4-3.7,2.6-5.2,6.1-5.2c0.9,0,1.6-0.5,1.9-1.3c0.5-7.1,7.5-7.1,11.5-10.2c1.7-1.3,3.7-2,4.5-4.2c1.1-2.9,3.3-3.9,6.2-4.2
		c4.5-0.5,7.9-2.7,9.4-7.1c0.9-2.7,2.5-4.5,5.1-5.7c2.1-0.9,3.5-2.1,2.7-5c-1.5-5.7,0.7-9.6,6.2-12.2c2.2-0.4,3.2,0.7,3.6,2.6
		C248.5,258,251.9,258.3,256,257.5z"/>
	<path class="st1255" d="M337.9,250.6c-0.1,2-1.5,2-3,2c-0.4-0.2-0.7-0.5-1-0.8c-0.7-0.7-1.4-1.7-2.4-0.9c-1.4,1.3-0.1,3-0.1,4.5
		c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.7-1,1c-1.2,0.9-2.4,0.9-3.4-0.3c-0.3-0.6-0.4-1.2-0.5-1.9c-0.3-1.9,1.7-4.7-0.5-5.4
		c-2.3-0.7-5.9,0-7.4,1.7c-4.6,5.2-11.4,5.7-16.9,9.1c-2.8,1.7-5.6,4.4-9.6,3.8c3.9,0.6,4.9,4.3,7,6.7c2.1,2.3-0.5,3.2-2.3,3.3
		c-4.4,0.3-6.4,3-7.4,6.9c-0.7,0-1.3,0-2,0c-3.1-2.6-6.4-5-9.1-7.9c-3.4-3.6-2.2-6.9,2.4-8.4c1.3-0.4,2.4-1.3,3.7-1.5
		c3.6-0.5,3.4-2,1.1-4c-0.7-0.6-2.1-0.7-1.8-2c0.3-1.5,1.8-1.2,2.9-1.3c8-0.9,11.2-3.5,11.8-9.3c-9.4,4.9-18.3,11.5-29.9,10.3
		c-1.3,0.6-2.1-0.3-3-1c-3,0-6,0-9,0c-1.3,0.8-2.9,1.4-3.8-0.1c-0.7-1.2,0.7-1.9,1.6-2.5c1.3-0.6,2.7-0.7,4.1-0.7
		c6.1-0.2,12.3,0.6,18.4-0.4c9.7-1.7,17.6-6.7,23.9-14.2c0.4-0.3,0.9-0.6,1.4-0.6c2.2,0.1,3.4,2,5.1,3c5.7,4.1,13.6,4.1,18.3-0.9
		c2.5-2.6,6.6-3.2,7.9-7.1c0.3-0.9,1.4-1,2.1-0.6c3.7,2.4,9.5,2.8,9.1,9.2c0,0.3,0,0.6-0.1,0.9c0,0.3,0,0.7,0,1
		C340.2,244.4,339.3,247.7,337.9,250.6z"/>
	<path class="st1256" d="M220.1,304.5c2.4-3.1,3.8-7.3,8.9-7.1c1.7,0.1,2.4-1,3.1-2.6c2.5-6.1,5.7-11.5,13.2-12.6
		c1.2-0.2,3.2-1.3,3.2-2c-0.1-5.4,5.3-7.8,6.2-12.6c0.1-0.7,1.2-1.4,0.6-2.2c-2-2.7-1.4-4,1.8-4.8c1.2-0.3,0.9-2,0.9-3.1
		c2.1,0.2,3.7,1.4,5.2,2.7c2.4,2,6,2.5,7.7,0.4c3.7-4.7,7.1-0.6,10.6-0.6c0.8,0,1.4,1.1,1.1,1.9c-0.6,1.5-1.9,1.8-3.3,1.5
		c-2.2-0.6-4.2-0.8-6.7-0.1c-6,1.7-10,4.1-8.7,11.4c0.5,3.1-3.5,4.6-4.3,8.1c-0.6,2.7-3.9,4.7-3.4,8.6c0.2,1.2-2.7,2.2-4.6,2.1
		c-2.2-0.1-3.7,0.6-3.9,2.8c-0.2,3.6-3.4,5.5-4.4,8.6c-0.3,0.8-0.9,1.5-1.8,1.5c-4.8-0.2-8.8,2.5-13.4,3.1
		C223.9,310.3,221.7,308.9,220.1,304.5z"/>
	<path class="st1257" d="M220.1,304.5c2.3,2.2,4.3,5,8,5c-1.9,3.8-6.6,2.2-9.4,4.9c2.6,2.4,6.2,0,8.9,2.4c-6.7-0.4-12.3,2-17.5,5.7
		c-0.7,2.9-3,2.3-4.7,1.7c-5.3-1.9-10.8-1-16.2-1.2c-2.9-0.1-5.2,0.5-6,3.8c-0.3,1.1-0.9,2.3-2.4,1.9c-1.5-0.4-1-1.8-1.1-2.9
		c-0.2-1.4,0.4-3-0.5-4.3c0-4.7,0-9.3,0-14c2.6,0.3,2,2.5,2.2,4c0.2,1.9,0,3.9,0.1,6.3c1.4-1.6,2.4-3.6,4.7-3.3
		c5.3,0.5,10.1,0.4,14.5-4C205.8,305.3,212.8,304,220.1,304.5z"/>
	<path class="st1258" d="M239.1,328.5c-2.3-2.1-2.8-5.7-6-7c4.1-0.9,6.6-4.1,8.6-7.3c2.7-4.4,6.8-6.1,11.3-7
		c6.2-1.3,11.2-3.8,14.4-9.4c0.5-0.8,1.4-1.2,2.7-2.3c0,3.2,0,5.7,0,8.2c0,1.6-0.5,3.5,2,3.9c-1,2.7-2,5.3-3,8
		c-3.3-1-6.6-1.9-9.7-3.6c-2.2-1.1-5.4,1.1-6.3,2.7c-2.8,5.1-9.7,6.5-11,12.7C241.5,329.5,239,325.6,239.1,328.5z"/>
	<path class="st1236" d="M179.1,321.5c2.1,1.7,0,4.5,1.7,6.4c1.6-0.1,1.3-1.3,1.3-2.2c-0.2-3.3,1.6-4.5,4.6-4.2
		c6.6,0.7,13.4-1.7,19.8,1.8c1,0.6,2.6,0.5,3.6-0.8c2,1.6,4,0.3,6,0c0.7,0.5,1,1.2,1,2c-2.8,1.8-6.8,0.8-9,4c-0.3,0-0.7,0-1,0
		c-1.2-1.6-2.9-2-4.4-0.9c-3.5,2.7-7.9,2.1-11.7,3.4c-1.9,0.6-3.6,0.1-4.9-1.5c-2.3,0.3-4.6,0.7-7,1
		C179.2,327.5,179.1,324.5,179.1,321.5z"/>
	<path class="st1256" d="M254,254.6c-0.4,0.5-1.1,1.3-1,1.4c0.8,1.1,2,0.3,3,0.5c0,0.3,0,0.7,0,1c-4.7,3-8.4,1.4-9-4
		C249.7,252,251.9,252.9,254,254.6z"/>
	<path class="st1257" d="M217.1,324.5c-0.8-0.4-1-1.2-1-2c1.4-1.7,3.3-0.8,5-1c-0.7,1-1.3,2-2,3
		C218.4,324.5,217.8,324.5,217.1,324.5z"/>
	<path class="st1257" d="M226.1,319.5c-1,1.3-2.4,1.8-4,2C222.4,318.8,224.5,319.7,226.1,319.5z"/>
	<path class="st1256" d="M265,256.6c1,0.3,2,0.6,3,1C266.4,259.2,265.4,258.7,265,256.6z"/>
	<path class="st1259" d="M289,281.5c-1-5.3,0.8-6.9,9.8-8.7c-1.2-1.6-4-1.4-3.7-4.4c0.1-1.3-2.1-3.4-4.6-2.6c-0.9,0.3-2,0.8-2.2-0.8
		c-0.1-1,0.8-1.6,1.6-1.4c5.4,1.1,8.9-3.1,13-5.1c3.7-1.7,8.3-2.2,11-5.1c2.7-2.9,5.6-4,9.2-4.8c1.4-0.3,2.7-1.8,4.1-0.7
		c1.5,1.2,0.9,3.1,0.6,4.7c-0.2,1.3-0.5,2.6-0.8,3.9c-0.7,2.5-1.6,4.4-4.9,3.9c-3.8-0.6-4.9,2.4-6.1,5.1c-3.3-1.6-6.1-0.3-8.9,1.6
		c1.5,1.7,2.9-1.1,4.4,0.8c-2.7,2.6-7.7,2.9-8.5,7.6C298,276.7,293.2,278.3,289,281.5z"/>
	<path class="st1260" d="M186.1,329.5c5.4,0.6,10.5,0.2,15.3-2.7c2.4-1.5,5-3,5.7,1.7c-3.3,0.7-6.4,2-9.5,3.4
		c-1.8,0.9-3.7,2.4-5.9,0.7c-1.6-1.3-2.4,0.3-3.2,1.1c-1.4,1.3-3.1,2.8-4.7,1.2c-1.9-2.1,0.3-3,1.8-4
		C186,330.6,186,329.9,186.1,329.5z"/>
	<path class="st1261" d="M180.1,283.5c0-3.7,0-7.3,0-11c0.3-3.6,1.2-5,4.3-1.5c2.8,3.2,4.4,3.1,7-0.2c2.3-2.9,6.2-2.8,8.7-5.5
		c2.7-2.9,5.8-5.5,10.6-5.3c3,0.1,3.8-3.4,4-6.1c0.4-3.5-0.2-7.1,5.1-7.4c1.7-0.1,2.6-2,2-3.9c-0.4-1.7-0.4-3.3,0-4.9
		c0.3-1.1,0.3-2.6,1.5-2.9c1.8-0.6,2,1.4,2.9,2.2c0.6-0.5,1.3-0.8,2.1-0.5c2.2,1.2,4.1,2.6,5.7,4.6c1.5,1.7,4.4,2.5,3.2,5.7
		c-4.6,0.5-6.2,4.3-7.6,7.6c-1.9,4.6-4.4,8.9-6.4,13.5c-1.3,2.9-7.9,5.2-12.4,4.1c-2.9-0.7-4.6,0.1-6.3,2.3
		c-4.6,6.1-9.6,11.8-17.1,14.7c-2,0.2-3.1-1.1-3.6-2.7C183.2,284.3,181.7,283.9,180.1,283.5z"/>
	<path class="st1262" d="M226.1,237.6c0.2,0.7,1,1.3,0.4,2.3c-2.3-0.7-0.6-3.5-2.8-4.6c-0.8,2.2-1.6,4.3-0.8,6.6
		c1,3-1.7,7.6-4.4,6.8c-2.9-0.9-2.3,0.6-2.4,2c-0.1,1.7,0.4,3.5-0.2,4.9c-1.2,3.3-2.3,7-7.2,6c-4.7-1-5.7,4.3-9.1,5.7
		c-1.2,0.5-2.2,2.4-4.2,2.3c-1.2-0.1-2.3,0.3-2.9,1.5c-0.9,1.6-0.7,4.9-3.3,4.1c-2.6-0.8-6.9-0.5-6.6-5.2c-1.9,0.3-1.3,2.2-2.5,2.6
		c-2.1-4.4-0.6-9-1-13.6c-0.2-3.1,0-6.3,0-9.4c3.8,3.8,4.5,9.1,6.8,13.3c2.3-1.3,3.4-3.9,5-6.1c3.1-4.4,6.5-9.5,13.3-9.1
		c2.1,0.1,2.7-1.5,3.5-3c1.9-3.4,4.7-5.7,8.8-5.8c3.4,0.2,3.9-2.1,3.9-4.6c0-1.4-0.9-3,1.1-3.7c2.3-0.8,3.3,1,4.3,2.4
		C226.7,234.5,227.3,236,226.1,237.6z"/>
	<path class="st1263" d="M216.1,239.6c-3.3,1.9-7.3,3-8.1,7.6c-0.3,2-1.7,2.4-3.8,2c-3.6-0.7-12.3,4.9-12.8,8.8
		c-0.5,3.2-0.8,5.4-4.7,5.2c-0.4,0-0.8,1.3-1.2,2c-2.1-5.2-4.2-10.4-6.3-15.6c0-1.3,0-2.7,0-4c1.2-0.7,0.9-1.9,1-3
		c4.9-2.3,3.3,3.2,5.8,4.8c2.6-5.7,8.2-8.4,12.4-12.6c1.5-1.5,3.1-2.5,5.2-2.5c1.2-0.1,2.5-0.1,2.9-1.5c1.9-7.2,8.9-6.5,13.8-9
		c1,1.5,0.3,2.9-0.5,4.2c-1.7,2.9-4.2,5.4-1,9.1C219.9,236.5,219.4,239.6,216.1,239.6z"/>
	<path class="st1264" d="M180.1,283.5c1.9-1.2,3.8-3.2,4.3,1.2c0.1,1.5,1,3.1,2.7,3.8c3.5-0.7,5.9,0.2,6.3,4.2
		c0.4,4.1,4.4,4.6,6.7,6.8c0,0.2-0.1,0.5-0.1,0.5c-8.8,0.8-9.2,9.7-13.9,14.5c-2.1,1.6-3.9,3.4-4.2,7.2c-3.5-5-0.1-10.2-2.7-14.2
		c1.9-2.6,0.2-5.1,0-7.7C178.5,294.3,180.5,289,180.1,283.5z"/>
	<path class="st1265" d="M316,265.5c-1.5-4.5,1.6-5.9,4.9-6.6c1.8-0.4,4.5,2,4.1-2.2c0.6-0.1,1.3-0.1,1.9-0.2c0.7,1.2,1.9,1,3,1
		c0,1.4-0.7,2.4-2,3c-1.6,0.7-3.7,0.5-5,2c0,0,0,0,0,0C320.5,263.2,319.7,267.9,316,265.5z"/>
	<path class="st1253" d="M343.9,242.6c0.2-6.8-7-6-10.4-10.7c0.8,4-0.1,5.7-3.3,5.8c-2.8,0.1-3.5,3.3-5.6,4.8
		c-5.4,3.9-13.6,4-18.6-0.9c-0.5-1.2-0.2-2.3,0.2-3.4c0.5-1.1,1.3-1.9,2.5-2.1c0.2-0.9,1.7,1.8,1.3-0.4c-0.2-1.2-0.8-2.4-0.5-3.6
		c0.6-1.1,1.3-2.1,2.8-1.4c1,1,1.3,2.3,1.8,3.5c1,2.1,2.8,2.6,4.5,1.4c4.6-3.3,10.7-4.8,12.7-11.1c0.5-1.5,1.8-2.7,3.2-2.9
		c5-0.7,7.8-4.7,11.4-7.3c0.8-0.3,1.5-0.2,2.1,0.3c-0.3,4.8,1.2,9.9-2.6,14.1c-0.3-0.6-0.6-1.3-0.9-2c0.2,4.7-1.2,9.3,0.4,13.9
		C344.9,241.4,344.7,242.1,343.9,242.6z"/>
	<path class="st1253" d="M337.9,250.6c-0.9-3.1,2.8-7.4,6-7C341.9,245.9,339.9,248.2,337.9,250.6z"/>
	<path class="st1265" d="M334.9,251.6c0,0.3,0,0.7,0,1c-0.4,2.3-1.1,4.2-4,4c0-0.3,0-0.7,0-1c-0.7-2-0.4-3.9,1.3-5.2
		C333.3,249.5,334.3,250.6,334.9,251.6z"/>
	<path class="st1240" d="M323,262.5c1-2.3,3.1-2,5-2c0,0.3,0,0.7,0,1C326.3,261.9,324.6,262.2,323,262.5z"/>
	<path class="st1266" d="M204.1,233.6c-3.2-1.3-4.6,1.9-5.9,3.3c-3.3,3.7-8.9,5.1-10.3,10.6c-0.4,1.5-1.5,2.1-3,1.9
		c-1.9-0.2-1.8-1.7-1.8-3c0-2.1,0-4.1-3-3.9c0-2.1-0.1-4.3,0.1-6.4c0.2-1.4,0.5-3.5,2.4-3.1c4.9,1.2,5.1-3.2,5.2-5.2
		c0.3-4.6,0.9-9,2.3-13.3c1.5-1.6,3.7-2.1,5.5-3.3c3.2-2.2,6.9-3.6,7.8-8.3c0.5-2.8,3.6-4.1,6.8-3.6c1.6,3.6,2.7,7.4,5.5,10.4
		c1,1.1,1.5,2.6,0.4,4.1c-2.3,1.2-3.7-0.2-5.1-1.6c-0.9-0.9-1.6-2.4-3.2-1.7c-1.5,0.6-2,2.2-2.1,3.7c-0.4,4.4-0.1,8.9-0.2,13.3
		C205.4,229.6,205.7,231.8,204.1,233.6z"/>
	<path class="st1267" d="M178.2,194.6c3.5-1.6,2.9-5.3,2.6-7.6c-0.4-2.9,0.9-4.6,2.7-4.8c6.1-0.6,8.9-6.2,13.7-8.6
		c1.7-1.3,3-0.6,4,0.9c1.1,1.7,2.7,3.2,3.4,5.2c-0.5,3.4-0.8,6.7,0.2,10.1c0.4,1.4,0.5,2.9-0.6,4.2c-2.7,2.3-5.8,1-8.6,0.9
		c-3.5-0.1-5.1,1.2-6.4,4.6c-1.8,4.6-0.4,9.7-3,14c-2.8,0.8-4.4-0.5-3.7-3.2c1.2-4.8-1.3-9.2,0.1-15.1c-3.1,2.6-2,5.5-4,7
		C177.6,199.9,178.4,197.2,178.2,194.6z"/>
	<path class="st1268" d="M186.1,213.6c-0.4-5.7,0.3-11.3,2.1-16.8c0.7-2.2,1.8-3.1,4.2-3.3c3.9-0.3,7.8,1.3,11.7,0.1
		c1.3,2.7,5.8,2.2,6,6c-3.2,1.4-6.6,2.3-6.2,7.2c0.2,3.1-3.8,2.4-5.6,4.1c-2.1,2.1-4.9,3.7-8.2,3.6
		C188.8,214.3,187.5,213.9,186.1,213.6z"/>
	<path class="st1269" d="M204.1,179.6c-3.4-0.7-2.8-6.1-7-6c0.1-2.2-2.6-2.2-3-4c0.9-0.7,1.3-3.5,2.9-1.5c1.4,1.8,3.3,2,4.1,1
		c1.7-2.2-1.6-2.1-2.2-3.4c-0.1-0.3-0.1-0.6-0.2-1.5c5.9,3.7,9.3,0.1,11.2-4.3c3.2-7.4,9.2-11.2,15.9-14.3c1.9-0.9,2.2-2.2,2.2-4
		c3.2-0.7,6.5,0.3,9.7-0.9c1.6-0.6,3.2,0.2,4.4,1.6c3,3.5,2.5,11.1-1.2,14.2c-0.4,1-1.1,1.5-2.1,1.9c-0.8,0.3-2.8-1.5-1.9,1.7
		c0.6,2.4-2.8,2.4-4.8,3.1c-0.7,0.1-1.5,0.1-2.2-0.1c-3.6-1.5-3-4.3-2.4-7.1c0.2-0.9,0.7-2.1-0.3-2.8c-1.2-0.9-2.3,0-3,0.9
		c-2.1,2.3-5.2,2.8-7.4,4.9c-4.3,4-6.5,9.2-7.5,14.8c-0.5,3-1.7,4.9-4.7,5.6C204.6,179.6,204.3,179.6,204.1,179.6z"/>
	<path class="st1270" d="M332,126.7c0,1,0,2,0,3c-1.1,5.3-1.1,10.6-1,16c-6.3,1.6-8.5,8.3-13.9,11.1c-0.8,0.4-1.7,2-2.4,1.5
		c-5.1-3.1-8.4,1.6-12.3,3c-7.6,2.6-15.3,2.3-23.1,1.8c-1.2,0-2.5,0.1-3.7,0c-5-3.2-5-3.8-1.1-7.7c2.9-2.9,6-5.5,9.5-7.6
		c5.9-3.5,12-6.3,18.9-6.5c6.2-0.1,10.9-3.6,15.7-6.6c1.2-0.8,2.2-2.1,3.8-2.1c2.6,0,3.9-1.1,4.4-3.7
		C327.4,126,329.2,125.1,332,126.7z"/>
	<path class="st1271" d="M279,161.7c8.8,1.3,17.5,0.3,25.9-2.3c1.1-0.3,1.8-1.8,2.6-2.9c1.5-2.1,2.8-2.6,4.6-0.2
		c1.4,2,3.1,2,3.8-0.5c1-3.4,6-1.7,6.7-5.7c0.8-4.6,4.5-4.8,8.3-4.4c0.8,0,1.6,0.2,2,1c-0.9,1.1-2.5,1.9-1.9,3.6
		c0.9,2.5-0.8,3.8-2.1,5.4c-0.9,5.4-5.9,6.2-9.7,7.7c-2.7,1.1-5.6,1.3-7.2,4.3c-0.9,1.7-2.9,2-4.8,1.7c-6.8-3.6-14.2-1.9-21.4-2.4
		C282.5,166.8,278.6,166.9,279,161.7z"/>
	<path class="st1272" d="M322,111.7c2.9,0,6-0.7,6.7,3.5c0.1,0.3,1.5,0.3,2.2,0.5c0,2,0,4,0,6c-2.1,2.2-4.4-2.3-6.7,0.4
		c-1.7,2-2.4,4.3-3.4,6.5c-0.6,1.3-1.8,2-3.2,2c-4.8,0.2-9.4,1.8-14.3,1.3c-2.9-0.3-5.4,0.1-5.5,3.9c0,0.8-0.5,2-1.2,2
		c-5.3,0.2-8.5,4.6-13.1,6.4c-1.8,0.7-3.6,0-5.3-0.6c-1.8-0.6-3.6-0.9-5.4-0.6c-5.4,0.7-9.7-0.8-12.8-5.5c-1.4-0.1-2.9,0.5-4-1
		c5.2-3.3,10.3-6.6,16.9-3.4c1.3,0.7,3.4,0.7,4.8,0.2c5.2-1.8,10.5-3.1,15.9-3.8c2.5-0.3,2.4-2.1,2.2-3.7c-0.4-3.7,1.4-4.5,4.7-4.4
		c4.6,0.2,9.3,0,13.9,0.1c2.1,0,3.7-0.5,3.4-3C317.6,115.4,321.6,114.6,322,111.7z"/>
	<path class="st1273" d="M279,142.7c3.4,2.4,7.1,0.3,8.2-1.9c1.7-3.2,3.7-3.2,6.3-3.1c2.4,0.1,4.1-0.6,3.2-3.4c-1-3,0.7-3.8,3-3.5
		c5.9,0.7,11.6-1.4,17.4-1.1c1.8,0.1,2.4-0.8,3-2.5c0.7-1.9,0.8-3.8,2.3-5.7c3.4-4,5.7-1.4,8.5,0.2c-0.1,1.8,0.1,3.5,1,5
		c-3-0.4-4.3,0.8-4,3.9c0.2,2.9-0.8,4.5-4.1,3.3c-1.2-0.4-2.5-0.4-3.6,0.9c-4.2,4.5-9.4,7.6-15.5,7.8c-7.6,0.2-13.9,4-20.7,6.2
		c-2.5,1.3-4.9,1.9-7.3-0.2C275.7,145.9,277.2,144.2,279,142.7z"/>
	<path class="st1274" d="M308,168.7c0.3,0,0.8,0,0.9-0.2c4.3-6.6,12.8-5.9,18.2-10.6c0.7-0.6,1.2-1.5,1.9-2.2c2.6-0.3,4.2,3,7,2
		c0.8,0.7,1.4,2,0.9,2.6c-2.7,3.7-3,9.3-8.5,10.8c-2.9,0.8-4.4,3.5-6.9,4.8c-5.4,4.5-11.9,3.3-18.2,3.7c-3.1-1.7-5.3-4.5-8.1-6.5
		c-0.8-3.3,1.1-4.3,3.9-4.8C302.2,167.8,305.1,168.9,308,168.7z"/>
	<path class="st1252" d="M321,174.6c2.9-2.1,4.9-5.9,9.3-5.3c0.1,0,0.4-0.2,0.4-0.2c-0.1-4.6,5.9-6.5,5.2-11.4c0.7-0.6,1.3-0.6,2,0
		c1.1,0.2,3,0,3.2,0.5c2.3,5.6,6.8,3.6,10.8,3.5c0,1.3-0.1,2.6,0,3.9c0.4,2.7,1.3,0,2,0.1c0,10.3,0,20.6,0,31c-1.9-2.1-0.6-4.7-1-7
		c-0.6-3.9-1.9-7.6-1.6-11.6c0.3-3.9-1.6-5-5.3-3.5c-2.7,1.1-5.2,0-7.8-0.1c-4.1-0.1-8.2-1.4-12.2,0.8
		C324.5,176,322.5,176.2,321,174.6z"/>
	<path class="st1274" d="M337.9,157.7c-0.7,0-1.3,0-2,0c0.2-0.9-0.5-2.6,1.2-2.4C338.2,155.4,337.9,156.8,337.9,157.7z"/>
	<path class="st1275" d="M279,142.7c0.7,2.2-1.9,3.1-2,5c-6.5,5.6-12.9,11.2-22,12c0.3-2.3-1.1-5,2.2-6.5c1.2-0.5-0.1-1.6-0.8-2.3
		c-2.8-2.9-2.4-7.2,1.3-9c2.3-1.1,2.3-2.4,2.3-4.2c0.7,0,1.7-0.2,1.9,0.1c4,5.9,10.1,3.3,15.4,4C277.9,141.8,278.5,142.4,279,142.7z
		"/>
	<path class="st1276" d="M255.1,159.7c1.2-1.4,2.7-2.3,4.7-2c1.5,0.2,3.1,0,3.6-1.8c0.8-2.6,2.1-3.9,5-2.6c0.8,0.4,2-0.1,2.1-0.8
		c0.6-4,4.1-3.6,6.6-4.8c2.1,1.9,4.7,0.6,7,1c-3.8,3.4-7.5,6.7-11.4,10.2c0.9,1.3,1.7,2.9,3.4,3.8c-0.7,2.9-3.5,3.8-5.5,3.8
		c-4.7,0-7.1,2.3-8.6,6.3c-2.3,0.2-3.2-1.2-3.9-3.1C258.6,165.9,257.8,162.5,255.1,159.7z"/>
	<path class="st1277" d="M258,169.6c1.3,0.7,2.3,1.7,3,3c2.1,1,2.2,2.9,2.4,4.8c0.2,2.6,1.2,5.2,0.5,7.8c-0.9,2.1-0.8,4.7-3.6,5.5
		c-0.7-1.7-0.8-1.7-2.5-1.3c-1.8-3.4-3.5-6.7-3.6-10.6c0.2-1.1,0.9-1.9,1.9-2.3c2.7-0.5,2.1-1.9,1-3.5
		C256,171.6,255.4,170.2,258,169.6z"/>
	<path class="st1278" d="M256,176.6c-0.4,0.7-0.7,1.4-1.1,2.1c-0.9,0.1-1.4-0.4-1.8-1.1c-1.7-3.2-2.8-6.7-3.9-10.2
		c-0.4-2.9-2.1-5.6-1.9-8.6c0.1-0.5,0.4-0.9,0.8-1.3c4.4,3.3,5.3,8.3,6.2,13.1C254.6,172.9,254.8,174.9,256,176.6z"/>
	<path class="st1278" d="M232.1,162.7c1.3-1.6,5.8-0.4,3.9-4.7c1.7-0.5,3.6-0.2,5.1-1.3c1,1.5,2.6,0.9,4,1c-0.4,3.1-3.8,2.4-5.2,4.3
		c-0.4,0.3-0.8,0.5-1.2,0.7c-2.3,0.6-4,3.6-6.9,2C231.5,163.9,231.5,163.2,232.1,162.7z"/>
	<path class="st1279" d="M239.3,161.8c0.7-3.1,4.2-2.3,5.8-4.2c1,0,2,0,3,0c0,0.4-0.1,0.8-0.1,1.2
		C245.4,160.8,242.8,162.6,239.3,161.8z"/>
	<path class="st1269" d="M240.2,138.7c0.6-0.4,0.9-0.8,1.3-0.8c0.3,0,0.6,0.4,0.9,0.7c-0.3,0.3-0.6,0.7-1,0.8
		C241.1,139.4,240.7,139,240.2,138.7z"/>
	<path class="st1280" d="M321,174.6c1.6-0.2,3.7,0.2,4.8-0.6c4.3-3.1,8.8-1.2,13-0.7c4.2,0.5,7.8,1.5,9.8-3.3
		c6.3,5.7,3.5,13.1,4.3,19.6c-1.2-1.9-1.5-5-5-4c-0.2-0.2-0.3-0.3-0.5-0.5c-0.6-1-0.6-2.2-0.9-3.3c-0.8-2.8-2.7-3.5-4.8-1.8
		c-2.3,1.9-4.8,2.7-7.6,3.1c-2.5,0.4-3.8,2.4-4.9,4.5c-1.2,2.3-1.8,5-4.1,6.6c-0.9,0.4-1.8,0.5-2.7,0.6c-3.3,0-2.3,4.2-4.8,5
		c-1.8-0.2-2.5-1.6-3-3.1c-0.9-2.5-2.8-3.8-5.3-3.7c-3.4,0.1-6.4-1.5-9.6-2.2c-1.2-0.3-2.9-0.3-2.8-2.1c0.1-1.5,1.6-2,2.9-2.2
		c1.2-0.2,2.3-0.1,3.3,0.5c0.8,0.7,1.7,1.5,2.9,1.6c-0.8-0.1-1.5-0.4-2.1-0.9c-2.8-2.4-6-4.6-6.3-8.9c1.6-1.8,3.4-0.9,5.2-0.2
		C308.9,177.1,315.8,179.5,321,174.6z"/>
	<path class="st1281" d="M347.9,214.6c-0.7,0-1.3,0-2,0c-1.6-1.6-1.5-3.7-2-5.6c0.5-6.5,0.6-12.9,0.5-19.4c0.5-1.9-0.1-4.7,3.4-3.9
		c0,0,0,0,0,0c0,6.7-0.3,13.4,1,20C348.6,208.6,348.3,211.6,347.9,214.6z"/>
	<path class="st1282" d="M200.1,299.5c-4.5-0.6-8.4-1.7-8-7.5c0.3-3.8-3.9-1.5-5-3.5c2.4-2.6,5.7-4.2,8.5-6.4
		c3.8-2.9,6.2-6.8,9-10.3c1.6-2,2.7-2.9,4.7-1.7c4.1,2.4,7.8-0.5,11.4-1.2c1.7-0.3,2.8-4.4,4-6.8c2.7-5.4,5.1-10.9,7.9-16.2
		c1.5-2.9,3,0.2,4.5,0.6c3.8-1.2,5.7,1,7,4c0.1,0.5,0.4,1.3,0.3,1.4c-5.2,3-6,5-4.5,11.1c0.5,1.9,0.8,3.8-2.1,4.6
		c-3.5,1-5.7,3.4-7,7.2c-1.5,4.4-5.2,6.4-9.9,6.7c-1.3,0.1-3.4-0.4-3.7,0.8c-1.1,5.5-6,6.8-9.6,9.6c-0.8,0.6-1.7,1.4-2.5,1.4
		C200.7,293.3,199.9,296,200.1,299.5z"/>
	<path class="st1283" d="M244,250.6c-2.3-1.4-4-3.9-7-4c0-2.8-2.7-3.3-4-5c-0.4-0.9,0.2-1.2,0.9-1.5c2.6,0.1,3.7,2.4,5.6,3.7
		c2,1.4,3.7,3,5.9,4.1c0.8,0.4,1.6,0.8,2.4,1.3c2.2,1.5,4.8,2,7.2,2.9c1.3,0.5,3,0.4,3.2,2.4c-1.4,0-2.8,0.1-4.1,0.1
		c-2.3-0.4-4.6-0.7-7-1.1C247,251.6,246,250.6,244,250.6z"/>
	<path class="st1284" d="M258.2,254.5c-3.6-2-8.1-1.6-11.2-4.8c-0.4-0.5-0.4-0.9,0.1-1.3c5.1-0.2,9.4,2.7,14.4,3.3
		c10.4,1.2,20.2,0.5,28.3-7c1.3-1.2,3.1-2,4.7-2.8c1.5-0.6,2.7-1.7,3.7-2.9c0.5-0.5,1.1-0.9,1.8-0.9c0.5,0,0.9,0.2,1.2,0.6
		c-6.6,9.8-16.3,14.5-27.7,16C268.4,255.3,263.3,254.7,258.2,254.5z"/>
	<path class="st1285" d="M301.1,238.7c-0.4-0.1-0.8-0.1-1.2-0.2c0-5,3.4-8.8,5.5-12.8c3.8-7,3.8-14.7,5.6-22
		c0.5-2.2-1.3-3.1-3.2-3.6c-1.4-0.4-3.9,0.3-3.6-2.6c3.7-1.8,7.1-1.4,10.2,1.4c1.8,3.7,0.8,7.5,0.3,11.2c-0.7,4.8-2.1,9.4-3.5,14
		c-0.4,1.7-1.3,3.2-2,4.8c-0.4,0.8-0.9,1.7-1.3,2.5c-1.7,2.7-2.7,5.9-5.9,7.3C301.7,238.6,301.4,238.6,301.1,238.7z"/>
	<path class="st1251" d="M302,238.6c1.7-2.6,3.5-5.3,5.2-7.9c2.6,0.8,0.8,2.6,0.9,4c0.1,1.5-2,2.2-1.2,3.9c-0.3,1-0.7,2-1,3
		C304.3,241,303.2,239.7,302,238.6z"/>
	<path class="st1259" d="M334.9,251.6c-4.5-1.8-2.9,2.4-4,4c-1-2.2-2.8-4.6,0.1-6.3C333,248.1,334.5,249.4,334.9,251.6z"/>
	<path class="st1286" d="M216.1,239.6c2.4-2.2,3.8-4.3-0.4-6c-2.2-0.9-1.3-1.7-0.3-3.2c1.9-2.7,4.5-5.1,4.7-8.7
		c0.2-0.1,0.4-0.1,0.6-0.2c2.4,1.7,2.3,5.1,4.7,6.8c0.6,0.7,1,1.5,1.4,2.3c0.7,1.4,1.6,2.7,2.1,4.2c0.3,0.8,0.9,1.9-0.9,1.8
		c-0.7,0.3-1.3,0.7-2,1c0.4-2-1-3.4-1.9-4.9c-0.5-0.8-1-1.9-2.2-1.4c-2.3,0.9-0.1,1.7,0.1,2.5c0.9,4.1-2.9,5.6-4.7,8.1
		C216.5,242.9,216.4,240.5,216.1,239.6z"/>
	<path class="st1287" d="M228.1,236.6c0.6-2.4-2.3-3.6-2-5.9c3.9,0.1,4,4.1,6.2,5.9c0.4,0.5,0.9,1,1.3,1.5c0.5,0.7,1,1.4,0.6,2.4
		c-0.4,0.3-0.7,0.7-1.1,1C232.2,239.1,228.7,239.3,228.1,236.6z"/>
	<path class="st1286" d="M204.1,233.6c0-6.3,0.1-12.6-0.1-19c-0.1-2.9,1.1-4.9,3.5-6c2.5-1.2,3.5,1.4,4.4,2.9
		c1.1,1.7,2.3,2.2,4.1,2.1c1.7,0.5,1.9,2.2,2.6,3.5c0.5,1.2,1.2,2.2,1.7,3.4c0.1,0.4,0,0.8-0.2,1.2c0,0,0,0,0,0
		c-3.7,1.5-5.6,6.3-10.7,5.5c-1.5-0.3-1.3,1.7-1.2,2.7C208.5,232.9,207,233.9,204.1,233.6z"/>
	<path class="st1251" d="M344.9,209.6c1.7,1.4,0.8,3.3,1,5c-1.8,3.2-3.6,6.2-7.6,7c-3.3,0.7-6.6,1.2-6.6,5.8c0,2.5-2.9,2.8-4.3,4.2
		c-2.6,2.7-6.3,4.8-9.9,5.8c-5.6,1.5-4.8-3.6-5.6-6.8c0.4-1.3,1.4-1.9,2.6-2c2.2-0.3,3.3-1.1,2.8-3.6c-0.3-1.6,0.3-2.9,2.5-2.6
		c4.3,0.4,5.9-1,6.7-5.4c1.1-6.1,1.1-6.3,7.4-6.6c3.3-0.2,6.3-2.5,9.7-1.5C344.1,209,344.6,209.3,344.9,209.6z"/>
	<path class="st1288" d="M343.9,209.6c-4.8,0.3-9.1,2.8-14.1,2.2c-2.1-0.3-2,2-1.8,3.4c0.4,3.1-1,5.3-2.7,7.6
		c-2.3,3.2-4.2,4.1-6.3,0c-0.4,0.6-0.8,1-0.8,1.1c2.4,4.4,1.4,6.5-3.9,5.9c-0.7-0.1-1.5,0.6-2.3,0.9c-1,0.4-1.6,1-2,2
		c-1,1.2,0.4,2.9-1,4c-1,0.4-1.6,1-2,2c-0.7,0.1-2.4,1-1.4-0.8c1.3-2.3,2.1-4.5,1.6-7.1c0.3-0.7,0.6-1.4,0.9-2.1
		c2-0.8,2.5-2.5,2.4-4.5c1-3,1.8-6,2.9-9c0.8-0.8,1.1-2.7,1.8-2.8c2.8-0.1,1.6-1.8,1.5-3c0-1.6-0.2-3.3,1-4.6
		c0.8-0.8,1.7-0.7,2.4,0.3c0.4,2.7-0.4,5.5,0.3,8.4c-0.2-3.8,1.1-6.4,5.2-6.7c1.9-2.5,4.1-3.6,7.3-2.4c1.9,0.7,4-0.1,5.9,0.3
		C341.6,205.2,344.9,205.4,343.9,209.6z"/>
	<path class="st1251" d="M309,236.6c0-1.4-1.4-3.2,1-4c0.5,1.4,1.1,2.7,1.4,4.1c0.2,1-0.2,2.2-1.2,2.3
		C308.2,239.2,310.3,237,309,236.6z"/>
	<path class="st1289" d="M218.1,217.6c-0.7-1.3-1.3-2.7-2-4c-0.1-2-1.3-3.3-2.6-4.6c-2.7-2.5-4-5.6-3.4-9.4c-0.6-1.3-1.6-1.9-3-2
		c-2.6-0.1-3.1-1.8-3-4c-0.3-2.1-0.3-4.4-1.1-6.3c-1.3-3-0.3-5.3,1.1-7.7c0,0,0,0,0,0c4.9-1.3,7.5,0.7,9.4,5
		c2.9,6.5,2.1,13.5,3.3,20.1c0.6,2,0.3,4.3,1.7,6.1c1,1.9,1.3,3.8,1.1,5.9C219.3,217.4,218.9,217.9,218.1,217.6z"/>
	<path class="st1287" d="M216.1,205.6c-1.9-1.9-1.1-4.4-1-6.6c0.3-5.4-2.3-10.1-3.3-15.2c-0.6-3.2-4.4-4-7.7-4.2
		c5.1-2.2,4.2-7.6,5.7-11.6c2.2-5.7,5-10.2,10.8-12.5c1.6-0.6,2.7-1.9,3.6-3.3c1.3-2,2.8-1.1,4.4-0.6c2.1,0.8,0.6,2.2,0.5,3.3
		c-0.2,2.6-2.4,5.4,0.9,7.6c-0.6,3.8-4,5.5-6,8.2c-3.6,4.8-4.6,10.4-5.8,16c-1.5,4.2-0.4,8.5-0.8,12.7
		C217.3,201.7,217.9,203.9,216.1,205.6z"/>
	<path class="st1290" d="M218.1,185.6c0.1-4.5,0.3-8.6,3.3-12.7c2.7-3.7,5.6-7,8.7-10.3c0.7,0,1.3,0,2,0c0,0.7,0,1.3,0,2
		c0.9,2.3-0.6,4.1-2.1,5c-4.2,2.5-5.5,6.8-7.2,10.8c-1.3,1.8-1.9,4.2-4,5.3C218.5,185.8,218.3,185.7,218.1,185.6z"/>
	<path class="st1291" d="M262,177.6c0.1-1.8-0.1-3.5-1-5c1.3-1,1-2.4,1-3.7c0.1-2.3,1.2-3.5,3.4-3.3c4,0.4,7.3-1.1,10.5-3.1
		c0.7-1.2,1.9-1,3-1c1,3.2,3.1,4.3,6.4,4.1c3.5-0.3,7,0.1,10.4-0.1c4.4-0.3,8.5,0.3,12.1,3c-2.5,2-5.4,0.9-8.1,0.9
		c-3.1,0-4.2,1.2-3.9,4.1c-1.9,1.8-4.4,1.7-6.5,1c-6.5-2.1-12.1-0.3-17.6,3.2C268.8,179.8,265.3,179.8,262,177.6z"/>
	<path class="st1292" d="M262,177.6c2.1,0,4.3,0,6.4,0c0.8,0,2.2,0,2.4-0.3c2-6.2,8.5-2.3,12-5.4c0.8-0.7,3.3-0.7,4.2,0.1
		c2.8,2.4,6,1.2,9,1.6c4.3-1.1,4.8,3.1,7,5c-1.7,0.2-3.6-0.7-5,1c1.1,2.5-0.2,2.7-2,2c-1.1-0.4-2-1.4-3.2-0.9
		c-8.5,3.7-18.5,1-26.6,6.3c-2.1,1.4-2.4,1-3.1-1.4C263.5,182.9,262.1,180.3,262,177.6z"/>
	<path class="st1293" d="M263,185.6c0.1,1.4,0.7,1.5,1.9,0.8c6.1-3.9,12.9-5.2,20.1-4.8c1.2,0.1,2.2-0.4,3.3-0.8
		c2.9-0.8,6-4.6,8.8,0.2c0.1,0.2,0.8-0.8,0.9-1.4c2.2,2.5,4.6,4.9,6,8c-2,0.3-4.3-0.7-6.7,1.5c4.6,0.2,7.9,2.9,12.3,2.7
		c3-0.2,5.8,1.2,6.4,4.9c-5.2-0.4-10.2-2.2-15.4-2.7c-6.3-0.5-11.7,1-16.3,5.4c-0.8,0.8-1.7,1.6-2.9,1.7c-4.5-2.3-1.9-5.2-0.3-8.3
		c-6.7-2-13.1-3.9-19.2,1c-0.7,0.6-1.5,0.4-2.2-0.3c-0.5-1-0.4-2,0.4-2.8C261.9,189.5,261.4,186.9,263,185.6z"/>
	<path class="st1294" d="M260,190.6c0,1,0,2,0,3c0.2,2.5-2.4,3.8-2.7,6.1c-0.5,3.7-0.7,6.8,3,9.5c2.7,2,6.7,3.9,5.3,8.8
		c-6.9,1.1-9.2-5.6-13.6-8.5c-2.4-1.6-0.2-4.9,0.1-7.5c0.4-1.2,0.7-2.4,0.8-3.7c1.2-3.3,1.7-7,5.3-8.8c0.5-0.4,0.9-0.8,1.4-1.2
		C259.8,189.2,259.9,189.9,260,190.6z"/>
	<path class="st1295" d="M258.2,189.6c-1.4,2.9-2.9,5.9-4.3,8.8c-0.3,0.5-0.7,0.7-1.2,0.7c-0.5-0.1-0.9-0.4-1-0.9
		c2.8-6.6,2.9-13.4,1-20.2c0.3-0.4,0.7-0.5,1.2-0.3c0.4,0.4,0.7,0.7,1.1,1.1C255.4,182.6,258,185.7,258.2,189.6z"/>
	<path class="st1296" d="M239.3,161.8c3-0.8,5.5-3,8.7-3c0.8,2.9,2.2,5.5,2.2,8.6c0.4,0.6,0.4,1.3,0.2,2c-1.9,0.9-2.4-0.8-2.6-1.8
		c-0.9-5.1-3.8-5-7.6-3.2c-0.7,0.1-1.4-0.1-2.1-0.5C237.4,162.7,238.3,162.2,239.3,161.8L239.3,161.8z"/>
	<path class="st1297" d="M250,169.7c0-0.7,0.1-1.5,0.1-2.2c1.2,3.4,2.5,6.8,3.7,10.2c-0.3,0-0.6,0-0.9,0
		C249.5,175.9,249.1,173.1,250,169.7z"/>
	<path class="st1279" d="M239.2,161.8c-0.4,0.6-0.8,1.2-1.2,1.8c-5.6,5.3-10.1,11.6-15.3,17.2c-0.2-0.1-0.4-0.2-0.6-0.3
		c1.2-4,1.6-8.2,6-10.5c2-1,3.2-3.2,4.1-5.4C234.8,164.5,236.3,161.4,239.2,161.8z"/>
	<path class="st1250" d="M347.9,185.6c-2.7,0.3-1.8,2.4-2,4c-0.4,3.6-2.3,5.5-6.1,5.3c-5.4-0.2-10.1,1.1-12.7,6.4
		c-0.2,0.5-0.8,0.4-1.3,0.3c-1.6-2.6-1.7-5.3-0.8-8c1-2.2,2-4.4,3.1-6.5c1.5-3.1,3.5-5.6,7.5-5.5c1.5,0.1,3.2,0.2,4-1.4
		c1.4-2.8,3.9-3.3,6.3-2.3c2.3,0.9,2.1,3.5,2.1,5.7C347.9,184.3,347.9,185,347.9,185.6z"/>
	<path class="st1298" d="M281,200.6c9.7-11.4,23.3-9,35-4c0,1.4,0.7,2.4,2,3c-0.6,5.5-2,10.8-4,16c-1,3-2,6-3,9.1
		c-0.4,0.4-0.7,0.4-1-0.1c1.8-8.3,4.9-16.3,4.1-25c-0.6-0.6-0.8-1.8-1.6-1.9c-7-0.8-14.3-2.6-21-0.3c-6.2,2.1-8.3,8.7-10,14.7
		c-0.5,1.7-0.3,3.7-1.6,5.2c-1,0.7-1.7,2.3-3.4,1.1C277.2,212.2,277.2,205.9,281,200.6z"/>
	<path class="st1299" d="M325,193.6c1.5,2.5,0.8,5.3,1,8c2.4,2,1.7,4,0,6c-1.7,1.4-3.8,5.6-4.7,9.3c-2.8-2.6-3.2-6.1-1.3-11.3
		c0.7-4-0.3-8.3,2-12C323,193.6,324,193.6,325,193.6z"/>
	<path class="st1300" d="M322,193.6c-0.2,4.1,0.9,8.4-2,12c-2.5-1.5-2.1,0.8-1.9,1.7c0.6,3.8-0.9,6.4-4.1,8.3c0.5-5.5,1.1-11,4-16
		C319.3,197.6,317.6,193.6,322,193.6z"/>
	<path class="st1293" d="M303.7,187.5c2.4,0.3,4.6-0.4,6.6,1.1C308,189.6,305.8,190.8,303.7,187.5z"/>
	<path class="st1301" d="M326,207.6c0.7-2,0.7-4,0-6c2.7-6.4,5.1-8.2,12.6-7.9c3.5,0.1,6-0.7,7.3-4.1c-0.2,6.7,0.6,13.4-1,20
		c-0.3,0-0.7,0-1,0c-0.3-2.9-2.6-3.9-4.8-3.8c-3.1,0.1-6.1,0.5-9-0.9C327.3,203.4,329,209.2,326,207.6z"/>
	<path class="st1302" d="M247.1,248.6c0,0.4,0,0.8,0,1.2c-0.7-0.3-1.4-0.6-2.1-0.9c-1-1.7-3.5-2.3-3.3-4.8c2.4-2.9,4.5-2.9,6.3,0.6
		C248.3,246.1,248.2,247.4,247.1,248.6z"/>
	<path class="st1303" d="M234.1,240.5c-0.3-0.6-0.7-1.3-1-1.9c-0.1-2,1.3-1.2,1.8-0.8c2.3,1.8,5,3.3,5.4,6.6
		c-0.3,0.5-0.7,0.6-1.2,0.1C237.5,243.2,235.8,241.8,234.1,240.5z"/>
	<path class="st1284" d="M239.2,244.5c0.3,0,0.6,0,1,0c0.3-0.3,0.6-0.5,1-0.5c0.4,0.1,0.7,0.3,0.9,0.6c1.2,1.3,2.7,2.3,2.9,4.2
		C242.7,247.8,240.9,246.2,239.2,244.5z"/>
	<path class="st1304" d="M247.1,248.6c0-1.3,0-2.7,0-4c0.7-2.3,1.2-4.5-1.6-6c-1.1-0.6-2.7-1.3-2.4-2.8c0.3-1.9,2.1-2.5,3.8-2.8
		c4.2-0.9,8.6-0.5,12.7-2.4c0.6-0.3,1.3-0.2,1.8-1.3c-1.9-2.9-5.2-4.7-7.8-7.1c-2.5-2.1-3.8-4.2-0.8-6.9c1.9,0,3.2,1.1,4.1,2.6
		c1.8,4.3,5.2,5.3,9.4,4.9c0.4,0.1,0.9,0.2,1.3,0.4c1.9,1.4,3.9,1.3,6,0.6c2.8,0.4,4.4-0.8,5.1-3.5c0.4-1.5,0.9-2.8,1.9-4
		c0.8-1.5,0.4-3.1,0.6-4.7c0.4-1.5,0.8-3.1,1.8-4.4c1.1-1.1,1.7-2.5,2.2-4c0.2-0.4,0.4-0.8,0.7-1.1c1.3-0.9,2-2.3,2.8-3.6
		c0.9-0.9,1.8-1.8,3.2-1.9c3.9-0.4,7.7-0.2,11.5,0.4c0.2,0.2,0.4,0.4,0.6,0.6c8.7,3.1,7.8,3.1,7.4,11.1
		c-0.7,11.3-6.1,20.4-11.4,29.7c-0.3,0.3-0.7,0.7-1,1c-1.2,0.8-2.2,1.8-2.9,3c-5.4,1.6-8.2,6.9-13.7,8.6c-9.5,2.8-18.9,3.8-28.3-0.3
		C251.7,249.9,249,250.3,247.1,248.6z"/>
	<path class="st1285" d="M296,242.5c0.1-1.8,1.1-2.8,2.9-3C298.4,241,297.3,241.9,296,242.5z"/>
	<path class="st1305" d="M284,207.6c-1.3,1-1.8,2.4-2,4c-1.2,1.5,0.2,3.5-1,5c-0.3,1.1-0.8,2.1-1,3.2c-0.8,3.9-2.8,6-7,4.8
		c-2.2,1.1-4.1,0.3-6-1l0,0c-4-0.9-8.4-1-11-5c-1-1-2-2-3-2.9c-6.4-2.6-8.3-6.2-5.7-11.2c0.5-0.1,1,0.1,1.3,0.6
		c0.7,1.5,1.1,3.1,2.3,4.3c5.8,5.7,12.5,9.8,20.7,11.4c4.2,0.8,6.1-0.5,7.4-4c1.6-6.2,2.9-12.6,7.5-17.6c2.8-3,5.9-4.6,10.1-3.9
		c4.6,0.7,9.2,1,13.8,1.5c1.9,0.2,3.8,0.1,3.5,2.9c-3-2.6-6.6-1.9-10.1-1.9c0,0,0,0,0,0c-4.3-0.8-8.7-0.8-13,0c-0.7,0.7-1.3,1.3-2,2
		c-0.8,1.2-1.8,2.2-3,3l0,0C285.1,204.2,284.8,206,284,207.6z"/>
	<path class="st1251" d="M310,224.6c0.3,0,0.7,0,1,0.1c1.2,2.9,0.6,4.4-2.9,3.9C308.7,227.2,309.3,225.9,310,224.6z"/>
	<path class="st1306" d="M232.1,237.6c-2-2.3-4-4.6-6-6.9c-0.4-0.7-0.7-1.4-1.1-2.1c-1.7-2.2-2.7-4.8-3.7-7.4
		c-0.5-1.1-1.1-2.1-0.6-3.4c0.3-0.4,0.7-0.6,1.2-0.6c0.5,0.1,0.9,0.3,1.3,0.6c0.3,0.3,0.6,0.7,0.9,1.1c1.9,5.9,5.3,10.9,8.9,15.8
		C233.1,235.9,232.9,236.8,232.1,237.6z"/>
	<path class="st1287" d="M221.8,220.6c1,2.6,2.1,5.3,3.1,7.9c-3.5-1-2.8-5-4.9-7c0-0.3,0-0.7,0-1c0.2-0.1,0.4-0.3,0.5-0.4
		C221.1,220.1,221.5,220.3,221.8,220.6z"/>
	<path class="st1302" d="M232.1,237.6c0-1,0-2,0-3c0.8-1.4,1.7-2,3.1-0.7c3.1,2.9,5.7,6.2,5.9,10.7c-0.3,0-0.6,0-0.9-0.1
		c-2.2-2.1-3.4-5.5-7-5.9C232.7,238.2,232.4,237.9,232.1,237.6z"/>
	<path class="st1287" d="M218.1,217.6c0.3-0.3,0.7-0.7,1-1c0.2-0.1,0.4-0.2,0.6-0.3c0.4,0.2,0.7,0.5,0.9,1c-0.2,1.1,0.4,2.3-0.5,3.3
		c0,0-0.1,0.1-0.1,0.1C218.8,220,218.1,219,218.1,217.6z"/>
	<path class="st1290" d="M216.1,205.6c0-5.1,0-10.2,0-15.3c0-1.9-0.1-3.7,2-4.6c0,0,0,0,0,0c1.3,1.4,1,2.9,0.8,4.6
		c-0.7,6-0.7,12,0,18.1c0.2,1.2,0.1,2.4-0.9,3.3C215.4,210.3,216.3,207.8,216.1,205.6z"/>
	<path class="st1279" d="M218,211.6c0-1,0-2,0-3c0.3-0.1,0.5-0.1,0.8-0.2c2.1,2.4,3.1,4.9,1.4,8c-0.4,0-0.7,0.1-1.1,0.1
		C218.7,214.9,218.4,213.3,218,211.6z"/>
	<path class="st1279" d="M218,208.7c-1.9-6.2-0.9-12.4-0.5-18.6c0.1-1.5,0.4-2.9,0.6-4.4c1.8-1.3,1.7-4.1,3.9-5.1
		c0,0,0.3,0.2,0.3,0.2l-4,27.7L218,208.7z"/>
	<path class="st1307" d="M281,200.6c-1.3,6-2.7,12-4,18c0.2,0.8,0.4,1.7-0.3,2.3c-2.3,1.7-9.1-0.5-10.7-3.3
		c-2.6-3.2-4.5-6.8-8.7-8.9c-2.7-1.3-1.6-5.7-1.3-8.7c0.3-2.5,1.5-5,4-6.4c2.8,0.2,1.2-4.3,4.6-4.1c6.1,0.5,12.3-0.7,18.1,2.2
		c1.9,1,0.7,2.7-0.3,3C278.2,195.9,280,198.3,281,200.6z"/>
	<path class="st1308" d="M266,217.6c3.9,3.2,8.8,3.7,11,1c0.7-0.6,1.4-1.3,2.1-1.9c-1.1,6.3-1.1,6.2-7.5,5.1
		c-8.6-1.5-15.8-5.5-21.6-12c-1.7-2.6-0.4-4.9,0.8-7.2c0.3-0.4,0.6-0.7,0.9-1c0.5-0.2,0.9-0.1,1.3,0.1c0.6,2.5-2.3,6-0.5,7
		C257.4,211.1,259.5,217.7,266,217.6z"/>
	<path class="st1309" d="M253.1,201.6c-0.4-0.1-0.8-0.1-1.2-0.2c-0.8-1.4,0.1-2.1,1.1-2.8c0.3-0.1,0.6-0.1,1-0.2
		C254.1,199.6,254.3,200.8,253.1,201.6z"/>
	<path class="st1310" d="M250,169.7c0.8,2.7,1.3,5.6,3,8c0.2,0.6,0.2,1.3,0.5,1.9c4,6.7,1.8,12.9-1.5,19c-0.3,0.4-0.6,0.7-1,0.9
		c-3.5,2.3-5.1,6.4-8.5,8.9c-1.8,1.3-3.1,3.3-5.3,4.3c-2.9,1.2-2.4,5-5.2,6.9c-5.1,3.5-5,3.9-8.9-1l0,0c-1.6-2.1-1.1-5-2.8-7.1
		c-1.5-4.3-1.9-8.7-1.7-13.3c-0.3-1.2,3.3,0.5,1.4-2.1c-0.8-1.1-0.1-2.9,0-4.3c2.2-12.6,9.2-21.7,20.1-28c0.9-0.5,1.7-0.9,2.6-1.4
		c3-1.8,4.6-0.8,5.3,2.5C248.4,166.5,248.7,168.4,250,169.7z"/>
	<path class="st1311" d="M251,198.6c0.3,0,0.6,0,0.9,0c0.3,0,0.7,0,1,0c-0.4,0.9-0.7,1.9-1.1,2.8c0,0,0.1,0.1,0.1,0.1
		c-1.2,1.5-1.1,4-3.7,4.4c-0.1-0.4-0.1-0.9-0.2-1.3C246.8,201.5,249.9,200.5,251,198.6z"/>
	<path class="st1312" d="M219.2,197.7c-0.7,4.8,0.7,9.4,1.9,13.9c0.9,1.9,2.1,3.7,1,6c-0.3,0-0.6,0-0.9,0c-0.7,0-0.7-0.6-1-1
		c0,0,0-0.1,0-0.1c-0.1-2.8-0.8-5.4-2-7.9c-0.8-9.6,0-18.9,4-27.7c4.3-6.5,8.6-13.1,15.7-17.2c0.7,0,1.5,0.1,2.2,0.1
		c-7.2,6.4-14.3,12.9-18.2,22.1c-0.8,1.9-0.8,3.8-0.9,5.8C220,193.5,221.1,196.1,219.2,197.7z"/>
	<path class="st1313" d="M251,198.6c-1,2-2,4-3,6c-2.3,3.3-0.4,5.8,1.8,8.2c1,1,2.7,1.3,3.2,2.9c-1.1,2.3-1.8,3.6,1.3,5.8
		c2.8,1.9,6.5,3.6,8.2,7.4c1,2.4,0.2,2.5-1.5,2.7c-4,0.6-7.7,2.6-12.1,2.2c-1.8-0.2-3.9,0.5-5.4,2.2c2.2,2.5,10.1,2.8,3.5,8.7
		c-1.7-3-3.3-1.6-5,0c0,0-0.5,0-0.5,0s-0.5,0-0.5,0c-2.2-3.4-4.3-6.8-6.5-10.2c-0.9-1.4-1.6,0.2-2.5,0.2c-4.9-4.3-7.4-9.8-9-16
		c1-0.4,1.6-0.4,2.1,0.9c1.3,2.9,3.1,2.8,4.6,0.2c1.3-2.4,5.7-2.9,4.1-7.1c-0.2-0.6,1.6-0.8,2.5-1c2.1-0.5,3.3-2.5,4.7-3.6
		C245,205.2,246.2,200,251,198.6z"/>
	<path class="st1313" d="M256.1,218.6c3.2,2.6,7.7,2.5,11,5C260.7,224.5,258.2,223.3,256.1,218.6z"/>
	<path class="st1285" d="M291,197.6c4.3-1.6,8.7-1.6,13,0C299.7,197.6,295.3,197.6,291,197.6z"/>
	<path class="st1313" d="M267,223.6c2,0.3,4,0.7,6,1C270.7,226.2,268.6,226.2,267,223.6z"/>
	<path class="st1285" d="M284,207.6c0-1.9,0.4-3.7,2-5C286.1,204.6,285.6,206.3,284,207.6z"/>
	<path class="st1285" d="M281,216.6c0.2-1.7-0.7-3.6,1-5C281.8,213.3,282.7,215.2,281,216.6z"/>
	<path class="st1285" d="M286,202.6c0.6-1.4,1.6-2.3,3-3C288.9,201.5,287.9,202.5,286,202.6z"/>
	<path class="st1309" d="M248.2,205.9c1.2-1.5,2.5-2.9,3.7-4.4c-0.1,2.9-2.5,5.2-1.9,8.2C248.6,208.8,248.1,207.5,248.2,205.9z"/>
	<path class="st1313" d="M222.1,217.6c-0.3-2-0.7-4-1-6c3.1,1.6,1.6,4.5,2,6.9C222.8,218.2,222.4,217.9,222.1,217.6z"/>
	<path class="st1279" d="M220.2,216.5c0.3,0.3,0.6,0.7,1,1c0.2,1,0.5,2,0.7,3.1c-0.6,0-1.1-0.1-1.7-0.1
		C220.1,219.2,220.2,217.9,220.2,216.5z"/>
	<path class="st1313" d="M219.2,197.7c0.5-2,0.5-4.3,1.9-6c0,2.1,0.3,4.2-0.1,6.2C220.6,199.8,219.8,197.6,219.2,197.7z"/>
</g>
<g id="mano" [@ManoTrigger]="isEstadoMano" (click)="clickEvent('mano')">
	<path class="st1314" d="M1013.1,559.5c0,0.3,0,0.6-0.1,0.9c-0.6,0.7-0.4,1.7-0.7,2.5c-0.9,2.5-2.8,3.7-5.3,4
		c-0.7-0.1-1.7-0.3-1.7-0.8c0.6-6.4-3.6-11.9-3.5-18.2c0-2.2-0.2-4.4,1.9-5.7c1.8-1.1-0.7-1.6-0.2-2.5c0.2-0.6-0.2,0.1,0-0.5
		c1-3.2,2.3-7.1,6-6.9c3.2,0.2,2.5,4.4,3.5,6.9C1013.9,546,1014.1,552.8,1013.1,559.5z"/>
	<path class="st1315" d="M842.3,698.2c-0.2-0.2-0.7-0.4-0.7-0.7c-0.1-13.9-0.2-27.8,0.1-41.7c0.2-9.6,3.4-19.1,1.5-28.7
		c-1.3-6.3-4.7-12.1-4-18.9c-0.6-1-0.5-2.1-0.1-3.1c1.3-0.4,2,0.5,2.1,1.4c0.8,8.4,4.4,16,6.8,24c-0.1,0.8-0.4,1.6-0.8,2.3
		c-1.4,3.3-1.8,6.8-1.4,10.4c-1.7,15.5-0.7,31-0.9,46.5c0,1.8,0,3.6-0.5,5.4C844.1,696.4,843.5,697.5,842.3,698.2z"/>
	<path class="st1316" d="M977.2,918c-4.1,2.1-8.5,3.3-13,3.7c-1.1-0.6-3.3,0.5-3-2.1c1.1-1.1,2.6-1.1,3.9-1.6c2-0.6,3.9-1.2,5.9-1.9
		c1.1-0.4,2.1-0.7,3.2-1c2.6-0.7,4.9-2,7.3-3.1c0.6-0.3,1.2-0.6,1.8-0.8c1.9-0.6,3.6-1.7,5.2-2.8c0.5-0.4,1-0.9,1.5-1.3
		c1.7-1.2,3-3.2,5.5-1.9c0.3,0.7,0.1,1.4-0.3,2c-4.5,3.1-8,7.9-13.9,8.8C980.1,916.9,978.4,916.9,977.2,918z"/>
	<path class="st1317" d="M837.2,593.2c-1.4-8.9-3.3-17.6-2.9-26.7c0.2-3.5,0.1-7,0-10.5c-0.1-3.5,1.1-6.1,4-7.9
		c1.3,1.4,0.4,3,0.2,4.4c-0.1,0.5-0.2,1-0.4,1.5c-0.8,3.4-0.2,6.9-0.4,10.3c0,1.7,0,3.4,0,5.1c-0.4,6.1,0.9,12.1,1.4,18.2
		c0.2,1.8,0.6,3.6-0.4,5.3C838.4,593.4,837.8,593.5,837.2,593.2z"/>
	<path class="st1318" d="M1003.3,814.1c1.2,4.9,2.6,9.7,2,14.9c-1.1,10.1-2.1,20.2-3.1,30.3c-0.1,0.7-0.3,1.3-1.1,1.5
		c-0.2-0.2-0.4-0.4-0.6-0.7c-1-2-0.7-4.2-0.5-6.3c0.6-9.9,1.6-19.8,1.8-29.7c0.2-2.3-1.2-4.2-1.6-6.3c-0.1-2.3-0.3-4.7,2.9-5.1
		C1003.6,813.1,1003.7,813.6,1003.3,814.1z"/>
	<path class="st1319" d="M1012.3,489.2c1.7,1.5,0.9,3.4,0.8,5.1c-0.1,10.6,0.9,21.4-1.9,31.8c-0.2,0.4-0.4,0.7-0.7,1
		c-1.2,0.7-1.7,2.6-3.6,2c-0.3-0.3-0.4-0.7-0.5-1.1c0.2-1.9,0.2-3.8-0.4-5.6c0.7-1.3,2.3-1.8,2.9-3.1c1.7-7.2,0.4-14.5,0.8-21.7
		c0.2-2.8-0.5-5.6,1.1-8.2C1011.2,489,1011.8,488.9,1012.3,489.2z"/>
	<path class="st1320" d="M862.2,790c-2.7-0.9-3.1-3.6-3.9-5.7c-3.6-8.7-7.6-17.4-6.7-27.3c2.7,0.6,1.8,3.2,2.6,4.8
		c0.4,2.1,1,4.2,1.9,6.2c1.2,5.7,3.3,10.9,5.9,16.1c0.5,0.8,1,1.6,1.6,2.4c0.9,1.7,2.4,3.3,1.1,5.4
		C862.5,792.7,863.2,790.3,862.2,790z"/>
	<path class="st1321" d="M1022.1,778.1c0,0.3,0,0.6-0.1,1c-0.6,0.8-1.2,1.7-2.4,0.7c-0.6-0.9-0.6-1.9-0.5-2.9
		c2.5-6.3,5.7-12.2,8.7-18.3c1.7-2.7,2.5-5.1-1.6-6.4c-0.6-0.2-0.5-1.1,0.1-1.6c2.4-0.8,4.8-1.8,7.5-0.9c0.7,0.6,0.7,1.3,0.7,2.1
		c-0.1,3.2-1.7,5.9-3.5,8.4c-0.4,1.3-0.7,2.7-1.1,4c-1.2,1.6-1.9,3.4-2.9,5.1c-0.3,0.3-0.6,0.5-1,0.8c-0.9,1.2-0.7,3-2,4l0.1-0.1
		C1023.4,775.2,1023.2,776.9,1022.1,778.1z"/>
	<path class="st1322" d="M1020.1,776.9c0,1,0,2,0,3.1c1.2,3.4-2.7,5-2.9,7.9c-1.7,1.3-2,3.5-3.1,5.1c-0.8,1-1.3,2.2-1.9,3.3
		c-1.3,2-2.1,4.4-4.1,6c-1.1,0.1-1.8-0.4-2.3-1.3c-0.2-1,0.2-1.9,0.7-2.8c2.8-7.7,7.6-14.3,12.3-20.9
		C1019.1,776.8,1019.6,776.8,1020.1,776.9z"/>
	<path class="st1323" d="M842.3,698.2c0.3-0.7,0.7-1.4,1-2.1c0.5,0.4,1,0.9,1.2,1.6c1.2,6.5,2.6,12.9,3.3,19.4
		c-0.5,4.5,1.1,8.7,2.4,12.9c0.3,1.3,0.5,2.6,0.1,4c-0.2,0.5-0.5,1-1,1.3c-2.6-5.7-5.7-11.2-4-17.8c0.8-3.3-2.1-5.5-2.2-8.9
		C842.9,705.1,842.6,701.6,842.3,698.2z"/>
	<path class="st1324" d="M970.4,529.2c1.9-1.9-0.5-5.6,2.8-6.9c0.4,0.3,0.6,0.7,0.8,1.2c0.5,1.8-0.4,3.4-0.6,5.1
		c-0.1,0.7-0.3,1.5-0.5,2.2c-0.7,2.3-0.3,4.7-0.9,6.9c-0.8,6-0.7,12,0,18c0.3,1.1,0.4,2.1,0.4,3.2c0,1.3,0,2.7-0.6,4
		c-1,1.8-2.5,2.7-4.4,2.9c-0.5,0-1-0.2-1.4-0.6c3.8-3,3.5-7.2,2.8-11.3c-0.2-1.4-0.4-2.4,0.3-3.6c-0.8-5.4-0.7-10.8,0-16.2
		C969.1,532.3,969.1,530.6,970.4,529.2z"/>
	<path class="st1325" d="M1048.1,445.4c2.8,2.3,4.6,5.6,5.8,8.8c1.9,5.3-2.4,8.5-5.2,11.9c-2.2,2.7-5,4.8-7.5,7.2
		c-0.6,0.5-1.3,0.6-2,0.2c-1-2.3,1-3.3,2.1-4.5c2.9-3.2,6.2-6,9-9.3c2.1-2.8,0.4-5.2-0.6-7.7c-0.8-2-2.9-3.6-2-6.2
		C1047.8,445.7,1048,445.5,1048.1,445.4z"/>
	<path class="st1326" d="M907.2,619.1c-0.6-3.1,1.8-4.3,3.7-5.9c0.6-0.3,1.2-0.6,2-0.5c2.6,3,6.5,2.1,9.7,3.3
		c1.6,0.4,3.1,0.9,4.7,1.4c4.1,1.6,8.3,2.9,11.2,6.6c0.1,0.5,0.1,1-0.2,1.4c-2.2,1.1-3.7-0.4-5.5-1.3c-2.2-1.5-4.4-2.6-7.1-2.7
		c-2.3-0.6-4.8-0.7-7-1.9c-1.9-1.3-4-0.8-6.1-0.9C910.8,618.1,909.2,619.9,907.2,619.1z"/>
	<path class="st1327" d="M1036.4,729c0.3,1.3,0.5,2.6,0.8,3.9c0.3,0.3,0.4,0.5,0.3,0.8c-0.1,0.3-0.2,0.4-0.2,0.4
		c-0.5,0.1-0.9,0.1-1.4,0c-2.5-2.3-5.7-0.8-8.5-1.8c-2.1-0.2-4.3-0.1-6.2-1.4c-2-1.7-4.2-3.1-6.4-4.5c-1.2-1-2.2-2-2.3-3.6
		c1-3.9,3-1.4,4.2-0.4c3.3,2.6,6.7,4.9,11,5.4C1030.4,729.4,1033.7,727,1036.4,729z"/>
	<path class="st1328" d="M883.4,570.1c3.6,5.1,3.2,11.4,5,17.1c-0.2,0.1-0.4,0.1-0.6,0.2c-0.4-0.1-0.9-0.3-1.2-0.5
		c-1.6-1.3-3.5-1.6-5.5-1.8c-1.7-3.3,1.4-6.6-0.1-9.9c0-0.5,0.2-0.9,0.6-1.2c0.4-0.6,3,0.7,1.3-1.7
		C882.2,571.6,882.7,570.6,883.4,570.1z"/>
	<path class="st1329" d="M1044.1,719.2c-0.3,0.3-0.6,0.5-0.9,0.8c-1,1.2-0.9,3.3-3,3.6c-0.5,0-0.9-0.2-1.3-0.5
		c-0.2-0.4-0.4-0.9-0.4-1.3c1.4-3.5,3.3-6.8,4.9-10.2c1.1-2.2,0.2-3.3-1.4-4.5c-0.1-2.4,2-3.2,3.3-4.7c3.1-1.5,3.9-0.1,3.7,2.8
		c-0.4,2.4-0.8,4.7-1.9,6.9l0-0.1C1046.4,714.5,1045.7,717,1044.1,719.2z"/>
	<path class="st1330" d="M1015,573c0.1,0.3,0.2,0.6,0.1,1c1.2,8,0.4,15.8-1.1,23.7c-0.2,1.3-0.4,2.6-0.5,3.9
		c-0.2,0.6-0.6,0.9-1.3,0.9c-1.8-1.5-1.4-3.5-1.2-5.4c1.4-5.4,0.6-11,1.9-16.4c0.6-2.7-2.4,0-2.3-1.7c-0.9-2.9,0.3-5.6,0.7-8.4
		c0.5-1,1.3-1.6,2.4-1.6C1015.4,569.9,1014.1,571.8,1015,573z"/>
	<path class="st1331" d="M1000.3,866.8c4.2,11,4.6,22.1,0,33.1c-0.6,0.3-1,0.2-1.4-0.3c-0.4-1-0.4-1.9,0-2.9
		c2.8-8,2.1-16.1,0.5-24.3c-0.3-1.8-0.9-3.6-0.4-5.5C999.3,866.5,999.8,866.4,1000.3,866.8z"/>
	<path class="st1332" d="M866.2,856.9c0.3-4.1-3.3-7.6-2-11.9c-0.9-4.6-0.3-9.2-0.3-13.9c3.4-0.5,2.4,2.3,2.7,3.8
		c0.6,3.7-0.5,7.5,0.6,11.1c1,4.8,3.2,9.5,2.4,14.6c-0.6,0.7-1.4,0.8-2.2,0.5C866.2,860,867.2,858.1,866.2,856.9z"/>
	<path class="st1333" d="M866.2,856.9c1.6,1,0.9,2.6,1.1,4c0.3,2.7-0.9,5.2-1.2,7.8c-0.7,7-0.7,14,0.1,21c0.2,1.2,0.1,2.3-0.8,3.3
		c-2.6-0.7-2-2.8-2.1-4.6C863.2,877.7,862.8,867.1,866.2,856.9z"/>
	<path class="st1334" d="M853.3,762c-1.8-1.2-0.2-3.7-1.8-5c-0.9-1.2-1.4-2.4-1.2-3.9c-1.1-1.9,0.1-4.1-0.8-6.1
		c-0.1-0.7-0.1-1.3-0.2-2c-1.1-3.5-0.9-6.5,2.7-8.5c0.8-0.3,1.6-0.3,2.3,0.2c1.4,2.1-0.8,3.4-1.1,5.1c-1.1,4-0.4,7.8,0.6,11.7
		c0.5,2.1,1,4.2,0.6,6.4C854.4,760.7,854,761.5,853.3,762z"/>
	<path class="st1335" d="M861.3,547.1c0.4,0.9,1.3,1,2.2,1.2c1,0.9,2.3,1.3,3.3,2c0.6,0.7,1.3,1.4,0.2,2.3c-4.4,0.4-7.1-3.7-11-4.5
		c-3.9-1.1-7.7-0.9-11.5,0.6c-0.6,0.4-1.2,0.7-1.9,0.9c-0.5,0.1-0.9,0.2-1.4,0.1c-0.5-0.1-0.8-0.4-1.1-0.8c0.1-2.7,2.6-2.8,4.3-3.9
		l-0.1,0.1C850.3,542.3,855.9,543.6,861.3,547.1z"/>
	<path class="st1336" d="M1012.2,596.2c0,2,0,3.9,0,5.9c-0.1,2.1,0.4,4.2-1,6.1c0,1.1-0.1,2.1-0.1,3.2c-1.3,2.8-1.4,6.5-5.4,7.4
		c-1.5,0.1-2.6-0.6-3.2-1.9c-0.7-3.5-1.3-7.1,0.5-10.5c0.4-0.4,0.9-0.4,1.4-0.2c1.6,1.9,0.7,4.4,1.6,7.4c2-3.1,2.7-5.8,3-8.8
		c0.3-3,0.1-6.3,2.5-8.7C1011.7,596.1,1011.9,596.1,1012.2,596.2z"/>
	<path class="st1337" d="M1006.2,619.1c-0.8,1.3-0.9,2.7-1.1,4.2c-0.1,0.6-0.4,1.5-1.6,1.2c-0.5-0.1,0-3.4-1-0.5
		c-0.2,0.7-3.2,1.9-0.5,3.4c1.2,0.7,2.8,1,3.4,2.6c-5,2.1-7.6,0.3-7-4.8c0.1-1,0.4-1.9,0.5-2.9c0.7-3.8,3.2-4.6,6.6-3.7
		C1005.7,618.8,1005.9,619,1006.2,619.1z"/>
	<path class="st1338" d="M979.2,457.2c0.1-5.5-0.8-11.2,3.2-15.9c0.6-0.2,1.1,0,1.4,0.6c0.6,5.3-1.5,10.2-1.8,15.4
		c-0.2,2.6,0.3,5.6-1.5,8c-0.6,0.3-1.1,0.3-1.6-0.2C978.8,462.5,978.3,459.8,979.2,457.2z"/>
	<path class="st1339" d="M1018.2,458.2c-4.1,3.6-2.6,8.9-3.8,13.3c-0.8,2.9-1,6-2.3,8.8c-0.5,0.3-1,0.2-1.3-0.3
		c-0.2-0.5-0.4-1-0.4-1.5c0.5-3.8,1.6-7.6,2-11.4c0.8-2.5,0.1-5.2,0.9-7.7c0.6-1.9,0.5-5,4.1-4.1
		C1018.1,456,1018.6,457,1018.2,458.2z"/>
	<path class="st1340" d="M1010.2,413.1c6.7-3.8,9.9-2.6,11.1,4.2c-0.2,0.1-0.4,0.2-0.5,0.3c-1-0.2-1.7-1.1-2.5-1.6
		c-0.9-0.6-1.7-1.1-2.8-1.2c-1.9-0.1-3.2,1-4.6,2c-0.9,0.6-1.7,1.4-2.6,1.9c-0.4,0.1-0.9,0-1.3-0.2c-0.3-0.3-0.6-0.7-0.8-1.1
		c-0.3-1.4-1.9-2.6-0.9-4.3C1006.9,411.9,1008.5,414.6,1010.2,413.1z"/>
	<path class="st1341" d="M902.2,630.1c1.1-1.5,2.2-3,3.4-4.5c0.5,0,0.8,0.2,1.2,0.5c1.8,2.9,4.8,1.2,7.2,2.1
		c1.8,1.3,6.6,0.7,3.3,5.2c-3.4,0.4-6.6-1.5-10.1-1.1C905.1,632.3,903.1,632.3,902.2,630.1z"/>
	<path class="st1342" d="M939.3,605.3c7.1-2.7,14.7-0.6,21.9-2.3c2.2,1.7,2.1,2.6-0.8,4.3c-6.9-0.9-13.8-0.1-20.6-0.5
		C939.1,606.4,939,605.9,939.3,605.3z"/>
	<path class="st1343" d="M877.2,561.2c0,0.3,0.1,0.7,0.1,1c1.3,2.2-0.5,2.7-1.9,3c-3,0.6-5,2.4-6.9,4.4c-0.8,1.1-1.3,2.6-2.9,2.8
		c-0.4-2-3-1.8-3.6-3.6c1.2-4.1,5.1-4.7,8.2-6.2c1.8-0.8,3.8-1,5.6-1.7C876.3,560.8,876.8,560.9,877.2,561.2z"/>
	<path class="st1344" d="M1013.1,602c1.2-9.3,2.3-18.7,2.1-28.1c2.1,8,1.3,16-0.5,23.9c-0.4,1.7,0.6,4-1.5,5.3
		c-0.3-0.2-0.4-0.5-0.3-0.7C1012.9,602.2,1013,602,1013.1,602z"/>
	<path class="st1345" d="M876.3,904.7c-1.5-2.9-4.4-4.1-6.9-5.9c-0.6-0.6-0.6-1.3-0.3-2c3.7-1.5,5.1,2.1,7.6,3.3
		c1.8,3.4,6.2,4.2,7.9,7.7c-0.5,1-1.2,1.5-2.3,1.1l0,0C880.7,907,877.6,907.1,876.3,904.7z"/>
	<path class="st1346" d="M903.1,918.8c-3.9-0.5-7.8-1-10.9-4c0.6-0.8,1.5-0.9,2.4-0.8c0.4,0.1,0.9,0.2,1.3,0.3
		c1.9,0.7,3.8,1.3,5.8,1.2c0.7,0,1.5,0,2.2,0c2.8,0.8,6.7-0.1,7.1,4.6C908.3,920.7,905.7,919.9,903.1,918.8z"/>
	<path class="st1347" d="M985.2,421.3c-0.5-4.4,2.9-10.8,9-12.5c0.3,0.3,0.5,0.7,0.4,1.2c-0.5,1.9-2.4,2.5-3.4,4
		c-0.5,0.7-1,1.4-1.5,2.1c-1,1.5-1.4,3.2-1.9,5c-0.8,1.3-0.3,3.7-2.9,3.2C984.7,423.2,984.5,422.2,985.2,421.3z"/>
	<path class="st1348" d="M994.1,409.2c0,0,0-0.5,0-0.5c3.5-2,6.6-1.8,9.8,0.8c1.8,1.5,4.1,2.4,6.2,3.6c-1.4,2.6-3.1,0.1-4.6,0.3
		C1001.8,411.9,997.8,411,994.1,409.2z"/>
	<path class="st1349" d="M883.4,570.1c-1.7,0.8,3.6,5.2-2,4.1c-2.1-0.7-3.9-2-6.2-2.2c-0.4-0.2-0.8-0.5-1.1-0.8
		c-0.1-0.8,0.4-1.3,1-1.6c1.8-0.5,3.8-0.7,3.6-3.4c0.1-0.4,0.3-0.8,0.7-1.1C881,566.5,883.2,567.4,883.4,570.1z"/>
	<path class="st1350" d="M1013.1,559.5c-0.5-7.1,0.4-14.2-0.9-21.3c-1-1.4-0.4-2.9-0.6-4.4c0.2-0.6,0.6-0.8,1.2-0.6
		C1014.9,542,1014.9,550.7,1013.1,559.5z"/>
	<path class="st1351" d="M1046.3,678.2c0.6,3.3,1.1,6.6,1.7,9.9c-0.8,2.9,0.6,5.8,0.3,8.7c-0.1,0.8-0.4,1.6-0.9,2.3
		c-0.5,0.3-1,0.3-1.5-0.1c-0.7-0.9-0.9-2-1-3.1c-0.2-3.4,0.3-6.8-0.5-10.2c-0.3-1.2-0.4-2.4-0.5-3.6c-0.1-0.9-0.4-1.8-0.7-2.7
		c-0.5-1.8-0.5-3.3,1.9-3.6C1046,676.3,1046.1,677.2,1046.3,678.2z"/>
	<path class="st1352" d="M1000.1,622.1c0,0.5,0.1,1-0.1,1.5c-1.4,6.2-1.2,6.4,5.2,6.4c3,0.1,6.4-0.5,6.9,4c0.5,0.4,0.4,0.8-0.1,1.2
		c-2.2-0.1-3.5-2-5.4-2.6c-1.5-0.3-3-0.2-4.5-0.3c-2.5-0.1-4.9-0.6-5.1-3.6C996.9,626.2,996.2,623.1,1000.1,622.1z"/>
	<path class="st1353" d="M888.3,587.1c5.3,5.8,4.6,13,4.8,20c-0.5,0.3-1,0.1-1.3-0.4c-1.3-3-1.4-6.4-2.3-9.5c-0.3-3-2-5.7-1.9-8.8
		C887.7,588,887.9,587.5,888.3,587.1L888.3,587.1z"/>
	<path class="st1354" d="M961.1,606.3c0.8-1.1,1.1-2.2,0-3.3c0-0.3,0.1-0.6,0.3-0.9c2.9-3.9,0.7-8.3,1.3-12.5
		c0.2-1.6-0.9-3.3,0.8-4.6c1.4,0.3,1.9,1.4,1.9,2.6c0.1,6.4,1,12.9-0.6,19.2C963.4,608.1,962.1,608.5,961.1,606.3z"/>
	<path class="st1355" d="M1021.2,417.3c0.2,0.2,0.7,0.5,0.7,0.6c-1.1,6.9,2.8,11.1,8.1,14.5c-0.3,0.8,0,2-1.3,2.3
		c-2.7-0.5-4.4-2.2-6-4.3C1019.8,426.3,1018.6,422.1,1021.2,417.3L1021.2,417.3z"/>
	<path class="st1356" d="M1046.3,678.2c-0.9-0.4-1.1-1.2-1.2-2.1c-1.1-3.7,0.4-7.9-2.3-11.2c-0.3-0.7-0.4-1.4-0.1-2.1
		c0.1-0.2,0.3-0.4,0.4-0.6c6.4,1.4,3.2,7.1,4.5,10.8C1047.9,673.8,1048.1,676.7,1046.3,678.2z"/>
	<path class="st1357" d="M1039.2,723.1c0.4,0,0.7,0,1.1,0c0.5,0.7,0.4,1.4-0.1,2c-1,1.5-0.9,4.1-3.8,3.9c-3.1,0-6.2,1.7-9.2-0.1
		c-0.3-0.5-0.3-0.9,0.2-1.3c1.3-0.9,2.8-0.8,4.3-0.9C1035,727.4,1036.3,723.6,1039.2,723.1z"/>
	<path class="st1358" d="M895.3,914.8c-1,0-2,0-3.1,0.1c-3-2.6-8.1-1.6-10-6c0.7-0.4,1.4-0.7,2.1-1.1
		C888.4,909.4,892.9,910.4,895.3,914.8z"/>
	<path class="st1359" d="M939.3,605.3c0,0.3,0,0.7,0,1c-0.4,2.2-2.3,1.9-3.8,2.2c-3.3,0.6-6.6,1.6-10,1.7c-0.9-0.1-1.7-0.4-2.2-1.1
		C928.2,606.1,933.6,605.1,939.3,605.3z"/>
	<path class="st1360" d="M961.2,920c1,0.6,1.9,1.1,2.9,1.7c-2.1,1.6-4.5,0.9-6.8,1.1c-3.8,0.5-7.6,1.3-11.3-0.5
		c1.1-2.4,3.4-1.7,5.3-1.9c1.3,0,2.6-0.1,3.9-0.5C957.3,919.7,959.3,918.7,961.2,920z"/>
	<path class="st1361" d="M892.3,645c0.4-4.1,2-7.8,4.1-11.3c1.1-1.9,2.7-2.8,4.9-2.6c0.3,0.4,0.3,0.9,0.1,1.4
		c-2.5,2.6-4.3,5.7-5.6,9.1c-0.7,1.8-0.8,4.3-3.5,4.8c-0.3-0.3-0.4-0.6-0.3-0.8C892.1,645.2,892.2,645,892.3,645z"/>
	<path class="st1362" d="M903.1,918.8c2.7,0.3,5.4,0.7,8.1,1c1.4-0.3,2.9-0.3,4.3-0.2c1.2,0.3,2.6,0.2,2.7,2c-1.1,0.4-0.4,2-0.5,1.9
		C913,921.3,907.3,922.4,903.1,918.8z"/>
	<path class="st1363" d="M938.2,923.6c-4.8,0.2-9.5-0.3-14-1.8c0.8-1.1,2-1.3,3.2-1.4c2.9-0.1,5.9,0.1,8.8-0.1
		c2.5,0.4,5.4-0.7,7.3,1.9C942.1,924.4,939.7,922.5,938.2,923.6z"/>
	<path class="st1364" d="M879.4,565.1c0,0.4,0,0.8-0.1,1.1c-1.9,1.9-4.9,0.1-6.8,2c-1.6,0.7-3.1,2.5-5.2,1c2-2.5,4-5,7.6-5
		c1.4,0,2.2-0.6,2.3-2C878.6,562.7,879.4,563.6,879.4,565.1z"/>
	<path class="st1365" d="M985.2,421.3c0,1.1,0.1,2.2,0.1,3.2c2.4,5.4,1.5,12.7-2,16c-0.9-1.7-0.8-3.5-0.1-5.2
		C983.1,430.5,983.3,425.8,985.2,421.3z"/>
	<path class="st1366" d="M966.1,565.2c0.5,0.1,1,0.1,1.5,0.2c0,4.2-3.7,7.4-3.1,11.8c0,1.3-0.1,2.5-0.7,3.7
		c-0.3,0.5-0.8,0.6-1.3,0.4C962.8,575.6,960,569.4,966.1,565.2z"/>
	<path class="st1367" d="M906.3,626c-0.3-0.2-0.5-0.3-0.8-0.3c0.5-2.2-0.8-4.9,1.7-6.6c1.6-0.8,2.8-2.6,5-1.8
		c-0.5,3.2-3.9,5.1-3.6,8.6c-0.3,0.4-0.7,0.6-1.1,0.7C907,626.6,906.6,626.4,906.3,626z"/>
	<path class="st1368" d="M962.5,581.2c0.2,0,0.4,0,0.6,0c1.3,1.3,1.3,2.6,0.2,4c0,4.3,0,8.6-0.1,12.9c0,1.5,0.7,3.5-1.8,4
		c1.7-3.6,0.9-7.4,0.7-11.1C961.8,587.7,961.9,584.4,962.5,581.2z"/>
	<path class="st1369" d="M864.3,830.9c0,4.7,0,9.4-0.1,14.1c-2.2-5.9-0.3-12-1-18c-0.8-1.9-0.4-3.9-0.3-5.8c0.4-0.5,0.9-0.6,1.5-0.4
		c0.4,0.4,0.7,0.9,0.9,1.5C865.6,825.2,866.4,828.2,864.3,830.9z"/>
	<path class="st1370" d="M1041.1,429.3c2.9,5.1,6,10.1,7,16c0,0,0.1,0,0.1,0c-1.5,0.5-2.2-0.7-2.6-1.6c-2-4.3-5-8.1-5.3-13.1
		C1040.4,430.1,1040.7,429.7,1041.1,429.3z"/>
	<path class="st1371" d="M861.3,547.1c-5.5-2.3-11-3.8-17-1.9C848.5,541.5,857.3,542.4,861.3,547.1z"/>
	<path class="st1372" d="M1035,427.3c0.3-1.8-1.9-3.4-0.2-5.4c0.7-0.8,0.1-2.9,1.7-3c1.3-0.1,2.2,1.2,2.5,2.5
		c-0.5,1,0.2,2.2-0.5,3.1c-0.8,1-1.4,2.2-2.3,3.1C1035.8,427.7,1035.4,427.6,1035,427.3z"/>
	<path class="st1373" d="M892.4,607.1c0.2,0,0.5,0,0.7,0c0.1,0.7,0.1,1.3,0.2,2c0.8,2.3,0.8,4.7,0,7c0,3.5,0.5,7.1-1.9,10.2
		c-0.7-0.6-1.1-1.4-1.2-2.2c-0.2-1.2-0.1-2.3,0.1-3.5c0.9-2.6,0.6-5.2,0.7-7.9C891,610.8,890.6,608.7,892.4,607.1z"/>
	<path class="st1374" d="M852.4,737c-2,2.3-3.7,4.7-3,8c-1.7-3.3-2.5-6.6,0-9.9c0.1-0.5,0.2-0.9,0.2-1.4c0.4-0.1,0.8,0,1.1,0.2
		c0.3,0.3,0.5,0.6,0.7,1C851.7,735.6,852.7,736,852.4,737z"/>
	<path class="st1375" d="M1035,427.3c0.4,0,0.8,0,1.1,0.1c0.7,2.3,1.1,4.7,0.6,7.2c-1.1,1.9-2.3,3.7-4.7,1.5
		c0.1-0.3,0.1-0.5,0.2-0.8c1.2-0.5,1.6-1,1.2-2.5C1032.8,430.8,1032.5,428.5,1035,427.3z"/>
	<path class="st1376" d="M1047.2,697.2c0.3-3-1-6.2,0.8-9.1c0.7,4.3,2.9,8.4,2,13c-0.4,0.2-0.8,0.2-1.2-0.2
		C1047.8,699.9,1048.4,698.2,1047.2,697.2z"/>
	<path class="st1377" d="M888.6,632.1c-2.1,4.5-1.1,9.3-1.2,13.9c-0.2,0.1-0.5,0.1-0.7,0.2c-2.8-2.2-0.8-5.7-2.1-8.3
		c-0.3-0.7-0.6-1.3-0.8-2.1c-0.2-2.5,1.4-3.6,3.5-4.2C887.7,631.6,888.2,631.8,888.6,632.1z"/>
	<path class="st1378" d="M1034.4,752c-0.1-0.7-0.2-1.4-0.3-2c-1.4-0.8-2.1-2-1.6-3.7c0.7-1.6,1.8-2.8,3.4-3.6c0.5-0.1,0.9,0,1.3,0.3
		c-0.7,3-1.4,5.9-2,8.9C1035,752.3,1034.7,752.3,1034.4,752z"/>
	<path class="st1379" d="M913.1,613.2c-0.7,0-1.5,0-2.2,0c2.2-3.4,5.2-4.9,9.3-3.9c0.3,0.4,0.3,0.9,0.1,1.3
		C918.4,612.6,915.9,613.3,913.1,613.2z"/>
	<path class="st1380" d="M901.2,632.1c0-0.3,0.1-0.7,0.1-1c0.3-0.3,0.6-0.6,0.9-0.9c1.5,1,3.2,1.2,4.9,1.1c1.3,1.1,3.7-0.8,4.4,1.7
		c-0.4,0.7-1.1,1-1.9,1.2c-0.7,0.2-1.5,0.2-2.2,0.2c-0.7,0-1.5,0-2.2,0c-1,0-2-0.1-3-0.5C901.5,633.5,901,633,901.2,632.1z"/>
	<path class="st1381" d="M837.2,593.2c0.4,0,0.7,0,1.1-0.1c3.1,3.7,3.2,7.7,1.1,11.9c0,1.1-0.1,2.1-0.1,3.2
		C837.8,603.4,837.9,598.2,837.2,593.2z"/>
	<path class="st1382" d="M1018.2,458.2c-0.3-0.6-0.7-1.3-1-1.9c0.3-0.7,0.7-1.3,1.6-1.3c2.6,1.6,6.4,2,5.4,6.6
		c-0.7,0.4-1.4,0.3-2.1-0.2C1020.8,460.3,1019.5,459.2,1018.2,458.2z"/>
	<path class="st1383" d="M863.4,820.9c0,2-0.1,4.1-0.1,6.1c-1.5-6.1-1.5-12.1,0-18.2c0.5,0.4,0.8,0.9,1,1.5
		C864.6,813.9,865.6,817.5,863.4,820.9z"/>
	<path class="st1384" d="M999.1,899.7c0.4,0,0.8,0.1,1.2,0.1c-0.3,3.4-2.3,5.5-5.1,7.1c0-0.8-0.1-1.6-0.1-2.3
		c-0.2-0.4-0.3-0.8-0.1-1.3C996,901.8,996.5,899.6,999.1,899.7z"/>
	<path class="st1385" d="M1006,618c2.9-1.3,2.8-4.9,5-6.7c-0.5,3.3,0.8,7.8-4.9,7.8c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.4-0.7
		C1005.9,618.1,1005.9,618,1006,618z"/>
	<path class="st1386" d="M1008,801.9c1.4-2,2.7-4.1,4.1-6.1c0.3,0.2,0.4,0.5,0.3,0.8c-0.1,0.3-0.2,0.4-0.3,0.4
		c-0.7,3.9-3.6,6.6-5.3,9.9c-0.5,0.2-0.9,0.1-1.1-0.4C1005.3,804.4,1006.2,802.9,1008,801.9z"/>
	<path class="st1387" d="M863.4,809.9c0-0.4-0.1-0.7-0.1-1.1c-0.3-2.6,0.4-4.7,3-5.9c0.4-0.3,0.8-0.4,1.3-0.1c1.1,2.3-0.2,4.2-1.2,6
		c-0.4,0.6-1,1-1.6,1.4C864.2,810.3,863.7,810.2,863.4,809.9z"/>
	<path class="st1388" d="M1034.4,752c0.3,0,0.6,0,0.9-0.1c0.8,3.8-1,6.3-4.1,8.2C1032.2,757.4,1033.3,754.7,1034.4,752z"/>
	<path class="st1389" d="M969.2,534.1c0,5.4,0,10.8,0,16.2C967.6,544.9,967.6,539.5,969.2,534.1z"/>
	<path class="st1390" d="M1014.1,569.2c-0.6,0.4-1.2,0.8-1.8,1.1c-1.4-1-1.4-2.5-1.4-4c0.4-1.4,0.6-2.9,1.2-4.2
		c0.1-0.7,0.1-1.4,0.9-1.7c0.3,2.6,1.7,5.1,1.1,7.8c0.3,0.2,0.4,0.4,0.3,0.6C1014.3,569.1,1014.2,569.2,1014.1,569.2z"/>
	<path class="st1391" d="M869.3,896.8c0,0.7,0,1.4,0,2c-0.3,0.5,0.1,2.6-1.2,0.4c-1.2-2-0.2-4.9-2.7-6.3c-0.1-1-0.2-2-0.3-3
		c0.2-0.1,0.4-0.2,0.6-0.3C868.4,891.3,869.7,893.7,869.3,896.8z"/>
	<path class="st16" d="M1039.1,473.3c0.7,0,1.4,0,2.1,0.1c-1.2,2.6-3.5,2.4-5.5,1.7c-2.7-1-1.2-3.8-1.7-5.7c0.4-0.2,0.8-0.2,1.2,0
		c1,0.8,1.1,2.3,2.4,2.8C1038.2,472.4,1038.7,472.8,1039.1,473.3z"/>
	<path class="st1392" d="M888.6,632.1c-0.4,0-0.9-0.1-1.3-0.1c-0.5-0.6-0.6-1.3-0.5-2c1.2-2.2,1.4-5.2,4.4-6.1
		c0.1,0.8,0.1,1.6,0.2,2.4C891.5,628.8,890.6,630.7,888.6,632.1z"/>
	<path class="st1393" d="M1038.1,424.4c0-1.1-0.2-2.2,0.9-3c1.8,2.4,2.5,5,2.1,7.9c0,0.4-0.1,0.8-0.1,1.2
		C1038,429.5,1037.6,427.2,1038.1,424.4z"/>
	<path class="st1394" d="M844.4,545.2c-1.1,1.6-3.4,2-4.1,3.9c0.1,0.9-0.1,1.7-0.6,2.5c-0.6,0.6-1.3,1.2-2.3,0.7
		c0.3-1.4,0.6-2.8,0.9-4.2C839.8,545.9,841.9,545.1,844.4,545.2z"/>
	<path class="st1395" d="M1015,649.1c0.8,1.2,1.6,2.2,3.2,2c0.5-0.4,0.9-0.3,1.2,0.3c-0.4,1.5-1.8,1.7-3,2.1
		c-1.4,0.4-3.1,0.9-4.1-0.5c-1-1.5-0.3-3.1,1.2-4.2C1014,648.6,1014.5,648.7,1015,649.1z"/>
	<path class="st1396" d="M946.3,921.9c3.6,2,7.4,0.4,11.1,0.9c-3.9,2.1-8.1,0.5-12.1,0.8c-0.6-0.3-1.3-0.6-1.3-1.4
		C944.6,921.2,945.4,921.1,946.3,921.9z"/>
	<path class="st1397" d="M1031.2,651.9c3.1,0.7,6.1,1.4,6.9,5.2c-0.2,0.1-0.4,0.3-0.6,0.4c-3.1-0.1-4.8-2.3-6.7-4.3
		C1030.8,652.7,1030.9,652.3,1031.2,651.9z"/>
	<path class="st1398" d="M892.3,645c0,0.4,0,0.8,0.1,1.2c0.8,0.8,0.8,1.7,0.4,2.7c-0.7,1.1-1.9,1.6-3.1,1.8c-0.5,0-1-0.1-1.5-0.2
		c-0.7-0.4-1.2-0.9-1.5-1.6c-0.3-1-0.2-2,0.6-2.8c0,0,0.1,0,0.1,0C889.6,648.5,891,647,892.3,645z"/>
	<path class="st1399" d="M1049.2,701.1c0.3,0,0.5,0,0.8,0c0.2,4-0.2,7.8-2.8,11c0.4-2.3,0.8-4.6,1.1-6.9
		C1049.2,704,1047.9,702.2,1049.2,701.1z"/>
	<path class="st1400" d="M974.2,480.3c0,0.4,0,0.7-0.1,1.1c-2.1-4.2-0.6-7.8,2-11.1c0.6,0.6,0.7,1.3,0.7,2.1
		C976.2,475.1,976.1,478,974.2,480.3z"/>
	<path class="st1401" d="M1030.1,465.1c-2.4,0-4.9,1-7.1-0.7c0.4-1,1.1-1.8,2-2.5c1.1-0.5,2.2-0.2,3.2,0.3c0.8,0.4,1.5,0.9,2,1.7
		C1030.5,464.3,1030.4,464.7,1030.1,465.1z"/>
	<path class="st1402" d="M1019.3,651.2c-0.4,0-0.7-0.1-1.1-0.1c3.3-2,6.7-2.7,10,0c-0.4,0.5-0.8,0.8-1.4,1
		C1024.2,652.4,1021.6,653,1019.3,651.2z"/>
	<path class="st1403" d="M868.3,799.8c-0.5-1.6-1.6-2.8-3-3.8c-0.5-0.6-0.6-1.3-0.3-2c0.3-0.4,0.8-0.5,1.3-0.5
		c1.2,0.4,2,1.2,2.8,2.1c1.1,1.7,2.1,3.3,1.3,5.4c-0.7,0.4-1.4,0.4-2.1,0C867.9,800.7,867.9,800.3,868.3,799.8z"/>
	<path class="st1404" d="M1017.3,787.8c-0.7-3.2,2.8-4.9,2.9-7.9c0.6-0.3,1.3-0.6,1.9-0.9C1021.6,782.6,1019.4,785.2,1017.3,787.8z"
		/>
	<path class="st1405" d="M927.3,921.8c-1,0-2.1,0-3.1,0.1c-2-0.1-3.9-0.2-5.9-0.2c-0.8-1-1.9-0.7-3-0.9c-0.2-0.9,0.4-1.4,1.1-1.7
		c1.8-0.6,3.5,0.3,5.2,0.4c1.5,0.1,3.1,0,4.6,0.6C926.9,920.4,927.5,920.8,927.3,921.8z"/>
	<path class="st1406" d="M1015,649.1c-0.3,0-0.6,0-0.9,0c-2.2-3.4-1.2-7-1-10.6c0.2-0.6,0.6-1.1,1.1-1.5
		C1016.2,640.9,1014.4,645.1,1015,649.1z"/>
	<path class="st1407" d="M867.3,552.2c-0.4-0.6-0.8-1.3-1.2-1.9c0.4-0.6,0.9-0.6,1.4-0.2c3,0.4,4,2.3,3.7,5.1
		c-0.2,0.1-0.4,0.1-0.7,0.2C868.9,555,867.8,554,867.3,552.2z"/>
	<path class="st1408" d="M976.1,472.2c0-0.7,0.1-1.3,0.1-2c0.1-1.6,0.8-2.8,1.9-3.9c0.4-0.4,0.8-0.4,1.3-0.1
		c0.7,0.8,0.7,1.8,0.5,2.8c-0.7,1.5-1.5,3-3.2,3.6C976.5,472.4,976.3,472.3,976.1,472.2z"/>
	<path class="st1409" d="M1037.3,743c-0.3,0-0.7,0-1,0c-1-1.7-0.2-3.5-0.4-5.3c0-1.3,0.2-2.6,1.1-3.7c0.1-0.4,0.1-0.7,0.2-1.1
		C1038.7,736.3,1038.7,739.7,1037.3,743z"/>
	<path class="st1410" d="M1030.1,465.1c0-0.3,0-0.5,0-0.8c3.9-1.1,5.3,0.9,5.4,4.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.4,0-0.7,0-1.1,0
		C1032.7,467.9,1032,466,1030.1,465.1z"/>
	<path class="st1411" d="M1013.1,602c0,0.4,0.1,0.7,0.1,1.1c0,1.9,0.6,4.1-2,5c0.4-2-0.7-4.3,1-6.1
		C1012.5,602.1,1012.8,602.1,1013.1,602z"/>
	<path class="st1412" d="M1006.2,806.9c0.2,0,0.4,0,0.7,0c-0.8,2.6-1.8,5.1-3.6,7.2c-0.1-0.5-0.2-1-0.3-1.6
		C1002.5,809.8,1002.9,807.5,1006.2,806.9z"/>
	<path class="st1358" d="M876.3,904.7c2,1.4,4.8,1.5,6,4.2c-2.4-0.7-4.7-1.6-6.5-3.5c-0.1-0.1,0-0.3,0.1-0.4
		C876,904.9,876.2,904.8,876.3,904.7z"/>
	<path class="st1413" d="M1044.1,719.2c0.4-2.7,1.5-5,3.1-7.2C1047.2,714.8,1047,717.6,1044.1,719.2z"/>
	<path class="st1414" d="M1012.8,533.2l-0.4,0c-2.8-1.9-2.9-2.1-2.2-7c0.4,0,0.7,0,1.1-0.1C1013.3,528.2,1011.2,531.1,1012.8,533.2z
		"/>
	<path class="st1415" d="M979.1,466.4c-0.4-0.1-0.7-0.1-1.1-0.2c0.8-3-1-6.2,1.1-9c0.1,2.7,0.1,5.5,0.2,8.2
		C979.5,465.8,979.5,466.2,979.1,466.4z"/>
	<path class="st1416" d="M1000.3,866.8c-0.4,0-0.7,0.1-1.1,0.1c-0.5,0.3-0.9,0.3-1.3-0.2c0-2.5-0.2-5.2,3.3-5.8c0,0,0-0.1,0-0.1
		C1000.9,862.8,1000.6,864.8,1000.3,866.8z"/>
	<path class="st1417" d="M1011.2,480.3c0.3,0,0.5,0,0.8,0c0.1,3,0.2,6,0.3,8.9c-0.4,0-0.7,0-1.1,0c-1-0.8-1.3-2-1.4-3.2
		C1009.9,484.1,1009.4,481.9,1011.2,480.3z"/>
	<path class="st1418" d="M865.3,794c0,0.7,0,1.4,0,2.1c-2.3-1.4-2.8-3.7-3.1-6.1c0.7,0.7,1.4,1.3,2.2,2
		C865.2,792.3,865.9,792.8,865.3,794z"/>
	<path class="st1419" d="M1038.1,657.1c3,0.3,4.6,2.1,5,5c0,0,0.1-0.1,0.1-0.1C1039.9,662,1038.2,660.4,1038.1,657.1L1038.1,657.1z"
		/>
	<path class="st1420" d="M877.2,561.2c-0.4,0-0.7,0.1-1.1,0.1c-2.2-0.5-3.1-2-2.9-4.2c0.4-0.3,0.9-0.3,1.3,0.1
		C875.9,558.2,877.6,559,877.2,561.2z"/>
	<path class="st1421" d="M1024.9,462.4c0.1,1.4-0.7,1.9-1.9,2c-1.4-0.7-0.6-2-0.9-3.1c0.7,0.1,1.3,0.1,2,0.2
		C1024.6,461.6,1024.8,462,1024.9,462.4z"/>
	<path class="st1422" d="M1027.1,769.2c0-2.2,0.7-4.1,2.9-5.1C1029.8,766.2,1029.1,768.1,1027.1,769.2z"/>
	<path class="st1423" d="M893.3,616.1c0-2.3,0-4.7,0-7C894.9,611.5,894.9,613.8,893.3,616.1z"/>
	<path class="st1424" d="M1040.1,725.2c0-0.7,0.1-1.4,0.1-2c1-1,2-2.1,3-3.1C1043.4,722.5,1042.2,724,1040.1,725.2z"/>
	<path class="st1425" d="M1029,434.6c0.3-0.7,0.6-1.5,1-2.2c0.6,1.1,2.6,1,2.2,2.9c0,0-0.1,0.1-0.1,0.1c-0.5,0.6-1.1,0.7-1.9,0.6
		C1029.6,435.8,1029.1,435.4,1029,434.6z"/>
	<path class="st1360" d="M944.2,922.7c0.4,0.3,0.7,0.6,1.1,0.9c-2.4,0-4.7,0-7.1,0c1.5-1.4,3.4-0.6,5.1-1
		C943.6,922.3,943.9,922.4,944.2,922.7z"/>
	<path class="st1320" d="M849.5,747c1.5,1.9,0.6,4,0.8,6.1C848.6,751.2,849.5,749.1,849.5,747z"/>
	<path class="st1426" d="M983.2,435.3c0,1.7,0,3.4,0.1,5.1c0.2,0.2,0.3,0.4,0.2,0.6c-0.1,0.2-0.2,0.3-0.2,0.3c-0.3,0-0.5,0-0.8,0
		C982.4,439.3,981.3,437.1,983.2,435.3z"/>
	<path class="st1427" d="M1014.2,637c-0.1,0.5-0.1,0.9-0.2,1.4c-1.9-0.3-2.9-1.2-1.9-3.2c0-0.4,0-0.7,0-1.1
		C1013.6,634.6,1014.3,635.5,1014.2,637z"/>
	<path class="st1428" d="M1024.1,774c0.1-1.6,0.8-3,2-4C1025.6,771.3,1027.5,774,1024.1,774z"/>
	<path class="st1429" d="M1027,651.3c0.4-0.1,0.8-0.1,1.2-0.2c1,0.1,2.3-0.5,3,0.8c0,0.4,0,0.7,0,1.1
		C1029,654.2,1027.7,653.5,1027,651.3z"/>
	<path class="st1430" d="M1022.1,778.1c0-1.7,0.7-3.1,2-4.2C1024.2,775.7,1024.2,777.4,1022.1,778.1z"/>
	<path class="st1431" d="M868.3,799.8c0.1,0.4,0.1,0.8,0.2,1.2c0.6,1.2,0.4,1.9-1.1,2c-0.4-0.1-0.7-0.1-1.1-0.2
		C866.3,801.4,866.9,800.4,868.3,799.8z"/>
	<path class="st1432" d="M920.3,610.2c0-0.3,0-0.6-0.1-1c1,0,2-0.1,3-0.1c0.7,0,1.4,0,2.1,0.1C924.2,612.2,922.3,611.4,920.3,610.2z
		"/>
	<path class="st1433" d="M1012.2,797c0-0.4-0.1-0.8-0.1-1.2c0.1-1.3,0.5-2.5,2-2.8C1014.1,794.6,1013.4,795.9,1012.2,797z"/>
	<path class="st1434" d="M1014.1,569.2c0-0.3,0-0.6,0-1c1.9,1.3,0.4,3.2,0.9,4.7C1013.8,572,1014.3,570.5,1014.1,569.2z"/>
	<path class="st1435" d="M874.5,557.1c-0.4,0-0.8,0.1-1.3,0.1c-1.5,0.1-2.3-0.4-1.9-2.1c0,0-0.1,0-0.1,0
		C872.7,555.3,874.5,554.7,874.5,557.1z"/>
	<path class="st1436" d="M867.6,550.1c-0.5,0.1-1,0.1-1.4,0.2c-1.2-0.3-2.2-0.8-2.7-2.1C865.1,548.3,866.4,549,867.6,550.1z"/>
	<path class="st1437" d="M977.2,918c0.9-1.6,2.4-2,4.1-2.2C980.6,917.8,978.8,917.7,977.2,918z"/>
	<path class="st1438" d="M1004.2,540.2c0.4,0.5,1.2,1.4,1.1,1.5c-4.2,4-2.3,8.7-1,12.8c1.2,3.9,1.7,7.8,2.7,11.7
		c0.9,0.9,0.3,1.7,0,2.5c-0.5,0.9-1.1,1.6-2,2c-0.5,0.1-1,0.1-1.4,0c-0.4-0.2-0.8-0.6-1-1c-0.2-2.1-0.4-4-1.7-6
		c-2.4-3.7-0.7-8-1.1-12c-0.3-2.5-0.4-4.8-1.9-6.9c0.4-1.8,1.7-2.8,2.9-3.9C1001.9,540.2,1002.9,539.5,1004.2,540.2z"/>
	<path class="st1439" d="M1004.2,540.2c-1,0.3-2,0.7-3,1c-0.9,0.4-1.1-0.2-1.3-0.9c0.2-1.1,1.1-1.9,1.8-2.7c1.1-1.1,2.1-2.1,2.3-3.7
		c0.7-1.8,0.4-4.3,3.1-5c1-0.9,2.1-1.8,3.1-2.8c1.5,2.1,0.2,5.1,2.2,7c-0.1,1.7-0.1,3.3-0.2,5c-1.9-1.3-0.5-5.1-2.7-5
		c-2.5,0-3.3,3.2-4.4,5.3C1004.8,539.1,1004.5,539.6,1004.2,540.2z"/>
	<path class="st1440" d="M1006.2,568.2c0.3-0.7,0.5-1.4,0.8-2c2.2-0.7,3.8-2.2,5.1-4c0,1.3,0,2.6,0,3.9c-1,0.9-0.2,2.3-0.9,3.4
		C1008.9,572.1,1007.5,570.2,1006.2,568.2z"/>
	<path class="st1441" d="M973.2,523.3c0-0.4,0-0.7,0-1.1c1.8-0.8,2.1-2.2,2-3.9c0.3-0.4,0.7-0.5,1.2-0.4c0.9,0.3,1.7,0.7,2.2,1.5
		c0.1,0.4,0.2,0.9,0.1,1.3c1.5,3.3,0.9,7.2,2.4,10.5c2.7,5.8,4.7,6.5,8.9,4.8c1,0.4,1.4,1.3,1.6,2.3c0,0.8-0.3,1.4-0.8,2
		c-1.2,1.4-2.7,2.1-4.5,2.2c-2.7,0.1-4.6-1.6-6.6-3.1c-2.3-1.9-3.8-4.4-5.1-7c-0.5-1.4-1-2.7-1.2-4.2
		C973.1,526.5,974.4,524.9,973.2,523.3z"/>
	<path class="st1408" d="M976.2,518.3l-0.5,0c0,0-0.5,0-0.5,0c-1.7-6.6-1.1-13.3-0.5-20.1c0.5-6,1.5-12-0.5-18c0-2.8,0.5-5.5,1.8-8
		c0,0,0.2,0.1,0.2,0.1c0.8,0.7,1.2,1.7,1.1,2.7c-0.4,4.6,1.2,8.8,2.3,13.1c-3.3,8.8-2.7,17.8-1.7,26.9c0.5,1.1,1.9,1.9,1.1,3.4
		C978.1,518.9,977.1,518.9,976.2,518.3z"/>
	<path class="st1442" d="M846.6,717c-1.1-7-2.2-14-3.3-21c0.4-18-0.7-36,1.1-53.9c0.8-4.6,3.7-4.3,7-2.9c1,0.5,1.6,1.3,1.2,2.5
		c-5.9,5.7-3.8,13.3-4.6,20c-1,8.8,0.9,17.7,1.1,26.6c0.4,5.9,3.2,11.5,2,17.6c-0.5,2.5,2.1,4.4,2.6,6.9c0.1,1.4-0.7,2.3-1.7,3
		C850.3,716.6,848.8,718.4,846.6,717z"/>
	<path class="st1443" d="M839.4,605.1c0.1-4-0.1-8-1.1-11.9c-0.4-8-2.4-15.9-2-23.9c4,1.5,1.5,5.6,3.2,8c0.3,5.3,1.2,10.6,1.8,15.8
		c0.4,3.5,0.5,7.1,1.3,10.6c-0.3,4.9,1.9,9.4,2.3,14.2c0.3,1.4,0.9,2.6,1.8,3.7c0.3,0.4,0.6,0.8,0.8,1.2c1.6,2.7,2.8,5.4,0.9,8.4
		c-0.6,0.3-0.9,0.1-1.2-0.5C843.1,622.7,841.4,613.8,839.4,605.1z"/>
	<path class="st1444" d="M850.4,640.1c-2.7-1.2-4.8-0.7-6.1,2.1c-0.2-3.4,0.4-6.8,1.7-9.9c3.8-0.3,2.3,4.2,4.7,5.2
		C851.9,638.4,851.7,639.3,850.4,640.1z"/>
	<path class="st1445" d="M850.4,638.1c-1.3-0.2-2.7-0.4-2.4-2.3c0.3-1.6-0.6-2.8-1.9-3.6c0.4-0.5,0.9-1,1.3-1.4
		c0.3,0.1,0.7,0.1,1,0.2c2.5-0.2,3.5,1.5,4.3,3.5c-0.5,0.9,0,1.8-0.1,2.7C852,637.9,851,637.5,850.4,638.1z"/>
	<path class="st1446" d="M995.1,903.7c0,0.3,0,0.6,0,0.9c-1.6,1.1-3.2,2.2-4.8,3.4c-1-0.3-2,0-2.9-0.3c-2.4-1-3.3-2.4-0.8-4.3
		c1.2-0.9,2.5-1.6,3.7-2.6c0.7-0.6,1.8-1.3,1-2.4c-4.2-5.3,0.7-8.9,2.4-13c0.6-1.3,2-2.2,2.2-3.8c0.5-3,1.6-6.1-0.9-8.9
		c0-0.5,0.1-1,0.4-1.4c4.4-1.7,4.6,1.5,4.9,4.3C1001,885.5,999,894.8,995.1,903.7z"/>
	<path class="st1447" d="M997.2,881.8c-2,3.5-3.9,7.1-6.1,10.5c-1.1,1.7-1,3.5,0.3,4.5c3.3,2.5,1.5,3.8-0.7,5.3
		c-1.6,1.1-3.2,2.2-4.8,3.2c0.8,2.3,3.7,0.7,4.5,2.7c-0.4,0.3-0.7,0.5-1.1,0.8c-2.4,0.2-4.2,1.7-6,3.1c-0.3,0.3-0.7,0.6-1,0.9
		c-1.1-0.9-2-2-1.4-3.4c0.7-1.9,1.4-3.3-0.8-5.1c-1.5-1.2,0-3,1.6-3.9c0.4-0.2,0.8-0.4,1.3-0.5c1.3-0.3,2.7-0.8,2.6-2.5
		c-0.2-3,1.5-5.3,3-7.6c1.6-2.2,3.1-4.5,5.1-6.4C994.6,882.5,995.5,881.2,997.2,881.8z"/>
	<path class="st1448" d="M994.2,883.9c-1.7,1.9-2.8,4.4-5,6c-1.9,1.9-2.8,4.9-6,5.6c-1.6,0.3,0.1,0.4,0.1,0.4c1.3,1.7,1.2,3.3-0.1,5
		c-0.3,0-0.7,0-1,0c-4.7,1.2-3.6,5.5-3.4,8.1c0.2,3.9-1.5,5.6-4.4,7c-0.8,0.9-1.9,0.9-3,1c-2.2-1.1-0.1-2.8-0.7-4.1
		c2.9-6.4,3.8-13.7,8.5-19.3c1.4-1.9,3.5-2.9,5.2-4.4c0.8-0.5,1.6-0.9,2.4-1.3c3.1-0.8,3.5-5.6,7.4-5.2
		C994.6,883.1,994.6,883.5,994.2,883.9z"/>
	<path class="st1449" d="M974.4,915.9c3.4-2.5,3.2-6.1,3.2-9.7c0-2.7,0.6-5.7,4.6-5.4c-0.8,1.4-4.2,2.8,0.1,4c2.4,0.6,1.1,2.6,0.6,3
		c-1.8,1.6-1,3.3-0.7,5C979.7,914.2,977.3,915.6,974.4,915.9z"/>
	<path class="st1450" d="M971.2,912.8c0.1,1.4,0.2,2.8,0.2,4.2c-1.9,1.1-3.9,1.8-6.1,1.9c-0.9,0.6-1.8,0.6-2.3-0.5
		c2.7-1.5,2.8-6.1,7-6C970.4,912.3,970.9,912.5,971.2,912.8z"/>
	<path class="st1451" d="M963.2,917.8c0.5,0.8,1.3,1,2.1,1c-1.1,1.3-2.7,0.8-4.1,1.2c-1.9,0.8-4,0.9-6,0.8c-1.2,0.3-1.2-0.3-1.1-1.2
		c1.5-1.7,3.5-1.9,5.5-2.1C960.8,917.5,962.1,917.1,963.2,917.8z"/>
	<path class="st1452" d="M983.2,911.9c1.1-2.8,2.9-4.1,6-3.1C987.4,910.3,985.7,911.9,983.2,911.9z"/>
	<path class="st1453" d="M836.4,564.2c0.3-3.6-1.1-7.3,1-10.8c3.1,3,2.1,7.4,3.5,11c2.2,5.6,5.7,10.8,6.4,17c0,4.8,2.3,9.2,2.7,13.9
		c0.1,1.5,0.5,2.9-0.1,4.3c-2.4,1.4-2.7-0.8-3.2-2.1c-1.7-4.2-4.2-8.2-4.7-12.8c-0.1-1.2-0.5-2.2-1.6-2.9c-0.4-0.4-0.6-0.8-0.8-1.3
		C838.4,575.2,838.1,569.6,836.4,564.2z"/>
	<path class="st1454" d="M846.4,581.2c-3.1-9.2-8.8-17.6-9-27.7c0-0.4,0-0.8,0-1.2c0.7-0.3,1.3-0.7,2-1c2.8,2.6,2.1,6.5,3.3,9.6
		c2.1,7.7,6.4,14.6,8,22.5c0.5,2.3,1.1,4.5,1,6.9c-0.1,1.1-0.3,2.2-1.1,3c-2.7-1.7-2.5-4.7-3.1-7.3
		C847.1,584.3,847.3,582.6,846.4,581.2z"/>
	<path class="st1455" d="M836.4,564.2c0.6,0.6,1.6,1.2,1.6,1.7c-0.3,5.3,2.5,10.1,2.3,15.3c-2.9-0.2-0.5-3.1-2-4
		c-1.9-2.3,0.1-5.7-2-7.9C836.4,567.6,836.4,565.9,836.4,564.2z"/>
	<path class="st1456" d="M1001.2,860.9c-2,1.5-1.8,4-2.9,5.9c0.4,1-0.1,1.8-0.7,2.6c-0.9,0.7-1.8,1.5-2.9,2
		c-2.4-0.4-3.9-3.1-6.7-2.4c-0.6,0.1-1.4-0.6-1.4-1.2c0.2-4.4-2.5-7.3-4.8-10.5c0.4-1.2,1.5-1.7,2.5-2.2c1-0.5,1.8-1.3,2.6-2.1
		c2.3-1.3,4.4-2.6,4.2-5.8c-0.1-1.4,1.1-2.3,2.3-2.9c4-0.8,3.8-4.7,5.2-7.3c0.8-1.4,1.5-2.8,1.9-4.4c0.7-2,0.4-4.2,0.6-6.3
		c0-0.7,0.1-1.5,0.4-2.2c0.3-0.7,0.8-1.3,1.7-1.2c0.5,10.6-1.3,21-1.7,31.5C1001.3,856.6,1001.2,858.7,1001.2,860.9z"/>
	<path class="st1457" d="M1006.2,806.9c-1.7,1.5-2.5,3.6-3.3,5.6c-1.8,1.4-1.9,3.3-2,5.3c-1.7,1.4-3.5,0.6-5.3,0.3
		c-2.7-1.4-2.4-3.3-1-5.4c2.1-1.8,4.9-2.4,6.7-4.6c0.8-0.9,1.2-1.7,0.7-3.1c-1.7-4.6-1.1-5.2,4-4.2c0.6,0.3,1.2,0.6,1.9,1
		C1007.4,803.6,1006.8,805.2,1006.2,806.9z"/>
	<path class="st1458" d="M996.2,817.9c1.6-1.1,3.2,0.3,4.8,0c0.9,1.6,2.1,3,2,5c-0.3,0.3-0.6,0.6-0.8,1c-1.3,0.5-1.8-1.1-2.9-1.1
		c-1.3,0.4-1.7,1.6-2.4,2.5c-1,1.5-1.9,3-3,4.4c-0.8,0.9-1.6,1.9-3,1.1c-0.8-1.4-0.2-2.8,0.2-4.1c0.7-1.9,1.6-3.8,2.3-5.8
		C994.1,819.7,994.6,818.3,996.2,817.9z"/>
	<path class="st1459" d="M1011,486.5c0.1,0.9,0.1,1.9,0.2,2.8c0,9.2,0,18.4-0.1,27.6c0,1.6,0.8,3.8-1.9,4.4
		c-5.3,0.9-6.7,0.2-7.1-4.9c-0.6-7.5-1-15.1,2.7-22.2c0.9-2.8,1.5-5.8,3-8.4C1009.2,483.3,1010.4,483.8,1011,486.5z"/>
	<path class="st1460" d="M1006.1,494.2c-3.5,6.8-3,14.2-2.9,21.5c0.1,3.8,1.5,6.4,6,5.7c-1,0.7-2,1.3-3,2c-1.4,2.1-3.6,2.2-5.9,2.3
		c-2.6-0.4-4.4-1.1-1.8-3.9c0.7-0.7,1.3-1.7,1.1-2.7c-1.3-6,0.3-12.2-1.9-18.1c-0.1-0.8,0.1-1.5,0.7-2
		C1001.3,497.8,1002.6,494.2,1006.1,494.2z"/>
	<path class="st1461" d="M1007.1,529c-0.7,2-1.4,4.1-2.1,6.1c-1.3,0.7-2,2-2.8,3.1c-0.7,0.7-1.3,1.4-2,2.1c-0.5,0.9-1.2,1.5-2.2,1.6
		c-3.1-0.3-1.9-2.6-2-4.3c0-0.5,0.1-0.9,0.2-1.3c0.5-1.3,0.4-2.7,0.9-4c1.7-2.3-0.8-6.4,3.4-7.5c2.8,0.1,6.5-1.5,6.5,3.6
		C1007.1,528.6,1007,528.8,1007.1,529z"/>
	<path class="st1462" d="M1007.1,528.3c-1-3.8-4.2-3-6.9-3.1c-0.3-0.2-0.4-0.5-0.3-0.6c0.1-0.1,0.3-0.2,0.4-0.2
		c2-0.4,3.9-0.7,5.9-1.1C1010.2,524.3,1006.7,526.7,1007.1,528.3z"/>
	<path class="st1463" d="M868.3,795.9c-1-0.4-1.6-1-2-2c-1.6-2.2-0.8-5.7-3.9-7c-0.3-0.7-0.7-1.3-1-2c-0.7-1.1-0.8-2.3-0.5-3.5
		c1.4-0.5,2.9,0.1,2.2-2.9c-0.8-3.4-0.2-7.4,3.1-10.1c0.5,0,1,0,1.4,0.2c3,3.8,4,8.6,5.7,13c0,0.8-0.2,1.5-0.6,2.1
		c-1.5,1.7-3.6-2-4.6,0.3c-0.9,2,0.3,4.1,1.5,5.9C871.1,792.4,872.7,794.8,868.3,795.9z"/>
	<path class="st1464" d="M861.3,781c0,1.3,0,2.7,0.1,4c-3.1-5.3-5.7-10.7-6-17c0.2-0.1,0.4-0.1,0.7-0.2c1.2,0.5,1.7,1.5,2.2,2.6
		C859.1,773.9,860.8,777.3,861.3,781z"/>
	<path class="st1465" d="M862.4,786.9c4,0.9,3.3,4.3,3.9,7c0,0-0.5,0-0.5,0s-0.5,0-0.5,0c-0.3-0.7-0.7-1.3-1-2
		C864.8,789.8,863.2,788.5,862.4,786.9z"/>
	<path class="st1464" d="M853.3,762c0-0.7,0-1.3,0-2c3.6,1.9,3.5,4.8,2,8c0,0-0.1,0-0.1,0C853.4,766.4,853.3,764.2,853.3,762z"/>
	<path class="st1466" d="M1020.1,776.9c-5,7-9.1,14.4-13,22c-6.1-2.9-4.5-8.1-4.1-13c2.7-5,3.1-11,8.3-15.1c2.3-1.8,2.2-6.9,4.5-9.9
		c0.7-0.9,0.9-2.1,2.1-2.7c1.9-0.6,3.4-1.8,4.9-3c2.9-2.2,5.8-0.3,6.2,3.7C1026.1,764.9,1023.1,770.9,1020.1,776.9z"/>
	<path class="st1467" d="M1029.1,759c-2.2-4.1-3.3-4.3-6.3-0.9c-1.4,1.5-3.1,0.7-4.7,0.9c-3.6-1.9-2.5-3.9,0.2-5.3
		c1.7-0.9,2-1.4,0.7-2.7c0.8-1.1,1.9-1.5,3.2-1.5c1.4,0.2,2.9,0.3,4,1.5c0.1,0.1,0.2,0.3,0.3,0.4
		C1033.4,751.7,1031.2,755.4,1029.1,759z"/>
	<path class="st1468" d="M1026.1,750.9c-1.4-0.1-3,0.5-4.1-0.9c-0.3-0.3-0.6-0.7-0.9-1c-0.3-0.9-0.7-1.7,0.3-2.4
		c1.4-0.5,2.6,0.2,3.9,0.6c1.9,0.7,3.7,0.9,5.3-0.7c0.6-0.4,1.2-0.7,1.8-0.8c0.2,0.1,0.4,0.2,0.6,0.3c-0.2,1.4,0.3,2.7,0.9,4
		C1031.4,750.3,1028.8,750.6,1026.1,750.9z"/>
	<path class="st1469" d="M1004.2,785.9c-0.3,4.6-0.6,9.3,3,13c-0.3,0.7-0.7,1.3-1,2c-2,0.9-7.6,0-2.2,4.6c0.9,0.8-0.3,2.7-1.8,3.4
		c-0.5-0.1-0.9-0.6-1.4-0.4c-1.2,0.4-0.8,3.2-3,2.2c-1.7-0.8-1.6-2.7-1.9-4.3c-1.3-7.8,1.4-14.7,5.1-21.4
		C1002.4,784,1003.4,784.5,1004.2,785.9z"/>
	<path class="st1470" d="M846.6,717c1.6-0.6,3.2-1.2,4.8-1.9c1.1,0.4,2.5-0.3,3.2,1.1c0,0.5-0.1,0.9-0.5,1.3c-3.4,1-4.7,3-3.5,6.6
		c0.7,2,1.4,4.1-0.2,6.1c-0.5,0.2-0.9,0.2-1.4,0C847.2,726,845.9,721.7,846.6,717z"/>
	<path class="st1471" d="M849.1,730.2c0.4,0,0.8-0.1,1.2-0.1c1.2,0.1,1.9,0.9,2.4,1.9c0.1,0.4,0,0.9-0.1,1.3
		c-0.6,0.7-1.2,1.3-2.2,0.8c-0.3-0.1-0.6-0.2-0.8-0.3C849.4,732.5,849.3,731.4,849.1,730.2z"/>
	<path class="st1472" d="M963.4,577c-0.1-4.4,1.5-8.3,4.2-11.7c1.3-0.8,2.5-1.5,3.8-2.3c1.5-0.4,2.7,0.1,3.8,1.1
		c0.6,2-0.9,3.4-1.4,5.1c-0.6,1-1.4,1.8-2.5,2.3c-0.4,0.1-0.9,0.2-1.4,0.1c-4.6,0.5-3.2,4.2-3.9,6.8c0.7-2-1.1-5.7,3.6-4.9
		c0.3,3.6-0.7,7.3,0.7,10.9c0.2,1.2,0.5,2.4,0.3,3.6c-0.7,2.8,0.1,5.7-0.2,8.6c-1.3,3.6,0.2,8.2-3.7,10.9c-0.5,0.1-1.1,0.1-1.6-0.2
		c-0.5-0.7-0.5-1.5-0.3-2.3c0.5-5.4,0.6-10.9-0.1-16.3C964.4,584.9,963.6,581,963.4,577z"/>
	<path class="st1473" d="M971.6,559.6c-0.1-1.1-0.3-2.3-0.4-3.4c-0.6-2.7-1-5.3,1.6-7.3c5.5,2.4,6.9,5,6.6,12.4
		c-0.4,1.8-1.5,2.8-3.4,2.6C973.8,563.2,971.3,562.8,971.6,559.6z"/>
	<path class="st1474" d="M971.2,537.3c0.4-2.4-0.8-5.1,1.2-7.3c1.9,0,1.6,2,2.5,2.9c0.7,1.4,2.1,2.5,1.4,4.4
		c-3.6,2.5-0.9,6.8-2.8,9.8c-0.5,0.3-0.9,0.3-1.4,0C970.7,544,970.3,540.7,971.2,537.3z"/>
	<path class="st1475" d="M971.2,537.3c0,3.4,0.2,6.8,1.9,9.9c0.7,0.9,0.1,1.5-0.6,1.9c-0.4,2.3-0.8,4.7-1.2,7
		C969.6,549.9,969.7,543.6,971.2,537.3z"/>
	<path class="st1476" d="M974.2,533.3c-0.6-1.1-1.2-2.2-1.8-3.2c0.1-0.6,0.2-1.3,0.4-1.9c0.5-0.5,1-0.5,1.5,0
		c0.4,1.3,0.8,2.6,1.2,3.9C975.7,533.1,975.2,533.5,974.2,533.3z"/>
	<path class="st1477" d="M971.6,559.6c0.6,1.8,2.1,2.6,3.6,3.5c0.1,0.2,0.3,0.4,0.4,0.6c-0.1,0.2-0.2,0.4-0.4,0.6
		c-1.4-0.1-2.8-0.3-3.8-1.3C971.5,561.9,971.5,560.7,971.6,559.6z"/>
	<path class="st1478" d="M974.2,528.2c-0.5,0-1,0-1.5,0c0.2-1.6,0.4-3.3,0.5-4.9C975,524.6,974,526.5,974.2,528.2z"/>
	<path class="st1479" d="M1038.1,424.4c1,2,1.1,4.5,2.9,6.2c1.8,5.2,3.8,10.3,7.1,14.7c-0.2,3.6,2.8,5.8,3.7,8.9
		c0.7,2.2,1.8,4.4-0.9,6c-0.8,0.3-1.2-0.2-1.4-0.9c-0.1-0.4-0.1-0.9,0-1.3c0.5-1.9-0.6-3.4-1.3-5c-2.8-4.7-5.3-9.5-7.5-14.5
		c-0.8-1.9-1.7-2.2-3.2-1c-1.1-0.2-1.4-1.1-1.7-2c-0.2-0.5-0.1-0.9,0.3-1.2c0.4-2.3-1.9-4.6-0.1-7
		C1036.4,426.1,1036,424.4,1038.1,424.4z"/>
	<path class="st1480" d="M1050.1,459.3c0.3,0.3,0.6,0.7,0.9,1c-3.2,5.1-8.3,8.3-11.9,13c-0.7,0-1.3,0-2,0c-0.3-0.4-0.5-0.9-0.6-1.4
		c0.4-3.3-0.4-6.6,0.4-9.9c0.3-1.4,0.1-3,1.8-3.8C1042,463.2,1046,461.2,1050.1,459.3z"/>
	<path class="st1481" d="M938.2,624.2c-3.7-2-7.4-4-11-6c-0.4-0.6-0.9-1-1.5-1.1c-2.4-0.3-2.9-2.6-3.2-4.1c-0.3-1.5,1.9-1.8,3.4-2.2
		c0.8-0.2,1.6-0.5,2.3-0.8c1.3-0.9,2.8-0.9,4.4-1c6.8-1.1,13.7,0.1,20.3,1.2c3.8,0.6,7.6,3.4,11.2,5.3c4.2,2.2,1.3,6.2,0.4,9.4
		c-1,3.4-3.3,5.3-6.9,5.3c-5.2,0.1-10.2-0.6-14.9-3C940.8,626.8,939.5,625.6,938.2,624.2z"/>
	<path class="st1482" d="M932.3,610.1c-1.5-0.1-2.8,0.3-4,1c-0.2,0.2-0.5,0.5-0.8,0.4c-4.2-0.6-6.1,1-5.1,5.4
		c-2.2-0.3-4.5-0.6-6.7-0.8c-1.9-0.2-3.3-0.6-2.4-2.9c2.4-1,4.8-2,7.2-2.9c1.7,0,3.6,0.3,5-1c4.6-1,9.3-1.9,13.9-2.9
		c7.3-1.1,14.6-1.3,21.8,0c0.8,1.1,2,0.9,3.1,0.8c0.4-0.5,0.8-0.5,1.2,0c0.3,0.1,0.6,0.2,0.9,0.3c0.6,0.2,1.3,0.3,1.9,0.6
		c1.2,0.6,2.2,1.4,3.3,2c0.7,0.4,1.5,0.8,2.2,1.2c0.4,0.2,0.8,0.4,1.1,0.6c1.1,0.8,2.3,1.4,3.3,2.2c2.1,2.1,4.2,4.1,4.4,7.4
		c0.6,9.3-6.1,16-15.5,15.5c-2.4-0.1-3.8-1.6-4.8-3.7c0.1-2.5,2.2-2.1,3.7-2.4c1.8-0.4,3.4-1.1,4.7-2.6c5.8-6.6,5.2-10.2-2.5-14.3
		c-8.1-4.4-16.8-6.3-25.9-4.6C938.8,609.8,935.4,609,932.3,610.1z"/>
	<path class="st1483" d="M908.4,626c-2.4-4.5,1.7-6.2,3.9-8.7c2.3-0.1,4.7-0.5,6.8,0.8c-2.9,0.6-1.8,3.6-3,5.2
		c-0.1,0.5-0.4,0.9-0.6,1.2c-1.5,1.2-3.3,1.7-5.1,2.2C909.5,626.8,908.8,626.6,908.4,626z"/>
	<path class="st1484" d="M938.2,624.2c1.8-0.2,2.6,1.4,4.1,1.9c2.7,1.3,5.3,2.7,8.2,3.7c2.2,1.4,5,1.6,7.1,3.3
		c0.3,0.8,0.2,1.5-0.2,2.2c-2,1.4-4,0.5-6,0.1c-1.5-0.3-2.8-0.8-4.2-1.5c-2-1.1-4.3-2-5.7-3.9c-0.9-1.8-3.4-2.3-3.4-4.7
		C938.1,625,938.2,624.6,938.2,624.2z"/>
	<path class="st1485" d="M915.3,623.1c0.4-2.2-0.6-5.6,3.7-5c2.4,0.6,4.9,1.3,7.3,1.9c0.4,1,2.9,0.3,1.9,2.4
		c-0.6,0.5-1.3,0.7-2.1,0.9c-1.3,0.1-2.6,0.2-3.9,0.2C919.9,623.2,917.6,624,915.3,623.1z"/>
	<path class="st1486" d="M938.1,625.4c1,1.6,3.4,1.7,4,3.8c-0.6,0.8-1.3,1.3-2.3,1.4c-3.7-0.5-6.3-3-9-5.2c-0.6-0.8-1.3-1.6-0.4-2.6
		c1-0.3,2-0.3,2.9,0.2C934.9,623.8,936.5,624.6,938.1,625.4z"/>
	<path class="st1487" d="M922.3,616.9c-2.2-5.3-1.8-5.8,6-5.8c-1.2,1.9-3.9,0.1-5.1,2c-0.1,2,2.8-0.1,2.6,1.9c0,0.1-0.9,0.2-1.6,0.3
		c0.4,1.7,4.7-0.6,3,2.9C925.6,617.8,923.9,617.4,922.3,616.9z"/>
	<path class="st1488" d="M933.4,623c-1,0-2-0.1-3.1-0.1c-0.8,0.1-1.5-0.1-1.9-0.9c-0.7-0.7-1.4-1.4-2.1-2
		C929.1,619.9,931.3,621.3,933.4,623z"/>
	<path class="st1489" d="M1028,731.5c2.5,1.5,6.7-2.4,8.1,2.5c-0.3,0.3-0.6,0.6-1.1,0.7c-0.4,0-0.8,0-1.3-0.1
		c-1.5-0.4-2.9-0.1-4.3,0.3c-2.4,0.5-4.9,0.4-7.4,0.1c-1.1-0.1-2.6-0.6-3.2,0.5c-0.9,1.7,1,2.1,1.8,3c0.6,0.6,1.3,1,2,1.4
		c1.1,0.9,2.8,1.3,2.4,3.3c-0.8,0.7-1.7,0.9-2.8,0.8c-1.7-0.2-3.1-1.1-4.6-2.1c-1.9-1.3-3.7-2.6-5.3-4.3c-1.5-1.7-3.4-3-4-5.3
		c0.1-1.5-0.4-3.1,0.5-4.5c3.5-1.8,6.4,0.9,9.6,1.4c0.4,0.1,0.8,0.4,1.2,0.6C1022.1,732.4,1025.2,731.3,1028,731.5z"/>
	<path class="st1490" d="M1027.1,728c0,0.3,0,0.6,0,1c-4.7-0.5-8.3-3-11.6-6c-0.5-0.5-1.6-3.4-2.3,0c-3,0.3-3-2.1-3.7-3.9
		c-0.3-2.8,1.3-4.7,3.1-6.5c5.3-2.5,6-1.9,6.4,4c0.3,3.7,3,6.1,5.6,8.4C1025.5,725.9,1026.8,726.6,1027.1,728z"/>
	<path class="st1491" d="M1010.1,718.9c1.5,1,1,3.4,3.1,4c0.8,0.9,1.6,1.9,2.3,2.8c-0.2,0.4-0.6,0.7-1,0.8c-1.8,0.1-2.9-1.2-4.2-2.1
		c-1.2-0.9-2.1-2-2-3.6C1008.5,719.8,1008.7,718.9,1010.1,718.9z"/>
	<path class="st1492" d="M1028,731.5c-3.1,0.6-6.3,1.8-8.8-1.5c0.7-0.6,1.4-0.5,2.2-0.1C1023.3,731.5,1025.9,730.6,1028,731.5z"/>
	<path class="st1493" d="M1021.4,729.9c-0.7,0-1.5,0.1-2.2,0.1c0,0-0.1,0.1-0.1,0.1c-1.5-1.2-4.6-0.7-4-4c0,0,0.4-0.2,0.4-0.2
		C1017.5,727.2,1019.4,728.5,1021.4,729.9z"/>
	<path class="st1494" d="M1035.2,734.2c0.3-0.1,0.6-0.2,1-0.3c0.3,0,0.6,0.1,0.8,0.1c0,1.2,0,2.4,0,3.5c-0.2,0.6-0.6,0.9-1.2,0.7
		C1033.9,737.2,1033.9,735.8,1035.2,734.2z"/>
	<path class="st1495" d="M881.3,575.2c3.6,3.3-1.2,6.7,0,10c-0.1,3.8-2.7,5.2-5.8,6.1c-5.2-0.9-7.8-3.8-7.2-8.2
		c0.1-1.1,0.2-2.5,1.5-2.9c1.3-0.3,2.2,0.6,3,1.5c0.2,0.2,0.4,0.6,0.6,0.8c1,0.9,0.6,4.3,3,2.5c1.7-1.2,0.1-3.3-0.9-4.9
		c-0.2-0.3-0.5-0.5-0.7-0.7c-4.1-3.5-4.1-3.5,0.1-7.2C877.9,571.3,879.1,574.3,881.3,575.2z"/>
	<path class="st1496" d="M875.3,590.2c3-0.5,4.4-2.9,6-5c2.4-2.1,4.2-0.4,5.9,1.1c-1.4,2.1-2.5,4.5-4.9,5.7c-2.3,0.1-4.7,0.4-7-0.5
		c-0.2-0.3-0.3-0.5-0.3-0.8C875.2,590.3,875.3,590.2,875.3,590.2z"/>
	<path class="st1497" d="M882.3,591.2c1.7-1.6,2.2-4.4,4.9-4.9c0.3,0.3,0.7,0.6,1,0.9c0,0.4-0.1,0.8-0.1,1.2
		c-1.5,1.7-2.5,3.8-3.9,5.5c-1.1,1.3-1.9,3.2-4.2,2.2C879.1,593.7,880.5,592.3,882.3,591.2z"/>
	<path class="st1498" d="M881.3,575.2c-0.1,1.2-0.7,2-1.6,0.6c-1.2-1.6-2.8-2.5-4.4-3.6c-0.3-0.2-0.3-0.4-0.3-0.7
		c0.1-0.2,0.2-0.3,0.3-0.3c2.7-0.4,4.8,0.4,6,3C881.3,574.5,881.3,574.8,881.3,575.2z"/>
	<path class="st1499" d="M1042.1,707c3.9-0.3,4.8,1.1,2.8,4.5c-2.1,3.4-3,7.5-5.7,10.5c-0.6,0.8-1.1,1.6-2.3,1.5
		c-1.3-0.4-2.1-1.2-2-2.6c1-1.9,1.1-4,1-6c0-1.2,0-2.4,0.3-3.6c0.7-2.4,2.6-3.5,4.6-4.6C1041.2,706.6,1041.7,706.7,1042.1,707z"/>
	<path class="st1500" d="M1049.2,701.1c-0.2,1.4,0.3,2.9-1,4.1c0.2-2.4-0.9-2.9-3-2c-0.2-0.2-0.3-0.3-0.5-0.5
		c-0.1-0.4,0-0.9,0.2-1.3c0.5-1,1.4-1.6,2.1-2.4c0-0.6,0.1-1.2,0.1-1.8C1050,697.4,1048.7,699.7,1049.2,701.1z"/>
	<path class="st1501" d="M1037.1,723c0.7-0.3,1.3-0.7,2-1c0,0.4,0,0.7,0,1.1c-1.9,2.3-3.4,5.1-7,4.9c-0.7-0.6-1.3-1.2-1.3-2.2
		c0.2-0.7,0.6-1.1,1.3-1.3C1033.9,724.4,1035.5,723.7,1037.1,723z"/>
	<path class="st1500" d="M1042.1,707c-0.3,0-0.6,0.1-1,0.1c-0.7,0.4-1.4,0.6-2.1,0.1c0.6-3.4,3.2-4,6-4.2c0,0,0.2,0.2,0.2,0.2
		C1045.2,705.2,1043.2,705.7,1042.1,707z"/>
	<path class="st1336" d="M1011.3,579.2c0.9,1.9,2.7-4.6,2.7,0c-0.1,5.6,0.1,11.4-1.7,16.9c0,0,0,0,0,0c-2.2-2.1-2-4.8-2.1-7.5
		c-1.4,4.2-3.7,8.1-3.4,12.8c0.1,1.6,0.3,4.2-2.5,4.7l-0.2-0.1c-1.4-1.4-1.2-3.1-1.3-4.8c0-1.9,0-3.8,0-5.7c0-2.1,0.1-4.3,1-6.2
		c0.4-0.9,1-1.7,1.6-2.5C1007.9,584.7,1008.2,580.8,1011.3,579.2z"/>
	<path class="st1502" d="M1011.3,579.2c-1.4,2.9-2.4,6-5,8.1c-0.4-0.3-0.6-0.7-0.7-1.2c0-1.2,0.5-2.3,1-3.4c1.1-2.1,1.9-4.3,2.3-6.6
		c0.6-2.4,0.9-4.9,2.2-7c0.1-1.1-0.1-2.3,1.1-3c0,1.4,0.1,2.8,0.1,4.3C1012.8,573.4,1011.3,576.2,1011.3,579.2z"/>
	<path class="st1503" d="M994.9,870.8c0.7-0.7,1.4-1.3,2.1-2c2.6-0.6,1.9,2.2,3.1,3c2.9,9.4,2.8,18.7-1.1,27.9c-1.9,0.8-1.9,3.4-4,4
		c0.2-5.1,2-9.8,3.2-14.7c1.3-5.1,0.9-10.3,0.5-15.5c-0.1-1.7-1.8-2.8-3.8-1.7c-0.1-0.2-0.2-0.3-0.3-0.5
		C994.8,871.2,994.9,871,994.9,870.8z"/>
	<path class="st1504" d="M1000.2,871.9c-1-1-2.1-2-3.1-3c0.4-0.7,0.8-1.3,1.2-2c0.3,0,0.6,0,0.9,0.1
		C1000.1,868.4,1000.2,870.1,1000.2,871.9z"/>
	<path class="st1333" d="M869.2,860.9c-1-4.7-1.9-9.4-2.9-14.1c-0.5-3.6-0.8-7.2,1.9-10.2c4.9-0.3,5.7,4.1,8,6.9
		c0.8,5.4,2,10.9,0.4,16.4c-0.1,1-0.3,1.9-0.9,2.7c-1.5,1.4-3.2,0.6-4.8,0.2c-0.5-0.2-0.9-0.4-1.3-0.7
		C869.3,861.8,869.1,861.4,869.2,860.9z"/>
	<path class="st1505" d="M869.2,860.9c0,0.3,0,0.7,0,1c0,0.2,0,0.5,0,0.7c-1.5,2.8-1.6,5.5,1,7.9c0.7,1.1,1.5,2,1.6,3.3
		c-0.2,2.1-0.1,4.3-0.9,6.3c-0.5,1.2-0.8,2.8-2.4,2.7c-1.9-0.1-2.9-1.5-3.1-3.3c-0.4-3.4,0.1-6.9-0.3-10.3c-0.2-0.5-0.2-1.1,0.1-1.6
		c0-2.5,0.6-4.8,2.1-6.9C868,860.9,868.6,860.9,869.2,860.9z"/>
	<path class="st1506" d="M868.3,836.9c-0.7,3.3-1.3,6.7-2,10c-2-2.8-0.6-6-1-9c-0.3-2.3,0.9-4.9-1-7c0-3,0-6,0-9
		c0.5-0.3,1-0.3,1.4,0.2c1.1,1.8,1,4,1.7,6C866.8,831.1,870.4,833.7,868.3,836.9z"/>
	<path class="st1391" d="M865.2,867.7c0,0.4,0.1,0.8,0.1,1.2c0.9,5.4,0,11,1.4,16.4c0.4,1.5-0.1,3.3-1.4,4.5c0,0-0.2,0.2-0.2,0.2
		C863.7,882.5,863.6,875.1,865.2,867.7z"/>
	<path class="st1507" d="M855.4,767.9c0-2.8-0.7-5.5-2-8c0.5-4.8-2.5-9.1-2-14c0.2-1.7-0.6-3.6,1-5.1c2.7-1.4,3-6.2,7.4-5.1
		c2.9,2.8,1.9,6.4,2.3,9.8c0.4,3.1,0.6,6.3,0.6,9.4c0.2,5.7-2.8,10.2-5.3,14.9C856.7,769.3,856,768.6,855.4,767.9z"/>
	<path class="st1508" d="M852.4,737c-0.4-0.9-2.5-0.1-2-2c1.2-0.9,2.8-1.3,3.6-2.8c2.9-2.9,6.6-4.7,9.9-6.9c2.4-1.1,4.4-2.7,6-4.9
		c2.1-1.6,3.2-4.8,6.6-4.5c0.5,0.6,0.6,1.3,0.6,2c-0.1,1-0.6,1.8-1.3,2.6c-5.4,5.8-11.1,11.2-17.9,15.2c-1.1,0.6-2.1,1.6-3.6,1.2
		C853.7,737,853,737,852.4,737z"/>
	<path class="st1509" d="M854.3,737c6.2-3.7,11.8-8.4,16.9-13.5c1.3-1.3,2.7-2.3,4.1-3.5c1.8,2.8-0.5,4-2.1,5.4
		c-2.8,2.4-5.4,4.9-8.3,7.1c-1.8,1.2-2.7,3.9-5.6,3.4c-3.1,0.6-4.1,4.1-7,4.9C853,739.6,853.7,738.3,854.3,737z"/>
	<path class="st1510" d="M871.4,555.2c0.3,1,1,1.6,1.9,2.1c1,1.3,1.9,2.7,2.9,4c-0.8,2.3-3,1.3-4.5,2c-3.4,1.5-7.2,2.3-9.2,6
		c-0.5,2.6-0.9,5.2-4.5,5c-6.4-5.1-9.2-12.5-11.5-19.9c-0.8-2.5-1.1-6.1,3.6-5.6c2.6,1.8,4-1.5,6.2-1.6c4.2,0.4,6.8,4.4,11,5
		C868.7,553.2,870,554.2,871.4,555.2z"/>
	<path class="st1511" d="M850.4,550.2c-5-1.8-3.1,2.4-2.7,3.7c1.8,6.2,4.4,12.1,8.6,17.1c0.8,1,2.2,1.6,2.2,3.2
		c0.4,0.4,0.6,0.8,0.6,1.4c0,1.1-0.5,1.9-1.5,2.5c-1.2,0.6-2.2,0.4-3-0.9c-5.3-8.8-10.6-17.6-12.2-28c0.3-0.3,0.7-0.7,1-1
		c2-0.6,4.1-1.2,6.2-0.8C851.4,547.7,852.3,548.7,850.4,550.2z"/>
	<path class="st1512" d="M842.3,549.2c3.1,2.9,3.4,7.2,4.5,10.7c2,6.4,7,11.1,8.9,17.4c0,0.2,1.2,0,1.8,0c1,1.1,1,2.4,1,3.7
		c-0.1,0.8-0.3,1.6-0.7,2.2c-2.7,2.7-3.3-0.4-4.6-1.7c-5.7-9-9.3-18.8-12.1-29c-0.3-1.1-0.3-2.3,0.3-3.4
		C841.7,549.2,842,549.2,842.3,549.2z"/>
	<path class="st1513" d="M841.4,549.2c1.5,9.4,6.6,17.6,9.4,26.6c0.6,1.9,2.3,3.5,3.5,5.3c-0.8,1.2-1.9,1.7-3.3,1.5
		c-2.6-1.4-2.2-4.3-3.1-6.5c-1.9-5.1-3.8-10.2-6.6-15c-0.7-3.3-1.4-6.6-2-9.9c0-0.9,0.2-1.6,0.9-2.2
		C840.7,549.1,841,549.2,841.4,549.2z"/>
	<path class="st1514" d="M850.4,550.2c0.3-1,0.7-2-1-2c-2,0-4,0-6,0c4.1-2.6,8.5-2.8,13-1C855.1,549.8,853.7,552,850.4,550.2z"/>
	<path class="st1515" d="M1004.2,606.2c0.9-2.7,1.8-5.3,1.4-8.5c-0.6-4.1,3.1-7.2,3.8-11.2c0-0.3,1.1-0.7,1.2-0.6
		c0.5,0.6,1.4,1.6,1.2,2c-1.3,2.9,0.3,5.5,0.4,8.2c-2.9,5.1-0.9,11.4-4,16.6c-0.7,1.2-0.7,3.3-2.6,3.1c-2.1-0.3-1.3-2.3-1.4-3.7
		C1004.1,610.2,1004.2,608.2,1004.2,606.2z"/>
	<path class="st1516" d="M1006,618c0,0.4,0.1,0.8,0.1,1.1c-2.4,0.2-5.5-1-6,3c-1.5,1.3-1.8,3-2,4.9c-0.2,2.9,0.6,4.3,3.8,4.1
		c2-0.1,4.3-0.8,6.2,0.9c-3.8,2.5-7.7,1.6-11.6,0.5c-2.8-4.3-0.4-8.4,0.4-12.6c1.6-2.5,2.9-5.1,3-8.1c0.1-1.3,0.3-2.7,1.9-3
		c2,2.4-0.4,5.6,1.4,8.1C1004.2,617.3,1005.1,617.7,1006,618z"/>
	<path class="st1517" d="M1003.3,617c-2.7-2.3-0.4-5.3-1.2-7.8c-2-2.6-1.4-5.5-0.7-8.3c1-1.4,1.8-0.9,2.6,0.2c0,1.7,0,3.3,0,5
		C1002.4,609.6,1003.5,613.3,1003.3,617z"/>
	<path class="st1518" d="M983.2,441.3c0.1-0.3,0.1-0.6,0-0.9c2-5.1,2-10.5,2-15.9c0.6-1.4,1.2-2.8,1.9-4.2c1.2,0.9,1.3,2.3,1,3.5
		c-0.6,2.7,0.5,4.7,2.4,6.4c0.4,0.7,0.4,1.4,0.4,2.2c-0.3,3.7-2.9,6.4-4.2,9.6c-2,6.1-3.6,12.3-3.3,18.8c-0.2,1.6-0.6,3.1-1.7,4.3
		c-0.5,0.4-0.9,0.4-1.4,0C979.7,457,982,449.2,983.2,441.3z"/>
	<path class="st1519" d="M980.3,465.1c0.3,0.1,0.6,0.2,0.9,0.2c3.4-0.3,6.2,1.6,9.2,2.6c0.7,0.2,1.3,0.5,1.9,0.8
		c1.4,0.9,2.6,2.1,3.4,3.6c0.7,1.6,1.3,3.2,0.9,4.9c-1.1,2.6-2.5,2.8-4.1,0.4c-1.6-2.8-2.8-6-6.8-6.1c-2.2-1.4-4.6,0.4-6.9-0.4
		c-0.3-0.7-0.2-1.3,0.3-1.9c0-0.9,0-1.9,0-2.8c0.1-0.3,0.1-0.7,0.2-1C979.7,465.3,980,465.2,980.3,465.1z"/>
	<path class="st1520" d="M1013.4,467.2c-0.2,4.2-1.1,8.2-2.2,12.1c-1.9,3-4.4,4.8-8.1,4.2c-3.7-2.9-0.4-6.2-0.1-9.1
		c0.8-8.2,3.6-16,5.4-24c0.1-0.5,0.5-0.9,0.8-1.2c2.5-0.9,4.1,0.7,5.6,2.2c0.3,0.5,0.5,1,0.5,1.6
		C1014,457.6,1013.5,462.4,1013.4,467.2z"/>
	<path class="st1521" d="M1003.2,483.3c2.9-0.8,5.5-2.4,8-4c0,0.3,0.1,0.6,0.1,1c-0.1,2-0.1,4.1-0.2,6.1c-0.7-0.3-1.3-2.7-2.2-0.3
		c-1,2.6-1.8,5.3-2.7,8c-2.5,1.5-3.6,4.8-6.9,5.1c-0.9-0.4-1.4-1.1-1.4-2.2c0.5-2.2,2.1-4,2.1-6.4c0.1-1.4,0.1-2.8,0.5-4.2
		C1001,485.1,1001.5,483.8,1003.2,483.3z"/>
	<path class="st1522" d="M1013.4,467.2c-0.6-4.9,0.2-9.7,0.9-14.6c2.5-0.8,3.9,0,3.9,2.7c-0.3,0.3-0.7,0.7-1,1
		c-3.7-0.8-2.3,2.5-2.9,3.9C1013.5,462.3,1014.9,465,1013.4,467.2z"/>
	<path class="st1523" d="M1016,413.8c1.4-0.2,2.1,1.1,3.1,1.6c1.6,3.9,1.5,7.9,1,12c-0.2,0.5-0.5,0.9-0.9,1.2
		c-4.4,0.2-7.2-2.2-9.3-5.7c0.1-1,0.8-1.6,1.3-2.4c1-1.7,1.3-3.7,2.5-5.4C1014.3,414.4,1015.1,414.1,1016,413.8z"/>
	<path class="st1524" d="M1007,417.3c0.1,0.3,0.3,0.7,0.4,1c0.4,0.6,0.4,1.3,0.1,2c-1.2,1.2-2.4,0.7-3.4-0.3
		c-1.2-1.2-2.4-2.1-4.1-2.4c-1.9-1.6-3.7-3.4-5.2-5.5c1.4-3.5,3.9-2.2,6.1-1.1C1003.7,412.3,1004.9,415.2,1007,417.3z"/>
	<path class="st1525" d="M1007,417.3c-2.8,0.1-2.8-3-4.5-4.1c-2.3-1.5-4.3-4.9-7.3-0.8c-0.6,1.1-1.7,1.6-2.9,1.8
		c-0.7,0-1.4-0.2-1.9-0.7c0.5-2.1,3.3-2.1,3.8-4.2c4.4-0.2,8.4,0.5,11.4,4.2C1005.6,414.8,1007.4,415.7,1007,417.3z"/>
	<path class="st1526" d="M1019.1,428.1c1.1-4.2,0-8.5,0-12.7c0.7,0.7,1.4,1.4,2.1,2c0,4.6-0.4,9.2,2.8,13.1
		C1021.5,431.4,1020.1,430,1019.1,428.1z"/>
	<path class="st1527" d="M1007.2,420.4c0.1-0.7,0.2-1.4,0.2-2.1c0.2,0,0.5-0.1,0.7-0.1c0.4-0.1,0.6,0.9,0.6,0.4
		c0-1.1,0.9-1.5,1.3-2.3c1.6-1.7,3.3-3.2,5.9-2.4c-0.6,0.5-1.2,1.1-1.8,1.6c-1,1.9-3.5,3.3-2,6.1c-0.4,0.9-1.2,0.9-2,1
		c-1.3,0.9-2.3,0.2-3.1-0.8C1006.8,421.2,1006.8,420.8,1007.2,420.4z"/>
	<path class="st1528" d="M1010.1,416.2c-0.5,1.1-1,2.2-1.5,3.3c-0.2-0.4-0.3-0.9-0.5-1.3C1008.1,416.8,1008.8,416.3,1010.1,416.2z"
		/>
	<path class="st1529" d="M917.3,633.1c1.4-4.1-3-2.3-4-3.9c-0.3-0.5-0.3-1,0.2-1.4c2.1-1.2,4.1-0.7,6.1,0.4c5.3,3.8,5.5,4.7,2.3,9
		c-0.7,0.4-1.4,0.2-1.9-0.3C919,635.5,917.7,634.6,917.3,633.1z"/>
	<path class="st1530" d="M917.3,633.1c1.3,0.8,3.1,1.1,3,3.2c-0.5,0.3-0.9,0.3-1.5,0.2c-2.4-1.6-6.1,0.1-7.7-3.3
		c-0.8-1.8-3-0.5-4-1.9C910.7,630.7,914,632,917.3,633.1z"/>
	<path class="st1531" d="M913.3,628.2c0,0.3,0,0.6,0,1c-1.6,0-3.3,0-4.9,0c-2.1-0.1-3.1-0.9-2-3.1c0.4,0.1,0.7,0.2,1.1,0.2
		C909.2,627.3,912,625.5,913.3,628.2z"/>
	<path class="st1532" d="M858.5,575.2c-0.1-0.3-0.1-0.6-0.2-0.9c1.3-1.7,2.7-3.3,4-5c1.3,0.6,3.6,0.3,2.9,2.9
		c1.7,3.5-0.2,7-0.1,10.5c-1.3,3.4,3.3,5.5,1.8,8.9c-0.9,0.6-1.9,0.6-2.9,0.4c-0.5-0.2-1-0.4-1.3-0.8c-2.4-3.8-2.6-8.1-2.7-12.4
		c0-0.8,0-1.6-0.7-2.2C859,576.2,858.8,575.7,858.5,575.2z"/>
	<path class="st1533" d="M864.3,583.2c-0.2-3.7,0.4-7.4,1-11c0.7-1,1.3-2,2-3c1.7-0.7,3.3-1.3,5-2c1.9,0.1,5-1.1,3,3
		c-0.4,0.3-0.7,0.7-1.1,1c-2.9,2.5-4.9,5.7-6.4,9.2C866.9,581.5,866.5,583.5,864.3,583.2z"/>
	<path class="st1534" d="M865.4,889.8c0-7,0-13.9,0-20.9c1.9,2.5,0.6,5.5,0.9,8.2c0.2,2,0,4,2.3,5c2.5-2.6,0.9-6.5,2.7-9.3
		c0.4-0.3,0.9-0.3,1.3,0c0.9,0.9,1.3,2,1.1,3.3c-1.1,3.5-1.4,7,0.1,10.5c0.6,1.4,0.6,2.9,0.2,4.3c-0.7,2.2-0.4,4.2,1.3,6
		c1,1,2.1,2.3,0.9,3.9c-2.7-0.6-4.1-3.7-7-4C868,894.5,866.7,892.1,865.4,889.8z"/>
	<path class="st1535" d="M876.3,900.8c0-1.7-1.1-2.7-2.3-3.6c-2.6-1.9-2.2-4.1-0.7-6.4c3.2,3,7.1,5.3,9.2,9.3c2.6,2.3,7.4,2,7.9,6.7
		c-0.2,0.2-0.5,0.4-0.8,0.5c-1.5,0.3-0.3,0.6,0,0.7c2.4,1,4.6,2.2,6.9,3.5c1.4,0.8,3.1,1.5,1.5,3.6c-1,0.1-1.9,0.3-2.8-0.4
		c0,0,0,0,0,0c-3.3-2.9-8-3.6-11-7C881.6,905.5,877.8,904.5,876.3,900.8z"/>
	<path class="st1536" d="M903.3,916.7c-0.7,0-1.3,0-2,0c-0.3-0.3-0.5-0.7-0.5-1.1c0.3-0.7,0.4-1.2-0.6-1.7c-1.4-0.7-2.1-2-0.8-3.6
		c2.4-0.8,4.8-0.5,7.2-0.2c0.8,0.3,1.3,0.9,1.5,1.7c0.4,1.3,0.3,2.6-0.4,3.8C906.5,916.9,904.6,915.6,903.3,916.7z"/>
	<path class="st1537" d="M903.3,916.7c1.1-1.4,2.6-0.8,4-1c2.4-1.5,4.3,0.2,6.3,0.8c1.2,0.8,2.9,1.1,2.7,3.1c-0.3,0.3-0.7,0.6-1,0.9
		c-1.4-0.1-2.9,0.5-4-0.9C909.1,917.6,906.4,916.6,903.3,916.7z"/>
	<path class="st1358" d="M901.3,915.8c0,0.3,0,0.6,0,0.9c-2.2-0.1-4.3-0.5-6-2c1,0,2,0,3,0C899.7,914.1,900.5,914.9,901.3,915.8z"/>
	<path class="st1538" d="M990.2,430.3c-3.1-0.7-4.5-2.3-3.3-5.7c0.5-1.3,0.2-2.9,0.3-4.4c0-1.9,0.5-3.6,2-5c1.6,2.6,4.1,4.6,5,7.6
		c0,1.8-0.4,3.5-0.8,5.2C993,429.7,992.5,431.5,990.2,430.3z"/>
	<path class="st1539" d="M993.2,422.4c-1.6-2.2-5-3.4-4.1-7c0.4-0.6,0.8-1.3,1.3-1.9c0.6,0,1.3,0,1.9,0c2.2,0.9,4.8,1.3,5.7,4
		c0.2,1.2,0,2.3-0.7,3.2c-0.5,0.5-1,0.9-1.6,1.3C994.9,422.4,994.1,423,993.2,422.4z"/>
	<path class="st1540" d="M867.3,580.2c1.2-3.9,2.1-8,7-9c0.4,0,0.7,0.1,1.1,0.1c0,0.3,0,0.6,0,1c-1,1.5-2.1,3-3.3,4.6
		c3.9,0.5,5.7,2.8,6.3,6.4c0.3,1.8,1,3.8-2,3.9c-1.9,0-4.2,0.7-3.4-2.9c0.4-1.7-1.7-2.5-3-3.7c-1,6.9-0.1,8.4,5.4,9.6
		c0,0.3,0,0.6,0,1c-1.2,1.2-2.6,1.1-4.1,0.7C865.7,588.6,865,586.6,867.3,580.2z"/>
	<path class="st1541" d="M875.3,570.2c1.2-3.2-2.3-1.7-3-3c2-2.8,4.7-0.5,7-1C882,571.5,877.7,569.8,875.3,570.2z"/>
	<path class="st1542" d="M1043.3,664c3.6,3.5,3,7.7,1.8,12c-1.5,0.5-1.7,1.6-1.2,3c-2.7,4-7.5,3.8-11.2,5.8
		c-3.5-0.8-6.6-1.9-7.1-6.2c-0.2-1.5-1.4-2.6-2.2-3.9c-1.3-2.2-2.1-4.6-3.1-6.9c-0.4-1.3-1.3-2.3-2.2-3.2c-2.7-3.7-6.6-3-10.3-3
		c-2.3,0-3.9-0.3-1.8-2.9c2.9-1.1,4-5,7.6-5C1026.3,649.2,1035.3,655,1043.3,664z"/>
	<path class="st1543" d="M1033.1,684.1c2.9-3.3,7.5-2.8,10.8-5.1c0.3,0.8,0.7,1.6,1,2.3c-0.8,0.7-1.1,2-2.2,2.4
		c-2.4,0.7-4.8,1.6-7.3,2.1c-0.7,0.1-1.4-0.1-2-0.5C1033.1,685,1033,684.6,1033.1,684.1z"/>
	<path class="st1544" d="M1043.1,682.9c0.2-1,0.4-2,1.8-1.6c0.1,1.2,0.2,2.3,0.3,3.5c0.6,2,0.3,4,0.2,6c-0.4,0.5-0.9,0.6-1.5,0.4
		C1042.4,688.5,1042.4,685.8,1043.1,682.9z"/>
	<path class="st1545" d="M1045.1,691.1c0.1-2.1,0.1-4.2,0.2-6.3c1.4,3.6,1,7.4,0.8,11.2c-0.5,0.4-1,0.3-1.4-0.2
		C1043.7,694.1,1043.9,692.6,1045.1,691.1z"/>
	<path class="st1546" d="M1045.1,696c0.3,0,0.6,0,1,0c0,1-0.1,2-0.1,3c0,1-0.2,1.8-1.1,2.3c-1.1-0.2-1.5-1-1.7-2
		c-0.1-0.7,0-1.4,0.3-2.1C1043.9,696.6,1044.4,696.2,1045.1,696z"/>
	<path class="st1376" d="M1045.1,701c0.3-0.7,0.6-1.3,0.9-2c0.4,0,0.7,0,1.1,0c0.1,1.4-0.7,2.4-1.9,3.1c-0.3-0.2-0.4-0.5-0.3-0.7
		C1044.9,701.1,1045,701,1045.1,701z"/>
	<path class="st1547" d="M1043.3,664c-4.5-2.2-7.3-6.6-12.1-8.7c-4.1-1.8-7.6-3.3-12.1-1.7c-1.8,0.6-3.9,0.4-5.9,0.6
		c-1.7,0.8-2.4-0.1-2.9-1.5c-0.4-1.8,0.2-3.6,0.6-5.3c1.6-7.3-0.8-10.7-8.2-11.6c-0.6-0.1-1.3-0.2-1.9-0.3c-1.9-0.4-4.4,0.3-4.8-2.7
		c0.1-0.2,0.3-0.4,0.4-0.7c4,0,8,0,11.9-0.1c1.6,0.6,2.8,1.8,4,3.1c0.1,1.4,0.9,2.3,1.9,3.2c0,3.6,0.1,7.1,0.1,10.7
		c-1.1,0.9-1,2.3-1.6,4c2.6-0.6,4.8-0.6,6.8-1.9c2.6,0,5.1,0,7.7,0.1c1.2,1,2.6,1.7,4.2,1.8c2.6,0.9,4.1,3.6,7,4
		c1.1,2.3,2.8,3.9,5,5C1043.2,662.7,1043.2,663.4,1043.3,664z"/>
	<path class="st1548" d="M892.4,607.1c-0.6,4.7,0.8,9.5-1.2,14c-0.5,0.2-0.9,0.2-1.3-0.2c-0.6-0.8-0.7-1.8-0.8-2.8
		c-1-10.5-3.6-13.2-14.3-14.5c-0.6-0.1-1.3-0.2-1.9-0.3c-1.2-0.3-2.5-0.5-2.7-2.1c0.9-1.2,2.3-1.2,3.5-1.5c4.2-0.8,7.7-2.8,10.4-6.1
		c2.8-3.4,3.8-3.2,5.5,0.7c0.4,0.9-0.2,2,0.8,2.7C891.8,600.3,892,603.8,892.4,607.1z"/>
	<path class="st1549" d="M890.4,597.1c-1.7-1.4-1.6-4-3.7-5.2c-4.3,5.1-8.7,10.1-16.4,9.3c-0.3,0.4-0.8,0.6-1.2,0.8
		c-1.2,0.3-2.5,0.2-3.7-0.2c-0.7-0.4-1.2-0.9-1.6-1.6c-0.2-0.7-0.2-1.5,0.2-2.2c0.4-0.3,0.8-0.5,1.3-0.6c2.4,0,4.4,1.5,6.8,1.4
		c3.1,0.3,5.3-2.3,8.1-2.7c2.2-1,3-3.3,4.4-5c1-1.2,1.4-3,3.5-2.8C888.9,591.3,890.6,593.9,890.4,597.1z"/>
	<path class="st1550" d="M965.4,607.1c-0.4,0-0.8,0-1.2,0c-0.6-7.3,1.1-14.7-0.9-21.9c-0.1-1.3-0.1-2.7-0.2-4
		c0.1-1.4,0.2-2.8,0.3-4.2c0.6,4.1,1.3,8.1,1.9,12.2c0.6,5.3,0.5,10.6,0.1,15.9C965.4,605.8,965.4,606.4,965.4,607.1z"/>
	<path class="st1551" d="M1019.1,428.1c1.6,0.8,3.3,1.6,4.9,2.4c1.7,1.4,3.4,2.7,5,4.1c0.3,0.3,0.6,0.6,0.8,0.9
		c1.1,0.3,1.8,0.9,1.7,2.2c-0.4,1.1-1.3,1.5-2.4,1.8c-2.3,0.1-4.5,1.1-6.8,0.6c-1.3-0.4-2.3-1.1-3.2-2.1c-1.2-1.5-2.6-2.9-4.1-4.2
		c-1.3-1-2.9-2.1-2.2-4c0.7-1.9,2.7-1.5,4.3-1.4c0.6,0,1.3,0.2,1.9-0.2L1019.1,428.1z"/>
	<path class="st1552" d="M1031.1,726c0.8,0.4,1,1.1,1,2c-1.7,0-3.3,0-5,0c-1-0.7-2-1.3-2.9-2c1-1.1,2.5-1.3,3.9-1.5
		C1029.3,724.5,1030.4,725,1031.1,726z"/>
	<path class="st1553" d="M944.2,922.7l-0.5,0c0,0-0.5,0-0.5,0c-2.1-1.7-4.7-0.6-7-0.9c-1-1-2.6,0-3.5-1.2
		c-1.2-5.6-1.5-11.1,1.5-16.3c1-1.4,2.2-2.4,4.1-1.9c3.3,3.2,1,6.8,0.8,10.2c-0.3,5.3,0.4,6.4,5.7,6.7c2.5,0.6,5.8-1.3,7.3,2.3
		c-2,0.1-3.9,0.1-5.9,0.2C945.4,921.8,944.7,922,944.2,922.7z"/>
	<path class="st1554" d="M952.1,921.7c-2.5-1.8-5.3-0.5-7.9-0.9c-4.7-3.8-4.6-9.2-4.4-14.3c0.1-2.5,2.8-3.4,5-4.2
		c0.8-0.2,1.6-0.1,2.3,0.2c0.4,0.4,0.5,0.8,0.5,1.4c-1.3,3.5-0.3,6.9,0.1,10.3c0.4,3.7,4.2,3.8,6.4,5.6c0.3,0.3,0.7,0.7,1,1
		C954.4,921.9,953.3,921.7,952.1,921.7z"/>
	<path class="st1555" d="M892.3,649c0-0.9,0.1-1.9,0.1-2.8c2.3-3.4,2.7-7.8,5.3-11.1c1-1.3,1.6-3,3.6-3c0.4,0.3,0.7,0.7,1.1,1
		c0.4,1.1-0.5,1.6-1.1,2.2c-1.6,1.9-2.7,4.1-3.8,6.2c-1,1.7-1.8,3.6-2.3,5.5c-0.4,1.1-0.7,2.2-1.5,3.1c-0.4,0.3-0.9,0.3-1.3,0.2
		C891.9,649.9,891.9,649.4,892.3,649z"/>
	<path class="st1556" d="M933.3,920.8c1.1,0,2.3-0.2,3,1c-3,0-6,0-9,0c-0.3-0.3-0.7-0.7-1-1c-1.1-0.8-2.7-1.4-2.5-2.8
		c0.5-4.7-1-9.7,1.7-14.1c0.4-0.4,0.9-0.6,1.4-0.8c1.4-0.2,2.6,0.2,3.6,1.3c1.6,2.6,1.2,5.3,0.5,8.1
		C930.2,915.6,929.9,918.7,933.3,920.8z"/>
	<path class="st1557" d="M913.3,628.2c-1.9-0.9-4.2-0.4-5.9-1.9c0.3,0,0.6-0.1,0.9-0.3c0.6,0,1.3,0,1.9,0c1.3-0.5,2.8-0.3,4.2-0.8
		c3.8-2.8,5.8,1.6,8.8,2.2c1.1,0.4,2.2,0.8,3,1.8c0.2,0.5,0.2,0.9-0.2,1.4c-0.9,0.6-1.8,0.7-2.9,0.5c-1.2-0.8-2.7-1.2-4-1.9
		C917.4,628,915.3,628,913.3,628.2z"/>
	<path class="st1558" d="M865.3,821.9c-0.3,0-0.6,0-0.9,0c0-0.3,0-0.7,0-1c-0.8-3.7-0.7-7.3,0-11c0.3-0.3,0.7-0.7,1-1
		c1.9,2.2,1.7,5,2.3,7.5c0.1,1.1,0.1,2.1-0.1,3.2c-0.1,0.8-0.3,1.6-0.8,2.2C866.2,822.3,865.8,822.3,865.3,821.9z"/>
	<path class="st1559" d="M864.3,809.9c0,3.7,0,7.3,0,11c0,0-0.5,0-0.5,0s-0.5,0-0.5,0c0-3.7,0-7.3,0-11
		C863.7,809.9,864,809.9,864.3,809.9z"/>
	<path class="st1560" d="M890.3,621.1c0.3,0,0.6,0,0.9,0.1c0,0.9,0,1.8-0.1,2.8c-1.8,1.8-1.8,4.6-3.9,6.2c-3.8,1.8-6.4-0.6-8.8-2.7
		c-6.6-5.8-11.4-12.6-11.8-21.8c4.6-3.3,5.6,1.1,6.3,3.6c1.8,5.7,5.9,9.4,9.9,13.3c2.1,2,4,2.1,5.6-0.6
		C889,621.3,889.5,620.8,890.3,621.1z"/>
	<path class="st1561" d="M854.4,733.1c-0.8,1.8-2.3,2-4,2c0-0.3,0-0.7,0-1c0.6-0.4,1.3-0.7,1.9-1.1
		C853,732.4,853.7,732.5,854.4,733.1z"/>
	<path class="st1562" d="M1031.2,437.5c-0.4-0.7-0.9-1.3-1.3-2c0.8,0,1.5-0.1,2.3-0.1c2,2.3,2.8-0.4,4.1-1c0.1,0.4,0.2,0.9,0.3,1.3
		c0.1,1.6,0.4,3.3-0.9,4.6c-0.4,0.3-0.8,0.5-1.3,0.5c-0.5-0.1-0.9-0.3-1.3-0.5c-0.2-0.1-0.4-0.3-0.5-0.4
		C1031.9,439.2,1031.5,438.4,1031.2,437.5z"/>
	<path class="st1563" d="M867.3,605.1c1.1,8.8,9.2,23.8,19.9,25c0,0.6,0,1.3,0,1.9c-0.8,1.4-3.2,1.6-3,3.8c-0.4,0.8-1.2,0.9-2,0.7
		c-2.4-2-4.5-4.3-7.5-5.5c-0.8-0.3-1.4-0.9-1.9-1.6c-5-7.4-9.6-14.9-9.2-24.4c0-0.9,0.2-1.6,0.9-2.2c1.1-0.4,2,0.1,2.8,0.8
		C867.7,604.2,867.7,604.7,867.3,605.1z"/>
	<path class="st1564" d="M887.3,646.1c0,1,0.1,2,0.1,2.9c-4.6,0.8-2.7-3.3-3.8-5.1c-0.1-1.7,0.5-3.4,0.1-5.1
		c0.2-0.9,0.1-2.1,1.6-1.7C887.8,639.7,884.8,643.5,887.3,646.1z"/>
	<path class="st1565" d="M885.3,637.1c-0.8,0.5-0.8,1.2-0.8,2c0,2-1,3.1-2.9,3.4c-3.3-1.4-0.2-3.6-0.6-5.4c-0.1-0.4,0-0.8,0.2-1.2
		c0.4-0.2,0.7-0.2,1.1,0c0.6,0,1.3-0.1,1.9-0.1C884.6,636.2,884.9,636.7,885.3,637.1z"/>
	<path class="st1468" d="M1036.2,737.9c0.3-0.1,0.5-0.2,0.8-0.3c0.3,1.9,0.1,3.7-0.8,5.4c-0.8,1.3-1.7,2.3-3.1,3c0,0,0,0,0,0
		c-0.3-0.3-0.5-0.7-0.6-1.2c0.2-1.6,1.3-2.8,1.9-4.2C1034.9,739.6,1035.3,738.6,1036.2,737.9z"/>
	<path class="st1566" d="M911.1,633.2c2.4,1.8,6.1,0,8.1,2.9c1.7,2,3.2,4.1,3.2,6.9c0,0.8,0.1,2,0.8,2.2c5.8,2.5,4.7,8.4,6.6,12.8
		c-1.2,2.6-2.9,1.2-3.9,0.1c-4.6-5.6-9.4-11.2-12-18.1c-1.4-2.3-5-3.1-4.6-6.7C909.9,633.2,910.5,633.2,911.1,633.2z"/>
	<path class="st1567" d="M900.3,634.1c0.8-0.1,1.6-0.2,2-1c1,0,2,0,3,0c2.9,8.8,5.5,17.8,14.4,22.9c0.8,0.5,1.6,1.1,2.2,1.8
		c0.6,1.1,1.2,2.2,0.5,3.4c-2.8,1.2-4.3-1.2-6.2-2.3c-1.8-1.7-4.2-1.6-6.4-2.3c-2.3-4.9-4.7-9.8-7.7-14.3
		C900.3,639.9,899,637.3,900.3,634.1z"/>
	<path class="st1568" d="M921.3,658.1c-0.7-0.3-1.3-0.7-2-1c-1.2-1.1-2.3-2.3-3.4-3.4c-5.5-5.6-8.9-12.3-8.6-20.4c0.7,0,1.3,0,2,0
		c1.7,2,3.3,3.9,5,5.9c-1.4,4.5,2.1,7.3,4,10.5c1.4,2.3,3.3,4.4,5.2,6.5c0.8,0.9,1.4,1.9-0.3,2.6
		C922.5,658.8,921.8,658.6,921.3,658.1z"/>
	<path class="st1569" d="M907.3,633.2c1.9,6.6,3.4,13.4,8.1,18.8c1.4,1.6,4,2.3,3.9,5.1c-5.6-1.9-8.4-6.3-10.1-11.6
		c-1-3-3.6-4.9-4.6-8c-0.6-2-0.2-3,0.6-4.3C905.9,633.2,906.6,633.2,907.3,633.2z"/>
	<path class="st1570" d="M1018.2,455.3c-0.8-1.6-2.4-2.1-3.9-2.7c-0.2-0.4-0.3-0.8-0.5-1.1c3.4-2,5.5,0.7,7.8,2.3
		c2.6,1.9,4.7,4.6,7.3,6.6c0.3,0.3,0.6,0.8,0.7,1.2c0,0.8-0.5,1.3-1.3,1.5c-1.1-0.2-2.2-0.5-3.3-0.7c-0.3-0.3-0.5-0.6-0.8-0.9
		C1023.7,457.9,1020.1,457.4,1018.2,455.3z"/>
	<path class="st1571" d="M866.3,817c-0.3-2.7-0.7-5.4-1-8.1c0.7-2,1.4-3.9,2.1-5.9c0.4-0.7,0.7-1.4,1.1-2c0.6-0.1,1.2-0.1,1.8-0.2
		c0.5-0.3,1-0.2,1.4,0.2c0.6,0.9,0.6,1.9,0.4,2.9c-1.2,2.4-2.3,4.9-2.9,7.6C868,813.2,869.7,816.4,866.3,817z"/>
	<path class="st1410" d="M1037.1,472.2c0,0.3,0,0.7,0,1c-2.6-0.3-1.7-2.4-2-3.9c0,0,0.2-0.2,0.2-0.2c0.4-0.2,0.8-0.2,1.2,0.1
		C1037.3,470.1,1036.5,471.3,1037.1,472.2z"/>
	<path class="st1572" d="M892.3,649c0,0.4,0,0.7,0,1.1c0.6,1.2-0.6,2.1-0.6,3.4c0.3-1.3,0.8-2.4,1.6-3.4c0.1-1.1-0.2-2.3,1-3
		c6.7,7,6.5,15.1,3.8,23.6c-1.8,1.9-1.1,5.3-3.9,6.6c-3.8-2.4,1.1-4.8-0.4-6.9c-0.6,0.6,0.9,3-1.6,2.8c-3-5-3-10.6-3.3-16.1
		c0.5-2.2-1.3-4.7,0.7-6.8C890.5,649.9,891.4,649.5,892.3,649z"/>
	<path class="st1573" d="M889.6,650.3c0.9,2.1,0.7,4.4,0.6,6.6c-1.4,1.9,0.9,4.8-1.6,6.4c-1.4-0.8-0.5-1.9-0.7-2.5
		c0.1,4.7,0.8,10.3-6.4,11.4c-1.2-0.7-1.6-1.9-1.9-3.1c0.6-5.5,3.7-10,5.7-14.9c0.8-1.4,1-3.3,2.9-3.9
		C888.7,650.2,889.2,650.3,889.6,650.3z"/>
	<path class="st1574" d="M888.2,650.2c-0.6,1.3-1.3,2.6-1.9,3.9c-2.5,0-1.9-2.3-2.7-3.6c-0.5-1.4-0.2-3.7-2.9-1.9
		c-1.4,0.9-2.8,1.4-2.9-1.2c0.7-1.9,2.2-3,3.8-4c0.9-0.3,1.7-0.8,2.7-0.3c1.9,1.6-0.5,5.3,3.1,6C887.7,649.4,888,649.8,888.2,650.2z
		"/>
	<path class="st1575" d="M1035.9,469.2l-0.3,0c0,0-0.3,0-0.3,0c-0.5-2.8-2.2-4.6-5.1-4.9c-0.6-0.4-1.3-0.8-1.9-1.2
		c0.4-0.3,0.7-0.6,1.1-0.9c0.5-0.5,1.2-0.6,1.9-0.5c1.3,0.4,2.5,0.9,3.3-0.8c0.5-0.7,1.1-1.1,1.9-0.5
		C1037.8,463.4,1036.9,466.3,1035.9,469.2z"/>
	<path class="st1576" d="M871.2,801c-0.3-0.1-0.7-0.1-1-0.2c-0.6-1.6-1.3-3.2-1.9-4.9c2.1-1.6,2.6-3.2,0.4-5.2
		c-0.6-0.5-1.1-1.2-1.4-2c-0.9-2.4-1.9-5.6-0.2-7.2c2-1.9,3.2,1.9,5.2,2.4c4.3,1.4,4.6,5.3,5.3,8.9c-2.7,1.6-6.2,2.7-3.7,7
		c0.2,0.7,0.1,1.4-0.3,2.1C872.4,802.6,871.9,801.6,871.2,801z"/>
	<path class="st1577" d="M926.3,903.8c-1.1,5.7-2.3,11.3,0,17c-1.7,0-3.3,0-5,0c-4.6-3.4-2.2-8.8-3.7-13.1c1-2.7,2.9-4.4,5.7-5
		C924.4,902.7,925.5,902.9,926.3,903.8z"/>
	<path class="st1578" d="M918.3,907.8c2.3,4,0,9.1,3,13c-1.7-0.3-3.3-0.7-5-1c-1-0.7-2-1.3-3-2c-0.3-2.8-0.3-5.7-1.4-8.4
		c0.2-1.1,0.8-1.9,1.8-2.3C915.4,906.5,916.9,906.5,918.3,907.8z"/>
	<path class="st1579" d="M979.1,469.2c0,0.7,0,1.4-0.1,2.1c0.2,0.9,0,1.8-0.2,2.7c-0.8,3.8-0.8,7.5,1.8,10.7c1,2.3,3,4.1,3.8,6.5
		c0.1,0.5,0.2,0.9,0.1,1.4c-0.2,0.7-0.7,1.1-1.5,1.3c-0.5,0-0.9-0.1-1.3-0.4c-1.4-1.5-3-2.8-2.6-5.2c-3.1-4.9-3.7-10.3-2.8-15.9
		C977.2,471.3,978.2,470.3,979.1,469.2z"/>
	<path class="st1580" d="M998.2,544.2c2.2,1.2,3.6,2.6,3,5.4c-0.8,3.7,0.7,7.5-0.3,11.2c-0.3,0.9,0,1.8,0.7,2.2
		c3.6,1.8,2.3,4.5,1.6,7.2c-0.8,2.9-1.3,2.9-3.8,0.3c-1.4-2-1.7-4.6-1.5-6.7c0.7-6.6-3.8-12.8-1-19.5
		C997.2,544,997.7,543.9,998.2,544.2z"/>
	<path class="st1581" d="M1010.1,577.1c-1,2-1,4.5-2.9,6.1c-2.4-0.5-1.5-3.1-2.8-4.3c-0.5-1.9-0.4-3.9-1.4-5.6
		c-0.2-1.3,0.5-2.1,1.2-3c0.3,0,0.5-0.1,0.8-0.2c2.2-0.4,2.7,1.3,3.2,2.8C1008.7,574.3,1008.6,576.1,1010.1,577.1z"/>
	<path class="st1582" d="M1010.1,577.1c-2,0.2-3-0.6-2.9-2.7c0.1-1.9-0.2-3.5-2.1-4.4c0.4-0.6,0.8-1.2,1.2-1.8c1.7,0,2.8,2.9,4.9,1
		C1011.7,571.9,1010.2,574.5,1010.1,577.1z"/>
	<path class="st1583" d="M1004.2,570.2c-0.3,1-0.6,2.1-0.9,3.1c-0.1,0.2-0.2,0.4-0.3,0.6c-1.9,1.9-3.9,3.4-6.8,2.6
		c-0.3-0.3-0.6-0.7-0.6-1.2c0-1,0.5-1.8,1.1-2.5c1.3-0.7,1.4-3,3.5-2.6c1,0.8,2,2.6,3,0C1003.5,570.2,1003.9,570.2,1004.2,570.2z"/>
	<path class="st1584" d="M998.2,544.2c-0.3,0-0.7,0-1,0c-1,0.8-1.7,0.5-2.3-0.6c0.2-2,2.2-1.5,3.2-2.4c0.7-0.3,1.4-0.6,2.1-0.9
		c0.3,0.3,0.6,0.6,0.9,0.9C1000.5,542.5,1000.1,544.2,998.2,544.2z"/>
	<path class="st1462" d="M1002.2,538.2c0.5-1.4-0.1-3.8,2.8-3.1C1004.6,536.7,1003.6,537.6,1002.2,538.2z"/>
	<path class="st1585" d="M990.2,536.2c-2.8,2.5-4.3,3-7.6-0.6c-2.2-2.4-2.3-4.8-3.5-7.2c-1-1.9,0.8-4.9-2.4-6.2
		c-0.6-0.3,0.7-1.5,1.5-2c4,2.2,7.2,5.3,9.4,9.3c1,1.7,1.6,3.7,2.7,5.3C990.5,535.3,990.4,535.8,990.2,536.2z"/>
	<path class="st1586" d="M990.2,535.2c-1.9-0.1-2.4-1.6-2.8-2.9c-1-3.1-2-6.1-5.8-7.2c-2.3-0.6-0.8-4.2-3.4-4.8c0-0.3,0-0.6,0-0.9
		c0.4-0.3,0.8-0.6,1.3-0.7C986.5,518.5,993.1,528.7,990.2,535.2z"/>
	<path class="st1587" d="M997.2,536.3c0.1,0.3,0.2,0.5,0,0.8c-0.7,1.5-1.2,3.2-2.7,4.1c-2,1.3-3.8,1.3-4.2-1.7
		c0.2-0.5,0.4-0.9,0.5-1.4c0.4-2.6-0.4-5.1,0-7.7c1.7-0.8,1-3.3,3-3.9c4.8-0.8,4.7,2.4,4.5,5.6C995.7,532.9,994.4,534.1,997.2,536.3
		z"/>
	<path class="st1588" d="M991.6,530.3c-0.6,2.6,1.2,5.4-0.7,7.8c-0.2-0.6-0.5-1.3-0.7-1.9c0-0.3,0-0.7,0-1c0.1-8.8-4.4-14.8-12-15.9
		c-0.8-0.1-1.6-0.2-2-1c1.1-0.1,2.1-0.1,3.2-0.2c0.8-0.8,1.8-0.9,2.8-0.7c2.7,0.9,4.7,2.9,6.7,4.7
		C990.7,524.5,992.9,526.8,991.6,530.3z"/>
	<path class="st1589" d="M990.3,539.5c4,4.3,4.7-1.4,6.9-2.3c0.8,1.2-2,3.3,0.9,4.1c-1,0.7-2,1.4-2.9,2c-1.4,1.4-3.2,1.5-5.1,1.6
		c-2.2-0.5-4.7,0-6.7-1.2c-1.1-3,2.1-1.2,2.7-2.3C987.9,541.6,989,540.3,990.3,539.5z"/>
	<path class="st1590" d="M986.1,541.3c-1,0.5-2,1.1-2.9,1.6c-2.4,1.3-3.5-0.6-4.9-1.9c-0.1-0.2-0.2-0.4-0.3-0.6
		c-0.1-0.4-0.1-0.8,0-1.3c0.5-0.8,1.6-0.4,2.2-1C982.2,539.3,983.5,541.5,986.1,541.3z"/>
	<path class="st1591" d="M980.2,538.3c-0.3,0.8-1,0.9-1.7,0.9c-1,0.1-1.6-0.5-2.1-1.3c-0.1-0.2-0.1-0.4-0.2-0.6
		c-0.7-1.3-1.3-2.6-2-3.9c0.4-0.4,0.8-0.9,1.2-1.3C977.1,534,977.9,536.7,980.2,538.3z"/>
	<path class="st1592" d="M979.1,488.3c1.1,1.6,2.2,3.2,3.2,4.9c-3.2,5.1-1.7,10.4-0.7,15.8c-0.9,1.6-3.8,1.7-3.2,4.4
		c-0.1,0.7-0.2,1.4-0.5,2c-0.2,0.4-0.6,0.7-1.1,0.9c-2.5-8.1,0-16.3-0.4-24.5C976.4,490.6,978.2,489.4,979.1,488.3z"/>
	<path class="st1593" d="M976.9,516.2c0.1-0.4,0.3-0.7,0.4-1.1c2.2-1.3,3.6-0.2,4.9,1.5c0.2,0.5,0.2,0.9,0.1,1.4c-1,0.1-2,0.1-3,0.2
		C978.5,517.5,977.7,516.9,976.9,516.2z"/>
	<path class="st1594" d="M848.4,689c-0.2-0.2-0.6-0.5-0.6-0.7c0.2-10.7-2.8-21.3-0.6-32.1c0.3-1.4,0.2-3,0.2-4.5
		c0-4.3-0.3-8.7,4.9-10.6c0.7,0,1.3,0.3,1.9,0.8c1.1,1.1,2.6,1.9,3.3,3.5c-0.1,1.4-1.3,1.9-2.2,2.7c-2,1.8-1.5,4.3-1.6,6.6
		c-0.2,3.8,0.6,7.7-1.2,11.3c-1.2,4.6-2.6,9.1-2.2,14.1C850.5,683,850.4,686.3,848.4,689z"/>
	<path class="st1595" d="M848.4,689c0.7-8-0.2-16.2,3-24c2.4,2.2,2.3,5.1,2.4,8c-0.8,10.5-0.1,21,1.6,31.4c0.8,1.7-0.1,3.5,0.4,5.2
		c-0.2,0.8-0.6,1.5-0.8,2.3c-0.2,0.6-0.5,1.1-1.2,1.2c-3.2-2.9-5.3-6.1-3.7-10.7c1-2.7-0.3-5-1.4-7.5
		C847.9,693.2,848.4,691,848.4,689z"/>
	<path class="st1596" d="M853.7,713.2c0.2-0.4,0.5-0.8,0.7-1.1c0.6-1.1,1.9-1.7,2.5-2.8c1.8-1.9,2.8-4.5,5.4-5.6
		c1.3,0,2.1,0.7,2.1,2c-0.6,1.4-1.7,2.5-2.4,3.8c-0.5,1-0.7,2.1-1.1,3.1c-1.7,2.2-3.5,4-6.7,3.3c-1-0.1-2.2,0.2-2.9-0.9
		C852.2,714.5,852.9,713.9,853.7,713.2z"/>
	<path class="st1597" d="M853.3,642.2c-0.4-0.3-0.7-0.7-1.1-1c-0.3-0.9-1.1-1-1.9-1.1c0-0.6,0.9-1.3,0-1.9c0.3-1.1,1.1-0.9,1.9-0.8
		c2.7,0.2,5.2,0.9,6.3,3.8c-0.5,1-1.3,1.5-2.3,1.8C855.2,643.1,854.2,642.7,853.3,642.2z"/>
	<path class="st1598" d="M852.5,635c-1.4-1.4-2.8-2.7-4.2-4.1c0.7-3.2-0.9-6-2-8.8c0.6-2.4,0.5-4.8,0.2-7.2c-0.2-1.7-0.6-3.7,2-4.2
		c1.1,0.2,1.5,1.1,1.8,2c1.4,3.7,2,7.6,2.2,11.5c0.1,1.6,0,2.8,1.6,3.9c1.8,1.3,2.6,3.3,1.8,5.6c-0.4,0.9-1.3,1.4-2.2,1.7
		C853.3,635.5,852.9,635.4,852.5,635z"/>
	<path class="st1599" d="M848.4,611.1c-2.1,1.8-0.5,4-0.3,6c0.2,2.1,0.6,3.9-1.7,5c0,0,0,0,0,0c-0.7-0.9-1-2.2-2-3
		c-1.4-4.9-2.8-9.8-3-15c1.6-0.2,2.7,1.1,4.6,1C849.4,605.1,849.7,608.2,848.4,611.1z"/>
	<path class="st1600" d="M848.4,611.1c0.1-2-0.6-3.8-1.6-5.5c-2.1-0.1-1,3.6-3.3,2.7c-1.8-0.7-0.4-3.2-2-4.2c-0.8-3.6-1.3-7.3-1-11
		c2.4-0.1,2.4,2.2,3.2,3.6c1,1.7,1.9,3.3,4,3.9c2.5,2,2.2,5.3,3.4,7.8c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.7,0.3,1.4,0.1,2.1
		c-0.6,0.9-1.3,1.3-2.3,0.5C849.3,612.3,849.2,611.6,848.4,611.1z"/>
	<path class="st1601" d="M847.4,601.2c-2.9,0.6-3.5-1.4-4.7-3.4c-0.9-1.5-0.7-3.5-2.3-4.6c-1.3-5.3-2-10.6-2-16c1.6,0.8,0.4,3.1,2,4
		l0,0C842.3,588,846.3,594.1,847.4,601.2z"/>
	<path class="st1598" d="M844.4,619.1c2,0.1,2,1.5,2,3C845,621.6,844.4,620.6,844.4,619.1z"/>
	<path class="st1602" d="M852.5,635c0.3,0,0.5,0.1,0.8,0.2c4.2,1.1,8.3,2.9,12.7,3.2c0.7,0.3,1.2,0.8,1.6,1.6c0,2-1.7,2.2-3,2.8
		c-0.5,0.1-1,0.2-1.5,0.2c-1.7-0.2-3.5-0.4-4.5-2.1c-2.1-1.2-4.2-2.4-6.3-3.6C852.3,636.6,851.3,635.7,852.5,635z"/>
	<path class="st1603" d="M994.2,883.9c0-0.3,0-0.7,0-1c-0.9-0.7-1.3-1.8-1.5-2.8c-0.1-0.4,0-0.9,0-1.3c0.5-1.4,1.1-2.8,1.5-4.3
		c0.2-0.6,0.5-1.2,1.2-1.5c5.4,2,0.9,6,1.8,9C996.2,882.5,995.2,883.2,994.2,883.9z"/>
	<path class="st1604" d="M995.3,872.9c-0.1,0.7-0.2,1.3-0.3,2c-0.5,0.9-1,1.7-2.2,0.9c-0.6-0.9-0.6-1.9-0.2-2.8
		c0.6-0.9,1.4-1.4,2.5-1.2c0,0-0.1,0.1-0.1,0.1C995.1,872.2,995.2,872.5,995.3,872.9z"/>
	<path class="st1605" d="M983.2,900.8c-2-5.8,2.4-8.2,6-11c-0.7,3-3.4,5.2-2.4,8.9C987.4,900.5,984.9,900.8,983.2,900.8z"/>
	<path class="st1606" d="M971.2,912.8c-0.3,0-0.7,0-1,0c-2.6-0.1-1.9-2.1-1.8-3.5c0.1-1.6,0.7-3.1,1.3-4.6c0.4-3.1,1.4-5.9,3.5-8.3
		c0.8-1,1.8-1.7,2.9-2.2c1.4-0.4,2.7-1.2,4.2-0.4c-2.8,5.7-6.4,11.1-7.1,17.6C972.9,912.4,972.1,912.7,971.2,912.8z"/>
	<path class="st1607" d="M980.2,893.8c-1.3,0.4-2.6,0.7-3.9,1.1c-1.2,0.5-1.8-0.2-2.4-1.1c1.3-3.8,5.3-3.8,8-5.6
		c0.5-0.1,1-0.2,1.5-0.2c1,0.2,2.1,0.3,1.7,1.8C983.5,891.1,981.9,892.5,980.2,893.8z"/>
	<path class="st1608" d="M993.3,879.8c1.1,0.8,0.9,1.9,0.9,3c-3.2,1-3.3,5.5-7,6c-0.7-1.8,0.8-3,1.1-4.5
		C989.6,882.3,990.4,879.9,993.3,879.8z"/>
	<path class="st1609" d="M989.2,884.6c0.3,1.8-1.4,2.8-2,4.3c-0.7,0.3-1.3,0.7-2,1c-0.4-0.9-1.3-0.9-2.1-1.1
		c0.1-2.3,2.1-2.7,3.5-3.7C987.4,884.6,988.2,884.2,989.2,884.6z"/>
	<path class="st1610" d="M971.2,904.8c-0.5,2.6-3.4,5-1,8c-0.3,1.4-3.2-0.3-2.8,2.7c0.3,1.7-2.3,2.4-4.2,2.3c-1,0.3-2,0.7-3,1
		c-0.4-0.4-1.1-0.6-1.3-1.3c0.6-1.1-1.9-2.6,0.5-3.6c3.4-1.4,5.4-4.3,7.3-7.2C967.8,905.2,968.9,903.4,971.2,904.8z"/>
	<path class="st1611" d="M959.2,917.8c0.3,0.3,0.7,0.7,1,1c-2,0.3-4,0.7-6,1c-3.6,0.7-7.3-1-7.4-4c-0.2-3.9-4-8.2,0.4-11.9
		c3.5-1.3,3.2,1.5,3.7,3.4c1,3.3,1.5,6.9,6.3,6.4C958.2,914.8,957.8,916.7,959.2,917.8z"/>
	<path class="st1455" d="M847.4,601.2c-2.5-6.6-7.1-12.5-7-20c1.6,0,2.9,0.2,3,2.3c0.1,6.2,3.6,11.3,6,16.7c1.6,1.3,1.9,3.2,2.2,5.1
		c0,1.4,0,2.8-1.2,3.8C848.2,606.9,848.8,603.6,847.4,601.2z"/>
	<path class="st1612" d="M850.4,605.1c-0.3-1.7-0.7-3.3-1-5c0.2-6.5-3.7-12.4-3-19c3.5,3.3,1.1,8.5,4,12c3.7,2.8,3.4,6.9,3.8,10.8
		c-0.1,0.6-0.4,1-0.7,1.4C852.3,606.1,851.3,605.9,850.4,605.1z"/>
	<path class="st1613" d="M841.4,561.2c4.3,3.6,5,9,6.9,13.8c0.9,2.4,1,5.2,3,7.2c4.3,5.7,5.8,12.3,6.4,19.2c-0.2,1.1-0.7,1.8-1.9,2
		c-1.5-0.3-2.4-1.4-2.4-2.7c0.1-3.9-2.1-7.1-3.2-10.6c-0.4-7.7-3.4-14.6-6.3-21.6C843,566.2,841.9,563.8,841.4,561.2z"/>
	<path class="st1614" d="M850.4,590.2c2.5,2.6,4,5.7,4,9.3c0,1.6,0.4,2.9,2,3.7c0.7,1,0.5,1.8-0.6,2.4c-1.2,0.3-1.8-0.5-2.4-1.4
		c-1-3.7-2-7.3-3-11C850.4,592.2,850.4,591.2,850.4,590.2z"/>
	<path class="st1615" d="M995.2,871.7c-0.6,0.4-1.3,0.9-1.9,1.3c-0.4,0.3-0.9,0.6-1.3,0.8c-1.7,0.4-3.5,0.9-5.3,1.1
		c-6.6-0.9-8.3-2.6-8.8-8.9c-0.2-2.2-1-4.1-1.4-6.2c0.6-3.5,3.2-3.1,5.7-2.9c4.8,5.8,4.8,5.8,5.3,11.4c3.6-2.5,5.3,0.6,7.4,2.5
		C995,871.1,995.1,871.4,995.2,871.7z"/>
	<path class="st1616" d="M993.2,844.9c-2.6,1.4-0.2,4.3-2,6.3c-1.3,1.4-2.4,2.2-4.1,2.6c-1.5,1-2.2,0.2-2.5-1.3c0.2-2.6,3.3-3,4-5.3
		c0.9-1.7,1.8-3.3,4.1-2.9C992.9,844.6,993.1,844.8,993.2,844.9z"/>
	<path class="st1616" d="M982.2,856.9c-1.2,1.8-3.6,0.7-5,2c-0.6,0.5-1.3,0.9-2.2,1c-1.1,0.1-2.2-0.1-3-1c2.2-4.2,6.2-4.8,10.3-5.2
		c1.3,0.3,2.9,0.2,2.9,2.2C984.2,856.2,983.2,856.5,982.2,856.9z"/>
	<path class="st1616" d="M999.2,836.9c-1.3,3.2,0.4,8.6-6,8c0,0-0.1-0.1-0.1-0.1c0.4-3.6,2.8-6,5.4-8.2
		C998.8,836.8,999,836.8,999.2,836.9z"/>
	<path class="st1617" d="M999,821c2.3-0.4,1.9,2.2,3.2,2.9c0,0.6,0,1.3,0,1.9c-1.6,1.8,1.1,4.8-1.8,6.3c-0.5-0.3-0.8-0.7-1-1.2
		c-0.8-2.5-0.2-5.2-0.8-7.7C998.4,822.5,998.7,821.7,999,821z"/>
	<path class="st1615" d="M985.2,855.9c-0.7-1.2-1.9-1-3-1c-0.3-2.3,0.7-3.2,3-3c0,1.3,0.7,1.9,2,2
		C986.8,854.8,986.1,855.5,985.2,855.9z"/>
	<path class="st1458" d="M1000.2,832c2-1.6,0-4.5,1.9-6.1c-0.3,2.4,0.9,4.9-0.9,7.1c-0.3,0.4-0.7,0.4-1.1,0.2
		C999.9,832.7,999.9,832.3,1000.2,832z"/>
	<path class="st1616" d="M1000.3,832.9c0.3,0,0.6,0,0.9,0c0.1,1.7-0.3,3.2-2,4c0,0-0.1-0.1-0.1-0.1
		C998,835.1,998.8,833.9,1000.3,832.9z"/>
	<path class="st1618" d="M1001.2,784.9c0.1,3.2,0.3,6.3-3.1,8.2c-1.2,0.7-1,2-0.9,3.2c0.3,4.6-0.8,9.4,0.7,14
		c2.8-1.2,1.1-3.1,1.6-5.1c1.1,1.4,1.9,2.5,2.7,3.6c-1.8,2.3-4.1,3.6-7,4c-1.6,1.2-2.8,0.7-3.5-1c-0.8-1.6-2.2-2.9-2.6-4.7
		c-0.5-3.8-0.5-7.6,0.1-11.3c1.6-3.2,2.9-6.4,5.4-9c1.9-1,3.6-2.5,6-2.3C1000.8,784.7,1001,784.8,1001.2,784.9z"/>
	<path class="st1619" d="M990.2,807.9c2.6,0.8,1.2,5.4,5,5c-0.4,1.8-1.3,3.7,1,5c-0.7,1-1.3,2-2,3c-0.9,2.1-1.6,4.2-3.2,6
		c-0.3,0.3-0.7,0.5-1.2,0.7c-1.2,0.2-2.3,0.1-3-1.2c-0.5-1.9-0.8-3.9-1.5-5.8c-0.4-2-1.5-3.8-2.1-5.8c-0.2-1-0.1-1.9,0.4-2.8
		c1.1-1.4,2.4-2.6,3.5-4.1C988.1,806.5,989.2,806.3,990.2,807.9z"/>
	<path class="st1620" d="M993.5,828.9c0.9-1.3,1.8-2.6,2.7-3.9c2.1,0,0.9,3.5,3.3,3.1c0.2,0.9,0.1,1.8,0.1,2.7
		c-0.5,4.8-3,8.6-6.4,11.8c-2.6,2.4-5.1,5.1-8,7.2c-0.4,0.3-0.8,0.6-1.2,0.8c-1.3,0.6-2.6,1.3-4.1,0.4c-0.3-0.4-0.4-0.8-0.5-1.3
		c0.6-2.8,3.6-3.5,5-5.6C988.3,839.5,993.5,835.8,993.5,828.9z"/>
	<path class="st1621" d="M993.5,828.9c1.4,3.9,0,7.1-2.5,10c-1.8,2.1-3.9,4-5.8,6c-1.1,0.5-1.7-0.1-2.3-0.9c-0.2-1,0.3-1.7,0.9-2.4
		c0.7-1.3,3.1-1.3,3-3.4c0.6-2.4,2.5-4,3.7-6c0.2-0.4,0.4-0.7,0.8-1C992,830.4,992.8,829.7,993.5,828.9z"/>
	<path class="st1619" d="M991.3,831.1c0,0.3,0,0.5-0.1,0.8c-0.5,0.9-1.1,1.6-2.2,1.5c-1.7-2.5-3.5-5.1,1.2-6.5l0,0
		c0.7-0.5,1.3-0.6,2,0C991.9,828.3,991.6,829.7,991.3,831.1z"/>
	<path class="st1622" d="M999.1,827.9c-3.2,1.3-1.5-2.3-2.9-2.9c0.5-1.6,1.2-3.1,2.8-4c0.1,0.6,0.2,1.2,0.3,1.9
		C999.7,824.6,1000.1,826.3,999.1,827.9z"/>
	<path class="st1457" d="M992.2,826.9c-0.7,0-1.3,0-2,0c1.3-2,2.7-4,4-6C994.4,823.2,993,825,992.2,826.9z"/>
	<path class="st1623" d="M997.3,532.5c1.2-3.8-0.1-5.6-4.2-5.3c-0.1-0.2-0.2-0.3-0.4-0.5c0.1-1.9,0.8-3.7,1.2-5.5
		c1-1.7,1.1-3.6,0.8-5.3c-1.1-5.4-0.6-10.7-0.3-16.1c1.3-0.7,2.6-0.6,3.7,0.4c1.5,1.2,2.6,2.3,2.2,4.8c-0.5,3.7-0.4,7.5,0.5,11.3
		c0.6,2.3,1.6,5.4-2,6.8c-0.8,0.3-1.3,0.6-0.1,1c0.5,0.1,1,0.1,1.5,0.2c0,0.3-0.1,0.6-0.1,0.8c-1.9,1.2-1,2.9-1,4.5
		c0,1.5,0.5,3.1-1.6,3.6c-0.3-0.1-0.5-0.2-0.5-0.4C997.1,532.7,997.2,532.5,997.3,532.5z"/>
	<path class="st1624" d="M998.1,500.3c-1.3,0.1-2.5,0.2-3.8,0.2c-0.7,0.5-1.5,0.5-2.3,0.2c-0.9-0.7-1.6-1.5-0.8-2.7
		c2.2-1.2,4.5-1.5,6.8-0.6c0.3,0.6,0.7,1.3,1,1.9C998.8,499.6,998.5,499.9,998.1,500.3z"/>
	<path class="st1625" d="M997.3,532.5c0.1,0.2,0.2,0.5,0.3,0.7c0.6,1.1,0.2,2.1-0.4,3c-2,0.4-3.6,0.3-3-2.5
		C994.6,532.1,995.2,530.5,997.3,532.5z"/>
	<path class="st1626" d="M857.3,770c0.9-1.5,1-3.1,1-4.8c0-1.8-0.4-3.5,2-4.4c2.9-1.2,0.1-3.9,0.9-5.7c3.4,0.7,3.6,1.1,3,6.7
		c-1.7,2-3,4.1-3.2,6.9c1.8-1.2,0.4-4.1,2.6-5c2.7,0.9,4.2,2.3,2.6,5.3c-1.2,3.8-3.9,7.5-1.3,11.8c0.6,1,0.9,3.1-0.4,3.5
		c-2.6,0.9-1.7-2.7-3.3-3.3C858.5,777.8,857.9,773.9,857.3,770z"/>
	<path class="st1627" d="M866.3,769c0.2-2.4-1-4-3-5c-0.3-0.2-0.3-0.4-0.3-0.7c0.1-0.2,0.2-0.3,0.3-0.3c-0.9-2.6,2-6.2-2-8
		c-0.5-6.3,0.2-12.8-2-19c1.7-1.3,3.3-2.7,5-4c-0.5,2.7,2.3,1.8,3.2,3c0.9,1.5,0.6,2.7-0.6,3.9c-3.4,1.3-2.1,3.8-1.5,6.1
		c-0.2,6.7,1.9,12.9,3.6,19.2c0.4,1.5,1.4,3,0.6,4.7c-0.7,0.6-1.5,0.5-2.2,0C867,769,866.7,769,866.3,769z"/>
	<path class="st1628" d="M877.3,792.9c-1.9-2.9-2.3-6.5-5-9c0-0.7,0-1.3,0-2c2.6-0.8,3.9,1.1,5.5,2.5c2.1,2.7,4.7,5.1,6.4,8.1
		c0.3,2.3,4,2.8,3,5.8c-1.9,1.7-4,1.9-6.2,0.6C879.1,797.4,877.8,795.4,877.3,792.9z"/>
	<path class="st1629" d="M877.3,784.9c-1.7-1-3.3-2-5-3c-1.7-4.3-3.3-8.7-5-13c0.7,0,1.3,0,2,0c1-0.4,1.5,0.3,1.9,1
		c2.7,4.3,5.9,8.2,9.4,11.8c0.1,0.5,0.1,1,0,1.5C880,784.6,879.3,786.1,877.3,784.9z"/>
	<path class="st1630" d="M1004.2,785.9c-1-0.3-2-0.7-3-1l0,0c1-8.7,2.2-17.4,4.9-25.9c1.5-4.7,2.1-9.8,1.7-14.9
		c0.3-0.4,0.7-0.6,1.2-0.7c4,1.6,8.2,3,10.1,7.5c1.7,1.4,1.6,2.8-0.4,3.6c-2.9,1.2-2,2.7-0.6,4.4c-0.6,4.2-6.1,6.8-4.1,12
		c0.1,0.3-0.2,1.2-0.5,1.3C1005.7,773.6,1008.2,782,1004.2,785.9z"/>
	<path class="st1321" d="M1019.2,751c-3.3-2.3-6.7-4.7-10-7c-0.5-0.6-0.8-1.2-0.7-2c0.1-0.5,0.4-0.9,0.7-1.2
		c5.1,1.5,8.6,5.5,12.9,8.3c0,0.3,0,0.7-0.1,1C1021.1,750.3,1020.1,750.7,1019.2,751z"/>
	<path class="st1631" d="M1033.1,744.7c0,0.4,0,0.9,0.1,1.3c-0.7,0.3-1.3,0.7-2,1c-1.9,1.1-3.8,1.7-6,1c-1.3-0.3-2.7-0.7-4-1
		c-3.4,0.3-5.2-2.2-7.4-4.1c-0.3-1.1,0-2,1.1-2.4c1.1-0.2,1.9,0.4,2.8,0.9c4,3,8.4,4,13.3,2.9
		C1031.7,744.2,1032.4,744.3,1033.1,744.7z"/>
	<path class="st1632" d="M1014.2,742.9c2.3,1.4,4.6,2.7,6.9,4.1c0.3,0.7,0.7,1.4,1,2c-5.2-1.3-8.8-5.1-13-8c-1-0.5-1.6-1.1-0.9-2.3
		C1011.9,737.8,1012.7,740.9,1014.2,742.9z"/>
	<path class="st1633" d="M1025.1,748c2-0.3,4-0.7,6-1C1029.7,750.5,1027.4,749,1025.1,748z"/>
	<path class="st1634" d="M852.4,732c-0.7-0.6-1.4-1.3-2.1-1.9c0.5-2.2-0.5-4.2-1-6.2c-1.3-5.9-1.2-6.2,5-6.8
		c2.5-1.6,4.7-0.5,5.9,1.6c1.3,2.3,3.9,5,0.7,7.9C858.3,728.6,856.5,732,852.4,732z"/>
	<path class="st1635" d="M860.3,726c2.1-4.2-0.4-8-6-9c0-0.3,0-0.6,0-0.9c2-1.3,4.7-1.7,6-4c0.3-0.4,0.7-0.6,1.2-0.6
		c0.7,0.1,1.2,0.6,1.6,1.1c2,3.1,5.7,1.7,8.2,3.2c0.7,2.5-1.8,3.6-2.3,5.6c-0.8,1.5-2.1,2.6-3.6,3.4
		C863.6,724.6,862.5,727.5,860.3,726z"/>
	<path class="st1636" d="M860.3,726c1.7-0.6,3.4-1.2,5-1.9c0.5,1.1-0.5,1.4-1.1,1.8c-3.3,2.4-6.6,4.8-9.9,7.1
		c-0.7,0-1.4-0.1-2.1-0.1c0-0.3,0-0.7,0.1-1C855.8,731,857.5,727.7,860.3,726z"/>
	<path class="st1637" d="M970,588.5c-0.3-1.1-0.5-2.2-0.8-3.3c0.4-0.3,0.8-0.5,1.3-0.4c2.5,1.6,3,4.6,4.5,7c0.4,2,1.5,3.7,2.8,5.2
		c0.7,1.4,2.1,2.6,1.4,4.4c-1.1,1-2.3,1.4-3.8,1.2c-1-0.6-1.3-1.8-1.8-2.7c-0.4-0.5-0.7-1.1-1-1.6c-0.6-1.1-1.1-2.3-1.5-3.5
		c-0.6-1.4-0.7-2.9-1.1-4.3C969.7,589.7,969.6,589.1,970,588.5z"/>
	<path class="st1638" d="M973.1,569.1c-0.2-2,1-3.3,2.1-4.7c0,0-0.1-0.2-0.1-0.2c2.8-0.6,5.7-1.6,7.6,1.6c0.4,1.1,0.4,2.1-0.1,3.2
		C979.6,570.4,976.3,568.2,973.1,569.1z"/>
	<path class="st1639" d="M973.1,569.1c3.1-2.9,6.1-1.3,9.1,0.1c1.6-0.1,2.7,0.7,3.5,2c0.2,0.7,0.2,1.4-0.3,2c-2.7,0.7-4.3-2-6.8-1.9
		c-0.4-0.1-0.9-0.1-1.3-0.2c-2.3-0.6-3.9,1.9-6.2,1.2c-0.3-0.4-0.2-0.8,0.1-1.2C971.8,570.4,972.5,569.8,973.1,569.1z"/>
	<path class="st1640" d="M969.2,573.2c-3.7,2.2-1,6.1-2.3,8.9c-2.3-3.4-2.1-8.8,0.4-11.5c1.3-1.4,2.2-1,2.9,0.5
		C970.2,572,970,572.7,969.2,573.2z"/>
	<path class="st1641" d="M969.2,573.2c0.3-0.7,0.7-1.3,1-2c0.3,0,0.6,0,1-0.1c0.1,0.4,0.1,0.8,0.2,1.2c1.2,1.8,0,3.7,0.3,5.6
		c-0.2,2.5,0.7,5.1-1.3,7.3c-0.4,0-0.7,0-1.1,0C967.8,581.2,967.9,577.2,969.2,573.2z"/>
	<path class="st1642" d="M966.9,608.2c-0.2-0.3-0.4-0.5-0.6-0.8c2.7-3.3,1.8-7.5,3-11.2c0.6-0.3,1.1-0.2,1.5,0.3
		c1.2,2.2,0.7,4.5,0.9,6.8c0,1.8,0,3.6-1.2,5.1C969.2,608.9,968,608.8,966.9,608.2z"/>
	<path class="st1643" d="M965.4,605.1c-0.6-5.3-0.2-10.6-0.1-15.9C966.6,594.5,967,599.8,965.4,605.1z"/>
	<path class="st1644" d="M970.1,596.2c-0.3,0-0.6,0-0.9,0c0.4-2.6-1-5.3,0.8-7.7c0.1,0.6,0.2,1.1,0.3,1.7
		C970.5,592.2,971.9,594.2,970.1,596.2z"/>
	<path class="st1645" d="M972.4,549.2c0.2-0.6,0.4-1.3,0.6-1.9c0,0,0.2-0.2,0.2-0.2c3-0.5,5.2,1.9,7.9,2.4c0.9,0.4,1.7,0.9,2.3,1.7
		c0.1,0.5,0,1-0.3,1.4c-1,0.7-2.1,0.7-3.2,0.6c-0.3,0-0.6,0-0.9,0.1c0.5,0.1,0.9,0.4,1.1,0.8c0.6,2.3,0.8,4.6-0.3,6.9
		c-0.4,0.5-0.8,0.6-1.4,0.3C978.6,556.1,977.2,551.8,972.4,549.2z"/>
	<path class="st1646" d="M978.3,561.2c0.4,0,0.7,0,1.1,0c2.2,0,3.1,2.4,5.1,2.7c0.4,0.2,0.8,0.4,1.2,0.6c1.1,0.7,1.5,1.7,1.2,3
		c-0.2,0.4-0.4,0.8-0.8,1c-1.8,0.1-2.7-1.2-3.8-2.3c-1.9-2.1-4.5-1.9-7-2.1c0-0.3,0-0.6,0-0.9C976.7,563.2,978.1,563.2,978.3,561.2z
		"/>
	<path class="st1647" d="M981.2,550.3c-3-0.2-5.5-1.8-7.9-3.3c-0.1-2.2-0.2-4.4-0.2-6.6c0-2,0.7-3.4,3.1-3.1c0,0,0,0,0,0
		c0.3,1.2,1.2,2.1,2.2,2.9l-0.1,0c0.1,2.8-0.1,5.7,2.7,7.5C981.4,548.5,981.8,549.4,981.2,550.3z"/>
	<path class="st1648" d="M1037.2,437.4c1.2-0.8,0.7-2.5,2.2-4.5c3.4,7.1,6.6,13.8,9.8,20.4c0,0.3-0.1,0.5-0.1,0.8
		c-3,2.6-7.3,1.6-9.4-2.4c-1.1-2-1.6-4.4-2.5-6.5C1036.4,442.6,1035.3,440,1037.2,437.4z"/>
	<path class="st1649" d="M1037.2,437.4c-0.4,2.7,0.8,5.3,1,7.9c0.6,2.3,1.3,4.5,1.9,6.9c0.3,1.9,0.7,3.8-0.3,5.6
		c-0.2,0.2-0.4,0.4-0.6,0.6c-1.5,2.9-1.5,6.1-1.2,9.2c0.1,1.7,0.1,3.2-0.8,4.6c-1.3-0.7-0.9-2-1.2-3c0.1-3,0.7-6-0.6-8.9
		c-2.9-6.5-2.5-13.1-0.2-19.7c0.5-1.7,0.9-3.3,1.4-5C1036.8,436.2,1037,436.8,1037.2,437.4z"/>
	<path class="st1650" d="M1050.1,459.3c-2.3,1.7-3.6,5-7.7,3.4c-2.5-0.9-3.5-1.9-3.2-4.3c0,0,0.1-0.1,0.1-0.1
		c0.8-0.7,1.8-1.2,2.9-1.4c2.6-0.5,5.3-1.1,7.9,0c0.5,0.4,0.5,0.9,0.2,1.4C1050.1,458.6,1050.1,459,1050.1,459.3z"/>
	<path class="st1648" d="M1050.2,458.3c0-0.3,0-0.7,0-1c-1.7-1-2-2.3-1-4c0,0,0,0,0,0C1050.3,454.8,1052.2,456.2,1050.2,458.3z"/>
	<path class="st1487" d="M950.2,630.2c-3-0.7-5.7-1.8-7.9-4.1c4.8,1,9.2,3.4,14.7,2.7c3.6-0.5,5.6-1.3,6.2-4.7
		c0.2-1.1,0.5-2.1,1.1-3.2c1.6-3-0.4-6-3.9-5.6c-1.7,0.2-4,0.9-4.4-0.8c-1.2-4.6-4.5-3.3-7.4-3.5c-5.4-0.3-10.8-1.5-16.3-1
		c14.7-4.4,28.8-4.3,41.4,6.1c2.9,2.4,2.7,6.1,1.5,8c-2.7,3.9-5.3,9.1-11.7,8c-0.3-0.1-0.8,0.5-1.2,0.8c-0.2,0.1-0.5,0.2-0.7,0.3
		C957.5,633.2,953.6,632.5,950.2,630.2z"/>
	<path class="st1651" d="M957,635.3c0-0.7,0-1.4,0.1-2.1c1.7-1.6,3.4,1,5.1-0.1c0,0,0,0,0,0c4.9,4.8,9.9,1.5,14.3-0.4
		c4.6-2.1,5.7-6.8,4.8-11.8c1.9,0.1,2.3,1.7,2.9,3c0.6,2.8,1.7,5.5,1.7,8.4c-0.7,1,0,2.1-0.1,3.1c-0.1,0.5-0.4,0.9-0.8,1.2
		c-0.6,0.4-1.3,0.6-2,0.7c-5.9,0.7-11.4,2.7-17.4,4.7c2.9,1.5,6.3,0.3,8,3.2c0.1,0.6-0.1,1-0.6,1.4c-4.9,0.6-9.7,0.8-14-2.4
		c-1-1.5-0.8-3.3-1.2-5c-0.2-0.4-0.3-0.9-0.4-1.3C957.1,637.1,956.8,636.2,957,635.3z"/>
	<path class="st1652" d="M983.3,624.1c-0.6-1-1.3-2.1-1.9-3.1c-0.1-3.1-3-4.4-4.4-6.6c-0.2-0.5-0.2-0.9,0-1.4
		c1.1-1.4,2.4-1.3,3.7-0.3c1.5,1.7,3.1,3.3,4.2,5.3c0.6,1.2,1.2,2.5,1.2,3.9c0,0.5-0.2,0.9-0.4,1.4C985,624,984.4,624.6,983.3,624.1
		z"/>
	<path class="st1653" d="M970.3,608.1c0-1.7,0-3.4,0-5.1c0.6-0.7,0.3-1.6,0.7-2.4c0.4-1.1,0.1-2.8,2.2-2.5c0.3,0.3,0.7,0.7,1,1
		c0.6,0.7,0.5,1.5,0.2,2.3c-0.4,3.5,1.2,7.2-1.3,10.5c-0.7-0.3-1.3-0.6-2-1C970.5,610.2,970.2,609.2,970.3,608.1z"/>
	<path class="st1654" d="M973.2,611.9c0.6-3.6-1.1-7.3,1-10.7c1.9,2.3,1,5.2,1.7,7.8c0.3,0.7,0.6,1.3,0.7,2.1c0,0.5-0.1,1-0.4,1.4
		c-0.7,0.4-1.4,0.2-2.1-0.2C973.8,612.2,973.5,612.1,973.2,611.9z"/>
	<path class="st1655" d="M970.3,608.1c1,0.7,0.9,1.8,0.9,2.8c-1.4-0.9-2.9-1.9-4.3-2.8C968.1,608.1,969.2,608.1,970.3,608.1z"/>
	<path class="st1656" d="M974.1,612.3c0.7-0.1,1.3-0.1,2-0.2c0.9-0.3,1.2,0.1,1.2,1c-0.1,0.4-0.2,0.8-0.4,1.2
		C975.7,614,974.7,613.4,974.1,612.3z"/>
	<path class="st1657" d="M923.2,628.1c-1.5,0-3.5-0.1-3.9-1.2c-1.1-3.2-3-0.4-4.3-0.9c-0.4-0.7-0.3-1.3,0.2-1.9c0-0.3,0.1-0.7,0.1-1
		c2.1-1.2,4.3-1.5,6.7-0.8c1,1.4,0.3,3.3,1.4,4.7C923.5,627.4,923.5,627.8,923.2,628.1z"/>
	<path class="st1658" d="M915.2,624.1c-0.1,0.6-0.1,1.3-0.2,1.9c-1.6,0-3.1,0-4.7-0.1C911.9,625.3,913.6,624.7,915.2,624.1z"/>
	<path class="st1482" d="M962.2,633.1c-1.7,2.2-3.4-0.4-5.1,0.1c-2.7,0-5.3-0.6-6.9-3C954,632,958.3,631.7,962.2,633.1z"/>
	<path class="st1659" d="M940.2,630.1c0.4-0.8,1.1-0.9,1.9-1c1.7,1.3,3.4,2.6,5.2,3.9c0.3,0.5,0.3,0.9-0.1,1.4
		C943.7,634.9,941.3,633.6,940.2,630.1z"/>
	<path class="st1660" d="M957,635.3c0.4,0.6,1.3,0.8,1.1,1.8c-0.3,0.3-0.7,0.5-1.2,0.6c-0.5,0-0.9,0-1.3-0.1
		c-1.6-0.6-3.8-0.1-4.8-2.1c-0.1-0.5,0.1-0.9,0.4-1.2C953.2,634.6,955.1,635,957,635.3z"/>
	<path class="st1661" d="M951.2,634.3c0,0.3,0,0.6,0,0.9c-0.8,0.8-1.7,0.8-2.7,0.5c-0.7-0.3-1.2-0.8-1.3-1.6c0-0.4,0.1-0.7,0.1-1.1
		C948.6,633.5,949.9,633.9,951.2,634.3z"/>
	<path class="st1662" d="M923.2,627.1c-3.5-0.8-1.4-3-1.2-4.8c1.4,0,2.7,0,4.1-0.1c0.4,0.2,0.7,0.6,0.7,1c-0.4,1.7-1.8,2.5-2.9,3.6
		C923.6,626.9,923.4,627,923.2,627.1z"/>
	<path class="st1663" d="M926.2,623.2c-0.1-0.3-0.1-0.7-0.2-1c0.8-0.1,1.6-0.1,2.3-0.2c0.6,0.3,1.3,0.6,1.9,0.9
		c0.3,0.7,0.7,1.4,1,2.2c-0.1,0.2-0.3,0.3-0.4,0.5c-0.4,0.2-0.8,0.3-1.2,0.3C927.9,625.7,926.9,624.7,926.2,623.2z"/>
	<path class="st1663" d="M947.2,634.1c0.3,0.3,0.7,0.6,1,0.9c-1.8,1.8-3.9,0.8-5.9,0.4c-4.6-2.5-9.4-4.7-11.2-10.1
		c0,0,0.2-0.3,0.2-0.3c3,1.7,6,3.4,8.9,5C942.3,631.9,944.3,633.7,947.2,634.1z"/>
	<path class="st1664" d="M1025.2,743c-1-0.7-2-1.4-2.9-2c-1.5-3.5-0.7-5.2,2.7-5.8c1.7-0.3,3.6-0.1,5.2-1.1c1.4-1.3,2.7-1.4,4.1-0.1
		c1.1,4-3.4,6.3-3,10.1C1028.9,745.6,1026.9,745.2,1025.2,743z"/>
	<path class="st1665" d="M1014.2,742.9c-2.4-0.8-3.3-4.1-6.3-3.9c-1,0.3-1.7-0.2-2.4-0.8c-1.5-1.9-0.9-4.2-0.9-6.4
		c0.5-1.3,0.7-2.7,2.4-3.1c1.5,0.6,1.6,2.2,2.2,3.4c1.3,1.6,2.7,3.2,4,4.8c0,1.7,2.5,2.1,2,4.1
		C1014.4,741.4,1014.2,742.1,1014.2,742.9z"/>
	<path class="st1666" d="M1030.1,734c-0.9,1.3-2.3,2-3.8,1.9c-3.7-0.1-4.3,2.1-4.1,5c-1.2-0.9-2.4-1.8-3.6-2.7c-1-0.7-2.7-1.2-2-2.9
		c0.7-1.6,2.1-2.8,4-2C1023.8,734.5,1027,733.8,1030.1,734z"/>
	<path class="st1667" d="M1031.3,743.1c-1.2-0.3-2.4-0.4-1.1-2.1c1.6-2.2,3.4-4.3,4.1-7c0.3,0.1,0.6,0.2,0.9,0.3
		c0.2,1.3-0.4,2.8,1,3.7c0,1.1,0.1,2.3-0.9,3.1C1034,741.8,1033.6,744.4,1031.3,743.1z"/>
	<path class="st1668" d="M1015.1,726c0.7,1.9,2.9,2.5,4,4c-3.3-0.7-6.6-1.4-9.9-2c-2.1-1.8-1.7-2.5,2-4
		C1012.6,724.5,1013.4,726.2,1015.1,726z"/>
	<path class="st1669" d="M1011.2,724c0.2,1.8-3.9,1.4-2,4c0.1,1.4,0.9,2.8,0,4.1c-1.2-0.6-2.1-1.4-2.4-2.8c-1.6-3.5-1.2-6.4,2.5-8.3
		C1009.1,722.6,1010.5,723,1011.2,724z"/>
	<path class="st1670" d="M1031.2,745.2c-5.5,2.5-9.8-0.2-14-3.2c-0.2-0.9-0.1-1.4,1-1c1.3,0.7,2.6,1.4,4,2
		C1025,744.3,1028.3,743.9,1031.2,745.2z"/>
	<path class="st1671" d="M1031.2,745.2c-3.1-0.2-6.3-0.1-9-2.2c1,0,2,0,3,0c2,0.1,4,2.1,6.1,0.1c1.4-0.7,2.7-1.4,4-2
		c-0.1,1.6-1.6,2.3-2.2,3.6C1032.4,744.9,1031.8,745,1031.2,745.2z"/>
	<path class="st1672" d="M1018.2,741c-0.3,0.3-0.7,0.7-1,1c-0.7-0.3-1.3-0.6-2-1c-0.6-1.4-3.4-1.7-2-4.1
		C1014.8,738.3,1016.5,739.6,1018.2,741z"/>
	<path class="st1673" d="M1028.1,725c-1,1.4-2.6,0.9-3.9,1c-3.3-2.1-5.1-5.4-6.9-8.8c-0.3-0.6-1-1-0.5-1.7c0.6-0.8,3.2-0.8,1.4-2.7
		c-1.6-1.6-3.4-0.6-5,0.2c-1.4-2,0.5-3.5,0.8-5.3c0.9-2.7,3.5-4.4,4.2-7.3c1.1-3.5,2.7-6.8,2.9-10.5c0.2-0.8,0.4-1.5,1-2
		c1.8-0.1,1.9,1.3,2.3,2.5c0.2,2,1.1,3.7,2.5,5.2c1.2,1.3,2.8,2.4,2.6,4.5c-2.3,5.1-5.2,10.1-4.7,16c-0.3,3-0.8,6.1,2.6,7.9
		C1027.7,724.3,1027.9,724.6,1028.1,725z"/>
	<path class="st1674" d="M1015.1,708c-0.6,1.7-1.3,3.4-1.9,5c-1.1,1.9-2.9,3.5-3.1,5.9c-0.7,0.5-0.9,1.3-0.9,2.1
		c-1.7,2.5-2.5,5.3-2.5,8.3c-0.9,0.7-0.2,2.2-1.5,2.7c-1.5-0.5-2.5-1.3-2.1-3c0.4-0.4,0.6-0.9,0.5-1.5c0,0.9-0.3,1.5-1.3,1.5
		c-1.7-1.4-1.3-3.1-1-4.9c3.5-7.8,2.6-15.8,1.4-23.9c0.6-1.6,1.7-2.6,3.4-2.8c2,0.2,2.9,1.6,3.4,3.2
		C1010.4,703.9,1012.6,706,1015.1,708z"/>
	<path class="st1675" d="M880.3,596.2c-2.1,2.5-4.7,3.9-8,3.8c-0.3-0.3-0.5-0.7-0.5-1.1c0-1.6-2.1-2.3-1.9-4
		c0.1-0.3,0.2-0.7,0.5-0.8c1.9-0.9,2-1.3,0.9-2.9c1.3,0,2.7,0,4,0c2.3,0,4.7,0,7,0C881.7,592.8,880.4,594.3,880.3,596.2z"/>
	<path class="st1676" d="M1037.1,723c-1.1,1.9-2.4,3.7-5,2c-4-6-3.7-11.4,1-18.3c2.9,0,2.9,2.6,4,4.3c0,1.3,0,2.7,0,4
		c-1.7,1.7-1.5,3.9-2,6C1035.8,721.7,1036.5,722.4,1037.1,723z"/>
	<path class="st1501" d="M1037.1,711c-2.6,0-1.7-3.6-4-4c-0.3-0.3-0.5-0.7-0.5-1.2c0.1-0.4,0.3-0.8,0.6-1.1c0.4-0.1,0.8-0.1,1.3,0
		c1.4,0.9,3.2,1,4.5,2.2c0.7,0,1.5,0.1,2.2,0.1C1039.8,708.4,1038.5,709.7,1037.1,711z"/>
	<path class="st1501" d="M1035.1,721c-1.9-2.8-1-4.8,2-6C1036.5,717,1038.8,720,1035.1,721z"/>
	<path class="st1677" d="M1039,707c-1.9,0.1-3.9,0.3-4.9-1.9c0.4-1.1,1.2-1.8,2.1-2.5c1.3-0.8,2.9-0.5,4.3-1.3
		c1.3-0.6,1.8-2.4,3.6-2.3c0.2,0.8,0.1,1.7,1.1,2.1c0.1,0.4,0.1,0.8,0.2,1.1c-0.1,0.3-0.1,0.6-0.2,0.8
		C1043,704.3,1041,705.6,1039,707z"/>
	<path class="st1678" d="M1033.1,706c0,0.3,0,0.7,0,1c-0.8,1.7-0.3,3.7-1.4,5.4c-2.7,4.2-0.6,8.4,0.4,12.6c-0.3,0.3-0.7,0.7-1,1
		c-1-0.3-2-0.7-3-1c-0.3,0-0.7,0-1,0c-2.5-2.2-0.5-4.5-0.4-6.8c-0.2-3.6,0.7-7.2,0.9-10.8c0.1-1.6-0.5-3.6,1.5-4.3
		C1031.5,702.3,1032,704.8,1033.1,706z"/>
	<path class="st1679" d="M1005.1,589.2c-1,1.9-0.9,4-1,6c-0.4,0.3-0.9,0.4-1.4,0.2c-0.7-0.4-1.1-1-1.4-1.7c-0.4-1.9-0.1-3.5,1.6-4.7
		C1003.8,588.6,1004.5,588.5,1005.1,589.2z"/>
	<path class="st1680" d="M1003.2,595.1c0.3,0,0.7,0,1,0.1c0,2-0.1,3.9-0.1,5.9c-0.6-0.3-1.2-0.7-1.7-1
		C1000.6,598.1,1000.9,596.4,1003.2,595.1z"/>
	<path class="st1681" d="M1005.1,589.2c-0.6,0.1-1.2,0.1-1.8,0.2c-0.1-0.2-0.3-0.4-0.4-0.6c0-1.5,0.5-2.7,2.1-3.2
		c0.5,0,0.9,0.2,1.2,0.6c0,0.4,0.1,0.7,0.1,1.1C1005.9,587.9,1005.5,588.6,1005.1,589.2z"/>
	<path class="st1682" d="M1006.1,586.2c-0.3,0-0.7,0-1,0c-1.5-0.7-1.9-2-1.9-3.5c0-0.5,0.1-0.9,0.3-1.4c0.4-0.9,0.8-1.7,1.7-2.1
		c1.7,0.8,1,2.9,2,4C1006.8,584.2,1006.5,585.2,1006.1,586.2z"/>
	<path class="st1683" d="M875.3,862.8c0-1,0-2,0-3c2-1.2,2.7-3.9,5.2-4.5c0.5,0,1.3,0.1,1.4,0.4c2.1,7,10.1,9.8,12.1,16.9
		c0.1,0.8,0,1.5-0.4,2.1c-2.6,0.9-4.4-1.3-6.7-1.7c-2.8-1.8-3.9-5.1-6.5-7.1c-1.4-1.1-2.8-2.2-4.6-2.7
		C875.6,863.2,875.5,863,875.3,862.8z"/>
	<path class="st1684" d="M880.3,855.9c-1.4,1.7-1.7,4.7-5,4c1.8-5.3,0.1-10.6,0-16c0.5-0.6,1.1-0.8,1.9-0.8c1.9,0.4,3.4,1.4,4.4,3
		c-0.2,1.8,2.2,1.6,2.5,3.1c0.3,2.2,1.9,4.1,1.9,6.5c-0.6,1.8-1.7,2-3.3,1C882,856.2,881.4,855.2,880.3,855.9z"/>
	<path class="st1685" d="M877.3,843.9c-0.7,0-1.3,0-2,0c-2.4-2.2-3.3-6-7-7c0.1-3.2-3.1-5.6-2-9c3,0.1,2.9,3.2,4.5,4.6
		c1.5,2.7,3.5,5.2,5.3,7.7C876.7,841.3,878,842.3,877.3,843.9z"/>
	<path class="st1686" d="M870.3,861.9c1.7,0.3,3.4,0.6,5.1,0.9c0,0,0,0,0,0c0.7,0.7,0.9,1.5,0.6,2.5c-0.9,1.5-2.3,1-3.6,0.9
		c-0.6-0.3-1.1-0.7-1.5-1.2C870.3,864,869.7,863.1,870.3,861.9z"/>
	<path class="st1687" d="M870.3,861.9c0.4,0.9,0.7,1.9,1.1,2.8c0.4,0.4,0.4,0.9,0,1.3c-0.5,0.2-1,0.2-1.4-0.1
		c-1.4-1.1-1.4-2.5-0.6-4c0,0,0,0,0,0C869.6,861.9,869.9,861.9,870.3,861.9z"/>
	<path class="st1688" d="M869.3,862c0.4,1.3,0.7,2.6,1.1,3.9c1,1.9,2.2,3.9-1,5C865.6,867.9,865.8,865,869.3,862z"/>
	<path class="st1689" d="M869.3,870.8c1.6-1.4,0.7-3.3,1-5c0.3,0,0.6,0,0.9,0c1,0.6,1.3,1.7,1.7,2.6c0.4,1,0.9,2,0.7,3.1
		c-0.3,0.7-0.7,1.1-1.4,1.2c-0.3,0-0.6,0-0.9,0C870.1,872.8,869.4,872.2,869.3,870.8z"/>
	<path class="st1690" d="M870.2,832.9c-2.3-0.9-2.1-3.8-3.9-5c-1.1-1.8-1-3.9-1.1-6c0.3,0,0.7,0,1,0c2.2,3.4,4.5,6.8,5.2,10.9
		C871.1,833.2,870.7,833.2,870.2,832.9z"/>
	<path class="st1691" d="M872.3,725c1.4-1.4,3.5-2.3,3-4.9c0.3-0.7,0.7-1.3,1-2c0.6-1.2,1.5-2.2,2.7-2.9c0.8-0.4,1.6-0.9,2.5-0.2
		c0.4,0.6,0.4,1.3,0.2,2c-1,1.8-1.8,3.5-0.6,5.6c0.7,1.1,1.1,2.5-0.4,3.5c-2.5,0.3-4.9,0.1-7.4-0.3C873,725.7,872.6,725.4,872.3,725
		z"/>
	<path class="st1692" d="M879.3,716c-1,0.7-2,1.4-3,2.1c0-0.7,0-1.4-0.1-2.1c-0.6-0.7-0.7-1.4-0.5-2.3c0.4-0.9,1.1-1.3,2-1.3
		C880.2,712.8,880.4,714.1,879.3,716z"/>
	<path class="st1693" d="M876.3,714.1c0,0.6,0,1.3,0.1,1.9c-2,1.7-4,3.4-6,5.1c-0.1-0.2-0.3-0.4-0.4-0.6
		C871.1,717.4,872.3,714.3,876.3,714.1z"/>
	<path class="st1693" d="M864.3,725.9c0.4-0.6,0.7-1.2,1.1-1.8c1.2-0.7,2.3-1.6,3-2.8c0.4-0.8,1.1-0.8,1.8-0.4c0,0,0.2,0.3,0.2,0.3
		C869.1,723.7,867.3,725.5,864.3,725.9z"/>
	<path class="st1694" d="M872.3,725c0.3,0,0.7,0,1,0c0.1,1.8,2.7,2.2,2.5,4.2c-0.3,3.4-1.7,5.5-5.6,4.4c-1.1-0.3-1.9,0-2.4,1
		c-0.1,0.2-0.3,0.3-0.5,0.5c-2.1,0.1-4.1,0.2-3-3C867,729.7,869.7,727.3,872.3,725z"/>
	<path class="st1695" d="M858.5,575.2c0.3,0.3,0.6,0.6,0.9,1c1.2,1.8,0.9,3.6,0.5,5.5c-1.1,1.4-1.8,0.6-2.5-0.4c0-1.3,0-2.7,0-4
		C857.9,576.6,858.2,575.9,858.5,575.2z"/>
	<path class="st1696" d="M857.3,601.2c-2-6.3-4-12.6-6-19c1-0.3,2-0.7,3-1c1.3,0.1,0.4,3.5,2.9,2c2.5,2.8,1,6.5,2.4,9.6
		c0.1,1.9,0.2,3.9-0.3,5.8C858.9,599.7,858.4,600.6,857.3,601.2z"/>
	<path class="st1697" d="M858.4,592.9c-1.8-3.1-0.8-6.5-1.2-9.8c0.1-0.6,0.2-1.3,0.3-1.9c0.6,0.3,1.1,0.7,1.7,1
		c1,0.7,1.1,1.8,1.4,2.9c0.3,2.7,1.3,5.3,1.1,8.1c-0.2,1.6,0,3.4-2,4.1C858,596.2,859.5,594.2,858.4,592.9z"/>
	<path class="st1698" d="M998.2,620.1c-1.3,3.9-1.5,8-1.9,12c0,0-0.1,0.2-0.1,0.2c-0.6,0.1-1.2-0.1-1.5-0.6
		c-1.9-7.1,0.6-14.1,0.6-21.2c0-1.9,0.5-3.9,0.7-5.9c1.2-2.9,0.8-7.1,5.6-7.3C1001.3,605.1,999.9,612.7,998.2,620.1z"/>
	<path class="st1699" d="M998.2,620.1c-0.4-6.3,0.7-12.4,2.3-18.4c0.4-1.6,0.8-3.2,0.5-4.8c-0.7-0.8-0.6-1.7-0.2-2.6
		c0.3-0.4,0.7-0.5,1.2-0.4c0.4,0.4,0.8,0.8,1.2,1.1c-1,1.5-1.1,3.2-0.9,4.9c-0.1,3-0.1,6-0.2,9.1
		C1000.3,612.6,1001.8,617.3,998.2,620.1z"/>
	<path class="st1700" d="M982.2,461.3c-1.2-6.2,1.8-11.8,2.1-17.8c0.1-1,0.9-1.7,1.8-2.2c1.2,0.9,1.2,2.4,1.8,3.7
		c1.3,2.7,1.8,5.9,4.7,7.6c0.4,0.9,0.4,1.8,0.2,2.7c-0.7,2-2.2,2.8-3.7,2.5c-1.3-0.2-0.7,0.8-0.8,0.1c0-0.2,0.1-0.2,0.5-0.1
		c1,0.1,2.1-0.2,2.5,1c0.4,1.3-0.5,1.7-1.5,2.1c2.5,0.7,1.3,2.1,0.6,3.4c-0.4,0.3-0.8,0.5-1.3,0.5
		C986.8,464,984.4,462.9,982.2,461.3z"/>
	<path class="st1701" d="M987.2,445.3c-1.5-1.1-0.8-2.6-1-4c0-3.6,3.4-5.6,4-9c0.9,2.6,3.7,3.1,5.4,4.8c0.3,0.6,0.4,1.3,0.3,2
		c-1.2,3.9-5.2,4.1-7.9,6.1C987.6,445.3,987.4,445.3,987.2,445.3z"/>
	<path class="st1702" d="M995.2,437.4c-2.5-0.8-6.3-0.4-5-5c0-0.7,0-1.3,0-2c3.9-1.6,1.1-5.7,3-8c0.6-0.3,1.3-0.6,1.9-0.9
		c1,0.8,1.1,1.9,1,3.1c-0.6,2.7-0.6,5.5-0.1,8.3C996.2,434.4,996.6,436,995.2,437.4z"/>
	<path class="st1703" d="M982.2,461.3c3-0.5,5,1.1,7,3c1.6,1-3.6,0.2-0.4,1.7c1.1,0.8,2.6,1.5,1.3,3.2c-3-1.3-5.9-2.5-8.9-3.8
		C981.6,464,981.9,462.7,982.2,461.3z"/>
	<path class="st1704" d="M993.2,477.3c1,1.5,2,1.3,3,0c3.4,0.2,1.5,4.4,4,5.4c0.3,0.4,0.5,0.8,0.6,1.2c0.4,1.8-0.6,3.6-0.5,5.4
		c0.2,1.7-0.8,3-2.4,3.1c-1.5,0.2-2.2-1.2-2.8-2.4c-0.6-1.4-1-2.8-0.7-4.3c0.5-1.6,0.4-3-0.8-4.3c-0.3-0.3-0.6-0.7-0.8-1.1
		c-0.3-0.6-0.4-1.3-0.3-2C992.6,477.9,992.9,477.6,993.2,477.3z"/>
	<path class="st1705" d="M978.1,473.3c0.3-0.7,0.6-1.3,1-2c2.4-1.7,4.7-3.2,7.1,0c0.6,0.5,0.6,1.2,0.4,1.9c-1.4,1.8-3.7,2.7-4.9,4.7
		c-0.5,0.6-0.9,1.1-1.5,1.7c-1,0.7-2.2,1.7-2.4-0.4C977.6,477.2,977.3,475.2,978.1,473.3z"/>
	<path class="st1706" d="M986.2,473.3c0-0.7,0-1.3,0-2c3.2-0.9,5.2,0.8,7,6c0,0.3,0,0.7-0.1,1C989.6,478.3,988.2,475.3,986.2,473.3z
		"/>
	<path class="st1707" d="M1000.3,483.2c-4.3,0.1-1.8-4.6-4.1-5.9c-0.3-1.6-0.7-3.2-1-4.8c0.3-0.4,0.7-0.5,1.2-0.5
		c0.7,0.3,1.2,0.8,1.6,1.3c1.1,1.8,1.9,3.7,2.6,5.7C1000.9,480.5,1001.4,481.9,1000.3,483.2z"/>
	<path class="st1708" d="M990.1,469.1c0.3-1.6-1.4-1.8-1.9-2.8c-0.4-1.6,0.8-1.4,1.7-1.5c2-0.1,3.1,1,3.6,2.9c0,0.8-0.5,1.3-1.3,1.5
		C991.5,469.1,990.8,469.1,990.1,469.1z"/>
	<path class="st1709" d="M992.2,469.2c0.4-0.3,0.7-0.6,1.1-0.9c1.1-0.4,1.8,0.3,2.4,1c0.5,1,1.4,2,0.3,3.2c-0.3,0-0.5,0.1-0.8,0.1
		C994,471.6,992.8,470.6,992.2,469.2z"/>
	<path class="st1710" d="M1000.3,483.2c-0.3-1.3-0.6-2.6-0.9-3.9c1-3.8-1.4-7.7,0.3-11.5c0.6-1.5,0.6-3.2,1.5-4.6
		c1.8-2.9,2.4-6.2,3-9.5c0.6-1.8,0.3-3.8,1-5.5c0.6-0.5,1.3-0.7,2.1-0.7c1,0.2,2,0.4,2,1.8c0.5,5.5-2.5,10.3-3.3,15.5
		c-0.6,4.1-0.5,8.4-2,12.1c-1,2.4-0.9,4.3-0.7,6.5c-0.6,1-1.1,2-1.7,3C1000,485.7,1000.9,484.1,1000.3,483.2L1000.3,483.2z"/>
	<path class="st1711" d="M1009.2,449.2c-0.6-0.3-1.1-0.7-1.7-1c-2.1-0.6-4.1-1.5-5.9-2.8c-0.8-0.5-1.5-1-2.2-1.5
		c-1.3-1.6-4.6-1.3-4.3-4.4c0-0.7,0-1.3,0-2c0.1-4.3-2.4-8.6-0.1-13c1-0.5,1.3,0.2,1.6,0.9c-0.1,2.1,0.5,4.2,0.7,6.3
		c0.5,4.1,2,7.3,6,9.5c3.4,1.9,6.6,4.2,10,6.3c3.1,1.7,6.5,2.8,9.3,5.1c2.5,2.7,6.3,4.4,6.5,8.8c-3.2-0.6-4.6-3.5-6.8-5.4
		c-2.5-2.2-5-4.3-8.5-4.5C1012.3,450.7,1010.7,449.9,1009.2,449.2z"/>
	<path class="st1712" d="M1000.3,483.2c1.1,0.8,0.7,2.1,1.1,3.1c-0.1,1.7-0.3,3.4-0.4,5c-3.8,6.6-5.6,7.3-12.6,5.6
		c-1.6-1.3-2.9-3-4.2-4.6l0,0c-0.6-1.6-1-3.3-0.1-4.9c1.5-0.9,2.4,0.4,3.4,1.1c1.2,0.9,2.5,1.7,4,0.8c1.9-1.3,1.1-3.7,1.9-5.4
		c0.2-0.4,0.6-0.7,1-0.9c1.1,0.4,1.3,1.2,0.7,2.2c0.1,2.4,0.3,6.1,2.4,6.1c3.3,0,1.4-3.8,2.1-5.9
		C1000,484.7,1000.1,483.9,1000.3,483.2z"/>
	<path class="st1713" d="M988.2,496.2c5.9,2.7,9.4-1.1,12.9-4.9c0.1,2.5-1.3,4.3-2.9,6.1c-2.2,1.3-4.7,0.7-7,1.1
		c-2.1,0.9-3.4,0.2-4.2-1.8C987.2,496.1,987.6,495.9,988.2,496.2z"/>
	<path class="st1714" d="M1019,428.2c-1,2.5-5.3-0.6-5.1,2.6c0.1,1.5,2.9,2.8,4.4,4.2c0.7,0.7,1.2,1.5,1.8,2.3
		c-0.6,0.6-1.3,0.6-2.1,0.4c-5.5-3.9-10.7-8.3-16.3-12.1c-1.8-1.2-4.3-3-1.8-5.9c2.7-1,4.7,1.9,7.3,1.3c0.9,0.5,1.8,0.9,2.8,1.4
		C1012.7,425,1015.5,427.3,1019,428.2z"/>
	<path class="st1715" d="M1012.1,421.4c-2.5-2.4-2.5-2.4,2-6.1C1013.5,417.4,1012.8,419.4,1012.1,421.4z"/>
	<path class="st1716" d="M997.3,417.3c-1.3-1.8-4.1-1.5-5-3.8c1-0.4,2-0.7,2.9-1.1c3,0.3,3.8,2.9,5,4.9c-0.2,0.2-0.3,0.3-0.5,0.5
		C998.8,418.2,998,418.1,997.3,417.3z"/>
	<path class="st1716" d="M1007.3,421c-2.6,2.3-4.7-0.8-7.1-0.7c-0.3-1-0.3-2-0.1-2.9c0,0,0.1-0.1,0.1-0.1c1.7-0.9,3-0.9,3.9,1.1
		c0.6,1.3,1.6,2,3,2C1007.3,420.6,1007.4,420.8,1007.3,421z"/>
	<path class="st1717" d="M929.3,658.1c-0.8-1.6-1.6-3.1-2.2-4.8c-0.8-2.2,0.5-6.6-4.6-6c-1.2,0.1-1.1-1.8-1.2-2.9
		c-0.2-2.9-0.1-6-3.2-7.6c0,0,0.1-0.4,0.2-0.4c0.3-0.1,0.6-0.2,1-0.2c0.4,0.1,0.7,0.1,1.1,0.2c0.3,0.2,0.7,0.5,1,0.7
		c2.9-0.1,4.3,2.3,6.5,3.5c1.2,1.1,2.4,2.3,3.6,3.6c2.9,4.2,5.7,8.4,3,13.7C932.8,660,931,659,929.3,658.1z"/>
	<path class="st1718" d="M927.3,641c-2.5-0.7-4-2.7-6-4.1c3.2-3.6,1-5.8-2-7.9c1.4-0.2,2.7,0.3,3.9,1c0.6,0.4,1.3,0.7,1.8,1.4
		C926.3,634.6,930.2,637,927.3,641z"/>
	<path class="st1719" d="M871.3,591.1c1.3,1.8,3.4,0.5,5.1,1.3c-0.7,4-4.8,0.3-6.1,2.7c-2.5-0.2-3.9-1.2-3.1-4c-1-2.7-4.5-4.4-2.9-8
		c1-1,2-2,3-3C867.2,584.4,866.6,588.7,871.3,591.1z"/>
	<path class="st1720" d="M867.3,591.2c0.4,1.8,1.9,2.7,3.1,4c0.4,1.4,4.9,0.9,2,4c-2.8,0.7-4.6-1-6.5-2.4c-1.2-1.6-2.7-3.1-1.6-5.4
		C865.2,591.2,866.2,591.2,867.3,591.2z"/>
	<path class="st1721" d="M861.3,577.2c-0.1,4.7,0.8,9.3,2.1,13.8c0.6,0.8,0.5,1.5-0.1,2.3c-3.7-1.8-2.4-5.7-3.4-8.6
		C860.6,582.3,858.3,579.2,861.3,577.2z"/>
	<path class="st1722" d="M863.3,593.2c0-0.7,0.1-1.4,0.1-2.2c0.3,0.1,0.5,0.2,0.8,0.2c0.7,1.6,1.5,3.3,2.2,4.9
		c0.5,1.1-0.2,1.5-1.1,1.9c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7-0.4-1-1-1.3-1.7C862.8,595.4,862.3,594.3,863.3,593.2z"/>
	<path class="st1723" d="M861.3,577.2c-1.9,2.4-0.5,5.3-1,7.9c-0.3,0.5-0.6,0.5-0.9,0c-0.1-1-0.1-1.9-0.2-2.9c0.1-2,0.2-4.1,0.2-6.1
		C860.6,575.6,861.5,575.5,861.3,577.2z"/>
	<path class="st1724" d="M881.3,900.8c-1-2.5-2.7-4.3-5-5.7c-1.5-0.9-2.9-2.2-3-4.2c0.5-3.9-2.9-7.1-1.7-11.2
		c0.4-1.4,0.6-2.8,1.7-3.8c0.8-0.4,1.2,0.1,1.5,0.7c1.4,2.8,4,4.5,6.1,6.8c1.2,1.6,3.3,2.4,4,4.4c0,1.8,0.7,3.1,2.1,4.2
		c1.2,1,2.7,2.2,1.2,4.1c-1.8,0.6-3.5,1.4-4,3.5C883.8,900.8,882.9,902,881.3,900.8z"/>
	<path class="st1725" d="M874.4,876.8c-0.4-0.3-0.7-0.6-1.1-0.9c-0.3-1-0.7-2-1-2.9c0.3-0.3,0.6-0.6,1-1c0.7,0,1.2,0.3,1.6,0.8
		c0.6,0.6,1.1,1.2,1.6,1.8c0.2,0.4,0.3,0.8,0.2,1.2c-0.2,0.6-0.6,1.1-1.2,1.4C875,877.2,874.7,877.1,874.4,876.8z"/>
	<path class="st1726" d="M881.3,900.8c1.1-0.1,2-0.6,1.9-1.8c-0.2-4.1,2-4.3,5-3.2c0.2,0.1,0.4,0.3,0.5,0.4c2.8,0.8,5,3.1,8.2,2.9
		c0.5-0.1,0.9-0.2,1.4-0.2c2.3,0.1,4.7,0.2,5.3,3.2c-3.2,2.9-7.8,3.1-11.2,5.4c-0.8,0.1-1.6,0-2.2-0.6
		C888.3,903.2,883.9,903.5,881.3,900.8z"/>
	<path class="st1727" d="M890.3,906.8c0.7,0,1.3,0,2,0c2.6,0.8,5.7,0.9,7,4c0.1,1.4,0.6,2.4,2.1,2.9c2.6,0.8-0.4,1.4-0.1,2.1
		c-1-0.3-2-0.7-3-1c-2.8-3.7-7.7-4.2-11.2-6.9c-0.5-0.4-1.3-0.3-1.3-1.3C887.3,905.5,888.8,907.3,890.3,906.8z"/>
	<path class="st1728" d="M899.3,910.8c-2.2-1.5-5.5-1.3-7-4c2.8-3.5,7.1-3.7,11-5c1.2-1.1,2.2-0.4,3.2,0.4c1.7,2.9-0.5,5.7-0.2,8.6
		C904,912,901.6,910.9,899.3,910.8z"/>
	<path class="st1729" d="M906.3,910.8c-2.3-2.7-0.2-5.3,0-8c1-1.2,2.4-1.3,3.8-1.6c0.9-0.1,1.8,0,2.4,0.9c0.6,1.9,1.7,3.6,0.8,5.7
		c-0.3,0.7-0.7,1.3-1,2c-1.1,2.1-2.8,2.7-5,2C907,911.5,906.6,911.1,906.3,910.8z"/>
	<path class="st1730" d="M907.3,911.8c1.7-0.7,3.3-1.3,5-2c2.6,2.4,3.2,5,1,8c-2-0.7-4-1.3-6-2C907.3,914.5,907.3,913.1,907.3,911.8
		z"/>
	<path class="st1731" d="M996.2,425.3c-0.4-0.3-0.7-0.7-1.1-1c0-1,0-1.9,0-2.9c0.5-0.4,1-0.9,1.4-1.3c4.8,6.1,12,9.3,17.6,14.3
		c1,0.9,2.4,1.3,2.6,2.9c-0.1,0.5-0.3,0.9-0.6,1.3c-3.2,0.2-5.9-0.8-8.3-2.9c-1.9-3.4-5.6-4.4-8.4-6.6
		C998.1,428.1,995.8,427.8,996.2,425.3z"/>
	<path class="st1732" d="M1016.2,437.3c-5.5-4.2-10.9-8.4-16.5-12.5c-1.7-1.2-3-2.5-3.2-4.7c0.9-0.8,0.6-1.9,0.8-2.8
		c1,0,1.9,0,2.9,0.1c0.6,1,0.6,2,0.1,2.9c-0.9,2.9,1.4,3.9,3.2,5.1c5.2,3.6,10.6,7,14.9,11.8c-0.1,0.2-0.3,0.3-0.4,0.5
		C1017.2,437.9,1016.7,437.9,1016.2,437.3z"/>
	<path class="st1733" d="M1011.1,652.1c0.1,1.3,0.8,2,2,2.1c-3,0.7-2.8,5.9-7,4.9c-3.5,2.3-7,2.4-10.4-0.3c-2.5-2.2-6.6-2.3-7.8-6
		c1.1-2.2,2.7-1.2,4.2-0.6c3.6,1.3,7,4.4,11,0.5c1.1-1.1,3.1,0,4.1,1.6c-0.2,0.6,0.2-0.2,0,0.4c-0.1,0.3-0.2,0.5-0.4,0.7
		c0.2-0.1,0.3-0.3,0.5-0.5C1008.1,653.3,1008.8,651.6,1011.1,652.1z"/>
	<path class="st1734" d="M996.2,658.1c3.1,2.8,6.6,0.6,10,1c-0.8,1.9-0.1,1.5,1.5,1.4c4.2-0.2,8.8-1.2,11.5,3.6
		c-0.6,0.6-1.3,0.9-2.1,0.9c-2.1,0-4-0.8-6-1.1c-1.9-0.7-3.9-0.5-5.8-0.5c-1.9,0-3.9-0.1-5.8,0.4c-1,0.2-1.9,0.2-2.8-0.2
		c-1.3-0.8-0.8-2-0.8-3.1C995.8,659.7,995.6,658.8,996.2,658.1z"/>
	<path class="st1735" d="M1021,667.4c1.7,1.9,2,4.5,3.1,6.7c1.4,3.3,2.4,6.7,2.6,10.3c-1.1,1.9-2.3,1.2-3.3,0
		c-0.9-1.1-1.6-2.4-3-2.8c-1.7-1-3.4-2-2.5-4.4c1-1,2.1,0.2,3,0c0.3-4.8-4.1-7.6-5-11.9c0.3-0.4,0.7-0.6,1.2-0.6
		C1018.7,665.1,1020.4,665.5,1021,667.4z"/>
	<path class="st1736" d="M1026.1,685c-0.9-3.6-2-7.2-2-10.9c1.4,1.2,2.8,2.7,2.8,4.4c0,4.4,3,5,6.2,5.7c0,0.3,0,0.6,0.1,0.8
		c-0.1,0.3-0.2,0.5-0.2,0.8c-0.3,0.5-0.7,0.8-1.2,1C1029.5,687.4,1027.5,687.1,1026.1,685z"/>
	<path class="st1737" d="M1021,667.4c-1.3-0.8-2.5-1.5-3.8-2.3c-0.3-0.1-0.4-0.3-0.4-0.5c0-0.2,0.1-0.4,0.1-0.4
		c0.7,0,1.5-0.1,2.2-0.1C1019.8,665.2,1021.6,665.6,1021,667.4z"/>
	<path class="st1738" d="M1044,698.9c-1.1,1-1.3,2.8-3.1,3.1c-0.7,0.5-1.3,0.5-2,0c0.2-3.2,2.5-5.7,2.8-8.9c0.6-5.6,0-6.4-5.5-6.4
		c-0.7-0.3-1.3-0.7-1.2-1.6c2.5-1.4,5.2-2.1,8-2.3c1.3,2.6,0.8,5.4,1.1,8.1c0.5,2,0.5,4,0,6.1C1044.2,697.7,1044.1,698.3,1044,698.9
		z"/>
	<path class="st1739" d="M1035.1,685.2c0.4,0.3,0.7,0.6,1.1,0.9c0.3,0.4,0.5,0.8,0.5,1.3c-0.3,2.5,0.7,4.8,0.7,7.3
		c-0.3,1.5,0,3.5-2.4,3.5c-2.9-4-2.9-8.3-1.7-12.8c0,0,0-0.4,0-0.4C1033.9,685,1034.5,685.1,1035.1,685.2z"/>
	<path class="st1740" d="M1044.3,697c0-2,0-4,0-6.1c0.3,0,0.6,0.1,0.8,0.1c0,1.6,0,3.3,0,4.9C1044.9,696.4,1044.6,696.7,1044.3,697z
		"/>
	<path class="st1741" d="M1011.1,652.1c-1.3,1-2.6,2-3.9,3l0,0c-2-1.6-3.9-3.7-5.3,0.7c-0.6,2-2.3,1.3-3.6,0.4
		c-2.6-1.8-6-1.6-8.4-3.9c-0.2-0.2-1.1,0.5-1.6,0.7c-0.3,0.3-0.7,0.6-1.1,0.6c-0.4,0-0.9-0.1-1.3-0.3c-1.8-1.2-3.8-2.1-5.4-3.6
		c-1.8-1.4-3.6-2.8-6-3c-0.4-0.1-0.8-0.3-1.1-0.7c0-0.3,0-0.7,0-1c-0.4-1.3,0.7-1.7,1.5-2.2c1.1-0.7,3.3-0.7,1.5-3.2
		c-0.6-0.8,1.1-1.2,1.9-1.8c1.6-1,3.4-1.2,5-1.9c0.7,0,1.3,0,2,0c1.3-1.1,3.5,0.5,4.5-1.5c0.8-0.8,1.6-1.7,2.7-2.1
		c0.7-0.2,1.4-0.2,2.1-0.1c1.8,2.5,4.1,2.9,6.8,1.7c4.8,0.3,9,1.2,11.2,6.5C1014.1,644.9,1010.9,648.2,1011.1,652.1z"/>
	<path class="st1742" d="M1001.2,634.1c-2.8,1.9-5.3,2.3-7.2-1.2c0.1-0.7,0.4-1.2,1.2-0.9c0.3,0.1,0.7,0.2,1,0.3
		C997,635.2,999.4,633.7,1001.2,634.1z"/>
	<path class="st1743" d="M890.3,621.1c-0.3,0.3-0.7,0.7-1,1c-0.5,0.7-1.2,1.6-2,0.9c-2.7-2.4-6.5-3.8-8.5-6.6c-2.7-3.7-4.6-8-6-12.5
		c-0.2-0.7,0.1-1.3,0.5-1.8c4,1.1,8,0.5,12,3.9c4.4,3.7,5.3,7.3,5,12.1C890.3,619.1,890.3,620.1,890.3,621.1z"/>
	<path class="st1744" d="M873.3,602.2c1.2,4.9,3.5,9.6,6.4,13.2c2.2,2.7,5.6,5.6,9.6,6.7c-2.2,4.3-3.6,4.8-6.7,1.8
		c-4.5-4.4-9.6-8.5-11-15.3c-0.4-1.8-1.4-4.2-4.3-3.5c0-0.3,0-0.7,0-1c-0.8-2,1.1-2.1,2-2.9c0.3,0,0.7,0,1,0
		C871.1,602.3,872.3,602.1,873.3,602.2z"/>
	<path class="st1745" d="M882.3,635.9c-0.3,0.2-0.6,0.2-0.9,0.2c-3.9,0.9-7.1-0.6-9.7-3.3c-5.7-5.9-8.6-13.3-10.3-21.2
		c0.3-1.8,0.1-3.7-0.1-5.5c0-0.7,0.1-1.4,0.3-2.1c0.7-1.5,0.9-3.4,2.9-3.8c0.3,0.4,0.6,0.7,0.8,1.1c0.5,0.7,0.4,1.3-0.1,1.9
		c-1.9,10.4,3.6,18.4,9.1,26.1C876.3,631.8,881.2,631.8,882.3,635.9z"/>
	<path class="st1746" d="M864.6,600.2c-0.7,1.4-1.3,2.8-2,4.1c-0.8,0.4-1.5,0.5-2.3,0.1c-0.8-0.9-0.9-1.9-0.8-3
		c0.3-2.1,1.2-3.9,1.5-6c1.4-1.3,2.2,0.4,3.3,0.7c0,0.7,0.1,1.4,0.1,2.2C864.5,598.9,864.5,599.5,864.6,600.2z"/>
	<path class="st1747" d="M865.3,598.1c0.4-0.6,0.7-1.3,1.1-1.9c1.9,1.2,3.5,2.9,5.9,3c0,0.3,0,0.6,0,0.8
		C869.7,600.3,867.5,599.2,865.3,598.1z"/>
	<path class="st1560" d="M865.3,603.1c0-0.6,0.1-1.3,0.1-1.9c1.3,0,2.6,0,3.9,0c-0.7,1-1.3,2-2,2.9
		C866.5,604.1,865.8,603.9,865.3,603.1z"/>
	<path class="st1748" d="M1031.2,437.5c0.9,0.2,1.5,0.8,1.8,1.7c-0.2,1.2-1,1.7-2.1,2.1c-1.6,0.3-3.1,0.4-4.5-0.7
		c-0.9-3,2.3-0.9,2.8-2.2C1029.8,438.1,1030.5,437.8,1031.2,437.5z"/>
	<path class="st1732" d="M1029.2,438.4c-1,0.7-2,1.3-3,2c-0.2,0.2-0.3,0.3-0.5,0.5c-1.4,0.5-2.8,0.7-3.7-0.9
		c0.1-0.2,0.2-0.4,0.2-0.7C1024.5,439,1026.8,438.7,1029.2,438.4z"/>
	<path class="st1732" d="M1018.3,437.2c0.6,0.1,1.2,0.1,1.9,0.2c0.7,0.6,1.4,1.3,2.1,1.9c0,0-0.1,0.2-0.1,0.2
		c-0.8,0.8-1.7,0.8-2.7,0.5c-0.9-0.5-1.5-1.3-1.3-2.4L1018.3,437.2z"/>
	<path class="st1749" d="M945.2,902.8c-4.5,3.2-5.5,6.4-3.4,12.1c0.7,2,1.6,3.9,2.4,5.9c-6-0.1-7.4-2.2-6.4-8.4
		c0.5-3.1,1.7-6.3,0.5-9.6c0.4-0.5,0.8-0.8,1.4-1C941.6,901.4,943.6,901.2,945.2,902.8z"/>
	<path class="st1750" d="M933.3,920.8c-2.9,0.3-4.1-0.7-4.1-3.8c0.1-4.1,1.9-8,1.1-12.2c1.7-1.4,3.3-1.5,5,0
		C932.1,909.8,933.8,915.4,933.3,920.8z"/>
	<path class="st1751" d="M935.3,904.8c-1.7,0-3.3,0-5,0c-1-0.3-2-0.7-3-1c0.8-0.8,0.8-2,0.1-3.3c1.4-1.1,3-1,4.6-1
		c1.1,0,2.2,0.1,3.2,0.4c1.5,0.8,3.9,0.3,4.1,2.9c-0.3,0-0.7,0-1,0C936.8,902.8,935.8,903.5,935.3,904.8z"/>
	<path class="st1752" d="M957.2,913.8c-5.4,1.8-7,0.4-7.7-7.2c-0.2-2-0.5-2.7-2.3-2.8c0-0.3,0-0.7,0-1c-0.2-1.6,1.1-1.8,2.2-2.2
		c3.1-0.2,6.2-0.5,9.3,0.3C962.4,905.2,961.7,911.2,957.2,913.8z"/>
	<path class="st1753" d="M949.2,901.8c-0.8,0-1.6,0.2-2,1c-0.7,0-1.3,0-2,0c-2,0-4,0-6,0c-0.8-1.6-3-0.4-3.9-1.9
		c2.2-2.3,5.4-0.9,7.9-2c2.2-0.4,4.5-0.7,6.6,0.3C951.2,900.4,950.1,901.1,949.2,901.8z"/>
	<path class="st1754" d="M900.3,634.1c0.6,7,6.1,11.7,8.7,17.9c0.7,1.7,1.4,3.2,1.3,5.1c1.7,2.1,0.7,3.9-0.5,5.8
		c-2.4,3.9-4.7,7.8-4.7,12.6c-0.8,3.1-1.5,6.3-1.5,9.5c0,1.6,0,3.3-1.7,4.2c-3.1-3.2-2.4-7.3-2.3-11.2c0-1.7,1-3.4,0.8-5.3
		c0.6-4.4,0.2-8.9,2.1-13.2c0.5-1.1,0.5-2.3,0-3.4c-2-4.4-4.4-8.5-4.3-13.5c0-0.7-1.3-0.9-1.8-1.6
		C897.7,638.8,899,636.5,900.3,634.1z"/>
	<path class="st1755" d="M896.3,641.1c2.1-0.1,4.6-1.5,3.3,3.1c-0.9,3.2,1.3,6.7,3.3,9.5c1.4,1.9,1.9,4.1,1.1,5.5
		c-2.6,4.4-1,9.5-2.8,13.9c-0.6,0.7-1.4,0.9-2.3,0.9c-1.5-0.4-2.8-0.9-1.7-2.9c0.3-2.6,0.9-5.2,1-7.8c0.1-4.6,0.9-9.3-3.4-12.8
		c-0.6-0.5-0.4-2.2-0.6-3.3C894.1,644.8,895.4,643,896.3,641.1z"/>
	<path class="st1755" d="M893.3,650.1c-0.4,1.4-0.8,2.8-1.3,4.4c-1.3-1.8-0.2-3.1,0.3-4.4C892.6,650.1,893,650.1,893.3,650.1z"/>
	<path class="st1756" d="M927.3,900.8c0.6,1.2,2.1,0.7,3.1,1.3c-0.7,1.2-2.5,0.2-3.1,1.6c-0.3,0-0.7,0-1,0c-1,0-2,0-3,0
		c-1.8-1.5-5.2,2-6.1-2c1-1.2,2.4-1.3,3.8-1.5C923.1,899.8,925.3,898.9,927.3,900.8z"/>
	<path class="st1757" d="M924.2,631.2c-0.3-0.3-0.7-0.7-1-1c1,0,1.9,0,2.9,0c2.1-0.4,4.1,0.2,5.9,1.2c0.6,0.3,1.1,0.7,1.7,1.1
		c1.4,1.1,3,2,4.2,3.5c0.5,0.8,1,1.6,0.2,2.5c-3.3,0-6.5-0.3-8.7-3.4c-0.9-1.3-2.6-1.5-3.8-2.2C925,632.4,924.7,631.6,924.2,631.2z"
		/>
	<path class="st1758" d="M932.1,632.2c-2-0.7-4-1.4-6-2.1c0.1-0.3,0.1-0.5,0-0.8c0.2-0.2,0.4-0.4,0.5-0.6
		C929.5,628.2,932,628.4,932.1,632.2z"/>
	<path class="st1758" d="M923.2,628.1c0-0.4,0-0.7-0.1-1.1c0,0,0-0.1,0-0.1c1.9-0.7,3,0,3.2,2c-0.1,0.1-0.2,0.3-0.3,0.4
		C925.1,629.1,923.9,629.2,923.2,628.1z"/>
	<path class="st1759" d="M871.2,832.9c-1.9-3.5-4.5-6.8-4.9-11c0.1-0.6,0.1-1.3,0.2-1.9c3.6,1.5,2.4,6.3,5.6,7.9
		c3.9,6.1,9,11.1,14.8,15.5c0.6,0.8,1.1,1.7,0.5,2.7c-2.1,1.1-3.7-0.4-5.5-1.2C877.6,841.5,873.4,838.1,871.2,832.9z"/>
	<path class="st1760" d="M871.4,828.9c-3.4-2-3.2-6-4.9-8.9c-0.1-1-0.1-2-0.2-3c2.2-1.6,0.1-4.5,1.9-6.3c2.2,1.8,1,4.6,2,6.7
		c0.6,2.6,2,4.9,2.3,7.6C872.6,826.5,872.5,827.8,871.4,828.9z"/>
	<path class="st1761" d="M1031.1,440.3c0.6-0.4,1.2-0.7,1.9-1.1c0.2,0.2,0.2,0.4,0.1,0.6c1.3,1.2-0.3,3.2,1,4.3
		c-0.7-1.2-0.6-2.4,0.2-3.7c0.3-0.1,0.6,0,0.9,0.2c0.1,6.6,0.1,13.1,0.2,19.7c-0.1,0.6-0.2,1.3-0.3,1.9c-0.5,0.6-1.2,0.7-1.9,0.4
		c-1.6-1.7-1.7-3.9-2-6c-0.3-2.2-1-4-2.4-5.8c-1.5-1.9-4.7-4.4,0.5-6.2c0.7-0.2,0.3-2.2,1-3.2C1030.5,441,1030.8,440.7,1031.1,440.3
		z"/>
	<path class="st1762" d="M1034.2,440.4c-0.1,1.3-0.1,2.6-0.2,4.3c-1.4-1.8-0.6-3.5-0.9-5C1033.7,439.6,1034,439.9,1034.2,440.4z"/>
	<path class="st1763" d="M884.3,643.1c-0.7,0.3-1.4,0.6-2.1,0.9c-0.6-0.4-1.6-0.3-2-1.1c0-0.7,0.2-1.1,1-0.9
		c1.5-0.5,2.2-1.9,3.3-2.9C886,640.5,884.8,641.8,884.3,643.1z"/>
	<path class="st1764" d="M862.3,611.1c1.6,5.9,3.7,11.5,6.8,16.7c2.8,4.8,7.1,7.1,12.2,8.3c-0.1,0.3-0.2,0.5-0.3,0.8
		c-1,1.8-2.8,2.2-4.7,2.3c-1,0-2-0.1-3-0.5c-5.5-4.6-10.1-9.7-11.2-17.2c-0.4-2.6-1.7-5-2.7-7.4c-0.3-0.6-0.5-1.3-0.4-2.1
		C860,611.1,861,610.5,862.3,611.1z"/>
	<path class="st1765" d="M876.6,638.3c1.5-0.4,3-0.9,4.5-1.3c0.4,0.4,2-0.1,1,1.2c-0.9,1.2-1.7,2.3-0.8,3.8
		c-0.3,0.4-0.6,0.8-0.9,1.2c-0.2,0.2-0.3,0.3-0.5,0.5c-0.6,0.4-1.3,0.5-2,0.6c-1.2,0.1-2.3,0-3.4-0.5c-2-0.6-0.1-2.7-1.4-3.7
		c-0.1-0.4,0-0.8,0.3-1.2C874.4,638.5,875.6,638.8,876.6,638.3z"/>
	<path class="st1766" d="M929.3,658.1c1.3-1.2,2.7,1.2,4,0c0.6,0.6,0.6,1.3,0.4,2.1c-4.2,2.8-8,2-10.4-2.1c-3-4.4-7.1-8.1-9.3-13.1
		c-0.7-1.6-4-4.2,0.3-5.9c3.4,5.7,6.6,11.4,11,16.4C926.3,656.7,926.7,659.3,929.3,658.1z"/>
	<path class="st1767" d="M904.3,675.1c-1-5.3,1.6-9.2,4.8-12.9c1.3-1.5,1.3-3.3,1.2-5.1c2.5-2.7,4.2-0.4,6,1c0.3,3.2,0.8,6.5-3,8.2
		c-2.1,2.1-3.7,4.5-4.9,7.2C907.6,675.4,906.4,676.6,904.3,675.1z"/>
	<path class="st1768" d="M913.3,665.1c3.4-1.3,0.7-5.2,3-7c2.3,0.4,3.4,3.2,6,3c2,0.2,4,0.5,4,3.2c-2.6,2.3-5.7-0.3-8.5,0.9
		c-0.2,0.4-0.5,0.8-0.7,1.1c-1,1.9-0.2,4.3-1.8,6c-0.5,0.2-1,0.1-1.4-0.3C912.7,669.7,912.6,667.4,913.3,665.1z"/>
	<path class="st1769" d="M926.3,664c-1.3-1-2.7-2-4-3c-0.3-1-0.7-2-1-3c0.7,0,1.3,0,2,0c3,2.6,6.2,3.7,10,2c0.9-0.2,1.3,0.3,1.5,1.1
		c-0.1,1.6-1,2.8-2.1,3.8C930.3,665.9,928.2,665.7,926.3,664z"/>
	<path class="st1770" d="M1031.1,441.2c-0.2,1.1-0.6,2.2-0.6,3.3c0,1,1.1,2.3-0.4,2.9c-1.2,0.5-0.6-2.4-3-1.2
		c4.2,4.5,6.3,9.8,6.1,15.9c-0.6,0.7-1.3,0.6-2,0.2c-0.6,0-1.2-0.1-1.8-0.1c-0.1-0.3-0.2-0.6-0.2-0.9c-2.3-2.7-4.6-5.3-6.9-8
		c0.6-2.8,0.1-5.7,0.5-8.5c0.8-2.4,3.1-2.4,5-3.2C1028.8,441.4,1029.9,441,1031.1,441.2z"/>
	<path class="st1771" d="M869.3,818c-1.7-2.2-0.5-4.9-1.1-7.3c0.4-2.7,1.2-5.3,3.1-7.4c2.4,0.2,3.1,2,3.5,3.9c0,0.7-0.2,1.3-0.4,2
		c-0.3,3,2,7.9-4.6,6.5C870.1,816.6,870,817.4,869.3,818z"/>
	<path class="st1772" d="M874.4,807.7c-1-1.4-2.1-2.9-3.1-4.3c0-0.8,0-1.6-0.1-2.3c0.7,0.3,1.4,0.6,2.1,0.9c2.5,1.2,3.3,3.1,2.3,5.8
		C875.2,808.2,874.8,808.1,874.4,807.7z"/>
	<path class="st1773" d="M888.5,663.4c1.5-1.9,0.2-4.6,1.8-6.5c-0.5,5.5,1.8,10.7,2.1,16.2c-0.4,5.2,1,10.4-0.4,15.6
		c-2.7,1.4-4.8,3.3-5.1,6.6c-0.1,1.4-0.7,2.7-2.6,1.8c-1.4-1.5-1.2-3.4-1.4-5.1c-0.1-3,0.5-5.9,0.9-8.9c0.6-2.9,1.1-5.7,1.5-8.6
		c0.4-1.2,0.6-2.5,1-3.8C887.8,668.5,886.8,665.6,888.5,663.4z"/>
	<path class="st1774" d="M897.3,671.1c0,1.3,0.7,2,2,2c1.8,2.6-0.1,5.1-0.2,7.7c-0.4,7.3,0.3,14.6-1.2,21.8
		c-0.8,3.7,1.5,7.6-0.3,11.3c-3.5,0.2-3.7-2.7-4.3-4.9c-0.8-2.7-0.5-5.6-0.4-8.4c-0.2-6-0.1-12-0.1-17.9c0-2-0.2-4,1.5-5.6
		C896.2,675.5,893.8,671.8,897.3,671.1z"/>
	<path class="st1775" d="M894.3,677.1c0,8,0,16,0,24c-0.7,0.6-1.5,0.6-2.3,0.2c-3.3-3.9-2.7-8-0.7-12.2c-0.1-4.2-0.1-8.4-0.4-12.6
		c-0.1-1.5,0.2-2.5,1.4-3.4c1.2-0.6,0.7-2,1.4-3C895.6,672.3,892.6,674.9,894.3,677.1z"/>
	<path class="st1776" d="M880.3,669.1c1.2,0.7,1,1.9,1,3c0,0.2,0.1,0.5,0.1,0.7c-0.2,2.4,1.2,5.5-2.3,6.7c-0.4,0.2-0.4-0.2-0.2,0.2
		c3.6,6.8-3.2,11-4.4,16.6c-0.4,1.6-2.4,4.8-5.6,2.1c1-3.4,0.3-7.4,3.7-9.8c-0.4-0.5-0.8-1-1-1.6c-0.3-2.4,1.1-4.2,1.9-6.2
		c1.5-2.7,2.5-5.6,3.8-8.4C878,671,878,669,880.3,669.1z"/>
	<path class="st1777" d="M880.3,669.1c-1.9,4-3.8,8.1-5.7,12.1c-2.8-0.5-1.4-2.6-1.5-4.1c-0.1-1.9,0.3-3.8,1.2-5.6
		c4.1-6.2,4.4-13.7,7.1-20.4c0.9-0.6,2-0.7,3-1c1.6,0.8,0.4,3.1,2,4C884.3,659.1,882.3,664.1,880.3,669.1z"/>
	<path class="st1776" d="M888.5,663.4c0,2.7-0.5,5.2-1.3,7.7c-0.5,1.7-2.3,2.2-3.5,3.1c-0.7,0.6-1.6,1.5-2.4,0.5
		c-0.6-0.7-0.5-1.8,0.1-2.7v0c5.4-2.3,6-7.1,6.8-12C888.2,661.2,888.3,662.3,888.5,663.4z"/>
	<path class="st1778" d="M884.3,650.1c-0.2,1.2-0.4,2.5-2,1c-3.7,0.7-2.8,4.5-3.3,6.4c-1,4.6-2.6,9-3.7,13.6
		c-0.8,3.3-1.6,6.6-0.8,10.1c-0.8,1.9-1.5,3.9-2.3,5.8c-0.5,1.1-0.4,2.8-2.4,2.4c-2.2-4.4-0.3-8.7,0.1-13c0.3-7.4,3.6-14.1,4.9-21.3
		c0.4-2.2,0.8-4.2,0.2-6.3c0.1-1.2,1-1.6,2-1.9c0.5-0.1,1,0.1,1.4,0.4c0.2,1.5,0.7,1.5,1.8,0.6c1.1-0.8,1.8-2.9,3.4-2.1
		C885.1,646.5,884,648.6,884.3,650.1z"/>
	<path class="st1779" d="M878.3,647.2l-0.5,0l-0.5,0c-0.3-0.3-0.5-0.7-0.5-1.2c0.3-2,2.4-1.8,3.5-2.8c0,0,0.1,0,0.1,0
		c0.7,0.1,1.5,0.1,1.8,0.9C880.9,645.1,879.6,646.1,878.3,647.2z"/>
	<path class="st1780" d="M1031.1,462.4c0.7-0.1,1.3-0.1,2-0.2c0.6,0,1.3,0,1.9,0C1033.9,466,1032.4,463,1031.1,462.4z"/>
	<path class="st1465" d="M877.3,792.9c2.3,0.9,2.1,3.7,4,5c3.4,2,5.8,5.4,9.5,7.1c1.9,0.9,3.7,2.2,3.7,4.7c-0.3,0.4-0.7,0.6-1.2,0.7
		c-3.6-1-6.9-3-10.6-3.9c-2.2-0.6-3.3-2.7-4.2-4.6c-0.9-2-2.2-3.4-4.6-2.1c-0.2,0-0.4,0.1-0.7,0.1c-1.4-1.4-2.4-3-1.4-5.1
		C873.1,792.2,875.4,793.1,877.3,792.9z"/>
	<path class="st1465" d="M875.3,807.8c0.2-2.3-1.2-4-2-5.9c0-0.7,0-1.3,0-2c0,0,0,0,0,0c1.2-0.4,2,0.3,2.6,1.1
		c3.2,4,5.1,8.8,8.4,12.8c0.5,0.6-0.1,1.2-0.8,1.6c-0.5,0.1-1,0.1-1.4,0.1C879.1,813.6,875.5,812.2,875.3,807.8z"/>
	<path class="st1781" d="M917.3,901.8c1.5,2.1,4.5-0.1,6,2c-2.2,0.7-3.7,2.3-5,4c-1.7,0-3.3,0-5,0c-2-1.3,0.1-3.5-1-5
		C913.8,901.5,915.3,900.5,917.3,901.8z"/>
	<path class="st1782" d="M981.2,509.3c-3.1-4.6-1.9-9.6-1.3-14.6c0.2-1.9,1.6-1.1,2.5-1.5c0.3,0.1,0.5,0.1,0.8,0.1
		c1.4,0.5,2.4,1.3,2.9,2.8c1,2.4,4,3.8,3.3,6.9c0.7,2.7,2,5.5-1.8,7c-0.5,0.5-1.1,0.8-1.8,1c-1.2,0.3-2.4,0.4-3.6,0
		C981.5,510.6,980.9,510.2,981.2,509.3z"/>
	<path class="st1783" d="M978.1,473.3c0,1.6-0.1,3.3,0.1,4.9c0.3,2.7,1.3,0.1,2,0.2c1.2,0.9,1.1,2.4,1.8,3.5
		c0.3,0.6,0.6,1.3,0.8,1.9c-0.1,2.1-0.3,3.8-2.8,1.5C976.1,481.9,976.6,477.7,978.1,473.3z"/>
	<path class="st1784" d="M980.1,485.3c2.2,0.9,2.2-0.5,2.1-2c0.2-0.1,0.4-0.3,0.5-0.4c0.5,0,0.9,0.1,1.2,0.4
		c0.8,0.8,1.4,1.7,1.6,2.8c0,0.5-0.2,0.8-0.6,1.1c-1.3,1.5-0.6,3.3-0.8,5C983,489.9,980.5,488.2,980.1,485.3z"/>
	<path class="st1785" d="M985.3,496c-0.7-0.9-1.4-1.8-2.1-2.7c0.3-0.3,0.7-0.7,1-1c2.4,0.2,3.7,1.5,4,3.9c-0.4,0.1-0.8,0.1-1.2,0.2
		C986.3,496.8,985.7,497,985.3,496z"/>
	<path class="st1786" d="M990.3,544.1c1.6-0.3,3.3-0.6,4.9-0.8c0.4,0.8,1.2,0.9,2,1c0.1,8.8,1.9,17.3,3,25.9c-1.3,0.7-0.8,3.2-3,3
		c-2.1,0.1-1.5-1.6-1.7-2.7c-0.7-3.4-0.3-6.8-1-10.2c-0.4-1.8-0.7-3.6-2.4-4.6c-0.7-0.6-1.3-1.4-1.6-2.3c-0.4-2.7-2.1-5.2-1.6-8.1
		C988.9,544.3,989.2,543.6,990.3,544.1z"/>
	<path class="st1787" d="M1005.2,579.2c-0.3,0.7-0.7,1.3-1,2c-1.4,0.9-0.8,2.2-0.7,3.5c-0.5,1-1.3,1.2-2.2,0.9
		c-1.2-0.8-1.9-1.9-3-2.8c-0.7-0.7-1-1.6-1-2.6c0.1-3.9,4.7-3.9,5.9-6.9c0,0,0.2,0.1,0.2,0.1
		C1006.7,574.4,1004.5,577.2,1005.2,579.2z"/>
	<path class="st1788" d="M992.3,555.1c2.5-1,2.7,0.6,2.9,2.4c0.6,5.2,1.3,10.4,2,15.6c-0.4,0.7-0.7,1.4-1.1,2.1
		c-0.5,0.3-1,0.2-1.4-0.2c-2.3-2.8-2.7-6.1-2.8-9.6c0-1.8,0.1-3.6-0.7-5.3c-0.4-1.2-0.9-2.4-0.6-3.7
		C990.9,555.8,991.5,555.3,992.3,555.1z"/>
	<path class="st1789" d="M1003.2,573.2c0.1,3.5-4.8,3.2-5,6.5c-2.2,0-2.4-0.4-1.8-3.5C998.7,575.2,1000.9,574.2,1003.2,573.2z"/>
	<path class="st1790" d="M996.4,576.2c0.7,1.1,0.4,2.8,1.8,3.5c0.1,1.1,0.4,2,1.3,2.7c0.4,0.9,0.6,1.7,0.1,2.6
		c-0.4,0.3-0.8,0.4-1.3,0.4c-0.4-0.2-0.8-0.5-1.1-0.9c-1.5-2.1-3-4.2-3.4-6.7c0-1.2,0.3-2.4,1.6-3c0.3,0.1,0.5,0.3,0.8,0.4
		C996.2,575.6,996.3,575.9,996.4,576.2z"/>
	<path class="st1791" d="M991.6,530.3c0.2-3.3-2.2-5.3-3.7-7.8c0.2-0.4,0.5-0.7,1-0.9c2-0.4,3,0.8,3.8,2.4c0.6,0.9-0.6,2.2,0.6,3
		c0,0-0.1,0.3-0.1,0.3C992.1,528,993.6,530,991.6,530.3z"/>
	<path class="st1587" d="M982.3,518c0-0.3,0-0.5-0.1-0.8c-0.3-0.2-0.4-0.4-0.4-0.7c0.1-0.3,0.1-0.4,0.2-0.4c0.4-0.2,0.8-0.3,1.3-0.2
		c0.6,0.2,1.2,0.6,1.8,1c1.5,1.1,3.2,1.9,3.6,3.9c0,0.5-0.1,0.9-0.4,1.3c-0.1,0.1-0.2,0.3-0.4,0.4C986,521,984.2,519.5,982.3,518z"
		/>
	<path class="st1792" d="M990.3,544.1c-2.3,3.3,2.1,5.9,0.9,9.1c-0.9,0.9-1.7,0.4-2.6,0c-2.7-1.9-5.9-2.7-8.4-4.9
		c-3.3-2-5.1-4.5-2-8.1c1.5,1.2,2.9,2.4,4.9,2.8C985.5,543.7,988.1,542.8,990.3,544.1z"/>
	<path class="st1474" d="M978.4,540.1c-1.2-0.6-4.2,0.2-2.2-2.9c0.7,0.6,1.5,1.3,2.3,1.9C978.4,539.5,978.4,539.8,978.4,540.1z"/>
	<path class="st1793" d="M982.4,516.2c-0.1,0.3-0.1,0.6-0.1,1c-1.6-0.8-2.9-2.3-4.9-2.1c0-0.7,0-1.3,0-2c1.1-1.8,3.1-1.7,4.8-2.1
		c0.4,0.2,0.7,0.6,0.9,1.1C983.5,513.5,983.4,515,982.4,516.2z"/>
	<path class="st1794" d="M982.2,511.3c-1.1,2-3.4,0.9-4.9,1.9c-0.6-3.2,1-4.2,3.9-3.9c0.4,0.3,0.7,0.7,1.1,1
		C982.6,510.7,982.5,511,982.2,511.3z"/>
	<path class="st1795" d="M852.4,673.1c0.5-2.8-0.9-5.3-1-8c2.2-4.2,0.3-8.8,1-13.1c0.3-1.9-1-5,2.9-4.9c1.6-0.9,2.2-0.2,2.3,1.4
		c-1.6,13.1-1.5,26.3,0.4,39.4c-0.4,1.4-1.3,1.9-2.7,1.8c-3.4-3.5-2-8.2-2.7-12.3C852.5,676,853.2,674.4,852.4,673.1z"/>
	<path class="st1796" d="M853.3,642.2c0.9,0,1.9,0,2.8,0.1c1.1,0.5,2.8-0.4,3.4,1.3c0,1.1-0.9,1.4-1.7,1.7c-0.2,0-0.4,0-0.7,0
		C855.4,644.9,854.2,643.7,853.3,642.2z"/>
	<path class="st1797" d="M857.3,648.1c-0.4-0.8-1.2-1-2-1c0.6-0.6,1.3-1.2,1.9-1.9c0,0,0.1-0.2,0.1-0.2c1.3-0.8,2.5-0.2,3.7,0.4
		c0.3,0.3,0.5,0.7,0.6,1.1c-0.4,1.2-1.5,1.4-2.5,1.8c-0.4,0.1-0.8,0.1-1.3,0.1C857.7,648.3,857.5,648.2,857.3,648.1z"/>
	<path class="st1798" d="M855.3,689c0.8,0,1.6-0.2,2-1c1.8-0.3,1.9,1.1,2,2.1c0.2,3,1.2,5.2,3.9,6.9c3.3,2-1,3.8-0.6,5.9
		c0.1,0.4,0,0.8-0.2,1.1c-1.7,2-3.5,4-5.2,5.9c-0.6,0.6-1.2,0.7-1.9,0c-0.9-1.3-2-2.7,0-4C854.4,700.4,852.5,694.7,855.3,689z"/>
	<path class="st1799" d="M855.3,689c0,5.7,0,11.3,0,17c-3.7-3.3-2.5-8.1-2.7-11.9c-0.4-7-1.7-14-0.3-21c1.6,3.8,1.3,7.9,2,11.9
		C854.6,686.3,853.9,687.9,855.3,689z"/>
	<path class="st1799" d="M855.3,710c0.6,0,1.3,0,1.9,0c-0.5,1.3-1.5,1.9-2.8,2.1C852.8,710.5,854,710.3,855.3,710z"/>
	<path class="st1800" d="M862.5,704.1c0-0.3,0-0.7,0.1-1c2.4-4.8,4-10.1,8-13.9c0.6-0.7,1.1-1.5,1.7-2.2c0.6,4.1-1.1,7.6-2.9,11.1
		c-1,3-0.1,6.9-4.3,8.2c-0.2-0.1-0.4-0.2-0.7-0.2C863.7,705.4,863.1,704.7,862.5,704.1z"/>
	<path class="st1801" d="M861.3,712c-0.3,0-0.6,0.1-1,0.1c0-1.1-0.2-2.3,1-3.1c0.6-0.6,1.2-0.6,1.9-0.1
		C864.1,710.8,862.6,711.3,861.3,712z"/>
	<path class="st1800" d="M863.4,709c-0.7,0-1.3,0-2,0c0.7-1.3,1-3,3-3c0,0,0,0,0,0c0.7,1.1,0,1.9-0.5,2.7
		C863.7,708.8,863.5,708.9,863.4,709z"/>
	<path class="st1802" d="M859.4,644c-1.1-0.6-2.2-1.2-3.3-1.8c0.8-0.4,1.6-0.9,2.4-1.3c1.6,0.5,3.2,0.9,4.8,1.4
		c0.9,1.9-0.4,2.3-1.8,2.6C860.7,645,860,644.7,859.4,644z"/>
	<path class="st1803" d="M849.4,613.1c0.8,0,1.6-0.2,2-1c1.1-1.7,2.6-2.1,4.5-1.6c0.4,0.2,0.7,0.6,0.8,1.1c-0.7,1.6-1.7,3.1-2,4.8
		c-1.6,6.3,2.7,10.8,4.7,16c0.6,2.2,0.1,3.6-2.6,3.2c-0.8-0.3-1.3-0.8-1.5-1.7c-0.1-1.1,0.2-2.8-0.4-3.3
		C849.6,626,851.8,618.8,849.4,613.1z"/>
	<path class="st1804" d="M855.3,634.1c0.3,0.3,0.7,0.7,1,1c3.3,1.1,7.4-0.4,10,3c0.2,0.5,0.1,0.9-0.2,1.2c-4.7-0.1-9.2-0.8-12.9-4.1
		C853.7,634.4,854.5,634.1,855.3,634.1z"/>
	<path class="st1805" d="M850.4,605.1c1,0,2,0,3,0c1.3,1.4,1.3,3,0.6,4.6c-0.9,1.1-2.1,1.7-3.6,1.3c0-0.7,0-1.3,0-2
		C850.3,607.8,850.4,606.5,850.4,605.1z"/>
	<path class="st1598" d="M850.4,611.1c1-0.4,2-0.7,3-1.1c0.8-0.8,1.7-1.4,2.9-1.2c0.6,0.3,1.1,0.7,1.3,1.4c-0.1,0.7-0.4,1.2-1.2,1
		c-1.7,0.3-3.4,0.6-5,1C851,611.8,850.7,611.4,850.4,611.1z"/>
	<path class="st1806" d="M873.1,640c4.2-0.5-0.6,2.6,1.2,3.1c-1.3,1.5-3.1,1.3-4.8,1.3c-2-0.1-4.2,0-5.1-2.4c1-0.7,2-1.3,3-2
		C869.2,639.5,871.1,638.7,873.1,640z"/>
	<path class="st1807" d="M864.4,642.1c1.5,0.9,3.2,1,4.9,1.2c0.2,0.3,0.3,0.6,0.3,0.8c-0.1,0.3-0.2,0.4-0.2,0.4
		c-0.9,0.9-2.1,1.3-3.3,1.6c-1.5,0.2-3,0.3-4.4-0.7c-0.5-0.4-0.6-0.8-0.3-1.3c1.2-0.1,2-0.5,2.1-1.8
		C863.7,642.2,864,642.1,864.4,642.1z"/>
	<path class="st1804" d="M873.1,640c-1.9-0.5-3.8,1.7-5.7,0.1c-0.4-0.3-0.8-0.5-1.2-0.8c0,0,0.3-0.4,0.3-0.4c2.4-1.6,4.7-1.5,7,0.3
		C873.3,639.5,873.2,639.7,873.1,640z"/>
	<path class="st1808" d="M989.2,884.6c-0.7,0.4-1.4,0.8-2,1.1c-0.6-0.2-1.1-0.5-1.3-1.2c-0.1-1.1,0.4-2,0.8-3
		c0.5-0.9,1.1-1.8,1.8-2.6c0.8-0.9,1.1-2.3,2.7-2.4c1,0.5,1.7,1.2,2.2,2.2c0,0.3,0,0.7,0,1C991.9,881.4,990.6,883,989.2,884.6z"/>
	<path class="st1809" d="M993.3,878.8c-1.2,0-2-0.5-2.1-1.8c0.2-1,0.8-1.6,1.9-1.2c0.7-0.3,1.3-0.7,2-1
		C995.1,876.4,994.4,877.7,993.3,878.8z"/>
	<path class="st1810" d="M993.1,875.9c-0.6,0.4-1.3,0.8-1.9,1.2c-0.7,0.9-1.4,1.7-2,2.6c-0.9-0.7-1-1.7-0.8-2.7
		c0.5-2.3,2.2-3.2,4.1-3.9c0.3,0,0.5,0,0.8,0C993.2,873.9,993.2,874.9,993.1,875.9z"/>
	<path class="st1811" d="M957.2,913.8c3.1-3.6,2.7-7.8,2-12c0.5-0.5,1-0.9,1.7-0.8c1.1,0.2,1.5-0.1,1.3-1.2c3.9-1.6,7.3-4.6,12-3
		c-1.6,2.5-3.5,4.8-3,8c-4.6,0.4-4.1,5.6-7.1,7.6c-1.6,1.1-3,2.4-5.2,2.7c2.7,0.9,1.8,1.8,0.4,2.7
		C956.6,917.5,957.4,915.4,957.2,913.8z"/>
	<path class="st1812" d="M974.2,896.8c-4,1.3-8,2.7-12,4c-1.6,0.9-3.5,0.5-5.2,0.4c-3-0.2-1.9-1.6-0.6-2.9c2-1.4,4.5-1.7,6.7-2.5
		c3.7-0.3,6.8-4.1,10.9-2.1c0.7,0.4,1.5,0.8,2.3,1.1C975.6,895.5,974.9,896.1,974.2,896.8z"/>
	<path class="st1813" d="M974.1,893.8c-3.5,1.1-7.1,2.1-10.6,3.2c-1,0.4-1.9,0.6-2.5-0.5c-0.2-1,0.2-1.9,0.8-2.7
		c0.3-0.3,0.7-0.6,1.1-0.8c1.7-0.6,3.3-1.5,5.2-1.7c3.6-0.3,6.5-2.1,9.4-3.9c0.8-0.5,1.6-1,2.6-1.1c0.5,0,0.9,0.1,1.4,0.3
		c0.8,0.6,1.3,1.3,0.8,2.4C980,891.3,976.5,891.7,974.1,893.8z"/>
	<path class="st1814" d="M982.2,888.9c-0.3-0.7-0.7-1.3-1-2c-0.6-1.4,0.6-1.6,1.4-2.1c1.2-0.3,2.4-0.8,3.6,0
		c0.3,0.3,0.6,0.6,0.9,0.9c-1.4,1-2.7,2-4.1,3C982.8,888.7,982.5,888.8,982.2,888.9z"/>
	<path class="st1815" d="M856.3,609.1c-1,0.3-1.9,0.6-2.9,0.9c0-1.6,0-3.2-0.1-4.9c0-0.3,0-0.7,0-1c0.7,0.3,1.3,0.7,2,1
		c0.9,0.7,1.5,1.6,1.6,2.8C856.8,608.4,856.6,608.8,856.3,609.1z"/>
	<path class="st1816" d="M856.4,608.4c-0.4-1.1-0.7-2.1-1.1-3.2c0.8-0.4,1-1.2,1-2c0.3-0.7,0.7-1.3,1-2c0-0.8,0.2-1.6,1-2
		c1.7,1.6,0.7,3.8,1.1,5.7C859.2,606.8,858.7,608.3,856.4,608.4z"/>
	<path class="st1817" d="M975.2,858.9c0.7,0,1.3,0,2,0c0.7,2.1,2.2,3.8,2.1,6.3c-0.2,6.4,1,7.5,7.9,8.7c-0.3,4.4-3.7,4.7-6.9,5.2
		c-6.6-1.8-11.8-5.7-15.9-11c-0.6-0.8-0.2-1.5,0.3-2.2C968.4,863.9,970.4,859.3,975.2,858.9z"/>
	<path class="st1818" d="M980.2,877.8c3.1,0,5.4-1.3,7-4c1.6-1,3.4-1.2,5.3-0.9c-1.1,1.3-2.2,2.6-3.3,3.8c-0.6,0.7-1,1.6-2,2.1
		c-3.5,2.1-7.5,3.1-11.2,4.8c-2.7,0.8-5.1,2.4-8,2.3c-1.3-0.2-2.7-0.3-3.1-1.9c2.7-5,8.3-3.7,12.5-5.6
		C978.2,878,979.4,878.6,980.2,877.8z"/>
	<path class="st1819" d="M985.2,851.9c-1.4,0.6-2.3,1.6-3,3c-3.9-0.1-6.9,2.1-10,4c-4.5,4.5-10.4,3.4-15.9,4.3
		c-1.4-0.2-2.9-0.3-3.3-2c0.9-1.6,2.6-2,4.2-2.3c5.2-0.8,10.1-2.7,15.1-4.1c4.1-1.3,8.2-2.6,11.8-5l0,0c1.7-0.5,2.9-2.6,5.1-1.9
		C989.9,851.2,986.1,850.2,985.2,851.9z"/>
	<path class="st1820" d="M989.1,847.8c-1.7,0.7-3,2.2-5.1,1.9c4.3-4.4,9.9-7.6,12.9-13.4c0.9-1.7,1.6-3.6,2.4-5.4
		c0.1-0.5,0.4-0.5,0.7-0.2c0,0.4,0.1,0.8,0.2,1.2c0,0.3,0,0.6,0.1,0.9c-0.4,1.3-0.7,2.7-1.1,4c-2,2.7-4,5.3-6,8
		C991.2,845,990.1,846.3,989.1,847.8z"/>
	<path class="st1821" d="M956.3,861.9c5.4-0.4,10.8-1.1,15.9-3c1,0,2,0,3,0c-4,1.3-5.2,6.8-10,7c-2.3,1.5-3.9-0.8-5.9-1.2
		c-1.1-0.4-2.3-0.8-3.2-1.6C955.9,862.7,955.9,862.3,956.3,861.9z"/>
	<path class="st1822" d="M1000.1,830.8c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1-1-0.2-2.1-0.3-3.1c0.1-1.7,0.2-3.3,0.3-5
		C1001,825.4,999.9,828.1,1000.1,830.8z"/>
	<path class="st1823" d="M1008.2,744c1,2-1,5.1,2.3,6.4c0.3,0.1,0.4,1.7,0.3,1.7c-3.8,1.1-1.8,5.2-2.6,6.1c-4,4.8-1.6,11-4.5,15.8
		c-0.9,1.5-0.5,3.9-0.4,5.8c0.1,2.1-0.3,3.9-2,5.2c-2.2,0-3.6,2.6-6,2c-2.2-2.7-1.4-5.8-1.1-8.9c1.6-6.5,5.2-12.3,6.3-19.1
		c0.5-3,3.1-5.3,4.2-8.3c0.8-2.3,1-4.4,0.2-6.6c0-0.5,0.1-1,0.4-1.4C1006.6,742.2,1007.6,742.6,1008.2,744z"/>
	<path class="st1824" d="M990.2,807.9c-1.3-0.8-2-0.2-2.6,1.1c-0.6,1.5-1.4,2.9-3.4,2.9c-0.8-0.7-1-1.7-0.8-2.7
		c1-4-0.4-7.3-2.8-10.4c-0.6-1.3-0.6-2.6-0.3-4c2.9-7.1,3.5-7.5,9.5-7.2c2.5,2.3,1.2,4.8,0.3,7.3
		C990.2,799.3,990.2,803.6,990.2,807.9z"/>
	<path class="st1825" d="M990.2,794.9c-0.4-2.3,0.9-4.9-1-7c-1.9-6.3,0.4-11.2,5.2-15.2c2.3,1,1.4,2.6,0.8,4.2c0,3.3,0,6.7,0,10
		C993.6,789.7,992.9,792.9,990.2,794.9z"/>
	<path class="st1826" d="M990.2,826.9c-2.8,1.6-2.3,3.8-0.9,6c0.3,1.6-0.7,2.1-2,2.4c-2.6-2.9-4.5-6.4-8.2-8.3
		c-2.5-4.1-2.3-8.6-1.7-13.1c0.3-0.8,0.7-1.3,1.5-1.6c2.2-0.1,3.7,1.1,5.3,2.5c1.3,1.8,2.4,3.6,2,6c0.1,1.7-0.4,3.5,1,5
		C987.9,827.1,989.1,826.8,990.2,826.9z"/>
	<path class="st1827" d="M984.2,814.9c-2.1,0.4-3.4-1.1-5-2c-3.8-4.9-1.2-9.3,1.3-13.6c0.2-0.1,0.4-0.3,0.7-0.4
		c3.7,1.8,4.2,5.1,3.9,8.7c-0.1,1.5-0.6,2.9-0.9,4.3C984.2,812.9,984.2,813.9,984.2,814.9z"/>
	<path class="st1827" d="M987.2,825.9c-3.1-1.1-2.3-3-1-5C987.6,822.3,987.6,824.1,987.2,825.9z"/>
	<path class="st1828" d="M983.2,843.8c0.7,0.4,1.4,0.7,2.1,1.1c-0.8,2.5-3.9,2.7-5.1,4.9c-1.6,2.5-3.6,3.3-6.3,1.4
		c-0.3-1.5,0.8-2.2,1.7-3C978.3,847.1,979.7,843.8,983.2,843.8z"/>
	<path class="st1829" d="M974.2,851c2.1,0.4,4.1-0.2,6-1.2c0,0.4,0.1,0.9,0.1,1.3c-1.6,2.7-4.6,3.1-7.1,4.2c-1.3,0.1-2.5,0-3.2-1.4
		C970.4,851.5,971.7,850.5,974.2,851z"/>
	<path class="st1820" d="M973.2,854.9c2.4-1.3,4.7-2.6,7.1-3.8c1.3-0.5,2.5-0.9,3.8-1.4c-2.9,3.5-6.9,4.9-11,6
		c-0.2-0.2-0.3-0.4-0.2-0.6C973,855,973.1,854.9,973.2,854.9z"/>
	<path class="st1830" d="M983.2,843.8c-2.4,1.6-4.1,4.1-7,5.1c-0.9,0.3-1.2-0.2-1.2-1c1.4-2.9,4.1-4.6,6-7.1c1.1-0.7,2.1-0.6,3,0.4
		c0.1,0.2,0.2,0.4,0.3,0.6C983.8,842.5,983.5,843.2,983.2,843.8z"/>
	<path class="st1831" d="M987.2,834.9c0.7-0.7,1.4-1.3,2.1-2c0.7-0.3,1.3-0.7,2-1c0,3.1-3.1,4.4-4.1,7c-0.4,0.3-0.8,0.3-1.2,0
		c-0.4-0.6-0.6-1.3-0.5-2C985.6,835.9,986.3,835.3,987.2,834.9z"/>
	<path class="st1832" d="M986.1,838.8c0.4,0,0.7,0,1.1,0c0,2-1.4,2.6-3,3c0,0-0.1-0.1-0.1-0.1C983.9,840.2,984,838.9,986.1,838.8z"
		/>
	<path class="st1833" d="M987.2,509.3c0.5-0.4,0.4-0.8-0.1-1.2c4-0.8,1-3.1,1.1-4.7c0-0.1,0.6-0.1,0.9-0.1c1.6-0.5,1-3.1,3.1-3.1
		c0.7,0.1,1.4,0.2,2.1,0.3c0.3,1.1,2.9,0.4,2,2.1c-2.2,4.2-0.5,8.3-0.3,12.5c0.2,2.4,1.2,5.4-1.9,7.1c-0.1-0.1-0.3-0.2-0.4-0.3
		C992.6,517.2,991.1,512.6,987.2,509.3z"/>
	<path class="st1834" d="M987.2,509.3c6.3,2.2,7,7.4,7,13c0.9,1.8-0.3,3.2-1,4.7c-1.4-0.4-0.7-1.8-1.2-2.7c-1.1-5-3.4-9.6-5.8-14.1
		C986.6,509.9,986.9,509.6,987.2,509.3z"/>
	<path class="st1835" d="M992.3,500.2c-1.5,0.6,0.1,4.2-3.1,3.1c-0.3-3-3.8-4.2-3.9-7.3c0.6,0.1,1.1,0.2,1.7,0.4
		c1,1.4,2.5,1.9,4.2,2.1C991.5,499,991.9,499.6,992.3,500.2z"/>
	<path class="st1507" d="M863.3,763c0,0.3,0,0.7,0,1c0.4,3.2,0.1,6-4.2,7.4C861.1,768.5,858.4,764.1,863.3,763z"/>
	<path class="st1836" d="M880.3,781.9c-5.4-2.8-7.8-8.3-11-13c-2.9-8.1-5.7-16.2-5-25c0.7-0.6,1.4-0.6,2.2-0.1
		c1.5,7.2,4.4,14,7.4,20.7c0.8,6,5.4,10.4,7,16.1C880.9,781.1,880.7,781.6,880.3,781.9z"/>
	<path class="st1694" d="M866.3,744c-0.7,0-1.3,0-2,0c-1.8-4.6-1.5-5.4,2-5c0.1,0.2,0.2,0.4,0.2,0.6c-0.2,1.1-0.3,2.1,0.1,3.2
		C866.7,743.2,866.6,743.6,866.3,744z"/>
	<path class="st1694" d="M866.3,739c0.7-1.2,1.2-2.5,1-4c0,0,0,0,0,0c1.7-0.8,2.6,0,2.4,1.5C869.5,738.3,868.8,740.1,866.3,739
		L866.3,739z"/>
	<path class="st1837" d="M877.3,784.9c1.6,0.2,2-1.4,3-2c2.1-1.4,3,0.3,4,1.6c6.9,8.5,14.4,16.4,23.3,22.9c0.4,0.3,0.7,1,1.1,0.9
		c5.6-0.3,6.8,3.8,7.9,8c0.3,0.4,0.5,0.9,0.6,1.4c0.6,3.3,0.1,6.4-1.9,9.3c-4-2-2.3-6.1-3.4-9.2c0.1-2.1,0-4.2-3-4
		c-3.3,0.2-5.1-2.5-7.1-4.2c-4.9-4.1-11-6.8-14.3-12.8c-0.9-1.7-3.5-1.9-4.3-3.9C881.6,790,878.4,788.3,877.3,784.9z"/>
	<path class="st1838" d="M883.3,792.9c5.5,2.9,9.2,8,14,11.9c3.1,2.5,7.2,3.9,9.6,7.5c0.3,0.5,1.6,0.7,2.3,0.4
		c4.4-1.6,4.2,1.4,4.2,4.2c-2,1.1-4.1,1.4-6.2,0.3c-5.3-5-11.9-8.4-17-13.7c-1.5-1.6-4.2-2.5-2.8-5.6
		C886.6,795.7,883.1,795.8,883.3,792.9z"/>
	<path class="st1839" d="M887.3,797.9c3.2,5.7,8.6,9.1,13.5,12.9c2.3,1.8,5.9,2.3,6.5,6c-3.7,1-5.9-3.1-9.3-2.8
		c-1.9-0.8-4-1.5-3.6-4.2c-1.3-2.2-3.2-3.6-5.5-4.7c-3.2-1.6-5.5-4.3-7.5-7.2C883.3,799.6,885.3,798.3,887.3,797.9z"/>
	<path class="st1840" d="M915.3,816.9c-1.3-0.9-1-2.4-1-3.6c0-3.1-1.4-4-4.1-2.8c-0.8,0.3-1.7,0.7-2.3,0c-8-8.6-17.9-15.4-24.5-25.3
		c-0.8-1.2-1.6-2.1-3.1-2.1c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1c1-1,2.3-1.1,3.6-0.8c4.1,2.2,8.1,4.6,9.8,9.4c0.3,0.8,0.7,1.7,1.4,2
		c4,1.6,6.5,4.6,9,8c1.5,2.1,3.7,4.2,7,4.2c2.1,0,3.4,1.9,4.5,3.6C916.8,810.5,917.4,813.7,915.3,816.9z"/>
	<path class="st1841" d="M1008.2,744c-1-0.3-2-0.7-3-1c-0.8-1-1-2.3,0.1-2.9c1.8-0.9,2.9,0.5,3.8,1.9c0,0.7,0,1.3,0.1,2l-0.5,0
		L1008.2,744z"/>
	<path class="st1630" d="M1009.1,742c-1.4,0.1-3.8-4.1-3.9,1c0,0,0,0,0,0c-1.6,0.4-2-0.9-2.6-1.9c-0.6-3,0.5-4.1,3.5-3.1
		c0.6,0.3,1.2,0.7,1.9,1c0.4,0.7,0.8,1.3,1.2,2C1009.1,741.3,1009.1,741.7,1009.1,742z"/>
	<path class="st1842" d="M861.3,712c0.7-1,1.3-2,2-3c0,0,0,0,0,0c0.5-0.9,1.3-1.6,2.2-2.1c0.4-0.2,0.8-0.5,1.2-0.8
		c2.2-1.4,4.8-3.3,7.2-1.4c2.6,2,2.6,5.1,1.2,8.1c-0.8,0.9-1.2,2.2-2.6,2.5c-2.5-2-4-6.9-8.5-2.7c-0.5,0.5-1.1,0.7-1.8,0.3
		C862,712.7,861.7,712.3,861.3,712z"/>
	<path class="st1843" d="M862.3,713c1.6-2.2,3.7-4.1,6.4-3.5c2.3,0.6,3.2,3.1,3.6,5.4c0.3,1,0,1.3-1,1
		C867.9,716.3,864.3,717.1,862.3,713z"/>
	<path class="st1844" d="M871.3,716c0.3-0.3,0.7-0.7,1-1c0.7-0.7,1.3-1.3,2-2c1.1-1.3,1.5-2.8,1.5-4.5c0-1.8,0.5-3.6,2.8-4
		c1,0,1.9,0.5,2.6,1.2c0.3,0.4,0.4,0.8,0.4,1.3c-0.7,2-2.6,3.2-3.5,5.1c-0.3,0.3-0.6,0.6-1,0.9c-0.3,0.4-0.6,0.8-0.9,1.2
		c-2.4,1.9-4.8,3.8-6.1,6.7c-0.6,0.1-1.2,0.3-1.8,0.4C868.1,718.8,870.6,717.9,871.3,716z"/>
	<path class="st1845" d="M970.3,585.2c0-2.7,0-5.3-0.1-8c0.3-0.3,0.7-0.5,1.2-0.5c0.6-0.4,1.2,2.7,1.9,0c0.2-0.8,1.2-1,2.1-0.5
		c0.5,0.6,0.7,1.2,0.8,1.9c0.1,1,0,1.9-0.4,2.8c-1.4,2.5-0.4,4.7,0.9,6.8c0.9,2.1,2.9,3.3,3.8,5.4c0,0.8-0.4,1.2-1.1,1.5
		c-1.7,0.2-2.9-0.9-4.2-1.6c-0.3-0.3-0.7-0.5-1-0.8C972.9,589.9,971.6,587.5,970.3,585.2z"/>
	<path class="st1846" d="M974.2,592.2c0.2,0,0.4,0,0.7,0c1.9,0.6,3,2.6,4.9,3.3c2.6,2,5.3,2.5,8.1,0.2c0.5-0.2,0.9-0.2,1.4-0.2
		c0.5,0.1,0.9,0.4,1.2,0.8c0.1,1.8-1.1,2.7-2.3,3.6c-2,1.2-4.2,1.7-6.5,1c-2-0.7-3.2-2.3-4.4-3.7C975.3,596.1,974,594.6,974.2,592.2
		z"/>
	<path class="st1847" d="M976.1,611.1c-0.3-0.3-0.7-0.6-1-1c-0.6-2.7-0.8-5.3,0.2-8c1.2-0.8,2.5-1.1,4-1.1c0.1,0.2,0.3,0.4,0.4,0.6
		c0,3.1-2.2,5.5-2.6,8.5C976.8,610.6,976.5,610.9,976.1,611.1z"/>
	<path class="st1848" d="M971.3,601.1c-0.3,0.6-0.7,1.3-1,1.9c-0.1-2.3-0.2-4.5-0.2-6.8c0.1-2,0.1-4,0.2-6.1
		c0.6,1.4,1.2,2.8,1.8,4.2C971.6,596.6,972.3,598.9,971.3,601.1z"/>
	<path class="st1849" d="M975.3,602.2c-0.1,2.7-0.1,5.3-0.2,8c-2-2.8-0.4-6-1-9c0-0.7,0.1-1.4,0.1-2.1
		C975.4,599.9,975.2,601.1,975.3,602.2z"/>
	<path class="st1850" d="M971.3,601.1c0.2-2.3-1-4.7,0.8-6.7c0.4,1.3,0.8,2.5,1.2,3.8C972.6,599.1,971.9,600.1,971.3,601.1z"/>
	<path class="st1851" d="M977.1,597.2c1.4,1,2.8,1.9,4.2,2.9c0.3,0.5,0.3,0.9-0.1,1.3c-0.7,0.4-1.4,0.3-2-0.2c0,0,0-0.1,0-0.1
		C978.6,599.8,977.1,598.9,977.1,597.2z"/>
	<path class="st1852" d="M982.2,566.2c1.7-0.1,2.6,1.3,3.9,1.9c0.7,1.4,0.1,2.4-1,3.2c-1-0.7-2-1.4-3-2
		C982.2,568.2,982.2,567.2,982.2,566.2z"/>
	<path class="st1853" d="M975.3,576.2c-1.7,0.4-1.9,2-2.5,3.2c-0.6-0.8-1.1-1.5-1.6-2.3c-0.6-4.6,2.5-6,6-7.1c0.3,0,0.7,0.1,1,0.1
		c1.8,1.1,4,1.4,5.5,3.1c1.7,1.9,1.9,3.4-0.7,4.6c-0.5,0.1-1,0-1.4-0.1c-0.4-0.2-0.8-0.5-1.1-0.8c-0.7-1-1.5-1.6-2.8-1
		C977,576.2,976.2,576.6,975.3,576.2z"/>
	<path class="st1854" d="M982.2,577.2c3.1-0.7,2-2.4,0.6-3.7c-1.4-1.3-3.1-2.2-4.6-3.3c2.8-0.1,4.8,1.7,6.9,3.2
		c0.7,0,1.3,0.4,1.7,0.9c0.6,1,1,2,0.9,3.1c-0.2,1.3-0.4,2.6-1.1,3.8c-0.4,0.3-0.8,0.4-1.2,0.3c-1.3-0.9-2.3-2-3.4-3.2
		C981.8,577.9,981.9,577.5,982.2,577.2z"/>
	<path class="st1855" d="M986,574.3c-0.3-0.3-0.6-0.7-0.9-1c0-0.7,0-1.4,0-2c0.3-1.1,0.7-2.1,1-3.2c0,0,0,0,0,0
		c1.5-0.6,2.1,0.5,2.9,1.3c0.7,1.1,1.7,2.2,1.4,3.7c-0.9,0.9-2.4,0.6-3.3,1.4C986.7,574.7,986.3,574.6,986,574.3z"/>
	<path class="st1854" d="M977.2,570.1c-2.7,1.7-5.8,3.2-6,7.1c-0.3,0-0.6,0.1-1,0.1c-0.6-1.8,0.7-3.2,1.1-4.9
		C973.1,571,974.7,569.2,977.2,570.1z"/>
	<path class="st1856" d="M984.1,565.1c-2.2-0.6-3.7-2-4.8-3.9c-0.1-2.7-0.1-5.3-0.2-8C983.5,554.6,985.6,559.7,984.1,565.1z"/>
	<path class="st1857" d="M984.1,565.1c-1.2-4.2-0.6-9-5-11.9c0.7-1,0.7-1,4-1c0.9-0.4,1.2,0.1,1.6,0.8c0.6,2.1,1.6,4,1.8,6.2
		c0,2.1,0.5,4.2-1.2,5.9C984.9,565.1,984.5,565.1,984.1,565.1z"/>
	<path class="st1647" d="M984.3,553.1c-0.4-0.3-0.8-0.6-1.2-0.9c0-0.3,0-0.6-0.1-0.9c2.3-1.5,4.1,0.5,6.1,1c0.4,0.8,1.2,1,2,1
		c0.4,0.6,0.7,1.3,1.1,1.9c-0.5,0.4-1,0.8-1.4,1.2c-1.1,0.8-2.1,0.2-3.2-0.2C986.3,555.4,984.8,554.8,984.3,553.1z"/>
	<path class="st1788" d="M989.2,552.2c-2-0.3-4.1-0.6-6.1-1c-0.8,0-1.5-0.1-1.9-0.9c-0.3-0.7-0.6-1.4-1-2.1
		C983.3,549.4,986.6,549.9,989.2,552.2z"/>
	<path class="st1856" d="M988.2,555.3c0.9,0.3,1.8,0.7,2.7,1c0.4,1,0.9,1.9,1.3,2.9c-0.1,4,2.7,8.6-2.7,11.3c-0.6,0.1-1-0.1-1.3-0.6
		c-0.7-0.6-1.4-1.2-2-1.8c0.1-1.1,0.4-2.3-0.8-3c0-2.3,0-4.6-0.1-6.9c1.4-0.6,1.1,1.1,1.8,1.4c-0.5-0.6-0.5-1.3-0.5-2
		C986.5,556.4,987.1,555.7,988.2,555.3z"/>
	<path class="st1858" d="M1049.1,453.4c-0.1,1.4,0.4,2.7,1,4c-2.7-0.2-5.4,0.2-8,1c-2.7-1.2-2.1-4-3-6c-0.3-2.3-0.7-4.7-1-7
		c2.3,2.8,1.7,7.4,6.6,9C1047.1,455.1,1047.8,454.5,1049.1,453.4z"/>
	<path class="st1480" d="M1039.1,452.3c1,2,2,4,3,6c-1,0-2,0-3,0C1039.2,456.3,1039.2,454.3,1039.1,452.3z"/>
	<path class="st1742" d="M983.2,636.1c-1.7,1-3.3,1.9-5.1,2.8c-0.7,0.4-2,0.6-0.8,1.5c2.9,2.3-0.2,2.4-1.1,3.1
		c-0.9,0.7-2.3,0.4-3,1.5c-2.6-0.8-5.1-1.2-7.8-1.1c-1.1,0-1.9-0.5-2-1.7c-0.1-1.1,0.4-2,1.6-2.1
		C971.2,639.7,976.8,636.1,983.2,636.1z"/>
	<path class="st1859" d="M973.2,646.1c0.3,0.1,0.6,0.1,0.9,0.1c2.5,0.6,4.7,2.1,7,3.1c1.4,1.7,4.1,1.5,5.1,3.8
		c-1.5,1.4-3.2,0.6-4.7,0c-3.7-1.4-7.6-2.4-11.3-3.7c-1-0.6-2.2-1-3.3-1.4c-2.5-0.9-5.1-0.5-7.7-0.3c-0.5,0-1-0.2-1.4-0.5
		c-0.8-1.2-0.8-2.4-0.2-3.7c0.3-0.5,0.8-0.6,1.3-0.5C963.3,646.2,968.5,645.2,973.2,646.1z"/>
	<path class="st1860" d="M985.2,635.1c-0.1-0.7-1.1-1.4,0-2c1.7-2-2.3-4.5,0.6-6.4c1.8-0.2,2.4,1.2,3,2.5c0.4,1.1,0.7,2.2,0.8,3.3
		c0,0.5-0.2,0.9-0.4,1.3C988.1,635.1,987,636.2,985.2,635.1z"/>
	<path class="st1861" d="M986.3,627.1c-2.5,1.6,1.7,4.5-1.1,6c-0.6-3-2-5.9-2-9c0.6-0.3,1.3-0.6,1.9-0.8
		C986.5,624.2,987.3,625.4,986.3,627.1z"/>
	<path class="st1742" d="M985.2,635.1c1.6,0.2,2.4-1.3,3.8-1.8c1,0.2,1.2,1,1.2,1.8c-0.9,4.4-3.3,1-5,1
		C985.2,635.8,985.2,635.4,985.2,635.1z"/>
	<path class="st1862" d="M958.3,643.3c0,1.3,0.1,2.5,0.1,3.8c-3.1-2-3.5-4.8-1.3-10c0.3,0,0.6,0,0.9,0c0.2,0.3,0.2,0.6,0.2,0.9
		C959,639.7,958.9,641.5,958.3,643.3z"/>
	<path class="st1660" d="M958.3,643.3c0-1.8,0-3.5-0.1-5.3c1.8,1.4,0.4,3.3,0.8,4.9C958.8,643,958.6,643.1,958.3,643.3z"/>
	<path class="st1863" d="M986.3,627.1c-0.4-1.3-0.8-2.6-1.2-3.8c0.1-0.4,0.2-0.9,0.3-1.3c0.7-1.2-0.6-2.7,0.6-3.9
		c0.4-0.2,0.9-0.2,1.3-0.1c0.8,0.6,1.2,1.4,1.6,2.3c0.4,1.3,0.7,2.7,1.1,4c0.3,1.2,0.4,2.4,0.3,3.6c-0.1,0.5-0.2,1-0.4,1.4
		c-0.4,0.7-0.9,1.2-1.7,0.8C987.6,629.1,986.9,628.1,986.3,627.1z"/>
	<path class="st1864" d="M986.3,618.1c-0.3,1.3,0.4,2.8-0.9,3.8c-0.4-1.2-0.9-2.4-1.3-3.6c-0.6-1.6-1.1-3.2-0.9-5
		c1.1-2.6-0.2-5.8,1.9-8.3c1.6-0.1,1.9,1.2,2.2,2.3C987.4,611,988.5,614.7,986.3,618.1z"/>
	<path class="st1865" d="M976.1,611.1c0.1-0.3,0.1-0.6,0.1-1c0.3-0.3,0.6-0.6,1-0.7c0.4-0.1,0.8,0,1.3,0c1.1,0.1,2.1-0.2,3.2-0.3
		c1,0,1.9,0.1,2.6,0.8c0.2,0.5,0.2,0.9,0,1.4c-1.2,1.1-2,3.1-4.2,1.8c-0.9-0.8-1.9-0.8-2.9-0.1c-0.4-0.3-0.8-0.7-1.2-1
		C976.2,611.8,976.2,611.5,976.1,611.1z"/>
	<path class="st1866" d="M980.2,613.2c1.3-0.7,2.6-1.3,4-2c0.7,0.6,0.6,1.3,0.1,2c0,1.7,0,3.4-0.1,5.1
		C982.8,616.6,980.9,615.3,980.2,613.2z"/>
	<path class="st1867" d="M938.3,638.1c-0.4-0.6-0.7-1.3-1.1-1.9c0.6-3.4,3.6-0.5,5-1.9c2,0.3,4,0.5,6,0.8c1,0,2,0.1,3.1,0.1
		c1.2,1.8,3.8,0.1,5,1.9c0.3,1.6,0.2,3.1-0.5,4.5c-0.4,0.6-1,0.9-1.7,0.9c-1-0.2-1.4-1.1-1.8-1.9c-2.2-2.2-5-1-7.5-1.3
		c-1.8-0.2-3.6,0.1-5.4-0.4C939,638.8,938.6,638.5,938.3,638.1z"/>
	<path class="st1868" d="M955.3,641.2c0.3-1.4,0.6-2.7,1-4.1c0.3,0,0.6,0,0.9,0c0.7,3.3-1.3,6.9,1.3,10c0.2-0.1,0.5-0.1,0.7-0.1
		c0.1,0.2,0.2,0.5,0.3,0.7c-0.9,1.4-1.5,3.5-3.9,1.9C954.3,646.8,955.3,644,955.3,641.2z"/>
	<path class="st1869" d="M926.4,628.9c-0.8-1-1.8-1.8-3.2-2c-0.1-2.2,1.9-2.5,3-3.7c1.3,0.7,2.6,1.4,3.8,2.2
		c1.6,2.3,5.4,3.7,3.1,7.6c-0.3-0.2-0.7-0.5-1-0.7C931.1,629.6,928.7,629.4,926.4,628.9z"/>
	<path class="st1870" d="M933.2,633c0.6-3.2-2.3-5-3.1-7.6c0.3,0,0.7-0.1,1-0.1c3.3,3.5,6.8,6.6,11.1,8.9c-1,2.3-3.7,0.2-5,1.9
		C935.8,635.1,934.5,634,933.2,633z"/>
	<path class="st1871" d="M1006.1,738c-1.4,0.6-3.6,0.3-2.9,3c1,3.4,3.5,6.9,0.1,10.3c-1.4,1-2.7,1.5-4.1,0.1
		c-0.7-1.2-0.3-2.3-0.1-3.5c0.2-1.1,0.6-2.2,0.8-3.3c0.1-0.4,0.2-0.9,0.3-1.3c1.6-2.9-0.8-5.7-0.3-8.6c0.5-3.6-1.2-7.5,1.6-10.7
		c0.3,0,0.5,0,0.8,0c0.2,1.7-0.7,3.6,1,5c0,0,0,0,0,0c0.7,1,1.3,2,2,3.1C1006.1,734,1004.7,736.2,1006.1,738z"/>
	<path class="st1872" d="M1015.1,708c-3.3-0.3-5.1-2-6.1-5.2c-0.6-1.7-0.6-4-2.8-4.8c-2.3-3.6-3.8-7.6-5.5-11.4
		c-1.1-2.6-1.6-5.4,1.2-7.6c2.7-0.7,3.8,0.8,4.4,3.1c0.3,1.4,0,2.9,0.9,4.1c0.5,0.5,0.9,1,1.4,1.6c0.9,1.4,0.7,3.1,1.3,4.6
		c0.2,0.4,0.3,0.8,0.5,1.3c0.5,3,4.9,2.8,5.1,4.8c0.2,3.3,3.3,0.8,3.8,2.6C1019,704.1,1015.8,705.3,1015.1,708z"/>
	<path class="st1873" d="M1019.1,701c-1.1,0.8-3.6-1-3.6,2.4c0-3.3-0.5-5.5-4.3-5.8c-1.7-0.1-2.1-2.7-2-4.6c3.9-2.7,3.9-2.7-1.1-8
		c-1.3,0.6-0.8,2.3-1.9,3c-1.5-1.3-1.1-3-0.9-4.6c0.2-2.4-1-3.6-3.1-4.4c0-0.2-0.1-0.5-0.1-0.7c0.3-0.4,0.6-0.7,1.1-0.8
		c1.9-0.1,3.5,1.1,5.3,1.5c0.4,0.2,0.8,0.4,1.2,0.6c3.3,2.6,5.6,5.9,7.3,9.7c0.4,0.9,0.7,2,2.1,1.7c1.1-0.1,1.7-1.8,3-0.9
		C1022.3,694.2,1020.5,697.5,1019.1,701z"/>
	<path class="st1874" d="M1036.4,703.2c-0.2,1.3-1.1,1.9-2.3,1.9c0,0-0.4-0.2-0.4-0.2l-0.4,0.1c-1.6-1.4-3.4-2.6-3.8-5
		c-1.5-3.3-6.7-4.1-6-8.9c0.3-6.7,5.3-3.8,8.7-4.3c1.1,2.4,1.7,4.9,1.6,7.6c0,2,0.4,3.9,2,5.3
		C1036.3,700.7,1036.8,701.8,1036.4,703.2z"/>
	<path class="st1875" d="M1029.3,699.9c1.7,1.3,3.3,2.8,3.8,5c0,0.4,0,0.7,0,1.1c-1.9,0.3-2.1-2.3-4.3-2.6c1,5.4-1.3,10.4-0.7,15.5
		c-2.3,0.3-3-1.5-4-3c-2.9-4.2,1.1-7.3,1.8-10.9C1026.3,703.4,1025.9,700.1,1029.3,699.9z"/>
	<path class="st1876" d="M1032.1,687c-3.2,0.8-7.5-1-8.8,4c-0.3-1-0.6-2-0.9-3.1c-1.7-1.1-1.4-3.1-1.9-4.7c-0.1-0.8,0-1.5,0.6-2.1
		c2.1-1.3,2.3,0.8,2.9,1.8c0.6,0.9,0.6,2.1,2.1,2c2,0.3,4,0.6,6.1,0.9c0.3,0.3,0.3,0.5,0.2,0.8C1032.3,686.9,1032.2,687,1032.1,687z
		"/>
	<path class="st1552" d="M1024.2,716c1.7,0.5,2.8,1.8,4,3c-0.2,2-2.3,3.7-1,6C1022.5,723.2,1022.4,719.9,1024.2,716z"/>
	<path class="st1877" d="M1021.3,683c1.4,1.4,0.8,3.3,1,5c-0.1,0.8-0.2,1.5-0.3,2.3c-1,0.5-1.5,2-2.9,1.8c-0.3-0.4-0.5-0.8-0.6-1.3
		c1.2-2.5,0.6-5.4,1.5-8C1020.5,682.6,1020.9,682.6,1021.3,683z"/>
	<path class="st1871" d="M1003.2,700c2.7,8.1,3.5,16.2-1,24c0,0,0,0,0,0c-4.1-3.1-3.1-8-4.2-12.1c-0.6-2.5-0.2-5.3,0-7.9
		c0.1-0.5,0.2-1,0.4-1.5c0.7-1.5,1.8-2.6,3.4-2.9C1002.3,699.5,1002.8,699.7,1003.2,700z"/>
	<path class="st1878" d="M1003.2,700c-0.3,0-0.7,0-1,0c-1.6-0.6-2.2-2.1-2.1-3.6c0.1-5.7-3.7-10-5.4-15c-0.3-2-3-4.1,0.4-5.8
		c3.2-0.7,4.7,2.4,7.2,3.4c0,0,0,0,0,0c-1.7,3.1-1.1,5.9,0.4,8.9c1.6,3.2,4,6.1,3.6,10C1005.2,698.7,1004.2,699.4,1003.2,700z"/>
	<path class="st1871" d="M1003.2,729c0.2-1.2-0.5-2.6,1.2-3.8C1004.5,726.8,1004.4,728,1003.2,729C1003.2,729,1003.2,729,1003.2,729
		z"/>
	<path class="st1739" d="M1036.4,703.2c-0.5-1-1-2.1-1.4-3.1c-0.2-0.5-0.1-0.9,0.2-1.3c2.8-0.8,3.4,1.2,3.9,3.2
		c0.6,0,1.2,0.1,1.8,0.1C1039.7,703.7,1038,703,1036.4,703.2z"/>
	<path class="st1879" d="M1001.9,594c-0.2,0.1-0.4,0.2-0.6,0.3c-1.8-1.9-2.3-4.1-1.6-6.7c2.2-1.8,2.7,0.2,3.4,1.6
		c0,0,0.2,0.2,0.2,0.2C1002.2,590.7,1002.3,592.5,1001.9,594z"/>
	<path class="st1880" d="M1003.1,589.2c-0.9-0.7-1.7-1.3-2.6-2c-0.8-0.3-0.9-1-0.7-1.7c0.3-0.4,0.7-0.6,1.2-0.4c0.9,0.1,1.7,0,2.2-1
		c-0.6-1.1-0.7-1.9,0.9-1.9c0.3,1.3,0.7,2.7,1,4C1004.4,587.2,1003.8,588.2,1003.1,589.2z"/>
	<path class="st1881" d="M1004.1,582.1c-0.3,0.6-0.6,1.3-0.9,1.9c-1-0.6-1-1.5-0.6-2.4c0.3-0.7,0.8-1.4,1.7-0.5
		C1004.2,581.5,1004.1,581.8,1004.1,582.1z"/>
	<path class="st1882" d="M893.3,874.8c0-0.7,0-1.3,0-2c-0.3-2.6-2-5.1-0.4-7.8c2.4-1.1,3.4,1.1,4.9,2.2c4.9,7.8,12.3,13,19.6,18.4
		c0.2,0.5,0.1,0.9,0,1.4c-4.5,2-6.9-2.5-10.4-3.4c-0.4-0.1-0.8-0.3-1.2-0.5c-1.2-0.8-2.7-1.2-4.1-1.5c-0.4-0.1-0.8-0.3-1.2-0.5
		C897.8,879.3,895.1,877.6,893.3,874.8z"/>
	<path class="st1883" d="M875.3,865.8c0-1,0-2,0-3c4.2-1.7,5.5,2.2,7.8,4.1c1.6,1.3,2.8,3.2,4.2,4.9c4.5,3.3,9.1,6.3,14,9l0,0
		c1.5,1,3.5,0.8,5,2h0c0.8,0.6,1.8,1.1,0.8,2.4c-5.2,0.9-9.1-2.4-13.5-4c-3.1-1.1-5.9-3-8.4-5.2c-1.9-2.2-4.1-4-6.6-5.4
		c-0.6-0.5-1.1-1.1-1.9-1.3c-0.4-0.1-0.7-0.3-1-0.6C874.8,867.8,875,866.8,875.3,865.8z"/>
	<path class="st1884" d="M893.3,864.8c0,2.7,0,5.3,0,8c-2.2-3.1-3.7-6.8-7.8-8.2c-1.8-0.6-2.2-2.4-2.2-4.1c0-2.3-1.1-3.7-3-4.7
		c1.7-2.6,3.3,2.6,5,0c1.8-1.1,4.3-1.4,3.9,1C888.6,860.9,891.8,862.3,893.3,864.8z"/>
	<path class="st1885" d="M901.3,880.8c-5.7-1.4-10.4-4.4-14-9c2,1,4,2,6,3C896.6,876,898.6,878.8,901.3,880.8z"/>
	<path class="st1886" d="M893.3,864.8c-3.1-1.6-6.9-2.6-5.1-7.5c0.4-1,0.1-1.1-2.9-1.4c-1-2.2-2.7-4.3-2-7c1.8-1.5,3-0.4,4.2,0.9
		c0.3,0.4,0.6,0.7,0.9,1.1c3.8,3.8,7.6,7.6,9.9,12.6c0.2,1.6,0.2,3.1-1.1,4.3C896,866.8,894.6,865.8,893.3,864.8z"/>
	<path class="st1887" d="M882.3,843.9c1.7,0.5,3,2,5,1.9c4.7,1.1,8.3,4.4,12.7,6.3c1.4,1.1,4.1,1.3,3.3,4.2
		c-1.1,0.8-2.3,0.9-3.6,0.8c-3.6-2.9-8.8-2.8-12.3-6.1l0,0c-0.3-3.9-4.3-2.2-6-4c-1.3-1-2.7-2-4-3c-0.6-1-1.3-2-1.9-3
		c1.5-1.3,2.2,0.1,3.2,0.8C879.8,842.4,880.2,844.6,882.3,843.9z"/>
	<path class="st1888" d="M881.3,846.9c2.3,0.9,6.5-1.1,6,4c-1.1-1.1-2.1-2.4-4-2C882.7,848.2,880.9,848.6,881.3,846.9z"/>
	<path class="st1889" d="M882.3,843.9c-3.5,1.8-3.9-3.6-6.9-3c-1.7-2.6-4.4-4.7-5.1-7.9c0.3,0,0.6,0,1,0
		C874.6,836.9,878.3,840.5,882.3,843.9z"/>
	<path class="st1890" d="M875.3,865.8c0.3,1,0.7,2,1,2.9c-0.1,0.7-0.5,1.2-1.2,1.5c-0.4,0.1-0.9,0.1-1.3,0c-0.6-0.3-1-0.9-1.1-1.6
		c-0.1-1-1.4-1.9-0.2-2.9C873.5,865,874.4,865.4,875.3,865.8z"/>
	<path class="st1891" d="M872.6,865.8c0.3,1,0.5,2,0.8,3c-0.4,0.5-0.8,0.6-1.2,0.1c-1-0.8-0.9-1.9-1-3c0-0.4,0.1-0.8,0.1-1.1
		C871.8,865.1,872.2,865.5,872.6,865.8z"/>
	<path class="st1892" d="M872.2,868.9c0.4,0,0.8,0,1.2-0.1c0.3,0.4,0.6,0.8,0.9,1.2c0.8,1,0.9,2-0.1,3c-0.4-0.3-0.7-0.7-1.1-1
		C872.9,870.9,872.6,869.9,872.2,868.9z"/>
	<path class="st1893" d="M881.3,717c0-0.7,0-1.3,0-2c-1.2-1.4-1.3-2.8-0.5-4.4c2-1.4,2.2,0.5,2.5,1.5c0.6,2.1,1.3,4.1,2.5,5.9
		c0.9,1.4,2.2,2.5,3,4c0.3,0.7,0.4,1.4,0.4,2.1c-0.8,2.9,0.1,5.9,0.2,8.8c0,1.1,0.2,2.2,0.7,3.3c0.5,1.4,1.2,2.7,0.1,4.1
		c-1.8,0.4-2.9-0.5-3.8-1.9c-1.3-3-2.3-6.1-3.1-9.2C883.6,724.9,881.5,721.1,881.3,717z"/>
	<path class="st1894" d="M875.3,729c-0.1-1.6-3.4-1.6-2-4c2.7,0.1,5.5-0.5,8,1c0.2,0.1,0.4,0.3,0.6,0.4c1.4,3.3,3.2,6.5,4,10.1
		c-0.2,0.5-0.5,0.7-1,0.9C879.7,736.8,879,731.3,875.3,729z"/>
	<path class="st1895" d="M881.3,717c2.8,3.5,3.4,7.7,3,12c-2.2,0.3-1.8-2.2-3-3c0,0,0,0,0,0C881.6,723,875.9,720,881.3,717z"/>
	<path class="st1896" d="M881.4,710c0,1.7-0.1,3.3-0.1,5c-0.7,0.4-1.3,0.7-2,1.1c0.1-1.6-0.1-3-2.1-3.1c0-0.3,0.1-0.6,0.2-0.9
		c0.9-1.2,1.5-2.9,3.6-2.5C881.1,709.7,881.3,709.9,881.4,710z"/>
	<path class="st1897" d="M875.3,729c2.1,0.2,3.8,1.2,4.6,3.2c1,2.5,3.1,3.8,5.4,4.8c0.5,0.6,0.5,1.3,0.1,1.9
		c-3.9,0.6-8.4-0.1-10.6,4.6c-0.3,0.6-1.5,0.4-2.4,0.3c-1.9-1.2-4-0.9-6.1-0.8c-2.9-1.3-1.3-2.7,0-4c2.3-0.8,4.1-1.8,1-4
		c-1.2-2.2-0.2-4.3,1.8-3.1C873.3,734.4,874.1,731.6,875.3,729z"/>
	<path class="st1898" d="M858.4,605.3c0-2.1-0.1-4.1-0.1-6.2c0-2.1,0.1-4.2,0.1-6.2c1.1,1.2,0.8,2.8,1.2,4.1
		c0.3,0.4,0.5,0.9,0.6,1.4c0.1,1.8-0.7,3.6-0.4,5.4C859.8,604.8,859.3,605.3,858.4,605.3z"/>
	<path class="st1899" d="M864.3,596.1c-1-0.4-1.9-0.7-2.9-1.1c-0.8-0.4-0.5-1.4-1-2.1c-0.3-2.6-0.7-5.2-1-7.8c0.3,0,0.6,0,0.9,0
		c1.1,2.7,0.6,5.9,3,8.1C863.7,594.2,864,595.1,864.3,596.1z"/>
	<path class="st1900" d="M860.5,593c0.3,0.7,0.6,1.4,1,2.1c-0.5,2,0.7,4.3-1,6.1c-1.1-0.8-0.3-2.1-0.9-3.1c-0.1-0.3,0-0.6,0.1-1
		C860.7,595.9,860.2,594.3,860.5,593z"/>
	<path class="st1901" d="M1000.1,585.2c0.1,0.6,0.3,1.3,0.4,1.9c0.3,2.4,0.5,4.8,0.8,7.1c-0.1,0.9-0.2,1.8-0.3,2.7
		c-1.9,2.1-2.8,4.7-3.8,7.2c-1.2,1.1,0.2,3-1.3,4c-2,0.1-1.7-4.6-4.7-2.3c-0.9,0.3-1.8,0.4-2.8,0.1c-1.4-0.5-1.7-1.5-0.9-2.7
		c1.4-1.2,0.9-3.2,2-4.6c0.4-0.6,0.9-1,1.5-1.3c2.1-1,1.8-2.9,1.9-4.7c0.2-1.9-0.3-4,1.2-5.7c1.4-0.3,1.7,1.1,2.4,2
		c0.7-1.5,0.4-3,1.7-3.8c0.4,0,0.8,0,1.2-0.1C999.6,585.1,999.9,585.2,1000.1,585.2z"/>
	<path class="st1902" d="M991.1,605c3.6-3.8,4-1,4.3,2.2c1.4-0.7,0.4-2.6,1.9-3c-0.7,9.3-1.4,18.6-2,27.9c-0.4,0.3-0.8,0.6-1.2,0.9
		c-0.6,0.1-1.2,0.2-1.7,0.2c-1.2,0-1.4-0.7-1.5-1.7c0.3-6.2-0.2-12.3,0.3-18.5c0.2-0.7,0.5-1.4,1.1-1.9c-0.2-1.2,2.1,1.5,1.2-0.8
		c-0.4-1.1-0.3-2.3-0.9-3.3C992,606.4,991.3,605.8,991.1,605z"/>
	<path class="st1903" d="M995.1,469.4c-0.6-0.4-1.2-0.8-1.8-1.2c-1-1-2-2-3-3c-0.4-0.2-0.5-0.4-0.4-0.6c0.1-0.2,0.2-0.3,0.3-0.3
		c-0.2-0.9,0.8-1.9,0-2.8c-0.9-1-0.8-1.4,0.6-2.1c-1.4-2.4-4.5,0.1-6.2-2.2c2.5-1.4,6,1.7,7.7-1.8c1.4-0.6,2.3,0.1,3.2,1.1
		c0.5,4.1-0.6,8.3,0.2,12.5C995.4,469.1,995.3,469.3,995.1,469.4z"/>
	<path class="st1701" d="M992.2,452.3c-1.1,0.3-2.3,1.6-3-0.6c-0.6-2.1-1.4-4.2-2-6.3c0,0,0,0,0,0c0.9-0.8,2-0.6,2.5,0.3
		c1.2,2.4,3.6,2.9,5.7,3.9C995.9,452.2,994.1,452.4,992.2,452.3z"/>
	<path class="st1904" d="M992.2,452.3c0.7-1.3,3.2-0.8,3-3c0.2-0.1,0.4-0.3,0.6-0.4c0.7-0.2,1.4,0,1.9,0.6c1,2.6-0.4,4.5-1.8,6.5
		c-0.2,0.1-0.4,0.2-0.6,0.4c-1-0.3-2-0.7-3-1C992.2,454.3,992.2,453.3,992.2,452.3z"/>
	<path class="st1905" d="M990.2,464.3c0,0.3,0,0.6,0,1c-0.8,0.1-1.6,0.2-2,1c-0.8-0.2-2.3,0.4-2.1-0.8c0.2-1.5,2-1.1,3.2-1.2
		C989.5,464.3,989.9,464.3,990.2,464.3z"/>
	<path class="st1906" d="M995.2,449.3c-4.4,2.2-5-3.2-8-4c1.4-3.6,6.2-2.8,7.9-6c0.8,2.4,4.2,1.6,5.1,3.9
		C1000,446.5,996.8,447.2,995.2,449.3L995.2,449.3z"/>
	<path class="st1907" d="M993.3,480.3c0.2,0.1,0.5,0.1,0.8,0.1c1,0.9,0.8,1.9,0.4,3l-0.4,0c-0.3,0.6-0.5,1.2-1.3,1.2
		c-2-1-3.1-3-5.1-4c-1.2-1.8-3.6-1.9-4.7-3.7c1.6-3.4,3.8-3.1,6.3-1C990.7,477.2,992.1,478.7,993.3,480.3z"/>
	<path class="st1908" d="M993.3,480.3c-1.3-1.1-2.7-2.3-4-3.4c-2.2-2-4.2-2.4-6,0.4c0.4,2.1,0.6,4.1-0.9,5.9c0,0-0.1,0.2-0.1,0.2
		c-0.3-0.4-0.6-0.7-1-1.1c-0.8-1.7-0.7-3.3,0-5c0.9-2.3,2.5-3.8,5-4c3.3,0.4,4.3,3.8,7,5.1C993.2,479,993.2,479.6,993.3,480.3z"/>
	<path class="st1909" d="M994.4,483.3c-0.1-1-0.3-2-0.4-3c1.4,1.4,3.1,2.7,1.3,4.9C995,484.6,994.7,484,994.4,483.3z"/>
	<path class="st1784" d="M981.2,477.3c0,1.7,0,3.3,0,5c-1.5-1-0.9-2.6-1-4C980.5,478,980.9,477.6,981.2,477.3z"/>
	<path class="st1910" d="M1002.2,463.3c-0.9,4-3,8-1.3,12.3c0.6,1.6,0,2.8-1.5,3.7c-0.8-1.9-1.5-3.9-2.3-5.8
		c-0.4-1.4-0.9-2.7-1.4-4.1c2.1-2.2-0.6-4.4-0.1-6.6c0.4-1.8,0.2-3.7,1-5.4c0.5-0.9,1.2-1.4,2.3-1.6
		C1001.8,457.5,1000.3,461.2,1002.2,463.3z"/>
	<path class="st1911" d="M996.2,469.3c0.7,1.3,1.4,2.6,0.9,4.1c-0.4-0.4-0.7-0.7-1.1-1.1c-0.3-1-0.6-2-0.8-3c0,0,0.1-0.3,0.1-0.3
		C995.6,468.8,995.9,468.9,996.2,469.3z"/>
	<path class="st1912" d="M1002.2,463.3c-3.3-1.3-2.9-4.3-3-7c-0.6-0.5-0.6-1.2-0.3-1.8c2-0.8,4.1-1.4,6.3-1.1
		C1004.9,456.9,1004.7,460.5,1002.2,463.3z"/>
	<path class="st1913" d="M1005.2,453.3c-1.8,1.3-3.6,2.8-6,1c-0.7-1.8-0.8-3.5,0-5.3c1.4-1,3.1-0.9,4.7-1.2c0.5,0,0.9,0.2,1.3,0.5
		C1006.3,450,1006.3,451.7,1005.2,453.3z"/>
	<path class="st1914" d="M1005.2,448.3c-0.3,0-0.7,0-1,0c-1.7,0.6-1.9-0.9-2.4-1.8c-0.2-0.7-0.1-1.4,0.5-1.9
		c1.7,1.2,3.5,2.4,5.2,3.5C1006.7,448.2,1006,448.3,1005.2,448.3z"/>
	<path class="st1915" d="M996.2,425.3c3.1,2,6,4.2,9.2,6c1.6,1,3.1,1.7,2.8,4c1,1.8,3.2,2.6,3.9,4.6c0,2.9,1.1,5.6,1.1,8.4
		c-5.6-3.7-13.2-5.1-16-12.3C995.9,432.7,994.5,429.1,996.2,425.3z"/>
	<path class="st1916" d="M1013.2,448.3c-1.7-2.4-2.4-5.1-2-8c2.6-1.8,3.8,0,4.9,2c1,1.8,2.3,2.7,4.4,2.2c0.2,1.3,0.2,2.6,0,3.8
		c0.4-1.3-0.1-2.6,0.4-3.9c1.2-0.7,1.8,0,2.3,0.9c-0.3,2.7,2.7,5.7-1,8C1019.2,451.7,1016.2,450,1013.2,448.3z"/>
	<path class="st1917" d="M1002.2,444.6c0,0.6,0,1.1,0,1.7c-0.8,1.7-2.9,1.9-3.9,3.3c-0.4,0.1-0.8,0.1-1.2-0.3c-0.7,0-1.3,0-2,0
		c0.2-3.2,3.4-4,5-6.1C1000.9,443.7,1001.6,444.2,1002.2,444.6z"/>
	<path class="st1918" d="M985,487.3c0.1-0.3,0.1-0.6,0.1-1c1.3-1.2,2.6-0.5,3.8,0.2c1,1.1,3.1,0.1,3.7,1.9c0.2,0.7,0.1,1.3-0.4,1.9
		C988.2,493.2,987,489.2,985,487.3z"/>
	<path class="st1919" d="M992.2,490.3c0-0.7,0-1.4,0-2.1c-0.6-1.5-0.2-2.8,1-3.9c0.3-0.3,0.6-0.6,0.9-0.9
		C994.3,485.9,995.2,488.6,992.2,490.3z"/>
	<path class="st1920" d="M924.2,631.2c0.3,0.3,0.7,0.6,1,0.9c3.8,2,5.7,5.7,8.4,8.8c1.4,1.6,1.5,3.6-0.2,5.3c-1.8,0.5-1.9-1.9-3.3-2
		c-1.3-0.8-2.2-1.8-2.9-3.2C928.7,637,924.1,634.8,924.2,631.2z"/>
	<path class="st1921" d="M930.1,644.2c1.6-0.4,2.1,1.3,3.2,1.9c2.5,0.1,2.4,2.3,3,3.8c1,2.7,1.1,5.8,2.2,8.5
		c0.2,0.5,0.2,0.9,0.1,1.4c-0.7,1.2-1.9,1.4-3,1.7c-0.5,0-0.9-0.1-1.3-0.4c-0.3-0.3-0.7-0.7-1-1c0-0.7,0-1.3,0-2
		C936,652.6,932.7,648.5,930.1,644.2z"/>
	<path class="st1922" d="M888.3,895.8c0.1-2-1.5-2.7-2.9-3.5c-1.7-1.1-2.4-2.5-1.1-4.5c2.6-1,4.5,0.5,6.4,1.8
		c4.3,3,9.1,4.9,14.2,5.9c1.2,0.4,2.8,0.5,2.5,2.4c-3,2.5-7-0.2-10,1.8l0,0C894.1,898.9,891.2,897.5,888.3,895.8L888.3,895.8z"/>
	<path class="st1923" d="M904.3,896.8c-7.5-1.1-13.2-6.2-20-9c-1.2-1.5-3.3-2-4-4c-0.3-0.4-0.3-0.8,0.1-1.2
		c4.3-0.5,6.8,3.2,10.3,4.5c4.4,3.4,10.2,3.9,14.6,7.4C906,895.7,905.1,896.2,904.3,896.8z"/>
	<path class="st1924" d="M880.4,882.7c0,0.4,0,0.7-0.1,1.1c-2.2-2.2-5.6-3.3-5.9-7c0.3,0,0.6,0.1,0.9,0.1c2.1,0.4,3.1,2.2,4.5,3.5
		C880.3,881.1,880.8,881.8,880.4,882.7z"/>
	<path class="st1925" d="M875.4,869.9c0.3-0.4,0.6-0.7,1-1.1c0,0,0,0,0,0c1.2,0.7,1.9,1.9,2.9,2.8c1.5,5.1,6.9,7,9.4,11.4
		c-0.1,0.8-0.6,1.3-1.3,1.5c-3.2,0-5-2.4-7.3-4c-1.1-1.7-3.5-2.2-3.8-4.6c0-0.3,0-0.7,0.1-1C875,873.4,874.8,871.7,875.4,869.9z"/>
	<path class="st1926" d="M876.2,875.9c1.4,1.3,3.3,2.1,4.1,3.9c-0.1,0.6-0.5,0.9-1,1.1c-1.6-1-3-2.3-4-4
		C875.6,876.5,875.9,876.2,876.2,875.9z"/>
	<path class="st1891" d="M875.4,869.9c0.3,1.7,0.6,3.3,0.9,5c-1.1-0.2-1.9-0.7-2-1.9c0-1,0-2,0.1-3C874.7,870,875,869.9,875.4,869.9
		z"/>
	<path class="st1927" d="M897.3,899.8c2.8-3.1,6.6-1.6,9.9-2c0.7,0,1.2,0.4,1.7,0.8c0.7,0.9,2.1,1.6,1.3,3.2c-1.1,1.4-2.6,0.8-4,1
		c-1-0.3-2-0.7-3-1C901.7,899.9,899.5,899.7,897.3,899.8z"/>
	<path class="st1724" d="M888.3,895.8c3.4,0.5,6.5,1.6,9,4C894.7,900.4,889.1,898,888.3,895.8z"/>
	<path class="st1928" d="M910.3,901.8c-0.7-1-1.3-2-2-3c3.5-1.9,6.6,1.7,10.1,0.7c1.3,0.3,2.9,0.2,2.9,2.2c-1.3,0-2.7,0-4,0.1
		c-1.7,0.3-3.3,0.6-5,1C911.8,902,911.1,901.8,910.3,901.8z"/>
	<path class="st1929" d="M1020.1,444.3c-2.1,1.3-4.3,2.8-5-1.3c-0.4-2.4-2-2.7-4-2.7c-0.7-1.8-3.1-2.7-3-5c2.6,1,5.3,2,7.9,3
		c2,0.7,3.5,2.3,5.7,2.6c2,1,4.6-0.5,6.4,1.4c-1.3,1.7-4.3,0.4-5,3c-0.6-0.3-1.3-0.7-1.9-1C1020.8,444.9,1020.5,444.9,1020.1,444.3z
		"/>
	<path class="st1930" d="M1021.1,441.4c-2.4,0.2-3.9-1-5-3c0-0.3,0-0.7,0.1-1c0.6,0.1,1.2,0.1,1.8,0.2c0.4,0.6,0.8,1.2,1.2,1.7
		c0.5,0.9,2,0.4,2.2,1.6C1021.3,441,1021.2,441.2,1021.1,441.4z"/>
	<path class="st1931" d="M987,653.2c0.4-0.1,0.8-0.1,1.2-0.2c2.5,2,5.9,2.5,8,5c0.3,0.7,0.6,1.4,0.9,2.2c-1.8,1.1-3-0.4-4.3-1.1
		c-1.3-0.9-2.6-1.8-3.9-2.6C987.8,655.7,986.7,654.8,987,653.2z"/>
	<path class="st1734" d="M1007.2,655.1c-0.2,2-1.4,2.3-3.3,1.6C1004.4,654.9,1005.9,655.2,1007.2,655.1L1007.2,655.1z"/>
	<path class="st1932" d="M989.3,655.8c1.3,0.8,2.6,1.7,3.9,2.5c1.3,1.4,2.4,2.9,3.3,4.6c0.9,0,1.7,0,2.6,0.1c1-0.5,2-0.4,3-0.2
		c0.9,2.4-1.3,2.2-2.4,3c-1.4,0.5-2.7,1.2-4.2,0.8c-2.3-1.6-3.8-3.8-5.6-5.9C989.9,659,988.2,657.6,989.3,655.8z"/>
	<path class="st1933" d="M996.5,662.9c-1.7-1.1-2.9-2.6-3.3-4.6c1.3,0.6,2.6,1.3,3.9,1.9C996.9,661.1,996.7,662,996.5,662.9z"/>
	<path class="st1934" d="M1017,664.2c0,0.3,0.1,0.6,0.3,0.9c-0.3,0-0.7,0.1-1,0.1c-2,1.2-3.6,0.2-5.1-1.1c-0.2-0.5-0.1-0.9,0.3-1.2
		C1013.4,662.6,1015.1,663.8,1017,664.2z"/>
	<path class="st1933" d="M1002.1,663.2c-1-0.1-2.1-0.1-3.1-0.2c1.9-1.2,4.1-1,6.2-0.8c0.3,0.5,0.2,1-0.3,1.3
		C1003.9,664,1003,664,1002.1,663.2z"/>
	<path class="st1934" d="M1005.1,663c0-0.3,0.1-0.6,0.1-0.8c2.1-0.4,4.2-0.7,6.2,0.7c-0.1,0.1-0.2,0.3-0.3,0.4
		C1009,664.7,1007,664.8,1005.1,663z"/>
	<path class="st1935" d="M1005.1,663c2,0.1,4,0.1,6,0.2c1.7,0.6,3.4,1.3,5.1,1.9c1.9,2.8,4.5,5.2,5.4,8.6c0.4,1.4,1.2,3.1,0.2,4.2
		c-1.2,1.3-2.5-0.8-4-0.7c-1.9,0.6-2.8-0.1-2.6-2.1c2.2-1.4,3.5-2.8,0.7-4.9c-1.8-1.3-2.8-3.6-5.3-4c-2.3-1-4.7-0.2-7,0.1
		c-1.6,0-3.2,0.1-4.4-1.3c1-0.6,2-1.2,3-1.9C1003.1,663.1,1004.1,663.1,1005.1,663z"/>
	<path class="st1936" d="M1015.3,675c0.5,1.1,1.4,1.8,2.5,2.2c0.8,1.6,1.8,2.9,3.3,3.9c0.1,0.6,0.2,1.2,0.3,1.8
		c-0.4,0-0.7,0.1-1.1,0.1c-4.3-2.3-8-5.3-10.4-9.6c0.2-0.4,0.5-0.8,1-1c1.9-0.4,3.2,0.5,4.3,1.9
		C1015.2,674.6,1015.3,674.8,1015.3,675z"/>
	<path class="st1937" d="M1035.2,699c-0.1,0.4-0.2,0.7-0.3,1.1c-2.9-3.9-1.8-8.7-2.8-13c0-0.4,0-0.7,0-1.1c0.4-0.2,0.8-0.4,1.1-0.6
		c0.6,4.2,1.3,8.5,1.9,12.7c0.3,0.2,0.4,0.4,0.3,0.6C1035.4,698.9,1035.3,699,1035.2,699z"/>
	<path class="st1938" d="M1035.2,699c-0.1-0.3-0.2-0.6-0.1-1c1.4-1.1,0.8-2.6,1-4c5.9-2.3,0.7-4.7,0-7c0-0.3,0-0.7,0-1
		c6.8-1.8,7.1-1.6,6.9,5.2c-0.1,4-1.1,7.7-4,10.7C1037.7,701.1,1037.4,698.9,1035.2,699z"/>
	<path class="st1939" d="M1036.1,687c1.5,0,2.5,0.8,3,2.2c0.6,1.5,2.3,3,0.9,4.5c-0.7,0.8-2.6,1-4,0.4
		C1036.7,691.7,1033.6,689.4,1036.1,687z"/>
	<path class="st1940" d="M989.3,655.8c-0.2,1.6,3,2.3,1,4.3c-0.7,0.4-1.2,0-1.7-0.4c-0.9-1.1-1.9-2.1-3.2-2.7
		c-0.4-0.2-0.7-0.3-1.1-0.5c-1.5-0.6-2,0.7-2.8,1.5c-0.4,0.2-0.8,0.3-1.2,0.1c-0.4-0.3-0.6-0.6-0.9-1c-0.5-1.3-1.1-2.4-2.6-2.7
		c-0.8-0.2-1.6-0.4-2.4-0.7c-1.2-0.5-2.2-1.1-3.2-1.8c-0.9-0.6-1.9-0.9-2.8-1.4c-0.3-0.3-0.6-0.7-0.7-1.1c0-0.5,0.1-0.9,0.3-1.3
		c0.8-0.8,1.7-0.5,2.5-0.2c5.2,1.7,10.5,3.4,15.7,5c0.3,0.1,0.5,0.2,0.8,0.2C987.8,654.1,988.5,654.9,989.3,655.8z"/>
	<path class="st1742" d="M981.2,649.2c-2.7-0.3-5-1.4-7-3.1C977.7,644.4,978.9,648.1,981.2,649.2z"/>
	<path class="st1860" d="M991.3,632c0.3,0.4,0.6,0.8,0.8,1.2c-0.7,0.6-1.3,1.3-2,1.9c-0.4-0.6-0.8-1.2-1.2-1.8c0,0,0.2-0.2,0.2-0.2
		c0.6-0.6,0.2-1.6,0.9-2.2c0.4-0.2,0.8-0.2,1.2-0.1c0.2,0.2,0.3,0.5,0.3,0.7C991.5,631.9,991.4,632,991.3,632z"/>
	<path class="st1941" d="M856.4,611.2c0.3-0.3,0.6-0.7,1-1c0.9-2.2,2.1-4.1,4.9-4.2c0.3,1.7,1.4,3.4,0.1,5.1c-1,0.3-2,0.7-3,1
		c-1,0.8-2,0.8-3,0C856.4,611.8,856.4,611.5,856.4,611.2z"/>
	<path class="st1942" d="M862.3,606c-1.6,1.4-3.3,2.8-4.9,4.2c-0.4-0.4-0.7-0.7-1.1-1.1c0.1-0.2,0.1-0.5,0.1-0.8c1.2-0.7,2-1.6,2-3
		c0.3-0.4,0.6-0.8,1-1.2c0.2-0.5,0.5-0.5,0.8-0.1c0.8,0.1,1.6,0.1,2.4,0.2C862.5,604.9,862.4,605.4,862.3,606z"/>
	<path class="st1943" d="M860.2,604.1c-0.3,0-0.6,0-0.8,0.1c0.1-2,0.1-4.1,0.2-6.2c1,0.8,0.7,2,0.9,3.1
		C860.4,602.1,860.3,603.1,860.2,604.1z"/>
	<path class="st1930" d="M1028.2,442.4c-2.3-0.4-4.9,0.9-7.1-1c0,0,0.1-0.1,0.1-0.1c1.5-1,3.4,0.3,4.9-0.9c0,0,0,0,0,0
		c1.6,0,3.3,0,4.9-0.1c0,0.3,0,0.6,0,0.9C1030.1,441.6,1029.1,442,1028.2,442.4z"/>
	<path class="st1748" d="M1026.2,440.4c-1.4,1.6-3.3,0.8-4.9,0.9c-0.7-0.7-1.3-1.3-2-2c1,0.1,1.9,0.1,2.9,0.2
		C1023.2,440.8,1024.8,440.2,1026.2,440.4z"/>
	<path class="st1944" d="M921.3,901.8c-0.7-1.1-1.9-0.9-3-1c-0.8-0.6-1.9-1.1-0.7-2.4c3.2-0.6,6.5,0.7,9.6-0.5
		c1.3-0.3,2.6-0.3,3.8,0.2c1,0.6,1.7,1.3,1.2,2.6c-1.7,0-3.3,0-5,0.1C925.2,900.7,923.1,900.6,921.3,901.8z"/>
	<path class="st1945" d="M932.2,900.8c-0.3-0.7-0.7-1.3-1-2c2.5-1.8,5.3-0.7,8-0.8c1.4,0.5,3.1,0.4,4.1,1.9c-2.5,1.6-5.4,0.5-8,0.9
		C934.3,900.9,933.3,900.8,932.2,900.8z"/>
	<path class="st1946" d="M957.2,898.8c0.1,0.7-2.6,1.6,0.1,1.9c1.6,0.2,3.2,0,4.8,0c-0.5,1.8-2,0.6-3,1c-3.3,0-6.7,0-10,0
		c0.4-0.6,0.7-1.3,1.1-1.9c0.8-1.2,2-1.6,3.3-1.9C954.9,897.7,956.2,897.7,957.2,898.8z"/>
	<path class="st1947" d="M953.4,899c-1,0.3-2.1,0.6-3.1,0.9c-2.3,0-4.6,0-6.9,0c-1.3-0.4-3,0.3-4-1.1c0.2-0.2,0.3-0.4,0.5-0.6
		c2.3-1.3,5-0.4,7.4-1.3c1.3-0.3,2.6-0.3,3.9,0.1C952.1,897.4,953,897.9,953.4,899z"/>
	<path class="st1948" d="M913.3,665.1c1.4,2.2,0.8,4.7,1,7.1c0.2,0.5,0.1,0.9-0.3,1.2c-0.5,0.1-0.9,0.1-1.4,0
		c-1.4-1.3-1.8-0.3-2.2,0.9c-1.2,3.5-2,7-2.3,10.7c-0.6,8.7-3.4,17.2-1.5,26c0.6,2.8,1.3,5,3.9,6.3c-0.3,4.2-3.6,4-6.5,4.2
		c-1.5-0.5-2.7-1.5-3-3c-1.4-8-3.9-16-2.5-24.3c0.3-1.9,1.3-3.7,2.8-5.1c2.2-3.7,0-8.1,2-11.9c0.4-0.7,0.7-1.4,1-2.1
		c4.5,0,3.1-5,5.6-6.9C911,667.3,911.8,665.8,913.3,665.1z"/>
	<path class="st1949" d="M901.3,689c-4.5,8.8-0.2,17.6,0.1,26.4c0.1,2.1,1.1,4.1,2.9,5.5c-2.4,1.7-3.9,0.2-5.3-1.4
		c-0.7-1.8-2.2-3.4-1.7-5.6c-1.4-13.7-0.1-27.4,2-40.9c0.7,0,1.3,0,2,0C901.3,678.4,901.3,683.7,901.3,689z"/>
	<path class="st1950" d="M938.2,659.1c-2.1-0.2-1.8-1.8-2-3.3c-0.6-3.3-0.9-6.8-2.9-9.7c0.8-2.4,0.1-4.3-1.6-6.1
		c-2.3-2.5-4.3-5.2-6.4-7.9c2.5,0.1,4.3,1.1,6.1,3.1c1.5,1.7,4.6,2,6.9,2.8c0.3,0,0.6,0.1,0.8,0c0.6,0.4,1.2,0.6,1.6,1.3
		c0.7,2.2,0.7,4.4,0.8,6.6c0,1.5-0.1,3,0.5,4.4c0.3,0.6,0.6,1.2,0.8,1.8c0.1,0.4,0.2,0.9,0.2,1.3c-0.3,1.8-0.8,3.6-2.1,5
		C940,659,939.3,659.7,938.2,659.1z"/>
	<path class="st1951" d="M871.4,828.9c0-1.3,0-2.6,0-4c2-0.4,2.4,1.6,3.6,2.5c1.5,2.1,2.6,4.5,4.9,5.9c2.7,3.2,6.4,5.4,8.9,8.8
		c0.2,2.1-1,2-2.5,1.7C879.9,840.3,875.4,834.8,871.4,828.9z"/>
	<path class="st1952" d="M886.3,843.9c0.3-1.1,1.9,0.1,2.1-1.1c3.3-0.6,5.2,2.8,8.3,3c1.2,0.3,2.3,0.8,2.9,2c0.1,0.5,0,0.9-0.2,1.4
		c-0.2,0.2-0.6,0.3-0.8,0.5c-1,0.7,0.3,0.2,0.2,0.4c1.6,0.5,3.1,1,2.5,3.2c-0.7,0.2-1.4,0.3-2-0.3c-4.2-2-8.6-3.8-12.1-7.1
		C886.9,845.1,886.6,844.5,886.3,843.9z"/>
	<path class="st1953" d="M874.3,828c-1-1-1.9-2-2.9-3c-0.3-2.4-2.3-4.3-2.1-6.9c0.1-0.6,0.1-1.8,0.2-1.8c4.7-0.6,3.8-4.2,3.8-7.2
		c2.6-0.1,3,1.9,3.6,3.6C876.8,817.8,875.8,822.9,874.3,828z"/>
	<path class="st1954" d="M860.3,613.2c1.3,2.9,2.7,5.7,3.3,9c0.8,5.3,3.5,9.7,7.8,13.2c0.8,0.7,1.3,1.8,2,2.7
		c-2.8,1.3-5.1,0.1-7.5-1.2c-5.2-4.5-6.8-10.8-8.5-16.9C856.6,617.3,857.8,614.8,860.3,613.2z"/>
	<path class="st1955" d="M860.3,613.2c-4.6,9.4,1.6,15.9,6,22.9c-3.2,2.1-4.1-1.7-6.2-2.4c-4.1-5.5-5-12.2-6.7-18.6
		c1.5-0.5,0.9-3.1,3-3c1,0,2,0,3,0C859.7,612.5,860,612.8,860.3,613.2z"/>
	<path class="st1956" d="M860.3,633.1c2.6-0.3,3.1,3.8,6,3c2.2,1.2,4.4,2.4,7,2c1.1,0.1,2.2,0.1,3.2,0.2c-0.8,1-2,0.9-3.2,1
		c-2.3-0.1-4.6-0.2-7-0.3c-2.8-2.6-7.1-1.5-10.1-3.8c1.3,0,3.1,0.4,2-2C859,632.5,859.7,632.6,860.3,633.1z"/>
	<path class="st1957" d="M880.3,643.2c-0.4,1.6-2.3,1.7-3,2.8c-1.2,1.1-2.5,1.3-3.9,0.5c-0.6-0.7-0.7-1.4-0.4-2.3
		c1.6-1.3,3.7,0.1,5.4-1C879,643.2,879.7,643.2,880.3,643.2z"/>
	<path class="st1958" d="M878.4,643.2c-1.5,1.3-3.4,0.7-5.1,1c-1.3,0.9-2.6,1.1-3.8-0.2c-0.1-0.3-0.2-0.5-0.3-0.7
		c1.7-0.1,3.4-0.1,5.1-0.2C875.7,643.1,877,643.2,878.4,643.2z"/>
	<path class="st1959" d="M915.1,671.9c0.1-2.3,0.2-4.6,1.2-6.8c2.1,0,1.9,1.7,2.3,3c0.1,1.7,0.1,3.4,0.1,5.1
		c-0.1,2.8,0.2,5.6-0.3,8.3c-0.4,1.5-0.8,3-0.7,4.6c0.2,3.4-0.7,6.9,0.7,10.3c0.5,0.9,1.4,1.6,0.8,2.8c-1.1,1.4-2.6,1.9-4.3,1.8
		c-0.9-0.1-1.6-0.4-2.1-1.2c-1.1-2.2-0.8-4.4-0.5-6.7c1.1-3.2,0.5-6.6,0.6-9.9c0-1.4,0.1-2.8,0.4-4.2
		C914,676.7,914,674.1,915.1,671.9z"/>
	<path class="st1960" d="M926.3,664c2,0.1,4.1,0.1,6.1,0.2c3.6,0.4,7.2,0.9,10.4,2.9c0.1,1-0.1,1.8-1.1,2.3c-2-1.2-1,1.8-2.2,1.6
		c-3.3,1.2-6.7,1.9-9.8-0.5c-0.2-0.4-0.2-0.9-0.2-1.3c0.1-0.6,0.4-1.1,0.6-1.7c-0.5,0.4-1,0.7-1.6,0.8c-3.2,0-6.2-0.9-9.2-2.1
		c-1-0.6-2-1.3-3-2C919.7,661.8,923,665.4,926.3,664z"/>
	<path class="st1961" d="M916.4,664.1c1.1,0,2.2,0,3,1c0.5,2.2,2.3,4.5-0.8,6.3c-1.3-0.7-0.3-2.3-1.2-3.1c-0.2-1.1,0.1-2.4-1.1-3.1
		C916.3,664.7,916.3,664.4,916.4,664.1z"/>
	<path class="st1962" d="M915.1,671.9c0.1,2.4,0.8,5-0.8,7.2c-1.8-2-1.5-4-0.1-6c0.1-0.3,0.2-0.6,0.1-0.9
		C914.6,672.1,914.8,672,915.1,671.9z"/>
	<path class="st1963" d="M942.4,667.2c-3.5-0.2-6.9-1-9.9-3c0.6-1,1.2-2.1,1.8-3.1c0.3,0,0.7,0,1,0c2.9,0.4,5.6,1.6,8.2,2.8
		c0.6,0.9,0.7,1.9,0.2,2.9C943.4,667.3,942.9,667.4,942.4,667.2z"/>
	<path class="st1964" d="M876.3,812.9c-0.8-1.5-1.1-3.3-3-4c0.4-0.4,0.7-0.8,1.1-1.3c0.3,0.1,0.6,0.1,1,0.2c1.8,2.9,4.2,5.1,7,7
		c1,1,0.3,2.5,0.9,3.6c0.7,2.3,2,4.1,3.5,5.8c1,1.1,2.7,2.2,1.2,4.1c-3.9,1.5-5.4-1.4-7.1-4c-1.2-3.3-1.5-6.9-4-9.7
		C876.4,814.2,875.9,813.7,876.3,812.9z"/>
	<path class="st1801" d="M881.4,706c-0.8,0-1.6-0.2-2.1-1c-1.9-2.9-5.2-3.7-8.1-1.6c-2,1.4-3.8,3.2-5.8,4.6c-0.7,0.3-1.3,0.7-2,1
		c0.3-1,0.7-2,1-3c3.9-1.3,2.2-6,5-8c2.4,1.6,3.6-1.1,4-2c2.2-4.4,4.8-9,5.6-13.8c0.6-3.5,1.6-6.8,2.3-10.2c0.5,0.4-0.8,3,1.2,1.4
		c1.5-1.2,3.3-1.3,4.7-2.4c0.2,1.1,0.1,2.2-0.7,3.2c-1.9,2.7-1.6,5.7-1.3,8.7c-0.3,3-0.6,5.9-0.9,8.9c-1.1,4.1,0.6,8.5-1.1,12.5
		C882.8,705.2,882.2,705.8,881.4,706z"/>
	<path class="st1965" d="M891.3,689c0.2,4-0.9,8.1,1,12c-0.4,1.1-1.1,1.9-1.9,2.6c-0.8,0.6-1.6,0.9-2.6,0.5c-2.2-1.9-3.9-4-2.4-7.1
		c0.6-2.1,1-4.3,1.7-6.3C887.8,688.9,889.2,688,891.3,689z"/>
	<path class="st1966" d="M885.3,697c0.4,2.6,1.4,4.9,3,7c0.8,1,0.4,1.7-0.5,2.3c-0.7,0.2-1.6,0.2-2.2,0.9c-0.7,1-1.3,2.8-2.9,2
		c-1.9-1-1-2.7-0.5-4.3c0.3-4.4,0.7-8.8,2-13C884.7,693.6,883.6,695.6,885.3,697z"/>
	<path class="st1776" d="M885.2,683c-1.7-2.7-0.7-5.7-0.7-8.5c0-1.4,1.2-1.2,2-0.2C886,677.2,885.6,680.1,885.2,683z"/>
	<path class="st1474" d="M890.3,703c0-1.3,0.7-2,2-2c0.7,0,1.3,0,2,0c0.1,4.5-0.8,9.3,3,13c0.9,1.6,2.4,2.9,2,5
		c-0.4,0.3-0.9,0.4-1.4,0.2C892.7,715,889.5,710,890.3,703z"/>
	<path class="st1967" d="M875.3,671.1c-1.3-5.1,2.3-9.3,2.7-14.1c0.1-1.7,1.2-3.3,1.5-5.2c0.2-1.4,1.1-3.8,2.8-0.7
		C879.7,657.7,880.2,665.3,875.3,671.1z"/>
	<path class="st1797" d="M870.5,689.1c-2.1,5-4.5,9.8-8,13.9c-1,0.1-2.7,1.1-1.8-1.2c0.5-1.3,2.6-1.5,3.1-3.8
		c-6.9-0.2-4.5-6.4-6.4-10c-2.3-3.5-2-7.4-2-11.4c0.1-7.4,0-14.8,0-22.3c0-2.3,0-4.6,2-6.3c0,0,0,0,0,0c1.3,1.3,1.3,3,1.3,4.6
		c0.2,8.8-0.9,17.8,2,26.4c0.5,1.6-0.2,4,1.9,4.7c2.6,0.9,3-1.9,4.3-3.2c1.7-1.3,1.4-4.6,4.4-4.6
		C870.7,680.4,869.6,684.7,870.5,689.1z"/>
	<path class="st1968" d="M871.3,676.1c-1.9,1.1-1.4,3.7-3,5c-2.8-3.4-0.3-7-0.3-10.5c0.6-7.9,2.7-15.4,5.2-22.8
		c0.8-0.3,1.5-0.2,2.2,0.4c2.1,1.7,2.1,3.5,1.4,6C874.8,661.4,871.9,668.5,871.3,676.1z"/>
	<path class="st1969" d="M875.4,648.2c-0.7,0-1.4,0-2,0c-1.1,0.7-2.3,1.1-3.7,1c-1.3-0.2-2.5-0.5-3.8-0.7c-1.8-0.2-3.7-0.3-4.6-2.2
		l0.1-0.1c-0.2-0.3-0.3-0.5-0.2-0.8c0.1-0.3,0.2-0.4,0.3-0.4c1.6,0.1,3.3,0.2,4.9,0.3c2.3,0.5,4.9-0.8,7,0.9c1.3,0,2.7,0,4,0
		c0,0.4,0,0.8,0.1,1.2C876.7,647.5,876,647.8,875.4,648.2z"/>
	<path class="st1970" d="M883.4,814.9c-3.7-4.8-5.6-10.8-10-15c1.3-3.7,4.4-3.3,5.2-0.8c1.7,5.7,5.9,6.9,10.5,8.2
		c1.5,0.4,2.8,1.7,4.2,2.5c0,2.7-2.9,2.1-4,3.6c-1.2,1-1.6,2.6-2.8,3.6c-0.4,0.2-0.9,0.2-1.4,0.2
		C884.2,816.7,882.6,816.6,883.4,814.9z"/>
	<path class="st1971" d="M888.3,812.9c1.1-1.9,3.7-1.4,5-3c0.3,0,0.7,0,1,0c0.9,1.6,2,2.9,4,3c3.7,2,7.3,4.3,10.6,6.9
		c1.1,0.9,2.8,1.3,2.5,3.3c-3.3,3.1-5.8,0.6-8.5-1.1C896.5,821.4,893,816.1,888.3,812.9z"/>
	<path class="st1972" d="M883.4,814.9c0.6,0.7,1.2,1.4,1.9,2.1c1.3,1.4,3,2.6,3.9,4.3c0.7,1.2,1.7,2.2,1.4,3.7
		c-0.3,0.7-0.8,1.1-1.6,1.3c-3.3-2-5.2-5-6.7-8.5c-0.7-1,0.1-2,0-3C882.7,814.8,883.1,814.9,883.4,814.9z"/>
	<path class="st1973" d="M986.2,510.2c5.2,3.4,6.3,8.4,5.8,14.1c-0.9-1.3-2-2.3-3.7-2.2c0-0.3-0.1-0.6,0-0.9
		c0.9-4.1-1.7-6.4-4.6-8.4c-0.6-0.3-1-0.8-1.2-1.5c0,0-0.4,0-0.4,0c0.1-0.3,0.2-0.6,0.1-1C983.6,510.3,984.9,510.2,986.2,510.2z"/>
	<path class="st1974" d="M989.2,487.3c-1.4-0.3-2.7-0.7-4.1-1c-0.6-1-1.3-2-1.9-3c-0.9-2-0.7-4,1.3-4.9c1.6-0.8,2.3,1.2,3.2,2.2
		c0.9,1.7,1,3.7,1.9,5.4C989.6,486.5,989.5,487,989.2,487.3z"/>
	<path class="st1975" d="M987.2,481.3c-0.5-1-1.3-2.5-2.3-2.2c-1.8,0.6-1.6,2.6-1.7,4.2c-0.3,0-0.6-0.1-0.9-0.1
		c0.2-2-0.9-4.2,0.9-5.9c2.5-0.3,4.2,0.6,5,3c0.3,0.2,0.4,0.4,0.3,0.7c0,0.3-0.1,0.4-0.2,0.4C988,481.7,987.6,481.6,987.2,481.3z"/>
	<path class="st1976" d="M1000.1,585.2c-0.2-0.1-0.4-0.1-0.7-0.1c0-0.9,0-1.8,0.1-2.7c1,0.6,1.6,1.4,1.5,2.6
		C1000.7,585.1,1000.4,585.2,1000.1,585.2z"/>
	<path class="st1977" d="M989.2,569.8c2.9-1.6,3.2-4.2,3-7.1c-0.1-1.2-1.5-2.3,0-3.5c1.9,2,1.2,4.4,1,6.6c-0.2,3.3,1.2,6.1,2.2,9
		c-0.4,1-0.8,2-1.2,3.1c-0.2,0.1-0.3,0.3-0.5,0.4c-0.7-0.2-1.1-0.6-1.4-1.2c-0.5-1.4-1.3-2.6-1.6-4.1
		C990.8,571.7,989.2,571.1,989.2,569.8z"/>
	<path class="st1978" d="M998.2,585.2c0,0.3,0,0.7,0,1c-0.5,1.9,1.3,5.1-1.4,5.6c-2.7,0.5-0.6-3.6-2.6-4.5c-2.7-0.1-2.7-2.1-2.8-4
		c0.1-0.4,0.3-0.8,0.7-1.1c1.7-0.4,2.3,1.6,3.7,1.7c0.8,0.1,1.5,0.1,2.2,0.4C998.1,584.5,998.2,584.8,998.2,585.2z"/>
	<path class="st1979" d="M998.1,584.1c-0.6,0.3-1.3,0.7-1.9,1c-2.5-1.8-4.1-3.9-2.1-7c0,0,0-0.2,0-0.2
		C995.5,580,996.8,582.1,998.1,584.1z"/>
	<path class="st1980" d="M984.2,512.2c4.9,2.1,6.7,6.1,4.1,9c-0.8-1.9-2.8-2.5-4-4c0.3-1.3-0.2-2.6-0.5-3.8
		C983.7,512.9,983.8,512.6,984.2,512.2z"/>
	<path class="st1981" d="M984.2,513.2c1.5,1.3,2.5,2.7,0,4c-0.4-0.3-0.7-0.7-1.1-1C982.3,514.8,982.9,513.9,984.2,513.2z"/>
	<path class="st1982" d="M984.2,513.2c-0.4,1-0.7,2-1.1,3c0,0-0.4,0-0.4,0s-0.4,0-0.4,0c-0.3-1.7-0.3-3.3,0.2-4.9
		c0.5,0.3,1.1,0.6,1.6,0.9C984.2,512.6,984.2,512.9,984.2,513.2z"/>
	<path class="st1983" d="M861.4,644.9c0,0.4,0,0.8-0.1,1.2c-1.3-0.3-2.7-0.7-4-1c0.4-0.9,1.3-0.9,2-1c0.6,0,1.2,0.1,1.8,0.1
		C861.3,644.4,861.4,644.6,861.4,644.9z"/>
	<path class="st1984" d="M861.3,646.2c1.7,0.3,3.3,0.7,5,1c-3.9,3.5-4.6,7.9-2.9,12.7c0.4,1,0.7,2.5,2,2.6c1.6,0.1,1.4-1.6,1.7-2.6
		c1.3-3.8,2.7-7.6,2.2-11.7c1.3,0,2.7,0,4,0c-0.6,5.5-1.4,11-3.7,16.2c-0.7,1.6-0.2,3.8-0.3,5.7c-0.8,1.6-1.3,3.4-1.5,5.2
		c-0.2,2.4-1.8,3-3.7,3.2c-2,0.2-3.5-0.3-4.3-2.4c-0.8-2-1.6-3.9-1.5-6.1c0.1-7.3,0-14.6,0-21.9
		C859.3,647.5,860.3,646.8,861.3,646.2z"/>
	<path class="st1985" d="M858.3,648.1c2.1,6.3,0.5,12.9,1,19.3c0.2,2.3-0.3,4.6,0.6,6.9c0.7,1.8,1.2,3.5,3.7,3.1
		c2-0.3,4-0.5,3.8-3.4c-0.1-1.5,0.7-2.9,1.9-3.9c0,3.7-1.6,7.2-1,11c-2.2,1.5-1.6,6.5-6,5.2c-4-1.2-2.2-5.2-3.2-7.9
		c-3.4-10-1.2-20.2-1.9-30.2C857.7,648.1,858,648.1,858.3,648.1z"/>
	<path class="st1986" d="M860.3,633.1c-0.7,0-1.3,0-2,0c-2.7-5.7-6.5-11.1-5-18c2.6,1.3,2.3,4.1,2.7,6.1
		C856.9,625.5,858.6,629.3,860.3,633.1z"/>
	<path class="st1987" d="M873.3,646c-2.4-0.1-4.9,0.8-7-0.9c1.1-0.4,2.1-0.8,3.2-1.2c1.3,0.1,2.6,0.1,3.8,0.2
		C873.3,644.8,873.3,645.4,873.3,646z"/>
	<path class="st1988" d="M987.3,877.9c0.6-0.4,1.3-0.7,1.9-1.1c0,0.9,0,1.9,0,2.8c-0.7,0.8-1.3,1.5-2,2.3c-0.9,1.1-1.7,2.4-3.2,2.8
		c-1,0.1-1.9,0.1-2.8-0.6C981.3,880.2,985.5,880.2,987.3,877.9z"/>
	<path class="st1989" d="M984.3,883.9c1-0.7,2-1.3,3-2c-0.3,1-0.7,2-1,2.9c-1,0.3-2,0.7-3.1,1C982.6,884.7,983.6,884.4,984.3,883.9z
		"/>
	<path class="st1990" d="M953.4,899c-0.7-0.4-1.4-0.7-2.1-1.1c0.7-1.2,2-1.6,3.2-2c2.2-0.2,4.4-1.9,6.7,0c0.8,0.3,1.5,0.7,2.3,1
		c-2.1,0.6-4.2,1.3-6.2,1.9C956,898.9,954.7,898.9,953.4,899z"/>
	<path class="st1991" d="M987.3,877.9c-1,2.9-4.3,3.6-6,5.9c0.4,1.3-0.5,2-1.3,2.8c-0.7,0.3-1.3,0.6-2,0.9c-0.9,0.6-1.9,1-3,1.2
		c-3.9,1.7-8.4,2.1-11.8,5c-0.4,0.1-0.7,0.1-1.1,0.2c-0.4,0.3-0.8,0.4-1.3,0.3c-0.9-0.3-1.7-0.7-2.2-1.6c-0.4-1.3,0.2-2.3,1.1-3.1
		c0.7-0.6,1.6-1,2.5-1.4c1.7-0.6,3.5-1.2,5.3-1.6c3.1-0.7,5.9-1.9,8.5-3.8C979.5,880.4,983.6,879.7,987.3,877.9z"/>
	<path class="st1992" d="M963.3,893.7c2.9-4.2,7.4-5,12-5.7c-1.6,3-4.6,3.3-7.4,4C966.5,892.8,965.1,893.7,963.3,893.7z"/>
	<path class="st1993" d="M967.9,891.9c2.5-1.3,4.9-2.6,7.4-4c0.9-0.2,1.9-0.3,2.8-0.5C975.4,890.6,972.2,892.6,967.9,891.9z"/>
	<path class="st1994" d="M961.5,893.8c0.2,0,0.4,0,0.6,0.1c0,0.8-0.3,1.6-1,2c-2.3,0.3-4.5,0.7-6.8,1c-1.1,0.5-1.3-0.2-1.3-1.1
		c0.3-0.4,0.7-0.8,1.1-1C956.5,893.7,959,893.4,961.5,893.8z"/>
	<path class="st1995" d="M980.1,886.6c0.4-0.9,0.8-1.8,1.3-2.8c1,0,2,0,2.9,0c-0.4,0.6-0.7,1.3-1.1,1.9c-0.8,0.1-1.5,0.3-1.9,1.1
		C980.8,886.8,980.5,886.7,980.1,886.6z"/>
	<path class="st1996" d="M959.2,863.8c2.4-0.6,3.8,2,6,2c2.1,2.7,3.6,6.6,6.4,7.8c2.9,1.3,5.4,3.6,8.6,4.2c-4.4,3.5-10.4,2.9-15,6
		c-0.4,0.4-0.9,0.5-1.4,0.5c-5.4-1-5.5-1.1-3.5-4.4c1.7-0.8,4-1.4,0.8-3.3c-1.7-1-3.4-2-4.7-3.6C953.9,870,955.2,865.7,959.2,863.8z
		"/>
	<path class="st1997" d="M964.2,883.8c0.3,0,0.7,0,1,0c0.7,1.2,1.9,0.9,3,1c-0.1,2.7-2.9,1.5-4,2.7c-1.6,0.3-2.9,1.2-4.6,1.3
		c-3.3,0.3-6.6,0.6-9.8-0.3c-2-1.2-2.3-2.9-1.5-5c0.2-0.5,0.6-0.9,1-1.1C954.2,883.8,959.2,884.5,964.2,883.8z"/>
	<path class="st1998" d="M964.2,886.8c1.3-0.7,2.7-1.3,4-2c2.6-0.7,5.2-1.4,7.8-2.2c-1.6,3.3-5.3,3.3-7.9,5
		C967,886.7,965.3,888,964.2,886.8z"/>
	<path class="st1999" d="M973.2,854.9c0,0.3,0,0.5-0.1,0.8c-6.4,2.4-13.1,4-19.8,5.3c-0.5,0.2-0.9,0-1.3-0.4
		c1.3-3.1,4.5-2.5,6.8-3.5c1.8-0.4,3.5-1.1,5.1-1.9c1-0.4,2.1-0.7,3.1-1.1c1-0.3,2.1-1,3.2-0.2C971.2,854.2,972.2,854.6,973.2,854.9
		z"/>
	<path class="st2000" d="M952.3,860.8c0.3,0.1,0.7,0.2,1,0.2c0.8,1,1.9,0.8,3,0.8c0,0.3,0,0.7,0,1c-1.1,1.2-2.6,1.3-4.1,1.4
		c-2.7-0.4-5.9,1.1-8.1-1.8c0.4-1.4,1.5-1.5,2.6-1.7C948.5,860.6,950.4,860,952.3,860.8z"/>
	<path class="st2001" d="M952.3,862.9c1.3,0,2.6,0,4,0c1.1,0,2.3-0.2,3,1c-4.6,5.5-4.1,8.1,2.2,11.6c1.2,0.7,3.4,0.8,3.3,2.5
		c0,1.8-1.7,2.7-3.5,2.8c-1.3-1.6-2.4-2.2-4.5-0.8c-4.1,2.8-4.1,2.4-5.1-2.5c-0.4-2-1.1-5-4.3-5.3c-0.4-0.3-0.7-0.7-0.9-1.1
		c-0.7-6-0.4-6.5,4.6-7.4C951.5,863.5,951.9,863.2,952.3,862.9z"/>
	<path class="st1841" d="M1002.2,751c3.7-3,0.1-6.7,1-10c0.7,0.7,1.3,1.3,2,2c3.7,5,0.5,9.1-1.9,13.2c-1,1.7-2,3.1-2.3,5.2
		c-0.8,5.6-3.6,10.5-5.9,15.6c-0.1-1.4,0.5-2.9-1-4c-2.4-1.4-1.1-3.2-0.3-4.7c1.9-3.7,3.6-7.3,3.8-11.6
		C997.7,753.8,1001.2,753.3,1002.2,751z"/>
	<path class="st2002" d="M1002.2,751c-0.4,1.7-0.7,3.6-2.5,4.5c-0.4,0.2-1.2,0.5-1.1,0.5c1.8,6.1-2.9,10.1-4.8,15.1
		c-0.2,0.5,0.3,1.2,0.4,1.9c-2.7,4.7-4.9,9.5-5,15c-4.6,0.1-6,3.8-8,7c-2-2.8,0.4-5.2,0.6-7.8c2.8-6.9,3.6-14.7,8.6-20.8
		c3-3.6,2.2-8.8,5.3-12.4c0.9-1.4,1.8-2.8,3.7-3C1000.3,751.7,1001.2,751.5,1002.2,751z"/>
	<path class="st2003" d="M983.2,786.9c-0.3,2.7-2.1,5.1-2,8c0,1.3,0,2.7,0,4c0,0,0,0,0,0c-2.9,1.9-5.4,1.7-7.5-1.3
		c0.3-4.9,2.4-9,5.1-13C981.2,783.6,982,785.7,983.2,786.9z"/>
	<path class="st2004" d="M974.2,796.9c1.6,3.1,4.6,1.6,7,2c-1,4.6-4,8.9-2,14c-0.3,0.3-0.7,0.7-1,1c-1.6,1.3-3.3,1.8-5.3,1.2
		c-3-1.9-3.1-4.8-3-7.9c0.8-3.5-0.2-7.3,2.1-10.5C972.8,796.3,973.5,796.3,974.2,796.9z"/>
	<path class="st2005" d="M973.2,814.9c1.5-0.9,3.2-1.1,5-1c0.5,4.3-0.6,9,2,13c-0.5,1-1.2,1.5-2.3,1.2c-2.5-1.5-3.6-4-5-6.4
		C972,819.4,971.3,817.1,973.2,814.9z"/>
	<path class="st2006" d="M978.2,827.9c0.7-0.3,1.3-0.7,2-1c3.9,1.3,6.3,3.9,7,8c-0.4,0.6-0.8,1.2-1.2,1.8c-0.5,0.7-1.2,0.9-2,0.8
		C980.6,835.2,977.4,832.8,978.2,827.9z"/>
	<path class="st2007" d="M970.2,853.9c-1,0.4-2,0.7-3,1.1c-0.9,0.3-1.3-0.2-1.5-1c1.2-3.3,4.6-4,6.8-5.9c0.9-0.3,1.8-0.8,2.6,0.1
		c0.3,0.2,0.6,0.5,0.9,0.7c-0.7,0.7-1.3,1.4-2,2.1C972.9,852,971.5,852.9,970.2,853.9z"/>
	<path class="st2008" d="M975.2,848.2c-0.7,0.2-1.4,0.4-2.1,0.6c-0.3-0.3-0.5-0.7-0.4-1.2c1-2.2,3.4-3.3,4.8-5.1
		c0.6-0.6,1.2-1.2,2-1.7c0.4-0.2,0.8-0.3,1.2-0.3c0.2,0.1,0.4,0.2,0.5,0.4C980.7,844.4,977.3,845.7,975.2,848.2z"/>
	<path class="st2009" d="M984.5,837.1c0.5-0.1,1-0.3,1.6-0.4c0,0.7,0,1.4,0,2.2c-0.7,1-1.3,2-2,3c-0.9-0.3-1.8-0.6-2.8-0.9
		c0,0-0.4,0-0.4,0c-0.3-1.5,1-2.2,1.8-3.2C983.2,837.3,983.9,837.2,984.5,837.1z"/>
	<path class="st2010" d="M866.3,743c2-2.6,4-2.6,6,0c0.6,0.6,0.8,1.5,0.5,2.2c-2.2,4.5-0.1,7.7,3.2,10.5c2.9,3.9,2.9,8.8,5,13
		c0.1,0.8,0,1.5-0.4,2.1c-4.6,0.7-5.2-3.5-7.2-6c-3.6-6.6-7.7-13-7-21C866.3,743.7,866.3,743.3,866.3,743z"/>
	<path class="st2011" d="M873.3,765c3.1,1.1,3.7,5.1,7,6c3.1,2.6,4.4,6,4,10c-1.3,0-2.7,0-4,0C878,775.6,873,771.5,873.3,765z"/>
	<path class="st2012" d="M915.3,816.9c-0.3-3.3,0.7-6.8-1-10c-0.8-1.4-0.6-4-2.5-4c-7,0-8-6.6-11.7-10.2c-3.7-3.6-7.1-7.4-11.1-10.6
		c-2.8-2.2-5.5-4.8-5.2-9c0.6-1,1.5-1.1,2.6-1c0.5,0.1,0.9,0.3,1.3,0.6c1.3,2.8,4,4.1,6.6,5.4c3.8,3.6,8.6,6.1,11.1,11.1
		c1.5,3.2,5.2,3.4,8.4,3.3c3-0.1,3.2,2.3,4.2,4.1c0.2,0.7,0.1,1.4-0.1,2c-1.6,3.5-0.3,7,0.3,10.5c0.2,1,0.2,1.9,0,2.9
		c-0.6,1.6-0.2,3.6-2,4.7C915.8,816.9,915.5,816.9,915.3,816.9z"/>
	<path class="st2013" d="M911.3,822.9c-4.3-3.3-8.6-6.7-13-10c3.6,0.1,6,2.6,9,4c2,0.5,4-1.1,6,0c0.4,3.4,0.5,6.8,2,10
		c0.6,0.6,0.7,1.3,0.5,2.2c-1.2,1.8-2.3,0.8-2.9-0.3C911.9,827,910.7,825.1,911.3,822.9z"/>
	<path class="st2014" d="M915.3,828.9c0-0.7,0-1.3,0-2c0.2-3.3,0.5-6.6,0.7-10c0.5-1.7,0-3.7,1.1-5.2c2.9,3.1,0.3,6.7,1.1,9.5
		c0.7-2.6-1.4-6.7,3.2-8.4c1.1,1.4,1,3,0.8,4.6c-0.6,2.3-0.7,4.7-0.8,7c-0.2,1.8-0.7,3.6-1.7,5.1c-1.1,1.1-2.2,1-3.5,0.3
		C915.9,829.6,915.6,829.3,915.3,828.9z"/>
	<path class="st2015" d="M884.3,773c2,3.4,2.9,7.2,7.4,9.4c3.8,1.8,6.6,6,9.3,9.5c1.9,2.4,5,4.1,4.9,7.9c0,1,1.8,1.2,3,1.2
		c4.4-0.3,6.1,1.7,5.3,6c-1.4-1.1-2.2-2.4-4.5-2.4c-4.3,0-7.1-3.3-8.6-6.7c-0.8-1.7-1.5-3-3-3.3c-3.7-0.8-5.4-3.2-6.5-6.6
		c-1.1-3.4-5.3-4.1-7.3-6.9c-1.3-3.3-2.7-6.7-4-10c0-0.7,0-1.3,0-2C884,767.9,884.3,770.3,884.3,773z"/>
	<path class="st2016" d="M865.3,708c6.1-7.7,6.9-7.7,12.9-6.2c3.2,0.8,1.5,1.9,1.1,3.2c-2.5,0.2-2.2,2.5-2.8,4
		c-0.5,1.5-0.4,3.2-2.2,3.9c0.2-0.8,0.6-1.6,0.6-2.3c-0.1-1.9,0-4.1-1.6-5.2c-1.7-1.1-3.4,0.3-4.9,1.3
		C867.4,707.3,866.6,708,865.3,708z"/>
	<path class="st2017" d="M882.3,705c0.3,1.2-0.5,2.7,0.8,3.4c2.3,0.5-0.3-6.9,4.2-2.4c1.1,1.1,1.1,2.5,0.8,3.9
		c-1.9,2.7-0.5,6.4-2.8,9c-2.9-2.3-2.4-6-3.9-9c0,0-0.2,0-0.2,0c-0.7-1-0.7-2,0.1-2.9c0-0.4,0-0.7,0-1.1
		C881.7,705.7,882,705.3,882.3,705z"/>
	<path class="st2018" d="M881.4,707.1c0,1-0.1,2-0.1,2.9c-1.7-0.1-2.5,1.4-3.9,2C877.6,709.5,879.4,708.2,881.4,707.1z"/>
	<path class="st2019" d="M988.5,596.3c-3.2,4-6.2,2.7-9.3-0.2c-0.1-0.6-0.5-1.3-0.1-1.9c0.3-0.3,0.7-0.7,1-1
		c1.1-0.8,2.6-0.2,3.7-0.9c0.8-0.3,1.6-0.7,2.5-0.1C986.8,593.7,986.8,595.4,988.5,596.3z"/>
	<path class="st2020" d="M974.6,581.2c0.2-1,0.4-2,0.7-3c0.6-0.6,1.3-0.6,2.1-0.2c0.9,1,1,2.3,1.2,3.6c0.2,1,0.4,2.1,0,3.1
		c-0.7,1.1-1.6,1.3-2.8,1C974.1,584.5,974.9,582.7,974.6,581.2z"/>
	<path class="st2021" d="M984.3,593.2c-1.4,1.7-2.7,0.5-4.1,0c-1.8-1.3-3.7-2.5-4-5c-0.3-0.2-0.4-0.5-0.3-0.8
		c0.1-0.3,0.1-0.4,0.2-0.4c0.8-0.3,1.3,0.1,1.9,0.6c0.9,1,1.7,2,2.8,2.8c0.8,0.7,1.8,1.2,2.7,1.8
		C983.8,592.5,984.1,592.8,984.3,593.2z"/>
	<path class="st2022" d="M977.1,578.2c-0.6,0-1.2,0-1.8,0c0-0.7,0.1-1.3,0.1-2c0.6-0.3,1.2-0.7,1.8-1c1.7-0.9,3-0.6,4,1.1
		c0.1,0.3,0.1,0.6,0.2,0.9c-0.5,0.5-1,0.7-1.7,0.5C978.4,576.1,978.1,578.7,977.1,578.2z"/>
	<path class="st2023" d="M976.2,587c0,0.4,0,0.8-0.1,1.2c-2.7-1.8-2.5-4.3-1.6-7c0.8,1.2,0.1,3,1.3,4.1
		C976.9,585.7,976.7,586.3,976.2,587z"/>
	<path class="st2024" d="M979.1,594.2c0.1,0.6,1.2,1.2,0.1,1.9c-1.8-0.9-3.3-2.2-4.4-3.9C976.8,591.9,977.7,593.5,979.1,594.2z"/>
	<path class="st2025" d="M986.2,581.1c0.1-1.4-0.4-2.9,0.9-4c4.2,2.1,3.7,6.6,5.1,10.1c0.4,2.1,0.3,4.2,0,6.4
		c-0.1,0.5-0.3,1-0.7,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-0.3,0.1-0.7,0.2-1,0.3c-1.6-2.5,0.7-5.5-0.8-8
		C987.5,585.8,985.5,583.9,986.2,581.1z"/>
	<path class="st2026" d="M989.2,587c2.2,3,0.7,6,0,9c-0.2,0.2-0.4,0.2-0.6,0.2c-5.5,1.4-2.8-2-2.4-4.1
		C985.7,589.6,987.2,588.2,989.2,587z"/>
	<path class="st2027" d="M981.2,601.3c0-0.4,0-0.8,0.1-1.2c2.4,0.4,4.6-0.2,6.9-1c0.1,0.2,0.2,0.5,0.3,0.7c-0.9,3.1-3.2,3.8-6.1,3.2
		C981.7,602.6,981.1,602.2,981.2,601.3z"/>
	<path class="st2028" d="M990.2,595.7c0.3-0.3,0.6-0.6,0.8-0.8c1.1,1,1.1,2.1,0.1,3.2c-0.3,0.4-0.7,0.7-1,1.1
		c-0.6,0.6-1.2,0.6-1.9,0.1c0,0-0.1-0.2-0.1-0.2C988.8,597.9,989.5,596.8,990.2,595.7z"/>
	<path class="st2029" d="M981.2,601.3c0.3,0.3,0.7,0.6,1,0.9c0.6,0.6,1.6,0.3,2.3,0.9c0.3,1.3,0.2,2.5-0.3,3.7
		c-1.8,2.1-5.1,1.1-6.9,3.3c-0.3,0-0.7,0.1-1,0.1c-0.3-3.4,2.2-5.9,3-9C979.9,601.2,980.5,601.2,981.2,601.3z"/>
	<path class="st2030" d="M979.3,577.3c0.7,0,1.4-0.1,2.1-0.1c0.3,0,0.6,0,0.9,0c0,0.3,0,0.6,0,0.9c0,0.6-0.2,1.1-0.3,1.6
		c-0.1,0.8,0.1,1.6,0.2,2.3c0,0.9-0.2,1.8-0.9,2.4c-2.2-0.1-2.4-1.9-2.9-3.5C978.3,579.6,977.3,578,979.3,577.3z"/>
	<path class="st2031" d="M981.2,576.2c-1.2-0.8-2.5-1.2-4-1.1C979,573.8,980.5,573.3,981.2,576.2z"/>
	<path class="st2032" d="M991.1,598.1c0-1.1-0.1-2.1-0.1-3.2c0-0.2,0.1-0.5,0.2-0.7c0.9-2.3,0.7-4.7,0.1-7.1c-1.1-3.4-2.1-7-4.2-10
		c-0.4-0.9-0.7-1.9-1.1-2.8c0.4-0.1,0.8-0.1,1.2-0.2c2.9,1.9,4.6,4.6,4.9,8.1c0,2,0.5,3.7,2.1,5c0,2.5,0.1,4.9-0.1,7.3
		C994,596.4,994.2,598.6,991.1,598.1z"/>
	<path class="st2033" d="M989.2,587c-1,1.7-2,3.4-3,5.2c-0.6,0.3-1.3,0.7-1.9,1c-0.4-0.1-0.9-0.1-1.3-0.2c-0.2-1,0.2-1.7,0.7-2.5
		c1-1.5,1.2-3.2,0.7-4.9c-0.1-0.4-0.2-0.9-0.2-1.4c0-0.5,0-0.9,0.1-1.4c0.1-0.7,0.4-1.3,1.1-1.7c0.3,0,0.6,0.1,0.9,0.1
		C987.2,583.1,988.2,585.1,989.2,587z"/>
	<path class="st2034" d="M985.3,581.1c0,0.7-0.1,1.5-0.1,2.2c-2.7-0.2-2.6-2.9-3.9-4.3c0.3-0.3,0.7-0.6,1-1
		C984,578.3,985.1,579.3,985.3,581.1z"/>
	<path class="st2035" d="M992.2,582.2c-1.6-2.7-3.3-5.4-4.9-8.1c0.3-2.6,2-0.5,3-1c0.4-0.6,0.8-0.6,1.1,0c1.2,1.1,1.8,2.4,1.9,4
		C992,578.6,994,580.7,992.2,582.2L992.2,582.2z"/>
	<path class="st2036" d="M991.3,573.2c-0.4,0-0.7,0-1.1,0c-0.7-1.1-1.4-2.2-2-3.3c0.4,0,0.7-0.1,1.1-0.1
		C991,570.2,991.4,571.6,991.3,573.2z"/>
	<path class="st2037" d="M988.2,555.3c-1.1,1.5-1,3.3-1,5.4c-0.8-1.1-1.4-1.8-1.9-2.5c0.3-1.8-2.3-3.1-0.9-5.1
		C985.6,553.8,986.9,554.6,988.2,555.3z"/>
	<path class="st2038" d="M968.2,649.1c0,0.3,0,0.6,0.1,1c-4,2.8-5.2,2.4-9-2.8c0,0-0.1-0.3-0.1-0.3c2.7-1.7,5.4-1.1,8.1-0.1
		c-0.4,0.8-1.1,0.6-1.8,0.6C966.6,647.6,967.7,647.9,968.2,649.1z"/>
	<path class="st1862" d="M968.2,649.1c-0.9-0.7-2.4-0.4-2.9-1.8c0.6-0.1,1.3-0.3,1.9-0.4c1.2,0.1,2.3,0.1,3.3,1
		C969.6,648.1,968.7,648.2,968.2,649.1z"/>
	<path class="st2039" d="M990.3,631.2c-0.1,0.8,0.2,1.8-1.2,1.9c-0.3-1-0.7-2-1-3.1c0.4-0.4,0.8-0.8,1.2-1.2
		C990.8,629.2,990.9,630.1,990.3,631.2z"/>
	<path class="st2040" d="M993.1,611c-0.3,0.4-0.6,0.7-0.9,1.1c-1.5,2.2-1.2,4.6-1.2,7c0,2,0,3.9-1.9,5.1c-1.2-1.2-0.7-2.8-1.1-4.2
		c-1.1-4.4-0.8-8.9-0.6-13.3c0.2-0.6,0.5-1.1,0.9-1.5c0.9-0.1,1.9-0.2,2.8-0.2c0.8,0.2,1.7,0.3,2.1,1.2
		C993.8,607.8,994,609.4,993.1,611z"/>
	<path class="st2041" d="M989.1,624.3c1.1-2.3,0.4-4.8,0.8-7.1c0.2-1.8,0-4.1,2.4-5c-0.5,6.6,0.7,13.3-0.9,19.8
		c-0.2-0.2-0.3-0.5-0.3-0.8c-0.5-0.7-0.4-1.5-0.5-2.3c-0.1-0.8,0.2-1.5,0.1-2.3c-0.3,0.5,0.2,1.6-1.1,1.3
		C989.4,626.7,989.2,625.5,989.1,624.3z"/>
	<path class="st2042" d="M988.1,606.2c0,4.6-0.1,9.2-0.1,13.9c-0.3-0.6-0.5-1.2-0.8-1.8c-1-3.4-0.2-6.9-0.5-10.4
		C986.8,607,986.8,606,988.1,606.2z"/>
	<path class="st2043" d="M987.1,607.2c0,3.7,0,7.4,0.1,11c-0.3-0.1-0.6-0.1-0.9-0.2c0-3.6-0.1-7.3-0.1-10.9
		C986.5,606.7,986.8,606.7,987.1,607.2z"/>
	<path class="st2044" d="M989.6,627.9c0.4-0.6,0.8-1.2,1.5-2.4c0,2.3,0,4,0,5.6c0,0-0.4,0-0.4,0s-0.4,0.1-0.4,0.1
		c-0.3-0.8-0.7-1.6-1-2.4C989.4,628.5,989.5,628.2,989.6,627.9z"/>
	<path class="st2045" d="M984.2,606.2c-0.6-1-0.7-2,0-3c0.8-0.4,1.2,0,1.5,0.7c0,0.5-0.1,0.9-0.4,1.2c-0.6,2.7,0.9,5.6-1,8.1
		c0-0.7-0.1-1.3-0.1-2c0-0.3,0-0.7,0-1C983.1,608.8,983.3,607.5,984.2,606.2z"/>
	<path class="st2046" d="M985.3,605.1c-0.1-0.3-0.1-0.6,0-0.9c0.7-1.2,1.5-2.1,2.9-1c-0.2,0.7-1,1.4,0.1,2c-0.1,0.3-0.1,0.6-0.2,1
		c-0.3,0.3-0.6,0.7-1,1c0,0-0.4,0-0.4,0s-0.4,0-0.4,0C985.9,606.5,985.6,605.8,985.3,605.1z"/>
	<path class="st2047" d="M984.2,606.2c0,1.3,0,2.7,0,4c-1,0-2,0-3,0c-1-0.8-2,0.2-3,0c-0.3,0-0.7,0-1-0.1
		C977.7,605.5,982.2,608.1,984.2,606.2z"/>
	<path class="st2048" d="M978.2,610.1c1-1.3,2-1.3,3,0C980.2,610.4,979.2,611.7,978.2,610.1z"/>
	<path class="st2049" d="M955.3,641.2c2.1,2.8,0.4,6,1,8.9c1,2.1,0,3-2.1,3.1c-0.4-0.4-0.7-0.8-0.9-1.3c-0.5-1.8-0.3-3.7-0.4-5.5
		c0-1,0.1-2.1,0.4-3.1c0.2-0.5,0.5-0.9,1-1.2C954.6,641.8,954.9,641.5,955.3,641.2z"/>
	<path class="st2050" d="M940.2,639.2c-0.3-0.4-0.7-0.7-1-1.1c3.3,0,6.7-0.1,10,0c1.6,0,3.6-0.4,4.1,2c-1.6,1.4-3.5,1.3-5.5,0.9
		c-2-0.8-4.2-0.5-6.2-1C941,639.9,940.5,639.6,940.2,639.2z"/>
	<path class="st2051" d="M947.3,640c2,0.1,3.9,0.1,5.9,0.2c0.4,0.7,0.7,1.3,1.1,2c0,0.3-0.1,0.7-0.1,1c-0.3,0.3-0.7,0.5-1.1,0.5
		c-1.4-0.4-2.6-1.4-4.1-1.5c-0.9-0.2-1.7-0.5-2-1.5C947.1,640.4,947.2,640.2,947.3,640z"/>
	<path class="st1862" d="M954.3,653.1c1.2-0.6,1.9-1.6,2-2.9c1-1,2-1.9,3-2.9c3,0.8,4.8,5.6,9,2.8c0,0,0,0,0,0c1.8,3,0,5.2-1.8,7.4
		c-4.5,0.7-8.5-0.5-12.2-2.9C953.9,654.1,954,653.5,954.3,653.1z"/>
	<path class="st2052" d="M999.2,703c-0.6,7.2,0.8,14.1,3,21c-2.1,3.9-0.5,8-1,12c-3.8-1.4-3.8-4.9-4.2-7.9c-0.4-2.7-0.5-5.5-1-8.2
		c-0.2-5.6-0.3-11.1,1.8-16.5C998.1,702.8,998.6,702.7,999.2,703z"/>
	<path class="st999" d="M997.2,720c1.8,5.2,0,11.3,4,16c0.4,2.7,1.6,5.4-0.1,8c-0.6,0.3-1,0.2-1.4-0.3c-3.5-6.1-6.2-12.5-5.9-19.8
		c0.1-1.4,0.1-2.8,1.1-4C995.6,719.4,996.4,719.4,997.2,720z"/>
	<path class="st2053" d="M999.3,751c-1,1.1-2.1,2.1-3.1,3.2c-1,1.8-2.7,2.5-4.5,2.9c-1.9,0.2-3.8,0.4-5.5-0.7
		c-1.4-1.5-2-3.2-1.4-5.2c0.5-0.7,1.2-0.9,2-0.9c3.7,0.3,7.5,1.5,10.6-1.7c0.8-0.5,1.6-1.1,2.7-0.6C999.8,749,999.6,750,999.3,751z"
		/>
	<path class="st2054" d="M1000.2,744c0.3,0,0.6,0,0.9,0.1c0,0.3,0,0.7,0,1c-2.5,1.7-4.4,0.7-6.1-1.3c-1.9-2.3-3.9-4.5-4.8-7.4
		c0-2.5-2.2-4.6-1.3-7.3c0.6-0.6,1.3-0.7,2.1-0.5c2.8,1.1,3.3,4,4.5,6.3C997.1,737.8,998.4,741,1000.2,744z"/>
	<path class="st2055" d="M991.2,736c2.8,3.6,3.7,9.2,9.9,9c-0.3,1-0.7,2-1,3c-0.6,0.3-1.2,0.6-1.9,0.9c-0.6,0.6-1.2,0.6-2,0.4
		c-2.8-3.3-6.1-6.3-7.4-10.7c0.4-1.2-0.6-2.5,0.2-3.7C990.2,734.4,990.6,735.3,991.2,736z"/>
	<path class="st2056" d="M1006.2,688c0.2-1.7-0.6-3.7,0.9-5.1c1.5,1.4,3.1,2.7,4.6,4.2c1.3,1.2,3,2.5,2.3,4.6
		c-0.9,2.4-3.1,1-4.8,1.4l0,0c-1.2-1.5,0.2-3.5-1-5C1007.5,688.1,1006.8,688,1006.2,688z"/>
	<path class="st1873" d="M1008.2,688.1c1.7,1.4,0.8,3.3,1,5C1007.5,691.6,1008.4,689.7,1008.2,688.1z"/>
	<path class="st2057" d="M1010.1,673.1c3.4,3.3,6.8,6.7,10.2,10c0.8,2.8,1.5,5.6-1.1,7.9c-1.5,1.3-2.2-0.1-3-0.9
		c-1.6-3.8-4.4-6.9-7-10c1.3-1,2.2-1.9,0.3-3.2c-1.8-1.3-3.7-2.6-4.5-4.9c0-0.5,0.2-0.9,0.6-1.3
		C1007.8,669.8,1009.3,670.6,1010.1,673.1z"/>
	<path class="st2058" d="M1005.2,671.1c0,0.3,0,0.7,0,1c-0.1,1-3.5,1.2-0.5,2.9c1.7,1,4.8,1.5,3.5,4.8c-1.9,0.1-3.2-1.2-4.8-1.9
		c-3-0.7-5.8-2.3-9-2.4c-1.5-0.8-3.1-1.4-4.7-2c-2.7-1.7-2.7-3.6-0.5-5.8c3.6-0.2,7.2-0.2,10.7,0.3c1,0.7,2.2,0.5,3.3,0.5
		C1004.3,669.2,1005.6,669.4,1005.2,671.1z"/>
	<path class="st2059" d="M1008.2,679.8c-0.9-4.1-6.2-3.4-7.6-7.1c1.6-0.2,3.1-0.5,4.6-0.7c1.9,1.5,3.9,2.9,5.8,4.5
		c1,0.8,2.5,1.6,1.6,3.2c-0.9,1.7-2.4,0-3.6,0.4C1008.7,680.1,1008.4,680,1008.2,679.8z"/>
	<path class="st1872" d="M994.2,675c3.5-0.3,6.6,0.4,9.2,2.9c-0.4,0.4-0.8,0.8-1.2,1.1c-3.3,1.3-4.5-2.4-7-3
		C994.1,676.5,994,675.9,994.2,675z"/>
	<path class="st2060" d="M1016.1,690.1c1,0.3,2,0.6,3,0.9c0,0.4,0.1,0.7,0.1,1.1C1016.6,693.8,1016.5,691.7,1016.1,690.1z"/>
	<path class="st2061" d="M994.2,675c0.3,0.3,0.7,0.7,1,1c-1.4,1.3-0.6,2.7,0,4c1.4,7.3,5.6,14.1,4,22c0,0.3,0,0.7,0,1
		c-0.3,0-0.7,0-1,0c-2.7-0.1-2.8-2.5-3.8-4.1c-0.2-0.7-0.4-1.5-0.5-2.2c0.7-6.2-2.2-11.5-3.8-17.2c-0.4-1.6-1.6-2-3-2.1
		c-1.5-0.2-3-0.1-4.1-1.3c-0.5-0.8-0.5-1.5-0.1-2.3c2.1-2.3,4.6-1.6,7.1-0.9C991.6,673.4,993.9,672.4,994.2,675z"/>
	<path class="st2062" d="M999.2,702c-1.3-7.3-3.7-14.5-4-22c2,4.8,5.6,8.8,6,14.2c0.1,2-0.1,3.9,1,5.7
		C1001.2,700.7,1000.2,701.4,999.2,702z"/>
	<path class="st2063" d="M897.3,867.8c0-1.7,0-3.3,0-5c2.6-0.8,3.9,1,5.2,2.7c1.7,2.4,3.5,4.7,5.5,6.9c3.2,2.7,4.5,7.2,8.5,9.3
		c1.2,0.6,1.6,2,1.2,3.5c-0.1,0.2-0.3,0.4-0.4,0.6C908.8,881.8,902.1,875.9,897.3,867.8z"/>
	<path class="st1885" d="M907.3,884.9c-0.3-0.7-0.6-1.4-1-2.1c4.3-0.5,6.7,4.5,11,4c3.5-2.1,6.7,1.3,10.1,0.7c1.3,0.3,2.9,0.3,3,2.2
		c-0.6,0.7-1.3,1-2.2,1.2c-4.5,0.5-8.7-0.9-13-1.8C912.5,887.9,908.7,888.6,907.3,884.9z"/>
	<path class="st2063" d="M927.3,888.8c-3.6,0.5-6.5-2-10-2c0-0.3,0-0.6,0-1c0,0,0,0,0,0C921.8,884.3,924.7,885.2,927.3,888.8z"/>
	<path class="st1885" d="M906.3,882.8c-2,0.1-3.7-0.4-5-2C903.2,880.8,905.3,880.5,906.3,882.8z"/>
	<path class="st2064" d="M907.3,884.9c2.4,1.6,5.3,2,8,3c0.2,1.5-1,1.7-2,2c-7.6-0.6-14.5-3.5-21.3-6.8c-2.1-1.8-4.9-2.8-6.5-5.1
		c-0.7-0.9-2.4-2.1-0.1-3.2C892,879.5,899.3,882.9,907.3,884.9z"/>
	<path class="st2065" d="M885.3,874.8c0.2,4.4,4.8,4.5,6.9,7.1c-0.4,1-0.9,1.9-2,2.3c-1,0.1-1.5-0.5-2-1.3c-4.1-2.9-8.4-5.6-10-10.9
		c-0.5-0.7-0.6-1.5,0-2.2C881.5,870.3,883.7,872.2,885.3,874.8z"/>
	<path class="st1890" d="M878.3,869.8c0,0.7,0,1.4,0,2.2c-0.6-1.1-1.3-2.1-1.9-3.2C877.4,868.3,878.4,868.1,878.3,869.8z"/>
	<path class="st1888" d="M907.3,872.8c-2.7-1.3-4.1-3.9-5.5-6.3c-1.1-1.9-2.7-2.9-4.5-3.7c-3.3-4-6.7-8-10-12c3.9,1.8,8.8,1.4,12,5
		c2.8,1,6.1,0.9,8.4,3.4c2.3,3.6,0.4,7.7,1.4,11.5C908.8,871.8,908.3,872.5,907.3,872.8z"/>
	<path class="st2066" d="M899.3,852.8c0.7,0,1.3,0,2,0c1.5-1.2,2.9-0.3,4.4,0.1c2.4,1.3,5.6-0.3,7.7,2c1.2,0.3-1.7,1.2,0.2,1.2
		c1.2,0,2.3,0.1,3.4,0.5c1,0.4,1.7,1,1.9,2.2c0,0.5-0.2,1-0.6,1.3c-2.4,1.1-4.5-0.2-6.8-0.6c-2.6-1.5-6.1-1.2-8-3.9
		C902.7,853.8,900.3,854.3,899.3,852.8z"/>
	<path class="st2067" d="M903.3,855.8c2.7,1,5.8,0.9,8.1,2.9c-0.4,1-1.3,1.4-2.2,1.7c-0.7,0.1-1.4,0-2-0.5c-2.7-1.3-5.3-2.7-8-4
		C900.6,855.8,902,855.8,903.3,855.8z"/>
	<path class="st2068" d="M885.3,719c0.6-3-1.6-6.6,2-8.9c4.5,2.4,7.2,7,11.4,9.7c1.2,0.7,2.7,1.3,1.8,3.2c-3.7,1.2-5.6-3-8.8-3
		c-2.6-1.5-1.5,3-3.6,2.2C887.2,721.1,886.3,720,885.3,719z"/>
	<path class="st1894" d="M885.3,739c0-0.7,0-1.3,0-2c0,0,0,0,0,0c1.1-0.6,1.4,0.5,2,1c1,0.7,2,1.3,3,2c0.7,0.1,1.2,0.4,1.8,0.8
		c1.3,1.2,2.7,2.2,4.4,2.8c3.2,1.5,6.6,1,9.9,1.1c1.3,0,2.6,0.1,3.8,0.7c1,0.7,1.6,1.5,1.2,2.7c-0.9,0.8-2,0.8-3.1,0.7
		c-2.1-0.6-4.2-0.7-6.3-0.5c-7.3,0.5-12.5-2.8-16.6-8.5C885.4,739.5,885.4,739.3,885.3,739z"/>
	<path class="st2069" d="M888.1,722.1c1.3-0.8-2.3-6.6,3.2-3.1c0.5,2,1.7,3.3,3.5,4.3c1.1,0.6,3,0.8,2.5,2.9
		c-0.8,0.7-1.7,0.8-2.7,0.9c-0.7,0-1.5-0.1-2.2-0.4c-1.5-0.8-3-1.5-4.1-2.8C888.3,723.3,888.2,722.7,888.1,722.1z"/>
	<path class="st1691" d="M887.3,738c-0.7-0.3-1.3-0.7-2-1c-0.6-3.9-4.1-6.8-4-11c1,1,2,2,3,3C885.3,732,886.3,735,887.3,738z"/>
	<path class="st2070" d="M888.3,724c1.7,0,3.3,0.2,4.1,1.9c0,0.8-0.2,1.6-0.8,2.2c-0.3,0.2-0.6,0.3-1,0.3c-1.2,1.3,1,4.3-2.3,4.6
		C888.2,730,887.3,727,888.3,724z"/>
	<path class="st2071" d="M888.4,733c1.9-1.7,0.2-4.1,1.3-6c0,0,0.4,0,0.4,0.1c0.1,0.3,0.2,0.6,0.4,0.9c1.1,1.3,1.7,2.7,1.6,4.5
		c-0.1,0.9-0.5,1.8-1.1,2.6c-0.4,0.6-1,1-1.7,1.1C888.2,735.3,888.3,734.1,888.4,733z"/>
	<path class="st2072" d="M889.2,736.1c0.4-0.4,0.7-0.7,1.1-1.1c1.7,1.2,2.5,2.9,2.6,5c-0.2,0.7-0.6,1.3-1.5,1c-0.3-0.3-0.7-0.7-1-1
		C889.9,738.7,889.6,737.4,889.2,736.1z"/>
	<path class="st2073" d="M875.3,756c-5.3-3.9-6.3-4.1-3-13c2.5-0.3,4.9-0.6,3.8-4.3c-0.4-1.5,1.5-2.5,3.3-1.3c1.9,1.3,4,1,5.9,1.7
		c0,0,0,0,0,0c4.5,6.3,10.1,10.5,18.5,9.1c1.3-0.2,2.7-0.3,3.7,0.9c-0.5,2.7-2.9,2.5-4.8,3.2c-1.8,0.1-3.4,0.8-5.2,0.8
		c-2.6,0-4.4-1.9-6.7-2.9c-6.3-2.7-9.1-1.7-11.7,4.6C878.1,755.9,877.1,757.2,875.3,756z"/>
	<path class="st2074" d="M988.2,603.2c-1,0.3-1.9,0.6-2.9,1c-0.4-0.3-0.7-0.7-1.1-1c-0.8,0-1.5-0.2-2-1c2.6,0.2,4.8-0.5,6.1-3
		c0.6,0,1.2,0,1.9-0.1C988.7,600.1,990.8,602.8,988.2,603.2z"/>
	<path class="st2075" d="M993.1,611c0-1.6,0.1-3.2,0.1-4.9c1.6,1.8,1.1,3.9,0.6,5.9c-0.1,0-0.3,0-0.4-0.1
		C993.3,611.7,993.2,611.4,993.1,611z"/>
	<path class="st2076" d="M996.2,469.3c-0.3,0-0.6-0.1-0.9-0.1c-2-4.3-1.5-8.6-0.1-12.9c0,0,0,0,0,0c1.1-0.5,1.6,0.1,2,1
		c0.8,3.2-1.8,6.5,0.6,9.7C998.3,467.7,995.7,467.8,996.2,469.3z"/>
	<path class="st2077" d="M997.2,457.3c-0.7-0.3-1.3-0.7-2-1c0.1-2.5,2.4-4.3,2-7c0.3,0,0.7,0,1,0c0.4-0.4,0.7-0.4,1,0
		c1.1,1.7,1.1,3.3,0,5c0,0.7,0,1.3,0,2C998.5,456.7,997.8,457,997.2,457.3z"/>
	<path class="st2078" d="M988.2,481.3c0-0.3,0-0.7,0-1c2.7,0,3.3,2.6,5,4c0.2,1.4-0.3,2.7-1,3.9c-1-0.3-2-0.6-3-0.9c0-0.3,0-0.6,0-1
		C989.4,484.6,988.7,482.9,988.2,481.3z"/>
	<path class="st2079" d="M999.2,449.4c-0.3,0-0.7,0-1,0c0.9-1.6,2-3,4.1-3c0.6,0.7,1.3,1.3,1.9,2
		C1002.8,450,1000.9,449.1,999.2,449.4z"/>
	<path class="st1770" d="M1020.1,444.3c0.4,0,0.7,0,1.1,0c0,1.3,0,2.6,0,3.9c-0.3,0-0.5,0-0.8,0.1
		C1020.3,447,1020.2,445.6,1020.1,444.3z"/>
	<path class="st2080" d="M943.3,664.1c-3.2,0.3-5.8-0.9-8-3c0.7-1.2,1.9-0.9,3-1c2.4-0.8,3.6,1.3,5.3,2.3
		C944,663,945.2,663.8,943.3,664.1z"/>
	<path class="st2081" d="M943.3,663c-2.2-0.1-3-2.5-5-2.9c0-0.3,0-0.7-0.1-1c0.7-0.3,1.4-0.7,2.1-1c2.3-0.4,3.2,1.3,4.2,2.8
		C944.7,661.9,944.2,662.6,943.3,663z"/>
	<path class="st2082" d="M917.3,898.8c0.3,0.7,0.7,1.3,1,2c-3.7,1-6.6-1.8-10-2c-0.3-0.3-0.7-0.7-1-1c-0.7-1.2-1.9-0.9-3-1
		c0.3-0.6,0.7-1.3,1-1.9c2.4-1.9,4.3-0.3,6.4,0.6C913.3,897,915.8,897,917.3,898.8z"/>
	<path class="st2083" d="M911.3,896.8c-1.8-1.4-3.8-1.9-6-1.9c-5.1-2.1-10.6-3.4-15.1-6.9c-0.3-1,0.3-1.4,1-1.8
		c6.2,0.8,11.5,4.4,17.5,5.8c4.8,1.8,9.7,2.7,14.7,3.5c1.6,0.2,3.3,0,4.1,2c-0.5,1.1-1.6,1.4-2.6,1.8
		C920.2,899.1,915.7,898.1,911.3,896.8z"/>
	<path class="st2084" d="M891.3,886.8c-0.4,0.4-0.7,0.8-1.1,1.1c-3.3-1.7-6.6-3.5-9.8-5.2c-0.4-0.6-0.7-1.2-1.1-1.9
		c0.3-0.4,0.7-0.7,1-1.1c2.4,1.1,4.1,3.5,6.9,4.1C888.7,884.7,891.3,883.9,891.3,886.8z"/>
	<path class="st2085" d="M891.3,886.8c-1.4-1-2.8-1.9-4.1-2.9c0.4-0.4,0.7-0.7,1.1-1.1c0.7,0.3,1.4,0.6,2,0.9
		c6.8,1.6,13.3,4.1,19.2,8c0,0.9-0.2,1.5-1.3,1.2C902.2,891.8,896.9,888.9,891.3,886.8z"/>
	<path class="st2086" d="M989.2,659.1c0.3,0.3,0.7,0.7,1,1c2.1,1.7,4.2,3.3,5,6c0.3,0.5,0.3,1-0.2,1.4c-5.5,2-7.5-2.1-9.8-5.7
		c-0.6-1.4-1.8-2.4-2.1-3.9c-0.3-1.6-0.1-2.8,2-1.8l0,0C986.6,657,987.8,658.2,989.2,659.1z"/>
	<path class="st2087" d="M995.1,667c0-0.3,0.1-0.6,0.1-1c1.3-0.3,2.6-0.7,3.9-1c1.8,0.1,3.5,0.1,5.3,0.2c0.1,0.5,0,1-0.4,1.4
		c-0.9,0.9-2,1.4-3.3,1.6C998.8,668.2,996.5,670,995.1,667z"/>
	<path class="st2088" d="M1010.9,665.2c4.1,0.7,6.5,4,8.1,7.1c1.9,3.7-1.9,2.4-3.6,2.8c0,0-0.2-0.1-0.2-0.1c-0.8-3.2-4.2-5-4.4-8.4
		C1010.7,666.1,1010.7,665.6,1010.9,665.2z"/>
	<path class="st2089" d="M1010.9,665.2c0.1,0.3,0.2,0.6,0.4,0.9c-0.1,0.2-0.2,0.4-0.3,0.6c-2.3,1.8-0.1,2.9,0.6,4.2
		c0.3,0.7,0.3,1.3-0.1,2c-0.5,0.1-0.9,0.2-1.4,0.3c-1.5-1-2.9-2.3-4.9-2c-0.6-0.7-1.3-1.3-1.9-2c0.1-0.8,0.4-1.5,1.2-1.9
		c2.7-1.2-1.4-0.6-0.3-1.3c0.1-0.3,0.2-0.5,0.4-0.8C1006.6,664,1008.7,664.2,1010.9,665.2z"/>
	<path class="st2090" d="M1011.4,672.8c-0.2-0.6-0.3-1.2-0.5-1.7c-0.7-1.7-1.3-3.3-0.2-5.1c0,0,0.4,0.1,0.4,0.1
		c1.4,1.9,1.7,4.5,4,5.9c1.3,0.8,0.4,2-0.1,3C1013.9,674.2,1012.7,673.5,1011.4,672.8z"/>
	<path class="st2091" d="M980.1,657.3c0,0.2,0.1,0.5,0.1,0.7c-0.1,0.2-0.2,0.4-0.3,0.7c-4.1,6-4.1,6-11.1,3.7
		c-1.4-0.5-2.7-1.2-3.1-2.8c0-0.8,0.4-1.4,1-1.8c0.4-0.2,0.9-0.4,1.3-0.5c0.7,0,1.4,0.1,2,0.5c2.6,3.3,3.7,0.2,5.2-1.2
		c1.2-0.6,2.4-0.6,3.6-0.2C979.5,656.5,979.9,656.9,980.1,657.3z"/>
	<path class="st2092" d="M985.2,656.1c-1.4,0.1-1.6,0.8-0.9,1.9c0.6,1.1,2.1,1.7,1.9,3.2c-0.4,0.3-0.8,0.4-1.3,0.4
		c-1.9-0.5-3.3-1.6-3.6-3.7C981.2,654.5,982.2,653.4,985.2,656.1z"/>
	<path class="st2093" d="M971.2,651.1c1.1,0.7,2.1,1.4,3.2,2.1c0.4,0.8,0.3,1.6-0.3,2.3c-2.7,1.1-4.3-0.1-5.4-2.5
		C968.8,651.2,969.2,650.1,971.2,651.1z"/>
	<path class="st2094" d="M974.1,655.1c0.1-0.6,0.2-1.3,0.4-1.9c0.9,0,1.8,0,2.7,0c0.6,1.3,2.4,2,2.1,3.8c-1.3,0.1-2.6,0.2-3.8,0.2
		c-0.5,0.3-0.9,0.3-1.4,0C973.6,656.5,973.6,655.8,974.1,655.1z"/>
	<path class="st2038" d="M971.2,651.1c-1.2,0.1-2,0.6-1.9,2c1.1,1.9,0.3,3.4-1,4.9c-0.4,0.1-0.8,0.2-1.2,0.4
		c-0.6-0.2-0.9-0.7-0.9-1.3c0.2-2.5,2.4-4.3,2-7C969.3,650.1,970.5,650,971.2,651.1z"/>
	<path class="st2091" d="M981.3,658c1.3,1.1,2.6,2.1,3.9,3.2c0.6,0.6,0.7,1.3,0.2,2c-3.3-0.1-4.9-1.8-5.2-5c0,0,0.1-0.1,0.1-0.1
		C980.6,658,980.9,658,981.3,658z"/>
	<path class="st2095" d="M979.2,657c-1.2-1-2.3-2-2.1-3.8c2.1,0.6,3.5,1.7,3,4.1C979.8,657.2,979.5,657.1,979.2,657z"/>
	<path class="st2092" d="M989.2,659.1c-2-0.1-3.1-1.4-4-3C987.2,656.2,988.3,657.4,989.2,659.1z"/>
	<path class="st2096" d="M911.3,896.8c4.7,0.7,9.3,1.3,14,2c0.7-0.6,1.3-0.6,2,0c-3.3,2.1-6.6,0.6-10,0
		C915.1,898.7,913,898.3,911.3,896.8z"/>
	<path class="st2097" d="M927.2,898.8c-0.7,0-1.3,0-2,0c0.7-0.4,1.4-0.7,2.1-1.1c1.1-1.2,2.5-1.2,4-1.3c2.5,0.1,5.1-0.4,7.6,0.8
		c0.5,0.4,0.7,0.9,0.4,1.5c0,0,0.1,0.1,0.1,0.1c-2.7,0-5.4,0-8.1-0.1C929.9,898.8,928.6,898.8,927.2,898.8z"/>
	<path class="st2098" d="M939.3,898.8c0-0.3,0-0.5,0-0.8c0.8-1.3,2.1-1.3,3.3-1.4c1.5,0,2.9-0.4,4.4,0c0.3,0.2,0.5,0.5,0.4,0.7
		c0,0.3-0.1,0.4-0.2,0.4C944.8,899.9,941.9,898.4,939.3,898.8z"/>
	<path class="st2099" d="M947.2,897.7c0-0.2,0-0.3,0-0.5c1-2,2.9-1.7,4.7-2c0.5,0.1,1,0.3,1.4,0.7c0.4,0.4,0.7,0.7,1.1,1.1
		c-1,0.3-2,0.7-3.1,1C949.9,897.9,948.6,897.8,947.2,897.7z"/>
	<path class="st2100" d="M913.4,693.1c0,2.3-0.1,4.7-0.1,7c0.2,1-0.1,2-0.4,2.9c-0.9,4.5,0.8,9.3-2,13.5c-0.2,0.2-0.4,0.3-0.6,0.5
		c-3.6,1.2-4.1-0.5-4.7-3.9c-2.1-12.6,0.9-24.8,2.9-37c0.4-2.2,1.2-4.5,3.2-6.1c1.3,0.6,0.2,2.6,1.6,3.1c1,3.3,0.2,6.7,0.5,10
		C913.6,686.4,914.2,689.7,913.4,693.1z"/>
	<path class="st2101" d="M900.2,722.9c0.2-1.6-1.4-1.9-2-3c-0.3-0.2-0.4-0.5-0.3-0.7c0.1-0.2,0.2-0.3,0.3-0.3c0.3,0,0.7,0,1,0
		c1.5,0.9,2.9,2.4,5,2c1.9-1.4,5.4-0.5,6-4c0,0,0,0,0,0c0.5-0.3,0.9-0.3,1.3,0.1c1.4,3.9-1.4,6-3.7,8.2c-1,0.7-2.1,1.2-3.3,1.3
		c-0.4,0-0.9-0.1-1.3-0.3C902.6,725,900,725.2,900.2,722.9z"/>
	<path class="st1768" d="M913.3,683.1c0-3.4,0-6.7-0.1-10.1c0.3,0,0.6,0,0.9,0c0,2,0.1,4,0.1,6c-0.1,1.3-0.1,2.5-0.2,3.8
		C914,683.4,913.7,683.5,913.3,683.1z"/>
	<path class="st2102" d="M943.3,663c0.3-0.6,0.6-1.3,1-1.9c-1.5-2.4,1.2-5.8-1.8-7.9c-0.1-0.4-0.2-0.7-0.3-1.1
		c0.2-0.2,0.4-0.4,0.5-0.5c2-1,3.9-1.3,5.3,1.1c0.7,2.2,0.6,4.4,0.2,6.6c-2.2,3.1,1.7,2.6,2.4,4c0.2,0.5,0.2,0.9,0.3,1.4
		c-0.2,2.6,1,4.7,2.1,7c2.6,5.4,3.7,12,11.6,12.4c0.7,0,1,1.2,0.9,2.1c-3.9,1.7-7.8,3.3-12.2,3.3c-0.8-4.2-2.8-8-3.8-12.2
		c-1-3.9-2.2-8-6.2-10.2c0-1,0.1-2,0.1-3C944.1,663.7,943.8,663.3,943.3,663z"/>
	<path class="st2103" d="M942.5,653.1c6.4,1.4,1.9,5.1,1.8,7.9c-1.3-1-2.6-2-3.9-3C941,656.4,941.8,654.8,942.5,653.1z"/>
	<path class="st2104" d="M940.2,639.2c0.4,0,0.8,0,1.1,0c0.4,0.4,0.7,0.9,1,1.4c1.1,3.8,2.5,7.7-0.1,11.4c0,0,0,0,0,0
		c-0.3-0.3-0.7-0.6-1-0.9c-1-1.6,0.2-3.5-0.9-5C940.8,643.8,939.2,641.5,940.2,639.2z"/>
	<path class="st2105" d="M940.3,646.1c1.5,1.5,0.8,3.3,0.9,5C939.7,649.6,940.4,647.8,940.3,646.1z"/>
	<path class="st2106" d="M877.3,813.9c3.3,2.5,3.8,6.2,4,10c1.3,4.9,4.5,8.8,6.5,13.4c3.2,2.4,5.5,5.9,9.5,7.3
		c0.7,1.2-0.2,1.7-1,2.2c-3.2-0.2-5.2-2.9-7.9-4.1c-3.1-2.9-6.7-5.2-9.1-8.7C874.3,827.7,876.9,820.7,877.3,813.9z"/>
	<path class="st2107" d="M877.3,813.9c0.9,3.3,1.8,6.7,0,10c-1.2,2.2,0.9,3.9,1.2,5.9c0.2,1.4,0.7,2.7,0.7,4.2
		c-2.8-1.1-3.7-3.7-5-6.1c-1-5.2,1.1-10.1,2-15C876.6,813.2,877,813.6,877.3,813.9z"/>
	<path class="st2108" d="M905.3,853.9c-1.3-0.4-2.7-0.7-4-1.1c-0.8-2.4-3.8-1.6-5.1-3.4c0.9-1,2.1-0.3,3.1-0.5
		c4.1,0.5,7.8,2.2,11.2,4.5C908.9,855.1,907.1,854.4,905.3,853.9z"/>
	<path class="st2109" d="M910.2,852.9c-4.4,0.7-7.5-2.1-10.9-4c0-0.3,0-0.6,0-1c0.3-0.3,0.7-0.5,1.2-0.6c1.2,0,2.2,0.5,3.3,0.9
		c2.5,1.1,5.2,1.8,7.5,3.3c0.3,0.4,0.4,0.8,0.2,1.3C911.1,853.2,910.7,853.2,910.2,852.9z"/>
	<path class="st2110" d="M900.3,847.9c-0.3,0-0.7,0-1,0c-1-0.4-2-0.7-3-1.1c0.3-0.7,0.6-1.3,1-2c-1.2-0.7-2-1.7-2-3.1
		c-0.1-1.1,0.1-2.1,0.9-2.9c2.6-0.3,2.6,1.9,3.3,3.4C899.5,844.1,901.1,845.8,900.3,847.9z"/>
	<path class="st2111" d="M930.2,669.1c0.1,0.3,0.1,0.6,0.1,1c-0.1,2.2,0.3,4.5-0.5,6.6c-0.6,3.2-1.2,6.4-1.9,9.5
		c-0.2,1.7-0.6,3.4-1.4,4.9c-1.6,2.2-1.7,5.4-4.5,6.9c-0.5,0.7-1.1,1.2-2.1,1.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.5-0.8-1.2-0.9-2-1
		c-0.9-3.7-0.2-7.4-0.4-11.1c0-0.8,0.1-1.6,0.9-2c1.2-0.1,1.6,0.9,2,1.5c0.7-4.8-0.2-9.7,1.4-14.5c0.3-1,3.1-2.2,0.3-3.9
		c-0.7-0.4,0.5-1.3,1.4-1.2c1.6,0.3,3.1,1.1,4.8,1.3C928.6,668.1,929.5,668.2,930.2,669.1z"/>
	<path class="st2112" d="M912.1,702.1c0.2-0.8,0.3-1.6,1.2-2c0.6,0,1.3,0.1,1.9,0.1c2.6,0.4,4.8,1.5,5.1,4.5c0.3,2.6,0,5-3.1,5.9
		c-0.5,0-1-0.2-1.3-0.6c-1.2-1.9-1.7-4.3-3.2-6C912.2,703.4,911.9,702.8,912.1,702.1z"/>
	<path class="st1962" d="M916.5,709.8c4.3-3.1,3.8-6.2-1.4-9.6c1.4-0.4,2.7-0.8,4.1-1.2c0,0,0,0.2,0,0.2c3.8,4.8,3.2,9.8-1.5,13.1
		c-0.6,0.1-1.1,0-1.6-0.3C915.7,711.2,915.7,710.4,916.5,709.8z"/>
	<path class="st2113" d="M917.2,686.1c0,4,0,8,0,11.9c-2.1-3.9-0.5-7.9-0.8-11.9C916.7,685.6,916.9,685.6,917.2,686.1z"/>
	<path class="st2114" d="M917.2,682.1c0.1-3,0.1-6,0.2-9c0.6-0.3,1.1-0.1,1.5,0.4c1.2,3.5,1.4,6.9-0.3,10.3
		C916.6,684.4,916.7,683.4,917.2,682.1z"/>
	<path class="st1962" d="M913.3,683.1c0.3-0.1,0.5-0.2,0.8-0.2c0,3.4,0.9,6.9-0.7,10.2C913.4,689.8,913.4,686.4,913.3,683.1z"/>
	<path class="st2115" d="M918.2,673.1l-0.4,0c0,0-0.4,0-0.4,0c0-1.6,0-3.3,0-4.9c1.1,0.7,0.8,1.9,1,2.9
		C918.9,671.8,918.8,672.4,918.2,673.1z"/>
	<path class="st1962" d="M917.2,682.1c0.3,0.7,0.6,1.3,0.8,2c0.3,0.2,0.4,0.4,0.3,0.6c0,0.2-0.1,0.3-0.2,0.3c-0.3,0.4-0.7,0.7-1,1.1
		c-0.2,0-0.5,0-0.7,0C916.4,684.7,915.9,683.2,917.2,682.1z"/>
	<path class="st2116" d="M943.2,667c2.4,0.2,4.2,0.9,5.4,3.5c2,4.7,2,10.1,5.3,14.3c0.6,0.8-0.4,2.8-0.6,4.2c1,1.9,1,3.9,0.3,5.9
		c-1.6,1.2-3.1,1-4.7,0c-0.3-0.4-0.5-0.8-0.6-1.3c-1-3.7,1.3-7.6-0.7-11.2c-1.9-3.4-1.2-7.5-2.7-11.1c0-0.5-1.1,0.2-0.5,0.1
		c0.1,0,0.2,0,0.2,0.2c0.6,2.8-0.5,4.7-3.1,5.8c-2-1.5-0.6-3.7-1.1-5.5c0-1,0.2-2,1-2.7c0.3-0.7,0.7-1.3,1-2
		C942.6,667.1,942.9,667.1,943.2,667z"/>
	<path class="st2117" d="M918.2,685c0-0.3,0-0.6-0.1-0.9c0.1-3.7,0.1-7.3,0.2-11c0-0.7,0.1-1.3,0.1-2c2.5-1.6-1-4.3,1-6
		c3.2,0.9,6.4,2,9.8,2.1c0.7,2-1.5,0.9-1.9,1.9c-1.7-0.6-3.3-1.2-4.8-1.7c-1.4,1.2,4.1,2.4-0.3,3.3c0.8,2.5-1.3,4.5-0.9,7
		c0.5,3.4,0.3,7-1.3,10.3C918.7,687.4,919,685.9,918.2,685z"/>
	<path class="st2118" d="M941.4,669.2c-0.1,0.9-0.3,1.9-0.4,2.8c0.3,2.1-0.9,4-1,6.1c-0.6,1.6-1,3.1-2.2,4.4c-0.5,0.5-1.1,1-1.7,1.3
		c-2.8,0.1-4.6,3.5-7.8,2.5c-0.6-1-0.1-2.1,0.6-2.6c4.2-2.9,5.7-7.8,8.7-11.5c0.7-0.6,1.3-1.3,1.8-2.1
		C940.1,670.1,940,668,941.4,669.2z"/>
	<path class="st2119" d="M939.3,670.1c-0.1,0.8-0.3,1.5-1,2c-1.7,1.8-3,3.9-5,5.5c-1.2,1-0.8,3.9-2.9,3.3c-2-0.5-1.3-3.1-1.9-4.8
		c1.2-1.8,0.1-4.3,1.8-6C933.3,671.3,936.3,670.9,939.3,670.1z"/>
	<path class="st1961" d="M927.3,669.1c0.6-0.6,1.2-1.3,1.9-1.9c1.9-0.2,1.5,0.8,1.1,1.9C929.2,669.1,928.2,669.1,927.3,669.1z"/>
	<path class="st2120" d="M896.3,838.9c0,1,0,2,0,3c-2.2,1.4-3.2-0.6-4.7-1.5c-1.4-0.9-2-3.2-4.3-2.5c-0.7,0.2-2,0.8-2-0.3
		c0.1-4.4-2.9-7.3-4.6-10.9c-0.5-1.1,0.3-1.9,0.6-2.8c2.2,1.5,3.2,5.1,7,4c0.7,0.2,1.2,0.6,1.4,1.3c-0.4,2.2,1.2,2.7,2.7,3.2
		c1.8,1.1,2.3,3.2,3.6,4.7C896.4,837.6,896.8,838.2,896.3,838.9z"/>
	<path class="st2121" d="M889.3,828.9c-0.3-0.3-0.7-0.7-1-1c-2-3.4-6.4-5.3-6-10.1c2.3,2.7,4.6,5.4,6.9,8.1c1.6,0.3,2.3,1.3,2.6,2.8
		c0,0.5-0.2,0.9-0.4,1.3C890.3,830.4,889.8,829.8,889.3,828.9z"/>
	<path class="st2122" d="M898.3,719c0,0.3,0,0.7-0.1,1c-4.3-2.6-8.2-5.6-10.9-10c0-1.3,0-2.7,0-4c0.8-0.4,1-1.2,1-2
		c0.7-0.3,1.3-0.7,2-1C891.5,709.1,894.1,714.4,898.3,719z"/>
	<path class="st2123" d="M869.3,648.1c2.3,2.9,2.6,5.6,0.1,8.8c-1,1.3-0.8,3.5-1.3,5.2c-0.4,1.2-0.8,2.6-2.4,2.8
		c-1.7,0.2-1.8-1.2-2.6-2.3c-2.9-3.9-1.6-8.2-1.7-12.4c-0.1-3.4,3.7-1.2,4.8-3C867.3,647.5,868.3,647.8,869.3,648.1z"/>
	<path class="st2124" d="M888.3,812.9c2-0.4,3.9,0.6,4.8,2c2.5,3.7,6.3,4.8,10.2,6c-1.1,2.4-3.1,2.1-5.1,1.7c-2-0.3-3.9-1-5.9-0.9
		c-2.9-0.4-5.1-1.7-6.1-4.6C886.4,815.5,886.9,814,888.3,812.9z"/>
	<path class="st2125" d="M886.2,817.2c1.7,1.8,3.7,3,6,3.9c0.8,0,1.3,0.3,1.6,1c0.2,0.7,0.1,1.3-0.3,1.9c-0.4,0.2-0.8,0.3-1.3,0.3
		c-1.5-0.5-2.6-1.6-3.9-2.4c-0.5-1.9-3.5-2.4-3.1-4.9C885.6,817,885.9,817.1,886.2,817.2z"/>
	<path class="st2126" d="M893.3,822.1c-0.4-0.3-0.7-0.7-1.1-1c1.8-2.1,3.8-0.1,5.4,0.2c2.1,0.4,3.8,0.7,5.7-0.4c2.4,1.7,4.7,3.7,8,2
		c2.5,1.2,0.5,5.5,4,6c0.3,0.1,0.6,0.2,0.8,0.3c0.9,2.5-0.8,2.4-2.3,2.4c-1.7-0.3-3.4-1-5.1-1.5c-3.8-1.5-7.5-3.3-11.3-4.7
		C895.6,824.8,894.3,823.7,893.3,822.1z"/>
	<path class="st2127" d="M888.3,821.9c1.6,0.2,3,0.7,4,2c1.5,1,3.6,1.5,3.1,4.1c-0.6,0.5-1.3,0.6-2,0.4c-1.5-0.8-2.7-1.8-3.1-3.6
		C889.7,823.9,889,822.9,888.3,821.9z"/>
	<path class="st2128" d="M890.3,824.9c1.3,0.7,2.3,1.6,3,2.9c0.4,0.4,0.4,0.9,0.1,1.3c-0.7,0.4-1.4,0.4-2-0.3
		c-0.7-0.9-1.4-1.9-2.1-2.8C889.6,825.6,890,825.2,890.3,824.9z"/>
	<path class="st2129" d="M988.2,481.3c2.2,1.3,3.3,2.9,1,5.1c-2.2-1.1-2.2-3-2-5C987.6,481.3,987.9,481.3,988.2,481.3z"/>
	<path class="st2130" d="M992.2,582.1c1-1.5-2.8-4,1-4.9c0.3,0.3,0.6,0.6,0.9,0.9c-0.2,2.6,0.2,5,2.1,7
		C993.9,585.4,993.5,583.2,992.2,582.1z"/>
	<path class="st2131" d="M953.2,895.8c-0.3-0.1-0.7-0.1-1-0.2c-0.9-0.3-1.7,0.2-2.6,0.1c-1.2-0.1-2.3-0.2-2.6-1.7
		c0.3-1.2,2.9-0.5,2.2-2.6c0.7-0.7,1.5-1.2,2.4-1.5c1.2-0.4,2.4-0.5,3.7-0.6c1.7-0.1,3.5-1.2,5,0.5c-0.3,1-0.7,2-1,3
		c-0.6,2.8-3.8,0.8-5,2.6C953.9,895.6,953.6,895.7,953.2,895.8z"/>
	<path class="st2132" d="M964.2,886.8c1.3,0.2,2.9-0.5,3.9,0.9c-1.9,0.4-3.9,0.7-5.8,1.1c-0.6,0.5-1.3,0.5-2,0.2
		c-0.3-0.3-0.3-0.5-0.3-0.8c0.1-0.3,0.1-0.4,0.2-0.4C961.6,887.5,962.9,887.2,964.2,886.8z"/>
	<path class="st2133" d="M954.2,895.5c0.6-2.9,3.9-0.7,5-2.6c0.9,0,1.7,0.1,2.3,0.9C959.2,894.7,956.8,895.3,954.2,895.5z"/>
	<path class="st2134" d="M960,888.7c0.8,0.1,1.6,0.1,2.3,0.2c-0.4,0.8-1.2,1-2,1.1c-1.7,0.3-3.4,0.6-5.1,0.8
		c-0.3-0.4-0.5-0.8-0.4-1.3c0.1-0.5,0.5-0.8,0.9-1C957.1,888.4,958.6,888.1,960,888.7z"/>
	<path class="st2135" d="M947.2,871.8c3-0.2,6.6,3.4,5.7,6c-0.3,0.9-2.3,1.5-0.6,2.6c1.4,0.9,2.8,0,3.8-1.3c1-1.4,2.4-2.6,4.2-1.9
		c1.5,0.6,0.8,2.3,0.9,3.6c-1.3,0.3-2.5,0.8-0.6,1.8c1.1,0.6,2.7-0.2,3.6,1.2c-5.2,2.6-10.2,2.2-15-1
		C945.6,879.4,945.1,876.6,947.2,871.8z"/>
	<path class="st1818" d="M974.6,877.3c-0.5,0.1-1,0.4-1.6,0.4c-0.6,0-1.5-0.1-1.2-1c0.2-0.7,0.9-0.8,1.6-0.7
		C974,876,974.7,876,974.6,877.3z"/>
	<path class="st2136" d="M947.2,871.8c-0.3,3.8,0.2,7.5,2,11c0,0.3,0,0.7,0,1c-5.5,1.5-8.3-1.8-10.8-5.9c-1-1.7-2.4-3.2-2.6-5.3
		c0.1-1.2,0.7-2,1.8-2.5C941.1,869.6,944.3,870,947.2,871.8L947.2,871.8z"/>
	<path class="st2137" d="M936.3,872.8c0.6,0.8,1.3,1.4,1.8,2.3c2.5,4.5,5.4,8.4,11.2,8.7c-1,1.3,0,2.7,0,4
		c-4.2,1.2-7.2-1.5-10.5-3.2c-3.2-2.2-5.3-5.3-7-8.7c-0.7-1.4-1.8-2.9-0.4-4.5C933.4,870.5,935.1,870.7,936.3,872.8z"/>
	<path class="st2138" d="M939.2,883.8c3.3,1.3,6.7,2.7,10,4c3.7,0,7.3,0,11,0c0,0.3-0.1,0.6-0.3,0.8c-1.6,0.1-3.1,0.1-4.7,0.2
		c-7.6,2.1-14.9,2.3-21.8-2.3c-1-1.5-0.4-2.7,0.5-3.9C936.1,881.5,937.5,883.4,939.2,883.8z"/>
	<path class="st2139" d="M952.3,860.8c-1.6,0.8-3.2,1-4.9,0.8c0.4-2.2,2.7-2.2,3.8-3.6c2.1-1.2,4.3-2.4,6.9-1.7
		c0.7,0.4,1.2,0.9,1.3,1.7C957.2,859.5,954.2,858.9,952.3,860.8z"/>
	<path class="st2140" d="M959.2,858c-0.3-0.3-0.7-0.7-1-1c1-1.8,2.7-2.5,4.7-2.5c0.7,0.2,1.2,0.7,1.4,1.5
		C963,857.4,961.2,857.8,959.2,858z"/>
	<path class="st2141" d="M964.4,855.9c-0.4-0.3-0.8-0.6-1.2-1c0.2-1,0.9-1.5,1.8-1.7c0.5,0,0.8,0.2,1.2,0.5c0.4,0.4,0.7,0.8,1.1,1.2
		C966.5,856,965.4,855.9,964.4,855.9z"/>
	<path class="st2142" d="M951.2,859c-0.6,1.9-2.7,1.5-3.9,2.5c-1,0.2-2,0.3-3,0.5c-0.4,0.4-0.9,0.7-1.5,0.9c-3.2,0.2-6.4,1.1-9.3-1
		c0.5-3.4,3.4-2,5.3-2.5c3.5,0,6.9-1,10.3-1.3C949.9,858.1,950.7,858.3,951.2,859z"/>
	<path class="st2143" d="M943.2,862c0.4,0,0.7,0,1.1,0c2.5,1.6,5.3,0.4,8,0.8c0,0.3,0,0.7,0,1c-5.1,0.7-10.1,2.6-15.3,0.8
		c-0.6-0.3-0.9-0.7-1-1.4C938.1,861.2,940.9,863.2,943.2,862z"/>
	<path class="st2144" d="M936.3,863.8c5.3,0,10.6,0,15.9,0c-5,0.6-5.5,4-5,8c-3.4,0.6-6.6-1.1-10-1c-2.8-2.6-7.1-3.8-8-8.2
		C931.8,861.6,934,862.9,936.3,863.8z"/>
	<path class="st2145" d="M992.2,756.2c1.4-0.7,2.7-1.3,4.1-2c0.1,0.3,0.5,0.7,0.4,0.9c-3,4.9-3,11.4-8,15.4c-0.6,0.5-1,1.5-1,2.2
		c0,3.9-0.9,7.4-3.7,10.4c-0.7,0.8-0.4,2.5-0.6,3.8c-1.3-0.7-2.7-1.3-4-2c-0.5-7.6,3.1-13.7,7.3-19.6c1.8-2.5,3.7-5,4.7-8
		C991.5,756.9,991.8,756.6,992.2,756.2z"/>
	<path class="st2146" d="M992.1,757c-0.2,1.1-0.1,2.5-0.7,3.3c-5.6,7.5-10.1,15.5-12.2,24.7c-1.7,4-3.3,8-5,12c-0.7,0-1.3,0-2,0
		c-1.1,0.9-1.9,0.3-2.6-0.6c-1.1-3-0.4-5.6,0.8-8.5c2.5-6.1,5.2-12,8.8-17.6c2.6-4.1,6.2-7.2,9.1-11c1.4-0.4,0.3-1.9,1.2-2.6
		C990.3,756.5,991.3,756.3,992.1,757z"/>
	<path class="st2147" d="M970.2,795.9c0.4,0.8,1.2,1,2,1c-0.3,3.3-0.7,6.7-1,10c-0.9,1.8-1.4,3.6-1.8,5.5c-0.3,1.7-1,3.1-3,3.4
		c-0.8,0.1-1.5-0.1-2.2-0.5c-2-2.3-2.9-5.1-3.6-8.1c0.7-2.3,2-4.3,2.5-6.7c1.1-2.6,3.3-4.1,5.8-5.2
		C969.4,795.4,969.8,795.6,970.2,795.9z"/>
	<path class="st2148" d="M966.2,814.9c2.1-0.2,2.1-1.8,2-3.3c-0.1-2.3,1.7-3.3,3-4.7c-0.5,3,0.5,5.5,2,8c0.1,2.4-0.4,4.8,1,7
		c-0.8,0.8-1.8,0.9-2.9,0.7C967.8,821.3,966.4,818.5,966.2,814.9z"/>
	<path class="st2149" d="M971.2,821.9c1,0,2,0,3,0c1.3,2,2.7,4,4,6c1.1,3.8,3.5,6.6,6.3,9.2c-0.3,0.3-0.7,0.7-1,1
		c-2.2,0.5-3.4-1.5-5.1-2.1c-2.9-1.3-6.6-1.7-7.2-5.7C971.5,827.4,967.5,824.6,971.2,821.9z"/>
	<path class="st2150" d="M966.2,853.8l-0.5,0l-0.5,0c-0.6-0.3-1.2-0.5-1.3-1.3c0.1-0.4,0.3-0.8,0.6-1.1c1.1-0.7,2.3-1.1,3.4-1.8
		c1.7-0.8,2.8-3.2,5.3-1.8c0,0.3,0,0.6,0,1C970.8,850.4,968.5,852.1,966.2,853.8z"/>
	<path class="st2151" d="M979.2,834.9c1.4,1.1,2.8,2.1,4.3,3.2c0.3,2-2.1,1.6-2.5,2.9c-0.3,0-0.5,0-0.8,0c-2,1.3-3.8-0.7-5.7-0.4
		c-2.4-1.1-5-1.9-7.2-3.3c-1.5-0.9-3.6-2-2.7-4.6c1.6-1,3.3-0.5,4.7,0.2C972.5,834.3,975.7,835,979.2,834.9z"/>
	<path class="st2152" d="M973.2,847.8c-2.2,0-3.1,2.3-4.9,2.9c-0.7-0.3-1.2-0.9-1.6-1.5c-0.4-0.5-0.7-1-0.8-1.5
		c-0.1-0.8,0.2-1.6,0.4-2.4c0.1-0.4,0.3-0.8,0.6-1.1c0.5-0.5,1.1-0.7,1.8-0.8c1.9,0,3.5-0.8,5.1-1.5c1.7-0.7,3.5-1.4,4.5,1
		C977.2,845.1,974.9,846.2,973.2,847.8z"/>
	<path class="st2153" d="M978.2,842.9c-2-1.4-3.6-0.2-5.3,0.6c-1.5,0.7-3,2.4-4.7,0.4c-2.4-0.7-5.6,1.8-7.2-1.9
		c2.2-2.5,5.1-2.4,8.1-2.3c2,0.2,4.1,0.7,6.1,0.2c2-1.5,3.2,1.3,5,1C979.5,841.5,978.9,842.2,978.2,842.9z"/>
	<path class="st2154" d="M875.3,756c1.4,0,2.4-0.7,3-2c5.9-0.1,8.8,1.9,9.7,6.6c-1.3,2.6-2.1,5.1-0.2,7.9c0.9,1.3,0.9,3.2-0.5,4.5
		c-1,0-2,0-3,0c-1.5-1.2-1.1-4.2-4-4C877,765.3,877.6,760.1,875.3,756z"/>
	<path class="st2155" d="M887.3,773c-0.2-1.7,0.5-3.5-1-5.1c-2.5-2.6-1.5-4.9,1-6.9c1.1-1,2.4-1.2,3.7-0.6c1,6.9,7.9,10.4,10,17
		c0.9,2.8,4.2,5.6,7.5,7.4c3.8,2.2,7.9,4.2,10.4,8.2c0.4,1.7,0.3,3.2-1.6,4c-1.5-3.2-4.5-2.8-7.2-3.3c-2.2-0.4-4.7-0.6-5.8-3.2
		c-2.1-5.3-7.2-7.8-11-11.5C892.1,776.1,889.7,774.6,887.3,773z"/>
	<path class="st2156" d="M921.2,812.9c-2.7,3.9-1.3,8.8-2.5,13.1c-0.5,0-1.1-0.1-1.6-0.1c0-4.8,0-9.5,0-14.3c0-0.9-0.1-1.9-0.1-2.8
		c-0.6-3.2-0.3-6.3,0.5-9.4c0.1-1.8,1.2-3.4,1.3-5.2c0.4-1,0.3-2.1,0.8-3c2-2.7,4.1-2.3,6.2-0.1c1.9,5.1-1.2,9.7-1.5,14.5
		C923.4,808.1,923.9,811.2,921.2,812.9z"/>
	<path class="st2154" d="M887.3,773c4.1-0.2,6.1,1.8,6,6C890.9,777.3,886.9,777.3,887.3,773z"/>
	<path class="st2155" d="M918.3,798.9c-0.4,3.3-0.8,6.6-1.2,9.9c-0.7-3.3-3-6.7,0.2-9.9C917.6,798.4,917.9,798.4,918.3,798.9z"/>
	<path class="st2157" d="M918.3,798.9c0,0-0.5,0-0.5,0s-0.5,0-0.5,0c0-0.7,0-1.3,0-2c0.7-1.2,1.2-2.5,1-4c1-0.1,1.6,0,1.1,1.2
		C919,795.7,918.6,797.3,918.3,798.9z"/>
	<path class="st2158" d="M921.2,812.9c0.6-2.4,1.2-4.7,1.8-7.1c2.5-3.6,4.4-2.2,6.1,0.7c0.5,2.1,0.5,4.3,1.6,6.2
		c0.2,1.5-0.1,2.8-1,4.1c-1,0.9-2,0.5-3-0.2c-1.5-1.2-2.2,0.1-3,1c-0.8,0.7-1.6,0.9-2.5,0.2C921.2,816.2,921.2,814.6,921.2,812.9z"
		/>
	<path class="st2159" d="M913.3,830.7c1.2,0,2.7,0.6,2.8-1.5c1.1,0.2,2.1,0.4,3.2,0.6c0.2,0.1,0.4,0.2,0.7,0.2
		c2.7,1.7,5.7,2.6,8.6,3.9c1.6,0.7,3.5,1.2,4,3.4c-0.3,0.8-0.9,1.2-1.6,1.5c-3.5,0.6-6.8-0.4-10-1.6c-2.4-2-5.9-1.8-8-4.3
		C912.6,832.1,912.7,831.4,913.3,830.7z"/>
	<path class="st2160" d="M921.2,817.9c0.8,0,1.6-0.2,2.1-1c3.6,0.9,5.8,3.1,6.7,6.7c-0.1,0.7-0.5,1.3-1.2,1.7
		c-2.3,0.7-3.2-2.6-5.4-2c-1.7-0.8-1.3,2.4-3,1.6C920.4,822.5,919.9,820.1,921.2,817.9z"/>
	<path class="st2161" d="M920.3,824.9c1.9,0.8-0.6-5.1,3-2c0.9,2,1.1,4.1,0.4,6.2c-1.1,1.3-2.3,2.1-3.9,0.8c0,0-0.4,0-0.4,0
		C919.6,828.2,920,826.5,920.3,824.9z"/>
	<path class="st2162" d="M984.4,591.1c-0.5,0.6-0.9,1.2-1.4,1.9c-0.9-0.6-1.9-1.1-2.8-1.7c-0.6-1.2-1.5-2.2-2.6-3.1
		c-0.4-0.4-0.9-0.8-1.3-1.2c-0.1-0.6-0.2-1.2-0.3-1.7c1-0.1,1.9-0.3,2.4-1.3c2.3,0.2,2.5,2.4,3.6,3.7
		C982.5,589,984.6,589.2,984.4,591.1z"/>
	<path class="st2163" d="M981.1,588.2c-0.9-1.4-1.8-2.8-2.8-4.2c-0.2-0.6-0.5-1.3-0.7-1.9c0.4-0.7-0.3-2.2,1.5-1.8
		c0.4,1.5,0.9,3,2.2,4c0.9,0.2,1.4-0.8,2.2-0.7c0.7,0,1.3,0.2,1.8,0.5c0,0.4-0.1,0.7-0.1,1.1C984.6,587.2,983.9,589.1,981.1,588.2z"
		/>
	<path class="st2164" d="M979,580.2c-0.5,0.6-1,1.2-1.5,1.8c-0.2-1.3-0.3-2.5-0.5-3.8c0.5-0.8,0.7-2.3,2.2-1
		C978.4,578.2,978.6,579.2,979,580.2z"/>
	<path class="st2165" d="M977.5,588.2c1.9,0.2,2.6,1.3,2.6,3.1C978.9,590.6,978,589.6,977.5,588.2z"/>
	<path class="st2028" d="M991.3,587.1c1.4,2.4,1.5,4.7-0.1,7.1C991.2,591.9,991.3,589.5,991.3,587.1z"/>
	<path class="st2166" d="M985.2,584c-0.6,0-1.3,0-1.9,0.1c-1.2-0.6-1.2-2-2.1-2.9c-0.1-0.7-1.2-1.5,0-2.2c2.5,0.4,2.4,3,3.9,4.3
		C985.2,583.5,985.2,583.8,985.2,584z"/>
	<path class="st2167" d="M981.2,581.2c1.3,0.5,1.9,1.5,2.1,2.9c-0.7-0.6-1.2,3.4-2.1,0.1C981.2,583.2,981.2,582.2,981.2,581.2z"/>
	<path class="st2168" d="M981.1,588.2c1.7-0.6,3-1.7,4.1-3.1c1.4,2.2,1.1,4.2-0.7,6.1C983.5,589.9,981.1,590.4,981.1,588.2z"/>
	<path class="st2169" d="M954.3,653.1c-0.1,0.3-0.1,0.6-0.1,0.9c-0.6,0.6-1.2,0.7-2,0.4c-0.9-0.4-1.5-1.2-1.8-2.1
		c-0.4-1.3-0.5-2.7-0.7-4c0-0.5,0-0.9,0.1-1.3c0.8-1.6,1.7-3.2,3.5-3.8c0.3,0,0.6,0,0.8-0.1c0,1,0,2,0,3c-0.8,2-1.6,4,0,6
		C954.2,652.4,954.2,652.8,954.3,653.1z"/>
	<path class="st2170" d="M954.1,652.1c-3.9-2-1.2-4,0-6C954.1,648.1,954.1,650.1,954.1,652.1z"/>
	<path class="st2051" d="M941.3,640.1c-0.1-0.3-0.1-0.6,0-0.9c2,0,4.2-0.4,6.1,0.8c0,0-0.2,0.3-0.2,0.3c-1.4,1.2-2.9,1.1-4.5,0.7
		C942.1,640.8,941.7,640.5,941.3,640.1z"/>
	<path class="st2171" d="M942.3,640.1c1.6,0,3.3,0.1,4.9,0.1c0.7,0.3,1.4,0.6,2.1,0.9c2.1,2.8-1.9,3.3-1.9,5.3
		C940.9,648.2,943.5,642.5,942.3,640.1z"/>
	<path class="st2172" d="M947.3,646.1c-0.9-2.3,2.9-2.7,2-5c1.9-0.4,2.9,1.1,4.1,2.1c-1,1.3-1.4,3.1-3,4c-0.9-2.2-1.5,0.4-2.3,0.2
		C947.4,647.2,946.9,646.9,947.3,646.1z"/>
	<path class="st2173" d="M952.4,654c0.6,0,1.2,0,1.8,0c4,1,8,2,12,3c0.3,0.4,0.6,0.8,0.9,1.3c-0.3,0.3-0.6,0.5-0.9,0.8
		c-1.6,0.8-3.3,0.1-5,0.1c-1.7-0.2-3.2-0.7-4.9-1.1c-1.8-0.7-3.6-1.5-4.3-3.5C952.2,654.4,952.3,654.2,952.4,654z"/>
	<path class="st2174" d="M995.2,699c1,1.3,2,2.7,3,4c0,5.7-1.6,11.3-1,17c-0.7,0-1.3,0-2,0c-2.2-2.5-1.1-5.5-1.4-8.3
		c-0.2-2.1,0.2-4.2-0.5-6.2C992.9,703,992.9,700.7,995.2,699z"/>
	<path class="st2175" d="M1000.2,744c-4.3-4.2-5.8-10.1-9-15c-1.2-1.3-1.3-2.9-0.9-4.5c0.4-1.3,1.1-2.4,2.5-2.9
		c1.9-0.2,1.6,1.6,2.4,2.4c-0.4,5.9,1.2,11.2,4.3,16.2C1000.2,741.4,1000.1,742.7,1000.2,744z"/>
	<path class="st2176" d="M995.2,724c-0.7-0.7-1.3-1.3-2-2c-2.5-5.4-1.7-11-0.6-16.5c0.4-0.6,0.9-0.8,1.6-0.5c2.2,4.9,0.4,10,1,15
		C995.2,721.3,995.2,722.7,995.2,724z"/>
	<path class="st2177" d="M992.1,757c-0.9,0-1.9,0.1-2.8,0.1c-1.5,1.5-3.7,1.1-5.4,1.9c-3.9,1.5-7.5,3.7-11.4,5.1
		c-8.6,1.5-17.2,2.5-26.1,2.5c-8,0-14.6-3.4-21.5-6.4c-3.9-1.7-7.8-3.7-11.6-5.6c-2.2-1.1-4.7-1.4-7.2-1.5c-1.5,0-3.5,0.4-3.7-2.1
		c1.8-0.4,3.6-0.7,5-2c0.1-0.6,0.4-0.9,1-1c1,0,2,0,3,0c1.5-0.4,2.9-0.1,4.3,0.6c6.7,3.3,13.8,5.9,19.8,10.5c1.4,0.9,3,1.5,4.7,1.8
		c5,1.8,9.7,0.3,14.4-1.1c5.6-1.4,11.4-2.3,16.8-4.5c4.1-1,8.2-1.2,12.4-1c1.1,0,1.8,0.6,2.5,1.5c2,0.2,4,0.3,6.1,0.5
		C992.2,756.5,992.2,756.7,992.1,757z"/>
	<path class="st2178" d="M986.1,755.7c-0.6-0.3-1.2-0.6-1.9-0.8c-1.6-0.4-2.6-1.6-3.2-3.1c-1.4-5.1-1.5-10.5-3.3-15.5
		c0-0.5,0.1-1,0.4-1.4c2.4-1,2.9,1,3.7,2.4c0.4,0.8,0.7,1.7,1.1,2.5c0.5,1.2,1.2,2.5,0.8,3.9c-0.7,0.6-0.8,1.1,0.1,1.6
		c0.6,1.9,1.4,3.7,1.4,5.8C985.5,752.6,985.8,754.2,986.1,755.7z"/>
	<path class="st2179" d="M996.2,749c0.7,0,1.4,0,2-0.1c-3,4.3-6.9,3.7-11,2c0.1-0.2,0.1-0.4,0.2-0.7c0.3-0.4,0.6-0.7,1-0.9
		c1.7-0.5,3.5,0.2,5.2-0.4C994.5,748.8,995.3,748.9,996.2,749z"/>
	<path class="st2180" d="M985.2,751.1c-2-1.6-2-3.8-2.1-6.1c1.5-0.6,2.2,0.5,3,1.4c0.8,1.4,2.3,2.7,1,4.6c0,0,0.1-0.1,0.1-0.1
		C986.6,751,985.9,751,985.2,751.1z"/>
	<path class="st2181" d="M991.2,724c0,1.7,0,3.3,0,5c-0.7,0-1.3,0-2,0c-0.5-0.3-0.8-0.7-1.1-1.2c-2.9-4.6-1.9-9.3-0.4-14
		c0.8-0.8,1.6-1.6,2.9-0.6C992,716.8,992.3,720.4,991.2,724z"/>
	<path class="st2182" d="M989.2,728c0,0.3,0,0.7,0,1c0.3,2.4,2.8,4.2,2,7c-0.7-0.3-1.3-0.7-2-1c-3.4,0.1-4.3-2.5-5.3-4.9
		c0.4-1.3,1.3-2.1,2.5-2.7C987.4,727.1,988.4,727.2,989.2,728z"/>
	<path class="st2183" d="M996.2,749c-0.4,1-1.2,1-2,0.8c-0.3-0.2-0.5-0.5-0.6-0.9c-0.3-0.8-0.8-1.3-1.4-1.8c-2.8-2.7-6.9-4-8.2-8.1
		c1.1-1.3,2.8-0.7,4.1-1.4c0.4-0.1,0.8,0,1.1,0.3C990.9,742.1,995.1,744.6,996.2,749z"/>
	<path class="st2184" d="M989.2,738c-0.3,0-0.7,0-1,0c-3.2-0.2-4.5-2.5-5.7-5c-0.3-1-0.3-2,0.3-2.9c0.4-0.3,0.9-0.4,1.3-0.1
		c1.9,1.5,2.7,3.9,5,5C988.9,736,990.3,737,989.2,738z"/>
	<path class="st2185" d="M980.1,658.2c1.4,1.9,3,3.6,5.1,4.9c2.1,1.1,3.9,2.5,4,5.1c-0.5,1.7-1.4,3.5,0.9,4.6
		c-2.3,0.4-4.7,0.8-7,1.2c-4.4,1-7.2-0.8-8.6-5c0.3-1.7,0.6-3.3-1.9-3.5c-1.4-0.1-3-0.3-4-1.6c-0.4-1-0.3-1.9,0.5-2.7
		c3.7,1.7,7.4,3.2,9.1-2.5C978.5,658.4,979.5,658.4,980.1,658.2z"/>
	<path class="st2186" d="M989.2,668.2c-1.3-1.7-2.7-3.4-4-5.1c0-0.6,0-1.3,0-1.9c0.3,0,0.7,0,1,0c2.2,3.1,3.8,7.2,9,5.8
		c1.9,1.8,3.9,0.9,6,0.5c0.6,0.5,0.6,1,0,1.5C997.1,668.9,993.1,669.7,989.2,668.2z"/>
	<path class="st2187" d="M1001.1,669c0-0.5,0-1,0-1.5c1-0.5,2-1,3-1.5c0.7,0.1,1.5,0.3,2.2,0.4c-1,0.9-2.1,1.8-3.1,2.7
		C1002.5,669.3,1001.8,669.7,1001.1,669z"/>
	<path class="st2188" d="M983.3,676.1c1.8,0,3.6,0,5.5,0c1.3,0,2.6,0.5,2.7,1.7c0.6,6.6,5.3,12.3,3.8,19.3c-2.7,0-3-2.7-4.5-4
		c-1-6.1-3.3-11.7-7.6-16.3C983.1,676.5,983.2,676.3,983.3,676.1z"/>
	<path class="st2189" d="M969.2,661.2c0,1,0,1.9,0,2.9c1.7,2,4.3,3,6,5c1.5,3.4,4.8,4.1,8,5c0,0.7,0.1,1.4,0.1,2.1c0,0,0,0.1,0,0.1
		c-1,1.3-2.3,1.8-3.9,1.7c-0.5-0.1-0.9-0.3-1.4-0.5c-3.4-4.1-7.5-7.3-12.2-9.7c-1.5-0.8-3.5-1.2-3.7-3.5c0.9-1.5,2.7-1.2,3.7-1.8
		c-2.1-0.6-4.4-1.2-4.5-4.3c1.8-0.2,3.4,0.1,5,1C966.8,660.4,967.8,661,969.2,661.2z"/>
	<path class="st2190" d="M991.2,693c2.6,0.1,2.3,3.1,4,4c0,0.7,0,1.3,0,2c-0.3,2-1.4,3.9-1,6c-0.3,0-0.7,0-1,0
		c-2.5,0-2.3-2.4-3.4-3.7c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.5,0-1,0-1.4C990.2,697.2,989.4,694.8,991.2,693z"/>
	<path class="st2191" d="M907.3,872.8c0.3-0.7,0.7-1.3,1-2c2.6-0.6,4.5,1,5.3,2.8c2.6,5.7,8,7.7,12.8,10.4
		c6.6,4.6,14.2,4.9,21.8,5.6c0.3,0.5,0.3,1-0.1,1.5c-1.5,1.1-3.2,0.9-4.9,1c-2.5-0.1-5.1,0.1-7.6-0.3c-1.8-0.7-3.9-0.4-5.3-2.1
		c-0.8-1.1-2-0.9-3.1-1c-3.1-1.8-6.4-3-10-3c-0.3-1.6-0.7-3.2-2.4-3.9C910.8,880.2,909.9,875.8,907.3,872.8z"/>
	<path class="st2192" d="M913.2,888.9c0.8-0.1,1.5-0.2,2-0.9c4.3,0.7,8.5,2,12.9,2c0.4,0.4,0.4,0.9,0.2,1.4
		c-0.4,0.7-1.1,1.1-1.8,1.3c-3.2,0.7-6.1-0.6-9.1-1C915.9,890.9,913.5,891.4,913.2,888.9z"/>
	<path class="st2193" d="M928.3,890.8c0-0.3-0.1-0.6-0.1-0.8c0.7-0.1,1.4-0.1,2.2-0.2c1.7,0.3,3.5-0.4,5,0.9
		C933,892.7,930.7,892.6,928.3,890.8z"/>
	<path class="st2194" d="M913.2,888.9c1.4,0.6,2.7,1.3,4.1,1.9c-0.7,1.4-2,2.1-3.3,2.7c-1.9,0.5-3.3-0.5-4.7-1.6
		c-6.3-2.7-13.1-4.4-18.9-8.2c0.6-0.6,1.3-1.3,1.9-1.9C898.9,885.1,905.8,887.8,913.2,888.9z"/>
	<path class="st2195" d="M925.3,884.8c-1.3-0.7-2.6-1.5-3.9-2.1c-3.1-1.5-6.1-2.9-7.3-6.6c-0.9-2.7-3.6-3.8-5.7-5.3
		c-1.8-3.5,0.1-7.4-1-11c0.7,0,1.3,0,2,0c0.2,0,0.5,0,0.7,0c3.5,0.7,6.6,1.7,5.4,6.4c-0.2,0.8-0.1,1.8,0.5,2.3
		c5.3,3.8,5.9,11.4,11.5,14.9C927.2,884.7,925.9,884.3,925.3,884.8z"/>
	<path class="st2196" d="M939.4,860.7c-2,0.6-4.3-0.5-6.2,1c-0.2,0.2-0.4,0.4-0.5,0.6c-1.8,0.2-3.7,0.2-5.5,0.4
		c-2.9-1-6.7,0.4-8.9-2.9c0-0.3,0-0.7,0-1c1.1-1.2,2.6-1.2,4-1.3c3.4-0.3,6.7,1.3,10.1,1C934.9,858.9,937.9,857.4,939.4,860.7z"/>
	<path class="st2197" d="M910.2,852.9c0.4,0,0.7,0,1.1-0.1c3.5-1.9,6.5,0.5,9.8,1.1c1.5,0.6,3.1,0.7,4.3,1.9c0.2,0.6,0,1.1-0.4,1.5
		c-1.8,0.9-3.7,0.6-5.6,0.4c-1.8-1.2-4.3-0.5-6-2c-2.4-2-5.8,0.2-8.2-1.8C906.9,853.6,908.9,854.6,910.2,852.9z"/>
	<path class="st2198" d="M918.3,859.8c2.8,1.5,6.3,0.3,9.1,2c-1.2,1.5-3.1,1.2-4.7,1.7c-0.5,0.1-1,0.2-1.5,0.2
		c-4.4,0-7.9-2.9-11.9-3.8c0,0,0,0,0,0c0.4-0.9,1.3-1,2.1-1.1C913.7,859.1,916,859.4,918.3,859.8z"/>
	<path class="st2199" d="M919.5,856.8c1.9,0.1,3.8,0.2,5.7,0.3c0.2,0.2,0.3,0.4,0.2,0.7c0,0.2-0.1,0.4-0.2,0.4
		c-0.9,0.5-2-0.1-2.8,0.6c-1.4,0-2.8,0-4.1,0c-0.7-0.3-1.3-0.7-2-1C916.7,855.3,918.2,856.6,919.5,856.8z"/>
	<path class="st2200" d="M919.5,856.8c-1.1,0.2-2.4-0.2-3.1,1c-1.2-0.3-2.4-0.7-3.6-1c-0.6-0.2-0.7-0.5-0.2-0.8
		c0.2-0.2,0.6-0.2,0.9-0.3C915.5,856,917.6,855.5,919.5,856.8z"/>
	<path class="st2201" d="M900.2,722.9c0.1,1.9,4.4-0.3,3.1,3.1c-2,1.6-4,1.7-6,0c-2.4-2-7.3-1.8-6-7
		C895.3,718.1,897.1,722.1,900.2,722.9z"/>
	<path class="st2202" d="M910.3,746c-1.3,0-2.6,0-4,0c-0.4-2,1.5-1.9,2.4-2.7c1.4-0.7,2.3-2.1,3.7-2.8c0.7-0.2,1.4-0.1,2,0.3
		c0.8,0.7,0.4,1.9,2.4,2.1c5,0.6,8.7,4.4,12.8,7.2c1.2,1.5,3,2.8,1.5,5.1c-2,0-3.8-0.7-5.5-1.9C921,750.1,915.7,747.9,910.3,746z"/>
	<path class="st2203" d="M910.3,746c5.9-1.1,9.9,3,14.5,5.3c2.2,1.1,4.3,2.5,6.4,3.7c2.6,0.3,4.4,1.5,4.4,4.5
		c-0.1,0.2-0.3,0.4-0.4,0.6c-7.7-4.7-15.8-8.4-24-12C911,747.3,910.6,746.7,910.3,746z"/>
	<path class="st2204" d="M909.4,744.1c-1,0.6-2,1.3-3.1,1.9c-3.7-0.4-7.8,1.3-11-2c-1.2-1.2-2.4-2.3-3-4c-0.7-1.7-1.4-3.3-2.1-5
		c0.1-0.8,0.2-1.6,1-2c0.7,0.1,1.2,0.5,1.7,1c0.5,0.7,1,1.5,1.5,2.3c3.1,5,8.1,5.7,13.3,5.7C908.7,742.4,909.7,742.7,909.4,744.1z"
		/>
	<path class="st1897" d="M908.3,748c-0.3,0.3-0.7,0.7-1,1c-5.7,0.6-11.2,1-16.3-3c-2.6-2-4.8-3.8-5.7-7c1,0.8,2.1,1.6,3,2.6
		c3.8,3.8,8.1,6.1,13.8,5.5C904.1,746.9,906.5,746.2,908.3,748z"/>
	<path class="st2205" d="M892.3,740c1.5,0.9,2.9,2,3,4c-1.9-0.2-3.1-1.3-4-3C891.6,740.7,892,740.3,892.3,740z"/>
	<path class="st2206" d="M893.3,736c-0.3-0.7-0.7-1.3-1-2c-0.4-2-1.2-3.9-0.9-6c0-0.9,0.2-1.7,1.2-2.1c0.6,0.1,1.2,0.1,1.8,0.2
		c0.6,0.6,1.7,0.3,2.3,0.9c0.1,0.4,0.1,0.8-0.1,1.2c-0.6,0.7-1.7,1.1-1.8,2.3c-0.3,1.7,0.1,3.5-0.8,5.2
		C893.6,735.7,893.5,735.9,893.3,736z"/>
	<path class="st2207" d="M896.3,727.1c-0.7-0.4-1.4-0.7-2.1-1.1c1,0,2.1-0.1,3.1-0.1c2,0,4,0,6,0c0.4,0.1,0.8,0.1,1.2,0.2
		c0.1,0.6-0.1,1.1-0.7,1.5C901.2,728.6,898.7,728.8,896.3,727.1z"/>
	<path class="st2208" d="M891.3,728c1.1,1.9,1,4,0.9,6c-0.3-0.3-0.6-0.7-1-1c-0.3-1.7-0.6-3.3-0.9-5C890.7,728,891,728,891.3,728z"
		/>
	<path class="st2209" d="M902.3,751c2.2,2.4,5,0.1,7.6,0.9c5.7,1.9,11,4.7,16.3,7.6c0.4,0.2,0.8,0.7,1.2,0.9
		c8.3,3.1,16.6,5.3,25.8,5c6.8-0.2,13.3-2.8,20.1-2.4c-2.2,5.5-7.5,6.3-12,7c-14.2,2.2-27.6-0.8-40.3-7.3c-2.1-1.1-4.1-2.3-6.4-2.6
		c-6.3-0.6-10.9-5.3-16.8-6.7c-0.5-0.4-0.6-0.9-0.3-1.5C899,751.6,900.6,751.3,902.3,751z"/>
	<path class="st2210" d="M897.3,752c0,0.3,0,0.7,0,1c-0.3,1.3-0.8,2.5-0.9,3.8c-0.4,3-2.4,4-5.1,4.2c-1.3,0-2.7,0-4,0
		c-0.4-5.7-5.9-4.8-9-7c1.9-1.2-0.2-3.9,2.7-5.4c5.1-2.6,8.9-0.6,12.9,1.6C895,750.8,895.8,752,897.3,752z"/>
	<path class="st2211" d="M909.3,891.9c1.7,0.3,3.3,0.7,5,1c6.2-0.2,12.3,1,18.5,1.8c0.5,0.1,0.9,0.3,1.4,0.6
		c0.4,0.4,0.5,0.8,0.5,1.3c-0.8,1.2-2.3,0.3-3.2,1c-1.3,0-2.7,0.1-4,0.1c-4.7-1-9.5-2-14.2-3c-1.7-0.4-3.5-0.6-4.9-1.9
		C908.6,892.6,908.9,892.2,909.3,891.9z"/>
	<path class="st2212" d="M1010.8,666c0.5,1.7,0.6,3.4,0.2,5.1c-1.3-0.8-3-1.3-2.7-3.5C1008.5,666.1,1009.7,666.1,1010.8,666z"/>
	<path class="st2213" d="M968.3,657.9c0.3-1.6,0.7-3.2,1-4.9c1.4,1,2.8,2.3,4.8,2c0,0.5,0.1,1,0.1,1.5c0,3.4-2.2,2.4-4,1.9
		C969.6,658.3,968.9,658.1,968.3,657.9z"/>
	<path class="st2214" d="M970.2,658.5c1.4-0.6,3.4,0.2,4-1.9c0.4,0.2,0.8,0.4,1.1,0.6c-0.2,1.6-0.1,3.6-2.2,3.5
		C972.4,660.6,969.6,661.2,970.2,658.5z"/>
	<path class="st2215" d="M931.4,897.6c0.8-1,1.9-0.7,2.9-0.9c2.7-1.9,5.5-1.5,8.4-0.6c0.6,0.3,0.8,0.8,0.5,1.4
		c-1.3,0.1-2.6,0.3-3.9,0.4C936.6,897.9,934,897.8,931.4,897.6z"/>
	<path class="st2216" d="M943.2,897.6c0-0.2,0-0.5,0-0.7c-0.3-0.5-0.3-1,0.2-1.4c2.2-1.8,4.7,0.9,6.9-0.6c0.7,0.1,1.5,0,1.9,0.8
		c-1.4,1.4-3.3,1.1-5,1.6C945.9,897.5,944.6,898.1,943.2,897.6z"/>
	<path class="st2217" d="M908.2,710.1c-1.4-3-1.4-5.4-0.8-7.2c1.8-5.3,2.6-10.5,1.1-16c-0.3-0.9,0.2-1.7,1.3-1.7
		c1.3,0,1.4,1.2,1.5,2.2C911.9,694.9,910.7,702.1,908.2,710.1z"/>
	<path class="st1948" d="M912.1,702.1c0.4,0.3,0.8,0.7,1.1,1c0.9,1.4,0.9,3.1,1.3,4.7c0.2,1.9,0.2,3.8-0.3,5.7
		c-0.6,1.4-0.7,3.3-2.7,3.6c-0.4,0-0.8-0.1-1.2-0.1C912,712.2,910.3,706.9,912.1,702.1z"/>
	<path class="st2218" d="M911.5,717.1c0.5-1.1,1.1-2.2,1.6-3.3c1,0.4,1.4,1.3,1.6,2.2c0.1,1.5-0.3,2.8-0.9,4.1
		c-1.5,2.1-2.4,4.8-5.4,5.4c-0.5,0-0.9-0.2-1.3-0.6C908.9,722.5,911.2,720.3,911.5,717.1z"/>
	<path class="st2219" d="M907.2,725c0.4,0,0.7,0,1.1,0c0.3,0.2,0.5,0.6,0.5,1c-0.1,1-0.8,1.7-1,2.7c-0.1,0.8-0.3,1.6-1.1,2
		c-1.1,0.4-2.2,0.3-3.2-0.3c-0.5-0.5-0.8-1-0.7-1.7c0-1,1.3-0.8,1.5-1.6c0.1-0.3,0.2-0.5,0.2-0.8C905.3,725.4,906.1,724.9,907.2,725
		z"/>
	<path class="st2220" d="M953.2,695c0.1-2-0.8-4,0-6c3.9-1.2,7.7-3.3,12-3c3.2-1.2,6.4-0.4,9.5,0.5c-0.4,3-1,3.3-5.1,3.8
		c-2,0.2-4.7-1.5-6,1.6c-0.9,4.9-1.8,9.8-2.4,14.7c-0.9,2.2-0.5,4.6-1,6.9c-0.5,1.5-0.7,3-0.9,4.5c-0.1,0.5-0.2,1-0.3,1.5
		c-0.8,3.1-0.3,6.2-0.5,9.3c0,1.4-0.1,2.7-0.5,4c-0.6,1.2-1.1,2.6-2.9,2.3c-0.8-0.8-1-1.9-1.1-3c-0.1-1.3,0-2.6,0.2-3.9
		c1-2.4-0.1-4.8,0.1-7.2c0.3-1.7,0.7-3.3,1.1-4.9c1.2-5.9,0.8-11.9,1.5-17.8c0.1-0.9,1.5-5.4-3-3.1
		C953.7,695.1,953.5,695.1,953.2,695z"/>
	<path class="st2221" d="M950.3,664.1c0-0.4,0-0.7,0-1.1c2.2-3.7,4.2-0.7,6.2,0.2c2.5,2.8,4.9,5.7,6.5,9.2c1.4,3,3.9,4.7,6.7,6.2
		c2.4,1.3,5.4,1.6,7,4.3c0.1,0.5,0,1-0.2,1.4c-1.1,1.6-2.8,1.9-4.4,1.5c-5.7-1.5-11.6-2-14.5-9C955.7,672.5,953,668.2,950.3,664.1z"
		/>
	<path class="st2222" d="M950.3,664.1c1.5,0,2.7,0.8,3,2.2c0.9,4.8,5,7.9,6.1,12.7c0.9,3.6,12.2,6.7,16.9,5.1
		c0.3,0.3,0.5,0.7,0.5,1.1c-0.1,0.7-0.4,1.2-0.9,1.6c-0.2,0.1-0.4,0.1-0.6,0.2c-3.4,0-6.7,0.2-10-1c-1.6-3.2-5.1-1.5-7.5-2.8
		c-1.1-0.6-3.2-0.5-3.1-1.9c0.6-5.3-3.1-8.8-4.9-13.1C949.2,666.7,948.8,665.3,950.3,664.1z"/>
	<path class="st2105" d="M947.3,646.1c0.3,0.3,0.7,0.6,1,1c1.9-0.1,1.1,1.1,0.9,1.9c-0.3,0.5-0.5,1.1-0.8,1.6
		c-0.2,0.5-0.5,1.1-1.1,1.4c-1.7-1-3.3,0.5-5,0.1c0.3-4-0.1-8-0.9-11.9c0.3,0,0.7,0,1,0c0.1,1.7,2.6,2,1.9,4.4
		C943.4,647.2,946.2,645.5,947.3,646.1z"/>
	<path class="st2223" d="M956.2,664.1c-2-0.4-3.5-3.3-6-1.1c-0.7-1.3-5.5,1.6-3-3c0.6-0.5,1.3-0.8,2.1-1c2.1-0.2,4,0.6,5.9,1.2
		c1.9,1,4.7,0.9,5.2,3.8c-0.5,0.6-1.2,0.9-2.1,0.9C957.6,664.8,956.9,664.6,956.2,664.1z"/>
	<path class="st2224" d="M949.2,660c-0.6,0-1.3,0-1.9,0c0-2.7,0-5.3,0-8c0-0.6,0.1-1.2,0.1-1.8c3.4,1,1.8,3.9,2.3,6
		C949.6,657.5,949.9,658.8,949.2,660z"/>
	<path class="st2225" d="M887.3,837.9c-0.1-0.8-1.2-2.2,0.9-1.4c3.1,1.2,4.7,4.7,8.1,5.4c0.3,1,0.7,2,1,3
		C892.1,845.2,890.4,840.6,887.3,837.9z"/>
	<path class="st2226" d="M921.2,854.9c-3.3-0.7-6.6-1.4-9.9-2.1c0-0.3-0.1-0.6-0.1-0.9c-2-1.5-4.1-2.6-6.5-3.3
		c-1.6-0.8-2.9-1.9-3.2-3.8c-0.1-1.4,0.4-2.5,1.6-3.2c4.5,0.2,8.2,3.1,12.4,4.2c1.4,0.6,2.9,0.8,3.9,2c0.1,0.5,0,1-0.3,1.4
		c-2,1.4-4,0.3-5.9-0.1C916.3,850.5,920.1,850.9,921.2,854.9z"/>
	<path class="st2227" d="M902.2,844.9c0.7,1.3,1.7,2.3,3,3c0.1,1.7-0.8,1.6-1.9,1c-1-0.4-2-0.7-3-1.1c-0.7-1.7-1.9-3.1-2-5
		c0.5-0.5,1.2-0.6,1.9-0.4C901.4,842.8,901.6,844.1,902.2,844.9z"/>
	<path class="st2228" d="M903.3,848.9c0.8-0.1,1.5-0.2,1.9-1c2.5,0.5,5,1.2,6,4C908.2,851.9,905.9,850.2,903.3,848.9z"/>
	<path class="st2229" d="M900.2,843.1c-0.6-0.1-1.3-0.2-1.9-0.2c-0.2-1.6-0.7-3-2-3.9c-0.3-0.4-0.6-0.7-1-1.1
		c-0.8-1-0.5-1.7,0.6-2.3c1-0.1,1.9,0.3,2.7,0.7c1.6,1.3,3.8,1.9,4,4.4C902.1,841.8,901.3,842.6,900.2,843.1z"/>
	<path class="st2230" d="M917.2,712c4.6-3.7,3.2-8.3,2-12.9c0.7-0.4,1.3-0.8,2-1.1c1.7-0.8,3.4-1.2,4.7,0.8
		c0.2,7.4-1.3,14.7-3.1,21.8c-2.4,1.9-3.2,0.2-3.7-1.8c-0.5-2-0.3-4.3-1.7-6.1C917.4,712.5,917.3,712.3,917.2,712z"/>
	<path class="st2231" d="M925.3,699c-1-1.5-2.6-0.8-4-1c1.9-2,1.8-5.1,4-6.9c0.4,0.1,0.8,0.3,1.2,0.5c2.2,0.8,4.4,2.2,6.6-0.2
		c0.9-1,1.6,0.3,2,1.2c0.3,1.7,0.5,3.3-1,4.6c-2-0.4-3,0.7-3.9,2.2c-0.8,1.3-1.5,2.6-3.1,3C925,702.1,926.4,699.9,925.3,699z"/>
	<path class="st2232" d="M928.5,676.1c0.6,1.3,1.2,2.6,2.1,4.4c1-4.6,4.5-6.3,7.7-8.4c-2,5.6-5.6,10-10,14c-0.6,0.5-1.3,0.6-1.9,0
		C927.1,682.7,927.8,679.4,928.5,676.1z"/>
	<path class="st2233" d="M926.4,686c0.6,0,1.3,0,1.9,0c2.9-0.3,4.6-4.1,8-3c0.2,0.4,0.3,0.9,0.2,1.3c-1.9,3.3-5.2,5.1-7.9,7.7
		c-1.1,0.5-2.2,0.8-3.3,0c0-0.3,0-0.7,0-1C926.4,689.5,926.3,687.8,926.4,686z"/>
	<path class="st2234" d="M914.2,716c-0.4-0.7-0.7-1.4-1.1-2.1c0.1-1.9,0.2-3.9,0.4-5.8c1.5-1.2,2,0.2,2.8,1c0.1,0.2,0.2,0.5,0.2,0.8
		c-0.1,0.7-0.2,1.5-0.2,2.2C916.1,713.6,915.5,715,914.2,716z"/>
	<path class="st2235" d="M916.3,709c-0.9-0.3-1.9-0.7-2.8-1c-0.1-1.6-0.2-3.3-0.3-4.9C914.9,704.7,915.7,706.8,916.3,709z"/>
	<path class="st2236" d="M914.2,716c0-1.7,0.4-3.2,2.1-3.9c0.3,0,0.6,0,0.9,0c0,0,0.1,0,0.1,0c1.2,1.7,0.2,3.3-0.2,4.9
		c-0.6,1.3-0.9,2.9-2.5,3.5c-0.5,0-1-0.1-1.4-0.4C913.6,718.6,913.9,717.3,914.2,716z"/>
	<path class="st2237" d="M941.2,677.1c2.1-2,2.6-4.7,4-8.8c2.2,9.4,5.5,17.2,4,25.7c-2,1.7-2.3,4.2-3.2,6.5c-0.7,1.9-1.1,4.2-3.5,5
		c-2.4-0.3-2.3-2.3-2.6-4c-0.5-4.6,2.7-8.7,1.8-13.5c-0.5-2.5,0.1-5.1-1.2-7.6C940,679.6,938.7,677.6,941.2,677.1z"/>
	<path class="st2238" d="M943.2,705c0.8-2.7,1.5-5.4,2.6-8c0.6-1.3,0.4-4.2,3.4-3c0,0.3,0,0.7,0,1c2.5,3.7,1.4,7.5-0.3,10.8
		c-2.6,5.1-2.7,10.4-2.3,15.9c0.3,3.3,0.2,6.5-0.1,9.8c-0.2,0.6-0.5,1-1.1,1.1c-1.2,0.2-2.3,0-3-1.2c-1.9-7.1-2.3-14.2-1.3-21.5
		C941.5,708.2,941.9,706.4,943.2,705z"/>
	<path class="st2220" d="M946.2,732c-2.3-3.3-0.4-7.1-0.8-10.6c-0.5-5-1.3-10.4,2.1-15.3c2.2-3.1,1.9-7.3,1.6-11.1c1.3-1,2.7,0,4,0
		l0,0c1.4,1.5,1.6,3.4,0.8,5c-2.4,4.4-1.5,9.7-3.9,14.1c-1.1,2-0.4,4.5-0.5,6.8c-0.2,3.3-0.8,6.5-1.5,9.7
		C947.7,731.5,947.3,732.2,946.2,732z"/>
	<path class="st2239" d="M941.2,677.1c-1.4,3.2,1.7,5.5,1.9,8.6c0.4,5.4-1.9,10.2-1.9,15.3c-1.8,2.4,0.2,5.7-1.8,8
		c-2.6,0.2-2.2-2.2-2.9-3.6c-0.1-4.3,0.7-8.4,1.2-12.6c0.7-4.9,0.6-10,1.4-14.9c0.2-2.1,0.7-4.1,1.8-5.9
		C943.3,673.6,940.5,675.4,941.2,677.1z"/>
	<path class="st2240" d="M939.2,677.9c0.2,2.3-0.2,5,0,6.9c1,7-2.2,13.4-2,20.2c-1.8,0.5-2.6-0.7-3.4-2c-1.4-3.7,1.9-7.1,0.9-10.8
		c-0.2-1.6-0.2-3.1,0.4-4.6c1.1-1.7,1.3-3.7,2.2-5.5C937.9,680.8,938.6,679.3,939.2,677.9z"/>
	<path class="st2241" d="M937.3,682.2c-0.6,2,1.4,4.8-2,5.9c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-1.3,0.6-2.4,1.2-3.5c0-0.3,0.1-0.7,0.1-1
		C936.7,682.8,937,682.5,937.3,682.2z"/>
	<path class="st1964" d="M889.3,828.9c0.7,0.3,1.3,0.7,2,1c1,0.2,1.4,0.9,1.5,1.9c0,0.4-0.2,0.8-0.6,1.1c-1.4-0.2-3.3,0.6-4.3-0.6
		C886.9,830.9,889,830.1,889.3,828.9z"/>
	<path class="st2242" d="M892.2,832.9c0-0.3,0.1-0.7,0.1-1c3.5-0.9,3.9,1.4,4,3.9c-0.8,0.5-0.9,1.2-1,2
		C893.6,836.6,893.3,834.5,892.2,832.9z"/>
	<path class="st2243" d="M896.3,835.8c-1.2-1.4-1.7-3.6-4-3.9c-0.4-0.7-0.7-1.3-1.1-2c0-0.4,0.1-0.7,0.1-1.1
		c0.6,0.1,1.2,0.2,1.8,0.3c2.1,0.2,3,1.8,4.1,3.4c0.6,1.4,1.8,2.7,1,4.5C897.5,836.9,896.7,836.6,896.3,835.8z"/>
	<path class="st2244" d="M893.3,822.1c5.3,2.3,10.6,4.6,15.9,6.9c0.1,1.4-1,1.5-2,1.7c-2,0-3.9-0.7-5.8-1.1
		c-3.1-1.3-6.1-2.9-8.1-5.8C893.3,823.3,893.3,822.7,893.3,822.1z"/>
	<path class="st2245" d="M893.3,823.9c2.7,1.6,5.3,3.3,8,4.9c1.3,1.3,4.3,2.5,0,3.8c-2.6-0.8-5.5-1.3-6-4.7c-1-1.3-2-2.7-3-4
		C892.6,823.9,893,823.9,893.3,823.9z"/>
	<path class="st2246" d="M907.3,829.9c0.6-0.3,1.3-0.6,1.9-0.8c1.4,0.6,2.8,1.1,4.1,1.7c0,0.7,0,1.4,0,2.1
		C910.1,834.3,908,833.6,907.3,829.9z"/>
	<path class="st2247" d="M895.3,827.9c2,1.3,4,2.7,5.9,4c0.4,0.2,0.6,0.5,0.6,0.9c-0.2,1,0,2.1-1.3,2.5c-2.1,0.1-2.8-1.7-4-2.9
		c-1.1-1.1-2.2-2.3-3.2-3.4c0-0.4,0.1-0.9,0.1-1.3C894,827.8,894.6,827.9,895.3,827.9z"/>
	<path class="st2248" d="M949.3,891.8c1.1,2.4-0.8,1.9-2,2c-2.2,2.4-5.1,1.6-7.8,1.9c-1.7-0.9-5.2,0.1-4.9-2.7
		c0.2-2.1,3.4-0.9,5.2-1.2c0.8-0.1,1.6,0,2.5-0.1c0.5-0.1,1-0.3,1.6-0.4C945.6,891.6,947.5,890.8,949.3,891.8z"/>
	<path class="st2249" d="M939.2,894.8c2.7-0.1,5.5,0.5,8-1c0.7,1.2,2,0.9,3.1,1c-2.2,1.6-4.7,0.6-7.1,1c-3,0.6-6,0.6-9,0l0.1-0.1
		C935.5,893.2,937.4,894.4,939.2,894.8z"/>
	<path class="st2250" d="M949.3,891.8c-2,0-4,0-6-0.1c-0.2-0.2-0.3-0.4-0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.3c1.7-0.1,3.3-0.1,5-0.2
		c1-0.7,2.1-0.9,3.1,0.1C950.6,891.2,949.9,891.5,949.3,891.8z"/>
	<path class="st2251" d="M951.2,890.8c-1,0-2,0-3.1-0.1c0.1-0.3,0.2-0.6,0.3-0.8c2.2-1.1,4.5-0.7,6.7-0.2c0,0.3,0,0.7,0,1
		C953.9,890.8,952.6,890.8,951.2,890.8z"/>
	<path class="st2252" d="M955.2,889.7c-2.2,0.1-4.5,0.2-6.7,0.2c-7.9-0.7-16.3,0.4-23.2-5.1c0.4-0.8,1.2-1,2-1c2.8-1.6,4.6-0.4,6,2
		c7,3.8,14.6,2.9,22,3C955.2,889.1,955.2,889.4,955.2,889.7z"/>
	<path class="st2253" d="M929.2,862.9c0,0.2,0,0.4,0.1,0.5c4,1.1,6.2,4,7.9,7.5c-0.3,0.7-0.7,1.3-1,2c-1.5-0.9-3.2-1.1-5-1
		c-3.9-2-6.6-4.6-5.6-9.4C926.9,862.5,928.2,862,929.2,862.9z"/>
	<path class="st2254" d="M925.3,862.9c2,3,4,6,6,9c1.7,4.6,4.2,8.7,8,12c-1.7-0.3-3.3-0.7-5-1c-1.7,0.6-3.2-0.3-3.9-1.4
		c-3.4-5.4-7.2-10.5-9.2-16.6c-0.3-1,0.4-1.5,1.1-2l0.1-0.1C923.3,862.2,924.3,862.2,925.3,862.9z"/>
	<path class="st2255" d="M922.3,862.9c0.8,3.1,0.3,6.6,3.8,8.6c1,0.6,1.9,2.1,1.9,3.3c0.2,4.2,3.1,6.2,6.2,8.1c-0.3,1-0.7,2-1,3
		c-1.9-0.8-3.7-2.2-6-2c-3.3-1.6-6.1-3.5-6.9-7.5c-0.4-2.2-2-4-4-5.5c-1.8-1.3-4.4-2.7-1.8-5.8c0.7-0.9,0.3-2.6-1.5-3
		c-1.5-0.3-2.9-0.8-3.8-2.1C913.8,860,917.8,862.3,922.3,862.9z"/>
	<path class="st2256" d="M964.2,852.8c0.3,0.3,0.7,0.7,1,1c-0.7,0.4-1.3,0.8-2,1.2c-1.7,0.7-3.3,1.4-5,2c-2.5,0.2-4.9,0.7-7.1,2
		c-0.6,0-1.2-0.1-1.8-0.1c-2.5,0.5-5,1-7.4-0.3c-0.3-0.9-0.1-1.6,0.6-2.2c1.4-0.9,3-0.9,4.5-1.4c1.2-0.3,2.5-0.4,3.7-0.4
		c1.4,0,2.8,0,4.2-0.5c0.5-0.2,0.9-0.3,1.4-0.4C959,853.5,961.5,851.8,964.2,852.8z"/>
	<path class="st2257" d="M942.1,857.6c2.1,2.4,4.9,0.7,7.3,1.4c-3.1,1.6-6.6,1.3-10,1.8c-2.3-1.2-4.8-0.3-7.1-0.9
		c-0.1-2.2,1.9-2,3.1-2.7c1.3-0.4,2.5-0.5,3.8-0.4C940.3,856.7,941.3,856.8,942.1,857.6z"/>
	<path class="st2258" d="M943.2,862c-2.2,1.6-4.7,0.5-7,0.8c-1.1,0.4-2.5,1.3-2.9-0.8c0,0-0.1-0.4-0.1-0.4
		C936.6,861.8,939.9,861.9,943.2,862z"/>
	<path class="st2259" d="M933.4,862.1c0.8,1,1.9,0.7,2.9,0.8c0,0.3,0,0.6,0,0.9c-2.4,0.4-4.7-0.4-7-0.9c-1.3,0-2.7,0-4,0
		c-1,0-2,0-3,0c1.4-1.7,3.4-0.6,5.1-1C929.4,861.9,931.4,862,933.4,862.1z"/>
	<path class="st2260" d="M970.2,795.9c-0.3,0-0.7,0-1,0c-2.9-1-3.3-3.5-3.2-6.1c0.1-3.5,0.5-6.9,0.9-10.4c0.2-1.7,0.8-3.3,2.2-4.3
		c6.2-4.2,9.8-11.2,16.3-15c0.5-0.3,0.4-1.2,1-1.6c1-0.4,1.9-0.4,2.7,0.4c0,1.5-0.9,2.4-1.9,3.4c-6.7,6.9-11.8,14.6-14.7,23.9
		C971.7,789.5,969.8,792.3,970.2,795.9z"/>
	<path class="st2261" d="M989.2,759c-1,0-2,0-3,0c-0.5,0.8-1,1.5-2.1,1.3c-0.8-0.6-1.4-1.2-0.9-2.3c1.9-1.2,4-1.1,6.1-0.9
		C989.3,757.7,990.3,758.4,989.2,759z"/>
	<path class="st2262" d="M968.2,778.9c-0.3,3-0.5,6.1-0.9,9.1c-0.3,2.9-0.2,5.6,1.9,7.8c-1.5,1.9-4.1,2.5-5,5
		c-4.2-0.3-4.9-4.5-7.4-6.7c-3.3-2-3.1-4.1,0-6c1.4-0.9,2.9-1.6,3.6-3.2C962.7,782.6,964.8,779.9,968.2,778.9z"/>
	<path class="st2263" d="M958.2,792.9c1.2,3.3,3.4,5.7,6,8c-0.7,2.1-0.6,4.7-3,6c-2,2.6-4.5,3.9-7.8,2.5c-1.9-2.5-1.8-6.1-4.3-8.2
		c0.1-3.7,5.1-2.9,5.5-6.2C955.4,793.5,957.1,793.8,958.2,792.9z"/>
	<path class="st2264" d="M953.2,808.9c3,0.8,5.3-1.2,8-2c1.3,2.5,2.6,5.1,3,8c0.3,0.9-0.3,1.4-0.9,1.9c-3,1.7-5.9,1.1-8.9,0.1
		c-1.6-0.7-3-1.8-3.4-3.6C953.1,812.5,951.2,809.7,953.2,808.9z"/>
	<path class="st2265" d="M963.2,815.9c0.3-0.3,0.7-0.7,1-1c0.7,0,1.3,0,2,0c2.4,1.8,1.9,5.7,5,7c-2.1,3,1.7,5.2,1,8
		c-3.7,0.5-5.7-2-7.9-4.2C962.5,822.6,960.6,819.5,963.2,815.9z"/>
	<path class="st2266" d="M965.2,825.9c2.3,1.3,4.7,2.7,7,4c0.2,4.6,4.7,3.3,7,5c-3.4,1.4-6.6,1.1-9.8-0.7c-1.5-0.8-3.4-0.9-5.2-1.3
		c-0.2-0.1-0.5-0.1-0.7-0.2c-2.4-1.4-3.7-3.4-3-6.3C961.9,824.2,963.3,823.3,965.2,825.9z"/>
	<path class="st2267" d="M964.2,852.8c-2.6,1.1-5.2,2-8,2.1c1.6-4.4,3.4-5.2,7.7-3.6c0.1,0.2,0.3,0.4,0.4,0.6
		C964.3,852.2,964.2,852.5,964.2,852.8z"/>
	<path class="st2267" d="M967.2,848.8c0.4,0.6,0.7,1.2,1.1,1.9c-1.2,0.8-2.5,1.3-4,1.2c0,0-0.2-0.1-0.2-0.1c-1-0.6-1.4-1.5-0.5-2.4
		C964.6,848.4,965.8,847.9,967.2,848.8z"/>
	<path class="st2268" d="M975.2,839.9c-1.5,1.3-3.1,1.8-5,1c-4.7-0.9-9.1-2.7-13.3-5c-0.6-2.7,1.6-3.1,3.3-3.8c1.5-0.4,2.8,0,4,0.8
		c0,0,0,0,0,0C966.9,836.8,971.9,837.1,975.2,839.9z"/>
	<path class="st2269" d="M961.2,841.9c1.9,2.3,5.2-0.3,7,2c-0.3,0.3-0.7,0.6-1,0.9c-1.7,1.6-3.8,1.5-5.8,1.1c-1.5-1.6-2,0.2-2.8,0.9
		c-2.5,1.4-5.3,1.5-8.1,1.5c-1.8,0-3.6,0-5.4,0.5c-1.1,0.1-2.1,0.1-3.1-0.7c-0.5-0.7-0.5-1.5-0.1-2.3c1.5-1.4,3.3-1.1,5-1.2
		c0.5,0,0.9,0,1.4,0c1.3,0.1,2.6,0,3.7-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.6,0.2,0,0.7,0.4,0.1c0.1-0.1,0-0.2-0.3-0.4c0,0-0.2,0-0.5,0
		c-0.4,0-0.8-0.1-1.2-0.1c-4.7-1.8-9.6-2.9-13.6-6.2c0.6-1.6,1.9-2.3,3.5-2.7c3.1-0.2,6,0.6,9,1.5
		C954.1,837.6,958.3,838.6,961.2,841.9z"/>
	<path class="st2270" d="M967.2,848.8c-1.4,0.6-3.9,0-3.1,2.9c-3.6-1.3-5.9,0.5-7.9,3.1c-0.3,0-0.6,0-0.9,0
		c-0.7-0.2-1.2-0.6-1.3-1.4c0.2-1.3,1.1-2,1.9-2.8c2.4-1.5,5-2.6,7.6-3.6c1-0.2,2-0.1,2.7,0.7C966.5,848.2,966.9,848.5,967.2,848.8z
		"/>
	<path class="st2271" d="M960.3,845c2.3-0.1,4.6-0.1,6.9-0.2c0.1,0.3,0.1,0.6,0,1c-1.1,0.9-2.1,1.7-3.6,1.5c-1.2-0.1-2.3-0.3-3.2-1
		c-0.2-0.3-0.3-0.5-0.2-0.8C960.1,845.2,960.2,845,960.3,845z"/>
	<path class="st2272" d="M963.2,846.8c1.3-0.3,2.7-0.7,4-1c-0.3,0.7-0.6,1.4-1,2.1c-1,0-2,0-3,0c-0.1-0.2-0.2-0.4-0.3-0.5
		C963,847.2,963.1,847,963.2,846.8z"/>
	<path class="st2273" d="M961.2,841.9c-3.7-1.7-7.3-3.3-11-5c-0.8-0.6-1.8-1.2-0.7-2.4c1.7-1,3.5-0.9,5.3-0.4
		c1.1,0.3,1.7,1.1,2.4,1.8c5-0.2,8.7,3.2,13,5C967.1,840.4,964.2,841.6,961.2,841.9z"/>
	<path class="st2274" d="M891.3,761c0.4-2.3,4.6-0.8,4-4c2-1.5,3.7-1,4.9,1c1,1.7,2.2,3.2,3.4,4.7c0.9,2.4,1.3,5,4,6.3
		c0.2,7.4,3.4,13.2,9.3,17.6c1.6,1.2,2.4,3.5,3.2,5.5c-0.3,0.7-0.5,1.5-0.8,2.2c-0.4-0.4-0.7-0.8-1.1-1.2c-4.5-2.6-8.2-6.4-13.2-8.6
		c-1.5-0.7-5.4-3.1-6-7c-0.5-3.7-2.1-7-6.3-8.7C889.6,767.3,891.9,763.6,891.3,761z"/>
	<path class="st2157" d="M920.2,791.9c-3.4-2.5-5.1-6.6-8.9-8.9c-5.6-3.4-3.9-9-4-14.1c0.8-2.8,2.6-1.7,4-0.8
		c1.6,1.1,2.7,2.9,4.2,4.2c2.7,5,8.2,7.8,10.4,13.1c0.3,0.6,0.9,1,1.6,1.1c5.8,1.1,11.1,3.2,16.3,5.8c0.9,0.5,1.9,0.5,2.9,0.1
		c3.4-1.4,6.1,0,8.5,2.3c1.1,5.1-4.6,3.4-6,6c-1.7,1.7-3.6,0.9-5.4,0.2c-3.2-1.2-6.5-1.8-10-1.7c-5.3-0.3-8.2-3.2-8.9-8.4
		C923.2,790.2,921.4,789.8,920.2,791.9z"/>
	<path class="st2275" d="M925,791c0.5,0.1,1.3,0.2,1.3,0.3c-0.1,4.3,1.5,6.7,6.2,6c0.2,0,0.5,1,0.8,1.6c2.1,2.4,1.1,5.4,1.7,8.1
		c-2.2,3.9-3.5,3.8-6.7-0.1c-1.3-2.5-3.1-2.8-5.3-1.1C922.8,800.8,926.5,796.3,925,791z"/>
	<path class="st2276" d="M929.3,816.9c-0.2-1.5,0.3-2.7,1-4c2.5-1.8,4.8,0.2,7,0.7c5.9,1.4,12,1.2,17.7,3.5c3.5,2.6,3.3,5.3,0.4,8.2
		c-3.5,1.2-7.1-0.1-10.6,0.4c-4.6-2.4-8.5-6.2-14.1-6.7C929.8,819,928.9,818.2,929.3,816.9z"/>
	<path class="st2277" d="M955.2,817.9c-5.9-1-11.8-2.5-17.7-2.8c-2.8-0.2-4.6-2.5-7.2-2.2c-3.6-1-1.3-4-2-6c2,0.8,4,4.5,6,0
		c0.2-2.5,2-2.9,3.3-1.6c3.2,3.1,6.9,4.4,11.1,4.9c1.3,0.2,2.3,1.2,2.6,2.7c1,1,2,2,3,3C954.8,816.4,955.8,816.8,955.2,817.9z"/>
	<path class="st2278" d="M929.3,816.9c4.3,2.4,9.9,2,13.4,6.2c0.5,0.6,1.1,1.2,1.6,1.8c0.9,1.1,4.7-0.3,2.8,3.2
		c-6.6,1.4-12.2-1.4-17.8-4.2c-0.8-3.4-4.2-4.5-6-7C925.3,809.9,927.3,817.1,929.3,816.9z"/>
	<path class="st2279" d="M941.2,834.9c-1,0.7-2,1.3-3,2c-1.1,1.1-2.4,1.3-3.9,1.2c-0.9-0.1-1.6-0.4-2.2-1.1
		c-3.1-1.4-6.3-2.7-9.4-4.1c-1.4-0.6-3.5-0.5-3-3c1.2-0.3,2.4-0.7,3.6-1c1.2-1.1-0.4-3,1.6-4.2C929.2,829.8,935.6,831.7,941.2,834.9
		z"/>
	<path class="st2280" d="M901.3,832.8c0-0.3-0.1-0.6-0.1-0.9c3.5-0.9-0.5-2.1,0.1-3.1c2,0.4,4,0.7,6,1.1c1.4,2.3,3.5,3,6,2.9
		c2.7,0.9,5.6,1.2,7.9,3.1c-2.4,2.1-5,0.4-7.5,0.4c-1.3-0.1-2.5,0.1-3.7,0.4C906.5,836.9,904,834.8,901.3,832.8z"/>
	<path class="st2281" d="M913.2,834.9c2.7,0.4,5.3,0.7,8,1.1c3.1,1.6,6.5,1.8,10,2.1c0.2,0.3,0.3,0.5,0.3,0.8c0,0.3-0.1,0.4-0.2,0.4
		c-1.9,2.3-4.5,0.5-6.7,1.3c-2.8-0.3-5.3-1.4-7.9-2.3C915.1,837.8,913.1,837.3,913.2,834.9z"/>
	<path class="st2282" d="M931.3,838.9c0-0.3,0-0.6-0.1-0.9c0.3-0.3,0.7-0.7,1-1c0.7,0,1.3,0,2,0c1.1,0.8,3.3,0.3,3.3,2.6
		C935.1,841.8,933.2,840.5,931.3,838.9z"/>
	<path class="st2283" d="M941.2,834.9c-2.9-0.3-5.5-2.2-8.6-1.4c-1-4.2-6.5-3.8-7.9-7.6c-0.8,0.8-0.2,2.4-1.4,3c0-2-0.8-4,0-6
		c0.7-1.3,1.6-2.3,2.7-0.4c0.6,1,1.1,2,2.3,2.4c5.9,1.8,11.8,3.5,17.3,6.3c1.6,0.8,3.2,1.7,3.6,3.7c0.3,0.7,0.7,1.3,1,2
		C947.2,836.2,944.2,835.5,941.2,834.9z"/>
	<path class="st2284" d="M949.2,834.9c-4.7-3.4-9.8-5.5-15.5-6.9c-2-0.5-4-1.4-5.5-3.1c0.3-0.3,0.7-0.7,1-1c4.8,0.1,9,2.8,13.7,3.4
		c1.4,0.2,2.9,0.4,4.3,0.6c0.2,0.1,0.4,0.2,0.6,0.3c1.7,3.1,5.7,3.6,7.4,6.7C953.2,834.9,951.2,834.9,949.2,834.9z"/>
	<path class="st2285" d="M951.2,652.1c0.4,0.6,0.8,1.2,1.2,1.9c0,0-0.2,0.2-0.2,0.2c-0.8,1.4-1.8,1.9-3.2,0.9
		c-0.3-1-0.4-2.1,0.1-3.1C949.8,651.5,950.5,651.5,951.2,652.1z"/>
	<path class="st2286" d="M948.3,649c0-0.6,1-1.3-0.1-1.9c0.4-0.4,0.8-1.2,1.3-1.3c0.8-0.1,0.7,0.8,0.9,1.4c0,0.3,0,0.7,0,1
		c0.2,0.5,0.1,0.9-0.2,1.2C949.5,649.7,948.8,649.7,948.3,649z"/>
	<path class="st2287" d="M950.1,649c0-0.3,0.1-0.6,0.3-0.8c1.3,1.1,0.7,2.6,0.8,3.9c-0.6,0-1.3,0-1.9,0
		C948.6,650.8,949.5,650,950.1,649z"/>
	<path class="st2288" d="M960.5,663.9c-1.8-0.9-3.5-1.8-5.3-2.7c-0.5-1.6,0.4-2.8,1-4.1c1.7,0.3,3.3,0.7,5,1
		c1.1,2.2,3.8,2.5,5.2,4.5c-1,1.5-2.7,1.4-4.3,1.6C961.5,664.6,960.9,664.5,960.5,663.9z"/>
	<path class="st2289" d="M956.2,657.1c1.5,1.8,0,2.9-1,4.1c-2-0.4-4-0.8-6-1.2c-0.3-0.9-0.6-1.9-0.9-2.8c0.9-0.4,1.1-1.2,1-2.1
		c1-0.3,1.9-0.6,2.9-1C953.5,655.1,954.9,656.1,956.2,657.1z"/>
	<path class="st2290" d="M990.2,701c2.1,0.5,1.6,3,3,4c0,5.7,0,11.3,0,17c-0.7,0.7-1.3,1.3-2,2c-0.8-3.6-1.3-7.3-1-11
		C987.9,709,987.9,705,990.2,701z"/>
	<path class="st2291" d="M903.3,763c-2-0.3-3.4-1.7-3.7-3.3c-0.6-2.7-2.2-2.7-4.3-2.7c0.2-1.6-0.7-3.7,2-4c3.3,0.3,7.3-1.5,9,3.5
		c0.3,0.7,3.1,0.3,4.6,0.9c9.3,3.6,18.3,8.9,28,10.6c11.5,2.1,23.9,3.5,34.3-5.1c2.9-2.6,6.6-3.4,10-5c0.3,0.7,0.7,1.3,1,2
		c-0.9,2.1-3,2.7-4.7,4c-4,2.5-8.7,4-12.1,7.5c-0.4,0.3-0.8,0.6-1.3,0.8c-4,2.2-8.2,3.3-12.9,2.4c-3.4-0.7-6.9-0.2-10.4-0.3
		c-3.5,0-7,0.2-9.5-3c-1-1.2-2.7-0.9-4.2-1c-5.6-0.7-10.7-3.4-16.8-2.6C909.2,768.1,906.1,765.1,903.3,763z"/>
	<path class="st2292" d="M982.1,752c0.7,1,1.4,1.9,2.1,2.9c-4.3,0.4-8.7,0.7-13,1.1c-4.2,0.8-7.4-1.1-10.2-3.9
		C966.1,745.8,977,745.8,982.1,752z"/>
	<path class="st2293" d="M961.3,753c3.8-0.8,6.4,2.7,9.9,3c-4.8,3.4-10.8,3-16,5c-0.9-3.4,3.1-4.7,3.4-7.6
		C959.4,752.6,960.3,752.1,961.3,753z"/>
	<path class="st2292" d="M959.2,754.1c0.1,3.1-2.6,4.6-4,6.9c-5.3,1.4-10.5,3.2-16,1c-0.3-0.5-0.3-0.9,0.1-1.3
		c0.8-0.6,1.8-0.8,2.8-0.6c5.9,1.4,10.1-1.2,13.7-5.6C956.9,753.7,957.9,752.8,959.2,754.1z"/>
	<path class="st2203" d="M939.3,760.9c0,0.3,0,0.7,0,1c-1.6-0.1-3-0.7-4-2c0,0,0-0.1,0-0.1c0.5-0.6,0.9-1.4,2-1.3
		c0.9,0.2,1.6,0.5,2.2,1.2C939.6,760.2,939.6,760.6,939.3,760.9z"/>
	<path class="st2294" d="M959.2,754.1c-0.9,0.3-1.9,0.6-2.8,0.9c-1.2,0.6-1.9-0.1-2.6-1c-0.6-2.5,0.3-4.5,1.9-6.2
		c6.3,0.4,9.8-4.8,14.7-7.4c2-1.7,4.1-3.2,6.4-4.5c0.4-0.2,0.8-0.1,1.2,0.1c3.7,4.7,2.5,10.7,4,16c-7.1-4.7-15.6-4.3-20.8,1
		C960.4,753,959.6,753.1,959.2,754.1z"/>
	<path class="st2295" d="M983.2,730c0,1,0,2,0,3c-0.6,1.7,0.1,3.9-2,5c-1.3-0.7-0.9-3.1-2.9-3c-1.2-1-1.3-2.4-1.4-3.8
		c0.1-2.4-0.2-4.9,1.1-7.2c0.3-0.5,0.7-0.9,1.1-1.2C983.2,723.6,983.6,726.6,983.2,730z"/>
	<path class="st2296" d="M978.2,731.1c0,1.3,0.1,2.6,0.1,3.9c-0.1,0.3-0.1,0.7-0.2,1c-0.3,0-0.6,0.1-0.9,0.1
		c-1.4,0.9-2.9,1.7-4.7,1.3c1.3-3.6-3.1-7.1-0.3-10.7c1.3-0.8,2.8-1.1,4.3-1C979,726.8,976.8,729.5,978.2,731.1z"/>
	<path class="st2297" d="M984.2,739c3,2.6,6,5.3,9,7.9c-2.2,1.9-4.2-0.3-6.2-0.3c-1.4-0.7-2.6-1.7-3.5-2.9c-0.4-1.2-0.8-2.5-1.2-3.7
		c-0.6-1-0.6-1.9,0.6-2.3C983.7,737.4,984.3,738,984.2,739z"/>
	<path class="st2298" d="M984.2,739c-1.6-1.5-1.8-0.3-2,1c-0.8-0.4-0.9-1.2-1-2c1.1-1.5-0.9-4.2,2-5c1.7,1.7,3.3,3.3,5,5
		C987.5,740.9,985.4,738.1,984.2,739z"/>
	<path class="st2299" d="M983.4,743.7c1.3,0.7,2.5,1.5,3.8,2.2c0.3,0.5,0.2,0.9-0.1,1.3c-0.8,0.3-1.4,0.2-2-0.4
		c-0.7-0.6-1.3-1.3-2-1.9C982.3,744.3,983,744.1,983.4,743.7z"/>
	<path class="st2300" d="M994.2,749c0,0.3,0,0.6,0,0.8c-2,0.8-4,0.3-6-0.1c-0.2-0.5-0.2-0.9,0.2-1.2
		C990.4,748.1,992.4,746.8,994.2,749z"/>
	<path class="st2301" d="M988.2,749.1c0,0.2,0,0.5-0.1,0.7c-0.3,0.4-0.7,0.8-1,1.3c0.7-2.1-1.9-2.5-2.1-4.2c0.7,0.1,1.4,0.2,2.1,0.4
		C988.1,747.6,988.8,747.9,988.2,749.1z"/>
	<path class="st2302" d="M989.2,728c-1,0-2,0-3,0c-2.1-1.5-2.7-3.8-3.2-6.1c-0.3-2.9,0.4-5.7,1.3-8.4c0.4-1,0.9-1.9,1.7-2.5
		c2.4-0.1,2.1,1.6,2.2,3.1C988.3,718.7,986.3,723.5,989.2,728z"/>
	<path class="st2303" d="M988.2,714c-0.7-1-1.3-2-2-3c-0.9-3.3-0.2-6.6,0-9.9c0.2-0.5,0.4-0.9,0.8-1.3c1.5-0.8,2.4,0,3.2,1.2
		c0,0,0,0,0,0c0,4,0,8,0,12C989.5,713.4,988.9,713.7,988.2,714z"/>
	<path class="st2304" d="M984.2,722c0.7,2,1.3,4,2,6c-0.7,0.7-1.3,1.3-2,2c-0.3,0-0.7,0-1,0c-1.4-2.3-1.2-5.5-4-7
		c-0.2-1.6,0.9-1.7,2-2C982.4,720.9,983.3,721.3,984.2,722z"/>
	<path class="st2305" d="M988.2,749.1c-0.3-0.6-0.7-1.2-1-1.8c0-0.4,0-0.8,0-1.3c2.3-1.4,3.8,2,6,1c0.7,0.5,2.1,0.7,1,2.1
		C992.2,747.9,990.2,749.4,988.2,749.1z"/>
	<path class="st2306" d="M975.2,669.1c-2.8-0.6-5.5-1.6-6-5c1.3,0.1,2.6,0.5,3.9,0.4C978.2,664,977.3,666.2,975.2,669.1z"/>
	<path class="st2307" d="M976.2,685.1c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1c-0.5-1,0.1-1.7,0.7-2.4c1.3-0.8,1.4-2.3,2-3.4
		c1.4-0.4,2.9-0.7,4.3-1.1c5.7,4.2,8.1,9.9,8,16.9c-0.4,2.3,0.9,4.9-1,7c-2.7-4.2-1.9-9.3-3.9-13.7c-1.3-2.9-2.5-3.8-5-1.3
		C979.6,686,977.9,686.5,976.2,685.1z"/>
	<path class="st2308" d="M978.9,677.2c0.6,1.8,2.2,4.1-1.7,3.8c-0.7,0.6-1.6,0.7-2.2,0c-2.7-2.7-5.8-4.9-8.6-7.5
		c-1.9-1.8-1.5-3.1,0.8-4.1c5.2,0.2,7.9,4.3,11.1,7.4C978.5,677,978.7,677.1,978.9,677.2z"/>
	<path class="st2309" d="M978.3,676.9c-4-1.8-6.3-6.3-11.1-6.9c-4.2-0.2-6.9-2.6-9-6c0.8-0.1,1.5-0.1,2.3-0.2
		c0.5,0.1,1.1,0.2,1.6,0.2c4.1,2.6,8.7,4.5,12.4,7.8C976.1,673.4,977.9,674.7,978.3,676.9z"/>
	<path class="st2310" d="M981.2,684c0.8-1.1,0.9-3.1,2.9-2.8c1.6,0.3,2.5,1.5,3,3c1.5,5.2,3.1,10.3,3.1,15.8c0,0.3,0,0.7,0,1
		c-1-0.3-2-0.7-3-1c-1.2-0.9-1.2-2.3-1.5-3.6C984.7,692.1,983.7,687.8,981.2,684z"/>
	<path class="st2311" d="M943.2,890.9c0,0.3,0,0.6,0,0.8c0,0,0,0.1,0,0.1c-3.9,1-8.1-0.1-12,1.2c-1.3,0.4-2.9-0.1-3.9-1.4
		c0.3-0.3,0.7-0.6,1-0.9c2.3,0,4.7-0.1,7-0.1C938,890.8,940.6,890.9,943.2,890.9z"/>
	<path class="st2193" d="M927.3,891.7c5.3,0.1,10.7,0.1,16,0.2c-2.4,2-5.3,0.3-8,1.2c0.9,1.5,3.2,0,3.9,1.7c-1.7,0.3-3.6-0.7-5,1
		c-6.7-0.6-13.6-0.2-20.1-2.9c0.6-1.4,1.6-2.1,3.1-2.1C920.6,891.1,924,891.4,927.3,891.7z"/>
	<path class="st2312" d="M935.3,858.1c-1,0.6-2,1.1-3,1.7c-3.4,0.5-6.5-1.2-9.9-1.1c0.7-1.1,2-0.7,2.9-1c1.1-1,2.4-1.2,3.8-1.2
		c1.6,0,3.3-0.1,4.9,0.4C934.6,857.2,935,857.6,935.3,858.1z"/>
	<path class="st2313" d="M921.2,854.9c-2.9-1.9-5.8-3.8-9.3-6.1c2.7,0,5.1,0,7.4,0c1.3-0.8,2.4-0.8,3.2,0.6c1.1,0.4-2,1.2,0.3,1.7
		c2.2,0.5,5.1-0.9,6.5,2.7c0.3,0.9,4.7-1.5,6.1,1.9c-1,1.5-2.7,0.8-4,1.4c-0.7,0.2-1.4,0.2-2.2,0.2c-1.5,0.1-2.8-0.4-4-1.4
		C923.9,855.6,922.3,856.3,921.2,854.9z"/>
	<path class="st2314" d="M925.3,855.9c1.3,0.3,2.7,0.6,4,0.9c0.3,0.2,0.4,0.4,0.3,0.5c-0.1,0.2-0.2,0.3-0.3,0.3
		c-1.3,0-2.6,0-3.9,0.1c-0.1-0.2-0.2-0.5-0.2-0.7C925.2,856.7,925.2,856.3,925.3,855.9z"/>
	<path class="st2315" d="M929.3,750c-2.6,0.9-3.4-1.8-5.2-2.6c-2.8-1.2-5.1-3.7-8.3-3.9c-1.9-0.1-1.3-1.5-1.4-2.5
		c0.2-2.6,2-0.8,3.1-1.1c0.5,0.1,0.9,0.2,1.3,0.4c3.3,2,6.1,4.5,9.4,6.4c1.1,0.6,1.8,1.4,1.6,2.8C929.5,749.6,929.4,749.8,929.3,750
		z"/>
	<path class="st2315" d="M937.1,759c-0.6,0.3-1.2,0.6-1.8,0.9c-0.1-2.7-2.7-3.3-4-5c-0.7-1.7-1.3-3.3-2-5c0,0,0,0,0,0
		c1-0.6,1.7,0,2.4,0.5c0.5,0.4,1,0.9,1.4,1.4c1.3,2.1,3.3,3.7,4.4,5.9C937.6,758.3,937.4,758.7,937.1,759z"/>
	<path class="st2316" d="M909.4,744.1c-0.5-0.9-1.4-1.1-2.3-1.2c-0.3-0.9,0.1-1.6,0.5-2.3c0.5-0.9,0.8-2,1.5-2.8
		c0.4-0.2,0.8-0.3,1.2-0.3c1.5,0.4,1.9,1.6,2.2,2.9c0,0.2,0,0.4,0,0.7C911.6,742.1,911.3,743.9,909.4,744.1z"/>
	<path class="st2317" d="M917.3,741c-1,0.3-2-1.2-3,0c-0.6,0-1.1,0-1.7,0c0,0-0.3-0.1-0.3-0.1c-0.3-1.7,0.2-2.9,1.9-3.5
		c0.5-0.1,1,0,1.5,0.1C916.9,738.3,918.5,739,917.3,741z"/>
	<path class="st2318" d="M908.3,740.9c-0.4,0.6-0.8,1.3-1.2,1.9c-7.9,0.5-11.3-1.2-13.8-6.8c0,0,0.1-0.2,0.1-0.2
		c1.2-0.5,2.3-0.1,3.4,0.3c2.6,1.1,4.3,4.3,7.8,3.3C905.8,739.7,907.3,739.8,908.3,740.9z"/>
	<path class="st2319" d="M896.4,737c-1-0.4-2-0.7-3-1.1c0-2.3,0-4.6,0-6.9c1.3-0.6,1.8,0.6,2.6,1.1c0.3,0.3,0.6,0.6,0.8,0.9
		c0.2,0.6,0.3,1.2,0.2,1.8c-0.3,1,0.3,2,0.1,3C896.9,736.3,896.7,736.7,896.4,737z"/>
	<path class="st2320" d="M895.3,731c-0.6-0.7-1.3-1.3-1.9-2c0.7-1.2,1.8-1,2.9-1.1c1.5-0.8,3.1-0.3,4.6-0.4c1.1,3-1.7,2.1-2.8,3
		C897.2,730.9,896.1,730.2,895.3,731z"/>
	<path class="st2321" d="M901.3,728c-1.7,0-3.3,0-5-0.1c0-0.3,0-0.6,0-0.8c2.7,0,5.3-0.1,8-0.1c0.8,1.4-0.6,0.8-1,1
		C902.6,728.6,902,728.6,901.3,728z"/>
	<path class="st2322" d="M934.2,895.9c3,0,6,0,9,0c0,0.3,0,0.6,0,1c-3,0-6,0-8.9-0.1C934.3,896.5,934.2,896.2,934.2,895.9z"/>
	<path class="st2323" d="M913.3,720c0.3,0,0.6,0.1,0.9,0.1c0.8,1.9-0.5,3.2-1.5,4.5c-1.3,1-2.3,2.7-4.3,1.4c0-0.3-0.1-0.7-0.1-1
		C910.8,724.2,911.1,721.2,913.3,720z"/>
	<path class="st2324" d="M908.3,740.9c-1.4-0.1-3,0.5-4.1-1c-1.2-1.7,0.1-3.5-0.2-5.2c0.1-1.3-0.6-2.4-1-3.6c0-0.2,0-0.4,0-0.6
		c0.1-0.2,0.2-0.4,0.4-0.5c1.3,0,2.6,0,3.9,0c0.6-0.5,1.3-0.6,2-0.5c1.2,0.4,2.2,1,2.6,2.3c0.1,0.5,0,0.9-0.2,1.4
		c-1,1.5-1.5,3.3-2.3,4.9C909.2,739.1,909.4,740.3,908.3,740.9z"/>
	<path class="st2325" d="M909.3,730c-0.7,0-1.3-0.1-2-0.1c-0.3-0.6-0.5-1.3-0.8-1.9c2.2-1.2,5,0.5,7.2-1.2c-0.4-0.9-2-1.3-1.3-2.7
		c0.6-1.3,1.3-2.6,1.9-4c0.7-1,1.4-2,2.1-3c2.9,2.8,3.4,6.3,2.6,10.1C916.6,731.1,913.3,731.9,909.3,730z"/>
	<path class="st2326" d="M912.3,724c0.4,2,2.5,1.3,3.9,2.2c-2.9,3.6-6.3,3-9.8,1.8c0.7-0.7,1.3-1.3,2-2
		C910.1,726.1,910.9,724.5,912.3,724z"/>
	<path class="st2327" d="M901.3,728c0.7,0,1.4,0,2,0c0,0.6,0,1.3,0,1.9c0,0,0,0.1,0,0.1c-2,1.5-4,1.7-6,0
		C898.2,728.4,900.4,729.6,901.3,728z"/>
	<path class="st2328" d="M948.2,721c-0.1-3.9,1.1-7.6,1.8-11.4c1-4.9,3.4-9.5,3.2-14.6c0.3-0.5,0.8-1.1,0.9-1.6
		c0.3-0.9,0.2-2.1,1.5-2.1c1.3,0,1.1,1.3,1.6,2.1c2.3,4,0.8,8.3,0.3,12.2c-0.4,3.5,0.8,7.2-1.4,10.4c-1.7,0.8-2,2.8-3.4,4
		C951.5,721.1,950.2,722.4,948.2,721z"/>
	<path class="st2329" d="M960.1,706.7c-0.2-5.3,1.8-10.4,2.2-15.6c3.3-2.4,6.8-0.8,9.8,0c4.8,1.3,0.7,5.6,1.9,8.3
		c-0.2,1.6-0.8,3-1.7,4.3c-2.7,2.9-3.3,6.9-4.9,10.4c-2.8-0.1-2.2-2.4-2.4-4c-0.3-3.4,0.7-6.6,1.5-9.8c0.4-1.6,1.3-3.2-0.8-4.3
		c-1.9,3.6-2.1,7.5-3,11.3c-0.3,1.3,0.3,3.3-2.2,2.9C959.3,709.2,959.7,708,960.1,706.7z"/>
	<path class="st2330" d="M948.2,721c2.5-0.5,4.7-1.2,5.1-4.3c0.3-2.3,1.8-1.1,2.9-0.7c0.1,1.8,0,3.4-1,5c-1.4,2.3-0.6,4.7,0,7
		c0,1.3,0,2.7,0,4c0.1,3-2.8,3.7-4.3,3.9c-2.8,0.3-3.1-2.7-3.7-4.8C946.6,727.6,948.4,724.4,948.2,721z"/>
	<path class="st2331" d="M973.1,699.8c-0.7-2.5-1.3-5,0.8-7.6c-4.3-0.4-7.9-0.7-11.6-1c-0.1-2.4,1.1-2.4,3.2-2.3
		c3.3,0.2,7,1.3,9.8-1.8c0,0,0,0,0,0c1.6-0.5,2.1,0.6,2.5,1.7c1,4,0.4,8.3,2.1,12.2c0.1,1.3-0.3,2.4-0.9,3.5
		c-2.8,2.5-3.9-0.2-5.3-1.8C973.3,701.7,972.6,700.9,973.1,699.8z"/>
	<path class="st2332" d="M961.2,710c0.8-5.8,1.2-11.6,4-17c2.8,5.3,0.5,10.6,0.2,15.9c-0.1,2.5-0.6,4.9,1,7.1
		c0.1,2.1-1.7,2.9-2.9,4.1c-2.2,0.6-3.7-0.6-5-2.2c-0.3-1.5-0.2-2.9,0.6-4.3c0.7-0.7,0.5,2.5,1.5,0.3
		C961,712.8,959.9,711.2,961.2,710z"/>
	<path class="st2333" d="M947.2,731c4,5.1,4.8,5.2,8,1c0,1,0.1,2.1,0.1,3.1c0.3,3.5-1.5,5.8-4.5,7.4c-2.9,0.2-1.5-2.3-2.1-3.5
		c-1.8-2-3.4-4-2.5-7c0,0,0,0,0,0C946.6,731.7,946.9,731.3,947.2,731z"/>
	<path class="st2334" d="M950.3,742c2.7-1.5,3.5-4.5,5-6.9c0.6-0.8,1.1-1.6,1.7-2.4c1,0.4,1.4,1.2,1.6,2.1c-0.2,4.4-3.9,6.4-6.5,9.1
		c-0.8,0.6-1.7,1.3-2.5,2c-0.4,0.3-0.8,0.4-1.2,0.4c-1.3-0.3-2.5-0.7-2.6-2.4c0.2-0.6,0.7-1.1,1.3-1.5
		C948.2,741.8,949.4,742.7,950.3,742z"/>
	<path class="st2335" d="M961.2,710c-0.2,1.7,2.1,3.4-0.1,5c-0.5,0.3-0.8,1.8-1.8,0.6c-0.4-0.4-0.2-1.3-0.4-1.9
		c0.1-2.4-0.3-4.8,1.1-7C960.3,707.9,960,709.2,961.2,710z"/>
	<path class="st2335" d="M958.5,718c1.6,0.7,3.2,1.4,4.7,2c0.7,0.1,1.2,0.6,1.5,1.3c0.2,1-0.2,1.8-1.2,2.2c-1,0.2-1.8-0.1-2.7-0.5
		c-0.8-0.4-1.7-0.8-2.4-1.5c-0.6-0.8-0.9-1.6-0.3-2.5C958.2,718.6,958.3,718.3,958.5,718z"/>
	<path class="st2336" d="M958.1,734.9c-0.4-0.7-0.7-1.5-1.1-2.2c0.1-1.2,0.2-2.5,0.3-3.7c1-0.7-0.6-3.2,1.9-3
		c1.1,0.7,1.6,1.7,1.8,2.9c0.2,1.5-0.4,2.8-1,4.1C959.5,733.8,959,734.6,958.1,734.9z"/>
	<path class="st2328" d="M955.2,728c-3.4-2.3-2-4.7,0-7C955,723.3,957.2,725.7,955.2,728z"/>
	<path class="st2337" d="M959,726.1c-0.5,1-1.1,1.9-1.6,2.9c-0.1-3.4-0.9-6.8,0.8-10c0.4,0.7,0.8,1.4,1.1,2.1
		c0.5,1.5,0.5,3.1,0.2,4.6C959.3,725.8,959.1,726,959,726.1z"/>
	<path class="st2338" d="M958.2,664.1c3,2,6,4,9,6c0,0.8-2.1,1-0.7,2.1c3.6,3,7.1,5.9,10.7,8.8c-0.3,0.7-0.7,1.3-1,2
		c-10.2-2.6-15.3-10.6-20-18.9C956.9,664.1,957.5,664.1,958.2,664.1z"/>
	<path class="st2339" d="M977.2,689c-0.4-0.9-1-1.6-2-2c0.3-0.7,0.7-1.3,1-2c1.7,0.1,3.5,0,5-1c5.8,3.9,5,10.3,6,16c0,0,0,0,0,0
		c-1.3,1-2.3,0.4-3.2-0.5c-0.6-0.7-1.1-1.6-1.5-2.4C980.6,694.5,980.8,690.6,977.2,689z"/>
	<path class="st2340" d="M949.3,655.1c0.4,1.1,0.6,2-1,2.1c-0.4-2.3,0.9-4.9-1-7c0.3-0.4,0.7-0.8,1-1.2c0.6,0,1.2,0,1.8,0
		c-0.3,1-0.6,2.1-0.9,3.1C949.3,653.1,949.3,654.1,949.3,655.1z"/>
	<path class="st2341" d="M896.4,832.5c1.6,0.8,3.2,1.7,4.8,2.5c0.2,0,0.5,0,0.7,0c4.2,1.6,7.9,4.3,12,6c1.4,0.7,3.4,0.7,3.5,2.9
		c-1.9,0.1-2.2,1.4-2.2,2.9c-4.2-1.2-8.2-2.8-11.9-5.1c-0.5-0.1-0.8-0.4-0.9-0.9c-1.4-1.3-2.8-2.6-4.1-3.9
		C897.7,835.4,897,834,896.4,832.5z"/>
	<path class="st2342" d="M922.3,849.8c-0.7-1-1.8-0.9-2.9-1c-0.1-0.3-0.2-0.6-0.2-0.8c-0.1-0.7,1.8-1.4,0-2.2
		c-0.9-0.4-1.6-1.2-0.7-2.3c0.4-0.3,0.9-0.4,1.4-0.5c4.6,0.5,8.9,1.9,13.2,3.5c1.1,2-0.7,2.3-1.8,2.4c-2.1,0.1-3.7,1.1-5.1,2.6
		C924.5,851.8,923.2,851.4,922.3,849.8z"/>
	<path class="st2343" d="M918.3,844c-0.2,1.4,6.6,1.2,0.9,3.9c-1.5,0.1-2.8-0.3-3.9-1.2c-0.5-1.8-0.4-3.3,2.1-3
		C917.7,843.6,918,843.6,918.3,844z"/>
	<path class="st2344" d="M902.4,840.8c0.3,0.3,0.6,0.6,0.9,0.9c-0.4,1.1-0.7,2.2-1.1,3.3c-0.7-0.6-1.4-1.3-2-1.9
		C900.3,841.7,901,840.9,902.4,840.8z"/>
	<path class="st2345" d="M918.3,727c0.2-3.5-1.4-6.6-2-10c0.4-1.7,0.7-3.3,1.1-5c3,0.3,2.9,2.5,3,4.7c0.1,1.6-0.8,3.8,2,4.3
		c0.6,0.6,0.8,1.3,0.7,2.1C922.2,725.3,921.2,727.3,918.3,727z"/>
	<path class="st2346" d="M922.3,723c0-0.7,0-1.3,0-2c1-7.3,2-14.7,3-22c0.7,1,1.3,2,2,3c0.4,0.3,0.7,0.8,0.8,1.3
		c0.2,3.4-1.7,6.4-1.4,9.8c0,2.6-0.8,5.1-1.5,7.6C924.5,721.9,924,723.3,922.3,723z"/>
	<path class="st2347" d="M927.3,703c0-0.3,0-0.7-0.1-1c1.7-1.7,1.9-4.5,4.2-5.8c1.3-0.8,1.7-0.3,1.9,0.9c0.7,3.2,0.5,6.3-1.5,9
		c-1.3,0.8-2.5,0.6-3.5-0.4C927.6,705,928,703.8,927.3,703z"/>
	<path class="st2348" d="M935.3,688.1c1,1.3-0.1,2.6-0.1,3.9c-0.2,0.5-0.5,0.6-0.9,0.2c-3,3.7-6,1.8-9-0.1c1.1,0,2.3,0.2,3-0.9
		c2.5-0.5,3.7-4,6.8-3L935.3,688.1z"/>
	<path class="st2241" d="M934.3,692.1c0.3-0.1,0.6-0.1,0.9-0.2c0.7,3.8-0.7,7.4-0.9,11.1c0.9,1.2,0.4,2.2-0.4,3.2
		c-0.5,0.5-1.1,0.9-1.8,1.1c-0.8-0.1-1.3-0.5-1.1-1.4c1.4-2.8,1.9-5.8,2.3-8.9C934.6,695.6,934.1,693.8,934.3,692.1z"/>
	<path class="st2349" d="M935.1,688.1c-2.7,0-3.8,3.6-6.8,3c2.6-2.4,5.3-4.7,7.9-7.1C936.4,685.6,935.8,686.9,935.1,688.1z"/>
	<path class="st2350" d="M939.2,709c0.1-2.8-0.6-5.8,2-8c0.2,1.6-0.7,3.7,2,4c-1.8,9-0.8,18,0,27c-0.2,0.8-0.7,1.3-1.5,1.4
		c-0.5,0-1-0.2-1.4-0.4c-1.1-0.8-1.9-1.9-2.2-3.2c-0.4-4.3-1.1-8.5-1.2-12.8C936.9,714.1,937.1,711.2,939.2,709z"/>
	<path class="st2351" d="M942.3,733c0.3-0.3,0.7-0.7,1-1c1,0,2,0,3,0c1.1,2.5,1.6,5.3,4,7C945.6,739.6,942.9,737.5,942.3,733z"/>
	<path class="st2352" d="M939.2,709c-2.1,7.3,0.5,14.6,0,22c-2.1,0.7-3.4-0.5-4.8-1.8c-2.9-7.6-2.8-15.4-1.2-23.2c0.3-1,0.7-2,1-3
		c1,0.7,2,1.3,2.9,2C938.9,705.9,937.6,708.2,939.2,709z"/>
	<path class="st2353" d="M901.3,832.8c3.5-0.4,5.5,3.4,8.9,3.2c3.8,0.9,7.7,1.3,10.1,5.1c-2.2,1.6-4.4,0.3-6.5-0.2
		c-4.6-1-8-4.6-12.5-5.9c0,0,0,0,0,0C901.3,834.3,900.2,833.5,901.3,832.8z"/>
	<path class="st2354" d="M947.6,856c-1.3,1-2.8,0.9-4.3,0.8c-1.5-0.5-3,0.3-4.5-0.3c-2.1-0.5-3.5-2.3-6-2.7c2.6-4,6-1.7,8.8-2.3
		c2.6,0.1,5.2-0.3,7.7,0.5c1.5,0.8,1.2,1.6,0,2.5C948.8,855,947.1,854.3,947.6,856z"/>
	<path class="st2355" d="M947.6,856c-0.5-0.4-2.6-0.1-0.7-1.4c1-0.7,2.2-1.2,3.4-1.8c0.5-1,1.4-1.5,2.4-1.8c1.3-0.3,2.6-0.3,3.6,0.8
		c-0.7,0.7-1.3,1.4-2,2.1c-0.5,1.6-2.1,1.2-3.2,1.8C949.9,855.8,948.7,855.9,947.6,856z"/>
	<path class="st2356" d="M939.3,855.8c1.4,0.2,2.9-0.4,4,1c-0.4,0.2-0.7,0.5-1.1,0.7c-1,0-2,0-3,0c-1.7-0.5-3.4-0.2-5.1-0.3
		c-0.9-0.1-2,0.3-2.8-0.6c1.1-1.3,2.6-0.8,3.9-0.9C936.6,855.1,937.9,855.2,939.3,855.8z"/>
	<path class="st2267" d="M951.1,855.7c1.1-0.6,2.1-1.2,3.2-1.8c0.3,0.3,0.7,0.7,1,1C954.1,856.2,952.6,855.8,951.1,855.7z"/>
	<path class="st2357" d="M934.2,856.9c1.6,0.1,3.4-0.6,4.9,0.7c-1.3,0.2-2.6,0.3-3.8,0.5c-0.4-0.1-0.7-0.3-1.1-0.4
		c-0.3-0.2-0.4-0.4-0.3-0.5C934,857,934.1,856.9,934.2,856.9z"/>
	<path class="st2358" d="M979.2,763c1.7-1,3.3-2,5-3c0.7-0.3,1.3-0.7,2-1c-0.7,4.4-5.3,5.4-7.5,8.7c-2,3-4.8,5.8-8.2,7.7
		c-1.3,0.8-2.3,1.9-2.3,3.6c-1.8,2.6-4.3,4.4-7,6c-2.1-0.5-4.4-0.5-6.3-1.9c-0.5-3.5,2.8-8,7.1-9.5c1.9-0.6,3.7-1.2,5.2-2.6l0,0
		C971.4,768.6,975.5,766.1,979.2,763z"/>
	<path class="st2359" d="M955.2,782.9c2,0.7,5-1.6,6,2c0.2,2.5-0.3,4.2-3.4,4.1c-1.4-0.1-2.6,0.9-2.4,2.5c0.2,1.6,1.7,1.2,2.8,1.4
		c-1,0.7-2,1.3-3,2c-9.8,0-18.5-5-27.9-6.6c-0.5-0.1-1.2-0.3-1.3-0.6c-2.2-6-9.1-8.4-10.8-14.7c1.2-1.4,3-1.9,4.5-1.5
		c5.9,1.5,12,1.7,17.6,4.2c3.8,1.7,7.4,3.8,11.6,4.2C951.4,780.1,953.4,781.4,955.2,782.9z"/>
	<path class="st2360" d="M951.2,812.9c-1.6-0.9-2.8-2.3-4.9-2c-3.9,0.7-6.8-1.5-8.8-4.2c-1.7-2.3-2.3-0.6-3.3,0.2
		c-2-2.5-0.6-5.3-1-8c3.6-2.2,7-0.3,10.3,0.8c1.9,0.6,3.6,1.4,5.7,1.2c4.3,1.2,4.1,4.6,4,8C952.5,810.2,953.8,812.5,951.2,812.9z"/>
	<path class="st2361" d="M955.2,817.9c-0.3-0.7-0.7-1.3-1-2c3,0.3,6,1.7,9,0c-0.9,3.6,1.1,6.7,2,10c-1.7-1-3.1-1.1-4,1
		c-2.5,0.8-4.4-0.1-6-2C956,822.6,957.4,820.2,955.2,817.9z"/>
	<path class="st2362" d="M955.2,824.9c2,0.7,4,1.3,6,2c-0.6,2.8,2,4,3,6c-1.3,0-2.7,0-4,0c-4.8-0.5-8.3-4.3-13-5c0,0,0,0,0,0
		c0.2-2.2-2.3-1.7-3-3C947.9,823.6,951.6,824.6,955.2,824.9z"/>
	<path class="st2363" d="M947.2,827.9c5.6-1.7,8.8,2.9,13,5c-0.7,1.3-3.2,0.8-3,3c-0.8,0-1.6-0.2-2-1
		C952,833.2,948.1,832.3,947.2,827.9z"/>
	<path class="st2364" d="M913.3,839.9c2.4-0.2,4.6,1.5,7.1,0.9c3.6-1.1,6.9,0.4,10.3,1.1c4.9,1.4,9.9-0.1,14.8,0.9
		c1.3,0.5,2.7,1,2.8,2.8c-2,0.1-4,0.2-6,0.2c-2.7,1.9-5.6,1.3-8.5,0.7c-4.8-1-9.4-2.9-14.4-3c-0.4,0.1-0.7,0.3-1.1,0.4
		c-0.3-0.1-0.6-0.2-0.9-0.2c-1.3-0.7-2.7-1.5-4-2.2C912.7,841.1,912.7,840.5,913.3,839.9z"/>
	<path class="st2365" d="M919.3,843.6c2.8-1.9,5.3,0.3,7.9,0.8c5,1,9.9,1.6,15,1.5c0,0.6,0,1.1,0,1.7c-0.8,1.4-2.1,1.4-3.5,1.5
		c-1,0-2.1-0.1-3.1-0.4c-1-0.3-1.8-0.8-2.5-1.6C928.5,846,923.9,844.8,919.3,843.6z"/>
	<path class="st2366" d="M937.3,838.9c-1.1-0.6-2.1-1.3-3.2-1.9c1.4,0,2.7-0.1,4.1-0.1c5,2,10,4,14.9,6c-1.3,1.2-2.9,1.3-4.5,1.1
		c-1.3-0.2-2.3-1.1-3.6-1.2C942.5,841.4,939.3,841.4,937.3,838.9z"/>
	<path class="st2367" d="M960.1,845.8c1.1,0.1,2.3-0.2,3.1,1l0.1,0.3c-3.4,2.1-7.1,3.5-11.1,3.6c-1-0.2-1.7-1-2.1-1.9
		c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.5,0.1-0.9,0.6-1.1c3,0.2,5.9-0.5,8.8-1.3C959.5,845.3,959.8,845.4,960.1,845.8z"/>
	<path class="st2367" d="M938.3,848c1.3-0.1,2.6-0.2,3.9-0.4c1.1,0,2.1,0,3.2,0c1.6-0.3,3.1-0.4,4.6,0.1l0.3,0.4
		C947,851.2,941.3,851.2,938.3,848z"/>
	<path class="st2368" d="M948.3,842.8c1.6,0,3.3,0,4.9,0c0,0,0.1,0,0.1,0c0.3,0.2,0.4,0.4,0.3,0.6c-0.1,0.2-0.2,0.3-0.3,0.3
		l-0.1,0.1c-1.2,0.9-2.6,1.3-3.9,1.8c-0.3,0-0.7,0-1-0.1c-0.7-0.6-1.4-1.2-2-1.8C946.6,843.1,947.7,843.5,948.3,842.8z"/>
	<path class="st2153" d="M960.1,845.8c-0.3,0-0.6,0-0.9-0.1c-0.5-0.6-1.1-1.2-1.6-1.8c0.3-0.3,0.7-0.8,1-0.8c1.4-0.1,1.5,1,1.7,2
		C960.3,845.3,960.2,845.6,960.1,845.8z"/>
	<path class="st2365" d="M953.3,843.9c0-0.3-0.1-0.6,0-1c0.9,0.1,1.9-0.3,2.5,0.7c0.1,0.2-0.2,0.8-0.2,0.7
		C954.8,844.3,954,844.1,953.3,843.9z"/>
	<path class="st2365" d="M949.2,845.7c1.1-1.1,2.4-1.7,3.9-1.8C952.4,845.7,951,846.1,949.2,845.7z"/>
	<path class="st2365" d="M950,847.6c-1.6,0-3.1,0-4.6-0.1c1.5-1.3,3.3-0.6,5-0.6C950.3,847.2,950.2,847.4,950,847.6z"/>
	<path class="st2369" d="M951.2,849.7c4.3,0.3,8-2.2,12.1-2.6c0,0.3-0.1,0.5-0.1,0.8c-1.8,2.2-4.9,2.2-7,3.9c-1.3,0-2.7,0.1-4,0.1
		c-1,0.7-1.8,0.4-2.4-0.6c-0.1-0.5-0.1-1,0.1-1.4C950.3,849.6,950.7,849.5,951.2,849.7z"/>
	<path class="st2261" d="M955.2,782.9c-6.7-1.5-13.6-2.9-19.6-6.6c-0.6-0.3-1.1-1.2-1.5-1.1c-6.6,1.6-12.2-4.5-18.8-2.3
		c-2,0-2.6-1.7-3.6-2.9c-1.1-1.3-2.3-3.2-4.4-1.1c-5.3,0.6-3.8-3.3-4-6c1,0.1,2.3-0.3,2.9,0.2c4.3,3.4,8.7,5.4,14,2.5
		c3.6,5.8,12.2-0.2,15.6,6.5c0.6,1.1,2.1,0.7,3.2,0.8c5.9,0.4,11.9-0.5,17.8,0.8c3.6,0.8,6.7-2.5,10.3-2.8c-0.3,0.7-0.6,2.1-0.9,2.1
		c-4.7-0.2-6.1,4.6-9.8,6.1C954.7,779.9,955.4,781.6,955.2,782.9z"/>
	<path class="st2370" d="M930.2,842.9c-3.3-0.7-6.6-1.4-9.8-2.1c-3.4-1.6-6.8-3.2-10.1-4.8c0.7-1.3,1.9-1.1,3-1.2
		c3.4,3.1,8,3.3,12.1,5c1.9-1.1,3.5,1.2,5.3,0.5c1.4,0.2,2.8,0.4,3.9,2.1C933.1,843.7,931.7,843.5,930.2,842.9z"/>
	<path class="st2371" d="M930.3,840.9c-1.7-0.4-3.3-0.7-5-1.1c1.7-2.3,4.3,1.2,6-1c2-0.7,4,2.2,6,0c2.7,0.8,5.6,1.3,7.9,3
		c-3.1,1.8-6.4,1.4-9.4,0.5C934,841.8,932,841.7,930.3,840.9z"/>
	<path class="st2261" d="M979.2,763c-3.5,3.4-6.3,7.8-12,8C969.7,766,975.2,765.7,979.2,763z"/>
	<path class="st2372" d="M954.1,753.8c0.8,0.4,1.6,0.8,2.3,1.2c-2.5,4.8-8.1,7.5-13.3,6.5c-1.3-0.3-2.6-0.4-3.8-0.6c0-0.3,0-0.6,0-1
		c0.5-1.4,1.5-2.3,2.9-2.7c1.8-0.3,3.5,0.6,5.3,0.3c1.6-0.2,2.8-1,3.9-2.1C952.2,754.8,952.8,753.7,954.1,753.8z"/>
	<path class="st2373" d="M942.2,758c-1,0.6-1.4,2.1-2.9,2c-0.9,0-1.7-0.1-2.2-0.9c0-0.3,0.1-0.7,0.1-1c-1-2.1-3.1-3.4-3.7-5.7
		c-0.5-2.1-1.2-4.2,1.4-5.5c2.2,1.6,2.3,4.6,4,6.5C939.8,755,942.5,755.5,942.2,758z"/>
	<path class="st2374" d="M954.1,753.8c-0.6,0.7-1.2,1.4-1.8,2.1c-1.5,0-3-0.9-2.6-2.2c0.8-2.7-0.1-5.2-0.5-7.7l0.1-0.3
		c0.9-0.6,2-0.7,3-0.5c1.5,0.7,3.9,0.2,4.1,2.8C955.6,750,954.8,751.9,954.1,753.8z"/>
	<path class="st2375" d="M956.4,748c-1-1.6-3.2-0.6-4.3-1.9c0.1-0.2,0.3-0.4,0.4-0.6c1.3-0.8,2.7-0.7,4.2-0.4c1.6,0.6,3,0.3,4.3-0.8
		c2-1.1,3.1-3.3,5.3-4.1c0.5-0.2,0.9-0.3,1.4-0.3c1.3-0.1,2.5,0,3.4,1.1c-2.5,2-5.5,3.5-7.6,6C961.5,749.6,958.8,748.1,956.4,748z"
		/>
	<path class="st2376" d="M971.2,741c-1.3,0-2.7,0-4,0c-0.3-0.4-0.4-0.8-0.3-1.2c0.8-1.4,1.9-2.6,3.4-3.3c0.7-0.2,1.4-0.1,1.9,0.5
		c1.7-0.3,3.3-0.6,5-0.9C975.9,738.6,973,739.1,971.2,741z"/>
	<path class="st2377" d="M974.2,702c1.5,0.4,1.3,4.1,4,2c5.7-0.2,3.7,4.5,4.6,7.4c0,0.3-0.1,0.6-0.1,1c-0.3,2.3-3.9,4.3-0.6,7.1
		c0.5,0.5-0.2,1.8-0.9,2.5c-0.8,0-1.6,0.2-2,1c0,0.3,0,0.7,0,1c-4.6-2-2.4-6.4-3.4-9.7c-0.4-1.5,0.4-3.3-1.3-4.4
		c-0.4-0.3-0.7-0.7-0.9-1.1C973,706.4,971.1,704,974.2,702z"/>
	<path class="st2378" d="M975.2,709c3.1,0.6,1.8,3,1.9,4.7c0.2,3.5-0.2,7.1,2.1,10.2c-1.4,2.2-0.8,4.7-1,7.1c-2-1.3-0.3-3.8-1.8-5.3
		c-0.5-0.3-0.8-0.7-1.1-1.3c-0.6-3.6-1.5-7.1-1.5-10.7C973.8,712.1,973.9,710.4,975.2,709z"/>
	<path class="st2379" d="M972.2,737c-0.7,0-1.3,0-2,0c-1.6-1.5-1.2-3.4-0.8-5.1c0.9-4.1-0.1-7.8-1.7-11.5c-0.9-1-1.6-2-1.5-3.3
		c-1.5-1-3.1-1.6-2.2-4.4c1.9-6.3,1.2-13,1.2-19.5c2.6,2.1,4.4,3.7,2.5,8c-1.7,3.7-3,8.5-0.5,12.9c1.6-1.7,3.5-2.5,5.8-1.5
		c0.5,0.3,0.8,0.7,1,1.2c0.3,3.5,0.7,7-0.4,10.4c-0.1,1.1-0.7,2.1-1.4,2.9c0.2,2.1-0.4,4.1,1.7,5.9C976.4,735.2,972,735.3,972.2,737
		z"/>
	<path class="st2380" d="M972.2,727c0-1,0-2,0-3c1.7-1.3,2.8,0,4,1c0.2,0.2,0.2,0.5,0.2,0.8C975.4,727.4,973.7,726.9,972.2,727z"/>
	<path class="st2381" d="M984.2,699c1,0.3,2,0.7,3,1c-0.3,3.7,0.8,7.5-1,11c0,0.8-0.2,1.6-1,2c-2,0.2-2.1-1.5-2.7-2.7
		C982.6,706.5,980.3,702.3,984.2,699z"/>
	<path class="st2382" d="M983.2,711c0.7,0.7,1.3,1.3,2,2c-0.3,3-0.7,6-1,9c-1,0-2,0-3,0c-0.1-3.4-3.3-7,1-10
		C982.4,711.6,982.6,711.1,983.2,711z"/>
	<path class="st2383" d="M934.2,856.9c0,0.3,0,0.5,0,0.8c-1.6,0-3.3,0-4.9,0c0-0.3,0-0.6,0-0.8c0.7,0,1.4-0.1,2-0.1
		C932.3,856.8,933.2,856.9,934.2,856.9z"/>
	<path class="st2384" d="M939.3,855.8c-1.3,0-2.7,0-4,0c-4.1-1.3-8.2-2.9-12.5-3.8c-2.9-0.6-0.9-1.4-0.5-2.3
		c1.3,0.7,2.6,1.3,4.1,0.7c6-2.4,12.1-0.2,18.2-0.6c0.9,0,1.8,0,2.5,0.6c0.8,1.3-0.1,1.6-1.1,1.8c-1.5,0.3-3.2-0.4-4.7,0.5
		c-2.3,0.6-4.7-1-7.5,0.3C935.8,854.2,937.5,855,939.3,855.8z"/>
	<path class="st2317" d="M931.3,751c-0.7-0.3-1.3-0.7-2-1c-1-3.7-5.2-3.5-7.3-6c-1-1.2-2.5-2-3.7-2.9c0.6-3.1,2.7-2.7,4.9-2.3
		c2.2,2.2,4,4.7,6.9,6.4C932.1,746.2,933.2,748.5,931.3,751z"/>
	<path class="st2385" d="M923.3,739c-1.8,0.4-3.6,0.6-5,2c-0.3,0-0.7,0-1,0c0.2-1.6-1.4-2-2-3c1.1-2.3,3.6-2.2,5.5-2.7
		c2.1-0.5,3.5,0.6,3,3.1C923.6,738.6,923.4,738.8,923.3,739z"/>
	<path class="st2386" d="M931.3,751c0.2-2.9-0.5-5.1-3.6-6.1c-2.9-0.9-3.9-3.3-4.4-5.9c0,0,0,0,0,0c1.8-1.5,3.4-0.9,4.8,0.6
		c1.3,1.3,2.5,2.7,4,3.8c1.8,2.3,1.2,4.8,1.1,7.4c-0.1,0.5-0.4,1-0.8,1.3C932,751.7,931.6,751.3,931.3,751z"/>
	<path class="st2387" d="M932.3,752c0.1-0.4,0.1-0.8,0.2-1.2c0.5-2,0.3-3.9,0.1-5.9c-0.1-1.7,0.6-2.5,2.3-2.3
		c0.8,0.5,1.1,1.2,1.2,2.1c0,0.9-0.2,1.7-1,2.2c-1.9,3-0.7,5.6,0.9,8.3c0.5,0.8,2.4,1.2,1.1,2.7C934.7,756.7,933.4,754.5,932.3,752z
		"/>
	<path class="st2388" d="M914.2,737.9c-0.6,1-1.3,2-1.9,3c-0.7-1-1.4-2-2.1-3c-0.6-1.1-0.7-2.2,0.4-3.1c1.4,1.1,1.4-1.7,2.7-1
		c1,0.8,1.2,1.8,1.5,3C914.7,737.2,914.6,737.6,914.2,737.9z"/>
	<path class="st2389" d="M911.2,734.9c-1,0.8-0.9,1.9-1.1,3c-0.3,0.1-0.5,0.1-0.8,0.2c-0.4-2-0.9-4.1,1.8-5
		C911.9,733.6,911.8,734.3,911.2,734.9z"/>
	<path class="st2390" d="M914.2,737.9c0-0.3,0-0.6,0.1-1c-0.8-1-0.4-1.8,0.5-2.5c3.7-0.7,7.2-2.6,11.2-2.4c4.1,2,4.5,6.6,6.7,9.9
		c-0.1,0.8-0.5,1.3-1.4,1c-2.4,1.2-3.4-1.6-3.8-2.3c-1.2-2.1-2.7-1.5-4.3-1.7c0.1-2.2-0.9-3.9-3.1-2.6c-1.6,1-3.5,0.3-4.9,1.6
		C914.9,738,914.6,738,914.2,737.9z"/>
	<path class="st2391" d="M905.3,734c0.4,2.1-0.5,4-1.1,6c-4,2.7-5.3-1.8-7.8-3c0-0.3,0-0.7,0-1c0.3-0.3,0.8-0.5,1.2-0.5
		c1.6-0.5,4.5,4.1,4.9-1.2c0.1-0.5,0.3-0.9,0.6-1.3C904.2,732.4,904.7,733.3,905.3,734z"/>
	<path class="st2392" d="M903.3,733c0,0.3,0,0.7,0,1c-1.7,1.5-4.2,0.7-6,2c-0.3,0-0.6,0-0.9,0c0.2-1-1.3-2-0.2-3
		C898.6,730.3,900.9,729.9,903.3,733z"/>
	<path class="st2393" d="M903.3,733c-2.3-1.4-4.7-1.2-7.1-0.1c0-0.6,0.1-1.3,0.1-1.9c2.3-0.9,4.6-0.7,6.9-0.1c1.3,0.6,2,1.6,2,3
		C904.6,733.7,903.9,733.3,903.3,733z"/>
	<path class="st2394" d="M903.3,731c-2.3,0-4.6,0-6.9,0.1c-0.3,0-0.7,0-1,0c0.4-0.8,1.2-1,2-1c2,0,4,0,6,0
		C903.3,730.3,903.3,730.6,903.3,731z"/>
	<path class="st2395" d="M922.3,723c0.7-0.7,1.3-1.3,2-2c1.6,1.5,0.7,3.4,0.6,5.1c-0.2,0.9-0.6,1.8-1.3,2.4
		c-0.7,0.7-1.5,1.1-2.4,1.5c-2.7,0.8-4.8,3.3-7.9,2.9c-0.8-0.1-1.4-0.4-2-0.9c-0.7-0.7-1.4-1.4-2-2.1c3.5,0.5,6.5-0.5,9-3
		C920,726.1,921.3,724.7,922.3,723z"/>
	<path class="st2396" d="M911.3,732.1c0.6,0,1.3,0,1.9-0.1c0.6,0.7,0.5,1.3-0.1,1.9c-0.9-0.1-0.5,2.2-2,0.9c0-0.6,0-1.2-0.1-1.9
		C911.2,732.7,911.3,732.4,911.3,732.1z"/>
	<path class="st2397" d="M973.4,712.8c-2.1,0.4-4.1,0.8-6.2,1.1c-0.5-4.3,1.4-7.8,4.2-10.9C972.8,706.2,973.2,709.5,973.4,712.8z"/>
	<path class="st2378" d="M973.4,712.8c-3.2-2.7-2.1-6.4-2-9.8c0.6-1.1,1.2-2.2,1.8-3.3c0.4,0.7,0.7,1.5,1.1,2.2
		c-1.6,2.3,0.6,4.7,0,7c0.9,1.2,0.4,2.5,0.4,3.8C974.2,713.3,973.7,713.3,973.4,712.8L973.4,712.8z"/>
	<path class="st2398" d="M977.2,689c1.6-0.9,3.1-1.2,3.8,1.1c0.7,2.3,1.4,4.6,2.1,6.9c-0.8,1.9-1.4,3.9-4,4
		C976.2,697.4,977.5,693.1,977.2,689z"/>
	<path class="st2399" d="M979.2,701c1.5-1.2,2-3.3,4-4c0.8,0.4,1,1.2,1,2c-1.4,3.9-0.9,8-1,12c-0.3,0.3-0.7,0.7-1,1
		c-1.9-2.4,0.5-6.9-4-8C978.5,703,978.9,702,979.2,701z"/>
	<path class="st2400" d="M966.2,717c0.6,0.7,1.3,1.4,1.9,2.1c1.2,1.4,1.9,3,1.3,4.9c-1.3,1.3-2.5,2.6-4.5,2
		c-1.6-1.4,0.6-3.5-0.8-4.9c-0.3-0.3-0.7-0.7-1-1C963.3,718.1,964.2,717,966.2,717z"/>
	<path class="st2401" d="M942.3,733c1.6,3.4,4.2,5.5,8,6c1.1,1-2.3,2.1,0.1,3c-0.8,1-1.9,1-3.1,1c-6.5-0.9-9.7-4.5-8.6-9.5
		c0.7-0.9,1.5-1.3,2.6-0.5C941.6,733,941.9,733,942.3,733z"/>
	<path class="st2402" d="M939.2,734c-1.5,4.9,3.5,5.3,5.6,7.7c0.6,0.6,1.9,0.3,2.4,1.3c-0.3,0.3-0.7,0.6-1,0.9
		c-1.9,1.5-3.8,0.1-5.8,0.1c-2.2-0.5-4.1-1.7-6.2-2.6c-0.4-0.3-0.7-0.7-0.9-1.2c-0.2-0.5-0.4-0.9-0.5-1.4c-0.4-1.6-1.5-2.9-1.4-4.7
		c0.1-1.7,0.4-3.4,1.9-4.3c1.8-1.1,2.5,0.9,3.6,1.6C938,732.1,938.8,732.9,939.2,734z"/>
	<path class="st2403" d="M940.2,742.9c2.1-0.3,4,0.5,6,1c1,0.7,2,1.4,2.9,2.1c0.7,2.2,3,4,1.3,6.7c-0.9,1.6,1.1,2.1,1.8,3.1
		c-0.7,1.7-2.3,2.3-3.9,2.7c-1.1-0.8-1.2-2.1-1.3-3.3c-0.1-0.9,0-1.9-0.1-2.8c-0.1-1.3-0.4-2.5-1-3.7c-1.1-1.9-3.3-1.8-5-2.7
		C940.2,745.1,939.5,744.2,940.2,742.9z"/>
	<path class="st2404" d="M958.1,734.9c0.3-0.7,0.7-1.3,1-2c0.5-0.4,0.9-0.3,1.4,0.1c1.4,2.4,0.4,4.5-1.1,6.4c-2.1,2.4-4,4.9-7.2,5.8
		c-0.9-0.1-1.3-0.4-1.1-1.4C953.3,740.8,957,738.9,958.1,734.9z"/>
	<path class="st2405" d="M951.1,743.9c0.4,0.4,0.7,0.8,1.1,1.1c0.2,0.1,0.3,0.3,0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.3
		c0,0-0.2,0.3-0.2,0.3c-1-0.1-1.9-0.3-2.9-0.4C949.3,744.6,950,744,951.1,743.9z"/>
	<path class="st2406" d="M964.2,721c1.8,1.3,0.8,3.2,1.1,4.9c1.3,2.7-0.6,3.7-2.5,4.6c-0.5,0.1-1,0-1.5-0.2
		c-0.3-0.4-0.5-0.8-0.5-1.3c0.3-2.2,1-4.1,2.4-5.8C963.9,722.7,964.2,721.9,964.2,721z"/>
	<path class="st2407" d="M963.2,723.2c0.6,2.4-0.7,4.1-1.9,5.9c-0.3,0.4-0.7,0.4-1,0c-0.4-1-0.9-1.9-1.3-2.9
		c0.2-0.1,0.3-0.2,0.4-0.2c0.3-1.3,0.5-2.8,1.7-3.7C961.8,722.5,962.5,722.8,963.2,723.2z"/>
	<path class="st2408" d="M961.2,722.2c0.2,1.6,0,3-1.7,3.7c-1.5-1.6-1.4-3.2-0.2-4.8C959.9,721.4,960.5,721.8,961.2,722.2z"/>
	<path class="st2409" d="M960.3,729c0.3,0,0.7,0,1,0c0,0.4,0.1,0.7,0.1,1.1c0.7,0.9,0.8,1.8,0.2,2.8c-0.4,0.4-0.8,0.5-1.3,0.1
		c-0.4,0-0.7-0.1-1.1-0.1C959.5,731.6,959.9,730.3,960.3,729z"/>
	<path class="st2247" d="M913.3,839.9c0,0.6,0.1,1.1,0.1,1.7c-4.4-1.6-8.7-3.4-12.2-6.6c2.1-1.3,3.1,0.6,4.6,1.3
		C908.3,837.5,910.3,839.7,913.3,839.9z"/>
	<path class="st2410" d="M945.3,850.8c-6.3,0.7-12.6,0.2-18.9-0.3c0.5-4.7,5-1.4,6.8-3.4c0.8-0.1,1.6-0.1,2.2,0.6
		c2.8,2.8,6.5,1,9.7,1.8c0.3,0.2,0.4,0.5,0.3,0.8C945.4,850.6,945.3,850.8,945.3,850.8z"/>
	<path class="st2411" d="M924.3,726c0-1.7,0-3.3,0-5c0.3-2.7,0.7-5.3,1-8c0.6-0.3,1-0.2,1.5,0.3c1.5,2.9,0.7,6,0.8,9
		c0,0.8-0.2,1.6-0.6,2.4C926.3,725.7,925.6,726.4,924.3,726z"/>
	<path class="st2231" d="M926.3,713c-0.3,0-0.7,0-1,0c-0.2-3.5,1.4-6.7,2-10c0.4,0.7,0.7,1.3,1.1,2c0.2,0.4,0.3,0.9,0.3,1.4
		C928.3,708.8,928.2,711.2,926.3,713z"/>
	<path class="st2412" d="M928.2,706.2c0.1-0.4,0.1-0.8,0.2-1.2c0.9,0.3,1.8,0.6,2.7,0.9c0.4,0.4,0.7,0.8,1.1,1.2
		c1.3,2.6,0.7,4.6-1.7,6.1C928,711.4,927.4,709,928.2,706.2z"/>
	<path class="st2413" d="M930.3,713.2c1.3-1.8,1.3-4,1.8-6c0.4-0.4,0.8-0.8,1.1-1.2c1.8,3.8,0,7.7,0.5,11.6
		c0.4,3.8,0.2,7.7,1.5,11.4c-3.5-0.7-2.4,4.5-5.5,4.4c-2.2-1-2.5-3-2.7-5.1c-0.1-1,0-2,0.2-3c0.4-1.9,0.8-3.8,0.9-5.7
		c0.1-0.5,0.2-0.9,0.3-1.4c0.5-1.3,0.6-2.7,1-4C929.6,713.8,929.9,713.4,930.3,713.2z"/>
	<path class="st2414" d="M930,733c2.1-1.1,0.5-2.8,0.5-4.3c1.7-0.9,3.1,0.7,4.7,0.2c1.3,0.7,2.7,1.3,4,2c0.9,0.4,1.6,1,2,2
		c-0.8,0-1.6,0.2-2,1c-1.8-0.9-4.1-1.2-4.7-3.8c-3.7,3.1-1.2,6-0.3,8.8C930.6,738.6,929.6,736.3,930,733z"/>
	<path class="st2415" d="M941.3,852.8c1.6-2,4.3,0,6-1.8c0.8-1.1,1.7-1.1,2.8-0.5c0.4,1,1.3,1.3,2.2,1.4c-0.7,0.3-1.4,0.6-2,0.9
		C947.2,852.9,944.3,852.8,941.3,852.8z"/>
	<path class="st2416" d="M930.3,840.9c5,0.1,9.9,1.5,15,1c1,0.3,2,0.6,3,1c-0.4,0.8-1.2,1-2,1.1c-5.3-0.5-10.8,1.1-16-1
		c1,0,2-0.1,3.6-0.2C932.2,842,931.3,841.4,930.3,840.9z"/>
	<path class="st2417" d="M945.2,849.9c-3.2,0-6.5,0.2-9.7-0.1c-3.1-0.3-1-1.3-0.1-2.1c1,0.1,1.9,0.1,2.9,0.2c4,1.5,8,1.2,12,0.1
		c0.8,0.3,0.9,1,0.9,1.7c-0.3,0.1-0.6,0.2-1,0.1C948.6,850.6,946.9,850.6,945.2,849.9z"/>
	<path class="st2367" d="M945.2,849.9c1.7,0,3.3,0,5,0c-0.1,0.2-0.1,0.4-0.2,0.7c-0.9,0.2-1.9,0.4-2.8,0.5c-0.7-0.1-1.3-0.2-2-0.3
		C945.2,850.5,945.2,850.2,945.2,849.9z"/>
	<path class="st2418" d="M948.1,754.8c0.1,1.3,0.2,2.5,0.3,3.8c-2.2,1-4.1-0.3-6.1-0.6c-1.4-1.3-2.7-2.6-4.1-3.9
		c0.2-0.2,0.3-0.3,0.5-0.5c1.2-0.4,2.2,0.1,3.2,0.5C943.8,755.5,946.2,753.4,948.1,754.8z"/>
	<path class="st2418" d="M935.2,747c0-0.7,0.1-1.4,0.1-2c2.5,0.1,2.5,2.2,3.2,3.8c0.3,1.7,1.2,3.5-0.2,5.1c0,0-0.2,0.2-0.2,0.2
		C936.6,751.9,935.2,749.8,935.2,747z"/>
	<path class="st2419" d="M952.3,745.8c0-0.3-0.1-0.5-0.1-0.8c2-2,4-4,6.1-6c2.5,2.2,2.4,4.6,0.4,7c-0.5,0.4-0.9,0.4-1.4,0
		C955.6,746,953.9,745.9,952.3,745.8z"/>
	<path class="st2420" d="M961.2,733c0-1,0.1-1.9,0.1-2.9c0.3,0,0.6,0,0.9,0c2.2,0.8,2.6,3.1,3.8,4.8c0.6,1.6,2.6,3,1.2,5.1
		c0,0.3,0,0.7,0,1c-0.3,0-0.7,0-1,0c-2,0-2.9-1.4-3.7-2.8C961.7,736.5,960.2,735,961.2,733z"/>
	<path class="st2421" d="M963.2,738c1,1,2,2,3,3c-1.7,1.3-2.3,3.9-4.9,4.1c-0.2-0.2-0.3-0.4-0.5-0.6
		C960.9,742,959.8,739.1,963.2,738z"/>
	<path class="st2421" d="M957.2,746c0.3,0,0.7-0.1,1-0.1c0.8-0.9,1.5-2.1,3-1c0,0,0.1,0.1,0.1,0.1
		C960.2,746.6,959.1,747.8,957.2,746z"/>
	<path class="st2422" d="M967.2,740c-0.7-1.7-1.3-3.3-2-5c1.7-3.5,2.5-7.4,4-11c-0.3-1.6-0.7-3.3-1-4.9c3.2,3.5,2.9,8,2.7,12.2
		c-0.1,1.9-0.6,3.8-0.6,5.8C969.6,738.3,968.6,739.3,967.2,740z"/>
	<path class="st2331" d="M974.2,713c0-1.3,0-2.7,0-4c0.3,0,0.7,0,1,0c-0.1,1.8-0.1,3.6-0.2,5.4C974.3,714.2,973.7,713.9,974.2,713z"
		/>
	<path class="st2423" d="M974.2,713c0.3,0.4,0.6,0.9,0.9,1.3c0.2,3.6,1.7,7,1.1,10.6c-1.3-0.3-2.6-0.7-4-1c1.4-3.6,0.8-7.4,1-11.1
		C973.6,712.9,973.9,713,974.2,713z"/>
	<path class="st2424" d="M934.1,740.1c0.1,0.3,0.2,0.5,0.2,0.8c0.5,0.8,2.1,0.4,2,1.7c0,0.5-0.6,0.6-1.1,0.4c-4,1.5-0.5,5.7-2.7,7.8
		c-0.4-2.6-0.8-5.2-1.2-7.8c0.3-0.3,0.7-0.6,1-0.9C932.9,741.4,932.3,739.7,934.1,740.1z"/>
	<path class="st2425" d="M935.2,743c1.2-1.3-1.1-1.2-0.9-2.1c2.2-0.1,4.1,1,6,2c0.3,0.9,0.7,1.9,1,2.8c-0.2,1.1-1,1.8-1.7,2.7
		c-0.7,0.5-1.4,1.1-2.3,0.6c-0.2-1.6-0.7-3-2-4C935.3,744.3,935.2,743.7,935.2,743z"/>
	<path class="st2426" d="M913.2,734c0-0.6,0-1.3,0.1-1.9c3.2,0.4,5-3.1,8-3c1.2,5.2-3.5,4.5-6,6c-0.8,0.4-0.9,1.2-1,2
		C913.1,736.3,913.4,735,913.2,734z"/>
	<path class="st2427" d="M934.1,740.1c-0.6,0.6-1.2,1.3-1.7,1.9c-3.9-1.7-3.2-6.6-6.1-9.1c-1-1.1-0.5-2.2-0.2-3.4
		c0.4-0.9,0.8-1.8,2.1-1.6c0.2,1.8,0.5,3.6,1.9,5c0.7,2.4,1.6,4.8,4.2,6C934.2,739.4,934.1,739.8,934.1,740.1z"/>
	<path class="st2428" d="M927.2,730c-0.3,1-0.7,2-1,3c-3.8-0.2-7.1,2.8-11,2c1.8-2.2,5.8-2.2,6-6c0.7-0.3,1.4-0.7,2-1
		C925.3,727.3,926.3,728.6,927.2,730z"/>
	<path class="st2429" d="M897.3,736c1.2-3.1,3.8-1.9,6-2c0.2,1.5,1.3,3.5-0.6,4.4C900.4,739.3,899.1,736.8,897.3,736z"/>
	<path class="st2430" d="M927.2,730c-1.3-0.7-2.6-1.3-3.9-2c0.3-0.7,0.6-1.3,1-2c0.7-0.3,1.3-0.7,2-1c0.7-0.6,1.3-0.6,2,0
		c0,1-0.1,2-0.1,3C927.9,728.7,927.6,729.3,927.2,730z"/>
	<path class="st2431" d="M969.2,724c0.5,4.3,0.1,8.3-4,11c-1.6-1.3-2-3.3-3-4.9c1.6-1,2.9-2.1,3-4.1
		C966.4,724.9,967.4,723.6,969.2,724z"/>
	<path class="st2432" d="M947.2,748c0.3,1.3,0.6,2.7,0.9,4c-2.5,3.4-5.2,0.3-7.7,0.1c-1.1-1-1.1-2.2-0.6-3.5
		C941.9,744.6,944.7,747.4,947.2,748z"/>
	<path class="st2433" d="M941.1,752.1c2.3-1.5,4.7,1.9,7,0c0,0.9,0,1.9-0.1,2.8c-2.2-0.1-4.4,2.9-6.6,0.1
		C940.4,754.1,940.4,753.1,941.1,752.1z"/>
	<path class="st2434" d="M947.2,748c-2.3,0.6-5.1-2-7,1c-0.9,0.2-1.3-0.1-1-1c0.1-1.3,0.8-2,2-2.3
		C943.7,745.2,945.6,746.4,947.2,748z"/>
	<path class="st2435" d="M961.2,745c-1,0.3-2,0.6-3,1c1-2.3,0.9-4.6,0-6.9c1.9-1.6,2.1-3.7,2-6c0.3,0,0.6,0,1,0c0.7,1.7,1.4,3.3,2,5
		C961.6,740,962.3,742.8,961.2,745z"/>
	<path class="st2436" d="M929.4,718.2c0.1,0.3,0,0.5-0.1,0.8c-1.2,1.2,0.4,4.5-3,4c0-3.3,0-6.7,0-10c0.2-2.4,0.6-4.8,1.9-6.8
		c-0.2,2.6,1.9,4.5,2.1,7c0,0.3,0,0.5-0.1,0.8C928.9,715.2,930.4,717,929.4,718.2z"/>
	<path class="st2412" d="M926.3,723c2.1-0.6,1.5-3,3-4c0.1,2.1,0,4.1-1,6c-0.7,0-1.3,0-2,0C926.3,724.3,926.3,723.7,926.3,723z"/>
	<path class="st2412" d="M929.4,718.2c-0.2-1.1-0.4-2.2-0.7-3.3c-0.5-1.9,0.7-1.1,1.5-1C930.3,715.5,930.4,717,929.4,718.2z"/>
	<path class="st2437" d="M939.2,748c0.3,0.3,0.7,0.7,1,1c0,1.1-0.2,2.3,0.9,3.1c0.1,1,0.2,1.9,0.3,2.9c-1-0.4-2.1-0.7-3.1-1.1
		c-0.4-1.6-0.7-3.3-1.1-4.9C937.9,748.6,938.6,748.3,939.2,748z"/>
</g>
<g id="robot">
	<path class="st2438" d="M510.5,891.9c-0.4-0.1-0.7-0.1-1.1-0.2c-0.1-0.8,0.1-1.6,0.4-2.4c1.2-5.4-0.1-10.9,0.8-16.3
		c1.3-4.9,0.7-9.9,0.6-14.9c-0.1-3.7,1.5-6.2,3.8-8.7c1-1.1,2.1-1.6,3.5-1.2c1.7,0.5,1.6,1.9,1.6,3.2c0,2.6,0,5.2,0,7.8
		c0-3,0-6,0.1-9c0-0.7,0.4-1.5,1.1-1.3c0.9,0.3,0.8,1.3,0.7,3.3c0.6-3.9-2.6-7.3,1.5-9.5c0.4,0.4,0.7,0.8,0.8,1.4
		c-0.3,3.6-0.1,7.3-0.1,10.9c0.1-3.4-0.4-6.8,0.4-10.3c0.7-0.2,1.2,0,1.6,0.5c1,1.5,0.9,3.2,0.9,4.8c0,16.3,0.1,32.5,0.1,48.8
		c-0.6,1.9-1.7,3-3.8,1.8c-1.8-2.9-0.8-6-0.9-9.1c-3.4,0.9-7.1,1.4-10.9,1C511.3,892.4,510.9,892.2,510.5,891.9z"/>
	<path class="st2439" d="M510.7,890.7c-0.4,0.3-0.8,0.7-1.3,1c-8.9-3-15.8-8.6-21.2-16.1c-1.2-1.6-1.7-3.5-1.5-5.6
		c-3.5-13.4-1.7-27-1.7-40.5c0.3-0.6,0.7-0.9,1.4-0.9c2,1.4,1.6,3.5,1.6,5.4c0.1,6.7,0.3,13.3,0,20c-0.5,9.1,2.3,17,7.7,24.1
		c1.1,1.4,2.2,2.8,3.8,3.7c1.4,0.7,3,1,4.4,1.6c1.2,0.5,2.5,1.3,3.1-0.8c0.3-1.1,0.3-2.3,0.3-3.5c0.6-21.7,1.4-43.4,1-65.2
		c0-1.7-0.1-3.3,0.5-4.9c0.5-0.8,1-1.7,2.2-1.2c0.4,0.5,0.7,1.1,0.9,1.7c1,13.1,0.2,26.2,0.1,39.3c0,12.6-0.8,25.2-0.8,37.8
		C511.2,888.1,511.6,889.5,510.7,890.7z"/>
	<path class="st2440" d="M778.5,233.5c6.8,8.7,12,18,12.9,29.3c0.9,10.5-2,20.7-2.1,31.1c0,1.1-0.6,2.1-1.5,2.4
		c-2.9,0.8-2.3,3.2-2.7,5.3c-0.4,0.7-0.8,1.7-2,1c-1.6-1-0.6-2.6-0.9-3.9c-3.1-4.6-6-9.2-4.1-15.2c2.3-0.4,2.5,1.7,3.5,2.9
		c0.9,1.2,1.8,2.6,3.6,2.2c1.7-0.4,2-2.1,2.3-3.5c0.7-3.8,0.8-7.6,0-11.4c-0.7-3,1.1-6.3-1-9.1c0.5-9.8-3.7-18.1-7.8-26.5
		C778.2,236.6,777.1,235.1,778.5,233.5z"/>
	<path class="st2441" d="M729.6,100.8c2.1-0.3,2.9,2.4,4.9,2.1c3.9,2.1,3.9,2.1,4.9-4.2c0-17.8,0-35.5,0-53.3
		c0-8.1,3.7-11.7,11.7-11.6c4.1,0,6.1,2.5,7.3,5.9c0.2,0.3,0.3,0.5,0.3,0.8c0,0.3-0.1,0.4-0.2,0.4c-0.6,0.4-1.3,0.5-2,0.3
		c-0.4-0.2-0.7-0.6-0.9-0.9c-0.7-1.5-1.5-3-3.1-3.8c-1.6-0.7-3.2-0.6-4.8,0c-1.8,0.7-3.7,1.4-4.3,3.5c-0.2,0.5-0.3,0.9-0.5,1.4
		c-0.2,8.3-0.5,16.6-0.1,24.9c-0.1,10.1,0.3,20.2-0.2,30.3c-1.4,3.8-0.5,7.8-1.1,11.6c-0.1,0.5-0.4,1-0.7,1.4
		c-1,0.8-2.4,1.1-3.1,0.3c-2.7-3-6.9-4.5-8.4-8.6C729.4,101.2,729.5,101,729.6,100.8z"/>
	<path class="st2442" d="M778.5,233.5c2.4,7.1,6,13.6,8.6,20.6c1.4,3.9-0.7,7.1,0.3,10.5c0.5,0.4,0.4,0.9-0.1,1.2
		c-2.3-0.4-2-2.6-2.7-4.1c-0.4-2.9-2.1-5.3-3.1-7.9c-4.2-12.5-10.6-23.5-20.6-32.4c-1.4-1.2-3.3-2.5-2.5-4.9
		c0.7-0.4,1.4-0.3,2.1,0.2l-0.1,0c1.9,1.8,4.4,2.9,6.1,5.1l-0.1-0.1c1.3,1.1,2.6,2.3,4.1,3c1.6,1.6,3.2,3.2,4.9,4.8
		C776.3,230.9,777.2,232.4,778.5,233.5L778.5,233.5z"/>
	<path class="st2443" d="M390.8,357.3c0.2-2.2,1.6-3.6,3.6-3.7c2.4-0.1,1.6-2.1,2.3-3.1c1.3-1.2,2.4-2.6,4.1-3.3
		c4.1-1.7,8.2-3.4,12.8-2.9c1.5,0.1,3,0.7,4.5,0.8c3.1,0,6.1,1.1,9.1,1.6c1.5,0.2,2.8,0.9,2.9,2.7c-1.9,3-5,3.4-8.1,3.3
		c-1.8-0.1-3.1,0.1-3.4,2.2c-1.1,0.6-2.3,0.5-3.4-0.1c-5.8-3.2-11.5-2.1-17.3,0.2c-2.1,1.1-4,2.7-6.6,2.8
		C391.2,357.6,391,357.4,390.8,357.3z"/>
	<path class="st2444" d="M485.8,828.9c0.2,13.7-0.9,27.5,1,41.1c-2.6-4-2.9-8.7-2.9-13.2c-0.1-13.3,0-26.6,0.1-39.9
		c0.7-17.1,0.2-34.1,0.3-51.2c0-4.8,0.5-9.6-0.5-14.4c0.1-1,0.3-2,1.3-2.5c2.3,1.1,2,3.3,2,5.2c0.1,23.2,0.1,46.5,0,69.7
		C487.2,825.6,487.1,827.4,485.8,828.9z"/>
	<path class="st2445" d="M453.7,733.1c-8.7-0.4-16.4-4.9-25-6.4c-12.9-2.2-25.1-6.8-37.4-11.4c-8.7-3.3-17.2-6.7-24.6-12.5
		c3.8-1.3,6.2,2,9.3,2.9c0.4,0.2,0.9,0.4,1.3,0.6c3.5,1.8,6.9,3.8,10.7,5c3.1,1.5,6.5,2.5,9.8,3.5c0.4,0.1,0.9,0.3,1.3,0.4
		c2.8,1.1,5.7,2,8.6,2.8c0.5,0.1,0.9,0.3,1.4,0.5c8.2,2.2,16.4,4.5,24.7,6.5c3.6,0.9,7.1,2.2,10.7,3c2.5,0.8,5.1,1.3,7.5,2.6
		c0.9,0.4,1.8,0.8,2.1,1.8C453.9,732.7,453.8,732.9,453.7,733.1z"/>
	<path class="st2446" d="M754.5,398.4c-1.9,6.5-5.6,12.1-8.5,18.1c-1.3,2.6-2.2,5.1-0.5,7.8c0.2,0.3,0.3,0.6,0.3,0.9
		c-0.1,0.3-0.1,0.5-0.2,0.5c-3.1,0.5-5.1,3.4-8.2,3.9c-0.5-0.6-0.8-1.2-0.8-2c0.3-3.5,3-6,4.1-9.2c1.7-2.8,2.7-6,4.8-8.5
		c1.7-1.8,2.6-4.1,2.9-6.5c0.2-0.9,0.5-1.7,1.1-2.5c1.3-1.4,1.7-3.2,1.7-5c1.2-4.7,2-9.5,5-13.5c0.7-0.4,1.5-0.4,2.2-0.1
		c1.8,1.9,0.3,3.7-0.2,5.6C757,391.3,754.9,394.5,754.5,398.4z"/>
	<path class="st2447" d="M412,302.5c1.8-6.7,1.2-13.5,0.7-20.1c-1.1-14-1.3-27.9,0.1-41.9c0.3-3.4,0-7,0.2-10.4
		c0.1-1.5-0.9-3.3,0.9-4.6c1.3,1.1,1.3,2.7,1.4,4.2c0,1.8,0,3.7,0.1,5.5c0,19.2,0,38.3,0,57.5c-0.5,3.3,1,6.9-1.5,9.8
		C413.2,302.9,412.6,302.9,412,302.5z"/>
	<path class="st2448" d="M342.9,649c1.2-9.3,6.1-17.1,9.8-25.5c4.1-9.3,10.7-16.9,15.9-25.5c0.7-1.2,2.2-2,3.3-3
		c0.3,0.3,0.5,0.7,0.6,1.2c-0.6,2.8-2.7,4.7-4,7.1c-1.9,2.2-3.6,4.6-4.9,7.2c-3,4.4-5,9.4-8,13.8c-4.2,5.6-6,12.4-8.6,18.8
		c-0.1,0.4-0.3,0.8-0.5,1.3c-0.8,1.4-1.2,2.9-1.9,4.3c-0.3,0.4-0.6,0.6-1.1,0.7C343.3,649.3,343.1,649.2,342.9,649z"/>
	<path class="st2449" d="M813.4,723.1c-0.1,4.3-0.1,8.6-0.2,12.9c-0.8,3.1-0.1,6.2-0.4,9.3c-0.1,1.1,0.5,2.8-1.7,2.6
		c-0.4-0.5-0.7-1-0.9-1.6c-0.6-18.1-0.2-36.1-0.2-54.2c0-0.8,0.2-1.6,0.6-2.4c1.1-1.5,1.9-1,2.1,0.6c0.4,2.2-0.3,4.5,0.4,6.7
		c0.1,4.7,0.1,9.3,0.2,14C814.2,715,814.2,719,813.4,723.1z"/>
	<path class="st2450" d="M391.9,425.4c-1.9-5.3-5.2-10.1-6-15.9c-1.4-6.5-2.2-13.1-2.4-19.7c0.1-2.4-0.2-4.8,0.5-7.2
		c0.2-0.6,0.6-1,1.1-1.4c2.6,0.8,2.1,3,2.2,4.9c0.3,3.7-0.2,7.5,0.3,11.3c0.5,1.7,0.4,3.6,1,5.4c0.2,0.5,0.3,1,0.4,1.5
		c0.7,5,2.5,9.6,4.4,14.2c0.8,1.8,1.7,3.6,1.7,5.7c0,0.8-0.3,1.5-0.7,2.2C393,427.2,392.5,426.1,391.9,425.4z"/>
	<path class="st2451" d="M470.7,480.4c-5.9-2.1-11-5.9-16.2-9.2c-2.3-1.4-5.9-1.2-6.7-4.8c0.1-0.2,0.3-0.4,0.4-0.6
		c1.4-0.9,2.9-1.5,4.6-1c1.9,0.9,3.1,2.7,4.7,3.9c3.5,1.9,6.5,4.7,10.5,5.5c2.6,0.5,4.2,2.2,5.5,4.4c0.4,0.9,0.8,1.8,0.1,2.8
		C472.2,482.4,471.5,481.1,470.7,480.4z"/>
	<path class="st2452" d="M437.9,466.1c-3.4,0.3-6.4-0.8-9.1-2.8c0.5-1.1-1.3-2.6,0.5-3.6c3.9,0.1,7.8,0.2,11.7-0.9
		c2.9-0.9,5.6-2,7-4.9c0.5-0.6,1.1-1,1.9-0.9c2.2,3.4,0.7,5.9-2.1,7.8c-1.9,1.3-3.6,2.7-4.9,4.6
		C441.2,466.2,439.4,465.4,437.9,466.1z"/>
	<path class="st2453" d="M756.6,40.8c0.6,0,1.2,0,1.9,0.1c0.8,1.4,0.8,2.7,0,4.1c0,1.9,0,3.8,0,5.8c1.1,8.6,0.3,17.2,0.4,25.9
		c0.1,5.1,0.6,10.3-0.4,15.4c0,6.2,0,12.4,0,18.6c0.7,3.1,0.7,6.2,0,9.3c-2.6-0.4-2.2-2.5-2.4-4.2c-0.4-3.3,0.1-6.6-0.1-9.9
		c0-7.9,0-15.8,0-23.7c0-3.1,0-6.2,0-9.3c0-9.2,0.1-18.4,0-27.6C756,43.6,755.3,42.1,756.6,40.8z"/>
	<path class="st2454" d="M766.3,367.3c0.1,0.9,0.2,1.7-0.8,2.2c-0.7,1.6-1.1,3.2-1.1,4.9c-0.7,1.7-1.4,3.5-2,5.2
		c-0.9,1.3-0.4,3.2-2,4.1c-1.5-0.1-1.3-1.8-2.3-2.4c-0.4-1,0-1.9,0.5-2.7c1.2-1.8,1.7-3.9,1.6-6c-0.3-4.8,2.2-9.1,2.3-13.8
		c0.8-1.8,0.4-4.1,2.2-5.5c2.5,0.7,1.9,2.7,1.7,4.3c-0.2,1.4,0.5,0.4,0.9,0.5c0.4,0.1,0.7,0.4,0.7,0.9
		C767.9,361.9,766.4,364.4,766.3,367.3z"/>
	<path class="st2455" d="M758.5,201.7c0,0.3,0,0.5,0,0.8c0.7,1.7,0.7,3.3,0,5l0,0.3c-3.2-1.2-2.3-4.1-2.3-6.4
		c-0.1-18.4,0-36.9-0.1-55.3c0.4-5-0.9-10.2,0.9-15.1c0.5-0.6,1-0.7,1.7-0.3c0.6,3.8,0.2,7.6,0.3,11.4c0,18.1,0,36.2,0,54.2
		C758.9,198.1,759.4,200,758.5,201.7z"/>
	<path class="st2456" d="M450.7,145.6c0-3.2,2.6-4.7,4.4-6.6c7.8-8.1,15.5-16.4,24.5-23.4c0.6-0.5,1.3-0.6,2-0.2
		c1,2.5-1.1,3.6-2.4,4.9c-2.1,2.1-4.7,3.7-6.9,5.7c-1.7,1-3.2,2.3-4.1,4.1c-2.3,3.3-6,5-8.7,7.9c-1.3,1.1-2.4,2.4-3.3,3.9
		c-1.3,1.5-2.5,3.1-4.4,4C451.5,146.1,451.1,145.9,450.7,145.6z"/>
	<path class="st2457" d="M814.6,591.2c1.4,4.4-1.8,8-2.4,12.1c-1.3,9.6-3.7,19.1-4.9,28.7c0,0.4-0.5,0.8-0.8,1.1
		c-0.4,0.4-0.9,0.4-1.4,0c-0.5-0.6-0.7-1.4-0.7-2.2c0-0.5,0.1-1,0.2-1.5c0.8-3.6,1.6-7.3,1.5-11c0.1-1.2,0.5-2.4,1.1-3.4
		c1-1.6,0.8-3.5,1.2-5.3c0.1-0.5,0.2-0.9,0.3-1.4c0.5-1.6,0.3-3.3,0.3-5c1-4.3,0.3-9.1,3.9-12.6
		C813.6,590.7,814.1,590.8,814.6,591.2z"/>
	<path class="st2458" d="M757.4,145.6c0,18.7,0,37.4,0.1,56.1c0,2.1-0.8,4.3,1,6.1c0,0.6-0.1,1.2-0.1,1.8c-2.3,1.6-4.6,3.1-5.8-1.1
		c2.4-1.8,2.6-4.3,2.6-7.1c-0.1-16.1,0-32.2,0-48.3c0-2.3-0.3-4.7,0.7-6.9C756.2,145.6,756.7,145.4,757.4,145.6z"/>
	<path class="st2459" d="M484.7,751.1c-0.1,20-0.1,39.9-0.2,59.9c0,2,0.5,4-0.6,5.9c0-19.7-0.1-39.5-0.2-59.2c0-2,0.7-4.2-1.8-5.7
		c-1.6-0.9,1.8-1.7,0.7-3C484.7,748.4,483.9,750.5,484.7,751.1z"/>
	<path class="st2460" d="M408.7,559.1c12.2-9.8,24.4-19.4,38.1-27c0.3,0.4,0.4,0.8,0.3,1.3c-2,3.8-6.1,4.9-9.1,7.3
		c-3.8,2.9-7.8,5.5-11.6,8.3c-4,2.6-7.7,5.7-11.6,8.5c-1.5,1.1-2.9,2.3-4.8,2.3C409.5,559.7,409.1,559.5,408.7,559.1z"/>
	<path class="st2461" d="M662.6,848c-0.3,0.9-1,0.9-1.7,0.8c-4.9,3-9.8,5.8-15,8.4c-2.3,1.7-4.7,3-7.3,4.1c-1.9,1.1-3.6,2.5-5.9,2.3
		c-0.5-0.1-0.9-0.4-1.2-0.7c1.7-4.5,6.4-5.1,9.7-7.6c2.4-1.7,5.3-2.6,7.9-4.1c0.8-0.5,1.6-1,2.4-1.4c2.9-1.6,5.3-4.1,8.6-4.9
		c1.1-0.1,2.1,0.1,2.9,0.8C663.2,846.5,663.2,847.3,662.6,848z"/>
	<path class="st2462" d="M484.7,751.1c-0.7-0.7-1.4-1.4-2-2.1c-7.5-6.7-16.9-9.7-25.7-14c-1.1-0.6-2.2-1.2-3.4-1.8
		c0,0,0.4-0.3,0.4-0.3c4.5-1.5,8,1.5,11.9,2.7c1.3,0.5,2.6,1,3.9,1.7c1.2,0.6,2.4,1.4,3.5,2.1c2.3,1.5,4.9,2.5,7.2,4
		c1.8,1.6,5.5,1.8,4.5,5.7C484.9,749.7,484.8,750.4,484.7,751.1z"/>
	<path class="st2463" d="M376,706.9c-3.1-1.3-6.2-2.7-9.3-4c-4.1-1.8-8.3-3.6-10.7-7.8c-1.5-1.1-3.3-2-3.7-4.1
		c0.1-0.5,0.3-0.9,0.7-1.2c1.7-0.7,3.1,0.1,3.9,1.3c3.3,5.6,9.8,6.7,14.4,10.4C372.8,703.3,376.8,703,376,706.9z"/>
	<path class="st2464" d="M821.4,553.4c-0.3,1.6,0.8,3.4-0.8,4.8c-1.4,3.2-1,6.8-2.2,10.1c-0.6,0.2-1.1,0.1-1.5-0.3
		c-1.3-2.3-1-4.7-0.3-7.1c0.9-3.3,1.4-6.6,1.8-10c2.1-9.1,1.5-18.3,1.6-27.5c0-2.6-0.5-5.3,0.8-7.8c0.4-0.6,0.9-0.7,1.6-0.6
		c0.9,2.1,0.2,4.2,0.4,6.4c0,5.2,0,10.4,0,15.6c-0.2,2.7,0.5,5.5-0.5,8.2l0-0.1C821,547.8,822.7,550.8,821.4,553.4z"/>
	<path class="st2465" d="M780.5,305.6c-1.2,5.8-0.6,11.8-3,17.4c-0.5,1.1-1,2-2,2.6c-2.1-2.3-0.6-4.8-0.5-7.2c0.2-2.2,0-4.4,0.5-6.6
		c0.7-2.1,0.6-4.4,1.1-6.5c1.4-3.5-0.7-7.6,1.9-10.8c1.8,0,2.2,1.5,2.1,2.7C780.2,299.9,781.1,302.8,780.5,305.6z"/>
	<path class="st2466" d="M540.6,910.9c-6,0.8-9.7-2.6-12.9-6.9c-0.5-0.6-0.6-1.3-0.2-2.1c1.9-0.9,3.1,0.6,4.4,1.5
		c2.8,2.1,6,3.5,9.3,4.3c3.1,1.5,6.1,0.2,9.2-0.2c1.7,0,3.4-0.8,5.2-0.5c0.5,0.1,0.9,0.4,1.3,0.8c-2.6,2.2-5.7,3-9.1,3
		C545.4,911.5,543,911.5,540.6,910.9z"/>
	<path class="st2467" d="M382.9,393.5c-1.9-13.1-1.9-25.7,8-36.2c-0.1,0.2-0.1,0.4,0,0.5c0.7,2.5-1.1,4.4-1.3,6.7
		c-2.5,3.3-2.4,7.4-3.9,11.1C383.2,381.3,384.9,387.7,382.9,393.5z"/>
	<path class="st2468" d="M776.3,318.4c-0.3,2.4-0.5,4.8-0.8,7.3c-0.4,1.6,0.4,3.5-1,4.9c-0.6,0.7-0.2,2.3-1.8,2.1
		c-1.3-0.9-1.4-2.3-1.6-3.7c0.1-4.8-0.4-9.7,0.3-14.5c0.5-1.7,1.2-3.2,2.8-4.1c1-0.2,1.5,0.3,1.7,1.2
		C776.1,313.8,775.5,316.1,776.3,318.4z"/>
	<path class="st2469" d="M758.5,201.7c0-20,0.1-40,0.1-60c0.2-0.5,0.5-0.5,0.8,0c0.1,19,0.1,37.9,0.1,56.9
		C759.5,199.7,759.8,201,758.5,201.7z"/>
	<path class="st2470" d="M586.7,63c3.2-0.9,6.5-1.5,9.9-1.2c3.3-1.1,6.7,0.2,10-0.8c7,0,14.1,0,21.1,0c0.7,0.9,4.1-0.6,2.2,2.6
		c-3,1.3-5.9-0.4-8.8-0.4c-8.5,0-16.9,0.9-25.3,1.8c-2.2,0.2-4.3,0.3-6.5,0.1C588.1,64.7,586.8,64.6,586.7,63z"/>
	<path class="st2471" d="M372,596.3c0-0.4-0.1-0.8-0.1-1.2c0.1-4.1,4.1-5.2,6.2-7.8c4.3-5.3,9.5-10,14.2-15c1.1-1.1,2.4-0.9,3.6-1.4
		c0.6,1,0.1,1.8-0.5,2.6c-5.4,4.4-10.3,9.3-14.6,14.6c-1.4,1.9-3.4,3.3-4.6,5.4c-0.2,0.4-0.5,0.7-0.7,1.1
		C374.4,595.5,373.6,596.7,372,596.3z"/>
	<path class="st2472" d="M811.3,747c0.1,0.3,0.2,0.6,0.3,0.9c0.9,4.3-1.7,7.7-2.7,11.5c-0.7,2.6-2.3,4.7-4.1,6.6
		c-1.5,2.1-3.3,3.8-5.4,5.2c-0.4,0.5-0.7,1.1-1.1,1.6c-1.1,1-2.4,1.5-3.9,1.3c-0.6-0.5-0.8-1.2-0.7-2c0.1-0.5,0.2-0.9,0.4-1.3
		c2.3-1.9,5-3.1,6.8-5.6c3.5-3.5,5.2-7.9,6.7-12.5c0.9-1.9,0.4-4.4,2.4-5.8C810.4,746.6,810.9,746.7,811.3,747z"/>
	<path class="st2473" d="M382.9,393.5c0.2-6.4-0.7-12.9,2-19.1c1.6,1.6,1.3,3.5,1,5.4c-0.2,0.5-0.4,1-0.9,1.4
		c-0.1,0.4-0.1,0.8-0.2,1.2c-0.8,2.3-0.8,4.7,0,7c-0.7,6.7,1.3,13.4,1,20.1C383.6,404.4,382.7,399.1,382.9,393.5z"/>
	<path class="st2474" d="M391.9,425.4c0.7,0.3,1.4,0.7,2,1c2.9,1.4,3.7,4.4,5.5,6.7c1.8,2.3,3,5.1,5.7,6.7c0.7,0.6,1.3,1.4,1.6,2.3
		c0.3,0.9,0.3,1.9,0,2.9c-0.2,0.4-0.5,0.8-0.8,1.2c-4.5-3.6-7.5-8.4-10-13.4C394.6,430.2,391.1,428.9,391.9,425.4z"/>
	<path class="st2475" d="M801.4,465.3c6.1,6.3,11.3,13,12.8,22c-0.4,0.3-0.8,0.5-1.3,0.4c-1.2-0.4-1.8-1.5-2.4-2.5
		c-2.8-6-6-11.8-9.8-17.3c-0.2-0.7-0.2-1.4,0.1-2.1C801.1,465.7,801.2,465.5,801.4,465.3z"/>
	<path class="st2476" d="M503.7,98.6c0.1-0.3,0.1-0.5,0.2-0.7c2.2-0.9,4-2.7,6.6-2.5c0.9,1,0.3,1.8-0.2,2.6
		c-3.3,2.6-6.9,4.8-10.3,7.3c-4.2,1.6-6.3,6.5-11.2,7c-0.7-0.3-1.3-0.7-1.1-1.6c0.7-2.1,3-2.6,4.2-4.1
		C495.8,103.9,499.7,101.1,503.7,98.6z"/>
	<path class="st2477" d="M487.7,110.8c0.4,0.3,0.8,0.7,1.2,1c0.2,0.4,0.2,0.9,0.1,1.3c-1.7,2.9-4.7,4.3-6.9,6.5
		c-0.3,0.3-0.6,0.6-0.9,0.9c-1.5,1.2-2.7,2.7-3.8,4.3c-0.8,1-2,1.6-3,2.4c-1.6,1.1-2.6,3-4.9,2.9c-0.4-0.1-0.8-0.4-1-0.8
		c0.3-1.9,2.1-2.6,3.2-3.9c3-3.7,7.5-5.8,10.1-9.8c0.7-2,2.8-2.5,3.9-4C486.1,110.8,486.9,110.8,487.7,110.8z"/>
	<path class="st2478" d="M529.6,83.7c1-2.4,3.4-2.9,5.4-3.8c7-3.2,13.8-7,21.6-8.2c0.5-0.5,0.9-0.4,1.2,0.1
		c-0.6,2.9-3.2,2.7-5.2,3.5c-1.9,0.8-3.9,1.5-5.8,2.4c-4.1,1.9-8.3,3.8-12.4,5.7C532.9,84.2,531.4,85.3,529.6,83.7z"/>
	<path class="st2479" d="M697.6,828.9c-5.7,4.7-12,8.3-18.9,11c-0.7,0.5-1.4,0.5-2.1,0.3c0.2-4.1,4.8-3.5,6.5-6
		c4.2-2.1,7.7-5.5,12.5-6.2C696.4,828,697.1,828.3,697.6,828.9z"/>
	<path class="st2480" d="M666.6,69.8c7.6,3.2,15.2,6.3,22.8,9.5c0.3,0.1,0.2,0.9,0.4,1.4c-0.5,0.6-1.2,0.9-2,0.9
		c-0.5,0-1-0.1-1.5-0.2c-6.2-3.3-13.4-4.4-19.2-8.7C666.5,71.8,666,70.9,666.6,69.8z"/>
	<path class="st2481" d="M753.6,797.9c-0.1,0.3-0.2,0.6-0.3,0.9c-4.7,2.2-8.7,5.6-13.7,7.1c-1.7,1-3.3,2-5,3
		c-2.2,1.2-4.2,2.9-6.8,3.4c-0.7-0.2-1.2-0.6-1.6-1.3c-0.1-0.8,0.2-1.3,0.8-1.7c1.3-0.7,2.7-1.3,3.8-2.4c0.4-0.3,0.7-0.5,1.2-0.6
		c6.6-2.6,12.3-6.9,18.9-9.3C752,796.9,752.9,797.1,753.6,797.9z"/>
	<path class="st2482" d="M780.5,305.6c-1.8-3.5,0-7.7-2-11.1c-2.5-4.9-3.1-10.3-3-15.7c0.1-0.5,0.4-1,0.8-1.4c0.5-0.3,1-0.2,1.4,0
		c1.1,1.4,1,3.2,1.3,4.8c0,0.5-0.1,0.9-0.5,1.3c0.1,4.5,1.2,8.7,3.6,12.5c0.6,1,0.7,1.7,0.4,2.6C780,300.5,782.9,303.8,780.5,305.6z
		"/>
	<path class="st2483" d="M462,518.2c3.9-9.6,7-19.5,14.8-27c0.3,0.3,0.6,0.7,0.6,1.2c0,0.5-0.1,0.9-0.3,1.4
		c-1.8,2.3-3.3,4.9-4.5,7.6c-0.7,1.4-1.4,2.9-2.1,4.3c-1.6,3.1-3,6.2-4.7,9.2C464.9,516.5,464.4,518.5,462,518.2z"/>
	<path class="st2484" d="M529.6,83.7c6.2-1.1,11-5.5,17.1-7c0.7-0.6,1.4-0.5,2.1-0.2c0.3,2.3-1.6,2.8-3,3.4
		c-5.5,2.3-10.6,5.5-16,7.8c-0.7,0.3-1.4,0.5-2.1,0.6c-0.8,0.1-1.5,0-2.1-0.5c-0.4-0.7-0.4-1.4,0.3-2
		C527.1,85.2,528.4,84.4,529.6,83.7z"/>
	<path class="st2485" d="M687.6,80.7c0.7,0,1.4,0,2.2,0c0.6,0.1,1.2,0.1,1.8,0.2c1.2,0.7,2.6,0.9,3.6,1.9c4.4,1.6,8.7,3.3,12.6,5.9
		c1.8-0.2,3.2,0.2,3.8,2.1c0.6,0.7,0.5,1.4,0.1,2.1c-2.1,1.3-3.8,0.1-5.5-1c-3.1-2-6.2-3.8-9.7-5c-1.1-0.4-2.2-0.9-3.3-1.4
		C691.3,83.9,687.9,84.1,687.6,80.7z"/>
	<path class="st2486" d="M794.5,772c0,0.6,0,1.3-0.1,1.9c-1.8,4.4-6.6,4.9-9.9,7.4c-3.3,2.1-7.1,3.2-10.2,5.5
		c-0.2,0.1-0.4,0.1-0.6,0.1c-0.7,0.7-1.5,1-2.5,0.7c-0.4-0.2-0.8-0.5-1-0.9c0.1-2,1.7-2.5,3-3.4c0.4-0.2,0.8-0.4,1.2-0.6
		c3.3-1.5,6.3-3.4,9.2-5.5c0.5-0.4,1.1-0.8,1.7-1.1c2.2-0.9,4.6-1.5,6.3-3.4c0.5-0.5,1.1-0.8,1.7-1.1
		C793.8,771.5,794.2,771.6,794.5,772z"/>
	<path class="st2487" d="M683.6,834.9c-1.5,2.8-5.2,2.6-7,5c-1.3,2.5-4.3,2.9-6.1,4.9c-2.6,1.1-5.2,2.2-7.9,3.2c0-0.7,0-1.4-0.1-2.1
		c0.7-1.9,2.5-2.5,3.9-3.6c3.6-1.9,7.3-3.7,10.6-6.1c1.2-0.8,2.4-1.6,3.8-2C681.9,834,682.8,834,683.6,834.9z"/>
	<path class="st2488" d="M433.1,170.9c0.3-0.4,0.6-0.7,0.9-1.1c1.5-2.3,2.3-5,4.7-6.7c0.5,0,0.9,0.1,1.3,0.4c0.9,1.4,0.2,2.7-0.3,4
		c-0.4,0.8-0.8,1.6-1.3,2.4c-0.7,1.1-1.5,2.2-2.1,3.4c-2.6,3.8-4.3,8-6.7,11.9c-0.5,0.6-1.1,0.9-1.9,1c-0.5-0.1-0.9-0.3-1.2-0.8
		C427.6,180,431,175.7,433.1,170.9z"/>
	<path class="st2489" d="M439.9,163.7c-0.4-0.1-0.7-0.1-1.1-0.2c-0.5-0.6-0.5-1.2,0-1.9c0.1-0.7-0.1-1.6,0.8-2
		c1.3-1.7,3.1-3.1,4.1-5.1c1.8-3.4,3.7-6.6,7-8.9c0.4,0,0.8,0,1.1,0c0.5,0.6,0.6,1.3,0.4,2C447.9,152.8,445.7,159.6,439.9,163.7z"/>
	<path class="st2490" d="M752.8,209c1.6,1.9,3.6,0.9,5.5,0.6c-0.3,0.7-0.6,1.4-1,2c-1,1.1-1.8,2.2-3.3,2.7c-2.9,0.4-5.8,0.1-8.6,0.1
		c-1-0.3-1.8-0.8-2.4-1.7c-0.4-0.9-0.5-1.9,0-2.9c2.6-2.1,5.8,0.2,8.5-1C751.9,208.8,752.4,208.9,752.8,209z"/>
	<path class="st2491" d="M753.6,797.9c-1,0-2.1,0-3.1,0.1c-0.6-1.5,0.7-2.1,1.6-2.6c5.3-3.2,10.5-6.6,16.2-9.2
		c0.8-0.1,1.5,0,2.1,0.6l0.1,0.3c0.6,1.3-0.3,2.1-1.1,2.9l0.1,0c-2,1-4.1,2-5.9,3.4c-2.7,0.6-4.7,2.6-7.3,3.4
		C755.5,797.3,754.8,798.1,753.6,797.9z"/>
	<path class="st2492" d="M758.5,91.9c0-13.7,0-27.5,0-41.2c1.1,0.6,1,1.6,1,2.6c0,12,0,24,0,36.1C759.4,90.3,759.6,91.3,758.5,91.9z
		"/>
	<path class="st2493" d="M697.6,828.9c-0.7,0-1.5-0.1-2.2-0.1c1-4.1,5.1-4.1,7.8-6c3.6-0.6,6.3-4.3,10.4-3.2
		c0.5,0.7,0.4,1.4-0.1,2.1C708,823.6,703.6,828,697.6,828.9z"/>
	<path class="st2494" d="M591.7,888.7c-4.7,2.7-9.4,5.3-14,8c-0.8-0.3-2,0-2.2-1.3c1.4-3.1,4.7-3.7,7.1-5.4c3-1.2,5.3-4.6,9.2-3.3
		C592.3,887.3,592.2,888,591.7,888.7z"/>
	<path class="st2495" d="M715.4,91.7c5.5,1.9,10.8,4,14.2,9c0,0-0.2,0-0.2,0c-0.6,0.5-1.2,0.6-2,0.5c-2.7-0.8-4.9-2.8-7.5-3.7
		c-2.4-1.5-5.9-1.6-6.8-5.1C713.5,91.3,714.2,91,715.4,91.7z"/>
	<path class="st2496" d="M399.8,343.3c2.2-4.9,5.9-8.5,10-11.9c0.4-0.3,0.9-0.3,1.3,0c0.4,0.7,0.4,1.4,0.2,2.1
		c-2.5,2.6-4.4,5.6-6.3,8.6c-1.1,1.6-2.3,3.2-4.5,3.5C399.3,345.2,399.2,344.3,399.8,343.3z"/>
	<path class="st2497" d="M405.9,446.1c0-0.2,0-0.5,0-0.7c2.7-1.7,3.8,1.1,5.4,2.1c2.6,3.7,6.9,5.2,10.1,8.3c0.5,0.6,0.8,1.2,0.8,2
		c-0.6,1.1-1.4,1.3-2.4,0.5c-3.1-2.2-6.7-3.7-8.9-6.9c-1.3-0.8-2.1-2-3.1-3.1C407.1,447.6,406.5,446.9,405.9,446.1z"/>
	<path class="st2498" d="M778.3,440.3c0.7,0.6,1.5,1.2,2.2,1.9c1.1,1.4,3.5,1.8,2.9,4.3c-1.2,0.7-2.3,0.3-3.2-0.5
		c-4.7-4.3-10.3-7.5-15.6-11c-0.7-0.5-1.2-1.1-1.4-2c0.1-0.2,0.1-0.5,0.2-0.7c4.1,0.7,7.2,3.7,11,5.1
		C775.9,438.2,777,439.5,778.3,440.3z"/>
	<path class="st2499" d="M417.7,208.4c0.5-7.6,3.1-14.5,7.1-20.9c0.4,0.3,0.7,0.8,0.9,1.3c-1.7,7.3-3,14.8-7.8,21
		C417.4,209.4,417.3,209,417.7,208.4z"/>
	<path class="st2500" d="M783.3,446.5c-0.9-1.4-1.9-2.9-2.8-4.3c6.4,4.3,11.9,9.4,16,16c0.3,0.2,0.4,0.5,0.3,0.8
		c-0.1,0.3-0.1,0.4-0.2,0.4c-2.4,0.2-3.6-1.6-4.7-3.1c-2.5-3.1-5.4-5.8-8.4-8.5C783.2,447.4,783.1,447,783.3,446.5z"/>
	<path class="st2501" d="M822.3,515.3c-0.3,0-0.7,0-1,0c-1.5-1-2-2.5-2.3-4.2c-0.8-4.7-2.2-9.2-2.9-13.9c0.1-1.2,0.3-2.3,1.3-3.1
		c2.7,6,4.3,12.4,5,19C822.9,513.9,823,514.6,822.3,515.3z"/>
	<path class="st2502" d="M462,518.2c3.8-3.6,4.4-9.1,7.7-13.1c0.2,0.2,0.4,0.4,0.5,0.5c0.7,1.6,0.1,3-0.5,4.4c-1.9,4-4,7.8-5.5,12
		c-0.7,1.9-1.4,4.2-4.4,3.4c-0.9-1.4,0.5-2.3,0.9-3.4C461.2,520.8,461.6,519.5,462,518.2z"/>
	<path class="st2503" d="M425,188.8c-0.1-0.4-0.1-0.9-0.2-1.3c1.1-6.4,4.2-11.8,8.3-16.7c-0.6,5.5-4.7,9.6-6,14.8
		C427.3,187.3,426.9,188.6,425,188.8z"/>
	<path class="st2504" d="M808.3,759.9c1.1-4,2.2-8.1,3.3-12.1c2.1-3.7-0.7-8.1,1.6-11.8c0.5,8.3,0.7,16.5-4.1,23.9
		C808.9,760.3,808.6,760.3,808.3,759.9z"/>
	<path class="st2505" d="M817.3,568.2c0.3,0,0.6,0,1,0c0.3,0.2,0.4,0.5,0.4,0.8c-0.1,0.3-0.2,0.4-0.3,0.4c0.2,7.6-1.5,14.8-3.9,21.9
		c-0.5,0-1-0.1-1.4-0.1c-0.2-0.5-0.3-1-0.3-1.5c1-6.7,2.4-13.4,3.6-20.1C816.6,569,816.9,568.5,817.3,568.2z"/>
	<path class="st2506" d="M410.9,331.5c-0.3,0-0.7,0-1,0c2.2-7.5,0.7-15.1,1.2-22.7c0.1-2.1-0.5-4.3,1-6.2c0.3,0,0.5,0,0.8,0.1
		c0.7,0.7,0.8,1.5,0.8,2.4c-0.7,7.7-0.2,15.3-0.3,23c0,0.8,0.1,1.7,0,2.5C412.9,331.8,412.1,332.3,410.9,331.5z"/>
	<path class="st2507" d="M645.5,856.8c4.8-3.2,9.6-6.5,15.3-8c-3.5,4.1-7.9,6.8-13.2,8.1C647,857.4,646.2,857.4,645.5,856.8z"/>
	<path class="st2508" d="M636.7,61.7c5.2,0.3,10.2,1.4,14.8,4c-0.4,0.5-0.8,0.8-1.4,1c-6.4-0.9-13.1-0.3-18.8-4.1
		C632.8,60.1,634.9,61.4,636.7,61.7z"/>
	<path class="st2509" d="M784.4,780.8c2.8-3.2,7.3-3.7,10.1-6.9c1.3-0.4,2.6-0.7,3.9-1.1c-3.8,4.3-8.4,7.4-13.9,9.3
		c-0.3-0.3-0.4-0.5-0.3-0.8C784.2,781,784.3,780.8,784.4,780.8z"/>
	<path class="st2498" d="M750.6,425.3c3.1,1,6.3,2,9.4,3c2,0.6,3.4,1.8,3.4,4.1c0,0,0,0,0,0c-2.1,1.4-3.6-0.4-5.4-1.1
		c-2.5-1.3-5.2-2.4-7.9-3.3c-1.1-0.8-3.3-0.6-2.5-3C748.6,424.8,749.6,424.7,750.6,425.3z"/>
	<path class="st2510" d="M758.5,381.4c0.6,0.7,1.2,1.3,1.8,2c0.3,0.2,0.4,0.5,0.4,0.7c-0.1,0.3-0.2,0.4-0.2,0.4c-2,4.6-4,9.3-6,13.9
		c-1.6-2.4,0.5-4.2,1.1-6.3c1-3.3,2.8-6.3,2.7-9.9C758.1,381.9,758.2,381.6,758.5,381.4z"/>
	<path class="st2511" d="M417.7,208.4c0.1,0.4,0.2,0.9,0.3,1.3c1.9,2.6,0,5.1-0.2,7.6c-0.7,1.9-0.6,4-1.1,6
		c-0.7,1.9-0.3,4.1-1.5,5.8c-0.4,0.4-0.8,0.6-1.4,0.5c0-1.4-0.1-2.8-0.1-4.2C414.5,219.7,414.5,213.7,417.7,208.4z"/>
	<path class="st2512" d="M557.8,71.8c-0.4,0-0.7,0-1.1,0c4-3.4,8.9-4.2,13.8-5c1.2-0.2,2.2-0.5,3.1-1.2c0.4,0.4,0.5,0.9,0.5,1.5
		C569.3,71,563.9,72.6,557.8,71.8z"/>
	<path class="st2513" d="M339.9,672.1c-1.8-8.1-1.8-15.9,3-23.1c0,0,0.3,0.3,0.3,0.3c1.6,3.9-1,7.3-1.3,11
		c-1.4,3.7,0.3,7.7-1.4,11.3C340.3,671.8,340.1,672,339.9,672.1z"/>
	<path class="st2514" d="M419.7,458.3c0.8,0,1.6-0.2,2.1-1c3.1-0.7,5.5,0.2,7,3c-1,1,3,2.1-0.1,3
		C425.6,461.9,421.9,461.5,419.7,458.3z"/>
	<path class="st2515" d="M408.7,559.1c0.5,0.1,0.9,0.1,1.4,0.2c0.3,0.9-0.1,1.7-0.6,2.5c-0.4,0.5-0.9,0.9-1.5,1.3
		c-2.1,0.8-3.4,2.6-5,4c-1.9,1.1-3.4,3.3-6.1,2.5C399.4,564.4,404.2,561.9,408.7,559.1z"/>
	<path class="st2516" d="M460.8,522.1c-0.3,1.1-0.7,2.1-1,3.2c-0.1,0.7-0.5,1.3-1,1.8c-3.8,2.3-6.6,6.6-11.9,6.1c0-0.3,0-0.6,0-1
		C451.4,528.8,456.1,525.4,460.8,522.1z"/>
	<path class="st2517" d="M397,569.6c2.4-0.4,3.6-2.6,5.7-3.5c0.5-0.4,0.9-0.4,1.4,0c0.4,0.7,0.5,1.4,0.2,2.2
		c-2.7,2.6-4.4,6.9-9.4,6.2c-0.3-0.3-0.3-0.6-0.3-0.9c0.1-0.3,0.1-0.4,0.2-0.4c0.4-0.7,0.7-1.5,1.1-2.2
		C396.3,570.4,396.6,570,397,569.6z"/>
	<path class="st2518" d="M352.9,691.1c1,1.3,2.1,2.6,3.1,4c-5.1-2.3-8.4-6.3-11.1-11c-0.5-0.6-0.5-1.3-0.2-2c2.8-0.6,3.3,2,4.7,3.4
		C350.4,687.4,352,689,352.9,691.1z"/>
	<path class="st2519" d="M476.8,492.3c0-0.4-0.1-0.7-0.1-1.1c0.6-3.3,1.2-6.4-3.6-7.1c-1.8-0.2-2-2.2-2.5-3.8c1,0.3,2.1,0.6,3.1,0.9
		c0.2,0,0.5,0.1,0.7,0.1c3,1.3,4.9,3.6,5.9,6.6c0,0.5,0,1-0.1,1.4C479.6,490.9,479.1,492.7,476.8,492.3z"/>
	<path class="st2520" d="M527.7,901.8c0,0.7,0,1.4-0.1,2.1c-3.5-1.1-7.2-1.4-10.1-4c0.2-0.5,0.6-0.8,1-1.1c2-1.1,3.8-1.2,5.1,1
		c0.9,1.5,1.8,0.5,2.6,0C527.6,899.9,528,900.6,527.7,901.8z"/>
	<path class="st2521" d="M575.5,895.7c0.7,0.3,1.4,0.6,2.2,1c-3.6,1.8-6.8,4.6-11,5.3c-0.1-0.2-0.3-0.4-0.4-0.6
		c-0.2-0.7-0.1-1.4,0.3-2.1C569.2,897.3,571.6,894.7,575.5,895.7z"/>
	<path class="st2522" d="M626.6,869c-3,1.6-5.3,4.5-9.1,4.7c-0.8-0.2-1.3-0.6-1.2-1.5c3.2-2.9,6.3-6,11.2-5.5
		C628.3,867.8,627.1,868.3,626.6,869z"/>
	<path class="st2523" d="M739.6,806c4.3-2.8,8.5-5.9,13.7-7.1C749.2,802.2,745.4,806,739.6,806z"/>
	<path class="st2524" d="M713.5,821.7c0-0.6,0-1.3-0.1-1.9c2.5-4.3,7-5.2,11.2-6.8c0.8,0,1.5,0.2,2,0.9
		C722.6,817.1,718,819.4,713.5,821.7z"/>
	<path class="st2525" d="M813.2,697c-2.1-2.3,0.4-5.6-1.7-7.9c-2.2-1.3-2.3-3.4-2.4-5.6c0-1.1,0.2-2.1,0.7-3.1
		c0.3-0.5,0.8-0.6,1.3-0.4C813.1,685.6,814,691.2,813.2,697z"/>
	<path class="st2526" d="M806.6,651c3.1,2.2,5.3,4.9,4.8,9c0.7,0.9,0.5,1.9,0.3,2.9c-0.4,0.4-0.8,0.5-1.3,0.4
		c-1.8-1.8-1.4-4.5-2.8-6.4c-0.9-1.6-2.6-2.7-2.9-4.7c0-0.5,0.2-0.9,0.5-1.3C805.8,650.6,806.2,650.7,806.6,651z"/>
	<path class="st2527" d="M650.4,65.9c0.4,0,0.8-0.1,1.2-0.1c4.7,1.4,9.9,0.9,14.2,4c-0.8,1-1.9,1.2-3,1.3
		C658.4,69.8,653.2,70.7,650.4,65.9z"/>
	<path class="st2528" d="M707.7,88.8c-4.7-1.4-9-3.7-13.3-5.9c0.4-0.6,0.8-0.6,1.2-0.1C700.4,83.3,704.6,85,707.7,88.8z"/>
	<path class="st2529" d="M784.4,780.8c0,0.4,0.1,0.8,0.1,1.2c-2.9,2.6-6.3,4.2-10.1,4.7C776.7,783.1,780.7,782.4,784.4,780.8z"/>
	<path class="st2530" d="M503.7,98.6c-3.4,3.4-7.2,6.3-11.8,8C494.8,102.4,499.1,100.3,503.7,98.6z"/>
	<path class="st2531" d="M591.7,888.7c0-0.6,0-1.3,0-1.9c2-4.2,5.8-5,9.9-5.2c0.4,0.4,0.5,0.8,0.2,1.3
		C598.4,884.8,595,886.8,591.7,888.7z"/>
	<path class="st2532" d="M518.8,899.8c-0.4,0-0.8,0-1.2,0.1c-4.1-1.1-5.1-5-7.1-8c0.4,0,0.8,0,1.2-0.1
		C515.5,893.2,518.1,895.7,518.8,899.8z"/>
	<path class="st2533" d="M759.3,141.7c-0.3,0-0.5,0-0.8,0c-0.1-3.7-0.2-7.5-0.2-11.2c-1.7-2.4-1.4-4.9-0.5-7.4
		c0.3-0.6,0.8-0.7,1.4-0.5c0.1,1,0.1,2,0.2,2.9c0.7,1.7,0.7,3.5,0,5.2C759.4,134.4,759.4,138.1,759.3,141.7z"/>
	<path class="st2534" d="M645.5,856.8c0.7,0,1.4,0,2.2,0.1c-2.7,2.3-5.3,4.6-9.1,5.1c-0.5-0.4-0.5-0.8-0.1-1.3
		C640.7,859.2,642.9,857.6,645.5,856.8z"/>
	<path class="st2535" d="M616.5,872.8c0.4,0.3,0.7,0.6,1.1,0.9c-2.2,2.3-4.7,3.9-7.9,4.2c-0.1-0.2-0.3-0.4-0.4-0.6
		C610.3,873.6,612.7,872,616.5,872.8z"/>
	<path class="st2513" d="M345,681.9c0,0.7,0,1.4-0.1,2.1c-3-3.4-4.9-7.3-5-11.9l0.3-0.3c0.5-0.1,0.9,0,1.3,0.4
		C342.6,675.5,345.3,678.2,345,681.9z"/>
	<path class="st2536" d="M726.7,813.9c-0.7,0-1.5-0.1-2.2-0.1c-0.1-2.2,1-2.7,2.9-2.1c2.6-0.5,4.5-2.8,7.3-2.8
		C732.8,811.9,729.8,813,726.7,813.9z"/>
	<path class="st2537" d="M810.4,663.1c0.3,0,0.6,0,0.9,0c0.7,1,0.7,2.1,0,3.1c-0.1,4.6-0.1,9.3-0.2,13.9c-0.3,0-0.5,0-0.8,0
		C807.9,674.4,807.9,668.8,810.4,663.1z"/>
	<path class="st2538" d="M739.4,98.6c0,0.8,0.1,1.6,0,2.4c-0.2,1.7,0.9,4.2-0.7,4.9c-2.1,0.9-3-1.7-4.2-3.1
		C739,104.8,737.8,100,739.4,98.6z"/>
	<path class="st2539" d="M399.8,343.3c0.1,0.8,0.2,1.6,1,2.1c0.9,1,0.7,2,0,3c-1.4,0.6-2.3,2.2-4.1,2
		C397,347.7,398.1,345.4,399.8,343.3z"/>
	<path class="st2540" d="M670.5,844.7c0.6-3.4,4-3.3,6.1-4.9c0.7,0,1.5,0,2.2,0C675.9,841.4,674.2,844.8,670.5,844.7z"/>
	<path class="st2541" d="M601.7,882.9c-0.1-0.5-0.1-1-0.2-1.4c0.5-3.1,3.2-3.3,5.3-4.4c1-0.2,2-0.1,2.7,0.7c0,0,0,0,0,0
		C607.6,880.6,604.5,881.4,601.7,882.9z"/>
	<path class="st2542" d="M512.7,92.6c1.5-2.8,4.9-2.3,7-3.9c0.4,0.4,0.6,0.9,0.6,1.4c-1.5,3.2-4,4.7-7.6,3.9
		C512.3,93.5,512.3,93,512.7,92.6z"/>
	<path class="st2543" d="M806.6,651c-0.4,0-0.8,0-1.2,0.1c-3.7-2.2-3.9-3.8-1.6-8.7c0.4-0.4,0.9-0.5,1.5-0.3
		C805.7,645.1,804.8,648.3,806.6,651z"/>
	<path class="st2544" d="M771.3,345.4c-0.8,2.7,2.4,6.2-1.9,8.1c-0.7-0.5-1-1.3-1.2-2.2c-0.1-2.2-0.6-4.5,1.5-6.2
		C770.3,345,770.8,345.1,771.3,345.4z"/>
	<path class="st2545" d="M822.5,537.3c0-5.4,0-10.7,0-16.1c0.3-0.4,0.5-0.4,0.8,0c0,5.3,0,10.7,0,16
		C823,537.7,822.7,537.7,822.5,537.3z"/>
	<path class="st2546" d="M754.4,213.6c0.6-1.2,1.5-2,2.9-2c0.1,2.3,2.5,3,3.2,4.9c-0.7,0-1.4,0.1-2.1,0.1
		C756.2,216.8,754.7,216,754.4,213.6z"/>
	<path class="st2547" d="M512.7,92.6c0,0.4,0.1,0.9,0.1,1.3c0.2,1.6-0.9,1.8-2.1,1.8c-2.5,0.1-4.2,2.6-6.8,2.2
		C506.5,95.5,509.4,93.8,512.7,92.6z"/>
	<path class="st2548" d="M773.3,332.4c0.4-0.6,0.8-1.3,1.2-1.9c1.1,3.4-0.1,6.3-2.2,8.9c-0.9,0.2-1.2-0.3-1.4-1c0-0.4,0-0.9,0.1-1.3
		C771.5,335.4,771.9,333.6,773.3,332.4z"/>
	<path class="st2512" d="M586.7,63c0.7,0.3,1.4,0.6,2.1,0.9c-0.9,3.1-4,1.9-5.9,2.9c-1.3,0.2-2.7,0.2-3.9-0.5
		c-0.5-0.3-0.6-0.8-0.4-1.4C581.3,64.3,584,63.6,586.7,63z"/>
	<path class="st2549" d="M626.6,869c0.3-0.7,0.7-1.4,1-2.1c0.6-2,2.2-2.5,3.9-3c1.4,0.2,2.8-1.3,4.2,0
		C633.1,866.3,629.9,867.8,626.6,869z"/>
	<path class="st2550" d="M519.7,89.7c0-0.3,0-0.7,0-1c1.5-2.2,3.5-3.1,6.1-2.7c-0.1,0.6-0.2,1.2-0.3,1.7
		C524.1,89.6,522.4,91.1,519.7,89.7z"/>
	<path class="st2551" d="M756.3,796.8c1.9-2.1,4-3.8,7-3.9c1-0.4,1.2,0.1,1.1,0.9C761.6,794.5,759.6,797.4,756.3,796.8z"/>
	<path class="st2522" d="M635.7,863.9c-1.5,0.3-2.9,0.5-4.4,0.8c-0.1-1.1,0.7-1.4,1.4-1.8c1.9-0.7,3.8-1.5,5.8-2.2
		c0,0.4,0.1,0.8,0.1,1.3C638.1,863.2,637.2,864,635.7,863.9z"/>
	<path class="st2552" d="M818.4,569.3c0-0.4-0.1-0.8-0.1-1.2c0.2-3.4,0.3-6.9,2.3-9.9C820.2,562,820.7,565.9,818.4,569.3z"/>
	<path class="st2553" d="M808.3,759.9c0.3,0,0.6,0,0.9,0c0.1,2.8-1.5,4.6-3.7,6c-0.4,0.4-0.8,0.4-1.1,0
		C805.7,764,807,761.9,808.3,759.9z"/>
	<path class="st2554" d="M438.8,161.6c0,0.6,0,1.2,0,1.9c-1.7,2-2.2,4.9-4.7,6.3C434.2,166.2,436.3,163.8,438.8,161.6z"/>
	<path class="st2555" d="M804.4,766c0.4,0,0.7,0,1.1,0c-1.4,2.5-2.5,5.2-6.1,5.2C800.5,768.9,802.5,767.5,804.4,766z"/>
	<path class="st2556" d="M805.2,642.1c-0.3,0-0.5,0-0.8,0c-2.3-3.3-0.9-6.2,1.1-9c0.4,0,0.7,0,1.1,0
		C805.9,636.1,807.5,639.4,805.2,642.1z"/>
	<path class="st2557" d="M759.3,122.7c-0.3,0-0.6,0-0.9,0c-0.8-0.9-0.9-1.9-0.1-2.9c0.1-3.1,0.1-6.2,0.2-9.3
		C760.6,114.5,759.1,118.6,759.3,122.7z"/>
	<path class="st2558" d="M555.7,907.8c-1.7,0.3-3.4,0.6-5,1c1.7-3.7,4.8-4.8,8.6-4.4c0.2,0.2,0.4,0.3,0.6,0.5
		c-0.1,0.3-0.2,0.6-0.3,1C558.5,907,556.9,907,555.7,907.8z"/>
	<path class="st2559" d="M566.7,901.9c-1.8,2-3.8,3.8-6.8,2.9c0,0-0.2-0.1-0.2-0.1c-0.3-0.5-0.2-1,0.1-1.4c2.1-1.5,4.1-3.3,6.9-1.5
		L566.7,901.9z"/>
	<path class="st2560" d="M437.9,466.1c1.5-1.4,3.3-0.6,4.9-0.9c2-1.2,3.9-2,5,1c0,0-0.1,0.2-0.1,0.2
		C444.5,467.2,441.2,466.1,437.9,466.1z"/>
	<path class="st2561" d="M813.4,723.1c0-4.1,0-8.1,0-12.2C815,715,815,719.1,813.4,723.1z"/>
	<path class="st2546" d="M766.5,221.6c-2.6-1-4.8-2.5-6.1-5.1C763.3,217.3,766,218.1,766.5,221.6z"/>
	<path class="st2562" d="M766.3,367.3c-1.2-3.2,1.3-5.8,1.3-8.9c0.2,0,0.4,0,0.7,0C768.8,361.6,767.7,364.5,766.3,367.3z"/>
	<path class="st2563" d="M764.4,793.8c-0.4-0.3-0.8-0.6-1.1-0.9c1.6-2.1,3.7-2.9,6.2-2.9C768.4,792,766.2,792.6,764.4,793.8z"/>
	<path class="st2564" d="M485.7,111.7c-0.2,2.4-2.5,2.8-3.9,4c-0.7,0-1.4,0-2.1,0C481.3,113.7,483.2,112.3,485.7,111.7z"/>
	<path class="st2565" d="M796.4,459.3c0-0.4,0-0.8,0-1.1c2.5,1.8,4.7,3.8,5,7.1c0,0,0,0,0,0C797.9,464.9,796.8,462.4,796.4,459.3z"
		/>
	<path class="st2566" d="M606.6,60.9c-3.2,1.5-6.7,0.5-10,0.8C599.8,59.8,603.2,61.1,606.6,60.9z"/>
	<path class="st2567" d="M636.7,61.7c-1.7,0.2-3.5-0.4-5.2,0.5c-0.3,0.7-0.6,0.7-0.9,0c-1-0.4-2-0.9-3-1.3
		C630.7,61.1,633.9,59.8,636.7,61.7z"/>
	<path class="st2568" d="M771.3,337.4c0.3,0.7,0.7,1.3,1,2c-0.3,2-0.6,4-1,6.1c-0.3,0-0.7,0-1,0
		C768.8,342.5,767.8,339.7,771.3,337.4z"/>
	<path class="st2569" d="M796.7,294.6c0-2.8,0-5.6,0-8.5c0.2,0,0.3,0,0.5,0c0,2.9,0,5.8,0,8.6C797,294.7,796.9,294.7,796.7,294.6z"
		/>
	<path class="st2570" d="M769.4,789.9c0.4-1,0.7-1.9,1.1-2.9c1.1,0,2.1-0.1,3.2-0.1C773.2,789.3,771.3,789.6,769.4,789.9z"/>
	<path class="st2571" d="M768.3,358.4c-0.2,0-0.4,0-0.7,0c0,0-0.4,0.1-0.4,0.1c-1.1-2.9-0.9-5.5,2.1-7.3c0.1,0.8,0.1,1.5,0.2,2.3
		C769.5,355.3,769.7,357,768.3,358.4z"/>
	<path class="st2545" d="M821.4,553.4c0.4-2.7-1-5.7,1-8.2C821.8,547.9,823.5,550.9,821.4,553.4z"/>
	<path class="st2572" d="M823.2,521.2c-0.3,0-0.5,0-0.8,0c0-2-0.1-3.9-0.1-5.9c0-0.7,0.1-1.4,0.1-2.2
		C824.4,515.7,823.1,518.5,823.2,521.2z"/>
	<path class="st2573" d="M822.5,537.3c0.2,0,0.5,0,0.7,0c-0.2,2.7,1.2,5.5-0.8,8.1C822.4,542.6,822.5,539.9,822.5,537.3z"/>
	<path class="st2574" d="M443.8,154.5c-0.2,2.6-2,4-4.1,5.1C440,157.1,442.1,156,443.8,154.5z"/>
	<path class="st2575" d="M817.4,494.2c0,1,0,2.1,0,3.1c-3-0.5-2.5-3-3-5c0.1-0.8,0.3-1.6,1-2.1C817,491.1,816,493.2,817.4,494.2z"/>
	<path class="st2576" d="M760.5,384.5c0-0.4-0.1-0.7-0.1-1.1c0.3-1.5,0.7-2.9,2-3.8C761.9,381.3,764.1,384,760.5,384.5z"/>
	<path class="st2577" d="M778.6,233.5c-2.6-0.2-2.8-2.2-3.1-4.1C776.9,230.5,778.5,231.4,778.6,233.5z"/>
	<path class="st2578" d="M815.4,490.2c0,0.7-0.1,1.4-0.1,2.2c-3-0.6-3.1-2.5-2.2-4.9c0.4,0,0.7-0.1,1.1-0.1
		C814.8,488.2,815.6,488.9,815.4,490.2z"/>
	<path class="st2579" d="M662.5,69.8c1.1,0,2.2-0.1,3.2-0.1c0.3,0,0.6,0.1,0.8,0.1c0.3,0.8,0.6,1.5,0.9,2.3
		C665.1,72.9,663.1,72.8,662.5,69.8z"/>
	<path class="st2580" d="M540.6,910.9c2.4,0,4.8,0,7.2,0C545.4,912.4,543,912.4,540.6,910.9z"/>
	<path class="st2581" d="M578.7,64.9c0,0.3,0,0.5,0,0.8c-1.4,1.4-3,2.2-5,1.2c0-0.4-0.1-0.8-0.1-1.3C575.1,64,577,65,578.7,64.9z"/>
	<path class="st2582" d="M695.6,82.7c-0.4,0-0.8,0.1-1.2,0.1c-1.2-0.3-2.4-0.6-2.9-1.9C693.4,80.5,694.8,80.9,695.6,82.7z"/>
	<path class="st2583" d="M764.4,374.3c-1.4-2.1,0.1-3.4,1.1-4.9C765.4,371.1,765.8,372.9,764.4,374.3z"/>
	<path class="st2482" d="M783.3,302.5c0.7-0.3,1.3-0.7,2-1c0.3,1.5,0.3,2.8-1.3,3.6C783.8,304.3,783.5,303.4,783.3,302.5z"/>
	<path class="st2584" d="M778.3,440.3c-1.9-0.1-2.9-1.4-3.8-2.9C776.7,437.2,777.6,438.7,778.3,440.3z"/>
	<path class="st2546" d="M770.6,224.5c-2.2,0-3.3-1.3-4.1-3C768.6,221.5,769.6,223.1,770.6,224.5z"/>
	<path class="st2585" d="M407.8,448.4c1.5,0.6,2.5,1.6,3.1,3.1C408.3,451.9,408.4,449.7,407.8,448.4z"/>
	<path class="st2586" d="M715.4,91.7c-0.6,0.2-1.2,0.3-1.9,0.5c-0.4,1.8-1.1,0.7-1.7,0.2c-0.1-0.5-0.2-1-0.2-1.5
		C712.8,91.3,714.5,89.8,715.4,91.7z"/>
	<path class="st2587" d="M811.4,666.2c0-1,0-2.1,0-3.1c0-1,0-2,0-3.1C813,662.1,812.9,664.1,811.4,666.2z"/>
	<path class="st2588" d="M750.6,425.3c-1.1,0-2.2,0.1-3.2,0.1c-0.7,0.5-1.4,0.5-1.9-0.3c0-0.3,0-0.6,0-0.8
		C747.1,424.8,749.2,423.4,750.6,425.3z"/>
	<path class="st2589" d="M759.5,130.8c0-1.7,0-3.5,0-5.2C761,127.3,761,129.1,759.5,130.8z"/>
	<path class="st2590" d="M758.5,207.5c0-1.7,0-3.3,0-5C759.9,204.2,760,205.9,758.5,207.5z"/>
	<path class="st2591" d="M555.7,907.8c1-1.2,2.3-1.8,3.9-2c-0.2,1.6-1.3,2.1-2.7,2.1C556.4,907.8,556.1,907.8,555.7,907.8z"/>
	<path class="st2592" d="M758.5,44.9c0-1.4,0-2.7,0-4.1c0-0.4,0-0.8,0.1-1.1C760,41.4,760.1,43.2,758.5,44.9z"/>
	<path class="st2593" d="M527.7,901.8c-0.4-0.7-0.9-1.3-1.4-2c-0.9-2.9-0.8-5.9-0.8-8.8c-0.1-15.4-0.1-30.7-0.2-46.1
		c-0.7-1.2-0.9-2.6-0.6-4c0.9-1.9,0.7-4.5,3.5-5.2c1.3,0.8,1.5,2.1,1.4,3.5c-0.3,8.6,0.7,17.2,0.3,25.8c-0.3,8.2,0.5,16.3,2.6,24.1
		c1.1,4-0.6,8.3,1.9,12c1.8,2.7,5.4,4,6,7.5C535.5,908.2,531.7,904.8,527.7,901.8z"/>
	<path class="st2594" d="M510.7,890.7c0.1-27.2,0.2-54.5,0.3-81.7c0.5-0.6,1.2-0.8,1.9-0.8c2.9,0.6,5.1,2.5,7.3,4.2
		c0.5,0.6,0.8,1.2,0.9,2c-3.4,8.3-3.3,16.4,1.2,24.3c0.6,1,1.1,2.1,0.1,3.2c-6.6,2.9-7.9,2-8.1-4.9c0-2.1-0.1-4.3,0.1-6.4
		c-1.3,14.1-2.2,28.2-2.7,42.4C511.1,878.8,513.1,884.9,510.7,890.7z"/>
	<path class="st2595" d="M511.8,872.8c0-14.5-0.2-29.1,1-43.6c0.1-1.5-0.2-3.6,1.6-3.7c2.3-0.1,1.3,2.2,1.4,3.4
		c0.2,2.1,0.1,4.3,0.1,6.5c0,7,0,7,7,5.4c1.8,0,1.5,0.9,1,2c-2.2,3-0.1,6.7-1.5,10.5c-0.4-1.5-0.6-2.5-0.8-3.5
		c-0.2,0-0.4,0.1-0.7,0.1c0,4.2,0,8.4,0,12.6c-0.3,0-0.7,0-1,0c0-3.5,0-6.9,0-10.4c0-1.3,0.3-2.8-1.5-3.2c-1.7-0.4-1.8,1.4-2.6,2.1
		c-5.1,4.2-2,9.7-2.9,14.5C512.3,868.1,513.6,870.7,511.8,872.8z"/>
	<path class="st2596" d="M523.7,842.9c0-0.8-0.2-1.6-1-2c-6.3-6.8-7.2-19.4-2-26.9c3-0.1,3.2,2,2.9,4c-0.8,6-0.6,11.9,2,17.5
		c0.8,1.7-0.1,3.6,0.1,5.4c-0.1,1.3-0.2,2.7-0.3,4c0,0-0.3,0-0.3,0l-0.3,0c-0.6-0.1-0.7-0.7-1-1
		C523.7,843.5,523.7,843.2,523.7,842.9z"/>
	<path class="st2597" d="M518.8,899.8c-2.3-2.7-4.7-5.3-7-8c3.9-0.5,8.1,0,12-2.1c0,3.5,0,6.8,0,10
		C522.1,898.7,520.4,898.7,518.8,899.8z"/>
	<path class="st2595" d="M523.7,843.9c0.3,0.3,0.7,0.7,1,1c0,3.6,0,7.2,0,10.8c-0.3,0-0.7,0-1,0
		C523.7,851.7,523.7,847.8,523.7,843.9z"/>
	<path class="st2598" d="M502.8,883.8c-1.4-0.1-3,0.5-4-1c-0.7-1.3-1.7-2.3-3-3c-6-5.5-8.6-12.6-8.9-20.5
		c-0.3-10.1-0.1-20.3-0.1-30.4c-0.9-1.6-0.4-3.2-0.3-4.8c0.4-0.4,0.9-0.5,1.5-0.3c0.4,0.4,0.7,0.8,0.8,1.4
		c0.8,7.6,0.3,15.1,0.3,22.7c0,4.6,0.9,9.2,1.2,13.8c0.1,1.3,0.3,2.7,1.7,3.4c1.9-0.7,2.6-3,4.5-3.7c2.9-0.9,5.1,1,7.4,2.2
		c2,3.2,1.2,6.7,0.9,10.1c-0.3,2.9-2.3,3.3-5.7,1.5C501.1,878.6,505.6,880,502.8,883.8z"/>
	<path class="st2599" d="M502.8,883.8c0-2.1,0.3-4.5-2.7-5c-3.1-0.5-2.7-3-2-4.6c1.3-2.9,2.3-0.3,3.5,0.5c1.9,1.4,2.2-0.1,2.2-1.5
		c0.1-3.1,0-6.3,0-9.4c3.9,1.7,2.5,5.3,2.7,8.1c0.2,2.2-0.2,4.5-0.2,6.2c0-0.3-0.1-1.3,0-2.2c0.7-5.1-0.3-10.2,0-15.4
		c0.2-3-0.2-6,0.4-8.9c1.2-6.2,0.4-12.5,0.6-18.8c0.3-7.4-0.4-14.9,0.4-22.3c0.4-0.9,0.8-1.8,2-1.6c0,15.3,0.5,30.6-0.2,45.8
		c-0.4,8.9-0.7,17.9-0.8,26.8c0,2,1.1,4.9-1.6,5.7C504.9,887.9,504.4,884.7,502.8,883.8z"/>
	<path class="st2600" d="M486.8,823.9c0,1.7,0,3.3,0,5c-0.3,0-0.7,0-1,0c0-24.9,0-49.7,0-74.6c0-1.8,0.5-3.7-0.7-5.3
		c-0.4-3.1-3.8-3.1-5.3-5c0.4-0.4,0.8-0.7,1.4-0.8c2.4-0.1,4.2,1.1,5.6,2.9c0.7,1,1.1,2.1,1.3,3.3c0.4,23.3,0.1,46.5,0.2,69.8
		C488.2,820.9,488,822.6,486.8,823.9z"/>
	<path class="st2601" d="M512.8,808.8c-0.6,0.1-1.2,0.1-1.8,0.2c-0.1-0.3-0.3-0.7-0.4-1c-1.3-1.4-1.2-3.1-0.8-4.7
		c0.4-1.1,1.1-1.8,2.4-1.7c2.3,1.5,4.3,3.4,4.8,6.3C516,809.7,513.8,806.7,512.8,808.8z"/>
	<path class="st2602" d="M510.7,803c0,1.7,0,3.3-0.1,5c-0.3,0.3-0.6,0.7-0.9,1c-0.3,0.3-0.7,0.7-1,1c-2.4,0.1-2.8-1.8-2.6-3.3
		C506.4,804.2,508.3,803.2,510.7,803z"/>
	<path class="st2599" d="M495.8,879.8c1.9,0.1,2.9,1.1,3,3C497.4,882.2,496.4,881.2,495.8,879.8z"/>
	<path class="st2603" d="M778.5,283.5c0-0.3,0-0.7,0-1c-0.4-0.9,0.2-1.3,0.8-1.6c5.7,0.9,5.7,0.9,8.7-7c0.2-0.1,0.4-0.3,0.5-0.4
		c2,5,1.2,10.1-0.3,14.9c-1,3.1-3.6,2.6-6,0.8C780.1,287.8,780.5,284.9,778.5,283.5z"/>
	<path class="st2603" d="M787.4,265.6c0-0.4,0-0.7,0.1-1.1c3.5,2.6,0.4,6,1,9c0,0-0.1,0.1-0.1,0.1
		C784.8,271.3,785.8,268.5,787.4,265.6z"/>
	<path class="st2604" d="M758.3,119.8c0,1,0.1,1.9,0.1,2.9c0,2.6-0.1,5.2-0.1,7.9c-0.3,0.1-0.6,0.2-0.9,0.2c-2-1.8-0.9-4.5-1.8-6.6
		c-0.5-1.9-1-3.7-1.5-5.6c-0.9-2.5-3-1.3-4.7-1.6c-2.1-0.2-4.3-0.7-4.4-3.1c-0.1-2.8,2.4-3.2,4.6-3.4c1-0.1,2-0.1,3-0.3
		c2.6-1.4,1-3.8,1.4-5.8c0-7.5,0-14.9,0-22.4c-0.1-1.8-0.1-3.7,0-5.5c0.1-10.9,0-21.8,0-32.7c0.1-1.2,0.1-2.5,0.8-3.6
		c0.4-0.6,0.9-0.7,1.5-0.4c0,0.3,0,0.6,0.1,0.9c0.3,1,0.5,2,0.8,3c-0.7,9.7-0.7,19.4,0,29.2c0,2.9,0,5.8,0,8.7
		c-0.7,8.1-0.6,16.2,0,24.3C757.7,110.5,756.6,115.3,758.3,119.8z"/>
	<path class="st2605" d="M742.6,39.8c0-0.3,0.1-0.7,0.1-1c0.8-0.7,1.6-0.6,2.3,0.2c0.5,0.8,0.7,1.6,0.8,2.6
		c0,14.6,0.3,29.2-0.4,43.9c-0.3,7.8-0.6,15.8,1.2,23.7c0.1,0.2-0.3,0.6-0.5,0.8c-4.4,0.2-4.8-2.8-4.6-5.9
		c0.7-11.3,0.4-22.5,0.5-33.8c0-1.5,0-3,0-4.5C742.1,57.1,741.3,48.5,742.6,39.8z"/>
	<path class="st2606" d="M742.5,65.7c0,12.6,0.1,25.1,0,37.7c0,3,0.2,5.7,4,6.3c0.9-0.1,1.8-0.1,2.7,0.1c0.8,0.4,1.8,0.6,1.3,1.9
		c-1.5,0.8-4.4-0.6-4.4,1.9c0,2.9,2.8,1.9,4.6,2.1c0.5,0.4,1.3,0.7,1.1,1.6c-0.5,1-1.4,1.2-2.4,1.4c-1.2,0.1-2.5,0.1-3.7-0.2
		c-2.2-0.9-3.3-2.9-4.7-4.6c-0.6-1.3-1.6-2.6-0.6-4.1c0.1-0.2,0.3-0.4,0.5-0.4c0.5-3.9-0.4-7.9,0.6-11.9c0-10.6,0-21.2,0-31.7
		C741.7,65.4,742.1,65.3,742.5,65.7z"/>
	<path class="st2607" d="M740.4,109.9c0.5,1.2,0.9,2.5,1.4,3.7c-3.9,1.6-5.4-2-7.7-3.7c-1.1-1.6-2.4-3.2-4.1-4.3
		c-0.9-0.6-1.7-1.4-2.2-2.4c-0.4-0.8-0.7-1.7-0.1-2.6c0.6,0,1.2,0.1,1.9,0.1C732.8,104.1,735.9,107.9,740.4,109.9z"/>
	<path class="st2608" d="M742.5,65.7c-0.4,0-0.7,0.1-1.1,0.1c-0.7-7.7,0-15.3,0-23c0-1.1-0.2-2.4,1.1-3
		C742.5,48.5,742.5,57.1,742.5,65.7z"/>
	<path class="st2608" d="M741.4,97.6c-0.1,4,0.7,8-0.6,11.9C740.5,105.5,740.3,101.5,741.4,97.6z"/>
	<path class="st2609" d="M744.4,38.7c-0.6,0.1-1.2,0.1-1.7,0.1c0.8-2.3,2.9-2.6,4.8-3.2c0.7-0.4,1.4-0.4,2.1,0
		C749.1,38.8,746.6,38.4,744.4,38.7z"/>
	<path class="st2609" d="M756.4,39.9l-0.4-0.1l-0.4,0c-0.7,0-1.3-0.3-1.9-0.7c-0.8-1.3-4-0.9-2.9-3.7c0.9-0.1,1.9-0.3,2.7,0.4
		C755,36.7,756.5,37.7,756.4,39.9z"/>
	<path class="st2453" d="M753.5,35.8c-1,0-2.1,0.1-3.1,0.1l-0.4,0.3l-0.4-0.3c-0.7-0.1-1.4-0.1-2.2-0.2
		C749.5,34.4,751.5,34.3,753.5,35.8z"/>
	<path class="st2610" d="M754.4,213.6c1.3,1,2.7,2,4,3c6.3,7.2,13.7,13.4,18,22.3c2.3,4.8,4.6,9.5,6,14.6c0.7,1.2,0.6,2.5,0.3,3.8
		c-1.9,0.8-2.1-0.8-2.5-1.9c-0.7-1.6-0.9-3.4-1.6-5.1c-2.3-5.2-4.9-10.3-8-15.2c-6-8.5-14.1-14.2-23.7-17.9
		c-1.3-0.9-2.6-1.8-1.5-3.7C748.4,212.7,751.4,213.5,754.4,213.6z"/>
	<path class="st2611" d="M782.4,257.5c0-1.3,0-2.7,0-4c1.4,2.5,3.4,4.8,3,8c1.1,4,1.5,8,1.7,12.1c0.1,1.8,0,3.4-1.7,4.2
		c-1.8,0.9-3.5,0.4-5-0.9c-2.3-2-3.4-4.8-5.6-6.9c-0.7-0.4-1.3-0.9-1.8-1.5c-0.9-2.2-1.9-4.3-0.9-6.7c0.9-1,1.8-0.4,2.7,0.1
		c2.9,2.7,4.2,6.8,7.7,9C782.5,266.4,782.6,262,782.4,257.5z"/>
	<path class="st2612" d="M775.4,268.6c1.8,2.3,3.7,4.5,5.3,7c1.1,1.7,2.6,1.9,4.2,1.5c1.5-0.4,1.3-2,1.5-3.3c0.6-4.1-1.3-8.1-1-12.2
		c0.6,1.4,1.3,2.7,1.9,4.1c0.5,2.6-0.9,5.5,1,8c-0.2,1.4-0.3,2.9-0.6,4.3c-1.5,6.3-2.5,6.8-8.3,3.6c-1.4-1-0.9-2.9-2-4l0,0
		C776,274.7,773.6,272.1,775.4,268.6z"/>
	<path class="st2613" d="M400.9,348.4c0-1,0-2,0-3c4-3.4,4.9-9.5,10-12c2.3-2.7,4.1-2,5.7,0.8c0,5,2.8,7.5,7.4,8.7
		c2.4,0.6,4.6,1.7,6.7,3.1c1.3,0.8,3.1,1.6,2.2,3.7c-1,0.6-2,0.5-2.9-0.3c-3.7-2-7.7-3-12-3c1.1-1.7-0.6-1.5-1.3-2.1
		c-1.7-1.3-2.2-0.3-2.7,1.1C409.2,345,405,346.8,400.9,348.4z"/>
	<path class="st2614" d="M396.8,354.4c6.1-3.3,12.3-4.8,18.7-0.7c1.1,0.7,2.1,0.8,3.3,0.8c2.6-0.7,4.3,1.1,6.2,2.3
		c1.3,1.2,2.6,2.3,4.2,3.1c0.6,0.4,0.9,0.9,1,1.6c-1.7,2.6,1.4,4.8,0.5,7.4c-0.6,0.4-1.3,0.5-2.1,0.3c-0.7-0.2-1.3-0.6-1.8-1.2
		c-6.6-7.7-14.3-12.8-25.1-10.9c-1,0.2-2-0.1-2.9-0.7C398,355.9,397.2,355.3,396.8,354.4z"/>
	<path class="st2615" d="M424.8,357.5c-2-1-3.9-2.1-5.9-3.1c-0.4-1.1-2.2-1-2.2-2.8c4.4-0.5,9.2,0.8,13.1-2.2c1,0,1.9,0.1,2.9,0.1
		c2,0.1,3.9,0.4,4.3,2.9c-0.3,0.4-0.7,0.7-1.1,0.9c-4,0-7.5,1.5-10.5,4.2C425.2,357.4,425,357.5,424.8,357.5z"/>
	<path class="st2616" d="M396.8,354.4c0.8,0.1,1.6,0.2,2.1,1.1c-0.8,2.2-3.2,2.5-4.6,4c-2.3,0.7-1.7,5.3-5.4,4
		c0.7-1.9,1.3-3.7,2-5.6C392.9,356.7,394.9,355.5,396.8,354.4z"/>
	<path class="st2617" d="M413.9,345.4c-0.6-1.3-1.8-2.9-0.1-4c1.4-0.9,1.5,1.4,2.5,1.7c0.6,0.2,1.2,0.7,1.8,0.9
		c4.5,1.2-0.6,1.6-0.2,2.4C416.5,346.1,415.2,345.8,413.9,345.4z"/>
	<path class="st2618" d="M376,706.9c-0.5-2.9-4.2-2.4-5.1-4.7c-0.9-1.8-4.4-1.4-3.8-4.4c0.9-0.6,2-0.9,2.8-0.4
		c2.5,1.5,5.9,2.1,7.7,3.5c2,1.6,4.1,2.6,6.2,3.8c4.6,2.7,9.9,3.8,14.6,6.3c3.1,1.4,6.4,2.4,9.6,3.6c1.7,0.6,4,0.8,4,3.5
		c-2,1.8-3.8-0.3-5.8-0.1c-2.5-0.6-4.9-1.7-7.6-1.8c-0.3,0-0.5-0.1-0.8-0.2c-2.6-2.5-6.5-2.1-9.3-4.2c-4.1-1.1-7.5-4.3-11.9-4.6
		C376.4,707.1,376.2,707,376,706.9z"/>
	<path class="st2619" d="M405.9,717.1c2,0.3,4,0.6,6,0.9c3.3-2,6.1,0.8,9.2,1.1c8.1,2.3,16.2,4.3,24.1,7.2c1.4,0.5,3.9,0.2,3.5,3
		c-1.4,0.2-2.7,0.5-4.1-0.2c-3.8-0.2-7.3-1.6-10.9-2.7c-8.4-3.3-17.3-4.9-25.9-7.3l0,0C407.5,718.1,405.3,719,405.9,717.1z"/>
	<path class="st2620" d="M465.9,736.7c-4-1.3-7.9-2.6-11.9-3.9c-0.7-0.3-1.5-0.6-2.2-0.9c-0.5-0.7-0.6-1.4-0.1-2.1
		c4.1-1.7,7.2,1.7,10.9,2.1c2,0.8,4.5,0.9,5.4,3.5C468,736.8,466.7,736.3,465.9,736.7z"/>
	<path class="st2463" d="M407.8,719.1c8.9,1.4,17.7,3.2,25.9,7.3C425.1,724,416.2,722.6,407.8,719.1z"/>
	<path class="st2621" d="M376.7,707.1c5.1-1.7,8.1,2.7,12.1,4.1c0.1,1-0.2,1.3-1.2,0.9C383.6,711.4,380.1,709.3,376.7,707.1z"/>
	<path class="st2463" d="M387.6,712.2c0.4-0.3,0.8-0.6,1.2-0.9c2.7,2.1,7.4,0.5,9.1,4.7C394.3,715.1,390.8,714.1,387.6,712.2z"/>
	<path class="st2463" d="M405.9,717.1c0.7,0.7,1.3,1.4,2,2c-3.3-0.3-6.3-1.4-9.2-3C401.3,714.9,403.5,716.8,405.9,717.1z"/>
	<path class="st2622" d="M451.8,730c0,0.6,0,1.2,0,1.9c-2.4-1-4.8-1.9-7.2-2.9c1.4,0,2.8,0,4.2,0C450.3,728,451.2,728.6,451.8,730z"
		/>
	<path class="st2623" d="M756.6,382.6c-1.4,4.6-2.8,9.2-4.2,13.8c-0.9,1.5-1.1,3.4-2,4.9c-0.3,0.7-0.6,1.4-0.9,2.1
		c-1.1,2.3-2,4.7-3.1,7c-1.5,2.7-3.1,5.5-4.6,8.2c-0.9,0.4-1.5-0.1-1.9-0.9c-0.6-3.5,1.8-5.9,3-8.8c7.2-13.6,11.1-28.3,14.8-43.1
		c3.8-18,3.3-35.9-0.7-53.8c0.2-1.2,0.7-2.3,2.1-2.7c2.4,1.7,2.8,4.5,3.3,7c2.8,12,3.2,24.3,1.9,36.5c-1.6,7.3-3.2,14.6-4,22
		C759.3,377.5,758.4,380.2,756.6,382.6z"/>
	<path class="st2624" d="M745.5,425.1c0.6,0.1,1.3,0.2,1.9,0.3c1.1,0.6,2.2,1.2,3.2,1.8c-0.1,0.2-0.3,0.4-0.4,0.6
		c-0.1,0.1-0.3,0.2-0.4,0.2c-7.7-1.5-10.4,4.3-13.5,9.3c-0.4,0.6-0.8,1.1-1.2,1.6c-2.9,3.1-4.8,6.9-7.2,10.4
		c-0.4,0.3-0.8,0.4-1.3,0.3c-1.1-0.6-1.8-1.5-2.2-2.6c3.4-6.6,7.6-12.6,13.1-17.5C739.6,427.1,741.6,424.4,745.5,425.1z"/>
	<path class="st2625" d="M737.4,429.5c-4.1,5.9-8.3,11.8-12.4,17.7c-0.3,0.4-0.7,0.5-1.1,0.6c-0.4-0.1-0.8-0.3-1.2-0.6
		c-0.6-0.7-0.9-1.6-0.7-2.5c4-5.1,6.5-11.5,11.8-15.6c1.8,0.6,1.6-3,3.7-1.7C737.5,428.1,737.4,428.8,737.4,429.5z"/>
	<path class="st2626" d="M737.5,427.3c-1.6-0.2-1.2,2.6-3.1,2c-1.8-3.2,1.8-5.1,2-7.9c0.6-1.6,1.4-3.1,2.7-4.2
		c0.5-0.2,0.9-0.2,1.3,0.2c0.4,0.4,0.9,0.7,1.3,1.1C741.3,421.9,738.5,424.2,737.5,427.3z"/>
	<path class="st2627" d="M756.6,382.6c0.9-2.4,1.8-4.9,2.6-7.3c0.2,0.2,0.4,0.3,0.6,0.5c0,1.2,0.6,2.5-0.6,3.5
		c-0.8,0.5-0.8,1.3-0.8,2.1c-0.1,0.3-0.2,0.5-0.2,0.8C757.8,382.3,757.2,382.5,756.6,382.6z"/>
	<path class="st2626" d="M746.4,410.3c0.1-2.7,1.2-5,3.1-7C749.4,406.1,748.3,408.4,746.4,410.3z"/>
	<path class="st2626" d="M750.4,401.3c0-1.9,0.5-3.6,2-4.9C752.4,398.2,752.1,400,750.4,401.3z"/>
	<path class="st2628" d="M414,293.3c-0.2-19.5-0.8-39.1,0-58.6c0.7-0.2,1.2,0,1.6,0.7c0.6,1.2,0.7,2.6,0.6,3.9
		c-0.7,23.6,1.3,47.2,1,70.8c0,2.3,0.9,5.1-2.1,6.6c-2.8-3.7-1.6-7.9-1.2-12C414.3,300.9,414.7,297.1,414,293.3z"/>
	<path class="st2629" d="M414,293.3c1.6,5.6,0.4,11.3,0.9,17c0.2,1.9,0.2,3.9,0.4,5.8c1.4,1.2,1.9,2.9,1.9,4.7
		c-0.2,3.2,0.7,6.5-1,9.6c-1.1,0.9-2.2,0.9-3.3,0.1c-0.3-0.4-0.6-0.8-0.9-1.1c1-6.4,0.1-12.7,0.4-19.1c0.1-1.9-0.5-3.9,0.4-5.8
		c0-0.6,0-1.3,0-1.9C414.8,299.7,413.2,296.4,414,293.3z"/>
	<path class="st2630" d="M414.9,234.6c-0.3,0-0.6,0-0.8,0.1c0-1.7-0.1-3.3-0.1-5c0.2-0.1,0.5-0.2,0.7-0.3c0.2,0.2,0.4,0.3,0.7,0.5
		C416.2,231.6,416.3,233.2,414.9,234.6z"/>
	<path class="st2631" d="M366.9,698c1.2,1.5,3.4,2,4,4.1c-5-3-10.9-4.5-14.3-9.8c-0.8-1.2-2-2.3-3.6-2.3c-1.9-0.8-2.8-2.7-4.3-3.9
		c-1.2-1.4-2.5-2.8-3.7-4.2c-1.3-3.3-3.7-6.1-4-9.8c-0.8-4-0.8-8,0-12c0.7-3.6,1.5-7.2,2.2-10.8c0.2-0.1,0.5-0.2,0.7-0.2
		c1-1.5,1.3-3.3,2-5l0-0.1c0.6-0.3,1.1-0.2,1.5,0.3c1.9,4.1-0.6,7.8-1.5,11.4c-1.2,4.7-1.5,9.4-1.5,14.1c3.1,7.7,7.7,14.1,14.2,19.2
		C361.8,691.6,365.2,694,366.9,698z"/>
	<path class="st2632" d="M354.9,624c2.5-4.8,4.8-9.6,7.9-14c0.5-0.3,1-0.3,1.4,0.1c0.7,0.9,0.8,1.9,0.6,2.9
		c-0.2,1.2-0.7,2.3-1.4,3.4c-2.7,2.6-3.4,6.5-5.8,9.4c-1,1.7-1.7,3.5-2.6,5.3c-0.8,1.7-1.6,3.7-4.3,3.1
		C350.5,630.1,353.6,627.4,354.9,624z"/>
	<path class="st2633" d="M354.9,624c-0.3,3.8-3.4,6.4-4,10.1c0.5,4-1.2,7.2-3.9,10c-0.4,0-0.7-0.1-1.1-0.1
		C347.8,636.9,350.2,629.9,354.9,624z"/>
	<path class="st2634" d="M372,596.3c0.9-0.8,1.9-1.5,2.8-2.3c0.9,4.9-2.8,7-5.8,9.5c-0.5,0.1-1-0.1-1.4-0.4
		C369.1,600.8,370.6,598.6,372,596.3z"/>
	<path class="st2635" d="M367.6,603.1c0.4,0,0.7,0.1,1.1,0.1c0.1,3.5-2.1,5.5-4.8,7.1c-0.4-0.1-0.8-0.1-1.2-0.2
		C363.8,607.3,365.5,605,367.6,603.1z"/>
	<path class="st2633" d="M345.9,644.1c0.2,2-0.3,3.8-2,5C344,647.2,344.3,645.4,345.9,644.1z"/>
	<path class="st2636" d="M810.1,682.9c0.5,2,1,4.1,1.4,6.1c-0.1,19.3-0.1,38.6-0.2,57.9c-0.3,0-0.6,0-1,0c-1.7-1.8-1.3-4-1.3-6.1
		c-0.1-11.6-0.2-23.2,0-34.8c0.1-7.6-0.2-15.2-1.5-22.7C808.2,681.9,808.9,681.2,810.1,682.9z"/>
	<path class="st2637" d="M405.9,442.1c-0.3-0.6-0.7-1.1-1-1.7c-4.5-4.9-8-10.4-11-16.3c-1.6-4.6-3.3-9.3-4.9-13.9
		c-0.8-2.2-1.3-4.4-1.2-6.8c0.3-2-0.5-4.2,0.8-6.1c0.5-0.3,1-0.3,1.5,0c1.1,1.3,1,2.9,1.1,4.5c1.6,11.3,6.1,21.4,13.1,30.4
		c2.1,2.4,3.8,5.1,6,7.4c2,2.1,4,4.1,6.1,6.1c1.8,2.6,5.2,3.4,6.7,6.3c0,1-0.5,1.8-1.2,2.5c-0.4,0.2-0.9,0.3-1.3,0.3
		c-3.6-1.7-6.6-4.1-9.3-7C409.4,446.1,407.2,444.5,405.9,442.1z"/>
	<path class="st2638" d="M389,397.2c-0.5,2.1,0.7,4.4-1.2,6.2c0,0,0,0,0,0c-1.1-1.5,0.3-3.6-1-5c-1.7-3.9-0.5-8.1-0.9-12.1
		c-0.2-1.7,0.6-3.6-0.9-5.2c0,0-0.1-0.5-0.1-0.5c0.2-0.7,0.5-1.4,1.3-1.6c0.5,0,0.9,0.2,1.2,0.6c0.3,0.6,0.4,1.2,0.3,1.9
		c0.4-0.4,0.2-1,0.5-1.4c1.1-1.4,1.8-1.1,2.5,0.4C390.1,386.1,391.3,391.9,389,397.2z"/>
	<path class="st2639" d="M393.9,424.1c4.1,5.1,7.8,10.5,11,16.3c-5.8-3-7.6-9.2-11-14C393.9,425.6,393.9,424.9,393.9,424.1z"/>
	<path class="st2640" d="M384.9,389.4c-1.5-2.3-1.5-4.7,0-7C384.9,384.7,384.9,387.1,384.9,389.4z"/>
	<path class="st2637" d="M386.8,398.4c1.8,1.4,0.8,3.3,1,5C386.4,402,387,400.1,386.8,398.4z"/>
	<path class="st2641" d="M447.8,466.2c-1.4-1.6-3.3-0.7-5-1c-1.3-2.7,0.9-3.5,2.6-4.2c3.6-1.5,4.9-4,4.4-7.7
		c-1.3-1.6-0.3-2.9,0.4-4.3c0.3-0.5,0.6-0.9,1-1.2c4.8-9.4,3.7-19.3,2.4-29.2c-0.7-5.4-3.3-10.5-2.2-16.1c1.3-1.3,2.5-0.6,3.7,0.2
		c3.8,3.6,5,8.4,5.9,13.2c-0.3,5,1.3,9.9,1.1,14.9c-0.1,3.2-0.4,6.3-1.2,9.4c-1.7,5.2-2.5,10.6-4.2,15.7c-1.1,3.2-0.2,4.6,2.9,5.1
		c0.8,1.5-0.5,2.2-1.3,3.2c-1.7,1.2-3.4,2.4-5.7,1.1C451.2,465.6,449.5,465.9,447.8,466.2z"/>
	<path class="st2642" d="M472.8,479.3c-1.3-1.8-2.5-3.7-5-4c0-2.5,2-2.7,3.7-3c2.1-0.4,4.1-1,6-1.9c2-0.3,3.9-1.1,6-0.5
		c1.3,4.2,3.9,6.1,8.4,4.7c1.1-0.3,2.3-0.2,3.1,0.9c-0.3,2-1.9,2.4-3.5,3C485.3,480.1,479.1,480,472.8,479.3z"/>
	<path class="st2643" d="M477.8,471.3c-2.6,3.2-7.4,0.7-10,4c-4.3-0.8-7.6-3.4-11-6c2.1-1.9,5.2-0.1,7.4-1.8
		c5.2,0.4,10.3,1.4,15.6,0.6C481.3,470.5,478.9,470.5,477.8,471.3z"/>
	<path class="st2644" d="M463.8,468.3c-1.9,3.7-4.8-0.1-7,1c-1.7-1-3-2.3-4-4c1.8-0.5,3.6-0.7,5-2c2.2-0.7,4.1-2.1,6.4-2.3
		c2,0,3.7-1.7,5.8-0.7c0.6,0.9,0.7,1.8,0.3,2.8c-1.6,1.9-4.1,1.7-6.2,2.3c-1.8,0.5-1.5,1.3-0.5,2.4
		C463.6,467.9,463.7,468.1,463.8,468.3z"/>
	<path class="st2645" d="M472.8,479.3c2.4-1.5,5.1-1,7.7-0.9c3.8,0.1,7.5-0.2,11.2-1c0.3,0.3,0.4,0.7,0.3,1.2
		c-0.3,0.6-0.8,1-1.4,1.4c-1.2,0.6-2.2,1.6-3.5,2c-0.5,0.1-1,0.1-1.4,0c-4-0.7-8,1.9-11.9-0.4c0,0,0-0.1,0-0.1
		C473.5,480.6,473.1,479.9,472.8,479.3z"/>
	<path class="st2646" d="M428.8,460.4c-2.3-1-4.7-2-7-3c-0.3-0.3-0.7-0.6-1-1c0.1-0.7,0.6-1.2,1.3-1.4c2.6,0.4,4.8,2,7.4,2.4
		c0.4,0.1,0.9,0.2,1.3,0.4c2.9,0.4,5.7,0.7,8.5-0.4c2.7-0.9,4.8-3,7.5-3.8c0.8,0,1.4,0.2,2,0.7c-2.3,5-10.1,8.1-17.1,6.8
		C430.7,460.9,429.8,460.6,428.8,460.4z"/>
	<path class="st2647" d="M448.8,454.3c-0.6,0-1.3,0-1.9,0c-0.1-2.8,0.9-4.7,3.9-5c-0.3,1.4-0.6,2.7-0.9,4.1
		C449.5,453.7,449.1,454,448.8,454.3z"/>
	<path class="st2609" d="M757.4,72.9c-1.2-9.7-1.3-19.5,0-29.2C757.4,53.5,757.4,63.2,757.4,72.9z"/>
	<path class="st2609" d="M757.3,105.9c-1.3-8.1-1.2-16.2,0-24.3C757.3,89.7,757.3,97.8,757.3,105.9z"/>
	<path class="st2648" d="M763.3,353.3c0.4-14.2,0.7-28.5-3.9-42.2c-0.2-0.6-0.6-1.2-0.8-1.8c-1.4-0.8-1.6-2.3-2.1-3.5
		c-0.4-1.8-1.7-3.3-1.2-5.3c1.5-0.4,3-1.2,4.6-0.8c0.3,0.4,0.5,0.8,0.6,1.2c0.2,3.2,1,6.3,0.3,9.7c0.6-1,0.1-2.9,2.2-2.2
		c2.3,1.8,1.7,4.7,2.7,7c1.8,10.4,1.2,20.9,1.4,31.4c0,2.3,0.3,4.7-1.6,6.6c-0.6,1.7-1.2,3.5-1.8,5.2
		C762.6,356.8,762.9,355.1,763.3,353.3z"/>
	<path class="st2649" d="M765.4,353.3c0.3-12.9,0.7-25.9-1-38.8c0.3-0.1,0.5-0.1,0.8-0.2c2.1,1.4,1.5,3.8,2.3,5.7
		c0.2,5.7,2.4,11.3,0.6,17.1c0.9-2.2-0.5-5.5,3.7-5.4c0.7,2,0.4,3.9-0.3,5.8c0,0-0.2-0.1-0.2-0.1c-1.4,2.5-0.8,5.3-1,8
		c-1.3,1.8-0.8,3.9-1,5.8c-0.7,2.4-1.4,4.8-2.1,7.3c-0.6,0-1.3,2.2-1.7,0.1C765.2,356.9,765.4,355.1,765.4,353.3z"/>
	<path class="st2627" d="M763.3,353.3c0.1,1.7,0.1,3.5,0.2,5.2c0.3,5.1-2.3,9.7-2.2,14.7c-0.6,0.7,0.1,2.4-1.7,2.3
		c0,0-0.4-0.3-0.4-0.3C760.6,367.9,762,360.6,763.3,353.3z"/>
	<path class="st2510" d="M759.7,375.6c0.6-0.8,1.1-1.5,1.7-2.3c0.1,2.3-0.1,4.4-2,6C759.4,378,759.5,376.8,759.7,375.6z"/>
	<path class="st2650" d="M756.5,123.6c1.6,2.2,0.5,4.8,0.9,7.2c0,4.9-0.1,9.9-0.1,14.8c-0.3,0-0.6,0.1-0.8,0.1
		C754.2,138.4,754.2,131,756.5,123.6z"/>
	<path class="st2477" d="M451.7,147.6c0-0.6,0.1-1.3,0.1-1.9c1.3-1.4,2.6-2.8,3.9-4.2c1.2-1.2,2.1-2.5,3.1-3.8
		c2.9-2.7,5.8-5.4,8.8-8.1c0.4-0.4,0.8-0.4,1.2,0.1l0.1,0.2c0.3,1-0.2,1.8-0.8,2.6c-4.3,4.7-7.8,10.3-12.7,14.5
		c-0.7,0.6-1.6,1-2.5,1.2C452.5,148.1,452,147.9,451.7,147.6z"/>
	<path class="st2651" d="M468.8,129.7c-0.4,0-0.8-0.1-1.2-0.1c0.7-2,1.9-3.5,4.1-4C470.7,126.9,469.7,128.3,468.8,129.7z"/>
	<path class="st2489" d="M458.8,137.7c-0.2,2-1.3,3.2-3.1,3.8C456,139.6,457.2,138.5,458.8,137.7z"/>
	<path class="st2652" d="M813.4,590.2c-0.1,0.3-0.2,0.6-0.2,0.9c-1.6,4.2-2.5,8.5-2.8,13c-1.1,1.5,0.2,3.5-0.9,5.1l0,0
		c-1.3,1.8,0.3,4.2-1,6c-0.3,1-0.7,2-1,3c-0.7,4-1.1,8.1-1.9,12c0,0.3,0,0.6,0,0.9c-0.6,0.3-1.1,0.2-1.5-0.3
		c-1.1-1.8-0.8-3.8-0.9-5.8c1.8-10.5,3.7-21.1,6.3-31.5c1.2-2.7,1-5.8,3-8.3c0.5-0.3,1-0.3,1.5,0.1
		C813.8,587,814.3,588.6,813.4,590.2z"/>
	<path class="st2653" d="M804.5,631.2c0.3,0,0.6-0.1,0.9-0.1c0,0.7,0.1,1.4,0.1,2c-0.4,3-0.7,6-1.1,9c-0.8,3.1-1.9,6.3,1,9
		c0,0.3-0.1,0.6-0.1,0.9c-0.3,0.9-0.7,1.4-1.7,1.4c-0.5-0.3-0.9-0.7-1.2-1.2C800,644.9,801.4,637.9,804.5,631.2z"/>
	<path class="st2653" d="M805.4,630.1c-0.3-4.2,0.7-8.1,1.9-12C807.6,622.3,807.1,626.3,805.4,630.1z"/>
	<path class="st2654" d="M808.4,615.1c0.2-2-0.6-4.2,1-6C809.1,611.2,810.2,613.4,808.4,615.1z"/>
	<path class="st2654" d="M809.4,609.1c0.1-1.7-0.4-3.6,0.9-5.1C810.2,605.8,811.1,607.7,809.4,609.1z"/>
	<path class="st2655" d="M756.5,123.6c0,7.4,0,14.7,0,22.1c0,19.4-0.1,38.8,0,58.2c0,2.8-0.4,4.8-3.7,5c-0.3,0-0.6,0.2-0.9,0.4
		c-0.5,0.3-0.8,0.2-1-0.3c-0.2-0.7-0.2-1.4,0-2.1c0.2-0.4,0.5-0.8,0.9-1.1c0.6-0.4,1.3-0.8,1.7-1.4c0.7-1.6,0.5-3.3,0.5-4.9
		c0-20.1,0-40.2,0-60.2c0-2.8-1-5.6-0.5-8.5c1.8-4.3-0.8-8.7,0.5-13c0.4-0.5,0.9-0.6,1.4-0.3C755.8,119.7,756.2,121.6,756.5,123.6z"
		/>
	<path class="st2656" d="M446.8,533.1c4.7-1.4,7.9-5.3,12.3-7.2c0.8,0.2,1.2,0.7,1.4,1.5c0.1,0.7-0.1,1.4-0.5,2.1
		c-1.5,1.2-3.4,1.5-4.9,2.7c-7.1,2.7-12,8.8-18.8,12c-2.1,1.2-4.3,2.3-6.2,3.9c-1.2,1-2.5,2.3-4.3,1.4c-0.3-0.3-0.3-0.6-0.3-0.8
		c0.1-0.3,0.2-0.4,0.3-0.4C432.8,543.2,439.8,538.1,446.8,533.1z"/>
	<path class="st2657" d="M425.8,548.2c0,0.4,0,0.7,0.1,1.1c-1.7,4.5-6.5,5.7-9.5,8.8c-2.3,1.5-3.9,4.6-7.4,3.2
		c0.4-0.7,0.8-1.3,1.2-2C415.7,556.1,420.1,551.2,425.8,548.2z"/>
	<path class="st2658" d="M641.6,855.9c-2.8,3.2-7.3,3.8-10.1,6.9c-3.2,3.5-7.9,4.7-11.9,6.9c-5,2.8-9.8,6-14.9,8.6
		c-3.6,2.1-7.1,4.7-11.3,5.6c-2.9,0.4-5-1.1-7-2.8c-0.6-0.9-0.7-1.9-0.2-2.9c4.7-2,8.8-4.6,10.1-10c4.2-3.3,9.3-4.5,14.4-4.5
		c5.4-0.1,8.4-4.5,12.7-6.2c1-0.4,0.7-1.4-0.1-2c-1-0.8-2.4-1.2-3-2.6c4.5-5.5,9.9-9.6,17.3-10.3c0.8,0.7,1,1.7,1,2.7
		c0.2,2.2-1.7,3.4-2.8,6.1c3.8-3,8.6-3.5,9.5-8.5c1.3-1.3,2.8-1.2,4.3-0.4c1.8,2.5,3.6,5,1.8,8.2c-0.7,0.4-1.3,0.7-2,1.1
		C646.8,853,643.8,853.6,641.6,855.9z"/>
	<path class="st2659" d="M651.6,850.8c-0.7-2.6-1.3-5.3-2-7.9c-1.3-0.9-3.5-1.6-2.9-3.5c0.6-2.1,3-1.7,4.8-1.7c2.2,0,5,0.1,4.9,2.9
		c-0.2,2.9,1.5,3.2,3.2,4c0.2,0.3,0.3,0.6,0.3,0.9c-0.1,0.3-0.1,0.4-0.2,0.4C656.9,847.5,654.2,849.1,651.6,850.8z"/>
	<path class="st2660" d="M604.5,877.7c8.6-5.7,17.7-10.4,27-15c0.4,0.1,0.9,0.1,1.3,0.2c-0.5,0.6-0.9,1.2-1.4,1.8
		c-1.2,0.8-2.5,1.5-3.7,2.3c-4.2,0.9-7.6,3.6-11.1,5.8c-2.8,1.1-5.7,1.8-6.9,5c-1,0-2.1,0-3.1-0.1
		C605.8,878.4,605.1,878.4,604.5,877.7z"/>
	<path class="st2659" d="M641.6,855.9c1.6-3.4,4.9-3.6,8-4C647.3,853.9,644.3,854.6,641.6,855.9z"/>
	<path class="st2661" d="M659.6,845.9c0-0.3,0-0.7,0-1c0.3-0.4,0.6-0.7,1-0.9c1.8-0.6,3.5-2.1,5.6-1.5c0.2,0.2,0.3,0.3,0.5,0.5
		c-0.9,1.7-2.6,2.1-4.1,2.9C661.6,845.8,660.6,845.9,659.6,845.9z"/>
	<path class="st2662" d="M485.7,746c-1.6-0.7-3.3-1.4-4.9-2c-2.5-1.7-5.2-2.9-8-4c-1.1-0.6-2.1-1.1-3.2-1.7
		c-0.3-0.5-0.4-0.9-0.1-1.4c7-0.6,12,3.1,16.6,7.7C486.3,745.1,486.1,745.6,485.7,746z"/>
	<path class="st2663" d="M472.9,740c3.2,0.1,5.9,1.4,8,4c-0.4,0-0.8,0-1.1,0C477.4,742.7,475.1,741.3,472.9,740z"/>
	<path class="st2663" d="M469.8,736.9c-0.1,0.5-0.1,0.9-0.2,1.4c-1.3-0.5-2.5-1.1-3.8-1.6c0.6-0.3,1.3-0.5,1.9-0.8
		C468.9,735.3,469.7,735.5,469.8,736.9z"/>
	<path class="st2664" d="M348.7,686.1c2,0.6,3.1,2.3,4.3,3.9c0,0.4,0,0.7-0.1,1.1C351.1,689.8,349.4,688.3,348.7,686.1z"/>
	<path class="st2665" d="M813.1,487.4c0.4,1.8,0.8,3.6,2.2,4.9c0.4,1.7,0.8,3.5,2,4.9c1.3,6,2.6,12,4,18c-0.1,10.9,0.9,21.9-1,32.7
		c-0.2,1.1,0,2.3-0.9,3.3c-1.9-1.9-1.7-4.3-1.2-6.5c0.9-4.1,0.7-8.1,0.7-12.2c0.1-6,0-12-1.5-17.9c-1.4-7.7-2.8-15.3-6-22.5
		c-0.7-1.5-1.2-3.1-1.2-4.8c0.1-0.9,0.3-1.6,1-2.2C812,486,812.6,486.7,813.1,487.4z"/>
	<path class="st2666" d="M820.3,532.2c0.9,6.4-1.4,12.7-0.9,19.1c0.4,5.8-2.8,11.1-2.1,16.9c-0.1,0.3-0.1,0.5-0.1,0.8
		c-1.4,5.4-1.4,11.1-3.9,16.2c-0.3,0-0.6,0-1,0c-0.2-0.2-0.4-0.4-0.6-0.6c-1.2-2.4-0.7-4.8-0.1-7.3c2-8.1,3.1-16.3,4.3-24.5
		c0.9-5.5,0.9-11.1,1.9-16.5C818.2,534.7,817.8,532.6,820.3,532.2z"/>
	<path class="st2667" d="M776.4,277.5c0.7,5.7,1.4,11.3,2.1,17c-0.7,3.7,0.8,7.5-0.9,11.2c-1.6-1.2-1.1-3.7-2.9-4.7
		c-0.2-0.4-0.4-0.9-0.4-1.4c-0.5-3.3,0.3-6.7-0.8-10c-0.6-2.5-0.1-5.2-0.9-7.8c-0.5-2.3-0.6-4.6-0.7-6.9c0.3-1.3,0.8-2.5,2.4-2.6
		C775.7,273.8,776.7,275.4,776.4,277.5z"/>
	<path class="st2668" d="M775.6,301.5c3.9-0.2,0.9,3,2,4.2c-0.2,2,0.4,4.1-1.1,5.9c-0.4,0.4-0.7,0.4-1.1,0c-0.3-0.4-0.6-0.7-1-1.1
		c-1.8-2.4,0.8-4.8,0-7.2C774.6,302.6,774.9,301.9,775.6,301.5z"/>
	<path class="st2669" d="M775.5,311.5c0.4,0,0.7,0,1.1,0c-0.1,2.3-0.1,4.5-0.2,6.8C774.6,316.3,775.8,313.8,775.5,311.5z"/>
	<path class="st2670" d="M540.8,908.7c-2-2-4.1-3.9-6.1-5.9c-2.1-2.2-2.2-4.8-1.4-7.5c2.2,0.5,2.2,0.5,3,2.4
		c3.4,8.6,8,10.4,16.4,6.8c2.2-1,4.2-2.9,7-1.9c0.4,0.4,0.5,0.8,0.2,1.3c-0.1,0.3-0.1,0.6-0.1,0.9c-3.5,0.2-6.2,2.1-9,4
		C547.4,909.1,544.1,910.9,540.8,908.7z"/>
	<path class="st2640" d="M386,379.5c-0.3,0.4-0.7,0.8-1,1.2c0-2.1-0.1-4.2-0.1-6.3c1-3.8,1-7.9,4-11c2.9-0.4,2.2-4.5,5-5
		c0.9,0.1,1.8,0.2,2.3,1.2c-0.2,3.1-3.6,3.9-4.6,6.4c-1.7,2.5-2.1,5.4-3,8.1c-0.2,0.4-0.4,0.8-0.7,1.2
		C387,376.6,387.1,378.4,386,379.5z"/>
	<path class="st2671" d="M772.6,313.6c-0.1,5.2-0.2,10.4-0.2,15.6c-0.6,0.5-1.1,0.4-1.5-0.3c-1.6-5.7-0.4-11.5-0.8-17.3
		c0.5-11-0.3-22-2.7-32.8c-0.3-1.4-0.3-2.9-0.2-4.4c0.4-2.4,1.2-4.1,4.1-2.5c0.5,0.4,0.7,0.9,0.9,1.5c0.2,3.4,0.2,6.7,0.7,10.1
		c-0.3,3.7,1.1,7.2,1,10.9c0.1,2,0.1,4,0,6c0,1.2,0,2.3,0,3.5C773.8,307.2,773.5,310.5,772.6,313.6z"/>
	<path class="st2672" d="M772.6,313.6c0-3.3,0-6.7,0-10c0.9-1,1.8-0.9,2.8-0.2c1.9,2.6-1.2,4.6-0.9,7.1
		C773.8,311.5,773.2,312.6,772.6,313.6z"/>
	<path class="st2673" d="M771.7,329.4c0.2,0,0.4-0.1,0.6-0.2c0.2,1.1-0.1,2.4,1,3.2c-0.3,1.8-0.6,3.6-1.9,5c0-2-0.1-4-0.1-5.9
		C770.8,330.7,770.9,330,771.7,329.4z"/>
	<path class="st2674" d="M662.5,69.8c1.3,1.5,3.2,1.8,4.9,2.3c6.1,3.5,13.2,4.8,19.2,8.5c-0.7,1-1.7,1.1-2.8,1.1c-1-0.1-2-0.4-3-0.7
		c-6-1.9-11.4-5.4-17.5-7c-1.2-0.2-2.4-0.5-3.4-1.1c-1.9-1.2-4.1-1.5-6.1-2.2c-3.9-1.5-7.9-2.5-12-3.2c-8.9-2-18.1-2-27.1-2.3
		c-7.1-0.1-14.1,1.1-21.2,1.4c-0.5,0.2-1.1,0.3-1.6,0.4c-3,0.2-5.9,2-8.9,0.2c-0.5-0.4-0.6-0.9-0.3-1.5c2.1-0.6,4.1-1.3,6.2-1.9
		c13.9-1.2,27.8-2.4,41.8-1.6c0.3,0.1,0.6,0.1,0.9,0c6.1,2,12.6,2.4,18.8,3.6C654.1,68.1,658.3,68.9,662.5,69.8z"/>
	<path class="st2675" d="M364,610.2c1.6-2.4,3.2-4.7,4.8-7.1c2.1-3,5.3-5.3,6.1-9.1c0.1-0.2,0.2-0.5,0.4-0.7c1.8-0.8,3.4-2,4.8-3.3
		c0.5-0.1,0.9-0.1,1.3,0.1c3.8,2.8,0.3,4.8-0.8,7c-2.7,5.2-6,10-7.9,15.7c-1.2,3.5-3.9,3.5-8.8,0.3C363.9,612.2,364,611.2,364,610.2
		z"/>
	<path class="st2634" d="M394.8,573.1c0.1,0.4,0.1,0.8,0.2,1.1c0.3,2.2-1.6,2.9-2.6,4.2c-1.1,0.9-2.4,1.7-3.4,2.8
		c-0.3,0.3-0.6,0.6-0.9,0.9c-1.2,1.1-2.4,2.3-3.6,3.5c-0.4,0.5-0.9,0.9-1.4,1.4c-1,0.7-1.9,2.1-3.4,1.1
		C383.9,582.2,388.7,577,394.8,573.1z"/>
	<path class="st2676" d="M379.7,588c1-0.7,2.1-1.3,3.1-2c1.4,2.4,0.6,3.6-2,4.1c-1.3,2.1-2.6,4.3-5.7,3.2
		C376,591,378.1,589.7,379.7,588z"/>
	<path class="st2677" d="M794.5,772c-0.3,0-0.7,0-1-0.1c-4,0.8-6.9-2-6.5-6.4c0.2-2.1,0.7-4.3,1.4-6.4c-0.7,2.2-2.1,3.3-4.3,2.3
		c-1.7-0.8-1.7-2.4-1.3-3.9c0.4-1.7,1.4-3.1,2.5-4.3c2.3-2.3,3.4-5.3,4.6-8.2c0.6-0.8,1.3-1.4,2.4-1.2c2.5,2.3,4.5,0.6,6.7-0.6
		c1.9-1.1,4.3-2.3,5.6,0.2c1.2,2.2-0.7,4-2.3,5.5c-1,0.9-2.4,1.1-3.5,1.8c-1.5,0.9-1.7,2.3-1.1,3.8c0.6,1.5,1.7,1.7,3.2,1.2
		c1.8-0.6,3.7-1.4,5.7-0.9c0.1,4.2-3,6.8-4.8,10.1c-1.9,2.7-5.2,3.7-7.3,6.2C794.6,771.5,794.5,771.8,794.5,772z"/>
	<path class="st2678" d="M808.4,682.1c4.1,21.5,1.1,43.3,2,64.9c-1.5,1.7-0.2,4.4-1.9,6.1c-0.2-0.2-0.4-0.4-0.6-0.6
		c-1.1-2.7-1.5-5.5-1.1-8.3c2.1-6.8,1.1-13.8,1.2-20.8c0.1-6.5,0-12.9,0-19.4c0.1-5.9,1-11.9-1.7-17.5c-0.5-1.1-0.2-2.3,0-3.4
		c0.1-0.5,0.3-1,0.6-1.5c0.3-0.5,0.8-0.7,1.3-0.7C808.6,681.1,808.7,681.6,808.4,682.1z"/>
	<path class="st2678" d="M801.4,765c1.7-3.3,3.3-6.7,5-10c-0.3-1.6,0.4-2.2,1.9-2c0,0,0.1,0.1,0.1,0.1c-1.2,4.9-3,9.6-7,12.9
		c-0.3-0.2-0.4-0.4-0.3-0.7C801.2,765.1,801.3,765,801.4,765z"/>
	<path class="st2679" d="M801.4,765c0,0.3,0,0.7,0,1c-1.6,2.7-4,4.3-6.9,5.3C795.2,767.4,799.5,767.5,801.4,765z"/>
	<path class="st2680" d="M405.9,442.1c2.7,1,4,3.5,5.9,5.4c0.1,0.8,0,1.3-1,0.9c-1.8-0.8-2.5-3.3-4.9-3
		C405.9,444.3,405.9,443.2,405.9,442.1z"/>
	<path class="st2681" d="M811.4,485.2c0,0.7-0.1,1.4-0.1,2.1c-3.8-0.7-3.9-4.5-5.8-6.8c-2.7-3.9-3.8-8.7-7.1-12.3
		c-0.7-0.7-0.9-1.8-0.4-2.8c2-1,2.3,1.1,3.3,1.9C805.8,472.7,809,478.8,811.4,485.2z"/>
	<path class="st2682" d="M801.5,467.3c-1-0.7-2-1.4-3-2c-3.6-1.5-5.9-4.5-8-7.6c-1.2-2.5-3.6-4.1-5.2-6.3c-0.9-1.2-2.6-2.1-1.8-4.1
		c5.7,2.6,8.3,8.4,12.9,12.2c1.7,2,2.7,4.5,5,6C801.4,466,801.5,466.7,801.5,467.3z"/>
	<path class="st2683" d="M488.8,112.7c0-0.3,0-0.6,0.1-0.9c4.2-1.4,6.2-6.3,10.9-7.1c3.1-0.1,6.1-0.3,8.9-2c2.6-1.6,5.5-0.5,8,1
		c5.2,3.3,9.5,7.2,8.5,14.3c-0.2,1.4,0,3,0.3,4.4c0,0.5,0,1-0.1,1.5c-0.9,5.7-5.2,7.3-10,8.2c-3.6-0.4-4.3-2.9-4.7-6
		c-0.4-3.2-7.8-9.9-10.9-10.3c-2.1-0.3-2.7,1.6-4.2,2.3c-0.5,0.1-0.9,0.1-1.4,0C493.2,115.4,489,116.1,488.8,112.7z"/>
	<path class="st2684" d="M524.7,122.7c-1.7-1.6-0.9-3.7-1-5.6c-0.1-6.6-4.8-12.2-11.3-13.7c-1.9-0.4-3.5-0.3-4.5,0.9
		c-2.7,3.3-5.4,2.2-8.2,0.4c2.8-3.1,6.3-5.2,10-7c1.1-0.6,1.9-1.7,3.2-1.9c3.8,0.6,7.1,0.6,10.8-1.6c5.1-2.9,7.1-1.9,10.4,3.1
		c0.9,1.4,1.9,2.7,2.8,4.1c2.1,3,2,5.5-0.8,8.3C532.1,113.8,529.8,119.5,524.7,122.7z"/>
	<path class="st2685" d="M512.7,96.7c-1,0.3-2,0.7-3,1c0.3-0.7,0.7-1.4,1-2.1c0.7-0.6,1.4-1.2,2.1-1.8c2.6-0.8,5.3-1.7,6.9-4.2
		c2.3,0.2,3.7-1.8,5.8-2c0.7,0,1.4,0,2.1,0.1c-2.1,5-7.2,5.7-11.2,7.9C515.4,96.2,513.9,95.9,512.7,96.7z"/>
	<path class="st2686" d="M467.8,131.7c0.4-0.6,0.7-1.3,1.1-1.9c2.2-0.2,3-2.4,4.9-3.1c1.5,1.9,2.9,3.8,5.4,1.3
		c0.9-0.9,2.2-0.9,3.3-0.6c6.7,1.8,14.3,1.5,18.9,8.1c0.7,1.1,2,1.7,2.8,2.7c0.6,0.8,1.1,1.7,1.3,2.7c0.2,1.2,0.5,2.9-0.4,3.2
		c-4,1.5-5.4,5.9-9,7.7c-1.6-0.9-1.2-2.5-1.3-4c-0.2-4.4-2.7-7.3-7-7.8c-1.4-0.2-2.7-0.3-3.5-1.4c-2.5-3.2-4.6-2.4-6.5,0.6
		C473.1,140.5,467.2,136,467.8,131.7z"/>
	<path class="st2687" d="M503.8,138.7c-1-0.6-2.5-0.9-2.9-1.7c-3.7-6.7-10.9-6.2-16.9-8.1c-1.2-0.4-3.1-1.5-4.5,0.4
		c-0.9,1.3-1.9,2.8-4,1.7c-2-1-1.6-2.7-1.6-4.3c1-0.7,2-1.3,3-2c1.1-1.8,2.9-3.1,4-5l0,0c3.5-2.2,2.8,1.4,3.6,3.1
		c2.7-2.8,5.6-4.9,9.4-5.1c0.3,0,0.7,0,1,0c4,0.9,6.5,3.1,6.4,7.5c-0.1,1.8,1.3,2.9,2.4,4.1C506.8,133.1,506.8,136.3,503.8,138.7z"
		/>
	<path class="st2688" d="M493.8,117.7c-2.8,2.5-7,3.1-9.1,6.7c-0.2,0.3-1.6,0.1-2.2-0.3c-0.8-0.5-0.7-1.5-0.2-2.2
		c1.7-2.2,0.3-2.3-1.5-2.3c2.1-3,5.4-4.6,8-7c1,0.6,1.4,2.1,3,1.9C494.9,114.1,494.4,115.9,493.8,117.7z"/>
	<path class="st2688" d="M480.8,119.7c-0.3,2.5-2.1,3.7-4,5C477.1,122.2,479,121,480.8,119.7z"/>
	<path class="st2689" d="M582.7,65.8c0,0.3,0,0.7,0,1c-0.3,0.4-0.7,0.7-1.2,0.9c-2.4,0.5-4.8,1.1-7,2.2c-7.3,2.5-14.7,4.6-22,7.2
		c-1.3,0.4-2.5,0.8-3.7-0.2c-0.7,0-1.4-0.1-2.1-0.1c3.3-2.7,7.9-2.3,11.1-4.9c5.4-1.2,10.8-2.5,15.9-4.9c1.7-0.4,3.3-0.8,5-1.2
		C580,65.8,581.3,65.8,582.7,65.8z"/>
	<path class="st2659" d="M703.5,823.9c-2.7,1.7-5.9,2.5-8.1,5c-4,2-7.9,4-11.9,6.1c-1,0.1-1.9,0.1-2.9,0.2c-2.3-3.8-0.8-7.3,3.9-7.2
		c5.7,0.2,9.6-3.1,13.9-5.7c3.9-2.3,8.2-3.7,12.1-5.9c1.2-0.7,2.6-0.6,3.8,0.2c1,1.9-0.7,2.1-1.8,2.6
		C709.5,820.7,706.4,822,703.5,823.9z"/>
	<path class="st2690" d="M683.6,80.8c1,0,2.1-0.1,3.1-0.1c0.3,0,0.6,0,0.9,0.1c2,1.3,4,2.7,6,4c-1.7,1.7-3.4,0.9-5.2,0.2
		C686.7,83.5,683.3,84.2,683.6,80.8z"/>
	<path class="st2691" d="M768.4,786.9c-5.7,4.2-12.4,6.6-17.9,11.1c-5.3,2.8-10.6,5.6-15.8,8.5c-1.1,0.6-1.9,1.2-3.2,0.6
		c-1.6-2.1-1.6-4.5-0.9-6.9c1.6-3.2,3.8-1.6,5.9-0.8c4.5,1.8,6,1.2,8.8-3.3c-1.7,1.7-2.8,4.5-6.2,2.9c-3.2-1.5-5-3.6-4.9-7.3
		c0.1-2.9-0.5-4.9-3.8-6c-4.4-1.5-5.4-5.3-3.5-10.1c0.7-0.7,1.5-1.3,2.1-2.2c3.4-5.1,3.4-5.1,8.2-6.5c-3-3.4-2.9-3.2,1.3-6.3
		c1.2-0.8,4.8-0.5,2.2-4.5c-1.7-2.7,0.3-7.1,1.9-10.4c0.4-0.8,1.1-1.2,2.1-1c1.1,0.3,1.5,1.3,1.6,2.3c0.4,2.1,0.2,4.4,2.4,5.8
		c0.3,0.3,0.6,0.7,0.9,1.1c-0.7,4.2,1.4,7.2,4.1,9.9c0.8,2.1-2.2,3.6-0.8,5.7c1.1,0.1,1.7-0.9,2.5-1.3c1-0.6,2.1-1,3.3-0.7
		c2.9,2.2,1.2,5.2,1.2,7.8c0,3,0.7,4.6,4,3c1.3-0.6,2.9-1.6,3.7,0.5c0.6,1.6-0.5,2.8-1.7,3.8c-0.9,0.7-2.2,0.6-2.8,1.7
		c2,0.6,4-0.7,5.9,0.2C770.2,785.8,769.2,786.3,768.4,786.9z"/>
	<path class="st2692" d="M731.5,800.9c0,2,0,4,0.1,6.1c0,0,0,0,0,0c-1,1.5-2.8,1.8-4.1,3c-0.3,0.3-0.6,0.7-0.9,1
		c-2.3,1.4-4,3.9-7.1,3.7c-1.7-0.5-4.1-0.4-4.2-2.7c-0.1-2,1.8-1.9,3.3-1.9c-4.4-0.1-8.2,2.2-12.6,2.6c-6.6,0.6-6.6,0.9-6.7-4.8
		c0-2.4,1.9-2.9,3.6-3.4c2.1-0.7,3.4-1.9,3.9-3.9c0.4-0.7,1-1.2,1.7-1.5c1.1-0.5,2.3-1,3.3-0.6c4.5,1.9,9,0.7,13.5-0.1
		C728.1,797.8,730.3,798.2,731.5,800.9z"/>
	<path class="st2693" d="M719.5,813.9c2.8,0.1,4.3-2.9,7.1-3c0.3,0.3,0.5,0.6,0.8,0.8c-1.2,0.3-2.4,0.8-2.9,2.1c-3.7,2-7.3,4-11,6
		c-3.3,1.3-6.6,2.7-9.9,4c2.7-3.8,7.9-3.8,10.9-7C715.4,814.7,717.3,814.1,719.5,813.9z"/>
	<path class="st2694" d="M727.5,809.9c0.7-1.8,2.5-2.2,4.1-3C731.2,809.3,729.3,809.6,727.5,809.9z"/>
	<path class="st2695" d="M776.4,277.5c-0.8-1.6-2.3-3-1.9-5c-1.2-1.2-2.6-2.3-0.7-4.1c0.6,0,1.1,0.1,1.7,0.1c-0.2,3.2,2.8,5.6,2,9
		C777.1,277.5,776.7,277.5,776.4,277.5z"/>
	<path class="st2695" d="M777.5,277.5c1.8,0.8,2.1,2.3,2,4c-0.3,0.3-0.7,0.7-1,1C776.9,281.1,777.6,279.2,777.5,277.5z"/>
	<path class="st2696" d="M476.8,492.3c1-1,2-2.1,2.9-3.1c3.5-2.5,6.5,0.3,9.7,0.9c1.8,0.7,3.3,1.7,3.6,3.7c-0.1,0.8-0.6,1.4-1.2,1.8
		c-2.1,0.8-3.5-0.8-5.2-1.6c-1.3-0.7-2.6-1.5-4.1-2c-2.6-0.8-3.4,3.1-6,2.3c-0.3-0.4-0.3-0.8,0.1-1.2
		C476.8,492.9,476.8,492.6,476.8,492.3z"/>
	<path class="st2502" d="M476.7,493.2c0,0.4,0,0.7,0.1,1.1c0.2,0.4,0.3,0.9,0.1,1.4c-1.3,2.1-1.8,4.9-4.6,5.9
		c-0.2-0.1-0.4-0.2-0.6-0.3C472.7,498.1,473.8,495.1,476.7,493.2z"/>
	<path class="st2502" d="M469.7,505.2c0.3-1.5,0.8-2.9,2-3.9c0,0,0.2,0.1,0.2,0.1c1.2,1.5,0.6,2.8-0.6,4c-0.5,0.3-0.9,0.3-1.4,0.1
		L469.7,505.2z"/>
	<path class="st2697" d="M548.8,76.8c8.6-2.7,17.3-5.4,25.9-8.1c2.3-0.8,4.7-1.1,7-1.9c0.3,0,0.6,0,1-0.1c3.1,0.5,6-0.8,9-1
		c-0.7,3.8-4.5,3.2-6.7,5c3.1-0.1,6.2-0.6,9.3,0c2.1,0.5,4.1,1.3,4.8,3.7c-1.8,3.8-4.7,6.8-7.1,10.1c-1.7,2.3-4.7,3.5-7.6,2.5
		c-2.1-0.7-3,0-4.1,1.5c-1.8,2.6-4.1,4.4-7.4,4.3c-0.7,0-1.7-0.1-1.9,0.3c-2.1,4.8-7.4,5.8-10.5,9.4c-0.6,1-1.3,1.9-2.2,2.7
		c-2.2,1.3-4.9,2.1-5.8,5c-4.5,2.1-9,4.1-13.7,5.7c-1.1-0.5-1.8-1.5-2.4-2.5c-0.8-1.2-1.8-2.5-0.6-4.1c1.1-1.4,2.5-1.5,4-1
		c1.1,0.3,2.2,0.9,3.7,0.5c-1.3-3.3,1-6.2,1.3-9.4c0.2-2,1.8-3.2,3.5-2.5c3.9,1.7,3.9-1.4,4.8-3.5c1.2-2.9-0.3-5.8,0.3-8.8
		c0.4-2.1-1.9-2.1-4-1.5c-5.3,1.6-10.4,3.9-15.9,5c-1.6,0.3-3.9,1.6-4.7-1.4C535.4,83.5,542.1,80.2,548.8,76.8z"/>
	<path class="st2698" d="M528.8,86.9c1.9,1.9,3.8,0.4,5.6-0.1c5.1-1.6,10.1-3.2,15.1-5.2c2.1-0.8,3.6,0.1,5.1,0.9
		c1.6,0.9,1.3,2.5,0.2,3.8c-0.7,0.8-1.4,2-0.6,2.7c3.8,3.6-2.2,6.3-1,9.9c0.4,1-2.2,0.7-3.6-0.2c-2.3-1.6-4.2-1-3.7,1.9
		c0.5,2.9-4.4,5.9,0.2,8.6c0.6,0.3,0,1.3-0.7,1.4c-1,0.2-2,0.1-3,0.1c-2.1,0.2-4-3.2-5.8-0.9c-1.9,2.5,2.7,3.4,2.2,5.9
		c0.6,2.3-2.1,3.3-2.2,5.3c-1,1.8-2.6,2.7-4.4,3.3c-2.4-0.4-5.2,3.5-7.3-0.6c0-0.3,0-0.7,0-1c3.1-5.4,7.3-9.9,11.3-14.5
		c1.7-1.9,1.8-3,0.7-5.1c-1.6-2.9-3.8-5.2-5.4-8.1c-1.7-3.1-5.7-0.8-6.9,0.3c-3.8,3.4-7.5,2.9-11.6,1.4c4.7-3.5,10.6-4.9,14.9-9
		C528.1,87.4,528.4,87.1,528.8,86.9z"/>
	<path class="st2699" d="M711.8,92.4c0.6-0.1,1.1-0.1,1.7-0.2c1.6,2.7,4.9,2.8,7,4.7c2.8,1.7,3.2,3.2-0.2,4.6c-3.3,0-5.8-2-8.6-3.3
		c-3.1-1.5-6.3-2.8-9.7-3.5c-0.7-0.2-1.4-0.5-2-0.9c-0.8-0.5-1.7-1-2.5-1.5c-2.7-2.4-6.6-1.5-9.3-3.7c-0.3-0.8-0.2-1.5,0.4-2.2
		c2.6-1.3,5.3-0.2,7.9-0.5c3.9,0.8,7.1,3.1,10.4,5.1C708.5,91.9,709.8,93.4,711.8,92.4z"/>
	<path class="st2700" d="M696.6,85.8c-2.5,1.8-5.4,0.5-8.1,0.9c-3.9,1.5-7.3-0.4-10.8-1.3c-0.7-0.4-1.2-1.1-1.4-1.9
		c0.5-2.3,2-2.2,3.8-1.6c2.8,0.9,5.7,1.2,8.5,1.9c1.6,0.9,3.3,1.1,5,1C694.6,85.1,695.6,85.5,696.6,85.8z"/>
	<path class="st2679" d="M769.4,784.9c-3.3,0.8-6.6,2.2-10-1.1c3-1.5,7.2-0.6,8-4.8c-0.6-0.2-0.9-0.4-1.2-0.4
		c-2.1,0.4-3.6,3.5-6.2,1.7c-2.7-1.8-0.7-4.6-1-6.9c-0.2-1.8,1.2-3.9-0.6-5.6c-1.3-0.6-1.8-1.5-1.1-2.9c3-1.6,6-2.9,9.5-1.7
		c0.4,0.2,0.7,0.6,0.8,1.1c-2.5,1,0.4,1.5,0.2,2.3c1.5,1.5,1.5,1.5,4.6,1.7c0.5-1-0.8-1.8-0.5-2.8c0.9-1.4,2.3-2.4,2.7-4.2
		c1-1.9,3-2.8,4.4-4.2c3.3-2.2,5.6-5.4,7.7-8.7c0.9-1.3,1.3-3,3-3.7c0.2,0.1,0.5,0.1,0.7,0.2c0.2,3.4-1.4,5.9-3.5,8.3
		c-1.1,1.2-2.1,2.6-3,3.9c-0.6,1-0.8,2.3,0.2,3.2c0.4,0.4,1.3,0.5,1.8,0.4c1.6-0.3,1.4-1.6,1.3-2.8c-0.1-1-1.5-2.5,0.5-2.7
		c2-0.2,1.7,1.7,1.5,2.9c-0.3,2.4-1.1,4.8-1.1,7.2c0,3.3,0.8,6.5,5.2,6.5c-0.3,0.3-0.7,0.7-1,1c-2.1,1.8-5,2.1-7,4
		c-0.4,0.3-0.7,0.7-1.1,1c-3.7,1.4-7.3,2.9-9.9,6c-0.3,0-0.6,0.1-1,0.1C772.1,784.4,771,785.3,769.4,784.9z"/>
	<path class="st2701" d="M774.4,783.9c1.7-4.6,5.6-5.6,9.9-6C781.4,780.4,777.9,782.2,774.4,783.9z"/>
	<path class="st2701" d="M785.4,776.9c1.4-3,4.3-3.3,7-4C791,775.8,788.2,776.3,785.4,776.9z"/>
	<path class="st2701" d="M769.4,784.9c1.3-0.7,2.6-1.2,4-1c-1,0.9-2.1,1.9-3.1,2.8c-0.6,0-1.3,0.1-1.9,0.1
		C768.8,786.2,769.1,785.6,769.4,784.9z"/>
	<path class="st2702" d="M719.5,813.9c-1.4,1.6-3.3,2.1-5.1,3c-5.6,0.4-9.9,3.9-14.7,6.1c-4.8,2.2-8.4,6.7-14.6,6
		c-3.7-0.4-5.8,2-4.4,6.1c-1,0.6-2,1.2-3,1.8c-0.8,0.6-1.9,0.5-2.8,0.8c-2.3-1-6.4-8.3-5.6-10.2c0.8-2,3-1.5,4.5-2.2
		c-0.6-1.1-1.7-1.3-2.2-2.2c0.9-4,4.6-4.4,7.5-5.8c5.5-2,10.3-5.1,15.1-8.4c1.7-0.7,3.4-1.9,5.4-1c2.7,0.5-2,4.1,1.5,3.9
		c4-0.3,8-0.7,11.8-1.9c3.4-1.1,6.3-1.7,9.7,0.8c-2.4,2.1-4.7-1.2-6.4,1C716.5,813.6,718.9,812.3,719.5,813.9z"/>
	<path class="st2661" d="M674.6,836.9c1,0,2,0,3.1,0c-3.3,2.7-6.9,4.8-11,6c0,0-0.1-0.1-0.1-0.1
		C667.4,838.4,671.5,838.4,674.6,836.9z"/>
	<path class="st2703" d="M437.8,169.6c0-0.8,0.2-1.6,0.9-2c1.6-2.1,3.2-3.9,5.6-0.8c1.1,2.5,1.1,2.5,3.5,1.4
		c2.5,1.3,4.3-0.8,6.4-1.3c2.1,0.2,3.8,1.6,5.9,2.1c6.7,2.2,10.2,7.3,12.6,13.5c0.3,1.1,0.3,2.1,0,3.2c-0.5,1.4-1.4,2.3-3,2.2
		c-2.2-0.8-4.6-1.6-4.9-4.3c-0.6-4.7-4-5.5-7.6-5.1c-4.8,0.5-9.5,0.1-14.3-0.3C435.7,177.5,434.6,175.9,437.8,169.6z"/>
	<path class="st2704" d="M437.8,169.6c1.2,0.9,0.2,1.5-0.2,2.4c-1.5,3.1-0.1,4.2,3.3,4.9c7,1.3,14.1-0.4,21.1,0.8
		c1.5,0.2,3.7,0.8,3.5,2.2c-0.7,4,2.5,5.4,4.3,7.7c0.5,2.3-0.4,3.7-2.7,4.3c-0.6-1.2-1.6-1.3-2.8-1.1c-1.5-0.4-3-0.9-3.4-2.6
		c-0.9-3.8-3.9-4.1-6.9-4.3c-5.1-0.4-10.3,0.8-15.4-0.7c-2.7-0.8-3.6,2.7-6,2.9c-0.5-0.1-0.9-0.3-1.3-0.8c-1-1.9-0.1-3.5,0.9-5.1
		c1.5-2.4,2.7-4.9,3.6-7.6C435.8,171.2,436.5,170.2,437.8,169.6z"/>
	<path class="st2705" d="M428,185.7c0.3-0.4,0.6-0.7,1-1.1c1.5-1.1,2.1,0,2.9,1l0,0c1.3,1.2,1.1,3.1,2,4.4c8.5-3.2,17.1-1.9,25.7-1
		c1.3,0.1,2.4,1.1,3.3,2.1c0,3.8-3.6,5.5-4.8,8.6c-5.1,0.1-9.4-2.7-14.2-3.8c-2.2-0.5-4.1-1.4-4.8,2c-0.5,2.3-2.4,1.9-4,1
		c-2.7-1.6-4.3-4.1-5.6-6.8c-0.4-1.9-0.4-4-1.9-5.5C427.4,186.3,427.5,185.9,428,185.7z"/>
	<path class="st2706" d="M443.8,167.7c-1.7-2.8-3.4-1-5.1,0c0.4-1.3,0.8-2.6,1.1-4c4-5.3,7-11.4,11.9-16.1c0.4,0,0.7,0.1,1.1,0.1
		c0.5,2.3-0.4,4.4-1.7,5.9c-2.2,2.5-2.4,5.2-2.5,8.2c-0.3,1.5-3.6,1.1-2.2,3.9C446.8,166.5,445.6,168.1,443.8,167.7z"/>
	<path class="st2707" d="M428,185.7c-0.1,0.3-0.1,0.6-0.1,0.9c1,1.5,0.2,2.9-0.3,4.3c-1.8,4.8-3.8,9.6-4.9,14.6
		c-0.8,2.6-1.3,5.3-2,7.9c-0.7,2.4-1,4.9-1.5,7.3c-0.2,1.3-0.3,2.6-1.1,3.7c-0.5,0.4-1,0.4-1.5,0.1c-0.3-0.5-0.4-1-0.3-1.6
		c0.2-1.9-0.3-3.8,0.4-5.7c0.4-2.5,0.8-5.1,1.3-7.6c2.3-7,4.6-13.9,7-20.9c0.7-1.1,1.5-2.1,2.2-3.2
		C427.4,185.7,427.7,185.7,428,185.7z"/>
	<path class="st2708" d="M431.8,185.6c-1-0.3-1.9-0.7-2.9-1c2.1-4.1,3.6-8.5,6.9-12c0.4,2.7-0.4,5.1-2.1,7.2
		C432.3,181.5,431.5,183.4,431.8,185.6z"/>
	<path class="st2709" d="M743.2,209.8c0.1,0.9,0.2,1.8,0.3,2.7c1.6,3.2,4,6.3,2,10.3c-0.5,1.3-1.1,2.4-2.2,3.3
		c-2.3,1.1-4.7,1-7.1,0.4c-0.5-0.2-0.9-0.5-1.3-1c-1-2.1-0.6-4,0.4-5.9c2-3.9-0.7-4.9-3.5-5.6c-2.8-0.7-6-0.7-7-4.2
		c0.8-3.5,3.4-4.1,6.3-3.8c2.1,0.2,3.5,0,3.7-2.5c0.2-0.7,0.7-1.2,1.4-1.4c0.9-0.1,1.8,0.2,2.6,0.7c1.5,1,2.1,2.7,3.1,4.1
		C742.6,207.8,743.4,208.6,743.2,209.8z"/>
	<path class="st2710" d="M743.2,209.8c-0.6-0.7-1.2-1.4-1.8-2.1c-1.1-3,0-4.1,3-3.4c3.1,0.7,5.6,2.5,7.4,5.2c0,0,0.2,0,0.2,0
		C749.1,212,746.1,209.3,743.2,209.8z"/>
	<path class="st2711" d="M744.5,222.6c1.9-3.6-0.5-6.8-0.9-10.1c0.6,0.4,1.3,0.8,1.9,1.2c0,1.4,0.8,2.3,2,2.9c1.5,1.5,2,3.5,2.7,5.3
		c0.2,0.7,0.2,1.4,0,2.1c-0.1,0.4-0.4,0.8-0.7,1.2c-0.9,0.8-1.9,1.2-3,0.5C745.8,224.7,745.4,223.5,744.5,222.6z"/>
	<path class="st2712" d="M604.5,877.7c0.7,0,1.3,0,2,0.1c-1.7,1.2-3.3,2.5-5,3.7c-3.2,1.9-7.1,2.7-9.9,5.3c-3.4,0.5-5.7,3.2-8.9,4.1
		c-0.3-0.4-0.5-0.8-0.5-1.3c0.6-2.1,2.6-2.5,4.2-3.5c2.4-1.2,4.8-2.5,7.3-3.3C597.7,882,600.8,879.2,604.5,877.7z"/>
	<path class="st2713" d="M582.6,889.8c0,0.4,0,0.8,0.1,1.2c-2.4,1.6-4.8,3.2-7.2,4.8c-2.9,1.4-5.8,2.8-8.7,4.2
		c-0.7,0.5-1.3,0.5-2.1,0.1c1.2-4.5,5.7-4.7,8.7-7C576.6,892.3,578.7,888.2,582.6,889.8z"/>
	<path class="st2714" d="M720.2,100.9c3-1.1,1.2-2.6,0.3-4c2.8,0.3,4.8,2.3,7,3.8c0.3,0.7,0.6,1.4,0.9,2.1c-0.5,1.3-0.8,2.7-2.3,3.3
		C723.5,105.2,721,104,720.2,100.9z"/>
	<path class="st2715" d="M412.9,330.6c1,0,1.9-0.1,2.9-0.1c1.3,1.3,1.3,2.6,0.1,4c-1.4-1.8-3-2.4-5-0.9c0-0.7,0-1.3,0-2
		C411.5,331.2,412.2,330.9,412.9,330.6z"/>
	<path class="st2716" d="M410.8,448.4c0.3-0.3,0.6-0.6,1-0.9c3,2.3,6,4.6,9,6.9c0.4,0.3,1.1,0.4,1.1,1.1c-0.3,0.3-0.7,0.6-1,0.9
		C417.4,453.9,413.1,452.3,410.8,448.4z"/>
	<path class="st2717" d="M783.5,447.2c1.8,4,6.4,5.8,7.9,10.1c-2.8,0.8-4.2-1.3-5.7-3c-2.2-2.7-4.4-5.3-7.5-7.1
		c-2.2-1.1-3.7-3.3-6-4.1c-4.9-3.4-9.6-7.2-15.3-9.5c-0.9-0.4-1.7-1-1.9-2.1c0-0.5,0.2-1,0.5-1.4c1-0.2,2-0.4,2.9,0.2
		c1.7,0.7,3.3,1.4,5,2.1c6.6,4.7,13.3,9.4,19.9,14.1C783.3,446.7,783.4,447,783.5,447.2z"/>
	<path class="st2718" d="M460,527.2c-0.3-0.4-0.6-0.9-0.9-1.3c0.1-0.3,0.3-0.6,0.7-0.7c2,0,2.7-1.3,3.2-3.1
		c1.3-4.6,3.6-8.8,5.9-12.9c2.5,0.5,4.5,3.1,3.2,4.7c-2.9,3.6,0.2,5,2,6.8c1.7,1.8,4,2.9,5.9,4.6c1.2,1.1,2.7,2,2.7,3.9
		c-0.3,3-2.7,4.4-4.5,6.3c-4.7,1.9-8.5-0.3-12.1-3c-1.7-1.3-3-3-4.6-4.3C460.9,528,460.4,527.7,460,527.2z"/>
	<path class="st2719" d="M481.8,529.2c-0.8-2.5-3.2-3.7-5-4.8c-4.2-2.6-7.3-5.7-8.1-10.9c0.9,0.4,1.8,0.7,4,1.5
		c-2.4-2.2-0.7-5.1-3.7-5.8c0.3-1.3,0.7-2.6,1-3.9c0.3,0,0.6-0.1,1-0.1c1.7-1.7,3.4-3.6,4.2,0.7c1.1,4.7,4.7,8,7,12.1
		c0.5,1,1.5,1.7,1.6,2.9c0.4,2.8,2.4,5.6,0.2,8.5C483.1,529.8,482.4,529.8,481.8,529.2z"/>
	<path class="st2654" d="M813.4,585.2c1.6-5.3,1.6-11,3.9-16.2c-0.6,7.2-1.7,14.3-3.9,21.2C813.4,588.5,813.4,586.8,813.4,585.2z"/>
	<path class="st2720" d="M412.8,304.5c0,6.7,0,13.5,0,20.2c0,1.6,0.5,3.3-0.9,4.7c0-6.9-0.1-13.8,0-20.8
		C411.9,307.2,411.5,305.7,412.8,304.5z"/>
	<path class="st2624" d="M758.4,430.3c-1,0-2,0.1-3,0.1c-2.1,0.7-3.5-0.6-4.9-1.6c-0.3-0.4-0.3-0.8-0.2-1.3c0,0,0.3-0.3,0.3-0.3
		C753.5,427.5,755.9,429,758.4,430.3z"/>
	<path class="st2630" d="M416.7,217.4c0,2,0.1,4,0.1,6.1c-0.2,0.4-0.5,0.6-0.9,0.5c-0.1-0.2-0.2-0.3-0.3-0.5
		C416,221.4,415.2,219.2,416.7,217.4z"/>
	<path class="st2630" d="M414.6,229.4c0.3-2-0.2-4.2,1-6c0.1,0.1,0.3,0.2,0.4,0.4c0.8,2.2,2,4.4-1,6
		C414.9,229.6,414.8,229.5,414.6,229.4z"/>
	<path class="st2721" d="M340.9,660.1c0,4,0,8,0,12c-0.3,0-0.5-0.1-0.8-0.2C340.2,667.9,339,663.9,340.9,660.1z"/>
	<path class="st2675" d="M408.9,561.3c3-0.2,4.6-2.7,6.9-4.1c2.8-0.2,4.9-3.4,8.1-2.2c0.2,0.4,0.3,0.9,0.2,1.3c0.4,1-3.2,1.9,0,3
		c3.2,1.1,1.5,2.6,0.1,3.9c-1,0.9-2.4,1.1-3.7,1.2c-3.9,0.3-7.5,1.4-10.6,3.8c-1.7,1.4-3.7,1.4-5.6,0.3c-0.2-0.2-0.4-0.3-0.5-0.5
		c0-0.6,0.1-1.3,0.1-1.9c0.9-1.8,2.5-2.9,4-4.1C408.3,561.9,408.6,561.6,408.9,561.3z"/>
	<path class="st2657" d="M408,562.2c-0.7,2-2.2,3.3-4,4.1c-0.4-0.1-0.9-0.1-1.3-0.2C404.2,564.4,405.4,562.5,408,562.2z"/>
	<path class="st2675" d="M380.9,590.1c1.1-1.1,2.5-2.2,2-4.1c0.3-0.3,0.6-0.6,1-1c1.9-0.7,3.3-2.1,4-4l0,0c1.5-0.8,2.9-1.8,3.9-3.3
		c0.2-2,2.7-2,3.3-3.7c3.9-0.6,5.3-4.8,8.8-6c0,0,0,0,0,0c3.6,1.1,2.8,2.8,0.8,4.7c-5.4,2.5-7.5,8.7-12.9,11.4
		c-1.8,0.9-2.5,3.6-4,5.4C385.3,592.3,383.3,592.2,380.9,590.1L380.9,590.1z"/>
	<path class="st2722" d="M489.8,491.3c-3.5,0.1-6.4-2.9-10-2c0-0.3,0-0.7,0-1c0.2-4.4,3.1-6.2,7-7c0.3,0,0.7,0,1,0
		c0.9-0.3,1.2,0.2,1.5,0.9c-0.3,3.3,1.8,4.5,4.6,5c0.3,0.4,0.4,0.9,0.3,1.3C493.7,490.9,491.6,490.9,489.8,491.3z"/>
	<path class="st2723" d="M486.8,481.3c-2.4,2.3-5.8,3.5-7,7c-1.4-2.8-3.8-4.7-6-6.9C478.1,482.1,482.4,478.6,486.8,481.3z"/>
	<path class="st2724" d="M564.7,899.8c0.7,0,1.4,0.1,2.1,0.1c0,0.6-0.1,1.3-0.1,1.9c-2.7-0.6-4.5,1.9-6.9,2c0-0.4-0.1-0.7-0.1-1.1
		c0.3-0.9,0.9-1.4,1.6-1.9C562.3,900.3,563.2,899.1,564.7,899.8z"/>
	<path class="st2725" d="M808.4,682.1c0-0.3-0.1-0.6-0.1-0.9c-0.7-0.9-1.5-1.6-1.8-2.7c-1-2-2.3-1.3-3.6-0.5c-1.5,1-2.6,2.6-4.6,2.8
		c-3.1-0.4-3.6-4.6-6.9-4.6c-0.5-1.4,0.5-2.2,1.4-3c3-2.5,7.2-2.9,10-5.7c2.8-2.9,3.9-6.5,4-10.4c0.3-0.7,0.7-1.2,1.6-1
		c2.1,1.9,1.8,4.6,2.2,7c0,5.7,0,11.3,0,17c-0.1,1-0.2,1.9-0.3,2.9C809.5,682.6,809,682.4,808.4,682.1z"/>
	<path class="st2726" d="M808.3,656.1c-0.3,0.3-0.6,0.7-0.9,1c-1.5,0.8,1.1,4.1-2.3,3.9c-1.4-2.5-1.5-5.1-0.8-7.8
		c0.3-0.4,0.6-0.8,1-1.2C806.3,653.4,807.3,654.7,808.3,656.1z"/>
	<path class="st2727" d="M533.8,894.8c-0.4,2.7,0.3,5.4,0.9,8c-2.5-1.4-3.6-3.2-2.5-6.2c0.9-2.3-0.3-4.6-0.8-6.8
		c-2.3-8.7-2.8-17.6-2.8-26.5c0-9.2-0.4-18.3-0.6-27.5c-1.3-1.4-0.3-2.3,0.6-3.3c0.5-0.2,1-0.2,1.5-0.1c2.4,1.4,1.3,4.5,3.1,6.2
		c2.4,3.4,1.5,7.3,2,11.1c0.1,12.7,0.1,25.4,0,38.1C535.3,890.2,535.7,892.8,533.8,894.8z"/>
	<path class="st2728" d="M528.8,833.2c-0.2,0.9-0.4,1.8-0.7,2.7c-0.8,1.7-1.6,3.3-2.4,5c-2.2-7.6-5.8-15-3.1-23.3
		c0.5-1.6-0.2-2.9-1.9-3.6c-0.3-0.4-0.7-0.7-1-1.1c1.7-1.6,3.6-0.4,5.5-0.3c1.1,0.2,2.2,0.5,3,1.4
		C528.7,820.4,528.5,826.8,528.8,833.2z"/>
	<path class="st2729" d="M524.7,813.8c-1.7-0.3-3.3-0.6-5-0.9c-2.3-1.4-4.6-2.8-6.9-4.1c-0.3-1-1.6-1.7-0.7-3.1
		c1.8,0.2,2.6,2.5,4.6,2.2c2.3-0.9,4.6-0.1,6.8,0.2c1.4,0.5,3.1,0.6,4.2,1.7c0.3,1.4-0.7,1.7-1.7,2.1c-1.6,0.2-3.1-0.4-4.8-0.8
		C522.7,811.8,525.1,811.2,524.7,813.8z"/>
	<path class="st2730" d="M510.7,803c-1.2,2.2-6.5,3.2-1.9,7c0,12.3,0,24.5,0,36.8c0,1.7,0.7,3.7-1,5.2c-1.2-1.2-1-2.8-1-4.2
		c-0.2-11.1,0.5-22.3-0.5-33.4c-0.1-0.6,0-1.2,0.1-1.3c-0.7,2.9,0.4,6,0.4,9.1c0.1,12.3-0.6,24.6,0.4,36.9c0.1,0.7,0.1,1.3,0.5,1.9
		c0.7,6.5-1.2,12.9-1.3,19.5c-1-5.5-0.3-11.3-2.7-16.5c-2.8,0.9-4.4-2.3-7-2c-1.2-4.2,0.9-8.2,1-12.3c0-1.5,0.9-3.1,0.4-4.4
		c-1.4-4.4-1.2-8.7,0-13.1c0.6-2.1-0.3-4.1-1.8-6c-3.4-4.2-3.4-5.8,0.2-9.4c2-2,1.9-4,1.3-6.4c-3.2-6.1-3.7-12.3-0.4-18.6
		c1.1-2,0.8-4.2,0.8-6.4c0-4.2-0.2-8.3,0.1-12.5c0.2-3.6,0.3-7-2.7-9.6c-1.1-0.9-1.1-2.4-0.5-3.7c2.8-2.5,5.2-0.4,6.1,1.5
		c3.7,8.1,12.1,13.8,11.9,23.8c0,1.7,0.7,3.3,1.5,4.7c2.7,4.8,1.7,8.8-2,12.5C511.8,801.9,511.1,802.2,510.7,803z"/>
	<path class="st2731" d="M495.8,759c0,0.8-0.2,2.1,0.1,2.4c6.5,4.1,3.4,10.5,3.8,16c0.2,3.3,0,6.6,0,9.9c0,2.5-0.7,4.5-1.8,6.9
		c-2.5,5.3-2.2,10.9,1.8,15.8c-1.1,2.8-1.1,6.2-4.3,8.1c-1.1,0.6-1.4,2.2-1.1,3.6c0.1,0.7,0.3,1.3,0.3,2c-0.2,2.7-1.2,5.2-3.9,5.8
		c-3.7,0.8-1.7-3.1-3-4.5c0-0.3,0-0.7,0-1c-0.9-1.5-0.5-3.2-0.5-4.9c0-20.2,0-40.4,0-60.5c0-2.3,0.1-4.6,0.8-6.9
		c1.6-2.4,3.8-2,6-1.3c2.2,1.6-0.8,2.5-0.6,3.9c-0.1,1.8,1.3,2.8,2,4.1C495.6,758.5,495.7,758.8,495.8,759z"/>
	<path class="st2732" d="M487.8,824.9c1.6,0.7,0.3,4,2.5,3.6c2.1-0.4,3.5-2.9,3.4-4.8c-0.5-5.9,4.1-9.2,6.2-13.8
		c-0.1,3.2,0.8,6.4-3.3,8.5c-2.5,1.3-1.8,4.6,0.1,6c5,3.6,3.5,7.9,2,12.3c-0.3,0.9-1.1,2.1-0.6,2.8c3.2,4,1.2,8.2,0.8,12.3
		c-0.3,3.5-0.9,6.9-2,10.1c-1.4,2.2-3.5,3.9-4.4,6.9c-5.2-5.3-4-11.8-4.5-17.6C487.3,842.4,487.8,833.7,487.8,824.9z"/>
	<path class="st2733" d="M488.8,751c-1.9,8.5-0.9,17.1-1,25.7c-0.2,15.7,0,31.5,0,47.2c-0.3,0-0.6,0-1,0c0-25.3,0-50.6-0.1-75.9
		C489,747.9,489.2,749.3,488.8,751z"/>
	<path class="st2734" d="M507.7,860.9c-1.5-1.9-1.9-4.3-1.9-6.5c-0.1-14.1-0.1-28.2,0.3-42.3c2.9,13.2,1.1,26.6,1.6,39.9
		C507.8,854.9,507.7,857.9,507.7,860.9z"/>
	<path class="st2735" d="M488.8,751c-0.7-1-1.4-2-2-2.9c-0.3-0.7-0.7-1.3-1-2c0-0.4,0-0.7,0.1-1.1c0.6-0.5,1.3-0.6,2-0.4
		c4,2.1,7.9,4.3,11.5,7.1c10.8,8.7,20.6,18.3,26.6,31.1c2.6,5.6,4.5,11.4,5.2,17.6c0,3,2.7,5.7,1.1,8.9c-0.7,0.7-1.5,1.2-2.6,1
		c-1.8-1.3-3.6-2.6-4.1-4.9c-0.1-0.5-0.2-0.9-0.3-1.4c-1.2-3.9,1.6-8.1-0.6-12c-2.9-7.2-6.5-14-11.3-20.2
		c-4.3-5.5-8.7-10.7-14.5-14.6c-1.8-1.2-4.1-2.3-4.1-5.1C493,750.2,490.8,751.3,488.8,751z"/>
	<path class="st2736" d="M494.8,752c4.5,4.5,10.2,7.4,14.3,12.6c6,7.6,12.7,14.7,15.3,24.4c0.2,0.7,0.9,1.3,1.4,2
		c0.8,2.9,0.9,5.8-0.2,8.7c-0.7,1.8-0.5,3.2,1.2,4.3c0,0.3,0,0.7,0,1c-0.9,1.6,1.6,6.1-3.5,4.1c-2.2-0.1-4.5,0.4-6.5-1.2
		c-1.1-2.1-4-3-4.1-5.8c-0.1-0.5-0.5-1.2-0.3-1.4c4.2-4.2,2.4-8.4,0.1-12.6c-0.3-0.6-0.8-1.3-0.7-1.8c1.2-6.7-2.7-11.1-6.6-15.7
		c-2.3-2.7-2-6.9-5.2-9.3c-1.3-1-2.5-2.1-4.2-2.3h0c-0.6-1.9-2.5-3-3-5C491.7,751.5,493.5,752,494.8,752z"/>
	<path class="st2737" d="M750.4,111.7c-0.3-0.9-1.1-1-1.8-1.2c-3.6-3-3.1-7.3-2.9-11.1c0.7-14.1,0.1-28.2,0.4-42.3
		c0.1-4.2,0.2-8.3,2.6-12c0.4-0.5,1-0.7,1.7-0.6c0.9,0.4,1.4,1.2,1.9,2c1.9,2.3,1.6,5,1.7,7.6c0.2,13.8,0,27.6-0.9,41.4
		c-0.3,4.3-0.5,8.6,1.1,12.8c0.4,1.1,0.2,2.3-0.7,3.2C752.3,111.6,751.4,111.7,750.4,111.7z"/>
	<path class="st2738" d="M753.3,111.6c-4.5-19.4,0.8-39-0.8-58.5c-0.2-2.3,0.4-4.7-2-6.2c2.4-1.3,3.7-0.6,4.1,2
		c0.4,2.3,0.4,4.6,0.1,6.9c-0.7,7,0.1,14,0.7,21c0,1.7,0,3.3,0,5c-0.9,8-1.8,16,0,23.9C754.9,107.7,757.4,110.8,753.3,111.6z"/>
	<path class="st2739" d="M755.5,76.8c-1.1-7-2.7-14-1.5-21.3c0.5-3.1,2.8-7.9-3.4-8.7c-0.1-0.7-0.1-1.3-0.2-2c1.5-0.6,2-2.8,3.9-2.7
		c0.4,0.1,0.8,0.3,1.1,0.7C755.4,54.1,755.4,65.4,755.5,76.8z"/>
	<path class="st2739" d="M755.4,105.7c-3.7-8-1.7-16,0-23.9C755.4,89.7,755.4,97.7,755.4,105.7z"/>
	<path class="st2650" d="M755.5,117.7c-0.3,0-0.7,0-1,0c-0.7,0.6-1.4,0.2-2.1-0.1c-0.3-0.2-0.6-0.5-0.8-0.8c-0.3-0.3-0.7-0.7-1-1
		C752.4,116,754.8,114.6,755.5,117.7z"/>
	<path class="st2738" d="M755.4,42.8c-0.3,0-0.7,0-1,0c-1.1-1.1-2-2.2-1.2-3.9c0.4-0.4,0.8-0.4,1.2-0.1c0.4,0.4,0.7,0.7,1.1,1.1
		C755.5,40.8,755.4,41.8,755.4,42.8z"/>
	<path class="st2740" d="M749.4,44.9c-1.6,5.2-1.9,10.6-2,16.1c-0.3,12.9,0.8,25.7-0.6,38.6c-0.4,3.4-0.3,7.5,1.7,10.9
		c-0.9,0.2-1.6,0-2.1-0.8c-2-2.3-3.1-4.5-2.2-7.9c0.8-3.2,0.7-6.9-0.3-10.3c-1.4-4.6,0.6-9.2,0.6-13.8c-0.1-12.3,0-24.5,0-36.8
		c1.4-0.6,1.8,0.7,2.5,1.3C747.8,43.1,749,43.7,749.4,44.9z"/>
	<path class="st2741" d="M746.4,42.8c-0.7-0.7-1.3-1.3-2-2c0-0.7,0-1.4-0.1-2.1c1.7-0.9,3.4-1.9,5.2-2.8c0.2,0.1,0.5,0.1,0.7,0
		c1.2,1.1,3.4,0.9,4.1,2.9c-0.3,0-0.7,0.1-1,0.1C750.4,39,748.3,40.7,746.4,42.8z"/>
	<path class="st2742" d="M726.5,105.6c0-1.4,0.7-2.3,2-2.8c0.7,0.7,1.3,1.3,2,2c1.1,1.6,2.4,3,4,4c2.1,2.1,4.1,4.4,7.2,4.9
		c1.2,1.4,2.5,2.7,3.7,4.1c2.3,1.4,3.8,3.5,4.4,6.1c0,0.5-0.1,0.9-0.4,1.3c-1.2,1.1-2.4,2.2-3.9,2.9c-0.5,0.1-0.9,0.1-1.3-0.1
		c-2.8-2.2-3.5-6.2-6.4-8.3c-0.9-0.7-1.6-1.5-2.1-2.5c-0.6-1.6-1.7-2.9-2.9-4.1C730.8,110.6,727.6,109,726.5,105.6z"/>
	<path class="st2743" d="M751.6,116.8c0.3,0,0.6,0.1,0.9,0.1c1.1,2.7,1.9,5.4,1.9,8.4c-0.6,1.9-0.3,4.1-2.1,5.6
		c-5.1-1-5.3-1.4-2.9-6l0.1-0.3c0.8-2.3-1.7-4.5-0.1-6.8C750.3,117.8,751.2,117.7,751.6,116.8z"/>
	<path class="st2744" d="M749.4,117.8c0.2,2.3,2.2,4.5,0.1,6.8c-1.4-2.3-2.7-4.6-4.1-6.9C746.8,117.8,748.1,117.8,749.4,117.8z"/>
	<path class="st2714" d="M734.5,108.7c-2.3-0.3-3.7-1.7-4-4C732.2,105.7,733.6,107,734.5,108.7z"/>
	<path class="st2745" d="M749.5,222.6c-0.9-1.9-2.3-3.7-2-6c6.4,2.2,12.4,5.2,17.3,10c2.5,2.4,4.5,5.3,6.7,8
		c-0.1,0.2-0.1,0.4-0.2,0.7c-0.7,1.6-0.8,3.8-3.1,3.9c-3.3,0.2-2.4-3.8-4.5-5.1c-0.6,1.6,1.1,2.9,0.2,4.3c-2.2,1.3-3.2-0.9-4.8-1.6
		c-0.8-0.5-1.5-1-2.2-1.7c-1.3-1.2-2.3-2.7-3.1-4.3c-0.6-1.6-1.3-3.1-2.2-4.5C750.8,225.1,750.6,223.6,749.5,222.6z"/>
	<path class="st2746" d="M782.4,257.5c3.4,4.6-0.6,10.1,2.7,15.9c-6.3-2.3-8.3-6.8-10.7-10.9c-2.1-2.4-4.4-4.8-5.6-7.8
		c-0.4-1.5-1.1-3-1.8-4.4c-0.2-0.7-0.1-1.4,0.5-1.9c2.2-0.3,3.6,0.8,4.2,2.6c1.1,3.4,3.3,5.9,5.9,8.5c1.2-2.8,0.1-5.9,1-8.8
		c0.2-0.5,0.5-0.8,0.9-1.1C781.5,251.8,780.4,255.3,782.4,257.5z"/>
	<path class="st2745" d="M779.4,249.6c0,0.3,0,0.7,0,1c-1.7,1.3-1.2,5.2-3.5,4.7c-2.6-0.5-1.7-3.9-1.8-6.1c-0.1-3.2-1.1-6.2-2.4-9.1
		c-0.8-1.8-1.9-3.6-0.3-5.6c0,0,0,0,0,0C774.9,239.2,777.2,244.4,779.4,249.6z"/>
	<path class="st2747" d="M773.8,268.5c-0.9,1.6,1.3,2.6,0.7,4.1c-0.6,0.7-1.3,1.3-1.9,2c-1-0.4-0.5-1.5-1-2.2l0.1,0
		c-2.7-4.4-3.4-9.3-4.1-14.3c0.2-0.8,0.6-1.4,1.3-1.8c2.5,0.9,3.2,2.9,3.3,5.3C772.7,263.9,773.2,266.2,773.8,268.5z"/>
	<path class="st2748" d="M772.2,261.6c-0.9-1.7-1.7-3.4-2.6-5.1c-0.7-0.4-0.7-1-0.4-1.7c0.2-0.1,0.3-0.3,0.5-0.4
		c1.6,2.7,4.1,4.9,4.8,8.1C773.7,262.2,772.9,261.9,772.2,261.6z"/>
	<path class="st2749" d="M415.8,334.4c0-1.3-0.1-2.6-0.1-4c0-3.3,0.1-6.5,0.1-9.8c0.4-0.5,0.8-0.5,1.3-0.2c0.3,0.4,0.6,0.8,0.7,1.3
		c0.5,3.6,1.4,7.1,1.9,10.7c0.2,1.7-1,3.3-0.2,5c0.3,0.3,0.5,0.6,0.8,0.9c4.7,1.3,9.2,3.2,12.9,6.6c0.9,0.5,1.9,1,2.7,1.7
		c0.5,0.4,1,0.8,1.5,1.2c0.7,0.6,1.4,1.1,2,1.8c0.8,1.3,1.5,2.6,0.3,4c-1.4,0.5-2.4,0.1-3-1.3c-1.4-1-2.7-2-4.1-2.9
		c-2.5-3.5-6.5-4.5-10.2-5.6C417.4,342.5,415,339.7,415.8,334.4z"/>
	<path class="st2750" d="M430.8,368.5c-1.2-2.2-4.6-4.1-1.1-7c1.9,0.2,3.2,1.4,4.6,2.5c0.9,1,1.6,2.2,2,3.5
		c-0.1,2.2,1.5,3.7,2.1,5.6c0.7,3.6,3.4,6.2,4.8,9.5c0.8,1.9,2.4,3.6,1.8,5.9c-0.4,0.3-0.8,0.5-1.3,0.5c-2.5-0.8-3.2-3.2-4.5-5.1
		c-0.9-1.8-2.1-3.4-3-5.1c-0.9-2-2.8-3.4-3.9-5.3C431.8,371.8,430.2,370.4,430.8,368.5z"/>
	<path class="st2751" d="M395.9,359.4c-0.8,0-1.6-0.2-2-1c1.3-1.6,3.3-2,5-3c3.6,1.2,7.1-0.7,10.7,0.1c7.6,1.6,13.6,5.7,18.2,12
		c-0.6,0.5-1.3,0.6-2.1,0.4c-4.9-3.4-9.4-7.4-15.6-8.5c-3.5-0.8-7-1-10.5,0.2C398.4,360,397.1,360.5,395.9,359.4z"/>
	<path class="st2752" d="M433.8,364.4c-1.6-0.7-3.1-1.5-4.1-3c-0.3-0.4-0.5-0.7-0.8-1.1c-0.4-0.4-0.7-0.9-0.9-1.4
		c0.2-4.9,1.3-5.8,7.5-6c1.4,0.1,2.4,0.8,3,2c0.3,1.2,0.1,2.7-1.2,2.8c-3.9,0.2-3.9,2.1-2.7,5C434.8,363.3,434.3,364,433.8,364.4z"
		/>
	<path class="st2751" d="M430.8,368.5c0.9,1.5,2.4,2.7,2,4.7c-0.2,0.1-0.4,0.3-0.6,0.4c-1.6-0.2-2.4-1.3-3.2-2.5
		c-0.4-0.8-0.8-1.7-0.3-2.7C429.5,368.5,430.1,368.5,430.8,368.5z"/>
	<path class="st2615" d="M428.8,359.4c0,0.3,0,0.6,0.1,0.9c-2.1,0.1-3.1-1.4-4.1-2.8c0,0,0.2-0.1,0.2-0.1
		C427.3,356,427.8,358.2,428.8,359.4z"/>
	<path class="st2753" d="M428.8,368.5c0.3,0.7,0.6,1.3,0.9,2c-2.1,1.1-3.1-0.3-4-1.8c-0.1-0.5-0.1-0.9,0.2-1.4
		c0.6,0.1,1.3,0.1,1.9,0.2C428.1,367.8,428.5,368.1,428.8,368.5z"/>
	<path class="st2754" d="M428.8,359.4c-1.4-0.6-2.2-2.1-3.9-2c2.4-4.2,6.2-5.5,10.8-4.8c0.4,0.2,0.5,0.4,0.4,0.6
		c-0.1,0.2-0.2,0.3-0.3,0.3C431.8,353.4,428.4,354.2,428.8,359.4z"/>
	<path class="st2755" d="M435.8,353.5c0-0.3,0-0.6-0.1-0.9c0.3-0.1,0.7-0.1,1-0.1c1,0.4,2,0.7,2.9,1.1c0.8,0,1.3,0.4,1.6,1.1
		c0.2,0.7,0.1,1.3-0.5,1.9c-1.3,0.6-2.1-0.2-2.9-1C437.2,354.8,436.5,354.1,435.8,353.5z"/>
	<path class="st2756" d="M356.9,625.1c2.5-2.7,2-7.3,6-9c3.9,1.1,5.1,4.5,6.2,7.8c0,6,0.1,11.7,3.1,17.3c2.2,4,0.6,9.1,2.5,13.6
		c0.9,2.1,1.2,4.3,3,6c1.7,1.6,2.5,4,2.9,6.2c1.7,9.9,7.8,17.9,11.2,27.1c0.3,0.8,1.1,1.6,0.8,2.3c-1.5,3.4,0.7,5.2,3,6.6
		c4,2.5,6.4,7,11.1,8.6c4.8,0.6,8.3,4.4,12.9,5.5c1.1,0.6,2.4,1.2,2.4,2.8c-0.4,0.5-0.8,0.6-1.3,0.1c-3-0.7-5.9-1.4-8.9-2.1
		c-4.1-3.2-9.7-3.2-14-5.9c-0.9-1-2-1.9-3.1-2.8c-1.3-1.3-2.6-2.4-4.5-3c-3.3-1.1-5.4-3.2-5.3-6.9c0-0.7,0.2-1.6-0.2-1.9
		c-5.4-3.8-5-10.9-8.7-15.6c-1.7-2.2-2.3-4.8-3.2-7.2c-2.2-6.2-7.5-11.1-7.3-18.2c0.1-1.5-1.2-1.7-2.1-2.4c-3.7-2.7-7.1-5.8-10.4-9
		C351.6,637.7,354.6,631.5,356.9,625.1z"/>
	<path class="st2757" d="M395.9,709c1.3,0.6,2,1.6,2,3c-7-3-14.1-6.1-21.1-9.1c0.4-0.5,0.9-0.9,1.8-2c-3.3,0.8-6.1,1.6-7.7-1.7
		c-0.8-1.7-2.6-1.1-4-1.2c-9.1-8-18.8-15.5-23.9-27c0.9-1.8,2.5-2,4.2-1.6c9.2,1.7,15.4,6.7,18.2,15.9c1.5,4.9,6.1,7.6,9.3,11.3
		c4.7,1.1,8.8,2.7,11.9,6.9C388.7,706.4,393.2,706.5,395.9,709z"/>
	<path class="st2758" d="M420.8,720.1c0.4,0,0.7-0.1,1.1-0.1c1.8-2.3,4-1.7,6.3-1c2.3,1,4.6,1.8,7.1,2.1c9.5,2.6,18.6,6.2,27.7,9.7
		c0.4,0.8,0.4,1.5-0.2,2.2c-3.9,0-7-3.2-11-3c-1-0.3-2-0.7-3-1c-8.4-3.4-17.1-5.6-25.8-8C422.2,720.8,421.5,720.4,420.8,720.1z"/>
	<path class="st2759" d="M462.8,733c0-0.7,0-1.3,0-2c1.4-0.8,3.1-0.2,4.5-0.9c2.7-0.4,4.8,0.3,6.2,2.9c1.6,3.1,5.5,2.8,7.9,4.8
		c1.5,1.3,3.5,1.9,5.1,3.2c1.1,1.4,3.7,1.6,3.3,4.2c-0.7,0.4-1.4,0.3-2-0.2c-0.6,0-1.3-0.1-1.9-0.1c-4.8-3.7-10-6.6-15.9-8
		c-0.7-0.3-1.4-0.6-2-0.9C466.8,733.8,464.4,734.1,462.8,733z"/>
	<path class="st2760" d="M758.5,366.4c0.2,6.3-2.8,11.8-4.4,17.6c-2.2,8.4-5.3,16.7-9.4,24.4c-0.2,0.4-0.8,0.6-1.2,0.9
		c-0.2-0.2-0.3-0.4-0.5-0.6c-0.6-3.4-0.4-6.7,0.2-10.1c0.3-1.6,0.3-3.3,2.3-3.8c0.3,0,0.6,0.2,0.9,0.4c0.5,0.4-0.4,0.1,0.2,0.4
		c0.1-0.2-0.2-0.3-0.2-0.4c-0.3-0.3-0.4-0.6-0.5-1c0-0.4,0.1-0.8,0.2-1.2c1.1-1.8,2.2-3.5,2.3-5.7c0.1-0.6,0.3-1.2,0.5-1.8
		c0.7-1,1.4-1.9,2-3c1.1-0.8-0.1-1.6,0-2.4c0.4-1.9,1.2-3.8,0.9-5.8c0.1-0.5,0.2-0.9,0.4-1.3c2.2-2.6,2.5-5.9,3.8-8.9
		c0.8-0.9,1.2-2.5,2.9-1.4C758.9,363.9,759.3,365.2,758.5,366.4z"/>
	<path class="st2761" d="M758.5,366.4c0-1.3,0-2.7,0-4c-0.6-0.6-0.7-1.4-0.7-2.2c0.4-1.8,0.4-3.6,0.3-5.5
		c2.2-15.1-0.5-29.7-4.2-44.3c0-1.4,0.3-2.7,1.3-3.8c0.4-0.4,0.9-0.5,1.5-0.3c1.1,1.5,1.8,3.1,1,5c4.5,14.1,4.7,28.5,3.1,43.1
		C760.2,358.5,760.4,362.7,758.5,366.4z"/>
	<path class="st2760" d="M740.5,417.4c-0.3,0-0.7,0-1,0c-0.5,0.7-1.1,1.4-1.9,0.8c-0.9-0.6-0.9-1.7-0.6-2.7c0.2-0.5,0.5-0.9,0.8-1.2
		c1.4-1.2,1.2-3,1.8-4.5c1-2.3,2.3-2.7,3.9-0.5c0,0,0,0.1,0,0.1C742.5,412.1,741.5,414.7,740.5,417.4z"/>
	<path class="st2762" d="M757.6,311.4c-0.4-1.6-0.8-3.2-1.2-4.9c-0.3-0.9,0.1-1.2,0.9-1c0.4,1.3,0.8,2.5,1.3,3.8
		C758.3,310,757.9,310.7,757.6,311.4z"/>
	<path class="st2763" d="M725,447.2c1,0.2,1.5,0.8,1.6,1.9c1.3,3.5,5.9,4.9,5.8,9.3c-2.9,0.9-4.2,3.3-5.9,5.7
		c-3.1,4.5-6.8,9.1-12.8,8.6c-2-0.2-6.4,3.4-7-2.2c0.5-2.9,2.8-4.3,4.8-6c4.6-4,8.5-8.6,10.9-14.3c0.4-1.2,1.2-2,2.1-2.8
		C724.7,447.4,724.9,447.4,725,447.2z"/>
	<path class="st2764" d="M734.5,438.3c0.3-0.3,0.6-0.7,1-1c1.6-1.7,2.7-3.7,4.1-5.5c2.6-3.4,5.6-4.9,9.8-3.8
		c1.3,1.4,3.9,0.9,4.5,3.3c-2.4,2.8-6.8,3.5-7.9,7.8c-0.3,1-1.6,1-2.6,0.9c-1.9-0.2-3.4-0.1-2.6,2.6c0.3,0.9-0.4,1.8-1.1,1.9
		c-3.9,0.7-5,4.6-7.9,6.5c-0.4,0.3-0.8,0.6-1.2,0.8c-1.1,0-1.9-0.5-2.3-1.5C729.1,445.6,732.2,442.2,734.5,438.3z"/>
	<path class="st2765" d="M732.5,458.3c-1.4-3.4-6.5-4.5-5.9-9.2c0.3,0.1,0.6,0.2,0.8,0.2c0.4,0.3,0.6,0.7,0.7,1.1
		c8.5,6.7,13.8,16,20.1,24.4c0.6,1.1,1.1,2.2,0.5,3.4c-0.4,0.3-0.8,0.5-1.3,0.6c-2.5-0.4-3.3-2.5-4.5-4.2c-2.6-3.8-5.2-7.5-8.6-10.6
		c-0.3-0.3-0.6-0.7-0.8-1.1c-0.6-1.2-1.1-2.5-1.2-3.9C732.4,458.8,732.4,458.5,732.5,458.3z"/>
	<path class="st2766" d="M749.4,428.4c-4.6-0.7-7.8,1.6-10.4,5.1c-1.1,1.4-1.8,3.2-3.6,3.8c0.7-4.2,4.1-6.7,6.5-9.7
		c2.4-3,5.5,0.4,8.4-0.1c0.1,0.3,0.2,0.5,0.2,0.8C750.2,428.7,749.8,428.8,749.4,428.4z"/>
	<path class="st2766" d="M727.8,450.3c-0.1-0.3-0.2-0.6-0.3-1c1.2-4.4,3.7-8,7-11c-0.8,4.6-4.7,7.6-5.9,12
		C728.3,450.7,728,450.7,727.8,450.3z"/>
	<path class="st2767" d="M737.5,421.3c0.5,3.2-3,5-3,8c-4,5.3-8,10.6-12,16c-0.7,1.8-1.2,3.7-3.2,4.6c-0.8,0.2-1.5,0-2.1-0.5
		c-0.6-0.9-0.6-1.9-0.4-2.9c0.4-1.5,1-2.8,2-4c3-3,5.2-6.5,7-10.2c2.1-4.1,5.5-7,8.3-10.5C735,420.5,736.2,420.3,737.5,421.3z"/>
	<path class="st2768" d="M719.4,449.4c0.9-1.4,1.3-3.3,3.1-4c0.3,0.6,0.7,1.2,1,1.9c-0.1,2.5-2.3,3.8-3.1,5.9
		c-3,4.1-6.7,7.5-10.1,11.2c-1.2,1.3-2.6,2.5-4.7,2.2c-0.4-0.3-0.6-0.8-0.6-1.2C708.1,458.5,713.6,453.8,719.4,449.4z"/>
	<path class="st2769" d="M719.6,452.3c0.7-2.2,2.5-3.5,3.9-5.2c0.4,0.1,0.7,0.1,1.1,0.2c-0.4,0.9-0.7,1.9-1.1,2.8
		c-0.6,1-1.1,1.9-2.1,2.6C720.7,453,720.1,452.9,719.6,452.3z"/>
	<path class="st2770" d="M737.5,421.3c-4.5,2.3-7.3,6.2-9.9,10.3c-2.5,4.1-4.5,8.4-8.1,11.7c-0.5-0.5-0.9-1-1-1.7
		c-0.1-4.5,0.8-9.6,3.3-12.5c2.8-3.2,4.4-6.6,6.6-9.9c1.9-3,3-6.1,3.9-9.4c0.4-1.2,0.5-2.8,2.3-2.8c1.7,0.1,1.9,1.7,2.1,2.9
		c0.2,1.8,1.1,3.5,0.8,5.3c0.1,1.3-0.4,3.1,2,2C739.6,419.1,738.1,420,737.5,421.3z"/>
	<path class="st2771" d="M416.8,320.5c-0.3,0-0.7,0.1-1,0.1c-0.2-1.5-0.4-3-0.6-4.4c1.2-11.2,1-22.5,0.2-33.7
		c-0.5-6.8-0.4-13.6-0.6-20.4c-0.2-9.1,0-18.3,0-27.4c0.1-1.6,0.1-3.3,0.2-4.9c1.3-1.8,0.6-4,1-6c0.3-0.1,0.5-0.2,0.8-0.3
		c0,0.4,0,0.8,0.1,1.2c1.9,2.5,1.2,5.3,1,8c-1.3,7-0.3,14-0.6,21c-0.9,11.3,1.6,22.5,1.1,33.8c-0.3,7.3,0.1,14.6-0.1,21.8
		C417.7,313.1,419.5,317.1,416.8,320.5z"/>
	<path class="st2772" d="M373.9,698c-3-3.1-6-6.2-9-9.3c-0.3-0.3-0.9-0.8-0.8-1.1c1.7-6.1-4.2-8.3-6.4-12.4
		c-1.7-3-10.7-5.3-14.7-4.2c-0.6-8,0.7-15.6,3.7-23c0.5-1.2,0.2-2.6,0.2-3.9c1.3-3.3,2.6-6.7,3.9-10c4-1.7,3-6.6,6-9
		c-1.1,6.6-3.3,13.1-3,20c0.9,1.1,0.8,2.5,0.7,3.8c-0.5,6.8,1.4,12.4,8.1,15.3c0.6,0.3,1.4,0.7,1.5,1.2c0.3,5.1,5.5,8.4,5,13.8
		c-0.2,2.1,1.2,3.6,2.1,5.3C373.6,688.7,375.5,693.1,373.9,698z"/>
	<path class="st2773" d="M367.9,624.1c-0.8-3.2-3.2-5.4-5-8c0.3-1,0.7-2,1-3c0.9-0.4,1.7-2.6,2.8-0.2c1.8,3.9,4.4,1.3,4.8-0.3
		c1.5-6.3,5.5-11.1,8.3-16.5c1.7,1.6,2.6,0.3,3.6-0.9c1.1-1.2,1.9-2.8,3.4-3.6c2.4-1.3,3.9-1,3.5,2.2c-0.6,3.1-2.3,5.4-4.6,7.5
		c-2.1,1.9-4.4,3.6-5,6.6c-0.1,0.5-0.4,1.1-0.8,1.2c-5.5,1.7-3.9,3.7-0.6,6.6c2.6,2.3,2.2,5.8,1.2,8.9c-2,6.1-1.9,12,0.4,18
		c1.9,4.8,2.9,9.8,5.4,14.4c1.3,2.4,1,5.2,0.4,7.8c-0.6,1.5-0.9,3.5-3,3.5c-2.4,0.1-3.7-2.1-4.2-3.7c-1.6-4.8-4.1-9.4-2.7-14.8
		c0.6-2.4,0.5-4.8-1.5-6.5c-5.8-4.8-4.9-10.7-3-16.9c0.2-0.8,0.8-1.4,0.6-2.4C371.3,624.6,369.6,625.8,367.9,624.1z"/>
	<path class="st2774" d="M450.8,449.3c-2,1.2-2.6,3.3-3.9,5c-2.1,1.7-4.3,3.3-7,4c-3.4,0.8-6.7,0.7-10.1,0.1l0,0
		c-2.6-1.5-5.7-2-7.9-4.1c0-0.9,0.2-1.7,1.1-2.1c1.5-1.1,3.2-0.9,4.8-0.6c5.8,1.2,10.8-0.3,15.1-4.2c4.3-2.2,4.6-6.8,6.6-10.4
		c3.1-2.2,3.2,0.6,4.1,2.3c0.3,1,0.2,2,0,3.1C452.6,444.7,452.4,447.3,450.8,449.3L450.8,449.3z"/>
	<path class="st2775" d="M443.5,448.2c-5.9,6.3-13.1,5.5-20.5,4c-2.5-1.8-5.5-3-7.2-5.9c-0.3-1.4-0.3-2.8,1.4-3.4
		c2-0.1,3.5,1.1,5.3,1.6c5.9,1.4,11.2,0.6,15.2-4.5c0.5-0.7,1.3-1.1,2.2-0.9c0.8,0.8,0.9,1.8,0.7,2.8c-0.4,1.2-1.3,2.1-2.1,3
		c-3.2,2.9-6.9,4.8-11.5,4.5c4.8,2.2,9,1,13-1.7c0.9-0.8,1.6-1.8,2.9-2c0.5,0,0.9,0.2,1.3,0.6C444.4,447.2,444.4,447.9,443.5,448.2z
		"/>
	<path class="st2776" d="M403.8,433.4c-7.6-9-11.9-19.5-13.8-31.1c0.3-0.6,0.5-1.2,0.8-1.8c0.4-0.9,0.9-1.7,2.1-1.1
		c1.8,2,0.8,4.7,1.8,6.9c0.4,2.1,0.6,4.3,1.4,6.3c1.7,7.1,6.4,12.7,9.1,19.3C405.1,432.7,404.6,433.2,403.8,433.4z"/>
	<path class="st2777" d="M391.9,399.4c-0.1,0.8-0.2,1.6-1,2c-1.4-0.9,0.6-2.9-1.2-3.8c-0.3-0.1-0.5-0.2-0.7-0.3
		c-0.3-6,0-11.9,1.2-17.8c0-1.3,0.1-2.6,2-1.9c1.7,2.8,0.3,5.6-0.6,8.2c-0.7,2.3,0.5,2.8,2,3.5C393.8,392.7,393.8,396.2,391.9,399.4
		z"/>
	<path class="st2778" d="M416.8,443.4c-0.4,1-0.7,2-1.1,2.9c-2.1-2-4.2-3.9-6.3-5.9c0.3-0.8,0.7-1.3,1.5-1.7
		C414.1,439,416.4,440,416.8,443.4z"/>
	<path class="st2779" d="M411,439c-0.5,0.5-1,1-1.4,1.5c-1.9-2.4-3.8-4.7-5.7-7.1c0.4-0.4,0.7-0.7,1.1-1.1
		C408.6,433.1,409.9,435.9,411,439z"/>
	<path class="st2780" d="M421.9,454.3c2.9,0.9,5.7,1.9,7.9,4.1c-2.9-0.2-5.5-1.4-7.9-2.9c-0.4-0.4-0.7-0.7-1.1-1.1
		C421.1,454.3,421.5,454.3,421.9,454.3z"/>
	<path class="st2781" d="M389.7,397.6c0.4-0.2,0.2-2.2,1-0.5c0.6,1.2,0.1,2.9,0.2,4.3c-0.3,0.3-0.6,0.6-0.8,1
		C390,400.7,389.8,399.2,389.7,397.6z"/>
	<path class="st2782" d="M391.7,377.1c-0.5,0.8-1.1,1.5-1.6,2.3c-2.2,1-0.6,3.9-3,5.1c-0.1-1.8-0.3-3.5-0.4-5.1
		c-0.4-1.7-0.8-3.3,0.3-4.9c0.2,0,0.5-0.1,0.7-0.1c2-1.5,3.8-3.2,5-5.4c0.3-0.4,0.6-0.7,1-1c0.6-0.3,1.3-0.4,2.1-0.3
		c0.5,0.1,0.9,0.3,1.2,0.7c0.3,3.1-3,4.2-3.7,6.8c-0.2,0.4-0.4,0.8-0.6,1.2C392.5,376.7,392.2,376.9,391.7,377.1z"/>
	<path class="st2450" d="M387,374.5c-0.1,1.6-0.2,3.3-0.3,4.9c-0.3,0-0.5,0.1-0.7,0.1C386.1,377.8,385.1,375.9,387,374.5z"/>
	<path class="st2783" d="M459.8,415.4c-0.9-4.3-2.6-8.3-5-12c-2.8-2.9-4.2-6.7-5.9-10.3c-2.3-5-4.8-9.8-8.1-14.3
		c-0.8-1.1-1.7-2.1-1-3.6c2.9-1.2,4,2,6.2,2.6c2.6,1.9,4,4.7,5.5,7.4c1,2.9,2.9,5.3,4.1,8.1c0.9,2.7,1.7,5.3,3,7.9
		c0.5,1.3,0.9,2.7,1.3,4.1c0.4,2.7,1.8,5.1,2,7.8c0,0.8-0.2,1.5-0.6,2.1C460.7,415.7,460.3,415.7,459.8,415.4z"/>
	<path class="st2784" d="M459.8,415.4c0.3,0,0.7,0,1,0c1.6,1,2,2.6,2.2,4.2c0.1,1.1,0.1,2.2,0.1,3.4c-0.1,5.1,0.3,10.2-0.2,15.3
		c-1.5,4.8-1.4,9.3,2.4,13.2c0.8,1.3,1.8,2.7,1.8,4.4c-0.6,2.2-2.2,3.3-4.3,3.6c-4.4-0.1-5.7-2.6-5.1-6.5c0.6-3.9,1.4-7.7,2-11.5
		C462,432.7,460,424,459.8,415.4z"/>
	<path class="st2647" d="M450.8,449.3c-0.1-2.6,0.7-4.9,2-7.1c1.9-12.8-0.4-25.1-4.3-37.2c-0.6-1.9-1.6-3.6-1.3-5.7
		c0.5-1.4,1.4-1.2,2.5-0.7c1.3,0.9,2.1,2.1,2.1,3.8c1.9,11.1,5.2,21.9,4.1,33.3c-0.4,3.9-1.3,7.5-2.4,11.1
		C453.1,448.1,452.5,449.4,450.8,449.3z"/>
	<path class="st2785" d="M451.8,402.4c-0.7-1-1.3-2-2-3c-2.6-3.3-4.7-6.7-5-11.1c-1.7-5.3-6.4-9.1-7-14.9c1.6-0.3,2.3,0.4,2,2
		c5.6,7.7,9.8,16.2,13.8,24.7c0.5,1,0.8,2.1,1.1,3.2C453.8,403,452.8,402.7,451.8,402.4z"/>
	<path class="st2786" d="M459.8,441.3c0.5,4-0.6,7.9-1,11.8c-0.4,3.8,1.1,5.7,5,5.2c-0.3,2.4-1.8,3.2-4,3c-5.9,0.8-5.8,0.8-4.2-4.9
		C457,451.4,457.5,446.1,459.8,441.3z"/>
	<path class="st2787" d="M459.8,461.3c1.3-1,2.7-2,4-3c1.3-0.7,2.3-1.7,3-3c0.2-1.9,0.6-3.7,2.8-4.2c0.9,0.4,1.3,1.2,1.6,2.2
		c0.1,0.5,0.1,0.9,0,1.4c-1.1,3.4-3.5,5.7-6.4,7.7c-2.3,0.3-4.7,0.7-7,1C458.5,462.6,459.2,462,459.8,461.3z"/>
	<path class="st2788" d="M477.8,471.3c0.6-1,2.1-1.4,2-3c6.4-5.5,9.5-13,12-20.7c3.1-9.3,3.4-19,2.1-28.5c-0.7-5.4-1.9-10.8-3-16.1
		c-1-2.9-2-5.7-3-8.6c-0.4-2.7-2.8-5.2-1.1-8.1c1.1-0.1,2,0.4,2.5,1.3c2.7,5.9,6.4,11.6,6.8,18.4c0.2,6.4,2.2,12.6,2.6,19.1
		c0.8,13.7,0.3,27.1-6.6,39.4c-0.4,0.7-0.9,1.4-1.6,1.8c-2.5,0-3.8,1.4-4.2,3.8c-0.7,0.8-1.5,1.2-2.4,0.3
		C481.8,470.6,479.9,471.7,477.8,471.3z"/>
	<path class="st2789" d="M483.8,470.3c0.8,0,1.6-0.2,2-1c1-1,0.3-3.7,3-3c4.5-5.8,7.1-12.3,8-19.6c1.2-9.9,1.1-19.8-0.1-29.7
		c-0.5-3.9-2.7-7.5-1.9-11.6c3.6-0.9,3.6,2.1,4,4c1.1,5.3,2,10.7,2.3,16.1c0.7,15.2,2.3,15.9-10.5,46.7c4.3,1.1,6.7-4,10.8-3.3
		c-0.1,3.9-1.8,5.5-6.7,6.4c-1.8,0.3-3.5,0.8-5.3,0.9C484.1,476.8,483.4,476,483.8,470.3z"/>
	<path class="st2790" d="M494.8,475.3c2.5-1.8,5.4-3.1,7-6c1.8-3.9,4.3-7.5,6.8-11c0.4-0.2,0.9-0.3,1.4-0.1c0.5,0.6,0.7,1.3,0.6,2
		c-0.1,1-0.5,1.8-0.9,2.7c-1.2,2.3-2.6,4.5-3.6,6.9c-2.1,2.7-4.6,5.2-7.3,7.4c-2.1,1.6-4.4,3.2-7.1,1.1c0-0.3,0-0.6,0-1
		C492.8,476.6,493.8,476,494.8,475.3z"/>
	<path class="st2644" d="M491.8,402.4c2.7,11.9,5,23.6,3.4,36c-1,8-4.4,14.8-6.9,22.1c-1.2,3.4-4.4,6.6-8.5,7.8
		c-5.3,3.4-10.6,0.5-16,0c0,0,0,0,0,0c0.2-2.4,2-1.9,3.5-2.2c8.5,1.4,15-1.9,17.5-9c1.7-3.1,3.1-6.3,4.2-9.6c3.4-9.5,4-19.3,3-29.3
		c-0.2-0.5-0.3-1-0.3-1.5c-0.2-1.8-0.2-3.7-0.8-5.4c-0.2-0.8-0.3-1.6-0.4-2.4C490.6,406.6,489.9,404.2,491.8,402.4z"/>
	<path class="st2791" d="M467.8,467.3c-1.4,0.1-2.9-0.4-4,1c-1.5-0.4-3.7-0.3-3.4-2.5c0.2-1.7,2.3-1.4,3.7-1.6
		c1.9-0.3,3.8-0.6,5.7-1c0.5-0.5,0.7-1.2,1.4-1.4c0.5-0.1,0.9-0.1,1.4,0c1.8,0.5,4-0.2,5.3,1.7C475.5,467.1,471,465.6,467.8,467.3z"
		/>
	<path class="st2792" d="M464.8,462.3c2-2.7,4-5.3,6-8c2.2-0.5,3.9,0.8,5.8,1.6c0.9,3.3-2.2,2.7-3.6,3.7c-0.4,0.2-0.8,0.3-1.2,0.5
		c-0.6,0.2-1.3,0.3-1.9,0.2C468.1,461,466.5,461.6,464.8,462.3z"/>
	<path class="st2793" d="M469.8,460.3c0.4-0.8,1.1-1,2-1c0.7,1.3-0.2,2.1-1,2.9c-0.3,0.3-0.7,0.7-1,1
		C469.8,462.3,469.8,461.3,469.8,460.3z"/>
	<path class="st2794" d="M491.8,478.3c4.4,0.7,6.9-2.4,9.6-5c1.4-1.4,2.9-2.7,4.3-4c2.3,3-1,4.7-1.7,7c-1,3.4-3.1,6.2-5.9,8.4
		c-3.3-0.2-4.4-3.6-7.1-4.8c-0.1-0.2-0.2-0.4-0.3-0.6C491.1,478.9,491.4,478.6,491.8,478.3z"/>
	<path class="st2795" d="M494,488.1c0-0.3-0.1-0.6-0.2-0.8c-2.2-1.2-4.2-2.5-5-5c-0.4-0.3-0.7-0.7-1.1-1c0.1-2,1.5-2,2.9-2
		c0,0,0.2,0.1,0.2,0.1c2,2.4,4.4,4.6,5.4,7.7c0,0.8-0.4,1.3-1.1,1.6C494.7,488.7,494.3,488.5,494,488.1z"/>
	<path class="st2780" d="M429.8,458.4c3.4,0,6.7,0,10.1-0.1C436.5,459.9,433.2,460,429.8,458.4z"/>
	<path class="st2796" d="M759.3,299.5c-0.9,1.7-2.3,1.3-3.7,1c-2.3-0.7-2.9-2.4-2.7-4.6c1.1-2.1-0.1-3.7-0.9-5.5
		c-0.6-1.4-2.1-2.8,0.1-4.2c1.3,0.1,2.1,0.9,3,1.8c0.9,1.1,1.6,2.3,2.3,3.6C758,294.2,761.2,296.3,759.3,299.5z"/>
	<path class="st2797" d="M753.3,295.6c0.8,1.6,1.6,3.3,2.4,4.9c0.6,1.7,1.1,3.4,1.7,5c-0.3,0.3-0.6,0.7-0.9,1
		c-0.3,0-0.6-0.1-0.9-0.1c-0.2-0.1-0.4-0.3-0.5-0.4c-1.9-2.8-2.3-6.2-4.2-9c-1.8-3-3.5-6.2-5.8-8.9c-0.5-0.8-1-1.6-0.8-2.6
		c0.3-0.7,0.9-1.1,1.6-1.3C750,286.9,752.5,290.6,753.3,295.6z"/>
	<path class="st2796" d="M762.3,308.5c-1,0.7-0.7,2-1.4,3.6c-1-4.3-1.9-7.9-1.4-11.7c0.2-0.1,0.4-0.1,0.6-0.2c0.4,0.2,0.8,0.5,1,0.9
		c0.7,1.4,1.2,2.9,1.9,4.3c0.1,0.4,0.2,0.9,0.2,1.4C763.2,307.5,763,308.1,762.3,308.5z"/>
	<path class="st2798" d="M760.3,300.6c-0.3-0.1-0.5-0.1-0.8-0.2c0.1-0.3,0-0.6-0.2-0.9c-0.3-2.9-2.4-5.1-3.3-7.9
		C761,291.5,762.8,295.4,760.3,300.6z"/>
	<path class="st2649" d="M762.3,308.5c0-0.7,0.1-1.3,0.1-2c1.7-0.4,2.1,1,2.7,2c0.2,0.5,0.3,1,0.4,1.5c0.1,1.6,0.3,3.1-1,4.4
		c0,0-0.2,0.2-0.2,0.2C763.7,312.5,763,310.5,762.3,308.5z"/>
	<path class="st2798" d="M771.7,329.4c-0.1,0.7-0.2,1.4-0.3,2.1c-2,1.5-1.5,4.3-3.6,6.4c-0.5-6.4-0.9-12.4-1.3-18.4
		c0.3,0,0.5,0,0.8-0.1c2.2,2.3,1,5.3,1.7,7.2c-0.1-2.4-0.7-5.5-0.8-8.6c0-2.4-0.7-5.1,1.7-6.9c0.6-0.2,1,0,1.5,0.3
		C771.5,317.4,771.6,323.4,771.7,329.4z"/>
	<path class="st2798" d="M764.6,314.4c0-1.6-0.1-3.3-0.1-4.9c4,2.5,4.7,5.8,2,10c0,0,0,0,0,0C764.6,318.2,766.2,315.7,764.6,314.4z"
		/>
	<path class="st2799" d="M447.8,161.7c-1.8-5.9,3.1-9.4,5-14c0.4-0.8,1.2-1,2-1.1c1.6,0.1,2.7-1.1,4.1-1.5c1.6-0.4,3.1-0.2,3.5,1.6
		c1.2,4.7,3.1,2.2,5,0.6c1.5-1.2,2.8-3.9,5-0.7c-0.7,7-0.2,7.5,9,10.4c5.1,1.6,5.3,2,3.1,6.9c-1.1,1.5-1.4,3.7-3.8,4
		c-4.3-1.1-8.4-2.3-11.7-5.6c-1.1-1.2-2.9-1.4-4.7-1.1c-4.2,0.7-8.1-1-12.2-1.2C450.4,159.5,449.7,162.1,447.8,161.7z"/>
	<path class="st2800" d="M471.8,146.7c-2-1.8-2.5,0.2-3.2,1.3c-1.3,2.2-3.5,3.4-5.8,3.1c-2.2-0.2-1.1-2.5-1.3-4.1
		c-0.2-1.6-1.5-1.5-2.6-0.9c-1.4,0.8-2.6,2.4-4.2,0.4c4.4-4.9,7.9-10.6,13-14.9c0,1.5,0,3.4,1.8,3.6c2.5,0.3,4.1,1.1,5.2,3.5
		c0.6,1.3,2,0.2,3.1-0.2c3.7,0.9,5.7,3,4.4,7C478.9,148.1,475.4,148.2,471.8,146.7z"/>
	<path class="st2801" d="M810.4,593.2c-2,10.7-4,21.3-6,32c-0.7,6.2-1.5,12.4-2.4,18.6c-0.4,2.9-0.3,5.9,0.9,8.8
		c-0.9,1.7-1.8,0.5-2.7,0c-0.4-0.4-0.7-0.9-0.9-1.4c-0.4-1.2-1.7-2.4-1.5-3.6c0.9-8.5-4-15.8-5-23.8c0.7-2.6,0.1-6.8,3.6-6.7
		c3.9,0.1,1.7,4.6,3.5,7c-1.5-7.4,4.7-12.3,5.8-19c0.8-1.9,0.6-3.9,0.6-5.8c0-0.8,0.1-1.5,0.2-2.3c1.1-2.2,1.2-5.1,4.2-6
		C810.9,591.7,811,592.5,810.4,593.2z"/>
	<path class="st2802" d="M802.3,653.1c-2.4-9.6,0.4-18.7,2.1-28c0,2,0,4,0.1,6.1c-0.5,7.3-3.5,14.4-1,21.8c0.5,1-0.1,1.2-0.9,1.3
		c-0.3-0.2-0.4-0.5-0.4-0.7C802.2,653.2,802.2,653.1,802.3,653.1z"/>
	<path class="st2666" d="M810.4,593.2c0-0.7,0-1.3-0.1-2c-1.3-2.7-1.2-4.8,2-6c0,0,0.1,0.1,0.1,0.1
		C812.4,588,811.8,590.7,810.4,593.2z"/>
	<path class="st2803" d="M802.6,654.2c0.3-0.4,0.6-0.8,0.8-1.2c0.3,0.1,0.6,0.1,0.9,0.2c1.8,2.4,1.2,5.1,1.2,7.8
		c0.1,1.9-0.9,2.5-2.6,2.4c-2.6-1.9-1.8-4.6-1.7-7.1C801.4,655.5,801.8,654.7,802.6,654.2z"/>
	<path class="st2804" d="M751.5,130.7c1.9-1.2,0.1-3.8,2-5c2.6,1.2,1.3,3.2,1,5c0.3,2.7,0.6,5.3,1,8c-1,1.7-0.5,3.6-0.5,5.4
		c0,18.6,0,37.3,0,55.9c0,2.3,0.2,4.7-1.5,6.6c-0.6,0-1.1-0.1-1.7-0.1c-2.5-0.6-2.8-2.8-2.8-4.7c0.1-14.7-0.6-29.3,0.2-44
		c2-5.6,1.2-11.3,1.2-17.1C750.4,137.4,748.6,133.8,751.5,130.7z"/>
	<path class="st2805" d="M750.7,156.5c-0.1,15.3-0.2,30.6-0.3,45.9c0,1.5,0.2,3,1.4,4.1c-0.1,0.3-0.2,0.7-0.3,1
		c-1.7,0.7-2.1-0.8-3-1.6c-1.3-1.3-2.7-2.6-3.1-4.5c0.7-12.4-0.4-24.9-0.4-37.3c0-7.8,1.5-15.5,0.9-23.4c-0.8-3.5-2.4-7.2,1.1-10.3
		c2.8,0.8,3.2,3,3.5,5.4C751.5,142.7,751.3,149.6,750.7,156.5z"/>
	<path class="st2806" d="M753.5,206.6c0.8-2.6,1.1-5.2,1-7.8c-0.1-18.3,0-36.6,0-54.9c0-1.7-0.7-3.7,1-5.2c0,20.8,0,41.5-0.1,62.3
		C755.4,202.9,756.6,205.6,753.5,206.6z"/>
	<path class="st2807" d="M749.4,205.6c0.4,1,1.1,1.6,2.1,1.9c0.1,0.6,0.2,1.3,0.2,1.9c-2.5-1.3-5.1-2.7-7.6-4
		c-2.7-1.4-2.6,0.6-2.7,2.2c-1-1.5-2.1-2.9-3.1-4.4c1.9-3.1,4.3-1.4,6.5-0.6C746.5,203.4,747.8,204.7,749.4,205.6z"/>
	<path class="st2806" d="M754.5,130.7c-0.3-1.7,0.6-3.6-1-5c-0.3-2.9-0.7-5.9-1-8.8c0.7,0.3,1.3,0.5,2,0.8
		C754.3,122.1,757.1,126.4,754.5,130.7z"/>
	<path class="st2808" d="M435.8,543.2c6.7-3.4,11.5-9.8,19-12c2.7-1.1,3.7,0.8,4.1,2.8c0.4,2,1.7,3.6,2.6,5.3c0.7,1.8,0.8,3.6,0,5.4
		c-3.5,1.8-2.9-1.9-4.4-3.1c-1.3,1.2-2.8,5.4-4.5,0c-1-3.2-3.4-3.3-5.9-1.7c-2.8,1.8-5.4,3.9-8.8,4.2
		C437,544.1,436.3,543.8,435.8,543.2z"/>
	<path class="st2809" d="M435.8,543.2c0.7,0,1.3,0,2,0c0.3,0.4,0.4,0.9,0.3,1.3c-2.6,4-5.4,7.7-10.8,8.1c-1.6,0.1-2.2,1.8-3.5,2.5
		c-2.7,0.4-4.8,3.3-8,2c3.3-2.6,6.7-5.3,10-7.9C429.6,547.9,432,544.4,435.8,543.2z"/>
	<path class="st2810" d="M460.8,539.2c-1.7-1-3.1-2.3-3-4.5c0.1-2.1-0.8-3.4-3-3.5c1.3-1.6,3.1-2.1,5-2c0.8,0.4,1.3,1.2,1.6,2
		c0.5,1.2,0.8,2.5,0.8,3.8c-0.2,1.3-0.4,2.6-1,3.8C461.1,539,460.9,539.1,460.8,539.2z"/>
	<path class="st2811" d="M460.8,531.2c-0.8-0.4-0.9-1.2-1-2c0.1-0.7,0.1-1.4,0.2-2c0.6,0,1.2,0,1.8,0
		C462.3,528.8,463.5,530.5,460.8,531.2z"/>
	<path class="st2812" d="M596.6,868.8c-0.4,1-1.2,2-0.2,3.2c1.9,2.4,0.4,2.7-1.7,2.8c-1.1,0-2.6-0.2-2.9,1.3c-0.8,3.8-3,2.6-5.2,1.6
		c-2.7,0-3.3,3.9-6.2,3.5c-4.6-1.5-8.5,0.6-11.2,6c-3.1,2.7-6.6,0.3-9.8,0.8c-2.2-0.1-4.3,0.2-6.5-0.4c-2.3-0.6-4-1.9-4-4.5
		c0-2.6,1.8-3.8,4.2-4c2.2-0.2,4.3-0.4,5.9-2.2c1.4-1.1,3-1.7,4.6-1.5c5.8,0.7,10.2-1.7,14-5.8c2-2.2,5.4-2.3,7.7-4.3
		c1.8-1.6,2.9-2.9,2.2-5.4c-1.3-4.4,0.8-8.4,1.8-12.6c1.6-4.9,1.6-9.9,1.4-15c2-1.9,3.6-0.3,5.2,0.6c4.5,6,1.7,11.6-1,17.2
		c-0.2,1.7-0.2,1.7,0.8,1.9c0.7-2,0.3-5.2,2.9-5.2c2.5,0,2.1,3.3,2.9,5.1c1.4,3.2,0.9,5.6-3.3,5.7c-1.9,0.2-3.2,1-3.3,3.2
		c0.9-0.7,1.2-1.9,2.3-2.3c2.6-0.9,4.1,0.5,4.8,2.7c0.7,2.2-0.2,3.8-2.6,4.3C598.1,866.2,597.7,867.9,596.6,868.8z"/>
	<path class="st2813" d="M596.6,868.8c0.3-1.8,0.7-3.6,2.9-4c1.7-0.3,2.3-1.3,1.9-2.7c-0.5-1.8-1.4-3.4-3.8-2.3c-0.9-1-0.8-2,0-3
		c3.3,0,5.5-0.5,2.7-4.4c-0.8-1.2-0.8-3-1.2-4.8c-1.9,0.6-1.5,2.2-1.6,3.4c-0.1,1.5,0.4,3.4-2,3.7c-1.3-1-0.8-2.9-2-4
		c2.8-5.8,5.4-11.5,2-18c-0.6-2.3,0.5-3.5,2.7-3.5c2.3,0,2-1.7,2.3-3c1.4-3.5,4.6-3.1,7.2-2.7c2.8,0.4,3,2.7,2.2,5.1
		c-0.5,1.3-1.5,2.2-2.5,3.1c-6.3,6.3-6.3,10.4,0.2,16.6c0.5,0.5,0.9,1,1.1,1.6c0.7,4.3,2.5,5.3,6.2,3.4c1.8-0.9,3.3-2.4,5.4-0.7
		c0.8,0.3,1.5,0.7,2.3,1c1.5,0.6,3.6,0.6,3.5,2.8c-0.1,2-2,2.5-3.5,3c-1.4,0.5-2.4,0.8-3.6,2.1c-1.8,2-4.3,3.3-7.4,3.2
		C606.4,864.6,601.5,866.8,596.6,868.8z"/>
	<path class="st2814" d="M580.7,880.8c2.1-0.8,2.4-5.1,6-3c0,1,0,2,0,3c0.9,2,3,4,0,6c-1.3,1-2.7,2-4,2.9c-3.5,0.3-5.5,3.7-8.9,4.1
		c-1.7,0.5-3.3,1.2-5.2,1c-4.9-0.6-6.4,0.8-7,5.5c-0.1,0.4-0.6,0.7-1.1,1c-1.7,0.6-3.5,0.7-5.3,0.6c-3-0.2-4.8-1.3-3.8-4.7
		c0.5-1.5,0.3-2.9-0.6-4.2c-0.7-1-0.9-2.2-0.1-3.2c0.9-1,2.1-0.6,3.2-0.1c1.7,0.7,3.3,1.6,5.1,0.5c1.6-0.9,0.3-2.4,0.7-3.5
		c3-0.4,6,1.4,9,0C572.3,884.1,576.1,881.7,580.7,880.8z"/>
	<path class="st2815" d="M620.6,852.9c-2.2-0.1-4,0.8-5.7,2.3c-2,1.8-4.3,0-6.3-0.3c-1.3-0.2-1.5-2.6,0-4c1.8-2.7,4.1-5.1,5-8.3
		c4.3-1.3,3.5,2.4,4.3,4.4c-0.1,2.3-2.1,3-4.2,4.7c7.3-2.7,12.1-8,18.5-10.7c3.2-0.8,5.7-3.2,9.1-3.5c1,0.2,1.8,0.6,2.4,1.5
		c-0.9,3.1-3.5,3.6-6.2,4C631.2,844.9,626.1,849.2,620.6,852.9z"/>
	<path class="st2816" d="M637.6,842.9c2-1.3,4-2.7,6-4c1.4-3.5,4.5-4.3,7.8-4.8c1.4,0.2,2.6-0.3,3.8-0.7c2.3-0.7,4.1-0.1,3.9,2.7
		c-0.1,1.9,0.8,3.1,2.1,4.3c1.7,1.7,1.3,3.3-0.7,4.5c-0.3,0-0.7,0-1,0c-1.8,0-3.6,0-5.3,0c2.3-6.2,1.9-6.5-6.8-5.7
		c-0.2,1.8,1.8,2.2,2.1,3.7c-1.4,0.1-2.9-0.5-4,1c-2.4,1.9-5,2.6-8,2C637.6,844.9,637.6,843.9,637.6,842.9z"/>
	<path class="st2659" d="M637.6,845.9c2.5-1.2,5.1-2.1,8-2c1,2.1,0.1,3.6-1.8,4.3c-2.2,0.8-4.1,1.9-5.7,3.7c-1,1.1-2.3,1.4-3.5,0.2
		c-1.4-1.5-0.3-2.4,1-3.1C636.7,848.3,637.6,847.4,637.6,845.9z"/>
	<path class="st2812" d="M586.7,886.8c2.2-2-1.5-4,0-6c2.6-0.3,4.2,2.9,7,2C591.6,884.6,589.5,886.4,586.7,886.8z"/>
	<path class="st2659" d="M660.6,844.9c0.8-1.7,1.9-3.3-1-4.2c-1.7-0.5-2-1.9-1.9-3.5c0.1-1,1.4-2.2-0.2-3c-1.4-0.7-2.4,0.2-3.2,1.4
		c-1.3,1.8-1.7-0.5-2.6-0.6c-0.7-1.2,0-2.1,0.6-3.1c0.3-0.3,0.7-0.6,1.1-0.8c3.5-1.5,7.2-2.8,9.7-5.9c0.7-0.7,1.6-1.1,2.5-1.2
		c2.1,0.2,3.9-1.5,6.1-0.9c0.8,0.2,1.6,0.4,2.3,0.7c1.1,0.5,3.1,0.3,2.9,2c-0.2,1.6-2,1.4-3.3,1.3c-4.1-0.3-4.3,1.4-1.9,4
		c1.6,1.8-0.5,5.3,3,6.1c-2.1,2.7-6,3-8,6C664.5,843.5,662.6,844.2,660.6,844.9z"/>
	<path class="st2817" d="M820.3,532.2c-1.5,3.8-1.6,7.9-1.8,11.8c-0.2,3.1-0.8,6.1-1.1,9.2c-0.3,0.3-0.7,0.4-1.1,0.3
		c-4.1-0.4-4.3-3-3.4-6.1c1.8-3.8,0.9-7.8,1.2-11.7c2.1-4.5,1.6-9.3,1.8-14.1c2.4-1,0.9-3.1,1.3-4.7c0.1-1,0.3-2,1.2-2.7
		C820.8,520,820.3,526.1,820.3,532.2z"/>
	<path class="st2818" d="M818.4,514.2c0,1,0,2,0,3c-1.3,0.2-1.7-0.7-1.9-1.7c-1.2-5.6-3.7-10.8-4.7-16.4c-1.3-3.8-3.6-7.1-4.7-11
		c-1.8-1-4.1,0.6-5.7-1.3c-0.3-0.4-0.5-0.8-0.7-1.2c-0.5-2.1,0.2-3.7,2.1-4.8c0.8-0.5,1.6-0.8,2.5-1.1c0.5,0,0.9,0.1,1.2,0.4
		c1.6,2.4,3.3,4.7,4.9,7.1C814.3,496.1,817.9,504.8,818.4,514.2z"/>
	<path class="st2819" d="M816.4,553.2c0.3,0,0.7,0,1,0c-1.2,7.1-2.3,14.2-3.6,21.2c-0.7,3.5-1.7,7-1.4,10.7c-1.4,1.8-1.9,3.8-2,6
		c-1,2-2,4-3,6c-4.2,2.1-4.7-1.9-6.5-3.9c-0.3-0.7-0.5-1.5-0.5-2.3c1-3.5,1.8-7,1.9-10.7c0.1-2.2,0.8-4.5,3.2-5.1
		c2.3-0.5,2,2.5,3.6,3.4c2.2-5.3,3.1-10.8,3.4-16.5C814.4,559.4,813.5,555.5,816.4,553.2z"/>
	<path class="st2820" d="M772.5,294.5c-0.3-3.7-0.7-7.3-1-11c0.9-0.5,0.1-2.7,2.1-2c1.4,2.5,0.7,5.2,1,7.8
		C775.2,294.1,775.2,294.1,772.5,294.5z"/>
	<path class="st2821" d="M772.5,294.5c3.3-0.7,1.4-3.5,2.1-5.2c1.6,3.7,0.7,7.5,0.7,11.2c-0.9,1.7-1.8,1.5-2.7,0
		C772.5,298.5,772.5,296.5,772.5,294.5z"/>
	<path class="st2822" d="M773.6,281.5c-0.7,0.7-1.4,1.3-2.1,2c0-3.7,0.1-7.4,0.1-11.1c1,0.4,0.8,1.4,1,2.2
		C773.3,276.8,773.7,279.1,773.6,281.5z"/>
	<path class="st2823" d="M772.6,300.5c0.9,0.7,1.8,0.7,2.7,0c0.1,0.3,0.2,0.6,0.3,1c-0.1,0.6-0.1,1.3-0.2,1.9
		c-0.9,0.1-1.9,0.2-2.8,0.2C772.6,302.6,772.6,301.5,772.6,300.5z"/>
	<path class="st2824" d="M559.7,886.8c0.3,1.2,3.5,0.7,1.9,3.1c-1.3,2-3,3.1-5.4,2.5c-1.1-0.3-2-1.1-3-1.7c-0.7-0.4-1.5-1.1-2.1,0
		c-0.5,0.8-0.1,1.6,0.9,2c2.2,0.8,2.2,2.5,1.2,4c-2.6,3.7-0.5,4.2,2.6,4.1c1.7,0,3.3,0,5,0c0.9-0.5,0.9,0.1,0.9,0.7
		c-0.7,0.4-1.3,0.8-2,1.2c-2.1,0.9-4.3,1.6-6.3,2.8c-8.2,5-17.5,0.5-18.6-8.5c-0.1-0.8-0.6-1.5-0.9-2.2c0-15.3,0-30.6,0.1-45.9
		c1.2-2.7-0.5-6.2,2.4-8.4c3.4,0.9,1.8,2.4,0.6,3.8c4.2-5,4.3-5,7.5,1.7c0.2,3.9-1.6,8,1.8,11.4c-1.3,6.2,1.7,11.1,5,15.8
		c0.6,1.1,0.6,2.4,1.4,3.5c0.6,0.6,1.3,0.8,2.1,0.8c1.7,0.2,3.5-1.1,5.1,0.3c-1,3.3-4.1,2.5-6.3,2.6c-2.1,0.1-3.3,0.9-3.5,2.6
		c-0.3,2.1,1.3,3.4,3.3,3.7C555.3,887,557.5,886.8,559.7,886.8z"/>
	<path class="st2825" d="M393.8,369.3c-1.6,2.1-2.2,5.6-6.1,5c0.2-3.3,0.9-6.4,3.1-9c0.7-0.4,1.2,0.1,1.6,0.6
		C393.2,367,392.7,368.5,393.8,369.3z"/>
	<path class="st2826" d="M391.9,366.5c-0.3-0.4-0.7-0.7-1-1.1c0.7-2.8,3.9-3.5,5.1-5.9c4.9-2,9.8-3.2,14.9-1c0.3,0.4,0.3,0.8,0,1.3
		c-2.9,1.4-5.9-0.9-8.8,0.2c-0.4,0.2-0.8,0.3-1.2,0.5c-3.1,0.9-5.1,3.2-7.1,5.5C393.2,366.4,392.6,366.8,391.9,366.5z"/>
	<path class="st2711" d="M771.4,311.5c-0.3,0-0.6,0-0.9,0c-1.7-1.6-1.1-3.8-1.8-5.7c-0.2-0.7-0.3-1.5-0.3-2.2c0-0.7,0.1-1.5,0.3-2.2
		c0.7-2.5,0.9-5.1-0.1-7.6c-2.5-9.2-3.1-18.8-5.3-28.1c-0.8-6.9-4-12.9-6.5-19.2c-1.3-1.6-2.4-3.4-2.8-5.5c0-1.6-0.3-3-1.5-4.1
		c-0.6-0.8-1-1.6-1.3-2.5c-1.3-3.2-4.2-6-1.6-9.8l0.1-0.1c1.1-0.5,1.6,0.3,2.2,1c0.7,1.8,2.2,3.2,2.8,5.1c1,1.2,2,2.5,3,3.7
		c1.4,2.7,2.4,5.7,4.1,8.3c0.7,1.1,1,2.2,1.2,3.5c0.4,4.5,2.1,8.6,3.2,12.9c1.3,4.8,3.2,9.6,2.4,14.8c-0.2,5.6,1.4,11.1,2.2,16.6
		C771.7,297.3,771.4,304.4,771.4,311.5z"/>
	<path class="st2827" d="M768.4,273.7c-1.8-9.5-5.8-18.6-6.8-28.3c4.5,2.9,5.8,7.6,6.7,12.5c0.9,4.9,3.6,9.4,3.5,14.5
		C770.6,272.9,768.6,271,768.4,273.7z"/>
	<path class="st2828" d="M598.6,74.8c-4.2-4.3-9.2-3.5-14.2-2.2c-0.9,0.2-1.8,1.5-2.5,0.2c-0.7-1.2,0.3-2.2,1.3-2.8
		c2.7-1.5,6-1.8,8.3-4.2c0.3,0,0.7,0,1,0c7.3,0.5,14.4-1.5,21.6-1.5c1.6,0,3.2,0,4.8,0c2.6,1.1,5,2.4,7.9,2.6
		c1.6,0.1,3.3,0.8,4.3,2.3c1.3,2,1.5,3.9-1.8,3.9c-3.1,0-6.3-0.2-10.2,0.1c2.4,1.8,4.3,1.3,6.3,0.9c3.9-0.8,7.2,0.2,9.4,3.8
		c-0.3,1.7-1.6,1.7-2.9,1.7c-3.5-1.2-6.7,1.4-10.1,0.7c-3.5-2-6.9-0.2-10.3,0.5c-1.2,0.3-2.3,1.3-3.6,1.3
		C604.8,79.6,601.3,77.8,598.6,74.8z"/>
	<path class="st2829" d="M634.6,77.8c-3.5-1.6-6.9-4.5-11-1.6c-0.2,0.2-0.7,0.1-1,0c-2.2-0.9-6.1-0.5-6-3.1c0.1-2.3,3.6-1.1,5.7-1.3
		c2.9-0.2,5.9,0,9,0c-0.7-3.7-3.8-3.8-6.4-3.9c-2.4-0.1-4.1-0.9-5.2-3.1c4.3-1.4,8.1,2.1,12.4,1c3.6-0.9,7.1-0.3,10.5,1
		c4.3-0.3,8.1,1.5,12,3c0.6,1.5-1.1,1.1-1.4,1.6c2.5-0.6,4.9,0.3,7.4,0.3c0.7,1.1,1.9,0.9,3,1c0.4,0.7,0.8,1.4,1.3,2
		c1.1,1.2,3.7,2,2.3,4.1c-1.1,1.7-3.2,0.9-4.9,0.1c-0.8-0.3-1.6-0.4-2.4-0.5c-4.2-0.3-8.2,0.5-12.3,1.2c-2.5,0.6-4.9,1.7-7.6,0.9
		C638.1,79.9,636.1,79.3,634.6,77.8z"/>
	<path class="st2830" d="M639.6,79.7c2.8,0.5,5.3-0.9,8-1c4.2-0.6,8.3-1,12.5-0.5c2.9,0.9,5.7,1.5,8.7,0.4c2.4-0.8,4.9-0.2,7.4,0.5
		c1.7,0.5,3.4,1.5,5.3,0.7c0.7,0.3,1.4,0.6,2.1,0.9c0.7,2.6,3.7,1.3,5,3c-3.4-0.4-7,0.9-10.3-1.3c-0.7-0.5-1.3,0.6-1.7,1.3
		c-0.8,0.9-1.9,0.9-3,0.7c-2.4-1.5-5.1-1.4-7.8-1.3c-12.8-2-25.4-0.5-37.9,1.9c-2.3-0.2-4.9,0.7-6.5-1.9c1.9-4,6-2.7,9.1-3.7
		C633.5,80.5,636.6,77.9,639.6,79.7z"/>
	<path class="st2831" d="M681.5,79.8c-7,0.9-14,0.1-20.9-1.1c-0.3-0.2-0.4-0.5-0.3-0.7c0.1-0.2,0.2-0.3,0.3-0.3
		c2.1-1.3,3.5,2.7,5.9,0.3c-0.5-0.9-1.1-2-2.7-2.3c-3.7-0.7-0.8-2-0.3-3C669.9,74.2,675.3,78.1,681.5,79.8z"/>
	<path class="st2832" d="M642.6,66.8c-7.6-1-15.5,1.1-23-2c-1.7,0-3.3,0-5,0c-0.3-0.2-0.4-0.4-0.3-0.6c0.1-0.2,0.2-0.3,0.3-0.3
		c7.7,0.3,15.4,0.6,23,1.1C639.4,65,641.2,65.4,642.6,66.8z"/>
	<path class="st2697" d="M614.6,63.8c0,0.3,0,0.6,0,1c-2.8,0-5.6-0.2-8.3,0.9c-4.5,1.8-9.1,0.3-13.6,0.1
		C599.9,64.4,607.2,63.8,614.6,63.8z"/>
	<path class="st2831" d="M660.6,71.8c-2,0.4-4,1.5-6.1,0.1c-0.6-0.4-1.9,0.5-1.8-0.8c0-0.5,1.2-0.9,1.9-1.3
		C656.6,70.4,658.8,70.3,660.6,71.8z"/>
	<path class="st2833" d="M389.9,593.2c-1.7-3.2-2.9-0.4-3.7,0.6c-1.6,2.3-3.5,4.2-5.6,5.9c-1.1-1.2-0.6-2.4-0.7-3.5
		c0.9-1.9,4.1-3.4,1-6c4,1,7.7-1.6,8.2-5.8c0.1-0.8-0.1-1.9,1-1.9c4.5-0.1,6.9-2.3,7-6.8c0-0.5,0.4-1,1-0.7c3,1.4,3.9-1.8,5.8-2.7
		c4-2.1,8.9-2.6,12.3-4.9c5.9-4,11.5-3.8,17.7-2.6c1.5,0.3,2.7-1.1,4.2-1.5c7.1-2,14.2-4.5,21.8-2.3c-0.9,3.7-4.3,3.8-7.1,4.6
		c-4.5,1.3-9.4,1.5-13.5,4.1c-5,1.1-9.8,3.2-15,3.2c-2.5,0-4.4,1.2-6,3.1c-3.5,4.1-7.7,6.8-13.1,7.4c-2.4,0.3-3.4,2.1-4.7,3.9
		c-2.4,3.4-4.5,7.7-10,6.4C390.2,593.5,390.1,593.3,389.9,593.2z"/>
	<path class="st2834" d="M387.9,581.1c0.2,2.9-1.1,4.3-4,4C384.9,583.5,386.1,582.1,387.9,581.1z"/>
	<path class="st2835" d="M391.7,577.9c-0.4,2.1-1.4,3.6-3.9,3.3C388.7,579.5,390,578.4,391.7,577.9z"/>
	<path class="st2836" d="M808.3,752.9c-0.6,0.7-1.3,1.4-1.9,2c-2.1,1.9-5,1.6-7.4,3.1c-1.4,0.9-2.3-2.6-3.1-4.3
		c-1.2-2.4,0.5-4,2.4-4.5c2.2-0.6,4.1-1.1,5.1-3.4c0.3-0.7,0.7-1.8,0.4-2.2c-0.6-0.9-1.8-0.8-2.7-0.4c-1.6,0.9-3,2-4.6,2.9
		c-2.5,1.4-4.1,1.1-4.1-2.2c0-0.2,0-0.5,0-0.7c1.3-5,5-9.4,4-15.1c-0.1-0.7-0.2-1.3-0.2-2c0.1-2.3-1.3-3.6-3.3-4.4
		c-3-2-4.5-5.7-8.2-6.9c-2.8-0.7-5.6-1.4-8.3,0.6c-1.3,1.3-2.8,1.8-4.5,1.3c-1.9-4.1-6.2-3.5-9.5-5c0-1.2,0.9-1.8,1.8-2.1
		c4.1-1.7,7.5-4.6,11.6-6.3c5-0.3,10.2,0.2,14.9-2.2c2.2-1.2,4.4-1.9,6.5-0.4c2,1.5,1.5,3.9,1.4,6c-0.1,1.2-0.6,2.2-1,3.3
		c-0.5,1.5-0.6,3.6,0.8,4c7.5,2.2,6.8,9.7,9.2,14.8c2.2,4.7,0.9,10.6-0.2,16C808.2,747.6,808.7,750.2,808.3,752.9z"/>
	<path class="st2679" d="M797.4,726c0,0.7,0,1.3,0,2c-0.7,5.6-2.8,10.8-5,16c0,0,0,0,0,0c-0.7,0.3-1.3,0.7-2,1c0,0,0,0,0,0
		c-1.7-1.9-0.2-3.7,0.2-5.6c1-4.3,3.2-8.4,1.2-12.9c-0.3-0.4-0.5-0.7-0.8-1.1c-0.3-0.6-0.6-1.2-0.8-1.8c-0.6-1.8-1.6-3.4-3.3-4.5
		c-0.7-0.5-1.4-0.9-2.2-1.3c-2.1-0.8-4.2-0.6-6.3-0.2c-0.4,0.1-0.8,0.2-1.3,0.3c-0.9,0.2-1.7,0.3-2.6,0.3c-1.2-0.1-2.7,0.8-3.5-1
		c0-0.5,0.1-0.9,0.4-1.2c1.6-0.2,3.7,0.7,4-2c3.3-0.8,6.7-0.8,10,0c3.8,1,5.2,4.8,8,7C795,722.5,796.4,724.1,797.4,726z"/>
	<path class="st2837" d="M807.4,745c0.4-4.4-1.3-8.8-0.1-13.2c0.4-1.5-0.5-2.8-1.6-4c-1.3-1.3-2.4-2.9-2.3-4.8
		c0.2-3.6-0.6-5.7-4.7-6.6c-3.4-0.7-4.5-4-2.5-7.1c1.1-1.7,1.1-3.4,1.1-5.1c0-1.1,0.2-2.5-1.3-2.9c-1.4-0.4-3.5-0.8-4.1,0.5
		c-1.4,3-3.8,1.9-5.9,2.3c-2.2,0.4-4.7-0.9-6.8,1.3c-1.4,1.4-2.4-1-3.8-1.4c-0.5-1.5,0.8-1.9,1.6-2.6c1.8-0.9,3.6-1.7,4.7-3.5
		c0.6-0.8,1.3-1.4,2.2-1.9c5.4-2.5,10.9-4.6,15.7-8.3c4.2-3.2,7.3-1.8,8.7,3.4c1.1,3.9-0.2,7.9,1,11.8c0,11,0,22,0,32.9
		C809.4,739.1,809.1,742.2,807.4,745z"/>
	<path class="st2838" d="M798.4,680.1c1.3-1,2.7-2.1,4-3.1c1.9-0.7,3.7-1,5,1c0.3,1,0.6,2.1,0.9,3.1c-0.3-0.1-0.6-0.1-0.9-0.1
		c-2.4-1.2-4.2-0.9-6.6,0.8c-6.7,4.8-14.4,8-21.8,11.8c-2.5,1.3-5.1,2.4-7.3,4.1c-4.7,2.8-9.4,5.5-14.2,8.2
		c-13.9,7.3-27.7,14.7-41.4,22.4c-4.5,2.5-8.9,5.7-14.4,6.1c-0.4-0.3-0.7-0.7-0.8-1.2c0.2-2,1.9-2.8,3.2-4
		c16.6-8.1,32.3-17.7,48.7-26.2c12.5-6.9,24.9-13.9,37.7-20.2C793.1,681.5,795.6,680.3,798.4,680.1z"/>
	<path class="st2839" d="M809.4,703c-2.2-3.4-0.4-7.4-1.7-11.1c-1.9-5.2-3.8-6.1-8-2.6c-4.8,3.9-10.3,6.2-16.2,7.7
		c-0.1-0.2-0.2-0.4-0.2-0.7c1.5-4.1,5.7-4.4,8.6-6.3c2.8-1.4,5.6-2.6,8-4.6c2.1-1.3,4.3-2.4,6.3-3.7c0.5-0.1,0.9,0,1.2,0.4
		c-0.9,5.1,3.2,9.4,2.1,14.6C809,698.7,809.4,700.9,809.4,703z"/>
	<path class="st2840" d="M807.4,682.1c-0.3,0-0.6,0-1,0c-3.9-1.2-5.1,3.1-8.1,3.9c-3.5,1.9-7.5,2.8-10.7,5.2
		c-3.4,1.9-6.6,4.2-10.2,5.6c-1.9,0.7-3.9,1.8-6,0.3c2.7-3.2,6.7-4.1,10.1-6c7-3.9,14.7-6.7,20.9-12.1c2.4-2.1,3.8,0.3,5,2.1
		C807.4,681.4,807.4,681.7,807.4,682.1z"/>
	<path class="st2841" d="M806.4,480.3c-0.4,0-0.7,0-1.1,0c-2.4,0.3-2.8-2.2-4.1-3.3c-0.7-0.8-1.3-1.6-2.3-1.9
		c-5.1-1.2-8-4.2-8.1-9.6c0-0.5-0.2-1-0.7-1.2c-3.9-1.1-6.2-3.4-6.6-7.7c-0.1-1.7-1.4,0-2.2,0.1c-3.1,0.5-7-3.3-6.4-6.4
		c0.2-0.9,0.8-1.7,1.6-1.5c2.9,0.7,0.7-2.1,1.8-2.5c3.7,0.9,5.6,4,7.7,6.7c1.5,1.9,3.1,3.3,5.2,4.3c2.4,2.7,4.7,5.3,7.1,8
		C801.2,470.2,804.5,474.9,806.4,480.3z"/>
	<path class="st2688" d="M503.8,138.7c2.9-3.3,0.9-7.2-1.3-8.4c-2.8-1.6-3.1-3.3-2.6-5.5c1-4.5-3.8-4.4-5.1-7.1
		c2.7-4.7,5.4-4.9,9.3-0.8c1.1,1.2,2.6,2,4,2.8c2.4,1.3,4,2.9,3.6,5.8c-0.4,2.8,0.8,4.7,3,6.2c-0.2,4.1-3,6.2-6.3,7.8
		c-1.3,0.3-2.2,1.7-3.7,1.2C503.9,140.3,503.8,139.5,503.8,138.7z"/>
	<path class="st2842" d="M507.8,138.7c3.5-1.1,4.9-4.5,7-7c0.4-0.3,0.7-0.9,1.1-1c4.5-0.4,6.9-3.5,8.9-7c2.3,2.6,4.7-1.6,7,0
		c0,2.7-2.5,3.4-4,4.6c-5.9,4.5-11.2,9.8-17.5,13.8C509.3,141,506.5,141.4,507.8,138.7z"/>
	<path class="st2843" d="M481.8,145.7c0.8-3.6-2.5-4.7-4-7c-0.3-2.3,1.1-3.3,3-3.8c1.8-0.5,2.5,0.6,3.4,2c0.9,1.4,1.9,3.2,4.7,1.7
		c2.5-1.4,7.8,5.1,7.5,8.8c-0.1,1.5-0.4,2.9-0.6,4.4c0.3,0.4,0.4,0.9,0.2,1.4c-2.2,2.3-3.1,6.4-7.5,6.1c-1.1-2.7-1.1-2.7,1.7-4.9
		C484.3,154.6,485.1,148,481.8,145.7z"/>
	<path class="st2844" d="M495.8,152.7c0-0.3,0-0.7,0-1c1.6-0.6,2.2-2.1,2.6-3.5c0.6-1.8,1.1-3.6,3.5-3.5c3.1,0.1,2.9-2,2.9-4
		c1-0.6,1.4-2.2,3-2c-0.5,1.8,2.6,1.2,2,3c0.4,3.6-3.2,4.2-4.8,6.2c-2.6,3.1-4.8,6.7-8.8,8.4c-0.5,0-0.9-0.1-1.4-0.4
		C494,154.5,495.2,153.7,495.8,152.7z"/>
	<path class="st2674" d="M581.7,66.8c-2.1,1.6-4.5,2-7,1.9C576.7,67.1,579.1,66.7,581.7,66.8z"/>
	<path class="st2845" d="M748.5,754c-4.1-1.5-2.4-5.6-3.7-8.9c-2.8,3.3-2.3,7.1-3.2,10.4c-0.3,1.3,0.1,2.4,1.8,1.3
		c1.2-0.7,1.9-0.2,2,1.1c0.1,1.1-0.1,3.3-1,2.8c-4.2-2.2-5,3.6-8.4,2.6c0.2,0.8,0.2,1.1,0.4,1.3c1.1,1.1,4-0.5,3.8,2.1
		c-0.2,2.2-2.5,2.4-4.3,2.3c-2.8-0.2-4.3,0.6-4.7,3.6c-0.3,1.7-1.3,3.5-3.6,3.5c-1.6,0.2-3.2,0.3-4.4-1c-0.7-5.8-3.7-11.8,1.4-17.3
		c1.9-2.1-0.6-2.5-2-3.2c-5.2-2.8-9.7-6.1-9.7-12.9c1.6-2,0.4-4.8,1.8-6.8c0.5-0.2,1-0.3,1.5-0.3c1,0.1,1.9,0.6,2.7,1.2
		c7,5.3,13.2,5.5,20.8,1.3c3.3-1.8,7.1-2.9,10.9-0.5c0.3,0.4,0.7,0.9,0.6,1.3C748.8,742.8,750.8,748.8,748.5,754z"/>
	<path class="st2694" d="M723.5,774.9c1.4,0.1,2.9-0.5,4,1c-1.1,5.8,0,7.8,5.6,9c1.9,0.4,2.5,1.3,2.4,3c0,2,0,4,0,6
		c0.1,3.4,3.4,3.3,5.4,4.4c1.8,1,2.1-1.5,2.5-2.8c0.6-1.5,1.5-3.1,3.1-2.1c1.6,1,1.1,3,0.2,4.5c-1.9,3.2-7.5,4.5-11,2.7
		c-1.5-0.8-3.2-3.5-4.4,0.3c-2.6-3.1-5.9-1.2-8.7-0.9c-3.7,0.4-7.3,1.9-10.9-0.7c-0.6-0.5-2.2,0.3-3.3,0.6c-1.2-1.2-1.9-2.8-3.2-3.9
		c-0.4-1.6,1.3-2.2,1.6-3.7c-2.7-0.4-4.6,2.6-7.2,1.7c-0.4-0.3-0.5-0.8-0.6-1.2c0.4-1.8,2-2.5,3.3-3.5c1.1-0.7,2.4-1.4,3.7-1.3
		c4.1,0.3,1.6-2.1,1.5-3.5c-0.2-2.5,1.4-3.6,3.6-3.9C715.9,780,719.9,778,723.5,774.9z"/>
	<path class="st2846" d="M748.5,754c-0.3-2-1.8-4.1,0.3-6c0.3-0.3,0.6-1.2,0.5-1.3c-3.8-3.8,1.2-6.4,1.2-9.7
		c-0.6-5.3-1.3-10.5,4.6-13.8c2.3-1.3,4.6-1.4,7.1-1.6c0.8,0.2,1.3,0.7,1.7,1.4c0.1,0.7,0.2,1.4-0.2,1.9c-3.9,4.9-6,10.9-9.8,15.8
		c-2.2,1.8-1.9,4.3-2,6.8c0,1.8,0.2,3.6-0.5,5.3c-0.4,0.7-0.9,1.3-1.8,1.1C749.1,754,748.8,754,748.5,754z"/>
	<path class="st2847" d="M753.4,740c2.7-6.1,6.3-11.5,10-17c1.9-3,3.8-6,8-6c1,1.1,2.2-0.4,3.2,0.2c0.1,2-1.6,2.6-2.8,3.6
		c-2.8,1.7-5.3,4-7.3,6.6c-0.4,0.5-0.8,1-1.3,1.4c-1.2,1.2-2.1,2.6-2.9,4.1c-1.1,2.1-2.3,4.3-2.9,6.6c-0.5,1.3-0.9,2.7-1.2,4
		c-2.8,5.9-0.4,11.5,0.9,17.1c-0.2,2.5-0.9,4.8-1.7,7.1c-0.7,1.5-2,2.7-2.7,4.2c-2.6-2.9-1-5.4,0.8-7.9c-0.5-4.5-2-8.9-2.4-13.4
		C751.1,746.9,750.7,743.1,753.4,740z"/>
	<path class="st2848" d="M751.5,751c0.9,4.3,4.6,8.2,2,13c-3.8-2.3-6.8-5-4-10c0.3-0.3,0.7-0.7,1-1
		C751.2,752.5,750.8,751.4,751.5,751z"/>
	<path class="st2845" d="M750.8,774.5c0.4,1.2,0.3,2-0.4,2.1c-0.5,0.1-1.5-0.2-1.7-0.5c-1.1-3.2-2.2-6.3-3.1-9.6
		c-0.3-1,0.9-1.7,1.9-1.8c1.3-0.2,1.7,0.8,1.8,1.8C749.9,769.3,750.4,772.2,750.8,774.5z"/>
	<path class="st2847" d="M757.6,765.2c0.3,0.9,0.6,1.9,0.8,2.8c-0.9,0.3-1.9,0.7-2.8,1c-0.2-0.1-0.4-0.3-0.6-0.4
		c-0.2-1.5,0.3-2.7,1.3-3.7C756.9,764.7,757.3,764.8,757.6,765.2z"/>
	<path class="st2849" d="M752.6,771.8c0-1.6,0.8-2.9,1.7-4c0.6,0.1,0.7,0.7,0.9,1.1c0,0,0.4,0,0.4,0
		C754.6,769.9,753.6,770.9,752.6,771.8z"/>
	<path class="st2850" d="M705.3,795.8c3-0.1,3.5,1.7,3.3,4.2c-0.3,0.3-0.7,0.7-1,1c-0.3,0.3-0.7,0.5-1.1,0.5
		c-0.8-0.1-1.7-0.3-2.5-0.4c-3-1.1-5.4,2-8.4,1c-0.3-0.4-0.4-0.8-0.4-1.3c0.9-2.5,3.4-3.3,5.4-4.6
		C702,795.5,703.5,794.5,705.3,795.8z"/>
	<path class="st2851" d="M706.5,800.9c0.3,0,0.7,0,1,0c0.2,2.3-0.8,3.5-3.1,4.2c-1.8,0.5-4,0.5-4.9,2.8c-1.7,0.7-3.3,1.3-5,2
		c-0.3-0.4-0.5-0.8-0.5-1.3C697.2,804.4,701.6,802.2,706.5,800.9z"/>
	<path class="st2852" d="M486.7,493.2c1.6,0.7,3.3,1.5,4.9,2.2c1.3,0.1,2.3,0.8,3.3,1.6c0.2,0.4,0.4,0.7,0.6,1.1
		c0.6,2.6,4.9,2.3,4.3,5.8c-1.3,4.8-1.7,9.3,2.1,13.3c1,1.1,1.9,2.3,1,4c-1,0.6-2,0.1-2.7-0.5c-6.2-6.3-12.8-12.4-15.8-21.1
		c-0.6-1.7-1.1-3.5-0.4-5.3C484.5,493,485.4,492.4,486.7,493.2z"/>
	<path class="st2853" d="M486.7,493.2c-3.1,1.1-2,3.4-1.3,5.3c3.2,9.5,10.2,16.2,17.4,22.7c2.3-0.5,3.2,1.5,4.7,2.6
		c1.1,1.4,3.2,2.4,2.3,4.8c-0.9,0.8-2.2,1.5-3.1,0.5c-4.6-5-12.4-6-15.7-12.6c-5.4-5.6-7-13.1-9.9-20c-0.8-1.8-0.1-3.9,1.8-5.2
		C484.3,491.4,485.7,492,486.7,493.2z"/>
	<path class="st2854" d="M494.6,497.4c-1.3-0.2-2.4-0.8-3-2c0.4-0.4,0.7-0.7,1.1-1.1c3.4-1,0-4.4,2-5.9c0.4-0.3,0.7-0.7,1.1-1
		c0.7-0.3,1.3,0,1.9,0.4c5,2.8,8.7,7,12.5,11.2c0.7,1.1,1.6,2.2,2.3,3.4c0.9,3.9-1.5,2.9-3.3,2.1c-2.4-1-4.5-2.6-7.3-2.5
		c-1,0-1.8-0.4-2.6-1C497.9,499.6,496.7,497.9,494.6,497.4z"/>
	<path class="st2855" d="M482.8,491.3c-2.4,5.7,1.6,10,3.3,14.8c1.3,3.6,3.8,6.8,5.7,10.2c0.2,1.5-0.9,1.6-1.9,1.9
		c-6.5-2-9.1-6.4-8.2-14c0.3-0.6-0.8-0.1-0.1,0.1c0.2,0,0.1,0.2,0,0.5c-0.9,2.7-2,1.9-2.7,0c-1.1-2.8-1.1-5.8-1.9-8.7
		c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.3,0-0.7,0.1-1C479.4,494.4,479,488.7,482.8,491.3z"/>
	<path class="st2856" d="M494.8,488.3c-0.5,2.2,1.1,3.4,2.9,5.1c-2.1,0.4-3.5,0.6-4.9,0.9c-0.7-1.3-1.7-2.3-3-3
		c1.4-1,2.8-2.1,4.2-3.1C494.3,488.1,494.5,488.2,494.8,488.3z"/>
	<path class="st2857" d="M473.8,506.3c0.3-4.3-2.1-0.3-2.9-1c0.4-1.3,0.7-2.6,1.1-3.9c2.5-1.3,2.2-4.9,4.8-6.1c0,0,0.1,0,0.1,0
		c1.2,2.4,0.6,5.1,0.7,7.6C477.6,504.8,477.1,507.4,473.8,506.3z"/>
	<path class="st2858" d="M598.6,74.8c3.7,1.5,7.3,3,9,7c0.8,2.8-1.3,3.6-3.2,4.5c-2.4,1.2-5.2,1.8-7,4.2c-0.5,0.5-1.2,0.9-1.9,1.2
		c-5.3,0.6-9.3,5-14.7,5.2c-5.4-0.2-9.8,2.4-14.4,4.8c-2.1,1.1-4.2,3.1-6.8,1.1c-1.2-4.8,3.9-3.4,5.7-5.3c1.3-1.4,3.3-1.7,2.9-4.3
		c-0.3-2,1.5-1.5,2.4-1.4c4.5,0.4,7.6-1.1,9.5-5.4c0.6-1.3,1.6-3.4,4.2-0.5c1.6,1.8,5.4,0,7.3-2.9C593.7,80,595.3,76.7,598.6,74.8z"
		/>
	<path class="st2859" d="M559.7,102.7c4.4,0,7.3-3.5,11.2-4.8c3.2-1.1,6.2-3,9.8-2.2c0.4,2-1,2.7-2.4,3.2c-5.5,2.3-10.7,5.1-16,7.8
		c-1.5,0.8-3.1,1.3-4.7,0.2c-0.5-0.8-0.4-1.5,0.1-2.2C558.1,103.8,558.7,103.1,559.7,102.7z"/>
	<path class="st2860" d="M535.7,120.7c-0.7-2.7,2.3-3.2,3-5c4.1-3.4,8.9-5,14-6c0.3,0.4,0.3,0.9,0.2,1.3c-0.3,0.6-0.9,1.1-1.5,1.5
		c-3.8,1.8-7.3,4.1-10.9,6.4C539.1,119.8,537.8,121.4,535.7,120.7z"/>
	<path class="st2858" d="M552.7,110.7c0-0.3,0-0.7,0-1c-2.4-5.8,3.7-3,5-5c0,0.7,0,1.4,0,2.1C557.2,109.6,555.7,111.1,552.7,110.7z"
		/>
	<path class="st2861" d="M535.7,120.7c5.3-3,9.7-7.7,16-9c1.4,0.4,3-1.2,4.4,0.3c0.5,2.5-1.7,2.8-3.1,3.8c-4,2.9-9.2,3.7-12.6,7.6
		c-5,4.9-11.1,8.5-17,12.1c-1.5,0.3-2.6,1.1-3.2,2.5c-1.4,1.6-3.3,2.7-5,4.1c-1.5,0.7-3,1.3-3.9,2.9c-0.4,0.5-0.9,1-1.4,1.3
		c-1.9,1.1-3.4,2.7-4.5,4.6c-0.7,0.9-1.5,1.7-2.4,2.4c-2.5,1.2-3.7,4-6.6,4.7c-1-0.5-1.4-1.2-0.6-2.2c4.7-4.7,9.3-9.4,14-14.1
		c6.9-6.5,14.6-12,22-18C533.1,122.7,534.4,121.7,535.7,120.7z"/>
	<path class="st2697" d="M550.1,83.9c0.7,0,1.3,0.3,1.3,1.1c0,0.7-0.6,1.2-1.3,1.1c-0.4-0.1-0.8-0.5-1-0.9
		C548.9,84.4,549.3,84,550.1,83.9z"/>
	<path class="st2862" d="M726.5,105.6c2.4,2.3,5.1,4.3,7,7.1c0.9,1.4,2,2.7,3.1,4c0.6,0.7,1.3,1.4,1.9,2.1c0.3,0.4,0.4,0.9,0.2,1.4
		c-3,2.3-7.7,0.3-9.8-4.2c0.6-1.8,0.9-3.5-1.1-4.8c-3.3-2.2-5.6-5.5-9-7.5c-3.9-3.7-9.3-3.9-14-6c-1.4-1-3.8-1.4-3.2-4
		c5.2,0,9.2,3.1,13.7,5c1.6,0.7,3.3,1.4,4.9,2.2C722.3,102.5,724.4,104,726.5,105.6z"/>
	<path class="st2863" d="M701.6,93.7c0.5,2,2.6,2.1,3.8,3.2c5.4,4.6,9.4,10.3,12.8,16.4c1.5,2.4,2.8,4.9,3.9,7.5
		c0.2,0.7,0.3,1.4,0.3,2.2c-0.1,0.7-0.3,1.4-0.9,2c-3.5-0.6-2.7-4.7-5-6.3c-4.6-8.3-11.5-15-16.8-22.8c-0.2-1-0.2-2,0.7-2.8
		C700.8,93.2,701.2,93.5,701.6,93.7z"/>
	<path class="st2864" d="M677.6,84.7c3.6,0.7,7.3,1.4,10.9,2.1c0.1,0.6,0.1,1.2,0.2,1.8c-0.2,0.2-0.3,0.4-0.5,0.6
		c-2.9,1-5.8-0.3-8.6-0.5c-3-1-6.5-0.6-9.2-2.8c-0.2-0.6,0-1.1,0.5-1.5C673.2,83.8,675.4,84.2,677.6,84.7z"/>
	<path class="st2865" d="M700.4,93c0.1,0.9,0.1,1.9,0.2,2.8c-5.3-0.3-10.1-1.9-14.3-5.1c0-1.6,1.3-1.4,2.3-1.4
		c3.6-0.4,6.7,1.2,9.9,2.5C699.2,92.2,699.8,92.6,700.4,93z"/>
	<path class="st2866" d="M698.5,91.8c-3.3-0.7-6.6-1.4-9.9-2.1c-0.4-0.1-0.5-0.3-0.4-0.5c0.1-0.2,0.2-0.3,0.3-0.3
		c0,0,0.2-0.3,0.2-0.3C692.2,88.8,695.9,88.9,698.5,91.8z"/>
	<path class="st2867" d="M677.6,84.7c-2.4,0.1-4.7,0.2-7.1,0.2c-1.4,1.2-3,1.3-4.7,1c-11.2-2-22.3,0.1-33.3,1.6
		c-4.4,0.6-8.8,1.1-13.2,1.9c-1.5-0.2-3.4-0.4-3.5-2c-0.1-2,2.1-2.2,3.6-2.7c2.7-0.7,5.6,0,8.2-1c12.9-2.9,25.9-3.9,39-2
		c2.1,1.7,4.9,0.3,7,1.9c1,0,2,0,2.9,0C676.9,84,677.3,84.3,677.6,84.7z"/>
	<path class="st2868" d="M792.3,725c4,5.3,0.3,10.2-0.7,15.3c-0.3,1.6-1.4,2.9-1.3,4.7c-3.3,4.6-5.4,10.3-10.9,13
		c-0.4,0.4-0.8,0.4-1.2,0c-0.3-2.9,2.4-3.9,3.5-5.9c3.4-4,5-8.9,7.1-13.5c1.4-1.5,1.3-3.5,1.8-5.3
		C791.7,730.6,790.2,727.5,792.3,725z"/>
	<path class="st2869" d="M772.4,765c0.1,0.9,0.4,1.6,1.3,1.9c1,0.4,1.9,0.9,1.3,2.1c-0.4,0.9-1.3,1-2.1,0.9
		c-2.1-0.3-4.3-0.4-6.3-1.2c-0.9-0.4,0.7-1.1,0.8-1.8C768.6,764.9,770.2,764.2,772.4,765z"/>
	<path class="st2870" d="M772.4,765c-1.7,0.7-3.3,1.3-5,2c-0.6-1.2-2-0.7-3.1-1.3c0.7-1.2,2.5-0.2,3.1-1.6c2.5-1.5,4.8-3.3,8-2.1
		C774.3,762.8,776.2,766.7,772.4,765z"/>
	<path class="st2871" d="M775.4,761.9c-2.7,0.4-5,2.6-8,2.1c0,0,0,0,0,0c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.3,0.1-0.4,0.1-0.4
		c0.6-0.7,1.4-1.1,2.1-1.5c0.4-0.2,0.8-0.4,1.2-0.5c1.7-0.6,3-1.7,4.6-2.5c1-0.4,2-1,3.2-0.4c0.4,0,0.7,0,1.1,0.1
		C778.1,759.3,776.8,760.6,775.4,761.9z"/>
	<path class="st2847" d="M767.5,763.1c0,0.3,0,0.6-0.1,0.9c-3.4-0.8-6.5,1.4-9.8,1.2c0,0-0.1-0.2-0.1-0.2c-0.6-1.6,0-2.7,1.3-3.5
		C761.8,761.6,764.9,760.9,767.5,763.1z"/>
	<path class="st2872" d="M694.5,808.8c0,0.4,0,0.7,0,1.1c-3.8,3.9-9.5,4.8-13.3,8.6c-0.5,0.5-1.2,0.1-1.7-0.5
		c-0.4-0.4-0.5-0.8-0.5-1.3c1.3-3.5,4.9-3.9,7.6-5.5c1.6-1,3.3-2.1,5.1-2.8C692.7,808,693.7,808,694.5,808.8z"/>
	<path class="st2873" d="M679.5,816.8c0,0.4,0,0.7,0,1.1c-2.7,1.7-5.3,3.3-8,5c-1.8,1.3-3.7,2.4-6,2
		C668.8,819.7,673.4,816.9,679.5,816.8z"/>
	<path class="st2659" d="M678.1,832.5c-0.8,0-1.2-0.2-1.1-0.8c0.1-0.3,0.7-0.6,1.1-0.7c0.2-0.1,0.5,0.3,0.7,0.5
		C678.6,831.9,678.3,832.3,678.1,832.5z"/>
	<path class="st2874" d="M451.8,158.7c5.8,1.8,12.1,0,17.8,2.4c3.5,1.5,5.6,6.2,10.6,4.4c0.6-0.2,0.5,1.4,0.6,2.1
		c0.8,5.4-2.7,7.9-6.8,9.9c-1,0.1-2.4-0.1-2.7-0.9c-1.9-5.6-7.1-5.7-11.5-7c-2.5,0.8-4-1.5-6-2c-1.7-0.8-3.7,1.7-5.4-0.3
		C449,164.2,450.5,161.5,451.8,158.7z"/>
	<path class="st2875" d="M466.8,191.5c1.8-0.6,2.2-2.5,3-3.9c0.7-0.7,1.3-1.3,2-2c0.4-0.4,0.8-0.6,1.3-0.7c4.7,1.4,0.9,3.9,0.8,5.9
		c-1.8,3.9-3.7,7.8-6.4,11.2c-3.2,5-7.5,9.3-9.6,15c-2.8,1.7-2.9-0.5-3.3-2.3c-0.1-1.9,0.5-3.6,1.3-5.2c2.6-5.4,5.7-10.6,8.5-16
		C464.9,192.6,465.2,191.3,466.8,191.5z"/>
	<path class="st2706" d="M459.8,169.7c2.1,0,4.3,0.1,6.4,0c2.6-0.2,4.2,0.9,4.5,3.4c0.3,2.7,1.5,3.8,4.1,3.6c0.6,1.3,0.7,2.6,0.7,4
		c-0.2,0.7-0.6,1.2-1.1,1.7c-0.8,0.5-1.6,0.9-2.5,0.3c-0.1-0.1-0.2-0.3-0.3-0.4C468.6,177.1,465.6,172.1,459.8,169.7z"/>
	<path class="st2876" d="M451.8,158.7c0.9,3.6-1.1,6.3-3,9c-0.1,0.6-0.4,0.9-1,1c-1.8,1.7-3.2,1.6-4-1c1.3-0.6,2.5-1.8,1.8-2.9
		c-2-3.2,1.9-1.8,2.2-3C449,160.5,449.2,158,451.8,158.7z"/>
	<path class="st2706" d="M447.8,168.7c0.3-0.3,0.7-0.7,1-1c1.7-0.7,3.3-1.9,5,0C452,169.4,450.4,171.7,447.8,168.7z"/>
	<path class="st2877" d="M471.8,182.6c0.7-0.3,1.4-0.6,2-1c1.2,1.8,0,2.8-1.1,3.9c-0.3,0-0.6,0-0.9,0
		C471.8,184.6,471.8,183.6,471.8,182.6z"/>
	<path class="st2708" d="M462.8,191.6c-8.1-3.5-16.6-1.8-24.9-1.9c-0.3,0-0.7,0.1-1,0.2c-4.7,2.8-4.9,2.7-5.1-3.8c0-0.2,0-0.3,0-0.5
		c3-3.6,6.2-4.2,11.1-3c4.7,1.2,10-0.4,15.1,0.6c2.3,0.4,4.4,1,3.7,3.9c-0.6,2.7,3.1,1.3,3,3.4C464.4,191.5,463.9,192.1,462.8,191.6
		z"/>
	<path class="st2878" d="M462.8,191.6c0.7-0.3,1.3-0.7,2-1c1-0.4,2-0.9,2,0.9c-3.3,6-6.6,12.1-10,18.1c-0.4,0.3-0.8,0.3-1.2,0
		c-0.8-1.7-0.8-3.6-0.9-5.5c0-2.2,1.3-3.6,3.1-4.5C457.4,195.7,461,194.2,462.8,191.6z"/>
	<path class="st2879" d="M457.8,199.6c-0.9,1.6-2.4,2.9-2,5c-1.2,2.2-3.2,2.1-5.1,1.6c-8.4-2.4-16.6-0.7-24.7,1.6
		c-1.3,0.5-2.3,2.5-3.8,1.7c-1.5-0.8-0.9-2.5-0.3-3.9c0.4-5.4,2.6-10.3,5-15c1.1,0.1,1.8,1.2,3,1c2.5,2.5,3.6,6.7,8,7.4
		c0.2-6.1,0.4-6.2,6.2-4.2C448.6,196.4,453.5,197.2,457.8,199.6z"/>
	<path class="st2708" d="M429.9,191.6c-2.1,2.7-2.1-0.4-3-1c0.4-1.3,0.8-2.6,1.1-4C431.3,187.3,430.1,189.6,429.9,191.6z"/>
	<path class="st2880" d="M417.9,220.6c0.9-2.3,0.4-4.9,1.9-7c1.8,0,2.6,1.2,3.3,2.6c2.9,5.5,6.6,6.3,11.5,2.3
		c1.5-1.2,2.9-2.2,4.9-1.5c4,1.4,6.6,4.7,9.8,7.1c-0.3,3.5,0.4,7.3-3.9,9c-1.6-0.5-1.4-2.1-1.9-3.2c-0.7-1.3-1.6-2.3-3.1-2
		c-4.7,1.2-9.9,1-14.1,3.9c-2.9,2-4.5,1.8-5.9-1.8C419.2,226.9,418.3,223.8,417.9,220.6z"/>
	<path class="st2881" d="M417.9,220.6c0.3,3.8,3.6,6.4,4.1,10.2c0.4,2.8,2.3,1.2,3.2,0.3c4.6-4.6,11-3.4,16.4-4.8
		c2.6-0.7,4.2,3,3.2,6.3c-0.2,1-1,1.6-1.7,2.3c-4.2,4.2-9.3,4.4-14.5,2.8c-3.8-1.1-5.9-1.1-5.8,3.6c0,1.4-0.7,3.3-2.5,3.2
		c-1.7-0.1-1.9-2-2.4-3.4c-1.2-4.2,0.5-8.6-0.6-12.8c0.1-1.2-0.4-2.5,0.4-3.7C417.9,223.3,417.9,221.9,417.9,220.6z"/>
	<path class="st2882" d="M439.8,217.6c-1.8,0-3.6-0.3-4.3,2.2c-0.4,1.4-1.9,1.5-3.2,1.8c-5.4,1.3-10.2,1.9-10.7-5.8
		c0-0.8-1.2-1.5-1.8-2.2c-0.1-2.8,0.6-5.5,2-8c0.2,1.1-0.6,2.7,0.6,3.1c1.3,0.5,1.3-1.7,2.4-2.1c1.2,1.6,2.3,2.4,4.7,2.6
		c4.6,0.4,9.1-1.6,13.6,0.3C444.7,213.3,443.7,216.1,439.8,217.6z"/>
	<path class="st2883" d="M417.9,224.6c0,1.3,0,2.7,0,4c1,3.8,0.2,7.7,0.4,11.5c0,0.9-0.5,1.5-1.4,1.6c-0.9-3-0.8-6.1-0.1-9.1
		c0-2.6,0.1-5.3,0.1-7.9C417.2,224.6,417.5,224.6,417.9,224.6z"/>
	<path class="st2884" d="M735.5,225.6c2.7,0,5.3,0,8,0c0.2,0,0.5,0,0.7,0c1,0.3,2.2,1.2,1.8,2c-2.1,4.2,0.4,8,0.8,12
		c0.5,4.4-0.5,8.4-2,12.3c0,2.1,0.1,4.3,0,6.4c-0.2,3.4,0.4,5.9,4.5,6.1c1.1,0.1,2.4,0.6,2.4,2c0,1.4-1.2,1.9-2.3,2.1
		c-3.3,0.6-6.1,3.6-9.9,2.2c-2.7-0.6-5.1-1.6-6.4-4.2c-0.4-2.3,1-4.1,1.3-6.6c-2,0.7-3.6,2.9-5.8,1.6c-2.4-1.4-1.3-3.8-1.3-5.9
		c0-1.3-0.5-2.6-1-4.3c-0.5,3.3-2.7,4.6-5.3,4.8c-4.7,0.3-9.1,1.9-13.7,2.6c-2.1,0.3-4.8,1-5.3-2c-0.4-2.8,1.8-4,4.4-4.3
		c0.5-0.1,1,0,0.3-0.1c-7.3-2.5-16.1-1.7-24.8-1.6c-4.8,0-9.7,0-11.9,5.9c-0.7,2-2.9,1.5-3.4-0.1c-1-3.5-4.2-3.9-6.5-5.6
		c-0.3-0.2-0.6-0.5-0.9-0.8c-0.8-0.8-1.6-0.9-2.4,0c-0.8,0.7-1.6,1.5-2.7,1.7c-0.5,0-0.9-0.1-1.3-0.4c-1.1-0.9-2-2-2.4-3.4
		c0.4-3.4-1.7-5.3-4.3-6.9c-0.2-0.1,0-0.4-0.2-0.5c0,0.3-0.1,0.6-0.3,0.9c-4.2-0.4-5-3.9-5.8-6.9c-0.6-2.3,1.4-2.5,3.7-2.3
		c-6-1.9-6.8-8.5-11.9-11c-2.4-1.2-3-4.2-4.1-6.4c-1.2-2.4,2.1-1.7,3.9-3.6c-7.4,0.8-7.8-6.3-12.1-9c-0.3-0.2-0.5-0.5-0.6-0.8
		c-0.3-5.6-2.3-11.4,1.2-16.7c0.9-0.4,1.9-0.5,2.9-0.6c2.2-0.3,5.2,1,6.4-1.4c1.5-3,0.8-6.5,0.4-9.7c-0.2-1.6-1.7-1.9-3-2
		c-2.3-0.2-4.6,0-6.9-0.1c-1.4,0-2.7-0.1-4-0.8c-0.5-0.3-0.8-0.7-1-1.2c-0.7-2.6-0.9-5.2-3.2-7.4c-2.7-2.6-0.8-5.4,3.9-6.3
		c-1.6-0.5-3.8-0.2-4-2.8c0.6-0.6,1.3-0.9,2.1-1c1.9-0.2,3.7-0.1,5.6-0.2c7,0.6,13.9,1.9,20.8-0.6c2.5-0.9,4.6,1.4,6.7,2.4
		c1.7,0.4,3.3,0.2,4.8-0.6c3.7-1,7.1,1.2,10.6,1.4c1,0.1,1.9,1,2,2c0.4,3.9,3.2,4.5,6.3,5c0.4,0.2,0.7,0.6,1,0.9
		c0-0.4-0.4-0.7-0.2-1.2c4.1-3.8,5.5-3.7,8.6,0.1c0.4,0.5-0.1-0.1,0.3,0.4c1.2,2,5.4,2,4.4,5.2c-0.9,3-4.4,3.6-7,4.9
		c-7.7,0-15.3,0-22.9-0.1c-4-0.1-5.8,1.3-5.7,5.5c0.2,6,0.2,12,0,18c-0.1,4.1,1.7,5.8,5.6,5.5c0.3,0,0.7,0,1,0
		c8.9,0.2,9.2,0.6,9.4,9.4c0.1,6.2,0.4,6.5,6.5,6.6c1.5,0,3,0,4.5,0.1c3.2,0.2,4.8,1.9,4.9,5.1c0,0.3,0,0.7,0.1,1
		c0.6,9.7,0.6,9.7,10.3,9.7c11,0,22-0.2,32.9,0.1C729.7,232.3,734.2,231.9,735.5,225.6z"/>
	<path class="st2885" d="M735.5,225.6c0,1-0.1,2,0,3c0.5,3.9-1,5.2-5,5.1c-15.3-0.2-30.6-0.2-45.9,0c-3.8,0-5.2-0.9-5.2-5
		c0.2-10.9,0-11.1-11.2-11c-3.8,0-4.7-1.3-4.7-4.9c0.1-11-0.1-11.1-11.2-11.1c-3.7,0-4.9-1.1-4.8-4.8c0.3-7.8,0.1-15.6,0.1-23.5
		c0-1.6-0.3-3.7,2.2-3.7c9.6,0.3,19.2-1.2,28.8,0.9c1.1,0.4,1.7,1.2,2,2.2c0.6,2.8,0.2,5.6,0.4,8.3c0.1,1.4,0.5,2.6,2.2,2.9
		c1.5,0.2,2.8-0.3,3.2-1.7c1.2-4,4.8-6.1,7.3-9.1c4.8,1.3,6.8,3.9,6.4,8.3c-1.1,1.6-4.7,1.2-3.9,4.4c0.8,3.2,0.2,7.3,4.8,8.3
		c1.3,0.3,2.8,0.7,3.2,2.1c1.1,4.2,4.3,4,7.5,3.9c1.8,0,3.6,0,5.5-0.1c6.6-0.4,9.8,3.3,8.4,9.5c1.5,2.6,4.1,2.8,6.8,3.1
		c4.7,0.5,6.4,3.4,4.2,6.9c-0.4,0.4-0.8,0.4-1.2,0.1c-2.9-4.3-3.6-4.3-6.4-0.2c-0.5,3.1,0.7,4.9,4.1,4.9
		C733.9,224.4,734.8,224.9,735.5,225.6z"/>
	<path class="st2886" d="M699.5,181.6c0.2-4.3-3.5-5.7-6-8c-0.6,0.6-1.3,1.1-2.1,1.2c-2.7,0.4-3.4,4.5-6.7,3.5
		c-2.8-0.8-2.8-4.4-5.2-5.7c-0.3-0.7-0.7-1.3-1-2c0.1-2,1.3-2.9,3.2-3c1.1-0.1,2.1-0.5,2.3-1.6c0.2-1.3-1-1.8-1.8-1.9
		c-2.4-0.4-2.9-2-4.1-3.9c-2-3-4.6-0.6-6.6,1.4c-1.6,0-3.3-0.1-4.9,0c-2,0.1-3.3-0.7-3-2.8c0.5-3.8-1.7-4.8-4.7-4.1
		c-2.7,0.6-4.6-3.2-7.3-1.1c-1.7,0.2-3.6-0.6-5,1c-3.2-2.6-6-3.3-10.6-1.6c-5,1.9-10.9,0.4-16.4-0.4c-2-0.8-4-0.8-6,0
		c-0.7,0-1.3,0-2,0c-3.4,2.1-6.9,3-10.8,1.5c-0.5-0.4-0.7-0.9-0.7-1.5c2.3-3.3,6.1-2.7,9.3-3.3c4.8-0.8,9.7-1.3,14.5-1.9
		c10.2,0.1,20.4-0.6,30.6,1.2c3.9,0.9,8,1,11.9,1.7c2.2-0.2,4.4,0,6.6,0.6c2,0.7,4.1,0.9,6,1.9c0.4,0.2,0.8,0.5,1.2,0.7
		c2.4,2.2,5.2,3.7,7.7,5.6c3.2,2.4,6.7,4.4,9.9,6.9c2.6,1.9,5.1,4.2,7.7,6.1c1.6,1.2,2.9,2.6,4.3,4c1,1.1,2.1,2.1,3.4,2.9
		c1.8,1.3,2.8,3.3,4.1,5c0.8,1.3,1.8,2.4,3,3.3c4.4,0.5,7.3,3.1,9.5,6.7c1.1,1.8,2.7,3.4,4.7,4.3c1.1,1.2,2.3,2.4,2,4.3
		c-0.4,0.4-0.8,0.7-1.2,1.1c-5.6,3.1-7.5,2.7-10-2.5c-1.1-2.2-2.7-2-4.5-2.1c-6.2-0.4-6.7-0.7-6.6-7.1c0-3.6-1.4-5.9-5-6
		C705.4,185.7,702.4,183.9,699.5,181.6z"/>
	<path class="st2884" d="M699.5,181.6c4.3,0.4,7.8,3.3,12.3,3.6c2.6,0.2,5.1,2.1,3.8,6.2c-1.4,4.3,0.5,6.8,5.6,6.2
		c2.9-0.3,6.8-1.2,5.5,4.5c-0.4,1.7,2.1,2.5,3.8,2.1c1.6-0.4,3.2-0.8,4.9-0.7c1.1,3.1,0.3,4.9-3.2,3.7c-3-1-5.1-0.2-6.7,2.3
		c-1.3-2,0.3-4.6-3-6.8c-2.8-1.9-4.8-0.8-7-1.1c-2.3-0.4-4.7-0.2-7-0.1c-2.9,0.1-5.4,0.1-5.1-4c0.1-1.8-1.4-1.9-2.8-2.1
		c-3.4-0.4-5.6-2-5.2-5.9c0.1-0.8,0-1.7-0.3-2.4C693.5,182.2,693.6,182.1,699.5,181.6z"/>
	<path class="st2887" d="M736.6,202.5c-0.8-1.3-1.6-2.6-2.4-3.9c-3-2.5-5.8-5.2-7.1-9c0.3-0.7,0.8-1.2,1.5-1.4c4.7,1.4,7,6,10.8,8.7
		c2.4,1.7,6.6,1.6,7.1,5.8c1.3,0.7,2.4,1.6,2.9,3c-3.9,0.2-6.8-4.6-11.1-2.3C737.7,203,737.2,202.8,736.6,202.5z"/>
	<path class="st2884" d="M735.5,219.6c0.3,0,0.7,0,1,0c-1.2,1.9-1,3.9-1,6c-0.3,0-0.6,0-1,0c-7,0-7,0-7-7
		C730.3,217.7,733,217.9,735.5,219.6z"/>
	<path class="st2888" d="M744.5,222.6c2.4-0.2,3.4,0.6,2,3c-0.2,0.2-0.3,0.3-0.5,0.5c-0.9,0.1-1.8,0.2-2.5-0.5c0,0,0,0,0,0
		C743.8,224.6,744.1,223.6,744.5,222.6z"/>
	<path class="st2888" d="M749.5,224.6c-1,3.9,2,6.6,3,10c-0.6,0.6-0.8,1.4-1.2,2.1c-0.7,1.3-1.5,2.8-3.4,2.1
		c-1.5-0.6-1.5-2.2-1.4-3.4c0.5-3.3-0.2-6.5,0-9.8c0,0,0,0,0,0C747.5,225.3,748.5,224.9,749.5,224.6z"/>
	<path class="st2889" d="M751.5,225.6c-0.7-0.3-1.3-0.7-2-1c0-0.7,0-1.3,0-2c2,0.1,2.3,1.7,2.9,3
		C752.1,226.1,751.8,226.1,751.5,225.6z"/>
	<path class="st2890" d="M561.6,901.5c-0.3-0.2-0.6-0.5-0.9-0.7c0.2-0.7,0.9-1.8-0.4-1.9c-3.1-0.3-1.7-1.3-0.5-2.5
		c4.1-4.1,9.2-2.6,14-2.5c-3,2-6,4-9.1,5.9C563.6,900.4,562.6,900.9,561.6,901.5z"/>
	<path class="st2891" d="M778.4,446.3c-0.2,1.8,2,2.1,2.4,3.4c-1.7,1.3-3.3-0.3-5,0c3.1,8,3.6,8.2,8.2,3.3c1.2,0.4,1.4,1.3,1.2,2.4
		c-1,4.4,0.8,6.9,5.2,7.4c1.4,0.2,2.2,0.7,2.2,2.1c0,5.4,1.1,7.1,5.9,8.4c1.7,0.5,2.6,1.9,3.8,3c-2,2.1-0.5,3.6,1,5
		c-1.6,1.3-2.3,2.9-2,5c-0.7,0.5-1.5,0.6-2.3,0.8c-5.6,1.1-6.2,0.6-7.1-5.6c0-1.6-1.2-2.2-2.5-2.8c-1-0.5-2.6-0.9-2.6-2
		c-0.1-5.7-5.1-6.7-8.6-9.2c-3.2-2.3-6.6-3.6-10.6-1.7c-2.4,1.1-4.4,0.5-5.5-2.4c-1.4-3.5-4.8-5.4-7.1-8.1c-0.5-3.1,2.3-3.2,3.9-4.3
		c3.6-2.3,8.7-2.5,10.3-7.7c0.3-1.1,1.8-1.3,3.1-1C775.5,442.1,776.3,445.2,778.4,446.3z"/>
	<path class="st2892" d="M772.4,442.3c-0.6,0.3-1.6,0.6-1.7,1c-1.4,7.9-9.3,6.9-14,10.1c-0.8,0.5-1.2,1.1-1.3,1.9
		c-5.1,5.6-10.3,6.8-15.1,3.4c-1.2-2.9-2.1-6-5.7-7c-0.4-0.3-0.6-0.7-0.7-1.2c4.6-4,9.6-7.2,15.3-9.3c2.2-0.8,4.3-1.7,6.1-3.3
		c1-0.9,2.3-0.9,0.1-2.7c-3.3-2.7,1.4-1.6,2-2.7C763,434.9,768.1,438,772.4,442.3z"/>
	<path class="st2893" d="M757.4,432.4c-0.8,0.5-1.5,1.1-2.3,1.6c0.8,1.7,2.1,0.1,3.1,0.4c0.8,0.2,1.7,0.6,1.8,1.5
		c0.2,1.1-0.9,1-1.6,1.5c-7.5,4.8-15.7,8.3-23.4,12.6c-0.3,0.2-0.3,0.9-0.5,1.4c-1.7,1.3-2.8,0.1-4-1c1.1-2.1,3.9-2.8,4.5-5.6
		c0.5-2.4,3.3-0.3,4.6-1.2c1-2-3.1-2.1-1.4-4.1c1.2-1.5,3-1.1,4.6-0.7c1.3,0.4,2.2,0.5,2.5-1.2c0.8-4.4,4.8-4.7,8-6.1
		c0.7-0.6,1.3-0.6,2,0.1C756.1,431.7,756.8,432.1,757.4,432.4z"/>
	<path class="st2894" d="M755.4,431.4c-0.7,0-1.3,0-2-0.1c-1.3-1-2.7-2-4-3c0.4,0,0.7-0.1,1.1-0.1c1.6,0.7,3.3,1.4,4.9,2.1
		C755.5,430.8,755.4,431.1,755.4,431.4z"/>
	<path class="st2895" d="M460.8,531.2c1.5-1.1,0.8-2.6,1-4c4.7,3.9,8.9,8.8,16,8c2.5-1.9,5.5-1.8,8.1-1.1c4.4,1.1,8.9,1.1,13.2,2.1
		c5.3,1.2,10.4,1.8,15.7,0.5c1.7-0.4,3.6-0.2,5.5,0.1c1.4,0.9,3.2,1.5,2.3,3.8c-7,3.1-14.5,2.6-21.8,2.4c-3.6-0.1-7.2-0.4-10.9-0.4
		c-0.7,0-1.5-0.2-1.9,0.2c-4.3,3.5-8.6,3.2-13.5,0.9c-2.6-1.2-2.3,3-3.9,4.2c-0.4,0.3-1,0.8-1.3,0.4c-3.6-3.7-8.1-6.9-7.6-13
		C461.4,534,461.1,532.6,460.8,531.2z"/>
	<path class="st2896" d="M520.7,537.3c-6.8,0.8-13.3,2.3-20.6,0.7c-7.2-1.6-14.7-3.7-22.3-2.8c0.3-2.7,3.7-3.3,4-6c0.7,0,1.3,0,2,0
		c2.5-1.1,5-0.9,7.2,0.3c6.2,3.1,12.7,3.7,19.4,3.3c2.2-0.8,4,0.6,6.1,0.8c0.9-0.1,1.6,0.3,2.4,0.8c0.8,0.6,1.8,1.1,2.1,2.3
		C520.9,536.9,520.8,537.1,520.7,537.3z"/>
	<path class="st2897" d="M473.8,506.3c2.5-0.5,3.4-2.4,2.6-4.3c-1-2.5,0-4.4,0.4-6.6c3.8,2.4,1.5,6.9,3.6,10.2
		c0.3-1.3,0.5-2.3,0.7-3.4c1.7,5.8,1.5,12.6,8.7,15.1c1.7,2.1,3.8,4,2.2,7.1c-3.8,1.1-6.2-1.8-9.2-3.1
		C479.7,516.3,475.4,512.1,473.8,506.3z"/>
	<path class="st2898" d="M482.8,521.2c3.8-1.3,5.7,2.9,9,3c1.5-2.1,3.8-2.2,5-0.5c3,4.2,8.1,4.6,11.9,7.4c1.2,0.9,3.4,0.9,3.1,3.2
		c-9.9,1.5-18.7-2.9-28-5C484.6,526.4,482.6,524,482.8,521.2z"/>
	<path class="st2899" d="M459.8,561.2c-8.4,0.7-17.2,0-24.6,5.5c-0.9,0.7-1.8-0.2-2.7-0.3c-4.6-0.5-9.6-5-13.4,2.1
		c-1.2,2.3-5.2-0.1-7.8,1.9c-1.8,1.4-4.9,1.2-7.5,1.8c1.9-1.3,1.9-2.7,0-4c3.6,0.3,6.8-1,9.3-3.4c2.3-2.2,5.2-0.4,7.8-1.5
		c1.6-0.7,3.5-0.4,4.1-2.6c-1.5-1.8-4.1,1.5-4.8-0.6c-0.8-2.4,2.3-2.6,3.6-3.9c6.5-1.4,13.2,0.8,19.7-0.4c7.3-1.4,14.4,0.3,21.6,0.3
		c2.5,0,4.4,1.4,6.6,2.1c0.7,0.4,1.3,1,1.5,1.8c0,1.6-1.4,3.2-2.2,2.7C467.4,560.7,463.2,564.2,459.8,561.2z"/>
	<path class="st2900" d="M471.8,559.2c-5.9-3-12.6-1.6-18.9-2.8c-3.7-0.7-8.9,0.4-13.4,0.9c-5.2,0.5-10.5,0.2-15.6-1
		c0-0.3,0-0.7,0-1c0-1.5,0.1-3.7,1.7-3.6c6.2,0.7,8.4-4.6,12.3-7.4c0.6-0.6,1.4-0.5,2.1-0.4c2.2,0.8,3.9,2.6,5.8,3.8
		c4.3,2.6,8.5,5.3,13.9,3.9c3.1-0.8,5.7,1.6,8.6,1.9c1.2,0.5,2.1,1.3,2.8,2.4C471.8,556.8,472.5,557.9,471.8,559.2z"/>
	<path class="st2856" d="M488.8,482.2c1.9,1.4,3.7,2.9,5,5C488.5,487.9,487.7,487.1,488.8,482.2z"/>
	<path class="st2901" d="M798.4,680.1c-15,8-30,16-45,23.9c-0.4-0.3-0.9-0.3-1.4-0.1c-1.9,0.5-3.8,1.9-5.6,0
		c-1.5-1.6,1.4-2.8,0.7-4.5c5.4-4.7,11.9-7.6,18.1-11.1c3.2-1.8,6.6-3.1,9.7-5.1c3.4-1.3,6.3-3.5,9.5-5.3c2.1-1.2,4.3-2.6,7-1.9
		c2.8-1,5-1.1,5.2,2.8C796.7,679.8,797.7,679.9,798.4,680.1z"/>
	<path class="st2902" d="M791.4,676.1c-5.8,1.7-10.1,6.5-16,8c-1.9,1-3.9,0.8-6,0.3c-0.3-0.3-0.5-0.8-0.6-1.2c0.1-1.3,1.1-2,2.1-2.6
		c1.6-0.8,3.2-1.6,4.6-2.7c0.4-0.3,0.8-0.5,1.2-0.7c7.3-4.2,15.1-7.5,22-12.3c1.4-0.8,2.9-1.1,4.5-1.4c0.7-0.8,1.5-1.5,2.2-2.3
		c1.5-0.9,0.3-3,1.8-3.9c0.8,4.4-0.5,8.1-4,11c-4,0.8-7.2,3.1-10,6C792.5,674.4,791.8,675.1,791.4,676.1z"/>
	<path class="st2903" d="M793.4,674.1c2.8-2.9,4.4-7.8,10-6C801.2,671.9,796.4,671.4,793.4,674.1z"/>
	<path class="st2901" d="M807.4,678c-1.5-0.9-3.2-1.1-5-1C804.4,676,806.6,673.7,807.4,678z"/>
	<path class="st2904" d="M528.8,833.2c-3-6.2-1.8-12.7-1.1-19.2c0.6-0.9,1.6-1.2,2.7-1.3c2.2,0,3.9,0.5,3.4,3.3
		c-0.4,2.7,1.7,4.8,1.7,7.5c-0.4,2.1-2.7,3.2-2.8,5.4c-0.6,1.6-1.6,2.9-3,3.8C529.4,832.9,529.1,833.1,528.8,833.2z"/>
	<path class="st2905" d="M536.6,840.8c-0.9,2.7-1.9,5.4-2.8,8.1c-0.6-3.4,1.1-7.3-2.2-10.1c-0.6-3.5-1.4-7.1,1.8-9.9
		c2.3-0.2,4.4-2.4,6.8-0.6c0.6,0.5,1,1.1,1.1,1.9C540.9,834.2,539.1,837.6,536.6,840.8z"/>
	<path class="st2906" d="M534.7,828.9c-3.3,2.6-3.1,6.3-3,9.9c-2.1-1.5,0.2-4.5-2-6c-0.1-1.7,1.6-2.5,2-4c0.6-0.6,1.1-1.5,2.2-1.4
		C534.6,827.7,535,828,534.7,828.9z"/>
	<path class="st2907" d="M530.7,813.9c-1,0.1-2,0.1-3,0.2c-1-0.1-1.9-0.2-2.9-0.3c-0.7-1.8-2.5-1.6-5.2-2.8c2.9,0,4.5,0,6.1,0
		c7-0.7,12.8-4.2,18.6-7.7c6.3-3,12.4-6.6,18.4-10.2c0.9-0.8,1.9-1.4,3-1.9c4.7-2,9.5-3.6,13.3-7.2c6.2-4.1,13-7.2,19.5-10.9
		c3.8-1.6,6.9-4.5,11-5.4c8.7-3,16.3-8.2,24.4-12.4c1.6-0.8,3-2.3,5-1.5c0.9,1.5,0.3,2.9-0.3,4.3c-2,2.8-5.2,3.9-8.1,5.4
		c-5.5,3-10.9,6.1-16.3,9.2c-18.4,8.9-36,19.2-54,29c-6.7,2.6-12.4,7.2-19.1,9.8c-1.1,0.4-2.2,0.8-3.3,1.4
		C535.6,814.8,532.9,812.5,530.7,813.9z"/>
	<path class="st2908" d="M544.8,804.1c-4.7,4.6-11.2,5.5-17,7.8c-0.8,0.3-1.6-0.2-2.2-0.8c0.7-0.3,1.4-0.6,2.1-1
		c0.5-0.7,1.2-0.9,2-0.7c0.7-0.2,1.4-0.3,2.1-0.5c2.1-1.5,4.1-3.1,6.6-3.9c1.8-0.6,3.3-2,5.3-1.7
		C544.2,803.4,544.6,803.6,544.8,804.1z"/>
	<path class="st2909" d="M529.7,809.3c-0.7,0.2-1.3,0.5-2,0.7c-1.5-0.3-3-0.6-4.5-1c3.9,0.9,1.7-3.3,3.5-4.1
		C526.4,807.3,529.2,807.5,529.7,809.3z"/>
	<path class="st2733" d="M492.8,754c1.5,1.3,4.3,1.9,3,5C493.8,757.9,491.4,757.1,492.8,754z"/>
	<path class="st2910" d="M529.8,800.9c-1.1-13.9-7.7-25.2-16.8-35.1c-4.4-4.9-9.4-9.2-14.2-13.8c0.5-0.5,1.2-0.7,1.9-0.6
		c1.8,0.5,3.2,1.6,4.5,2.8c0.8,0.8,1.5,1.5,2.4,2.2c1.7,1.3,3.1,3.1,5,4.2c3.1,2.7,6,5.7,8.4,9.1c0.4,0.5-0.2,0,0.3,0.4
		c9.4,6.8,11.5,17.2,14,27.5c-0.3,1.3-1,2.2-1.9,3.2C532.1,801.7,531,801.8,529.8,800.9z"/>
	<path class="st2911" d="M529.8,800.9c1.1,0,2.3,0.1,3-1.1c0.4-0.2,0.8-0.3,1.3-0.2c2.4,1.5,3.2,4,4.6,6.2c-2.2,1.3-4.2,3-6.9,3
		C531.1,806.2,530.5,803.6,529.8,800.9z"/>
	<path class="st2912" d="M500.8,752c-0.7,0-1.3,0-2,0c-3.8-2.2-7.9-3.9-11.1-7c0.7,0,1.4,0,2.1,0C496.1,745.3,499.5,747.5,500.8,752
		z"/>
	<path class="st2909" d="M526.7,803.9c-2.5-0.4-2.9-2.6-2.1-4c1.7-3,0.8-6,1.1-9c1.9,2.7,1.1,5.6,0.6,8.4
		C526,801,525.9,802.4,526.7,803.9z"/>
	<path class="st2913" d="M746.4,42.8c0.9-3.8,2.6-6.1,6.9-3.9c0.3,1.3,0.7,2.6,1,3.9c-1.7,0-1.9,3-4.1,2c-0.3,0-0.6,0.1-0.9,0.1
		C748,44.8,747,44.1,746.4,42.8z"/>
	<path class="st2914" d="M746.6,130.5c0.3,3.1-1.6,6.3,0.8,9.2c-1.1,1.1,0.2,3-1.4,4.3c-2.4-1.6-2.8-4.5-4.7-6.2
		c-1.8-1.7-3.5-3-6.1-1.4c-3.1,2-5.3,0.7-6.7-2.5c-1.4,1.1-0.6,3.1-2.1,4c-1,0-1.6-0.6-1.8-1.4c-0.6-4.6-1-9.2-1.6-13.7
		c-0.6-4.6,2.9,0.1,4.4-0.8c4.2,0.5,5.8,5.4,9.9,6.1c1.2,0.3,2.5,0,3.6,0.3c0.4,0.1,0.6,0.8,1,0.7c0.4-0.1-0.2-0.6,0-0.9
		c0.7-1.2,1.8-0.2,2.6-0.5c0.3,0,0.5,0.1,0.8,0.2C746.8,128.3,747.3,129.1,746.6,130.5z"/>
	<path class="st2915" d="M746.6,130.5c-0.4-0.9-0.8-1.8-1.2-2.7c0.9-1.5,1.8-3,3.9-2.9c-0.6,2.4-0.2,4.5,2.1,5.8
		c0.3,6.9,0.5,13.8,0.8,20.8c0.1,1.9-0.6,3.5-1.5,5.1c-0.1-5.1-0.1-10.2-0.2-15.2C750.5,137.2,749.5,133.5,746.6,130.5z"/>
	<path class="st2916" d="M744.6,127.5c-0.7,1.2-1.4,0.3-2.1,0.1c-1.8-0.8-2.8-2.3-3.8-3.9c-0.6-1.3-1.1-2.6-0.3-4c0-0.3,0-0.6,0-0.9
		C741.5,121,742.7,124.6,744.6,127.5z"/>
	<path class="st2916" d="M736.5,116.7c-1.7-0.9-3-2-3.1-4C735,113.6,736.3,114.8,736.5,116.7z"/>
	<path class="st2917" d="M771.4,234.6c1.3,6.6,6.1,12.4,3.4,19.7c3.2,0,0.9-5,4.5-3.7c-0.3,3.4,0.9,7-1.3,10.3
		c-3.3-2.3-6.6-4.5-7.2-9c-0.2-1.8-2-2.6-3.5-3.3c-1.7-3.2-4.9-5.7-4-10c0-1.4-0.5-2.6-1.5-3.5c-1.4-1.3-0.7-1.9,0.5-2.5
		c1.2-0.6,2.5-1.4,3,0.7c0.1,0.3,0.1,0.7,0.3,0.9c1,1.2-0.1,3.8,2,4c2.3,0.3,0.9-2.9,2.7-3.4C770.8,234.7,771.1,234.7,771.4,234.6z"
		/>
	<path class="st2748" d="M763.4,238.6c0.8,3.5,4.3,6,4,10c0.1,0.6,0.1,1.2,0.2,1.8c-0.2,0.1-0.4,0.3-0.6,0.4
		c-3.7-2.3-3.8-6.8-6.4-9.8c-0.8-1.7-2.1-3.2-1.6-5.3C761.1,235.9,761.8,238,763.4,238.6z"/>
	<path class="st2918" d="M759.1,235.7c0.8,1.6,1.7,3.2,2.5,4.8c-0.1,0.8-0.3,1.6-1.1,2c-1.2-2.7-4.2-4.7-3-8.2
		C758,234.8,758.5,235.3,759.1,235.7z"/>
	<path class="st2919" d="M751.5,225.6c0.3,0,0.6,0,0.9,0c1,1.5,2.6,2.8,2.1,4.9C753,229.2,751.8,227.7,751.5,225.6z"/>
	<path class="st2748" d="M767.6,250.4c2.2,0.6,1.7,2.5,2,4c0,0-0.3,0.3-0.3,0.3c-2.2-0.7-2.9-2-1.9-4.1L767.6,250.4z"/>
	<path class="st2920" d="M767.4,250.6c0.2,1.5,0.8,2.9,1.9,4.1c0.1,0.6,0.2,1.2,0.3,1.8c-0.4,0.5-0.9,0.9-1.3,1.4
		c-2.6-4-3.6-8.8-6.7-12.5c-0.4-1-0.7-1.9-1.1-2.9c0.1-0.8,0.2-1.6,1.1-2C763.5,243.9,765.5,247.2,767.4,250.6z"/>
	<path class="st2921" d="M416.9,309.5c0-7.3,0-14.6,0-22c1.7-0.3,1.9,1.1,2,2.1c0.8,5.4,3.9,10.5,1.7,16.3c-0.6,1.6,0.8,2,2,2.1
		c2.1,0.2,2.8-0.6,3-2.8c0.4-4.8,2.2-5.5,6-2.8c0.5,9.3,2.9,18.5,0.6,27.9c-2.9,4.5-2.8,4.7,2,6.5c1.1,0.4,2.2,0.7,2.6,2
		c0.3,3,1.2,6.1-1,8.8c-1-0.4-2-0.8-3-1.2c-1.9-4.5-6-5.8-10.2-6.9c-1.3-0.3-2.6-0.2-3.8-1l0,0c-1-2.5-0.4-4.1,2.5-3.6
		c3,0.6,3.8-0.8,4.2-3.3c-0.4-3.1-3-0.9-4.3-1.8c-0.4-0.2-0.7-0.6-0.9-1c-0.4-1.4,0.2-2.5,0.8-3.7c0.4-0.9,1.2-2.1,0.4-2.7
		C417,319,418.3,313.8,416.9,309.5z"/>
	<path class="st2922" d="M438.7,349.4c-0.8,0-1.6-0.1-2-0.9c0.1-9.9-0.6-19.8-0.4-29.7c0.1-4.8,0.1-9.6-1-14.3
		c0.1-9.4-0.8-18.9,0.2-28.3c2.4-7.8,1.9-15.9,3.1-23.8c0.8-4,3.2-7.7,1.5-12.3c-0.7-1.8,1.7-3.7,2.3-5.6c3,0.2,3.4,2.6,4.2,4.8
		c0,0.8-0.1,1.5-0.6,2.1c0.2,1.4-2.1-1.8-1.4,0.7c0.5,1.9,0.4,3.9,0.4,5.9c-0.1,2.8-0.8,5.5-1.5,8.2c-4.5,25.2-3,50.7-3.4,76.1
		c-0.1,5.4,1,11-0.8,16.3C439.2,349,439,349.2,438.7,349.4z"/>
	<path class="st2923" d="M436.8,304.5c1.5,14.7,1.7,29.4,0,44c-0.3-0.3-0.7-0.7-1-1c0-3.4,0-6.7,0-10.1c-1.6-1.5-1.4-3.4-1.4-5.3
		c0-7.3,0-14.5,0-21.8c0-1.8-0.2-3.7,0.8-5.3C435.6,304.4,436.2,304.2,436.8,304.5z"/>
	<path class="st2924" d="M441,354.5c-0.5-0.3-0.9-0.7-1.4-1c-0.3-1.4-0.6-2.7-0.9-4.1c0.2,0.2,0.4,0.2,0.6,0.3c1.7-0.2,2-1.4,2-2.8
		c-0.3-5.2,0.9-10.5-1.6-15.4c-0.7-1.5-0.1-3,0.1-4.3c1-4.7,0.7-9.7,3.5-13.9c1.8,0.9,1.7,2.5,1.8,4.1c0,1.7,0,3.3-0.3,4.9
		c-1,2.7-0.6,5.4,0.2,8.1c0.5,6.3,0.2,12.5,0.2,18.8C445.1,351.9,444.9,354.6,441,354.5z"/>
	<path class="st2925" d="M416.9,309.5c1.5,1.7,1.3,4,2,5.9c0.3,0.9,0.3,2.4,0.9,2.7c5.7,3.3,2.2,7.4,1.1,11.3
		c-1.7,0.9-2.2,0.1-2.5-1.5c-0.4-2.2-0.1-4.5-1.5-6.5c0-0.3,0-0.7,0-1C416.8,316.8,416.9,313.2,416.9,309.5z"/>
	<path class="st2925" d="M425.8,331.5c1,4.7,0,5.7-4.3,4.4c-2.7-0.8-2.6,0.9-2.7,2.6c-1.8-1.7-0.7-3.3,0-5c1-0.9,0.7-2.8,2.3-3.3
		C422.9,330,424.6,330,425.8,331.5z"/>
	<path class="st2925" d="M418.9,338.5c7.9,0,12.8,2.7,14,7.9c-3.3-3-6.6-5.8-11.4-6C420.2,340.3,419.4,339.6,418.9,338.5z"/>
	<path class="st2771" d="M420.9,330.5c0,1.4-0.7,2.4-2,3c-0.8-4-2.1-7.8-2-12c2.1,0.9,2,2.8,2,4.6c0,1.5-0.2,3.1,2,3.3c0,0,0,0,0,0
		c0.3,0.2,0.4,0.4,0.3,0.7C421.1,330.4,421,330.5,420.9,330.5z"/>
	<path class="st2926" d="M447.9,398.4c3.5,11,7,22.1,6.9,33.8c0,3.5-0.5,6.8-2,10c0-1,0-1.9,0-2.9c-0.6-1.7-1-3.5-0.6-5.2
		c0.9-3.6-0.5-6.7-1.9-9.9c0.5-1.8-1.3-1.5-2-2.2c-1.1-2.7-1.1-5.7-2.5-8.3c-1.4-2.4-1-5.3-1.7-7.9c-0.4-1.9-1.2-3.7-2-5.4
		c-0.6-2.3-2.2-4.4-1.9-6.9c0.8-1.8,0.2-3.4-0.7-5c-0.9-1.8-1.4-3.6,0.4-5.3c1.3,1.8,2.6,3.5,3.8,5.3
		C445.8,391.5,447.9,394.5,447.9,398.4z"/>
	<path class="st2927" d="M439.9,383.2c-0.1,2.3-0.3,4.4,1.7,6.2c1.6,1.5,1.2,3.2-1,4.2c-0.4,0.2-0.9,0.2-1.3,0
		c-1.3-1-1.9-2.5-2.6-3.8c-1-2.9-4.2-4.6-4.3-8c0.9-1.7,0.4-3.8,1.4-5.5c1.8-0.3,2.1,1.3,3,2.2C438.1,379.9,439.6,381.1,439.9,383.2
		z"/>
	<path class="st2928" d="M437.8,355.4c1,0.3,2,0.7,3.1,1c0,0.2,0.1,0.5,0.1,0.7c-1.7,2.8-6.3,5.2-1.2,8.9c1.1,1.3,1.3,2.7,0.4,4.2
		c-2.8,1.2-3.5-1-4.4-2.7c-0.7-1-1.3-2-2-3c1.4-2.9-3.8-3.9-2-6.7C433.2,355.6,436.8,358.7,437.8,355.4z"/>
	<path class="st2929" d="M435.8,367.4c1.3,1,2.7,1.9,4,2.9c3.2,1.8,5.6,4.1,5.7,8.1c-2.8,0.7-3.1-3.3-5.7-3c-0.7-0.7-1.4-1.3-2-2
		C437.1,371.4,433.7,370.4,435.8,367.4z"/>
	<path class="st2930" d="M447.9,398.4c-1.4-3.3-2.8-6.6-4.2-10c0.4,0,0.7-0.1,1.1-0.1c2.3,3.4,3.6,7.2,5,11.1
		C449.2,399,448.6,398.7,447.9,398.4z"/>
	<path class="st2753" d="M436.8,378.4c-1-0.7-2-1.3-3-2c-1.2-0.6-1.9-1.3-1-2.7c0-0.2,0-0.4,0-0.5
		C434.5,374.7,436.5,375.9,436.8,378.4z"/>
	<path class="st2931" d="M410.9,359.4c0-0.3,0-0.7,0-1c6.1,1.1,10.8,4.5,15,8.9c0,0.4,0,0.7,0,1.1c-0.2,0.1-0.4,0.2-0.6,0.3
		C420.3,365.9,414.9,363.7,410.9,359.4z"/>
	<path class="st2931" d="M432.8,373.7c0.3,0.9,0.7,1.8,1,2.7c0.1,1.8,1.9,4-1.1,5c-2,0.4-2.6-1.4-3.8-2.4c-2.2-3.5-6.6-4.5-8.9-7.8
		c-0.6-0.9-1.6-1.7-1.3-3c2.4-3.5,4.8,0.7,7.1,0.1c0,0,0,0,0,0c1.3,0.6,2.2,2.1,3.8,2.1C430.8,371.6,431.8,372.7,432.8,373.7z"/>
	<path class="st2932" d="M443.8,322.5c0-1.7,0-3.3,0.1-5c0.6-0.3,1.1-0.2,1.5,0.4c1.5,3.7,0.5,7.6,0.8,11.4
		c0.2,3.9-0.5,7.9,0.5,11.8c0.3,1.1,0.4,2.2,0.4,3.3c-0.6,3.5-0.7,7-1.5,10.5c-0.5,1.1-1.4,1.6-2.4,2c-0.8,0.2-1.6,0.1-2.2-0.5
		c0,0,0,0,0,0c0-0.6,0.1-1.3,0.1-1.9c2.3-1,2.9-2.8,2.8-5.2c-0.2-6.6-0.1-13.2-0.1-19.9C443.1,327.1,443.1,324.8,443.8,322.5z"/>
	<path class="st2933" d="M373.9,698c0.1-4-3-7.4-2.2-11.5c-5.5-2.3-2.3-8-4.3-11.1c-1.9-3-3.9-5.5-3.9-9.3c0-1.2-2-0.8-3-1.1
		c-2.4-0.8-4.7-1.8-4.6-5c0-0.5,0.1-1.3-0.2-1.4c-5.8-3.8-1.6-8.9-1.8-13.5c5.5,0.8,6.8,8.3,13,8.3c0.8,0,1.2,1.4,0.6,2
		c-2.4,2.8-0.4,5.7,0.9,7.2c3.4,3.8,4.2,8.7,6.3,12.9c2,4.1,6.4,7.5,5.6,13.1c-0.3,1.7,1,3.6,3,4.8c2.3,1.3,4,3,2.8,6.5
		c-0.9,2.5,1.7,4.4,3.9,4.9c2.7,0.6,4.4,2.1,5.7,4.2c-5-1.9-10.4-3.3-13.5-8.2C380.2,697.5,376.3,700.3,373.9,698z"/>
	<path class="st2934" d="M367.9,624.1c2.2,0.4,3.8-0.5,5.1-2.3c0.5-0.7,1.5-0.8,2.2-0.2c0.8,0.6,0.7,1.5,0.2,2.2
		c-1.3,1.9-2,4-2.6,6.3c-1.5,5.3-0.2,9.1,4.1,12.5c2,1.6,2.4,4.1,1.5,6.8c-1.8,5.5,0.7,10.6,2.7,15.5c1.2,3,3.2,3.4,4.8,0
		c0.2,0.1,0.4,0.3,0.6,0.4c0.9,1.4,1.8,2.8,2.4,4.5c0.5,2.2,0.4,4.4,0.4,6.6c-0.3,3.5,0.4,6.4,2.7,9.6c1.9,2.7,2.1,6.6,4.6,9.3
		c1.5,4.2,3.6,8.1,7.1,11.2c1.7,1.6,3.8,3.3,3.3,6.3c-2.5,0-4.5-0.6-5.3-3.4c-0.4-1.3-1.6-2.4-2.8-2.6c-3.7-0.5-4.5-5.1-8.6-5.3
		c-1.8-0.1-0.5-1.8,0.4-2.4c1.5-1.1,2-2.9,0.8-3.8c-4.2-3.3-3-9-5.7-12.6c-4.3-5.8-5.7-12.6-7.7-19.2c-0.9-3-3.6-4.6-4.6-8.1
		c-1.8-6.4-0.5-13.6-4.9-19.7C366.6,633.2,368,628.1,367.9,624.1z"/>
	<path class="st2935" d="M406.9,713c-1.7-2.8-3.6-5.5-6.1-7.6c-3-2.5-4.5-5.6-4.9-9.4c-2-6.6-4.9-12.8-8-19c0-2.7,0-5.3,0-8
		c-0.9-1.2-1-2.6-1-4c0.1-1.4-0.2-2.8,0.7-4.1c3.7-2,4.6,0.2,5.7,3.3c3.6,10.7,5.8,21.9,11.3,31.9c0.8,3.3,2.9,5.7,4.6,8.5
		c3.1,4.8,7,9,10.6,13.3C414.8,718.3,411.7,713.4,406.9,713z"/>
	<path class="st2936" d="M419.8,718c-4-3-8.1-5.4-10-10.9c-1.2-3.3-6-5.3-5.9-10.1c0.6-1.7-2-3.4-0.1-5.1c2.6-0.3,3.1,2,4.1,3.6
		c2.8,4.2,5.3,8.5,8.5,12.5c1.1,4.9,5.4,6.6,9,9.1c1.2,0.8,2.9,0.9,2.5,3c-2-0.7-4-0.7-6,0C421.2,719.3,420.5,718.7,419.8,718z"/>
	<path class="st2937" d="M466.8,731c-1.3,1.8-2.7,0.4-4,0c-9.8-1.5-18.5-6.3-27.9-9c-1.6-0.9-3.7-0.8-4.9-2.4
		c-1.9-2.2-1.7-4.3,0.3-6.4c2-1.5,3.9-1.7,5.2,0.8c0.8,1.6,2.1,1.8,3.6,1.9c1.2,0.1,2.9,0.4,2.9,1.5c0.2,4.1,3.4,4.4,6.2,5.1
		c6.1,1.5,11.4,5.3,17.6,6.7C466.4,729.6,466.7,730.2,466.8,731z"/>
	<path class="st2938" d="M430.8,719c1.3,1,2.7,2,4,3c-2.6,0.4-4.8-0.8-7-2c-2.7-1.9-5.3-4.1-8.2-5.5c-3.1-1.4-4.3-3.3-3.7-6.5
		c-1-1.6-2.8-2.9-2-5.2c1.8-1.1,3.2-0.4,4,1.3c1.8,3.6,5,5.9,7.4,8.9C427.9,714.3,428.2,717.7,430.8,719z"/>
	<path class="st2939" d="M500.8,752c-2.2-4.6-7.3-4.8-11-7c-1.3-1-2.7-2-4-3c1-1.4,2.4-1.9,4.1-1.6c2.7,1,5.9,0.4,8,3.3
		c0.9,1.3,2.9,0.5,4.1-0.2c2.2-1.2,3.9-0.9,5.8,0.7c6.7,5.7,13.7,10.8,22.5,12.9c1.7,0.4,2.5,1.6,2.1,3.4c-1.6,2.7-2.9,5.9-7.1,4.9
		c-5-0.1-8.7-3.1-12.3-5.8c-2.5-1.9-5-3.8-8.2-4.5C503.4,754,502.1,753,500.8,752z"/>
	<path class="st2940" d="M489.8,741c-1.3,0.3-2.7,0.7-4,1c-3.9-2-7.2-5-11.6-6.1c-0.7-0.2-1.9-0.9-1.9-1.3c-0.1-4.1-3-3.5-5.5-3.6
		c-0.3-0.3-0.7-0.7-1-1c0.3-1,1.2-1.5,2.2-1.6c3.1-0.3,6.1-0.8,9.1-1.4c1-0.1,2,0.1,2.9,0.7c1,4.6,4.1,7.1,8.4,8.6
		c1.8,0.6,3.8,1.4,3.9,3.9C491.7,741.4,490.9,741.8,489.8,741z"/>
	<path class="st2941" d="M737.5,415.4c-2.7-1.8-1.3-5.1-3.2-7.4c0.5,3.7-5,6.9,0,10.8c-5-0.3-6.7,1.8-5.5,6.4c0.3,1.3,0.2,2.1-1,2.1
		c-5,0-5.9,2.6-6.2,7.2c-0.2,2.7-2.5,5.6-2,8.8c-0.7,1-1.3,2-2,3c-2.5,2-5.1,3.7-6.3,7.2c-0.7,2.1-2.9,3.9-5.2,4.9
		c-3.2,1.4-5.2,4.4-8.2,6.1c-0.5,0.1-0.9,0.1-1.4-0.1c-0.5-0.6-0.7-1.2-0.7-2c0-1.2-0.6-2.2-1.3-3.1c-3.4-4.9-3.1-5.9,1.1-10
		c5.1-5.1,10.5-10.1,11.6-17.9c0.2-1.6,1.3-3,2.2-4.5c0.6-1,1.5-2.4,0.7-3.2c-3.2-3.4-1.6-7.1-1.2-10.7c0-0.7-0.1-1.5,0.3-1.9
		c2.3-2.5,6-4.6,2.1-8.8c-0.6-0.7,0.8-2,1.7-2.8c1.9-1.6,3.4-3.7,5.6-4.9c2.5-0.6,4.7-1.5,6-4c1-1.8,2.5-3.2,4.4-3.9
		c8.3-3.2,12.3-10,15.6-17.6c0.5-1.2,1.1-2.4,2.1-3.3c1.4-1.3,3-1.3,3.5,0.6c0.7,2.4,2.6,4.3,2.3,6.9c0,0.3,0,0.7,0,1
		c-1.2,1.9-2,3.8-1,6c0.1,0.7,1.1,1.3,0,2c-1,0.8-1,2.2-2,3c0,0.7,0,1.3,0,2c-1.9,1.6-2,4-3,6c0,0.3,0,0.7,0,1
		c-4.2,2.2-7.2,5.2-7.7,10.2c-0.4,3.6,1,7.2-0.3,10.7C738.1,415.4,737.8,415.4,737.5,415.4z"/>
	<path class="st2942" d="M754.5,309.5c2.6,7.8,4.4,15.6,5.2,23.9c0.7,7.4,0.6,14.7-0.3,22.1c-1.2,1.5,0.2,3.5-1,5c0,0.7,0,1.3,0,2
		c-0.7,0.7-1.3,1.3-2,2c-1.5,1-1.1,4.8-3.6,3.8c-2.5-1.1-0.8-3.8-0.1-5.7c1.8-5,1.9-7.8,0-17.1c-1.3,0.5-1.6,3.2-3.7,1.7
		c-0.9-5.1-2.1-10.2-1.2-15.6c0.8-4.7,0.6-9.5-1.1-14.2c-2.4-6.8-2.1-7.1,4.5-8.1c0.6-0.1,1.2-0.5,1.9-0.5
		C753.6,308.9,754.1,309.1,754.5,309.5z"/>
	<path class="st2943" d="M749.5,347.4c2.6-0.8,1.4-3.8,3.4-5.8c2.6,9,4.4,17.4-0.8,25.8c3.2,0.6,2.1-3.4,4.4-2.9
		c-0.8,3.3-0.2,7.1-4,9c-1.7-2.2-3.6-4.3-3.2-7.8c-2.3,1-3.4,2.4-3.7,4.2c-0.9,5.7-5.7,9-8.1,13.8c-1.3,2.7-5,2-7.3,3.8
		c-2.1,1.6-4.5,2.8-5.5,5.5c-1.2,3.2-3.9,2.6-6.3,2.6c-2.1,1.2-4.2,1.8-6.2-0.1c-0.6-1.8,0.5-2.7,1.9-2.8c4.2-0.4,6.3-3.2,8.3-6.3
		c0.4-0.6,0.9-1.2,1.6-1.5c5.1-2,8.6-5.5,11.8-10.1c4.6-6.6,9-13,11.2-20.8C747.8,351.6,747.1,348.9,749.5,347.4z"/>
	<path class="st2770" d="M738.5,415.4c-1.5-4.3-1-8.8-0.9-13.2c0.1-3.3,6-8.2,8.9-7.8c0,0.3,0,0.7,0,1v0c-3.8,1.1-2.2,4.7-2.4,6.7
		c-0.2,2.3-0.6,4.8-0.7,7.2c-1.5-0.7-3-1.2-2.9,1.4C740.5,412.6,740.6,414.5,738.5,415.4z"/>
	<path class="st2943" d="M751.5,380.4c-3.4-2.6-2.3-4.5,1-6C754.2,376.7,752.2,378.5,751.5,380.4z"/>
	<path class="st2770" d="M746.5,393.4c-0.8-2.9-0.9-5.5,3-6C749.4,389.9,748.8,392.1,746.5,393.4z"/>
	<path class="st2770" d="M749.5,385.4c0-1.5,0-2.9,2-3C751.5,383.9,751.5,385.3,749.5,385.4z"/>
	<path class="st2770" d="M746.5,395.4c1.4,1.1,1,2.2-0.1,3.6C745.3,397.5,746.8,396.5,746.5,395.4L746.5,395.4z"/>
	<path class="st2944" d="M754.5,309.5c-0.3,0-0.6,0-1,0c-0.3-0.4-0.7-0.7-1-1.1c-0.5-2.1-3.4-4.1-0.2-6.3c2.5,0.5,2,3,3.1,4.4
		c0,0,0,0,0,0C755.4,307.6,755.6,308.8,754.5,309.5z"/>
	<path class="st2760" d="M758.5,360.4c0.2-1.7-0.6-3.6,1-5C759.2,357.1,760.1,359,758.5,360.4z"/>
	<path class="st2945" d="M734.5,462.3c0,0.3,0,0.7,0.1,1c-2.1,4.3-3,9.6-9.2,10.4c-1.3,0.2-1.3,1.8-1.6,2.8
		c-1.2,3.5-3.3,5.8-7.1,5.9c-2.4,0.1-4,1.4-5.5,2.9c-0.4,0.2-0.9,0.3-1.4,0.3c-1.5-0.8-1.9-2.5-2.9-3.8c-1.3-1.8-2-3.9-3-5.9
		c-0.3-0.6-0.6-1.2-0.7-1.9c0.2-2,1-3.7,3.2-4.2c0.5,0,0.9,0.2,1.2,0.6c-0.1,3.6,2.2,1.3,3.3,1.2c3.3-0.2,6.1-0.3,9.5-2.9
		c3.5-2.7,5.2-6.3,7.8-9.3c1.6-1.8,2.7-1.3,4.3-1c0,0,0,0,0,0c0.7,1.8-1.5,2.4-1.6,3.9C732.1,461.9,733.3,461.2,734.5,462.3z"/>
	<path class="st2946" d="M707.5,470.3c-0.4,0-0.8-0.1-1.2-0.1c-0.4-0.6-0.4-1.3-0.2-2c5.2-5.1,10.1-10.6,15.2-15.9
		c0.7-0.7,1.5-1.4,2.2-2.1c-1.7,6.3-6.3,10.7-10.8,14.9C710.9,466.8,708.9,468.2,707.5,470.3z"/>
	<path class="st2947" d="M727.8,450.3c0.3,0,0.5,0,0.8,0c0.6,0.3,1.3,0.7,1.9,1c1.1-0.5,1.7,0.4,2.4,0.8c2.8,2.7,4.6,6.2,7.4,9
		c0.3,0.3,0.6,0.7,0.8,1.1c0.7,1.1,1.4,2.2,2.3,3.2c1.7,2.9,4.9,5,4.8,8.9c-0.1,0.5-0.4,0.8-0.7,1.2
		C741,466.9,734.8,458.3,727.8,450.3z"/>
	<path class="st2892" d="M732.5,452.3c-0.7-0.4-1.3-0.7-2-1.1c0-0.3,0-0.6,0-1c1.3,0.3,2.7,0.7,4,1l0,0
		C734.5,453,733.6,453,732.5,452.3z"/>
	<path class="st2948" d="M710.5,485.4c0.8-2.7,2.1-4.6,5.5-4.2c3.7,0.5,5.7-1.7,6.6-5.2c0.5-1.7,0.1-4.1,4-3.5
		c3.1,0.5,3.4-4.9,5.7-7.1c0.7-0.7,0.8-2.1,2.2-2.2c4.4,1.9,6,6.3,8.8,9.6c1.4,1.8,2.1,4.1,4.2,5.4c1.9,2.1,4,4.1,2.9,7.3
		c-1.3,1.1-3.3,2.1-4.6,1.3c-2.7-1.7-5.4-0.1-7.9-0.5c-6.3-1.1-10.1,3-14.7,5.6c-2,1.1-2.2,3-2.5,5.1c-0.6,3.7,0.5,7.6-1.1,11.2
		c-1.8,0.4-2.3-1.1-2.6-2.2c-1.6-4.6-3.6-9.1-4.7-13.8c0-2.1-0.2-4.1-1.9-5.6C710.2,486.2,710.2,485.8,710.5,485.4z"/>
	<path class="st2949" d="M750.5,485.3c-1-2.3-2-4.7-3-7c0.4,0,0.8,0,1.1-0.1c2.3,0,3,1.9,3.9,3.4c6,10.7,11.5,21.6,15.2,33.3
		c1.1,3.5,3.6,7.5-0.4,11c-1,0.5-2.2,0.6-3.3,0.7c-3-0.5-6.4,1.3-8.8-2c1.9-2.6,4.8-1.4,7.2-1.9c2.5-0.5,2.6-2.2,2.2-4.3
		c-0.6-1.4-0.4-3-1.1-4.4c-1.2-2.6-2.6-5-3.5-7.7c-0.2-0.4-0.3-0.8-0.5-1.2c-0.5-1.5-1.4-2.7-2.4-3.8c-1.7-3.6-3.5-7.1-5.4-10.5
		C750.7,489.1,749.5,487.4,750.5,485.3z"/>
	<path class="st2950" d="M767.3,525.2c2-2.9,0.5-5.9-0.4-8.6c-4.5-13.5-10.4-26.5-18.3-38.4c-0.4-1-0.8-1.9-1.1-2.9c0-0.3,0-0.7,0-1
		c2.9-0.3,3.2,2.4,4.7,3.8c4.6,6.1,7.2,13.4,11,20c1.4,2.5,1.5,5.6,3.1,8.1c2.1,5,4.3,10.1,5,15.6c0.1,0.8,0,1.5-0.2,2.2
		c-0.7,1.9-1.8,3.5-3.5,4.8c-1.3,0.7-2.6,1.4-4.1,0.8c-0.6-0.5-0.7-1.2-0.6-1.9c0.2-0.4,0.4-0.8,0.8-1
		C764.9,526.1,766.4,526.4,767.3,525.2z"/>
	<path class="st2763" d="M734.5,462.3c-0.2,0-0.4,0.1-0.5,0c-1.9-1.7-2,3.6-3.8,1.1c-2-2.7,1-3.6,2.3-5.1
		C733.1,459.7,733.8,461,734.5,462.3z"/>
	<path class="st2951" d="M697.5,464.3c2.4-4.8,7.7-6.3,11.4-9.7c2.1-1.9,1.1-5.3,4.1-6.6c1.5-0.6,2.6-1.9,4.4-1.7c0,1,0,2,0,3
		c-6,8.2-13.5,15.1-20.5,22.4c-0.9,0.9-2,1.7-2.6,2.9c-0.4,0.6-0.9,1.1-1.5,1.5c-1.8,0.8-3.3,2.1-5.4,2.1c-0.8,0-1.4-0.2-2-0.7
		c-0.6-1.7,0.5-2.9,1.6-3.8C690.5,470.6,694.1,467.6,697.5,464.3z"/>
	<path class="st2952" d="M693.5,474.3c3.1-5.2,8-8.9,12.3-13c4-3.9,6.7-9,11.7-11.9c0.6,0,1.3,0,1.9,0c-4.1,5.8-10.1,9.9-13.9,15.9
		c-2.2,2.7-4.7,5-7.2,7.3C697,473.8,695.6,475.1,693.5,474.3z"/>
	<path class="st2953" d="M721.2,452.3c-3.9,6.2-9.2,11.2-14.5,16.2c-1.5,0.9-1.9,3.2-4.2,3c-0.3-0.3-0.5-0.7-0.6-1.2
		c0.3-2.1,1.5-3.4,3.5-3.9c5.8-3.6,9.5-9.2,14.1-14C720.1,452.3,720.7,452.3,721.2,452.3z"/>
	<path class="st2954" d="M705.5,466.4c-1,1.3-2,2.6-3,3.8c-2.6,4.2-6,7.4-11.1,8.2c-0.8-1.5,0.1-2.3,1.2-3c0.3-0.3,0.7-0.7,1-1
		c3.2-1.2,5.2-3.9,7.6-6.1c1.3-1.2,2.3-2.8,4.3-3C705.5,465.6,705.5,466,705.5,466.4z"/>
	<path class="st2955" d="M430.8,301.5c-3.2,0-5.3,0.8-3.7,4.6c0.7,1.5,0.3,2.8-1.3,3.1c-2.4,0.4-4.9,1-7-1.2
		c-1.4-1.5-0.3-2.3,0.8-3.1c2.6-2.1,0-4.2-0.3-6.2c-0.5-3.8-1.7-7.4-2.6-11.1c0.3-11.3-1.7-22.6-1-34c0.5-0.3,1-0.3,1.4,0.2
		c0.8,1.1,0.8,2.4,0.9,3.7c0,3.5-0.1,7,0.8,10.4c0.7,2.7,0,5.7,4.9,5.8c3.9,0.1,9.5,9.2,9.2,13.4c-0.2,3.5,0.8,6.8,1.4,10.1
		C433.4,298.8,432.4,300.4,430.8,301.5z"/>
	<path class="st2956" d="M416.9,253.6c-0.3,0-0.7,0-1,0c0.5-7-1.2-14.1,1-21c0,3,0,6,0,9c1.5,1.4,1.4,3.3,1.4,5.1
		C418,249,418.8,251.6,416.9,253.6z"/>
	<path class="st2833" d="M387.9,661.1c0,1.3,0,2.7,0,4c-0.7,0.6-1.3,0.6-2,0c1.6-6.7-3-11.9-4.8-17.6c-2.5-8.1-4.9-15.6-1.5-24.1
		c1.2-2.9,1-6.7-3.1-8.4c-1.7-0.7-3.5-1.8-3.2-3.9c0.3-2.2,2.4-1.7,4-2c0.8-0.1,2.1-0.6,2.1-0.8c0-5.4,5.1-6.7,7.6-10
		c1.2-1.5,1.9-3.4,2.8-5.1c0,0,0,0,0,0c1.6,4.2,2,8.5,0,12.5c-2,4-2.8,8.1-2.6,12.5c0.1,2.3,0.2,4.7-0.8,6.9c-1,2.4-0.4,4.5,0.5,6.7
		c2.7,7.6,2.6,15.4,2.4,23.3C389.3,657.2,389.5,659.3,387.9,661.1z"/>
	<path class="st2957" d="M449.2,422.3c1.3-0.9,1.7-0.2,1.7,1.1c1.5,4.8,2.6,9.6-1,14c-0.7,4.4-1.6,8.8-6.3,10.9
		c0.2-0.6,0.4-1.2,0.5-1.8c0.2-3.2,2.6-5.5,3-8.6c0.6-3.1,1.6-6.1,1.4-9.2c0-1.3-0.3-2.5-0.5-3.7C448,423.9,448,422.8,449.2,422.3z"
		/>
	<path class="st2958" d="M449.8,437.4c1-4.6,1.1-9.3,1-14c4.7,4.8,2.3,10.5,2,16C451.5,439,452.3,435.9,449.8,437.4z"/>
	<path class="st2959" d="M449,425.2c0.8,1.1,0.6,2.2,0.3,3.4c-0.4,1.3,1.4,3.6-1.7,3.7c-1.1-1.3-0.8-2.9-1-4.6
		c-1.3,4.3-1.9,8.6-4.1,12.3c-0.6,1-0.8,2.7-2.6,2.3c0-1,0-2,0-3.1c-1.4-2.2,0.1-4,1-5.9c1.9-8.5,0.4-16.7-2.1-24.8
		c-0.7-2.4-2.4-4.9-0.4-7.5c0.5-0.2,1-0.2,1.4,0c2.3,1.9,0.9,5.8,4,7.2c1.5,2.4,2.2,5.1,3.5,7.6C449,418.8,447.5,422.3,449,425.2z"
		/>
	<path class="st2960" d="M439.8,442.3c3.5-5.1,4.1-11.3,6.5-17.1c2.3,2.1,1.2,4.8,1.5,7.2c0.2,0.3,0.3,0.6,0.3,0.9
		c0,0.3-0.1,0.5-0.1,0.5c-0.7,4.6-1.1,9.4-5.3,12.5c-0.7,0.7-1.3,1.4-2,2.1c-3.8,0.8-4.1-1.1-3-4C438.5,443.6,439.1,443,439.8,442.3
		z"/>
	<path class="st2961" d="M411,439c-2-2.2-4-4.5-6-6.7c-4-6-7.9-12-9.8-19.1c0.7-1.3-0.7-3,0.8-4.1c3.6,2.6,3.2,7.2,5.1,10.7
		c1.6,2.9,3,5.8,5.1,8.4c1.1,1.4,2,3,3.2,4.3c1,1.2,2.1,2.3,3.3,3.3c0.5,0.5,1.1,1,1.6,1.4c3.8,3.7,8.3,5.5,13.6,5.7
		c1.5,0,3.7-0.5,4.1,2.1c-3.3,1-6.7,0.8-10.1,0.1c-1.7-0.6-3.3-1.3-5-1.9C414.9,442,412.9,440.5,411,439z"/>
	<path class="st2962" d="M437.8,444.3c0,2.1,0.4,3.9,3,4c-5.4,5.1-11.2,4.3-17.3,0.6C428.8,448.7,433.8,447.9,437.8,444.3z"/>
	<path class="st2963" d="M421.8,445.3c3.3-0.4,6.8,1,10-1c-0.3-0.5-0.3-1,0.2-1.5c7.6-5.1,8.3-12.6,7.1-20.7
		c-0.3-2.4-0.7-4.9-1.2-7.3c-0.3-1.7-0.8-3.7,1.5-4.7c3.5,7.8,3.5,15.9,2.3,24.1c-0.6,1.6-1.3,3.3-1.9,4.9
		C434.1,446.6,429.3,448.2,421.8,445.3z"/>
	<path class="st2959" d="M442.8,446.3c3.2-3.7,3.4-8.6,5-12.9c0.8,1.7,0.7,3.4,0.1,5.1c-0.3,3.1-1.5,5.8-3.8,8
		C443.6,446.4,443.2,446.3,442.8,446.3z"/>
	<path class="st2961" d="M391.9,399.4c0.3-3.6,0.6-7.2,0.9-10.8c-0.9-2.4,0.7-4.3,1.1-6.5c0.2-0.4,0.4-0.8,0.6-1.2
		c1.8-2.4,3.8-4.5,6-6.5c0.8-0.6,1.8-0.9,2.8-1.1c4.3-0.1,8.6-0.3,11.8,3.5c0.2,0.5,0.2,1,0,1.5c-1,1.1-2.1,0.6-3.1,0.2
		c-4.2-1.4-8-0.7-11.2,2.3c-1,1-1.7,2.1-2.4,3.3c-1,1.6-1.4,3.4-1.8,5.2c-2.2,5.2-0.4,10.6-1,15.9c-0.4,0.8-1,1.3-1.8,1.4
		C392,404.4,393.7,401.4,391.9,399.4z"/>
	<path class="st2964" d="M393.8,406.5c0.4-0.4,0.7-0.8,1.1-1.1c1.3,1.1,1.3,2.6,1,4.1c-0.1,1.3,0.4,2.7-0.7,3.8
		C394.1,411.1,393.7,408.9,393.8,406.5z"/>
	<path class="st2965" d="M395,381.6c-0.7,2.3-1.5,4.6-2.2,6.9c-3.6,1.5-2.9-1.2-2.4-2.9c0.8-2.8,2-5.5,1.4-8.5c0-0.3,0-0.5,0.2-0.7
		C394.9,377,394.2,379.8,395,381.6z"/>
	<path class="st2966" d="M391.9,366.5c0.3-0.3,0.7-0.7,1-1c2.7-2,4.9-4.6,8-6l0,0c3.3-1.4,6.4,0.6,9.7,0.8c0.8,0,1.5,0.9,1.3,1.8
		c-0.1,0.7-0.9,1-1.6,1c-3.3-0.1-6.1,1.3-8.8,3c-1.3,0.6-2.6,1.2-4.1,1.4c-1.3,0.1-2.7-0.2-3.6,1.1c0,0.3-0.1,0.6-0.1,0.8
		C391.7,369.4,391.9,367.9,391.9,366.5z"/>
	<path class="st2967" d="M395,381.6c-2.6-0.8-1.5-3.8-3.1-5.2c-0.1-0.3,0-0.7,0.2-0.9c1.9-1.3,2.6-4.2,5.5-4.4c2,0.3,4,0.5,5.4,2.1
		c0.2,0.4,0.2,0.8,0,1.2c-0.7,0.3-1.5,0.7-2.2,1c-3,1-4.5,3.4-5.8,6.1L395,381.6z"/>
	<path class="st2968" d="M397.8,371.4c-2.2,0.9-3,3.8-5.7,4.1c0.4-3.2,3.6-4.4,4.8-7c0.3-0.3,0.6-0.6,1.1-0.7c0.7-0.1,1.3,0,1.9,0.4
		c0.3,0.4,0.4,0.8,0.3,1.2C399.8,370.5,398.9,371,397.8,371.4z"/>
	<path class="st2825" d="M397.9,368.4l-0.5,0c0,0-0.5,0-0.5,0c-0.4,0-0.7,0-1.1,0c-0.5-1.1,0.5-1.4,1.1-1.9c1.3-0.4,2.6-0.7,4-1.1
		c0.7-0.5,1.4-0.5,2.1,0C402.9,369.1,399.4,367.1,397.9,368.4z"/>
	<path class="st2969" d="M396.9,366.5c-0.4,0.6-0.7,1.3-1.1,1.9c-0.6,0-1.3,0.1-1.9,0.1C394,366.4,395.4,366.4,396.9,366.5z"/>
	<path class="st2970" d="M461.8,419.4c-0.3-1.3-0.7-2.6-1-4c0-0.7,0-1.4,0.1-2.1c1.5-0.3,2,1,2.7,1.9c0.9,1.5,1.8,3.1,2.6,4.8
		c0.6,1.3,1.5,2.9,3.2,2.7c1.8-0.2,1.8-2.1,2.1-3.5c0.2-0.8,0.1-1.6,0.1-2.5c0.1-1.3,0.2-2.6,1.5-3.5c3.8,2.2,1.2,6.2,2.7,9
		c0.5,2.2,0.5,4.4,0.1,6.6c-0.5,1.5-0.7,3.1-1.6,4.4c-7.1,3.3-9.6,1.6-11.3-7.7C462.6,423.5,463,421.3,461.8,419.4z"/>
	<path class="st2971" d="M445.5,378.4c-1.9-2.7-3.8-5.4-5.7-8.1c-0.1-1.4,0.4-2.9-1-3.9c-1.3-1.8-2.3-3.8-0.5-5.7
		c1.6-1.8,3.6-0.9,5.5-0.2c2.3,0.8,4.7,1.5,6.8,2.7c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.4,0.3,0.9,0.3,1.4c-3.1,1.7-5.3,1.7-9.7,0.2
		c1,3.2,2.1,6.6,4.5,9.4c0.2,0.4,0.5,0.8,0.7,1.2c0.6,1.2,1.4,2.3,2.4,3.2c1.5,1.8,3.7,3.3,2.1,6.2c-0.5,0.3-0.9,0.2-1.3-0.1
		C449,383.1,447.3,380.8,445.5,378.4z"/>
	<path class="st2972" d="M462.8,415.4c-0.6-0.7-1.3-1.4-1.9-2.1c-0.7-2.7-1.4-5.3-2-8c0.2-0.1,0.4-0.2,0.6-0.3
		c0.5,0.1,0.9,0.4,1.2,0.7c0.5,0.6,0.9,1.4,1.3,2.1c3.4,5.4,4,5.5,8.6,1.2c0.6-0.6,1.3-1,2.2-0.7c1.5,1.6,1.5,3.2,0.2,4.9
		c-0.1,1.4-0.1,2.7-0.2,4.1C469.3,417.2,465.6,418.5,462.8,415.4z"/>
	<path class="st2973" d="M457.7,401.4c-1.8-2.3-2.5-5.1-3.1-8c0.4-0.3,0.8-0.3,1.3-0.1c2.3,2.6,3.7,5.8,5.7,8.5
		c1.1,2.5,2.5,4,5.1,1.4c0.2-0.4,0.4-0.8,0.6-1.2c0.7-1.6-0.2-3.9,2-5c1.8,0.8,1.7,2.5,1.8,4c0,0.5,0,1,0,1.5
		c-0.5,3.3-0.8,7-4.8,7.8c-3.7,0.8-4.8-2.8-6.4-5.1l0,0C458.7,404.3,459,402.4,457.7,401.4z"/>
	<path class="st2974" d="M455.8,393.4c-0.4,0-0.8,0-1.1,0c-1.5-2.6-3.6-4.8-3.9-8c0.4,0,0.8,0,1.1-0.1c2,1.3,2.9,3.4,3.9,5.4
		c0.3,0.6,0.4,1.3,0.4,2C456,393,455.9,393.2,455.8,393.4z"/>
	<path class="st2975" d="M457.7,401.4c1.9,0.6,2.2,2.2,2.1,3.9c-0.3,0-0.7,0-1,0C458.1,404.1,457.6,402.9,457.7,401.4z"/>
	<path class="st2976" d="M461.8,419.4c2.9,3.4,2.2,8.1,4.4,11.8c1.1,1.9,1.6,5.3,4.8,1.7c0.8-0.9,1.8,0.1,2.7,0.4
		c1.3,1.2,1.2,2.6,0.7,4.1c-0.3,0.6-0.7,1.2-1.2,1.6c-1.8,1.7-3.6,3.9-6.4,3c-2.9-0.9-3.5-3.6-4.1-6.3c-1-4.4,0.2-8.9-1-13.3
		C461.8,421.4,461.8,420.4,461.8,419.4z"/>
	<path class="st2977" d="M461.8,422.4c2.3,3.6,0.4,7.7,1.6,11.5c2.6,8.8,3,9.1,10.2,3.5c0.7,0.4,0.9,1.1,0.9,1.8
		c-0.3,1.6-0.6,3.3-1.6,4.7c-1.4,1.9-3.2,3.2-5.7,3.1c-0.5-0.1-0.9-0.6-1.3-0.6c-5.1-0.2-5.3-3.2-4-7
		C461.8,433.7,461.8,428,461.8,422.4z"/>
	<path class="st2978" d="M461.8,439.3c0,0.7-0.1,1.3,0,2c0.1,1.2-0.6,2.8,0.9,3.6c2.1,1.1,1.7-2,3-2.1c2.2,0.4-0.8,3.6,2.1,3.6
		c1.3,3.1-0.4,4.8-3,6C460.5,448.8,459.4,444.4,461.8,439.3z"/>
	<path class="st2979" d="M464.8,452.3c1.1-1.9,2.9-3.6,3-6c1.8-0.4,2.9-1.7,4.1-3c1.9,3.3,0.4,5.8-2.1,7.9c-1,1.3-2,2.7-3,4
		C466.1,454.3,465.5,453.3,464.8,452.3z"/>
	<path class="st2980" d="M476.8,456.3c-2.4,0.4-4.2-0.7-6-2c0-0.3,0-0.7,0-1c-0.3-3.9,1.6-7,3.4-10.2c2.5,0.8,1,3,1.8,4.5
		c1.5-1,2.3-3.1,4.3-3.7c0.5-0.1,0.9,0,1.4,0.2c0.9,1.1,0.3,2.2,0,3.4c-0.3,0.9-0.8,1.6-1.3,2.4c-1.2,1.5-2.1,3.1-2,5.2
		C478.2,455.8,478.1,456.7,476.8,456.3z"/>
	<path class="st2981" d="M474.8,443.3c-1.3,3.3-2.7,6.6-4,10c-0.8-0.4-0.9-1.2-1-2c0.5-2.7,2.5-5,2.1-7.9c0.6-1.4,1.2-2.8,1.8-4.2
		c0.6,0.3,1,0.8,1.3,1.3c0.2,0.4,0.3,0.8,0.4,1.2C475.4,442.3,475.3,442.9,474.8,443.3z"/>
	<path class="st2982" d="M499.8,426.4c-0.8-5.8-1.4-11.6-2.4-17.4c-0.2-1.4-0.3-3.5-2.6-3.6c-1.3-6.9-4-13.2-8-19
		c-2,0.1-1.8-1.5-2.1-2.7c-0.1-1.1,0.1-2.1,0.7-3c0.3-0.4,0.8-0.6,1.3-0.6c6.7,5.5,9.6,13.4,12.7,21c1,2.3,2,3.4,3.9,4.4
		c0.7,1.3,0.9,2.8,1.4,4.2c0.4,2.4,1.5,4.7,1,7.3c-1.7,4,1.1,8.4-1.4,12.3C501.5,430.3,501.5,426.8,499.8,426.4z"/>
	<path class="st2983" d="M485.7,383.5c0,1.1,0,2.2,1,2.9c0.7,2.6,1.4,5.3,2,7.9c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0-0.4-0.1-0.6-0.1
		c-0.2-0.1-0.3-0.3-0.5-0.4c-0.8-0.9-1.5-2-2.3-2.9c-2-2.1-3.3,1-5.1,0.6c-0.7-0.1-1.2-0.6-1.3-1.3c-0.5-0.6,1.7-1.3-0.1-1.9
		c-0.4-0.1-0.7-0.3-1-0.6c-0.7-1.1-1.1-2.3-1.1-3.6c0.1-1.1,0.5-2.2,0.9-3.2c0.8-2.4,3-3.2,5-4.2
		C485.2,378.2,484.5,381.4,485.7,383.5z"/>
	<path class="st2983" d="M491.8,402.4c0,2.3-0.1,4.6-0.1,7c-3.4-1.1-1.9-4.4-2.9-6.6c-0.8-1.6-1.3-3.4-1.3-5.2
		c0-1.2,0.4-2.2,1.2-3.1c0,0,0.1-0.1,0.1-0.1C490.5,396.8,491,399.7,491.8,402.4z"/>
	<path class="st2642" d="M488.8,466.3c-1,1-2,2-3,3c0.2-1.7-0.6-3.8,1.7-4.5C488.5,464.4,488.6,465.5,488.8,466.3z"/>
	<path class="st2984" d="M499.8,426.4c3.1-1.4,2.2,2.6,4,3c3.1,2.4,1.1,5.3,0.9,8c-0.8,3.4-1.1,7-2,10.4c-3.4,6.2-4.7,13-7.5,20.4
		c4-1.5,6.8-3.1,8.7-5.8c1.4-1.5,2-3.7,4.3-4.3c0.2,0.1,0.4,0.2,0.6,0.3c-1.4,4.3-3.4,8.1-7,10.9c-4.4,1.7-8.1,5.1-13.9,5.5
		C495.4,459.4,502.3,444,499.8,426.4z"/>
	<path class="st2985" d="M497.8,484.3c3.7-4.5,6.3-9.5,7.9-15.1c-0.2-2.9,2.3-4.5,3-6.9c1-0.2,1.3,0.4,1.6,1.2
		c-1.6,8.4-3.5,16.7-9.9,23C498.9,486.5,498.3,485.4,497.8,484.3z"/>
	<path class="st2984" d="M509.7,458.3c-0.3,0-0.6,0-0.9,0.1c0,0-0.1-0.1-0.1-0.1c-1.4-1.7-0.5-3.4,0-5.1c1.2-3.5,2.2-7.1,3.2-10.6
		c0.9-1.8,1.1-3.7,1.8-5.5c0.4-1.1,0.8-2.8,2.6-1.3c0.9,1.7,0.7,3.6,0.7,5.4c-0.3,3.1-1.1,6.2-2.3,9.1
		C512.8,452.7,512.8,456.5,509.7,458.3z"/>
	<path class="st2986" d="M509.7,458.3c1.3-2.7,1.7-5.8,4-8c1.7,1.9,0.5,3.7,0,5.6c-0.3,0.9-0.8,1.8-1.2,2.6
		c-0.7,0.9-1.1,2.2-2.6,1.9C509.8,459.7,509.8,459,509.7,458.3z"/>
	<path class="st2987" d="M509.8,460.4c0.6-0.7,1.3-1.4,1.9-2.1c1.5,2.5,1.6,4.5-1.9,5c-0.3-0.3-0.7-0.7-1-1
		C509.1,461.7,509.5,461,509.8,460.4z"/>
	<path class="st2988" d="M488.7,397.4c1,4,2,8,3,11.9c0,0.7,0.1,1.4,0.1,2c1.1,1.5-0.2,3.4,0.9,4.9c0,0.4,0.1,0.7,0.1,1.1
		c0.5,0.6,0.5,1.2,0.3,1.9c-2.9,0-5.8,0.4-8.3-1.6c-1.6-2.5-1.3-5.3-1.4-8.1c0.3-5.5-0.2-10.9-1.9-16.2c0.3-0.4,0.8-0.7,1.3-0.9
		c2.1-0.5,3.8-0.1,4.9,1.9l0,0C488.2,395.4,487.6,396.7,488.7,397.4z"/>
	<path class="st2989" d="M477.8,463.3c-2-0.3-4.2,0.8-6-1c-0.5-1.7,1.2-2.2,1.8-3.3c1.9-0.6,3.7-1.5,5.4-2.6
		c0.6-0.3,1.2-0.6,1.9-0.8c2.2-0.4,3.3-2.1,4.3-3.8c0.9-1.5,1.3-3.5,3.4-4.1c0.5,0,0.9,0.2,1.2,0.5c-0.7,3.3-2,6.3-4,9
		c-0.7,1.1-1.5,2.1-2.7,2.6c-1.3,0.7-2.4,1.6-3.5,2.6C479,463,478.4,463.3,477.8,463.3z"/>
	<path class="st2793" d="M477.8,463.3c0.3-0.3,0.7-0.7,1-1c1.4-0.9,2.6-2.1,4-3c1-0.7,2-1.3,3-2c-1.2,7.3-9.9,12.1-18,10
		C470.3,464,474.7,465.1,477.8,463.3z"/>
	<path class="st2983" d="M489.8,448.3c-0.4,0-0.7,0-1.1,0c-0.5-0.6-0.7-1.4-0.6-2.2c1-4.2,1.7-8.5,2.8-12.7c1.3-2.7-0.4-5.5,0.1-8.2
		c0.7-1.9,0.1-4.2,1.7-5.9c0.1-0.6,0.1-1.3,0.2-1.9C495,428,494,438.3,489.8,448.3z"/>
	<path class="st2983" d="M492.7,416.3c-1.6-1.4-0.8-3.2-0.9-4.9C493.3,412.8,492.6,414.6,492.7,416.3z"/>
	<path class="st2990" d="M473.7,459.3c0.2,1.6-1.4,2-2,3c-0.3,0-0.6-0.1-0.9-0.1c0.3-1,0.6-2,1-2.9c0,0,0,0,0,0
		C472.4,458.8,473.1,458.7,473.7,459.3z"/>
	<path class="st2991" d="M473.7,459.3c-0.7,0-1.3,0-2-0.1c1.4-1.5,3.9-1,5-2.9c0.8-0.4,1-1.2,1-2c2.3-0.1,3.1,0.7,1.8,2.9
		C477.8,458.3,476,459.5,473.7,459.3z"/>
	<path class="st2992" d="M497.8,484.3c1.3,0,1.9,0.7,2,1.9c0.3,2.3-0.9,2.8-2.9,2.2c-0.3-0.4-0.7-0.8-1-1.1c-1.4-2.8-4.6-4.5-5-8
		C494.3,479.3,495.5,482.6,497.8,484.3z"/>
	<path class="st2993" d="M769.4,301.5c0,0.7,0,1.3,0,2c-1.1,1.6-0.2,3.3-0.3,3.8c0.3,1.7,1,1,0.3,1.1c-0.2,0.1-0.4,0-0.4-0.3
		c-0.1-0.9-0.3-1.9,0.4-2.7c1.8,1.8,0.7,4,1,6c-2.4,5.5-0.1,11.3-1.3,17.1c-1.6-2.9-0.8-6.4-2.7-9.1c0.9-3.6,0.6-7-2-10
		c0-0.3,0-0.7,0-1c-0.8-1.1-0.5-2.6-1.4-3.6c-0.2-0.4-0.4-0.8-0.5-1.3c-0.3-1.6,0-3.2,0.4-5.2c-0.7,1-0.8,1.8-1.4,2.3
		c-0.4,0.2-0.9,0.2-1.3-0.1c0,0,0,0,0,0c0.3-3.8,0.2-7.5-4.3-9c-0.5-1-1.1-2-1.6-3c-0.7-1.1-0.5-1.9,0.4-2.7
		c4.4,0.6,5.3-1.6,6.1-5.6c0.7-3.8,1.3-7.4,0.9-11.2c-0.2-1.6-1.1-4.5,2.6-3.5c1.7,9.3,3.3,18.6,5,28
		C770.2,296.2,770.2,298.8,769.4,301.5z"/>
	<path class="st2994" d="M753.3,295.6c-2.7-3.6-4.1-8.1-7.9-10.9c-0.2-0.5-0.3-0.9-0.1-1.4c1.6-2.1,3.5-1.4,5.4-0.4
		c1,1,2.6,1.7,1.6,3.6c-1.5,2.2,1.4,2.9,1.5,4.5C753.7,292.6,756.5,294.4,753.3,295.6z"/>
	<path class="st2798" d="M752.2,286.5c-0.6-1-1.2-2-1.8-3c-0.7-0.6-1.5-1.1-1-2.3c5.1-1.6,7.2,0.2,6.1,5.2c-0.9,0.5-1,1.3-1,2.1
		C753.7,287.9,752.9,287.2,752.2,286.5z"/>
	<path class="st2995" d="M752.4,302.5c-1.9,2.4,1.5,3.9,1,6c-4.2,1.1-3.9-2.4-4.7-4.6c-2.2-6-5.5-11.1-11.5-14
		c-0.8-1.2-1.2-2.2-3.3-1.8c-3.5,0.7-6.7,3.1-10.5,2.1c-5.3-3.6-5.6-4.6-3.1-10c0.3-0.4,0.6-0.7,1-1c1.1-0.7,2.5-0.6,3.7-1.3
		c-0.3-1.5-2-1.2-2.6-2.1c-0.3-2.8,1.8-3.1,3.7-3.6c2-0.2,3.7,0.9,5.4,1.6c1.1,0.5,2.2,0.8,3.2,1.3c3.4,3.3,8.6,5,9.6,10.5
		c0.4,0.7,0.7,1.4,1.1,2.1c2,3.5,4,6.9,6.4,10.1C752,299.3,753.6,300.7,752.4,302.5z"/>
	<path class="st2996" d="M744.4,285.5c-2.5-4.1-7.2-6.1-10-9.9c0.3-0.3,0.7-0.5,1.2-0.6c1.7,0.1,2.9,1.2,4.2,2c2,2,4.8,3.5,5.5,6.6
		c0,0.4,0.1,0.7,0.1,1.1C745.1,285,744.7,285.2,744.4,285.5z"/>
	<path class="st2997" d="M751.5,297.5c-2.7-2.9-5.4-5.8-6-10c3,2.3,4.3,5.8,6,8.9c0.3,0.2,0.4,0.5,0.3,0.7
		C751.6,297.4,751.6,297.5,751.5,297.5z"/>
	<path class="st2761" d="M751.5,297.5c0-0.3,0-0.7,0-1c2.2,3,3.5,6.3,4,10c-1-1.3-2-2.7-3-4C752.1,300.8,751.8,299.2,751.5,297.5z"
		/>
	<path class="st2649" d="M760.3,300.6c0.4,0,0.8-0.1,1.2-0.1c2.1,0.6,0.9,2.9,2,4l0,0c0.2,1.1-0.2,1.5-1.3,1.1
		C761.1,304.1,760.4,302.5,760.3,300.6z"/>
	<path class="st2798" d="M762.1,305.6c0.4-0.4,0.9-0.7,1.3-1.1c1.5,1,0.9,2.6,1,4c-0.7-0.7-1.4-1.3-2-2
		C762.3,306.2,762.2,305.9,762.1,305.6z"/>
	<path class="st2998" d="M481.8,145.7c2,0.4,3.9,0.8,4,3.4c0.2,3.1,2.5,4.2,5,4.9c0.1,2-3.8,2-2.1,4.7c0.3,0.4,0.4,0.8,0.3,1.3
		c-1.1,2.2-2.4,4.1-5.3,3.7c2-3.3,1.3-5-2.8-5.6c-2.8-0.4-5.5-1.8-8.1-3.1c-4.6-2.2-1.7-5.4-1.1-8.3
		C475.1,146.3,478.5,146,481.8,145.7z"/>
	<path class="st2877" d="M483.8,163.7c1.7-1.3,3.3-2.7,5-4c0.8,0.7,0.9,1.5,0.7,2.5c-0.2,0.4-0.4,0.8-0.7,1.2c-1.4,1-1.8,2.5-2.2,4
		c-2.4,3.6-5,7-7.5,10.5c-1,1.4-2,3.2-4.2,2.9c-0.1-1.3-1-2.7,0-4c2.5-2.6,5.4-5.1,6-9C481.8,166.3,482.8,165,483.8,163.7z"/>
	<path class="st2999" d="M793.4,623.1c1.7,3.7,1,8.3,4.7,11.4c0.8,0.7,0.3,3.1,0.3,4.8c0,4.1-0.3,8.2,2,11.8c-3,2.5-5.1,6.3-9.6,6.7
		c-5.7,0.7-7-0.2-9-5.1c-1.8-4.3-1.9-8.9-2.4-13.5c2.5-3.8,1.9-8.7,4.3-12.5C787.2,626.3,789.9,623.5,793.4,623.1z"/>
	<path class="st3000" d="M793.4,623.1c-2.7,2-4.9,5.1-9,4c-0.2-1.2-1.4-1.9-1.7-3c2.4-5.1,3.2-11.1,7.9-15c0.9-0.8,1.5-1.8,1.8-2.9
		c1.3-5.5,4.3-9.8,9-13c2.7,0.3,2.3,5.1,6,4c0,0.7,0,1.3,0,2c-0.8,1.9-0.6,4-1,6c-1.3,3.1-0.3,7.2-4.4,9.2c-1.3,0.6-0.6,2.8-0.6,4.2
		c-0.1,3.1,0.3,6.3-1.8,9.1c-2.7-1.6-2-4.2-2.2-6.6c-0.1-1.2,0.7-3.2-1-3.4c-2.1-0.3-1.9,1.8-2,3.2
		C794.4,621.8,794.1,622.6,793.4,623.1z"/>
	<path class="st3001" d="M791.4,656.1c2.8,0.3,4.3-1.5,5.6-3.4c0.9-1.3,1.5-2.7,3.4-1.6c0,0.3,0,0.7,0,1c1.5,3-1.1,4.2-2.7,5.5
		c-4,3.2-8.8,5.3-13,8.2c-6.6,2.7-12.4,7.1-19.3,9.1c-1.1-0.1-1.9-0.5-2.3-1.6c3.2-4.4,7.6-7.3,12.7-9.1c1.4-0.5,2.9-0.5,4.2-1.1
		C783,659.6,788,659.2,791.4,656.1z"/>
	<path class="st3002" d="M784.4,665.1c3.3-3.5,8.1-4.9,11.8-7.8c1.8-1.4,3.9-2.5,4.2-5.1c0.6,0.3,1.3,0.7,1.9,1
		c0.1,0.4,0.2,0.7,0.3,1.1c-0.1,0.6-0.2,1.2-0.2,1.9c-4.2,5.1-9.8,7.9-16,9.9C785.6,666,784.9,665.7,784.4,665.1z"/>
	<path class="st2819" d="M806.4,605.2c-1.1-2.2-0.1-4.1,1-6C807.1,601.2,808.2,603.4,806.4,605.2z"/>
	<path class="st3003" d="M786.4,665.1c5.8-2.1,10.7-5.9,15.9-9c0.6,2.4-0.9,5,1,7.2c-0.7,1.7-2.3,1.7-3.8,1.9
		c-4.6,2.5-9,5.4-13.9,7.2c-1.8-0.7-3.1-2.1-4.4-3.3C781.7,666.1,784.6,666.3,786.4,665.1z"/>
	<path class="st3004" d="M726.5,137.7c0-0.8,0-1.6,0-2.5c0.1-1.5-0.4-3.4,1.4-3.9c2.3-0.6,2,1.9,2.3,3c0.6,2.5,2.6,2.7,3.6,1.7
		c5.6-5.5,7.5,0.5,10.3,3.1c0.9,0.8,1.2,2.3,1.8,3.6c1.2-0.6,0.2-2.5,1.6-3c-1.5,20.9,0.3,41.9-0.9,62.9c-1.8-2.2-3.9-3.6-6.9-2.6
		c-1-4.3-5.9-5.1-7.9-8.5c-0.7-1.2-1.3-2.8-3.2-2.8c-4-3.8-2.7-8.4-2-12.9c1-3.4,0.9-6.8,0-10.3c-0.4-1.7-0.3-3.4-0.4-5.1
		c-0.2-6.1,0.6-12.3-0.5-18.4C725.3,140.4,725.3,138.9,726.5,137.7z"/>
	<path class="st3005" d="M439.8,544.2c-0.7,0-1.3,0-2,0c0-0.3,0-0.7,0-1c2.5-0.8,4.9-1.9,7-3.5c5.2-4.1,8.4-3,9.5,3.5
		c1.8-0.8,1.1-3.1,2.7-4.4c1.7,1.9,1.7,4.8,3.8,6.4c3.8-0.3,5.4,1.6,5.2,5.3c-2.4,2.5-5.1,1.9-6.7-0.5c-1.8-2.7-3.8-3.8-6.9-3.6
		c-2,0.1-3.2-1.3-4-3c-0.4,2-1.9,2.7-3.6,2.7C443,546.1,441.4,545.1,439.8,544.2z"/>
	<path class="st2810" d="M465.8,550.2c-0.8-2.5-2.5-4.1-5-5c0-2,0-4,0-6c0,0,0.1,0,0.1,0c4.4,5.7,10.9,10,12.3,17.8
		c-1.2,1.1-1.7,0-2.3-0.8c-0.7-0.7-1.3-1.3-2-2C467.2,553.4,464.9,553,465.8,550.2z"/>
	<path class="st3006" d="M472.8,557.2c-0.5-3.8-3.5-6.2-5.8-8.8c-2.4-2.8-4.5-5.8-6.2-9.2c0.1-1.3-0.5-2.8,0.8-3.9
		c1.4,5.1,5,8.7,8.2,12.7c2.4,0.9,3.7,3.1,5.5,4.6c1.2,1.2,2.4,2.5,3.7,3.7c0.5,0.4,0.9,0.9,1.4,1.4c1,1.1,2.1,2.1,3.2,3.2
		c1.3,1.3,2.5,2.6,3.3,4.3c3.1,5.4,8.4,8.9,12.2,13.7c3.2,4,6.6,7.8,10,11.6c1.2,1.4,2.7,3.1,2,5.4c-1.6,1.3-2.6-0.2-3.8-0.7
		c-0.8-0.5-1.5-1.1-2.1-1.9c-4-5.2-8.5-9.9-12.9-14.8c-2.5-3.1-5.3-6-8.1-8.8C480.6,565.3,475.3,562.5,472.8,557.2z"/>
	<path class="st3007" d="M601.6,826.9c-0.8,1.5,2.2,6.7-3.6,3.5c-1.4-0.8-1.7,1.5-2.4,2.5c-1.7,1.4-2.9,0.2-4-1
		c-1.1-0.3-1.6-1.1-2.1-2c-2.5-4.8-8.1-6.4-14.7-4.1c-1.2,0.4-2.4,1.1-3.5,1.7c-2.7,1-4.3,3.6-6.6,5.1c-4,2.1-4.8-1.4-6.3-3.6
		c-1.5-1.9-4.6-2.6-4.3-5.8c1-2.1,2.9-3.1,5-3.4c2.5-0.3-0.4-1.2,0.4-1.6c0.5-0.9,1.4-1.2,2.3-1.4c3.5-0.6,6.8-2.3,10.7-1.4
		c2.1,0.5,3.9-1.1,4.9-2.8c2.4-4.1,5.5-7.1,10.6-7c1.4,0,2.4-1.1,3.3-2.1c1.7-1.6,3.6-1.9,5.8-1.4c2,0.5,3.2,2.2,5.1,2.9
		c1.8,0.6,3.2-0.3,4.4-1.5c0.8-0.8,1.8-1.5,2.9-1.8c1.9-0.6,4.2-1.5,5.3,0.6c1.2,2.2-1.3,2.9-2.6,3.9c-0.5,0.4-1,0.8-1.5,1.3
		c-0.8,0.8-1.5,1.7-1.7,2.9c-0.4,2.5-1.6,3.7-4.4,2.7c-2.8-1-3.4,0.7-3.3,3.1c0,0.5-0.1,0.9-0.2,1.4
		C599.6,820.7,599.7,823.8,601.6,826.9z"/>
	<path class="st3008" d="M559.7,877.8c-2,0.3-3.9,1.4-6,1c-0.4-0.4-0.4-0.9-0.2-1.4c1-1.7,2.5-2.4,4.3-2.7c1.9-0.2,3.8,0,5.6-0.6
		c3.7,0.2,6.9-0.9,9.9-3.1c3.6-2.4,6.9-5,10.8-7c2.2-0.7,1.6-2.1,0.9-3.6c-0.3-0.9-0.3-1.8-0.2-2.8c1.3-3.4,2.1-7,4.5-9.9
		c0.5-0.3,0.9-0.3,1.4,0c-1.1,4.9-2.4,9.6-1.4,15c0.6,3.3-4.7,4.3-7.2,6.4c-0.4,0.3-1.7-0.2-1.8,0
		C575.3,877,567.2,876.6,559.7,877.8z"/>
	<path class="st2824" d="M580.7,880.8c-3.7,2.6-7.5,5.1-12,6C569.9,880.8,576.7,877.5,580.7,880.8z"/>
	<path class="st3009" d="M590.6,847.9c-0.3,0-0.6,0-0.8,0c-1.8-3,0.1-6.1-0.2-9.1c-0.2-2.1-0.2-4.3-0.7-6.4c-0.1-0.8,0-1.5,0.3-2.3
		c0.4-0.5,0.8-0.5,1.3-0.2c0.3,0.7,0.7,1.3,1,2C592.7,837.3,592.9,842.7,590.6,847.9z"/>
	<path class="st2658" d="M597.6,856.9c0,1,0,2,0,3c-1.8,1-0.1,4.3-2.9,4.5c-1.1,0.1-1.1-1.2-1-1.9
		C594.2,860.1,593.7,856.8,597.6,856.9z"/>
	<path class="st2824" d="M559.5,881.4c2.5-0.6,4.4-1.2,6.3-1.5c1.1-0.2,1.8,0.7,1.8,1.8c0,1.2-0.6,2-1.8,1.5
		C564,882.4,561.9,883.5,559.5,881.4z"/>
	<path class="st2658" d="M593.7,850.9c1.8,0.8,2.1,2.3,2,4C593,854.5,593.8,852.4,593.7,850.9z"/>
	<path class="st3010" d="M600.6,816.9c-0.8-1.6-1.7-3.4-0.2-5c1.5-1.6,3.2-1.1,4.8,0.1c1.8,1.3,2.3,0.1,2.3-1.5c0-1.6,0.5-2.9,2-3.6
		c0.6,0.5,0.8,1.2,0.8,2c-0.2,2-0.7,4.1-0.6,6.2c0,1.5,0.6,2.8,1.5,3.9c0.5,0.6,1.2,1.1,1.9,1.3c1.8,0.8,3.8-0.7,5.6,0.5
		c1.5,2.7-1,5.5,0,8.2c-1.3,1.3-3.2,1.7-4.8,2.5c-2.8,1.5-3.9,3.4-2.1,6.4c0.6,0.9,1.3,1.7,1.9,2.7c0.2,0.4,0.3,0.8,0.3,1.3
		c-0.1,0.4-0.3,0.8-0.6,1.1c0.4,3.9-1.3,6.6-5,8c-2.8-2.1-4.8-4.5-6.2-8c-1.9-4.6-1.1-7.6,2.6-10.3c0.8-0.6,1.3-1.5,2-2.1
		c1.8-1.4,4-3.5,1.9-5.1c-1.9-1.4-5.5-2-7.3,1.5C597.8,823.9,597.6,820.6,600.6,816.9c0.7,0.7,1.6,0.9,2.8,1.4
		C602.4,817.6,601.4,817.6,600.6,816.9z"/>
	<path class="st3011" d="M613.6,842.9c0-0.3,0-0.7,0-1c2.1-2.4,5.2-2.6,7.8-3.8c6.2-2,12.1-4.5,17.7-7.9c1.1-0.7,2.3-1.2,3.6-0.4
		c0.9,1.2,0.7,2.4-0.2,3.5c-1.1,1-2.4,1.6-3.7,2.3c-1.7,0.8-3.3,1.7-5,2.5c-1.5,0.7-3.1,1.7-4.6,1.4c-3.2-0.7,0.3,1.2-0.7,1.7
		c-1.4,0.8-2.9,1.5-4.1,2.6c-2.1,1.4-3.8,3.8-6.8,3.1c-0.8-0.1-1.6-0.2-1.3-1.4C617,843,615.4,842.9,613.6,842.9z"/>
	<path class="st3012" d="M617.7,846.9c2-1.3,4-2.6,6-4c2.7-0.7,5.1-2.3,7.8-2.7c0.8,0.3,1.4,0.7,1.2,1.7c-4.4,2.8-8.8,5.8-13.3,8.5
		c-2,1.2-4.2,3.4-7.2,1.6C613.5,849.4,616.6,849.2,617.7,846.9z"/>
	<path class="st3013" d="M632.6,841.9c-0.3-0.3-0.7-0.7-1-1c-0.5-1.5,0.6-2.1,1.6-2.7c2.7-1,5.3-2.3,7.9-3.8
		c0.4-0.2,0.8-0.4,1.2-0.6c3.4-0.7,5.2-5.1,9.4-4c0.4,0.2,0.8,0.5,1.1,0.9c0.2,0.5,0.1,0.9-0.2,1.2l0,0c-3.2,2.8-6.7,5.1-11,6.1
		C638.5,839.1,635.9,841.3,632.6,841.9z"/>
	<path class="st3014" d="M641.6,837.9c3.7-1.9,6.9-4.7,11-6.1c-0.3,1-0.7,2-1,3c-3.1,0.5-5.5,2.4-8,4
		C642.8,838.9,642.1,838.7,641.6,837.9z"/>
	<path class="st3015" d="M679.5,816.8c-5.2,1.7-9.8,4.6-14,8.1c-0.8,0-1.5,0.2-2,1c-3.4,2.3-6.6,4.9-11,5.1v0
		c-1.3-1.9,0.3-2.7,1.5-3.4c4.1-2.7,6.9-6.8,11.3-9.2c3.5-2.3,6.7-4.9,10.8-6c1.5-0.4,2.9-1.2,4.3-1.8c0.4-0.1,0.9-0.2,1.4-0.2
		c3.4,0.3,6.2-3.4,9.8-1.3C688.5,812.9,682.7,812.9,679.5,816.8z"/>
	<path class="st3016" d="M652.6,830.9c3.7-1.7,7.3-3.4,11-5.1c-2.7,3.8-6.6,5.4-11,6C652.6,831.5,652.6,831.2,652.6,830.9z"/>
	<path class="st3017" d="M816.4,553.2c-1.4,2.9,0,6.7-3,9c-1,1.3-0.4,2.9-0.5,4.3c-0.1,1.5-0.5,2.8-2.1,3c-1.8,0.3-2.2-1.2-2.5-2.5
		c-0.3-1.3-0.1-2.7-1.1-4c-2.8,1.2-3.3,5.1-6.6,5.6c-2.6-0.1-4.4-1.9-6.4-3.2c-0.7-2.7,1.2-4.6,2.1-6.8c2.4-4,3.9-9,10-9
		c1.4,0,1-1.9,1.4-2.9c0.7-1.9,1.7-3.4,4-2.7c2.3,0.7,2.6,2.3,1.5,4.3C812.6,550.9,814.4,552.1,816.4,553.2z"/>
	<path class="st3018" d="M816.4,521.2c0,1.3-0.1,2.7,0.2,3.9c0.6,2.7,3.2,5.6-1,7.7c-0.4,0.2-0.1,1.5-0.1,2.4
		c-1.1,1.5-2.1,1.5-2.8-0.3c-0.7-1.9-0.3-3.9-0.5-6c-1.2,0.6-1.2,1.8-1.6,2.8c-0.1,0.4-0.3,0.9-0.5,1.3c-0.7,1.8-1.2,3.7-1.4,5.7
		c-0.2,1.3-0.5,2.7-1.9,3.2c-5.6,2.1-8.5,6.9-11.7,11.4c-0.7,0.8-1.5,1.3-2.6,1.1c-2.1-1.3-2.2-3.2-1.5-5.2c0.7-1.8,0.9-3.5-0.1-5.3
		c0.8-6,5.5-9.6,9.9-11.3c6.6-2.6,8.5-7.2,9.4-13.1c0.1-1,0.2-2,0.5-2.9c0.4-1.2,1-2.2,2.3-2.7c4.3,0.5,1.3,4.5,3,6.2
		C816.1,520.4,816.3,520.8,816.4,521.2z"/>
	<path class="st3019" d="M794.4,553.2c1-5.6,5.6-8.7,9.5-11.6c1.9-1.4,3.6-1.7,3.6-4.5c0-1.7,0.5-3.5,1.9-4.9
		c0.6,0.3,0.7,1.4,1.6,1.4c0.1-1.2-1-1-1.6-1.4c-0.2-2.7,1.8-4.4,3-7.2c1.6,3.1,0.8,5.7,1,8.2c0.1,1.3-0.2,2.9,2,2
		c-0.6,4.3,1.6,9.1-2,13c-0.2-1.6,0.3-3.9-2.3-3.3c-3.3,0.8-2.1,4.2-3,6.5c-4.1-2-6.6,0.5-8.3,3.5c-0.8,1.4-1.6,2.9-2.4,4.3
		C794.1,558.6,794,558.4,794.4,553.2z"/>
	<path class="st3020" d="M816.4,521.2c-0.3,0-0.7,0-1,0c-1.4-1.5-0.3-3.3-0.5-5c-0.1-0.9,0.5-2.5-1.5-2c-0.7,0.7-1.3,1.3-2,2
		c-1.2-0.9-1.9-2.1-2.7-3.3c-0.5-1.2-0.6-2.4-0.6-3.6c0.2-3-1.7-5.6-1.8-8.5c0-0.6,0-1.3,0.1-1.9c0.3-1.4,0.7-3.1,2.2-3.5
		c1.6-0.4,1.8,1.5,2.6,2.4c0.3,0.4,0.8,0.5,1.3,0.5c2,6.3,4,12.6,5.9,19C817.4,518.4,820.4,521.6,816.4,521.2z"/>
	<path class="st3021" d="M812.4,498.3c-2.1,1.9-2.1,1.9-3.4-2.1c-1.7,0.9-1.6,2.6-1.6,4.1c-1.5,2.7-4.2,2.3-6.6,2.3
		c-4,0-7.8,0.1-11.5,2.2c-2.9,1.6-6.5,1.1-9.7,0.6c-2.3-0.4-4.4-1.9-7.1-0.7c-1.2,0.6-2.4-0.9-3.1-2c-0.9-1.3-1.6-2.9-3.1-4.1
		c-0.1,2.7,1.3,5.5-0.9,7.7c-2.4-2.1-1.9-5.3-3-8c-1.7-4.6-5-8.6-5.6-13.6c-0.3-2.4,1.4-5.5-3.2-5.8c-1.5-0.1-4-11.6-3.2-13
		c0.6-1.2,1.6-1,2.5-0.4c1,0.6,1.8,1.5,2.5,2.4c2.6,3.3,6,4.5,10.1,4.2c5-0.4,9.9-0.3,10.5,6.6c0.1,1.5,1.6,2.4,2.9,2.1
		c3.7-0.9,7.7,0.6,11.3-1.1c2.1-0.9,2.6,0.2,3.2,1.7c-0.1,3.7-0.1,7.1,5.3,4.2c0.6-0.3,1.8,0.5,2.7,0.8l0,0c1,1.4,1.5,4.2,3.8,1.1
		c0.5-0.7,1.4-0.7,2.1-0.1c0.6,0.2,1.2,0.4,1.9,0.6C808,492,812.1,494.6,812.4,498.3z"/>
	<path class="st3022" d="M803.4,481.3c-1.7,0-3.6-0.1-3.8-2.3c-0.2-1.9,1.1-2.6,2.8-2.7c1,1.3,2,2.7,3,4
		C805,481.1,804.2,481.3,803.4,481.3z"/>
	<path class="st2891" d="M807.4,487.3c-0.5,0.2-1,0.2-1.4,0.5c-1.1,0.9-0.1,4-2.8,3.2c-2.3-0.7-1.7-2.9-1.8-4.7
		C803.1,488.5,805.7,484.9,807.4,487.3z"/>
	<path class="st3023" d="M801.4,593.2c-0.2,1.1-0.2,3.2-0.6,3.2c-6.7,1.1-5.2,7.5-7.3,11.4c-0.7,1.3-1.3,1.9-2.4,2.7
		c-2,1.4-3.9,3.1-3.8,6c0.2,3.6-4.3,5-3.9,8.7c-2.6,2.1-5.5,1.6-8.3,0.7c-2-1.8-0.9-3.7,0.2-5.2c2.8-3.5,3.3-7.3,2.2-11.5
		c-1.4-5.5,0.9-9.1,5.8-11.7c2.7-1.5,5.3-3.4,8.3-4.3c1.7-0.4,3-1.4,4-2.7c2.3-2.9,4.1-1.9,5.8,0.7
		C801.4,591.8,801.4,592.5,801.4,593.2z"/>
	<path class="st3024" d="M801.4,591.2c-2-1-3.9-2.3-5.4,0.8c-0.6,1.3-1.9,2.2-3.6,2.2c-1.3-1.7-0.6-3.5,0-5c1.1-2.6,0.8-5.4,1.1-8.1
		c0-5.5,2.4-9.7,6.9-12.8c2.3-1.5,3.4-3.9,4.8-6.1c0.6-0.9,1.5-1.9,2.8-1.4c1,0.4,1.1,1.5,1.4,2.5c0.5,1.8-1.5,5.2,1.5,5.2
		c2.6,0,0.9-3.7,1.9-5.5c0.2-0.3,0.4-0.5,0.6-0.7c0.2,7-0.5,13.7-5.2,20c-1.2-2.5-0.3-4.8-2.7-6.5
		C801.4,580.1,805.2,586.8,801.4,591.2z"/>
	<path class="st3025" d="M554.7,822.9c0.5,2.9,3.2,3.5,5,5c-1.1,1.4-1.6,2.8,0.2,4.1c1.1,0.8,1.8,1.8,1.6,3.2
		c-0.1,0.5-0.2,0.9-0.5,1.3c-0.6,1-1.3,2.5-2.2,2.5c-3.9-0.1-2.1,2.6-2.3,4.2c-0.2,0.5-0.4,0.8-0.7,1.2c-0.4,0.3-1.1,0.7-1.3,0.5
		c-4-4.3-7.4-2.2-10.8,0.9c-2.1-0.6-1.6-3.1-3-4.3c-2.1,0.3-1.4,3.3-3.4,3.6c-1.5-1.3,1.5-3.2-0.6-4.3c0.2-4.1,3.3-7.1,4.1-10.9
		c0.8-2.4,1.8-4.7,4.5-5.4c1.1-0.1,2.2-0.1,3.3-0.1c1.6,0.1,2.9-0.7,4.3-1.5c0.2-0.1,0.4-0.2,0.6-0.3
		C553.9,822.5,554.3,822.6,554.7,822.9z"/>
	<path class="st3026" d="M543.7,845.9c1.2-2.5,2-5.2,6-4.7c2,0.2,3.8-0.8,4.1,2.3c0.1,1.3,1.2,0.6,1.9,0.4c1,4-0.8,6.9-3.4,9.6
		c-3.3-0.2-3.4,4.3-6.6,4.4C540.1,854.7,543.3,850,543.7,845.9z"/>
	<path class="st3027" d="M545.7,857.9c2.2-1.4,1.7-6.1,6-5c-0.5,6.6,3,13,1,19.6c-0.5,0.7-1.1,1.2-2,1.4
		C546.9,869.2,542.1,864.8,545.7,857.9z"/>
	<path class="st3028" d="M551.7,872.8c1.1-2.7,1-5.3-0.1-8c-1.7-4-1-8,0.1-11.9c2.3-2.6,2.9-5.9,4-9c0,0,0,0,0,0c1.1-3,3.5-5.2,5-8
		l0,0c0.9-1.8,2.4-2.9,4-4c1.6-2.1,3-4.5,6-5c0.6-0.5,1.3-0.5,2-0.2c0,4.2-4.5,4.7-6.2,7.6c-1.7,1.4-2.9,3.2-4.1,5
		c-0.7,1.1-1.4,2.2-2.1,3.3c-1.5,2.2-3,4.4-3.9,6.9c-1,2.2-1.4,4.4-1.9,6.7c-0.7,2.9-0.7,5.7,0.1,8.6c1.9,4.5,0.3,8.8-0.9,13.1
		c0,0.3,0,0.6,0,1c-0.8,0-1.6-0.2-2-1C551,876.2,551,874.5,551.7,872.8z"/>
	<path class="st3008" d="M551.7,872.8c0,1.7,0,3.3,0,5c-1.4-1.1-0.8-2.6-1-4C551,873.5,551.4,873.2,551.7,872.8z"/>
	<path class="st2969" d="M403,365.5c-0.7-0.1-1.4-0.1-2.2-0.2c3-2.5,6-4.7,10.4-4.4c-3.4-0.5-6.9-1-10.3-1.5c3.3-2.5,6.7,0,10,0
		c5.3,2.5,10.3,5.5,15,9c-0.6,1-1.5,2.4-2.4,0.8c-1.4-2.4-3.1-0.8-4.6-0.7c-0.9,0.5-1.8,0.3-2.7,0.2c-2.6-0.5-5.1-1.5-7.7-2.2
		c-1.4-0.6-2.9-0.1-4.3-0.2C403.7,366.1,403.3,365.8,403,365.5z"/>
	<path class="st2969" d="M400.9,359.4c-2.5,2.3-4.6,4.9-8,6C394.9,362.5,397.1,360,400.9,359.4z"/>
	<path class="st2888" d="M755.5,286.5c0.1-4.2-2.4-5.3-6-5c-3.7,0.2-4.4-3-5.9-5.3c-0.7-2.3-3-3.2-4.4-4.9c-0.3-0.7-0.3-1.3,0.3-1.9
		c4.5,2,7.1-3.9,11.5-2.1c-0.2-0.9-0.3-1.4-0.4-1.4c-7.1-1.6-7.1-1.6-7.1-9c0-1.8,0-3.6,0-5.4c4.8-1.6,9.1-4.5,14-6
		c3,6.4,6,12.8,7,20c-2.2,0.3-2,1.7-1.4,3.3c2.3,6.6-2.3,12.6-1.8,19.2c0.2,1.8-1.4,1.1-2.5,0.4
		C757.8,287.6,757.6,285.3,755.5,286.5z"/>
	<path class="st2709" d="M757.5,245.6c-0.1,1.4,0.4,3-1,4.2c-1.9-1.4-3.4-4.3-5.3,0.4c-1.3,3.1-5,0.8-7.6,1.4
		c1.3-3.9,2.9-7.7,2.1-12.1c-0.3-1.6-0.1-3.3-1-4.8c-1.3-2-2.7-3.6,0.3-5.7c1.9-1.3-0.7-2.3-1.3-3.5c1,0,2,0,3,0
		c2.6,3.8,1.5,8.2,0.9,12.6c3-0.5,2.6-3.8,5.1-3.6c0,0.8,0.2,1.6,1,2c1,1.2-0.2,2.9,1,4C755.5,242.2,755.9,244.3,757.5,245.6z"/>
	<path class="st2798" d="M769.4,301.5c0-2.7,0-5.3,0-8C771,296.2,771,298.8,769.4,301.5z"/>
	<path class="st2888" d="M754.5,240.6c-3.5-0.6-0.5-2.7-1-4C754.9,237.6,755.4,238.9,754.5,240.6z"/>
	<path class="st2798" d="M769.4,305.5c0,0.9,0,1.9,0,2.8c-1-1.7-1.5-3.3,0-4.8C769.4,304.2,769.4,304.8,769.4,305.5z"/>
	<path class="st2832" d="M596.7,89.8c1.1-2.9,4.1-3,6.3-4.1c1.9-0.9,4-1.5,4.6-3.9c3.2-3.3,7.8-2.8,11.7-3.9
		c1.3-0.4,2.1,0.5,2.3,1.9c-3.5,1.8-7.8,2.2-10.4,5.7c-2.6,2.7-6.3,2.9-9.4,4.4C600.1,90.6,598.3,91.3,596.7,89.8z"/>
	<path class="st3029" d="M610.6,84.7c2.6-3.9,6.3-5.5,11-5c3.4-0.1,6.4-3.3,10-1c0.1,0.9-0.4,1.4-1,2c-3,0.7-6,1.3-9,2
		C618.1,84.5,614.9,87.3,610.6,84.7z"/>
	<path class="st2832" d="M630.6,80.7c0-0.8,0.2-1.6,1-2c1.1,0,2.3,0.2,3-1c1.9-0.1,3.7,0.4,5,2C636.6,80.1,633.6,80.4,630.6,80.7z"
		/>
	<path class="st3029" d="M660.6,77.8c0,0.3,0,0.7,0,1c-4.3,0-8.6,0-13,0C651.9,77.8,656.1,76.2,660.6,77.8z"/>
	<path class="st3030" d="M610.6,84.7c3.9,0.9,7.1-2.5,11-2c1.8,1.8,4,0.7,6,1c-3.7,1.1-7.3,2.1-11.1,3.2c0.1,1.3,1.7,0.7,2.1,1.6
		c-0.6,1.1-1.7,1.3-2.8,1.5c-1.6,0.1-3.1,0.1-4.7,0.2c-2.7,0-5.1,2-7.9,1.2c-1.3-0.4,0.4-2.6-1.5-2.6
		C604.4,87,608.1,87.2,610.6,84.7z"/>
	<path class="st2831" d="M673.6,83.7c-2.4-0.3-5.3,1.2-7-1.9C669,82,671.9,80.8,673.6,83.7z"/>
	<path class="st3031" d="M385.9,631.1c-1.9-2-3-4-0.6-6.5c1-1.1,0.6-2.5,0.6-3.8c-0.1-5.9,0-11.6,3.3-17.1c1.6-2.7,0.6-7,0.7-10.5
		c5.2,0.5,7.8-3.3,9.6-6.9c1.6-3.4,3.5-4.3,6.9-4.5c3.7-0.3,7-2.4,9.4-5.2c2.5-2.9,5.1-5.6,9.1-5.2c4.9,0.5,9-3.2,13.9-2.2
		c0.9-0.8,1.8-0.9,2.9-0.3c0.6,4.3-2.7,3.9-5.3,4.6c-7.1,1.9-13.3,5.8-19.9,8.9c-0.8,0.4-1.2,1.6-1.7,2.4c-0.3,0.4-0.6,0.8-1,1.1
		c-8.9,4.1-14.4,11.6-19.3,19.6c-2.1,3.2-3.5,6.3-2.3,10.6C393.7,621,389.9,628.9,385.9,631.1z"/>
	<path class="st3032" d="M441.8,569.2c-1,0-2,0-3,0c2.8-4.3,7.7-3.4,11.7-4.5c3.3-0.9,6.4-1.8,9.3-3.5c3.2,0.7,6.7-1.4,9.9,0.9
		c1,0.7,2.4-0.5,3.1-1.9c4,0.4,6,2.7,6.6,6.5c-4.7,1.4-9.5,0.1-14.3,0.2c-3.9,0.1-7.4,0.6-10.9,2.2
		C450.1,570.9,446,571.5,441.8,569.2z"/>
	<path class="st3033" d="M777.4,702c-0.7,0.7-1.3,1.3-2,2c-3.4,4.2-8.8,5.1-13,8c-0.1,0.2-0.2,0.4-0.2,0.6c-0.8,1.1-1.6,2.2-2.7,3
		c-0.4,0.3-0.8,0.5-1.2,0.8c-5.5,4.3-11.7,6.6-18.7,6.9c-0.8-1.1-0.1-1.9,0.5-2.7c1.4-1.2,2.9-2.3,4.5-3c9.6-4.1,18.2-10,27.4-14.9
		c1.3-0.7,2.5-1.7,4.1-1.5C776.7,701.4,777.1,701.6,777.4,702z"/>
	<path class="st3034" d="M758.5,715c0.3,0,0.7,0,1,0c1.3-0.7,2.4-1.6,3-3c0.8,0,1.8,0.2,2.5-0.1c4.5-2.2,6.1,0,6.5,4.1
		c0,0.3,0,0.7,0,1c-2.7,2-5.3,4-8,6c-0.3-0.3-0.7-0.7-1-1C757.1,720.1,756.8,719.6,758.5,715z"/>
	<path class="st2677" d="M792.4,744c1.2-5.5,2.5-10.9,5-16C798.6,734.2,795.7,739.2,792.4,744z"/>
	<path class="st2677" d="M797.4,726c-1.9-1.2-3.7-2.5-4-5C796.3,721.4,797.5,723.2,797.4,726z"/>
	<path class="st3035" d="M785.4,714c-3.3,0-6.7,0-10,0C778.8,712.5,782.1,712.5,785.4,714z"/>
	<path class="st3036" d="M789.5,738.7c-1,5.4-3.6,9.9-6.8,14.2c-0.6,0.5-1.3,0.7-2.1,0.7c-0.8-0.1-1.4-0.6-1.6-1.3
		c0.1-3.1,2.8-4.9,3.8-7.5c1.9-3,4.8-5.7,3.8-9.9c-1.6-7.3-3.4-8.1-9.6-4.2c-1.3,0.8-2.4,1.7-3.9,2.2c-1.5,0.3-2.9,0.4-3.8-1.1
		c1.2-3-3.6-3.8-2.5-6.8c1-2.8,4-3.3,5.8-5.2c1.8-0.8,3.4-2,5.5-2.4c3.1-0.5,6.1-0.7,8.9,1.2c2.2,0.9,2.9,3.3,4.5,4.8
		c0.1,0.5,0.1,1,0.2,1.5c0,0.3,0,0.5-0.1,0.8C791.2,730,792.1,734.6,789.5,738.7z"/>
	<path class="st3037" d="M787.3,719.4c-3.1-1.8-6.4-1.6-9.8-1.5c-0.4-0.2-0.5-0.4-0.4-0.6c0.1-0.2,0.2-0.3,0.3-0.3l0.2-0.1
		c2.6-0.6,5.2-0.6,7.8,0c0.7,0.4,1.4,0.7,2.1,1.1C787.8,718.6,787.8,719,787.3,719.4z"/>
	<path class="st3038" d="M777.4,717c0,0.3,0.1,0.6,0.1,0.9c-1.7,0.7-3.3,1.5-5,2.2c-0.4,0.4-0.8,0.3-1-0.2c1.1-0.9,2.1-1.8,3.2-2.8
		C775.6,717.1,776.5,717.1,777.4,717z"/>
	<path class="st3039" d="M792.3,725c-0.2,3,0.9,6.1-0.8,9c-1.3-2.9-0.6-6-0.4-9c0,0,0.3-0.2,0.3-0.2
		C791.8,724.9,792.1,724.9,792.3,725z"/>
	<path class="st3040" d="M785.4,717c-2.6,0-5.2,0-7.8,0C780.2,715.4,782.8,715.5,785.4,717z"/>
	<path class="st3041" d="M787.3,719.4c0-0.5,0.1-0.9,0.1-1.4c2.1,1.1,3.5,2.8,3.9,5.2C789,723,788.7,720.7,787.3,719.4z"/>
	<path class="st3042" d="M776.4,702c-7.4,3.1-13.9,7.9-21,11.6c-4.9,2.5-10,4.9-14.9,7.4c-1,0.3-1.2-0.3-1.3-1.1
		c1.1-2.7,3.8-3.3,6-4.6c2.2-1,4.4-2.2,6.6-3.3c1.2-0.6,2.4-1.2,3.5-2c3.4-2,6.8-4,10.3-5.9c3.8-1.3,7-3.8,10.5-5.6
		c2.3-1.2,4.5-3,7.3-1.5c0,0,0,0,0,0c-0.3,0.7-0.7,1.3-1,2C780.6,700.4,778.5,701.1,776.4,702z"/>
	<path class="st2836" d="M776.4,702c1.7-1.6,3.7-2.6,6-3c-1,2-2.9,2.7-5,3C777.1,702,776.8,702,776.4,702z"/>
	<path class="st3043" d="M747.5,700c3.9,1.5-2.8,2.1-0.1,3.6c1.8,1,4.1-2.1,6.1,0.4c-16.4,8.5-31.9,18.6-48.9,26
		c-1.4,0.9-2.9,1.6-4.3,2.5c-2.7-0.4-4.5-2.2-6-4.3c0.4-1.6,1.6-2.4,3-3.1c6.8-3.4,13.2-7.4,20.1-10.7c8.6-4.3,17-8.8,25.6-13.1
		C744.3,700.5,745.6,699.2,747.5,700z"/>
	<path class="st3044" d="M701.6,734.1c15.6-7.1,30.1-16.1,45.2-24c3.5-1.8,6.8-4,10.7-5.2c0.2,4.4-3.9,4.4-6.4,6
		c-2.6,1-5,2.4-7.2,4.1c-3,3-7,4.5-10.7,6.3c-6.4,2.9-12.4,6.4-18.6,9.7c-7.2,3.8-14.4,7.4-21.6,11.3c-1.8,0.9-3.6,1.8-5.1,3
		c-10,6-20.8,10.5-31.4,15.4c-1.1-0.1-2-0.3-2.2-1.6c0.6-1.9,2.3-2.9,3.9-3.6c14-6.3,26.9-14.9,41-21
		C699.9,734.2,700.7,734,701.6,734.1z"/>
	<path class="st3045" d="M750.5,710c2.5-1.5,5.7-2,7-5.1c4.4-3.1,9.1-5.7,13.9-7.9c6.1-0.5,10.7-4.7,16.1-6.9c1.5,0.9,4-2.6,5,1
		c-3,1.9-7,2.4-9,6c-4.8,0.7-8.5,3.6-12.4,6c-1.7,1-3.3,2.1-5.5,2c-3.6,1.7-7.2,3.2-10.1,6c-1,0.7-2,1.3-3,2
		C751.2,712.4,749.8,711.9,750.5,710z"/>
	<path class="st3046" d="M701.6,734.1c-8.1,4.4-16.2,8.7-24.3,13.1c-7.5,4-15,8.1-22.6,12.1c-1.1,1.5-2.5,2.4-4.4,2.1
		c-0.8-0.3-1.1-0.9-1.1-1.7c1.7-3.4,5-4.6,8.1-6.1c8.8-4.1,17.4-8.5,25.6-13.7c1.6-1,3.4-1.1,5.1-1.7c2.1-0.8,4-2.1,6.1-3
		c2-1.2,4-2.3,6.3-2.6c0.5-0.1,0.9,0.1,1.2,0.4C701.5,733.3,701.5,733.7,701.6,734.1z"/>
	<path class="st3047" d="M701.5,733c-0.3,0-0.7,0-1,0c-0.3-0.2-0.5-0.4-0.3-0.6c0.1-0.2,0.2-0.3,0.3-0.3c1-1.3,2.4-1.8,4-2
		C703.5,731,702.5,732,701.5,733z"/>
	<path class="st3048" d="M792.4,691c-1.3-2-3.7,1.5-5-1c3.2-2.8,7.2-3.6,11-5c1.1-0.6,1.6,0.1,2,1C798.5,689,795,689.3,792.4,691z"
		/>
	<path class="st3049" d="M800.4,686.1c-0.7-0.3-1.3-0.7-2-1c1.3-1.2,3-2.2,4-3.6c2-2.8,3-1.3,4,0.6
		C805.3,684.8,802.6,684.9,800.4,686.1z"/>
	<path class="st3050" d="M488.8,162.6c0-1,0-2,0-3c0-0.3,0-0.7,0-1c3.7-0.4,3.7-5.2,7-6c-0.3,1-0.6,2.1-1,3.1
		C494.7,159.7,492.2,161.6,488.8,162.6z"/>
	<path class="st3051" d="M488.8,162.6c2-2.3,4-4.6,6-6.9c0.3,0,0.6,0,1,0c0,0.8,0.2,1.5,1,1.9c0.4,0.3,0.6,0.7,0.7,1.2
		c0.2,1.9-1,3.1-2,4.4c-6.8,7.9-13.2,16.1-18.7,25c-1.1,1.2-1.9,3-4,2.4c-1.4-1.7,4.4-3.4-0.1-5c0.4-1.3,0.8-2.6,1.1-3.9
		c0.3-0.3,0.6-0.7,0.9-1c4.4-4.1,6.9-9.7,11-14C486.8,165.3,487.1,163.5,488.8,162.6L488.8,162.6z"/>
	<path class="st3052" d="M723.5,774.9c-1.8,5.9-7.2,5.8-11.7,6.8c-2,0.4-3.5,0.7-3.2,3.1c-3.3-0.4-1.8-4.6-4.3-5.6
		c-1-1.4-2.6-2.1-3.9-3.3c-0.6-2.6,3.8-3.5,2.2-6.4c-3.2-3.2,1.1-4.9,1.5-7.3c0.2-1.5-0.7-3.1,0.3-4.5c1.3-1,2.5,0.3,4,0.1
		c0.6-1.8-0.1-3.1-1.3-4.3c-1.3-1.3-3.1-2.1-4.1-3.7c-0.6-4.2-2-8.7,4.7-9c1-0.1,0.7-1.7,1.7-2.1c2-0.3,3.4,0.4,4.1,2.2
		c0.6,5.5,5.1,10.8,10,12.2c1.4,0.4,3.5,0.3,3.9,2.5c0.3,1.8-0.9,2.7-2.3,3.5c-2.3,1.3-3.1,3.2-2.1,5.9
		C724.1,768.3,723.3,771.7,723.5,774.9z"/>
	<path class="st3035" d="M758.5,715c-1.6,4,2.9,4.5,4,7c-1.6,2.5-4.5,0.2-6.6,2c-2.7,2.3-5.2,4.3-4.5,8.4c0.3,1.5,0.6,3.3-0.9,4.6
		c-4.1-0.3-8.6-2-11.6,2.8c-0.7,1.1-1.9,1.2-3.1,1.2c-3.5,0-6.9-0.2-10.4,0c-4.8,0.3-6.3-4.7-10-6c-0.4-1.8,1-2.3,2.2-2.9
		c6-2.8,11.7-6.2,17.7-8.9c1.3-0.6,2.7-1.4,4.1-0.2C746,720.9,752.9,719.6,758.5,715z"/>
	<path class="st3053" d="M739.5,723c-8.4,3.2-16.2,7.6-24,12c-0.3,0-0.7,0-1,0c-1,3.3-4.9,2.4-6.5,4.9
		c-13.2,7.9-27.1,14.6-40.7,21.7c-1.3,0.5-2.4,1.3-3.6,2c-3.7,1.8-7.2,3.9-10.8,5.9c-1.4,0.5-2.9,1.1-4.1,2.1
		c-3.6,1.6-6.8,4-10.4,5.8c-0.4,0.2-0.8,0.4-1.2,0.6c-1,0.5-1.9,1.2-2.8,1.8c-3.2,1.3-6,3.8-9.7,3.9c-0.5-0.1-0.9-0.4-1.3-0.7
		c-0.2-0.5-0.1-1,0.1-1.4c3.6-3.2,8.1-5.1,12.3-7.4c7.9-4.2,15.3-9.4,23.5-13.1c10.9-5.2,21.2-11.7,32.1-16.9
		c14.4-7.4,28.5-15.5,43-22.7c1.6-0.8,3-2.3,5-1.4c0.3,0.3,0.7,0.7,1,1C740.1,721.7,739.8,722.3,739.5,723z"/>
	<path class="st3035" d="M707.6,739c1.8-2.2,5-2,7-4c-0.2,2,3.1,4.6-1,6c-1.3-0.7-2.7-1.3-4-2C708.9,739.6,708.2,739.6,707.6,739z"
		/>
	<path class="st3054" d="M702.5,789.9c-0.4,1.6-2.2,1.8-3,3c-5.4,3.6-11.5,5.9-16.7,9.9c-8.2,2.5-14.4,8.6-22.1,12.1
		c-1.5,0.7-2.8,1.9-4.5,0.5c-1-0.9-1.5-1.9-1.5-3.3c0.4-3.4,3.1-4.8,5.7-6.3c3.4-2,7.4-2.9,9.7-6.5c0.8-1.3,2.1-1.9,3.5-2.1
		c4.2-0.6,7.8-2.6,11.3-4.9c4.4-2.9,9.2-5.3,14.3-6.6C701.6,786.1,705.5,785.3,702.5,789.9z"/>
	<path class="st3055" d="M702.5,789.9c-0.1-1.9-0.2-3.7-3-3c-1.6-3.2,2.5-3.3,2.9-5.4c0.6-1,1-2.1,2.1-2.6c4.1,0.2,1.5,4.7,3.9,6
		c0.8,1.1,1.5,2.1,2.5,3.4C708.1,788.9,705.3,789.4,702.5,789.9z"/>
	<path class="st3056" d="M682.5,801.9c3.7-1.7,5.8-6.2,10.5-6.2c2.5,0,3.1-4.7,6.5-2.8c0,0.3,0,0.5,0,0.8c0,0.2,0,0.5,0,0.7
		c-3.9,4.6-9.6,6.1-14.9,8.3C683.7,802.7,683.1,802.5,682.5,801.9z"/>
	<path class="st3057" d="M705.3,795.8c-1.6,0.4-3.1,0.9-4.7,1.3c-1.4-0.7-2.1-1.5-1-3c0,0-0.1-0.4-0.1-0.4c1.9-0.9,3.9-1.7,5.8-2.5
		c1.3-0.6,2.8-1.8,3.7,0.3c0.7,1.5-0.6,2.4-1.7,3.1C706.7,794.9,706,795.3,705.3,795.8z"/>
	<path class="st2845" d="M751.5,751c0,0.8-0.2,1.6-1,2c0-2.7-0.1-5.3,0-8c0.1-2.2,0.2-4.4,3-5C752.8,743.6,751.1,747.1,751.5,751z"
		/>
	<path class="st3058" d="M767.5,725.1c0.1,1.8,1.2,2.9,2.7,3.7c2.7,1.6,1,1.9-0.7,2.2c-1,1,0,1.1,0.6,1.7c3.9,3.7,4.7,8.7,2.2,13.9
		c-0.9,1.2-2.2,1.9-3.7,2.3c-3.3,0.5-5.5-1.3-7.4-3.7c-2.4,2.3-0.4,6-3,8c-0.5-0.1-1-0.3-1.3-0.8c-1.7-4.3-0.5-8.5,0.5-12.7
		c0.2-2.3,1.3-4.2,2.2-6.3c0.9-1.8,2.5-3.2,3-5.2c0.4-0.4,0.8-0.8,1.2-1.2C765,726.4,765.5,724.4,767.5,725.1z"/>
	<path class="st3059" d="M767.5,763.1c-3-0.3-5.9-0.7-8.9-1c-1.2,0.4-1.8-0.2-2.2-1.2c-2.3-5.9-4.2-11.8-1.1-18
		c0.2,0.2,0.4,0.3,0.6,0.5c1.7,3.6-1.1,7.9,2,11.2c1.5,1.7,2.7,3.6,4.7,4.8c0.4,0.2,0.7,0.5,1.1,0.8c1.8,1,4.2,0,5.7,1.8
		C768.8,762.4,768.1,762.7,767.5,763.1z"/>
	<path class="st3060" d="M756.4,760.9c0.7,0.4,1.5,0.8,2.2,1.2c-0.4,1-0.7,1.9-1.1,2.9c-1.1,1.1-1.8,2.4-2.2,4
		c-0.3-0.4-0.6-0.8-0.9-1.1C755,765.5,755.7,763.2,756.4,760.9z"/>
	<path class="st3061" d="M767.5,725.1c-1.4,0.4-1.9,2.4-3.7,2c1.6-3.4,4.6-5.4,7.8-7.1c0.3,0.1,0.7,0.1,1,0.2
		C771.1,722.1,768.4,722.7,767.5,725.1z"/>
	<path class="st3062" d="M760.6,733.1c-0.7,1.9-1.4,3.8-2.2,5.8c-0.2,0.4-0.5,0.6-0.9,0.7c-0.4,0-0.7-0.2-1-0.6
		c0.4-2.6,1.6-4.9,3.1-7C760.5,731.9,761,732.1,760.6,733.1z"/>
	<path class="st3063" d="M760.6,733.1c-0.3-0.4-0.6-0.7-1-1.1c0.7-1.4,1.2-3.1,3-3.7C763.6,730.6,761.5,731.6,760.6,733.1z"/>
	<path class="st3064" d="M756.5,739c0.4,0,0.8,0,1.1,0c0.6,1.4,0.4,2.8-0.7,3.9c-0.4,0.3-0.8,0.3-1.3,0.1c0,0-0.3,0-0.3,0
		C755.3,741.5,755.7,740.2,756.5,739z"/>
	<path class="st3065" d="M699.6,794c0.3,1,0.7,2,1,3c-1.7,1.3-3.4,2.5-5.1,3.8c-2.3,2.4-5.2,3.8-8.3,5.1c-1.5,0.2-3,0.5-4.1,1.6
		c-1,0.8-1.9,1.7-3.2,2.2c-1.5,0.4-2.9,0.8-4.1,1.7c-0.4,0.2-0.8,0.4-1.3,0.5c-1.9,0-4.2,2.1-5.5-1.1c2.5-3.7,7-4.6,10.5-6.8
		c1.5-0.9,3.3-1.4,5-2.1C689.6,799.3,694.6,796.7,699.6,794z"/>
	<path class="st3066" d="M679.6,808.9c1-0.7,2-1.4,2.9-2c1.1-1.1,2.7-1.2,4-2c3-1.4,6-2.7,9-4.1c0,0.3,0,0.7,0.1,1
		c-0.9,2.3-3.2,3-5,4.3c-0.4,0.2-0.8,0.4-1.2,0.6c-1.6,0.7-3.1,1.4-4.4,2.6c-1,0.9-2.1,1.5-3.5,1.5c-0.3,0-0.6,0-1,0
		c-1.1,1.1-2.7,1.2-4,2c-3.7,2-7.3,4-11,6c-3.5,1.1-6,4.6-10.2,4.1c-0.7-0.9-0.7-1.8-0.3-2.8c6.8-3.1,13.6-6.4,20.5-9.3l0,0
		C676.7,809.8,678.3,809.7,679.6,808.9z"/>
	<path class="st3067" d="M681.5,810.9c1-0.7,2-1.3,3-2c1.6-1.1,3.7-1.4,5-3l0,0c5.1-1.8,9.6-4.9,15-6c0.8,0,1.6,0.2,2,1
		c-3.7,3.2-8.5,4.5-12,7.9c-1,0-1.9,0.1-2.9,0.1C688.1,808.8,685.3,812.4,681.5,810.9z"/>
	<path class="st3068" d="M704.5,799.9c-4.8,2.4-9.2,6-15,6c1.7-1.7,3.8-2.9,6-4C698.4,800.7,701.2,799.1,704.5,799.9z"/>
	<path class="st3069" d="M506.7,524.3c-1.3-1-2.6-2.1-3.9-3.1c-0.1-2.3-2-3.3-3.3-4.7c-2.6-3-2.4-10.6,0.3-13.2
		c1.3-0.2,2.4,0,3.2,1.1c-0.7,1.6-2.7,3.2,0.6,4.4c1.3,0.5,2.1,1.8,1.5,3.3c-1.9,5.3,3.1,4.7,5.3,5.5c2.2,0.8,5.8,2,6.5-2.7
		c0.3-2.5,1.6-2.7,4.2-2.2c3.3,0.7,6.4,1.1,8.6,4.1c0.3,0.4,0.4,0.8,0.3,1.3c-0.6,3.6-2.5,6-6.4,6.4c-2.4,0.2-4.4,1.5-6.7,2
		C513.1,527.3,509.6,527.4,506.7,524.3z"/>
	<path class="st3070" d="M502.7,504.3c-1.1,0-2.3,0.2-3-1c-1.2-2.2-5.2-1.5-5-5c2.3-0.9,3.4,1.3,5,2c0.7,0.3,1.3,0.7,2,1
		c5.7,1.8,11.2,4.2,17,6.1c1.7,0.8,3.6,1.4,5.3,2.2c1.5,1.4,4.6,0.8,4.8,3.9c-0.4,0.3-0.9,0.5-1.4,0.6c-7-1.6-14.4-2.3-20.8-6.1
		C505,507,503.4,506.2,502.7,504.3z"/>
	<path class="st2696" d="M499.8,500.2c-1.7-0.7-3.4-1.3-5-2c0-0.3,0-0.6-0.1-0.9C497.8,495.8,498.4,498.7,499.8,500.2z"/>
	<path class="st3071" d="M506.7,524.3c6.4,3.7,12.3-0.7,18.5-1.2c2.5-0.2,2.8-3.4,4.6-4.8c2-0.7,3.4-0.1,4.5,1.6
		c-1.6,7-9.2,13.5-16,13.5c-2.6,0-4.2-2.4-6.6-3.1c-0.9-0.4-1.6-1-1.9-2C508.7,526.9,507.7,525.6,506.7,524.3z"/>
	<path class="st3072" d="M509.7,528.2c0.7,0.3,1.4,0.7,2,1c1.5,1.8,5,1.6,5,5c-0.9-0.1-1.5,3.1-2.6,0.3c-0.8-2.2-1.7-0.2-2.5-0.2
		c-4.1-1.9-7.4-5.6-12.4-5.6c-0.9,0-1.3-0.8-1.6-1.7c-0.9-3.5-3.1-3.9-6.1-2.7c0.6-2.7-2.6-4.3-2-7c0.8,0,1.6-0.2,2-1
		c1.8-1,1.8,0.7,2.2,1.5c1.8,3,3.9,5.5,7.7,6c2.1,0.3,4.3,1.1,4.9,3.5C507.2,529.6,508.7,528,509.7,528.2z"/>
	<path class="st3073" d="M496.9,488.5c1.4-0.1,2.3-0.9,2.9-2.2c5.7-6.6,7.3-15,10-22.9c2.3-1,2-3.1,1.9-5c0.3-0.7,0.6-1.4,0.9-2.1
		c2.6,0.2,2,2.2,2.2,3.8c-1,5.1-1,10.5-3.5,15.3c-1.1,2.7-2.1,5.6-3.6,8.2c-3.1,5.8-3.2,5.8,1.2,10.9c1.1,1.2,2.4,2.3,2.3,4.2
		c-0.3,0.7-0.7,1.3-1.6,0.9C505,496.1,502,491,496.9,488.5z"/>
	<path class="st3074" d="M518.6,508.4c-5.7-2.1-11.7-3.7-16.9-7.1c3.5-2,5.6,1.2,8.2,2.4c1.8,0.8,2.6,0.9,1.7-1.4
		c4.5-0.7,6.9,2.3,9.3,5.3C520.6,508.9,519.7,508.9,518.6,508.4z"/>
	<path class="st3075" d="M520.5,507.5c-3-1.6-5.5-4.2-8.8-5.2c-1.3-0.5-1.9-1.6-2.1-2.9c0.4-0.4,0.8-0.8,1.2-1.3
		c0.4-0.3,0.8-0.4,1.3-0.3c4,3,8.4,5.4,11.9,9c0,0.8-0.5,1.3-1.1,1.7C521.9,508.8,521.2,508.1,520.5,507.5z"/>
	<path class="st3076" d="M555.7,111.7c-1.3,0.5-2.7,1.8-4,0c0.3-0.3,0.7-0.7,1-1c2.1-0.7,3.5-2.5,5-3.9c8.1-2.8,15.6-6.7,23-11
		c3.3,0.1,5.7-2,8.5-3.3c2.1-1,4.1-2,6.5-1.7c1.3,0.6,3-0.5,4.1,0.9c0.2,2.2-1.7,2.1-3,2.6c-11.1,4-21.9,8.9-32.5,14.1
		C561.5,109.6,559,111.7,555.7,111.7z"/>
	<path class="st3077" d="M599.7,91.8c-1.4-0.2-3,0.5-4-1c0.3-0.3,0.7-0.7,1-1c1.7,0.1,3.5-0.1,5-1c0.7,0.1,1.7-0.4,2.1,0.5
		c0.1,0.3-0.7,1-1.1,1.5C602,92.1,601.1,92.8,599.7,91.8z"/>
	<path class="st3078" d="M599.7,91.8c1-0.3,2-0.7,3-1c2.7-0.7,5.3-1.3,8-2c0.7,1.2,3.5-0.5,3,2.3c-4.9,3.1-10.7,3.9-16,6.2
		c-2.9,1.2-5.9,2-8.8,3.2c-10.6,3.2-20.2,8.3-29.6,14c-4.9,2.7-9.3,6.2-14.6,8.3c-1.7,0.2-3.4,1.2-5,0c2.9-4.7,8.7-4.8,12.6-8
		c1.2-1,3.2-0.9,3.4-3.1c6.1-4.3,13-7,19.6-10.2C583.2,97.8,591.5,95,599.7,91.8z"/>
	<path class="st3079" d="M539.7,122.7c1.6-0.4,3.2-0.7,4.9-1.1c0.2,0.2,0.3,0.3,0.5,0.5c0,0.2-0.1,0.5-0.1,0.7
		c-1.1,2-3.4,2.5-5.1,3.6c-2.9,1.7-5.8,3.6-8.5,5.7c-0.9,0.8-1.7,1.5-2.6,2.2c-4.6,3.9-10.4,6.5-13.9,11.7c-0.3,0.4-0.6,0.7-0.9,1
		c-0.3,0.3-0.7,0.5-1.1,0.7c-1.4,0.7-2.6,1.4-3.7,2.5c-1.7,1.4-2.9,3.4-5.3,3.9c-0.5,0-0.9-0.1-1.3-0.4c-0.3-0.4-0.3-0.8,0-1.2
		c0.7-0.7,1.4-1.4,2.1-2.1c1.5-1.9,3-3.6,5-4.9c0.3-0.3,0.7-0.7,1-1c0.7-1.8,2.4-2.3,3.9-3c1.1-2.1,3.2-3,5-4.1
		c0.8-1.2,1.7-2.2,3-2.9C528.5,130.8,534.5,127.3,539.7,122.7z"/>
	<path class="st3080" d="M502.7,152.6c0,0.4,0.1,0.7,0.1,1.1c-0.5,3.4-3,4.5-5.9,5c0-0.4,0-0.8-0.1-1.1
		C498.4,155.5,499.8,153.2,502.7,152.6z"/>
	<path class="st3080" d="M509.8,145.7c-1.3,2-2.9,3.7-5,4.9C505.4,147.9,507,146.2,509.8,145.7z"/>
	<path class="st3080" d="M522.7,134.7c-0.7,1.3-1.6,2.3-3,2.9C519.5,135.4,520.6,134.5,522.7,134.7z"/>
	<path class="st3080" d="M514.7,141.7c-1.3,1-2.6,2-3.9,3C510.9,142.1,512.5,141.6,514.7,141.7z"/>
	<path class="st3081" d="M721.4,120.8c-1.3-2.4-2.6-4.8-3.9-7.2c-0.7-2.2-2-4.1-2.9-6.3c-1.4-3.4-0.7-4.1,3.1-3.5
		c1.2,0.2,1.1-0.9,1.8-1.2c4,2,6.3,6.1,10.2,8.4c1.8,1,2.5,3.2-0.2,4.6C727.3,118.2,723,117.4,721.4,120.8z"/>
	<path class="st3082" d="M719.5,102.8c-0.1,1.1-0.1,2.3-0.4,3.4c-0.1,0.4-0.6,0.9-1,0.9c-0.8,0.1-1.2-0.5-1.1-1.2
		c0.2-1.1,0.1-2.2-1.3-1.9c-1.9,0.4-0.6,1.8-0.4,2.8c0.4,2.4,2.5,4.3,2.1,7c-4-5.6-8-11.2-12-16.8
		C710.3,98.5,715.5,99.2,719.5,102.8z"/>
	<path class="st3083" d="M721.4,120.8c-0.2-6.2,5.1-3.8,8.1-5.1c2.4,3.3,6.6,5.2,9,4c0.3,1.3,0.7,2.7,1,4c0.3,0.4,0.4,1.3,0.3,1.3
		c-1.7-0.1-3.4,1.7-5,0.1c-2.5-2.7-6.1-3.2-9.3-4.5c-0.9-0.6-1.9-1.1-2.8-1.8c0.9,1.3,0.1,2.6,0,3.9c-0.4,0.3-0.8,0.3-1.2,0
		C721.5,122.1,721.5,121.4,721.4,120.8z"/>
	<path class="st3084" d="M723.7,152.7c-1.3-11.8-2.7-23.6-6.3-35.1c2.4,1.8,2.3,5,3.9,7.2c1.7,1.2,1.4,3.3,2.1,5
		c0.4,1.8,0.6,3.7,1,5.5c-0.1,5.6,2.7,11.1,0.7,16.8C724.8,152.7,724.4,152.9,723.7,152.7z"/>
	<path class="st3081" d="M722.3,129.9c-1.4-1.5-0.4-3.4-0.9-5.1c0.1-0.7,0.2-1.4,0.3-2.1c0.3,0,0.6,0,0.9,0.1c1.1,2.2,2.8,4.3,1.3,7
		C723.3,130.1,722.8,130.2,722.3,129.9z"/>
	<path class="st3085" d="M615.6,88.8c1-0.1,2-0.2,3-0.2c17.2-3.3,34.3-6.6,51.9-3.6c0,0.3,0,0.5,0.1,0.8c-0.9,1.1-2.2,1-3.4,1
		c-9-0.5-18-1.2-26.9,0.6c-2.4,1-5.1,0.1-7.5,1c-4,0.6-8,1.3-11.9,2.4c-1.6,0.1-3.3,0.5-4.9-0.4C615.4,89.8,615.3,89.3,615.6,88.8z"
		/>
	<path class="st3086" d="M667.5,85.9c1-0.1,2.1-0.1,3.1-0.2c3.1,0.2,6.2,0.7,9.1,1.8c-0.2,0.2-0.4,0.4-0.5,0.6
		c-3.8,0.9-7.4-1.2-11.2-1C667.4,86.9,667.2,86.5,667.5,85.9z"/>
	<path class="st3086" d="M686.5,90.6c-0.7,0-1.3,0-2,0c-0.8-2.6-4.4,0.1-5.1-2.7c0,0,0.3-0.4,0.3-0.4c2.9,0.4,5.8,0.9,8.8,1.3
		c0,0.3,0.1,0.5,0.1,0.8C687.9,89.9,687.2,90.3,686.5,90.6z"/>
	<path class="st3087" d="M791.2,725c0.1,3,0.2,6,0.4,9c-0.6,1.6,1.1,4.2-2,4.7C791.2,734.2,789.8,729.5,791.2,725z"/>
	<path class="st3088" d="M780.8,753.1c0.6-0.1,1.3-0.1,1.9-0.2c-0.8,2.2-3.2,3.1-4.4,5.1c-0.9,0.4-1.8,0.8-2.6,1.1
		c-0.4,0.3-0.9,0.3-1.3,0.1c-0.7-2.2,1.2-2.7,2.3-3.8C777.9,754.3,779.2,753.3,780.8,753.1z"/>
	<path class="st3089" d="M774.1,758.7c0.5,0.1,1.1,0.2,1.6,0.3c-1.6,1.2-3.1,2.7-5.3,2.7c-0.7-0.3-1.3-0.5-1.2-1.4
		C770.5,758.6,772.1,758,774.1,758.7z"/>
	<path class="st3090" d="M769.3,760.9c0.4,0.3,0.7,0.6,1.1,0.8c-0.3,0.1-0.6,0.2-0.9,0.2c-2.3-0.5-4.9,1-7-1.2
		C764.8,759.3,767,759.2,769.3,760.9z"/>
	<path class="st3091" d="M445.7,241.5c0.1-0.6,0.1-1.2,0.2-1.8c-0.3-4.7,2.5-8.6,3.2-13c2.1-4.1,2.3-9.3,6.7-12.1
		c0.2,1.1-0.7,3.2,2,2.1c0.8,0.1,1.3,0.6,1.5,1.4c-0.1,2.5-1.7,4.4-2.8,6.5c-2.4,6.6-4.1,13.4-7.9,19.4c-1.5,1.1-1,4.2-4,3.5
		C444.2,245.3,444.2,243.2,445.7,241.5z"/>
	<path class="st3092" d="M472.8,190.6c1.4-0.6,2.3-1.7,3.1-3c0.6-0.6,1.3-0.6,2.1-0.2c2,4.6-2.4,6.5-4.1,9.5
		c-0.8,1.4-1.8,2.7-2.6,4.2c-1,1.9-2.5,3.7,1.7,4.1c3,0.3,2.5,2.8,0.9,4.8c-2.1,1.3-4.3,2.5-6.8,2.8c-1.7-0.1-3.4,0.9-5.2,0
		c-0.1-4.5,3.5-7.4,4.9-11.3C468.8,197.9,470.8,194.3,472.8,190.6z"/>
	<path class="st3093" d="M455.8,214.5c-2,4.1-3.9,8.1-5.9,12.2c-1.1-1.3-0.3-3-0.8-4.4c0.2-1.6,1.1-3.1,0.9-4.8
		c-0.5-3.8,1.5-6.2,4.5-8.1c0.5-0.2,1-0.1,1.4,0.2c0.3,0,0.7,0,1,0C456.5,211.2,456.1,212.9,455.8,214.5z"/>
	<path class="st3094" d="M466.8,201.6c-0.8,4-3.3,7.2-4.9,10.9c0,0.7,0,1.4-0.2,2.2c-0.1,0.4-0.2,0.7-0.4,1c-0.3,0.5-0.6,0.9-1,1.3
		c-0.4,0.4-0.9,0.7-1.5,0.7c-0.4-0.3-0.7-0.7-1.1-1C458.3,210.1,463.7,206.6,466.8,201.6z"/>
	<path class="st3095" d="M455.8,209.6c-0.3,0-0.7,0-1,0c-2.5,0.1-4.7,1.6-7.2,1.5c-1.8,0-3.7,0.4-4.8-1.5
		c-5.1,1.8-10.5,0.2-15.7,1.5c-3.7,1-1.6-2.9-2.3-4.5c8.3-2.3,16.7-4.4,25.4-1.9c1.9,0.5,3.7,1.4,5.6-0.1
		C455.5,206.3,456.9,207.9,455.8,209.6z"/>
	<path class="st3096" d="M442.8,209.6c2.9,0.7,5.7,0.8,8.4-0.6c1.2-0.6,2.6-0.7,3.6,0.6c-1.9,2.8-5.4,4.9-3.4,9.2
		c0.3,0.7-0.6,2.1-1.6,2.8c-0.7,0.5-0.3,1.5-1,2c-0.6,0.1-1.7,0.5-1.9,0.3c-1.8-2.8-5.1-3.8-7.1-6.3
		C441.8,215.3,443.5,212.9,442.8,209.6z"/>
	<path class="st3097" d="M448.8,223.6c0-0.8,0.2-1.6,1-2c0,1.7,0,3.4,0,5.1c-0.8,4.5-2.4,8.8-4,13c-1-1.7-2-3.4-3-5.1
		c-0.3-0.2-0.3-0.4-0.3-0.7c0.1-0.2,0.2-0.3,0.3-0.3c0.7-0.3,1.3-0.7,2-1C448.8,230.8,447.2,226.5,448.8,223.6z"/>
	<path class="st3098" d="M442.8,233.6c0,0.3,0,0.7,0,1c-2.7,5.4,1.4,11.9-3,17c-4.7-3.6-10.5-4.4-15.8-6.5c-1.2,1.6-0.7,3.5-1,5.3
		c-0.2,1.4-0.2,3.3-2.1,3.4c-1.6,0.1-1.9-1.5-2.6-2.6c-0.9-1.5-0.6-3.2-1.5-4.7c0-1.7,0-3.3,0-5c2.2-4.2,0.4-8.7,1-13
		c1.9,2.4,0.8,5.1,0.9,7.7c0.1,2.5,0.1,5,1.5,7.5c2.1-2,1.3-4.5,1.6-6.8c0.2-1.4-0.1-3.3,2.4-2.4c4.9,1.8,9.8,5.5,14.7,0.1
		C441.5,238.4,440.9,231.8,442.8,233.6z"/>
	<path class="st3099" d="M600.7,153.7c3.7-0.3,7.3-0.7,11-1c1.3,1.7,4,0.4,5.1,2.5c-0.9,1.1-2.2,1.1-3.5,1.6
		c-2.5,0.9-2.2,3.8-0.9,4.2c4.6,1.4,2.9,4.9,3.2,7.7c-1,1.9-3.6,3.4-2.1,6.4c1.4-1.7,0.3-4.1,2.1-5.4c1.3,0,2.7,0,4,0
		c2.4,5.3,2.4,10.7,0,16c0,1.2,0.2,2.4,0,3.5c-2,7.7,2.1,13.5,6.2,19.1c1.6,2.2,5.6,0.8,7.4,3.4c0.2,0.4,0.6,0.6,1.1,1
		c-1.9,1.5-4.1,1-6.6,1.7c3.7,2.2,3.9,7.3,8.6,8.2c1.1,0.2,1.1,1.5,1.3,2.5c0.4,2.1,1.3,3.7,3.9,3.6c0.7,0,1.8,0.4,2.2,0.9
		c1.1,1.6,2.1,4,1,5.4c-1.5,1.9-2-2-4.1-1.5c0.8,3.3,2.7,5.8,4.9,8c2.1,1.3,1.3,2.8,0.1,4.2c-2.7,0.3-3.2-2.4-5-3.5
		c-1.5-1.5-2.8-3.2-3.3-5.4c-0.2-3.4-2.6-5.9-3.4-9c-0.2-0.7-0.9,0.4-1.4,0.2c-3.9-1.2-4.9-5.3-7.4-7.7c-0.8-0.9-1.5-1.7-2.2-2.6
		c-3-4.4-7.3-7.6-10.8-11.5c-0.6-0.7-1.1-1.5-1.5-2.4c-1.9-4-5.8-6.4-8.5-9.7c-0.7-2-2.7-3.2-3.8-4.9c0.5-2.3-1.9-1.8-2.7-2.8
		c-0.8-0.5-1.5-1.1-1.3-2.1c0.4-1.1,1.5-1.2,2.3-1.7c4.9-2.8,5.2-3.8,3.4-9.3c-1.4-4.2-1.2-8.9-5.1-12c-0.6-0.5-1-1.6-0.4-2.3
		c1.1-1.3,2.2-0.2,3.2-0.1c-1.2-0.9-2.9-0.5-4.4-0.9c-0.9-0.5-1.8-1-1.7-2.1c0.1-0.9,1.1-1,1.9-1c1-0.1,2.5,2.2,3-0.9
		C596.8,153.1,599.2,153.4,600.7,153.7z"/>
	<path class="st3100" d="M659.6,249.6c2,0.5,3.4,2.5,5.9,2.1c1.5-0.3,1.9,0.8,1.5,2.2c-0.4,1.5,0.5,2.4,2.1,3.8
		c-0.3-7.8,5.7-7.5,10-7.9c6.7-0.7,13.6-0.1,20.4-0.2c3.7-0.1,7.1,4.1,11.1,0.8c0.4-0.4,0.8,0.8,0.8,1.4c-0.1,1.2-0.8,1.7-1.9,1.8
		c-1.8,0.2-3.7-0.4-5.2,1c-0.7,0.7-1.6,1.4-1.2,2.5c0.4,1.1,1.2,1.4,2.4,1.2c5.6-1.1,11.2-2.2,16.7-3.4c1.3-0.3,3.5-0.3,2.4-2.6
		c-1.1-2.3-0.2-2.7,1.7-2.6c1.6,0,3.3-0.6,2.2,2.6c-0.6,1.6,1.3,4-0.2,6.2c-0.6,0.9,0,2,0.9,2.5c1.2,0.7,2.2-0.4,3.1-1
		c1.3-0.8,2.1-3.4,3.9-2.2c2,1.4-1.1,2.8-0.9,4.6c0.1,1.3-1.1,2.7-1.7,4c-1.8,0.7-3.3-0.4-4.7-1.1c-11.4-4.8-23.1-5.2-35.2-4
		c-5.7,0.5-11.3,2.2-17.2,2.1c-3.3-0.1-6.7,2.6-10,4.1c-7.2,3.5-7.1,3.5-11.7-2.6c-0.4-0.5-0.9-1-1.3-1.5c-0.6-0.8-1.1-1.7-1-2.8
		C657.9,259.1,657.2,253.3,659.6,249.6z"/>
	<path class="st3100" d="M663.6,241.5c0-1.2,0.1-2.3,0-3.5c-0.5-3.6,1.2-4.9,4.6-4.4c0.7,0.1,1.3,0,2,0c9.8,0,9.7,0,9.5,9.8
		c0,2.8-0.5,4.6-3.8,4.2c-1.9-0.2-3.4,0.6-5.1,1.5c-4.5,2.2-7.1,0.4-7.2-4.6C663.6,243.5,663.6,242.5,663.6,241.5z"/>
	<path class="st3101" d="M619.6,185.6c0-5.3,0-10.7,0-16c0.2,0,0.3,0,0.5,0c12.1,0,12,0,11.6,12.2c-0.1,3.2-1.3,4-4.2,3.8
		C624.9,185.5,622.3,185.6,619.6,185.6z"/>
	<path class="st3102" d="M653.6,262.5c1.1,1,2.9,1.9,3.1,3c1.1,6,4.3,3.4,7,1.8c3.6-2.1,7.5-3.7,11.2-5.1c3-1.2,6.9-0.5,10.5-1.1
		c14.9-2.4,29.8-3.3,44.2,3.2c1.3,0.6,2.6,1.4,3.9,2.1c1.9,1.1,3.5,2.9,6,3c0,0.6,0.1,1.3,0.1,1.9c-0.2,0.1-0.4,0.3-0.6,0.4
		c-4.7-0.5-7.8-4.5-12.3-5.5c-5.1-1.7-10.2-3.5-15.7-3.9c-7.7-1.3-15.4,0-23,1c-7.9,1.4-15.6,3.3-22.9,6.7c-1.7,0.8-3.4,1.3-5.3,1.5
		c-4.8,0.2-5.2-3.9-6.7-6.9C652.9,263.9,653,263.1,653.6,262.5z"/>
	<path class="st3099" d="M659.6,249.6c1,4-1.9,7-2.6,10.6c-0.4,2.3-2.8,0.3-4.3,0.4c-1.2-0.5-1.6-1.6-2-2.7c-0.3-2-1-3.8-2.7-5
		c-1.1-1.3-2.1-2.6-1.6-4.5c1.8-1.7,3.5-1.6,5.2,0.2c0.6,1,2.2,1.4,2,3c-0.5,0.5,0.2-0.2-0.2,0.2c-0.1,0.1,0,0,0.2-0.2
		c1-0.7,2-1.3,3-2C657.6,248.7,658.6,248.7,659.6,249.6L659.6,249.6z"/>
	<path class="st3100" d="M651.6,248.6c-1.6-0.5-3.3-0.5-4.9,0c-1.4-0.6-2.1-1.5-1.1-3c0-1.3,1-2.7,0-4c0-0.9,0-1.9,0-3.3
		C648.2,241.8,653.7,242.9,651.6,248.6z"/>
	<path class="st3101" d="M615.6,169.7c0,2,0,4-0.1,6c0,1.2-0.7,1.8-1.9,1.9c-1.2,0-1.9-0.6-2-1.7c-0.4-3.3-0.4-6.4,3.9-7.1
		C615.6,169,615.6,169.3,615.6,169.7z"/>
	<path class="st3099" d="M646.6,154.7c1.1-3.1,3-2.3,5-1C650.2,155.3,648.3,154.4,646.6,154.7z"/>
	<path class="st2886" d="M671.5,161.6c1.2,0.3,2.8-0.6,3.7,1.3C673.3,163.9,672.1,163.9,671.5,161.6z"/>
	<path class="st3099" d="M613.6,152.7c2-1.5,4-1.5,6,0C617.6,152.7,615.6,152.7,613.6,152.7z"/>
	<path class="st3100" d="M659.6,249.6c-1,0-2,0-3,0c0.1-1,0.4-2,1.7-1.8C659.3,247.9,659.4,248.8,659.6,249.6z"/>
	<path class="st2884" d="M679.6,172.6c2,0,3.9-0.2,4.1,2.8c0.2,2.8,2.8,2.9,3.4,0.9c1.3-4,4.1-2.4,6.4-2.7c-0.2,1.4,0.6,3.4-1.4,3.8
		c-3,0.5-3.9,2.8-4.2,4.9c-0.6,4.2-3.4,3.3-5.9,3.2c-3.2-0.1-2.3-2.6-2.4-4.5C679.5,178.3,679.6,175.5,679.6,172.6z"/>
	<path class="st2709" d="M735.5,219.6c-2.7-0.2-5.5,0.5-8-1c0.3-3.9,3.8-3.4,5.9-3.5C736.1,215,735.3,217.8,735.5,219.6z"/>
	<path class="st3103" d="M594.6,184.6c0.4,0.8,1.2,1,2,1c-0.9,2,2,1.6,2,3c1.8,1.3,3.8,2.3,4,4.9c-0.3,0.4-0.7,0.6-1.2,0.7
		c-1.5-0.1-2.5-1.2-3.6-2.1c-4.7-3.9-9.8-7.4-14.9-10.9c-5.7-3.6-11.4-7.2-17.6-10c-1.5-0.7-3.1-1.1-4.1-2.6c-0.6-3,2-3.5,3.7-4.5
		c1.9-1.1,3.8-2,5.9-2.7c3.2-1.2,6.4-2.4,9.4-4c4-1.4,7.9-3.2,12-4.3c2.7-0.5,5.3-2.7,8.2-0.6c0,0.4,0,0.7,0.1,1.1
		c-2.5,0.6-4.2,3.1-7.5,2.3c-1.4-0.3,0.7,1,0.5,1.7c-2.6,3.2-6.9,1.9-10,3.7c-2,1.1-5.4-0.1-5.8,3.6c-1.1,2-2.7,3.3-4.8,3.6
		c3.9,3.9,8.7,6.8,13.6,9.4C589.8,179.6,593,181.1,594.6,184.6z"/>
	<path class="st3104" d="M667.8,151.2c-4.2,0.4-8.2-0.2-12.2-1.5c-6.4-2-13-1.6-19.5-2.1c-1.6-0.4-3.4-0.5-4.7-1.9
		c-0.1-0.6,0.1-1.1,0.6-1.5c6.3-2.2,12.4,1,18.6,0.4c2.4,0.7,5,1.1,7.4,1.6c1.2,0,2.4-0.4,3.5,0.1c1.7,1.5,4,2.1,6,3.1
		C668.1,149.9,668.1,150.5,667.8,151.2z"/>
	<path class="st3105" d="M635.6,146.7c5.9,0.3,11.8,0.7,17.6,1c1.3,0.1,2.2,0.6,2.3,2c-10.6-1.2-21.3-0.9-32-1
		c0.3-2.8,2.9-2.1,4.5-3C630.6,145.6,633.1,146.1,635.6,146.7z"/>
	<path class="st3106" d="M627.6,146.7c-0.9,1.6-3.1,0.4-4,2c-4.7,0.6-9.4,1.2-14.1,1.8c-1.2-1-3.1,0.5-4.1-1.2
		c0.8-2.2,3-1.9,4.6-2.6c3.8-0.3,7.4-1.6,11.3-1.4c0.8,0,1.7,0,2.5,0C625.2,145.4,626.6,145.5,627.6,146.7z"/>
	<path class="st3107" d="M727.6,189.6c1.9,3.3,5.1,5.5,6.7,9c-4.1,0.3-4.8-3.7-6.7-5.8c-1.9-2.1-2.9-4.5-6.3-3.5
		c-0.4,0.1-1.1-1-1.7-1.6c-0.4-0.4-0.5-0.9-0.1-1.3c1.1-0.8,2.3-0.9,3.6-0.7c1,0.2,1.9,0.5,2.7,1
		C726.8,187.4,727.4,188.4,727.6,189.6z"/>
	<path class="st3108" d="M605.6,149.6c1.3,0.2,2.9-0.4,4,0.9c-3,0.7-6,1.4-9,2c-2.7,0.6-5.4,1.2-8.1,1.8c-0.9-0.6-2.6,0.6-3.1-1.3
		c1.9-2.4,5.1-2.2,7.6-3.4C599.9,149.4,602.7,147.3,605.6,149.6z"/>
	<path class="st3109" d="M693.5,160.8c1.1,0.7,2.2,1.4,3.2,2.1c1.4,0.5,3,0.7,3.4,2.5c0,0.4-0.1,0.8-0.3,1.2
		c-0.7,0.8-1.5,0.2-2.3,0.1c-3.5-2.1-7.1-4-10-7C690,157.7,691.4,161.2,693.5,160.8z"/>
	<path class="st3110" d="M693.5,160.8c-2.4,1.7-3.8-1.6-6-1.1c-2.9-1.7-6.2-2.9-8-6c3.5,0.6,6.2,2.7,9.3,4.2
		c1.3,0.4,2.6,0.9,3.7,1.8C692.8,160,693.2,160.4,693.5,160.8z"/>
	<path class="st3111" d="M697.5,166.6c0.7-1.1,1.3-0.1,2,0c2.2-0.2,3.5,1.6,5.2,2.5c0.9,0.8,1.5,1.7,1.2,3c-0.1,0.2-0.3,0.4-0.4,0.5
		C702.2,171.5,699.9,169,697.5,166.6z"/>
	<path class="st3112" d="M716.5,184.6c-1.4-1.6-1.9-3.8-3.9-4.9c-0.3-0.2-0.4-0.5-0.4-0.7c0-0.3,0.1-0.4,0.2-0.4
		c2.2-1.1,3.6,0.6,5.2,1.5c0.6,0.4,1,1,1.3,1.7c0.2,1.3-0.3,2.3-1.1,3.2C717.2,185.1,716.8,185,716.5,184.6z"/>
	<path class="st3113" d="M673.6,150.8c2.4,0,5-0.2,6,2.9c-2-0.7-4-1.3-6-2c-0.4-0.2-0.5-0.4-0.3-0.6
		C673.3,150.9,673.4,150.8,673.6,150.8z"/>
	<path class="st3114" d="M673.6,150.8c0,0.3,0,0.6,0,0.9c-1.9-0.2-3.9-0.4-5.8-0.5c-0.1-0.5-0.2-1-0.3-1.4c0.3-0.6,0.7-0.6,1.2-0.1
		C670.4,149.6,672.2,149.4,673.6,150.8z"/>
	<path class="st3115" d="M709.5,176.7c-1.7-1-3-2.3-4-4c0,0,0-0.1,0-0.1c3.5-1.1,4.6,1,5.2,3.9C710.4,177,710,177.1,709.5,176.7z"/>
	<path class="st3116" d="M716.5,184.6c0.3,0,0.7,0,1,0c1.6-0.3,2.3,0.4,2,2c0,0.3,0,0.7,0,1C718.2,187,717.1,186,716.5,184.6z"/>
	<path class="st3117" d="M709.5,176.7c0.3,0,0.7,0,1,0c1.6-0.3,2.3,0.4,2,2c0,0.3,0,0.7,0,1C711.1,179.1,710.2,178,709.5,176.7z"/>
	<path class="st3118" d="M727.6,189.6c-0.8-0.7-1.5-1.4-2.3-2.1c-0.6-1.4-1.2-2.7-1-4.3c0.9-7,0.1-14.1,0.2-21.2
		c0.3-9.6,0.9-19.3-1.1-28.9c-0.4-1.2-0.6-2.3,0.1-3.4c-0.3-2.3-0.7-4.7-1-7c0.3-1.4-0.7-3.1,0.6-4.1c0.8-0.6,1.8,0.3,2.4,1.1
		c0.8,0.9,2.6,1.1,2,3c-2.4,3.1-1.9-1.8-4.2-0.9c3.9,4.7,1,10.7,3.2,15.9c0,1.3,0,2.7,0,4c1,4.5,0.2,9.1,0.5,13.7
		c0.1,1.8-0.5,3.6,0.5,5.3c0,1.7,0,3.3,0,5c0.7,3.3,0.8,6.7,0,10c-0.4,4.4-0.8,8.8,1,13C728.2,188.9,727.9,189.3,727.6,189.6z"/>
	<path class="st3119" d="M793.4,481.3c-0.4-0.3-1-1-1.1-0.9c-3.8,3.3-8.4,0-12.5,1.7c-2.5,1-5.5,0.4-5.4-3.6c0.1-4.2-2-5.3-5.9-5.2
		c-10.4,0.1-10.4,0-17.2-7.8c0.5,3.7,0.7,7,1.5,10.2c0.5,2.2,1.5,4.9,4.7,2.3c0.8-0.7,1.9-0.8,2.6,0.3c0.4,0.8,0.2,1.7-0.5,2.2
		c-4,3.1-1.7,7-0.3,9.7c1.4,2.7,2.3,5.4,3.1,8.2c-4.4-6.2-7-13.5-11-20c-2.6-4.1-4.1-9-7.9-12.4c-1.1-1.2-2.2-2.2-3.1-3.6l0,0
		c-0.3-1.3-3.2-2.7,0-4c4.4,1.1,8.6,1.9,12.4-1.8c0.6-0.6,1.7-0.8,2.6-1.2c4.1,0.8,6.5,3.9,7.8,7.3c0.9,2.3,1.8,3.5,3.7,2.2
		c3.8-2.6,7.2-1.5,10.5,0.6c2.8,1.7,5.1,4.2,8.6,4.5C785.7,475.5,791.7,477,793.4,481.3z"/>
	<path class="st2841" d="M798.3,479.3c0,0.8,0,1.5-0.9,1.6c-0.9,0.1-1.5-0.6-1.6-1.5c-0.1-0.7,0.3-1.3,1-1.5
		C797.8,477.8,798,478.6,798.3,479.3z"/>
	<path class="st3120" d="M740.5,458.3c-1.8,1.3,0.4,2.7,0,4c-3.7-2.5-5.8-6.3-8-10c0.8,0,1.6-0.2,2-1
		C738,450.7,740.9,454.1,740.5,458.3z"/>
	<path class="st2764" d="M750,436.6c-0.5,0.3-0.9,0.6-1.2,0.6c-0.4-0.1-0.6-0.5-0.9-0.8c0.3-0.3,0.6-0.7,0.9-0.7
		C749.1,435.8,749.5,436.2,750,436.6z"/>
	<path class="st3121" d="M474.8,553.3c-2-1.4-3.7-3.1-4.9-5.2c2.1-7.9,2.2-8,9.1-4.3c2.3,1.2,4,2.8,5-1.1c0.5-1.7,2.1-1.4,3.4-1.3
		c4.9,0.2,9.9,0.3,14.8,0.6c6.8,0.4,13.7-1,20.6-1.6c1.1,0.5,2.2,0.1,3.4,0.3c1,0.3,1.7,0.9,2,1.9c0,0.5-0.2,1-0.6,1.3
		c-0.7,0.4-1.4,0.6-2.1,0.7c-7.2-1.3-13.8,2.5-20.9,2.7c-5.3,0.1-10.8,0.4-15.9,3c-2.6,1.3-5.8,0.3-8.8,0.2
		C477.6,550.1,474.9,549.8,474.8,553.3z"/>
	<path class="st2896" d="M525.7,541.2c-1-0.4-2.8,1.6-3-1c-0.6-1-2.1-1.4-2.1-2.9c0,0,0.1-0.1,0.1-0.1c2.6-0.8,3.8,1.2,5.2,2.7
		C526.1,540.4,526,540.8,525.7,541.2z"/>
	<path class="st3122" d="M516.8,534.2c-0.9-2.4-4-2.7-5-5c0.8,0.2,1.6,0.4,2.3,0.7c1.4,0.8,3.6,2.7,4,2.4c3-2.4,7.6-1.2,10.1-4.6
		c1.8-2.5,3.7-5,5.6-7.5c4-1.5,8.2-1.7,12.4-1.6c2,0.1,3.9-0.2,5.8-0.6c2.1-0.1,4.3-0.6,5.9,1.3c-2.8,4.1-7.4,5.2-11.8,6.5
		c-2.4,0.7-4.9,0.9-6,3.5c-4.6,2.1-9.4,3.6-13.8,6.3c-2.2,1.3-5.1,1-7.5-0.5C518.1,534.9,517.5,534.5,516.8,534.2z"/>
	<path class="st3123" d="M518.8,535.2c3.6,0.5,7.1,0.2,10-2.2c3.2-2.8,7.1-3.2,11-3.8c4.7-3.7,10-5,15.9-4.3
		c1.4,2.3-0.3,2.9-1.9,3.3c-2,0.5-3.6,1.4-5.1,2.6c-6.9,4.7-14.4,8.1-22.8,9.3c-1.7-1-3.3-2-5-3
		C520.1,536.6,519.4,535.9,518.8,535.2z"/>
	<path class="st3124" d="M479.8,566.2c-2.7-1.6-4-4.8-7-6c-0.3-0.3-0.7-0.7-1-1c-0.3-1-0.7-2-1-3c0.7,0.3,1.3,0.6,2,1
		c4.3,3.7,8.5,7.4,12,12c-1.3,1.1-2.5,0.4-3.6-0.2c-0.4-0.2-0.7-0.6-0.9-0.9C479.9,567.5,479.7,566.9,479.8,566.2z"/>
	<path class="st3124" d="M465.8,550.2c1,1.3,2,2.7,3,4c-10.3-1.5-21.5-0.4-29-10c2.7-0.1,5.5,2.2,8.2-0.8c1.7-1.9,1.7,1.9,3.2,1.7
		c4.6-0.7,8,0.1,9.7,5.3C461.5,552.2,464.1,551,465.8,550.2z"/>
	<path class="st3125" d="M747.5,700c-8.3,5.2-17.6,8.4-25.7,13.8c-1.3,0.8-2.7,1.3-4.2,1.2c-2.7-1.9-0.1-3.1,0.6-4.6
		c4.3-4,10.4-5.1,14.6-9.3c0.7-0.7,1.6-1.1,2.6-1.3c5.1-1.1,9.1-4.4,13.6-6.7c3.3-1.8,6.6-3.7,10.1-5.1c3.8-0.6,6.1-4.9,10.4-4
		c2-0.7,4-0.7,6,0c-4.1,3.7-9.5,4.9-14.1,7.8C756.9,694.8,752.1,697.3,747.5,700z"/>
	<path class="st3126" d="M785.4,672c4.8-2.1,9-5.4,14.2-6.8c-6.8,5.8-15.2,8.9-23,13c-0.3-0.4-0.5-0.8-0.4-1.3
		C778.5,673.8,781.4,671.9,785.4,672z"/>
	<path class="st3127" d="M776.4,677c0,0.4,0.1,0.8,0.1,1.1c0,0,0,0,0,0c-1.7,1-3.6,1.7-5.1,3c-0.7,0.6-1.4,1.2-2.1,1.8
		c-1.8,1.2-3.4,2.9-5.9,2.4c-0.3-0.4-0.4-0.9-0.3-1.4c2.4-3,5.7-4.7,8.8-6.7C773.3,676.2,774.9,675.7,776.4,677z"/>
	<path class="st3128" d="M763.5,685.2c2-0.8,3.9-1.5,5.9-2.3c0,0.4,0,0.8,0.1,1.1c-3.6,1.2-6.2,4.3-10,5c-0.4-0.3-0.5-0.8-0.5-1.2
		C759.8,685.8,761.4,685.1,763.5,685.2z"/>
	<path class="st3126" d="M771.5,681.1c1.1-2.1,2.9-2.8,5.1-3C775.3,679.9,773.6,680.8,771.5,681.1z"/>
	<path class="st3129" d="M530.7,813.9c2-1.9,5-0.2,7-2c1.7-0.4,4.5-1.4,2.3,2c-1.3,2.1-0.8,4-0.8,6c-1,1.9-1.7,4.1-4.5,3.9
		c-0.7-3.1-3.9-5.7-1.8-9.4C532.2,814.3,531.4,814.1,530.7,813.9z"/>
	<path class="st3130" d="M534.7,823.8c1.3-1.3,2.7-2.6,4-3.9c1.4-1.7,3.5-2.1,5.2-3.2c0.4-0.2,0.9-0.4,1.3-0.5c1-0.1,1.9,0,2.6,0.7
		c-0.7,4.2-4.9,4.5-7.5,6.6c-2.4,1.2-4.1,3.5-6.8,4.2c-0.6,0.4-1.3,0.7-1.9,1.1C531.2,826.2,533.4,825.3,534.7,823.8z"/>
	<path class="st3131" d="M539.8,822.9c2.2-2.6,6-3.1,8-5.9c0.2-0.4,0.6-0.7,1-0.9c1.1-0.5,2.1-0.8,3.3-0.5c1.4,1,3.7,1,3.9,3.4
		c-2.7,3.1-5.9,5.3-10.1,5.9c-2.6,0.7-2.8,3.9-5,5c-0.4-0.3-0.7-0.7-1.1-1C538,826.8,538.3,824.9,539.8,822.9z"/>
	<path class="st3132" d="M539.8,822.9c0,2-0.1,3.9-0.1,5.9c-1.7-1.6-3.3,1.3-5,0c-0.4-0.4-0.7-0.8-1.1-1.2
		C535.1,825.4,537.3,824,539.8,822.9z"/>
	<path class="st3133" d="M688.6,739c-13,7.1-26.6,12.9-39,20.9c-4.6,4.1-9.8,7.1-15.6,8.9c-0.3,0-0.7,0.1-1,0
		c-6.8-0.7-6.8-0.7-8.5,5c-0.3,0.3-0.7,0.5-1.2,0.7c-1.8-0.1-3.2-1.7-5.4-0.5c-1.8,1-3.4-0.2-4.2-2c8-4.7,16-9.3,24-14
		c0.5-0.8,1.2-1.7,2.1-2.1c14.7-7.4,28.9-15.6,43.4-23.3c0.6-0.3,1.3-0.4,1.9-0.4c0.5,0,1,0.1,1.5,0.3
		C688.2,734.3,691.2,735.7,688.6,739z"/>
	<path class="st3134" d="M613.6,772c2,0.9,4,2,6.1,0.3c2.5-2,1.9,2.7,3.9,1.7c0.2,2.1-1.7,2.1-2.9,2.8
		c-14.5,8.6-29.7,15.9-44.4,24.1c-1.8,1-3.6,1.6-5.6,1.8c-1.9-0.3-3.4,1.1-5.2,1.2c-2.5,0.2-5.4,1.1-5.8-2.9
		C577.4,790.8,595,780.4,613.6,772z"/>
	<path class="st2907" d="M685.5,733c-11.7,6.2-23.4,12.2-34.9,18.7c-4.2,2.4-8.7,4-13,6.3c0.3-1.3,0.7-2.6,1-3.9
		c0.3-1.7,1.7-2.2,3-3c6.9-3.2,13.3-7.3,20-10.8c7.6-3,14.3-7.7,21.7-11.1c9.7-5.1,19.2-10.6,28.8-15.9c2.2-0.7,3.7-3.3,6.4-2.2
		c0.2,1.5-2.3,2.2-1,3.9c-5.9,4.4-12.8,7.1-19.1,10.9c-1.3,0.8-2.6,1.4-3.9,2.1C692.6,731.7,689,732.2,685.5,733z"/>
	<path class="st3135" d="M559.7,800.9c2,3,4.6,2.4,7.2,1.1c1.3-0.7,2.4-1.8,3.8-0.2c-0.5,1.8-2.1,2.3-3.6,2.9
		c-1.5,0.4-3.5,0.1-4.1,2.2c-5.6,4.2-12.2,6.4-18.2,9.9l0,0c-2.8,1.2-3.1-0.9-3.6-2.8c-0.3-1.1,1.4-2.4-0.4-3.2
		C547,807.6,552.9,803.3,559.7,800.9z"/>
	<path class="st3136" d="M538.7,805.8c-3.3-0.6-3-4.1-4.9-5.9c1.3-1.4,2.7-2.3,4.7-1.6c1.2,1.7,2.3,3.6,4.7,1.5
		c2.6-2.2,6.1-2.8,9-4.5c2.4-1.3,4.8-2.7,7.1-4.2c2-0.8,3.6-2,5.5-3c1.6-0.7,3.1-1.5,4.9-0.5c0.4,0.4,0.6,0.8,0.6,1.3
		c-0.5,2.4-3.1,1.9-4.4,3.2c-0.7,0.6-1.5,1.1-2.2,1.7c-6.8,3.1-12.9,7.5-19.9,10.3C542,804.6,540.4,805.2,538.7,805.8z"/>
	<path class="st3137" d="M569.8,789.2c0-0.4-0.1-0.8-0.1-1.2c-0.1-2.3,2-2.3,3.3-3c6.9-3.8,14.3-6.8,20.4-12c1.6-1.4,3.8-2,6-1.3
		c1.1,1.2-0.1,1.7-0.7,2.4c-6.3,3.6-12.7,7.2-19,10.9C576.4,786.5,573.7,789.1,569.8,789.2z"/>
	<path class="st3138" d="M641.7,752c-1,0.7-2.1,1.4-3.1,2.1c-8,4.3-16,8.6-24.1,12.9c-1.6,0.8-3.1,2-5.1,1.8c-0.3-1,0.1-1.8,0.6-2.6
		c0.8-1,1.8-1.6,2.9-2c6.2-1.7,11.3-5.5,16.7-8.6c1.7-1,3.4-2.2,5.2-2.8c1.8-0.5,3.5-1.8,5.6-1.5C641,751.4,641.4,751.7,641.7,752z"
		/>
	<path class="st3139" d="M540.7,810.9c0.5,0.5,2.5-1.2,1.7,1.4c-0.6,1.9-1.1,4.4,2.3,4.6c-1.3,2.4-3.8,2.3-6,3
		c-0.8-2.3-2.3-4.6,0.5-6.8c0.6-0.5,0.4-0.7-1.5-1.2C538.4,810.8,539.6,811,540.7,810.9z"/>
	<path class="st3140" d="M543.7,803.9c6.5-3.7,12.6-8.1,19.9-10.3c-5.8,4.2-12,7.8-18.7,10.4C544.5,804,544.1,804,543.7,803.9z"/>
	<path class="st3141" d="M610.6,766.9c-0.4,0.6-0.8,1.3-1.1,1.9c-3.6,1.7-7.2,3.5-10.8,5.2c0.3-0.7,0.7-1.4,1-2.1
		C602.6,768.8,605.7,765.8,610.6,766.9z"/>
	<path class="st3142" d="M569.8,789.2c3.3-1.4,6.6-2.8,9.9-4.2c-3.9,3.7-8.7,5.9-13.9,7.1C567.1,791,568.5,790.1,569.8,789.2z"/>
	<path class="st3143" d="M504.8,755c4.2-1.8,6.3,1.8,9,3.6c3.7,2.4,7,5.7,11.9,5.4c2.1,3,5.6,2.2,8.5,2.9c3.1,0.8,10,5.9,10.5,8.9
		c0.5,3-0.4,6.2,1.1,9.2c0.6,1.2-0.8,2-1.8,2.6c-2.2,1.1-2.9,3.1-3.3,5.2c-1.2,1-1.9,2-0.8,3.5c-1.4,1.7-3,2.4-5.1,1.6
		c-1.4-2.2-2.3-4.4-2.2-7.2c0.1-3.4-3.2-5.7-4.2-8.9c-0.5-1.8-3.7-2.9-1.3-5.4c0.1-0.1-0.2-1-0.5-1.1c-7.7-1.9-9.4-10.1-14.9-14.2
		c-1.7-1.3-2.4-3.8-5-4C505.8,756.6,505.1,755.9,504.8,755z"/>
	<path class="st3144" d="M534.7,797.9c1.9,0,3.3-1.3,5-2c4.4-4.3,10.1-6.8,15.5-9.5c0.5,0,1,0.1,1.4,0.3c0.8,0.9,0.4,1.8,0,2.8
		c-0.2,0.4-0.5,0.8-0.8,1.2c-5.2,3.7-11.2,5.9-17.1,8.3c-1.7,0.3-3.3,0.7-5,1c-0.3,0-0.6-0.1-1-0.1
		C533.4,799.2,534.1,798.6,534.7,797.9z"/>
	<path class="st2912" d="M506.7,757c3.5-1,4.2,1.5,5,4C509.1,760.9,508.5,758.2,506.7,757z"/>
	<path class="st3145" d="M727.5,122.7c-0.7-1-1.3-2-2-3c3.7,0.9,7.7,1.1,10.4,4.7c0.7,0.9,2.7,1.3,3.6-0.7c1.1,1.3,2.8,2,3,3.9
		c0,1,0.5,1.8,1.4,2.5c-2.1,1.2-2.3-1.1-3.4-1.4c-1-0.6-2-0.5-3,0c-3.7,1.4-4.5-2.8-7-3.8C729.4,124.4,729.1,122.7,727.5,122.7z"/>
	<path class="st3118" d="M737.5,128.7c1-2,2-2,3,0C739.5,129.3,738.5,129.3,737.5,128.7z"/>
	<path class="st3146" d="M430.8,301.5c0.3-1.8,0.6-3.7,3-4c0.5,0.4,0.8,0.9,1,1.6c-0.1,10.6,0.1,21.2-0.2,31.7
		c-0.9,2.6-2.2,2.9-3.9,0.7c1.5-6.2,1.2-12.3,0.4-18.6C430.7,309.1,430.9,305.3,430.8,301.5z"/>
	<path class="st2925" d="M430.8,331.5c1,0.3,2,1.6,3,0c1.1-0.6,1.4,0.5,2,1c0,1.7,0,3.3,0,5c-3.4,1.9-5.3-0.6-7-2.7
		C426.9,332.4,429.8,332.4,430.8,331.5z"/>
	<path class="st3146" d="M420.9,330.5c0-0.3,0-0.7,0-1c2-1.6,4-0.4,6,0c0.7,0.1,1.9,0.3,1.2,1.4c-0.3,0.5-1.5,0.4-2.2,0.6
		C424.1,331.6,422.4,331.4,420.9,330.5z"/>
	<path class="st2956" d="M433.8,298.5c0-0.3,0-0.7,0-1c-2.7-3.8-2.2-8.2-1.9-12.4c0.2-3.8-3.2-4.7-4.8-7c-0.8-1.1-2.1-1.9-3.1-3.1
		c-2.6-3-1.6,1.6-3.3,2c-3.6-6-4-12.6-3.9-19.4c2.9-0.2,3.2,2.1,3.9,3.9c-0.1-2.1,0.2-4.1,1.3-6c1.4-2.3,3.2-3.7,5.6-1.6
		c1.8,1.6,3.4,1.3,5.4,0.6c2.6-0.9,6,1.9,5,4.2c-2.3,5.2-0.3,10.4-0.8,15.5c0,0.5-0.1,1-0.5,1.4c0,9.7,0,19.3,0,29c-0.3,0-0.7,0-1,0
		C433.8,302.9,435.9,300,433.8,298.5z"/>
	<path class="st3147" d="M442.8,313.5c0,4.1-0.5,8-2,12c-1.8,4.6,2.4,9.1,1.9,14.2c-0.3,3.4-0.7,7.2,0,10.7c0.1,0.6-0.3,1.7-0.9,1.5
		c-1-0.4-1.7-1.4-2.5-2.2c-1-3.4-0.5-6.9-0.5-10.4c0-17.8-0.3-35.6,0.1-53.4c0.3-10.2,1.1-20.5,3.7-30.5c2.4,0.7,3.3,2.4,3.4,4.7
		c-2.1,14.4-1.9,28.8-2.1,43.3c-0.6,2.1-1.1,4.2-0.3,6.3C444,311.1,443.9,312.4,442.8,313.5z"/>
	<path class="st3148" d="M436.8,275.5c-1.8-4.7,0.1-9.9-2-14.5c-0.5-1.2,0.3-1.3,1.1-1.5c3.4-1,0.2-2.1,0-3.1
		c-0.4-2-1.8-1.8-2.9-0.8c-2.4,2.2-4.1,3-5.9-0.8c-0.9-2-2.5-1.1-3.8,0.6c-2.2,2.8-0.7,6.5-2.7,10.1c-1.3-3.1-0.8-6.5-3.8-7.9
		c0-1.3,0-2.7,0-4c0-2.3,0-4.7,0-7c1.3,2.1,2.6,4.3,4.2,6.8c1.6-3.2,0.1-6.5,1.5-9.3c0.8-1.7,1.6-3.6,3-0.8c1,2.2,2.5,2,4.3,2.3
		c4,0.8,8.1,1.7,10.1,5.9C439.2,259.6,439.9,267.8,436.8,275.5z"/>
	<path class="st3149" d="M444.9,258.6c0.4-1.8-2.6-1.3-2.2-3.2c0.4-2.6,0.8-5.1,1.2-7.7c0.2-0.5,0.4-0.6,0.8-0.2
		c1.7-0.8,1.1-3.4,3.1-3.9c1.4,1.2,0.9,2.5,0.3,3.8c1.3-1,0-4.1,2.9-3.9c0.3,0.4,0.6,0.8,0.7,1.3c0.4,3-1.3,5.6-1.7,8.4
		c-0.5,2.2-2.3,3.7-3.2,5.7C446,259.1,445.4,259.1,444.9,258.6z"/>
	<path class="st3097" d="M444.7,247.5c-0.3,0.1-0.5,0.1-0.8,0.2c0-2.1-0.4-4.3,0-6.3c0.4-2,1.2,0.2,1.8,0.1
		C445.3,243.5,445,245.5,444.7,247.5z"/>
	<path class="st3150" d="M433.8,298.5c1-0.3,2-0.7,2,1c0,1.7,0,3.3,0,5c0,9.3,0,18.6,0,28c-0.7-0.3-1.3-0.7-2-1
		C433.8,320.5,433.8,309.5,433.8,298.5z"/>
	<path class="st3151" d="M444.9,258.6c0.6,0,1.1,0,1.7,0c1.1,0.6,1.3,1.7,1.5,2.8c0.1,0.6,0.1,1.1,0.1,1.7c0,1.4,0,2.9,0,4.3
		c-2.1,11.9-1.7,23.9-2,35.8c-0.7,3.4-1.3,6.8-0.3,10.3c0.4,1.4,0.2,3-1.3,4l-0.3,0c0,0-0.3,0-0.3,0c-0.2-1.4,0.4-2.9-1-4
		c0-1.3,0-2.7,0-4c-0.7-1.7-0.7-3.3,0-5C442.6,289.1,442.7,273.8,444.9,258.6z"/>
	<path class="st3151" d="M443.8,322.5c0,2.3,0,4.7,0,7C442.3,327.1,442.3,324.8,443.8,322.5z"/>
	<path class="st3152" d="M446.8,415.5c-2.1-1.9-2.2-5-3.9-7.2c-1.8-2-2.4-4.5-3-6.9l0,0c-2.6-2.4-3.9-5.3-4.7-8.7
		c0.1-0.7,0.4-1.3,1.1-1.6c2.1-0.5,1.9,2.3,3.5,2.4c0.2,0,0.5,0.1,0.7,0.1c0.8,2.1,1.5,4.2,2.3,6.3c0.6,1.9,0.8,3.9,2.2,5.5
		c2,2.3-0.3,5.6,1.9,7.8C447.3,413.9,447.5,414.7,446.8,415.5z"/>
	<path class="st3153" d="M446.8,415.5c0.1-0.8,0.1-1.5,0.2-2.3c1.7,2.8,1.4,6.1,2.2,9.2c-0.1,1-0.1,1.9-0.2,2.9
		C447.1,422.2,448,418.6,446.8,415.5z"/>
	<path class="st3154" d="M445.1,405.3c-2.1-1.3-2.3-3.3-2.2-5.5C444.2,401.5,444.8,403.3,445.1,405.3z"/>
	<path class="st3155" d="M429.9,378.8c0.9,0.9,1.9,1.8,2.8,2.7c1.7,2.7,3.3,5.3,5,8c-0.3,0.7-0.7,1.3-1.6,1.2
		C433.2,387.1,429.1,384.3,429.9,378.8z"/>
	<path class="st3156" d="M436.7,390.4c0.3-0.3,0.7-0.6,1-1c0.7,1.3,1.4,2.7,2.2,4c-2.2,1-1.7-1.9-3.1-2.1c-0.3-0.2-0.4-0.4-0.3-0.6
		C436.6,390.5,436.7,390.4,436.7,390.4z"/>
	<path class="st3157" d="M450.7,364.4c-2.4-0.6-4.3-2.3-6.8-2.5c-1.6-0.2-3-2.4-4.9-0.6c-1.7,1.6-0.4,3.4-0.2,5.1
		c-4.3-3.3-3.9-5.3,2.1-9.9c0.6,0.1,1.3,0.1,1.9,0.2c0.7,0,1.2,0.4,1.7,0.8c1,1.5,2.5,2.3,4.1,3.1
		C449.7,361.7,451.6,362.3,450.7,364.4z"/>
	<path class="st3158" d="M436.7,390.4c0,0.3,0,0.6,0.1,1c-0.4,0.3-0.7,0.7-1.1,1c-2.8,0.4-3.4-2.1-4.7-3.6
		c-3-4.9-5.6-10.1-11.2-12.7c-0.8-0.5-1.4-1.2-2-1.9c-2.4-3.1-5.8-4.7-9.1-6.5c-0.4-0.2-0.7-0.5-1-0.9c-0.2-0.4-0.2-0.9,0.1-1.3
		c3,0.7,5.9,1.4,8.9,2.2c0.9,1.4,2.2,2.2,3.8,2.8c2.7,3.2,7.3,4.4,9.3,8.4C431.1,383.3,434.2,386.7,436.7,390.4z"/>
	<path class="st3159" d="M420.6,370.4c-2.1,0.2-3.5-0.5-3.8-2.8c0.7,0.3,1.3,0.6,2,0.9C419.4,369.1,420,369.7,420.6,370.4z"/>
	<path class="st3160" d="M444.9,355.5c0-3.7-0.1-7.4,1.1-11c1.1-3.9,2.7-1.9,4.3-0.4c0.9,1.6,2.7,2.5,3,4.5c0,1.2-0.2,2.4-0.2,3.6
		c-0.2,3-1.2,5.2-4.7,5.2C446.9,357.3,445.7,356.8,444.9,355.5z"/>
	<path class="st3161" d="M444.5,317.5c0.7-4.6-2.1-9.4,0.4-14c0.5-0.1,0.9,0,1.3,0.4c0.3,0.4,0.5,0.9,0.6,1.3c0.2,1,0.3,2,0.3,3
		c0,1.8,0,3.6,0.1,5.4c0.1,0.8,0.1,1.6,0,2.4c0,3,0.1,6.1,0,9.1c-0.1,1.4-0.1,2.8-0.8,4.1c-0.4,0.5-0.9,0.7-1.5,0.5
		C444.8,325.6,444.6,321.5,444.5,317.5z"/>
	<path class="st3162" d="M449.8,344.5c-1.3-0.8-2.5-2.4-3.8,0c-0.2-1.1-0.4-2.3-0.5-3.4c-0.1-0.8-0.1-1.5,0.6-2
		c4.6-0.9,6.6,2.6,9.1,5.3c0.1,0.5,0,1-0.4,1.4C452.7,346.6,451.2,345.6,449.8,344.5z"/>
	<path class="st3163" d="M446.2,339.3c-0.3,0.6-0.5,1.1-0.8,1.7c-1.3-3.8-0.4-7.6-0.6-11.4c0.3-0.1,0.6-0.2,0.9-0.3
		c0.7,0.5,1,1.2,1.2,2.1c0.3,2.2,0.3,4.3,0,6.5C446.8,338.4,446.5,338.9,446.2,339.3z"/>
	<path class="st3164" d="M444.9,355.5c0.9,0.3,1.8,0.7,2.7,1c0.4,0.3,0.7,0.6,0.6,1.2c-0.2,0.8-0.7,1.2-1.5,1.4
		c-1.4,0.2-2.3-0.5-2.9-1.7c-0.4-0.3-0.7-0.5-1.1-0.8C443.1,355.6,444,355.4,444.9,355.5z"/>
	<path class="st2773" d="M387.9,677.1c5.2,5.3,7.3,11.8,8,19c-4.4-1.7-2.7-7.1-4.7-8.9C387.7,684,387.9,680.7,387.9,677.1z"/>
	<path class="st2773" d="M385.9,665.1c0.7,0,1.3,0,2,0c0,1.3,0,2.7,0,4C386.1,668.3,385.8,666.8,385.9,665.1z"/>
	<path class="st3165" d="M385.9,631.1c3.7-5.4,6.3-10.9,4.2-17.9c-0.9-3,0.9-6.1,3.8-8.1c2.1,2.3,1.1,5.1,1.4,7.7
		c-0.8,10.1-1.6,20.1-1.2,30.3c0.1,2.7,0.2,5.2,0.8,7.8c2.3,10.7,4.8,21.2,8.8,31.5c1.1,2.9,1.8,6.4,0.3,9.7c-0.7,1.7,1.8,3.3,0,5
		c-3.4-6.7-7.4-13.1-8.1-20.8c-0.5-4.9-4.8-8.4-4.2-13.7c0.2-1.8-2.3-1.2-3.7-1.4c0-3.3-0.4-6.7,0.1-10
		C388.8,644.2,386.4,637.8,385.9,631.1z"/>
	<path class="st3166" d="M403.9,692c-1.3-8.6-3.8-16.7-7.4-24.8c-1.9-4.2-1.1-9.5-2.4-14.3c-3.5-13.2-0.8-26.5-0.1-39.8
		c1.7-4.6,4.3-8.8,5.6-13.6c0.4-1.5,1-3,2.4-3.6c4.8-1.7,6.3-7.6,11.2-9.2c0.2-0.1,0.2-1.2,0.7-1.7c2.4-1.7,4.7-4,8-2.1
		c1.6,2.3,0.6,3.9-1.4,5.4c-4.9,4-9.4,8.3-13.3,13.4c-3,3.9-5.5,7.9-6.1,12.9c-0.2,1.5-0.5,3-1.4,4.2c-3.9,5.4-2.8,11.4-1.6,17.2
		c0.3,1.8,1.1,3.5,1.1,5.3c-0.1,9.9,2.8,19.3,4.7,28.8c0.9,4.7,2.4,9.3,3.6,13.9c0.9,3.7,2.3,7.2,5.3,10c2.8,2.6,3.3,5.6,1.1,8.9
		c0.7,1.7,1.3,3.3,2,5c-4.8-2.3-5.4-7.8-8.6-11.4C406,695.3,405.9,692.9,403.9,692z"/>
	<path class="st3167" d="M467.8,729c-0.7,0.3-1.3,0.7-2,1c-1.1-0.3-2.2-0.8-3.3-1c-7.3-1.3-13.3-6-20.7-7.2
		c-1.4-0.2-1.6-1.2-0.8-2.6c1.4-2.6-0.9-2-2.1-2.2c-7.4-7.4-12.6-16.2-16.3-25.9c-3.7-9.5-7.6-19-11.3-28.5
		c-0.5-1.3-0.6-2.6-0.6-3.9c0.3-6.1,0.5-12.2-1.5-18.2c-1.1-3.2-1-6.7-0.2-9.9c1.8-7.2,4.2-14.1,7.2-20.9c0.8-0.6,2-0.9,2.4-1.7
		c3.8-7.2,10.4-11.7,16-17.2c4.2-4.2,9.5-6.6,14.5-9.4c1.6-0.9,3.6-0.7,5.2,0.5c1.6,3,0.3,4.6-2.6,5.6c-11.9,4.3-21,12.5-27.4,22.7
		c-4.1,6.6-9.3,13.8-8.2,22.5c1.1,8.4-0.3,17.2,3.6,25.2c2.5,5,2.5,10.6,4.5,15.7c2.5,6.4,5.4,12.5,9.6,17.8
		c0.4,0.5,0.7,1.1,0.9,1.8c2.3,10.5,8.7,17.9,17.6,23.4c3,4.8,7.6,7.6,12.6,9.9C465.9,727.2,467.9,726.7,467.8,729z"/>
	<path class="st3168" d="M411.9,659.1c0.1,3.7,1.7,7,3.2,10.1c4.8,9.8,7.8,20.3,12.6,30.1c2,4.1,3.6,7.9,7.4,10.4
		c2.6,1.8,2.6,4.8,3.8,7.3c-1.9-0.2-4.3,0.8-4.5-2.9c-0.1-1.7-2.2-1.3-3.5-0.1c-0.9,0.3-1.4-0.3-1.8-1c-0.4-2.8-1.8-5.2-3.3-7.6
		c-7-11.6-12.8-23.8-15.3-37.3c-0.6-3.3-3.6-6.3-1-9.8C410.7,657.3,411.2,658.3,411.9,659.1z"/>
	<path class="st3169" d="M429.8,713c0.3,0.3,0.7,0.7,1,1c-1.6,1.7-1.6,3.3,0,5c-3.2-0.2-3.5-4-6-5c-4.7-5.3-7.5-11.8-10.7-18
		c-1.2-2.2-1.9-4.2-0.8-6.6c2.5,0,8.2,4.3,8,6.7c-0.4,4,1.8,6.4,4.2,8.9C427.5,707.4,429.4,709.8,429.8,713z"/>
	<path class="st3170" d="M413.9,689c-0.3,3.6,1.3,6.3,2.9,9.5c2.6,5.2,5.8,10.1,8.1,15.5c-2.8-3-6.5-5.1-7.9-9.2
		c-0.6-1.7-1.7-1.8-3.1-1.8c0.2-3.7,0.4-7.2-3.7-9.4c-1.1-0.6-1.7-2.4-2.2-3.8c-2.7-7.8-4.7-15.7-6.3-23.8
		c-1.8-8.8-3.8-17.5-4.1-26.5c-0.1-3-2-6.1-1.8-9.5c0.3-4.3,0.1-8.3,2.8-12.5c2.5-3.8,2.1-9.4,5.2-13.5c4.8-6.4,9.9-12.5,16.6-17
		c1.7-1.1,1.2-2.5,1.4-3.9c0.2-0.2,0.3-0.4,0.5-0.6c8.5-2.9,16.5-7,24.6-10.7c2.5-1.1,4.8-1.1,6.7,1.1c-0.3,3.8-3.3,4.9-6.1,5.3
		c-9.4,1.6-16.2,7.3-22.7,13.6c-6.8,4.7-12,10.9-15.9,18c-2,3.7-2.8,8.1-3.6,12.2c-1.7,8.1-2.4,16.4-1.7,24.6
		c0.4,4.4,0.5,9.3,3.4,13.2c0.4,0.5,0.7,1.2,0.7,1.8c0.2,9,4,17,6.5,25.3C414.5,687.9,414.3,688.5,413.9,689z"/>
	<path class="st3171" d="M489.8,741c0.8,0,1.6-0.2,2-1c6.1-2.2,12.2,0.9,18.4,0c2.8-0.4,5.9-0.7,8.8,0.3c2.7,2,6.1,1.6,9.1,2
		c5.5,0.9,9.5,5,14.8,6.1c4,3.1,8.7,3.5,13.4,4.2c2.4,0.3,4.8,0.7,3.9,4.1c-3,2-7.2,1.7-9.6,4.9c-6.3,1.9-12.5-0.8-18.8-0.7
		c-0.1-1,0-2.7-0.9-2.6c-5.7,0.7-9.1-4.2-13.9-5.7c-3.8-1.2-6.1-4.8-9.3-7.1c-1.8-1.3-2.8-2.4-5.3-0.9c-2.2,1.3-5.4,3.2-6.9-1.8
		C494.7,740.6,490.7,744.5,489.8,741z"/>
	<path class="st3172" d="M531.7,761c6.3-3,12.7,1.2,19,0c3.7-1.2,8.5,0.8,9.3,5.3c0.8,4.5,3.7,8.6,2.5,13.5c-1.2,2.9-4.5,3-6.5,4.9
		c-3.4,3.2-8.3,4.4-11.8,7.8c-0.9,0.9-2.8,1.9-4.5,0.5c-0.1-1.8-0.2-3.8,1.5-5c2.2-1.5,3-2.1,2.4-5.9c-0.7-4.9-0.5-8.8-5.4-11.6
		c-1.3-0.7-0.9-3.5-3.9-1.9c-2.1,1.1-8.4-2.9-8.6-4.7C529,765.6,529.7,761.8,531.7,761z"/>
	<path class="st3173" d="M479.8,728c-1,0-2,0-3,0c-2.8-5.7-9.2-4-13.4-6.9c-2.1-2.7-2.3-5.1,1.1-6.9c0.6-0.3,1.3-0.7,1.9-0.5
		c7.2,2.7,15,1.1,22.4,2.8c6,2.7,12.5,3.3,18.9,4.1c1.3,0.2,2.6,0.1,3.9,0.3c2,0.5,3.3,1.5,2.6,3.8c-8.5,1.9-15.3,9.9-25.1,7.6
		C485.4,732,482.7,729.9,479.8,728z"/>
	<path class="st3174" d="M479.8,728c3.7-1.2,5.5,3.6,9,3c3.5,1.5,7.1,0.5,10.7,0.3c1.3-0.1,2.8-0.7,3.8,0.2
		c4.5,4.3,10.3,3.8,15.7,3.9c7.1,0.2,14.1,1.9,21.3,0.8c1.5-0.2,2.9,0.4,3.7,2c-0.6,1.9-2.3,2.6-3.9,2.4c-5.2-0.6-10.1,0.5-15.1,1.2
		c-2.1,0.3-4.3,0.4-6.2-0.9c-5.1,1.1-10.4,0.2-15.5,0.5c-3.4,0.2-7.8,0.3-11.5-1.5c-1.5-2.8-4.7-2.8-7.1-3.9
		C480.9,734.5,479,732.2,479.8,728z"/>
	<path class="st3175" d="M463.8,721c1.9,0.3,3.8,0.8,5.7,1c3.8,0.4,6.8,1.6,7.3,6c-2.9,1.2-5.9,1.6-9,1c-5.5-1-9.4-4.9-13.9-7.6
		c-2-1.3-2.1-2.6-2.1-4.3c0.9-2.8,2.8-1.4,4.1-0.7C458.5,717.9,461.4,719,463.8,721z"/>
	<path class="st3176" d="M712.5,394.4c1.8,1.8,4,0.7,6,1c-1.2,1.3-2.2,2.9-3.6,4c-2.1,1.8-4.1,3-0.6,5.6c1.9,1.4,2.1,4-1.4,4.3
		c-2.6,0.2-3.1,2-3.4,4c-3.5,1.7-4-2.8-6.5-3.1c-4.9-1.2-9.3-0.9-12.8,3.3c-0.6,0.7-1.5,1.2-2.6,1c-1.9-3.5,0.2-6,2.3-8.3
		c4.6-5.1,4.3-8.4-1.7-11.8c-2.5-1.4-4-2.6-4-6c0-2.5-3.3-3.6-5.9-3.5c-1.8,0.1-4.2,0.9-4.7,2.4c-2.1,6-7.6,9.7-9.7,15.9
		c-2.9,8.5-6.9,16.7-13.7,23.2c-1.6,1.5-1.9,3.6-1,5.5c0.9,2.1,2.6,0.4,3.9,0.4c3.4,0.2,6.8-0.6,9.9,2.2c2.8,2.5,5.6,0.4,7.2-1.9
		c4-5.8,4.3-5.8,8.6,0.7c-3,4.1-5.4,8.8-11.6,8.3c-0.8-0.1-1.7,0.3-2.3,0.8c-2.8,2.4-5.9,1.9-8.1-0.3c-3.9-3.9-9-4.6-13.6-6.6
		c-1.5-0.4-2.9-0.9-4.1-1.9c-2.4-3.5-1.4-6.7,1.4-9.2c1.4-1.2,2.7-2.5,3.4-4.1c1.8-3.7,3.8-7.1,6.7-10.2c3.6-3.8,3.3-9.5,5-14.3
		c1.2-2.3,2.2-4.7,2.8-7.3c1.1-4.3,4.2-7.9,5-12.5c0.2-1.4,2.1-2.2,3.5-2.4c5.2-0.8,10.1-2.2,13.5-6.6c0.5,0,1,0.2,1.4,0.6
		c1.1,1.6,0.9,3.4,0.9,5.2c-0.8,4,0.2,6.9,4.7,7.8c1.8,0.6,3.1,1.9,4.3,3.3c0.3,0.3,0.6,0.6,1,1c1,0.9,2,1.7,2.4,3
		c1,3.4,3.3,4.1,6.4,2.9c3.1-0.1,4.5,4.8,8.3,3C710.9,393.5,711.8,393.6,712.5,394.4z"/>
	<path class="st3177" d="M703.5,409.4c3.1-0.3,2.9,4.3,6,4c1,0.7,1.3,1.8,0.6,2.6c-2.2,2.5-1.5,4.4,0.8,6.3c1.6,1.3,1.6,2.5,0.3,4.2
		c-2.3,2.9-2.8,6.5-3.9,9.9c-1.5,4.9-5.5,8.9-9.2,12.5c-5.6,5.3-6,4.9-1.5,11c0.7,0.9,1.1,1.7,0,2.5c-6.3-2.3-10.6,3.4-16.2,3.8
		c-1.6-0.4-3.1,0-4.7-0.1c-0.5-0.1-0.9-0.4-1.2-0.7c-0.3-1.1,0.2-1.9,0.8-2.6c1.2-1.2,2.8-1.9,4.3-2.6c4.8-2.1,7.9-6.2,11.6-9.7
		c6.1-5.8,8.6-13.2,12.3-20.1c1.5-2.9,2-5.9,1.8-9.1c0.3-3.7-0.2-7.3-2.2-10.5C703,410.2,703.2,409.8,703.5,409.4z"/>
	<path class="st3178" d="M679.6,465.3c2.3,0.2,4.5-0.7,5.9-2.1c3.6-3.6,7.2-2.9,11.1-0.9c0,0.7,0,1.3,0,2
		c-5.8,7.3-13,13.2-20.6,18.6c-1.2,0.9-2.5,1.6-4.1,0.8c0.1-3.1-0.6-5.6-4.6-4.7c-1.6-0.5-3.6-0.4-4.1-2.6c0.1-0.9,0.6-1.7,1.5-1.8
		C670.9,473.6,675,469.2,679.6,465.3z"/>
	<path class="st3179" d="M671.6,483.3c6.1-2,10.9-6.2,15.4-10.4c3.1-3,6.5-5.6,9.6-8.5c0.3,0,0.7,0,1,0c-2.1,6.1-9,7.7-12,13
		c0.3,2.9-2.3,3.2-3.9,4.3c-4.1,2.7-7.9,5.8-11.6,9c-1.7,1.5-4.3,2.5-6.9,2.1c-1.1-0.9-3.4-0.3-3.5-2.1c-0.1-2.1,2.1-2.2,3.6-2.6
		c2.7-0.8,4.1-3.8,7-4.3C670.7,483.5,671.1,483.4,671.6,483.3z"/>
	<path class="st3180" d="M737.5,289.5c2.7-0.4,4.6,0.4,6.1,2.9c2.6,4.3,6.9,7.6,7,13.3c0,1.8,1.2,2.6,2.9,2.7c0,0.3,0,0.7,0,1
		c-2.3-0.2-1.7,2.6-3.1,3.3c-1.5-0.6-1-3.5-3-2.7c-1.7,0.7-1.2,2.7-0.6,3.9c3.1,6.7,2.8,13.5,1.8,20.7c-0.6,4.1,1.2,8.5,0.8,12.8
		c-0.2,7-2.4,14-6.4,19.1c-5.3,6.9-8.1,16.9-18.6,18.8c-0.7-1.9,0.8-3,1.5-4.4c3-3.9,7.6-6.6,9.2-11.7c1.5-1.5,2.8-3.1,3.7-5
		c5.1-9,7.5-18.7,7.3-29c-0.2-7.1-1.7-13.6-7-19c-2-2.1-2.7-5.3-5-7.3c-0.9-1.4-2.3-2.3-3.7-3.1c-0.3-0.3-0.6-0.7-0.7-1.2
		c-0.4-3.3,2.4-3.9,4.7-5.2c-1.7-2.8-3.2-5.7-1.3-9C734.2,288.8,735.8,289,737.5,289.5z"/>
	<path class="st3181" d="M726.5,381.4c0.1,1.7-1.4,2.6-2,4c-0.3,0.3-0.7,0.7-1,1c-4.5,4.6-10.2,5.7-16.3,5.2c-0.5-0.1-1-0.2-1.6-0.3
		c-4.9-1.5-9.4-4-14.1-5.9c0-0.3,0-0.7,0-1c-0.9-2.4-3.4-4-3.6-6.9c0.1-0.8,0.5-1.3,1.3-1.5c4.3,2.3,6.9,7,11.7,8.7
		c7.5,2.2,14.6,0.5,21.5-2.2C723.7,381.9,724.9,380.7,726.5,381.4z"/>
	<path class="st697" d="M706.5,390.4c5.2,0,10.4,0.2,14.5-3.8c0.4-0.4,1.6-0.1,2.4-0.2c-1.6,3.7-3.3,7.4-8.3,7c-1-0.1-2,0.1-2.7,1
		c-1,0-2,0-3,0C706.7,394.4,706.1,392.8,706.5,390.4z"/>
	<path class="st3182" d="M707.5,481.3c1.2,1,1.8,2.4,2,4c0.6,0.9,1,2,0.3,2.8c-5.6,6.2-1.2,12.3,0,18.5c0.2,0.4,0.4,0.9,0.6,1.3
		c0.4,3.2,0.7,6.4,1.9,9.4c0.3,1.7,0,3.2-1.5,4.2c-0.5,0.2-1,0.1-1.5-0.2c-1.2-1.5-1.6-3.3-1.9-5.1c-1-5.3-2.7-10.5-4.5-15.6
		c-0.7-1.9-1.4-3.7-1.4-5.7c1-2.5-1.2-5-0.3-7.5C703.7,485.8,705.5,483.4,707.5,481.3z"/>
	<path class="st3183" d="M709.5,507.3c-4.6-4.8-5-14.1-0.8-19.2c0.7-0.9,0.7-1.8,0.8-2.8c0.3,0,0.6,0.1,0.9,0.1c0,0.3,0,0.6,0.1,0.9
		c1.2,0.8,1.9,1.9,1.9,3.4C713.8,495.9,714.9,502.1,709.5,507.3z"/>
	<path class="st3184" d="M707.5,481.3c-0.8,3.2-1.9,6.1-6,6c-2.6-0.1-1.9-2-1.3-3c1.3-2.2,0.6-4-0.2-6c0.6-2.3,2.4-2.9,4.4-2.9
		C705.5,477.4,706.5,479.3,707.5,481.3z"/>
	<path class="st3185" d="M702.6,471.5c1.4-1,2.8-2,4.1-3c-0.1,0.6-0.3,1.2-0.4,1.7c-1.7,0.9-2,2.7-2.8,4.1c-3.1,2-4.5,6.4-9,6.5
		c-2.1-0.9-1-2-0.1-3C696.9,475.4,699.7,473.4,702.6,471.5z"/>
	<path class="st3186" d="M694.3,480.5c3.6-1.2,5.6-5,9.2-6.1c0.3,0.4,0.7,0.7,1,1.1c-1.3,1-2.6,1.9-4,2.9c-0.7,1.8-2,2.8-3.8,3.5
		c-0.7,0.2-1.3,0.2-1.9-0.2C694.5,481.3,694.3,480.9,694.3,480.5z"/>
	<path class="st3187" d="M743.5,465.3c5.2,2.8,6.7,7.8,8,13c-1.3-1.3-2.6-2.7-4-4c-0.3-3.8-4-5.6-5-9
		C742.8,464.9,743.1,464.9,743.5,465.3z"/>
	<path class="st3120" d="M743.5,465.3c-0.3,0-0.7,0-1,0c-1.3-0.6-2-1.6-2-3C741.8,463,742.8,464,743.5,465.3z"/>
	<path class="st3188" d="M764.5,514.2c0.3,1.3,0.7,2.7,1,4c0.2,2.1-0.5,4-1,6c-3,0-6,0.1-9,0.1c-1.4,1.4-3.4,1.5-5.1,1.2
		c-5.5-1.1-11.1-0.5-16.6-1.7c-2.2-0.4-4.5-0.1-6.7-0.6c-1.3-0.4-2.7-0.8-3.9-1.5c-2.7-4.1-5.3-8.2-3.7-13.4
		c0.5-2.7-0.4-5.4-0.4-8.2c0.1-5.7,2.6-8.1,6.9-11.6c4-3.3,7.4-2.7,11.2-3.2c1.3-0.2,2.9,0.6,3.7-0.7c1.6-2.5,2-3,3.3,0.4
		c1.2,3,4.1-0.4,6.2,0.3c2.7,5.3,5.3,10.7,8,16c0.8,1.3,0.6,3,2,4l0,0C761.2,508.5,762.5,511.5,764.5,514.2z"/>
	<path class="st3189" d="M719.5,508.3c0.7,4.5,2.4,8.7,4,12.9c-0.8,1-1.9,1.2-3.1,1.3c-2.6,0.1-5.1,0.2-7.7-0.1
		c-0.9-0.2-1.7-0.5-2.2-1.3c0.7-1.2,1.1-2.5,1-3.9c-0.4-3.4,0.4-7-2-10c0,0,0,0,0,0c-0.2-1.9,0.6-3.6,1.7-5.1
		c2.9-4.2,0.7-8.6,0.3-12.9c2-0.6,1.1,1.6,2,2.1C715.1,497.1,717,502.8,719.5,508.3z"/>
	<path class="st2945" d="M713.5,491.4c-0.7-0.7-1.3-1.4-2-2.1c-0.3-1-0.6-2-0.9-3C713.9,486.6,713.5,489.1,713.5,491.4z"/>
	<path class="st3190" d="M733.4,522.6c7.3,0.6,14.7,1.2,22,1.7c2.9,1.3,6.1,0.4,9,1.2c0.6,0.6,0.6,1.1,0,1.7c-0.3,0.2-0.7,0.4-1,0.5
		c-0.5,0.3-1.1,0.4-1.7,0.5c-7.7-1-15.4,0.3-23.2-0.5c-3.3-0.3-6.6-0.7-9.8-1.2c-1.7-0.3-3.2-0.9-3.5-2.9
		C727.8,521.8,730.8,523.3,733.4,522.6z"/>
	<path class="st3191" d="M764.5,514.2c-3-2.2-4.1-5.4-4-9C762.1,508.1,763.6,511,764.5,514.2z"/>
	<path class="st3191" d="M764.4,524.2c0-2-0.2-4.1,1-6C767.3,520.6,765.6,522.4,764.4,524.2z"/>
	<path class="st3191" d="M760.5,505.3c-2.7-0.3-1.8-2.4-2-4C759.8,502.3,760.3,503.7,760.5,505.3z"/>
	<path class="st3192" d="M764.4,527.2c0-0.6,0-1.1,0-1.7c0.9-0.1,1.9-0.2,2.8-0.2C766.9,526.7,765.8,527.2,764.4,527.2z"/>
	<path class="st3193" d="M763.5,529.2c1.3-0.3,2.6-0.7,4-1c2.3-0.3,3.2-1.8,3-4c0-0.7,0-1.3,0-2c1-1.6,2-1.5,2.6,0.2
		c0.3,0.8,0.3,1.5,1.3,1.6c1.5,0.9,1.6,2.1,0.6,3.3c-2.5,3-0.4,4.3,1.9,5.7c3.5,2.2,7.5,3.6,10.1,7.1c0.7,1.3,1,2.8,1,4.3
		c-2.1,3.8-4.4,7.4-8.2,9.8c-0.3,0.2-0.7,0.3-0.8,0.6c-2.2,7-8.8,7.7-14.3,9.9c-2.7,1.1-3.9,4.7-7.4,4.4c-1.4-2-0.5-3.7,0.4-5.5
		c1.6-2.9,2.7-6,3.4-9.2c0.1-1,0.1-2,0.6-2.9c2-4.1-0.5-6-3.7-7.5c-2.6-1.2-5.9-1.1-7.7-3.9c-0.5-2.1-2.1-1.5-3.6-1.6
		c-2.2-0.5-4.6-0.8-4.5-4c0.4-1.2,1.1-2.1,2.4-2.3c2.1-0.3,4.1,1.7,6.5-0.6c1.9-1.8,5.3-1.7,7.8-3C760.4,528.1,762,528,763.5,529.2z
		"/>
	<path class="st3194" d="M773.4,524.2c-1.9,0.6-0.2-3.9-3-2c-1.7-5.3-3.4-10.7-5-16c0.5-3.6-0.5-7-1.9-10.9c2.8,0.8,4.5,2.3,5.5,3.9
		c1.5,2.5,1.7,6.9,5.9,2.4c0.3-0.3,1.4-0.2,1.8,0.2c4.8,4.5,9.7,3.2,14.5,0.1c1.9-1.2,3.9-0.6,5.8-0.7c3.4-0.3,7,0.8,10.2-1
		c-0.3,3.2,2.7,5.7,2,9c-1.1,4.1-3.7,5.9-8,5.4c-1.8-0.2-3.6,0.4-5.1,1.7c-2.2,1.9-4.7,3.1-7.5,3.7c-1.7,0.4-2.9,1.2-3.9,2.7
		c-3,4.3-4.5,4.6-8.9,1.6C775,523.4,774.3,522.9,773.4,524.2z"/>
	<path class="st3195" d="M763.5,529.2c-4.7,0.1-8.8,2.5-12.9,4.3c-2.8,1.2-6.3-2.4-8.1,1.7c-2.1,0.9-3.8-0.8-5.7-0.9
		c-2.5-0.8-4.6-2.2-6.8-3.6c-1.1-0.7-2.8-1.7-0.6-3.2c1.1-0.5,2.2-0.3,3.4-0.3c10,0.4,20,0.4,29.9,0.2c0.3,0.1,0.5,0.2,0.8,0.4
		C763.5,528.2,763.5,528.7,763.5,529.2z"/>
	<path class="st3194" d="M770.5,524.2c1.3,1.2,1.4,2.5,0.2,3.7c-0.8,0.8-2,1.8-3.2,0.3C768.4,526.9,769.5,525.6,770.5,524.2z"/>
	<path class="st3196" d="M662.6,492.3c3.9-1.5,7.8-2.2,10.6-6.3c1.8-2.6,5.6-3.9,8.6-5.8c1.4-0.9,3.1-1.2,3.8-2.9c0.7,0,1.3,0,2,0
		c1.4,2-0.5,3.2-1.3,4.7c-0.3,0.3-0.6,0.6-1.1,0.8c-2.2,0.4-3.9,1.6-5.3,3.3c-2.2,2.4-5.2,3.8-7.5,6c-0.3,0.3-0.7,0.6-1.1,0.8
		c-1.9,0.8-3.8,1.8-5.8,2.3c-1,0.1-1.9,0.1-2.8-0.4c-0.7-0.5-0.8-1.2-0.7-1.9C662.3,492.7,662.4,492.5,662.6,492.3z"/>
	<path class="st3197" d="M685.6,482.4c-0.3-2.1,2.1-3.1,1.9-5.1c1.7-0.7,3.3-1.3,5-2c-0.3,0.9-0.7,1.9-1,2.8
		C690.5,480.8,689.2,483.3,685.6,482.4z"/>
	<path class="st3198" d="M685.6,482.4c2.7-0.4,3.8-3,5.9-4.2c4.1-2,7.2-5.4,11-8c0,0.4,0.1,0.8,0.1,1.3c-1.8,3.9-7.3,4.4-8.3,9
		c0,0.3,0.1,0.5,0.2,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-4.7,3.6-9.7,6.8-14.5,10.2c-2.1,0.9-3.6,3.4-6.4,2.2c-0.6-0.9-0.6-1.8-0.3-2.7
		c3-2.8,6.7-4.7,9.7-7.5C683.5,483.2,684.5,482.8,685.6,482.4L685.6,482.4z"/>
	<path class="st3199" d="M447.9,438.4c0-1.7,0-3.4-0.1-5.1c0-0.3,0.1-0.6,0-1c1.5-0.9,0.6-2.6,1.4-3.7
		C449.9,432,449.3,435.3,447.9,438.4z"/>
	<path class="st3200" d="M431.8,388.4c1.5,1.2,2,3.2,3.9,4c1.3,3,3.4,5.6,4.2,8.9c-2.7,1.6-0.9,3.7-0.4,5.3c2.5,7.5,4,15.2,3.3,23.2
		c-0.1,1.6,0.6,3.3-1.1,4.5c0.3-8.1,0.8-16.3-2.9-24c-2.5-2.7-2.9-6.4-4.7-9.4c-0.9-2.1-2.6-3.7-2.9-6.1c0.2-1.8-0.9-3.4-0.9-5.1
		C430.6,389,431.1,388.6,431.8,388.4z"/>
	<path class="st3200" d="M439.9,401.3c1,2.3,2,4.6,3,6.9C439.9,406.8,439.8,404.1,439.9,401.3z"/>
	<path class="st3201" d="M395.9,388.4c0-1.9-0.3-4,2-5c0.8-1.1,1.8-2.2,2.8-3.1c0.4-0.3,0.9-0.4,1.3-0.5c1.6-0.1,3.3-0.3,4.9-0.1
		c1.3,0.1,2.8-0.2,3.3,1.5c0.4,1.5-0.7,2.4-1.7,3.3c-0.4,0.3-0.9,0.5-1.2,0.8c-1.4,1.7-4.9-1-5.2,2.3c-0.2,2.1,1.7,3.9,2.3,5.9
		c1.5,4.9,0.7,10.1,1.7,15.2c0.5,2.9,0.6,5.9,0.9,8.8c0.2,0.5,0.3,1.1,0.6,1.3c6.5,3.4,2.4,9.1,2.9,13.8c0.1,0.6-0.8,1-1.5,0.7
		c-1.8-0.8-2.2-2.6-3-4c-6.2-9.6-9.5-20.2-11.3-31.3C394,394.7,395.6,391.6,395.9,388.4z"/>
	<path class="st3202" d="M412.8,437.4c-0.3-0.3-0.7-0.7-1-1c1.2-3.3,0.3-6.3-0.4-9.6c-0.6-2.5,2.2-2.6,4.3-1.9
		c2.1,2.8,4.3,5.3,7.1,7.4c3.6,2.8,3.4,4.4-0.3,7.8c-2.3,0.9-4.9,2.9-6-1.5C415.9,436.6,413.7,438.9,412.8,437.4z"/>
	<path class="st3203" d="M415.9,425.4c-2.9,0-5.6,0.2-2.5,4c1.7,2,3,3.8-1,4.8c-0.3,0.1-0.4,1.5-0.5,2.3c-1.3-0.7-2.3-1.7-3-3
		c1.6-1.5,1.6-3.7,0.3-4.9c-3.1-3,2.5-3.1,1.5-5.5c-1-2.3-5.1-0.6-4.8-4.1c0-0.5,0-1,0-1.5c1-1.2,2.3-1.6,3.9-1.3
		c2.3,2.2,4.2,4.8,6.6,6.9C416.6,423.8,418.2,425,415.9,425.4z"/>
	<path class="st3203" d="M412.8,437.4c2.2,0.6,2.8-1.6,4-2.6c2.2,1.2-1.4,8.4,5,4.6c3.8,1.6,7.1,0.6,10.3-1.7c1.9-1.3,3.1,0,4,1.6
		c-0.1,2.7-2.6,3.1-4.2,4.2c0,0.3-0.1,0.6-0.1,0.9C424.5,444.7,417.6,443.9,412.8,437.4z"/>
	<path class="st3203" d="M395.9,388.4c-2.1,14.1,3.6,26.4,9.3,38.6c0.3,0.7,0.5,1.6,0.7,2.4c-5.9-5.4-7.2-13-10-19.9
		c-1.4-1.1-0.8-2.7-1-4.1C394.4,399.7,392.8,393.9,395.9,388.4z"/>
	<path class="st3204" d="M431.9,443.5c1-1.7,3.3-2.1,3.9-4.1c-1.5-3.1,2.2-4.7,2.2-7.4c1.1-6.5,0.1-12.7-2.8-18.6
		c-2-4.1-2.5-8.4-1.6-12.9c0.5-0.4,0.9-0.4,1.4-0.1c2,3.1,3.3,6.4,3.9,10c0,6.6,2.6,13,2,19.7C440.3,436.4,436.7,442.2,431.9,443.5z
		"/>
	<path class="st3205" d="M418.8,373.4c0.3,0.7,0.7,1.4,1,2c4.4,2.4,7.7,5.5,8.2,10.8c-0.3,0.4-0.7,0.6-1.2,0.7
		c-4.9-2.1-8.3-6.2-11.9-9.9c-3.6-2.9-7.9-2.4-12-2.7c-0.1-0.3-0.1-0.7-0.1-1c0.3-0.4,0.6-0.7,1.1-0.9c4.1-2.1,8.2,1,12.3,0.2
		C417.2,372.6,418.1,372.8,418.8,373.4z"/>
	<path class="st3206" d="M399.9,380.4c3.9-4.7,8.1-5.3,15-2c2.1,0.2,3.2,1.4,3.5,3.4c-0.8,2-3,1.1-4.2,2.3c-1.1,0.6-1.9,1.5-2.5,2.6
		c-0.2,0.4-0.5,0.7-0.7,1.1c-1.4,1.5-2.8,1.8-4.1,0c-1.1-1.6-0.1-2.8,1.4-3.6c1.4-0.8,2.6-1.8,2.1-3.5c-0.6-1.7-2.2-0.1-3.2-0.5
		c-2.1-0.9-4.1,0.1-6.2,0.3l0,0C400.5,380.8,400.2,380.8,399.9,380.4z"/>
	<path class="st3207" d="M417.9,382.4c-0.3-1.9-2-2.7-3.1-4c0-0.4,0.1-0.9,0.1-1.3c4.8,2,7.7,6.3,11.5,9.5c3.2,1.6,4.4,4.7,5.5,7.8
		c1,2,2,4,3,5.9c-0.4,0-0.8,0.1-1.2,0.1c-2.5,0-2.9-2.3-3.9-3.8c-3.1-4.6-6.1-9.3-10.9-12.5C418.4,383.7,418,383.1,417.9,382.4z"/>
	<path class="st2965" d="M395,381.5c0.5-3.4,1.7-6.1,5.8-6.1C398.9,377.5,396.9,379.5,395,381.5z"/>
	<path class="st3208" d="M399.9,380.4c0.3,0,0.7,0,1,0c-0.7,1.3-1.6,2.4-3,3C397.9,382,398.6,381,399.9,380.4z"/>
	<path class="st3209" d="M403.9,373.4c-0.3,0-0.7,0-1,0c-2,0-3.7-0.6-5-2c0.4-1,1.1-1.6,2-2c1.9-1.5,3.6-1,5.1,0.6
		C406.8,371.8,405.7,372.8,403.9,373.4z"/>
	<path class="st3210" d="M403.9,373.4c2.3-4.9-1.8-3.5-4-4c0-0.3,0-0.6,0.1-0.9c2.4-2.1,5.1-2.7,8.2-1.6c2.6,2.2,6.6,2.5,7.7,6.4
		c-0.1,1.4-0.8,1.7-1.9,1.1C410.7,372.6,407.3,372.8,403.9,373.4z"/>
	<path class="st3211" d="M408.9,367.4c-3-0.2-6-0.1-8.9,1.1c-0.7-0.1-1.4-0.1-2.1-0.2c1.7-1,3.4-1.9,5.1-2.9c0.3,0.1,0.7,0.1,1,0.2
		c1.3,0.6,2.8-0.6,3.9,0.7c0.3,0,0.6,0.1,1,0.1c0.3,0.2,0.3,0.4,0.2,0.6C409.1,367.3,409,367.4,408.9,367.4z"/>
	<path class="st3212" d="M469.9,401.1c0.1-1.4-0.1-2.7-1.1-3.8c-1.9-1.5-2-3.8-2.8-5.8c-0.2-0.7-0.3-1.5-0.2-2.2
		c0.2-1.1,0.6-2,1.8-2.1c1.9,0.7,2.5,2.4,3.2,4.1c1,3.3,2.3,6.5,3.1,9.8c0.2,1.5,0.8,2.9,1.1,4.3c0.6,3,1.7,5.9,1.9,9
		c0,1.1-0.1,2.1-0.3,3.1c-0.7,1.7-0.3,3.8-2,5.1c-1.6-2.9,0-6.4-1.7-9.3c-0.2-1.7,0.1-3.5-1-5C469.7,406.3,471,403.4,469.9,401.1z"
		/>
	<path class="st3213" d="M462.8,415.4c3,2.2,7.1-1.1,10,2c0.3,2.9-0.4,6.7-2.8,7.1c-2.5,0.5-4.9-2.4-5.6-5.6
		C464.1,417.7,462.7,416.8,462.8,415.4z"/>
	<path class="st3214" d="M474.6,422.6c0.5-1.7-0.5-3.7,1.1-5.2c2,1.6,2.1,3.9,2.4,6.2c0,2.8,0.2,5.7-0.4,8.5
		c-0.5,1.4-0.8,2.8-2.3,3.5c-2-1.9-1-4.2-0.7-6.4C474.7,426.9,474.6,424.8,474.6,422.6z"/>
	<path class="st3215" d="M474.7,429.1c0.6,2-0.7,4.3,1,6.2c1.9,2.3,1.2,4.3-0.9,6c-0.9-0.5-1-1.4-1.1-2.2c0-0.6-0.1-1.2-0.1-1.8
		c0.1-1.3,0.1-2.7,0.2-4C474.1,432,473.6,430.4,474.7,429.1z"/>
	<path class="st3216" d="M454.9,391.3c-0.8-2.1-2.8-3.6-3.1-5.9c0.5-2.8-2-4-3-6c-0.8-0.9-1-2.2-2-3l0,0c-0.6-1.5-1.4-3-2.3-4.4
		c-0.9-1.4-1.8-3.1-0.2-4.5c1.6-1.4,3.5-1.7,5.5-0.6c3.3,1.7,5.6,4.6,8.6,6.7c1.2,1,2,2.4,2.3,3.9c-0.1,2.9,0.7,5.8,0.3,8.8
		C460.6,390,459,392.2,454.9,391.3z"/>
	<path class="st3217" d="M458,374.3c-3.2-0.9-4.8-3.8-7.2-5.7c-2-1.6-3.8-2.1-5.6-0.7c-2,1.4,0.1,2.9,0.6,4.3
		c0.5,1.4,1.2,2.7,1.1,4.2c-3.8-4.7-3.8-4.7-6.1-13.4c3.4,3.4,7.2,2.4,10.9,2.5c0.3-0.4,0.7-0.6,1.2-0.4c1.5,1.1,2.5,2.8,3.7,4.2
		C457.2,370.9,459.1,372.2,458,374.3z"/>
	<path class="st3218" d="M446.7,358.5c0.4-0.4,0.8-0.8,1.2-1.2c1.5-0.4,2.3,0.7,3.3,1.5c0.5,0.4,0.9,0.9,1.3,1.4
		c0.5,0.7,0.9,1.5,0.7,2.5c-0.3,0.9-0.6,1.7-1.6,2c-0.3-0.1-0.6-0.2-0.9-0.3c-0.9-1-1.7-2.1-2.6-3.1
		C447.1,360.6,446.5,359.8,446.7,358.5z"/>
	<path class="st3219" d="M451.6,364.8c0.2-0.9,0.2-1.8,1.1-2.3c2.4,0,2.6,1.4,1.9,3.3c-0.8,0.4-1.6,0.3-2.3-0.1
		c-0.2,0-0.5-0.1-0.7-0.1C451.6,365.2,451.6,365,451.6,364.8z"/>
	<path class="st3217" d="M446.8,376.4c2,0.1,2,1.5,2,3C447.5,378.8,446.8,377.8,446.8,376.4z"/>
	<path class="st2975" d="M469.9,401.1c1.3,2.2,1.8,4.6,2,7.1c-1.5,2.1-1.6,5.7-5.5,5.1c-4.5-0.7-5.3-4.5-6.6-8
		c2.3,1.1,3.1,5.3,6.5,4.1C470,408.1,469.5,404.3,469.9,401.1z"/>
	<path class="st2974" d="M466.7,389.6c0.1,0.6,0.1,1.1,0.2,1.7c1.2,4,2.2,7.9,0.9,12.1l0,0c-2.7,2.4-5.1,2.2-7-1
		c-2.1-2.8-4.4-5.4-5-9c0,0,0.1-0.1,0.1-0.1c2.1-0.6,2.8,1.3,4.1,2.2c0.8,0.6,1.7,1.2,2.7,0.8c1.4-0.5,1.2-1.8,1.2-3
		c-0.1-2.7-1.9-5.4-0.1-8.2C466.8,385.3,465.8,388.1,466.7,389.6z"/>
	<path class="st3220" d="M467.8,403.4c0.6-4.1-0.9-8-1-12.1c1.3,1.8,1.8,3.9,2,6C468.4,399.3,469.6,401.6,467.8,403.4z"/>
	<path class="st3220" d="M460.8,402.4c2.1,2.1,4.5,2,7,1c-0.2,2.4-2,2.5-3.8,2.5C461.7,405.8,461.1,404.3,460.8,402.4z"/>
	<path class="st3221" d="M464,385.1c-0.3,2.5,0.4,4.8,0.9,7.2c0.4,2,1,4.4-1.2,5.5c-2.2,1.1-4,0-5.3-2.1c-0.6-0.9-1.6-1.5-2.5-2.3
		c-0.3-0.7-0.6-1.3-0.9-2c5.9-0.8,5.2-5.5,5-9.3c-0.1-1.6,0.2-3.1-0.1-4.7C463.4,378.7,464.3,381.6,464,385.1z"/>
	<path class="st3222" d="M474.8,441.3c0.4-2,1.4-3.9,0.9-6c0.2-1.1,0.1-2.3,1-3.1c3.5-1.5,6.1,0.3,8.6,2.3c0.8,2.3-0.2,4.4-0.7,6.5
		c-0.6,1.4-1.2,2.9-2.9,3.3c-0.3,0-0.6,0-0.9-0.1c-2-1-4.4-1.1-5.8-3L474.8,441.3z"/>
	<path class="st3223" d="M488.8,446.4c0,0.6,0,1.3-0.1,1.9c-1,1.8-1.9,3.7-3.1,5.3c-1.1,1.5-1.9,4.1-4.7,2.7
		c-1.9-2.1-2.7-4.4-1.1-7.1c0.4-0.6,0.8-1.2,1.2-1.8c0.5-0.6,1.2-0.8,2-0.7c0.7,0.2,1.2,0.7,1.3,1.5c0,1.1-1.4,1.8-1,3.1
		c1.8-0.9,2.1-2.9,3.2-4.2C487,446.5,487.7,445.9,488.8,446.4z"/>
	<path class="st3224" d="M474.9,441.3c2.4,0.1,4.4,1,5.8,3c-0.3,0.3-0.7,0.7-1,1c-1.4,1.4-3.3,4.2-4,3.9c-2.9-1.1-0.3-4-1-6
		C474.8,442.6,474.9,442,474.9,441.3z"/>
	<path class="st3225" d="M479.7,449.2c-0.1,2.4,0.4,4.8,1.1,7.1c-0.4,0.3-0.8,0.6-1.2,1c0.2-1.5-0.5-2.4-1.8-2.9
		C477.4,452.3,477.6,450.4,479.7,449.2z"/>
	<path class="st3226" d="M482.8,447.3c-0.6,0-1.3,0.1-1.9,0.1c0.3-1,0.6-2,0.9-3c0.6-1,1.3-2.1,1.9-3.1c1.5,1.3,0.9,2.8,0.5,4.3
		C483.8,446.2,483.3,446.8,482.8,447.3z"/>
	<path class="st3227" d="M486.8,380.4c-0.3,0-0.7,0-1,0c-2.8-2.9-5.6-5.7-6.3-9.9c0.5-1,1.2-1.5,2.3-1.5c4.6,2.4,7.6,7.6,13.3,8.4
		c0.5,0.2,0.9,0.4,1.3,0.7c2,1.8,2.5,4.3,3.4,6.5c2,6,3.9,12,5.2,18.2c0.1,0.8,0.1,1.5,0.1,2.3c0,1-0.1,2.1-0.4,3
		c-0.2,0.5-0.5,0.9-0.9,1.2c-1.4-1.1-0.8-2.6-1-4c-3.1-3.7-2.5-8.8-4.6-12.9c-0.7-1.4-0.1-3.2-2.9-4.3
		C492,387,489.2,383.6,486.8,380.4z"/>
	<path class="st3228" d="M486.8,380.4c2.6,3.2,7.3,3.6,9.3,7.7c0.5,0.9-0.1-1.7,1.2-1.6c2.7,5.9,4.2,12.4,5.4,18.9
		c-2.9,0.6-3.3-0.7-4.3-3.5c-2.1-6.1-5.1-12.1-9-17.4C488.5,383.2,487.7,381.8,486.8,380.4z"/>
	<path class="st3229" d="M512.8,442.3c0,3.9-1.6,7.4-3,11c-1.3,2-3,3.8-5.6,2.4c-2.5-1.4-2.9-3.8-2-6.4c1.3-3.9,0.6-8.1,1.6-12
		c-0.2-2.7,1.3-5.3,0-8c1.7-3.9-1.5-8.2,1-12c2.7,1.2,2.2,3.8,2.4,6c0,2.7,0,5.4,0,8.1c0.7,5.7-2.1,11.6-0.1,17.7
		c2.9-1.9,2.5-5.4,5-6.9C512.3,442.2,512.5,442.3,512.8,442.3z"/>
	<path class="st3230" d="M505.8,423.4c0-2,0.2-4.1-1-6c-0.3-2.7-0.7-5.3-1-8c0-0.3,0.1-0.7,0.1-1c2.5,0.2,2,2.4,2.7,3.8
		c0.3,0.9,0.4,1.9,0.5,2.8c0.1,0.8,0.2,1.6,0.5,2.4c0.5,1.4,0.3,3.4,1.9,3.7c2.3,0.4,1.7-2.3,2.8-3.4c0.3-0.4,0.8-0.6,1.3-0.6
		c0.5,0.3,0.8,0.7,1,1.2c1.3,4-0.3,7.8-0.8,11.7c-0.2,1.6-0.9,3.6-2.9,3.4c-2-0.2-3.7-1.4-4-3.7
		C506.6,427.5,506.6,425.4,505.8,423.4z"/>
	<path class="st3231" d="M479.9,370.4c2,3.3,3.9,6.7,5.9,10c0,1,0,2-0.1,3.1c-1.9-1.5-1.8-4.5-4-5.8c-1.8,0.1-2.1-1.4-2.5-2.5
		c-3.3-7.7-8.1-14.6-12.9-21.5c-0.2-0.8-0.2-1.5,0.2-2.2c1-0.7,1.9-0.1,2.8,0.4C474.4,357.2,479.2,362.6,479.9,370.4z"/>
	<path class="st3232" d="M466.9,353.3c6.7,7,10.9,15.7,14.9,24.3c-1.3,1.3-2.6,2.5-3.9,3.8c-3.6-0.8-2.7-5.4-5.8-6.7
		c-1.7-1.3-2.6-3.1-2.9-5.2c0.1-0.7,0.4-1.3,0.8-1.8c1.5-1.3,1.7-2.8,0.5-4.5c-0.2-0.4-0.4-0.8-0.6-1.2c-0.7-2.6-2.8-4.5-3.7-6.9
		C466.2,354.4,466.3,353.8,466.9,353.3z"/>
	<path class="st3233" d="M472.7,374.7c1.6-1.5,1.8,0.3,2.3,1.1c1,1.9,1.9,3.8,2.9,5.7c-0.1,1.1,0.1,2.2-0.8,3c-0.8,0-1.4-0.4-1.9-1
		C474,380.6,471.8,378.1,472.7,374.7z"/>
	<path class="st3234" d="M482.8,393.4c-0.3,0-0.6,0-0.9,0.1c-0.4,0.6-0.7,1.2-1.5,1.3c-0.5-0.1-0.8-0.3-1.1-0.7
		c-0.4-0.9-0.3-1.8,0.5-2.6c2-0.2,4-6.3,6-0.1C485.2,392.6,483.6,392.5,482.8,393.4z"/>
	<path class="st3235" d="M479.7,391.5c0,0.9,0.1,1.8,0.1,2.8c0.5,0.6,0.6,1.3,0.2,2c-2.4,0.7-2.5-1.7-3.7-2.7
		c-0.8-1.8-0.4-3.4,0.9-4.9c0.2-0.1,0.4-0.3,0.7-0.4c0.3,0.1,0.5,0.1,0.8,0.2c0.8,0.4,0.7,1.1,0.5,1.8
		C479.3,390.7,479.5,391.1,479.7,391.5z"/>
	<path class="st3236" d="M482.8,393.4c0.6-1.3,1.6-2,3-2c1.3,0.6,2,1.5,2,3C486.2,393.4,484.5,393.3,482.8,393.4z"/>
	<path class="st3237" d="M479.1,390.3c-0.2-0.6-0.3-1.2-0.5-1.8c0.2-1.5,1.3-2.1,2.1-0.9C481.8,389,479.9,389.5,479.1,390.3z"/>
	<path class="st3235" d="M475.9,383.2c0.4,0.4,0.7,0.8,1.1,1.3c0.3,1.3,0.6,2.6,0.9,3.9c0,0-0.2,0.2-0.2,0.2c-2.7-0.1-2.9-1.9-2.7-4
		C475.1,383.9,475.4,383.5,475.9,383.2z"/>
	<path class="st3236" d="M488.7,397.4c-2.5-0.3-1.5-1.7-1-3c0.3,0,0.6,0,0.9,0C488.7,395.4,488.7,396.4,488.7,397.4z"/>
	<path class="st3238" d="M504.8,462.3c-1.2,4.8-6,5.1-9.2,7.4c-1.1,0.8-2.1-0.4-1.7-1.4c2.4-6.5,3.6-13.4,7-19.5
		c0.1-0.2,0.5-0.3,0.8-0.5C502.1,453.1,503.3,457.8,504.8,462.3z"/>
	<path class="st3239" d="M504.8,462.3c-4.6-3.9-2.9-9.1-3-14c0-3.8,0.7-7.4,2-11c-0.3,4.4,0.6,8.8-0.8,13.2
		c-0.6,1.7,0.1,3.4,1.6,4.4c2.1,1.4,3.6-0.7,5.3-1.5c-0.3,1.7-0.7,3.3-1,5C507.4,459.6,506.1,461,504.8,462.3z"/>
	<path class="st2987" d="M513.9,460.5c-0.3-1.5,0.3-3.2-1.2-4.3c0.4-2,0.7-3.9,1.1-5.9c0.7-3,1.4-6,2.1-8.9c1.1-0.8-0.3-2.8,1.5-3.2
		c1.5,0.7,1.6,2.1,1.7,3.5c-0.5,5.4-1.4,10.8-2.9,16C515.7,458.9,515.5,460.2,513.9,460.5z"/>
	<path class="st3229" d="M515.7,435.4c-2.3,1.8,0.1,5.5-2.9,7c0,0,0,0,0,0c-1.2-1.1-1.3-2.6-1.3-4.1c1-4.9,2.7-9.7,2.9-14.7
		c0-1,0.6-1.4,1.6-1.1c2,3,0.9,6.4,1.1,9.7C517,433.3,516.8,434.5,515.7,435.4z"/>
	<path class="st3240" d="M515.7,435.4c0-1,0.1-2,0.1-3c0.9-2,0.2-4.1,0.5-6.2c0.1-1-0.1-2.6,1.8-1.7c2.1,4.8,0.8,9.4-1.2,13.9
		c-0.2,1,0,2.2-1.2,2.9C515.7,439.4,515.7,437.4,515.7,435.4z"/>
	<path class="st3241" d="M484.5,434.4c-2.6-0.7-5.2-1.5-7.8-2.2c0.1-3,0.1-5.9,0.2-8.9c0.4-3.2,0.8-6,2.7-9.1
		c1.6-2.6,1.6-6.8,3.2-10c3.1,0.9,0.6,3.8,1.9,5.4c0.1,3,0.1,5.9,1.3,8.7c1.2,1.1,1.2,2.6,1.3,4c-0.1,2.6,0.1,5.1-0.4,7.7
		C486.2,431.6,486.2,433.4,484.5,434.4z"/>
	<path class="st3242" d="M485.9,422.4c0-1.4,0-2.7,0-4.1c2.4-0.6,4.7-0.7,6.7,1c-0.1,2,0.9,4.2-0.9,6c-1.3-0.4-2.6-0.7-4-0.5
		C486.2,424.7,486.5,423.3,485.9,422.4z"/>
	<path class="st3236" d="M484.6,409.6c-1.6-1.4-0.2-3.8-1.8-5.2c-3.5-2.7-2.7-6.3-2.1-9.8c0.4-0.4,0.8-0.7,1.2-1.1
		C485.5,398.3,484.8,404,484.6,409.6z"/>
	<path class="st3225" d="M482.8,459.3c-0.9,1.6-2,2.9-4,3C479.7,460.7,480.8,459.4,482.8,459.3z"/>
	<path class="st3243" d="M488.8,446.4c-0.7,0.3-1.3,0.6-2,1c-0.5,0.4-1,0.4-1.5,0.1c-0.9-0.7-1.4-1.7-1.2-2.9
		c1.3-5.3,0.3-11.1,4-15.8c4.3-0.9,4.6,1.3,3.7,4.6C491.2,437.8,490.8,442.3,488.8,446.4z"/>
	<path class="st3244" d="M491.8,433.3c0.3-3-1-4.4-4.1-4c-0.5,0.4-0.9,0.3-1.3-0.2c-0.1-1.8-0.7-3.7,1.3-4.8c1.7-1.3,3.3-1.8,4,1
		C491.4,428,494.2,430.6,491.8,433.3z"/>
	<path class="st2798" d="M763.5,304.5c-0.7-1.3-1.3-2.7-2-4c0-0.8,0-1.6,0-2.5c0.1-0.9,0-2.2,1.4-2c1.1,0.1,1.7,1.2,1.4,2.2
		C763.6,300.3,763.3,302.4,763.5,304.5z"/>
	<path class="st2798" d="M766.8,291.4c0.6,2.2,1.3,4.4-0.9,6.9C765.3,295.5,765,293.4,766.8,291.4z"/>
	<path class="st3245" d="M744.4,275.9c1.6,2,2.9,4.2,5.1,5.7c0.3,0.7,0.6,1.3,0.9,2c-1.7-0.3-3.4-1.3-5.1,0.1c-1.9-2-3.9-4-5.8-6.1
		c0.4-1.9-1.6-3.2-1.1-5.1c0.4-0.3,0.8-0.4,1.3-0.4C741.7,272.7,743,274.3,744.4,275.9z"/>
	<path class="st3246" d="M734.5,307.5c12.4,13.1,16.3,28,10.5,45.6c-1.5,4.4-3.5,8.3-5.5,12.3c-1.2-1.1-1.1-2.4-0.8-3.8
		c4-9.6,4.9-19.6,4.5-29.8c-0.2-5.2-3-9.4-5.1-13.9c-0.6-1.5-1.4-3-2.8-3.9c-0.4-0.3-0.7-0.7-1-1.1c-2.4-3.7-6-5-10.2-5.1
		c-4.9-0.2-9.6,0.8-14.4,1.4c-3.4,0.4-5.1,3.9-7.8,4.9c-6.5,2.5-8.6,8.1-10.4,13.6c-1.8,5.4-3.7,10.7-5.2,16.1
		c-0.4,1.5-0.2,3.9-3,3.5c-2.1-3.7,0.5-7.7-0.8-11.4c1.1-4.3-0.1-8.5-0.2-12.7c0-2.8-1.5-3.4-3.9-2.6c-2.1,0.6-3.9,1.7-5.6,3.1
		c-2.8,2.3-5.9,2.6-9.3,1.4c-5.1-1.8-5.1-1.8-7,3.5c-0.2,0.6-0.6,1.2-1.2,1.5c-6.2,0.9-12.4,2.6-17.9-2.3c-1.2-1.1-3-0.9-4.1,0.4
		c-2.1,2.5-4.8,2.7-7.7,2.7c-1.7,0-3.2,0.4-4.6,1.4c-4.1,3-5.1,2.7-7.8-2.1c1.3-4,2.9-8,6.4-10.6c2.6-1.9,2.6-6.4,6.7-6.6
		c3.4,2.2,7.2,3.3,11,4.2c3.9,0.9,8,2.3,9.8-3.3c0.4-1.2,1.7-1.7,2.8-1.7c5.1-0.1,10.2-1.1,15.1,1.4c4,2.1,12-1.7,13.4-6.1
		c0.5-1.8,1.5-2.9,3.3-3.3c4.3-0.8,7.2-4.7,11.6-5.1c0.7-0.1,1.1-1.1,1.7-1.7c3.2-1.8,5.6-4.7,8.5-6.6c5-3.3,9-8,14.3-10.9
		c1.3-0.7,2.6-0.5,3.7,0.5c-3.2,3.9-0.7,6.5,2,9c-2.5,2-3.2,3.7-0.3,6.2c3.1,2.6,4.7,6.6,7.3,9.8v0
		C731.8,306.3,733.4,306.4,734.5,307.5z"/>
	<path class="st3247" d="M721.5,280.5c-1.3,0.1-2.7-0.3-3.8,0.8c-4.6,5.2-11.3,8-15.8,13.3c-1.7,2-3.6,3.4-6.4,2.9
		c-0.7-4.1-4-6.5-6.3-9.7c2.2-3.2,6-3.8,9-5.8c4-2.6,7.4-6.1,12.5-6.9c-0.9-1-2.1-0.8-2.8-1.5c-2.8-3.1-6.3-4.7-10.5-4.9
		c-1.5-0.1-3.4,0.2-4.1-1.9c1.3-2.1,3.4-1.9,5.3-1.7c5,0.4,10,0.8,15,1.5c1.8,4,5.2,4.6,9.1,4c1.3,0.4,2.9,0.5,3.8,1.8
		c-1,1.4-3.5,0.9-4,3c-0.9,1.2-1.2,2.5-1,4C721.5,279.9,721.5,280.2,721.5,280.5z"/>
	<path class="st3248" d="M730.5,305.5c-1.4,0-2.1-0.4-3.1-1.7c-1.9-2.6-2.6-6.2-5.9-7.7c-2.1-1-2.2-2.8-1.9-4.6
		c0.3-2.5,2.4-1.7,3.9-1.9c4.2-1.7,9.3-1.1,13.1-4.6c1.4,1.3,0.6,3.1,0.9,4.6c-0.8,1.6-4.1-0.5-3.9,2.5c0.1,2.1-0.9,4.2,2,5.9
		c3,1.7-0.4,3.1-1.5,3.4C731.6,301.8,730.3,302.8,730.5,305.5z"/>
	<path class="st3249" d="M726.5,272.6c-1.4-0.2-3,0.4-4-1c-2.7-0.8-5.7-0.9-8-3c-7-0.9-13.9-3.3-21-1c-1.7,1.6-3.6,1.4-5.6,0.6
		c-0.5-0.3-0.8-0.8-0.7-1.4c0.8-1.3,2.2-1.7,3.6-2.1c1.1-0.3,2.2-0.4,3.4-0.4c5.2-0.1,10.4,0,15.6,0c1.9,0,3.8-0.1,5.6,0.4
		c0.5,0.2,0.9,0.4,1.2,0.8c3.7,3.2,8.4,3.7,12.8,5.2c0.5,0.2,0.9,0.4,1.3,0.7c0.8,1,1.4,2,0.7,3.3
		C729.8,273.9,728.1,273.2,726.5,272.6z"/>
	<path class="st3250" d="M721.5,279.5c-1.1-0.5-2.1-1.3-1.8-2.7c0.3-1.6,1.7-1.2,2.8-1.3c0.8,0,1.6,0,2.4,0c1.5,0,2.6,0.5,2.4,2.2
		c-0.1,1.5-1.3,1.7-2.5,1.7C723.8,279.5,722.6,279.5,721.5,279.5z"/>
	<path class="st3251" d="M731.4,274.6c-0.3-0.9-0.7-1.8-1-2.8c0.3-0.4,0.7-0.6,1.2-0.6c1.9,0.9,3.9,1.7,4.4,4.1
		c-0.5,0.1-1.1,0.2-1.6,0.3C733.3,275.5,732.1,275.7,731.4,274.6z"/>
	<path class="st3252" d="M736,275.3c-1.6-1.2-3.1-2.5-4.7-3.7c0.9-1.1,2.4-0.7,3.5-1.5c1.5,0.4,2.7,1.3,3.8,2.4
		c-0.3,1.8,5.1,2.5,1,5C737.8,277.6,737,276.3,736,275.3z"/>
	<path class="st3050" d="M488.7,162.7c-0.8,1.5-1.2,3.3-3,4C485.7,164.5,486.2,162.8,488.7,162.7z"/>
	<path class="st3253" d="M791.4,656.1c-2.3,4.5-7.8,4.6-11,8c-11.4,2.7-12.1-4.1-11.7-13.5c0.1-1.8,1.1-3.5,0.8-5.4
		c-0.1-1-0.1-2,0.4-3c2.8-3.3,6.9-2.6,10.5-3.2c1.7,4.1,0.7,8.7,2.7,13C785.4,657.2,786,657.9,791.4,656.1z"/>
	<path class="st3254" d="M780.4,639.1c-2.6,3.3-7,0.8-10,3c-1.7,1.3-3.4,3.4-5.6,2.1c-2.4-1.4-1.7-4-1.2-6.3
		c3.8-2.8,0.9-5.4-0.3-8.1c2-2.1,5-2.9,7.1-4.9c1.1-1,2.7-0.9,4,0.2c3,0,6,0,9,0c1.8,0,1.5,0.9,1,2c-0.9,0.7-1.2,1.7-1,2.7
		C784.2,633.5,782.5,636.3,780.4,639.1z"/>
	<path class="st3255" d="M770.4,645.1c1.1,0.8,1.5,2.3,0.7,3.1c-3.5,3.6-0.3,7.6-0.8,11.4c-0.3,2,2.6,4.4,5.6,4.5c1.5,0,3,0,4.5,0
		c-6.7,0.9-11.5,5.7-17,9c-0.9,1.3-2.3,1.9-3.8,2.1c-5.4,1.3-8.6-2.3-11.8-5.6c-2.1-2.2-0.3-4.2,0.9-6.1c-2.5-0.7-3.5-2.3-2.4-4.8
		c1-2.1,3.1-2.8,5.3-3.2c3,0,4.8-1.4,6.9-3.7C761.6,648.5,765.2,645,770.4,645.1z"/>
	<path class="st3256" d="M759.5,674.1c1.3-0.3,2.7-0.7,4-1c0.4,0.8,1.2,1,2,1c-0.4,3.5-3.6,3.6-5.8,4.9c-15,8.8-31.2,15.3-45.8,24.6
		c-1.5,1-3.1,2-5.1,1.8c-1.4-0.7-2.4-1.6-2.5-3.3c7.4-2.5,13.1-7.6,19.4-12c3.9-1.3,7.6-3.1,10.8-5.8c1.5-1.3,3.3-2.2,5.3-2.6
		c4.4-0.8,8.7-1.7,11.4-5.9C754.5,673.6,757.5,675.3,759.5,674.1z"/>
	<path class="st3257" d="M759.4,678c1.9-1.5,4.4-2,6-4c6.4-2.7,12.3-6.8,19-9c0.7,0,1.4,0,2,0c-1.7,1.3-3.3,2.7-5,4
		c-6.2,4.9-13.8,7.2-20.3,11.4c-0.6,0.4-1.3,0-1.9-0.4C758.9,679.4,758.9,678.7,759.4,678z"/>
	<path class="st3258" d="M759.5,680.1c7.2-3.9,14.2-8.3,22-11c2.3-0.4,3.2,1.2,4,3c-3,1.7-6,3.4-9,5c-5.5,0.1-8.2,5.4-13,7
		c-6.4,4-12.6,8.2-19.7,10.9c-2.6,0.3-4.8,1.9-7.2,2.8c-2.9,1.1-5.6,1.3-8.2-0.7c-0.2-2,1.5-2.5,2.7-3.1c8.5-4.5,17.2-8.6,25.5-13.5
		C757.4,679.9,758.4,679.9,759.5,680.1z"/>
	<path class="st2887" d="M727.5,160.7c-2.1-6.2-0.4-12.7-1-19C728.7,147.9,727,154.3,727.5,160.7z"/>
	<path class="st2887" d="M727.5,175.6c0-3.3,0-6.7,0-10C729,169,729.1,172.3,727.5,175.6z"/>
	<path class="st3259" d="M474.8,553.3c-0.4-1.2-0.8-2.4-1.2-3.6c4.4-1.6,9.3,1.1,13.1-0.7c9.9-4.6,21.2-1.2,30.9-6.1
		c2.7-1.4,5.4,1.1,8.2,0.3c-2.4,4.5-7.2,5.1-11.2,5.9c-8.9,1.9-18,2.6-26.8,5.1c-3,0.9-5.9,1.9-8.9,3
		C477.1,556.2,475.8,554.9,474.8,553.3z"/>
	<path class="st3260" d="M485.9,565c-1-1.2-2.1-2.5-3.1-3.7c-0.4-0.5-0.4-0.9,0-1.4c6-3.9,12.4-6.8,19.7-7.8
		c6-0.9,11.8-3.1,17.6-4.9c3.5-1.1,7-2.1,10.7-2c0.5,0,1,0.1,1.5,0.2c0.7,0.2,1.3,0.6,1.9,1.1c0.8,0.8,2.1,1.3,1.6,2.8
		c-0.3,0.3-0.7,0.6-1.1,0.7c-1.9-0.5-3.5,1.3-5.4,0.9c-2-0.5-4-0.2-5.9,0.6c-7.9,3.4-16.2,5.6-24.5,8.1c-0.8,0.2-1.5,0.7-2,1.4
		c-0.3,0.3-0.7,0.6-1.1,0.9c-2.1,1.5-5.7-0.9-7,2.7c-0.1,0.7-0.3,1.3-0.8,1.9C486.7,566.7,486.3,565.8,485.9,565z"/>
	<path class="st3261" d="M531.7,546.2c-8.4,1-16.8,2.1-24.3,6.4c-1.5,0.8-3.5,0.9-5.3,0.9c-7.3-0.1-12.6,5.2-19.3,6.7
		c-1.1-0.5-2-1.5-3.1-2c-0.3-0.3-0.6-0.7-0.9-1c0.6-2.5,2.9-3.5,4.7-3.8c9.6-1.5,18.9-4.5,28.6-5.2c5.1-0.4,9.2-3,13.7-5
		c0.7,0,1.4,0,2,0C530,543,531.6,543.7,531.7,546.2z"/>
	<path class="st3262" d="M485.9,565c0.6,0.4,1.3,0.7,1.9,1.1c2.1,0.6,3.1,2.4,4.7,3.6c1.9,1.9,3.8,3.8,5.7,5.7
		c0.5,0.5,1,0.9,1.4,1.4c1.9,2,3.9,3.8,5.7,5.8c1.5,2.2,3.2,4.2,5.1,6.1c1.6,2.3,3.4,4.5,5.1,6.8c1.4,1.7,2.6,3.6,4,5.2
		c1,1.3,1.9,2.7,1.9,4.4c-0.1,1-0.6,1.8-1.6,2.2c-3.5-0.5-4.3-4-6.5-5.9c-0.8-1.8-3.1-2.9-2.5-5.3c-1.1-2.9-2.8-5.4-4.9-7.7
		c-6.2-6.8-12-14-18.7-20.4C486.3,567.2,485.7,566.2,485.9,565z"/>
	<path class="st3263" d="M480.8,568.2c1.3,0.3,2.6,0.7,4,1c3.1,2.6,6.1,5.3,8.2,8.9c-0.3,0.3-0.7,0.5-1.2,0.5
		c-1.9-0.2-3.4-1-4.6-2.5c-0.9-1.5-2.4-2.3-3.7-3.4c-1.1-1-2.2-2-2.9-3.4C480.4,568.9,480.5,568.5,480.8,568.2z"/>
	<path class="st3264" d="M491.8,578.2c0.4,0,0.8-0.1,1.2-0.1c4.8,4.6,9.4,9.3,13.1,15c-0.3,0.4-0.6,0.6-1.1,0.7
		c-0.5,0-0.9-0.1-1.3-0.3c-1.5-1.6-2.8-3.3-4.4-4.8c-1.2-1.2-2.1-2.7-3.2-4.1c-1.3-1.8-3.3-2.8-4.6-4.6
		C491.1,579.3,491.2,578.7,491.8,578.2z"/>
	<path class="st3265" d="M504.7,593.2c0.4-0.1,0.8-0.1,1.3-0.2c0.6,0.4,1.1,0.8,1.7,1.2c1.3,2.2,3.8,3.4,4.6,5.9
		c-0.1,0.5-0.3,0.9-0.6,1.2c-3.6-0.7-5.7-3.4-7.6-6.1C504,594.5,504.2,593.8,504.7,593.2z"/>
	<path class="st3266" d="M511.7,600.2c-0.9-2.3-3.6-3.4-4-6.1c1,0.7,2.1,1.3,3.1,2c0.8,1.8,3,2.7,3.1,4.9c-0.2,0.1-0.4,0.2-0.6,0.3
		C512.5,601.3,512.2,600.7,511.7,600.2z"/>
	<path class="st3259" d="M479.7,558.2c1.5-0.1,2.5,0.6,3.1,2c0,0.4-0.1,0.7-0.1,1.1C481.7,560.3,480.7,559.3,479.7,558.2z"/>
	<path class="st3267" d="M601.6,805.9c-1.9-0.2-2.7-1.9-4-3c0.7-1.9,2.2-3.1,3.6-4.4c2.7-4.4,6.9-5.3,11.6-5.1
		c5.1-1.8,7.1-0.8,10.6,5.5c-0.4,2.6,2.2,6.3-2.4,7.6c-0.8-0.2-1.5-0.7-2-1.4C613.3,798.1,606.7,798.4,601.6,805.9z"/>
	<path class="st3268" d="M591.7,803.9c0.1,2-0.7,3.2-2.8,3c-5-0.6-7.8,1.8-10,6.1c-1.7,3.2-4.9,5.1-8.7,3.5
		c-3.3-1.4-5.3,2.6-8.4,1.4c-0.4-0.7-0.1-1.4,0.3-2c0.3-0.4,0.7-0.6,1.1-0.9c4.4-1.9,8.2-4.9,12.6-7c5.2-1.7,9.3-5.7,14.9-6.5
		C591.5,802.1,592.1,802.7,591.7,803.9z"/>
	<path class="st3269" d="M601.6,805.9c-0.4-3.3,0.2-5.3,4.3-5.2c2.7,0.1,4.2-4,8-2.3c3.6,1.6,5.2,4.3,6.7,7.5
		c-0.5,0.6-1.1,0.8-1.8,0.8c-3.5-1.4-6.1,1.5-9.1,2c0-0.6,0-1.2,0-1.9c0.7-2.3,3.8-1.9,4.7-4.4c-3.1-0.9-5.4,0.1-7.3,2.4
		C605.6,806.6,603.9,808,601.6,805.9z"/>
	<path class="st3026" d="M572.6,826.9c-0.7,0-1.3,0-2,0c4.7-4.4,10.5-3.1,16-2.6c2.6,0.2,3.6,3.1,4,5.7c-0.5,0-0.9,0-1.4,0.1
		c-2.7,0-3.9-3-6.5-3.2C579.4,825,576,828.7,572.6,826.9z"/>
	<path class="st3270" d="M562.7,815.9c-0.3,0.7-0.7,1.3-1,2c-0.8,0-1.6,0.2-2,1c-1.4,2.2-4.2,2.3-6,4l0,0c-1.6,0.9-2.9,2.3-4.9,2.1
		c-1,0.1-2,1.6-3,0c3.3-2,6.6-4,9.9-6C557.4,816.4,559.4,814.8,562.7,815.9z"/>
	<path class="st3269" d="M591.7,803.9c-0.3-0.7-0.7-1.3-1-2c0.7-2.6,2.8-3,5-3.3c2,0.5,4.7-2.3,6,1.3c-1.8,0.4-1.8,3.1-4,3
		C595.6,802.9,593.5,802.7,591.7,803.9z"/>
	<path class="st3026" d="M564.7,831.9c-0.9,1.7-2.3,3-4,4c0-1.3-0.2-2.3-1.7-3.1c-3.6-1.9-3.1-3.7,0.7-4.9
		C560.8,829.8,560.5,833.6,564.7,831.9z"/>
	<path class="st3271" d="M553.7,822.9c1.3-2.4,4-2.7,6-4c0.6,0.2,2.1,0.2,0.6,1.1c-1.7,1.1-3.7,1.9-5.6,2.9
		C554.4,822.9,554,822.9,553.7,822.9z"/>
	<path class="st3272" d="M553.6,855.7c-0.3-2.5,0.6-4.7,1.9-6.9c1.5-1.4,2.6-1.5,3,0.9c0,1.5-0.6,2.9-0.9,4.3
		c-0.9,3.1-0.7,6.3-0.4,9.5c0.2,0.4,0.4,0.8,0.6,1.2c1,1.7,1.5,3.6,3.4,4.6c2.9,0.6,5.7,0.2,8.6,0.3c1.5,0.6,4.7-1.7,4,2.5
		c-2.9,2.4-6.1,4.1-10.1,3c-2,0.9-4.4-0.8-6.3,0.8c-1.6,0-2.4,1.4-3.6,2.1c-1.2-0.8-1.5-2.2-0.8-3c2.4-3.2,1.1-6.5,0.7-9.8
		C553,861.9,553,858.8,553.6,855.7z"/>
	<path class="st3273" d="M573.7,872c-0.6-3.2-3-0.2-4.3-1.2c-0.2-0.4-0.2-0.9,0-1.3c1.8-2.7,4.3-4.6,6.5-7c1.1-1.1,2.2-2.2,3.4-3.1
		c1.6-0.8,2.4-2.6,4.2-3c1,0,1.7,0.6,2,1.5c0.1,1,0.1,2,0.2,3c0.5,1.6,0.8,3.1-1.1,4C581,867.2,577.4,869.6,573.7,872z"/>
	<path class="st3274" d="M585.6,857.8c-0.7-0.4-1.5-0.7-2.2-1.1c-0.7-1.1-0.5-2.3-0.1-3.3c2.6-5.8,3.3-12,3.9-18.2
		c3.8,1,0.2-3.3,2.4-3.3c2.5,5.3,1,10.7,0.1,16C588.4,851.2,587,854.5,585.6,857.8z"/>
	<path class="st3274" d="M584.7,864.8c1.3-1.1-0.1-2.9,1.1-4C588.8,863.1,587.4,864.1,584.7,864.8z"/>
	<path class="st3275" d="M557.3,875.8c1.7-3.2,4.2-0.9,6.3-0.8C561.7,876.5,559.4,875.6,557.3,875.8z"/>
	<path class="st3276" d="M589.7,831.8c-0.2,1.4,0.4,2.8-0.4,4.1c-0.5-0.4-1-0.7-1.5-1.1c-2.6-1.3-4.8-3.2-6.3-5.8
		c0.6-1.2,1.6-2.1,2.3-3.2c2.7,0.2,3.8,2.6,5.6,4C589.4,830.6,589.5,831.2,589.7,831.8z"/>
	<path class="st3277" d="M609.6,808.8c2.4-2.8,5.1-4.8,9.1-2.9c0.8,0.8,2.3,0.5,2.7,1.8c0.4,0.9,0.8,1.7,1,2.7
		c0.4,1.8-0.1,3.5-0.9,5.1c-1.7,3.8-4.2,6.4-8.8,5.5c-0.8-0.1-1.6-0.3-2-1.2c0.3-0.7,0.7-1.2,1.3-1.5c2-0.8,3.9-1.8,4.9-3.9
		c0.7-1.5,1.8-3.1,0-4.4c-1.6-1.1-3.1-0.4-4.3,1c-0.5,0.6-0.9,1.4-1.2,2.1c-0.8,1-0.7,2.9-2.7,2.8
		C608.5,813.4,608.4,811,609.6,808.8z"/>
	<path class="st3278" d="M618.6,828.9c-2.6-2.7,0.2-5.4,0-8c-0.3-0.9,0.3-1.4,0.9-1.8c1.9-0.8,3.7-1.9,5.9-1.3
		c2.2,3.1,3.5,6.2,0.5,9.5C623.6,828.1,621.6,830.9,618.6,828.9z"/>
	<path class="st3279" d="M618.6,828.9c2.7,0.6,4.3-2.5,7-2c0.1,0.2,0.2,0.4,0.2,0.6c-0.4,1.5-2,2.3-2,4c-0.1,1.1-0.2,2.3-0.8,3.3
		c-0.3,0.3-0.7,0.6-1.2,0.7c-0.5,0-0.9-0.1-1.3-0.3c-1.6-1.1-2.4-3.8-5.1-2.5c-1.4,0.8-1.6,2.2-2.1,3.5c-0.4,1-0.7,2.1-1.7,2.7
		C608.2,833.3,610.3,830.3,618.6,828.9z"/>
	<path class="st3280" d="M620.6,834.8c0.4,0,0.7,0.1,1.1,0.1c0.5,0.6,0.8,1.3,0.8,2c0,0.8-0.4,1.4-0.9,2c-2.5,1.4-5.1,2.7-8,2.9
		c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.4-0.3-0.8-0.2-1.2c0.3-0.3,0.6-0.6,0.9-0.9c1-0.7,2.1-1.4,3.1-2.1
		C618.4,836.1,619.3,835,620.6,834.8z"/>
	<path class="st3281" d="M619.6,819.9c-0.3,0.3-0.7,0.6-1,1c-2,0-3.9,2.2-5.9,0.2c4.2,0,6.3-2.8,8-6.1
		C624.2,816.8,624.2,816.9,619.6,819.9z"/>
	<path class="st3007" d="M600.6,816.9c1.6,0.1,3.2,0.1,4.8,0.2c0.2,0,0.4,0.4,0.8,0.8C603.9,820,602.1,819.7,600.6,816.9
		L600.6,816.9z"/>
	<path class="st3282" d="M611.8,818.6c-0.4,0.4-0.7,0.8-1.1,1.2c-0.8-1.2-2.1-2.2-2-3.9c1-0.6,0.4-2.3,1.7-2.7
		C612.1,814.7,612.6,816.5,611.8,818.6z"/>
	<path class="st3283" d="M613.7,839.6c-0.1,0.5-0.1,0.9-0.2,1.4c-0.7-0.7-1.3-1.4-2-2.1c0.2-1,0.5-2,0.7-3
		C615.2,836.2,614.7,837.8,613.7,839.6z"/>
	<path class="st3284" d="M655.8,822.9c3.2-1.5,6-4,9.8-4c-3.4,3-6.7,6.1-10.1,9c-1.1,0.9-2.8,1.1-2.9,3c-4.4-1.5-6.3,3.6-10,4
		c-0.6-0.5-1.6-0.8-1-1.9c1.1-0.6,1-1.6,1-2.7c0.7-2.2,2.6-2.8,4.5-3.5C650.6,826.7,652.2,822.7,655.8,822.9z"/>
	<path class="st3285" d="M621.6,838.9c0-0.7,0-1.4,0.1-2.1c1.5-1.2,3.4-1.9,5.1-2.8c1.2-0.5,2.3-1.1,3.5-1.7
		c0.4-0.2,0.8-0.4,1.2-0.6c2.1-0.8,3.8-2.4,6.2-2.2c0.9,0.3,1.7,0.6,2.1,1.6C634.2,834.5,628.3,837.7,621.6,838.9z"/>
	<path class="st3286" d="M639.9,831c-0.8-0.2-1.7-0.2-2.2-1.1c-0.6-1.5,0.7-1.9,1.5-2.5c1.5-0.7,2.8-2,4.2-2.8
		c0.4-0.1,0.8-0.1,1.2,0c1,0.7,2,1.3,3,2c0.2,0.2,0.3,0.5,0.3,0.7c0,0.3,0,0.4-0.1,0.4c-1.7,0.8-3.5,1.7-5.2,2.5
		C641.5,829.6,640.7,830.6,639.9,831z"/>
	<path class="st3287" d="M633.6,838.9c-0.7,0.7-1.4,1.3-2,2c-2.7,0.4-5,2.6-7.9,2.1c3.1-2.4,6-5.1,10-6
		C634.2,837.5,634.2,838.2,633.6,838.9z"/>
	<path class="st3284" d="M633.6,838.9c0-0.7,0-1.3,0-2c1.7-0.7,3.3-1.3,5-2c1-0.6,2-0.7,3,0C639.3,836.9,636.6,838.1,633.6,838.9z"
		/>
	<path class="st3287" d="M641.6,834.9c-1,0-2,0-3,0c1-0.7,2-1.3,3-2c0.3,0.6,0.7,1.3,1,1.9C642.3,834.9,641.9,834.9,641.6,834.9z"/>
	<path class="st3068" d="M676.6,812.9c1-1.3,2.4-1.9,4-2C679.8,812.7,678.3,813,676.6,812.9z"/>
	<path class="st3288" d="M800.4,568.2c-1.3,1.8-0.5,5-4,5.4c-1.3,0.1-0.9,1.9-1,3c-0.2,1.6,0.5,3.3-1,4.6c-0.4,3.6-2.6,5.6-5.8,6.5
		c-3.2,0.9-6.3,2.3-9.7,2c-2.8-6-2.3-7.8,2.3-11.8c3.2-2.7,7-4.8,9.2-8.6c0.7-2,0.1-5.3,4-4.1C797.2,564.6,798.4,567.2,800.4,568.2z
		"/>
	<path class="st3289" d="M794.4,565.2c-3-0.2-2.4,2.3-3,4c-0.6,0.5-1.3,0.9-2.1,1.1c-4.1,0.6-6.8-2.2-10-3.9
		c-0.1-6.2,0.9-7.6,6.6-8.9c2.5-0.6,4.6-1.7,6.5-3.3c0.7-0.3,1.3-0.7,2-1c2.4,1.3-0.8,5.4,3,6C796.4,561.2,795.4,563.2,794.4,565.2z
		"/>
	<path class="st3290" d="M792.4,554.2c0.1,2.3-0.6,4-3.2,4c-5.4,0-8.9,2.4-9.7,8c-5.5,3.4-9.3,9.4-15.7,10.9c-4.3,1-6.9,3.5-9.4,6.6
		c-1.2,1.1-1.8,3.1-4,2.4c-1.2-4,2.3-6.8,2.8-10.3c0.7-2.7,1.5-5.4,4.4-6.7c4.1-4.2,8.6-7.7,14.8-8.2c0.9-0.1,2.4-0.7,2.5-1.3
		c1.9-7.1,9.7-9.3,12.7-15.5c1.3-1.2,2.7-1,4,0c2.8,0.6,2.4,2.5,1.2,4.1C791.1,550.3,791.3,552.2,792.4,554.2z"/>
	<path class="st3291" d="M791.4,544.2c-1.3,0-2.7,0-4,0c-1.5-1.1-0.9-2.6-1-4c-0.9-4.1,1.8-6.6,4.5-8.4c4.5-3,8.5-6.2,10.4-11.5
		c1.4-3.8,5.9-4.1,8.1-7c2,0.1,2,1.5,2,3c0.8,4.8-0.8,9.1-3.2,13.2c-1.1,1.9-2.5,3.3-4.9,3.9C797.3,534.6,794.1,539.1,791.4,544.2z"
		/>
	<path class="st3291" d="M813.4,514.3c1.1-1,1.8-0.6,1.9,0.7c0.2,2.1,0.1,4.2,0.1,6.3c-1.6-0.7-2.1-1.8-1.3-3.5
		C814.6,516.5,814.7,515.2,813.4,514.3z"/>
	<path class="st2817" d="M809.4,532.2c0.9-0.4,2.4-1.2,2.5-1.1c0.9,1.5,0.7,3.1,0,4.6c-0.2,0.4-1.1,1-1.5,0.9
		c-1.5-0.5-0.8-1.9-1-2.9C809.3,533.2,809.4,532.7,809.4,532.2L809.4,532.2z"/>
	<path class="st3292" d="M809.4,513.3c1.3,2,0.5,3-1.6,3.3c-3.2,0.5-5.1,2.7-5.9,5.3c-1.4,4.7-4.6,7.7-8.7,9.9
		c-3.6,1.9-5.1,5.2-6.7,8.5c-4-2.4-8.2-4.6-12.1-7.2c-2.4-1.5-3.4-3.8-0.5-5.9c1.7-1.3,1.1-2.1-0.4-2.9c0.1-1.5,0.2-2.9,0.2-4.4
		c2.5,0.1,1.6,2.3,2.6,3.3c3.2,3,6.1,2.7,7.7-1.3c0.9-2.3,1.5-3.8,4.5-3.1c1.3,0.3,3.4-0.6,4.5-1.7c3.5-3.3,7.5-4.5,12.1-3.9
		c3,0.4,1.4-4.1,4.2-4C809.4,510.6,809.4,511.9,809.4,513.3z"/>
	<path class="st3293" d="M778.4,589.2c3.6-1.1,7.2-2.3,10.8-3.2c2.7-0.7,4.1-2.5,5.1-4.8c1.1,4.6-1.1,8.7-2,13
		c-4.5,1-7.7,4.3-11.6,6.4c-3.6,1.9-2.1,5.6-2.4,8.6c-4.6,0.6-7.8,5.4-13,4.3c-0.1-2.2-0.7-3.9-3-4.9c-1-0.4-1-1.9-1-3.1
		c0.3-4.4,1.8-7.8,6.7-9.3c2.6-0.8,4.1-3.8,6.2-5.6C775.5,589.7,776.4,587.8,778.4,589.2z"/>
	<path class="st3294" d="M765.5,613.1c1.4,0.1,3,0.1,3.7-1c2.3-3.7,5.9-2.9,9.3-3c1.8,3.9,1.7,7.6-1.1,10.9c-1.3,1.6-2,3.3-2.9,5.1
		c-4.5-0.3-6.5,5.2-11,5c-2.3,1.2-4.6,1.7-6.7-0.5c-0.2-0.3-0.4-0.6-0.4-0.9c0.4-4.3-0.2-8-5-9.7c-0.7-0.3-0.5-1.4-0.1-2.2
		C755.2,612.4,760.4,613.2,765.5,613.1z"/>
	<path class="st3026" d="M560.7,835.9c-0.7,3.3-2.5,5.8-5,8C554.1,839.2,558.5,838.2,560.7,835.9z"/>
	<path class="st3271" d="M548.7,825c1.6-0.8,3-2,4.9-2.1C552.4,824.5,551.3,826.5,548.7,825z"/>
	<path class="st3295" d="M583.7,826c1.5,2.5,0.1,3-2.1,3c-2.3,2-5.5,1.7-8.1,3c-2.4,0.7-3.9,2.8-6.2,3.5c-1-0.1-2.1-0.2-1.7-1.7
		c1.5-3.1,5-4.3,7-6.9C576.3,826.3,579.8,823.6,583.7,826z"/>
	<path class="st3296" d="M557.7,850c0-1.9-1.1-1.6-2.2-1.2c0.8-2.7,2.5-4.8,4.1-7c1.5-0.4,1.7,0.7,1.9,1.7c0.1,1-0.2,2-0.5,3
		C560.2,847.9,560.1,849.9,557.7,850z"/>
	<path class="st3297" d="M565.6,833.7c0.4,0.8,1,1.1,1.9,1.1c0.9,3-2.1,3.9-3.1,5.8c-0.6,0.5-1.3,0.8-2,0.6
		c-1.1-0.6-1.3-1.4-0.6-2.5C562.6,836.8,563.7,835,565.6,833.7z"/>
	<path class="st3296" d="M553.6,855.7c0,3.1,0,6.3,0,9.4C552.1,861.9,552.2,858.8,553.6,855.7z"/>
	<path class="st3298" d="M561.6,838.8c0.1,0.8,0.2,1.7,1.1,2.1c0.3,1.7,0.1,3-2,3c0-0.9-0.2-1.7-1.1-2.1
		C560.2,840.8,560.9,839.8,561.6,838.8z"/>
	<path class="st3299" d="M408,366.4c-1.3-0.1-2.8,0.4-3.9-0.7c1.3-0.2,2.5-1.9,3.9-0.3C407.9,365.7,407.9,366.1,408,366.4z"/>
	<path class="st3102" d="M744.4,275.9c-1.6-1.1-3.2-2.2-4.8-3.4c-0.3-0.1-0.4-0.3-0.4-0.5c0-0.2,0.1-0.3,0.2-0.3
		c0,0,0.3-0.3,0.3-0.3C742.1,271.9,743.9,273.2,744.4,275.9z"/>
	<path class="st2867" d="M615.6,88.8c0,0.3,0,0.7,0,1c-0.6,0.6-0.9,2-2,0.5c-0.9-0.7-2.4-0.2-3-1.5C612.3,88.8,614,88.8,615.6,88.8z
		"/>
	<path class="st3300" d="M421.8,583.2c-3-0.8-5,2.8-8,2c0.2-6.9,6.6-5.8,10.4-7.8c1.5-0.8,3.5-1.2,4.7-2.4c2.2-2.3,4.7-3,7.6-2.7
		c2.5,0.2,4.2-0.8,5.2-3c5.6,0.2,10.8-0.9,16.2-3c4.9-1.9,11.2-0.5,16.8-0.4c1.6,0,3.3,0.3,4.9,0.4c0.3,0.3,0.6,0.7,1,1
		c-1,2-3.3,1-4.6,2.2c-0.4,0.2-0.8,0.2-1.2,0.2c-0.8-0.2-1.6-0.2-2.4-0.1c-0.4,0-0.8-0.1-1.2-0.3c-0.7-0.5-1.5-0.8-2.4-0.7
		c-10.7,1.1-21.4,2.7-31.3,6.7C432.4,577.3,426.7,579.6,421.8,583.2L421.8,583.2z"/>
	<path class="st3300" d="M413.9,585.2c-0.7,0.8,0.5,1.8-1.3,3.1c-4,2.7-6.8,6.9-10.9,9.9c-2.2,1.6-1.8,6.5-3.9,9.2
		c-1.4,1.9-0.9,5-3.9,5.8c0-2.7,0-5.3,0-8C398,596,403.6,588.2,413.9,585.2z"/>
	<path class="st2836" d="M762.5,712c1.3,3.3-1.2,2.8-3,3C760.2,713.7,760.5,712.1,762.5,712z"/>
	<path class="st3301" d="M758.5,753c-0.1-3-0.2-6,1.1-8.7c0.3-0.7,0.9-1.6,1.5-1.7c1-0.3,1.2,0.8,1.4,1.6c0.7,3.3,3.3,3.7,6,3.9
		c3.1,0.2,3.3,0,3-2c2.7-6.2,0.7-11.5-5.5-14c0.8-1.6,2.3-0.8,3.5-1c1.1,1.5,2.6,0.9,4,1c1.5,2.1,1.6,5.1,4,6.7
		c0.2,0.4,0.3,0.9,0.3,1.3c-0.1,0.8-1.1,1.7-0.7,2.3c1.2,1.5,3.8,2.4,2.1,4.8c-1.9,2.6-3.9,5.5-7.4,6.3c-3.7,0.9-6.2,2.7-8.1,5.8
		c-0.4,0.2-0.9,0.3-1.4,0.2C759.5,758.1,758,756.1,758.5,753z"/>
	<path class="st3302" d="M763.5,759c-1-1.6,2.2-2.9,0.1-5.2c-0.9-1,2.2-2,3.6-1.7c4.2,0.7,7.2,0.2,9.5-4.2c1.9-3.6,2.7-3.2-1.9-4.9
		c0.9-1,1.8-1.9,2.7-2.9c1.2-0.2,2.3,0,3.2,0.9c0.8,0.2-1.5,1.5,0.5,1.5c1.5,0,2,1.3,2.3,2.6c-0.1,3.1-2.7,4.7-4,7
		c-0.3,0.6-0.6,1.3-1.3,1.6c-3.2,0.1-6,5-9.7,1c-0.2,0.3,0.2,0.6,0.1,0.9c-0.8,2.1-2.3,3.5-4.1,4.6C763.6,760.2,763,760,763.5,759z"
		/>
	<path class="st3058" d="M777.4,739c-4.2-0.8-4-3.9-3.9-7.1c1-0.8,2-1.7,3-2.5c2.2-1.6,5.3-3.5,7.4-2.5c2.7,1.2,4.2,4.5,3.5,8
		c-1.3,2.1-2.4,4.6-5.6,4.5c-0.4-0.2-0.7-0.6-0.7-1.1c0.1-1.4,1.1-2.4,1.5-3.6c0.5-1.5,0.7-3.2-1.1-3.8c-2.1-0.7-2.8,1.1-3.4,2.7
		c-0.5,1.6,0.3,3.3-0.3,4.9C777.7,738.7,777.5,738.9,777.4,739z"/>
	<path class="st3301" d="M781.5,739.1c3,0.1,3.3-3.8,6-4.1c1.8,4.6-1.2,7.3-4,10c-1.8-0.6-2.7-3-6.4-1.4c2-1.5,2.7-2.1,3.4-2.6
		C779.9,739.9,780.6,739.4,781.5,739.1z"/>
	<path class="st3303" d="M778.4,753c0.3-0.3,0.7-0.6,1-0.9c0.5,0.3,0.9,0.7,1.4,1c-0.9,1.2-2,2.3-3.4,2.9c-0.9,0.5-1.1-0.1-1.3-0.8
		C776.4,753.9,777.7,753.7,778.4,753z"/>
	<path class="st3304" d="M739.5,720c-14.9,8.4-30.4,15.5-45.1,24.4c-0.8,0.5-1.9,0.4-2.8,0.6c-1.3-0.1-2.7,2.6-4,0
		c0-1.7,1.4-2.1,2.5-2.7c8-4.1,16.3-7.6,23.8-12.4c9.5-6,20.5-9.2,29.6-15.8c1-0.6,2.1-1,2.9,0.1c0.7,0.9-0.9,1.1-0.9,1.9
		C743.5,717.4,741.5,718.7,739.5,720z"/>
	<path class="st3305" d="M745.5,716.1c0.2-1.5-0.2-2.5-2-2c2.1-1.7,4.3-3.3,7-4c0.6,1,1.3,2,1.9,2.9
		C750.2,714.1,748.1,715.6,745.5,716.1z"/>
	<path class="st3306" d="M755.5,711c2.4-3.7,5.8-5.5,10.1-6C762.6,707.8,758.9,709.2,755.5,711z"/>
	<path class="st3307" d="M685.5,733c3-1.7,6-3.3,9-5c2.5,0.6,3.8,3,6,4c0,0.3,0,0.6,0,1c-2,1-4,2-6,3.1c-3.7,1.7-5.3-2.1-8-3
		C686.2,733,685.9,733,685.5,733z"/>
	<path class="st3308" d="M687.5,745c1.3,1.1,2.7-0.5,4,0c-10.4,6.1-20.8,12.2-31.9,17c-2.4,0.6-4.5,2.4-7.2,2.1
		c-1.2-4,2.5-3,4.1-4.1c7.6-5.3,16.6-7.8,24.7-12.2C683.2,746.7,684.5,743.6,687.5,745z"/>
	<path class="st3309" d="M656.6,760c-1,1.7-3.9,1.4-4,4c0.4,1.5-0.9,1.4-1.7,1.8c-3.4,0.6-6.1,2.8-9.1,4.2c-3.1,1.3-5.5,4-9,4.7
		c-1.7,0.4-3.3,1.4-5.1,0.5c-0.9-1.9,0.6-2.9,1.8-3.4c7.2-3.3,13.7-7.9,21.1-10.8c1.3-0.6,2.7-1.2,4-1.7
		C655.3,759.5,656,759.8,656.6,760z"/>
	<path class="st3310" d="M650.7,761c-5,4.2-10.9,6.7-16.6,9.8c-2.3,1.3-5,1.9-6.7,4.1c-0.1,1.1-0.9,1.7-1.8,2.1
		c-9,4-17.5,8.8-25.6,14.4c-1.6,1.1-3.2,1.9-5.1,2.5c-5.5,1.5-10.5,4.1-15.3,7.3c-0.4,0.3-0.8,0.5-1.2,0.7c-5,1.7-9.5,4.4-13.9,7.3
		c-0.4,0.3-0.8,0.5-1.2,0.7c-4.9,2.5-9.7,4.9-14.7,7c-0.3,0-0.6,0-0.9,0.1c-1,0-2.1,0-3.1-0.1c4-4.6,9.8-6.2,15-8.8
		c1.2-0.6,2.4-0.8,3-2.2c0.6-2,2.8-1,4-2c1.3-0.7,2.7-1.4,4-2.1c10.6-5.4,21.3-10.7,31.8-16.3c7.1-3.7,14.1-7.8,21.1-11.7
		c0,0,0,0,0,0c2.9-2.8,5.9-5.2,10-6c5.3-2.7,10.6-5.4,16-8C649.9,760.3,650.3,760.6,650.7,761z"/>
	<path class="st3047" d="M686.5,733c3.6-1.4,4.8,3.4,8,3c-2,1-4,2-6,3C689.8,736.3,686.9,735.1,686.5,733z"/>
	<path class="st3311" d="M477.8,187.6c-0.6,0-1.3,0-1.9,0c5.5-8.9,11.7-17.4,18.9-25c0.4-0.4,0.8-0.3,1.2,0.1
		c0.4,0.7,0.4,1.4,0.2,2.1c-4.1,7.3-10.3,13.1-14.7,20.1C480.4,186,479.9,187.9,477.8,187.6z"/>
	<path class="st3312" d="M495.9,162.7c-0.3,0-0.7,0-1,0c0.7-1.3,1.3-2.6,2-3.9c2-1.7,3.9-3.3,5.9-5c0.4,0,0.7,0.1,1.1,0.1
		c0.9,1.3,0.4,2.6,0.1,3.9c-0.3,0.3-0.7,0.6-1,0.8c-1.9,0.7-2.9,2.5-4.5,3.7C497.6,162.7,496.8,163.1,495.9,162.7z"/>
	<path class="st3313" d="M703.5,769c3.6,4.4-2.1,4.6-3.1,6.9c-1,2-3,2.7-4.6,4c-3.3,2-6.6,4.1-10,5.8c-1.8,0.8-3.6,1.8-5.6,2.3
		c-1.8,0.2-3.1,1.5-4.4,2.7c-0.7,0.6-1.5,1-2.4,1.3c-2.6,0.7-5.3,1.3-8,2.2c-1.5,0.5-2.8,1.5-4.4,1.9c-1.1,0.2-2.4,0.6-3-0.9
		c-0.1-0.7,0.1-1.4,0.5-2c0.3-0.4,0.6-0.6,1-0.9c1.5-0.6,2.8-1.3,3.9-2.4c9.7-6.7,21-10.8,30.6-17.6
		C697.3,771.1,699.8,768.4,703.5,769z"/>
	<path class="st3314" d="M707.6,739c0.7,0,1.3,0,2,0c-0.8,0.8,0,2.8-1.4,2.9c-6,0.3-4.3,4.7-4.6,8.1c-0.5,1.1-1.5,1.7-2.4,1
		c-3-2.3-5.8-0.8-8.8,0.1c-2.2,0.7-3.4,1.6-4.3,4c-1.2,3.1-4.2,5.3-5.6,8.4c-2.5,1.1-5.4,0.8-7.9,2.2c-0.8,0.2-1.5,0.2-2.3-0.1
		c-0.1-0.1-0.3-0.1-0.4-0.3c-2-3.2-2.4-3.6-5.3-4.4C680.2,753.6,693.9,746.3,707.6,739z"/>
	<path class="st3315" d="M682.5,763c0.3-2.6,0.2-5.4,3.5-6.4c0.3-0.1,0.8-0.4,0.7-0.6c-1-7.3,6.3-4.8,9-7.8c1.1-1.2,1.9-0.3,3,0.2
		c1.5,0.7,2.6,2.8,4.8,1.5c1.5,0.8,3.3,1.2,4,3c1.4,3.1,0.2,4-2.7,3c-5.5-2-5.4-2.1-7.2,3c-0.2,0.5-0.4,0.9-0.7,1.3
		c-0.6,0.8-1.5,1.3-2.4,1.6c-2.8,0.7-5.8,0.8-8.4,2.4C684.9,765,682.9,765.6,682.5,763z"/>
	<path class="st3314" d="M696.5,759c0.3-0.9,0.5-1.9,0.8-2.8c1.3-4.2,3.4-4.9,6.8-2c2.1,1.8,3.4,2.3,3.3-1.2
		c3.4,1.9,2.6,4.6,1.6,7.5c-2.1-0.1-2.5-2.9-4.6-2.5C701.6,756.5,699.3,759.1,696.5,759z"/>
	<path class="st3316" d="M703.5,769c-3,1.4-6,2.8-8.9,4.2c-0.8-0.8-0.5-1.9-0.9-2.8c-0.3-0.6-0.5-1.1-0.6-1.8c0-0.4,0.2-0.8,0.5-1.2
		c1.6-1.2,3.3-2.1,5-3.1c1.7-0.6,3.2-1.8,5-2.1c0.7,0,1.4,0.2,1.9,0.8C705.5,765.2,700.1,765.6,703.5,769z"/>
	<path class="st3317" d="M695.6,778.9c1.3-1.4,2.8-2.6,4.8-3.1c2.1,0.1,4,0.5,4.2,3.1c-0.3,1-0.7,2-1,3c-0.7,1.1-1.6,1.7-2.9,1.7
		C697.8,783.2,695.7,782.2,695.6,778.9z"/>
	<path class="st3318" d="M705.5,763c-0.7,0-1.4,0-2.1,0c-1.1-1.5-2.7-1.8-4.5-1.8c-0.9-0.1-1.8-0.4-2.4-1.2c0-0.3,0-0.7,0-1
		c2.5-1.3,4.7-5.7,8-1C704.9,759.6,705.2,761.3,705.5,763z"/>
	<path class="st3319" d="M622.6,798.9c-0.7-2.8-6-1.5-5.3-5.8c0-0.2-2.3,1.4-3.7,1.8c-2.1-2.7-2-4.5,1.9-4.9c4.2-0.5,7.7-2,10.3-5.8
		c1.9-2.8,6.1-3,8.7-5.3c0.7-0.6,1.4-1.3,2.1-1.9c3.2,2.4,6,5.2,10.4,5.7c1.3,0.1,2.6,1.3,2.9,3c-0.9,2.6-3.6,2.6-5.6,3.6
		c-2.2,1.1-4.2,2.6-6.1,4.1c-1.7,2.2-2.8,4.4-6.7,3.9C628.8,796.8,625.8,799.1,622.6,798.9z"/>
	<path class="st3320" d="M666.5,761c3.7-1.3,5.2,0.9,6,4c0.7,1.1,0.2,2.2-0.1,3.3c-0.6,1.5-1.5,2.8-2.5,4.1
		c-1.2,1.8-3.6,1.2-5.6,2.6c2-1.1,3.3-1.2,4.4,0.3c0.1,0.5-0.1,0.9-0.5,1.2c-1.9,0.7-3.9,1.5-5.8,2.2c-5.1-1.7-11-2-13.9-7.8
		c0.8-1.8,2.3-2.1,4-2c4.2-1,7.3-4,11-6C664.1,761.7,665.1,761,666.5,761z"/>
	<path class="st3319" d="M648.6,770.9c0.8-0.1,2.3-0.3,2.3-0.3c1.1,7,8.6,3.7,11.7,7.3c-3,4.2-7.2,6.3-12.1,7.2
		c-1.6-3.6-4-5.6-8.3-5.1c-2.2,0.2-3.6-1.3-4.6-3.1C641.1,774.6,644.4,771.9,648.6,770.9z"/>
	<path class="st3321" d="M634.6,778.9c-0.3,2.2-2.5,2-3.7,2.9c-1.5,1-3.8,1.3-4.4,3.1c-1.7,5.2-6.1,5.9-10.2,6.2
		c-3.5,0.2-2.7,1.9-2.6,3.9c-5.1-0.9-9.1,0.6-12,5c-2-2.3-4,0.8-6,0c-0.4-2.2,1.3-2.9,2.7-3.6c3.1-1.6,5.9-3.7,9-5.1
		c5.8-2.7,11.2-6.3,17.3-8.2C627.9,781.6,631.3,780.3,634.6,778.9z"/>
	<path class="st3322" d="M625.6,775.9c0.6-0.4,1.2-0.7,1.8-1.1c5.4-0.3,9.4-4.1,14.2-5.9c3.5-0.1,5.8-3.1,9-4c0.8,0,1.6-0.2,2-1
		c2.3-0.9,4.5-2.1,7.1-2c-11.9,6.8-23.5,14.2-36,20c-0.8,0.7-1.8,0.9-2.8,0.4C619.6,778.1,624,778.1,625.6,775.9z"/>
	<path class="st3323" d="M620.6,781.9c1,0,2,0,3,0c0,0.3-0.1,0.6-0.1,1c-4.9,3.5-10.6,5.4-15.7,8.5c-1.3,0.7-2.6,1.6-4.3,0.9
		c-0.9-0.6-1.5-1.5-1.4-2.7c4.3-3.7,9.3-5.9,14.3-8.3C617.9,780.7,619.4,780.8,620.6,781.9z"/>
	<path class="st3324" d="M637.6,776.9c1.8,1.2,3.5,2.5,5.9,2c4.8-0.9,7.3,1,7.2,6c0.3,0.9-0.1,1.2-1,1c-1.5-1-3.1-2.9-4.4-2.7
		c-5.1,0.7-6.6-3-8.6-6.2C636.9,777,637.3,776.9,637.6,776.9z"/>
	<path class="st3325" d="M607.6,790.9c5-3.2,10.3-6,15.9-8c0.4,0,0.7,0,1.1,0c-5.1,4.1-11.1,6.4-17,9c-0.3-0.2-0.4-0.5-0.3-0.7
		C607.4,791.1,607.5,790.9,607.6,790.9z"/>
	<path class="st3326" d="M663.5,762.9c0.6,0.1,1.5,0.8,1.3,0.8c-4.8,0.2-6.8,6.9-12.3,5.2C655.8,766.1,659.4,764.1,663.5,762.9z"/>
	<path class="st3327" d="M673.6,791c0.6-0.4,1.2-0.7,1.8-1.1c1-0.6,2-1,3.2-1.2c2.4,0.1,5.2-0.9,6.4,2.4c-2.6,4.4-7.1,5.6-11.5,6.9
		c-1,0.6-2,1.3-3.1,1.9c-4.3,4.1-10.1,5.9-15,8.9c-1.6,0.2-3-0.1-3.5-1.8c-0.4-1.6,0.6-2.7,1.7-3.8c2.8-2.8,6.7-4,9.6-6.6
		c0.3-0.4,0.7-0.7,1.1-1C667.4,793.9,670.4,792.3,673.6,791z"/>
	<path class="st3328" d="M684.6,801.9c-5,2.9-10.1,5.9-15.1,8.8c-3.3,3.7-7.2,6.2-12.2,6.7c-1.1-0.6-1.4-1.5-0.6-2.6
		c4.2-0.5,7.6-2.9,10.8-5.1c4.7-3.3,9.5-6.3,15.1-7.8C683.2,801.9,683.9,801.9,684.6,801.9z"/>
	<path class="st3329" d="M673.6,798c3.7-2,7.3-4,11-6.1c4.7-4.2,10.1-7,16-8.9c1-0.4,2-0.7,2.9-1.1c0.1,2.8-4.1,2.2-4,5
		c-5.1,1.4-9.7,3.8-14.2,6.5C681.8,795.7,678.4,798.9,673.6,798z"/>
	<path class="st3330" d="M655.6,807.9c5-2.7,9.9-5.4,14.9-8.1c-1.2,4.9-6.2,4.7-9.4,6.9c-2.1,1.5-4.5,2.6-5.6,5.2
		c-2.1,0.7-4,0.4-5.8-0.7c-0.8-1.3,0.2-1.8,1.1-2.3C652.3,808,654.1,809,655.6,807.9z"/>
	<path class="st3331" d="M649.6,810.8c2,0.1,4.1-0.2,6,1.1c0.4,1,0.7,2,1.1,2.9c0,1,0.3,1.7,1.3,2.2c-0.3,1.2-1.1,1.4-2.2,1.2
		c-0.6-0.4-1-0.9-1.2-1.5c-1.3-3.7-3.3-4.2-6.2-1.5c-0.7,0.7-1.6,1.2-2.7,0.9c-1-1.1-0.6-2.2-0.1-3.3c0.2-0.4,0.5-0.8,0.8-1.2
		C647.3,810.9,648.3,810.2,649.6,810.8z"/>
	<path class="st3332" d="M771.3,740.7c-0.3,2.7-1.3,5-4.3,5.2c-2.5,0.2-3.1-1.7-2.9-3.7c0.3-2.7,1.4-5.5,4.6-5.6
		C771.1,736.6,771.1,738.9,771.3,740.7z"/>
	<path class="st3333" d="M756.4,743c0.4-1.3,0.8-2.6,1.2-4c0.3,0,0.5-0.1,0.8-0.1c-0.4,4.7-1.4,9.3-0.8,14.1c-0.3,0-0.6,0.1-0.9,0.1
		C755.4,749.7,755.8,746.4,756.4,743z"/>
	<path class="st3334" d="M758.5,753c1.1,2.2,1.3,4.9,4,6c0.3,0.3,0.4,0.5,0.2,0.7c-0.1,0.2-0.2,0.3-0.3,0.3
		c-2.6-0.2-3.3-2.5-4.6-4.1c-1.1-0.9-0.9-1.9-0.3-3c0,0,0.1,0,0.1,0C757.9,753,758.2,753,758.5,753z"/>
	<path class="st3335" d="M771.5,746c1.4,0.1,2.7,1.3,2.1,2.1c-1.4,1.7-3.4,1.3-5.1-0.1C769.5,747.3,770.5,746.7,771.5,746z"/>
	<path class="st3336" d="M762.3,760.1c0-0.4,0.1-0.7,0.1-1.1c0.3,0,0.7,0,1,0c0.3,0.3,0.7,0.6,1,0.9c3.8-2.2,7.4-4.7,12-4.8
		c0.3,0.3,0.6,0.6,1,0.9c-1.1,0.9-2.2,1.8-3.3,2.8c-1.6,0.7-3.2,1.4-4.8,2.2c-2.3,0-4.5-0.1-6.8-0.1
		C762.5,760.6,762.4,760.3,762.3,760.1z"/>
	<path class="st3337" d="M757.4,753c0.1,1,0.2,2,0.3,3c-4.1-3.8-1.6-8.6-2-12.9c0.3,0,0.5,0,0.8,0
		C756.9,746.3,755.5,749.8,757.4,753z"/>
	<path class="st3338" d="M656,818c0.6-0.3,1.3-0.6,1.9-0.9c3.9-2.1,7.7-4.2,11.6-6.3c2,1.9,4.1-0.7,6.1,0.2
		c-6.1,4.1-12.4,7.8-19.7,9.4C655,819.5,655,818.8,656,818z"/>
	<path class="st3338" d="M686.5,804.9c-1,1.3-2.3,2-4,2C683.3,805.1,684.8,804.8,686.5,804.9z"/>
	<path class="st3338" d="M679.6,808.9c-1.1,1.1-2.4,1.9-4,2C676.3,809.1,677.9,808.8,679.6,808.9z"/>
	<path class="st3339" d="M656,818c0,0.8,0,1.5,0,2.3c0,0.9-0.1,1.7-0.1,2.6c-2.5,1.9-4.6,4.5-8,4.8c-0.2-0.3-0.3-0.6-0.5-0.9
		c-1.7-0.2-2.2-1.8-3.2-2.8c-0.6-0.9-0.9-2-1.1-3.1c-0.2-1.2,0.1-2.2,0.8-3.1c0.5-0.7,1-1.4,1.9-1.8c0.3-0.2,0.6-0.3,0.8-0.5
		C652.2,810.2,653.8,810.6,656,818z"/>
	<path class="st3068" d="M689.5,805.9c-0.5,2.9-2.8,2.9-5,3C685.6,806.9,687.5,806.2,689.5,805.9z"/>
	<path class="st3340" d="M502.7,504.3c7.6,5.1,16.2,7.1,25,9c0.8,0.9,2.3,1.3,2.5,2.8c0,0.4-0.2,0.8-0.5,1.2
		c-2.9-2.3-6.6-2.3-9.9-3.6c-1.5-0.6-1.5-0.1-2,0.6c-0.8,1.5,1.7,4-1.1,4.7c-2.4,0.5-5,0.5-7.4,0.1c-1.9-0.3-3.7-1.3-5.3-2.3
		c-1.6-1-3.1-2.4-0.8-4.2c2-1.6,1-2.6-0.7-3.1c-1.6-0.5-3.6-0.4-3.6-2.6C499,504.5,502,505.9,502.7,504.3z"/>
	<path class="st3341" d="M529.7,517.2c0-0.3,0-0.7,0-1c2.7-1.5,5.8,0.6,8.5-0.7c0.5-0.2,1-0.2,1.5-0.3c3.6-0.1,6.9,1.3,10.4,2.1
		c0.9,0.2,1.8,0.7,1.6,2c-6,0.7-11.9,1.4-18,1c-1.3-0.7-2.7-1.3-4-2C529.7,517.9,529.7,517.6,529.7,517.2z"/>
	<path class="st3342" d="M528.8,513.2c-1.7-1-3.4-1.9-5.1-2.9c0.1-0.7,0.5-1.2,1.2-1.6c1.2-0.4,2.2,0.1,3.2,0.7
		c0.8,0.5,1.6,0.9,2.4,1.3c2.5,2.2,7,1.3,8.2,5.4l0,0C534.8,517.2,531.5,516,528.8,513.2z"/>
	<path class="st3343" d="M528.8,513.2c3.4,0.7,6.3,3.1,10,3c-3,2.2-6,0.6-9,0c-0.6-1-2.2-1.4-2-3
		C528.1,513.3,528.4,513.2,528.8,513.2z"/>
	<path class="st3344" d="M524.7,509.4c-0.4,0.3-0.7,0.7-1.1,1c-1.7-0.7-3.3-1.3-5-2c0.6-0.3,1.3-0.6,1.9-0.9
		c0.7,0.3,1.5,0.5,2.2,0.8C523.9,507.8,524.4,508.4,524.7,509.4z"/>
	<path class="st3345" d="M513.9,460.5c2-6.2,3-12.5,4-18.9c0.4-0.5,0.8-0.5,1.3-0.1c1.3,1.7,1.7,3.7,1.6,5.8c-1.8,2-0.9,4.2-0.5,6.3
		c-1.9,4.9-3.3,9.9-4.5,15c-0.6,2.5-1.1,5.2-3.4,6.8c-0.7,0.4-1.5,0.5-2.2-0.1C512.4,470.6,512.3,465.3,513.9,460.5z"/>
	<path class="st3346" d="M510.1,475.3c0.5,0,1.1,0,1.6,0c1.1,2.8,1,5.6-1.1,7.9c-2.8,3.1-3.1,5.8-0.2,9c0.9,1,1.5,2.5,2,4
		c0.1,0.7-0.1,1.4-0.6,1.9c-0.3,0-0.6,0-0.9,0c-6.4-5.3-9.2-11.1-2.7-18.4C509.1,478.6,509.5,476.8,510.1,475.3z"/>
	<path class="st3347" d="M511.8,498.1c0-0.6,0-1.2,0-1.9c3.4,0.2,5.1,3.5,8.2,4.3c2.1,1.8,4.5,3.1,7,4.2c0.8,0.7,1.3,1.5,0.9,2.6
		c-0.8,0.8-1.8,0.8-2.8,0.6c-0.5-0.2-0.9-0.5-1.1-0.9C519.6,504.4,515.1,502.1,511.8,498.1z"/>
	<path class="st3348" d="M523.9,507.1c0.3,0,0.6,0.1,0.8,0.2c0.9,1.1,4.4-0.6,3,2.9c-1-0.3-2-0.6-3-0.9c-0.7-0.4-1.3-0.8-2-1.1
		C523.1,507.8,523.5,507.5,523.9,507.1z"/>
	<path class="st3349" d="M613.6,90.3c0.7-0.2,1.3-0.3,2-0.5c1.7,0,3.3,0,5,0c-2.3,2.7-6.1,1.8-8.9,3.4c-0.6,0.3-1.6-0.1-2.1,0.8
		c-5.2,3-11.2,4-16.9,5.8c-1,0.6-2.2,0.6-3.4,0.7c-0.5-0.1-0.8-0.4-1.1-0.8C596.3,95.4,605.3,93.7,613.6,90.3z"/>
	<path class="st3350" d="M558.6,113.6c9.2-6.1,18.9-11.3,29.7-14c0.3,0,0.5,0,0.8,0.1c-0.6,3.1-3.8,1.7-5.4,3l0.1-0.1
		c-3.7,2-7.8,3.2-11,6l0.1,0c-1.9,0.7-4,0.7-5.7,2.1c-0.7,0.6-1.5,1-2.3,1.4c-1.5,1-3,2.1-5,2C559.3,114.2,558.9,114,558.6,113.6z"
		/>
	<path class="st3351" d="M558.6,113.6c0.4,0.1,0.8,0.2,1.2,0.3c0.3,0.2,0.4,0.4,0.3,0.6c-0.1,0.2-0.2,0.3-0.3,0.3
		c-4.4,3.8-9,7.2-15,7.9c0-0.4-0.1-0.7-0.1-1.1C549.3,119,553.9,116.3,558.6,113.6z"/>
	<path class="st3352" d="M528.7,134.7c-4,5.3-9.1,9.1-15,12c-0.2-2.7,2.2-3.5,3.6-4.9c3.3-3.3,7.3-5.9,11.3-8.3
		C529.2,133.9,529.2,134.3,528.7,134.7z"/>
	<path class="st3353" d="M513.8,146.7c0.1,1.3,0.2,2.4,2,1.5c1.6-0.8,3.2-1.6,4.9-2.3c1.2,1.3,0.3,2.3-0.5,3.3
		c-3.6,2-7.1,4.4-11.3,5.1c-2.2-0.8-1.2-2.2-0.5-3.4c1.5-1.4,3.4-2.2,4.4-4.1C513.1,146.7,513.4,146.7,513.8,146.7z"/>
	<path class="st3354" d="M508.8,150.8c0.2,1-1.2,2,0,3c-0.4,3.1-2.3,4.3-5.3,4c-0.7-1.3,0.2-2.6,0.3-3.8c1.7-1.4,3.3-2.7,5-4.1
		c0.3,0.2,0.4,0.4,0.3,0.7C509,150.6,508.9,150.8,508.8,150.8z"/>
	<path class="st3355" d="M538.7,127.9c-0.4,0.9-1.1,0.8-1.8,0.8c-1.8,1.6-3.1,4-6.2,3c2.5-2.7,5.6-4.5,8.9-6.2
		C540.5,126.7,539.2,127.2,538.7,127.9z"/>
	<path class="st3356" d="M530.7,131.7c2.5,0,4.1-2.1,6.2-3c-1,4.4-5.6,3.9-8.2,6c0-0.4-0.1-0.8-0.1-1.2
		C529.4,132.9,530,132.3,530.7,131.7z"/>
	<path class="st3357" d="M538.7,127.9c0.3-0.8,0.6-1.6,0.9-2.4c1.7-0.9,3.4-1.9,5.2-2.8C544.3,126.2,541,126.5,538.7,127.9z"/>
	<path class="st3352" d="M508.8,150.8c0-0.3,0-0.7,0-1c0.8-1.7,2.4-2.4,3.9-3.1C512.5,149.1,511,150.3,508.8,150.8z"/>
	<path class="st3358" d="M640.7,137.7c0.6,0,1.3,0,1.9,0c1.9,0.9,4.3,0.5,6,2l-0.1-0.1c0.6,0.5,1.4,0.8,2,1.3
		c0.9,0.7,2.3,1.2,1.9,2.5c-0.3,1.3-1.8,1.2-2.9,1.3c-1.6,0.1-3.2,0-4.9,0c-8.3-1.3-16.4,0.3-24.6,0.8c-2.5,0.2-5,0.4-7.5,0.7
		c-2.5,0.5-5,0.8-7.5,1.3c-1.5,0.5-3.2,0.3-4.7,1.2c-0.8,0.5-1.7,0.9-2.7,0.9c-0.5,0-0.9-0.2-1.4-0.4c-0.6-0.4-1.1-0.9-1.5-1.6
		c-2.6-4.9-2.3-5.6,2.9-6.5c1.4-0.2,2,0.3,1.8-2.1c-0.3-3.3,3.5-2.8,5.4-2.1c5.1,1.9,10.1,0.3,15.2,0.5c-0.1-1.9-3.2-1.6-2.3-3.5
		c0.7-1.5,2.4-0.4,3.7-0.6c1.5-0.1,2.9,0.1,4.4,0.5c1.7,0.4,3.4,0.9,5.2,1.2C634.3,135.8,637.8,135.6,640.7,137.7z"/>
	<path class="st3359" d="M608.6,130.7c0.7,0,1.4,0,2.2,0.1c1.5,1.1,3.6-0.3,5.1,1c-2.6,3.7-6.7,2.8-10.3,3.1
		c-6.7,0.5-13.5,1.5-20.2,1.3c-4.1-0.1-3.5,3.4-4.1,5.7c-0.6,2.4,1.3,2.9,3.3,3.1c2.6,0.3,5.2,1,7,3.2c0.4,3.2-2.6,2.4-4,3.4
		c-1.5,0.6-3.1,1.2-4.7,1.5c-1.9,0.1-3.9,0.1-5.5,1.5c-0.4,0.3-0.8,0.4-1.2,0.5c-1.9,0.3-3.9,0.5-5.6,1.6c-1.3,0.7-2.6,1.1-4.1,1
		c-0.7,0-1.4-0.2-2.1-0.5c-2.6-1.8-2.4-3.1,0.8-3.4c2.7-0.3,3.6-1.3,2.3-3.9c-0.6-1.1-0.9-2.5,0-3.3c2.7-2.4,2.8-6.3,5.1-8.9
		c-1.8,0.1-3.6,0.2-5.2-1.5c1.6-1.8,3.7-0.6,5.1-1.1c-1.8-0.6-4.3,1.5-5.9-1.3c3.6-2.5,7.9-1.9,11.9-2.4c8.8-1.1,17.7-1.1,26.6-0.4
		c0.7,0.1,1.3-0.1,1.9-0.4C607.5,130.4,608.1,130.4,608.6,130.7z"/>
	<path class="st3360" d="M591.6,148.7c-3.2-0.9-5.9-3.3-9.8-2.2c-2,0.6-3-5.3-1.6-8.3c1.6-3.2,4.4-3.4,7.4-3.5
		c9.4-0.1,18.7-0.8,28-2.6c0.1-0.8,0.4-0.9,1-0.4c1.7,0.4,3.4,0.7,5.2,1.1c0.3,0.3,0.2,0.6-0.2,0.8c-1,0-1.9,0-2.9,0
		c0,0.2-0.1,0.4-0.1,0.6c0.9,0.3,2,0.5,2.8,1c0.8,0.5,1.7,1.5,1.7,2.2c-0.2,1.6-1.8,1.3-2.8,1.1c-5.1-0.9-10.3,2.5-15.4-0.8
		c-1.1-0.7-3.2-0.7-4.7,0.5c0,2.1,4.3,0.7,2.9,3.4c-0.8,1.6-2.6,0.3-4,0.3c-1.6,0-3.2,0.5-4.8,0.8c-0.7,2,3.2,2.5,1.3,4.6
		c-1,0.9-2.4,0.4-3.4,1C592,148.6,591.8,148.7,591.6,148.7z"/>
	<path class="st3361" d="M725.4,161.9c0,8.5-0.1,17.1-0.1,25.6c-0.6-0.4-1.1-0.7-1.7-1c-2-0.8-3.8-1.7-4.3-4.1
		c0.1-0.2,0.1-0.4,0.2-0.7c2.6,0.9,4.5,1,4.1-2.8c-0.2-1.7,0.5-3.4,0.9-5.1c-1.1-2.6,0.2-5.4-0.8-8c0-4.3,0-8.7,0.1-13
		c0.2,0,0.4,0,0.6,0C726,155.6,724.1,158.9,725.4,161.9z"/>
	<path class="st3362" d="M609.6,93.9c3.1-2.8,7.5-2.3,11-4.2c3.9-1,7.8-2.2,12-2c0.3,0.2,0.5,0.4,0.4,0.6c-0.1,0.2-0.2,0.3-0.3,0.3
		C624.9,90.4,617.3,92.1,609.6,93.9z"/>
	<path class="st3363" d="M645.5,143.7c2,0,3.9,0,6,0c-0.2-1.5-2.3-1.5-1.9-3c1.5-1.3,2.6,0.4,3.9,0.6c1.3,1,2.9,1.4,4.2,2.4
		c1,0.4,2,0.7,3,1.1c0.5,0.5,1.5,0.8,0.8,1.9c-1,0.3-2,0.6-3,0.9c-1.6-2-4.2-0.9-6.2-1.8C650.3,144,647,146.9,645.5,143.7z"/>
	<path class="st3364" d="M667.5,85.9c0.1,0.3,0.1,0.5,0.2,0.8c-0.7,0.6-1.4,0.6-2.2,0.1c-6.9,0-13.8,0-20.8,0
		c-1.8,0.6-3.5,0.9-5.2-0.1C648.8,84.7,658.1,85.7,667.5,85.9z"/>
	<path class="st3365" d="M556.6,133.7c4-3.6,9.3-1.3,13.7-2.9c1.6-0.6,3.6-0.1,5.3-0.1c0.8-0.5,1.6-0.7,2.1,0.3
		c-0.1,0.3-0.3,0.8-0.5,0.8c-6.3,0.3-12.2,2.5-18.5,3C558,134.7,557.2,134.4,556.6,133.7z"/>
	<path class="st3366" d="M665.5,86.8c0.7,0,1.5-0.1,2.2-0.1c3.9,0.4,7.9,0.8,11.8,1.2c1.3,1.7,4.6-0.5,5.1,2.7
		c-5.5-0.9-11-1.9-16.5-2.8C667.1,87.6,666,87.9,665.5,86.8z"/>
	<path class="st3367" d="M542.5,137.8c1.1-0.4,2.1-0.8,3.2-1.2c0.9-0.7,1.9-0.7,2.9-0.3c0.4,0.4,0.5,0.9,0.3,1.4
		c-2.9,2.8-6.7,3.6-10.3,4.9c-1.3,0.2-2.6,0.2-3.8-0.7c-0.9-2.5,1.4-2.4,2.6-3C539,138.2,540.9,138.4,542.5,137.8z"/>
	<path class="st3368" d="M542.5,137.8c-2.4,1.8-5.8,1.5-8,3.7c-0.2,0.4-0.5,0.7-0.9,0.9c-1.4,0.4-2.8,1.1-4.4,0.7
		c-0.2-0.1-0.3-0.3-0.5-0.4C532.5,138.8,537.2,137.4,542.5,137.8z"/>
	<path class="st3369" d="M572.7,108.8c2.5-4.2,6.8-5,11-6C580.7,106,576.7,107.3,572.7,108.8z"/>
	<path class="st3370" d="M519.6,148.6c0.5-0.9,1.4-1.6,1.1-2.8c0.9-0.4,1.9-0.8,2.8-1.2c1.9,0.3,3.4-0.8,5-1.4
		c0.9,0,1.8-0.1,2.7-0.1c0.4,0.2,0.7,0.5,0.8,1c-0.5,1.5-1.8,1.9-3.1,2.5c-2.3,0.7-4.5,2-6.8,2.6c-0.4,0.1-0.9,0.1-1.4,0.1
		C520.2,149.2,519.9,149,519.6,148.6z"/>
	<path class="st3371" d="M639.5,86.7c1.7,0,3.5,0.1,5.2,0.1c-3.8,2.3-7.9,2.2-12.1,1.8c0-0.3,0-0.6,0-0.9
		C634.8,86.6,637.1,86.7,639.5,86.7z"/>
	<path class="st3372" d="M583.6,129.9c6.3,0,12.7,0,19,0c0.3,0.7,0,1.1-0.7,1.3c-5.9,0.1-11.8,0.1-17.7,0
		C583.6,131,583.4,130.5,583.6,129.9z"/>
	<path class="st3373" d="M661.4,146.6c-0.3-0.6-0.5-1.2-0.8-1.9c2.8,1.3,5.9,2.3,8,4.9c-0.4,0-0.8,0.1-1.2,0.1
		C665.2,149.2,662.4,149.7,661.4,146.6z"/>
	<path class="st3374" d="M702.6,166.8c4.6,0.2,6.5,3.9,8.9,6.9c-0.2,0.1-0.3,0.3-0.5,0.4c-2.9-0.1-4-2.6-5.7-4.2
		C704.6,168.7,702,169.1,702.6,166.8z"/>
	<path class="st3375" d="M718.4,181.8c-0.4-0.4-0.7-0.8-1.1-1.2c-1.8-2.5-6-3-5.8-7.1c0,0,0,0,0,0c3.6,1.7,7.2,3.5,7.9,8.1
		c0,0,0-0.1,0-0.1C719.2,182,718.9,182.1,718.4,181.8z"/>
	<path class="st3376" d="M640.7,137.7c-3.3-0.7-6.7-1.5-10-2.2c-0.1-0.8-0.1-1.4,1-0.8C635,134.8,638.4,134.4,640.7,137.7z"/>
	<path class="st3377" d="M583.6,102.8c1.8-1,3.6-2,5.4-3c1.2,0,2.5,0,3.7,0C590.2,102.1,587.1,103.1,583.6,102.8z"/>
	<path class="st3378" d="M556.6,133.7c0.7,0,1.4,0.1,2.1,0.1c-1.4,3.2-4.1,2.7-6.7,2.4c-0.5-0.3-0.7-0.8-0.4-1.3
		C553.1,133.5,554.9,133.6,556.6,133.7z"/>
	<path class="st3379" d="M702.6,166.8c1,1,2,1.9,3,2.9c-0.4,0.5-0.7,0.5-1.1,0c-2.3,0-3.2-2.3-5-3c0-0.4,0-0.8,0.1-1.3
		c0.7-0.3,1.3-0.3,1.9,0.3C701.9,166,702.3,166.4,702.6,166.8z"/>
	<path class="st3380" d="M723.7,165.7c1.7,2.5,0.5,5.3,0.8,8C722.5,171.2,723.8,168.4,723.7,165.7z"/>
	<path class="st3381" d="M583.6,129.9c0,0.2,0,0.5,0,0.7c-1.9,1.5-3.9,1.4-5.9,0.2c-0.7,0-1.4,0-2.1-0.1
		C578.2,128.6,581,130.1,583.6,129.9z"/>
	<path class="st3382" d="M701.5,165.6c-0.6-0.1-1.3-0.2-1.9-0.3c-0.9-0.8-1.9-1.7-2.8-2.5C699.4,162,700.8,163.2,701.5,165.6z"/>
	<path class="st3383" d="M631.7,134.7c-0.3,0.3-0.7,0.6-1,0.8c-1.7-0.4-3.3-0.7-5-1.1c0.1-1.5,0.9-1.3,1.8-0.7
		C628.9,134.1,630.6,132.8,631.7,134.7z"/>
	<path class="st3384" d="M551.7,134.9c0,0.2,0,0.5,0,0.7c-0.7,1.1-1.6,1.7-3,1.1c-1,0-2,0-3,0C547.3,134.7,549.3,134.4,551.7,134.9z
		"/>
	<path class="st3385" d="M648.6,139.7c-2.2,0-4.3-0.4-6-2C644.7,138,647.4,136.6,648.6,139.7z"/>
	<path class="st3386" d="M528.7,143.7c-1.8,0.3-3.1,3-5.3,0.9c1.5-1.2,3.4-1.7,5.2-1.9c0,0,0,0,0,0c0.3,0.2,0.4,0.5,0.3,0.7
		C529,143.6,528.9,143.7,528.7,143.7z"/>
	<path class="st3387" d="M627.5,133.7c-0.6,0.2-1.2,0.4-1.8,0.7c-1.4-0.2-2.7-0.5-4.1-0.7c0-0.3,0.1-0.6,0.2-0.8
		C623.7,133.3,625.9,131.6,627.5,133.7z"/>
	<path class="st3388" d="M559.7,114.8c0-0.3,0-0.6,0.1-0.9c1.6-0.7,3.3-1.4,4.9-2.2c0.3,0.2,0.4,0.5,0.3,0.7
		c-0.1,0.2-0.2,0.4-0.3,0.4C563.4,114.3,561.6,114.8,559.7,114.8z"/>
	<path class="st3389" d="M566.5,110.7c1.7-1.8,3.9-2.1,6.2-1.9c-1.4,1.4-3.2,1.6-5,2C567.3,111.2,566.9,111.2,566.5,110.7z"/>
	<path class="st3390" d="M653.5,141.7c-1.3-0.3-2.7-0.7-4-1c-0.3-0.4-0.7-0.7-1-1.1c2.2,0.1,4.5-0.1,6.1,2
		C654.3,142.1,653.9,142.1,653.5,141.7z"/>
	<path class="st3391" d="M616.6,131.7c-0.3,0.1-0.7,0.2-1,0.4c-1.6-0.7-3.6,0.6-4.9-1.3C612.7,131.1,614.9,129.7,616.6,131.7z"/>
	<path class="st3392" d="M602.6,130.6c0-0.2,0-0.5,0-0.7c2,0.1,4.2-1.1,5.9,0.8c-0.3,0-0.6,0.1-0.9,0.3
		C606,131.9,604.3,131.9,602.6,130.6z"/>
	<path class="st3393" d="M653.5,141.7c0.4,0,0.8,0,1.1,0c1.6-0.2,2.3,0.9,3,2C656.1,143.5,654.7,142.9,653.5,141.7z"/>
	<path class="st3394" d="M692.5,159.6c-1.5,0-2.7-0.7-3.7-1.8C690.5,157.5,691.9,157.7,692.5,159.6z"/>
	<path class="st3395" d="M566.5,110.7c0.4,0,0.8,0.1,1.2,0.1c-0.6,1.3-1.6,2-3,2.1c0-0.4,0-0.7,0-1.1
		C565.3,111.4,565.9,111,566.5,110.7z"/>
	<path class="st3396" d="M725.4,161.9c-1.6-2.9-0.3-6.2-1.1-9.2c0.7-6-1.3-11.8-1-17.7c-0.3-0.8-0.5-1.7,0.3-2.4
		C725.8,142.3,725.9,152,725.4,161.9z"/>
	<path class="st2863" d="M723.6,132.6c-0.1,0.8-0.2,1.6-0.3,2.4c-1.1-1.5-0.8-3.3-1.1-5c0.4-0.1,0.8-0.1,1.2-0.2
		C723.5,130.7,723.6,131.6,723.6,132.6z"/>
	<path class="st3397" d="M458.8,217.6c0.4-0.3,0.8-0.7,1.2-1c0.7-0.5,1.3-0.6,2-0.1c2,4.9-1.1,8.9-2.2,13.2
		c-0.7,1.7-1.9,3.1-2.8,4.6c-1.4,2.2-2.8,1.9-3.8-0.4c0.2-3.6,1.5-6.9,2.6-10.3C456.8,221.6,457.8,219.6,458.8,217.6z"/>
	<path class="st3398" d="M455.8,223.6c0.4,3.9-2.5,7.1-2,11c0.3,3.4-0.9,6.3-3.1,8.9c-1.5,1.5-1.1,4.1-3.4,5.5
		c0.1-2,0.3-3.7,0.4-5.4C450.5,236.9,453.2,230.3,455.8,223.6z"/>
	<path class="st3399" d="M473.8,209.2c0.1-1.6,1.5-4.4-2-3c-5,1.9-3.1-2.2-2.7-3.4c0.7-2.2,2.4-4.2,3.7-6.2
		c4.3-2.7,8.7-0.2,12.9,0.1c2.4,0.2,4.7,1.5,7.2,0.2c1-0.5,2.2,0.3,2.9,1.3c0.4,3.1-2.4,3.6-4,5.1c-4.2,2.5-8.5,4.7-13.3,6.1
		C477.1,209.9,475.3,211.3,473.8,209.2z"/>
	<path class="st3400" d="M495.9,198.7c-0.4-0.2-1-0.7-1.3-0.6c-7.5,2.7-14.4-2.3-21.8-1.5c1.4-3.2,5.5-4.8,5-9c1-1,2-2,3-3
		c2.6-0.7,3.3,1.2,4.4,2.9c2.5,4.1,6.2,5.6,10.7,4.6c1.9,0.5,3-0.9,4.4-1.6c2.1-1.1,3.8-0.9,4.3,1.9c0,0.5-0.1,0.9-0.4,1.4
		C501.6,195.6,500.1,199.4,495.9,198.7z"/>
	<path class="st3401" d="M473.8,209.2c6.5-0.8,12.1-4.2,18-6.8c0.8,2.2-1.3,3.2-2.1,4.7c-0.8,0.9-1.7,1.6-2.6,2.3
		c-1.4,1-2.8,1.8-4.3,2.5c-5.9,2.2-11.7,4.4-18.1,4.5c-1.2-0.6-2.8,0.1-3.9-0.9c0.1-0.3,0.2-0.6,0.3-1c1.3-2.2,4-1.7,5.8-2.9
		C469.4,211.7,471.3,209.6,473.8,209.2z"/>
	<path class="st3402" d="M466.8,211.7c-1.5,1.9-4,1.7-5.8,2.9c0.3-0.7,0.6-1.5,0.9-2.2C463.5,212.2,465.1,211.9,466.8,211.7z"/>
	<path class="st3402" d="M460.7,215.6c1,0.4,2.2-1.2,3.2,0.3c-0.2,1.3-1,1.5-2,1c-0.6-0.1-1.2-0.2-1.8-0.2
		C460.2,216.2,460.4,215.9,460.7,215.6z"/>
	<path class="st1310" d="M594.6,184.6c-2.7-2-5.3-4-8-6c0.5-1.3,2.8-2.5,2.5-2.7c-2.6-1.8-0.9-5.9-4-7.2c-2-0.8-2.6-2.4-2.5-4.3
		c0-1.3,0.5-2.5,1.1-3.7c2.8-2.6,6.8-1.4,10-3c1.8,0,3.7-0.2,5.1,1.3c-1,1.4-2.4,0.1-4.6,0.7c7.8,3.7,4.6,12.2,9.2,17.3
		c1.1,1.2-4.4,6.5-7.4,7.2C595.5,184.4,595.1,184.5,594.6,184.6z"/>
	<path class="st3403" d="M625.6,219.5c2.2,2.7,4.5,5.5,6.7,8.2c3.1,2.2,4.5,5.4,5.7,8.8c-0.1,0.8-0.5,1.3-1.3,1.5
		c-2.6-0.2-3.3-2.5-4.7-4.1c-2.5-4.1-6.1-7.5-7.9-12.1C624.3,220.9,624.7,220,625.6,219.5z"/>
	<path class="st3404" d="M645.6,245.6c0.4,1,0.7,2,1.1,3c0.7,1.3,1.4,2.7,2,4c1.8,1.1,1.9,3.3,2.9,4.9c0.4,1,0.7,2,1.1,3.1
		c0.3,0.7,0.6,1.3,0.9,2c0,0.6,0.1,1.3,0.1,1.9c-0.2,0.1-0.4,0.2-0.6,0.3c-1.1-0.3-1.7-1-2.1-1.9c-3-6.5-7.1-12.6-10-19.2
		c-0.1-0.8-0.1-1.5,0.5-2.1C643.7,242.1,643.5,245,645.6,245.6z"/>
	<path class="st3405" d="M612.8,205.5c4.2,3.6,8.4,7.1,11.1,12c-1.6,1.1-2.7-0.3-4-0.9c-3.3-3.1-7.7-5.3-8.8-10.3
		C611.5,205.6,611.9,205.1,612.8,205.5z"/>
	<path class="st3406" d="M637.6,236.5c-1.8-2.9-3.5-5.9-5.3-8.8c0.8,0.3,1.2-2.6,2.3-0.3c1.5,3,3.9,5.6,3.9,9.3
		C638.2,237.1,637.9,237,637.6,236.5z"/>
	<path class="st3407" d="M601.7,193.6c0.3,0,0.7-0.1,1-0.1c3.2,3.1,7.3,5.5,9,10c-0.9,0.8-1.6,0.1-2.4-0.3c-0.9-0.7-1.6-1.5-2.5-2.3
		C605.1,198.5,601.5,197.4,601.7,193.6z"/>
	<path class="st3408" d="M637.6,236.5c0.3,0,0.6,0.1,0.9,0.1c0.6,1.8,1.6,3.5,2.9,4.9c0.1,0.6,0.2,1.3,0.2,1.9
		c-0.9,0.5-2,0.1-2.9,0.2c-1.9-1.5-3-3.4-2.7-5.9C636.6,237.4,637.1,237,637.6,236.5z"/>
	<path class="st3409" d="M620.1,215.8c1.3,0.6,2.5,1.2,3.8,1.7c0.9,0.4,1.5,1,1.7,2c-0.3,0.7-0.6,1.4-0.9,2.1
		C622,220.6,619.9,219.1,620.1,215.8z"/>
	<path class="st3410" d="M609.8,202.3c0.6,0.4,1.2,0.8,1.9,1.2c0.4,0.7,0.8,1.3,1.2,2c-0.4,0.5-0.9,0.9-1.3,1.4
		c-1.6,0-2.4-1-2.6-2.5C608.9,203.5,609.1,202.8,609.8,202.3z"/>
	<path class="st1310" d="M598.7,188.6c-1.1-0.7-3.2-0.7-2-3C597.5,186.5,599.6,186.5,598.7,188.6z"/>
	<path class="st2884" d="M726.7,261.4c-3.1-0.1-5.1-1.9-8-3.8C722.2,258.2,725.8,256.3,726.7,261.4z"/>
	<path class="st3411" d="M693.1,265.4c-0.9,0.1-1.7,0.1-2.6,0.2c-1.6,0.6-3.5,0.3-4.9,1.7c-7.3,2-14.6,4.1-21.7,6.9
		c-6.7,2.6-12.8,6.1-18.7,10.1c-3.7,0.7-5.8,4.5-9.7,4.7c-0.8-0.2-1.3-0.6-1.5-1.4c0.5-3.3,3.4-4.4,5.7-5.8c3.3-2.2,6.6-4.4,9.8-6.8
		c1.1-0.8,1.8-1.9,2.8-2.7c1.2-0.4,2.4-0.8,3.5-1.3c1.2-0.2,2.6,0.6,3.7-0.4c7.7-2.3,15-6.3,23.1-7.5c1.6-0.2,3.3-0.5,4.9-0.7
		c0.3,0.2,0.4,0.5,0.4,0.9c1.9,0.8,4.3-0.7,5.9,1.4C693.9,265.2,693.6,265.4,693.1,265.4z"/>
	<path class="st3412" d="M687.5,263.5c0-0.4,0-0.8,0-1.1c8-1.6,16-2.8,24.1-0.9c-2.4,2.3-5.7,0.8-8.3,2.1
		C698.1,264.3,692.8,263.7,687.5,263.5z"/>
	<path class="st3413" d="M703.6,262.5c2.5-1.9,5.4-0.4,8.1-1c5.7-0.2,10.8,1.9,15.9,4c-0.6,0.7-1.3,1-2.2,1.1
		c-2.2,0.2-4.3-0.7-6.5-0.9c-3.2-1.5-6.8-1.5-10.2-1.8C706.9,263.7,704.9,264.1,703.6,262.5z"/>
	<path class="st3414" d="M725.6,265.6c0.7,0,1.3,0,2,0c4.4,1.1,7.9,4,11.8,6.2c0.1,0.3,0.1,0.6,0.2,0.8c-0.3,0-0.7,0-1,0
		c-1.7,0.1-2.6-1.5-4.1-1.9c-3.8,0.6-6.2-2.1-9-3.8C725.1,266.4,725.2,266,725.6,265.6z"/>
	<path class="st3404" d="M659.5,270.5c-1.1,1.3-2.5,1-3.9,0.9c-2.7-1.7-3.2-4.1-2.1-6.9c0,0,0.2-0.2,0.2-0.2
		C656.5,265.6,654.9,271,659.5,270.5z"/>
	<path class="st3100" d="M651.6,257.5c-1.6-1.3-2-3.2-2.9-4.9C651.4,253.2,651.4,255.4,651.6,257.5z"/>
	<path class="st3100" d="M653.6,251.6c-0.1,0.7-0.3,1.5-0.4,2.2c-0.4-0.2-1-0.3-1.1-0.6C651.5,251.8,652.7,251.7,653.6,251.6
		L653.6,251.6z"/>
	<path class="st3415" d="M583.7,163.7c0,0.2,0,0.3,0,0.5c0.5,3.9,6.4,4.8,5.3,9.6c5,1.9,2,3.2-0.4,4.4c-0.6,0.3-1.2,0.3-1.9,0.5
		c-4.7-1.4-9.1-3.3-11.9-7.7c-0.8-1.2-1.7-1.4-2.9-0.9c-1,0.4-2.1,0.6-2.4-0.7c-0.4-1.3,0.9-1.8,1.8-1.7c2.9,0.4,4.4-1.9,6.4-3.1
		C579.5,163.2,581.4,162.3,583.7,163.7z"/>
	<path class="st3416" d="M561.7,168.6c8.1,2.6,15.3,7,22.1,11.9c-0.5,0.7-1.2,1-2,1.1c-7.9-2.6-15.6-5.5-21.7-11.4
		c-0.1-0.5,0-1,0.2-1.5C560.8,168.3,561.3,168.3,561.7,168.6z"/>
	<path class="st3417" d="M581.6,180.6c0.8,0,1.5-0.1,2.3-0.1c5.3,3.2,10.4,6.7,14.8,11.1c-0.5,0.9-1.4,1.3-2.4,1.4
		c-4.8-2.4-8.7-6.2-13.2-9.1C582,183.3,581.1,182.2,581.6,180.6z"/>
	<path class="st3418" d="M596.7,192.5c0.7-0.3,1.3-0.6,2-0.9c1,0.7,2,1.3,3,2c2.1,2.3,4.2,4.5,6.3,6.8c-0.2,0.7-0.6,1.2-1.3,1.5
		c-0.7,0.1-1.4,0-2-0.4c-0.4-0.2-0.7-0.5-1.1-0.8C601.6,197.8,597.6,196.5,596.7,192.5z"/>
	<path class="st3419" d="M561.7,168.6c-0.3,0.1-0.6,0.1-0.8,0.1c-0.8,0-1.6,0.6-2.5,0.3c-0.9-0.3-1.7-0.7-2-1.6
		c0.2-1.3,1.2-1.8,2.3-2.4c2.4-0.8,4.6-1.9,6.8-3c1.3-0.6,2.6-1.2,4.1-1.2c1,0.2,2,0.3,2,1.6C568.4,164.6,563.8,164.6,561.7,168.6z"
		/>
	<path class="st3420" d="M583.7,163.7c-2,0.3-3.9,1.3-6,1c-0.4-0.9-2.7-1.8-0.2-2.8c2-0.7,4-1.5,6.2-1.2
		C584.8,161.7,583.3,162.7,583.7,163.7z"/>
	<path class="st3421" d="M589.6,153.6c1,0.1,2.1-0.2,2.9,0.7c-4,1.4-7.9,2.8-11.9,4.2c-0.7-0.2-1.2-0.6-1.2-1.5
		c0.7-1.4,2.2-1.7,3.3-2.5C585,153.6,587.1,151.8,589.6,153.6z"/>
	<path class="st3422" d="M579.6,157.6c0.3,0.3,0.7,0.6,1,1c-3,1.3-6,2.5-9,3.8c-0.7-0.2-1.3-0.5-2-0.7
		C572,157.9,575.4,156.9,579.6,157.6z"/>
	<path class="st3423" d="M645.5,143.7c2,1.8,5.1-0.1,7.1,1.9c-0.3,0.4-0.7,0.4-1,0c-6.7-0.3-13.3-0.6-20-0.9
		c-2.6,1.5-5.5,0.6-8.2,0.8c-0.8,0-1.7,0-2.5-0.1c-0.6-0.1-1-0.4-1.4-0.9c6.1-2,12.4-0.7,18.6-1C640.5,143.5,643,143.7,645.5,143.7z
		"/>
	<path class="st3424" d="M627.6,146.7c-1.3,0-2.7,0-4-0.1c2.4-2.9,5.3-1.1,8-1c1.4,0.2,3-0.5,4,1
		C632.9,146.7,630.3,146.7,627.6,146.7z"/>
	<path class="st3425" d="M631.6,145.7c-2.7,0.4-5.5-0.9-8,1c-0.6,0-1.3,0-1.9,0c-0.7-1.1,0.3-1.3,0.9-1.8c3,0,6,0,9,0
		C631.6,145.1,631.6,145.4,631.6,145.7z"/>
	<path class="st3426" d="M651.6,145.7c0.3,0,0.7,0,1,0c2,0.5,4.8-1.4,5.9,1.9C656.2,146.6,653.6,147.3,651.6,145.7z"/>
	<path class="st3427" d="M597.5,148.8c0.7-0.4,1.5-0.7,2.2-1.1c1.9-0.7,4.1,0.3,5.9-0.8c0.5-0.1,0.9-0.1,1.4-0.1c1,0,2,0.1,2.6,1
		c-1.4,0.6-2.7,1.3-4.1,1.9c-3,0.4-5.9,0.7-8.9,1.1c-1,0.7-1.8,0.6-2.2-0.7C595,148.4,596.6,149.5,597.5,148.8z"/>
	<path class="st3424" d="M609.7,147.7c-1,0-2.1,0-3.1-0.1c0.8-3,4-0.4,5.3-2.3c2.9-0.5,5.8,0.5,8.7-0.6c0.7,0,1.4,0.1,2.1,0.1
		c-0.3,0.6-0.6,1.2-0.9,1.8C617.7,147,613.7,147.3,609.7,147.7z"/>
	<path class="st3428" d="M718.4,181.8c0.4-0.1,0.7-0.2,1.1-0.2c0.6,2.2,3.1,2.9,4.1,4.8c-1.4,0.1-2.7,0.1-4.1,0.2
		c-0.4-1-1.1-1.6-2-2C717.8,183.7,718.1,182.8,718.4,181.8z"/>
	<path class="st3429" d="M594.7,149.8c0.7,0.3,1.3,0.6,2,0.9c-1.9,2-5,1.2-7,2.9c-2.5,0-4.7,1.2-6.9,2c-1.9,0.5-3.8,1.1-5.6,1.8
		c-1.2,0.4-2.7,1-2.8-1.3c0.3-1.3,1.6-1,2.4-1.6l0,0c1.7-1.6,3.9-1.6,6-2c0.9-1.6,2.8-1,4-1.9C589.4,151.4,591.8,148.3,594.7,149.8z
		"/>
	<path class="st3430" d="M704.5,169.7c0.4,0,0.7,0,1.1,0c2.3,0.9,3,4.1,5.9,4c1.1,3,4.7,4,5.8,7.1c-1.6-0.7-3.2-1.3-4.8-2
		c-0.7-0.7-1.4-1.4-2-2c-1.3-1.8-2.5-3.7-5-4C705.2,171.6,704.8,170.6,704.5,169.7z"/>
	<path class="st3431" d="M525.7,540.2c3-2.9,7.3-3,11-4.5c3.9-1.5,7.3-3.8,11-5.5c1.8-0.8,3.5-1.3,5,0.6c0.5,1.2-0.2,1.9-1.1,2.3
		c-4.3,1.6-7.6,4.8-11.7,6.8c-2.4,1.1-4.8,2.1-7.2,3.1c-1.9,0.8-3.5,0.2-5-0.8c-0.7-0.3-1.3-0.7-2-1
		C525.7,540.9,525.7,540.6,525.7,540.2z"/>
	<path class="st3432" d="M527.7,542.2c4.5,1.2,7.8-2.8,12-3c4.1-0.9,6.9-5.3,11.6-4.4c1.6,1.5,0.6,2.9-0.7,3.6
		c-5.8,2.9-11.7,5.9-18,7.8c-0.3,0-0.6,0-0.9,0c-1.3-1-2.6-2-4-3C527.7,542.9,527.7,542.5,527.7,542.2z"/>
	<path class="st3433" d="M555.7,525.2c-5.5,0.6-10.7,2.4-16,4c-0.2-2.5,0.3-3.9,3.4-4.1c3.6-0.2,7.4-0.7,10-3.8
		c1.3-1.6,3.3,0,4.6-1.1c1.2-1.2,2.7-1.3,4.2-1.3c4.8,1.7,10.1-0.2,14.8,2.1c0.1,0.3,0.2,0.9,0,0.9
		C569.9,524.4,563.2,527.7,555.7,525.2z"/>
	<path class="st3434" d="M561.7,520.2c-1.3,0-2.7,0-4,0c-1.8-1.8-4-0.7-6-1c-4.2-1.6-8.5-2.6-13-3c-2.5-2.6-6.6-2.2-9-4.9
		c-0.4-0.5-0.3-0.9,0.1-1.3c2.4-0.5,4.4,0.8,6.6,1.5c6.9,2.7,13.8,5.1,21.2,5.6c1.5,0.1,2.9,0.6,4,1.7
		C562,519.2,562,519.7,561.7,520.2z"/>
	<path class="st3435" d="M555.7,525.2c7.2,0,14-2.3,21-3.9c3.3-2.3,6.8-1.6,10.4-0.7c0.5,0.3,0.8,0.8,0.9,1.4
		c-2.6,3.4-6.9,3.7-10.2,4c-6.2,0.4-11.8,1.9-17.3,4.4c-2.6-0.4-5,1.6-7.7,0.8c-1.5-1-3.2-1.1-5-1c1.5-1.7,3-3.4,5.7-3
		C554.7,527.4,555.6,526.6,555.7,525.2z"/>
	<path class="st3170" d="M475.8,569.2c0.4-3.8,3.7-0.4,5-2c0,0.3,0,0.6,0,0.9c0,0.3,0,0.7,0,1C479.1,570.6,477.4,570.6,475.8,569.2z
		"/>
	<path class="st3436" d="M743.5,694c6.8-3,12.8-7.7,20-10c0,0.4,0,0.8,0,1.1c-1.4,0.9-2.7,1.9-4.1,2.8c-8.1,4.3-15.8,9.1-24,13
		c-0.6,0.3-1.3,0.7-1.9,1c-1.2,0.7-2.2,2.1-3.9,1.3c-0.3-0.4-0.4-0.9-0.4-1.4C733.1,697.8,738.8,696.9,743.5,694z"/>
	<path class="st3437" d="M735.4,701c7.7-5,15.6-9.5,24-13c0,0.4,0,0.7,0,1.1c-6.5,3.6-12.9,7.1-19.4,10.7
		C738.6,700.5,737.2,701.3,735.4,701z"/>
	<path class="st3438" d="M729.5,703c1.3-0.3,2.7-0.7,4-1c-4.1,4.5-10.2,5.7-15,9.1c-2,1-4,1.9-6,2.9c-0.4,0.4-0.8,0.4-1.2-0.1
		c-0.1-0.7,0-1.5,0.5-1.8C717.6,708.8,722.8,704.6,729.5,703z"/>
	<path class="st3439" d="M562.7,815.9c-2.6,0.3-4.6,2.1-7,3c-1-1.4-3.4-0.9-4-2.8c3.4-3.4,8.3-4.3,11.9-7.2l0,0c4.9-2.9,10.2-5,15-8
		l0,0c4.6-2.1,8.6-5.6,13.9-6.2c1,2.9-1.7,3.4-3.1,4.6c-1.6,1.3-4,1.5-4.7,3.9c-1.9,3.3-6,3.1-8.6,5.3c-0.4,0.1-0.9,0.1-1.3,0
		c-3.4,1-6.4,2.8-9,5C565.1,814.6,563.7,815.1,562.7,815.9L562.7,815.9z"/>
	<path class="st3440" d="M563.6,808.8c-3.1,3.8-8,4.7-11.9,7.2c-1,0.3-2,0.6-3,0.9C553.2,813.4,558.3,810.8,563.6,808.8z"/>
	<path class="st3134" d="M633.6,768c-3.3,2.1-6.2,4.7-10,6c0.1-0.3,0.2-0.7,0.4-0.9c1.7-1.3-2.9-5.1,2.4-5.4
		C628.9,767.5,631.2,767.3,633.6,768z"/>
	<path class="st3441" d="M711.2,713.9c0.4,0.1,0.9,0.1,1.3,0.2c-9.3,6-19,11.3-29,16c-0.4-0.4-0.5-0.8-0.5-1.4
		c0.7-2.2,2.8-2.7,4.5-3.6c6-3.4,12-6.9,18.2-10.1C707.4,714.2,709.1,712.8,711.2,713.9z"/>
	<path class="st3442" d="M683.6,729.1c0,0.3,0,0.7,0,1c-7.2,3.8-13.8,8.9-21.8,11c-0.1-0.2-0.3-0.4-0.4-0.6c0.9-2.4,3.5-2.7,5.1-4.3
		c2-1.1,4.1-2,6.1-3.2C676.3,732,679.1,728.4,683.6,729.1z"/>
	<path class="st3443" d="M641.7,752c-0.5-0.1-0.9-0.1-1.4-0.2c-0.1-1.4,0.9-2.1,1.9-2.7c5.3-3.3,11-5.9,16.8-8.6
		c0.8-0.4,1.6-0.3,2.4,0.2c0.1,0.1,0.3,0.2,0.4,0.4C655.5,745.5,648.8,749.2,641.7,752z"/>
	<path class="st3134" d="M566.7,803.9c-1.3,0.7-2.7,1.3-4,2C562.8,802.7,565.3,804.4,566.7,803.9z"/>
	<path class="st3444" d="M555.7,789.9c0-0.3,0-0.6,0-1c8.9-6.1,18.7-10.8,28.1-15.9c12.1-6.5,24.4-12.8,36.5-19.4
		c1.3-0.7,2.5-1.6,4.2-1.4c1.7,0.5,3.1,1.4,4.3,2.7c-3.5,4.9-9.1,6.6-14,9.2c-0.7,0.4-1.7,0.2-2,1.1c-0.7,0.5-1.4,1.1-2.1,1.6
		c-4.2,0.5-7.1,3.7-10.9,5c-5.6,0-8.4,5.2-13,7.1c-5.9,2.4-11.3,5.8-17,8.8c-1.7,0.3-3.3,0.7-5,1c-1.9,0.6-3.5,1.8-5,3
		c-2.1,1.7-4.3,3.2-7,4c-1,0.8-2.3,1.1-2.9-0.2c-0.5-1,0.7-1.6,1.4-2.3C552.6,792.2,554.9,792,555.7,789.9z"/>
	<path class="st3445" d="M555.7,789.9c0.6,3.3-3.1,2.7-4.1,4.5c-0.3,0.4-1.6,0.4-0.8,1.3c0.3,0.4,1.2,0.2,1.9,0.3
		c-3.8,2-7.5,4.1-11.3,5.8c-3.2,1.4-2.1-1.6-2.7-2.9C543.6,794.5,550.3,793.4,555.7,789.9z"/>
	<path class="st3445" d="M559.7,791.9c1-2,2.6-3.2,5-3C563.2,790.2,561.8,791.7,559.7,791.9z"/>
	<path class="st3446" d="M661.4,740.8c-7.1,3.4-14.4,6.6-21,11.1c-1.6,0.7-3.2,1.3-4.8,2c-0.6-0.4-1.3-0.6-1.3-1.5
		c0.8-1.9,2.8-2.3,4.3-3.4c6.7-3.2,12.8-7.7,19.6-10.7c3,0.6,5.4-3.3,8.6-1.2C665.5,739.1,662.9,739.2,661.4,740.8z"/>
	<path class="st3447" d="M634.6,753c0.3,0.3,0.7,0.6,1,0.9c-5.6,3.1-11.3,6.2-16.8,9.4c-1.9,1.1-3.9,1.9-6,2
		c5.3-3.4,10.6-6.9,15.9-10.3c0.5-2,2-2.8,3.9-2.9C633.4,752.1,634,752.4,634.6,753z"/>
	<path class="st3448" d="M539.7,792.9c3.3-0.3,5.1-3.2,7.7-4.5c4.9-2.6,9.1-6.4,14.3-8.5c4.6-5.2,10.5-8.5,16.4-11.8
		c1.8-0.5,3.5-0.6,5.2,0.3c1.4,1.7-0.3,2.5-1.3,3c-9,4.8-16.8,11.7-26.4,15.5c-5.3,3-10.7,6-16,9
		C538.2,794.9,538.4,793.9,539.7,792.9z"/>
	<path class="st3449" d="M624.6,753c-8.8,4.7-17.7,9.3-26.5,14c-13.5,7.2-26.9,14.5-40.4,21.7c-0.5,0.3-1.3,0.2-2,0.2
		c0.3-0.7,0.7-1.3,1-2c7.3-5,15-9.2,22.6-13.6c5.5-3.2,11.4-5.9,16.9-9.3c19.6-10.8,39.3-21.5,59.2-31.7c1.5-0.4,3-0.6,4.2,0.7
		c0.2,0.5,0.2,1,0,1.4c-1.1,1.6-2.7,2.5-4.3,3.3c-9,4.3-17.6,9.4-26.5,13.9C627.5,752.4,626,752.5,624.6,753z"/>
	<path class="st3450" d="M596.6,765c-13.3,7.3-26.6,14.7-39.9,22c-0.3,0-0.7,0-1,0c0.2-2.6,2.8-2.6,4.3-3.4
		c8.2-4.3,15.5-10.2,23.6-14.5c2.2-3.4,6.1-3.4,9.2-5c1.3-0.5,2.4-1.8,4-1C597.3,763.7,597.2,764.3,596.6,765z"/>
	<path class="st2924" d="M442.8,304.5c0,1.7,0,3.3,0,5C441.4,307.8,441.4,306.2,442.8,304.5z"/>
	<path class="st3451" d="M450.7,243.5c0.3-3.2,1.6-6.1,3.1-8.9c1,0.4,2.5,1.1,2.4-0.7c0-2.2,2.1-2.7,2.5-4.3
		c2.9,0.4,2.2,2.9,2.7,4.6c-0.2,2.3-1.7,4-3,5.7c-2.8,1.1-3.3,5.9-7.6,4.6C450.9,244.2,450.8,243.9,450.7,243.5z"/>
	<path class="st3452" d="M450.9,244.5c2.3-1.7,4.6-3.3,6.9-5c0.6,0.5,0.8,1.2,0.8,1.9c-0.2,1.7-1.1,3.1-2.1,4.4
		c-2.5,2.2-3.7,5.6-6.3,7.8c-0.4,0.2-0.9,0.1-1.3-0.1C448.6,250.4,450.9,247.7,450.9,244.5z"/>
	<path class="st3453" d="M448.9,253.6c0.3,0,0.6-0.1,0.9-0.1c1.5,2.3-0.8,4.2-0.5,6.4c-0.4,1.2-1,2.1-2.5,1.9
		c-0.1-1.1-0.2-2.1-0.3-3.2C446.4,256.4,448.5,255.4,448.9,253.6z"/>
	<path class="st3454" d="M446.9,266.5c0-1.3,0.1-2.6,0.1-3.8c6-2.4,8.4-7.6,10.7-13c0.7-1.3,1.6,1.5,2.9-0.4c1.3-2,2.2-0.1,2.7,1.4
		c-2,5.4,2.3,10,2.2,15.2c0,1.5,0.6,3.2,0.5,4.8c-0.2,2-0.8,3.6-3.1,3.8c-3.3,0.2-5.6-1.5-7.2-4.5c1,4.8-1.1,8.5-3.2,12.3
		c-0.4,0.7-0.8,1.5-1.2,2.2c-0.8,1.2-1.7,2.8-3.5,2.2c-1.9-0.6-2.2-2.4-1.9-4.1c0.7-4.1-0.1-8.3,1.5-12.2
		C447.9,268.9,448,267.6,446.9,266.5z"/>
	<path class="st3455" d="M446.9,266.5c4,0.3,1.9,3.5,1.4,4.4c-1.9,3.6-1.5,7.3-1.5,11.1c0,1.4-0.5,3.4,1.4,3.7
		c1.6,0.3,1.6-1.8,2.4-2.7c0.4-0.5,0.8-1,1.2-1.5c3.6,3.4,5.9-1.9,9.2-1.3c2.4,1.6,4.6,3.2,5.8,6c1,1.8,1.7,3.6,1.6,5.7
		c-0.5,2.6-0.8,5.2-0.6,7.9c0.1,1.2-0.1,2.6-1.5,2.8c-1.1,0.2-1.5-1.1-1.9-2c-0.7-1.7-1.5-3.3-1.1-5.2c0.4-0.8,1.3-1.4,1.5-2.4
		c-0.3,0.7-0.6,1.3-1.1,1.7c-0.1,0.1-0.3,0.2-0.4,0.3c-2.5,1.8-5.7-1.8-6.9-0.3c-2,2.5-4,4.3-6.9,5c-2,0.5-2.5,1.9-3.2,3.4
		c-0.2,0.2-0.3,0.3-0.5,0.5c-0.3,0-0.6,0-0.9,0C444.6,291.1,444.8,278.8,446.9,266.5z"/>
	<path class="st3456" d="M457.9,249.7c0.2,4.2-5.3,12.5-9.4,14c-0.3,0.1-1-0.7-1.5-1c-0.1-0.3-0.2-0.6-0.1-0.9
		c0.7-0.7,1.3-1.4,2-2.2c0.7-2.7,2.4-4.6,4.3-6.5c1.5-1.2,2-3.5,4.2-3.8C457.5,249.4,457.7,249.6,457.9,249.7z"/>
	<path class="st3457" d="M446.7,358.5c0.5,0.9,1,1.8,1.5,2.8c-2-0.7-4.2-1.1-4.2-3.9C444.8,357.8,445.7,358.2,446.7,358.5z"/>
	<path class="st3458" d="M427.8,386.4c-1.3-4.7-4.7-7.8-7.9-11c6.9,1.6,9,7.7,12,13c-0.3,0.3-0.7,0.7-1,1
		C428.8,389.5,427.9,388.4,427.8,386.4z"/>
	<path class="st3459" d="M408.9,367.4c0-0.3,0-0.6,0.1-0.9c3.8,1.6,7.3,3.6,9.9,6.9c-1,0-2,0-3,0
		C414.3,370.5,410.1,370.7,408.9,367.4z"/>
	<path class="st3460" d="M450.7,359.6c-1-0.8-1.9-1.5-2.9-2.3c-0.1-0.3-0.2-0.6-0.2-0.8c3.8,0.2,4.4-2.1,4.2-5.1
		c0.6-0.5,1.2-0.6,1.9-0.4c1.5,0.7,2.6,1.8,3.9,2.8c0.3,0.3,0.5,0.7,0.7,1.2c0.1,2.1-1.4,2.7-3.1,3.1
		C453.7,358.4,452.1,358.7,450.7,359.6z"/>
	<path class="st3461" d="M449.8,344.5c1.7,0.3,3.4,0.6,5.1,0.9c1.3-1,2.3-0.3,3.1,0.7c0.2,0.5,0.2,0.9,0,1.4c-0.9,0.9-2,1.2-3.2,1.5
		c-0.7,0.1-1.4-0.1-1.9-0.7C451.8,347.1,449.7,346.7,449.8,344.5z"/>
	<path class="st3462" d="M452.9,348.3c0.6,0,1.3,0,1.9,0c0.7-0.4,1.1,0,1.3,0.6c0,0.7-0.3,1.2-0.7,1.7c-0.4,0.4-1,0.8-1.6,0.8
		c-0.7,0-1.3,0-1.9,0C451.9,350.3,451.7,349.1,452.9,348.3z"/>
	<path class="st3463" d="M446.3,315.7c0-0.8,0-1.5,0-2.3c1.4-4.7,2.5-5.3,6.9-3.4c3.3,1.5,6.7,2.7,10.1,3.9c2.1,0.7,4.5,1.4,4.8,4.3
		c-1.8,1.6-4.2,0.6-6.3,1.3c-2.5,0.3-5.2-0.2-7.6,1.1c-2.4,0.5-3.9-1.4-5.8-2.2C447.3,317.8,446.6,316.9,446.3,315.7z"/>
	<path class="st3464" d="M467.8,318.5c-4.9-4.9-11.9-5.1-17.7-8c-3.2-1.6-1.6,3.2-3.9,3c-0.1-2-0.2-4-0.3-6c1.7-3,3.9-1.5,6-0.7
		c6.7,2.4,12.6,6.4,18.9,9.6c2.7,1.3,4.5,3.6,6.6,5.6c1.1,1.3,2.5,2.6,1.6,4.6c-0.4,0.3-0.8,0.6-1.3,0.7
		C473.2,325.7,470.9,321.7,467.8,318.5z"/>
	<path class="st3465" d="M445.9,304.6c0-0.4-0.1-0.7-0.1-1.1c0,0,0,0,0,0c3.9-3.5,7.3,0.3,10.9,0.9c4.3,1.6,8.7,3.1,12.6,5.4
		c0.8,1.8,1.6,3.7,0.6,5.7c-1.1,0.6-2-0.1-2.7-0.6C460.5,310.6,453.3,307.4,445.9,304.6z"/>
	<path class="st3466" d="M445.9,304.6c9.1,1.1,16.1,7,23.9,10.9c0.9,0.3,1.4,0.9,1.1,1.9c-6.8-2.6-12.5-7.3-19.4-9.7
		c-1.9-0.7-3.6-1.7-5.6-0.3C445.9,306.5,445.9,305.5,445.9,304.6z"/>
	<path class="st3467" d="M446.3,315.7c0.8,0.6,1.7,1.2,2.5,1.9c1,2.9,5.1,3.2,5.2,6.8c-1.5,1.8-3.8,0.7-5.6,1.6
		c-0.4,0.1-0.9,0.2-1.3,0.2c-0.5-0.1-0.8-0.3-1.1-0.6C445.8,322.2,445.6,318.9,446.3,315.7z"/>
	<path class="st3468" d="M446.1,325.4c0.3,0.1,0.5,0.1,0.8,0.1c1.3,2.5,2.5,5.1,5.4,6.4c2.1,3,5.9,4.9,5.9,9.3
		c-1.6,1.2-2.6-0.3-3.9-0.8c-3.3-2.6-6-5.6-8.3-9.1c-0.1-0.7-0.2-1.4-0.2-2.1C445.8,328,446,326.7,446.1,325.4z"/>
	<path class="st3469" d="M454.7,339.5c1,0.6,2,1.2,3.1,1.9c1,0.5,1.6,1.2,1,2.4c-1.2,1.1-2.5,1.6-4.1,0.7c-3.1-1.2-4.8-4.9-8.5-5.1
		c-0.2-0.3-0.2-0.6-0.2-1C449.3,335.7,451.7,339.9,454.7,339.5z"/>
	<path class="st3470" d="M454.7,344.5c1.4-0.4,2.8-0.7,4.2-1.1c2.8,0.1,3.9,1.5,3,4.3c-1.8,1-3.1,0.5-4.1-1.2c-1-0.3-2-0.7-3-1
		C454.8,345.1,454.8,344.8,454.7,344.5z"/>
	<path class="st3471" d="M454.7,339.5c-3.3,2.3-5.6-2.4-8.7-1.1c0-2.3,0-4.7-0.1-7C449.7,333.2,451.4,337.3,454.7,339.5z"/>
	<path class="st3472" d="M463.8,721c-2.5-0.1-4.6-0.9-6.6-2.5c-1.4-1.1-3-3.3-5.4-1.5c-5.4-1.5-9.4-4.8-11.8-9.9
		c-0.8-1.8-2.2-2.9-3.3-4.2c-1.7-1.9-3.1-3.8-2.9-6.6c0.3-4.5-3.6-6.9-5.6-10.2c-3.6-5.9-5.7-12.3-7.4-19c-0.9-3.7-1.3-7.8-3.3-10.8
		c-2.5-3.8-3.1-8.9-2.7-11.6c0.9-6.1-1.5-11.6-0.7-17.3c0.4-2.6,2.7-4.9,3.6-7.6c3-8.4,7.7-15.7,14.4-21.2
		c5.9-4.8,11.2-11,19.3-12.3c2.8-0.5,2.3-2.3,2.4-4.1c0.6-0.7,1.3-1,2.2-1.2c2.5-0.1,4.9-0.5,7.3,0.5c2.1,3.1-0.4,4.5-2.5,5
		c-6.2,1.4-10.5,6.3-16.3,8.4c-2.3,0.8-3.2,3.3-4.9,4.9c-5,5.7-9.7,11.4-13.7,18c-5.7,9.5-3.3,19.2-4.4,28.8
		c-0.7,7,4.4,12.1,5.5,18.6c0.5,2.9,1.5,5.7,1.6,8.8c0.1,2,0.3,3.9,2.8,4.6c1.8,0.5,2.1,2.4,2.7,3.9c1.9,4.7,3.2,9.8,8.7,11.8
		c0.5,0.2,0.9,0.5,1.1,1c4.1,7.9,4.1,7.9,11.8,12.9c0.3,0.3,0.6,0.7,0.8,1.1c1.3,2.9,4,4,6.7,5.2c0.1,0.2,0.3,0.3,0.4,0.5
		C462.2,717,463.9,719,463.8,721z"/>
	<path class="st3473" d="M455.8,582.2c-0.7,0-1.3,0-2,0c-7.8,0.5-13.6,5-19,10.2c-2,1.9-3.6,4-6.4,5.2c-3.9,1.7-5.1,6.2-7.6,9.4
		c-1.2,1.5-1.9,3-4,3.2c-1.2-1.4-0.4-2.8,0-4.3c3.9-6.2,7.2-12.8,13.2-17.5c10.9-6.4,21.8-12.8,34.4-15.7c4.7-1.1,9.5-0.5,14.2-2.1
		c1.7-0.6,3.5,0.7,5.1,1.6c1.5,0.8,3.3,1.2,4,3c-0.8,0.8-1.8,1-2.9,0.9c-2.8-1-5.5-1.1-8,0.9c-1.1,0.8-2.3,1.3-3.7,1.5
		C467.3,579.2,461.5,580.4,455.8,582.2z"/>
	<path class="st3474" d="M417.9,606.1c-0.3,1.3-0.7,2.7-1,4c-1.8,9.2-8.3,17.3-6.9,27.3c0.6,4.6,2.2,9.1,2.2,13.9
		c0,2.6-0.6,5.2-0.3,7.7c-0.7-0.3-1.3-0.7-2-1c-3.2-5.8-3.8-12.3-4.1-18.5c-0.4-8.2,1.3-16.5,3.2-24.5
		C410,610.8,413,607.2,417.9,606.1z"/>
	<path class="st3475" d="M417.9,606.1c-8.2,5-8.9,13.7-9.8,21.9c-0.5,5.2-2.8,10.7-0.9,15.7c1.8,4.8,1.4,9.7,2.7,14.4
		c-1.5,5.1,2.1,9.3,2.8,14c1.5,9.2,6.2,17.4,9.8,25.9c1.3,3,3.1,5.8,5.2,8.3c1.8,2.1,2.2,4.3,2.1,6.8c-1.8-4.9-7-7.3-9.1-12.1
		c-0.5-1.3-2.1-2.3-0.6-3.9c0.7-0.7,0.5-2-0.2-2.1c-3.5-0.5-3.7-4.4-6.1-5.9c-3-8.6-6.8-16.9-7.2-26.3c-0.2-3.1-2.1-6.4-3.2-9.6
		c-2.3-7-1.3-14.1-0.7-20.7c0.4-4.9,1.4-10.4,2.8-15.6c1.5-5.5,4.1-10.1,6.7-14.6c2.8-4.8,7.2-8.9,12.6-11.2c1.7-0.6,2.4-3.6,5-2
		C425.8,594.8,421.8,600.5,417.9,606.1z"/>
	<path class="st3476" d="M429.8,589.2c-1.7,0.7-3.3,1.3-5,2c1.5-4.7,6.2-6.1,9.6-8.6c4-3,8.3-5.6,13.8-5.5c2.3,0,3.7-2.5,5.6-3.8
		c0.2-0.2,0.3-0.3,0.5-0.5c3.6-1.8,7.5-2.1,11.3-2.9c0.6-0.2,1.3-0.3,2-0.4c1.4,0.2,2.8-1.4,4.3-0.2l0,0c1,0.8,2-0.4,3,0
		c0.3,0,0.7,0,1,0c1.7,0,3.3,0,5,0c1.3,0.7,2.3,1.6,3,3c-14.1-0.5-27.5,2.8-39.5,9.7c-3.8,2.2-9.3,2.7-11.7,7.5
		C432.5,589.7,430.8,589.3,429.8,589.2z"/>
	<path class="st3166" d="M467.8,570.2c-0.7,0-1.3,0-2,0c-4,1-8.4,0.2-12,3c-3.1-1-5.7-1-8.7,1.2c-1.9,1.5-4.7,3-7.7,2.9
		c-0.9,0-2.2,0.4-2.7,1.1c-3.2,4.6-8.5,3.7-13,4.8c0.6-2.3,2.2-3.3,4.4-4.1c3.1-1.2,6.8-1.3,8.7-4.8c0.2-0.4,0.8-0.7,1.3-0.7
		c7-0.1,13.2-4,20.2-4.4c4.8-0.3,9.4-2.4,14.4-2C470.9,569.3,468.7,569.1,467.8,570.2z"/>
	<path class="st3477" d="M593.7,765c-3.3,1.3-6.7,2.7-10,4c-2,0-4,0-6,0c-0.5-7.4-3.9-10.5-11.5-10.7c-2.2-0.1-4.6,0.6-6.5-1.3
		c0.2-2.4-1.1-3.2-3.3-3c-3.9,0.3-7.3-1.5-11-2c-1.7-0.3-2.6-1.3-2.7-3c0.4-4.9,3.7-6.7,8.1-4.3c1.7,0.9,2.9,2.9,5.3,2.6
		c0.9-1.5-2.7-1.9-0.9-3.5c1.5-1.3,3.4-1.5,5.2-1.1c5.4,1.3,10.9,0.8,16.4,1c2.4,0.1,4.5-0.2,6.3-2.2c2-2.2,4.8-1.9,7.5-1.3
		c1.6,0.6,2.1,2.1,2.4,3.6c0.4,2.5,1.9,1.7,3.3,1.1c1.6-0.7,2.3-2.4,3.5-3.5c1.9-1.8,4.2-1.9,6.5-1.2c2.4,0.7,2.4,2.9,2.6,4.9
		c0.4,4-0.8,7.5-2.6,11c-0.3,0.4-0.7,0.6-1.2,0.9C601.2,759.3,596.6,760.9,593.7,765z"/>
	<path class="st3478" d="M590.7,741c-2.8,0.4-6.6-1.5-7.6,3.3c-0.1,0.3-0.8,0.6-1.3,0.6c-6.5,0-13,0.4-19.4-0.4
		c-2.3-0.3-4.4-0.2-7.7-0.5c2.1,1.6,4.8,0.4,4.8,2.9c0,0.9-0.3,1.8-1.2,1.9c-2.2,0-4.5,1.2-6.5-1c-3.8-4.3-6.7-3.9-9.1,1.2
		c-3.1-0.1-5.5,0-8.2-3.1c-1.7-1.9-7.1-2.9-11.2-2.9c-1.8,0-3.7,0.1-4.6-2c6.7,1.3,12.9-3.7,19.6-1.6c2.1,0.6,3.8-0.1,5.4-1.4
		c7.4-3,15.2-2.6,22.9-2.1c5.9,1.5,11.8,0.2,17.7,0.5c2,0.1,4-0.2,5.9,0.6C592.4,738,592.9,739.4,590.7,741z"/>
	<path class="st3479" d="M559.7,757c2.3,0,4.7,0.4,6.9-0.1c5.7-1.3,8.3,2.9,11.6,5.9c2.5,2.3,0.2,4.1-0.5,6.1c-5.1,4-10.3,7.8-16,11
		c1-4.7-3.6-8.3-2.7-13.3c0.1-0.8-3.2-7-8.3-5.7C552.1,756,556.1,757,559.7,757z"/>
	<path class="st2939" d="M537.6,761.2c0.3,0.3,0.7,0.7,0.6,0.7c-0.3,0.3-0.6,0.8-0.9,0.9c-0.5,0.1-1.1-0.1-0.8-0.8
		C536.7,761.7,537.2,761.5,537.6,761.2z"/>
	<path class="st3480" d="M566.7,737c-7.7-0.7-15.3,1.2-23,1c-8.4-0.3-16.8-1.3-25.1-0.8c-4.5,0.3-8.6-3.8-13.1-0.7
		c-0.1,0.1-1-0.4-1.1-0.8c-1.4-6.1-5.9-3.4-9.5-3.4c-2.1,0-4.6,1.3-6.1-1.4c4.8,0,10.8,1.4,13.9-2.6c3.4-4.4,7.1-3.4,11.1-3.4
		c3.2-2.9,7.1-2.7,10.8-1.9c4.6,0.9,9.3,2.1,13.5,4.5c1.1,0.6,2,1,3.2,0.4c0.8-0.4,1.9-0.7,2.3-0.2c4.1,5.3,8.7,1.1,13.1,0.7
		c2.6,0,5.1,1.2,7.7,1.4c1.5,0.1,3.2,0.7,3.2,2.4c0,2-2.1,1.5-3.5,2.1c1.9,0.8,4.4-0.3,5.8,1.9C569.2,738.1,567.9,737.6,566.7,737z"
		/>
	<path class="st3481" d="M556.7,729c-0.9,2.1-2.8,2-4.6,2c-1.8,0-3.5,0.1-5.2,1.1c-1.6,0.9-4.8,2.9-2.8-2.1c0.6-1.4-1-2.3-2.4-0.8
		c-0.9,1-2,2.4-3.3,1.3c-7.3-6.2-15.6-7.6-24.7-5.5c0-1.6-0.3-3.2-2.3-2.9c-7.1,0.9-13.7-2.8-20.8-2.3c-2,0.2-1.7-1.5-1.8-2.8
		c3.4-2.6,7.3-1.2,11-1.6c2-0.2,4,0.2,5.9,0c12.5,0.1,25.1-0.6,37.6,0.4c5.4,3.3,11.5,4.3,17.8,4.6c2.7,0.1,5.1,0.9,7,3
		c0.1,2.9-2.1,2.9-4,3c-3.1,0.1-6.1,0.1-9.3,0C554.5,728.3,556.3,728,556.7,729z"/>
	<path class="st3482" d="M505.8,717c-5.7,0-11.3,0-17,0c-4.8,0-9.6-0.2-14.4,0.1c-3.8,0.2-6.6-3.9-10.5-2.1h0c-2.2-2.3-5.8-2.5-8-5
		c0-0.3,0-0.7,0-1c1.2-1.2,2.3-2.3,1.3-4.2c-0.6-1.1-1.3-2.5,0.4-3.4c1.2-0.3,2.4,0,3.2,0.8c4.3,4.3,9.5,5,15.2,4.4
		c7.7,1,15.6,0.5,23.1,2.9C504.5,710.3,505.1,710.9,505.8,717z"/>
	<path class="st3483" d="M678.5,433.3c-1.1-0.1-1.8-0.6-2.3-1.6c-0.6-1.2-1.2-2.5-2.9-2.3c-2.4,0.3-1,1.9-1.1,3
		c-0.4,3.4-4,3-5.9,4.5c-1.3,1-3,0.2-4.2-1.4c-2.8-3.6-7.1-2.6-10.2-1.3c-3.6,1.5-4.1,0.1-4.3-2.6c-0.2-3.3-0.3-6.5,3.9-7.5
		c0.7-0.2,1.6-0.9,1.7-1.6c1.5-6.2,7.5-10.4,8.3-16.7c1-7.9,8.7-11.7,10.3-19.2c0.6-3,7.8-3.9,10.3-1.7c1.4,1.3,3,0.8,5.1,0.8
		c-3.1,4-2.7,6.2,2.3,7.7c5.6,1.7,6.6,8.6,2.2,12.4c-2.6,2.2-4,5-4.3,8.4c0.2,0.3,0.3,0.5,0.3,0.8c0,0.3-0.1,0.4-0.1,0.4
		c-3.2,2.5-4.7,5.9-5.1,9.8c-0.1,0.4-0.3,0.8-0.5,1.2c-0.7,1.3-1.3,2.6-1.9,4C679.8,431.6,679.5,432.7,678.5,433.3z"/>
	<path class="st3484" d="M656.6,396.4c-1.9,3.3-0.2,7.5-2.9,10.8c-4.8,5.8-7.4,13.1-13,18.5c-2,1.9-2.7,4.8-1.1,7.7
		c-1.9,3.4-3.9,6.5-8.2,7.1c-2.1-2.3-4.7-2.2-7.3-1.2c-11.1,4.4-21.1,1.3-30.7-4.5c-2.5-1.5-3-4.8-5.5-6.3c-4.5-4-4.8-10-5.2-15
		c-0.9-11.1-3.1-22.1-3-33.5c0-5-2.8-10,0.3-15.2c0.6-1.1,0.2-2.9-0.2-4.4c0-2.9,0.6-5.4,3.9-6.2c0.5,0.2,0.9,0.6,1.3,0.6
		c8.1,0,8.4,6.6,10.3,12c1.5,4-2.1,6.4-2.4,9.7c-3,7.9-4.8,16.3-6.3,24.5c-1.6,8.8,0.6,17.5,6.9,24.5c0.6,0.4,1.1,0.9,1.4,1.6
		c0,3.3,2.8,3.9,4.9,5c1.2,0.6,2.5,1,3.8,1.5c0.4,0.2,0.8,0.4,1.2,0.6c4.1,2.8,8.9,2.6,12.9,1.4c10.7-3.4,20-9.2,25.9-19.3
		c1.2-2.1,1.8-4.2,1.5-6.6c0-1.5,1.1-2.5,1.9-3.6c1-1.2,1.6-2.7,2.2-4.1c0.9-1.5,1.4-3.1,1.9-4.7c1.5-3.8,0.9-7.8,1.2-11.7
		c0.2-3.2-0.2-6.7,3.3-8.7c1.9-0.1,3.2,1.1,2.6,2.6C656.1,385,658.6,390.9,656.6,396.4z"/>
	<path class="st3485" d="M656.6,396.4c0.3-2.8-0.7-5.5-0.9-8.3c-0.2-2.5-0.8-5.2,1.1-7.6c1.1-1.4,1-2.8-1.2-3.1
		c-1.9-2.1-1.6-4.6-0.8-7c0.7-2,0.7-3.8,0.2-5.8c-0.6-2.3,0.4-4.2,2.8-4.3c7.5-0.3,15.1-1.7,22.4,1.1c0.7,0.5,1.2,1.2,1.5,2
		c0.4,1.3,0.9,2.7-0.1,3.9c-0.5,0.1-1.3,0.2-1.4,0.4c-1.2,6.3-6.4,5.9-10.9,6.5c-4.3,0.5-6.3,2.4-5.8,6.8c0.2,2,0.4,4.1-2.5,4.5
		c-1.5,0.2-1.5,1.7-1.4,2.8C660.1,391.7,658.4,394,656.6,396.4z"/>
	<path class="st3486" d="M687.5,415.4c0-0.3,0-0.7,0-1c3.6-4.4,8.1-6.7,13.9-5.9c0.7,0.1,1.4,0.6,2.1,0.9c0,0.3,0,0.7,0,1
		c-2.4,0.8-5.2,0-7.5,1.5c-4.5,2.8-6.6,7.2-8.6,11.8c-4.2,9.7-10.5,17.6-19.8,22.9c-5.8,3.3-11.1,1.8-16.4-1.3c-1-3.2-3-5.6-6.2-6.9
		c-0.4-0.2-0.8-0.5-1.1-0.9c-0.7-1-1.4-2-0.3-3.1c5.6,1.8,11.1,3.8,15.7,7.7c0.6,0.5,1.6,1.6,2,1c3.5-4.1,10.3-0.7,13.2-6.1
		c0.8-1.5,2.6-2.4,4-3.6c0-1.1-0.2-2.3,1-3c1.1-1.1,1.3-2.7,2-4l0,0C683.8,422.8,684.4,418.4,687.5,415.4z"/>
	<path class="st3487" d="M681.5,367.4c-0.3-1-0.7-2-1-3c0.6-0.5,1.3-0.8,2.1-0.7c0.5,0.1,0.9,0.3,1.3,0.7c1.4,2.5,1.5,5.4,2.3,8.2
		c0.4,1.5,1.2,2.9,1.6,4.4c0.1,0.4,0.3,0.9,0.4,1.3c0.4,1.2,0.5,2.2-0.6,3.1c-5.2-0.3-7.1-3-6-8
		C682.4,371.4,682.4,369.4,681.5,367.4L681.5,367.4z"/>
	<path class="st3488" d="M643.6,434.3c0.3,1,0.7,2,1,3c-1.1,1.3-2.6,1.2-4.1,1.2c-2.7,0-4.7,1.1-5.5,3.8c-0.2,0.7-0.4,1.4-0.6,2
		c-1,2.2-1.1,4.8-2.5,6.8c-0.5,0.3-1,0.3-1.4-0.1c-1.9-3.9-0.8-7.4,1.2-10.8c3.2-1.7,4.6-5.4,8-7C640.9,433.7,642.3,434,643.6,434.3
		z"/>
	<path class="st3489" d="M691.5,385.4c5.1,0.4,9.5,2.8,14,5c-1,1.5-2.9-0.2-4,1c-3.4,2.8-5.8,1.6-7.1-2.2
		C693.8,387.5,693.2,386.2,691.5,385.4z"/>
	<path class="st2943" d="M701.5,391.4c1-1.5,2.6-0.9,4-1c0.3,0,0.7,0,1,0c0.8,1.5,1.2,3.3,3,4c-2,1.9-3.6,0.9-5.1-0.6
		C703.6,392.8,703.2,391.4,701.5,391.4z"/>
	<path class="st3490" d="M687.5,381.4c0-1.3,0-2.7,0-4c0.3-0.3,0.7-0.3,1,0c0.8,2.4,3.8,3.9,3,7
		C690.2,383.4,688.9,382.4,687.5,381.4z"/>
	<path class="st3485" d="M681.6,367.4c1.5,2,1.5,4,0,6C681.5,371.4,681.6,369.4,681.6,367.4z"/>
	<path class="st3491" d="M679.6,465.3c-1.1,3.7-8.4,9.6-12.3,10c-1.3,0.1-2.7-0.3-3.7,1c-4,2.7-8.1,0.5-12.1,0
		c-4-0.5-8.1-1.8-11.8-3.8c-5.6-2.4-8-6.8-7.6-12.7c2.2-1.6,3,0.6,4.3,1.5c3.6,4.9,8.3,7.3,14.4,7.2c0.5,0.1,0.9,0.3,1.4,0.5
		c6.2,0.3,12.3,0.3,18.4-0.5c2.2-0.1,3.6-1.4,4.9-3C676.9,465.7,678.2,463.5,679.6,465.3z"/>
	<path class="st3492" d="M695.5,410.4c2.7-0.7,5.3-1.8,8,0c3.5,3,3.1,7,3,11c-1.7,9.2-7.2,16.7-11.8,24.5c-3,5.1-7.7,9-13,12
		c-0.8,0-1.4-0.4-1.7-1.2c-0.1-1.6,1.1-2.6,1.9-3.8c8.6-7.7,14.3-17.3,18.7-27.8c0.5-1.2,1.2-2.3,1.4-3.6c1.2-7-0.1-8.7-6.9-9.1
		C694.9,411.6,695,411,695.5,410.4z"/>
	<path class="st3493" d="M681.5,457.3c9.6-6.6,14.8-16.7,20.6-26.2c1.9-3.1,1.4-7.2,4.4-9.7c1,6.2-3,10.9-4.9,16.3
		c-2.4,6.8-7.8,11.4-12,16.9c-2.5,3.4-6.2,5.3-10.1,6.8C678.2,459,679.9,458.2,681.5,457.3z"/>
	<path class="st3494" d="M651.6,469.3c-0.3,0-0.7,0-1,0c-5.6-0.9-10.3-3.9-15-7c-0.7-1.3-1.7-2.3-3-3c-1.1,0.2-1.5-0.5-1.9-1.4
		c-0.5-2.3-0.9-4.6,0.9-6.6l0,0c2.3-0.1,2.4,2,3.2,3.2c1.5,2.4,3,4.8,5.6,6.2c7.5,5.4,15.7,5.3,24.1,2.7c2.4-1,4.9-1.1,7.4-1.3
		c1.3,0,2.6,0.1,3.6,1.2c-0.3,0.7-0.7,1.3-1,2c-0.3,0.4-0.8,0.8-1.3,1c-5.6,4.3-12.2,2.9-18.5,3.7
		C653.6,470.1,652.5,470.2,651.6,469.3z"/>
	<path class="st3495" d="M675.6,463.3c-1.3,0-2.7,0-4,0c-1.4-2.6-1.2-2.9,3.1-4.1c1.8-0.1,3.1-1.1,4.2-2.5c0.5-0.4,1-0.8,1.7-0.4
		c0.3,0.3,0.7,0.7,1,1c-0.6,1.4-2.1,2.3-2,4C678.2,462,676.9,462.6,675.6,463.3z"/>
	<path class="st3178" d="M673.6,465.3c0.3,0,0.7,0,1,0c0.3,0,0.7,0,1,0c0.2,2.9-1.1,4.2-4,4C669.8,466.7,671.8,466.1,673.6,465.3z"
		/>
	<path class="st3496" d="M639.6,471.3c7.8,2.8,15.6,5,24,5c0.9,1.6,3.1,0.4,4,2c-0.7,1.9-2.4,3.2-4,3.4c-6,0.7-11.8,3.6-18,1.5
		c-0.3-0.1-0.7-0.3-1-0.2c-8.1,1.1-14.8-2.1-20.8-7.2c-0.4-0.3-0.9-0.6-1.4-0.6c-6-0.1-8.6-3.2-8.4-9.2c0.1-2.6-2.1-3.1-4.2-3.2
		c-4.6-0.3-8.7-0.3-13.1-3.2c-4-2.5-10-1.7-15-1.5c-2.8,0.1-6.1,3.4-5.1,7.8c0.2,0.8,0.3,1.6,0.3,2.4c-0.5,4.3-2.5,3.3-4.8,1.4
		c-0.9-0.8-1.4-1.8-2.1-2.7c-2.3-2.7-2.4-5,0.9-6.9c0.9-0.5,1.9-1.1,1.7-2.2c-0.2-1.2-1.4-1.1-2.4-1.4c-6.5-1.9-7.2-3.2-4.8-9.4
		c0.3-0.8,0.9-1.7,0.6-2.4c-2.7-6.1-0.2-12.3-0.4-18.4c0.1,1.3,0.2,2.5,0.1,3.8c-0.1,1.5,0.1,3.4-1.9,3.4c-1.9,0-2.2-1.8-2.2-3.3
		c-0.4-7.6-0.4-15.2,1.3-22.7c0.6-2.8,1-5.5,0.8-8.4c0.5-6.6,2.1-12.9,6.7-18c2.6,2.4,1.1,5,0.1,7.3c-1.2,2.9-1.2,5.4,0.9,7.8
		c1.2,1.3,1.4,2.9,1.4,4.7c-0.1,2,0.2,4.1,1.5,5.7c2.1,2.6,3,5.5,2.8,8.8c-0.3,4.1,3,6.1,5.3,8.5c1.9,1.8,3.9,3.5,5.9,5.3
		c0.4,0.3,0.7,0.6,1,1c0.7,1.3,1.2,2.6,1.8,3.9c0.2,0.5,0.3,0.9,0.3,1.4c0.3,3.7,2.4,5.4,5.8,7c6.2,2.9,12.5,2.7,18.9,2.5
		c4.9-0.2,8.5,1.7,10.6,5.9c2.4,4.7,5.7,9.1,5.7,15C632.4,469.3,636.5,470.4,639.6,471.3z"/>
	<path class="st3497" d="M575.7,466.3c-1.9-2.2-1.3-5.5,0.4-6c3.1-0.9,3.4-7.9,8.9-3.7c2.7,2.1,6.8,0.5,10.2,0.7
		c1.9,0.1,4.1-0.4,4.6,2.5c0.2,1.5,1.6,1.5,2.8,1.5c1.8,0,3.7,0,5.5,0c7.3,0,7.5,0,7.5,7.2c0,3.8,1.6,5.2,5.1,4.8
		c4.6-0.5,7.8,3.2,10.4,5.4c2.1,1.9,3.7,2.5,6,2.5c4.4,0.1,9.5,2.1,12.8,1.3c5.9-1.4,12.7,0.3,17.8-4.3c1.9,0,4-2.3,5.6,0.5
		c1.2,2-0.4,3.2-1.6,4.5c0,0.3,0,0.7,0,1c-5.1,0.3-9.5,2-12.7,6.4c-2,2.7-5.1,2.8-8,1.4c-1.8-0.8-3.3-2.3-5.3-1.8
		c0.4,0.2-0.4-0.2-0.3-0.2c4.2,1.5,4,3.3,0,4.1c-4.4,0.9-9,0.9-13.4,0.1c-5-1-10.1-1.5-15.1-2.5c-2.2-2.6-4.9-4-8.4-4.1
		c-3.9-0.2-7.9-0.5-10.5-4.2c-0.7-1-2.1-0.8-3.2-0.8c-1,0-2,0-3-0.1c-5.8-1.8-10-5.5-10.9-11.6C580.2,467.5,578.7,466.4,575.7,466.3
		z"/>
	<path class="st3498" d="M591.7,481.3c1,0,2,0,3,0c1.7,1.9,3.4,3.8,4.8,6c2.4,3.9,6.4,3.8,10.4,2.8c0.5-0.1,1-0.1,1.5-0.1
		c1.9,0,3.7-0.1,5.2,1.3c8.6-1,16.8,3.4,25.4,2c1.6-0.3,3.3,0.4,5.1-0.7c-1.8-2.7-5.1,0-7.5-2.9c4.4-0.1,8.2-1.6,11.9,1.3
		c2.2,1.8,4.9,0.7,6.4-2.4c1.5-3,8.7-5.2,13.6-4.2c-4.1,1.4-6.4,6.1-11.1,5.5c-0.5,2.4,3.9-1,2.2,2.5c0,0,0,0,0,0
		c-6.6,1.6-12.8,4.6-18.8,7.3c-7.7,3.5-15.2,1.9-22.7,3.5c-0.7,0.2-1.4,0.2-2.1,0.1c-3.3-2.3-7.1-1.2-10.7-1.4
		c-11.6-2.8-21.4-8.7-29.7-17.2c-1.3-1.4-3.1-2.7-2.3-5.1c4.2-1.8,7.2,1.1,10.4,2.8C589,483.3,590.3,483.3,591.7,481.3z"/>
	<path class="st3499" d="M726.5,381.4c-7.6,5.2-16,6.1-24.9,5.1c-0.7-0.1-1.4-0.7-2.1-1.1c2.8-2.2,6-1.3,9.1-1.3
		c4.6,0,8.8-1.2,12.5-4c1.7-1.8,3.6-3.3,5.8-4.5c1.9-0.6,2.8-2.2,4-3.5c1.1-1.3,1.8-3.3,4.1-3.1c0.2,0.1,0.4,0.3,0.5,0.4
		C735.5,375.7,729.9,377.7,726.5,381.4z"/>
	<path class="st3499" d="M739.5,361.4c0,1.3,0,2.7,0,4c-1,1.7-1.4,4-4,4c0,0,0,0,0,0C734.8,365.8,736.4,363.2,739.5,361.4z"/>
	<path class="st3248" d="M734.5,307.5c-1.6-0.2-3-0.7-4-2C732.2,305.4,733.7,305.7,734.5,307.5z"/>
	<path class="st3500" d="M688.5,377.4l-0.5,0c0,0-0.5,0-0.5,0l0,0c-0.7-1.3-0.9-2.9-2-4c-1.2-2.9-2-5.8-2-9c-2.6-4.7-1-9.7-1.3-14.5
		c0.1-1,0.5-1.8,1.3-2.5c2.7-7.9,5.6-15.8,7.8-23.8c1-3.4,5.2-4.9,4.5-9.1c-0.1-0.8,1.3-0.7,2.1-0.3c1.5,0.6,2.8,0,3.6-1.1
		c5.2-7,13-5.8,20.2-6.5c5-0.5,9.7,0.2,12.9,4.7c0.5,0.6,0.6,1.5,0.9,2.3c-1.8,1.2-3.1-0.3-4.6-0.8c-9.6-3.4-18.8-2.7-27.4,3.1
		c-5.6,5.9-10.7,12-12.2,20.3c-4.7,11.3-7,22.7-2.2,34.6c0.5,1.4,0.8,2.9,0.9,4.4c0,0.6-0.2,1-0.5,1.4
		C689.2,376.7,688.9,377.1,688.5,377.4z"/>
	<path class="st3501" d="M689.5,376.4c0-0.3,0-0.7,0-1c0.1-1.8,1-2.8,2.9-2.7c0.8,0.2,1.3,0.6,1.6,1.4c-1.4,2.6,1.8,1.7,2.2,2.9
		c3.6,3.7,7.4,7.1,13.2,6.2c1.8-0.3,3.6-0.5,4.8-2.1c0.8-0.9,1.8-1.5,3-1.4c1.6,1.4,3.9-2.1,5.3,0.7c-3,3.5-7.1,4.7-11.5,5
		c-3.8,0.2-7.7,0-11.5,0C695.4,383.3,692.2,380.2,689.5,376.4z"/>
	<path class="st3502" d="M694.5,481.3c0.7,0,1.4,0,2-0.1c4.3,3.3,3.8,8.8,5.9,13.1c1.7,5.7,3.3,11.4,5.2,17.1c0.5,1.6,1,3.2,0.9,4.8
		c-2.1,0.4-1-1.7-1.8-2.2c0.4,0.9,0.5,1.7-0.4,2.4c-2.7-0.9-2.4-3.4-2.9-5.3c-1.8-6.9-3.9-13.7-6.5-20.4c-0.5-0.8-1-1.6-1.2-2.5
		c-0.2-0.8-0.4-1.6-0.7-2.4c-0.2-0.4-0.3-0.8-0.5-1.1C694.2,483.6,693.4,482.4,694.5,481.3L694.5,481.3z"/>
	<path class="st3503" d="M702.5,494.3c-2-4.4-3.9-8.7-5.9-13.1c1.3-1,2.6-1.9,3.9-2.9c2,1.6,4.2,3.1,1,5.6c-1.1,0.9-1.4,2.3,0,3.4
		C701.4,489.7,704.3,491.7,702.5,494.3z"/>
	<path class="st3183" d="M709.5,507.3c2.5-1.2,2.2,1.2,2.5,2.2c0.6,2.6,1.3,5.3-0.5,7.8C709.1,514.3,709.6,510.7,709.5,507.3z"/>
	<path class="st3504" d="M706.4,516.4c0.1-0.8,0.3-1.5,0.4-2.3c0.6,0.7,1.1,1.4,1.7,2.1c0.4,1.6,0.7,3.3,1.1,4.9
		c0,0.7,0.1,1.3,0.1,2c-0.1,0.9-0.2,1.9-0.7,2.7c-0.6,0.6-1.2,0.8-1.9,0.1c-1.3-2.3-0.5-5-1.2-7.4
		C705.8,517.8,705.9,517.1,706.4,516.4z"/>
	<path class="st3505" d="M708.5,522.3c0.3-0.4,0.7-0.7,1-1.1c0.3,0.1,0.6,0.1,0.9,0c0.6,0.1,1.3,0.2,1.9,0.3
		C711.6,524,710.3,524.4,708.5,522.3z"/>
	<path class="st3506" d="M733.4,522.6c-2.6,1.4-5.3,0.4-7.9,0.7c-0.8,0.6-1.5,0.5-2.2-0.2c0.6-2,2.1-1.2,3.4-1
		C728.9,522.3,731.2,522.4,733.4,522.6z"/>
	<path class="st3190" d="M726.7,522.1c-1.1,0.2-2.4-0.1-3.1,1.1c-0.1,0.7-0.5,1.1-1.1,1.3c-1.3,0-1.7-1-2.2-1.9
		c-0.1-0.4,0-0.8,0.2-1.1c1-0.1,2-0.2,3.1-0.3C724.6,521.5,725.6,521.8,726.7,522.1z"/>
	<path class="st3507" d="M720.5,521.5c0,0.3,0,0.6,0.1,0.8c0.1,2.8-2.1,2.9-4,3.2c-2.7-0.2-5.4,0.6-8-0.3c0-1,0-2,0-3
		c1.5,0.9,2.8,0.4,3.9-0.8C715.1,521.5,717.8,521.5,720.5,521.5z"/>
	<path class="st3192" d="M722.5,524.2c0.4-0.3,0.7-0.7,1.1-1c0.7,0,1.3,0,2,0.1c3.4,3.3,7.8,2.4,11.9,3c8.4,1.2,16.9-0.9,25.3,1.1
		c-5,2.1-10.4,0.9-15.5,1.1c-6.2,0.3-12.5-0.1-18.7-0.3c-0.4,0.3-0.8,0.5-1.3,0.4c-3-1.2-4.6-0.7-4.4,3c0.1,2,0.6,4.4-1.5,5.9
		c-0.6,0-1-0.2-1.3-0.7c-1.3-3.4-0.8-6.8-0.5-10.3C719.6,524.7,721.5,525,722.5,524.2z"/>
	<path class="st3508" d="M757.5,569.2c-0.7,2.5-3.4,4.1-3,7c-1.5,1.6-2.4,4.2-5.4,2.9c-1-1.3-1.4-3-2.2-4.4
		c-1.4-2.8-3.5-4.1-6.7-4.1c-4.5,0-9.1,0.7-13.2-2.7c-1.8-1.5-3.7-0.5-3.6,2.5c0.1,2.7-0.4,5.2-4.2,3.9c-0.6-1-0.7-2-0.5-3.1
		c0.8-6,0.9-12.1,3.3-17.8c2.2-1.5,2.3-3.8,2.3-6.2c0-2.7-0.3-5.3,0.4-7.9c1.3-4.7,4.4-5.7,8.1-2.8c0.8,0.6,1.7,1.2,2.2,2
		c4.8,6.6,11.2,10.2,19.5,9.9c2.3-0.1,4.1,1.1,5.7,2.7c1,1,1,2.6,2.2,3.3c-0.7,3.7-1.5,7.3-3.9,10.3
		C757.4,566,757.5,567.6,757.5,569.2z"/>
	<path class="st3509" d="M762.5,554.2c-2.4,1.1-1.9-0.7-2-2c-0.5-1.9-0.8-3.9-3.6-3.4c-0.9,0.2-2.2,0.1-2.8-0.6
		c-4.2-5-10.7-6.2-15.6-9.9c-0.7-0.5-1.2-1.1-1.1-2.1c3.7-0.8-1.7-2.1,0.2-3c1.7,0.7,3.3,1.3,5,2c0.9,2.3,3.4,1.9,5,3
		c1.5-1,2.3-0.1,3,1.1c0.7,1.9,2.6,1.9,4.1,2.5C764,545.1,764,545.1,762.5,554.2z"/>
	<path class="st3195" d="M750.5,539.3c-1-0.4-2-0.7-3-1.1c0.6-0.9,1.5-1.2,2.4-0.7C750.6,537.9,751.4,538.4,750.5,539.3z"/>
	<path class="st3510" d="M737.5,533.2c0.6,0.9,1.3,1.9,1.9,2.8c-0.6,0.1-1.3,0.1-1.9,0.2c-5.8,0.3-9.4-4.5-14.2-6.3
		c0.7-2.7,2.5-2.4,4.5-1.8c0.2,0,0.5,0,0.7,0.1C731.2,530.3,734.2,532,737.5,533.2z"/>
	<path class="st3511" d="M620.6,502.3c1.6-1.7,3.8-1,5.7-0.9c4.6,0,10.7,1.4,13.5-1.4c3.8-3.8,10.1,0.3,12.8-5.9
		c0.8-1.7,6.5-1.2,10-1.8c0,0.7,0,1.3,0,2c-0.4,1.5-1.7,2-2.7,2.8c-2,1.4-4.4,1.8-6.6,2.8c-1,0.4-1.7,1.2-2.7,1.8
		c-0.4,0.1-0.9,0.2-1.3,0.2c-2.6-0.2-4.9,0.9-7.4,1.4c-3.4,0.2-6.6,1.6-10,1.2c-0.5,0-1.1-0.2-1.6-0.3
		C627.1,502.8,623.4,504.8,620.6,502.3z"/>
	<path class="st3512" d="M683.5,484.3c-2.4,3.6-6.5,4.9-10,7c-0.6,0.6-1.3,0.6-2,0.1c2.7-2,5.3-4.1,8-6.1
		C680.8,484.7,681.8,483,683.5,484.3z"/>
	<path class="st3513" d="M671.6,491.4c0.7,0,1.3-0.1,2-0.1c0,0.9,0,1.8,0.1,2.7c-0.4,2.2-2,2.7-3.9,2.9c-0.8-0.1-1.4-0.6-1.6-1.4
		c0-2.3,2.5-2,3.4-3.3C671.5,491.9,671.5,491.6,671.6,491.4z"/>
	<path class="st3197" d="M683.5,484.3c-1.3,0.3-2.7,0.7-4,1c1.5-2,2.9-4.3,6.1-2.9C685.2,483.3,684.5,483.9,683.5,484.3z"/>
	<path class="st3514" d="M671.5,492.1c-0.3,1.7-2.3,1.9-3,3.2c-0.9,0.8-1.8,0.8-2.8,0.3c-0.5-0.4-0.5-0.8-0.1-1.3
		C667.4,493.1,669.3,492.2,671.5,492.1z"/>
	<path class="st3512" d="M665.7,494.4c0,0.3-0.1,0.6-0.1,1c-0.3,0.4-0.7,0.7-1.1,1c-1.6,0.6-3.3,1.5-4.9-0.1c1-0.7,2-1.3,3-2
		C663.6,494.3,664.6,494.3,665.7,494.4z"/>
	<path class="st3515" d="M669.6,496.3c1.3-0.8,2.6-1.6,4-2.3c2-0.9,3.9-1.8,5.9-2.7c2.5,1.8,0.5,2.7-0.7,3.8
		c-3.3,1.8-6.9,3.3-7.4,7.8c-0.1,1-1.2,2-2.1,1.9c-4.9-0.4-5.8,3.3-7.4,6.5c-0.4,0.9-0.6,2.4-2.2,2.1c-2.2-2.2-1.2-5-1.4-7.6
		c0-1.1,0.1-2.1,0.6-3.1C661.5,499,665.3,497.2,669.6,496.3z"/>
	<path class="st3516" d="M678.5,494.3c0.7-0.9,2.1-1.5,0.9-3c4.8-3.7,9.2-7.9,15.1-10c0.3,1.3,0.7,2.7,1,4c-1.3,1.2-2.9,1.5-4,3.3
		c-2.3,3.9-6.9,4.8-10.7,6.7C679.9,495.9,679,495.3,678.5,494.3z"/>
	<path class="st3517" d="M427.8,386.4c0.7,1.3,1.8,2.3,3,3c0.4,1.7,2.7,2.8,1.2,4.9c-1.8-2.6-3.7-5.2-5.5-7.8
		C426.9,386.5,427.4,386.5,427.8,386.4z"/>
	<path class="st3208" d="M400.9,380.4c3-3.5,7.4,0.6,10.7-2c-1,2.1-0.3,4.8-2.8,6.1c-1.2,0.6-2.4,1.6-1,3c1.6,1.6,2-0.7,3.1-1.1
		c2.2,0.9,4.3-0.6,6.5-0.2c4.7,0.4,7.2,3.3,8.7,7.5c0.7,1.9,1.5,3.9-0.1,5.5c-1.7,1.7-2.8-0.6-4.2-1.2c-0.7-0.3-1.3-0.9-2.2-0.7
		c1.6,2.9,6,4.4,4.6,8.9c-2.2,1.1-2.8-1.1-3.8-2.1c-4.3-4.1-7.9-5.4-10.3-3.6c-2.9,2.2-3.4,8-1.2,12.2c0.6,1.1,1.6,2.2,1,3.6
		c-1.2,0.7-2.5,1.2-4,1c-1-6.8-2.1-13.5-2-20.4c0-3.2-2.1-5.4-3.4-7.9c-0.8-1.5-0.7-2.8,0.4-4.1c1-1.1,1.7-1.1,3.3-0.4
		c2.4,1.1,3.8-1.4,5.9-3.1C406.7,380.3,403.6,381.7,400.9,380.4z"/>
	<path class="st3203" d="M400.9,412.2c-0.4-0.6-0.8-0.9-0.8-1.2c-0.1-0.7,0.3-1.3,1-1.1c0.3,0.1,0.6,0.7,0.5,1.1
		C401.6,411.4,401.2,411.7,400.9,412.2z"/>
	<path class="st3208" d="M438.8,432.4c0.7,3.1-3,4.2-3,7c-3.3-2.8-6.2,0.2-7.7,1.8c-3.2,3.4-4,1.8-5-1.3c-0.1-0.3-0.9-0.3-1.3-0.5
		c3.5-3.1,3.3-4.9-1.7-7.6c-2.7-1.5-4.3-3.2-4.3-6.3c1.1-0.7,0.1-1.3,0-2c0.2-0.1,0.4-0.3,0.5-0.4c2.7-0.3,5.5-0.4,7.5-2.7
		c1.6-1.5,2.9-3.1,4.8-4.3c1.8-1.1,3.5-1.7,5.4-0.1c1.7,1.5,3.1,3.1,2.1,5.5c-1,2.5-1.5,4.4,2,5.7
		C440.3,427.9,439.4,430.5,438.8,432.4z"/>
	<path class="st3518" d="M409.9,416.4c-5.3-5.1-4.6-11.1-1.4-16.5c1.4-2.4,5.8-2.3,8.8-0.3c2.6,1.8,4.5,4.4,6.6,6.7
		c4.1,4.2,4.2,9.3,0.3,14.4c-0.5,0.1-1,0-1.4-0.3c-0.9-2.8,0.5-5.7-0.6-8.5c-1.1-3.5-2.9-6.5-5.9-8.7c-2.1-2-3.9-1.6-5.4,0.8
		c-0.9,1.6-0.6,3.3-0.7,5c0,3,1.4,5.3,3.2,7.5c1.3,1.8,3.5,2.9,3.8,5.3c-0.1,0.8-0.9,1-1.3,1.4c0,0,0,0,0,0
		C413.2,421.6,410.5,419.8,409.9,416.4z"/>
	<path class="st3206" d="M423.9,420.4c2.4-4.7,2.4-9.3,0-14c-0.4-4.9-4.6-6.9-8.3-10.6c4.2-0.5,7.1,0.1,9.4,3.2
		c3.3-4.7-4.3-6.7-2-11.1c-3.1,2-5.1,1.7-6.1-1.5c-0.5-1.8,0.6-2.6,2.1-3.1c6.1,2,8.4,7.7,11.7,12.3c1.1,1.5,1.9,3.3,3.2,4.8
		c-0.2,8,4.8,14.6,5.6,22.3c0.4,3.2,1.2,6.5-0.6,9.6c-0.3-2,1.3-4.9-3.4-4.5c-2.7,0.2-2.1-4-1.1-5.6c1.5-2.3,1.4-3.3-0.6-5.3
		c-2.5-2.5-3.7,0-5.4,0.7c-1.9,0.7-2,3.5-4.4,3.7c-0.3-0.2-0.4-0.4-0.3-0.7C423.7,420.5,423.8,420.4,423.9,420.4z"/>
	<path class="st3519" d="M418.9,383.3c-0.7,1-1.4,2.1-2.1,3.1c-2,1.1-4,3.7-6,0c0.8-1.2,1.8-2.2,3-3c0.8-2.4,2.6-0.9,4.1-1
		C418.3,382.7,418.6,383,418.9,383.3z"/>
	<path class="st3208" d="M413.9,383.4c-0.6,1.3-1.7,2.3-3,3C411,384.5,412,383.5,413.9,383.4z"/>
	<path class="st3520" d="M480.7,394.5c0.7,3.3,1.4,6.6,2.1,9.8c-0.2,4.7-1.3,9.1-4.1,13c-1.3,1.8-0.2,4.3-1.8,6
		c-0.4-2-0.8-4-1.1-5.9c0.1-1,0.2-2.1,0.2-3.1c0.7-6.1,0.2-12.4,3.8-17.9c0-0.7,0.1-1.4,0.1-2.2
		C480.1,394.3,480.4,394.4,480.7,394.5z"/>
	<path class="st3521" d="M475.7,384.4c-0.1,1.7,0.3,3.2,1.9,4.1c-0.3,1.6-0.6,3.3-0.9,4.9c0.5,0.5,0.6,1.1,0.4,1.7
		c-0.4,0.7-1.9,0.4-1.6,1.7c-0.1,0.4-0.2,0.8-0.3,1.2c-0.7,1.2-0.2,3.1-2.3,3.3c-1-3.3-2.1-6.6-3.1-9.9c4-3.3,1.2-6.7,0.1-10.1
		c-0.5-1.6-1.3-3.2-0.4-4.9c2.7-0.7,3.3,1.4,4.1,3.1C474.5,381.1,474.7,382.9,475.7,384.4z"/>
	<path class="st3522" d="M476.8,395.4c0-0.7,0-1.3,0-2c2.2-0.2,1.7,2.3,2.9,3c-1.5,5.9-1.6,12.1-3.8,17.9c-0.6-3-1.3-6-1.9-9
		c0.4-2.6-0.3-5.3,0.8-7.9l0,0C475.4,396.6,475.8,395.8,476.8,395.4z"/>
	<path class="st3523" d="M470.8,381.4c2.3,1.8,2.2,4.6,2.6,7c0.3,1.7-0.9,3.8-3.6,3c-0.7-1.3-1.4-2.6-2.1-4
		c-1.5-1.2-2.3-2.8-2.3-4.7c2-1.8-0.4-4.7,1.5-6.5C470.2,376.5,469.1,379.9,470.8,381.4z"/>
	<path class="st3524" d="M465.9,382.4c0.6,1.7,1.2,3.4,1.9,5c-0.9,0.5-0.9,1.4-1.1,2.2c-0.9-1.5-1.8-3-2.7-4.5
		c-1.1-2.7-2.2-5.4-4.1-7.7c-0.6-1-1.2-2-1.8-3c-0.8-1.6-1.6-3.2-2.4-4.9c0.6-0.6,1.3-0.8,2.1-0.7C462.5,372,465,376.7,465.9,382.4z
		"/>
	<path class="st3235" d="M474.8,397.4c-0.3,2.6,0.8,5.4-0.8,7.9c-0.3-1.3-0.7-2.7-1-4C474.4,400.4,473.2,398.2,474.8,397.4z"/>
	<path class="st3525" d="M457.7,369.4c-0.7,0-1.5,0-2.2,0.1c-1.6-0.9-2.4-2.4-3.2-3.9c0.9-0.1,1.8-0.2,2.8-0.3
		c0.5-0.2,0.9-0.1,1.3,0.1C457.7,366.5,459.1,367.5,457.7,369.4z"/>
	<path class="st3526" d="M475.7,384.4c-3-1.9-2.2-6.7-5.9-8c-3.2-1.3-3.4-4.7-5-7.2c-1.7-2.2-2.2-5.5-5.6-6.2
		c-2.5-0.8-5.3-0.9-7.6-2.5c-0.3-0.3-0.6-0.6-0.9-0.8c0.8-2.2,2.8-2.8,4.7-2.8c1.9,0,2.2-0.8,2.3-2.2c4.3,0.6,4.7,5.2,7.6,7.2
		c1.7,2.4,3.7,4.7,4.4,7.7c1,1.7,2,3.4,3,5.1c1.1,2.8,2.2,5.7,3.2,8.5C475.8,383.6,475.8,384,475.7,384.4z"/>
	<path class="st3527" d="M465.8,368.5c1.3,2.6,2.7,5.3,4,7.9c0.3,1.7,0.7,3.3,1,5c-2.5-0.7-1.5-4.3-4-5c-3.4-2.3-5-6.1-7.9-8.8
		c-1-0.9-1.6-2.1-3-2.3c-0.2,0-0.5,0-0.7,0c-0.8-1-1.6-1.9-2.3-2.9c-0.4-0.7-0.8-1.3-1.1-2c2.8,0.3,5.7,0.1,8.2,1.9
		C462.9,363.4,462.6,367.6,465.8,368.5z"/>
	<path class="st3528" d="M486.8,429.3c0.3,0,0.6,0,1,0c-1,5.4-2,10.7-3,16.1c-0.6,0.4-1.1,0.4-1.5-0.1c0.2-1.4,0.3-2.8,0.5-4.1
		c0.3-2.3,0.5-4.6,0.8-6.9c0.6-1.3-0.3-3,1.1-4.1C486,430,486.2,429.5,486.8,429.3z"/>
	<path class="st3529" d="M485.8,447.3c0.3,0,0.7,0,1,0c-0.5,1.3-0.8,2.9-1.7,3.9c-0.9,1-1.7,3.6-3.6,1.9c-1.6-1.5-0.1-2.9,1.1-4.1
		c0.3-0.3,0.8-0.5,1.2-0.8C484.3,447.6,485,447.3,485.8,447.3z"/>
	<path class="st3530" d="M485.8,447.3c-0.4,0.8-1.2,1-2,1c-0.3-0.3-0.7-0.7-1-1c0.1-0.6,0.3-1.3,0.4-1.9c0.5,0,1,0.1,1.5,0.1
		C485.1,446.1,485.5,446.7,485.8,447.3z"/>
	<path class="st3531" d="M494.7,378.4c-5.8-0.8-9.4-4.9-13-9c-0.9-0.6-1.5-1.3-1.8-2.3c-1.3-4.5-2.9-8.8-6.1-12.4
		c-1-1.1-1.3-2.7-0.9-4.3c3.9-4.3,5.7,0.6,8.3,2c5.4,6.4,9.5,13.6,12.8,21.2C494.8,375.1,495.6,376.7,494.7,378.4z"/>
	<path class="st3532" d="M473.8,350.4c-0.1,1.6,0.3,3,1.4,4c4.4,4,4.9,9.7,6.5,14.9c-0.6,0.3-1.3,0.6-1.9,1
		c-2.7-6.6-6.4-12.6-11.1-17.9c-1.2-2.9-5.1-4.2-4.5-8.1c0.7-1.8,1.9-2.9,4.1-2.5C471.4,343.9,472.2,347.4,473.8,350.4z"/>
	<path class="st3533" d="M494.7,378.4c-3.4-9-8.1-17.3-14-25c-3.7-4.8-7.9-9.2-11.5-14.1c-0.7-0.9-1.4-1.7-2.1-2.6
		c-0.9-1.8-2.7-3-3-5.1c0-0.5,0.2-0.9,0.5-1.3c0.4-0.2,0.9-0.3,1.3-0.2c2.6,1.6,4.5,3.9,6.5,6.2c5.2,6.3,9.6,13.1,14,19.9
		c3.8,6.5,7.4,13,10,20.1c0,0.8-0.2,1.5-0.8,2C495.4,378.4,495.1,378.4,494.7,378.4z"/>
	<path class="st3534" d="M503.8,403.4c-1.7-6.3-3.4-12.6-5.1-18.9c0.4-0.3,0.8-0.4,1.2-0.2c0.4,0.3,0.7,0.6,0.9,1
		c0.4,0.6,0.7,1.2,0.9,1.8c1.4,3.8,2.5,7.7,4.6,11.3c2.3-1.6,0-5.2,3.2-6.1c1.8,1.3,1.5,3.4,1.6,5.2c0,1,0,2,0,3
		c-0.1,2-0.2,4.1-2.3,5.1C506.4,406.7,505.1,404.8,503.8,403.4z"/>
	<path class="st3535" d="M505.7,412.4c-0.6-1.3-1.3-2.7-1.9-4c0-1,0-2,0-3c2.9,0.6,5.6,1.9,8.7,1.7c0.6,1.4,0.6,2.8,0.4,4.3
		c-0.3,2.1-1.2,3.8-3.3,4.4C506.8,416.5,506.7,413.9,505.7,412.4z"/>
	<path class="st3536" d="M511.7,406.4c-3.4,5.9-5.5,1-7.9-1c0-0.7,0-1.3,0-2c6,2.4,6,2.4,6-6c3.4,0.8,1.7,4,2.8,6
		C512.7,404.5,512.6,405.5,511.7,406.4z"/>
	<path class="st3537" d="M499.7,384.4c-0.4,0-0.7,0-1.1,0c-1-2-1.9-4-2.9-6c0-0.7,0-1.3,0-2c0.5-0.2,0.9-0.1,1.4,0.2
		C498.7,379,501.2,381.1,499.7,384.4z"/>
	<path class="st3538" d="M515.7,422.3c-1,5.3-2,10.7-3,16c-0.5,0.7-0.8,1.4-1,2.2c-0.3,1.1-0.7,2.3-2.2,2.1
		c-1.3-0.1-1.8-1.3-2.3-2.3c-1.2-2.5-0.6-5.4-1.6-8c0-3,0-6,0-9c1.5,1.7,1.9,3.8,2,5.9c0.1,2.2,2.2,2.7,3.4,2.9
		c1.2,0.2,1.4-1.8,1.5-3.1c0.4-3.9,1.5-7.8,1-11.8c-1.3-1.5-1.3-3.2-0.8-5c0.2-0.6,0.6-1,1.2-1.2
		C516.9,414.6,518.1,418.2,515.7,422.3z"/>
	<path class="st3539" d="M505.8,432.4c1.6,3,1.4,6.6,3.8,9.5c1.8-0.2,1.1-3.2,3.1-3.5c0,1.3,0,2.7,0,4c-1.5,3.1-3.1,6.1-4.7,9.4
		c-3.3-2.2-3.9-5.4-3.1-8.5C505.8,439.6,505.7,436,505.8,432.4z"/>
	<path class="st3540" d="M513.8,411.4c0,0.3,0,0.7-0.1,1c-1.1,1.5,0.2,3.5-0.9,5l0,0c-3.6,3.8-5.1,0.1-7-2c0-1,0-2,0-3
		c1.4,0.6,1.5,3.4,3.7,2.5c1.8-0.8,2.4-2.5,2.3-4.5c-0.1-1.3,0-2.6,0-3.9c0-1,0-2,0-3c-0.5-0.8-0.5-1.5,0.1-2.2
		c3.2,1.1,1.7,4.3,2.8,6.3C514.9,408.9,514.9,410.3,513.8,411.4z"/>
	<path class="st3541" d="M505.8,415.4c2.2,1.1,3.9,4,7,2c0,0.8,0.1,1.6-0.1,2.4c-0.3,1.6-0.7,3.3-2.8,3.4c-2.1,0.1-2.6-1.6-3-3.2
		C506.4,418.5,506.1,416.9,505.8,415.4z"/>
	<path class="st3541" d="M512.7,417.4c0.2-1.7-0.6-3.6,0.9-5c0,1.7,0,3.3,0,5C513.4,417.4,513.1,417.4,512.7,417.4z"/>
	<path class="st3542" d="M464.6,362.6c-2.3-2.6-4.5-5.3-6.8-7.9c0,0-0.1-0.1-0.1-0.1c-1-1.3-3.3-1.7-2.9-4.1c0.3-0.3,0.7-0.7,1-1
		c0.6-0.7,1.4-1.3,2-2.1c0.1-0.3,0.1-0.6,0.1-0.9c1.2,0.7,2.6,1.2,4,1c1-0.5,1.8,0.1,2.5,0.6c1,1,2.1,1.9,2.2,3.4
		c0.1,0.6,0.2,1.2,0.3,1.8c0,0.7,0,1.4-0.1,2.1c-0.2,0.1-0.5,0.1-0.7,0.2c-2.1-0.6-2.5-3.4-5.1-3.9c0.2,4.3,5.2,5.9,5.2,10
		C465.9,362.5,465.4,363,464.6,362.6z"/>
	<path class="st3543" d="M466.6,351.5c-1.3-0.7-2.1-1.8-2.8-3.1c-1.1-1.6-2-3.2-2.7-5c-0.1-0.8,0.1-1.5,0.7-2.1
		c2.2-0.1,3.3,0.8,3.1,3.1c0,3.3,4.8,4.3,4,8C468.1,352.1,467.3,351.8,466.6,351.5z"/>
	<path class="st3544" d="M464.6,362.6c0.4-0.4,0.8-0.8,1.2-1.1c0.5-1,0.5-2.4,2-2.5c2.1,0.2,2.1,2.2,3,3.5l0.1,0.2
		c1.1,1.9,1.1,3.9,0,5.8c-0.4,0.4-0.8,0.8-1.2,1.2C468,367.2,466.3,364.9,464.6,362.6z"/>
	<path class="st3545" d="M470.8,368.4c0-1.9,0-3.9,0-5.8C473.7,364.5,473.5,366.5,470.8,368.4z"/>
	<path class="st3542" d="M470.8,362.4c-1-1-2-2-3-3c-1.2-1.1-2.2-2.2-1-4c0,0,0,0,0,0C469,357.3,470.8,359.3,470.8,362.4z"/>
	<path class="st3546" d="M518.7,441.3c-0.3,0.1-0.6,0.2-0.8,0.3c-0.3-1,0.1-2.3-1-3.1c-0.5-4.8,1.7-9.4,0.7-14.2
		c-1.7-3.3-0.5-6.9-1.3-10.3c0.1-1.9,0.1-3.9-0.6-5.8c-0.5-4.9-2.5-9.6-2.2-14.7c0.3-1.1,0.6-2.2,2.1-2.3c1.3,0.9,1.7,2.3,2.1,3.7
		c1.5,8.9,3.4,17.7,3.4,26.7c-0.1,5.1,0.1,10.1-0.9,15.1C520,438.4,520.1,440.1,518.7,441.3z"/>
	<path class="st3547" d="M517.6,413.4c0,3.6,0.1,7.2,0.1,10.8c-2.1,2.4,0.3,5.8-1.8,8.1c0-3.4-0.1-6.7-0.1-10.1
		c0.1-3.8-0.3-7.5-1.9-11c0-1.3,0-2.7,0-4c-0.3-3.9-1-7.8-2.6-11.4c-0.2-0.5-0.2-0.9-0.3-1.4c0-3.2-1.5-6.1-1.8-9.2
		c-0.2-2.1-1.1-4.3,0.9-6.1c4.1,2.8,3.9,7.4,4.6,11.6c0.1,0.9-0.4,1.9,0.2,2.8c0.7,4.9,1.4,9.8,2.1,14.7
		C517.8,409.9,516.7,411.8,517.6,413.4z"/>
	<path class="st2988" d="M486.8,429.3c-0.4,0.3-0.8,0.6-1.3,0.9c0.1-2.6,0.3-5.2,0.4-7.8c0.6,0.6,1.2,1.3,1.8,1.9
		C486.9,425.9,486.7,427.6,486.8,429.3z"/>
	<path class="st3548" d="M683.5,347.4c0,0.7,0,1.3,0,2c-1.4,4.9,0.4,10.1-1,15c-0.7,0-1.3,0-2,0c0-0.7,0-1.3,0-2
		c0-5.5-2.5-10.6-2.3-16.1c0.1-2-0.6-3.3-2.7-3.4c-10.9-0.2-21.8-0.2-33,0c2.9,5.9,2.9,5.9-6,8.4c2.7,1.1,5.2,0.5,7.6,0.8
		c3.9,0.5,4.6,1.5,2.9,5c-1.1,2.1-0.5,3.7,0.9,4.9c6.2,5,5.4,11.9,5.4,18.7c-2.6,0.4-3,0.2-3.7-2.8c-0.5-2.1-0.4-4.3-2.9-5.1
		c-0.3-0.4-0.5-0.8-0.7-1.2c-2-10-13.2-18.1-22.9-17.1c-5.1,0.5-10.1,2.1-15.2,2.3c-2.4,0.1-2.8,1.9-3.6,3.5c-1,1.9-3.2,3-3.8,5.1
		c-0.8,1.5-1.5,3-2.3,4.5c-1,1.5-1.7,3.1-2.5,4.6c-0.8,1.5-1.7,3.3-4,2c-0.1-1.6,0.3-2.9,1.5-4c1.9-1.7,1.7-3.7,0.7-5.8
		c-1-2.3-2.7-4.1-1.6-7.2c0.8-2.4-2-1.9-3.4-1.9c-2.3-0.1-4.8,0.4-5.2-3c2.1-3.6,3.5-7.6,5.7-11.2c3.4,0.2,1,3.1,1.9,4.4
		c-1-2.9,3.9-5.2,1-8.3c0.2-1.7,1.1-2.7,2.7-3.2c2.6,1.2,4.4,3.2,4.3,6.1c-0.2,3.1,2.1,4,4,5c2.2,1.1,3.2-1,4.3-2.4
		c2.6-3.6,5.6-6,10.4-4.9c1,0.2,2.2,0.1,2.9-0.6c4.2-4,9.6-2.4,14.5-3.4c7-1.4,14.5,0.2,21-3.7c6.8-0.7,13.5-0.3,20.3-0.3
		c2.8,0,5.1,1.1,6.8,3.4C685.6,339.5,682.9,343.4,683.5,347.4z"/>
	<path class="st3549" d="M689.5,287.5c0.3,0.4,0.5,1.1,0.8,1.2c5,1.1,5.8,4.5,5.1,8.8c-0.2,1.1-0.4,2.2-0.6,3.4
		c-4.8-1-7.3,4.2-11.4,4.5c-2.9,0.2-4.4,1-4,4.1c0.4,2.9-0.7,4.4-3.9,4.1c-1.9-0.2-3.4,0.2-4.8,1.8c-1.8,1.9-4.5,1.9-6.3,0.3
		c-4.7-4.2-10.1-1.2-15.2-1.9c-0.4-0.1-1.4,0.7-1.4,1.1c-0.2,7.6-6.2,5.2-9.4,4.4c-4.4-1.1-9.9-1-13-5.6c-0.9-1.2-0.2-2.1,0.7-2.8
		c1.3-0.9,1.7-2.2,1.6-3.7c1.2-3.5,3.3-4.3,6.8-2.7c6.3,2.8,6.4,2.6,8.9-3.3c4.7-3.1,10,0.2,15-1.4c1.5-0.5,3.2-0.2,4.4-1.7
		c0.6-0.8,1.6-1.6,2.5-1.4c5.6,0.8,9.6-2.5,13.7-5.5c2.6-1.6,6.4,0.5,8.5-2.8C688.1,287.7,688.7,287.2,689.5,287.5z"/>
	<path class="st3550" d="M655.6,333.5c-1.4,1.1-2,3.5-4.2,2.9c-7.2-2-14.1,2.5-21.3,1.1c-0.5-0.1-1.2-0.2-1.5,0
		c-6.3,5.2-17.7,0.3-21.3,10.8c-0.5,1.4-1.9,1.1-3,1.1c-2.6-0.1-6-4.2-5.9-6.9c0.1-2.8-2.5-4-3.8-6.1c-0.6-1.4,0-2.3,1-3.2
		c1-0.8,2.9,1.6,3.5-1.1c0.1-0.5,1.7-0.5,2.3-0.1c3.6,2.9,6.1,0.9,8.7-1.5c0.9-0.8,2-1.8,3.5-1.2c2,2.2,3.4,7.7,6.7,0.7
		c0.8-1.7,4-0.5,6.1-0.7c2.2-0.2,4.5,0.6,5.5-2.7c0.6-2,6.6-2,7.2-0.1c1,3.3,3.2,2.8,5.5,2.8c3.6,0,7.3,0,10.9,0
		C656.8,330.8,656.6,332.1,655.6,333.5z"/>
	<path class="st3551" d="M613.6,329.5c-1.6,1.1-3.3,2.1-4.7,3.5c-2.1,2.1-3.3,4.4-6.8,0.8c-3.4-3.5-2.3,1.9-3.5,3.1
		c-1.7-0.7-0.6-3.6-3-3.4c-0.1-0.2-0.2-0.4-0.3-0.6c4.1-8.3,9.7-15.3,17.3-20.6c0.9-1.2,0.7,3.3,2,0.4c0.3-0.6-0.3-0.2,0.3-0.4
		c4.5-1.8,8.6-4.5,13.6-4.8c1.4,1.7,1.7,3.2-0.8,3.9c-1.2,0.3-2.1,0.7-2.2,2.1c-1.8,4.9-7.6,7.1-8.6,12.7
		C616.8,327.7,614.2,327.7,613.6,329.5z"/>
	<path class="st3552" d="M655.6,333.5c0-1.3,0-2.7,0-4c0-1-0.1-2,0-3c0.3-4.3,2.9-5.8,6.7-3.5c3.8,2.3,7.1,2.4,10.2-0.9
		c1.2-1.2,2.7-1.9,4.2-2.5c5.9-2.1,6.8-1.6,6.8,4.9c0,3.7,1.9,7.4,0,11.1c-6.9-3.6-14.3-1.4-21.5-2
		C659.9,333.3,657.7,333.5,655.6,333.5z"/>
	<path class="st3553" d="M702.5,315.5c3.4-5.3,9.2-5.2,14.3-5.9c6.5-0.9,13-0.2,18.7,3.9c0,0,0,0,0,0c1.2,0.8,2.2,1.8,3,3
		c9.7,11.4,6.5,36,1,45c-1.3,2.7-2.7,5.3-4,8c-2.5,0.5-3.3,2.9-4.7,4.6c-1,1.1-1.6,2.4-3.3,2.4c-1.3-1.9,0.1-3.1,1.2-4.3
		c9.1-10.9,11.9-23.4,10.1-37.2c-0.2-0.7-0.3-1.4-0.4-2.2c0-7.1-3-12.6-8.7-16.7c-4.9-4.3-10.4-5.2-16.5-2.9c-2.3,0.9-4.7,1.6-7,2.3
		C704.9,315.7,703.7,316.2,702.5,315.5z"/>
	<path class="st3500" d="M738.5,316.5c-1.3-0.7-2.3-1.7-3-3C737.4,313.6,738.4,314.6,738.5,316.5z"/>
	<path class="st3554" d="M689.5,287.5c-0.3,0.3-0.7,0.7-1,1c-1.9,4.2-6.2,1.4-9,3c-3.2,3.3-5.9,7.4-11.6,6.1
		c-1.8-0.4-4.1-0.6-4.5,2.4c-0.1,0.5-1,1.4-1.2,1.3c-3.3-1.1-7.2,1.7-9.1,0.7c-3.5-2-6.4,0-9.5-0.4c-0.8-0.8-0.9-1.7-0.7-2.7
		c0.5-1.4,1.2-2.6,1.5-4.1c1.1-3,2.6-5.6,6.3-5.7c7.5,0.2,15.2-0.5,21-5.4c8-6.7,17.7-8,27.2-9.9c3-0.6,6.3-2.9,9.5-0.2
		c1.8,0.2,3.6-0.4,6.4,1.2C705,277.3,698.7,285,689.5,287.5z"/>
	<path class="st3555" d="M708.5,273.5c-9.1,0.9-18.1,2.9-26.8,5.4c-5.1,1.5-9,6.1-14,8.7c-4.5,2.3-9.8,0.9-14.1,3.8
		c-1.1,0.7-2.5-0.5-3.1-1.8c0.4-0.9,1.5-1.7,1.5-2.6c-0.4-6.5,4-8.1,9.1-9c0.7,0-0.2-0.1,0.5-0.1c6.4,0,6.4,0,8.2-5.9
		c4.7-2,9.5-3.5,14.4-4.6c1.1-0.2,2.2-0.8,3.2,0.2c2,0,4,0,6,0c4,0.1,8.1-0.5,11.8,2C706.9,270.6,708.4,271.5,708.5,273.5z"/>
	<path class="st3556" d="M714.5,268.5c3,0,5.5,1.6,8,3c-0.9,0.1-2,0.1-2.8,0.5C716.1,273.4,714.4,272.3,714.5,268.5z"/>
	<path class="st3557" d="M687.5,267.5c-6.3,0.6-11.9,3.6-18,5.2c-2.5,3.1-6.9,3-9.7,5.7c-3.6,1.6-7.6,2.4-10.5,5.6
		c-1.1,1.2-2.8,1.8-4.5,1c-0.5-0.3-0.6-0.7-0.3-1.3c12.2-9.2,26.1-14.2,40.9-17.2c0.7-0.5,1.3-0.5,2,0.1
		C687.5,266.9,687.5,267.2,687.5,267.5z"/>
	<path class="st3558" d="M703.6,262.5c5.4-0.3,10.7,0.7,15.9,2.1c-0.4,2.3-1.9,1.7-3.3,1.1c-1.9-0.1-3.7-0.1-5.6-0.2
		c-1.3-1-2.9-0.1-4.3-0.7c-3.5-0.5-7-0.4-10.5,0c-0.7,0.2-1.5,0.3-2.2-0.2c-2.3,0-4.5-0.1-6.8-0.1c-0.1,0-0.2-0.2-0.3-0.3
		c0.3-0.2,0.6-0.5,0.9-0.7C692.8,262.1,698.2,262.7,703.6,262.5z"/>
	<path class="st3559" d="M725.6,266.5c3.4,0.4,5.7,3.2,8.8,4.1c-0.3,2.7-2.2,0.3-3.1,1c-0.3,0-0.6,0.1-0.8,0.1
		c-0.3-0.1-0.7-0.2-1-0.3c-2-1.4-4.9-1.2-6.4-3.5C723.1,266.2,723.9,265.6,725.6,266.5z"/>
	<path class="st3560" d="M723.5,268.1c1.9,1.3,4.5,1.3,5.9,3.4c-4.7-0.4-8.7-2.7-13-4.2c-0.1,0-0.2-0.3-0.1-0.4
		c0-0.1,0.2-0.3,0.3-0.4C719.2,265.6,721.6,265.9,723.5,268.1z"/>
	<path class="st3561" d="M723.5,268.1c-2.4-0.5-4.5-1.8-7-1.7c-0.1-0.3-0.2-0.5-0.4-0.8c1.1-0.3,2.5,0.1,3.3-1.1c2,0.3,4,0.7,6.1,1
		c0,0.3,0,0.6,0,0.9C724.5,266.6,723.7,266.9,723.5,268.1z"/>
	<path class="st3562" d="M706.5,264.4c1.3,0.4,2.9-0.1,4.1,1.1c-5.8,0-11.6-0.1-17.4-0.1c0.2-0.3,0.4-0.6,0.5-0.8
		c0.6-0.1,1.3-0.1,1.9-0.2C699.2,263.8,702.8,263.9,706.5,264.4z"/>
	<path class="st3562" d="M687.5,266.6c-0.7,0-1.3-0.1-2-0.1c1.5-1.2,3.3-0.9,5-0.9C689.8,266.7,688.6,266.5,687.5,266.6z"/>
	<path class="st3563" d="M770.4,645.1c-1.4,0-2.6,0.1-3.3,1.6c-0.9,2.2-2.6,3.3-5,3.4c-1.8,0-2.3,0.8-2.9,2.5
		c-1.2,3.9-4,5.3-7.9,3.5c-2.1-0.3-3.7,0.5-5.1,2c-1.9,2-4.2,2.8-7,2.1c-0.9-1.4-0.3-2.6,0.7-3.5c2.1-1.8,1.9-3.9,1.2-6.2
		c5-5,11.6-7.6,17.4-11.4c1.7-1.1,3-3.9,5.9-1.9c0,0.7,0.1,1.3,0,2c-0.5,4,0.6,5.5,4.5,3.1c0.4-0.2,1-0.1,1.5-0.1
		C770.4,643.1,770.4,644.1,770.4,645.1z"/>
	<path class="st3564" d="M764.5,637.1c-3.6-0.5-5.1,3.1-7.6,4.5c-5.2,3-10.6,5.8-15.4,9.4c-2.7,1.9-4.7,0.9-6.5-1.4
		c-0.6-1.3-0.7-2.7,0.2-3.8c3.7-4.5,6.9-9.4,11.1-13.5c1.6-0.8,3.1-1.7,4.3-3.1c2.2-2.6,4.5-2.9,7-0.3c1.7,2.3,4.2-0.4,6,1
		c1.3,1.4,4.6,0.8,4.1,4.3C767.2,636.5,766.4,637.2,764.5,637.1z"/>
	<path class="st3565" d="M739.5,660.1c2.6-1.2,5.7-1.4,7.7-4c1-1.4,2.9-1.6,4.3,0c-0.9,0.5-1.8,0.8-2.5,1.4c-1.4,0.9-3,1.9-1.7,3.9
		c1.9,3,2.1-1.9,3.7-0.7c0.1,0.2,0.4,0.5,0.4,0.7c-0.7,2.6-4.9,4.9-2.8,7c2.8,2.8,5.5,7.4,11,5.6c-3.6,0.7-6.4,2.7-8.4,5.8
		c-0.7,1.1-1.8,2.1-3.2,2.1c-5.6-0.1-10.1,2.3-14.1,6c-2.3,2.1-5.3,2.4-8.2,3.1c-1.7-0.1-3.1-1.2-2.9-2.8c0.5-6.3-1.6-12.5,0.2-18.8
		c3.8-2.1,7.3-5,11.8-5.7C737.1,663.5,738,661.5,739.5,660.1z"/>
	<path class="st3566" d="M723.5,669.1c-0.8,6.1,1.5,12.1,0,18.2c-0.4,1.8,1,2.6,2,3.8c-3.5,3.5-7.8,6-11.6,9.2c-2.3,2-5,1-7.4,1.8
		c-0.6,0.6-1.3,0.6-2.1,0.2c-1.1-4-2.7-7.9-0.7-12.2c1-2.2-0.4-4.4-0.8-6.6c-0.1-0.5-0.3-1.3,0-1.4c5-3.1,3.8-9.2,6.8-13.3
		c1.6-2.3,3.1-4.8,5.9-6C718.6,664.3,721.8,665.8,723.5,669.1z"/>
	<path class="st3567" d="M759.5,680.1c-10.3,5.7-20.7,11.3-31,17c-1.4,2.5-4.1,3.3-6.4,4.6c-14.8,8.6-30.3,15.7-45,24.5
		c-1.7,1-3.1,2.5-5,3.2c-1.2-0.2-1.5-1.1-1.9-2c-0.1-0.5,0-1,0.1-1.4c0.9-1.1,2.1-1.8,3.3-2.7c5.2-3.5,11-6.2,16.1-9.9
		c5-1.5,9-4.7,13.5-7.2c1.6-0.9,3.2-2.6,5.4-1.1c9.4-5.2,18.5-10.8,28.1-15.5c7.7-3.7,15.1-7.9,22.8-11.5
		C759.4,678.7,759.5,679.4,759.5,680.1z"/>
	<path class="st3568" d="M708.5,705c-4.3,1.1-7.5,4-11.2,6.2c-2.4,1.5-4.8,2.8-7.8,2.8c-0.6,0.5-1.3,0.6-2,0.2
		c-0.3-0.4-0.4-0.9-0.3-1.4c4.6-5.5,11.5-7.3,17.3-10.9c0.7,0,1.3,0,2,0C707.1,703.1,708.7,703.5,708.5,705z"/>
	<path class="st3569" d="M671.6,729c4.6-4.9,10.7-7.7,16.5-10.7c13.2-6.6,26.1-13.8,38.9-21c0.4-0.2,1-0.2,1.4-0.3
		c5.7,2.3,9.9-2.5,15-3c-4.1,3.6-9.4,5.1-14,8c-7.3,5.2-15.5,8.8-23.3,13c-11.3,6.1-22.2,12.9-33.8,18.4c-1.2,0.4-2.5,0.6-2.8-0.9
		C669.2,730.9,670.2,729.8,671.6,729z"/>
	<path class="st3570" d="M491.8,570c-1.8-0.9-2.9-2.4-4-3.9c0-0.6,0-1.3,0-1.9c2.7-1,5.6-1.2,8-3h0c7-2.2,14-4.5,21-7
		c4.3-1.5,8.1-4.5,13-4c1.7-0.3,3.1-2.1,5-1c0.1,3.9-3,5-5.8,5.8c-11,3.6-21.7,8-32.4,12.4C494.6,567.6,493.2,568.8,491.8,570z"/>
	<path class="st3571" d="M495.8,567.2c7-3.5,14.1-6.6,21.4-9.4c5.9-2.3,13.6-1.9,17.5-8.6c0.3,0,0.6,0,1,0c0.2,0,0.5,0.1,0.7,0.1
		c1.3,0.9,3.5,1.3,1.9,3.7c-10.4,6.8-22.4,9.8-33.7,14.6c-1.8,0.8-3.7,1.5-5.5,2.4C497.2,570,494.9,570.6,495.8,567.2z"/>
	<path class="st3572" d="M529.7,550.2c-5.9,2.3-11.8,4.8-17.8,6.9c-5.3,1.8-10.3,4.8-16.2,4.1c0.1-1.8,1-2.7,2.7-3.1
		c8.9-2.2,17.5-5.3,26.2-8.4C526.4,549.1,528.2,548.6,529.7,550.2z"/>
	<path class="st3573" d="M532.7,546.3c3.9-3.8,9.2-4.9,14-7c2.1-0.9,4.3-1.5,5-4.1c0.2-0.2,0.4-0.4,0.5-0.6
		c3.7-1.5,7.5-2.6,11.5-1.6c1.1,2.2-0.7,2.7-2,3.6c-4.3,2.9-9.3,4.1-13.8,6.4c-4.7,1.6-8.8,4.9-14.2,4.2
		C533.4,546.9,533,546.6,532.7,546.3z"/>
	<path class="st3574" d="M533.7,547.2c4.7-1.7,9.3-3.3,14-5c0.2,0.2,0.3,0.4,0.5,0.6c0.4,4.4-4.1,4.1-6.1,6.2
		c-2.1,1.9-4.2,2.2-6.4,0.2c0,0-0.1,0-0.1,0C535,548.6,534.4,547.9,533.7,547.2z"/>
	<path class="st3575" d="M495.8,561.2c-1.9,3-4.4,4.4-8,3c-1.5-2.4,0.3-2.8,2-3C491.8,561.1,493.8,561.2,495.8,561.2z"/>
	<path class="st3576" d="M514.8,596c-1.7-2.2-3.4-4.4-5.1-6.6c0.1-6.9,1.2-6.9,6.8-9.9c9.9-5.4,21.3-8.3,28.9-17.6
		c0.4-0.9,1-1.7,1.9-2.2c1.3-0.4,2.4-0.1,3.4,0.6c0.6,0.4,1.1,0.9,1.5,1.4c0.6,0.7,1,1.5,1.1,2.4c-0.1,1.8,2,2.9,1.6,4.8
		c-5.8,2.4-10.6,6.4-15.3,10.3c-3.5,2.9-7.6,2.9-11.4,4.4c-3.8,1.5-6.4,5-10.6,6.2C515.2,590.6,516.2,594.1,514.8,596z"/>
	<path class="st3577" d="M514.8,596c-1-5.9,0.4-7.9,6-8.8c1.5-0.2,3.6,0.2,1.7-2.4c-0.5-0.6-0.1-1.6,0.7-1.4c3.1,0.9,5.3-1.9,8-2.1
		c5.1-0.3,7.7-4.2,11.5-6.4c3.9-2.3,6.9-6.8,12.1-6.7c0.5-0.3,1-0.2,1.4,0.2c0.7,1,1.1,2.4,0.5,3.3c-3.5,4.7-5.9,10.4-11.7,12.9
		c-3.2,1.4-6.5,2.5-9,5.1c-2.9,3.2-7.1,2.3-10.7,3.3c-5.1,0.3-4,5.6-6.4,8.1C517.4,599.4,516.1,597.7,514.8,596z"/>
	<path class="st3578" d="M546.7,562.2c-4,4.4-8.2,8.7-14,10.9c-4.8,1.8-9.7,3.6-13.4,7.4c-0.2,0.2-0.6,0.5-0.8,0.5
		c-7.2-1.7-6.7,4.7-8.8,8.4c-2.1-1.7-3.7-3.7-5-6c-0.9-3.2,1.7-5.5,3.5-5.9c2.9-0.5,4.7-2.4,7.2-3.3c9.7-1.9,17.3-7.9,25.5-12.7
		C543.2,560.2,545.1,559.4,546.7,562.2z"/>
	<path class="st3579" d="M498.8,577.1c-0.3-0.3-0.7-0.7-1-1c-1.4-2.2,0.4-3.3,1.5-4.7c3.8-2.3,8.1-3.9,12.2-5.2
		c10.3-3.3,19.9-8.2,30-11.6c1.5,0.4,3.1,0.6,3.3,2.6c-2.1,4.8-6.9,5.6-11.1,7.2c-2.6,1-5,2.6-7.6,3.5c-3.8,1.1-7.4,2.3-10.6,4.8
		C510.7,576.4,504.6,576.2,498.8,577.1z"/>
	<path class="st3580" d="M518.7,601.1c0.1-0.5,0.2-1,0.3-1.4c0.4-1.8,3.5-6.7,4.7-7.5c3.2-0.2,4.3,2.1,5,4.6
		c-1.5,4.1,0.7,8.3-0.4,12.4c-0.8,1.2-1.5,2.4-2.7,3.2c-1.8,0.1-2.4-1.1-2.9-2.5c-0.4-1.7-1.5-3.2-1.9-4.9
		C520.1,603.7,519.4,602.4,518.7,601.1z"/>
	<path class="st3581" d="M498.8,577.1c1.3-2.5,3.6-1.8,5.7-1.9c6.3-0.3,11-4.6,16.5-7c1.6-0.7,3.1-1,4.7-1c1.2,2.8-1.2,2.9-2.7,3.4
		c-2.9,0.9-5.4,2.3-7.3,4.6c-1.4,2.1-5.4,1.5-5.4,5.1c0,0.1-0.5,0.4-0.8,0.4c-2.8-0.2-2.8-0.2-4.9,2.8
		C502.4,581.6,500.4,579.6,498.8,577.1z"/>
	<path class="st3582" d="M499.8,572.2c-0.6,1.4-2.2,2.2-2,4c-2.4-1.6-4.3-3.8-6-6.1c0.5-2,0.7-4.6,4-2.8c0.3,2.7,2.4,1.8,4,2
		C502.4,570.2,498.9,571.2,499.8,572.2z"/>
	<path class="st3266" d="M513.8,601.1c2.7,1.3,3.5,4.5,5.9,6.1c0.8,0.4,1.3,1.2,1.9,1.9c2.4,2.7,4.5,5.7,5.7,9.2
		c0.1,0.7,0,1.4-0.4,2.1c-0.4,0.3-0.9,0.4-1.4,0.4c-4-2-4.7-6.6-7.3-9.6c-1.2-1.4-2.1-3-3.1-4.5c-0.7-1.7-2.5-3-1.7-5.2L513.8,601.1
		z"/>
	<path class="st3583" d="M520.7,609.1c-0.8-0.4-1-1.2-1-2c0.4-0.7,0.7-1.5,1.1-2.2c1.3,1.2,2.7,2.3,2.8,4.2
		C522.7,610,521.7,609.9,520.7,609.1z"/>
	<path class="st3584" d="M484.8,575.2c1,0,2,0,3,0c0.5,2.1,3,1.6,4,3c0,0.7,0,1.3,0,2c-0.6,2.5-2.9,1.2-4.3,1.9
		c-3.6,0.2-7.1,0.7-10.7,0.5c-0.7-0.3-1.2-0.9-1.5-1.6c-0.1-3,2.4-3.4,4.4-4.4C481.3,575.8,483.2,576.1,484.8,575.2z"/>
	<path class="st3585" d="M496.7,584.2c1,1.3,2,2.6,3,3.9c-5.8,1.8-11.6,3.8-17.7,4.3c-0.5,0-1.1-0.1-1.5,0.2
		c-5.8,4.4-13.6,5.7-18.2,11.9c-0.8,1.1-1.3,2.6-2.6,3c-10.8,3.9-11.1,15.2-16.3,23.1c-0.6,1-1,2.3-0.5,3.4c3.1,6.7,2.6,14.2,5,21.1
		c0.7,2.1,1.8,3.9,3.9,4.9c1.3,3.9,3.7,6.8,7.5,8.4c0.8,0.7,1.1,1.5,0.8,2.5c-1.5,2-0.7,2.6,1.5,3.2c1.6,0.4,5.5-0.2,3.2,3.8
		c-4.1,1.1-7.9,0.4-11.7-1.1c-4.2-7.5-9.8-14-13-22.4c-4.1-10.7-3.1-20.7-0.7-31.3c3.3-15.2,15.8-21.7,26.8-29.7
		c4.5-3.3,9.7-5.8,15.7-5.8c1.6,0,2.5-1.5,3.5-2.6C489.1,583,492.9,583.7,496.7,584.2z"/>
	<path class="st3586" d="M451.8,660.1c-6.1,1.2-4.5-4.4-5.7-6.9c-2.4-5.3-1-11.6-4-17c-1.1-1.9-0.7-4.3,0.3-6.9
		c2-5.3,6.7-9.3,6.9-15.4c0.1-1.5,1.8-3.8,3.2-4.2c5-1.3,7.9-4.6,10.7-8.6c1.3-1.9,3.3-3.5,6.6-3.3c2.1,0.1,4.4-3,6.5-4.7
		c1-0.8,1.9-1.9,3.4-1.8c5.7,0.4,10.8-1.9,16.1-3.3c1.2-0.3,2.6,0.1,3.9,0.2c1.9,1.2,3.7,2.5,4.1,5c-6-2.6-11.4,0-16.7,2
		c-13.2,5-25.6,11.3-32.7,24.7c-5,5.8-5.7,12.7-5.9,19.9c0,1.5,0.7,2.5,1.5,3.6c2.2,2.7,3.3,5.7,3.1,9.2c-0.1,2.3,0.4,4.8-0.8,7
		C452.2,659.7,452,659.9,451.8,660.1z"/>
	<path class="st3587" d="M453.8,619.1c6-9.9,13.2-18,25.2-21.3c4.5-1.3,9.6-3.2,13.4-5.6c5-3.1,7.8-1,11.4,1c0.3,0,0.6,0,0.9,0.1
		c0,0.7,0,1.3,0,2c-0.4,1.3-0.8,2.8-2.3,2.9c-5.6,0.2-10.3,3.3-15.6,4.6c-5.5,1.3-10.6,4.8-14.8,8.4c-5.6,4.8-12.3,9-14,17.1
		c-1.7,4.5,0,9.7-2.9,13.9c-3.8,0.3-4.6-2.5-4.3-5.1C451.4,631,451,624.8,453.8,619.1z"/>
	<path class="st3588" d="M496.7,584.2c-3.3,2.5-7.7-0.7-11,1.9c-5.2,2.4-11.2,2.8-16.3,5.5c-1.2,0.7-2.5,0.5-3.7-0.4
		c-1.1-1.9,0.3-2.7,1.6-3.4c5.9-3.3,11.5-7.4,18.9-6.3c0.5,0.1,1-0.2,1.4-0.4c1.1-1.2,2.8-0.1,4-1
		C494.3,580.5,495.6,582.3,496.7,584.2z"/>
	<path class="st3589" d="M456.8,627.1c2.9-2.6,1.6-7.5,5.4-9.7c6.3-3.7,10.9-9.3,17.5-12.9c6.9-3.8,14.2-5.2,21.4-7.3
		c1.5-0.5,2.6-1.2,3.7-2.1c2.3,2,4.6,4,6.9,6.1c0.4,0.3,0.6,0.7,0.6,1.3c-0.1,0.5-0.3,0.9-0.6,1.3c-1.3,1.1-2.4,0.2-3.6-0.4
		c-4.3-2.3-8.8-1.5-11.9,2.2c-2.8,3.2-6.7,4.1-10.3,5.8c-4.2,1.4-7.3,3.8-9.9,7.7c-2.3,3.6-6.2,6.2-7.9,10.6c-1.9,5,0.6,7.9,3.5,11
		c2.6,2.8,5.6,2.4,8.7,0.9c0.4-0.2,0.9-0.6,1.4-0.6c7.8-0.5,15-3.4,22.5-5.1c3.2,0.3,6.4,0.6,9.4,1.9c-2.1,4.6-5.5,7.9-10.6,8.5
		c-2.9,0.4-5.2,1.5-7.7,2.7c-4.7,2.3-10.1,2.6-15.2,3.8c-1.7,0.4-3.6,0.1-5.4,0.3c-2.2,0.3-4.5,0.1-6.2-1.3
		C462.7,647,454.3,637.7,456.8,627.1z"/>
	<path class="st3590" d="M511.7,602.2c0-0.3,0-0.6-0.1-0.9c0-0.3,0.1-0.6,0.1-1c0.6,0.4,1.2,0.8,1.8,1.1c0.8,1.6,1.6,3.1,2.4,4.7
		c-0.4,0.3-0.8,0.4-1.2,0.4C512.6,605.7,512.4,603.8,511.7,602.2z"/>
	<path class="st3591" d="M622.6,798.9c2.3-2.9,6.9-0.9,9.2-4.4c0.5-0.9,1.5,1.9,2.9,1.9c2.3-0.1-0.9-4.7,2.9-3.4
		c0.8,1.7,1.4,3.5,1.5,5.4c-0.7,1.7-2.6,1.6-3.7,2.6c-3.5,2.5-7.7,3.9-11.3,6.4c-1.1,0.5-2.1,1.1-3.3,0.5c-0.7-0.7-1.3-1.3-2-2
		c0.6,0,1.3,0,1.9,0C625.3,804.7,620.4,800.8,622.6,798.9z"/>
	<path class="st3592" d="M574.7,808c3-1.8,6.6-2.3,9-5c6.5-3.9,12.3-9.1,20-11c1.4,0.1,2.7-0.3,4-1c0,0.3,0,0.7,0,1
		c-3.6,3.3-8.7,4.3-12,7.9c-2.1-0.4-3.4,1.1-5,2c-5,2.2-9.5,5.7-14.9,7C574.6,809.4,574.5,808.8,574.7,808z"/>
	<path class="st3593" d="M574.7,808c0.3,0.3,0.7,0.6,1,1c-4.3,2.5-7.9,6.1-13,7c0.7-1,1.4-1.9,2-2.9
		C567.9,811,571.1,809.1,574.7,808L574.7,808z"/>
	<path class="st3594" d="M559.8,869.8c-1.9-1.2-2.2-3.2-2.8-5.1c-0.1-2.7-1.4-5.6,1.1-8c1.6,0.1,2.8,1.1,4,2.1
		c0.5,0.9,0.6,1.9,0.3,2.9c-2.7,2.8-2.4,4.4,1.1,5.7c1.2,0.4,2.6,0.3,3.7,1.1c0.9,0.7,1.4,1.5-0.2,1.9
		C564.5,870.1,562,870.9,559.8,869.8z"/>
	<path class="st3595" d="M558.7,856.9c-2.2,2.2,0.1,5.5-1.6,7.8c0,0-0.4,0.2-0.4,0.2c-0.7-3.7-0.7-7.3,0-11c0.3-1.3,0.1-2.8,1.1-3.9
		c0.8-1.3,1.5-2.7,2.3-4c1.5,0.1,1.4,1.3,1.5,2.3C560.8,851.2,560.8,854.4,558.7,856.9z"/>
	<path class="st3296" d="M556.7,853.9c0,3.7,0,7.3,0,11C555.1,861.2,555.1,857.5,556.7,853.9z"/>
	<path class="st3596" d="M559.8,869.8c2.6,0,5.2,0,7.9,0c0.8-0.6,1.5-0.5,2.2,0.1c-0.1,0.3-0.2,0.5-0.3,0.8
		C566.2,870.7,562.8,871.7,559.8,869.8z"/>
	<path class="st3597" d="M579.8,860.2c-1,0.9-2,1.8-2.9,2.7c-1.6,0.9-3,0.8-4.3-0.5c-1.1-1.5-1.8-3.1-2.3-4.9
		c-0.2-5.4,3.3-7.9,7.7-9.6c2.8-0.2,3.9,1.4,3.7,3.9c-0.1,1.6-0.3,3.2,0,4.9C581.7,858.2,580.4,859.1,579.8,860.2z"/>
	<path class="st3598" d="M572.7,861.8c1.3,0.8,2.7,1.1,4.2,1.1c-2,2.7-4.4,5-7.1,7c-0.7,0-1.4-0.1-2.2-0.1c-1.4-1.7-3.5-0.6-5.2-1.1
		c-2.1-0.7-3.2-2.1-3.7-4.2c-0.6-2.6,1.8-1.7,2.7-2.6c0.9,0.2,1.5,0.8,2.3,1.3c1.9,0.8,3.7,1.3,5.2-0.7
		C570,861.2,571.2,860.6,572.7,861.8z"/>
	<path class="st3599" d="M579.8,860.2c0.3-1.1,0.6-2.2,0.9-3.3c0.7-3.7,2-7.2,3.5-10.7c1.3-3.1,1.8-6.3,0.6-9.5
		c-1.7-4.1-4.6-5.2-8.7-3.8c-1.1,0.4-2.2,0.7-3.4,0.2c-0.5-0.6-0.5-1.3,0-1.9c2.8-1.6,5.8-2.2,8.9-2.2c2.6,1.4,4.8,3.3,6.1,6
		c1.6,7.9-1.7,14.8-4.3,21.9C582.1,857.8,581.8,859.9,579.8,860.2z"/>
	<path class="st3600" d="M611.8,818.6c-0.4-1.8-0.9-3.6-1.3-5.4c0.5-1.2,0.7-2.7,1.5-3.6c1.5-1.8,3.6-2.3,5.8-1.1
		c2.5,1.3,1.9,3.2,1,5.1C617.5,816.6,616,819.5,611.8,818.6z"/>
	<path class="st3601" d="M619.6,819.9c0-1.7,5.4-2.3,1.1-5c1-1.5,1-3.3,0.9-5c-0.5-1.7,1-1.7,1.8-2.4c1.3-0.9,2.5-2,4.2-2.1
		c2.4,1,4.6-1.9,7-0.5c0.8,4.8-4,5-6.1,7.5c-1,1.3-2.7,2.1-3.2,3.9c0,0.6-0.1,1.2-0.7,1.6C623.2,819.2,621.4,819.5,619.6,819.9z"/>
	<path class="st3281" d="M623.6,807.9c-0.7,0.7-1.4,1.3-2,2c-0.8-0.5-0.9-1.2-0.9-2c0.9-0.3,1.9-0.7,2.8-1c0.4,0.2,0.5,0.4,0.4,0.6
		C623.8,807.8,623.7,807.9,623.6,807.9z"/>
	<path class="st3279" d="M624.6,817.9c0-0.7,0-1.3,0-2c3.2-2.7,6.7-4.7,10.9-5.3c2.2,2.1,0.1,4.3,0.1,6.8c2.1-0.6,3.9-3.1,6.1-1
		c0.6,0.5,0.9,1.1,1.1,1.9c-0.1,1.1-0.7,2-1.6,2.4c-5.2,2-9.4,6.8-15.6,6.2c0,0,0-0.1,0-0.1C627.5,823.6,626,820.8,624.6,817.9z"/>
	<path class="st3279" d="M620.9,822.1c0.7-0.1,1.3,0.2,1.5,0.9c0.2,1-0.3,1.6-1.2,1.6c-0.7,0-1.3-0.4-1.3-1.2
		C619.8,822.8,620.1,822.2,620.9,822.1z"/>
	<path class="st3602" d="M625.6,826.9c5.9-2.6,12-4.8,17-9c0.2-0.1,0.4-0.2,0.6-0.4c0.5,0,0.9,0.1,1.2,0.5c-0.2,0.9-0.5,1.8-0.7,2.7
		c-0.1,0.7-0.4,1.3-0.9,1.8c-1.6,1.4-3.3,2.5-5.2,3.4c-3.8,1.8-7.6,3.7-11.6,5.2c-1.2,0.3-2.4,0.7-3.5-0.2
		C622.3,828.6,624.6,828.2,625.6,826.9z"/>
	<path class="st3278" d="M622.6,830.9c1-0.3,2-0.5,3-0.8c1.9,1.8,4.4-1,6.2,0.8c0.3,0.7,0.2,1.3-0.4,1.8c-0.3,0.1-0.5,0.2-0.8,0.2
		c-1.3,0.6-2.8,0.6-4.1,1.4c-1.3,0.3-2.5,1.8-4,0.4C622.6,833.5,622.6,832.2,622.6,830.9z"/>
	<path class="st3603" d="M620.6,834.8c-0.7,1.3-1.4,2.6-2.9,3c-1.3-0.9-1-2.6-1.6-3.8c-0.3-0.7-0.9-1.3-1.2-2
		C618.6,829.3,619.6,832.1,620.6,834.8z"/>
	<path class="st3604" d="M614.9,832c0.7,0,1.3,0.1,1.7,0.8c-0.5,2.3-0.5,4.7-1.8,6.7c-0.4,0-0.7,0.1-1.1,0.1c0-1.4,0.3-3-1.4-3.8
		C612.5,834.1,613,832.5,614.9,832z"/>
	<path class="st3605" d="M622.6,834.8c1.3-0.3,2.7-0.6,4-0.9c0.9-0.4,1.1,0,0.9,0.8c-1.9,0.9-3.5,2.6-5.8,2.1c0-0.6,0-1.3,0-1.9
		C622,834.9,622.3,834.8,622.6,834.8z"/>
	<path class="st3606" d="M614.8,839.5c-0.1-2.4-1-5.1,1.8-6.7c1.9,1.3,0.8,3.3,1.1,5C616.7,838.4,615.7,838.9,614.8,839.5z"/>
	<path class="st3607" d="M631.5,832.7c0-0.6,0-1.2,0.1-1.9c0.5-4.1,4.3-3.2,6.7-4.5c0.5,0,1,0.1,1.4,0.3c0.3,0.4,0.3,0.9,0,1.4
		c-0.7,0.6-1.4,1.2-2.1,1.9C635.6,830.9,633.6,831.8,631.5,832.7z"/>
	<path class="st3607" d="M627.5,834.7c-0.3-0.3-0.6-0.6-0.9-0.8c1.1-1.5,2.7-0.9,4.1-0.9C629.7,833.5,628.6,834.1,627.5,834.7z"/>
	<path class="st3608" d="M639.7,828.1c0-0.4-0.1-0.8-0.1-1.3c-0.1-2.2,1.7-2.6,3.1-3.3c0.8,0.1,1.4,0.4,0.9,1.4
		C642.8,826.5,641.5,827.6,639.7,828.1z"/>
	<path class="st3609" d="M643.7,825c-0.4-0.3-0.7-0.6-1.1-1c-0.6-0.7-0.5-1.4,0-2.2c0.4-0.4,0.8-0.7,1.1-1.1c1,0.7,1.1,1.8,0.9,2.9
		c0.4,0.5,0.4,0.9-0.1,1.3C644.2,824.9,643.9,824.9,643.7,825z"/>
	<path class="st3610" d="M644.5,824.9c0-0.4,0.1-0.8,0.1-1.3c0.9,1.1,1.8,2.1,2.7,3.2C645.8,827,644.6,826.7,644.5,824.9z"/>
	<path class="st3611" d="M778.4,589.2c-3.2,1-4.5,4.2-6.8,6.2c-0.6,0.6-0.8,1.8-2,1.9c-4.8,0.5-6.6,3.6-7.2,8
		c-3.4,0.5-6.9,1.5-10.3,1.7c-2.1,0.1-4.1-1.1-5.2-3.2c-0.4-4,1.1-7,4.5-9.2c1.5-1,3.3-1.8,4.2-3.4c1.4-2.4,3.5-3.3,6.2-3.2
		c4.2,0.1,7.1-1.8,9.3-5.4c2-3.4,4.8-6,9.1-7c3.6-0.9,5.3-5.3,9.2-6.3c0.7,0,1.3,0,2,0c0.3,4.9-4.8,5.1-7.2,7.6
		c-3.1,3.4-7.1,5.5-5.2,11C779.1,588.2,778.6,588.7,778.4,589.2z"/>
	<path class="st3612" d="M789.4,569.2c-3.7,3.1-6,8-12,8.5c-1.9,0.1-4.1,3.2-5.3,5.5c-2.2,4.5-5.6,6.3-10.3,6c-2.4-0.1-4,0.3-5,2.8
		c-0.8,2-3.1,2.9-5.3,3.2c-2.6-1.4-2.4-3.4-0.7-5.3c1.7-1.9,2.5-4.2,2.7-6.7c2.2-4.3,6-6.1,10.6-7.4c4.6-1.2,7.6-5,11-8.1
		c1.4-1.3,2.8-1.5,4.4-1.5C783.3,565.4,785.4,570.4,789.4,569.2z"/>
	<path class="st3613" d="M753.5,583.2c2.5,2.8,0.2,6.2-1.1,6.9c-3.1,1.8-1.6,3.2-0.9,5c-1.9,2.4-3.4,5-4,8
		c-1.4,5.1-4.6,9.1-7.7,13.1c-0.5,0.5-1.6-0.7-2,0.9c-0.4,1.5-1.1,3.5-3.6,2.8c-1.3-2.4,0.7-4.2,1.3-6.2c4.1-7.7,7.9-15.5,12-23.2
		c0.8-1.6,1.5-3.3,3-4.5C751.5,585.2,752.5,584.2,753.5,583.2z"/>
	<path class="st3614" d="M750.5,586.2c0.4,3-2,4.8-3.1,7.1c-3.5,7-7.2,13.9-10.9,20.9c-1.1,1.5-2,1.5-2.7-0.4
		c-0.2-2.6,1.5-4.6,2.3-6.9c3-8.3,8.3-15.5,10.3-24.2c0.4-1.6,1.2-3.1,3.1-3.5c1.7-1,3.3-2,5-3C753.1,579.5,751.8,582.8,750.5,586.2
		z"/>
	<path class="st3615" d="M739.5,616.1c2-4.7,4.8-9,8-13c2.8,1.5,5.2,5.4,8.8,0.6c1.4-1.9,3.7,2.3,6.1,1.4c0,1.3-0.2,3,1.3,3.4
		c3,0.8,1.6,2.9,1.7,4.6c-4.3,2.6-9.9,0.5-14,4c-0.3,0.2-0.5,0.5-0.8,0.6c-5.1,0.3-9.7,2.2-14.1,4.6
		C734.6,618.9,737.6,617.8,739.5,616.1z"/>
	<path class="st3616" d="M736.5,622.1c3.9-4.9,9.2-5.7,15-5c0.4,2.8,3.4,2,4.8,3.5c1.2,1.3,4.5,1.9,1,4.4c-1.6,1.1,0.3,2.7,0.1,4.1
		c-2.8-1-5.3-1.4-6.8,2.1c-0.7,1.6-2.4,2-4.2,1.9c-2.6,1.5-5.1,1.6-7.3-0.5c-1.8-1.7-4.2-1.9-6.3-2.9
		C732,626.2,734.3,624.2,736.5,622.1z"/>
	<path class="st3617" d="M572.7,831.1c0,0.6,0,1.2,0,1.8c-0.3,4.7-4.2,5.9-7.5,7.7c-0.5-0.1-0.9-0.3-1.3-0.7c1.2-1.7,2.4-3.4,3.6-5
		C568.8,833,570.5,831.7,572.7,831.1z"/>
	<path class="st3618" d="M563.9,839.9c0.3,0,0.6,0,0.8,0c1.2,1.7,3.8,2.2,4.3,4.6c-0.1,0.7-0.4,1.2-0.9,1.6
		c-0.8,0.6-1.9,0.8-2.7,1.4c-0.6,0.5-1.1,1.2-1.7,1.6c-0.4,0.1-0.8,0.1-1.2-0.1c-0.5-0.6-0.4-2.1-1.8-1.2c-0.2-0.6-0.5-1.2-0.7-1.9
		c0.2-0.7,0.5-1.4,0.7-2.1c0.7-1,1.4-2,2-3C563.1,840.6,563.5,840.2,563.9,839.9z"/>
	<path class="st3170" d="M467.8,570.2c-0.2-2.2,2.3-1.7,3-3c0.8,0.4,1,1.2,1,2C470.2,568.4,469.7,572.2,467.8,570.2z"/>
	<path class="st3170" d="M474.8,569.2c-1,1.3-2,1.3-3,0C472.8,569,473.8,565.4,474.8,569.2z"/>
	<path class="st3619" d="M781.5,739.1c-0.3,0.6-0.7,1.3-1,1.9c-1.1,0-2.3,0.2-3-1c0-0.3,0-0.6,0-1c0,0,0.3-0.3,0.3-0.3
		c1.2-0.9,2.4-0.9,3.6,0.1L781.5,739.1z"/>
	<path class="st3301" d="M776.4,755c-4.2,1.2-7.4,4.5-12,4.8c2.3-1.3,1.2-7.3,6.9-4.7c1.7,0.8,4.2-2.9,7.1-2.2
		C777.8,753.7,777.1,754.3,776.4,755z"/>
	<path class="st3620" d="M781.3,738.8c-1.2,0-2.4,0-3.6-0.1c-2-3.4-1.6-6.6,1.5-9.1c1.3-1,3.2-1.3,4.3,0.5
		C785.6,733.7,783.7,736.4,781.3,738.8z"/>
	<path class="st3308" d="M650.6,765c-2.7,1.8-5.3,3.9-8.1,5.4c-1.9,1-1-0.7-0.9-1.4C644.4,767.2,647,764.9,650.6,765z"/>
	<path class="st3621" d="M603.6,791.9c-6.4,4-12.9,8.2-20,11c-0.1-1.7,0.5-2.6,2.2-3.3c2.6-1,5.1-2.3,6.7-4.8c-0.5-1.9,1.6-1,2-2
		c1.6-0.8,3-2,4.9-2.1c1-0.6,1.8-1.5,3.2-0.9C603,790.6,603.3,791.3,603.6,791.9z"/>
	<path class="st3309" d="M602.6,789.9c-1.1,0.3-2.1,0.6-3.2,0.9c0.3-0.6,0.4-1.5,0.8-1.7c8.5-4.2,16.2-10,25.3-13.2
		c-1.7,2-3.3,4-5,6C613.5,782.1,608.3,786.6,602.6,789.9z"/>
	<path class="st3440" d="M578.6,800.8c-0.1,2.4-2.1,2.6-3.8,3.2c-3.9,1.3-7.1,4.1-11.2,4.8C568,805,573,802.4,578.6,800.8z"/>
	<path class="st3440" d="M594.6,792.9c-0.7,0.7-1.4,1.3-2,2c-5.1,1-8.9,4.9-14,6C583.3,796.8,588.6,794.1,594.6,792.9z"/>
	<path class="st3622" d="M495.9,162.7c0.6-0.3,1.3-0.7,1.9-1c2.5-0.1,4.7-2.5,7.4-1.2c1.3,1.1,1.7,2.3,1,3.9
		c-0.8,1.6-3.1,0.5-4.1,2.2c1.9,1.6,2.5-0.5,3.5-1.1c3.1,1.8,5.7,4.7,5.8,7.7c0.1,4.5,2.6,4.4,5.2,4.1c3-0.3,4.5,0.4,4.6,3.5
		c-0.1,1-0.6,1.8-1.4,2.5c-2,1.2-3.8,3.2-6.5,2.7c-2.6-3.3-6.6-2.6-10.1-3.1c-6.6-1-11.4-7-10.3-13.1c1.2-1.6,1.8-3.4,2.8-5.1
		C495.8,164,495.8,163.4,495.9,162.7z"/>
	<path class="st3623" d="M495.7,164.7c0.1,2-0.4,3.7-2,5c-1.1,2.7-2.1,5.4-3.9,7.8c-2.2,4.6-1.5,8.3,3.1,11.1
		c1.4,0.8,3.3,1.7,2.8,4.1c-1.7,2.9-4.4,1.6-6.3,0.8c-3.1-1.5-5.6-3.9-6.7-7.5c-0.2-0.6-1.3-0.8-2-1.2
		C484.7,177.2,490.7,171.3,495.7,164.7z"/>
	<path class="st3624" d="M519.6,148.6c0.4,0,0.8,0.1,1.2,0.1c1.7,0.9,3.3,2,2.4,4.2c-0.8,2.2-2.7,1.4-4.3,1.1
		c-3.8-0.7-5.3,2.7-7.9,4.3c-1.3,0.8-2.7,1.3-4.2,1.5c-1.2,0.1-2.5,0.3-3.7,0.3c-1.9-0.1-2-1.2-0.4-2.3c0.2-0.1,0.5-0.1,0.7-0.1
		c1.8-1.2,4-2,5.3-4C512.5,152.2,515.7,149.6,519.6,148.6z"/>
	<path class="st3625" d="M502.8,157.8c-0.1,0.6-1.1,1.7,0.6,1.5c1.1-0.1,2.2-0.4,3.4-0.6c0.3,1.6-0.4,2.3-2,2
		c-2.3,0.7-4.4,2.2-7,1.1C499.4,160.4,499.9,157.6,502.8,157.8z"/>
	<path class="st3626" d="M694.5,770c1.6,1,0.1,2.1,0.1,3.2c-5.5,4.5-11.9,7.5-18.2,10.7c-4.4,2.2-8.7,4.6-12.9,7.2
		c-1.2,0.9-2.7,1.3-4.1,1.8l0,0c-0.4,0.6-1.1,0.7-1.8,0.9c-1.2,0-2.2-0.5-3.1-1.3c-0.5-0.5-0.7-1.1-0.6-1.9c0.1-0.4,0.4-0.8,0.8-1
		c1.2-0.5,2.3-1.1,3.2-2.1c1.1-1,2.1-2.2,3.5-2.9c0.4-0.1,0.9-0.2,1.3-0.2c3.2-0.1,5.7-1.8,8-3.8c0.8-0.8,1.5-1.6,2.2-2.5
		c1.4-1.6,3.4-2.1,5.3-2.8c1.8-0.4,3-1.5,4.1-2.8c1.1-0.8,2.4-1.3,3.8-1.3C689.1,771.9,691.6,769.2,694.5,770z"/>
	<path class="st3627" d="M700.6,783c-4.9,3.7-10.6,6.2-16,8.9c-1-3.6-4-1.3-6-2c-0.3-2.2,1.7-1.6,2.6-2.3c1.5-0.7,3.2-1.3,4.3-2.7
		c3.3-2.1,6.4-4.5,10.1-6C697.6,779.8,698,782.8,700.6,783z"/>
	<path class="st3628" d="M658.6,794.9c0.7,1.1,1.3,0.1,2,0c1,1,0.5,2-0.2,2.9c-0.3,0.3-0.6,0.6-1,0.8c-1.3,0.7-2.6,1.4-3.6,2.5
		c-1.6,1.4-3.6,1.9-5.7,1.6c-2.2,0-3.8,1.7-5.7,2.4c-1.6,0.6-3.1,1.9-5,0.7c-0.4-0.7-0.4-1.3-0.2-2.1c2.4-3.6,6.6-4.6,10-6.9
		c2.2-1.1,4.3-2.4,6.6-3.1C656.9,793.9,657.8,794.1,658.6,794.9z"/>
	<path class="st3629" d="M673.6,791c-2.8,2.1-5.4,4.5-9,5.1c-0.5-1-0.4-2.1,0-3.1C667.6,792.3,670.6,791.6,673.6,791z"/>
	<path class="st3630" d="M664.6,793c0.7,1,0.4,2.1,0,3.1c0,0.2-0.1,0.5-0.1,0.7c-1.4,1.1-2.9,2.1-4.8,1.3c0.3-1,0.6-2.1,0.9-3.1
		C662.3,795.1,663.2,793.5,664.6,793z"/>
	<path class="st3631" d="M685.5,784.9c-0.2,2.5-2.1,2.7-3.9,3.1c-0.9,0.5-1.7,0.9-1.9-0.6C681.6,786.5,683.6,785.7,685.5,784.9z"/>
	<path class="st3632" d="M679.7,787.3c0.6,0.2,1.3,0.4,1.9,0.6c-1,0.7-2,1.3-3,2c-1,0-2.1,0-3.1,0
		C676.6,788.5,677.4,786.7,679.7,787.3z"/>
	<path class="st3630" d="M659.5,792.8c1.1-1.2,2.4-1.9,4.1-1.8C662.7,792.8,661.2,793.1,659.5,792.8z"/>
	<path class="st3630" d="M658.6,794.9c-1,0-2,0-3.1,0c-0.4-1.8,1.4-1.3,2.1-1.9c0.6-0.1,1.2-0.1,1.9-0.2
		C659.2,793.5,658.9,794.2,658.6,794.9z"/>
	<path class="st3320" d="M682.5,763c1,1.4,2.4,1.1,3.4,0.2c2.6-2,5.6-2.1,8.6-2.2c0.2,0.7,0.1,1.4-0.5,1.7
		c-4.6,2.7-8.8,6.1-14.3,6.8c-1.6-1.6-5.2-1-5.1-4.6C676.5,761.6,679.8,763.5,682.5,763z"/>
	<path class="st3315" d="M674.6,765c0.5,2.8,4.8,0.9,5,4c0.3,0.5,0.3,1-0.2,1.4c-1.2,0.7-2.4,0.7-3.6,0.3c-1.4-0.9-3-1.4-4.2-2.6
		c0.3-1,0.7-2,1-3C673.2,765,673.9,765,674.6,765z"/>
	<path class="st3633" d="M679.5,769.9c0-0.3,0-0.6,0-1c5.3-2.1,10.2-4.9,15-8c0.7-0.3,1.3-0.7,2-1c1,0,2,0,3.1,0.1
		c0.3,0.4,0.5,0.8,0.5,1.2c-0.1,0.5-0.4,0.8-0.8,1.1c-2,0.3-3.7,1.2-5.3,2.4c-1.6,1.1-3.6,1.6-5.2,2.8c-2.2,1.5-4.6,2.6-6.6,4.4
		c-0.5,0.5-1.1,0.8-1.8,1c-4.1,1-7.1,4.8-11.8,4.3c-0.3-0.3-0.4-0.5-0.3-0.8c0.1-0.3,0.1-0.4,0.2-0.4l0,0c0.6-1.8,2.1-2.8,3.7-3.6
		c0.8-0.4,1.5-0.8,2.3-1.2C676.2,770.5,678,770.9,679.5,769.9z"/>
	<path class="st3634" d="M694.5,770c-2.6,0.8-4.8,3.6-8,2c-0.4-1.5,0.8-1.8,1.6-2.5c0.9-0.5,1.8-1.1,2.8-1.6
		c0.4-0.2,0.8-0.2,1.3-0.2c0.7,0.2,0.9,0.9,1.2,1.4C693.8,769.3,694.2,769.6,694.5,770z"/>
	<path class="st3635" d="M699.5,761c0-0.3,0-0.7,0-1c2.3-0.3,4.3-0.3,3.8,2.9c-1.5,0.9-2.7,2.4-4.7,2.2c-0.1-0.2-0.3-0.3-0.4-0.5
		C697.9,763.2,701.1,762.9,699.5,761z"/>
	<path class="st3636" d="M693.4,768.9c-0.3-0.2-0.6-0.5-0.9-0.7c-1.4-1.9,0.1-2.7,1.3-3.7c0.5-0.2,0.9-0.4,1.4-0.3
		c0.5,0.1,0.9,0.3,1.3,0.6c0.2,2.3-2.5,1.9-3.1,3.4C693.5,768.4,693.5,768.7,693.4,768.9z"/>
	<path class="st3637" d="M693.5,768.2c0.1-2,2.4-1.8,3.1-3.2c0.6-0.6,1.2-0.7,1.9-0.1c0,0,0.2,0.2,0.2,0.2
		C697.4,766.9,695.4,767.5,693.5,768.2z"/>
	<path class="st3638" d="M668.5,775.9c0,0.3,0,0.7,0,1c-0.4,2.1-2.3,2.8-3.8,3.7c-2.9,1.5-5.7,3.2-8.6,4.7c-1.1,0.6-2.2,1.3-3.2,2
		c-0.4,0.2-0.8,0.4-1.1,0.5c-1.4,0.4-2.6,1.3-3.9,2.1c-1.8,1-3.7,1.6-5.5,2.6c-1.1,0.7-1.6,1.7-1.5,3c0.1,0.6,0.1,1.3-0.1,1.9
		c-0.6,0.8-1.3,1.2-2.2,0.4c-1.3-1.5-1.8-3.1-1-5.1c2.7-4.5,7.8-5.1,12-7c0.3-0.3,0.7-0.7,1-1c4-2.3,8-4.7,12-7
		C664,775.7,666.3,775.9,668.5,775.9z"/>
	<path class="st3314" d="M674.6,772c-0.4,0.8-1.2,1-2,1c-1.3,0.4-2.7,1.4-3-1c-0.1-1.7,1.4-2.6,2-4c1.6,0.2,3.7-0.7,4,2
		C676.2,771.1,675.1,771.4,674.6,772z"/>
	<path class="st3638" d="M669.6,772c0.7,1.2,1.9,1,3,1c-1.3,1-2.7,2-4,3c-1-1.6-2.8-0.5-4.1-1.4C665.6,772.7,668,773.1,669.6,772z"
		/>
	<path class="st3639" d="M659.7,798.1c1.6-0.4,3.2-0.9,4.8-1.3c-2.6,3.7-7.3,4.3-10.4,7.4c-2.5,2.5-1.9,3.6,1.4,3.8
		c-1.3,0.7-2.7,1.3-4,2c-0.8-1.2-1.9-0.7-3-0.7c-0.5-1.2-1.9-0.9-2.6-1.7c-0.8-3.1,2.1-3.7,3.6-5.3c2.2,0.1,4.4-0.2,6-2
		C656.8,799.1,658.5,799,659.7,798.1L659.7,798.1z"/>
	<path class="st3640" d="M648.6,808.9c1.7-1.8,2.8-1.8,3,1c-0.7,0.3-1.3,0.6-2,0.9c-1,0.4-2,0.8-2.9,1.1
		C645.6,809.9,646.3,808.9,648.6,808.9z"/>
	<path class="st3641" d="M648.6,808.9c-1.3,0.6-2,1.6-1.9,3.1c0,0.3-0.1,0.6-0.1,0.9c-2,1.1-1.6,4.1-3.9,5.1c0,0,0,0,0,0
		c-0.3-0.3-0.7-0.7-1-1c-2-3.7-1.4-5.2,3.6-9.2c0.5-0.2,0.9-0.1,1.3,0.2C647.6,807.5,648.7,807.1,648.6,808.9z"/>
	<path class="st3279" d="M642.6,817.9c1.2-1.7,1.2-4.5,3.9-5.1c-0.3,1-0.6,2.1-0.9,3.1c-0.4,0.7-0.8,1.4-1.2,2.1
		C643.8,818,643.2,818,642.6,817.9z"/>
	<path class="st3642" d="M646.5,821.3c0.3-3.2,0.9-6.7,4.7-7.1c2.6-0.3,3.3,2.7,3.2,4.8c-0.2,3-1.6,5.5-5,5.9
		C646.8,825.2,646.4,823.4,646.5,821.3z"/>
	<path class="st3643" d="M529.7,510.4c0,0.3,0,0.6,0,0.9c-0.8-0.1-1.6-0.2-2-1.1c0.1-2.1-2.2-1.7-3-2.9c1-0.1,2-0.1,3-0.2
		c1.2-1.2,2.7-1.3,4.2-1.2c6.9,0.2,13.5-1.3,20.2-2.4c0.9-0.2,1.7-0.2,2.6,0.1c2,1.1,1.1,2.3,0.2,3.5c-6.6,3.3-13.6,3.8-20.8,3.4
		C532.6,510.6,531.1,509.2,529.7,510.4z"/>
	<path class="st3644" d="M519.7,501.3c-3.5-0.3-5.6-2.9-8-5c-2.3-1.9-2.2-5.5-5.2-7c-1.2-0.6-0.4-2.3,0.4-3c3.1-3,4.4-6.8,4.8-10.9
		c2.9-4.7,3.3-10.2,4.9-15.4c0.8-2.4,1.3-4.7,3.1-6.6c0.2,0.2,0.4,0.3,0.6,0.5c2.1,2.9,1.1,6,0.4,9c-0.5,2.1-0.6,4.2-1,6.3
		c-0.7,3-0.9,5.8,1.3,8.4c3.1,3.7,2.7,7.8,0.6,11.6c-1.4,2.7-2.3,5-0.7,7.8C521.7,498.6,521.8,500.3,519.7,501.3z"/>
	<path class="st3645" d="M519.7,453.3c-0.9-2-1.5-4,0-6c2.2-4.6,0.8-9.9,2.4-14.6c0.3-0.5,0.8-0.7,1.4-0.7c0.5,0.3,0.9,0.6,1.2,1.1
		c1.8,5.2,2.6,10.6,3.7,16c-0.3,2.4-0.7,4.8-3.7,5.4C522.8,455,521.1,454.9,519.7,453.3L519.7,453.3z"/>
	<path class="st3646" d="M522.7,432.4c-0.4,5.1,1.3,10.6-3,15c-0.3-2-0.7-4-1-6c0.4-6.6,0.7-13.3,1.1-19.9c2.4,0.2,2,2.2,2.2,3.7
		C522.2,427.5,522.3,430,522.7,432.4z"/>
	<path class="st3647" d="M519.7,501.3c0.2-0.6,0.9-1.5,0.7-1.8c-3.8-4.8-1.3-8.7,0.9-13.2c1.2-2.4,1.1-5.8-1.7-8.1
		c-2.9-2.6-2.5-5.7-1-8.9c3.4-1.9,4.8,0.6,6.4,2.8c0.6,4.5,4.4,8.2,3.5,13.1c-0.2,3,1.9,4.8,3.8,6.6c1.4,1.3,2.5,2.6,3.2,4.3
		c1.4,3,2.4,6.4,0.1,8.9c-2.4,2.6-6,1.7-9.1,0.2C523.7,505.3,521.3,504,519.7,501.3z"/>
	<path class="st3648" d="M526.7,505.3c0.3,0,0.7,0.2,1,0.1c2.7-0.4,6.5,2.1,7.7-1.7c1.2-4-0.4-7.6-3.9-10.7
		c-1.9-1.7-6.2-3.4-3.8-7.7c2-1.4,1.9-5.3,5.6-4.9c0.5,0.1,1.2,0.1,1.4,0.5c3,5.4,7,10.2,9.9,15.5c2.4,4.4,1.7,4.8,7,2.6
		c1.9,0,2.7,1.2,3.4,2.7c0,1.6-1.9,1.4-2.4,2.6c-8.1,2.9-16.4,3.4-24.8,2.9C527.4,506.5,527.1,505.9,526.7,505.3z"/>
	<path class="st3649" d="M594.7,149.8c-2.7-0.3-5.2,2.6-8.1,0.8c1.7-0.6,3.3-1.3,5-1.9c0,0,0.1,0,0.1,0c1.5-1.4,3.2-0.5,4.9-0.3
		c0.3,0.1,0.6,0.3,1,0.4C596.9,149.9,595.7,149.5,594.7,149.8z"/>
	<path class="st3650" d="M620.5,144.7c-2.8,1.9-5.9,0.6-8.8,0.9c0-0.2,0-0.4,0-0.6c2.6-0.1,5.2-0.2,7.8-0.4
		C619.8,144.7,620.1,144.7,620.5,144.7z"/>
	<path class="st3651" d="M611.7,145c0,0.2,0,0.4,0,0.6c-1.1,2.1-3.9,0.2-5.1,2.1c-0.4-0.1-0.7-0.1-1.1-0.2c-0.4-0.2-0.8-0.5-1-0.9
		C606.7,145,609.4,146,611.7,145z"/>
	<path class="st3359" d="M596.6,148.4c-1.6,0.1-3.3,0.2-4.9,0.3c0.5-3.3,2.4-1.5,4-1.1C596,147.8,596.3,148.1,596.6,148.4z"/>
	<path class="st3652" d="M604.5,146.6c0.3,0.3,0.7,0.6,1,0.9c-1.9,1.1-3.8,0.9-5.8,0.2C601.1,146.1,602.9,146.8,604.5,146.6z"/>
	<path class="st3653" d="M566.7,134.7c2.6,0,5.2,0,8.5,0c-2.5,1.8-4.8,0.3-7.2,1.7c2.5,0.2,4.4,0.3,7.2,0.5
		c-4.7,2.3-2.2,8.4-7.1,10.3c-0.8,0.3-0.3,1.7,0.5,2.5c1,1,2.4,2.3,1.3,3.8c-1.1,1.4-2.6,2.1-4.8,1.5c-3.7-1-0.6,1-0.6,1.6
		c-1.1,1.5-2.5,2-4.2,1.3c-4.5-3.8-5.7-8.6-4.6-14.1c0.5-2.2,1.1-4.5,1.3-6.7c0.4-1,1.3-1.4,2.1-2
		C561.8,134.5,564.2,133.2,566.7,134.7z"/>
	<path class="st3654" d="M566.7,134.7c-2.3,0.3-4.7,0.7-7,1c-3.6,1.1-6.9,3.8-11,2c0-0.3,0-0.7,0-1c1-0.4,2-0.7,3-1.1
		c2.5-0.1,4.9-0.7,7.1-1.9c6.3-1,12.7-2,19-3c2-0.1,3.9-0.1,5.9-0.2c6.3,0,12.7,0,19,0c1.7,0.1,3.4,0.2,5.1,0.4
		c-0.9,2.7-2.8,1.3-4.5,1.1C590.9,131,578.8,132.1,566.7,134.7z"/>
	<path class="st3655" d="M560.7,157.7c1.3-0.3,2.7-0.7,4-1c0.7,0.1,1.5,0.1,2.2,0.2c0.1,0.4,0.1,0.8-0.2,1.2
		c-0.8,0.7-1.7,1.1-2.6,1.7c-0.9,0.5-1.8,1.2-2.8,1.4c-0.4,0-0.8-0.1-1.2-0.4c-0.3-0.3-0.5-0.7-0.7-1.1c0-0.7,0.2-1.2,0.7-1.7
		C560.3,157.8,560.5,157.7,560.7,157.7z"/>
	<path class="st3656" d="M576.6,154.6c-0.6,0.4-1.3,0.8-1.9,1.2c-1,1.4-2.3,2.1-4,1.2c-0.4-0.4-0.5-0.9,0-1.3
		C572.5,154.6,574.4,153.8,576.6,154.6z"/>
	<path class="st3657" d="M582.6,152.6c-1.7,1.7-4,1.4-6,2C577.9,151.9,580.1,151.6,582.6,152.6z"/>
	<path class="st3658" d="M570.7,155.7c0,0.4,0,0.8,0,1.1c0,1.5-1.1,1.6-2.1,1.8c-0.8,0-1.5-0.3-2.1-1c0.2-0.2,0.3-0.5,0.4-0.8
		C568.2,156.4,569.4,156,570.7,155.7z"/>
	<path class="st3359" d="M591.1,140.9c-2.6-0.2-4.2,4.7-6.7,1.8c-1.3-1.4-2.3-5.9,2.8-4.3C588.3,138.8,589.8,139.4,591.1,140.9z"/>
	<path class="st3659" d="M548.7,137.7c3.7-0.7,7.3-1.3,11-2c-0.4,0.8-1.2,1-2,1c-4.1,1.8-4.5,8-9.9,8.4c-0.9,0.1-0.8,1.3-0.6,2.1
		c0.6,2.6-0.9,3.6-3,4.3c-3,1.1,0.5,2.7-0.3,4c-1.1,1.3-2.4,1.2-3.9,0.8c-2.1-1.3-4.3-2.4-6.3-3.8c-1.8-1.3-1.8-3.6,0.4-3.3
		c4.9,0.7,4.2-4.6,7-5.7c-0.7-0.3-1.7,0.1-2.3-0.5c-0.3-0.4-0.3-0.8,0-1.3C542,140.4,545.4,139,548.7,137.7z"/>
	<path class="st3370" d="M538.7,141.7c0,0.3,0,0.7,0,1c-2,2.2-4.2,3.6-7,1l-0.1-0.1c-0.5-1.9,1.6-1.1,2-2c0.3,0,0.5-0.1,0.8-0.1
		C535.9,141.6,537.3,141.6,538.7,141.7z"/>
	<path class="st3660" d="M533.7,141.6c-0.7,0.7-1.3,1.3-2,2c-1,0-2,0-2.9,0.1c0-0.4,0-0.7,0-1.1C530.4,142.3,532.1,142,533.7,141.6z
		"/>
	<path class="st3661" d="M531.7,143.7c2.5,1.1,4.6-0.7,7-1c1.3,0,2.6,0,4.3,0c-1.1,1.4-2.8,1.8-2.9,3.3c-0.3,4.5-2.7,5.5-7.3,3.4
		c1.7,3.8,6.2,3,7,6.3c1.9,3.7,4.7,7,5.3,11.3c-0.6,2.1-2.6,2.5-4.1,3.4c-2.6,1-4.7,2.9-7.3,4c-0.9,0.3-1.9,0.4-2.9,0.2
		c-1-0.2-1.7-0.7-2.2-1.6c-0.5-1.3,1.6-2.5,0.1-3.8c-0.6-2.2-3-2.9-4-5c-2.4-5.2-1.4-9.9,1.3-14.6c1.6-0.8,1.1-3.1,2.8-3.9
		C529.7,145,530.7,144.4,531.7,143.7z"/>
	<path class="st3662" d="M528.7,172.6c0.7,0.4,1.5,0.7,2.2,1.1c0.2,0.5,0,0.9-0.3,1.2c-1.9,0.7-4,0.3-5.9,0.2
		c-5.9-0.3-7.5-5.7-9.8-9.6c-1.5-2.5-2.3-5.4-4.1-7.9c2.7-3.1,4.9-7.3,10.2-4.2c0.6,0.3,1.3-0.1,1.5-0.8c0.5-1.8-1.3-2.5-1.7-3.9
		c0.3,0,0.6,0,1,0c1.9-0.7,3.5-0.3,5,1c-1.6,4.5-3.2,8.9-1.1,13.9c1,2.4,5.6,2.2,4.1,6.1C529.4,170.7,528,171.3,528.7,172.6z"/>
	<path class="st3624" d="M526.7,149.7c-1.7-0.3-3.3-0.7-5-1c2.2-1.3,4.5-2.5,7-3C527.3,146.6,531.4,150.4,526.7,149.7z"/>
	<path class="st3663" d="M460.8,234.6c-1.3-1.4-0.4-3.7-2-5c0.9-4.3,3.3-8.2,3-12.8c0.7-0.3,1.4-0.7,2-1c6.6-0.6,12.7-3.1,18.9-5.1
		c1.4,2.8-1.6,3.5-2.5,5.2c-4.7,3.9-8.5,8.8-12.9,13.1c-2.1,1-3,3.1-4.2,4.9C462.5,234.5,461.8,235.1,460.8,234.6z"/>
	<path class="st3664" d="M488.8,207c0.2-2,2.4-2.8,2.9-4.6c1.4-1.3,2.8-2.5,4.2-3.8c2.6-1.7,5.1-3.4,7.7-5.1
		c0.9-0.4,1.3,0.2,1.6,0.9c-0.2,3.1-3.3,3.7-4.8,5.8c-2.5,2.1-5.1,4-7.5,6.1C491.6,206.9,490.4,207.7,488.8,207z"/>
	<path class="st3665" d="M495.8,192.6c-0.7-1.9-2.6-2.6-4.1-3.4c-5.7-3.2-5.1-7.6-2.9-12.5c0.8,0.2,7.5,5.1,7.7,6
		c0.5,2.1,1.9,1.9,3.6,2.4c3,0.8,6.6,1.2,8.2,4.6c-0.6,2.4-2.1,3.4-4.5,3c-1.1-3.8-2.8-1.7-4.2-0.3
		C498.3,193.7,497.2,194.5,495.8,192.6z"/>
	<path class="st3666" d="M503.8,192.7c1.6-0.6,3-1.5,4.1-2.8c0.3-0.5,0.6-0.6,1.1-0.3c0.5,0.6,0.6,1.3,0.6,2
		c-0.1,0.5-0.2,0.9-0.4,1.4c-1.1,1.3-2.4,2.3-4.3,1.8c-0.4-0.4-0.8-0.8-1.2-1.2C503.7,193.3,503.8,193,503.8,192.7z"/>
	<path class="st3667" d="M479.7,215.6c-0.2-2.4,3-2.6,3.1-4.9c1.3-0.7,2.6-1.4,4-2.2c1.3,0.2,2.3,0.6,2.6,2.1
		c-0.4,3-3.5,3.7-5.1,5.7c-1.4,0.9-2.7,2.2-4.6,1.4C479.2,216.9,479.2,216.2,479.7,215.6z"/>
	<path class="st3668" d="M488.7,210.6c-0.7-0.7-1.3-1.4-2-2.1c0.7-0.5,1.4-1,2.1-1.5c1.3-0.6,2.5-1.2,3.8-1.8c0.7,0.5,0.9,1.1,1,1.9
		c0,0.5-0.2,1-0.4,1.4C491.9,209.8,490.8,211.2,488.7,210.6z"/>
	<path class="st3669" d="M609.5,204.9c0.7,0.6,1.3,1.3,2,1.9c2.9,3,5.8,6,8.6,8.9c1.1,2.3,2.8,4,4.6,5.8c2.7,4,5.3,7.9,8,11.9
		c-0.2,0.2-0.3,0.3-0.5,0.5c-2.5,0.5-4.2-1-5.4-2.7c-5.7-8.2-11.6-16.3-19.3-22.7c-1.3-1.1-2-2.6-2.4-4.2c0-1.1,0.4-2,1.6-2.1
		C609.1,201.6,608.9,203.6,609.5,204.9z"/>
	<path class="st3669" d="M636.1,237.8c1,2,1.8,4.2,3.5,5.7c1.6,0.9,2.6,2.1,2.3,4.1c-0.3,0.4-0.6,0.9-1.1,0.9
		c-5.6,0.1-6.7-5.1-9.3-8.2c-1.6-1.9-1.2-4.7,1-6.7l0.3-0.2C633.9,234.9,635,236.4,636.1,237.8z"/>
	<path class="st3670" d="M641.4,247.6c-0.6-1.3-1.2-2.7-1.8-4c0.7-1,1.4,0,2.1,0c4.8,6.6,7.5,14.2,11.8,21.1
		c0.7,2.3,1.4,4.6,2.1,6.9c-0.4,2-1.4,2.3-2.9,1c-2.4-1.9-2.3-5.2-3.9-7.6c-1.9-5.6-4.9-10.7-7.6-15.9
		C641.2,248.5,641.2,248.1,641.4,247.6z"/>
	<path class="st3671" d="M609.5,204.9c-0.8-0.8-1.7-1.6-2.5-2.5c-0.8-0.1-0.9-0.4-0.4-0.9c0.4-0.4,0.9-0.7,1.3-1.1
		c0.6,0.6,1.2,1.3,1.9,1.9C609.7,203.2,609.6,204,609.5,204.9z"/>
	<path class="st3672" d="M641.4,247.6c0.3,0.2,0.4,0.4,0.3,0.7c-0.3,1.8,0.2,3.5,1.4,4.9c2.6,3,4.5,6.5,4.5,10.4
		c0,3.3,1.9-0.3,2.2,0.9c1.6,2.4,2.4,5.2,3,8c-0.2,1.4-0.8,2.6-2.3,3c-4.7,2-8.7,5.1-12.9,7.9c-1.3,0.9-2.4,2.3-4.4,1.6
		c-1.1-0.7-0.8-1.8-0.4-2.7c0.9-2.1-0.1-3-1.9-3.7c-5.2-2-8.1-6.1-11-10.9c-3-5-6.2-10-8.9-15.1c-3.6-6.9-6.8-14.1-8.7-21.8
		c-1.1-4.4-4.2-8.3-6-12.6c-2.3-5.4-6.8-9.9-6-16.7c0.4-3.4,2.5-5,4.4-7.1c4.4,0.3,6.4,4.6,10.1,6.1l0,0c0.5,1.3-0.5,3,0.8,4.1
		c6.2,6.5,12.5,12.8,18,20.1c2.3,3,3.3,8.7,8.8,9c-0.5,2.2-1.6,5.8-0.2,6.4C636.2,241.9,635.7,248.5,641.4,247.6z"/>
	<path class="st3673" d="M634.6,287.5c0.4,0.4,0.7,0.7,1.1,1.1c-1.4,4.5-5.9,5.9-9,8.7c-3.5,3.2-7.1,6.5-10.4,9.9
		c-10,10.4-19.4,21.4-27,33.8c-4.3,6.2-8.4,12.6-11.3,19.6c-0.7,1.7-1.4,3.4-3.2,4.2c-1.1,0.2-1.9-0.4-2.5-1.3
		c-0.5-4.5,2.5-7.8,4.4-11.3c4.9-9.2,11-17.7,17.1-26.1c10-14.5,22.4-26.5,36.4-37.1C631.4,287.9,632.7,286.8,634.6,287.5z"/>
	<path class="st3674" d="M634.6,287.5c-10.1,6-18.2,14.3-26.3,22.7c-5,5.1-9.7,10.4-13.7,16.4c-0.9,0.3-1.2-0.2-1.5-1
		c0-3.7,3.4-5.4,4.8-8.3c0.7-1.2,1.4-2.4,2.5-3.3c2.6-2.2,5.3-4.3,6.5-7.7c3-2.3,5.8-4.9,8.1-7.9c2.8-2.7,6.3-4.6,9.1-7.3
		c3.1-2.3,5.3-5.8,9.5-6.4c3.9-2.6,7.7-5.3,11.7-7.7c1.5-0.9,3.4-0.9,5.1-1.3C645.9,280.4,639.1,282.5,634.6,287.5z"/>
	<path class="st3675" d="M615.6,306.6c5.5-7.3,13.2-12.1,20-18c3.5-0.7,5-5,9-4.8c0,0.3,0,0.6,0,0.9c0.4,2-1.1,3.1-2.5,3.9
		c-8.2,4.7-15.1,11.2-21.9,17.6c-1,0.9-1.9,1.8-3.4,1.9C616,307.8,615.5,307.5,615.6,306.6z"/>
	<path class="st3676" d="M604.8,200.5c-4.1-0.3-6.7-3-9.2-5.8c-4.7,0.1-6.9-4.1-10.4-6.2c-2.2-1.3-4.3-2.9-6.7-4.1
		c-5.6-2.5-9.8-7.5-16.1-9c-1.4-0.3-2.6-1.4-3.4-2.7c-0.4-1-0.4-2,0.2-2.9c0.4-0.5,0.8-0.5,1.3-0.1c6.4,4.7,13.7,7.9,21,10.9
		c4.1,5.1,10.2,7.7,15.1,11.9C599.4,195.2,602.1,197.8,604.8,200.5z"/>
	<path class="st3677" d="M560.6,169.7c-0.3,0-0.6,0-0.9,0c-1.3,1.6-2.5,3.3-4.9,2c-0.3-3,1.8-3.1,3.9-3.1c0.7,0.1,1.5-0.6,2.2,0.2
		C560.8,169,560.7,169.4,560.6,169.7z"/>
	<path class="st3676" d="M605.6,204.6c-2.3-1-1.9-2.5-0.8-4.1c0.6,0.4,1.2,0.7,1.8,1.1c0.2,0.3,0.3,0.6,0.4,0.9
		C606,202.8,605.7,203.6,605.6,204.6z"/>
	<path class="st3678" d="M558.8,165.8c-0.7,0.6-1.5,1.2-2.2,1.8c-3.8,4.4-9.4,6-14.1,9c-1.5,0.7-3,1.1-4.4,1.9
		c-0.9,0.4-1.7,0.8-2.7,0.5c-1.6-1.3-0.5-2.9,0.5-3c2.5-0.4,1.8-1.8,1.4-3.2c0.8-1.7,2.6-2,4-2.8c3.1-1.2,5.4-3.7,8.3-5
		c2-0.6,3.8-1.8,6-1.8C557,163.6,558.3,164.1,558.8,165.8z"/>
	<path class="st3679" d="M568.8,157.7c0.6-0.3,1.3-0.6,1.9-0.9c1.3-0.3,2.7-0.6,4-1c2.7,2.7,5.3-1.4,8-0.2c-1,0.6-2.1,1.3-3.1,1.9
		c-3.5,0.9-6.9,2.3-10,4.1c-1.3,0.4-2.6,0.7-3.8,1.1c-1.4-0.6-1.9-1.8-2.2-3.2C565.1,158.4,567,158.3,568.8,157.7z"/>
	<path class="st3680" d="M541.8,175.8c4.9-2.7,9.9-5.4,14.8-8.2c0.7,0.3,1.4,0.6,2.1,0.9c-1.4,1-2.8,2-4.2,3
		c-0.1,0.2-0.2,0.4-0.2,0.6c-2.5,2.3-5.6,3.6-8.5,5.3c-0.5,0.1-0.9,0.2-1.4,0.2C543.2,177.4,541.7,177.6,541.8,175.8z"/>
	<path class="st3681" d="M563.7,159.8c0.7,1,1.4,1.9,2.1,2.9c-2,1.6-4.3,2.8-6.9,3.1c-1-0.7-2.1-1.4-3.1-2.1
		c0.9-2.4,2.9-2.8,5.1-3.2c1.1-0.4,1.4-2,2.9-1.9C564.3,159.1,564.3,159.4,563.7,159.8z"/>
	<path class="st3682" d="M552.7,531.2c2.2-1.1,4.4-2.4,7-2c0.5,1.4-0.7,2-1.4,2.9c-1.8,1.9-4.7,1.6-6.6,3.1c0,0,0,0,0,0
		c-4.3,0.3-7.5,4.1-12,4C543.5,535.7,548,533.3,552.7,531.2z"/>
	<path class="st3683" d="M587.7,521.3c-3.7,0-7.3,0-11,0c-5-0.1-10,0.2-15-1.1c0-0.3,0-0.6-0.1-0.9c0.6-0.8,1.4-1.1,2.3-1.3
		c7.5,1.2,15.1,0.3,22.6,1.2c0.6,0.2,1,0.6,1.3,1.1C587.8,520.7,587.7,521,587.7,521.3z"/>
	<path class="st3684" d="M563.7,519.1c-0.7,0.1-1.4,0.2-2,0.3c-8.8-1.8-17.9-2.6-26.1-7c0.6-1.4,1.8-1.7,3.2-1.4
		c-2-0.6-4.6,1.8-6-1.4c7.4-0.5,14.9-0.2,21.9-3.3c2.6-2,5.9-2.4,8.8-3.6c0.5-0.2,1-0.2,1.5-0.2c1.1,0.2,2,0.5,2,1.9
		c-2.1,3.9-6.4,3.4-9.7,5.1c-3.8,1.3-7.9,1.7-12.3,2.9c1.7,1.9,3.6,1.6,5.2,2.2c4.3,1.3,9.2,0.3,13.2,3.1
		C563.8,518.1,563.8,518.6,563.7,519.1z"/>
	<path class="st3685" d="M532.8,509.5c2.1,1.5,4.5,0.2,7.2,1.1c-1.7,0.7-3,1.2-4.4,1.7c-2-0.6-3.9-1.3-5.9-1.9
		C530.1,508.1,531.7,509.8,532.8,509.5z"/>
	<path class="st3686" d="M557.7,531.2c0.7-0.7,1.3-1.3,2-2c1.4,0.1,2.6-0.2,3.7-1.1c2.9-2.6,6.3-3.2,10.1-2.9c5,0.5,9.3-2.6,14.2-3
		c1.9-1.5,3.8-1.6,5.8-0.4c0.9,1.7-0.5,2-1.5,2.4c-9.8,3.3-20.3,4.3-30.1,7.9C560.4,532.6,558.9,532.4,557.7,531.2z"/>
	<path class="st3687" d="M593.7,522.3c-2,0-4,0-6,0c0-0.3,0-0.6,0-1c0,0,0,0,0,0c3.9-2.1,7.8-1.9,11.7-0.2
		C598,523.9,597.4,524,593.7,522.3z"/>
	<path class="st3688" d="M672.5,733c18.6-11,37.6-21.3,57-30.9c0,0.3,0,0.6,0,1c-6.1,3.6-12.2,7.2-18.3,10.9
		c-9.6,4.4-18.7,9.7-27.7,15.2c-3.6,1.7-7.3,3.4-10.9,5.1c-0.3-0.2-0.4-0.5-0.3-0.8C672.4,733.1,672.4,733,672.5,733z"/>
	<path class="st3689" d="M574.7,807.9c-3.1,2.1-6.3,4.1-10,5.1c2.3-3.8,7.1-3.9,10.1-6.7C574.9,806.2,574.8,807.3,574.7,807.9z"/>
	<path class="st3690" d="M672.5,733c0,0.4,0.1,0.8,0.1,1.2c-1.7,1.4-3.7,2.3-5.8,3c-3-0.7-4.9,3.7-8.2,1.9c-0.8-1.9-0.2-3.5,1-5l0,0
		c0.9-1.3,2.2-2.3,3.3-3.4c0.5-0.4,1.1-0.8,1.7-1c1.4-0.2,2.9,0.2,4.1-1c0.6-0.6,1.1-1.2,1.9-1.6c0.3,0.7,0.7,1.3,1,2
		C673,730.1,667.1,732.8,672.5,733z"/>
	<path class="st3691" d="M659.6,734c-0.9,1.6,0.4,3.5-1,5c-6.6,3.8-12.8,8.2-20,11c-2.1,0.7-4.3,1.4-6,3c-1.7-0.1-2.6,1.4-4,2
		c-1.9,0.4-2.9-0.9-4-2c8.6-4.7,17.2-9.4,25.9-14.1C653.5,737.3,656.5,735.6,659.6,734z"/>
	<path class="st3444" d="M632.6,753c1.2-2.6,3.4-3.3,6-3c-1.3,1-2.7,2-4,3C633.9,753,633.3,753,632.6,753z"/>
	<path class="st3692" d="M604.6,756c0.3,0,0.7,0,1,0c7.5-4.5,15.6-7.9,23.3-12.1c1.3-0.7,2.7-1.3,3.6-2.6c1.6-2.1,3.7-3.3,6.2-3.7
		c2-0.4,3.6-1.7,5-3.1c4.8-4.8,4.8-4.8,11.3-2c0.2,0.2,0.3,0.4,0.5,0.6c-10.8,6-21.5,12-32.4,17.9c-8.8,4.8-17.7,9.4-26.6,14
		c0-0.7,0-1.3,0-2C599.5,760.8,601,757.2,604.6,756z"/>
	<path class="st3692" d="M663.6,731c-0.9,1.6-1.9,2.9-4,3c-1.1-1.5-2.6-0.9-4-1c0,0,0,0,0,0c-0.8-1.4-3.7-0.9-3.3-3.4
		c0.2-1.1,1.3-1.2,2.3-1.2C657.6,729.2,661.5,727.1,663.6,731z"/>
	<path class="st3693" d="M604.6,756c-3.2,1.8-3.8,6.4-8,7c-1,0.7-2,1.3-3,2c-0.1-2.1-0.1-3.9,2.8-4.2
		C599.8,760.4,600.7,755.5,604.6,756z"/>
	<path class="st3694" d="M460.8,234.6c0.7-0.3,1.3-0.7,2-1c0.7,0.2,1.1,0.6,1.3,1.3c0,1.4-1,2.4-1.6,3.6c-1.3,1.5-2.2,3.6-4.7,3.3
		c0-0.7-0.1-1.5-0.1-2.2C458.8,237.9,459.8,236.2,460.8,234.6z"/>
	<path class="st3695" d="M453.8,253.6c-1.5,2.1-2.8,4.4-4.9,6c-2-2.4,1.5-3.9,1-6.1c1.6-3,3.2-5.9,5.9-8.1c0.8,0.2,1.2,0.7,1.4,1.4
		C456.5,249.3,455.1,251.4,453.8,253.6z"/>
	<path class="st3696" d="M456.8,246.6c-0.4-0.4-0.7-0.8-1.1-1.2c0.7-1.2,1.4-2.4,2.2-3.7c1.3-1.4,2.6-2.8,4-4.1
		c0.9-0.3,1.2,0.2,1.4,0.9c-0.3,2.7-2.5,4.2-3.7,6.3C458.8,245.6,458,246.4,456.8,246.6z"/>
	<path class="st3697" d="M464.8,270.5c0.3-3.8-2.8-7.3-1.1-11.3c0.1-0.2-0.1-0.8-0.3-0.9c-6.1-2.2-0.9-5.2-0.6-7.8
		c0.8-2.2-1.7-3.5-1.4-5.5c0.1-0.5,0.3-0.9,0.6-1.2c2.3-1.6,4.4-3.4,5.8-6c0.9-1.7,2.5-3.1,4.8-2.9c2.6,0.2,5.3,1.9,7.7-0.9
		c1.2-1.4,2.6,0.1,3.9,0.8c-0.4,4.2-3.9,6.4-6.3,9.2c-1.6,1.7-1.7,3.9-2.2,5.9c-0.1,0.4-0.3,0.8-0.4,1.3c-1,1.8-1.1,3.9-2.1,5.7
		c-3.5,2-4,5.9-5.4,9.2C466.7,267.5,467.6,270.3,464.8,270.5z"/>
	<path class="st3698" d="M464.8,270.5c1.9-1.2,0.1-3.8,2-5c1.7,1.7,1.3,3.8,1,5.9c-0.3,1.3-0.6,2.6-0.7,3.9
		c-0.5,4.9-1.1,5.4-6.3,5.2c-0.9,0.5-2,0.8-2.5,1.5c-2.6,4.2-4.6,2.3-6.5-0.5c2.3-4.5,4-9.1,2.3-14.5c2,1.1,3.5,2,4.1,4
		c0.7,2.4,3,2.2,4.8,2.4C464.9,273.5,464.7,271.8,464.8,270.5z"/>
	<path class="st3456" d="M461.7,244.5c0.4,2,3.3,3.6,1.1,6c-0.4-0.6-1.5-1.7-1.6-1.1c-0.9,4.9-2.3-0.3-3.3,0.2c0,0-0.1-0.2-0.1-0.2
		c-0.6-1.8,0.8-2.9,1.6-4.2C460,244.6,460.7,244.1,461.7,244.5z"/>
	<path class="st3699" d="M466.8,271.5c0-2,0-4,0-6c1.6-3.2,0.7-8.2,6-9c1.1-1.9,1-4.1,2-6l0,0c0.5-2.4,1.9-4.5,2-7
		c2.8-2.7,5.3-5.5,7-9c-0.2-0.3-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.1-0.5c0.4-0.7,1-1.1,1.7-1.3c0.8,0,1.5,0.2,1.9,0.9
		c0.5,1.7-0.2,3-1,4.4c-9.5,16-15.9,32.8-15.1,51.8c0,0.5,0,1,0,1.6c-0.1,2.1-0.1,4.2,0.6,6.2c0.1,3,1.8,5.9,0.2,8.9
		c-0.8,0.5-1.6,0.4-2.4,0c-2.5-4.5-2.8-9.2-1.7-14.1c-0.7-1.7-1.3-3.3-2-5C465.4,281.5,466.3,276.5,466.8,271.5z"/>
	<path class="st3161" d="M467.8,291.5c-0.6,4.8,1.5,9.3,2,14c0.6,0.6,0.6,1.3,0.2,2.1c-0.4,0.3-0.8,0.5-1.3,0.5
		c-2.2-0.3-3.4-1.9-4.4-3.6c-1.1-1.9-2.2-3.8-4.8-3.6c-3.2,0.2-1.1,3.4-2.6,4.6c-3.7-0.2-6.9-3.6-11-2c-0.3-2.6-0.1-4.5,3.4-5.1
		c2.4-0.4,5.7-1,5.6-5c0-1.5,1.2-0.6,1.7-0.4c2.4,0.8,4.6,1.8,7.2,1.6l0,0c0.7,2.3,1.4,4.7,2.5,8.1
		C467.1,298.2,465,294.5,467.8,291.5z"/>
	<path class="st3697" d="M466.8,271.5c-0.6,5,1.2,10.1-1,15c-1-2.5-4.1-3.3-5-6C466.9,280.2,465.2,274.8,466.8,271.5z"/>
	<path class="st3698" d="M463.8,294.5c0.2-1.3-0.4-2.8,0.7-4.5C465.3,292,467.3,293.6,463.8,294.5
		C463.8,294.5,463.8,294.5,463.8,294.5z"/>
	<path class="st3700" d="M460,245.7c-0.8,1.3-1.5,2.6-2.3,3.8c-1.3,1.3-2.7,2.7-4,4c0.4-2.6,1.6-4.9,3-7c0.7-0.7,1.3-1.4,2-2.1
		C459.4,244.6,459.9,245,460,245.7z"/>
	<path class="st3526" d="M454.8,350.4c1,1.4,1.9,2.7,2.9,4.1c-1.9-0.2-3-1.5-3.9-3.1C454.1,351.1,454.4,350.7,454.8,350.4z"/>
	<path class="st3701" d="M457.8,347.3c0.6,1.9-0.5,2.1-2,2.1c-0.3-0.4-0.7-0.7-1-1.1C455.5,347.3,456.7,347.5,457.8,347.3z"/>
	<path class="st3702" d="M465.8,330.5c-0.3,0-0.7,0-1,0.1c-4.5-2.4-8.7-5.2-11-10c1.8-4,5.4-1.2,8-2c1.5,1.2,4.3,1,4.2,4
		c-0.3,0.3-0.7,0.6-1.2,0.7c-1,0.3-2.6,0-1.1,1.9C464.9,326.6,467.2,327.9,465.8,330.5z"/>
	<path class="st3703" d="M465.8,322.5c-1.3-1.3-2.7-2.6-4-4c2-0.7,4-0.7,6,0c4.4,1.3,6.1,6,9.9,8.1c0.9,1,2.5,0.8,3.1,2.2
		c0,0.8-0.5,1.2-1.2,1.5c-0.7,0.2-1.4,0.2-2.1,0C473.6,327.7,469.7,325.1,465.8,322.5z"/>
	<path class="st3704" d="M453.8,320.5c3.7,3.3,7.3,6.7,11,10c0,0.3,0,0.6,0,1c-1.1,0.7-2.3,0.2-3.4,0c-3-1.8-5.8-3.9-7.5-7
		c-0.9-2.9-5.7-2.9-5-6.9C450.5,318.5,452.1,319.5,453.8,320.5z"/>
	<path class="st3705" d="M468.8,307.5c0.3,0,0.7,0,1,0c2.7,1.5,2.1,5.4,4.9,6.8c2,1.6,2.6,3.9,2.9,6.3c-0.1,0.8-0.3,1.4-1.1,1.8
		c-1.9-1.7-3.7-3.4-5.6-5c-0.4-0.6-0.7-1.3-1.1-1.9c-0.3-2-1.3-3.9-1-6C468.2,308.8,468.2,308.2,468.8,307.5z"/>
	<path class="st3706" d="M476.5,322.5c0.1-0.7,0.3-1.3,0.4-2c3,0.1,3.6,2.6,4.4,4.8c-0.1,0.8-0.4,1.3-1.2,1.6
		c-0.5,0-0.9-0.1-1.3-0.5C478.7,324.7,477.3,323.7,476.5,322.5z"/>
	<path class="st3707" d="M478.9,326.4c0.3,0.1,0.6,0.1,0.9,0c0.5,0.3,1,0.7,1.2,1.3c0.1,0.4-0.1,0.7-0.5,0.9
		c-1.3-0.1-2.7-0.3-2.8-2.1C478.1,326.5,478.5,326.4,478.9,326.4z"/>
	<path class="st3708" d="M468.8,307.5c0,0.7,0,1.3,0,2c-4.5,0.2-7.9-2.8-12-4c0.2-2-2.9-4.7,0.7-5.8c2.8-0.9,6-0.7,7.2,3.1
		C465.4,305,467,306.3,468.8,307.5z"/>
	<path class="st3709" d="M453.8,324.5c2.7,2,5.3,4,8,6c2.3,1.4,1.7,3.2,0.7,5.1c-0.9,1.2-1.9,2.3-3.6,2.3c-1-0.3-1.6-1.1-2-1.9
		c-1.4-3.3-3.9-5.4-6.7-7.3c-1.1-0.8-2.9-1-2.3-3.1C449.4,322.4,451.9,325.3,453.8,324.5z"/>
	<path class="st3710" d="M447.8,325.5c2,2.6,5.5,3,7.7,5.7c1.2,1.4,1.5,2.9,2.3,4.3c-3.4,1.8-3.5-2.9-6-3c-3.7-0.8-5.6-3-5-6.9
		C447.2,325.5,447.5,325.5,447.8,325.5z"/>
	<path class="st3711" d="M451.8,332.5c3.2-1.4,3.1,3.7,6,3c0.3,0.7,0.7,1.3,1,2c2,1.5,2,4,3,6c0.7,1.7,1.3,3.3,2,5
		c-0.6-0.3-1.3-0.6-1.9-1c-0.1-2-1.5-3-3-4c-0.4-0.7-0.7-1.4-1.1-2C456.7,337.8,453.3,335.8,451.8,332.5z"/>
	<path class="st3712" d="M456.8,702c0.5,0.9,0.8,1.9,1.4,2.6c2.8,3.4,1.5,4.6-2.4,4.4c-3.5-1.6-7.5-2.5-9.3-6.6
		c-0.3-0.8-1.4-1.9-2.2-1.9c-7.5,0.1-2.8-2.6-0.7-4.5c-7.4-1.5-8.5-7.8-10.7-13c-0.8-1.9-0.5-3.8-3.4-3.8c-3.3-0.1-2.4-3.9-2.2-4.8
		c1-5.3-1-11.2-3.1-14.1c-3.4-4.5-3.3-8.9-3.8-13.5c-1-8.1-1.6-17.1,1.4-24.3c3.6-8.7,8.7-17.5,17-23.3c0.5-0.4,0.9-0.3,1.2,0.2
		c0,5.1-3.5,8.1-6.6,11.2c-4.4,4.4-6.1,9.7-6.3,15.7c-0.1,3-0.1,6-1.4,8.8c-0.7,1.5-0.8,3.3-0.6,4.9c1.4,10.4,2.5,20.9,8,30.4
		c1.8,3.1,2.3,7.1,4.6,9.9c5.1,6.2,7.5,14.8,15.7,18.3C454.9,699.1,456.3,700.3,456.8,702z"/>
	<path class="st3713" d="M439.8,599.2c-0.3,0-0.7,0-1,0c4.5-7.3,12.4-9.3,19.3-12.9c2.1-1.1,4.8-1.2,5.6-4.1c3.9-2,7.8-3.3,12-1
		c0.3,0.3,0.7,0.7,1,1c-3.3,2.5-6.7,4.6-11.1,4.6c-1.3,0-2.5,0.8-3.6,1.5c-5.6,3.8-12,6.1-17.1,10.5
		C443.2,599.4,441.6,600.5,439.8,599.2z"/>
	<path class="st3714" d="M475.8,581.2c-4,0.3-8,0.7-12,1c-2.7,0-5.3,0-8,0c4.1-2.4,8.2-4.3,13.2-4.4c3.1-0.1,6.4-0.8,9.1-3.1
		c1.9-1.6,4.6-1.7,6.7,0.5c-0.1,1.8-1.4,1.8-2.8,2.1C479.5,577.7,476.7,578.1,475.8,581.2z"/>
	<path class="st3712" d="M455.8,710c2.8,1.5,7.1,0.5,8,5C459.9,715.3,457.5,713.2,455.8,710z"/>
	<path class="st3170" d="M453.8,573.2c0.3-2.3,2.2-3.1,3.9-2.9c2.7,0.3,5.4-1.4,8-0.1C462,572,457.9,572.6,453.8,573.2z"/>
	<path class="st3693" d="M654.6,729c-1.5,0.4-2.9,1.1-0.3,1.9c1.5,0.4,1.9,0.8,1.2,2.1c-4-0.3-8.9,1.3-12.7,4.2
		c-1.3,1-2.8,1.7-4.5,1.8c-0.8,0-2.1,0-2.3,0.5c-2.6,5.5-8.6,6.2-13,9c-5.2,3.3-11,6.4-17.3,7.5c-0.5-3.7,3.2-6.6,2-10.5
		c-0.5-1.7,0.8-4.3-2.4-4.4c-2.2-0.1-5-0.8-5.5,2.6c-0.4,2.8-2.3,2.3-4.1,2.3c-1.5,0.4-2.8,0-4-1c-0.1-1.4,0.5-2.9-1-4
		c1.5-2.8-0.2-3-2.3-3c-5.7,0-11.3,0-17,0c-1.6,0-3.4,0.6-4.7-1c1.1,0,2.3,0.2,3-1c4.8-2.5,9.6-4.8,15.4-4.5
		c5.4,0.3,10.6-2.4,16.2-1.8c0.6,0.1,0.2,0.3,0.4-0.1c2.4-5.1,8.7-3.9,11.9-7.5c2.5-2.8,4.6-5.7,4.7-9.6c0.1-1.3,0.2-2.7,1.1-3.8
		c5.2-1.6,10-2.2,14.6,2.3c3.3,3.2,8.1,4.4,12.3,6.4c2.5,1.2,5.1,2.2,7.5,3.7C658.2,723.9,658.3,725.2,654.6,729z"/>
	<path class="st3478" d="M591.7,745c1.5-0.2,2.8,0.3,4,1C593.9,747.4,592.4,747.8,591.7,745z"/>
	<path class="st3715" d="M619.6,709c1,7.2-2.4,12.3-7.9,16.4c-0.5,0.4-1.2,1.3-1.5,1.1c-4.2-1.2-5.8,1.3-6.9,4.6
		c-0.1,0.4-1.2,0.8-1.7,0.7c-7.6-2.3-14.9,2.3-22.5,1.3c-3.1-0.4-5.5,4.2-9.4,2.8c-3.2-0.3-6.6,1-9.7-1.8c2-2.5,4.9-0.3,7.3-2
		c-3.4-2.1-7.8-0.5-10.6-3.2c-1.6-0.6-4.7,1.4-4.6-1.9c0.1-2.7,2.9-2,4.8-2.1c3.6-0.1,7.4,1.3,10.8-1c6.5-0.4,11.7-5.2,18.5-5.1
		c2.2,0,4.4-1.1,6.7,0c2.8,1.3,5.1,0.4,7.1-1.8c1.5-1.6,2.8-3.2,4.1-5c2.3-3.3,4.2-6.6-0.2-9.8c-1-0.7-1.1-2-0.9-3.2
		c1.6-3.1,3.9-1.9,5.2-0.3c2.9,3.4,7,5,10.5,7.4C619.3,707.1,619.7,707.9,619.6,709z"/>
	<path class="st3716" d="M603.6,699c-0.1,1.4,0.1,2.6,1.6,3.3c4.4,2,3.3,5.2,1.3,8.2c-2.2,3.2-4.8,6.2-7.1,9.4
		c-1.3,1.7-3.4,1.6-4.5,1c-5.3-3-10.3,1.1-15.4,0.2c-0.9-0.2-2.5,0.1-2.7,0.7c-2.1,4.9-5.7,3.1-9.2,2.3c-5.7-3.3-12.5-2.1-18.5-3.9
		c-1.9-0.6-4-0.5-4.4-3c3.1-2.6,6.9-0.6,10.3-1.7c8.9,0.4,16.9-2.9,24.7-6.6c1.3-0.6,2.5-0.6,3.8-0.5c5.9,0.3,8.3-1.9,8.8-8
		c0.2-1.9-0.9-4,0.6-5.7c2.2-1.1,4.4-0.9,6.6-0.4C601.6,695.2,601.7,697.9,603.6,699z"/>
	<path class="st3717" d="M555.7,716c-3.5,2.1-7.3,0.5-11,1c-13,0-26,0-39,0c-0.6-0.7-1.8-1.5-1.8-2.2c0.2-4.1-2.5-3.9-5.2-3.8
		c-1-3.2,1.6-3.2,3.3-3.2c3.1-0.1,6.1-0.9,9.1-1.3c7,1.2,14,4.3,21.2,1c0.8-0.4,1.9-0.6,2.4,0c1.9,2.7,5.1,1.9,7.6,3
		c3.6,1.7,7.2,3.3,11.3,3.6C554.6,714.2,555.4,715,555.7,716z"/>
	<path class="st3718" d="M439.8,599.2c1.7-0.3,3.3-0.7,5-1c0.8,4.4-3.9,6.1-4.7,9.7c-6.4,9.3-9.5,19.3-10,30.7
		c-0.3,5.8,0.8,10.8,1.6,16.3c0.9,6.7,6,11.2,6.8,17.8c0.4,2.8,3.9,5.9,6.2,8.7c0.8,1,1.9,1.9,2.1,3.3c0,2.6,1.2,3.9,3.9,4.1
		c2.4,0.1,4.1,1.4,4.2,4c0.1,2.2,1.2,3.3,3.4,3.5c2.6,0.3,4.3,2.3,6.2,3.9c2.8,2.5,5.3,4.8,9.8,4c2.9-0.6,2.6,2,1.4,4
		c-6.4,0.6-12.3,0-16.7-5.5c-0.4-0.5-1.5-0.3-2.3-0.5c-4.3-2.7-8.9-5.1-12.4-8.9c-0.4-0.5-1.2-0.9-1.3-1.4
		c-2.1-7.4-7.4-13.2-10.9-19.8c-4.4-8-4.3-16.6-7.5-24.6c-1.7-4.5-2-9.7,0.6-14.4c0.9-1.5,0.6-3.3,0.5-4.9
		c-0.7-8.7,2.8-15.8,9.2-21.1C437.8,604.6,437.5,601.2,439.8,599.2z"/>
	<path class="st3719" d="M475.8,708c0-2,0.6-4.3-3-2.6c-4.6,2.2-6-2.9-9-4.4c4.9-2.8,10-1.2,15.1-0.8c1.5,0.3,2.9,1.4,4.5,0.5
		c4.7-2,8.7,0.8,12.8,2.1c2.5,0.8,4.3,1.3,6.3-0.7c2.5-2.5,5.4-2.9,8.7-1.8c2.1,1.7,1.4,3.9,1,6.1c-0.2,0.2-0.3,0.4-0.5,0.6
		c-2.6,2-5.6,2.1-8.7,2c-1.6,0-3.8-0.8-4.3,2C491.3,708.5,483.4,709.1,475.8,708z"/>
	<path class="st3176" d="M687.5,415.4c-1.4,4-2.4,8.2-6,11C681.9,421.8,681.9,417.1,687.5,415.4z"/>
	<path class="st3176" d="M681.5,426.4c0.1,1.7-0.2,3.2-2,4C679.7,428.8,680.2,427.4,681.5,426.4z"/>
	<path class="st3487" d="M652.6,381.4c-0.4-4.1-0.7-8.2-1.1-12.3c-0.2-2.9-2.9-3.9-4.5-5.7c-2.1-2.4-4.9-4.6-0.2-7.6
		c1.6-1,0.1-2.3-1.4-2.3c-2.5-0.1-5,0-7.5-0.1c-1.8,0-4.2,0-3.9-2.5c0.2-2.1,2.7-2,4.4-2.3c0.7-0.1,1.6,0.5,2.4,0.6
		c1,0.2,2.1,0.2,2.5-0.9c0.5-1.2,0.3-2.4-1.2-2.7c-1.4-0.4-2.7-0.8-2.3-2.6c0.5-2.1,2.3-1.5,3.7-1.6c10.8,0,21.6,0,32.4,0
		c2.8,0,4.9,0.2,3.9,4.2c-0.7,2.6-0.4,5.4,1.1,8.1c1.4,2.6,1.1,5.8-0.4,8.6c-7.3-2-14.8-0.7-22.2-0.9c-2.9-0.1-3.6,1.6-1.8,3.8
		c1.6,1.9,2.2,2.9-0.3,5c-1.8,1.5-0.4,4.7-0.6,7.2c-2.4,3.9-2,8.2-2,12.5c0,3,0.1,6-2,8.5c-0.2-0.2-0.4-0.4-0.6-0.6
		C649.4,392.1,650.6,386.7,652.6,381.4z"/>
	<path class="st3720" d="M588.7,430.3c-5.1-2.2-8.1-6.9-12.3-10.1c-0.1-0.1-0.3-0.3-0.2-0.4c1.7-7.2-4.9-12.5-4.5-19.4
		c0-0.5,0.2-1.3,0-1.4c-7-5.5-0.7-11.7-0.9-17.5c-1.6-4.7,1.4-8.2,3-12.1c3.2-2.2,3-7.2,6.9-8.9c0.1,3.6,3.5,7.7-2.1,10.4
		c-1.9,0.9-0.4,2.9,0.8,3.6c2.8,1.6,2.7,4.2,2.2,6.5c-1.5,8.3,1.5,16.3,1.5,24.5c0,6,2.7,11.6,2.5,17.5c-0.1,2.5,3.4,2.8,3,5.4
		C589.3,429,589.2,429.7,588.7,430.3z"/>
	<path class="st3721" d="M588.7,430.3c0-0.7,0-1.3,0-2c1.5,0.1,2.4,0.8,2.9,2.2c1.5,4,5.4,4.7,8.7,6.3c6.4,3,12.9,4.3,20,2.5
		c2.8-0.7,5.2-2.6,8.5-2.1c2.1,0.3,3,1,2.9,3.1c0.9,3.7-2.8,7.3,0,11c0,2.3,0,4.7,0,7c-4.6-4.1-7.6-13.3-5.4-18
		c-6.9,5.9-14.2,3.4-21.4,1.6c-2.4-0.6-4.8-1.3-7.3-1.3c-4.1-0.1-6.5-2-6.8-6.3C589.7,433.2,589.7,431.5,588.7,430.3L588.7,430.3z"
		/>
	<path class="st3722" d="M645.6,409.4c3.5,4.7-0.7,8.3-2.9,10.9c-8.5,9.9-18.5,17.7-32.7,17.1c-2.6-0.1-4.9-0.6-6.4-3
		c3.3-1.9,6.9-0.7,10.4-1.2c3.8-0.6,7.4-1.2,10.6-3.2c0.4-0.2,0.9-0.4,1.3-0.5c4.5-0.5,5.7-4.4,7.7-7.5c0.5-1.1,1.3-1.9,2.4-2.4
		c2.2-0.9,3.8-2.3,4.5-4.5c0.1-0.4,0.3-0.8,0.5-1.2c0.7-1.9,1.7-3.5,3.4-4.7C644.9,409,645.2,409,645.6,409.4z"/>
	<path class="st3723" d="M591.7,376.4c4.3-0.3,2.5-5.9,6-7c0.2,0.2,0.3,0.4,0.5,0.5c1.3,2.7-1.3,4.5-1.6,6.9
		c-2.5,5.5-5.3,10.8-6.1,16.9c-2.4,9.6-2.6,19,2.4,27.9c0.6,1,1.1,2,1.3,3.2c0,0.6-0.2,1.1-0.6,1.5c-0.8,0.2-1.5,0.7-2.1-0.3
		c-5.3-9.3-8.7-18.7-5.1-29.6C588.5,389.9,589.2,382.9,591.7,376.4z"/>
	<path class="st3724" d="M580.7,360.4c-2.5,2.7-2.2,7.2-6,9c-0.9-0.5-1.3-1.3-1.6-2.2c-0.2-1.3,0.3-2.3,1.5-2.8
		c4.3-8.2,7.6-17,13.9-24c1.9-1.1,2.5-3.2,3.9-4.8c0.9-1,1.2-2.8,3.2-2.2c0,0,0.1,0,0.1,0c-0.4,1-0.7,2-1.1,3c0.1,1.5-1.4,2-2,3
		c0.5,2.5-1.3,3.2-3,4c-1.3,4.1-1.5,8.7-6,11C582.1,356.2,580.4,357.8,580.7,360.4z"/>
	<path class="st3725" d="M593.6,426.4c0-0.3,0-0.7,0.1-1c1.4-2.7,3.2-2.5,5.1-0.6c2.4,2.3,5,3.8,8.4,3.6c0.4,0.1,0.9,0.1,1.3,0.3
		c1.9,0.7,3.8,0.7,5.7,0.3c0.4-0.1,0.9-0.1,1.3-0.1c2.5,0,4.9-1.3,7.4-0.4c0.7,0.7,1.3,1.5,0.5,2.5c-8,3.7-16,4.3-24.2,0.8
		c-2-0.8-3.9-1.9-4.6-4.1C594.3,427,594,426.7,593.6,426.4z"/>
	<path class="st3723" d="M594.6,427.4c7.9,5.9,16.4,6.8,25.6,3.7c1.1-0.4,2.2-0.5,3.4-0.7c0.7-0.6,1.3-0.5,2,0
		c-6.6,5.5-14.3,4.4-22,4l0,0c-1.3-0.4-2.4-1.6-4-1c-2.2-0.7-4-2.1-5.6-3.7C592.7,428.2,594.6,428.2,594.6,427.4z"/>
	<path class="st3487" d="M645.6,409.4c-0.3,0-0.7,0-1,0c-1.1-1.9-0.1-2.8,1.6-3.5c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.3,0.6,0.4
		C647.7,407.9,646.2,408.3,645.6,409.4z"/>
	<path class="st3487" d="M649.6,402.4c0.1,1.7-0.2,3.2-2,4l0,0c-1.3-1.9-0.8-3.3,1.3-4.2C649.1,402.3,649.3,402.3,649.6,402.4z"/>
	<path class="st3722" d="M599.6,433.3c1.8-1.7,2.8,0,4,1C602.2,434.5,600.9,434,599.6,433.3z"/>
	<path class="st3487" d="M651.6,398.4c0.1,1.7-0.2,3.2-2,4l0,0C648.6,400.2,649,398.8,651.6,398.4L651.6,398.4z"/>
	<path class="st3726" d="M695.5,410.4c0,0.6,0,1.3,0,1.9c-3.9,7.5-7.4,15.3-12,22.4c-2.6,4.7-6.5,8.3-10.8,11.4
		c-4.5,2.3-8.6,5.7-14.3,4.7c-4.1-0.7-7.7-2.1-10.6-5.1c-1.2-1.2-2.4-2.3-4-2.9c-0.4-0.2-0.8-0.5-1.1-0.9c-1.6-1.9-3.6-1.6-5.6-0.8
		c-1.2,0.5-2.1,1.8-3.6,1.1c1.5-6.4,6.6-4.8,11-5c0,0,0,0,0,0c2.8,1.9,5.4,3.9,7,7c9.1,4.5,14.4,3.5,21.7-3.2
		c7-6.4,11.9-14.2,15.4-23.1C690,414.7,692.6,412.4,695.5,410.4z"/>
	<path class="st3488" d="M651.6,444.3c-2.7-1.9-5.1-4.3-7-7C648.3,438.3,651.4,439.8,651.6,444.3z"/>
	<path class="st3246" d="M682.5,364.4c0.6-5-1.2-10.1,1-15c0,5,0,10,0,15C683.2,364.4,682.9,364.4,682.5,364.4z"/>
	<path class="st3246" d="M685.5,373.4c1.8,0.7,2.1,2.3,2,4C686.2,376.4,685.7,375,685.5,373.4z"/>
	<path class="st3727" d="M639.6,461.3c-4.6-1.8-5.4-6.6-7.9-10c-0.1-2.5,0.3-4.9,2-7c1.5,0.4,2,1.7,2.6,2.9c0.5,1.7,0.9,3.4,1.7,5
		c0.4,1.7,1.2,3.3,2.2,4.8C641.3,458.5,642,460.2,639.6,461.3z"/>
	<path class="st3728" d="M635.5,447.4c-0.6-1-1.3-2.1-1.9-3.1c0-0.7,0-1.3,0-2c1-0.7,2-1.3,3-2c2.5-0.5,5-1.4,7,1
		c0,0.3,0.1,0.6,0.1,0.9c-0.1,0.2-0.3,0.3-0.4,0.5c-0.6,0.3-1.2,0.3-1.9,0.3c-1.9,0-2.9,1.3-3.9,2.6
		C636.9,446.4,636.4,447.1,635.5,447.4z"/>
	<path class="st3720" d="M590.7,434.3c1.8,3.8,4.7,5.2,9.1,5.2c3.8,0,7.7,1.2,11.4,2.4c4.5,1.5,8.7,1,12.5-1.3
		c2.2-1.4,4.1-1.6,6.5-0.8c-0.7,2.5-4.4,0.5-4.2,2.7c0.3,3.3,1,6.9,2.6,9.7c1.1,2,1.6,4.3,3.1,6c0.3,0.3,0.7,0.7,1,1
		c0.8,4.9,2.1,9.5,7,12c-3.2,0.6-5.5-1.9-8.4-2.6c-0.9-0.2-1.4-1.6-1.1-3c2.3-8.4-6.2-19.4-14.9-19.4c-2.8,0-5.8-0.5-8.5,0.1
		c-4.9,1.1-8.2-2.9-12.7-3.6C591.3,442.4,588.9,438.4,590.7,434.3z"/>
	<path class="st3177" d="M673.6,465.3c-1.1,1.1-2.4,2.1-2,4c-6.2,2.3-12.8,0-19.1,1.6c-0.2,0-0.6-1-0.9-1.6
		c4.6,0.8,9.1-2.1,13.7-0.1c0.7,0.3,1.7,0,2.1-0.9C668.5,465.3,671,465.3,673.6,465.3z"/>
	<path class="st3177" d="M635.6,462.3c3.8,0,6.4,3.1,9.7,4.5c1.6,0.7,4,0.7,5.3,2.5C644,470.3,639.5,467,635.6,462.3z"/>
	<path class="st3729" d="M682.5,434.3c4.9-7,6.8-15.8,13-22c5.8-2.5,8-1.1,8,5.1c0,2.8,0.5,5.7-2,7.9c-2.6-0.7-0.9-2.6-1.2-4
		c0.1-1,0.4-1.9,0.6-2.9c0.2-1,0.2-2.1-0.9-2.6c-1.3-0.6-2.3,0.3-2.9,1.3c-2,3.3-3,7-4.9,10.3c-1.7,2.4-2.6,5.5-5.3,7.2
		C685.7,433.2,684,434.7,682.5,434.3z"/>
	<path class="st3730" d="M701.5,421.3c0.5,1.3-1,2.7,0,4c-4,10.9-9.6,20.7-19,27.9c-1.3,1.2-2.5,1.9-3.3-0.5c3-4.1,6.6-7.7,9.5-11.8
		c2.3-3.2,4.5-6.5,6.1-10.1c2.2-3.1,2.5-7.3,5.3-10C700.7,420.9,701.1,421,701.5,421.3z"/>
	<path class="st3731" d="M679.7,453.4c1,1.3,1.9-0.8,2.9,0c0.2,1.6-1.4,1.9-2,3c-0.3,0.3-0.7,0.6-1,1c-1.4,1-2.6,2.1-4,3
		c-1.9,0.3-4.5-0.4-4,3c-2,0.3-4,0.7-6,1c-2.1,0.7-4.1,0.9-6-0.7c-0.5-2.6,1.8-2.3,3.1-2.9c2-0.8,4.1-1.3,6.1-2.1
		c3.1-1.2,5.5-3.4,8.3-5C677.9,453.2,678.7,452.6,679.7,453.4z"/>
	<path class="st3732" d="M659.4,463.2c2.1,0.4,4,1.4,6.2,1.1c-9.1,3.2-18,3.8-26-3c1.1-1.6,0.8-2.9-0.6-4.2
		c-1.3-1.2-2.4-2.7-1.5-4.7c0.2-0.1,0.4-0.3,0.6-0.4c0.5,0,0.9,0.2,1.4,0.4c4.1,5.8,9.8,8.8,16.7,9.6
		C657.3,462.2,658.5,462.2,659.4,463.2z"/>
	<path class="st3492" d="M675.5,460.3c0.9-1.6,2-2.9,4-3C678.7,458.9,677.6,460.2,675.5,460.3z"/>
	<path class="st3733" d="M564.7,399.4c1,4.8-0.9,9.3-1.5,13.9c-0.8,6.5-0.3,12.9,0.2,19.4c2.2-2.9,0.6-6.3,1.8-9.6
		c1.4,3.8,2.2,7.1,0.9,10.8c-0.7,1.9-0.9,4.1,0.1,5.9c1.8,3.5,2.7,6.4-0.6,9.8c-1.7,1.8-0.4,4.4,2.3,5.3c1.2,0.4,2.6,0.3,3.9,0.6
		c1.2,0.2,3.1-0.7,3.4,1c0.3,1.8-0.8,4.3-2.3,4.6c-6.1,1.2-3.2,3.7-1.2,6.2c0.5,0.6,0.8,1.5,1.1,2.2c0.1,1.2-0.7,1.4-1.6,1.4
		c-4.9-2.7-6-7.8-7.4-12.5c-2.8-9.1-4-18.5-4.1-28c-0.2-10.3,0.9-20.5,3.6-30.5C563.6,399.2,564.1,399.1,564.7,399.4z"/>
	<path class="st3733" d="M591.7,481.3c0,4.1-1,5.3-4.7,2.4c-3-2.3-6.6-3.5-10.3-4.3c-0.9,0.2-1.2-0.3-1.4-1c0-0.4,0.1-0.9,0.3-1.2
		c1.3-0.3,1.9-1.1,2-2.4c0.7-2.5,2.6-5.4-1.9-6.4c0-0.7,0-1.3,0-2c5.1-1.8,5.6-1.5,6,3.5C582,474.4,586.7,479.8,591.7,481.3z"/>
	<path class="st3734" d="M564.7,399.4c-0.3,0-0.6,0-0.9,0.1c-0.7-0.5-1-1.3-1.2-2.1c0-4.5,1.1-8.9,2.6-13.1c1-3.3,2.5-6.5,3.6-9.8
		c0.9-1.8,1.5-3.6,2.2-5.4c0.7-0.9,1.1-2.5,2.8-1.7c0.3,0.7,0.5,1.3,0.8,2c-1.7,3.9-3.2,7.8-4,12
		C567.6,387.1,565.1,392.9,564.7,399.4z"/>
	<path class="st3498" d="M575.7,477.3c0,0.3,0,0.7,0,1c-4-1.3-4.9-4.2-4-8c0.3-0.3,0.7-0.7,1-1c0.9-0.8,3.3,3.3,3-1
		c1.3-0.1,2.9-2.9,3.5-1c0.7,2.5,2.5,6-1.5,8C577.1,476.1,576.3,476.6,575.7,477.3z"/>
	<path class="st3720" d="M588.7,430.3c1.8,0.8,2.1,2.3,2,4C588.8,433.6,588.6,432.1,588.7,430.3z"/>
	<path class="st3733" d="M616.6,491.3c-2,0-4,0-6,0c-0.2-0.3-0.2-0.8-0.2-0.8c-0.7-0.2-0.5,0.5-0.8,0.8c-5.1,1.8-8.2,0.3-11.7-4.8
		c-1.1-1.7-3.3-2.7-3.3-5.2c2.2,0.1,3.8-0.1,5.5,2.6c2.1,3.4,7.1,2.3,10.9,2.9C614.1,487.3,616,488.2,616.6,491.3z"/>
	<path class="st3735" d="M571.7,470.3c0.6,3,1.9,5.7,4,8c0.3,0.3,0.6,0.7,0.9,1c4.3,8,11.8,12.3,19.3,16.5c4,2.2,8.5,3.6,12.8,5.3
		c-0.1,0.2-0.3,0.4-0.4,0.6c-1.1,0.7-2.4,0.6-3.6,0.3c-3.4-1.1-6.8-2.2-10-3.9c-2.6-1.4-5.1-2.9-7.8-4.2c-0.4-0.2-0.7-0.5-1.1-0.8
		c-3.1-2.6-5.9-5.5-8.8-8.3c-3.1-3.2-5.4-6.9-8.4-10.2c-2.2-4.3-3.9-8.8-6.1-13c-3.7-12.6-5.8-25.4-4.3-38.6
		c0.2-1.8,0.1-3.6,0.1-5.5c0.5-5.2,1.2-10.5,2.4-15.6c0.4-1.8,0.5-4.1,2.8-4.8c0.1,0.8,0.2,1.6,0.2,2.4c0.1,6.5-1.5,12.8-2.3,19.2
		c-1.1,8.1-0.6,16,0.6,23.7C563.4,452.1,563.8,462.6,571.7,470.3z"/>
	<path class="st3511" d="M608.7,501.1c3.5-1,6.9-1.7,9.9,1.2c0.3,0.5,0.3,1-0.2,1.4c-3.3,1.6-6.3-0.1-9.4-0.8
		c-0.5-0.3-0.7-0.8-0.5-1.4L608.7,501.1z"/>
	<path class="st3736" d="M618.6,503.1c0-0.3,0-0.5,0-0.8c0.7,0,1.3,0,2,0c3.3,0.4,6.8-0.8,10,0.8c-3.3,2.7-7,1.5-10.6,1.2
		C619.4,504,619,503.6,618.6,503.1z"/>
	<path class="st3497" d="M609.6,491.3c-0.2-0.9-1.3-1.7-0.2-2.6c0.7-0.6,1.7-0.4,2.2,0.3c1,1.2-0.8,1.5-1,2.3
		C610.3,491.3,610,491.3,609.6,491.3z"/>
	<path class="st3737" d="M739.5,334.5c1.8,5.6,1.6,11.5,0.3,17c-2.1,8.3-4.5,16.7-11.4,22.7c-0.5,0.5-0.6,1.5-0.9,2.2
		c-1.7,1.3-3.3,2.7-5,4c-1.7-1.5-3.4,1.7-5.1,0c-0.1-2,1.7-2.4,2.8-3.5c2.7-1.6,4.7-3.9,6.8-6.2c1.6-1.7,2.8-3.8,3.9-5.8
		c4-8.2,7.1-16.6,7.2-25.9C738.2,337.4,738.1,335.7,739.5,334.5z"/>
	<path class="st3738" d="M692.5,373.4c-1.5-0.1-2,1.4-3,2c-6.4-13.5-5.5-26.8,1-40c0.5-0.4,0.9-0.3,1.3,0.1c1.4,2.7-1,4.7-1.4,7.1
		c-1.3,7.7-0.5,15.4-0.2,23.2C690.4,368.7,694.2,370.2,692.5,373.4z"/>
	<path class="st3739" d="M691.5,335.5c-0.3,0-0.6,0-1,0c1.1-8.4,5.8-14.6,12-20c1-0.3,2-0.7,3-1c0.6,1,0,1.8-0.4,2.6
		c-2.7,4.1-6.5,7.4-8.9,11.8C694.8,331.1,694,333.9,691.5,335.5z"/>
	<path class="st3740" d="M692.5,373.4c-5.8-9.9-4.5-20.4-3-31c2.2,0.1,2.9,1.5,2.9,3.4c-0.7,7.3-1.1,14.5,1.1,21.6
		c0.3,2.3,2.2,4.2,1.4,6.8c-0.4,0.5-0.8,0.5-1.3,0.2C693.2,374.1,692.9,373.7,692.5,373.4z"/>
	<path class="st3741" d="M714.5,381.4c0.8,1.5-0.1,1.8-1.4,2.3c-6.9,2.4-11.9,0.7-17.6-6.3c0.2-0.1,0.3-0.3,0.5-0.4
		c1.9-0.5,3.2,1.1,4.8,1.4c0.4,0.1,0.8,0.3,1.2,0.5c1.8,0.9,3.7,1.4,5.7,1.5C710,380.7,712.5,379.4,714.5,381.4z"/>
	<path class="st3742" d="M714.5,381.4c-2.4,0-4.7,0.1-7.1,0.1c-0.6-0.8-1.8-1.3-0.7-2.6c0.9-0.6,1.9-0.8,3-0.9
		c3.5,0.4,6.5-2.2,10-1.5c0.5,0.2,0.9,0.5,1.2,1c-1.2,0.9-2.3,1.8-3.5,2.8C716.4,380.7,715.5,381.1,714.5,381.4z"/>
	<path class="st3741" d="M693.5,374.4c0.4,0,0.7,0,1.1,0c0.7-0.4,1.2-0.1,1.8,0.3c0.3,0.3,0.5,0.7,0.5,1.2c-0.1,0.7-0.7,1.1-1.2,1.5
		c0,0-0.2,0.1-0.2,0.1C694.5,376.7,692.3,376.6,693.5,374.4z"/>
	<path class="st3743" d="M678.5,494.3c4.1,0.7,6.1-1.2,6.3-5.2c2.1,0.3,1.5,2,1.9,3.1c0.2,0.1,0.3,0.1,0.4,0.1
		c-0.4-2.5,2.3-2.8,3-4.4c1.1-2.5,2.7-3.5,5.4-2.6c0,0,0,0,0,0c0.5,0.6-0.5,1.8,0.9,2.1c0.2,0.8,0.1,1.8,1,2.3
		c0.7,1.7,1.1,3.4,0.6,5.3c-7.4-0.6-11.6,4.9-16.3,9.1c-3.9,3.5-7.1,7.8-9.6,12.5c-2,3.7-5.3,3.6-10.8,0.1c-0.4-0.3-0.7-0.6-1.1-1
		c-0.4-0.7-0.4-1.4,0.1-2.1c1.7-4,0.2-10.5,8-9.4c0.7,0.1,2.2-1.1,2.2-1.7C670.3,496.7,674.7,495.8,678.5,494.3z"/>
	<path class="st3744" d="M697.5,495.3c0-1.9,0-3.8,0-5.7c3.7,6.7,5.1,14.1,7.1,21.3c0.5,1.9,1.2,3.7,1.8,5.6c0,0.6,0.1,1.2,0.1,1.8
		c-0.1,4.9,2.7,9.8-0.3,14.7c-0.4,0.4-0.9,0.6-1.5,0.6c-1.8-1-2.4-2.8-2.3-4.7c0.6-8.9-1.9-17.4-3.6-25.9c0.2-1.8-0.1-3.5-1-5.1
		C697.5,497,697,496.2,697.5,495.3z"/>
	<path class="st3516" d="M696.5,487.3c-1,0.3-2.2,1.5-2.9,0.2c-1-1.9,1.9-0.9,2-2.2C696.3,485.8,696.6,486.4,696.5,487.3z"/>
	<path class="st3745" d="M705.4,533.1c1.9-4.8,0.9-9.9,1.1-14.9c2.1,2.4,0.5,5.4,1.3,8.1c0.9,1,1,2.2,1.1,3.4
		c0.1,3.9,0,7.8,0.1,11.7c0.2,16.1-1.8,32-4,47.8c-0.2,1.6,0.3,4-2.6,3.9c-2.9-4.3-0.6-8.8,0-13.1c1.9-13.5,1.8-27.1,1.8-40.7
		C704.1,537.2,703.9,535,705.4,533.1z"/>
	<path class="st3505" d="M707.7,529.2c0-1,0.1-1.9,0.1-2.9c0.3-0.4,0.5-0.7,0.8-1.1c2.5-1.5,5.3-0.8,7.9-0.9c0.1,2.2-1.8,1.8-3,2.2
		c-2.3-0.1-2.7,2.7-4.7,3.2C708.3,529.7,708,529.5,707.7,529.2z"/>
	<path class="st3746" d="M712.5,525.2c1.4-0.2,2.9,0.5,4-1c1.7,0.1,3.2-0.2,4-2c0.7,0.6,1.3,1.3,2,1.9c0.2,2-1.4,0.6-2,1
		c-1.6,3.1-1.3,6.5-0.9,9.7c0.9,9.5-0.6,18.8-1.8,28.1c-0.3,2.2-0.4,4.7-2.6,6.2c-1.8,0.2-0.2-3-2.9-2.6c-1.3,0.2-1.5-2.5-1.4-4.2
		c0.3-3.4,0.4-7,0.9-10.3c1.1-6.7,0.3-13.2-0.4-19.7C711.2,529.9,710.8,527.4,712.5,525.2z"/>
	<path class="st3747" d="M714.5,569.2c2.4-4,2.3-8.5,2.9-12.9c1.2-8.4,1.3-16.8,1.1-25.3c-0.1-2.2,0.1-4.3,2-5.9c0,4,0,7.9,0.1,11.9
		c2.4,4.3,1.4,9,0.9,13.5c-0.3,3.1-0.8,6.2-1,9.3c0,0.6-0.1,1.1-0.3,1.7c-1.4,10.5-2.4,21-4.6,31.3c-2.3,9.8-3.8,19.7-6.6,29.4
		c-0.5,1.6-0.8,3.2-1.8,4.6c-0.4,0.4-0.8,0.6-1.4,0.6c-1.3-0.9-1.2-2.2-1.1-3.5c0.3-2.2-0.1-4.4,1.1-6.4c1.4-4.7,3.1-9.3,3.6-14.2
		c0.1-0.8,0.2-1.5,0.4-2.3c0.9-3,1.6-6,1.6-9.2c1-5.3,2.2-10.7,1.8-16.2c0.1-1.9-0.2-3.9,0.8-5.7
		C714.1,569.6,714.3,569.4,714.5,569.2z"/>
	<path class="st3748" d="M727.8,528.1c-1.7,0.1-3.5,0.2-4.2,2.1c1.1,1.2,1.6,2.6,1.4,4.1c-0.6,4.9-0.2,9.9-0.7,14.8
		c-0.1,1.5-0.6,2.9-1.8,4c-1,6-2,12-3,18c-0.9-3.3-0.7-6.7,0-10.1l0,0c1.6-8,1.3-16.1,2-24.2c0-1.8-0.1-3.6-0.1-5.5
		C721.4,525.2,721.8,525,727.8,528.1z"/>
	<path class="st3749" d="M721.4,537c0.1,8.1,0.6,16.3-2,24.2c0.4-8,0.7-16.1,1.1-24.1C720.9,537.1,721.1,537.1,721.4,537z"/>
	<path class="st3750" d="M749.5,579.2c-1.9,2-2.4,4.7-2.8,7.2c-0.4,3.1-1.2,5.8-3.9,7.6c-2.5,1.6-1.1,4.5-2.2,6.8
		c-2.2,4.3-4.9,8.5-6.1,13.3c-3.3,0.6-4.1-1.6-4.2-4.1c-0.2-4.6-0.8-9.2,0.8-13.7c0.6-1.8,1-3.7-0.6-5.1c-2.8-2.4-4.5-5.5-5.4-9.2
		c-0.9,2.2-1.5,4.5-4.4,3.8c-2.5-0.6-2.5-2.8-2.6-4.8c-0.1-1.3-0.1-2.6,0.2-3.9c0.2-1.1,0.1-2.2,1.1-2.9c2.7-0.3,3.3-1.9,2.8-4.2
		c-0.3-1.4-1.6-3.4-0.1-4c1.8-0.7,5.4-0.9,5.9,0c2.7,5,7.1,3.1,10.9,3.2C746,569.4,748.9,571.9,749.5,579.2z"/>
	<path class="st3751" d="M722.5,553.2c1.4-7.6,0.9-15.3,1-23c5.9-0.8,9,4.7,14,6c3,3.3,7.5,4.1,11.3,6c1.3,0.7,1.9,1.4,2.7,2.3
		c1.7,1.8,2.9,4.1,6.5,3.2c3.9-1,3.8,1.8,2.5,4.5c-3.8-4.1-8.9-2.1-13.4-2.9c-1.7-0.3-3.4,0-3.6-2.4c0-0.4-0.6-1.2-0.7-1.2
		c-6.7,1.5-6.6-6-10.7-8.1c-1.2-0.6-1.7-3-3.4-2.1c-1.8,0.9-2.9,2.7-3,4.8c-0.1,3.1-0.1,6.3,0,9.4C725.6,552,725,553.4,722.5,553.2z
		"/>
	<path class="st3749" d="M719.5,574.2c0,1,0,2,0,3c-1.2,5.9,0.1,12-2.4,17.7c-0.6,1.3-0.2,3.5-2.8,3.2c-0.4-1.7-0.6-3.4,0.1-5.1
		c1.7-10.6,3.4-21.2,5-31.8c0,3.3,0,6.7,0,10C719.5,572.2,719.5,573.2,719.5,574.2z"/>
	<path class="st3752" d="M631.6,503.4c3.5-0.4,7.1-0.8,10.6-1.3c1.3-0.3,2.8-1.1,3.5,0.9c-1.7,2.3-4.3,2.3-6.7,2.6
		c-0.8,0-1.6,0-2.4,0c-1.6-0.1-3.2,0-4.7-0.8C631.4,504.4,631.3,503.9,631.6,503.4z"/>
	<path class="st3753" d="M659.6,496.2c1.7-0.2,3.4-0.4,5-0.6c0.2,1.1-0.4,1.8-1.2,2.3c-1.7,0.7-3.4,1.5-5.2,2.2
		c-1,0.3-1.9,0.4-2.9,0.4c-1-0.1-2-0.3-2.7-1.2C654.9,498.2,657.2,497.2,659.6,496.2z"/>
	<path class="st3754" d="M631.6,503.4c-0.1,0.3-0.1,0.5-0.1,0.8c-3.2,2.4-6.8,1.3-10.3,1.1c-0.9-0.3-1.7-0.7-1.6-1.9
		c3.7-0.1,7.3-0.2,11-0.4C630.9,503.2,631.3,503.3,631.6,503.4z"/>
	<path class="st3755" d="M645.6,503.1c-0.9-1.1-2.2-0.7-3.3-1c2.5-0.6,4.9-2.6,7.7-1.1c0.2,1.3-0.8,1.8-1.6,2.4
		c-0.4,0.2-0.8,0.3-1.2,0.4C646.5,503.9,645.9,503.7,645.6,503.1z"/>
	<path class="st3756" d="M652.6,499.2c1.1,0,2.1,0,3.2,0.1c0.3,1.5-0.9,1.8-1.8,2.4c-0.4,0.2-0.9,0.3-1.3,0.4c-0.9,0-1.8,0-2.4-1
		C650.5,499.9,651.3,499.3,652.6,499.2z"/>
	<path class="st3757" d="M650.2,501.1c1.1,0,2.2,0.1,3.4,0.1c-1,3.7-3.3,3.6-6,2c0.8-0.7,1.7-1.4,2.5-2.2
		C650,501.1,650.2,501.1,650.2,501.1z"/>
	<path class="st3758" d="M647.5,503.2c2.3,0.2,4.5,0,6-2c0,0,0,0,0,0c1.8-0.9,4.1-0.8,5.8-2.1c1.3-0.9,3-1,4.2-2
		c0.3-0.5,0.7-1,1-1.5c0.3-0.1,0.6-0.2,1-0.3c1,0,2,0,3,0.1c0.4,0.3,0.7,0.6,1.1,0.9c-3.3,2-6.6,4.1-9.9,6.1
		c-0.4,0.2-0.8,0.5-1.1,0.8c-2.5,1.2-5.2,1.7-7.7,2.8c-0.8,0.4-1.6,0.9-2.4,1.4c-1.5,0.9-3.2,1.1-5,1.2c-1.8,0-3.5,0.1-5.2,0.7
		c-1.3,0.3-2.6,0.4-4,0.4c-2.8-0.2-5.6,0.5-8.3-0.8c-0.5-0.3-0.7-0.8-0.7-1.4c0.3-0.5,0.7-0.8,1.3-1.1c4.5-1.3,9.1-0.9,13.6-1.3
		C642.7,504.3,645.3,504.5,647.5,503.2L647.5,503.2z"/>
	<path class="st3759" d="M660.4,513.5c0,0.6,0.1,1.2,0.1,1.8c1.2,1.5,2.3,3.1,4.3,3.5c1.3,0.9,3.1,1.4,2.8,3.6
		c-0.4,0.3-0.8,0.5-1.3,0.6c-2.9-0.6-5.4,0.5-7.9,1.7c-2.1,1-4.1,2.8-6.8,1.8c-2.1-2.3-1.4-5-1.2-7.6c0.3-6.3,5.7-9.3,9-13.6
		C660.3,507.9,658.9,510.9,660.4,513.5z"/>
	<path class="st3760" d="M659.5,505.3c-0.7,2.9-2.1,5.2-4.7,6.7c-2.6,1.4-1.7,4.5-3.2,6.3c-2.2-2.5-0.8-5.7-1.6-8.5
		c-0.1-0.5,0-1,0-1.4c0.2-0.9,0.7-1.8,1.3-2.5c2.2-1.4,5.1-1.6,7.2-3.3c0.4-0.1,0.8-0.1,1.1-0.2
		C659.7,503.4,659.6,504.3,659.5,505.3z"/>
	<path class="st3761" d="M423.9,420.4c0,0.3,0,0.7,0,1c-2.1,3-5,2.9-8,2c0.4-0.4,0.7-0.7,1.1-1.1c1.7-1.4,3.5-2.4,5.7-2
		C423.1,420.3,423.5,420.3,423.9,420.4z"/>
	<path class="st3762" d="M408.9,409.4c0.1-2-0.8-4.2,0.8-6.1c2.3-1.2,4.5-2.5,7.1-0.9c3.2,2.2,5.2,5.2,6,8.9c1.2,7.8-1.1,10.3-7.2,8
		c-0.7-1.6,0.5-2.4,1.6-3.1c2-4.5-0.3-10.1-4.2-10.5c-1.8,0.7-1.8,2.8-3,3.9C409.6,409.7,409.2,409.7,408.9,409.4z"/>
	<path class="st3763" d="M415.9,418.4c5.4,1.7,6.9,0.2,7-7.1c1.6,3,1.4,6-0.2,9c-1.8,1.1-3.5,2.3-5.7,2c-1.4-1.6-2.8-3.2-4.2-4.9
		c0.2-0.1,0.3-0.3,0.5-0.4C414.6,416.6,415.3,417.3,415.9,418.4z"/>
	<path class="st3763" d="M408.9,409.4c0.3,0,0.7,0,1,0c3.2,1.8,4.7,4.3,3,8c0,0-0.1,0.1-0.1,0.1C410.3,415.3,409,412.7,408.9,409.4z
		"/>
	<path class="st3763" d="M416.9,402.4c-2.4,0.3-4.8,0.6-7.1,0.9C411.7,400.2,414.1,399.4,416.9,402.4z"/>
	<path class="st3235" d="M476.8,395.4c-0.3,1-1,1.6-2,2c0.2-1-0.6-2.2,0.5-3C475.5,394.2,476.3,395,476.8,395.4z"/>
	<path class="st3764" d="M455.8,365.3c0.1-0.9,0.6-1.9,1.4-0.8c3.3,3.9,7.9,6.7,9.5,11.9c0,2,2.3,4.4-0.9,6c-2.8-4.3-4.5-9.2-8.1-13
		C457.9,367.7,456.3,366.8,455.8,365.3z"/>
	<path class="st3765" d="M465.8,368.5c-4.6,0.6-3.5-4.6-6-6.1C464.3,362,463.4,366.8,465.8,368.5z"/>
	<path class="st3766" d="M469.8,338.5c4,4.8,8.6,9,11,15c-2.6-0.4-3.1-5.6-7-3c-3.1-1.8-3.9-5-5-8
		C467.9,340.8,467.1,339.2,469.8,338.5z"/>
	<path class="st3767" d="M469.8,338.5c-1.5,1-0.9,2.6-1,4c-1.9-0.4-2.9,0.9-4,2c-0.7-1.3-1.7-2.3-3-3c-1.1-1.4-0.3-2.5,0.5-3.6
		c1.6-1.5,3.1-3.1,5.5-1.4C468.7,336.8,469.4,337.5,469.8,338.5z"/>
	<path class="st3768" d="M465.8,330.5c-0.6-3.2-3.4-4.9-5.4-7.3c1.3-1.1,2.7-1.5,4.3-0.7c4.8,1,8,4.7,11.9,7.3
		c1.1,0.8,2,1.7,1.6,3.3c-0.3,0.4-0.6,0.7-1.1,0.7c-2.4-0.7-4-2.8-6.5-3.5c-0.5,2.3,3.2,3.9,1.2,6.3
		C469.3,334.9,467.5,332.8,465.8,330.5z"/>
	<path class="st3769" d="M471.7,336.5c0.2-2-1.6-3.1-2.3-4.7c-0.5-1-1.6-1.8-0.7-3c1.3-1.7,1.9,0.2,2.8,0.6c2.1,0.9,4.2,1.8,5.3,4.1
		c-0.2,0.2-0.3,0.3-0.5,0.5c-2.5,1.1-1.2,2.5-0.3,3.9c2.8,4.1,5.8,8,8.3,12.3c0.5,0.8,0.9,1.6,1.4,2.5c0.5,1.3,1.3,2.7,0,4
		C481,349.9,476.4,343.2,471.7,336.5z"/>
	<path class="st3770" d="M485.7,356.6c-0.3-1.4-0.6-2.8-0.9-4.2c1.6-0.5,2.6,0.6,3.2,1.7c3.4,6.5,7,12.9,9.5,19.8
		c0.2,0.6,0,1.3-0.2,1.9c-0.2,0.2-0.3,0.4-0.5,0.6c-0.3,0-0.6,0-0.9,0C492.4,369.8,489.1,363.2,485.7,356.6z"/>
	<path class="st3771" d="M467.8,336.5c-2.1-0.4-3.4,1.1-5,2c-1.4-0.6-2-1.5-1-3c0.2-1.7,1.4-3.3,0-5c1,0.3,2,0.7,3,1
		C465.5,333.3,467.9,334.1,467.8,336.5z"/>
	<path class="st3537" d="M508.8,392.4c-0.5,2.9,1.3,6.3-2.7,8.6c-2.3-4.7-4.6-9.3-5.4-14.5c3,0,2.7,2.7,4,4.3c0.6-3-0.1-5.8-0.8-8.7
		c-0.3-2.2-0.9-4.4-1.1-6.6c0-0.8,0.3-1.5,0.8-2.1c3.5,0.5,2.7,3.8,4,5.9c0.2,0.7,0.4,1.5,0.4,2.2c-0.1,3.3,1.1,6.3,1.3,9.5
		C509.3,391.5,509.1,392,508.8,392.4z"/>
	<path class="st3772" d="M504.8,382.4c2.2,3.6,1.4,7.4,0.6,11.6c-2.5-2.2-2.1-5.8-4.6-7.6c-0.3-0.3-0.5-0.7-0.8-1
		C501.3,384,503.1,383.3,504.8,382.4z"/>
	<path class="st3770" d="M504.8,382.4c-0.5,2.8-2.7,2.9-4.9,3c-0.1-0.3-0.1-0.7-0.2-1c-1-2.7-2-5.3-3-8c0,0,0.1-0.1,0.1-0.1
		c2.6-0.6,3,2,4.6,3.2c1.2-1.1,0-2.9,1-3.9c0.4-0.4,0.8-0.4,1.2-0.2C504.4,377.7,505.1,380,504.8,382.4z"/>
	<path class="st3535" d="M508.8,392.4c0-0.3,0-0.7,0-1c-0.8-1.3-0.5-2.6-0.2-3.9c2.6-0.5,1.5,1.9,2.1,2.8c1,1.5,0.1,3.5,1.1,5.1l0,0
		c0.6,2,1.1,4,0,6c0,0.7,0,1.3,0,2c-2-1.6,0.1-4.5-2-6C509.5,395.7,510.4,393.8,508.8,392.4z"/>
	<path class="st3773" d="M511.7,401.4c0-2,0-4,0-6c2.7,3.6,2.1,7.8,2,11.9C511.7,405.8,513.8,402.9,511.7,401.4z"/>
	<path class="st3774" d="M466.8,355.4c0.3,1.3,0.7,2.6,1,4c-0.7,0.7-1.3,1.3-2,2c-2.1-2.6-4.1-5.1-6.2-7.7c-1-1.2-1.1-2.5,0.1-3.5
		c1.3-1.1,2.3-0.7,3.3,0.7C464.2,352.5,465.5,353.9,466.8,355.4z"/>
	<path class="st3775" d="M461.8,335.5c0.3,1,0.7,2,1,3c-0.3,1-0.7,2-1,3c0,0.7,0,1.3,0,2c-1.5-1.7-3.2-3.4-3-6
		C459.8,336.8,460.8,336.1,461.8,335.5z"/>
	<path class="st3776" d="M516.6,394.4c-0.3-1-0.7-2-1-3c-2.4-7.3-6.4-14.1-7.6-21.9c2.2-2.7,3-0.1,3.7,1.2c1.9,3.4,3.1,7.1,4.9,10.6
		c1.6,5.2,3.1,10.4,5.2,15.4c3.3,3.2,3.3,7.7,4.4,11.7c-1,2.8-0.9,5.8-1.9,8.6c-0.3,0.5-0.8,0.6-1.3,0.5c-3.1-4.9-3.9-10.4-4.7-15.9
		C517.9,399.1,517.9,396.6,516.6,394.4z"/>
	<path class="st3777" d="M516.6,394.4c2.6,1.9,2,4.7,2.1,7.3c0.3,5.7,3.4,10.4,5,15.7c2.3,5,2.5,10,0,15c-0.3,0-0.7,0-1,0
		c-2.5-1.2-1.9-3.4-2-5.4c-0.2-1.9,0.7-3.9-0.9-5.5C519.7,412.3,517.7,403.4,516.6,394.4z"/>
	<path class="st3778" d="M508.8,369.4c2,7.4,5.9,14.3,6.9,22.1c-0.8,0.5-0.9,1.3-1,2.2c-2.1-4.6-2.2-9.9-4.8-14.3
		c-2.3-2.8-3.1-6.4-4.8-9.6c-0.5-1.4-1.2-2.7-0.9-4.2c0.7-1.7,1.7-1.4,2.9-0.6C508.3,366.1,508.2,367.9,508.8,369.4z"/>
	<path class="st3240" d="M517.6,413.4c-1.3-1.5-0.7-3.4-0.8-5.1C518.3,409.8,517.6,411.7,517.6,413.4z"/>
	<path class="st3773" d="M505.8,369.4c1.7,3.2,3.8,6.2,4,9.9c-0.7,5.5,2.6,10.5,1.9,16c-1-2.7-1.9-5.4-2.9-8c-1.6-1.7-1.1-4.1-2-6
		c0-0.6-0.1-1.2-0.1-1.9c-0.3-2.2-0.7-4.3-1.6-6.4C504.5,371.8,503.4,370.2,505.8,369.4z"/>
	<path class="st3779" d="M599.6,365.4c1.3-5.8,5.4-9.4,11.1-10.1c2.9-0.4,5.8-1.2,8.7-1.9c7.6-1.8,14.7,0.2,19.8,5.2
		c3.2,3.1,8.6,6.5,7.3,12.8c-0.4,0.3-0.9,0.4-1.3,0.1c-0.7-0.7-1.2-1.5-1.7-2.3c-8.6-13.2-18.4-13.1-32.7-8.3
		c-1.1,0.4-2,1.2-3.2,1.5c-1.5,0.3-3.1,0.4-3.4,2.4c-0.3,0.7-0.8,1.1-1.4,1.4c-0.9,0.3-1.8,0.3-2.7-0.2
		C600,365.8,599.8,365.6,599.6,365.4z"/>
	<path class="st3780" d="M651.6,398.4c-1.3,1-1.8,2.4-2,4c-1.8,0.8-2.1,2.3-2,4c-0.3,0-0.7,0-1,0c-2.4-2.8-0.1-5.8-0.2-8.7
		c1.4-7.3,2.1-14.6,0-21.9c-0.3-1.1-0.5-2.3,0.2-3.4c2.9-0.9,3.9,0.6,3.9,3.2c0,2.2,0.2,4.3,2,5.8
		C653.1,387.1,650.9,392.7,651.6,398.4z"/>
	<path class="st3781" d="M646.6,372.4c3.1,8.2,3.2,16.6,1,25c-0.8,0.6-0.5,3.5-2.7,1.4c-1.5-3.1,0.2-6.2,0.4-9.2
		c0.3-5.6-0.8-11.1-0.7-16.7c0.2-0.6,0.5-1.1,1-1.4c0.3,0,0.7,0,1,0C646.6,371.7,646.6,372.1,646.6,372.4z"/>
	<path class="st3550" d="M589.7,343.5c1-1.3,2-2.7,3-4c2.6,0.4,3.6,1.4,1.7,3.9c-1.4,1.8-3.5,3.4-3,6.3
		C590,347.6,591.6,345.1,589.7,343.5z"/>
	<path class="st3779" d="M597.6,369.4c0.2-1.6,0.7-3,2-4c0,0,0,0,0,0c0.8,0.8,0.9,1.8,0.5,2.8C599.6,369.2,598.9,369.9,597.6,369.4
		L597.6,369.4z"/>
	<path class="st3782" d="M616.7,307.5c8.6-8.5,18.2-15.7,27.9-22.9c5.4-1.4,9.4-6,15-7.1c1.1-0.6,1.6,0.1,2,1
		c-4.5,1.7-9.3,3.1-8.3,9.7c0.2,1.3-1.6,1.2-2.7,1.3c-2.5,1.3-4.9,2.6-5,6c-2.1,0.1-3.3,0.7-2,3c0,1,0,2,0,3c0,1,0,2,0,3
		c0,3.3-0.2,4.9-4.5,3c-2.7-1.2-6-4.4-9.7-1.6c-0.4,0.3-0.6,1.1-0.8,1.7c-1.8-0.1-3.3,0.3-4.1,2.1c-0.8,1.8-2.6,2.1-4.1,2.4
		c-4.5,0.6-4.5,0.5-6.3,3.7c-0.5-1.1-1-2.1-1.4-3.2C613.4,310.3,614.4,308.4,616.7,307.5z"/>
	<path class="st3247" d="M679.6,291.5c2.6-2.1,6.7,0.2,9-3C687,293.9,682.5,290.5,679.6,291.5z"/>
	<path class="st3783" d="M616.7,307.5c-1.5,1.6-2.1,3.9-4.1,5c-6.3,6.5-12.2,13.2-17,20.9c-2.1,1.2-2.8,3.6-4.2,5.4
		c-0.7,0.9-1,2.6-2.9,1.6c6-10.3,13.7-19.4,21.7-28.2c1.8-1.9,3.6-3.8,5.4-5.7C616,306.9,616.4,307.2,616.7,307.5z"/>
	<path class="st3784" d="M739.5,334.5c0.1,11.4-2,22.2-8.1,31.9c-3.3-1.5-1.1-3.8-0.4-5.3c3.9-8.2,4.5-16.9,3.8-25.8
		c-0.1-0.5-0.1-0.9,0-1.4c0.4-2.2,2.2-1.8,3.7-1.9c0.5,0,0.8,0.3,0.9,0.7C739.4,333.3,739.5,333.9,739.5,334.5z"/>
	<path class="st3785" d="M704.5,316.5c0.3-0.7,0.7-1.3,1-2c6-1.9,11.7-5.6,18.5-3.1c2.5,0.9,4.8,1.9,6.5,4.1
		c-1.5,1.2-3.1,0.3-4.7,0.3c-6.1-1.3-12.1-1.7-18,0.9C706.8,317.2,705.6,317.3,704.5,316.5z"/>
	<path class="st3786" d="M726.5,314.5c1.3,0.3,2.7,0.6,4,1c6.4,3.9,8.9,9.9,8.9,17.2c-0.3-0.1-0.6-0.2-0.8-0.3
		c-1.7-1.3-1.7-3.7-3-5.2c-1.5-4.3-4.1-7.6-8.3-9.6c-0.5-0.5-0.8-1.1-1.1-1.8C726,315.3,726.1,314.9,726.5,314.5z"/>
	<path class="st3549" d="M643.6,298.5c-1.3-1-3.8-1.9-2.1-4c1.6-2.1,2.7,0.7,4.1,1C645.6,296.9,644.9,297.9,643.6,298.5z"/>
	<path class="st3787" d="M661.6,278.5c-0.7-0.3-1.3-0.7-2-1c2.6-3.1,6.5-3.5,10-4.9c-0.5,1.7,3,3,0.9,4.6
		C667.9,279.2,664.9,280.2,661.6,278.5z"/>
	<path class="st3782" d="M659,287c-0.5,0.1-1,0.4-1.6,0.4c-0.6,0-1.5-0.1-1.2-1c0.2-0.7,0.9-0.8,1.6-0.7
		C658.4,285.7,659.1,285.7,659,287z"/>
	<path class="st3412" d="M706.5,264.4c-3.6,0-7.3,0-10.9,0.1C699.2,263,702.8,263.1,706.5,264.4z"/>
	<path class="st3788" d="M723.5,669.1c-2.9-1.7-5.7-3.5-8-6c-0.6-0.7-0.7-1.7-0.1-2.3c2.7-2.7,2.8-6.2,3.2-9.6
		c0.5-1.6,1.6-2.6,3.1-3.1c4.8-1.7,9.5-3.1,13.8,1c1.6,1.9,3.7,2.1,6,2c3.2,1.3,2.4,3.3,0.6,5.1c-1.2,1.2-2.6,2-2.6,3.9
		c0.3,3.4-0.8,5.4-4.6,5c-1.7-0.2-3.3,0.1-4.4,1.5C728.7,669,726.2,669.3,723.5,669.1z"/>
	<path class="st3789" d="M735.5,649.1c-0.5,0-1.1,0.2-1.5,0c-3.9-2.7-7.7-1.1-11.5,0c-0.5-0.8-0.5-1.7-0.1-2.3
		c3.4-5.8,5.2-12.7,10.1-17.6c3.1-0.1,5.9-0.2,8,3.1c1.2,1.9,3.9,0.7,6,0.9C743.9,639.2,737.2,642.4,735.5,649.1z"/>
	<path class="st3790" d="M704.5,702c-0.9,2.1-3,2.9-4.8,3.9c-4.1,2.3-8.1,4.7-12.2,7c-1.2,1.2-2.6,2.3-4.3,0.9
		c-1.6-1.4-1.7-3.2-0.9-5c2-4,4.6-7.7,6.3-11.8c4.1-5.5,8.2-11.1,14.8-14c1.9,3.2,2.9,6.1,0.5,9.9
		C702.5,695.5,706.1,698.8,704.5,702z"/>
	<path class="st3791" d="M703.5,683.1c-4.7,4.7-9.3,9.3-14,14c-0.1-0.2-0.3-0.4-0.4-0.6c-0.4-3,1.6-4.9,3.1-7
		c3-4.1,5.7-8.4,8.3-12.8c1.7-3.2,3.8-6.1,6.4-8.5c1-0.7,2.1-1.6,3.4-1c0.8,0.4,0.8,1.2,0.2,1.9c-1.1,2.9-2.9,5.5-2.1,8.9
		c0.3,1.5-0.5,3.1-2.5,2.8C704,680.4,703.7,681.6,703.5,683.1z"/>
	<path class="st3792" d="M710.5,669.1c-1-3.1-2,0.3-3,0c-1.6-2.9,1.5-4.2,2.2-6.3c0.6-2,2-3.6,2.9-5.5c0.9-1.7,1.9-3.3,3.2-4.6
		c1.1-0.8,1.9-2.2,3.7-1.5c1.1,4.8-1.2,8.5-4,12C713.8,665.1,712.2,667.1,710.5,669.1z"/>
	<path class="st3793" d="M670.6,727c-0.4,0.7-0.7,1.3-1.1,2c-3.9,2-5-0.3-5.3-3.6c-0.4-4.4,0.6-8.9-1-13.2c-0.1-0.9,0.2-1.6,0.8-2.2
		c1.6-0.7,2.3,0.5,3.2,1.4c1.4,1.5,1,3.7,2.2,5.4c2.2-3.2,3.5-6.7,4.7-10.2c1-1.1,1.9-2.6,3.8-1.3c0.2,0.4,0.6,0.9,0.5,1.4
		c-1.9,6.7-3.5,13.5-7.6,19.3C670.6,726.4,670.5,726.7,670.6,727z"/>
	<path class="st3794" d="M670.7,726.1c2.3-7,4.7-14,7-20.9c0-0.2,0-0.5-0.1-0.7c0.2-1.8,0.8-3.5,1.2-5.3c0.3-0.8,0.7-1.7,1.1-2.5
		c1.1-2,2-4,2.7-6.2c0.2-0.5,0.4-0.9,0.6-1.3c0.8-0.8,1.5-2.3,2.9-0.7c1.2,4.8-2.5,8.5-2.8,13c-1.6,3.2-3.4,6.4-4.2,9.9
		c-0.7,2.9-2.1,5.6-2.7,8.6c-0.5,1.7-1.8,2.7-2.9,4C673,725.3,672.1,726,670.7,726.1z"/>
	<path class="st3795" d="M673.6,724c-0.2-1.7,1.4-2.6,2-4c1.2-2.8,1.9-5.9,6.5-4.7c1.5,0.4,3.5-1.5,5.5-1.3c0.7,0,1.4,0,2,0
		C685.1,718.7,679.1,721.1,673.6,724z"/>
	<path class="st3796" d="M687.5,714c-1.4,1-3.5,0.8-4.5,2.7c-0.2,0.5-1.4,1-1.5,0.9c-3.8-3.7-4.1,1.3-6,2.4c0.1-3.3,1.6-6.1,3-9
		c1.8-5.2,5.4-9.2,8.8-13.4c0.5-0.6,1.3-1,2.2-0.6c0,0,0,0,0,0c-0.8,4.3-3.4,7.7-5.6,11.3c-0.9,1.4-1.6,2.9-0.4,4.5
		c1.3,1.8,2.7-0.2,4,0.1C687.5,713.4,687.5,713.7,687.5,714z"/>
	<path class="st3797" d="M499.8,572.2c-5.1-1,1.7-2,0-3c2.1-3.4,6.1-3.5,9.2-4.6c10.1-3.7,19.9-8,29.7-12.4c0.4-0.3,0.8-0.4,1.2-0.3
		c1.2,0.8,2.3,1.6,1.7,3.3c-7.6,4.2-15.6,7.4-23.8,10C511.7,567.2,506.2,570.9,499.8,572.2z"/>
	<path class="st3798" d="M539.7,552.2c-0.3,0-0.6,0-0.9,0c-1-1-2-2-3-3c2.3,1.3,4.1,0,6-1c4.4-1.9,8.8-3.6,13.2-5.5
		c1.5-0.7,3-1.5,4.7-0.7c0.6,0.6,0.7,1.3,0.6,2.1c-4.6,5-11,7-16.8,10.1C542.1,554.9,541,552.8,539.7,552.2z"/>
	<path class="st3799" d="M547.7,542.2c4.2-3.4,9.8-4,14.2-6.9c0.9-0.6,1.6-0.9,1.7-2c10.2-4.6,20.6-8.3,31.8-9.4
		c4,0.2,7.8-2.8,11.9-0.8c0.2,3.5-2.8,3.6-4.9,4.2c-8,2.6-16.2,4.6-24.3,6.9c-1.4,0.2-2.9-0.7-4.4,0.2c-5.5,3.8-12.3,4.5-18.2,7.6
		C553.1,543.4,550.4,544.3,547.7,542.2L547.7,542.2z"/>
	<path class="st3800" d="M595.6,525.2c-9.5-1.1-17.4,4-25.9,6.7c-2.1,0.7-4,1.2-6.1,1.2c-4,0.7-8,1.3-12,2c0.7-3.3,4.8-1.4,6-4
		c6.9-0.7,13.2-3.7,20-4.7c5.4-0.7,10.6-2.8,15.9-4.3c2-0.3,4.2,0.8,6-1c0.2-0.2,0.4-0.4,0.6-0.5c1.3-0.5,2.6-0.5,3.6,0.6
		c0,2-1.4,2.4-3,2.4C598.9,523.7,597.2,524.2,595.6,525.2z"/>
	<path class="st3801" d="M547.7,542.2c3.2,0.1,5.9-0.6,8.9-2.1c5.7-2.9,12.4-3.8,17.9-7.6c1-0.7,2.2,0.3,3.2,0.7
		c1.3,2.4-0.9,2.7-2.2,3.4c-4.9,2.7-10.3,4.4-15.8,5.6c-6,1.9-11.5,5.6-18,6C543.1,545.6,547.9,546.4,547.7,542.2z"/>
	<path class="st3802" d="M515.7,575.2c0.6-3.5,3.2-4.8,6.3-5.7c1.4-0.4,3.5,0.1,3.7-2.3c4.2-2.5,8.4-5.3,13.4-5.8
		c3.2-0.3,2-5.3,5.7-4.3c0.4-0.3,0.8-0.4,1.3-0.2c0.9,0.4,1.5,1.1,1.9,1.9c0.2,0.5,0.1,0.9-0.2,1.3c-0.8,0.4-1.1,1.2-1.1,2
		c-1.5-0.5-2.7-1.2-4.6,0.1c-5.2,3.6-10.9,6.3-16.1,9.7C522.8,574.1,518.9,573.5,515.7,575.2z"/>
	<path class="st3803" d="M552.7,564.2c-0.3-0.7-0.7-1.3-1-2c4.6-0.1,7.1-4.2,11-5.6c4.2-1.8,7.6-5.3,12.2-6.4c1.5-0.2,3-0.2,4.4,0.7
		c1.2,2.2-0.8,2.6-2,3.1c-4.7,2.3-8.6,5.3-12,9.3c-2.1,2.4-4.8,4.1-8.1,4.5c-0.5,0.1-1,0-1.4-0.2
		C554.4,566.8,552.4,566.6,552.7,564.2z"/>
	<path class="st3804" d="M562.7,557.2c-3.7,1.7-5.4,7.5-11,5c-0.3-0.3-0.7-0.7-1-1c0.3-1.7,1.6-2.7,2.6-4c0.9-0.8,1.9-1.1,3.1-1.2
		c1.5,0.1,2.8-1,4.2-1.1C561.6,555.4,562.3,556.1,562.7,557.2z"/>
	<path class="st3805" d="M553.7,558.2c-0.7,1.4-1.7,2.4-3,3c-1-0.4-1.9-0.7-2.9-1.1c0-0.3,0-0.6,0-1
		C549.5,557.7,551.4,556.9,553.7,558.2z"/>
	<path class="st3806" d="M552.7,564.2c1,1,2,1.9,3,2.9c0.3,0.2,0.3,0.5,0.2,0.7c-0.1,0.2-0.2,0.3-0.3,0.3c-0.3,0-0.7,0-1,0
		C553.7,567,550.8,566.9,552.7,564.2z"/>
	<path class="st3807" d="M555.7,568.2c0-0.4,0-0.7,0-1.1c0.3,0,0.7,0,1,0c0.9,0.2,1.5,0.8,1.8,1.6c2.7,11.3-2.1,17-12,21.8
		c-3.4,1.6-6.8,2.8-9.7,5.6c-2.4,2.3-5.8,3.3-9.1,1c-0.2-2.5-2.1-3.7-4-5c1.3-1.5,3.1-1.1,4.7-0.9c2.9,0.3,5.2-0.9,7.1-2.9
		c1.6-1.7,3.3-3.2,5.7-3.6c7.2-1.1,9.2-8.3,13.9-12.3C555.9,571.8,555.5,569.7,555.7,568.2z"/>
	<path class="st3808" d="M545.7,557.2c-0.3,0-0.6,0-1,0c-1-0.7-2-1.3-3-2c-0.7-1-1.4-2-2-3c3.6,2.3,6.5-0.7,9-2
		c3.7-1.9,7.4-3.9,11-6c2.8-2.4,5.8-4.2,9.8-3.2c1.5,1.9-0.1,2.5-1.3,3.3c-5.7,3.5-11.8,6.4-17.5,9.8c-1.5,0.9-2.5,2-3.6,3.2
		C546.5,557.6,546.1,557.6,545.7,557.2z"/>
	<path class="st3809" d="M525.8,611.7c0.1-1.3,0.8-2.1,1.9-2.6c2.3-1.2,2.3,0.6,2.6,2c-0.4-2.1,1.1-2.4,2.7-2.6
		c5.4-0.6,9.6-3.3,12.4-7.8c3.1-4.9,8.3-7.4,12.1-11.5c2.7-3,5.3-5.7,6-9.8c0.3-1.7,0.9-3.2,2.8-3.8c3.1-0.2,4.9,2.1,6.9,3.8
		c1.2,1.6,4.1,2.1,3.4,5c-1.3,0.8-2.9-0.3-4.3,0.7c-0.6,0.2,0,1-0.2,0.4c0-0.1,0.1-0.2,0.3-0.3c2.4-1.3,3.8-0.4,4.3,2
		c0.5,2.2,1.2,4.4,0.4,6.7c-1.2,1.2-2.5,2.3-2.6,4.2c-0.3,0.9-0.7,1.6-1.4,2.1c-1.6,0.6-3.1,1.2-3,3.3c-0.8,2.9-3.2,4.7-4.3,7.3
		c-0.7,1.8-1.9,3.2-3.5,4.2c-6,5-11.1,11-16.8,16.3c-1.7,1.6-3.1,3.6-5.8,3.3c-1.2-0.3-2.1-1.2-2.9-2.1c-3.5-5.9-6.1-12.4-10.7-17.6
		C525.9,614,524.5,612.9,525.8,611.7z"/>
	<path class="st3806" d="M566.7,576.2c-3.4,2.8-1.5,7.9-4.9,10.7c-3,2.6-5.5,5.8-8.6,8.2c-2.3,1.8-5.2,3.1-6.2,5.9
		c-1.8,5.3-7.9,5.2-10.8,9.1c-0.8,1-5-2.3-6,2c-0.7-1.1-0.2-3.2-2.4-3.1c0.4-4-1.4-8,0-12c4.3,0,8.5,0.3,10.6-5.1
		c0.7-2,4.1-1.5,6.2-2.2c10-3.5,14.7-10,13.2-20.6c0-0.3,0-0.7,0-1c1-0.5,1.7,0,2.5,0.5c1.5,1.4,3.1,2.6,4.8,3.7
		c0.9,0.7,1.9,1.3,2.2,2.5C567.2,575.4,567,575.8,566.7,576.2z"/>
	<path class="st3262" d="M525.8,611.7c-0.2,1.2,1.7,1.6,1.1,2.9c0.5,1.2,0.9,2.4-0.2,3.5c-2-3-4-6-6-9c1,0,1.9,0,2.9,0
		C524.1,610.2,524.5,611.3,525.8,611.7z"/>
	<path class="st3810" d="M514.8,606.2c0.4,0,0.7-0.1,1.1-0.1c4.1,4.1,6.1,9.7,9.8,14.1c3.2,3.4,4.8,7.7,7.2,11.6
		c1.2,2,2.4,3.9,4.9,4.4c1.1,0.4,2.4,0.2,3.4,0.7c3.3,1.7,4.8,2.4,6.8-2.5c1.7-4.3,6.1-8.6,11.9-9.5c0.3,3.7,0.5,7.4-3.1,9.9
		c-2.4,1.6-2,5.3-5,6.5c-0.3-0.2-0.8-0.5-0.8-0.4c-2.9,5.8-8.5,9.9-10.7,16.2c-8.4,5.5-18.5,5-27.6,8.1c-16.4,5.7-33.3,5.1-50.2,3
		c-1.1-0.4-2.2-1-2-2.4c0.3-1.6,1.7-1.8,2.9-2c3.6-0.4,7.3-0.2,10.9-0.2c5.6,0.1,11.2,0.2,16.5-2.6c2.5-1.3,5.4-1.5,8.3-1.3
		c4.4,0.3,8.6-0.7,12.4-2.9c6-1.1,10.7-6,17-6.5c1.2-0.1,2.2-1,2.5-2.2c1-3.3,3.6-5.1,6.7-6.9c-1.4-1.1-2.9-0.8-4.2-1
		c-3.6-0.6-4.4-3.8-5.4-6.5c-1.2-2.9-2.3-5.8-3.7-8.6c-0.6-1.2-1.3-2.4-2-3.5c-0.5-0.7-0.8-1.5-1.2-2.3c-0.5-1.4-1.1-2.7-2.1-3.8
		c-1.1-1.6-2.3-3.2-3.1-4.9C515.5,609.1,514.1,607.9,514.8,606.2z"/>
	<path class="st3811" d="M526.7,618.1c0.1-1.2,0.1-2.4,0.2-3.5c5.1,5,7.7,11.6,10.9,17.8c-0.9,2.4-2.8,3.1-3.9,1
		c-2.4-4.4-6.6-7.9-7-13.2C526.7,619.5,526.7,618.8,526.7,618.1z"/>
	<path class="st3812" d="M526.8,620.1c1.6,4.6,5,8.1,7.4,12.2c0.6,1.1,2.3,3,3.6,0.1c0.6,0.6,1.3,1.2,1.9,1.8
		c0.6,0.5,0.6,1.2,0.4,1.9c-0.6,0.9-1.5,1-2.4,0.9c-2.1,0.5-3.6-0.7-4.5-2.3c-2.6-4.8-5.8-9.2-7.5-14.5
		C526,620.1,526.4,620.1,526.8,620.1z"/>
	<path class="st3813" d="M439.8,607.1c-0.2-4,4.2-5.5,5-9c7.2-6.1,14.6-11.7,24.4-13c2.9-0.4,4.4-3.8,7.6-3c3.6-1.5,7.3-1.3,11-1
		c-0.4,0.5-1,1.4-1.3,1.3c-8.6-2.2-13.6,5.1-20.4,7.7c-0.2,0.1-0.2,0.6-0.3,0.9c-0.1,0.2-0.3,0.4-0.4,0.6c-4.7,2.1-8.7,5.6-13.6,7.2
		c-3.7,1.3-6,4.6-9.1,6.7C441.8,606.3,441.3,607.6,439.8,607.1z"/>
	<path class="st3814" d="M452.8,676.1c4.3,0.4,8.6,1.5,13,1c1.6-1.5,3.4-1,5.2-0.5c5.6,1.5,11.5,1.7,17.1,3.5
		c2.7,0.9,5.5-0.1,7.9-1.6c1.5-0.9,3-1.7,4.7-1.5c9.8,1.5,19-1.9,28.4-3.6c1.5-0.3,3,0.1,4.5-0.1c3.9-0.6,6.7-1.8,8.4-6.3
		c1.6-4.3,1.6-8.7,2.8-12.9c0.8-1.7,1.7-3.5,4.1-3c0.8,1,0.8,2.1,0.5,3.3c-0.9,3.6-0.3,6.7,2.6,9.3c1.5,1.4,1.9,3.4,1.7,5.4
		c-0.4,5.1-3.5,7.4-8,9c-4.7,1.7-9.3,4-13.7,6.6c-4.3,2.6-9.3,1.9-14,3.1c-3.6,0.9-6.9,2-10.1,3.8c-4.8,1.8-9.6,3.8-14.8,1.5
		c-1.5-1.2-3.2-1.7-5.1-1.7c-7.8-0.1-15.3-1.8-22.6-4.6c-0.3-0.1-0.9-0.1-0.9-0.2c-2.1-5.7-8.5-5.6-12.3-9
		C452.3,676.9,452.5,676.5,452.8,676.1z"/>
	<path class="st3815" d="M545.7,654.1c0.9,5.9-1.8,11.2-3.4,16.6c-1,3.2-5.7,4.4-8.4,4c-4.7-0.8-8.5,1.7-12.9,1.9
		c-3.6,0.2-7.5,0.2-11,2.2c-1.7,0.9-4.2,0.3-6.3-0.2c-3.2-0.7-6.7-1.9-8,2.9c-0.2,0.8-0.7,1.1-1.4,0.4c-0.4-0.3-0.6-0.8-0.9-1.1
		c-4.7,3.9-8.6-1.3-13.1-1.2c-4.8,0.1-9.7-1.6-14.5-2.5c-1.5-1.9-3.7-2-5.9-2c-1.2,0-2.5-0.3-2.7-1.7c-0.3-1.8,1.2-2.1,2.6-2.3
		c9.6,1.2,19.4,1.7,28.8,0.6c8.8-1,17.8-0.5,26.4-2.8c1.6-0.4,3.2-0.6,4.9-0.8c5-0.4,9.8-1.8,13.6-5.3c1.6-1.5,3.3-2.7,5.5-3.2
		c2-0.1,3.3-0.8,3.6-3.1C542.8,655,543.1,652.8,545.7,654.1z"/>
	<path class="st3816" d="M452.8,676.1c0,0.3,0,0.7,0,1c-3.9,1.5-6.4-0.3-8.6-3.5c-3-4.3-4.3-9.6-8.1-13.4c-3.8-8.3-5.1-17-4.3-26
		c0.2-2.1,0.7-4.3,1-6.4c1.2-7,3-13.6,9.4-17.8c1.5-1.2,3.1-2.3,3.8-4.2c0.4-0.6,0.8-1.2,1.4-1.6c4.3-2.8,8.9-5.3,12.1-9.4
		c1.7-2.1,4.6-1.8,6.3-3.6c4.1-0.1,7.8-1.3,11.1-3.6c2.8-2,6.2,0.7,8.9-1.4c-0.3,2.3-2.2,2.9-4,2.9c-9.1,0.3-16,5.7-22.8,10.6
		c-4.9,3.6-8.9,8.4-13.6,12.3c-4.3,3.6-3.3,9.1-5.2,13.6c-3.2,7.7-0.8,16.2-1.3,24.3c0,0.3,0,0.8,0.2,0.9c5.3,3.7,3.7,11.5,9.4,15.5
		C450.7,668,452.5,672.3,452.8,676.1z"/>
	<path class="st3817" d="M539.7,660.1c-5.3,3.1-9.4,8.7-16.1,8.9c-6.6,0.2-12.6,3.9-19.5,2.3c-3-0.7-6.3-0.6-9.5,1.3
		c-2.7,1.6-6.3,0.9-9.6,0.6c-6.3-0.5-12.6-0.2-18.9-0.1c-2.4,0-4.7,0-6.4-2c0-0.8-0.2-1.6-1-2c0.1-3.1,3.1-0.6,4-2
		c13.3,2.4,26.6,0.3,40-0.1c5.3-0.1,9.7-3.7,14.8-4.8c2.9-0.6,5.8-0.9,8.6-1.3c5.2-0.7,9.5-3.8,14.5-4.8
		C541.9,657.8,541,659,539.7,660.1z"/>
	<path class="st3586" d="M462.8,667.1c-0.9,1.6-3.1,0.4-4,2c-4.1-1.6-7.8-3.5-7-9h0c1.9-0.8,3.7-0.2,5.6,0.3
		c0.4,0.2,0.8,0.5,1.1,0.8c1.1,1.5,2.8,0.7,4.2,1.1c1.2,0.6,2.1,1.3,1.1,2.8c-0.9,0.1-2.1-0.3-2.4,0.8
		C461,666.9,462.1,666.9,462.8,667.1z"/>
	<path class="st3818" d="M457.8,662.1c0-0.3,0-0.7,0-1c-4.8-1.1-3.7-6.2-6-9c-0.1-1.5,0.4-3.7-0.4-4.3c-6.7-4.8-3.4-11.9-3.6-17.8
		c-0.2-3.4,2.6-8,6-10.9c-0.6,5.3-1.1,10.6-1.8,15.9c-0.4,2.9-0.3,5.6,2.8,7.1c1,0.7,1.6,1.7,2,2.8c3.1,9.2,10.8,11,19,11
		c6.5,0,12.9-0.1,19.4-0.2c3-0.1,5.5-1.8,8.3-2.7c1.7-0.8,3.4-1.2,5.2-1c2.8,0.6,4,2.4,4,5.1c-3.4,2.9-7.1,4.4-11.7,4.1
		c-4.9-0.4-9.8-0.1-14.1,3.4c-1.1,0.9-3.2,0.5-4.8,0.5c-6.1,0.1-12.2,0-18.4,0c0-1.3-0.7-2-2-2C460.7,661.6,458.9,663.5,457.8,662.1
		z"/>
	<path class="st3810" d="M451.8,652.1c4.1,1.6,2.3,7.1,6,9c-2.1,0-4.1,0.2-6-1C451.8,657.4,451.8,654.8,451.8,652.1z"/>
	<path class="st3819" d="M495.8,657.1c-7.9,0-15.8-0.2-23.8,0.1c-5,0.2-9-2.7-12.9-4.7c-2.7-1.4-3.8-6.5-4.3-10.4
		c0.7-5,0.4-10.1,2-15c0.4,7.2,4.1,12.6,7.7,18.7c4.9,8.3,11.5,5.9,17,5.5c3.7-0.3,9.6-0.5,11.7-2.8c3.9-4.2,8.7-2.1,12.3-4.2
		c2.9-1.7,5.1-4.7,8.3-6.3c6.9-2.1,7-9.6,11-14c3.3,3.5,3.7,8.3,5.9,12.3c0.8,1.4,1.4,2.6,3.1,2.6c-0.4,3.3-2.5,4.6-5.4,3.4
		c-1.4-0.6-2.2-1.9-3.4-2.9c-2.5,8.2-8.7,11.6-16.2,13.6c-1.8-0.1-3.5,0-5,1C501.1,655.1,497.5,653.7,495.8,657.1z"/>
	<path class="st3820" d="M521.7,619.1c0.8,0.4,1,1.2,1,2c0.9,2.6,1,4.6-2.6,4.8c-3,0.3-5.1,3-5.8,4.9c-1.9,5.3-5.8,5.5-10.2,5.6
		c-1.3-0.3-2.6-0.4-3.9-0.3c-2.8,0.1-4.9,0.4-4.7-4c0.1-3.1-2.8-6.2-5-8.8c-3.2-3.8-4.9-8.1-4.8-13.1c3.8-2.2,8.7-2.6,10.9-7.6
		c1.1-2.4,10.1-2.2,12.1-0.1c1,1.1,1.8,1.3,3,0.7c1.6,2.4,3.1,4.9,5,7c1,1.7,2,3.3,3,5C520.8,616.3,520.4,618.1,521.7,619.1z"/>
	<path class="st3821" d="M485.8,610.1c1.9,8.2,6.9,14.8,12.1,21.1c1.7,2.1-2.3,1.5-1.4,3.3c2.2,1.6,5.2-0.7,7.3,1.5
		c0.3,0.2,0.5,0.4,0.3,0.7c-0.1,0.2-0.2,0.3-0.3,0.3c-7.6,3.4-16.1,3.2-23.8,6.2c-2.8,1.1-5.9,2-8.2-0.6c-3-3.4-8.1-6.5-5.4-12.1
		c1.8-3.9,2.4-9,7.5-10.6c0.6-0.2,1.2-0.8,1.4-1.4C476.7,613.1,481.1,611.5,485.8,610.1z"/>
	<path class="st3822" d="M503.8,637.1c0-0.3,0-0.7,0-1c1.6-1,3.4-1,5.2-1c1,0,2.2,0,2.5-1.1c1.2-5.6,4.3-8.8,10.3-9.3
		c1.9-0.1-0.9-2.8,1-3.6c1.3,0.6,2,1.6,2,3c-2.1,5.9-2.2,13.3-11,14C510.3,638.7,507.1,637,503.8,637.1z"/>
	<path class="st3822" d="M516.7,610.1c-2.9-1.4-4.7-3.7-5-7c0-0.3,0-0.7,0-1c1,1.4,2,2.7,3,4.1C515.4,607.5,516.9,608.4,516.7,610.1
		z"/>
	<path class="st3823" d="M623.6,807.9c0-0.3,0-0.6-0.1-1c2.8-3.7,7.9-3.9,11.1-7c1-0.8,1.8-0.5,2.3,0.6c-0.2,1.3-1.1,2.2-2.1,2.9
		c-2.4,0.8-4.3,3.1-7.1,2.6C626.3,806.7,625,807.3,623.6,807.9z"/>
	<path class="st3824" d="M636.6,800.9c-0.4-0.8-1.2-1-2-1c0.9-1.6,3.1-0.4,4-2c0.7-0.3,1.3-0.6,2-0.9c0.6-1.2,1.6-1.6,3-1.3
		c0.3,0.4,0.5,0.8,0.6,1.3c-0.1,1.3-0.8,2.2-1.7,3c-0.4,0.3-0.8,0.5-1.2,0.7C639.7,801.2,638.2,802.2,636.6,800.9z"/>
	<path class="st3825" d="M562.7,848.8c0.3,0,0.6,0,1,0.1c2.6,0.5,5,1.2,5,4.6c-0.4,2.2-1.8,3.8-3,5.6c-1.1,1.1-1.6,2.5-1.5,4.1
		c-0.2,0.7-0.5,1.2-1.4,0.8c-0.9-0.4-1.1-1.2-1.3-2c0.1-1,0.1-2,0.2-3C562,855.5,560.3,851.9,562.7,848.8z"/>
	<path class="st3826" d="M562.7,848.8c-0.8,3.3,1.7,6.9-1,10.1c-1.5,0-2.9,0-3-2c0-3.1,0.8-6.1,2-9
		C561.7,847.4,562.7,847,562.7,848.8z"/>
	<path class="st3827" d="M567.7,845.8c0.3-0.3,0.7-0.6,1-0.9c3.8-4.1,7.3-8.8,14.1-7.2c1.2,0.3,1.9-1.2,2.9-1.8
		c1.9,2.2,1.5,5.1,0.8,7.1c-1.6,4.7-2.5,9.9-5.8,13.9c-0.9-1.5-1-2.9-0.3-4.6c1-2.7-0.7-3.5-3-3.5c-1.1-2,0-3.5,1-5.1
		c0.2-0.4,0.7-1,0.4-1.2c-0.8-0.7-1.3,0.3-2,0.7c-1.7,1.5-3.1,3.4-5,4.5C569.7,848.8,567.9,849.1,567.7,845.8z"/>
	<path class="st3828" d="M567.7,845.8c1.2,2.3,2.5,2.1,4.2,0.4c1.5-1.5,3-3,4.6-4.4c1.8-0.4,3.7-1.2,3.2,2c-1.2,1.5-1.9,3.1-2.3,4.9
		c-3.3,2.2-5.6,5.2-6.6,9.1c-0.8,2.7-2.9,3.9-5.3,4.6c-3.4-0.6-1.1-2.4-0.8-3.7c1.1-1.6,2.1-3.3,3.2-4.9c1-2,0.5-3.4-1.8-4.1
		c-1.4-0.4-2.3-1.3-1.3-2.9C565.3,845.4,566.6,846,567.7,845.8z"/>
	<path class="st3829" d="M565.4,861.8c1.8-1.3,3.5-2.6,5.3-4c1.8,0.8,2.1,2.3,2,4c-0.8,0.2-2.1,0.1-2.2,0.5
		c-1.7,5.5-4.8,2.7-7.7,1.5c0.3-0.3,0.7-0.6,1-0.9C564,862.1,565.2,862.6,565.4,861.8z"/>
	<path class="st3830" d="M585.7,835.9c-0.2,1.7,0.2,4.1-2.8,3.1c-4.8-1.5-8.3,0.4-10.9,4.3c-0.9,1.3-1.9,1.6-3.3,1.6
		c-0.6-2.3-4.8-1.5-4-4.9c3.1-1.9,6-3.9,8-7C581,829.5,582.4,829.8,585.7,835.9z"/>
	<path class="st3831" d="M635.6,811c-3.5,2-7.1,3.7-11,5c0.4-1.8,1.3-3.2,3-4c3-1.6,5.9-3.5,8.7-5.4c0.5-0.1,1-0.1,1.4,0
		c1.1,0.6,2,1.4,2.2,2.7C639,811.1,637.5,811.6,635.6,811z"/>
	<path class="st3832" d="M636.7,807c-2.7,2.1-5.5,4.3-9.1,4.9c1.8-2.9,6.3-3,7-7c0.1-0.1,0.3-0.3,0.4-0.4c0.6-0.2,1.2-0.1,1.7,0.2
		C637.2,805.5,636.7,806.2,636.7,807z"/>
	<path class="st3832" d="M627.7,806.1c2.3-1.1,4.6-2.1,6.8-3.2c2.6,0.6,0.6,1.4,0.1,2.1c0,0,0,0,0,0
		C632.2,804.8,630.5,808.7,627.7,806.1z"/>
	<path class="st3833" d="M635.6,811c1.3-0.7,2.7-1.4,4-2.1c1.6-2.6,3.6-2.7,5.9-0.9c-1.6,2.8-5.7,4.6-4,8.9c-1.8-0.6-3-0.1-4.1,1.5
		c-0.7,1.1-2,2.1-3.3,0.9c-1-0.9-0.7-2.3,0-3.2C635.4,814.5,635.7,812.8,635.6,811z"/>
	<path class="st3834" d="M638.6,826.9c-1.9,2.2-5.6,1.1-7,4c-2-0.2-4.4,3.1-5.9-0.8c4-1.7,8-3.5,11.9-5.2
		C638.4,825.3,639.2,825.8,638.6,826.9z"/>
	<path class="st3835" d="M638.6,826.9c-0.3-0.7-0.7-1.3-1-2c1.6-1,3.3-2,4.9-3.1c0,0.7,0,1.4,0,2.2c-1,0.9-2,1.9-3,2.8
		C639.3,826.8,638.9,826.9,638.6,826.9z"/>
	<path class="st3836" d="M714.5,598.2c2.1-5.6,3.2-11.5,4-17.4c0.2-1.3-0.2-2.7,1-3.6c0,1.8,0,3.7,0,5.5c0,1.3,0.4,2.3,1.9,2.4
		c1.9,0.1,1.9-1.3,2.1-2.7c0.1-1.3-1.6-3.4,0.8-3.6c0.9-0.1,2.8,2.3,2.8,3.5c0,4.1,2.1,6.3,5.1,8.4c1.8,1.3,1.7,3.6,0.6,5.4
		c-2.8,4.9-0.9,10.1-1.3,15.1c-0.2,1.9,1.6,2.3,2.9,3c0.7,1.1,1.3,0.1,2,0c0,2.2-2.6,3.6-2,6c0.1,1.1-0.5,2-1.2,2.7
		c-1.1,1-1.5,2.4-2,3.8c-0.3,0.8-0.8,1.6-1.4,2.3c-1.6,1.5-2.2,4.6-5.5,3.3c-0.1-3.7,2.7-6.6,2.7-10.3c2.5-1.9,3.8-3.8,0-5.7
		c-0.9-2-1.1-3.9-4.5-3.8c-4.9,0.2-7.4-2.7-7.5-6.6c-0.7,1.9,1.2,5.2-2.8,5.3C711.5,606.6,713,602.4,714.5,598.2z"/>
	<path class="st3837" d="M732.5,622.1c0.7-0.7,1.3-1.3,2-2c1.3-1.3,2.2-2.7,3-4.4c0.9-1.9,1.2,0.6,2,0.4c-1,2-3.6,3.2-3,6
		c-0.8,2.6-1.8,5.1-4,7c-3.3,6.7-6.7,13.3-10,20c-1.4,0-2.4,0.7-3,2c-1,0.7-2,1.3-3,2c-1.4-2,0.2-3.5,1-5c3.8-6.8,7.3-13.7,11.9-20
		c0.3-0.7,0.7-1.3,1-2C731.6,625,731.8,623.4,732.5,622.1z"/>
	<path class="st3838" d="M564.7,846.8c0.1,2.7,6.7,2.6,3.1,7c0.8-3.5-3.6-2.7-4.1-5C564,848.2,564.3,847.5,564.7,846.8z"/>
	<path class="st3839" d="M510.7,157.7c1.3,0,2.7,0,3,1.8c0.7,5.1,5.3,8.2,6.8,13c0.3,0.9,3,1.9,4.1,1.5c2.2-0.7,3.9,0.3,5.7,0.6
		c1.1,1.6-0.6,1.8-1.2,2.5c-0.9,0.5-2,0.9-3,1c-1.1,0-1.8,0.4-2.1,1.5c-0.8,1.1-1.6,2.1-3.1,1.1c-1.9-4.1-5.5-1.7-8.2-1.9
		c-1.8-0.2-3.2-0.5-2.6-2.3c1.1-3,0.6-5-2.3-6.5c-1.6-0.9-0.4-3.3-2-4.3c-0.3-0.2-0.3-0.4-0.3-0.7c0.1-0.2,0.2-0.3,0.3-0.3
		c0.2-1.5-0.3-2.7-1-4c0.7-0.7,1.3-1.3,2-2C508.1,158.3,509.4,158,510.7,157.7z"/>
	<path class="st3840" d="M508.7,189.6c-0.3,0.1-0.6,0.2-0.9,0.3c-3.1-1.8-5.8-4.8-10-3c-1.8,0.8-2-0.4-2-1.6
		c-0.1-1.5,0.2-3.5-1.6-3.7c-3.1-0.4-4.5-2.3-5.4-4.9c1.7-2.3,3.3-4.7,5-7c0,3.7,1.4,5.7,4.4,8.7c4.4,4.3,9.1,3.5,13.5,4
		c3.4,0.4,1.3,2.4,2.1,3.3C513.3,188.5,511.2,189.3,508.7,189.6z"/>
	<path class="st3841" d="M508.7,189.6c1.7-1.3,3.4-2.6,5-3.9c1.9-1.1,3.9-2.3,5.8-3.4c0.4,0.3,0.7,0.7,0.8,1.2
		c-0.6,3.4-3.8,4.2-5.8,6.2c-1.8,0.9-3,3.4-5.7,2.2C508.8,191,508.8,190.3,508.7,189.6z"/>
	<path class="st3625" d="M505.8,164.7c0,0.3,0,0.7,0,1c0,1.5,0,3-1.7,3.7c-1.4,0.5-2.1-0.4-2.7-1.4c-0.7-1.1-2.1-2-1.2-3.4
		c0.9-1.3,2.1,0.1,3.1,0.1C504.1,164.6,504.9,164.6,505.8,164.7z"/>
	<path class="st3842" d="M519.8,183.8c-0.1-0.5-0.1-1-0.2-1.5c0.4-0.5,0.9-1,1.3-1.5c1.3-0.2,2.2-1.1,3.1-1.9c0.3-0.8,1-1.1,1.8-1.1
		c0.9-0.4,1.9-0.8,2.8-1.2c0.9-0.5,1.3,0,1.5,0.8c-1.3,3.6-5,4.1-7.5,6.1C521.6,183.8,520.7,184.2,519.8,183.8z"/>
	<path class="st3843" d="M641.6,799.9c0.7-1,1.4-1.9,2.1-2.9c1.1-2.7,3.9-2.2,6-3.2c1.5-0.4,2.9-1,4.3-1.7c0.2-0.1,0.4-0.1,0.6-0.2
		c1,0.4,2,0.7,3.1,1.1c-0.7,0.6-1.4,1.3-2.1,1.9c-1.9,1.1-3.8,2.1-5.8,3.2C646.9,798.1,644.8,801.6,641.6,799.9z"/>
	<path class="st3844" d="M651.5,786.9c0.3-0.1,0.6-0.1,0.9-0.1c1.4-0.8,3-1.2,4.2-2.3c2.7-0.6,4.6-3.4,7.7-2.9
		c0.9,0.9,0.5,1.8,0,2.7c-0.5,0.6-1,1.1-1.8,0.8c-0.3,0-0.7,0-1-0.1c-3.1,2.3-6.8,3.7-9.9,6c-0.4,0.3-0.8,0.5-1.2,0.7
		c-1.3,0.6-2.5,1.2-3.9,0.4c-0.5-1,0.1-1.7,0.8-2.3C648.9,789.2,650.1,787.9,651.5,786.9z"/>
	<path class="st3845" d="M650.6,791c3-3.2,6.9-4.7,11-5.9c-1,1-2,2-2.9,2.9c-1.2,1-2.6,1.5-4.1,2l0,0
		C653.4,791,652.3,792.2,650.6,791z"/>
	<path class="st3846" d="M678.6,776.2c-1.6,1.2-3.9,1-5.2,2.8c-0.7,0.5-1.4,0.6-2.1,0c-0.3-0.5-0.3-0.9-0.3-1.5
		c2.4-2.2,5.1-3.7,8.4-3.9C680.3,774.9,679,775.4,678.6,776.2z"/>
	<path class="st3847" d="M688.6,770c-0.7,0.6-1.4,1.3-2.1,1.9c-1.3,0.5-2.6,0.9-3.9,1.4c-0.7,0.4-1.4,0.4-2.1,0
		c-0.2-0.3-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.2-0.5c0.4-0.4,0.8-0.7,1.1-1.1c2.1-0.5,3.9-1.9,5.9-2.4
		C688.3,768.7,688.8,769.1,688.6,770z"/>
	<path class="st3848" d="M662.6,785.1c0.3-0.3,0.7-0.6,1-0.8c1.4-1.3,2.8-2.6,4.6-3.4c1.2-0.4,2.3-0.9,3.4,0.2
		C669.1,783.7,666.7,786.2,662.6,785.1z"/>
	<path class="st3849" d="M680.7,772.9c0.7,0.1,1.3,0.3,2,0.4c-0.3,2.4-2.2,2.6-4.1,2.9c0.3-0.8,0.7-1.5,1-2.3
		C679.4,772.9,679.6,772.5,680.7,772.9z"/>
	<path class="st3850" d="M671.5,781c-1,0.3-2,0.5-3,0.8c-0.3-0.4-0.5-0.8-0.4-1.3c0.7-1.5,1.7-2.2,3.4-1.7c0.6,0.1,1.3,0.1,1.9,0.2
		C672.8,779.7,672.2,780.4,671.5,781z"/>
	<path class="st3851" d="M654.5,789.9c1.1-1.2,2.4-2,4.1-2C657.7,789.5,656.8,791.2,654.5,789.9z"/>
	<path class="st3852" d="M650.6,791c1.3-0.4,2.7-0.7,4-1.1c0,0.7,0,1.3,0,2c0,0,0,0,0,0c-1.2,1-2.4,1.2-3.8,0.4
		C650.3,791.9,650.2,791.4,650.6,791L650.6,791z"/>
	<path class="st3853" d="M645.6,808c-2-0.1-4.1-0.3-5.9,0.9c-0.7-0.6-1.3-1.3-2-1.9c0.3-1,0.9-1.6,2-1.2c3.8,0.1,6.1-3.8,9.9-3.7
		c0.2,2.6-3.8,3-3,5.8C646.2,807.9,645.9,807.9,645.6,808z"/>
	<path class="st3854" d="M641.6,799.9c3,0.5,5.1-2.6,8.1-1.9c-3.4,2-6.7,4-10.1,5.9c-0.2-0.1-0.4-0.2-0.6-0.3
		C637.2,800.6,640.4,801,641.6,799.9L641.6,799.9z"/>
	<path class="st3855" d="M639.7,805.8c-0.7,0.4-1.4,0.8-2,1.2c0,0-0.5,0-0.5,0s-0.5,0-0.5,0c-1.1-0.7-0.2-1.3,0-2
		c0.6-1.1,1.6-1.5,2.8-1.2c0,0,0.1,0.3,0.1,0.3C639.6,804.6,639.6,805.2,639.7,805.8z"/>
	<path class="st3630" d="M659.7,798c-0.9,1.6-2.4,1.9-4,2.1C656.5,798.5,658,798.1,659.7,798z"/>
	<path class="st3638" d="M674.6,772c0.3-0.7,0.7-1.3,1-2c1.3,0,2.7,0,4,0C678.2,771.3,676.3,771.5,674.6,772z"/>
	<path class="st3856" d="M680.7,772.9c-0.4,0.3-0.7,0.7-1.1,1c-2.9,0.8-5.7,2-8,4c-1.8,1.1-3.7,2.3-5.5,3.4
		c-0.4,0.1-0.9,0.1-1.3-0.1c-0.3-0.2-0.4-0.5-0.3-0.7c0.1-0.3,0.1-0.4,0.2-0.4c1.3-1.1,2.6-2.1,4-3.2c4.3-0.9,7.4-4.5,11.9-5
		C680.5,772.3,680.6,772.6,680.7,772.9z"/>
	<path class="st3857" d="M695.4,764.9c-0.3,0-0.6,0-0.9,0c-0.4-0.3-1-0.4-1-1c1.9-1,3.2-3.7,6-2.2
		C699.5,764.6,696.8,763.9,695.4,764.9z"/>
	<path class="st3858" d="M693.4,763.9c0.3,0.3,0.7,0.7,1,1c-1.4,0.7-2.2,1.7-1.9,3.3c0,0-0.4-0.1-0.4-0.1l-0.4,0
		c-1-0.1-2-0.1-2.8-0.7c-0.1-0.2-0.2-0.3-0.4-0.5C689.4,764.8,691.9,765,693.4,763.9z"/>
	<path class="st3859" d="M695.4,764.9c1-1.4,3.3-1.3,4-3.1c0.1-0.2,0.1-0.5,0.1-0.7c2,1.2,2,1.2-1,3.9c-0.6,0-1.2,0.1-1.9,0.1
		C696.2,765,695.8,764.9,695.4,764.9z"/>
	<path class="st3860" d="M687.5,768.9c-1.9,0.8-3.5,2.6-5.9,2c2.1-1.6,4.3-3.3,6.9-3.9c0,0,0,0.1,0,0.1
		C689,768.1,688.2,768.5,687.5,768.9z"/>
	<path class="st3861" d="M687.5,768.9c0.3-0.6,0.7-1.2,1-1.8c1.2,0,2.5-0.2,3.2,1.1c-0.7,1.2-1.8,1.8-3.1,1.8
		C688.2,769.6,687.9,769.3,687.5,768.9z"/>
	<path class="st3862" d="M664.6,780.1c0,0.3,0,0.6,0,0.8c0.2,0.2,0.3,0.4,0.2,0.6c-0.1,0.2-0.2,0.3-0.2,0.3c-2.6,1-5.3,2.1-7.9,3.1
		c-0.5,0.4-0.9,0.4-1.3-0.1C658.2,782.8,661,780.6,664.6,780.1z"/>
	<path class="st3863" d="M647.6,790.1c-0.4,0.7-0.7,1.4-1.1,2.1c-0.3,1.7-2.1,0.5-2.8,1.4c-1.1,0-2.3,0.1-2.1-1.6c2-1,4-2.1,6-3.1
		C648.2,789.3,648.2,789.7,647.6,790.1z"/>
	<path class="st3864" d="M641.6,792c0.8,1.1,1.9,0.8,3,0.9c0.3,0.7,0,1.2-0.5,1.7c-1.4,0.6-2.6,1.8-4.2,1.2
		C639.4,794.1,639.7,792.7,641.6,792z"/>
	<path class="st3865" d="M639.8,795.8c1.3-0.6,2.5-1.2,3.8-1.8c0.3,0.2,0.5,0.5,0.6,1c0,0.4-0.2,0.7-0.6,1c-1,0.4-2,0.7-3,1.1
		C640.4,796.6,640.1,796.2,639.8,795.8z"/>
	<path class="st3866" d="M647.6,790.1c0-0.4,0-0.7,0-1.1c1.3-0.7,2.3-2,3.9-2C650.9,788.9,649.4,789.6,647.6,790.1z"/>
	<path class="st3867" d="M655.4,784.8c0.4,0,0.8,0.1,1.3,0.1c-1.2,1.1-2.5,2-4.2,1.8C653.1,785.6,654.1,784.9,655.4,784.8z"/>
	<path class="st3868" d="M563.6,503.2c-2.6,2.2-5.8,2.6-8.9,3c0.2-0.8,1-1.6-0.3-2.2c1.7-3,4.8-3.5,7.7-4.3
		C564,500.4,564.2,501.7,563.6,503.2z"/>
	<path class="st3869" d="M562.8,500.6c-2.8,1.2-5.6,2.3-8.3,3.5c-0.6,0-1.2,0.1-1.8,0.1c-0.6-2,1-1.7,2.1-2c2.4-3.3,6-4.6,9.7-5.4
		c0.8,0.2,1.3,0.7,1.5,1.5C565.7,500.2,564.4,500.6,562.8,500.6z"/>
	<path class="st3870" d="M524.7,472.3c-2-1-3.2-3.6-6-3c-0.7-5.4,3.1-10.5,1-16c1.3,0,2.7,1,4,0c3.1,4.1,1.7,9.7,4.6,13.8
		C528.4,469.8,528.1,472.1,524.7,472.3z"/>
	<path class="st3871" d="M527.7,467.3c-4.2-3.8-2.9-9.3-4-14c3.4,0.8,2.5-2.8,4-4c1.5,0.4,2.8,1.1,3.7,2.3c2,3.5,1.4,7.6,2.2,11.4
		c0,2.1-0.4,4.2-1.5,6C529.9,470.5,529,468.3,527.7,467.3z"/>
	<path class="st3872" d="M530.7,451.3c-1.5,0-2.9,0-3-2c-2.4-5-2.4-10.7-4-16c1.7-2.4,3.6-1.2,5.5-0.3c2.4,3.7,3.4,7.9,4.5,12.1
		c0.2,1.3,0.2,2.6-0.2,3.9C532.9,450.2,532.5,451.6,530.7,451.3z"/>
	<path class="st3873" d="M528.7,433.3c-1.7,0.3-3.3-1.1-5,0c0-0.3,0-0.7,0-1c0-5,0-10,0-15c0,0,0,0,0,0c1.7-1,2.5,0.1,3.1,1.3
		c2,4,4,8,4,12.6C530.5,432.3,530.3,433.5,528.7,433.3z"/>
	<path class="st3874" d="M527.7,467.3c1.7-0.1,2.6,1.4,4,2c3.3,1.6,4.3,4.5,4.2,7.9c-0.2,1.6-0.7,3.1-2.2,4.1
		c-1.6-0.1-2.9,0.2-3.1,2.2c-0.2,1.8-1.6,1.7-2.9,1.8c-1-2.9,0.3-6.4-3.3-8.6c-1.7-1-0.4-3,0.3-4.4
		C526.7,471.2,527.4,469.4,527.7,467.3z"/>
	<path class="st3875" d="M564.7,497.3c-3.3,1.7-6.7,3.3-10,5c-1-1-2-2-3-3c-5.8,1.1-6.8,0.3-5.5-5.4c0.7-3.2-2.6-5.9-0.6-8.9
		c1.4-1,2.9-1,4.5-1c2.4,0,4.5,0.8,5.6,3.1c1.4,2.9,3.8,4.3,6.5,5.4c1.3,0.8,2,2.1,2.6,3.4C565.1,496.4,565,496.9,564.7,497.3z"/>
	<path class="st3876" d="M545.7,485.3c0.8,3.8,3.4,7,1,11.6c-1.2,2.2,3.3,1.4,5,2.4c-1.4,1.9-3.3,3.2-5.5,2.8
		c-2.4-0.5-2.7-2.9-2.4-4.8c0.5-3.4-2.8-3.9-4.3-5.5c-2.8-3.1-2.7-7.7-5.8-10.4c0.3-1.3,0.7-2.7,1-4c2.5-1.8,4.8-1.3,6.1,1.4
		C542.1,481.2,544.9,482.5,545.7,485.3z"/>
	<path class="st3655" d="M544.6,166.7c-2.3-3.4-4.6-6.7-4.9-11c1.4-0.1,2.9,0.5,4-1c-1.1-1.3-1.1-2.7,0-4c1.2-0.8,3.9-0.4,2.1-3.4
		c-1.9-3.2-0.1-4,2.8-3.7c1.2,0.1,2.8,0.3,2.8-1.1c0-4.3,3.5-4.5,6.2-5.7c0.6,1.2,1.3,2.4,0.6,3.8c-3.2,6.3-1.2,11.9,2.4,17.2
		c0,0,0,0,0,0c-0.6,0.5-1.7,0.7-1.1,1.9l0.1,0.1c-1.1,2.7-4,2.8-6.1,4c-1.6,0.9-3.5,1.1-4.9,2.2
		C547.3,166.2,546.3,168.1,544.6,166.7z"/>
	<path class="st3877" d="M548.4,165.4c3.4-2.7,8.1-2.9,11.4-5.7c0.4,0.3,0.7,0.6,1.1,0.8c-1.3,1.7-3.4,2.1-5.1,3.2
		c-1.9,0.7-3.6,2.2-5.9,2C549.2,166.2,548.8,166.1,548.4,165.4z"/>
	<path class="st3878" d="M563.7,159.8c0-0.4,0-0.7,0-1.1c0.9-0.4,1.9-0.7,2.8-1.1c0.7,0,1.5,0.1,2.2,0.1
		C567.3,159,565.4,159.2,563.7,159.8z"/>
	<path class="st3653" d="M559.6,159.6c-0.7-0.1-1.8-0.1-1.4-1.2c0.4-1,1.6-0.6,2.4-0.7C560.3,158.3,560,158.9,559.6,159.6z"/>
	<path class="st3661" d="M543.7,150.7c0,1.3,0,2.7,0,4c-1.4-0.1-2.8-0.5-2.6-2.2C541.2,151.1,542.5,150.7,543.7,150.7z"/>
	<path class="st3879" d="M548.4,165.4c0.5,0.1,1,0.2,1.5,0.2c-2.4,2.1-5.2,3.6-8.1,4.9c-0.4-0.3-1-0.4-1.1-1c1.4-1,2.7-1.9,4.1-2.9
		C545.9,166.3,547.1,165.9,548.4,165.4z"/>
	<path class="st3880" d="M540.6,169.6c0.4,0.3,0.7,0.7,1.1,1c-1.3,0.8-2.6,1.5-3.9,2.3c-0.9,0.8-1.4,2.4-3.1,2.1
		c-0.6-0.3-1.1-0.8-1.2-1.5C535.6,171.7,538,170.5,540.6,169.6z"/>
	<path class="st3881" d="M533.4,173.4c0.4,0.4,0.9,0.7,1.3,1.1c-1,2.1-2.4,3.6-5,2.9c-0.4-0.3-0.8-0.6-1.2-1
		c0.6-0.6,1.3-1.2,1.9-1.9c0.2-0.3,0.3-0.6,0.5-0.9C531.7,173.6,532.6,173.5,533.4,173.4z"/>
	<path class="st3839" d="M528.7,172.6c-1.8-1.7-0.1-2.2,1-3C530.9,171.1,529.3,171.7,528.7,172.6z"/>
	<path class="st3882" d="M479.7,215.6c0.1,0.7,0.1,1.5,0.2,2.2c0.2,0.4,0.3,0.9,0.1,1.3c-3.1,4.1-7,7.4-10.6,11
		c-0.8,0.5-1.7,1-2.7,0.4c-0.4-0.7-0.4-1.4,0.2-2C470.6,223.8,474.2,218.8,479.7,215.6z"/>
	<path class="st3883" d="M466.8,228.6c0,0.8,0.1,1.6,0.1,2.3c0,2.1-0.4,3.8-3.1,3.7c-0.3-0.3-0.7-0.7-1-1
		C463.3,231.3,463.8,228.9,466.8,228.6z"/>
	<path class="st3884" d="M504.8,194.7c1.3-0.7,2.5-1.4,3.8-2.2c1.2-0.5,1.4,0.3,1.2,1.2c-0.3,1.5-1.5,2.5-2.5,3.5
		c-2.1,1.6-3.5,4.5-6.8,4c-0.7-0.4-1.3-0.8-0.9-1.7C501.4,197.9,503.1,196.3,504.8,194.7z"/>
	<path class="st3885" d="M499.7,199.5c0.4,0.4,0.8,0.8,1.2,1.2c0.3,2.7-2.1,3.3-3.5,4.6c-1.5,0.8-2.3,3-4.6,2.2
		c0-0.7-0.1-1.5-0.1-2.2C495,203.3,497.3,201.4,499.7,199.5z"/>
	<path class="st3886" d="M506.7,196.6c0.1-1.6,3.1-1.9,1.9-4.1c0.1-0.3,0.1-0.5,0.2-0.8c1.7-1,3.3-2,5-3c1.4,0.5,1.8,1.6,1.6,3
		c-1.8,2.7-3.8,5.2-7.4,5.6C507.5,197.3,507.1,197,506.7,196.6z"/>
	<path class="st3887" d="M479.8,218.6c0-0.3,0-0.6,0-0.8c1.3-0.7,2.6-1.5,3.9-2.2c0.9-0.4,1.3,0.2,1.5,0.9c-0.3,3.3-3.3,4.7-5.1,6.9
		c-1.3,1.5-2.7,2.8-3.7,4.5c-1.4,1.6-2.6,3.5-4.9,4c-2.3-1.4-0.9-2.7,0.1-4C474,224.5,477.4,222,479.8,218.6z"/>
	<path class="st3888" d="M484.9,216.8c-0.4-0.4-0.8-0.8-1.2-1.2c0.9-2.4,3.7-2.9,5-4.9c1.3-0.7,2.5-1.4,3.8-2.2
		c0.4-0.3,0.9-0.3,1.3-0.1c0.6,3-2,4-3.6,5.7c-1.4,0.9-2.6,1.9-4,2.8C485.8,217,485.3,217,484.9,216.8z"/>
	<path class="st3889" d="M494,208.7c-0.5-0.1-1-0.2-1.5-0.2c0.1-0.3,0.2-0.6,0.3-0.9c1.3-1,2.7-2,4-3c1-0.5,1.2,0.1,1.3,0.9
		C497.3,207.3,496.2,208.7,494,208.7z"/>
	<path class="st3669" d="M649.8,264.5c-5.3,3.9-1.9-3.9-4.7-3.5c2.7-3.7-1.8-4.4-2.7-6.5c-0.9-2-3.2-3.8-0.6-6.2
		C645.1,253.3,647.8,258.7,649.8,264.5z"/>
	<path class="st3890" d="M578.7,183.6c4.8,0.4,7.7,4.2,11.2,6.8c1.9,1.4,3.8,2.8,5.8,4.2c-4.3,3.1-4,7.6-4,12.1
		c0,1.5,0.4,2.6,2.1,2.9c1.4,0.2,1.9,1.1,1.9,2.5c0.1,2.5,0.7,4.2,2.9,6.4c2.1,2.1,3.1,6.5,4.9,9.8c2.4,4.4,0.5,10.5,5.7,14.4
		c1.8,1.4,0.3,5.5,2.2,8.2c4.9,6.8,8.8,14.2,12.8,21.6c1.2,2.3,4.5,4.2,7.2,4.7c5,1,5.3,2.6,2.1,6c-0.2,0.2,0.1,0.8,0.2,1.2
		c-3,2.3-6,4.7-9,7c-4.8,0.3-6.1-4.3-9.2-6.4c-0.6-0.4-0.6-1.7,0-2.3c3.7-3.3,0.7-3.6-1.7-4.9c-3.4-1.9-4.9-5.7-7.2-8.7
		c-7-5.5-9.5-12.7-8.3-21.4c0.2-1.5-0.4-2.9-1.4-4.2c-0.5-0.7-1.4-1.3-1.4-2c-0.7-11.3-10.4-18.3-14-28.4c-2-5.5-3-10.6-3.1-16.1
		c0-3.3,0.3-6.6-0.7-9.9C577.2,185.8,577.4,184.6,578.7,183.6z"/>
	<path class="st3890" d="M602,216.3c1,0.7,1.8,1.7,1.1,2.4c-1,1-1.2-0.2-1.4-1C601.7,217.3,601.9,216.9,602,216.3z"/>
	<path class="st3891" d="M593.7,325.4c0.3,0.3,0.6,0.7,1,1c-7.1,10.8-14.9,21.1-20.4,32.8c-0.6,1.3-1.1,2.7-1.6,4.1
		c0.2,1.7-0.7,3.2-1.2,4.8c-0.9,1.3-1.4,3.4-3.8,2.5c-1.3-1.7-0.3-3.2,0.1-4.8c0.5-1.3,1.3-2.5,2-3.6c0.5-0.7,0.9-1.5,1.3-2.3
		c1-3.5,3.3-6.3,4.8-9.5c0.4-0.8,0.8-1.6,1.3-2.4c2.1-5.2,6-9.2,8.7-14.1C588.6,331.2,589.5,326.9,593.7,325.4z"/>
	<path class="st3892" d="M570.8,367.5c0-1.6,0.8-2.9,1.8-4.1c0.7,0.3,1.4,0.7,2,1c-0.2,1-0.5,2-0.7,3c-0.7,0.7-1.4,1.5-2.1,2.2
		C570.8,369.2,571.2,368.2,570.8,367.5z"/>
	<path class="st3893" d="M615.6,298.5c-1.3,4-4.5,6.2-8,8c-3,2.1-7.1,1.6-9.3-1.5c-1.6-2.3-3.5-4.4-5.5-6.3
		c-1.2-1.2-1.9-2.2-1.3-3.8c0.5-1.3,0.4-2.8-0.7-3.7c-4.6-3.8-5.9-9.4-8-14.5c-0.5-3.6-1-7.3,0.4-10.9c0.9-1.2,2.3-1.4,3.6-1.5
		c3.8-0.4,6.8,1,8.8,4.3c2.2,3.6,7.1,5.5,7.2,10.3c0.1,3.6,1.3,6.8,2,10.1c0.4,2.2,1.2,3.9,3.3,4.5
		C611.3,294.2,611.9,298.7,615.6,298.5z"/>
	<path class="st3894" d="M615.6,298.5c-1.9,0.8-4.4,3.2-4.6-1.5c0-0.4-0.5-1.1-0.8-1.1c-7.3-0.5-6.1-7.3-8.5-11.4
		c-1.4-2.4,1.6-5.7-1-7.8c-4.5-3.6-5.7-11-13.1-11.2c-2.3-3.6-1.7-7.5-0.8-11.3c2-2.4,4.5-2.2,5.4,0.4c2.7,7.6,8.2,12,15.3,14.9
		c1.7,1.3,3.1,2.8,4,4.9c0.8,2,2.3,3.2,4.6,3.1c1.3,0,2.8-0.3,3.2,1.5c0.3,1.4-0.2,2.6-1.7,3.2c-1.1,0.4-2.6,0.9-1.8,2.3
		c1.3,2.2,2.4-0.4,3.8-0.6c0.4,1.9-4,6.7,2.8,5c1.1-0.3,1.5,1.6,2,2.5C622.3,294.7,619,296.6,615.6,298.5z"/>
	<path class="st3895" d="M593.7,325.4c-2.3,3-4.7,6-7,9.1c-0.4,0.3-0.8,0.5-1.3,0.4c-0.8-4.6-4.7-6.3-7.8-9c-3.8-3.3-7.2-7.3-6-13.2
		c0.5-2.4-1-4.1-2.3-5.8c-1.5-1.8-2.7-3.7-2.8-6.2c0.3-2.9-1-6.2,2.6-8c3.2-0.7,5.5,0.1,6,3.7c0.5,3.4,3.4,5,5.1,7.5
		c1.9,1,3.3,2.7,4.8,4.1c2,1.8,3.5,3.5,2.8,6.7c-0.8,3.5,1.2,3.7,3.8,2.2c2.1,0.9,4.1,0.1,6.2-0.1c0.4,0.1,0.8,0.3,1.1,0.7
		C598.2,320.9,594.3,322.2,593.7,325.4z"/>
	<path class="st3896" d="M597.6,317.5c-2,0.7-4,3.4-6,0c0.9-4.6,1-8.9-3.9-11.8c-1.7-1-1.7-3.3-1.3-5c1.5-6.7-2.8-10.5-7.2-13.9
		c-2.4-1.9-4.3-3-1.5-6c2-2.1-1.4-5.2,0.8-7.5c4.4-1.7,3.4,2.8,5,4.3c0.8,0.1,1.8,0.4,1.8,1.4c0.1,4.9,2.9,8.3,6.3,11.3
		c0.6,0.5,1.6,1.4,1.5,1.9c-1.3,5.3,3.9,6.9,5.3,10.2c1.6,3.7,4,3.5,6.2,4.7c1,0.6,1.9-0.2,2.8-0.6c-0.6,4.2-3.4,6.4-7,8
		C598.6,314.4,598.8,316.6,597.6,317.5z"/>
	<path class="st3043" d="M597.6,317.5c0.3-1.7-1-5,3-3c-0.7,1-1.3,2-2,3C598.3,317.5,598,317.5,597.6,317.5z"/>
	<path class="st3897" d="M607.6,269.5c-7.5-0.5-14.4-6-15.8-13.2c-0.6-2.9-2.1-2.9-4.1-2.8c-0.6-0.5-0.9-1.1-1.1-1.9
		c0.1-2.8-1.1-4.8-3.4-6.2c-0.8-2.9-1.5-5.7-4.5-7.3c-1.8-1-2.1-3.2-2.3-5.2c-0.7-5.6,0-11.5-5.1-15.9c-1.6-1.4-1.1-4.4-1.7-6.6
		c-1.7-5.6-4.5-10.7-6.2-16.2c-1.1-3.7-1.6-7.1,2.1-10.5c-3.9-1-5.4-3.9-6.7-7c-0.6-1.5-0.3-2.9,1.1-4c6.8,2.8,13.3,6.1,19,11
		c1.5,7.6,0.9,15.3,1,23c0,0.6-0.1,1.5,0.3,1.9c4.2,4.6,6.1,10.2,7.9,16c0.3,0.8,1.4,1.3,2.1,2c3.3,3.3,6.7,6.5,5.7,11.9
		c-0.1,0.6-0.2,1.7,0.2,1.9c5.7,3.9,4,9.9,3.6,15c-0.4,5.2,2.8,7.8,6,10C607.4,266.6,607.6,267.8,607.6,269.5z"/>
	<path class="st3898" d="M587.7,251.6c0,0.7,0,1.3,0,2c0,4,0,8,0,12c-1.3,0-2.7,0-4,0c-3.1,1.7-4.3-0.2-5-2.7c-0.6-1.9-0.4-4-0.4-6
		c0-2.9-0.9-5.2-4-6.1c-1.5-0.4-3.3-1.3-3.3-2.8c0-6-4.8-10.4-5.1-16.4c-0.2-4.9-2.4-9.2-5.3-13.2c-3.7-5.1-3.5-11.6-4.8-17.5
		c-1.5-6.6-1.8-13.5-3.7-20.1c-0.6-2.3-2-5.3,1.9-6.7c1.2-0.4,0.1-1.8,0.5-2.7c0,0,0,0,0,0c2,0.3,3.5-1.2,5.2-1.8c0,1,0,2,0,3
		c0,4.3,0.4,8.4,5.9,9c1.1,0.1,1.9,0.6,1.9,1.8c0,0.9-0.2,1.7-1.2,1.9c-4.1,0.8-3,4.3-2.4,6.4c1.9,6.1,4.5,12,7,17.9
		c0.5,1.1,0.8,2.1,0.8,3.3c0,2.2,0.3,3.5,2.7,5c3.7,2.4,2.6,7.4,3.2,11.2c0.6,4.1-0.3,8.1,5.1,9.9c2.2,0.7,0.6,4.2,1,6.4
		C585.2,247.4,586.8,249.2,587.7,251.6z"/>
	<path class="st3899" d="M529.7,177.5c1.7-1,3.3-2,5-2.9c1-0.6,2-1.1,3.1-1.7c-0.2,1.1,2.3,2.7-0.8,3.3c-0.7,0.1-2.3,0.9-1.4,2.5
		c-0.7,4.1-4.8,5-7.2,7.5c-0.5,0.5-1.1,0.8-1.8,1.1c-0.7,0.3-1.4,0.4-2.1,0.4c-1,0-1.9-0.1-2.8-0.5c-1.9-1.4-1.4-2.8,0-4.2
		C524.4,181.1,527,179.3,529.7,177.5z"/>
	<path class="st3900" d="M527.6,185.6c2.7-2.3,5.3-4.7,8-7c0.7-0.3,1.5-0.5,2.2-0.8c1.1-0.1,1.8,0.3,2.2,1.4
		c-0.1,0.5-0.4,0.9-0.8,1.2c-2.4,1.1-4.1,3.2-6.3,4.5C531.2,185.9,529.6,187.3,527.6,185.6z"/>
	<path class="st3901" d="M540,179.9c-0.7-0.7-1.4-1.4-2.2-2c0.9-1.6,2.7-1.3,4-2.1c0.8,1.1,2,0.8,3.1,1
		C544.2,179.3,542.6,180.4,540,179.9z"/>
	<path class="st3677" d="M554.5,171.5c0.7,1.2,4.5,2.5,1.3,3.4c-4.7,1.3-2.9,4.9-2.3,6.2c2.8,6.8,2.5,14.2,3.9,21.2
		c0.8,4.1,3.2,8,2.4,12.5c-0.1,0.6,0.5,1.7,1,2.1c3.4,2.1,5.1,5.8,5.3,9.1c0.4,7.5,5.8,13.4,5.7,20.9c0,1.8,1.4,2.4,3.1,2.6
		c3.7,0.4,5,2.7,4.8,6.2c-0.1,2,0,4,0,6c-0.1,2.8,1.3,3.9,4,3.8c0,4,0,8,0,12c-2.8,0.1-1.4-5-5-4c-0.9,0.8-1.9,0.9-2.9,0.4
		c-2.2-3.3-5.3-5.7-4.5-10.8c0.5-3.5-4.1-5.7-6.4-8.4c-1.2-1.3-2.4-2.5-1.8-4.3c1.7-4.9-1.2-8.3-4-11.5c-1.4-1.6-1.7-3.1-2.1-5.2
		c-0.9-4.5-2.6-8.9-4.3-13.3c-3.6-9.6-5.7-19.6-7.3-29.8c-0.5-2.9,2.2-4.8,2.3-7.5c1.2-2.7-2.9-3.8-1.9-6.5
		C548.1,174.2,551.4,173,554.5,171.5z"/>
	<path class="st3902" d="M545.8,176.9c-0.2,2.5,5.3,2.1,2.9,5.8c-3.2,0.9-4.3,3.1-3.9,6.3c1.1,10.1,2.5,20.2,3.8,30.3
		c1,7.1,4.3,12.7,7.5,18.7c1.8,3.4,1.4,7.4,0.2,11.1c-2.1,0.6-4,1.1-3.5,4.2c0.3,1.9-0.1,4.8-3.6,3.8c-1.2-2-2.7-4.2-2.5-6.4
		c0.9-8.2-4.4-14.1-6.8-21.1c-2.2-6.4-3.5-13.3-3.6-20.3c-0.1-5.2,1.2-10.6-1.3-15.6c-0.2-0.4-0.4-0.9-0.5-1.3
		c0.1-1.1-0.3-2.1-1-2.9c-0.2-1.3,2.1-2.8-0.2-4c1.2-3.1,4-4.4,6.6-5.8c0,0,0.1,0.1,0.1,0.1c1.7-1,3.3-2.1,5-3.1
		C545.2,176.8,545.5,176.8,545.8,176.9z"/>
	<path class="st3903" d="M634.6,508.3c1.3,0,2.7,0,4,0c0.7-0.5,1.4-0.5,2.1-0.1c0.2,0.5,0.2,1,0,1.4c-4.3,2.5-8.8,4.5-13.2,6.7
		c-3.6,2-7.5,2.7-11.5,3.3c-6.5,0.6-13.1,0.6-19.6,1.1c-2.9,0-5.9,0.4-8.8-0.2c-8-0.4-16,0.3-23.9-1.3c0-0.3,0-0.6,0-0.9
		c0.2-0.2,0.5-0.4,0.7-0.6c5.8-1.5,11.8-0.7,17.7-0.9c6.4-0.2,12.9,0.1,19.3-0.1c5.6-0.4,11.2-1.6,16.6-2.9c0.5-0.1,1.1-0.2,1.4-0.5
		C623.3,508,629.4,509.6,634.6,508.3z"/>
	<path class="st3904" d="M587.6,520.4c3-0.1,6-0.2,9-0.2c1.2-0.2,2.8-1.4,3.1,1.1c0,0,0,0,0,0c-4,0-8,0-12,0
		C587.6,521,587.6,520.7,587.6,520.4z"/>
	<path class="st3905" d="M556.7,508.3c3.4-1.3,7-1.8,10-4c3.5-3.9,8.2-5.8,12.9-7.5c3.9,4,3.6,7.5-0.7,10.4c-1.4,1-4.2,1.2-2.5,4.2
		c0.2,1.3-0.3,2.4-1.3,3.2c-2.9,3.9-7.4,2.9-11.4,3.7c0,0,0,0,0,0c-4.5-1.9-9.6-0.7-14-3C550.9,511.8,555.1,511.3,556.7,508.3z"/>
	<path class="st3906" d="M556.7,508.3c-0.4,4.3-4.4,4.9-7,7c-2.6-0.1-6.2,0.2-6.4-2.7c-0.1-2.1,3.6-2,5.8-2.4
		C551.6,509.7,554.1,508.9,556.7,508.3z"/>
	<path class="st3907" d="M562.8,500.6c1.1-0.7,2.2-1.5,3.2-2.2c1.8-4.1,6.1-4.2,9.5-5.6c1.3,0.3,2.4,0.9,2.4,2.4
		c-3.2,4.7-8.2,6.6-13.4,7.9l-0.5,0c0,0-0.5,0-0.5,0C563.3,502.4,563,501.5,562.8,500.6z"/>
	<path class="st3908" d="M564.6,503.2c4.4-2.6,9.3-4.4,13-8.1c0.6-0.5,0.4-1.5,1.2-1.9c0.7-0.2,1.1,0.2,1.5,0.6
		c0.5,0.7,0.8,1.4,0.7,2.3c-0.2,0.6-0.9,0.8-1.3,1.1c-4.3,2.3-8.6,4.7-12.9,7C566.2,503.4,565.4,503.3,564.6,503.2z"/>
	<path class="st3909" d="M619.6,709c-0.8-0.4-1-1.2-1-2c-0.3-3.6-3.5-4.8-5.7-6.8c-2.2-2-6.1-2.5-6.1-6.6c5-2,8.3,2.1,12.1,3.8
		c4.3,2,8.2,4.8,12.6,6.6c4,1.6,7.4,3.8,10.3,7.2c3.1,3.5,8,5.4,12.6,6.5c5.3,1.3,8.1,3.9,8.7,9.1c0.1,0.5,0.4,0.9,0.7,1.3
		c0.4,0.6,0.6,1.3,0.6,2c-0.3,0.3-0.7,0.6-1,0.9c-2.6-2.4-6.3,0-9-2c0.5-1.7,1-3.4,1.5-5.1c-3.4-0.3-5.2-3.4-8.2-4.6
		c-5.9-2.4-12.6-3.6-16.7-9.3c-1.3-1.7-3.4-0.8-5.1-0.9C623.9,708.9,621.8,709,619.6,709z"/>
	<path class="st3910" d="M664.6,730.1c-0.3-0.4-0.7-0.7-1-1.1c-1.7-2.9-1.1-6.2-1.2-9.2c-0.2-4-2-6.1-5.5-7.2
		c-7.2-2.4-14.5-4.7-21.2-8.5c-8.1-3.6-16.5-6.4-24.2-10.8c-1.4-0.8-3.2-1-2.9-3.3c3.5-2.2,6.3,0.3,9.2,1.6c6.9,3,13.9,5.8,21,8.5
		c7.5,2,14.6,5.1,21.6,8.4c1.3,0.6,2.8,1,3.3,2.7c3.5,4.1,1.7,9.1,1.9,13.7c0.1,3,0.7,4.8,4.1,4.3
		C668.4,731.6,666.5,731.1,664.6,730.1z"/>
	<path class="st3911" d="M463.8,234.6c1.7-0.7,1.8-2.6,3.1-3.7c0.6-0.4,1.3-0.9,1.9-1.3c2.6,1.4,0.6,2.9-0.2,4
		c-1.3,1.8-2.9,3.5-4.5,5.1c-0.5,0.2-0.9,0.2-1.3-0.1c-0.3-0.3-0.6-0.7-1-1C462.5,236.6,463.2,235.6,463.8,234.6z"/>
	<path class="st3912" d="M462.8,238.6c0.3-0.1,0.6-0.1,0.9-0.1c0.7,0.2,1.2,0.6,1.4,1.3c-0.2,2.2-1.7,3.5-3.4,4.7c0,0,0,0,0,0
		c-0.6,0.4-1.1,0.8-1.7,1.2c-0.4-0.4-0.9-0.8-1.3-1.2C460.1,242.5,461.5,240.6,462.8,238.6z"/>
	<path class="st3913" d="M483.8,233.6c0,0.3,0,0.7,0,1c-0.9,1.3-2.5-1.3-3.5,0.8c-2.2,4.3-4.9,0.8-7.4,0.2c-1.6-2.1-0.2-3.4,1.2-4.6
		c2.5-2.3,4.4-4.9,6.7-7.4c0.3-0.3,0.7-0.6,1.1-0.9c1.8-1.2,3.5-1.1,5.3,0c1.4,1.5,3.9,0.6,5.2,2.4c-0.4,3.5-1.8,6.3-5.3,7.6
		c-0.7,0-1.4,0-2.2-0.1C483.7,232.2,484.4,233.5,483.8,233.6z"/>
	<path class="st3914" d="M481.8,223.6c-2.5,2.9-4.8,5.9-7.4,8.6c-1,1.1-1.6,2-1.6,3.4c-2.4,0.2-4,1.8-4.7,3.8
		c-1.1,3.2-3.5,4.4-6.4,5.2c1.1-1.6,2.1-3.2,3.2-4.8c0.7-4,3.7-6.1,6.6-8.2c1.3-1.3,2.6-2.6,3.9-3.9
		C477.7,226.4,478.1,222.3,481.8,223.6z"/>
	<path class="st3915" d="M476.8,243.6c1.8,3-1.1,4.7-2,7C474.7,248,475.1,245.6,476.8,243.6z"/>
	<path class="st3915" d="M474.8,250.6c0.1,2.2-0.1,4.4-2,6C472.7,254.3,472.8,252.1,474.8,250.6z"/>
	<path class="st3916" d="M469.9,289.5c-1-19.6,5.5-37.1,15.7-53.4c1.3,0.6,1.7,1.9,2,3.2c0.2,3.2-1.8,5.6-2.9,8.4
		c-1.7,2.8-2.8,6-4,9c-0.8,1.7-1.4,3.5-1.7,5.3c-0.1,0.7-0.3,1.4-0.5,2.2c-1.9,4.5-1.6,9.5-3.5,14c-1.3,2.8-2.8,5.4-3,8.6
		C471.9,288,471.7,289.5,469.9,289.5z"/>
	<path class="st3917" d="M469.9,289.5c1.3-4,1.3-8.4,4-12c2.5,4.2,0.7,8.8,1.3,13.2c-0.1,5.5,1,10.8,2.3,16c0.4,1.7,1.2,3.4,0.8,5.3
		c-1.3,2.2-3.1,2-5.1,0.9c-1.5-2.3-2.7-4.7-1.6-7.5c-0.3-2.9-0.7-5.8-1-8.7c-0.9-2,0.2-4.3-0.7-6.3
		C469.9,290.2,469.9,289.8,469.9,289.5z"/>
	<path class="st3918" d="M486.9,239.8c-0.4-1.2-0.9-2.5-1.3-3.7c0.4-1.2,0.9-2.3,1.3-3.5c1.7-2.7,3.3-5.3,5-8c0.3-1,0.9-1.5,2-1.1
		c0.8,0.9,1.1,2,1.3,3.2c0,0.5,0,0.9-0.2,1.4C492.4,232,491.7,237.3,486.9,239.8z"/>
	<path class="st3919" d="M471.7,305.5c0.6,2.3,1.3,4.7,1.9,7c0.8,0.5,0.7,1.1,0.3,1.8c-3.2-1.1-3-4.4-4.1-6.8c0-0.7,0-1.3,0-2
		C470.4,305.5,471.1,305.5,471.7,305.5z"/>
	<path class="st3697" d="M483.8,233.6c0-0.5,0-0.9,0-1.4c0.1-0.8-0.2-2.4,1.1-1.9c1.3,0.4,0,1.5-0.1,2.3
		C484.4,232.9,484.1,233.2,483.8,233.6z"/>
	<path class="st3916" d="M469.9,290.5c1.6,1.9,0.7,4.2,0.7,6.3C469.5,294.8,469.8,292.7,469.9,290.5z"/>
	<path class="st3920" d="M477.8,333.4c-1.6-4.8-6.7-5.7-9.8-8.8c-0.9-0.9-2.1-1.4-3.2-2.1c0.4,0,0.7,0,1.1,0c5.1,0.4,8.2,4.3,11.9,7
		c3.9,2.8,6.7,6.7,10.1,10.1c0.3,0.4,0.5,0.8,0.6,1.3c0.2,1,0.1,1.9-0.5,2.7c-4.1-0.2-4.7-4.6-7.5-6.3
		C479.4,336.1,478.1,335.1,477.8,333.4z"/>
	<path class="st3707" d="M479.8,329.5c0.2-0.3,0.5-0.6,0.7-0.8c0,0,0.3-0.2,0.3-0.2c1.7-0.6,2.5,0.7,3.5,1.5
		c1.2,1.4,2.1,2.9,3.3,4.3c1.7,2.4,3.6,4.6,5.1,7c0.9,1.5,2.2,2.9,1.3,4.9c-0.9,0.8-1.7,0.4-2.6,0c-2.6-1-2.3-3.8-3.6-5.7l0,0
		c-2.1-2.8-4.5-5.3-6.9-7.7C479.9,331.8,478.7,331,479.8,329.5z"/>
	<path class="st3921" d="M479.8,329.5c0.8,3.2,3.8,4.6,5.5,7c0.9,1.3,2.5,2.1,2.4,4c-4-3.1-7.3-6.7-10-11
		C478.4,329.5,479.1,329.5,479.8,329.5z"/>
	<path class="st3922" d="M473.9,314.3c-0.1-0.6-0.2-1.2-0.3-1.8c1.3,0.8,2.7,0.1,4,0c3.4,1.4,2.7,4.8,3.8,7.4c0.4,1.6,1.1,3,2.1,4.3
		c0.7,1,1.2,2.1,0.5,3.3c-2,0.7-2.5-0.8-3.2-2.1c-1.4-1.5-2-3.8-3.9-4.9C475.9,318.4,474.9,316.3,473.9,314.3z"/>
	<path class="st3923" d="M480.8,325.4c1,0.7,2,1.4,3,2.1c1.6-0.1,1.6,1,1.6,2.1c-0.3,0.8-0.8,1.4-1.8,1.1c-0.9-0.7-1.9-1.4-2.8-2.2
		c-0.3-0.7-0.6-1.4-1-2C480.2,326.1,480.5,325.7,480.8,325.4z"/>
	<path class="st3924" d="M606.6,694c4,3,7.9,6.2,12,8.9c2.5,1.7,1.3,2.7,0,4.1c-4.1-1.6-8.3-2.9-10.9-7c-0.7-1.1-2.3-2.7-4.1-1
		c-2.7,0-4.8-0.6-3-4c-0.8-1.9-2.3-3-4.2-3.8c-1-0.4-2.7-0.8-1.6-2.7c1.1-0.7,2.3-0.8,3.6-0.7C602.3,688.4,604.9,690.7,606.6,694z"
		/>
	<path class="st3925" d="M555.7,716c-7,0.2-12.6-5.4-19.7-5c-1.4,0.1-1.5-0.4-1.2-1.8c0.4-1.8-1.5-1.3-1.8-1c-3.9,5.3-8.8,1.4-13,1
		c-2.6-0.2-6.5,1.8-8.3-2.3l0,0c2.9-1.1,5.9-0.2,8.9-0.3c2.3-0.1,4.6-0.1,6.8-1.1c2.7-1.2,5.5-1.2,8.3-0.2c4.7,1.8,9.5,1.5,14.3,0.9
		c3.3-0.4,6.6-0.8,9.9-0.7c4,0.1,7.6-0.9,10.5-3.6c1.9-1.8,4.1-2.7,6.8-2.6c1.7,0,3.4-0.2,4.1-2.1c0.6-1.7-0.7-2.8-1.8-3.8
		c-2.8-2.6-2.9-4.7,1.1-6.2c2.1,0,3.6,1.3,5.1,2.5c1.9,1.4,3.4,3.4,6.3,3.1c1.1-0.1,1.5,1,0.7,2c2.5,4.5,0.8,8.8-1.4,12.6
		c-1.3,2.4-4.7,2.8-7.5,1.9c-2.3-0.8-3.8-0.6-4.5,2c-0.1,0.5-1.2,1.2-1.4,1.1c-2.9-1.8-4.7,1.2-6.9,1.3
		C565.8,714.4,561.2,718.9,555.7,716z"/>
	<path class="st3926" d="M592.7,695c-4.3-1.3-7.9-3.8-11-7c-1.4-1.9-3.4-3.2-4.4-5.4c0-1.4-0.9-2.3-2.1-2.9
		c-0.4-0.3-0.6-0.7-0.8-1.1c0.2-1.4,1.1-2.1,2.5-2.2c5.8,2.3,10.1,7.2,16,9.3c1.2,0.4,1.9,1.8,1.8,3.3c1.2,1.3,2.9,1.5,4.4,1.9
		c2.4,0.7,2.7,2.1,1.6,4.1C598,695,595.3,695,592.7,695z"/>
	<path class="st3927" d="M443.8,610.1c-12.4,11.8-12.3,26.3-9,41.6c0.6,2.8,0.8,5.7,2,8.3c2.8,8.6,8.5,15.6,12.8,23.4
		c0.4,0.8,0.7,1.5-0.2,2.1c-1.3,0.6-2.5,0.4-3.6-0.5c-3.1-3.1-4.4-7.5-8-10.1c-0.8-0.5-1.9-1.7-1.7-2.2c1.7-4.8-1.8-7.3-4.3-10.2
		c-1-1.1-1.2-2.4-0.7-3.7c0.9-2.3,0-4.3-1.2-6c-2.9-4.1-1.6-8.3-1.2-12.8c0.6-7.2,2.1-14.3,3.2-21.4c0.2-1.3-0.2-3,1.5-3.2
		c2.8-0.4,3.5-1.9,3.6-4.4c0-1.9,1.4-2.9,2.9-3.7c3.6-3.5,6.6-7.8,12-9c1.3,3.8-3.7,4.1-4,7c-0.3,0.3-0.7,0.7-1,1
		C445.8,607.5,444.6,608.6,443.8,610.1z"/>
	<path class="st3928" d="M445.8,685.1c1.3,0,2.7,0,4,0c1.3-1.2,3-1.3,4-0.1c3.3,4,8.3,4.7,12.5,7c1.2,0.6,2.5,1.2,3.8,1.2
		c7.4-0.2,14.1,3.8,21.4,3.8c5,0.1,9.9,1.2,15-0.7c3.9-1.5,5.7,0.1,5.9,4.2c-0.2,0.2-0.4,0.4-0.5,0.6c-3.6,0-7.2-0.6-9.6,3.3
		c-0.7,1.1-3.2,2.4-4.8,0.6c-2.7-3-6.9-1.4-9.9-3.7c-0.9-0.7-3.1,0.4-4.7,0.7c-1.1,0-2.1-0.3-3-1c-5.3,0-10.7,0-16,0
		c-2.4-1.8-4.1-4.5-7.9-3.8c-1.6,0.3-2.3-1.7-2-3.3c0.5-3.1-1.2-4.6-3.8-4.2C445.7,690.5,445.5,688.1,445.8,685.1z"/>
	<path class="st3929" d="M574.7,679.1c0.3,0,0.7,0,1,0c0.8,0.9,1.9,1.6,2,3c1,2.2,4.8,2.5,4,6c-4.4,0.9-4,2.9-0.8,4.8
		c2.2,1.3,3.1,2.7,2.3,5.2c-0.9,2.5-2.7,3.5-5.1,2.9c-3.2-0.8-5.3,0.2-7.3,2.7c-1.7,2.1-4.4,3.1-7,3.3c-7.9,0.6-15.8,0.9-23.8,1.1
		c-3.6,0.1-7-3.6-10.4-1.7c-4.3,2.4-8.6,1.4-13,1.8c-1.7,0.1-3.3-0.1-4.8-1c-0.8-2,0-4,0-6l0,0c1.1-2.2,2.7-3.1,5.2-2.8
		c8.6,1.2,17.1,1.2,25.6-0.4c1.7-0.3,3.4-0.4,4.5-1.8c2.4-3.1,5.8-3.6,9.3-4.2c6.4-1.2,12.4-2.8,13.8-10.6c0.3-1.5,1.2-2.8,3-2.8
		C573.8,678.5,574.3,678.7,574.7,679.1z"/>
	<path class="st3930" d="M479.8,701c1.1,0,2.3-0.2,3,1C481.1,703.8,480.5,702.1,479.8,701z"/>
	<path class="st3781" d="M625.6,430.4c-0.7,0-1.3,0-2,0c-0.3-0.7-0.7-1.3-1-2c7.3-5.9,13.7-12.5,17.6-21.3c0.5-1.1,1.1-2.1,2.5-2.2
		c0.5,0.1,0.9,0.4,1.3,0.8c1.2,3.4-2.2,5.6-2.4,8.6l0,0c-1,2.6-3.5,3.9-5,6c-0.9,0.4-1.6,1.1-2,2c-2.5,2.8-4.5,6.1-8,8
		C626.3,430.3,626,430.4,625.6,430.4z"/>
	<path class="st3723" d="M641.6,414.4c-0.8-3.3,2.8-5.7,2-9c0-0.2,0-0.4,0-0.6c0.4-1.2,0.8-2.4,1-3.7c0.2-0.7,0.4-1.2,1-1.6
		c0.7-0.7,1.3-1.3,2-2c-0.3,3-0.7,6-1,9c-1.3,0.6-2,1.6-2,3C643.6,411,642.6,412.7,641.6,414.4z"/>
	<path class="st3723" d="M626.6,430.3c2.3-3.1,4.9-5.7,8-8C632.7,425.8,631.6,430.1,626.6,430.3z"/>
	<path class="st3723" d="M636.6,420.4c-0.1-3.4,2.9-4.4,5-6C641.6,417.8,639.7,419.6,636.6,420.4z"/>
	<path class="st3931" d="M597.7,369.4c0.7-0.3,1.3-0.7,2-1c0.7-1.3,1.7-2.3,3-3c0.3-0.3,0.7-0.7,1-1c0.4-1.6,1.8-2.2,3-3
		c7.7-3.5,15.4-6,24.2-4.1c7.1,1.5,10.7,6.3,13.8,12.1c0,0.2-0.1,0.5-0.1,0.7c-0.4,1.1-1,2.1-2.2,2.6c-0.5,0-0.9-0.2-1.1-0.6
		c-0.5-1.3-0.4-3-2.3-3.2c-9.3-12.2-27.1-9.6-36.2,1.4c-1.4,1.7-2.8,3.4-4.2,5.2c-0.4,0.5-1,0.9-1.6,1.2c-0.5,0.1-0.9,0-1.2-0.3
		C595.3,373.8,598.6,372.2,597.7,369.4z"/>
	<path class="st3932" d="M607.7,429.3c-3.4,0.4-6.5-0.1-8.7-3.1c-1.4-1.8-3.2-2.2-5.2-0.9c-7-9.9-8-20.6-4-32
		c2.3-1.1,2.8,0.7,3.1,2.2c0.4,1.6,0.2,3.2,0.3,4.8c-0.1,8.5,1,16.6,7.5,22.9c1.1,0.9,2.2,1.7,3.5,2.5
		C605.1,427,607.6,426.9,607.7,429.3z"/>
	<path class="st3933" d="M591.7,401.5c-1-2.6,2-6.3-2.1-8.1c0-6.4,3.4-11.5,6-17c0.3,0,0.7,0,1,0c1.1,1.4,0.5,2.9-0.2,4.1
		c-1.9,3.6-2.1,7.4-1.5,11.4C594.1,395.2,595.4,399.2,591.7,401.5z"/>
	<path class="st3934" d="M642.6,405.4c-3.6,7.3-7.8,14.2-14.1,19.6c-1.8,1.5-3.4,3.1-5.9,3.3c-2.3,1-4.6,1.7-7.1,0.8
		c0.8-2.8,3.7-2.2,5.4-3.5c3.3-1.6,5.5-4.3,8-6.8c2-1.8,3.7-3.7,4.6-6.3c1.1-2,2.9-3.6,3.5-6c3.9-8.8,6.3-17.8,4.1-27.5
		c-0.3-1.3-0.6-2.6,0.4-3.8c3.5-0.7,3.2,2.3,3.4,4.1C646.1,388.1,646,396.9,642.6,405.4z"/>
	<path class="st3935" d="M607.7,429.3c-1.1-1.4-3.4-1.1-4.3-2.8c0.4-1.2,1.3-1.9,2.4-2.5c2.7,0.3,4.9,1.8,7.1,3.2
		c0.3,1.2-0.2,1.9-1.2,2.4c-1,0.2-2,0.3-2.9-0.1C608.3,429.5,608,429.4,607.7,429.3z"/>
	<path class="st3936" d="M611.6,429.2c0.3-0.6,0.6-1.1,1-1.7c0.3-0.4,0.6-0.8,1-1c2.3-0.9,4.5-2,7-1.7c0.7,0.3,1.3,0.7,1,1.6
		c-2,0.9-4,1.9-6,2.8c-0.3,0-0.6,0.1-0.9,0.2C613.6,430,612.6,430.2,611.6,429.2z"/>
	<path class="st3934" d="M611.6,429.2c1,0.1,2,0.1,3,0.2c-1.9,1.6-3.9,1.4-6,0.2C609.6,429.4,610.6,429.3,611.6,429.2z"/>
	<path class="st3937" d="M682.5,434.3c1.3-1,2.7-2,4,0c-1.2,6.6-5.7,10.6-11.1,13.9c-1.3,0.3-2.6,0.2-3.7-0.5
		c-1.1-1.2,0-1.8,0.8-2.4C676,441.7,680,438.7,682.5,434.3z"/>
	<path class="st3938" d="M672.5,445.3c-0.3,0.7-0.7,1.3-1,2c-1.5,3.1-4.8,3.6-7.5,4.9c-6.9,1.4-13-0.4-18-5.3
		c-1.2-1.2-2.7-2.4-2.5-4.5c0,0,0.1-0.1,0.1-0.1c1,0.3,1.9-0.7,3.2,0.4c10.3,9.1,11.4,9.2,23.8,3.2
		C671.2,445.5,671.9,445.5,672.5,445.3z"/>
	<path class="st3938" d="M643.6,441.3c-2.2-1.4-4.6-0.9-7-1C639.1,439.1,641.7,437.9,643.6,441.3z"/>
	<path class="st3732" d="M635.5,447.4c0.2-0.7,0.5-1.4,0.7-2.1c2.3,1.8,2.8,4.1,1.6,6.8c0,0-0.3,0.3-0.3,0.3
		C635.4,451.3,635.6,449.3,635.5,447.4z"/>
	<path class="st3939" d="M637.8,452.1c-0.5-2.3-1.1-4.5-1.6-6.8c1.4-1.8,2.2-4.6,5.5-3.1c2.7,3,5.6,5.9,7.4,9.6
		c0.2,2.7,4.4,3.5,3.5,6.8c-4.1,1.8-7.5,0.8-11.2-3c-1-1.1-1.2-2.7-2.8-3.3C638.4,452.2,638.1,452.2,637.8,452.1z"/>
	<path class="st3940" d="M648.8,452.1c-2.5-3.2-6.1-5.6-7.1-9.9c0.6,0,1.2,0.1,1.8,0.1c5.2,7.1,12.2,10.1,20.9,8.9
		c0.1,0.2,0.2,0.5,0.3,0.7c-1,2.9-3.5,2.5-5.7,2.6c-2.1,0.1-4.2-1-6.3,0.1C650.9,454.7,649.4,454.3,648.8,452.1z"/>
	<path class="st3941" d="M675.6,447.4c4.6-3.5,8.1-8,10.9-13c1.6-2.4,3.3-4.7,4.9-7.1c0.2,0,0.4,0.1,0.6,0.1c2.3,0.3,3.6,1.6,3.5,4
		c-3.2,8.8-9.6,15.4-16,22c-0.7,0.3-1.4,0.7-2.1,1c-4.7-0.4-4.3-2.9-2.5-6C675.1,448,675.3,447.6,675.6,447.4z"/>
	<path class="st3942" d="M701.5,421.3c-0.3,0-0.7,0-1,0.1c-0.9-0.7-1-2-1.9-2.8c-2.5,3-2.2,8-7,8.8c0,0-0.2-0.1-0.2-0.1
		c1.3-3.2,2.5-6.5,4-9.6c0.9-1.9,1.8-4.4,4.6-3.5c3,0.9,2.7,3.3,1.8,5.7C701.7,420.3,701.6,420.8,701.5,421.3z"/>
	<path class="st3943" d="M691.6,427.4c2.2-1.8,3.7-4.2,4.6-6.8c0.7-1.8,0.5-4.8,2.9-4.4c2.4,0.4,1,3.4,1.4,5.2
		c-1.4,3.4-2.4,7.1-4.9,10C694.3,430.1,693,428.7,691.6,427.4z"/>
	<path class="st3944" d="M638.6,452.3c3-1.2,1.9,2.2,3.3,3c3.3,1.9,6.4,4.4,10.6,3c0.1-0.1,0.3-0.3,0.4-0.3
		c4.6,2.6,9.2-0.4,13.9,0.1c0.5,0.1,1,0.2,1.5,0.3c0.5,0.2,0.9,0.5,1.2,0.9c-3,2.4-6.9,2.3-10.2,3.9
		C650.6,463.2,642.9,460.9,638.6,452.3z"/>
	<path class="st3945" d="M669.5,459.3c-0.3,0-0.6-0.1-1-0.1c-0.2-2.5,3.5-4.2,1.6-7.1c0.8-2.6,2.6-3.8,5.3-3.8
		c3.9,2.4-0.1,2.5-1.1,3.5c1.1,0.9,2.2,1.7,3.2,2.6C675.3,456.6,672.6,458.3,669.5,459.3z"/>
	<path class="st3497" d="M575.7,477.3c0-1.3,0.7-2,2-2c0.2,0.7,0.4,1.4,0.6,2.1C577.4,477.4,576.5,477.3,575.7,477.3z"/>
	<path class="st3946" d="M563.5,397c-2.5,6.6-2.7,13.6-4,20.4c-1.2,1.5,0.6,5-3.2,4.7c-0.8-1.1-0.9-2.5-0.9-3.8c0-1.6,0-3.1,0.2-4.7
		c0.4-1.7,0.9-3.5,0.8-5.3c0-0.8,0.1-1.5,0.3-2.3c0.8-3.2,1.3-6.5,2.1-9.7c2.1-4.6,1.7-10.1,5.3-14.1c2-0.8,1.8,1.1,2.5,2
		C565.2,388.5,564.1,392.7,563.5,397z"/>
	<path class="st3947" d="M566.6,384.4c-0.6-0.6-1.3-1.3-1.9-1.9c-1.3-1.6-0.3-3.2,0.1-4.8c0.5-1.3,0.8-2.7,2.1-3.5
		c1-0.1,2.1-0.4,3.1,0.2C568.9,377.7,567.7,381.1,566.6,384.4z"/>
	<path class="st3948" d="M570,374.4c-0.8,0-1.6,1-2.3,0c-0.4-0.3-0.6-0.7-0.8-1.2c-0.2-1-0.1-1.9,0.8-2.6c1.9-0.2,2.1-2,3.1-3.2
		c0.3,0.7,0.7,1.5,1,2.2C571.9,371.5,571.2,373.1,570,374.4z"/>
	<path class="st3949" d="M556.8,422.4c0.9-1.7,1.8-3.3,2.7-5c-1.1,15.1,0.2,30,4.4,44.6c0.2,0.5-0.1,1.3-0.2,1.2
		c-2-1.5-1.2,1.1-2,1.4c-0.5,0.1-1,0-1.4-0.3c-1.6-2.1-1.6-4.9-2.7-7.2c-0.8-2.4-1.4-4.9-1.4-7.4c-1.6-7.7-0.8-15.4-0.9-23.1
		C555.3,424.9,555.5,423.4,556.8,422.4z"/>
	<path class="st3950" d="M579.8,494.3c-0.4-0.3-0.8-0.7-1.3-1c-0.2-0.1-0.4-0.3-0.6-0.4c-1.7-3.7-5.9-5.7-7-9.9
		c0.4-2.6,1.8-2.2,3.3-1c1.4,1.1,2.4,2.5,3.8,3.6c2.6,2.5,4.9,5.3,8.4,6.5c0.2,0.3,0.3,0.6,0.4,0.9c1.9,1.4,4.2,2.3,5.4,4.5
		c0.1,1.6-0.8,2.3-2.2,2.6C585.6,499.9,583,496.6,579.8,494.3z"/>
	<path class="st3951" d="M577.7,486.3c-1.4-1.3-2.9-2.5-4.3-3.8c-1.1-1-1.6-0.5-1.7,0.7c-5.5-2.9-8-7.8-8.8-13.8
		c1.7-2.5,2.9-0.9,3.8,0.6c0.9,1.4,1.2,3.3,2.9,4.3c2.7,3.4,5.3,6.8,8,10.2C578.2,485,578.3,485.7,577.7,486.3z"/>
	<path class="st3952" d="M569.6,474.3c-1.9,0-2.1-1.8-3-2.9c-0.7-1-0.9-3.1-3-2.1c-3.1-0.7-3.2-2.6-2-5c-0.1-1.6,0-2.9,2.2-2.4
		C565.8,466.1,567.7,470.2,569.6,474.3z"/>
	<path class="st3953" d="M594.8,497.2c3.3,1.3,6.6,2.7,9.9,4c-0.2,0.8-0.7,1.4-1.5,1.7c-4.1-0.4-8.3-0.7-9.9-5.6
		C593.9,496.8,594.4,496.8,594.8,497.2z"/>
	<path class="st3954" d="M577.7,486.3c0-0.6,0-1.2,0-1.8c2.9,2.6,5.9,5.2,8.8,7.7C582.4,491.8,579.9,489.3,577.7,486.3z"/>
	<path class="st3955" d="M594.8,497.2c-0.4,0-0.8,0.1-1.2,0.1c-0.7,0.6-1.3,0.6-2,0c-2-1-3.7-2.3-4.8-4.2
		C589.9,493.5,592.3,495.5,594.8,497.2z"/>
	<path class="st3956" d="M603.6,502.2c0.4-0.4,0.8-0.7,1.1-1.1c1.2,0.1,2.4,0.2,3.7,0.3c0.1,0.3,0.2,0.5,0.4,0.7
		c-0.1,0.7-0.5,1.2-1.2,1.6c-1.2,0.4-2.2,0.1-3.2-0.5C604.1,503,603.8,502.6,603.6,502.2z"/>
	<path class="st3957" d="M607.6,503.3c0.4-0.4,0.8-0.7,1.2-1.1c3.3-0.1,6.4,1.6,9.8,0.9c0.3,0.1,0.7,0.2,1,0.3
		c0.3,0.3,0.7,0.6,1,0.9c0.3,0.5,0.2,1-0.2,1.4c-0.5,0.3-1,0.5-1.5,0.6C615.1,505.7,610.8,506.6,607.6,503.3z"/>
	<path class="st3958" d="M735.5,334.4c2,8.7,0.8,17-2.6,25.2c-0.9,2.2-2.5,4.2-1.5,6.8c-0.9,1.9-2,3.8-3.8,5.1
		c-2.2,0.9-3.5,3-5.3,4.4c-1.2,0.9-2.2,1.6-3.3,0c0-2.6-1-4.6-3-6.4c-3.2-2.9-5.7-6.4-6.7-10.8c-1.1-5,1.3-9,6.3-10.5
		c2-0.1,4,0,5.9-0.5c2.8-0.8,4.8-2.2,4.3-5.6c-0.3-1.7-0.1-3.3,0.7-4.8c2-3.8,2.7-7.4-1.1-10.7c-1.6-1.4-1.2-3.7-1.1-5.7
		c0.1-0.8,0.3-1.5,0.8-2.2c0.4-0.4,0.8-0.6,1.4-0.5c0.6,0.5,1,1.1,1.2,1.8c1.1,4,4.5,7.1,4.7,11.6
		C732.4,333,734.3,333.7,735.5,334.4z"/>
	<path class="st3959" d="M719.5,376.4c3.4-0.5,4.4-4.9,8.1-4.9c-1.8,2.5-3.7,4.9-6.7,6.1c-0.5-0.1-0.9-0.1-1.4-0.2
		c-0.2-0.2-0.3-0.5-0.2-0.7C719.3,376.5,719.4,376.4,719.5,376.4z"/>
	<path class="st3960" d="M691.6,345.5c0.1-1.6-1.4-2.1-2.1-3.1c-0.6-2.7,2.6-4.3,2-7c0.8-2.6,2.3-4.9,4-7c1,0.5,1.5,1.4,1.8,2.4
		c0.8,4.1-1.8,7.2-2.8,10.8C693.6,343,694,345.3,691.6,345.5z"/>
	<path class="st3961" d="M696.8,330.5c-1-0.4-1.2-1.2-1.3-2.1c1.9-4.9,5.9-8.1,9-11.9c7.1-3.3,14.4-3.9,21.9-2c0,0.3,0,0.7,0,1
		c-1,1.1-2.3,1-3.6,0.9c-4.4-1.3-8.4,0.4-12.5,1.8c-5.1,2.4-7.2,7.5-10.4,11.6C699.1,330.6,698.1,331.4,696.8,330.5z"/>
	<path class="st3962" d="M691.6,345.5c0.7-1.4,1.3-2.7,2-4.1c2.3,0.5,2.8,1.9,2.5,4c-0.4,2.6-0.2,5.3-0.1,7.9
		c-0.2,4.6,0.5,9.3-0.5,13.9c-0.6,1.2-1.5,2-2.9,1.1C689.6,360.9,689.9,353.3,691.6,345.5z"/>
	<path class="st3963" d="M692.5,368.5c0.8-0.1,1.6-0.2,2-1c0.5-0.4,0.9-0.4,1.3,0c0.9,1.3,1.1,2.8,1.5,4.3c0.2,1.3,0.2,2.6-0.8,3.6
		c-0.7-0.3-1.3-0.7-2-1C693.9,372.4,693.2,370.4,692.5,368.5z"/>
	<path class="st3964" d="M696.6,375.4c0-1.3-0.1-2.6-0.1-3.9c-0.5-1.8-1-3.6,0.4-5.2c3.7,3.1,5.4,7.3,7.3,11.6
		c-0.2,1.6-0.9,2.5-2.7,1.6c-0.3,0-0.6-0.1-0.9-0.1c-1.6-1-3-2.3-4.1-3.8L696.6,375.4z"/>
	<path class="st3965" d="M701.4,379.5c1.2-0.1,2-0.6,2-1.9c1.9-0.8,2.5,0.4,3.1,1.9c0.3,0.7,0.6,1.4,0.9,2.2
		C705.2,381.6,703.1,381.2,701.4,379.5z"/>
	<path class="st3966" d="M696.4,375.5c2,0.6,3.1,2.2,4.1,3.8c-2.1,0.3-3-2.1-4.9-2.1C695.9,376.7,696.2,376.1,696.4,375.5z"/>
	<path class="st3967" d="M719.5,376.4c0,0.3,0,0.7,0,1c-3.5,0-6.4,2.9-10,2c-0.6-0.8-2.6-0.2-2-2.1c1.8-1.6,4.5-2.4,5.8-5.1
		c-2.1-0.6-2.8,2.8-4.7,1.1c-2.3-2.3-2.7-6.3-6.9-7c-1.3-0.2-1.3-2.5-1.4-4c-0.1-4.3-0.1-8.6,0-12.8c0-3.1,1.5-4.9,4.6-5.4
		c0.8-0.2,1.5-0.7,2.1-1.2c4.3-3.3,8.5-2.3,10.8,2.6c1,2.3-1.1,2.8-2.1,3.9c-3.1,0.7-6.1,5-5.5,7.8c1.1,4.8,3.5,9.3,7.3,11.7
		C721,371.4,720.4,373.5,719.5,376.4z"/>
	<path class="st3968" d="M707.5,377.4c0.7,0.6,1.3,1.3,2,1.9c-1,0-2,0-3,0c-1-0.6-2.1-1.2-3.1-1.9c-2.5-3.4-5.1-6.8-6-11.1
		c-0.7-0.6-1.1-1.4-1.3-2.3c-1.1-5.8-0.6-11.7-0.4-17.5c0.3-0.9,0.8-1.5,1.9-1.3c2.1,4.8,1.8,9.9,2.3,14.9c0.2,2.3,0,4.6,1,6.8
		c1.3,3.2,3.9,5.7,5.7,8.6C707.1,376.1,707.5,376.7,707.5,377.4z"/>
	<path class="st3969" d="M667.6,522.2c-0.5-1.5-2.2-1.7-3.1-2.8c-1.1-1.3-4.4-1.1-2.9-4.2c1.4,0.9,2.8,1.9,4.2,2.5
		c2,0.9,4.5,0.5,5.3-1.4c4.2-9.9,12.4-16.1,20.6-22.2c2.2-1.7,4.4-1.9,5.9,1.1c0.3,0.7,0.7,1.3,1,2c-0.2,1.5,0.3,2.8,1,4
		c2.5,9.1,5.1,18.1,4.1,27.7c-0.1,1.4,0.6,2.9,0.9,4.3c1,1.5-0.1,2.8-0.5,4.1c-0.7,0.8-1.5,1.3-2.6,1.1c-0.3-0.1-0.8-0.1-0.9-0.3
		c-1.6-5.3-6.6-9.1-6.3-15.3c0.2-5.4-1.8-7.6-6.1-8.9c-2.1-0.7-3.1-0.2-3.3,2c-0.1,1.6-0.3,3.3-0.7,4.9c-1,4.9-3.6,6-8.3,3.8
		c-0.8,2.3,4.7,4.6,0,6.9c-2.6,0.2-3.5-2-4.8-3.6C670,526,668.1,524.5,667.6,522.2z"/>
	<path class="st3515" d="M661.6,515.3c-0.3,2.3,2.2,2.6,2.9,4.2c-2.7-0.1-4.2-1.3-3.9-4.2C660.9,515.3,661.2,515.3,661.6,515.3z"/>
	<path class="st3970" d="M703.5,537.2c0.4-1.3,0.7-2.6,1.1-4c0.3,0,0.6,0,0.9-0.1c0.4,17.9,0.5,35.8-2.7,53.5
		c-0.4,2.1-0.2,4.3-0.3,6.5c0.5,1.8,0.3,3.6-0.1,5.4c-1.4,7.4-3,14.8-4.8,22.1c-0.4,1.2-0.8,2.4-1.3,3.5c-1.3,1.9-1.5,4-1.5,6.2
		c-0.2,1.3-0.3,2.6-1.9,3c-1.1-0.4-1.6-1.4-2-2.4c-0.2-0.8-0.2-1.6-0.1-2.5c1.7-13.1,5.7-25.7,7.7-38.8c1.9-12.8,3.1-25.5,3.1-38.5
		C701.6,546.6,700.8,541.6,703.5,537.2z"/>
	<path class="st3743" d="M699.5,501.3c-1.6-1-2.9-2.1-1-4C699.6,498.4,700.4,499.6,699.5,501.3z"/>
	<path class="st3971" d="M707.7,529.2c0.3,0,0.6,0,0.9,0c1,0.9,1.3,2.1,1.4,3.3c1.4,8.1,0.9,16.2,1.1,24.3c0.2,2.6-0.1,5.2-0.5,7.8
		c-0.7,2.7-0.6,5.4-0.6,8.1c0,1.3-0.1,2.6-0.4,3.9c-0.9,2.4-0.6,4.9-0.9,7.3c-0.1,0.5-0.2,0.9-0.3,1.4c-0.7,1.8-0.4,3.7-0.7,5.5
		c-0.1,0.5-0.2,0.9-0.3,1.4c-0.5,1.6-0.6,3.3-1,4.9c-0.5,1.7-0.6,3.4-1,5c-1,2.9-1.3,6-2.1,9c-0.5,0.7-1.1,1.3-2,0.7
		c-0.6-0.7-0.8-1.5-0.8-2.4c0.3-7.3,3.2-14.1,4-21.2c1.6-15.7,4.8-31.3,3.1-47.1C707.6,537.2,707.6,533.2,707.7,529.2z"/>
	<path class="st3972" d="M707.6,541.2c1.9,6.7,0.8,13.4,0.4,20.2c-1,16.4-3.9,32.5-6.5,48.7c-0.6,0.4-1,0.3-1.4-0.3
		c-0.5-4,1-7.7,1.4-11.6c0.2-1.7-0.7-3.5,0.9-5c2.9-5.4,2.2-11.6,2.9-17.3C706.6,564.3,708,552.8,707.6,541.2z"/>
	<path class="st3973" d="M706.5,592.2c0-0.3,0-0.7,0-1c1.3-1.8-0.3-4.1,1-6l0,0c1.4-2.5-0.3-5.4,1-8c0-1.3,0-2.6,0-4
		c1.3-2.6-0.3-5.5,1-8c0-2.7,0-5.3,0-8c0.6-8.4,1.5-16.8-1-25c0-1,0-2,0-3c1.7-1,1.4-3.9,4-4c0.6,6.9,1.6,13.8,1.6,20.7
		c0,5.6-1.1,11.2-2,16.8c-0.2,1.5-0.1,2.5,0.6,3.6c1.3-0.3,0.2-2.1,1.8-2.1c0,1.7,0,3.3,0,5c-1.1,1.8-0.3,3.7-0.5,5.5
		c0,0.5,0,0.9-0.2,1.4c-1.7,0.8-1.7-0.2-1.6-1.4c-1.2,1.9,1.8,2.9,1.2,4.7c0,1.7-0.2,3.2-0.6,4.8c-1.8,6.4-4.6,12.6-4.3,19.5
		c0.1,2.4-0.1,5.8-3.7,6.7c-3.7-2.6,0.2-5.5-0.3-8.3c0.3-1.7-0.6-3.6,1-5C706.8,595.7,705.3,593.7,706.5,592.2z"/>
	<path class="st3974" d="M713.5,576.2c0-0.3,0-0.7,0-1c0.3-0.5,0.7-0.5,1,0c0.3,5.8-0.7,11.4-2,17c-1.1,2.9-1.2,6-2,9
		c0,0.7,0,1.3,0,2c-2.2,4.8-2.8,10-4,15c-1.5,1.4-0.3,3.4-1,5c-2.6,8.1-5.4,16.1-6.6,24.5c-0.2,1.4,0.1,3.9-2.8,3.2
		c-1.7-3.6-0.3-7.5-1.3-11.1c0-1.4,0.4-2.6,1-3.9c1.8-2.8,1.5-5.9,1.5-9c1.1-5,1.2-10.2,4.4-14.6c0.3-0.4,0.6-0.7,0.9-1.1
		c0.5-0.6,1.1-1,2-1c2.9-1.5,2.9-4.8,2.7-6.9c-0.5-6.7,1.9-12.4,4.2-18.3c0.9-2.2,1.3-4.4,1.1-6.7
		C713.1,577.6,712.7,576.6,713.5,576.2z"/>
	<path class="st3975" d="M696.5,651.1c1.3-0.9,0.9-2.4,1.1-3.7c0.7-7.9,4-15.2,5.8-22.9c0.3-1.5,0.6-2.1,2.1-1.4c0,1.4,0,2.7,0,4.1
		c1.3,1.5,0.7,3.1,0.2,4.7c-2.4,8.6-5.2,17-8.3,25.3c-1.1,2.4-1.7,5-2.8,7.5c-0.7,1.5-0.9,3.8-3.5,3.4
		C690.3,661.6,693.1,656.2,696.5,651.1z"/>
	<path class="st3976" d="M714.5,598.2c-0.7,4.3-1.3,8.7-2,13c1.7,5-1,9.4-1.9,14c-0.3,1.5-1.6,2.8-1.7,4.5c-0.6,2.9-1,5.8-1.9,8.7
		c-0.5,1.7-0.9,3.7-3.6,3c-1.3-3.1,1-5.5,1.4-8.3c0.3-2,1.1-4,1.5-6c2.7-11.3,5.3-22.7,8-34C714.5,594.7,714.5,596.4,714.5,598.2z"
		/>
	<path class="st3977" d="M706.5,627c0.4,5-2.6,9.3-2.9,14.1c2,3.3-1,5.9-1.1,9c-0.6,1.7-1.1,3.5-2.1,5c-0.8,1.3-1.6,3.1-3.8,2
		c2.7-10.1,7-19.6,9-29.9C705.9,627.1,706.2,627.1,706.5,627z"/>
	<path class="st3975" d="M706.5,618.1c0.8-5.2,0.1-10.7,4-15C709.9,608.3,708.4,613.3,706.5,618.1z"/>
	<path class="st3975" d="M710.5,601.2c0-3.2-0.2-6.3,2-9C712.6,595.3,712.2,598.4,710.5,601.2z"/>
	<path class="st3746" d="M714.5,575.2c-0.3,0-0.7,0-1,0c0.3-2-0.8-4.2,1-6C714.5,571.2,714.5,573.2,714.5,575.2z"/>
	<path class="st3978" d="M619.7,505.4c0.3-0.1,0.7-0.2,1-0.3c5.3-1,10.8,0.4,16.1-0.7c0.6,0.1,1.3,0.1,1.9,0.2c0.6,0.4,1.5,0,2,0.8
		c-4.6,1.4-9.4,1.3-14.1,1.9C624.4,506.2,621,509.5,619.7,505.4z"/>
	<path class="st3979" d="M636.8,504.4c-5.3,1.8-10.7,0.7-16.1,0.7c0-0.3,0-0.5,0-0.8c3.6,0,7.2,0,10.9-0.1
		C633.3,504.3,635,504.3,636.8,504.4z"/>
	<path class="st3980" d="M640.7,505.3c-0.7-0.3-1.3-0.5-2-0.8c2.3-0.5,4.6-1,6.9-1.4c0.7,0,1.3,0.1,2,0.1
		C645.8,505.6,643.2,505.2,640.7,505.3z"/>
	<path class="st3981" d="M659.7,499.4c-1.8,1.6-3.9,2-6.2,1.8c0.7-0.6,1.4-1.3,2.2-1.9c0.9,0,1.8,0,2.7-0.1
		C659,498.8,659.4,498.8,659.7,499.4z"/>
	<path class="st3513" d="M659.7,499.4c-0.4-0.1-0.9-0.1-1.3-0.2c1.3-1.6,3.2-2,5.1-2.1C662.8,498.8,661.2,499,659.7,499.4z"/>
	<path class="st3982" d="M574.7,514.2c0.3-1,0.7-2,1-3c1-1.1,2.3-1.4,3.6-1.4c11.7,0.8,23.1-0.7,34.5-3c3.9,0.3,8-0.4,11.7,1.4
		c3,0,6,0,9,0c-0.7,0.3-1.4,0.7-2.2,1c-3.5,1.4-7.3,0.2-10.6,4.3c-2.6,3.2-8.8,2.6-13.4,3.7c-1.9,0.4-3.8,0.7-5.8,1
		c-0.4-1.6-1.1-2.4-3.1-2.3c-4.6,0.2-9.2-0.3-13.7-1.1c-2.2-0.4-4.2,0.1-6.2,0.9C577.8,516.4,575.8,516.7,574.7,514.2z"/>
	<path class="st3983" d="M643.6,507.3c1.7-0.1,3.5,0,4.9-1.2c2-0.6,2.9-0.1,2.1,2.1l0,0c0,6.5-0.1,12.9,1.3,19.3
		c-0.3,2.4-2.1,2.9-4.1,3.3c-0.5,0-1-0.2-1.4-0.5c-0.9-6.3-1.9-12.6-5.2-18.2c-0.8-1.3-0.2-2.6,0.6-3.7
		C642.3,508,643,507.7,643.6,507.3z"/>
	<path class="st3984" d="M619.7,505.4c1.9,2.1,5.1-0.3,6.9,1.9c-0.3,0-0.6,0-1,0c-3.7,1-7.5,0.2-11.2,0.5c-3.4-0.4-7,1.2-10.2-0.9
		c-1.1-1.6-1.7-3.1,0.4-4.4c1,0.3,2.1,0.5,3.1,0.8C611.6,504,615.7,504.7,619.7,505.4z"/>
	<path class="st3985" d="M658.6,502.6c-1.3,3.1-4.5,2.6-6.9,3.8c-0.6-0.1-1-0.6-1.3-1.1C653,503.9,655.7,502.8,658.6,502.6z"/>
	<path class="st3978" d="M613.6,507.3c4,0,8,0,12.1,0c0,0.3,0,0.7-0.1,1c-4,0-8,0-12,0c-0.3-0.2-0.4-0.4-0.3-0.6
		C613.4,507.4,613.5,507.3,613.6,507.3z"/>
	<path class="st3982" d="M643.6,507.3c-0.4,0.3-0.7,0.6-1.1,1c-0.5,0.5-1.1,0.5-1.7,0.1c-0.8-0.1-1.5-0.1-2.3-0.2
		C640,506.7,641.9,507.4,643.6,507.3z"/>
	<path class="st3986" d="M650.5,505.2c0.4,0.4,0.8,0.8,1.3,1.1c-0.4,0.6-0.8,1.3-1.1,1.9c-0.1-1.3-0.9-1.9-2.1-2.1
		C649.2,505.8,649.8,505.5,650.5,505.2z"/>
	<path class="st3987" d="M652.6,526.5c2.8-1.5,5.6-2.9,8.3-4.5c2-1.1,3.8-1,5.6,0.3c0.9,0.5,1.6,1.1,2,2c0.5,1.8,0.9,3.5-1.8,3.6
		c-1-0.1-1.6-0.8-2.2-1.5c-1.5-1.4-3.1-0.9-4.6-0.1c-1.9,1-3.7,2.2-6,1.7C653.1,527.8,652.5,527.4,652.6,526.5z"/>
	<path class="st3986" d="M652.6,526.5c0.2,0.3,0.5,0.5,0.7,0.8c0.8,0.9,0,1.1-0.6,1.4c-0.5,0-0.9-0.3-1.1-0.7
		c-1-6.5-3.2-12.9-1-19.6c1.8,3.2,0.5,6.7,1,10C651.8,521,651,523.9,652.6,526.5z"/>
	<path class="st3988" d="M667.6,524.3c-0.4-0.7-0.7-1.3-1.1-2c0.4,0,0.8-0.1,1.1-0.1c1.7,1.3,3.2,2.9,4,5c-0.5,0.5-1.2,0.6-1.9,0.4
		C668.7,526.7,668.6,525.2,667.6,524.3z"/>
	<path class="st3989" d="M411.9,404.4c3.5-1.1,6-0.9,6.2,3.8c0.1,3.1,3.4,5.9,0.8,9.2c-2.8-1.7-5-3.9-5.3-7.4
		c-0.2-0.6-0.2-1.2-0.2-1.9c-0.4,1.3,0.5,2.3,0.9,3.4c-1.3,2-2.2,0.7-2.7-0.5C410.8,408.8,410.7,406.5,411.9,404.4z"/>
	<path class="st3990" d="M413.9,410.4c0.2,3.4,4.9,3.5,5,7c-1.1,0-2.3-0.2-3,1c-1-0.3-2-0.7-3-1c0.4-3.2-2.1-5.3-3-8
		c-0.1-2,0.4-3.7,2-5c0.1,2.1-0.1,4.4,0.4,6.4C412.7,412.3,413.6,411.7,413.9,410.4z"/>
	<path class="st3769" d="M477.8,333.4c1.3,0.7,2.3,1.7,3,3c-2.8,1-4-0.2-4-3c0,0,0,0,0,0C477.1,333.4,477.5,333.4,477.8,333.4z"/>
	<path class="st3991" d="M502.8,375.4c0,0.5,0,1,0.1,1.5c0.2,1.7,2,3.7-0.3,4.8c-2.8,1.3-2.3-1.8-3.3-3c-0.7-0.9-1.6-1.5-2.5-2.3
		c-3-8.5-6.4-16.8-12-23.9c-0.8-0.4-1-1.2-1-2c-1.6-2.3-2.2-5.2-4.4-7.1c-1.2-1-1.9-2.6-0.5-3.9c1.5-1.4,3.3-0.8,4.2,0.8
		c4.3,7,11.5,12,13.4,20.5c1.5,3.3,2,7,4.5,9.8c0.8,1.3,1.6,2.6,1.8,4.2C502.7,375,502.7,375.2,502.8,375.4z"/>
	<path class="st3992" d="M495.8,361.4c-2.4-8.3-9.8-13.3-13.5-20.8c-0.5-1-1.6-1.5-2.5-0.9c-1.3,0.8-0.9,2.1,0,2.9
		c2.3,2.1,3.6,4.7,4.1,7.7c-3.5-4.7-7-9.5-10.5-14.3c-0.6-0.8-0.8-2-0.1-2.8c1.1-1.5,2.4,0.4,3.6,0.1c0.9,1.6,2,2.9,4,3
		c3.1,1.6,4.4,5,7,7.1c5.9,4.4,8.1,11.1,10.8,17.4c-0.1,0.5-0.2,0.9-0.5,1.4C496.9,363.2,496.4,362.2,495.8,361.4z"/>
	<path class="st3993" d="M505.8,369.4c-1.5,3.5,3.2,6.5,0.9,10.1c-1-2-2-4.1-2.9-6.1c-1.9-1.9-2.6-4.3-3.1-6.8c-0.1-1-0.1-2,0-3.1
		c0.1-0.8,0.4-1.5,0.9-2.1c3.2-0.4,2.4,2.4,3.2,4C505.1,366.8,505.4,368.1,505.8,369.4z"/>
	<path class="st3994" d="M501.7,366.4c0.2,2.4,2.3,4.4,2,6.9c0,0.7,0,1.4-0.1,2.1c-0.3,0-0.6-0.1-0.9,0c0,0,0,0,0,0
		c-0.9-1.9-1.2-4.1-2.8-5.8c-0.8-2.4-3.6-4.2-2.2-7.2c0-0.4,0.1-0.7,0.1-1.1C501.5,361.3,500.4,364.8,501.7,366.4z"/>
	<path class="st3534" d="M506.8,381.3c1.4,1.8,1.9,3.8,2,6c0,1.4,0,2.7-0.1,4.1C507.3,388.2,506.6,384.9,506.8,381.3z"/>
	<path class="st3995" d="M529.7,431.3c-1.1-5.1-1.9-10.2-6-14c0.4-3.1-1.2-6.5,2-9c2.6-1.3,3.4,0.8,4.4,2.4c1.1,1.7,2.5,3.4,2.8,5.5
		c-0.4,4.9,4.2,8.3,4.1,13.2c0,1.4-0.1,2.8-1,3.9C533.2,435.1,531.3,433.8,529.7,431.3z"/>
	<path class="st3996" d="M531.7,416.4c-1.7-2.9-2.3-6.6-6-8c-3.2-3-1.1-8.4-5-11c-0.7-2.1-1.2-4.2,0.7-6c4.2-0.4,5.5,2.6,6.8,5.6
		c2.8,3.9,5.2,8,5.6,12.9c0.2,2.1,0.6,4.3-0.6,6.3C532.7,416.6,532.3,416.7,531.7,416.4z"/>
	<path class="st3997" d="M508.8,369.4c-1.1-1.1-1.8-2.4-2.1-3.9c-3.6-5.2-6.7-10.6-8.5-16.8c0.5-1.9,1.7-1.4,2.9-0.9
		c2.1,1.1,3.1,3,3.9,5c5.2,7.8,8.4,16.6,12.3,25c0.1,1.2-0.2,2.3-1,3.3c-0.2,0.1-0.4,0.3-0.6,0.4C512.1,378.1,513,372.3,508.8,369.4
		z"/>
	<path class="st3997" d="M521.7,391.4c-0.3,2-1.4,3.9-1,6c-2.3-5.1-4.2-10.4-5-16c0,0,0,0,0,0c2-2.4,3.2-0.7,4,1
		C521,385.2,522.4,388.1,521.7,391.4z"/>
	<path class="st3998" d="M498.7,348.5c2.5,5.7,6.2,10.9,7.9,17c-0.7-0.1-1.3-1.1-2,0c-1-1.3-2-2.6-3-3.9c-1.5-1.3-1.7-3.2-2.6-4.8
		c-1.3-3.4-3.6-6.3-5.3-9.6c0-0.2,0-0.5-0.1-0.7c-1-4.8-5.2-7.6-7.1-11.9c0.2-0.8,0.7-1.3,1.4-1.4
		C493.4,337,496.3,342.6,498.7,348.5z"/>
	<path class="st3723" d="M606.6,361.4c-0.7,1.3-1.7,2.3-3,3C602.3,361.1,604.8,361.6,606.6,361.4z"/>
	<path class="st3723" d="M602.6,365.4c-0.7,1.3-1.6,2.4-3,3c0-1,0-2,0-3C600.6,365.4,601.6,365.4,602.6,365.4z"/>
	<path class="st3931" d="M645.6,371.4c0,0.3,0,0.7,0,1c-0.5,0.6-1.1,1.6-1.9,0.8c-1.4-1.5,0.4-2.6,0.9-3.8c0,0,0,0,0,0
		C645.4,369.9,645.6,370.6,645.6,371.4z"/>
	<path class="st3999" d="M644.6,369.4c1.2,0.7-2.9,3.2,1,3c1.7,9,1,18,0,27c0,0.7,0,1.3,0,2c-0.8,1.3-0.9,2.9-2,4c0,0,0,0,0,0
		c-0.3,0-0.7,0-1,0c-0.5-1.2-1.4-2.3-0.5-3.7c4.9-8.2,2-16.8,1.1-25.3c0-0.4-1-0.6-1.6-0.9c-0.4-0.3-0.6-0.7-0.7-1.2
		c-0.1-0.7,0.1-1.4,0.7-1.9C642.8,371.7,643,369.8,644.6,369.4z"/>
	<path class="st4000" d="M735.5,334.4c-2.4,1.2-4.7-0.9-4.6-2c0.7-5-3.8-7.7-4.4-12c2.2-1.2,2.9,0.7,3.8,2c1.4,2,2.7,4.2,3.7,6.4
		c0.9,1.4,0.9,3.1,1.4,4.6C735.5,333.8,735.5,334.1,735.5,334.4z"/>
	<path class="st4001" d="M735.5,333.4c-1.3-1-1.8-2.4-2-3.9c1.8-0.2,1.8-2.1,3-3c1.8,1.6,1.9,3.7,2.1,5.9
		C738,334.1,736.2,332.3,735.5,333.4z"/>
	<path class="st4002" d="M736.5,326.5c-1.7,0.3,2.3,6.3-3,3c-2.7-2.7-3-7.3-6.9-9.1c0-0.6-0.1-1.3-0.1-1.9c-0.5-0.9-0.8-1.8,0.8-1.9
		C732.4,318,735.1,321.7,736.5,326.5z"/>
	<path class="st4003" d="M727.2,316.7c-0.3,0.6-0.5,1.2-0.8,1.8c0,0-0.5,0-0.5,0l-0.5,0c-1.7,0-3.4-0.4-4.4-1.6
		c-1.7-2.2,0.2-1.8,1.5-1.6c1.3,0.1,2.6,0.1,3.9,0.2C726.7,315.9,727,316.3,727.2,316.7z"/>
	<path class="st4004" d="M689.5,697c-4.1,4.3-7.5,9.2-11,14c0-3.6,1.3-6.6,4-9c2.6-6.2,5.9-12.1,10.2-17.4c2.7-2.8,4.5-6.2,6.3-9.5
		c0.4-0.3,0.8-0.4,1.3-0.4c1,0.4,0.6,1.6,1.2,2.3c-2.3,4.5-5.1,8.7-8.1,12.7C691.8,692,689.8,694.1,689.5,697z"/>
	<path class="st4005" d="M701.5,677.1c-0.8-0.4-1-1.2-1-2c1.9-7.2,5.4-13.5,10.9-18.6c0.8-0.1,1.5,0,2.1,0.6c-0.7,4.6-4.4,7.8-6,12
		C705.5,671.7,703.5,674.4,701.5,677.1z"/>
	<path class="st4006" d="M713.5,657.1c-0.7,0-1.3,0-2,0c-0.7-2.2,1.4-3.5,1.9-5.3c0.4-0.8,0.7-1.6,0.9-2.4c0.2-0.7,0.4-1.3,0.7-1.9
		c2.8-5.3,6.3-10.3,9.5-15.4c2.9,0.2,2.8-3.3,5-4c-3.3,7.8-7.8,14.9-11.8,22.3c-0.5,0.9-0.8,1.8-1.1,2.7
		C715.5,654.4,714.5,655.8,713.5,657.1z"/>
	<path class="st4007" d="M663.6,711c-8.4-3.3-16.4-7.5-25.1-10c-9.8-4.3-19.9-8-29.6-12.4c-14.3-6.5-29-12.6-41.6-22.4
		c-0.6-1.5-3.4-2.8-0.5-4.7c0.5,0,0.9,0.1,1.4,0.2c1.5,0.9,3,1.9,4.7,2.6c0.8,0.4,1.6,0.8,2.4,1.1c3,1.1,5.9,2.5,8.8,3.9
		c4,2.1,8,4.1,12.1,6c11.5,6.1,23.3,11.6,35.6,15.9c6.3,2.6,12.4,5.6,19,7.7c4.4,1.9,8.6,4.1,12.8,6.3c0.8,0.5,1.3,1.2,1.5,2.2
		c-0.6,0.8,0.2,1.8-0.3,2.7C664.2,710.4,663.9,710.7,663.6,711z"/>
	<path class="st4008" d="M680.6,697.1c-0.3,0.7-0.6,1.3-0.9,2c-1.5,1.7-1.1,4.1-2.1,6c0,0,0,0,0,0c-1,0.6-2.1,1.3-3.1,1.9
		c-0.7-0.5-1-1.3-1.1-2.1c-0.2-5.1,2.4-9.2,4.5-13.5c3.3-6.9,5.8-14,8.4-21.1c0.7-1.8,0.7-4,3.1-4.7c2.6,1.5,1.9,3.7,1.4,5.9
		c-0.6,2.4-1.6,4.6-2.1,7.1c-0.1,0.5-0.3,1-0.4,1.5c-1.5,3.4-3.3,6.6-4.8,10l0,0C682.5,692.4,681.4,694.7,680.6,697.1z"/>
	<path class="st4009" d="M674.5,705.1c0,0.7,0,1.3,0,2c0.4,4.4-3.2,7.4-4,11.4c-0.1,0.7-0.5,1.3-1.3,1.1c-0.9-0.2-1.4-0.9-1.5-1.8
		c-0.3-1.9,0.1-4-1.2-5.7c3.9-0.3,4.8-3.5,6.3-6.2C673.2,705.2,673.7,704.7,674.5,705.1z"/>
	<path class="st4010" d="M673.6,706c0.4,3.3-3.7,4.7-3.2,8.3c-1-1.6-2-2.7-3.8-2.3c-0.7-0.7-1.3-1.3-2-2c-0.1-0.7-1.1-1.5,0.2-2.1
		c0.9-0.5,2-0.5,2.9-0.9c1.6-0.5,3.1-1.5,4.8-1.6C672.9,705.5,673.3,705.7,673.6,706z"/>
	<path class="st4011" d="M687.6,680.1c0.1-0.3,0.1-0.6,0-0.9c0.9-2.3,1.6-4.6,2.4-6.9c0.4-1.1,0.8-1.8,2-1c3,4.3,1.8,9.1,1.5,13.8
		c-4.3,5.3-6.3,12-11,17c-0.9-5.1,3.7-9,2.9-14.1C684.2,684.8,687.1,682.7,687.6,680.1z"/>
	<path class="st4012" d="M687.6,680.1c-0.7,2.6-1.4,5.3-2.1,7.9c-0.6,0.7-1.3,1.4-1.9,2.1C684,686.4,685,682.9,687.6,680.1z"/>
	<path class="st4012" d="M680.6,697.1c-0.1-2.8,1.1-5.1,3-7C683.5,692.8,682.4,695.1,680.6,697.1z"/>
	<path class="st3793" d="M677.6,705.1c-0.2-2.3-0.2-4.6,2.1-6C679.6,701.3,679.3,703.4,677.6,705.1z"/>
	<path class="st4013" d="M569.7,541.2c-3.3,1-6.7,2-10,3c0-0.7,0-1.3,0-2c3.1-3.5,8.1-2.7,11.8-5c2.1-1.3,5-1.3,6.2-4
		c8.1-2.3,16.2-4.7,24.4-7c2.1-0.6,4.1-1.3,5.6-2.9c0.2-0.2,0.3-0.3,0.5-0.5c2.8-1.1,5.9-1.3,8.6-2.8c1.5,0.1,2,1.2,2.3,2.5
		c-0.4,1.7-1.9,2-3.2,2.6c-4.1,1.7-8.6,2.4-12.4,4.9c-5,1.6-10.1,3.1-15.3,3.7c-5,0.6-8.5,4.1-13,5.7c-1.5,0.5-3,1.4-4.5,1.9
		C570.2,541.2,569.9,541.2,569.7,541.2z"/>
	<path class="st4014" d="M595.6,525.2c0.8-1.8,2.3-2.1,4-2c1.7,0.1,3.2-0.2,4-2c3.7-2.2,7.8-2.5,12-2.2c0.9,1.6,0.3,2.3-1.4,2.7
		c-2.2,0.5-4.5,0.8-6.6,1.5c0,0,0,0,0,0C603.6,523.9,599.6,524.6,595.6,525.2z"/>
	<path class="st3687" d="M615.6,519.3c-4,0.7-8,1.3-12,2c-1.3,0-2.7,0-4,0c-0.7-1.2-2-0.9-3.1-1.1c6.6-1.2,13.3-1.8,20.1-1.9
		c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.4-0.2,0.4C616.4,519.7,616,519.6,615.6,519.3z"/>
	<path class="st4015" d="M545.7,557.2c0.3,0,0.7,0,1,0c1.1-2.6,3.8-3.1,5.8-4.3c4.6-2.9,9.5-5.3,14.6-7.3c2.3,0.3,4.4-2.2,6.7-0.4
		c-0.5,3.8-3.7,4.7-6.4,6.2c-3.5,2.1-7.7,3-10.8,5.8c-1.1,0-2.3-0.2-3,1c-2,0.3-3.8,1.4-5.9,1C547.1,558.5,546.4,557.9,545.7,557.2z
		"/>
	<path class="st4016" d="M559.7,569.2c-0.7-0.3-1.3-0.6-2-1c-0.3-0.3-0.7-0.7-1-1.1c5.9-1.1,8.8-6.4,12.9-9.8
		c3.2-2.6,7-3.6,10.1-6.1c0.1-0.2,0.2-0.4,0.3-0.6c1.1-1,2.7-1.5,3.7-2.7c1.6-2.5,4.4-2.6,6.8-3.7c8.6-4,17.2-7.9,25.4-12.5
		c2.9-1.6,5.9-3.1,9.3-2.9c0.4,0.3,0.7,0.7,0.7,1.2c-0.2,0.8-0.6,1.4-1.1,1.9c-5.5,6-13.2,8.8-20.2,12c-5.5,2.4-9.2,7.5-15.2,8.6
		c-3.9,3.2-8.4,5.4-13.1,7.1c-3.9,1.4-6.9,3.6-9.6,6.7C565,568.3,563,570.5,559.7,569.2z"/>
	<path class="st4017" d="M625.6,529.2c-5,1.2-9.4,3.6-13.8,6c-7.9,4.3-15.7,8.7-24.5,11.1c-1.8,0.5-1.4,3.2-3.6,2.9
		c-1.3,0.7-2.9,0.9-4,2c-1.7,0-3.3,0-5,0c-0.5-2.5,1.5-3.5,2.9-4.7c5.4-2.2,9.7-6.9,15.4-8c6.9-1.3,12.8-4.3,18.7-7.8
		c2.4-1,6.7-1.3,7-2.4c0.9-3.3,2.9-1.7,4.5-2.1c1,0.2,1.9,0.7,2.5,1.6C625.9,528.3,625.9,528.8,625.6,529.2z"/>
	<path class="st4018" d="M577.7,547.2c-1,1.3-2,2.7-3,4c-4.4,1.3-7.2,5.6-12,6c-1.3,0-2-0.7-2-2c4.2-4,10.1-5.2,14.8-8.5
		C576.1,546.2,577,546.6,577.7,547.2z"/>
	<path class="st3805" d="M577.7,547.2c-5.7,2.7-11.3,5.3-17,8c-1.1,1.1-2.1,2.4-4,2c2-3.9,6.1-4.8,9.5-6.6c2.8-1.5,5.3-3.1,7.4-5.4
		c9.8-6.7,21.2-9.7,32-14.1c1.6-0.7,3.5-1.1,5.3-0.4c0.2,0.2,0.4,0.3,0.6,0.5c-3,4.8-8.8,6.8-13,7.3c-7.2,0.8-12,5.5-18.1,7.8
		C579.5,546.6,578.6,546.9,577.7,547.2z"/>
	<path class="st4019" d="M567.7,546.2c-7.1,3.5-14.3,6.8-21,11c-0.1-5.4,5.4-4.2,7.9-6.7c4.2-4.2,11.2-4.1,15.1-9.2c0,0,0,0,0,0
		c4.3-2.3,9.1-3.4,13.2-6.2c2.5-1.6,5.6-1.7,8.5-0.3c1.1,2-0.6,2.1-1.7,2.5C582.3,540.2,575.5,544.4,567.7,546.2z"/>
	<path class="st4020" d="M576.7,584.2c-1.3-1.3-2.7-2.7-4-4c1.9-2.4,3.5-5,5.2-7.5c2.3-3.3,5.4-6.3,10-6.8c1.6-0.2,2.9-0.7,3.7-2.1
		c1.5-2.6,3.7-4.2,6.7-4.7c0.8-0.1,1.6-0.6,2.1-1.3c3.9-5.3,9.5-8.3,14.8-11.9c1.9-1.3,1.5-4.5,4.2-5.2c1.5-0.6,3.2-0.5,4.7-1.2
		c0.6-0.3,1.3-0.6,2-0.6c0.5,0.1,0.8,0.4,1.1,0.8c0.3,3.8-1.3,7.2-2.8,10.4c-1,2.2-4,3-6.1,4.4c-3.7,2.5-6.8,5.6-10.3,8.2
		c-3.6,2.6-6.6,5.7-9.9,8.5c-1.1,0.9-2.5,0.7-3.8,0.8c-5.3,0.5-11.1,6.8-11.1,12.2c0,0.4,0.6,0.7,1.1,1c2.1,1.3,4.5,0.6,6.7,1.1
		c0.5,0.1,1,0.2,1.4,0.3c2.4,0.9,5.2-0.3,7.4,1.6c0.2,0.6,0,1.1-0.4,1.5c-1.5,1-3.2,0.9-4.8,0.9c-4.2,0.3-8.2-0.8-12.3-1.4
		C580.2,587.7,577.1,587.5,576.7,584.2z"/>
	<path class="st763" d="M619.6,541.2c-1.3,4.6-5.5,6.2-8.7,9c-3.3,3-6.9,6.9-10.2,8.3c-5.6,2.4-8.1,8.9-15.2,9.3
		c-3,0.2-7.5,3.6-7.9,8.5c-0.3,3.3-3.8,2-5,3.9c-2-1.3-4-2.7-6-4c0-0.3,0-0.7,0-1c0.9-2.6,2.3-4.8,5.4-5.1c2.9-0.3,4.5-3,6.8-4.4
		c6.6-3.8,14.2-6.1,19.4-12.2c0.2-0.3,0.5-0.5,0.8-0.6c6.6-2,11.1-7,16.1-11.2C616.5,540.6,618,539.8,619.6,541.2z"/>
	<path class="st4021" d="M561.9,614.1c1.1-1.3,2.6-2.2,3-4c2.6-1.6,3.9-4.4,5.8-6.7c1.1-1.3,2.1-3.9,4.5-1.5c-0.5,2.4-1.3,4.6-1.8,7
		c-0.3,0.8-0.7,1.6-1.1,2.4c-0.9,1.5-1.4,3.2-2.1,4.9c-0.5,1.1-1.2,2-2.4,2.4c-0.5,0-0.9-0.1-1.3-0.3c-0.6-1.1-0.1-3.7-2.8-1.8
		c-0.8,0.5-1.8-0.4-2.3-1.2C561.3,614.7,561.5,614.4,561.9,614.1z"/>
	<path class="st4022" d="M576.7,584.2c1.3,1.8,4.3,1.7,5.1,4.2c0.5,0.6,0.5,1.2,0.2,1.9c-0.7,1.5-2,2.3-3.1,3.3
		c-0.3,0.3-0.7,0.6-1,0.8c-0.4,0.1-0.8,0-1.2-0.3c-0.3-2.3-0.7-4.6-1-6.8c-0.2-1.3-0.5-2.2-2.2-1.8c-0.8,0.2-0.2,2.3-1.7,1.2
		c-0.3-0.2-0.4-0.9-0.3-1.4c0.1-1.6,1.4-1,2.2-1.1C574.7,584.1,575.7,584.2,576.7,584.2z"/>
	<path class="st4023" d="M561.9,614.1c0,0.4,0,0.8,0,1.2c0.3,1.5-0.7,2.5-1.6,3.4c-5.1,5.2-10.2,10.4-15.5,15.4
		c-1.4,1.3-2.8,3-5.1,1.8c0-0.6,0-1.1,0-1.7c7.3-4.3,12-11.3,18.1-16.8C559.1,616.2,559.7,614.2,561.9,614.1z"/>
	<path class="st4024" d="M574.8,602.1c-2.9-0.5-3.5,2.2-4.9,3.7c-1.5,1.6-2.4,4-5,4.3c1.7-2.3,3.3-4.6,5-6.8c0-1.7,1.3-2.5,2.6-3.2
		c0.5-0.5,0.9-1,1.4-1.5c0.4-1.4,0.9-2.8,2.8-2.7c2.8,2.3,0.6,4.3-0.6,6.2C575.7,602.4,575.2,602.4,574.8,602.1z"/>
	<path class="st4025" d="M576.7,596.2c-1,0.8-1.9,1.6-2.9,2.4c-0.2-2.3-0.6-4.6,2.9-4.5c0.3,0,0.5,0,0.8,0c0.4,0.2,0.5,0.5,0.5,1
		C577.7,595.7,577.3,596.1,576.7,596.2z"/>
	<path class="st4025" d="M572.5,600.1c-0.4,1.4-1.4,2.3-2.6,3.2c-1.2-0.6-1.1-1.5-0.4-2.4C570.3,599.8,571.2,599.3,572.5,600.1z"/>
	<path class="st4026" d="M619.6,541.2c-3.8-0.5-6,2.7-7.7,4.7c-2.7,3.3-6.4,4.4-9.2,6.8c-6.3,5.3-13.7,8.9-20.8,12.8
		c-3.7,2-6.2,6.3-11.2,6c-1.8-0.1-1.9,3.2-4,3.6c-0.7-0.7-1.4-1.3-2-2c3.1-8.4,11-11.4,18-15c2.9-1.5,5.4-3.5,8.4-4.8
		c4.1-4.1,10.2-4.9,14.3-9.2c2.7-2.9,7-3.3,10.3-5.6c3.2-2.3,6.4-4.4,9-7.4c0.3-0.3,0.7-0.7,1-1c0.8,0.2,1.3,0.6,1.6,1.4
		c-0.4,2.5,1.1,5.2-0.6,7.6c-0.3,0-0.6,0-1,0c-1.2-0.6-1,0.3-1,1C623.2,541.9,621.3,541,619.6,541.2z"/>
	<path class="st4027" d="M591.7,554.2c-6,5.7-14.7,6.7-20.7,12.6c-1.4,1.3-3.2,1.9-3.4,4.2c-0.1,1.6-1.4,2.1-2.9,2.2
		c-1.9-1-4-1.9-5-4c2.5-1,4.7-2.2,6.6-4.5c2.1-2.5,4.6-4.9,8.5-6c4.9-1.4,9.9-3.7,13.8-7.5C589.6,552.2,592,551.9,591.7,554.2z"/>
	<path class="st4028" d="M508.7,653.1c5.2-1.4,8.1-6.2,12.9-8.3c1.5-0.6,2-2.6,0.9-4.3c-1.2-1.8,0-2.4,1.3-2.9
		c1.5-0.7,2.1-0.1,2.9,1.5c2.1,4,4.1,4,6.9,0.1c2.5-1.1,4.2,0.5,6.3,1.6c-3.1,2.6-7.6,3.9-8.1,8.8c0,0.4-0.5,1.1-0.9,1.1
		c-6.7,0.5-11.7,5.8-18.3,6.5C511,656.1,511.4,653.1,508.7,653.1z"/>
	<path class="st4029" d="M539.7,635.9c7.2-3.2,11.1-10.1,16.8-14.9c2-1.7,3.6-3.8,5.4-5.7c0.6,0.2,1.2,0.4,1.8,0.7
		c0.4-1.7,1.6-3.1,3.2-2c1.9,1.2-0.6,2.8-0.2,4.3c0.9,2.6-1.1,3.9-2.4,5.5c-0.5,0.6-1.1,1.1-1.4,1.9c-1.4,2.8-2.3,1.7-3.1-0.4
		c-4.2,2.2-6.7,6.5-10.9,8.7c-0.3,0.2-0.5,1.2-0.4,1.7c1,5.7-1.8,5.2-5.4,3.4c-1.7-0.9-3.9-0.6-5.4-2
		C538.3,636.6,539,636.2,539.7,635.9z"/>
	<path class="st4030" d="M539.7,660.1c0.3-1.3,0.7-2.7,1-4c-0.3-3.6,1.2-7.1,3.6-8.7c3.7-2.5,5-5.9,5.9-9.9c2,1,1.1,2.5,1.4,3.6
		c1.9,2.2-0.4,4-0.6,6c-0.1,0.9-0.2,1.9-0.4,2.8c-0.1,0.5-0.4,0.9-0.7,1.3c-0.4,0.3-0.9,0.3-1.3,0c-1.4,0.6-2.3,1.6-3,3
		c-2.5,0.3-2,2.3-2,3.8C543.8,661.4,542.2,661.4,539.7,660.1z"/>
	<path class="st4031" d="M549.7,647.1c0-2.2,2.6-3.6,2-6c1.6-3,2.5-6.3,6.1-8.3c0.7-0.4,1.7-4.9,1.9-7.7c0.7,0.2,1.9,2.7,2.1-0.4
		c0.1-1.2,0.9-1.5,1.9-1.6c1,0.9,1.1,2.2,0.5,3.2c-4,7.1-6.2,15.3-12.3,21.2C551.1,647.7,550.3,647.7,549.7,647.1z"/>
	<path class="st3822" d="M521.7,619.1c-2.7-0.3-1.8-2.4-2-4C521.5,615.9,521.8,617.4,521.7,619.1z"/>
	<path class="st3588" d="M447.8,605.1c-0.9-3.6,3.5-4.2,4-7c4.8-2,8.5-6.4,14-7C459.5,595.5,454.9,601.9,447.8,605.1z"/>
	<path class="st3930" d="M573.7,679.1c-2.1,0.1-2.4,1.9-2.5,3.2c-0.4,7.7-6.7,9-12.1,10.6c-4.1,1.2-8.6,0.6-11.6,4.9
		c-0.8,1.2-2.9,2.7-5.2,1.4c-0.9-0.5-2.4-0.5-3.4-0.1c-7.6,3.3-15.2,0.9-22.7,0.1c-2-0.2-2.9,1.2-4.4,1.8c0.5-4-2.7-5.4-4.7-3.7
		c-4.3,3.7-9,1.6-13,1.3c-9.5-0.9-18.9-3.6-28.3-5.6c1.3-1.4,2.9-1.5,4.6-1.5c7.8,0,15.6-0.4,23.4,0.5c4.9,2.5,9.3-0.9,14-1
		c3.2-1.6,6.5-2.2,10-1.6c3.3,0.5,6.6,0.3,9.9-0.3c3.3-0.6,6.6-0.7,9.5,1.4c1.3,0.9,3.1,1.4,4.2,0.4c3.2-2.8,7.2-3.1,10.9-4.3
		c4.5-1.4,6-5.3,6.9-9.5c2.3-2.2,6.1-0.8,8.3-3.4C571.7,673,573.3,675.3,573.7,679.1z"/>
	<path class="st4032" d="M559.7,677.1c0,8-3,11.3-11.1,11.9c-1.9,0.1-3.4,0.3-4.5,2.2c-2.1,3.2-6,2.7-7.7,0.9
		c-2-2.3-4.1-2.4-6.1-1.9c-7.5,2-15.1-0.4-22.5,0.9c2.6-2.1,5.3-3.5,8.8-4.6c5.4-1.6,11.2,0,16.6-4.2c2.7-2.1,7-4.9,11.5-5.4
		c4.4-0.4,7.4-3.2,8.1-7.9C556.2,670.7,560.2,671.9,559.7,677.1z"/>
	<path class="st4033" d="M493.8,692c-4.8,2-9.8,0.7-14.6,1c-4.4,0.2-8.9,0-13.3,0c-3.5-1.4-6.9-2.7-10.4-4.1
		c-2.1-0.9-1.9-5.2-5.6-3.9c-2.3-2.5-4.6-4.9-5.5-8.5c-1-4-5.2-6.1-6.5-10.4c-0.7-2.1,0-4.2-1-6.1c1.7,0.1,2.4,1.1,3.1,2.6
		c1.7,3.7,3.9,7.3,5.7,11c1.5,3.2,4.8,2.2,7.2,3.3c1.1,1.4,2.6,1.9,4.3,2c3.5,0.1,6.9,0.2,6.2,5.3c0,0.3,0.6,1.1,0.7,1.1
		c4.5-1.3,7.8,2.4,11.8,3.3C481.8,690,488.5,688,493.8,692z"/>
	<path class="st4034" d="M559.7,677.1c-0.8-4-5-5-7-8c0.1-1.4,0.1-3-1-3.8c-5.5-3.9-4.1-9-3-14.2c0.3,0,0.7,0,1,0
		c2.3,2.2,0.3,5.7,2.4,7.9c1.2,1.7,2.7,3.1,4.2,4.5c3.6,3.7,8.6,6,11.3,10.5C566.8,679.9,562.3,675.8,559.7,677.1z"/>
	<path class="st3588" d="M443.8,610.1c-0.4-2.4,1.2-3.3,3-4C446.7,608.2,445.4,609.3,443.8,610.1z"/>
	<path class="st3810" d="M457.8,662.1c1.4,0,3.2-1.4,4,1C460.5,662.6,458.4,665.5,457.8,662.1z"/>
	<path class="st4028" d="M495.8,657.1c0-3.3,1.2-4.6,4.6-3.3c1,0.4,2.3,0.2,3.4,0.3C501.1,655.1,498.7,656.9,495.8,657.1z"/>
	<path class="st4035" d="M639.5,803.7c-0.9,0.4-1.9,0.8-2.8,1.2c-0.7,0-1.4,0-2.1-0.1c0.1-0.7,1.1-1.4-0.1-2.1
		c0.7-0.6,1.4-1.3,2.1-1.9c1.7-0.3,3.3-0.7,5-1C641,801.3,638.1,801.3,639.5,803.7z"/>
	<path class="st4036" d="M643.6,796c0-0.2,0.1-0.5,0.1-0.7c2.1-1.5,4.1-3.2,7-2.5c0.5,0.8,0.4,1.5-0.2,2.2c-2.3,0.7-4.6,1.4-6.9,2.1
		C643.6,796.7,643.6,796.3,643.6,796z"/>
	<path class="st4037" d="M565.4,861.8c-0.2,0.9-1,1-1.7,1.1c-2.1-1.9-0.6-3,0.9-4.2C565.2,859.7,563,861.4,565.4,861.8z"/>
	<path class="st4038" d="M579.7,843.8c-0.1-2.1-1.8-1.9-3.2-2c1.1-1,2.3-2.2,3.7-0.8C581.1,841.8,580.9,843,579.7,843.8z"/>
	<path class="st4039" d="M724.5,632.1c-1.1,6.4-5.9,10.7-9,16c-4-1.7-6.6-7.1-5.1-11.1c1.3-3.5,1.2-6.4-2.8-7.9
		c2.7-5.7,3.8-11.9,5-18c1.7-1.4,2-3.3,2-5.3c0-2.2,1.1-2.9,3.5-2.3c-0.3,1.9-3.9,2.7-1.9,5.1c1.8,2,5.7,3.4,6.8,2.8
		c7.2-3.5,3.7,2.7,4.6,4.7c0.6,2,1.2,4,0,6C729,626.2,726.4,629.1,724.5,632.1z"/>
	<path class="st3750" d="M727.5,622.1c-0.3-2-1.6-4,0-6c1.6,0.2,3.8-0.6,4,2.2C731.6,621.2,729.7,621.7,727.5,622.1z"/>
	<path class="st4006" d="M732.5,622.1c0.1,1.7-0.2,3.2-2,4C730.6,624.5,731.2,623.1,732.5,622.1z"/>
	<path class="st4040" d="M525.7,177.7c-0.6,0.4-1.2,0.7-1.8,1.1c-0.7-0.4-0.8-0.9-0.2-1.5C524.5,176.6,525.2,176.8,525.7,177.7z"/>
	<path class="st4041" d="M521.8,182.9c-0.2,1.2-0.9,2.4-0.1,3.7c-1,3.6-3.5,5-7,5c-0.3-0.9-0.6-1.9-0.9-2.8c2-1.7,4-3.3,6-5
		C520.4,183.5,521.1,183.2,521.8,182.9z"/>
	<path class="st3626" d="M650.5,794.9c0-0.7,0-1.4,0-2.1c-0.3-0.3-0.2-0.6,0.2-0.8c1.3,0,2.6,0,3.8,0
		C654.4,794.5,651.8,793.8,650.5,794.9z"/>
	<path class="st4042" d="M650.7,792c-0.1,0.3-0.2,0.5-0.2,0.8c-2.3,0.8-4.6,1.6-6.9,2.5c0-0.4-0.1-0.8-0.1-1.2
		c0.3-0.4,0.7-0.8,1-1.1c0.6-0.2,1.3-0.5,1.9-0.7c1.4-0.4,2.7-0.9,4.1-1.3C650.7,791.3,650.7,791.6,650.7,792z"/>
	<path class="st4043" d="M664.6,781.9c0-0.3,0-0.6,0-0.9c0.3,0,0.6,0,1,0c1-0.6,2-0.7,3,0c0,0.3,0,0.6,0,0.9c-1.7,0.8-3.3,1.6-5,2.4
		C663.9,783.5,664.2,782.7,664.6,781.9z"/>
	<path class="st4044" d="M668.6,780.9c-1,0-2,0-3,0c1.7-1.5,3.8-2.5,6-3c0,0.3,0,0.6,0,0.9C670.5,779.5,669.5,780.2,668.6,780.9z"/>
	<path class="st4045" d="M575.7,493.3c-3.2,1.7-6.4,3.4-9.7,5.1c-0.4-0.4-0.9-0.8-1.3-1.2c0-0.3,0-0.7,0-1c1.2-1.7,3.6-1.6,5.1-3.6
		c-1.9-1.2-4.4,0.4-6-1.1c0.3-2.4-2.1-4.7-0.4-7.2c2.3-1.7,4.4,0.6,6.6,0.4c3.5,1.5,5.6,4.2,7.1,7.6
		C576.9,493.1,576.7,493.7,575.7,493.3z"/>
	<path class="st4046" d="M545.7,485.3c-2.5-1.8-5.2-3.2-6.2-6.6c-0.7-2.3-3.1-1.1-4.8-1.4c0.9-3.4-2-5.3-3-8c-0.4-2.1,0.7-4,1-6
		c3.6,0.2,6,2.6,8.1,5c1.8,2.1,4.1,3.9,5.6,6.3c0.6,2.6,1.1,5.1,3.6,6.8c1.2,0.8,1.9,2.4,0.7,3.9C549,485.3,547.4,485.3,545.7,485.3
		z"/>
	<path class="st4047" d="M545.7,474.3c-5-2.8-7.6-8.6-13-11c-1.6-3.8-0.8-8.1-2-12c0.7-0.7,1.3-1.3,2-2c3.6,0.9,5.8,3.1,6.8,6.9
		c1,4.1,3.9,7.1,7,9.9C547.4,469,547.9,471.8,545.7,474.3z"/>
	<path class="st4048" d="M545.7,466.3c-3.7-0.9-5.2-4.2-6.3-7.1c-1.6-3.8-3.2-7.4-6.6-9.9c0-1.3,0-2.7,0-4c2.2-1.1,4.2,1,6.3,0.3
		c4.6,2.1,8.1,5,8.1,10.7c0,3.7,4.5,4.2,5.7,7.1c1.4,3.7,0.9-2.1,1.9-0.4c0.5,0,1,0.2,1.3,0.6c0.7,3,2.6,6.3-2,8.1
		C551.6,469.6,549,467.4,545.7,466.3z"/>
	<path class="st4049" d="M538.7,446.3c-2.3,1.3-4-0.7-6-1c-1.3-4-2.7-8-4-12c0.8-0.4,1-1.2,1-2c1.9,0.9,3.7,2.3,6,2
		c1.8,1.8,3.7,3.4,4.7,5.8C540.4,441.7,539.2,443.9,538.7,446.3z"/>
	<path class="st4050" d="M545.7,466.3c4.1-0.6,7.3,0.2,8,5c1.3,1.2,3.4,0.5,4.5,2.1c1.1,2.5,2.6,4.8,4.6,6.7
		c1.3,1.2,2.3,2.4,0.9,4.1c-1.2,2.3,2.2,4.7,0,7c0.5,1.1-0.1,1.6-1,2c-3.7-0.2-6.6-1.5-7.8-5.4c-0.6-2-2.2-2.7-4.2-2.5
		c-0.4-1-0.6-2.8-1.2-3c-4.8-1.1-3.7-4.8-3.8-8C545.7,471.6,545.7,469,545.7,466.3z"/>
	<path class="st4051" d="M562.7,493.3c0.3-0.7,0.7-1.3,1-2c2.8,0.4,5.8-1.8,8.7,0.9c-2.1,2.3-4.3,4.1-7.7,4.1
		C563.4,495.7,562.7,494.7,562.7,493.3z"/>
	<path class="st4052" d="M471.6,231.4c-2.2,2.7-4.4,5.5-6.6,8.2c-0.4-0.4-0.8-0.8-1.2-1.2c0.3-3.7,5.8-4.5,5.1-8.9
		c3.2-4.1,6.8-7.8,11-11c-1.3,4.2-5.6,6.2-7.6,10C471.7,229.5,470.3,230.2,471.6,231.4z"/>
	<path class="st4053" d="M497.7,205.5c-0.3-0.3-0.7-0.6-1-1c1.4-1.3,2.7-2.6,4.1-3.9c2.7-0.2,3.6-3.1,5.9-4.1c0.4,0,0.8,0.1,1.3,0.1
		c0.3,1.6-0.9,2.5-1.8,3.6c-2.3,1.9-4.6,3.9-7.1,5.5C498.6,206,498.1,205.8,497.7,205.5z"/>
	<path class="st4054" d="M505.7,199.6c0.8-1,1.5-1.9,2.3-2.9c2.8-1,4.5-3.4,6.7-5.2c2.4-1.6,5.1-2.7,7-5c1.1,0,2.1,0,3.2,0
		c0.4,0.7,0.5,1.3,0.2,2.1c-0.3,0.4-0.7,0.7-1.1,0.9c-5.7,3.1-10.3,7.9-16.3,10.6C506.9,200.3,506.2,200.2,505.7,199.6z"/>
	<path class="st4055" d="M481.8,223.6c-2.9,0-3.7,3.4-6.3,3.9c2.1-4.4,6.7-6.8,9.4-10.7c0.3-0.1,0.6-0.1,0.9-0.1
		C488.4,221.2,483,221.2,481.8,223.6L481.8,223.6z"/>
	<path class="st4056" d="M497.7,205.5c0.3,0,0.6,0,1,0c2.6-1.6,5-3.6,7.9-4.7c0.8-0.1,1.5,0,2.1,0.5c1,2.4-1.3,3.4-2.3,4.9
		c-3.5,4.1-6.9,8.4-11.9,10.8c-0.5,0.2-0.9,0.3-1.4,0.3c-2.1-0.4-3.8-1.2-3.4-3.9c1.4-1.6,2.9-3.1,4.3-4.7
		C495.3,207.6,496.5,206.6,497.7,205.5z"/>
	<path class="st4057" d="M489.7,213.4c0.9,1.7,2,3.1,4.1,3.2c-0.8,3.8-4,5.4-7,7c-1.7-1.1-3.3-1.1-5,0c0.5-2.8,4.9-3.4,4-6.9
		C486.8,215.2,487.9,213.9,489.7,213.4z"/>
	<path class="st4058" d="M585.7,334.5c0.3,0,0.6,0,1,0c-3,4.7-6,9.3-9,14c-3.7,2-5.8,1.5-7-1.7c-2.5-6.6-6.4-12.5-9.6-18.8
		c-2.7-3.3-4.2-7.4-6.3-11.1c-0.2-5-1.6-9.9-1.8-14.9c1.9-2.2,4.1-2,5.2,0.3c2.1,4.4,5,8.1,7.8,12c1.7,2.4,3,5.4,4,8.2
		c1.2,3.4,3.2,6,5,8.9C578.5,336.6,579.6,336.9,585.7,334.5z"/>
	<path class="st4059" d="M571.7,360.4c0,0.8-0.2,1.6-1,2c-4.8,0.5-7.2-3.1-9.5-6.2c-2.2-3-4.5-5.6-7.8-7.2c-1.3-4.3-3-8.4-4.8-12.4
		c-0.7-2.6-3.7-5.3,0.2-7.9c1.9-0.3,3-2.3,5.1-2.4c0.7,0.3,1.2,0.8,1.5,1.4c2.2,3.2,3.4,7,6.4,9.7c1.2,1.1,1.2,2.7,1.6,4.1
		c1.5,4.7,2.7,9.6,7.1,12.7c1.6,1.2,1.2,3.3,1.5,5C572.1,359.6,572,360,571.7,360.4z"/>
	<path class="st4060" d="M571.7,359.4c-1.4-0.9-0.9-2.4-1.3-3.6c-0.9-2.7-2-0.7-3.2,0c-1-6-5.4-10.7-5.7-16.9
		c-4.7-0.3-2.2-6.7-6.5-7.7c-2.3-0.6,0-2.5-0.4-3.8c2.3-6,4.7-1.1,7,0c1.5,0,1.9,1,2.2,2.3c1.3,6.4,7.5,10.5,8.2,17.4
		c0.2,2,3.4,1.9,5.6,1.3c0,0.8-0.2,1.6-1,2C573.3,352.5,573,356.3,571.7,359.4z"/>
	<path class="st4061" d="M567.7,370.7c0,0.9-0.1,1.7-0.1,2.6c-0.7,0.8-1.5,1.4-1.7,2.5c-0.9,1.2-0.3,3.1-1.6,4.1
		c-3.4,4.2-3.4,4.2-6.9-0.2c-1.4-1.8-3.1-3.3-4.7-5c-2.3-3.6-2.9-8.3-7.3-10.5c-0.8-0.4-0.5-1.8-0.2-2.8c1.5-2.4,0.8-5.3,2.1-7.8
		c2.1-1.6,2.9,0.1,3.7,1.5c1.2,2.2,3.1,3.8,4.9,5.5c3.3,2.9,6.6,5.8,9.4,9.6c-0.1-2.8,0.3-4.9,3.4-4.8
		C568.4,367.2,568.1,368.9,567.7,370.7z"/>
	<path class="st4062" d="M568.7,365.4c-2.9,5.1-5.6,5.8-8.9,0.2c-0.9-1.5-3.1-2.4-4-4.2c0.3-2-1.5-3.2-2.1-4.7
		c-1-2.7-3.3-5.6,0.1-8.2c3.1,0.5,5.5,2,6.9,4.9c2.2,4.3,5.8,7,10,9C570,363.4,569.3,364.4,568.7,365.4z"/>
	<path class="st4063" d="M571.7,359.4c0.4-2.6,1.1-5.2-0.7-7.6c-0.3-0.4-0.1-1.4,0.3-1.8c0.5-0.5,1.5-0.2,1.7,0.4
		c1.3,4.1,2.5,0.1,3.7,0c-0.8,3.7-3.6,6.5-5,10C571.7,360.1,571.7,359.8,571.7,359.4z"/>
	<path class="st3896" d="M605.2,304.2c-0.1-0.7-0.3-1.2-0.1-1.4c0.4-0.4,1-0.6,1.5-0.9c0.1,0.4,0.5,0.9,0.3,1.1
		C606.6,303.4,606,303.7,605.2,304.2z"/>
	<path class="st4064" d="M534.7,191.6c2.2,1.5,3.3,3.4,3.1,6.2c-0.3,3.6,0.3,7.3-0.1,10.9c-0.8,6.4,1.9,12.2,2.7,18.3
		c0.4,3.3,1.8,8.6,6,11c0.9,0.5,1.4,1.5,1.3,2.5c-0.5,5.8,0.5,11.4,2,16.9c2.6,2.8,4.3,6.3,6.4,9.4c3.1,4.7,6.5,9.1,10.5,13.2
		c3.6,3.6,6.1,8,3.1,13.3c-4,1.8-1.1,5.4-2,8c-2.3,1.5-5.2,0.9-4.5-1.6c1.3-5.5-2.9-8.1-5.2-11.4c-2.2-3.1-4.1-6.7-7.4-9
		c-4.5-3.4-6.6-8.5-7.8-13.5c-3-12.4-7.6-24.3-10.8-36.5c-2.2-8.6-5.7-17.7-3.4-27C529.6,198.5,529.9,193.7,534.7,191.6z"/>
	<path class="st4065" d="M551.7,278.5c3,3.9,6,7.8,9.1,11.6c0.9,1.1,0.7,3,2.6,3.3c2.7,0.5,2.6,2,1.4,3.9c-2.5,3.8,0,4.1,2.9,4.1
		c-0.3,3.7,4.1,4.6,4.6,7.9c0.1,0.9,1.2,1.8,1.2,2.6c-0.3,8.9,1.2,12.1,9.6,15.6c4.1,1.7,1,4.9,2.6,6.9c-3.7,4.6-7.5,4.6-10.4-0.5
		c-2.1-3.7-4.7-6.8-6.4-10.9c-2.4-5.6-5.7-11.6-10.1-16.5c-1.6-1.8-0.5-6.2-5.1-5c-2-2.3-1.3-5.1-1.5-7.7c-0.2-3.3-2.2-6.3-2-9.7
		C550.4,282.1,550.1,280.1,551.7,278.5z"/>
	<path class="st3043" d="M575.7,273.5c1,0,2,0,3,0c0.2,1,0,2.1,0.5,2.9c2,3.2,1.3,5.5-4,7.5c7.2,0.9,8.6,6.2,12.1,9.2
		c0.5,0.4,1,1.3,0.8,1.7c-2,6.3,1,10.5,5.1,15c1.7,1.9,0.5,5.4-1.6,7.7c-1.7,0.3-2.1,3.6-3.6,2.7c-1.3-0.8-2.7-3.8-2.1-4.8
		c2.3-4.2-1.6-5.4-3.2-7.6c-0.7-1-1.5-2.2-3.1-2.2c-3.9-10.1-7.9-20.2-12.5-30c-0.9-1.9-0.9-3.8-0.3-5.8c3-3.8,5-0.1,7.2,1
		C574.9,271.2,574.8,272.8,575.7,273.5z"/>
	<path class="st4066" d="M567.7,269.5c-0.1,3.1-0.2,6.1,3,8.3c2.4,1.7,1.1,5.1,2.1,7.7c2.4,6.6,7.9,12.2,6.9,19.9
		c-2.2-3.2-6.8-4.9-6.2-10.1c0.3-2.4-2.3-1.7-3.8-1.9c1-7.3-2.5-12.3-8.2-16.2c-2.3-1.6-2.9-4.3-4.2-6.5c-2.7-4.3-7.1-7.7-7.6-13.3
		c2.5-1.2,1.8-3.5,2-5.6c0.2-1.9-0.5-3.9,0.7-5.9c2,0.4,0.8,3.8,3.3,3.5c1.4,3.7,3.4,6.9,6.6,9.4c1.9,1.6,3.3,3.8,3.1,6.4
		C565.2,267.4,566.5,268.4,567.7,269.5z"/>
	<path class="st3894" d="M587.7,251.6c-1.5-1.9-5-2.4-4-6C588.9,244.9,587.4,248.8,587.7,251.6z"/>
	<path class="st4067" d="M539.8,179.7c-2.1,1.9-4.1,3.9-6.2,5.8c-0.5,1.2-1.1,2.3-2.6,2.5c-1.3,0-2.5,0.3-3.8,0
		c-0.6-0.4-1-0.7-0.6-1.5c0.3-0.3,0.6-0.6,0.9-1c3.3,0.2,5.3-2.1,7.5-4C536.5,180.4,537.8,179.3,539.8,179.7z"/>
	<path class="st3900" d="M526.7,186.6c0.3,0.4,0.7,0.7,1,1.1c0.4,1.1-0.2,1.8-1.1,2.1c-0.9,0.3-1.4-0.3-1.8-1.1c0-0.7,0.1-1.4,0.1-2
		C525.5,186.6,526.1,186.6,526.7,186.6z"/>
	<path class="st4068" d="M567.7,269.5c-2.7,0.1-4.6-0.1-3.6-3.8c1-3.8-2-5.9-4.4-7.5c-3.2-2.3-4.5-4.9-4-8.7c0-2.3,0-4.6,0-7
		c0-2.2,0.6-4.8-2.8-5.3c-1.2-0.2-1.1-1.5-1.1-2.5c0-2.5,0.4-5.1-3.2-5.7c-0.4-0.1-0.9-1.1-0.8-1.7c1.2-6.4-2.6-12.1-2.2-18.4
		c0.4-7.3-2.4-14.4-2.3-21.8c0.1-5.4-0.1-6,5.5-4.6c0.1,0.7,0.5,1.5,0.3,1.9c-5.2,9,1.1,17,1.9,25.4c0.6,6,4.9,10.7,4.9,16.7
		c0,1-0.1,2.2,1.1,2.5c3.2,0.9,3.6,2.4,1.5,4.9c-0.8,1-0.9,2.6,0.3,3c4.6,1.4,3.9,6.5,6.6,9.1c0.3,0.3,0.5,1.1,0.3,1.4
		c-4.2,6.2,3.1,7,4.8,10.4c1.2,2.5,5.3,4.2,2.1,7.9c-1.3,1.5,0,2.5,1.3,3c2.2,0.9,2.2,2.7,2.1,4.6
		C572.8,272.7,572,267.5,567.7,269.5z"/>
	<path class="st4069" d="M530.7,187.5c1-0.7,2-1.3,3-2c2,1.3,1.2,2.7,0,4.1c0.2,0.8,1.7,0.8,1,2c0,0,0,0,0,0
		c-2,3.7-4.8,7.3-4.9,11.7c-0.2,6.5-1.4,13.3,3.4,18.9c0.3,0.3,0.5,1.1,0.3,1.4c-2.9,6,2.5,10,3.3,15c1,6,4.1,11.2,5,17.3
		c1.3,8.3,2.9,16.7,9.8,22.6c-0.7,5.2,0.8,10.1,2,15c-1.4,1.1-3.1,1.1-4.1,0c-3.1-3.4-7-6.3-7.9-11.1c-0.7-3.5-1.3-6.8-4-9.4
		c-1.4-1.4-1.3-3.4-1.3-5.2c0-3.1-0.9-6-2.7-8.4c-7.5-10-9-21.6-9.3-33.6c-0.1-2.6,0.6-5.3-1.1-7.7c-0.8-1.6-0.6-3.2,0.1-4.8
		c1.5-3,1.1-6.2,1-9.3c-0.1-4.9-0.5-9.6,4-13.1c0.4-0.3,0.3-1.3,0.6-1.9C529.4,188.2,529.9,187.8,530.7,187.5z"/>
	<path class="st4064" d="M534.7,191.6c0.1-0.9-2-0.6-1-2c0.9,0.1,2.1-0.3,2.4,0.8C536.6,191.5,535.4,191.4,534.7,191.6z"/>
	<path class="st4070" d="M627.6,515.2c4-2.4,7.9-5.2,12.7-5.9c2.6,4.8,3.9,9.9,4.2,15.3c0.2,2.8-0.1,5.2-2,7.4
		c-3.3,2.2-6.3,5.2-10.7,5.5c-1.3-1.8-0.4-4.3-2.1-6.1c-1.2-1.2,0.4-2.7,1.6-3.6c2.7-1.9,4.7-4.5,6.4-7.3c0.6-1,0.6-2.2,0.6-3.4
		c0.1-2.5-0.9-3.2-3.3-2.2C632.6,516,630.1,517.4,627.6,515.2z"/>
	<path class="st4071" d="M574.7,514.2c0.9,0.3,2.2,1.2,2.7,0.9c6.4-4.1,13.1,0.2,19.6-0.5c1.1-0.1,2.2,0.4,3.3-0.1
		c1.2-0.4,2.8-0.8,3.7,0.3c1,1.4-1.1,2.2-1.3,3.4c-13,0-26,0-38.9,0c0.4-0.3,0.8-0.8,1.2-0.8C568.4,517.2,572.2,517.7,574.7,514.2z"
		/>
	<path class="st4072" d="M627.6,515.2c1.8-0.1,3.6,0.2,5.3-0.6c1.9-0.9,5-2.7,5.7-1.6c1.4,2.3,2,5.5,0.1,8.3
		c-0.4,0.4-0.8,0.4-1.2,0.1c-0.8-3.1-2.4-3.8-5.3-2.3c-2.9,1.4-5.4,3.9-9.1,3.3c-0.7,1.8,2.2,3.1,0.6,4.9c-1.8,0.1-3.2-0.1-4.7,1.8
		c-1.4,1.6-4.5,2.7-7.3,2.2c0,0,0,0,0,0c-1.3-2.4,0.5-3.2,2.1-3.9c1.7-0.7,3.3-1.3,4.8-2.4c0.7-0.9,0.6-1.7,0.1-2.6
		c-0.6-0.7-1.3-1.4-1.9-2.1c-0.4-0.2-0.5-0.4-0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.4c0.1-0.3,0.1-0.6,0-1
		C620.3,517.3,623.9,516.3,627.6,515.2z"/>
	<path class="st3986" d="M641.3,532.1c3.9-5.2,0.9-10.7,0.6-16c-0.1-2.3-1.1-4.5-1.6-6.7c0.2-0.3,0.4-0.6,0.6-0.9
		c0.6,0,1.1-0.1,1.7-0.1c-0.5,1.4-1.3,2.5,0.1,4c3.4,3.7,2.4,9.1,5,13.2c0.6,0.9-0.6,3-1,4.5C645.1,531.6,643.7,533.1,641.3,532.1z"
		/>
	<path class="st4073" d="M613.6,507.3c0,0.3,0,0.6,0,0.9c-7.3,0.4-14.1,4-21.7,3.2c-5.4-0.5-10.8-0.2-16.2-0.2
		c-3.2-1.7-2.4-2.7,0.5-4.1c6.1-2.8,6.1-3.2,3.5-9.9c0.3-0.3,0.7-0.7,1-1c2.1-1.3,2.9,0.7,4.1,1.5c5,3.1,10.1,6.1,16.2,6.6
		c1,0,1.9,0.1,2.6,0.9C606.7,507.1,610.1,507.3,613.6,507.3z"/>
	<path class="st4074" d="M575.7,493.3c0.4-0.3,0.8-0.5,1.2-0.8c1-0.8,1.3,0.4,1.8,0.7c0,0-0.2,0.1-0.2,0.1c0.2,0.9,0,1.6-1,1.8
		C576.9,494.5,576.3,493.9,575.7,493.3z"/>
	<path class="st4075" d="M600.7,505.3c-6.5,0.2-11.4-3.4-16.3-6.9c-1.2-0.8-2-2.2-3.7-2.2c-0.3-0.6-0.6-1.3-0.8-1.9
		c4,0.3,6.1,4.2,9.8,5c3,0.6,5.8,1.8,8.5,3.1C599.4,503,601.1,503.3,600.7,505.3z"/>
	<path class="st4076" d="M635.6,703c5.8,1.7,11.1,4.7,16.9,6.7c11.2,3.8,11.1,4.2,11.1,16.3c0,1,0,2,0,3c-2.8-2.6-1.9-8.6-5.6-9.2
		c-5.2-0.7-8.9-3.8-13.5-5.3c-2.3-0.7-3.8-1.7-4.8-4.2c-1.3-3.2-5.3-3.4-8-5.3C630.9,700.3,634.3,703.7,635.6,703z"/>
	<path class="st4077" d="M635.6,703c-0.1,1.5-0.7,1.6-1.8,0.8c-1.5-1.1-2-0.2-2.2,1.2c-6.9-1.6-12.2-6.6-18.5-9.4
		c-2.2-0.9-4.1-1.7-6.4-1.6c-3.1-1-4.3-5-8-5c-1.7-1.1-4.8-0.8-4-4.2c5-0.8,8.4,3.4,12.9,4.2c0.4,0.3,0.8,0.6,1,1
		c7.3,4.7,15.3,7.6,23.2,10.8C633.2,701.5,634.6,701.8,635.6,703z"/>
	<path class="st4078" d="M608.6,690.1c-0.3,0-0.6-0.1-0.9-0.1c-12.1-5.5-23.7-11.8-35.5-18c-5.4-2.8-10.6-6-15.7-9.5
		c-4.4-3-6.6-6.8-5.7-12.2c0.1-0.5,0.2-1,0.4-1.4c5.9-7.3,8.9-16.2,13.5-24.2c1.1-1.1,1.9-2.5,2-4.1c0.2-0.9,0.4-1.8,0.9-2.5
		c0.6-0.7,1.2-1.4,1.8-2.1c1.4-1.4,1.3-3.4,2.2-5c0-0.8,0.1-1.5,0.9-2c1.5-1,0.5-4.1,3.3-4.1c0.4,0.3,0.7,0.7,0.9,1.2
		c0.7,1.7,0.3,3.2-0.4,4.8c-5.8,13-10.5,26.5-17.5,38.9c-1.8,2.4,1,3.9,1.2,5.9c-0.3,0.8-0.9,0.9-1.6,1c-0.5,0-0.9-0.2-1.3-0.4
		c-1.2-0.5-1.8-1.6-2.4-2.7c-0.5,1.2,0.4,1.8,0.9,2.5c0.3,0.3,0.6,0.6,0.9,0.9c1,1.2,2.4,1.8,3.7,2.6c2.6,1.7,5,3.7,7.5,5.6
		c10.6,7.4,22.3,12.8,33.8,18.5c11,5.4,22.2,10,33.5,14.7c1.3,0.6,2.4,1.7,3.5,2.6c-8.4-2.5-16.3-6.2-24.2-9.7
		C612.4,690.5,610.6,689.8,608.6,690.1z"/>
	<path class="st4079" d="M486.8,223.6c1.7-3,4.8-4.5,7-7c0,0,0,0,0,0c2-1,3.3-0.8,3.2,1.8c-0.8,1.8-0.9,4.2-3.2,5.1
		c-0.6,0.4-1.2,0.8-1.8,1.1C490.1,225.1,488.3,225,486.8,223.6z"/>
	<path class="st4080" d="M477.6,263.5c0.1-0.7,0.3-1.3,0.4-2c1.5-1.4,1-3.5,1.8-5.1c0.8-3.2,1.9-6.4,3.9-9.1
		c1.2-0.3,2.3-1.7,3.8-0.7c0.8,0.7,1.5,2.2,1,2.5c-2.7,1.5-0.4,3.7-0.9,5.4c-1.7,3.7-1.7,7.8-2.4,11.7c-0.3,1.6-0.1,3.2-0.9,4.7
		c-1.4,1.6-2.2,4.1-5.1,3.1C476.2,270.9,478.2,267,477.6,263.5z"/>
	<path class="st4081" d="M477.6,263.5c0.6,0,1.8-0.5,1.7-0.1c-1.3,3.3-0.1,6.8-0.5,10.1c2.2,1.9,2.1,4,0.4,6.2
		c-2.8,1.7-3.4,4.3-3.1,7.3c-0.3,1.7,0.4,3.8-2.2,4.4c0-4.6,0-9.3,0-13.9C475.6,273,475,267.8,477.6,263.5z"/>
	<path class="st4082" d="M486.8,246.6c-1.1,0-1.6,2.4-3.1,0.8c1.1-2.5,2.2-5,3.3-7.5c2.6-4.1,5.3-8.1,7.9-12.2c1,0.3,1.4,1.1,1.5,2
		c0.2,2-0.7,3.8-1,5.8c-1.7,3.2-3.7,6.2-5.5,9.3C489.1,245.8,488.5,247.1,486.8,246.6z"/>
	<path class="st4081" d="M479.7,256.5c-0.3,1.8,1.2,4.2-1.8,5.1C478.1,259.7,478.6,258,479.7,256.5z"/>
	<path class="st4083" d="M473.9,291.4c1.5-1.4,0.6-3.3,0.8-4.9c3.8,1.1,3.4,4.7,3.6,7.3c0.6,8.3,1.5,16.4,5,24
		c-0.2,1.4-1.1,2.3-2.5,2.6c-1.1-2.6-2.1-5.2-3.2-7.8C476,305.5,473.6,298.7,473.9,291.4z"/>
	<path class="st4084" d="M495.8,229.6c-0.3-0.7-0.7-1.3-1-2c0-0.4-0.1-0.7-0.1-1.1c2-5.3,6.3-9,9.4-13.6c0.6-0.5,1.2-0.8,1.9-0.5
		c2,1.4,3.1,2.8,1,5.2c-1.8,2-0.2,5.3-2.2,7.4c-1.3,1.1-3.3,0.7-4.6,1.9C498,226.7,498.4,230.6,495.8,229.6z"/>
	<path class="st4085" d="M504.7,213.6c-3,4.6-6.2,9.1-10,13c-1.1-0.8-0.9-2-0.9-3.1c-0.2-2,2-2.8,2.1-4.6c2-2.7,3.3-6,6.4-7.6
		C503.7,211.4,504.3,212.4,504.7,213.6z"/>
	<path class="st3921" d="M501.7,366.4c-1.8-1.3-1.7-4.1-3.9-5.1c-2.8-6.3-6.2-12.2-10.1-17.8c0-1,0-2,0-3.1c2.3,0.9,2,3.9,4,5
		c0.9,1.5,2.4,2.5,3.4,4c1.1,2.2,2.6,4.3,3.4,6.6c0.9,2.5,2.3,4.8,3.1,7.4C501.7,364.4,501.7,365.4,501.7,366.4z"/>
	<path class="st4086" d="M487.8,333.4c-0.4,0.4-0.7,0.8-1.1,1.2c-1-1.3-2-2.6-3.1-3.9c0.4-0.4,0.9-0.9,1.3-1.3
		C487.5,329.5,488.2,331.1,487.8,333.4z"/>
	<path class="st4087" d="M494.7,349.5c-2.3-0.3-2.6-2.2-3-4c0.7,0.3,1.3,0.7,2,1c0,0,0,0,0,0C494.2,347.4,495.3,348.2,494.7,349.5z"
		/>
	<path class="st4088" d="M487.8,333.4c-0.8-1.5-1.5-3-2.9-4.1c-0.4-0.6-0.7-1.3-1.1-1.9c-0.4-1-0.7-2-1.1-2.9
		c2.3-1.3,3.2,0.6,4.3,1.8c1.1,1.3,1.7,3.1,3.4,4.1c2.7,2.1,4.6,4.9,6.3,7.8c0.7,2,1.9,3.7,3.1,5.4c1,1.4,2.2,2.9,0.9,4.8
		c-0.7-0.1-1.3-1.1-2,0C494.6,343.8,492.3,337.8,487.8,333.4z"/>
	<path class="st4089" d="M489.8,331.5c-3.4-1.3-3-6.4-7-7c-1.8-0.9-2-2.4-1.9-4.2c0.6-0.6,1.3-1.2,1.9-1.9c0.4-0.3,0.8-0.4,1.3-0.2
		c2.1,3.7,7.1,5.6,6.4,10.9C490.4,330,490.5,330.8,489.8,331.5z"/>
	<path class="st4090" d="M551.7,650.1c-1.4,7.2,3.8,10.4,8.6,13.7c4,2.7,8.2,4.9,12.4,7.3c5.9,3.3,11.4,7.3,17.4,10.3
		c1.8,0.9,3.5,1.8,4.5,3.7c0.7,2,2.8,2.5,4,4c-1.3,0-2.7,0-4,0c-3-4.1-8.7-4.1-12-7.9c-1.5-1.8-4-2.8-6-4.1c-7.8-3.1-14.1-8.5-21-13
		c-1.7-1-3-2.3-4-4c-2.4-3-1.3-6.4-1.4-9.7C550.7,649.8,551.1,649.7,551.7,650.1z"/>
	<path class="st4091" d="M555.7,664.1c7.6,3.4,14.2,8.2,21,13c-0.9,0.4-1.6,1-2,2c-0.3,0-0.7,0-1,0c-2-1.6-2.5-5.1-6-5
		C563.3,671.2,559.3,667.9,555.7,664.1z"/>
	<path class="st3925" d="M577.7,682.1c-1.3-0.6-2-1.6-2-3C577.2,679.5,579.4,679.4,577.7,682.1z"/>
	<path class="st3781" d="M643.6,405.4c0.2-1.6,0.7-3,2-4C645.7,403.1,645.4,404.6,643.6,405.4z"/>
	<path class="st4092" d="M641.6,372.4c-0.1,0.8-0.1,1.5-0.2,2.3c-0.5,0.1-0.9,0.1-1.3-0.2c-2.8-3.5-5-7.7-9.6-9.5
		c-4.7-2.1-9.5-1.3-14.3-0.4c-5.8-1-8.9,3.8-13.3,6c-0.5,0.1-1,0-1.4-0.3c2-4.8,6.3-7.4,10.7-8.8c10.9-3.5,19.3-3.7,27.3,6.9
		C641.1,369.3,640.6,371.2,641.6,372.4L641.6,372.4z"/>
	<path class="st4093" d="M593.8,391.6c-2-5.6,1.4-10.2,2.9-15.2c0.3-0.3,0.7-0.7,1-1c1.2,0.4,2.3,1,3.2,1.9c0.3,0.9-0.1,1.7-0.5,2.5
		c-0.8,1.3-1.5,2.7-2.1,4.1c-1.4,2.3-1.7,5.2-3.2,7.5C594.7,391.6,594.3,391.7,593.8,391.6z"/>
	<path class="st4094" d="M600.5,377.4c-1.4,0-2.8-0.1-2.8-2c0.9-2,1.9-3.9,4-5c0.3,0,0.7,0,1,0c0.2,1.7-0.7,3.4-0.3,5.2
		C602,376.5,601.5,377.3,600.5,377.4z"/>
	<path class="st3999" d="M641.6,372.4c-1.3-1-1.8-2.4-2-4C646,366.9,639.5,371.8,641.6,372.4z"/>
	<path class="st4095" d="M593.8,391.6c0.3-0.1,0.5-0.2,0.8-0.3c1.4-0.2,2.1,1.6,3.5,1.3c0.8,1.5,0.2,2.8-0.4,4.2
		c-1.2,2.2-0.6,4.5-0.3,6.8c-1.3,3.6-0.9,7.2,0.2,10.8c0,3.7,3.7,6.3,2.9,10.2c-6.9-4.5-7.5-11.9-8.6-19.1c-0.2-1.3-0.1-2.6-0.1-4
		C593,398.4,593.3,395,593.8,391.6z"/>
	<path class="st4096" d="M600.5,424.5c-1.6-3.3-4.3-6.1-3.9-10.1c-0.6-1.3-1-2.8,0.2-3.9c0.9-0.8,1.6,0.5,2.2,1.1
		c1.7,4.5,5.3,8,6.6,12.8c0,1.5-1,1.9-2.3,2.1C601.8,426.7,601.2,425.5,600.5,424.5z"/>
	<path class="st4097" d="M634.5,413.3c-0.8,2.7-2.7,4.5-4.7,6.3c-2.4,1.3-4.7,1.9-7,0c-2-1.2-4.2-2-6.1-3.3c-2.6-1.8-2.5-4.1,0-5.9
		c5-3.6,10.5-3.4,15.9,0.7C633.5,411.6,634,412.4,634.5,413.3z"/>
	<path class="st4098" d="M634.5,413.3c-0.6-0.3-1.3-0.6-1.9-1c-4.2-4,0.1-6.5,1.7-9.6c2.9-5.6,4.6-11.5,5.2-17.8
		c0.2-0.8,0.6-1.5,1.5-1.8c2.3,1.3,1.5,3.4,1.4,5.3c-0.3,6.7-2.6,12.9-4.7,19.1C638.1,410.4,635,411.1,634.5,413.3z"/>
	<path class="st4099" d="M637.7,407.7c0.1-5,3-9.3,3.4-14.3c0.3-3.4,1.4-6.7-0.5-10c-1.5-0.6-1.8-1.7-1.7-3.1c0.5-1.9,0-4.1,1.6-5.7
		c0.3,0,0.6,0,0.8,0.1c0,0.3,0,0.5,0.2,0.8C644.7,386.7,643.4,397.4,637.7,407.7z"/>
	<path class="st4100" d="M623.6,419.4c2,1.2,4.1-0.2,6.2,0.3c-2.2,2.9-4.8,5.3-8.2,6.7c-0.3-0.3-0.7-0.7-1-1c-0.4-1.1,0.2-2,1-2.4
		C623.1,422.1,624.8,421.6,623.6,419.4z"/>
	<path class="st4101" d="M605.6,424.4c-3.1-3.5-5.7-7.4-7-12c-1.1-2.5-1.7-5.2-1-7.9c0.1-2.8-1.6-6,1.6-8.2c2.7,1.8,1.8,4.7,1.8,7.2
		c0,4.7,0.7,9.1,3.5,13c1.7,3.1,3.7,5.9,7.4,6.9c2,0.6,3.3,1.7,1.8,4c-0.4,0-0.7,0.1-1.1,0.1C609.7,427.6,607.8,425.6,605.6,424.4z"
		/>
	<path class="st4102" d="M613.6,427.3c0-1.3,0-2.9-1.9-2.9c-4.9,0-6.1-3.9-8.1-7.1c5.3-0.5,6.3-4.4,6.6-8.7c0.3-2.9,0.2-5.9,3-7.9
		c2.2-1.7,4.5-2.6,7.3-2c1.8,0.4,3.5-0.4,5.2-1.1c5-2,5.7-1.7,7.6,3.2c0.3,0.7,0.7,1.1,1.3,1.4c0.3,0.4,0.2,0.8-0.1,1.1
		c-0.8,3-4.6,5.3-2,9c-4.6-3.7-9.3-4.8-14.6-1.2c-0.9,0.6-2.3,0.5-2.3,2.1c0,1.5,1.3,1.5,2.1,2.2c1.8,1.6,5,0.9,5.8,3.9
		c2.9,4-2.5,3.7-3,6C618.3,426,615.9,426.7,613.6,427.3z"/>
	<path class="st3940" d="M675.4,448.3c-1.7,1.3-3.4,2.6-5,3.9c-0.3,0.4-0.7,0.7-1.2,0.9c-1.9,0.4-3.5-0.1-4.6-1.7
		c0,0-0.1-0.2-0.1-0.2c2.3-1.3,4.7-2.6,7-3.9c1.4,0,2.8,0,4.1,0.1C675.6,447.7,675.5,448,675.4,448.3z"/>
	<path class="st4103" d="M669.5,452.3c0.3,0,0.6-0.1,0.9-0.1c4.2,3.6-0.7,4.8-1.8,7c-2.9,0.1-6-0.3-8.6,1.1c-3.3,1.8-4.8-1.8-7.4-2
		c-0.9-1.7-2.3-2.9-3.9-4c-1.2-0.8-0.8-1.5,0.2-2.2c1.2,0.7,2.5,1.3,3.7,2C658.3,454.3,664,454.8,669.5,452.3z"/>
	<path class="st4104" d="M669.5,452.3c-5.3,4.1-11,3.9-17,1.9c0.5-2.2,1.9-1.5,3.4-1.3c3,0.4,6.3,1.8,8.7-1.5
		C666.2,451.7,667.9,452,669.5,452.3z"/>
	<path class="st4105" d="M556.8,422.4c-0.2,9.3-0.5,18.7,0.8,28c-0.5,0.3-0.9,0.3-1.4,0.1c-0.7-0.5-1.1-1.1-1.4-1.9
		c-0.4-2.7-0.8-5.4-0.7-8.1c0.4-5.6,0-11.2,0.3-16.8c0.1-2-0.7-4.5,2.3-5.3C556.7,419.7,556.7,421,556.8,422.4z"/>
	<path class="st4106" d="M556.6,418.4c-1.9,3.9-0.5,8-0.9,12c-0.4,3.7,1,7.5-0.9,11c-1.8-1.5-1.4-3.6-1.5-5.5
		c-0.3-5.8-0.1-11.5,0.2-17.3c0.4-1.7,0.7-3.4,1.7-4.9c0.4-0.5,0.9-0.6,1.5-0.2C556.7,415,556.7,416.7,556.6,418.4z"/>
	<path class="st4107" d="M563.7,379.4c1.6-0.8,0.4-3.1,2-4c0.6,0.7,0.6,1.4,0,2.1c-0.3,1.6-0.7,3.3-1,4.9c-1.7,4.7-3.3,9.3-5,14
		c-0.7-0.5-1-1.2-1.1-2c-0.5-3.3,1.4-6,2.1-9C562.3,383.7,561.9,381,563.7,379.4z"/>
	<path class="st4108" d="M557.7,406.5c0,0.6,0,1.2-0.1,1.9c-0.4,0.7-0.8,1.3-1.8,1.3c-0.5-0.3-0.8-0.7-1-1.2
		c-1.2-4.6,0.2-8.9,1.6-13.1c0.4-1,0.8-2,2.1-2.1c0.4,0.3,0.7,0.8,0.6,1.3C559.6,398.6,557.4,402.4,557.7,406.5z"/>
	<path class="st4109" d="M557.7,406.5c-1.8-4.2,1.5-7.9,1.1-12c0.2-0.5,0.5-0.5,0.9-0.2c0,0.7,0.1,1.4,0.1,2.2
		C559.6,399.9,558.8,403.2,557.7,406.5z"/>
	<path class="st4110" d="M555.7,409.4c0.6-0.4,1.3-0.7,1.9-1.1c0.2,1.8,0,3.5-0.9,5c-0.3,0-0.7,0-1,0
		C554.5,412,554.5,410.7,555.7,409.4z"/>
	<path class="st4111" d="M565.7,377.5c0-0.7,0-1.4,0-2.1c0-1.3,0.7-1.9,1.9-2.2c0,0.4,0.1,0.8,0.1,1.2
		C567,375.4,566.4,376.5,565.7,377.5z"/>
	<path class="st4112" d="M578.7,493.2c-0.6-0.2-1.2-0.5-1.8-0.7c-2.8-2-4-5.6-7.2-7.2c-4.6-2.6-5.7-7.6-8.1-11.8
		c-0.4-3.2-3.6-5.6-2.2-9.2c0.4-0.4,0.9-0.4,1.4,0c0.3,0,0.6,0,0.9,0.1c0,1.9,0.5,3.7,2,5c0.9,5.7,4.6,9.7,8,13.9
		C573.6,486.9,578.1,488.7,578.7,493.2z"/>
	<path class="st4113" d="M560.8,464.3c-0.4,0-0.7,0-1.1,0c-2.9-1.5-3.5-3.8-2.4-6.8c0.4-0.5,0.9-0.6,1.4-0.2
		C560,459.4,560.4,461.8,560.8,464.3z"/>
	<path class="st4114" d="M558.7,457.3c-0.3,0-0.6,0-1,0c-2.4-2-2.9-4.3-1-7c0.3,0,0.6,0,0.9,0C558,452.7,558.3,455,558.7,457.3z"/>
	<path class="st4115" d="M600.7,505.3c-3.2-3-8-2.9-11.1-6c1.3-0.1,2-0.7,2-2c0.7,0,1.3,0,2,0c2.5,3.3,6.3,4,10,4.9
		c0.3,0,0.6,0.1,0.9,0.2c-0.3,0.9-0.6,1.9-1,2.8C602.6,505.3,601.7,505.3,600.7,505.3z"/>
	<path class="st4116" d="M715.5,349.4c3.6-4.2-2.3-4.9-3.2-7.4c-0.6-1.8-3.6,0.5-4.8,2.3c-0.5,0.8-1.2,1.1-2,1.1
		c-2.2-3.8-2.7-7,1.2-10.4c1.9-1.6,3.6-4.7,0.1-7.1c-0.7-0.4-0.9-1-0.9-1.8c0-0.5,0.2-0.9,0.4-1.3c1.1-1.4,2-2.9,2.5-4.6
		c0.3-0.6,0.8-1.1,1.5-1.3c5.1,0.5,10.1,1.5,15.2,1.5c0.1,2.7-0.1,4.9,2.9,7.1c2.5,1.8,1.5,6.1-0.1,9.2c-0.6,1.1-2,2-1.6,3.5
		c0.5,2.2,1.9,4.7-0.3,6.5c-2.5,2-5.5,3.1-8.8,2.7C716.8,349.4,716.2,349.4,715.5,349.4z"/>
	<path class="st4117" d="M725.5,320.5c-5.2,2.2-10.1,0.7-15-1c0.8-1.3-0.4-1.5-1.1-2c4.2-1.9,8.4-4,13.2-2.1
		c-0.3,0.1-0.7,0.1-0.9,0.3c-0.5,0.5-0.2,0.8,0.3,0.8c1.6,0,2.8,0.5,3.5,2.1C725.5,319.2,725.5,319.8,725.5,320.5z"/>
	<path class="st3967" d="M723.7,369.5c-0.1-3.2,2.9-4.7,3.8-7.2c0.3-0.8,1.4-0.9,2.2-0.5c0.3,0.2,0.6,1,0.5,1.2
		C728.4,365.5,729,370.3,723.7,369.5z"/>
	<path class="st4118" d="M697.5,345.5c-0.3,0.3-0.7,0.7-1,1c-1.6,1.7,1.1,4.8-2,6c-0.8-3.6,1.7-7.6-1-11c0.4-3.8,3.2-6.9,3.3-10.9
		c0.9-0.4,1.7-0.8,2.6-1.2c1.1,0.8,3,0.1,3.7,1.8C701.9,336.2,700.3,341,697.5,345.5z"/>
	<path class="st4119" d="M702.5,330.5c-1.1-0.2-2.9,1.5-3.1-1.1c2.4-4.8,5.1-9.2,10-11.9c1.5,0.1,1.9,0.7,1.1,2
		c-0.3,0.3-0.7,0.7-1,1c-1,1.7-1.7,3.5-3,5l0,0c-0.9,1.3-1.3,2.8-2.3,4C703.8,330,703.2,330.4,702.5,330.5z"/>
	<path class="st4120" d="M694.6,352.5c2.1-1.5-0.1-4.5,2-6c0.3,6-0.7,12.1,0.9,18c-1.2,0.7-1,2.2-2,3c-0.3,0-0.6,0-0.9,0
		C694.5,362.4,694.5,357.4,694.6,352.5z"/>
	<path class="st4121" d="M695.5,367.5c0.9-0.9-1.8-4.1,2-3c0,0.7,0,1.3,0,2c-1.6,1.4-0.7,3.4-1,5
		C695.1,370.4,695.6,368.9,695.5,367.5z"/>
	<path class="st4122" d="M702.5,330.5c0.3-0.3,0.6-0.7,0.9-1c1-0.7,1.2-2.6,3.1-2c2.8-1.3,4.4-0.6,3.6,2.6c-0.7,2.8-0.8,5.9-4.3,7.8
		c-2.8,1.5,0.7,5-0.3,7.6c-2.8,0.1-4.4,1-4.1,4.4c0.4,4.3,0.1,8.6,0.1,12.9c0,1.1-0.1,3.3,0.8,3c6.2-2,4.6,3.5,6.1,6
		c0.3,0.6,0.7,1.1,1.1,1.6c-0.7,0.9-1.7,1-2.8,0.8c-2.3-1.7-4.5-3.6-6.2-5.8c-3.2-7.4-2.5-15.2-3-23
		C697.9,340,701.1,335.5,702.5,330.5z"/>
	<path class="st4123" d="M706.5,373.4c1,0,2,0,3,0c1-0.6,0.8-1.7,1.1-2.6c0.7-1.9,2-1.4,3.1-0.5c1,0.8,2.4,2.1,0.9,3.2
		c-2.2,1.5-3.8,4.3-7.1,3.9c-0.3-0.3-0.7-0.7-1-1C705.7,375.4,705.7,374.4,706.5,373.4z"/>
	<path class="st3967" d="M706.5,373.4c0,1,0,2,0,3c-2.4-2.3-4.9-4.7-6-8C702.7,369.9,705.4,370.7,706.5,373.4z"/>
	<path class="st4124" d="M675.5,531.5c0.3-1,2.6-1.8,0.6-3.1c-3.1-2.1-0.8-4.4-1-6.6c2.2,0.2,0.7,3.6,2.9,2.8c2-0.7,5.8,0.2,4.9-4.2
		c-0.1-0.6,0.2-1.3,0.5-1.9c1-2.3-1.6-6.5,2.8-6.8c4.2-0.3,9.3,4.7,9.3,8.8c-0.1,4.8,0.1,9.2,4.7,12.1c2,1.3-2,4.4,1.3,5.7
		c0,0.2,0,0.4,0,0.6c-0.5,1.4-1.1,2.8-2.1,3.9c-0.9,0.9-1.6,2-2.5,3c-0.6,0.6-1.4,1.1-2.2,1.5c-2.2,1.2-4,0-5.9-0.9
		c-5.7-2.7-8.1-8.8-12.9-12.4C675.2,533.3,674.8,532.5,675.5,531.5z"/>
	<path class="st4125" d="M703.5,537.2c-0.3,9-0.4,18-1,27c-0.8,13-2.4,25.8-5.3,38.6c-1.8,7.9-4.5,15.8-4.7,24.1
		c0,0.7-0.6,1.5-0.9,2.2c-3.2-1.7-2.3-4.5-2-7.1c1.9-11.1,5.2-22,6-33.2c1-13,3-26,3.6-39c0.2-4,1-7.8,2.4-11.6c0,0,0,0,0,0
		C702.2,537.9,702.8,537.5,703.5,537.2z"/>
	<path class="st4126" d="M675.5,531.5c0.4,0.6,0.7,1.2,1.1,1.8c1.7,2.9,5.5,4.7,5,8.9c-0.4,0.3-0.8,0.5-1.3,0.5
		c-4.4-4.6-9-9-10.6-15.5c0.7,0,1.3,0.1,2,0.1C672.9,528.6,674.2,530,675.5,531.5z"/>
	<path class="st4127" d="M690.5,622.1c0.1,2.4-0.4,4.8,1,7c0,0.7,0,1.3,0,2c-0.5,0.3-0.9,0.3-1.4,0c-0.9-0.9-1.4-2-1.9-3.2
		c-4.7-14.3-10.5-28.2-15.8-42.3c-0.6-2.2-1.8-4.2-2.2-6.5c-0.3-2.6-1.4-5.1-0.9-7.7c0.2-1.1,0.6-2,1.7-2.5c0.5-0.1,1-0.1,1.5,0.2
		c1.3,0.9,2.2,2.4,1.9,3.8c-1.5,5.6,2,9.8,3.7,14.5c0.2,0.4,0.3,0.9,0.4,1.3c3.8,4.8,4.5,11,7,16.3c0.2,0.9,0.4,1.8,0.5,2.8
		c0.6,3,2.1,5.7,3.3,8.4C689.6,618.2,690.6,620,690.5,622.1z"/>
	<path class="st3972" d="M695.5,624.1c0-1.1-0.2-2.2,0.9-3c1.5-0.4-0.6-3.8,2.4-2.8c-0.5,6-1.1,12.1-3.6,17.7
		c-0.5,1.1-1.1,2-2.5,2.1c-1.1-0.7-1.3-1.7-1.3-2.9c0.1-0.9,0.4-1.6,1.1-2.2c1.1-0.8,0.8-1.9,1-3
		C694.4,628.1,694.2,625.9,695.5,624.1z"/>
	<path class="st3745" d="M698.6,618.1c-0.7,1-1.4,2-2.1,3c1.3-7.7,2.9-15.4,5.1-23c-0.3,4-0.7,8-1,11.9
		C700.6,612.9,700.1,615.7,698.6,618.1z"/>
	<path class="st3745" d="M695.5,624.1c0,2.2-0.5,4.2-1.9,6C693.6,627.9,692.9,625.5,695.5,624.1z"/>
	<path class="st4125" d="M692.6,633.1c0,0.7,0,1.4,0,2.1c-2.5-0.5-3.3-1.8-1.8-4c0.2,0,0.5-0.1,0.7-0.1
		C691.9,631.8,692.2,632.4,692.6,633.1z"/>
	<path class="st4128" d="M698.6,618.1c0.1-2.8,0.6-5.6,2-8.1c0.3,0,0.6,0,1,0c0,0.7,0.1,1.4,0.1,2.2c-1,5-2,9.9-3.1,14.9
		c-0.9,2.9-0.4,6.2-2,9c-0.3,1-0.6,2-1,3.1c-0.6,0.5-0.2,1.5-0.9,2.1c-0.4,0.2-0.8,0.3-1.2,0.2c-0.6-0.2-1.1-0.6-1.4-1.2
		c-0.3-0.6-0.3-1.2-0.2-1.8c0.2-0.4,0.5-0.5,0.9-0.3C696,631.8,697.6,625.1,698.6,618.1z"/>
	<path class="st4129" d="M708.5,532.2c2.8,2.9,2.2,6.6,1.9,10.1c-0.4,5,1.2,10.1-0.9,15C709.4,548.9,709.9,540.5,708.5,532.2z"/>
	<path class="st4130" d="M704.5,610.1c-0.4,0.8-1.2,1-2,1c-0.1-3.2,0.3-6.2,2-9C705.2,604.8,702.9,607.5,704.5,610.1z"/>
	<path class="st4129" d="M709.5,565.2c-0.4,2.7,1,5.5-1,8C708.9,570.6,707.5,567.7,709.5,565.2z"/>
	<path class="st4129" d="M708.5,577.2c-0.4,2.6,1,5.5-1,8C708,582.5,706.5,579.6,708.5,577.2z"/>
	<path class="st4130" d="M707.5,585.2c-0.3,2,0.8,4.2-1,6C706.8,589.2,705.7,586.9,707.5,585.2z"/>
	<path class="st4130" d="M706.5,592.2c-0.2,1.7,0.7,3.6-1,5C705.8,595.5,704.9,593.6,706.5,592.2z"/>
	<path class="st3746" d="M713.5,576.2c0,0.8-0.2,1.6-1,2c-0.2-0.2-0.4-0.6-0.6-0.7c-1.6-0.9-1.3-2.5-0.3-3.1
		C713,573.6,712.3,576.1,713.5,576.2z"/>
	<path class="st4131" d="M694.6,641.2c0-0.8,0.1-1.6,1-2c1.8,3.9,0.6,8,1,11.9c-1.2,5.9-4.8,11-4.9,17.2c0.7,0.9,0.7,1.8-0.2,2.6
		c-2,2.4-1.5,6-3.8,8.3c-0.3-3.1,1.5-5.7,2.2-8.6c0.4-1.6,1.4-3.1-0.3-4.5c-1.1-2.2-0.1-4.4,0.7-6.2
		C692.6,653.8,692.5,647.3,694.6,641.2z"/>
	<path class="st4130" d="M696.5,636.1c0-3.1,0.8-6.1,2-9C698.3,630.2,699.4,633.6,696.5,636.1z"/>
	<path class="st4132" d="M691.5,670.9c0.1-0.9,0.1-1.8,0.2-2.6c2.7-3.3,2.4-7.8,4.9-11.2c3.7-0.9,2.9-5,5-7
		c4.7,4.3,5.3,10.5,1.3,14.8c-2.7,2.9-2.4,6.7-3.3,10.1c-0.7,4.1-3,7.3-6,10C691.2,680.6,693.8,675.4,691.5,670.9z"/>
	<path class="st4133" d="M699.5,675.1c-1-1.2-1.5,1.5-2.8-0.1c3.8-2,1.2-7.2,3.6-9.1c7-5.2,3-10.4,1.1-15.8c-0.7-3.3,2.2-5.8,2-9
		c4.1-3.1,2.5-8.1,4-12c2-1.2,4-3.4,4.1,1.4c0.1,2.8,2.5,5.1-0.6,8.5c-1.3,1.4,1.7,6.5,4.5,9.1c0,0.7,0,1.3,0,2
		c-0.9,0.4-1.8,0.8-1,2c-1,1.7-2,3.3-3,5c-4.5,5.5-6.9,12.2-11,18C700.2,675.1,699.9,675.1,699.5,675.1z"/>
	<path class="st4134" d="M641.3,532.1c1.8-0.5,3.1-2.2,5.2-2c0.4,0.1,0.8,0.1,1.2,0.2c0.2,0.5,0.2,0.9-0.1,1.3
		c-4.7,3.6-10.1,6-15.4,8.6c-0.7,0.3-1.4,0.5-2.2,0.5c-0.5-0.1-0.9-0.5-1.1-1c-0.2-1.9,0.1-3.2,2.5-2.7
		C635.2,536.2,637.7,533,641.3,532.1z"/>
	<path class="st4135" d="M647.5,531.2c0-0.3,0.1-0.6,0.2-1c1.3-0.8,2.6-1.5,3.9-2.3c0.3,0.1,0.6,0.3,1,0.4
		C652.2,531.5,650.4,532.3,647.5,531.2z"/>
	<path class="st4136" d="M647.5,531.2c2.1-0.3,3.9-1,5.1-2.9c0.2-0.3,0.5-0.7,0.7-1c2.3,0.2,4.2-1.2,6.2-2.1
		c1.2-0.7,2.5-0.4,3.7-0.3c1.1,3.5-2,4-3.9,5.1c-7.1,3.9-14.2,7.9-21.2,11.9c-2,1.1-3.8,2.6-5.8,3.8c-0.6,0-1-0.2-1.3-0.7
		c-0.9-2-0.6-3.8,0.7-5.4C637,536.6,642.3,533.9,647.5,531.2z"/>
	<path class="st4137" d="M632.4,545.2c0.2-2.5,2.3-3,4-4c9-5.4,18.7-9.8,27.3-15.9c0.6-0.7,1.1-0.9,1.6,0.1c0.3,0.7,0.2,1.6,1.2,1.9
		c0.3,0.2,0.4,0.5,0.4,0.8c0,0.3-0.1,0.4-0.2,0.4c-0.6,0.7-1.4,1.3-2.2,1.9c-8.1,4.8-16.6,9-24.7,13.7
		C637.7,545.4,635.2,547.3,632.4,545.2z"/>
	<path class="st4138" d="M666.7,528.2c-0.1-0.3-0.2-0.6-0.3-0.9c2.6-0.2,0.9-2,1.1-3c1.2,0.6,1.9,1.5,2,2.9
		c3.6,5,7.3,10.1,10.9,15.1c1.3,1.3,0.3,2.9,0.5,4.3c-0.1,0.5-0.4,0.8-0.8,1.1c-0.5,0.1-0.9,0.1-1.4,0c-2.3-6.1-6.6-10.9-10.6-15.8
		C667.4,530.8,666.7,529.6,666.7,528.2z"/>
	<path class="st4135" d="M665.3,525.4c-0.5,0-1.1,0-1.6-0.1c-1.4-0.1-2.8-0.1-4.1-0.2C661.5,524,663.5,523.3,665.3,525.4z"/>
	<path class="st3990" d="M414,410.9c-1.3-2-1.4-3.2-0.5-5.1C413.7,407.7,413.8,409,414,410.9z"/>
	<path class="st4139" d="M497.7,362.4c-0.1,2.8,2.6,4.4,3,7c0.6,0.7,0.6,1.3,0,2c-4-2.2-3.6-6.5-5-10
		C496.4,361.8,497.1,362.1,497.7,362.4z"/>
	<path class="st3770" d="M500.7,371.4c0-0.7,0-1.3,0-2c1.5,1.7,1.9,3.8,2,6C501.4,374.4,500.9,373,500.7,371.4z"/>
	<path class="st4087" d="M501.7,363.4c-1.7-2.1-3.6-4.1-3.9-7c0.7-0.6,1.3-0.6,2,0c1.5,1.4,1.8,3.2,2,5.1
		C501.7,362.2,501.7,362.8,501.7,363.4z"/>
	<path class="st4140" d="M531.7,416.4c0.3,0,0.7,0,1,0c3.5,1.1,6.2,3.9,9.6,5.2c1.3,3.7,0.8,7.5,0.8,11.2c-4.3,2.7-5.3-1.5-7.4-3.5
		c0.8-3-2.1-4.5-2.8-6.9C532.3,420.5,531.4,418.5,531.7,416.4z"/>
	<path class="st4141" d="M535.7,429.4c2.8,0.6,4,3.9,7,4c3.9-1.1,3.3,1.9,3.7,4c-0.5,3.2,2.1,6.1,1,9.3c-5.3,0.4-5.6-4.5-7.7-7.4
		c-1.2-2.1-4.1-3-4-6C535.7,432,535.7,430.7,535.7,429.4z"/>
	<path class="st4142" d="M541.7,421.4c-4.7,1.5-6.3-2.7-9-5c0.4-7.1-1.3-13.5-6-19c1.2-1,2.3-0.5,3.5,0c2.3,1.2,3.5,3.3,4.6,5.5
		c1.4,4,2.5,8,6.1,11c1.8,1.5,2.1,4.7,2,7.4C542.5,421.7,542.1,421.7,541.7,421.4z"/>
	<path class="st4143" d="M529.7,398.4c-1-0.3-2-0.7-3-1c-0.2-3.2-2.7-4.5-5-6c-1-2.8-2.1-5.5-2.9-8.3c-0.5-1.7-1.2-2.7-3.1-1.7
		c0.3-1,0.7-2,1-3c3.3-2,4.8,0.8,6.7,2.4c3.2,4.1,4.6,9.1,6.7,13.8C530.7,395.9,530.8,397.3,529.7,398.4z"/>
	<path class="st4144" d="M522.7,381.4c-2-1-3.2-3.6-6-3c-4.8-8.1-7.2-17.4-13-25c1.3-2.2,2.9-1.8,4.4-0.5c2.5,2.2,5.5,4.3,6.4,7.8
		c1,3.9,2.2,0.8,3.4,0.3c4.4,2.2,5.2,6.7,6.9,10.7c-0.8,3.6,3.3,6.5,1.4,10.2C525,382.5,523.8,382.2,522.7,381.4z"/>
	<path class="st4145" d="M517.7,361.4c0,1.5,0.1,3.4-1.8,3.6c-2,0.2-1.9-1.6-2.3-3.1c-0.9-4.5-6.4-9.1-9.9-8.5c-0.1-2.2-1.6-3.6-3-5
		c-0.1-4.1-4.8-5.9-5-10c0.4-1.1,1.2-1.7,2.3-2c3.8-0.6,5.8,1.8,7.4,4.7c0.8,3.5,2.7,5.7,5.6,8.1
		C514.6,352.1,517.1,356.5,517.7,361.4z"/>
	<path class="st3707" d="M499.8,356.4c-0.7,0-1.3,0-2,0c-0.8-2.4-3.4-4-3.1-6.9c-0.3-1-0.7-2-1-3
		C496.8,349.2,498.5,352.6,499.8,356.4z"/>
	<path class="st4039" d="M714.5,652.1c-0.9-0.5-2.1-1.1-1.6-2.1c0.6-1.3,1.7,0,2.6,0.1C715.2,650.8,714.8,651.4,714.5,652.1z"/>
	<path class="st4146" d="M631.5,692.4c-12.5-4.3-24.5-9.6-35.9-16.2c-0.5-1.5-3.6-1-2.8-3.5c5-0.6,8.4,3.1,12.7,4.6
		c5.4,1.9,10.3,5.3,15.8,6.9c1.7,0.9,3.5,1.8,5.1,2.9C627.8,689.2,631.9,688.6,631.5,692.4z"/>
	<path class="st4147" d="M631.5,692.4c-1.2-2.6-4.5-2.2-5.9-4.4c1.3-1.2,2.8-0.7,4.2-0.2c1.1,0.5,2.2,1,3.3,1.5
		c6.2,2.6,13,4.2,17.9,9.3c0.1,0.5-0.1,1-0.4,1.4C644,698.1,637.7,695.3,631.5,692.4z"/>
	<path class="st4148" d="M559.6,656.1c-0.7-2-5-2.9-2-6.1c0.8,0.3,1.2,1,1.8,1.6c0.4,0.4,0.9,0.9,1.4,1.3c2.2,1.9,4.9,3.2,7.3,4.7
		c2.8,2.5,7,3.3,8.9,7c-0.3,1.1-1.2,1.3-2.2,1.2c-0.8,0.1-1.5-0.1-2.1-0.8c-1.5-1.3-3.5-1.7-4.9-3.1l0,0c-2.2-0.9-4.3-1.9-6.2-3.3
		C560.7,658,559.8,657.3,559.6,656.1z"/>
	<path class="st4149" d="M650.6,699.9c0-0.3,0-0.6,0-0.9c1.6-1.4,3.1-0.6,4.6,0.1c1.7,0.8,3.1,0.7,4.6-0.4c2.9,0.4,4.4,2.4,5.5,4.8
		c0,1.4,0.2,2.8-1.9,2.7C659.1,704.1,654.8,702,650.6,699.9z"/>
	<path class="st4150" d="M561.8,658c2.3,0.8,4.4,2,5.9,4c-3.5,0.9,0.4,2.1-0.1,3.2c-3.2-0.9-5.8-2.6-8-5.1c-1.2-1.2-3.1-1.5-4-3l0,0
		c0-1.7,0.9-1.7,2.1-1.1l0,0C559,656.9,560.6,657.1,561.8,658z"/>
	<path class="st4151" d="M592.6,673.1c0.8,1.3,2.7,1.4,3,3.2c-4.3-1.4-8.3-3.5-12-6c-0.4-0.5-0.3-0.9,0-1.4
		C587.5,668.3,590.4,670,592.6,673.1z"/>
	<path class="st4152" d="M583.7,669c0,0.4-0.1,0.8-0.1,1.2c-2.9-1.4-5.9-2.9-8.8-4.3c0.7-0.3,1.3-0.5,2-0.8
		C579.6,665.3,582.4,665.9,583.7,669z"/>
	<path class="st4153" d="M663.4,706.3c1.2-0.7,1.2-1.9,1.1-3c-0.3-0.4-0.3-0.8,0-1.3c0.5-0.2,0.9-0.2,1.4,0c0.9,0.5,1.4,1.2,1.8,2.1
		c0.4,1.3,1.2,2.7,0,4c-0.9,0.6-1.9,0.5-2.8-0.1C664.3,707.4,663.8,706.8,663.4,706.3z"/>
	<path class="st4150" d="M567.7,662c2,0.5,3.9,1.1,4.9,3.1C570.6,664.7,568.6,664.2,567.7,662z"/>
	<path class="st4154" d="M694.6,641.2c1.1,8.8-2.6,16.7-5,24.9c-2.7,3.7-3,8.3-4.7,12.4c-3.2,8-7.2,15.6-10.2,23.6
		c-0.3,0.9-0.1,2-0.2,3c-0.3,0.3-0.6,0.6-1,1c-0.3,0-0.7,0-1,0c-2-2.5-1.1-4.9,0.1-7.4c7.1-14.4,12.5-29.5,17.8-44.6
		c1.5-4.2,0.7-8.9,3.2-12.8C693.9,641.2,694.2,641.2,694.6,641.2z"/>
	<path class="st4155" d="M693.6,641.2c0.1,13.2-6.4,24.5-10.4,36.4c-2.7,8-6.5,15.5-10.2,23c-0.7,1.5-0.3,3.6-0.4,5.4
		c-1.6,0.8-3.1,2-5,2c-0.2-1.3-0.4-2.6-0.7-3.9c2-1.2,2.7-3.2,3.6-5.2c3-6.4,5.6-13,8.5-19.5c4.3-12.4,10.4-24.2,12.5-37.3
		c0.1-0.8,0.5-1.6,1.4-2C693.1,640.6,693.3,640.9,693.6,641.2z"/>
	<path class="st4156" d="M591.6,535.2c-6.7-1.8-11.5,3.6-17.3,5c-1.6,0.4-3,1-4.7,1c6.1-3.9,12-8.2,19.6-9c4.9-0.5,9.4-2.9,14.4-3
		c0.1,0.2,0.1,0.5,0.2,0.7C600.9,534.3,596.2,534.7,591.6,535.2z"/>
	<path class="st4156" d="M603.6,529.2c3.1-3.8,7.7-4,12-5c0.1,0.2,0.3,0.5,0.4,0.7C613.6,529.3,608.2,531.1,603.6,529.2L603.6,529.2
		z"/>
	<path class="st3799" d="M616.6,519.2c0,0.3,0.1,0.6,0.1,0.9c-2.2,3.5-5.8,2.9-9.1,3.1c2.1-2.4,6.5-0.3,8-4
		C615.9,519.3,616.3,519.2,616.6,519.2z"/>
	<path class="st4156" d="M618.6,522.3c1.3,0.5,2,1.5,2,3c-1.8,0.2-3.7,1.4-5-1c0,0,0,0,0,0C616.6,523.6,617.6,522.9,618.6,522.3z"/>
	<path class="st4157" d="M615.6,524.3c1.5,1.3,3.4,0,5,1c-2,1.2-3.9,2.7-6.4,3.1c-1.7,0.2-2.5,1.3-2.6,2.9c-8.9,1.6-17,5.9-25.5,8.8
		c-4.3,1.5-7.6,5.4-12.5,5.2c-2.2-1.1-3.6,2.9-6,1c8-3.7,16-7.3,24-11c3.6-2.8,9-1.9,12-6C608.3,529.3,612.9,528.8,615.6,524.3z"/>
	<path class="st4158" d="M591.7,554.2c-0.7-1.3-3.2-0.8-3-3c4.1-1.2,7.1-4.2,10.8-6.1c4.7-2.5,9.1-5.9,14.1-7.3
		c4.5-1.3,6.8-5.4,11.1-6.6c-0.6,1.4-0.7,3-2.4,4c-4.7,2.8-9.2,5.9-13.8,8.7c-4.6,2.7-10,4.9-13.8,9.2
		C594,553.8,593.4,556,591.7,554.2z"/>
	<path class="st4159" d="M631.5,537c-2,0.3-2.2,1.6-2,3.2c-0.4,0.2-0.9,0.2-1.2-0.1c-1.3-2.8-0.5-5.8-0.8-8.7c0-0.1-0.2-0.2-0.4-0.3
		c-0.1,0-0.3,0.1-0.5,0.2c-0.3-0.3-0.7-0.7-1-1c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1c-1.1-1.5-0.9-3.2,0.9-3.6c3.8-0.8,7.1-3.5,11.2-3.4
		c0.3,0,0.7,0,1,0c-1.6,2.1-3.2,4.2-5,6.2c-1.6,1.7-6,2.5-1.1,5.7C634.2,534.1,631.2,535.4,631.5,537z"/>
	<path class="st3803" d="M579.7,551.2c0.8-1.8,2.3-2.1,4-2C582.6,550.5,581.2,551,579.7,551.2z"/>
	<path class="st4160" d="M637.6,521.2c-3.2,1.8-6.2,4.1-10.2,4.1c-1.9,0-1.8,1.6-1.8,2.9c-0.7-0.3-1.3-0.7-2-1
		c-0.7-1.3-1.5-2.5-2-3.9c-0.4-0.9-0.7-2.3,0.9-2.1c4.4,0.6,7.1-3,10.8-4.3c1.3-0.5,2.5-1.2,3.7-0.2
		C638.5,518,638.7,519.6,637.6,521.2z"/>
	<path class="st4161" d="M599.6,589.2c0-0.3,0-0.7,0-1c-2.5-1.4-5.5,0.3-8-1l0,0c-0.6-0.6-0.6-1.2-0.3-2c0.3-0.4,0.7-0.6,1.2-0.8
		c5.2-2.8,10.3-5.7,14.2-10.3c2.7-3.1,5.6-6.1,9.8-7.1c2.1-0.1,2.7,1.6,3.5,3c0.6,2.5-3.4,4.7-0.6,7.4c0.8,3,2.4,5.6,5.4,6.8
		c9.5,3.8,11.5,11.1,9.9,20.2c-0.1,0.5-0.2,1-0.3,1.5c-0.3,4.6-1.5,9-1.4,13.6c-0.2,2.2,0.6,4.5-0.4,6.8c-1.1,2.5-0.8,5.5-2.7,7.8
		c-0.4,0.3-0.9,0.5-1.5,0.5c-1.1-0.1-1.9-0.7-2.5-1.7c-1.5-6.2,0.3-12.2,0.9-18.2c0.5-4.5,0.5-8.9-0.2-13.4
		c-0.6-3.4-2.6-5.4-5.5-7.7c-3.9-3.1-7-3.8-11.1-1.6c-0.7,0.4-1.6,0-2.5-0.2C605.2,590.4,602,591.1,599.6,589.2z"/>
	<path class="st4162" d="M619.6,570.2c-1-1-2-2-3-3c-1.8-1.7-3.4-2.5-5.8-0.3c-6.7,5.8-13.5,11.4-19.1,18.3c-2.4,2.4-5.3,1-8,1
		c-1-0.9-2.1-1.6-3-2.6c-0.9-1-1.4-2.2,0.8-1.9c0.9,0.1,2.2,0.1,1.9-0.7c-1.4-4.4,2.3-5.3,4.4-7.6c1.7-1.8,3.2-2.6,5.4-2.6
		c4.1,0,5.9-3.2,8.7-5.6c4.5-3.8,9.3-7.3,13.5-11.6c0.1-0.1,0.3-0.1,0.5,0c4.5,1.5,3.2-2.4,4-4.5c1.7,1.6,3.3,3.3,3.9-0.8
		c0.5-3.1,1.8-6,2.7-9c-0.2-2.7-1.3-5.3,0-8c0.1-1.2,0.3-2.3,2.2-2.7c0,4,0,8,0,11.9c1.3,1.5,1.1,3.4,0.8,5.1
		c-1.1,5.2-1.9,10.6-4.5,15.4C621.6,563.1,622.5,567.8,619.6,570.2z"/>
	<path class="st4163" d="M599.6,589.2c2.7,0.5,5.8-0.7,7.9,1.9c-0.5,0.7-1.3,1-2.1,1.2c-1.4,0.2-2.8,0.2-4.2,0.2c-2.3,0-4.6,0-6.9,0
		c-3.6-0.8-7.9,1.8-10.8-2.1c1.7-2.2,3.9-0.6,5.8-0.8c1.7-0.1,3.5,0.4,5.2-0.4C596.2,589.3,597.9,589.2,599.6,589.2z"/>
	<path class="st4164" d="M594.5,589.3c-3.5,1.9-7.3,0.3-10.9,0.9c-0.4,0.5-1,0.6-1.6,0.5c-0.4-0.1-0.6-0.4-0.8-0.8
		c0.2-0.5,0.3-1.1,0.5-1.6C586.1,587.8,590.3,589.4,594.5,589.3z"/>
	<path class="st4165" d="M583.7,586.2c2.7-0.3,5.3-0.7,8-1c0,0.7,0,1.3,0,2C589,586.7,586.1,588.2,583.7,586.2z"/>
	<path class="st4165" d="M591.7,587.2c2.7,0.4,5.5-1,8,1C597,587.7,594.1,589.2,591.7,587.2z"/>
	<path class="st4162" d="M624.6,540.2c-1.1-0.8-1.2-1.9-0.7-3.1c1.2,0.2,1.2,1.4,1.7,2.1C625.3,539.6,624.9,539.9,624.6,540.2z"/>
	<path class="st4034" d="M567.6,618.1c0,1,0,1.9,0,2.9c-0.6,1.4-0.8,3-2,4.1c-3.5,6.7-7,13.5-10.5,20.1c-0.8,1.5-1.1,3.6-3.3,4
		c-0.6-0.7-0.7-1.4-0.1-2.1c3.3-6.2,6.6-12.4,10-18.5c1-1.8,2.1-3.4,1.9-5.5c1-1.7,2-3.3,3-5C567,618.1,567.3,618.1,567.6,618.1z"/>
	<path class="st4166" d="M575.7,605.2c-1,1.3-2.1,2.6-3.1,3.9c0.1-2.5,1.2-4.8,2.3-7c0.3,0,0.6,0,0.9,0
		C576.5,603.1,576.5,604.1,575.7,605.2z"/>
	<path class="st4166" d="M571.6,611.1c0.1,2-0.2,3.9-2.2,5C569.8,614.2,570.2,612.4,571.6,611.1z"/>
	<path class="st4167" d="M577.8,593.2c1.2-1.1,2.3-2.2,3.5-3.3c0,0,0.3,0.2,0.3,0.2c0.5,0.5,0.7,1.1,0.7,1.8
		c-0.1,0.9-0.3,1.8-0.5,2.7c-0.2,1.1-0.4,2.3-1.7,2.7c-0.5-0.1-0.8-0.3-1.1-0.7C578.5,595.6,578.9,594.2,577.8,593.2z"/>
	<path class="st4168" d="M577.8,593.2c2.2,0,1.9,1.4,1.8,2.8c-1.2,0.8-2,0.9-2-0.9c0-0.3,0-0.7-0.2-1
		C577.7,593.9,577.8,593.6,577.8,593.2z"/>
	<path class="st4169" d="M577.7,595.1c0.4,0.8,1.1,1,2,0.9c0.1,0.3,0.2,0.5,0.3,0.8c1.9,3.5-0.3,6-2.1,8.6c-0.6,0.6-1.2,1-2.1,1
		c0-0.4-0.1-0.9-0.1-1.3c0-1,0-2,0-3.1c-0.2-2,2.4-3.6,1-5.9C577,595.8,577.3,595.5,577.7,595.1z"/>
	<path class="st4091" d="M550.7,650.1c-0.1,3.4-0.2,6.7,1,10c-3.6-2.3-1.4-6-2-9c0-0.3,0-0.7,0-1C550,649.8,550.4,649.8,550.7,650.1
		z"/>
	<path class="st3814" d="M550.7,650.1c-0.3,0-0.7,0-1,0c0-1,0-2,0-3c0.7,0,1.3,0,2,0c0,0.7,0,1.4,0.1,2.1c0,0.3,0,0.6-0.1,0.9
		c0,0-0.5,0-0.5,0L550.7,650.1z"/>
	<path class="st4170" d="M562.6,473.3c2.3,4,4.7,8,7,12c-2.4,1.9-3.9-1.5-6-1c0.3-3.4-3.5-3.9-4.7-6.4c-0.7-1.5-1.4-2.9-1.3-4.5
		C559.3,474.6,561,474.7,562.6,473.3z"/>
	<path class="st4171" d="M539.7,439.3c3.6,1.2,3.5,6.7,8,7c3.2,1.7,1.9,5.8,4.3,8.1c0.6,0.6,0.2,1.8,0.1,2.7c0.4-0.6,0-1.9,1.3-1.7
		c0.9,0.2,1.1,1.1,1.4,1.9c0.2,2,0.8,4-0.1,6c-0.5,0.6-1.2,1-2,1c-4.1-2.9-7.9-5.6-7.4-11.9c0.2-3-4.4-3.9-6.6-6.1
		C536.3,443.6,539.6,441.7,539.7,439.3z"/>
	<path class="st4172" d="M554.7,463.3c-1.2-1.9-1-3.9-1-6c1.4-1.6,0.4-3.6,0.8-5.4c0.1-1,0.3-1.9,1.2-2.6c0.3,0.3,0.7,0.6,1,0.9
		c-0.3,2.4,0.4,4.7,1,7c-0.4,2.6,0.8,4.8,2,7c-0.1,3.4,2.9,5.7,2.9,9c-1.7,3-3.3,2.8-4.9,0c-1.7,0.1-3.2-0.2-4-2
		C557.4,469.1,555.8,466.2,554.7,463.3z"/>
	<path class="st4172" d="M552.7,464.3c0.4-0.8,1.2-1,2-1c-0.1,0.9-0.1,2-1.2,2.3C552.8,465.8,552.8,464.9,552.7,464.3z"/>
	<path class="st4173" d="M506.7,201.6c-2.5,1.6-4.9,3.6-8,4c1.7-2.7,4.5-4.2,7-6c0.7,0,1.4,0,2,0
		C508.3,200.7,507.4,201.1,506.7,201.6z"/>
	<path class="st4174" d="M506.7,201.6c0.3-0.7,0.7-1.3,1-2c5.3-3.8,10.1-8.4,16.3-10.8c0.1,1.6-1,2.5-1.8,3.5
		c-1.3,1.3-2.6,2.5-4.2,3.4c-3.3,1.6-5.1,5.8-9.3,6C508.1,201.6,507.4,201.6,506.7,201.6z"/>
	<path class="st4175" d="M521.8,191.7c0.7-1,1.5-1.9,2.2-2.9c0.3,0,0.5,0,0.7-0.1c1.4,0.8,2.4,0.4,2.9-1c1-0.2,1.9-1.6,2.9-0.1
		c-0.3,0.4-0.6,0.8-0.9,1.3c-1.3,1.7-2.1,3.9-4.6,4.2C524.2,192.1,522.3,193.6,521.8,191.7z"/>
	<path class="st4057" d="M502.8,211.6c-2.3,2.4-4.6,4.8-6.9,7.2c0.7-2.1-0.4-2.5-2.1-2.3c4.6-3,8.2-7.1,12-11
		c0.7,0.2,1.2,0.6,1.3,1.3C506.5,209.3,505,210.8,502.8,211.6z"/>
	<path class="st4176" d="M506.8,206.6c-0.4-0.3-0.7-0.7-1.1-1c1-1.3,2-2.7,3-4c3.3-2.1,5.5-5.5,9.3-6.8c0.2,1.3-0.3,2.3-1,3.3
		c-2.3,2.4-5,4.3-6.9,7C509.4,206.1,508.4,207.1,506.8,206.6z"/>
	<path class="st4177" d="M523.7,217.6c2.1,0.3,2.2,1.9,2,3.4c-1.6,10.6,2.3,20.5,4.2,30.6c0.8,3.9,6.2,5.9,6.4,10.2
		c0.5,7.1,5.5,12.6,6.4,19.5c0.5,3.9,6.8,11.5,10.9,12.2c0,2.7,0,5.3,0,8c2.2,5.1,2.1,10.6,2,16c-4.3,0.2-5.2-3.8-7.3-6.2
		c-0.7-0.7-1-1.8-1.1-2.7c-0.8-7.5-5.3-13-10.3-18.1c-3.1-3.1-4.9-6.6-4.6-11.1c0.1-2-0.1-4-2.4-5c-2.6-1.1-3.3-3.3-3.7-6
		c-0.5-3.9-1.4-7.8-3.3-11.4c-0.7-2,0-3.9,0.6-5.8c0.8-2.8,0.7-5.5-0.1-8.3c-2.4-7.8-1-15.8-0.7-23.7
		C522.7,218.6,523.2,218,523.7,217.6z"/>
	<path class="st4178" d="M523.7,257.5c3.9,2.9,3.2,7.5,3.9,11.5c0.4,2.2,0.8,4,3.1,4.3c2.7,0.3,3.4,1.9,2.9,4.1
		c-1.8,8,4,12.9,8.7,17c3.5,3.1,4.5,6.6,5.1,10c1,5.8,4.2,9.5,8.3,13c3.5,2.4,3.8,6.8,6,10c-2.3-0.1-4.7-3.9-7,0
		c-0.3-0.3-0.7-0.7-1-1c-4.8-4.6-6.1-11.7-11.7-15.7c-0.7-0.5-1.2-1.3-1.3-2.1c-0.8-6.9-5.8-11.3-9.4-16.6c-2-3-3-6.2-2.9-9.8
		c0.1-2.3,0.4-4.7-1.5-6.6c-2.7-5.5-4.9-11.2-3.7-17.5C523.3,257.9,523.5,257.7,523.7,257.5z"/>
	<path class="st4179" d="M527.7,275.5c3,0.6,2,3.1,1.9,4.8c-0.2,5.2,1.9,9,4.6,13.5c2.5,4.2,8.7,7.1,7.2,13.7
		c-0.2,0.8,0.9,2.3,1.8,2.8c3.7,2.2,5.3,5.5,6.7,9.4c0.9,2.3,5,3,3.8,6.8c-1.3,1-1.8,3.2-4,3c-2.8-3-5.2-6.4-8.7-8.8
		c-0.7-0.5-1.3-1.1-1.6-1.9c-3.2-8.3-9.3-15.3-10.8-24.2c-0.5-2.7-3.2-3.2-3.8-5.5c-0.5-2.4-0.5-4.8,0.2-7.1
		C526.6,280.1,526,277.3,527.7,275.5z"/>
	<path class="st4180" d="M525.7,289.5c2.9,0.4,4.6,1.7,4,5.1c-0.3,1.8,0.4,3.5,1.4,5.1c3,5.1,5.9,10.2,7.7,15.9
		c1.5,4.8,7.1,6.1,9.5,10.4c0.6,1.2,1.5,2.1,1.4,3.5c-2.4,0.4-2.5,2-1.3,3.5c0.8,1.1,1.4,2.1,1.3,3.5c-2.9,3-5,1.5-6.4-1.5
		c-0.9-1.9-1.5-4-3.5-5.1c-0.7-3.9-2.7-7.1-5.4-9.9c-0.7-0.7-1.7-1.3-2-2.2c-3.4-8-8.1-15.5-9.9-24.1
		C522.9,291.8,521.9,288.8,525.7,289.5z"/>
	<path class="st4181" d="M539.7,329.5c2.4-0.2,4,0.2,4.1,3.2c0.2,3.5,2.1,5.4,5.9,3.8c1.7,3.9,4.5,7.4,4,12
		c-1.2,3.6-0.4,6.7,2.3,9.3c1.3,1.3,3.2,2.7-0.3,3.7c-4.3-1-4.8-5.9-8-8c-2.1-0.1-3.2-1.5-3-3.2c0.7-6.3-3.8-10.6-5.6-15.9
		c-0.5-1.5-1.6-2.9-0.8-4.6C538.7,329.2,539.2,329.1,539.7,329.5z"/>
	<path class="st4182" d="M538.7,329.5c1.8,4,2.3,8.7,5.7,11.8c1.8,1.7,1.1,3.6,1.2,5.5c0.1,2.4-0.4,5,2,6.7c-0.9,2.6,2,6.2-2,8
		c-2.4,1.3-2.7-0.9-3.5-2.2c-2.6-4.2-4.3-8.8-5.8-13.5c-0.8-3-2.5-5.7-3.3-8.7c-0.9-3.1-2.1-6.4,0.8-9.2
		C535.9,327.4,538,326.4,538.7,329.5z"/>
	<path class="st4183" d="M537.7,345.4c2.1,5.6,3.5,11.6,8,16c0.1,0.6,0,1.8,0.2,1.9c5.7,1.5,5.4,7.3,7.7,11.1c0.6,2.5,2.1,5.2-1,7.2
		c-3.3-1.8-3.5-6.4-7.2-7.9c-6.2-7.3-9.2-16.4-13.5-24.7c-0.1-1.7,0.4-3.1,1.8-4.1C535.3,344.3,536.5,344.4,537.7,345.4z"/>
	<path class="st4184" d="M552.7,381.4c2.1-2.1-0.8-4.9,1-7c3.3,1.8,4.9,5.2,7,7.8c1-0.9,2-1.9,3-2.8c-0.7,2-1.3,4-2,6
		c-1.2,2.6-2.4,5.2-3,8c-0.8,0.4-1,1.2-1,2c-4.9-1.5-2.7-5.7-3.3-8.8C554.7,384.6,553.2,383.1,552.7,381.4z"/>
	<path class="st4185" d="M523.7,217.6c-0.1,6.3-0.3,12.6-0.3,18.9c0,1.5,0,3.2,0.7,4.4c2.6,4.3,2.1,8.5,0.2,12.8
		c-0.5,1.1-0.4,2.6-0.5,3.9c0,0,0,0,0,0c-2.3,0-3.1-2.1-3.8-3.4c-2.4-5.2-4.9-10.5-5.4-16.2c-0.5-5.9-0.6-11.9,0.4-17.8
		c0.3-1.7,0.1-3.3-0.5-4.9c-1.3-3-1.7-5.7,1.7-7.7c1.7-1,0.4-2.8,0.7-4.1c0.5-3.9,5.4-4.8,6-8.7c0.4-1,1-1.7,2.1-1.9
		c1.6-1.3,3.3-2.7,4.9-4c-0.1,1.1,1.2,2.6-0.1,3.2c-5.1,2.3-4.2,6.8-3.9,10.8C526.2,207.9,525.1,212.8,523.7,217.6z"/>
	<path class="st4186" d="M594.6,685.1c-7.5-4.3-15.3-8.3-22-14c8,2.9,14.8,8.1,22.2,11.9c4.3,2.2,9.6,2.8,12.8,7
		C602.9,689.6,599.2,686,594.6,685.1z"/>
	<path class="st4187" d="M575.8,606.5c0.4-0.4,0.8-0.8,1.3-1.2c1.3,0,2.6,0.2,3.3,1.7c0.4,2.4-0.7,4.4-1.8,6.4
		c-2.1,3.7-3.7,7.6-3.9,11.9c-2.1,4.8-5.3,8.9-7.4,13.7c-0.2,1.1-1.6-0.7-1.7,0.7c-0.1,2-1.2,3.7-2,5.5c-2,2-2.4,5.2-4.8,7
		c-0.9-0.4-0.9-1.2-1.1-2c6.4-12.8,11.8-26,17.4-39.1C575.7,609.4,576.3,608.1,575.8,606.5z"/>
	<path class="st4188" d="M557.7,656.1c-0.8,0-1.6,0.3-2.1,1.1c-4-1.6-2.9-3.9-0.5-6.9C555.8,652.6,555.6,655,557.7,656.1z"/>
	<path class="st4188" d="M555.7,657.1c1.5,0.8,3.3,1.2,4,3C557.9,659.7,556,659.4,555.7,657.1z"/>
	<path class="st4166" d="M565.6,625.1c0.2-1.6,0.8-3,2-4.1C567.8,622.8,567.6,624.4,565.6,625.1z"/>
	<path class="st4188" d="M561.8,658c-1.7,0-3.1-0.6-4.1-2c0.6,0,1.3,0,1.9,0.1C560.4,656.8,561.1,657.4,561.8,658z"/>
	<path class="st4189" d="M486.8,246.6c2.7-4,5.3-8,8-12c3.4,1.4,1.9,4,1.4,6.2c-0.2,1-1.3,1.7-1.2,3.1c0.9,7.2,1,14.5,1.9,21.7
		c0.3,3,3.7,5.1,3.6,8.5c0,0.7,0.1,1.3,0.1,2c-0.3,1.5-0.5,3.1-1.3,4.5c-1.8,2.1-4.2,1.8-6.5,1.6c-4.1-3.1-3.4-7.9-3.8-12.1
		c-0.6-5.2,0.2-10.5-2.1-15.5C484.5,251.9,488.2,249.2,486.8,246.6z"/>
	<path class="st4190" d="M486.8,254.6c2.8,0.8,2.9,2.9,3.1,5.4c0.3,6.4-0.1,13,1.8,19.3c0.4,1.2,0.7,2.2,2.1,2.3
		c5.9,4.5,3.8,10.3,2.4,15.9c-0.8,0.9-1.8,1-2.9,0.7c-3.7-6.4-5.6-13.5-7.5-20.6c-0.5-1.7-0.5-3.6-1.6-5.2c-0.4-0.5-0.8-1.1-0.4-1.8
		c-1.5-4.9,2.7-9.7,0.4-14.8C483.6,254.2,486.3,255.8,486.8,254.6z"/>
	<path class="st4191" d="M483.8,270.5c0.3,0.3,0.7,0.7,1,1c-0.8,4.4,1.1,8.7,0.3,13c-0.3,1.8,0.1,3.6-1.2,5.2
		c-0.7,0.4-1.5,0.4-2.2,0.1c-2.2-3.1-3.6-6.4-2.9-10.3c0.7-2,0.7-4,0-6C481.7,274.5,481.5,270.5,483.8,270.5z"/>
	<path class="st4192" d="M478.8,279.5c1,3.3,2,6.7,3,10c0.9,6.1,5.7,11,5.4,17.5c-0.1,2.6,2.7,4.2,2.2,6.9c-1.5,2.1-2.5,4.6-5.7,4.7
		c-0.3,0-0.7,0-1,0c-4.5-7.3-5.2-15.4-5.4-23.7c-0.1-2.8-0.6-5.9-2.6-8.3C475.1,283.6,473.8,279.8,478.8,279.5z"/>
	<path class="st4193" d="M499.8,276.5c-1-3.2-1.2-6.5-4.1-9.1c-1.9-1.7-0.1-5.2-0.6-8c-0.8-4.2-0.3-8.5-1.1-12.8
		c-0.3-1.9-1.2-4.4,1.4-6.3c0.4-0.3,1.7-4-0.5-5.8c0.1-1.7,0-3.5,1-5c1.7-0.6,1.9-2.4,2.9-3.6c1.1-1.5,0.9,0.1,1.1,0.6
		c1.1,1,1.3,2.3,1.1,3.7c-1.4,8.3,1.1,16.5,0.4,24.8c-0.1,1.6-0.3,3.8,1.5,4.5c4.7,1.8,4.5,5.6,4.5,9.5c0.8,6.9,0.7,13.9,3.9,20.3
		c0.6,1.3,0.7,2.5,0.1,3.8c-4,1.8-5.3-1.6-5.8-3.9C504.4,284.5,502.6,280.3,499.8,276.5z"/>
	<path class="st4194" d="M505.8,269.5c-0.3-3.5,1.9-8.3-4.9-8.4c-1.7,0-1.2-2.5-1.1-3.9c0.8-10.2-2.4-20.4,0-30.6
		c0.9-1.5,2.6-1.4,4-2c2.4,0.4,3.2,2.1,3.3,4.3c0.1,3.6,1.2,7.4,0.8,10.8c-0.6,4.5,0.8,8.4,1.3,12.6c-1.3,7.6,2.9,13.7,5.7,20.1
		c1.8,4.2,3,8.4,2.3,13c-0.1,1-0.3,2.1-1,2.9c-3.9-1-8.9-6.7-8.8-10.8C507.5,274.6,506.3,272.2,505.8,269.5z"/>
	<path class="st4195" d="M507.8,252.6c-2-8-1.8-16.1-2-24.2c0-1.8-1.1-2.6-2-3.7c2.4-2.5-0.8-7.2,3.6-8.9c-0.5-1-1.1-1.9-1.6-2.9
		c1.1-4.4,3.4-7.6,8.3-8.2c0.8,0.8,1.2,1.7,1,2.9c-2.9,4.9-4.8,9.7-2.4,15.8c1.5,3.9,0.5,8.5,0.4,12.8c-0.1,4.2,0.4,8.2,0.9,12.3
		c0.2,1.8,0.2,3.6-1,5.2C510.9,255,509.1,254.7,507.8,252.6z"/>
	<path class="st4196" d="M513.6,204.3c-3.1,2.4-5.3,5.5-7.8,8.5c-0.4,0.3-0.7,0.5-1.1,0.8c-0.6-0.6-1.3-1.3-1.9-1.9
		c1.3-1.7,2.7-3.3,4-5c3.5-2.9,5.7-7.2,9.8-9.4c1,0.7,1.1,1.7,1.1,2.9C517,202.1,516.3,204.2,513.6,204.3z"/>
	<path class="st4197" d="M483.8,318.5c2.2-0.7,2.1-4.2,5-4c0.9-0.3,1.4,0.2,1.9,0.8c2.3,4.2,6.4,7.9,4.1,13.5
		c-0.5,1.2,1,1.9,1.6,2.9c1,1.8,3,3.3,1.5,5.8c-0.7,0.3-1.3,0.7-2,1c-2.1-2.2-3.2-5.4-6-7C490.8,325.7,484.9,323.2,483.8,318.5z"/>
	<path class="st4198" d="M640.6,374.5c-0.4,1.9,0.9,4.1-1,5.8c-0.2,0-0.5-0.1-0.7-0.1c-2.5-1.3-3.8-3.6-5.3-5.7
		c-1.6-2.5-3.1-5-4.6-7.5c-1.6-2.8-0.2-3.6,2.6-2.7C636.4,366.1,638.7,370.1,640.6,374.5z"/>
	<path class="st4095" d="M631.6,364.3c-1.7,0.1-3.4,0.5-1.5,2.5c2.1,2.2,3.1,5,4.6,7.6c0.4,0.4,0.4,0.9,0,1.3
		c-2.3,0.8-3.3-0.8-4.1-2.5c-0.7-1.5-1.4-3-2.6-4.2c-3.6-5.6-8.4-2.6-12.8-1.6c-1.2,0.3-2.3,0.7-3.5,0.8c-0.8,0-1.5-0.2-2-0.7
		c0.4-3.8,3.9-2.9,6.1-4.1C621,362.4,626.4,361.1,631.6,364.3z"/>
	<path class="st4199" d="M615.6,363.4c-2,1.3-4.1,2.6-6.1,3.9c-1.4,3.9-4.2,6.4-7.9,7.9c-0.7-1.9-0.2-3.5,1-4.9
		C606.2,366.6,609.7,362.8,615.6,363.4z"/>
	<path class="st4200" d="M601.6,375.3c2.5-2.8,4.8-5.8,7.9-7.9c0.7,0,1.5,0,2.2,0c0.6,1.4-0.1,2.7-0.8,3.7
		c-3.2,4.4-5.5,9.5-9.1,13.6c-4.9-0.1-1.7-3.4-2.1-5.3c0.3-0.7,0.5-1.4,0.8-2C600.9,376.7,601.2,376,601.6,375.3z"/>
	<path class="st4201" d="M599.7,379.5c2.1,1.1-1.7,4.5,2,4.9c1.1,1.3,0.4,2.6,0.2,4c-0.1,0.7-0.4,1.3-0.8,1.9
		c-0.3,0.3-0.7,0.5-1.1,0.5c-0.6-1.5-2.2,0.7-2.8-0.7c-0.9-2.3,0.6-4.4,0.5-6.6C597.9,381.9,598.4,380.5,599.7,379.5z"/>
	<path class="st4202" d="M597.6,383.4c2,2.3-0.3,4.7,0,7c0.6,0.7,0.5,1.4-0.1,2c-1.5,1.2-2-0.6-3-1
		C595,388.5,595.7,385.7,597.6,383.4z"/>
	<path class="st4203" d="M598.6,404.4c0,2.7,0,5.3,0,8c-0.5-0.4-1.3-1.1-1.5-1c-1.1,0.8-0.2,2-0.6,3c-1.7-3.7-2.6-7.4-0.1-11.1
		c-0.3-0.4-0.2-0.8,0.2-1.1C598.4,402,597.9,403.7,598.6,404.4z"/>
	<path class="st4204" d="M598.6,404.4c-0.6-0.7-1.3-1.3-1.9-2c-0.8-2-0.8-4,0-6c0.7-1.2,1.2-2.5,0.9-4c0-0.7,0.1-1.3,0.1-2
		c0.7,0.4,1.4-3.3,2,0c1.5,2,1.7,3.9-0.1,5.9C597.7,398.8,599.1,401.7,598.6,404.4z"/>
	<path class="st4205" d="M596.7,396.4c0,2,0,4,0,6c0,0.3-0.1,0.6-0.2,0.9C595.2,401,595,398.7,596.7,396.4z"/>
	<path class="st4206" d="M600.7,388.4c0.3-1.3,0.6-2.7,1-4c2.2-6.3,7-11.1,10.1-17c1-0.3,2-0.6,2.9-1c0.4,0.9-0.1,2.6,1.7,2.6
		c3.4,0.1,6,1.6,7.8,4.4c1.2,1.8,2.1,0.9,2.8-0.5c0.7-1.5,0.3-3.4,1.7-4.5c3.4,1.1,1.7,6.6,6,7c0.7,0.9,1.9,2,1.7,2.9
		c-0.7,4,2.2,1.4,3.3,2c0,0,0,0,0,0c0.3,1,0.7,2,1,3c0,0.7,0,1.3,0,2c-4.6,1.1-5.6,4.4-5.2,8.5c0.3,2.9,1.3,5.8-0.8,8.4
		c-0.6,0.5-1.3,1.6-1.9,1.5c-1.4-0.1-0.8-1.3-0.8-2.3c0.2-4.1-1.2-5-4.9-3.2c-2.5,1.2-5,2.6-8,1.3c-2.2-1-3.4,1.9-5.5,1.7
		c-1.7,1.1-3.5,1.5-5.4,0.7c-0.9-6.2-0.3-12.3,1.9-18.2c1.1-3,1.2-7,6-6.7c-0.4-1.9-1.5-2.6-2.8-3.3c-0.7,2.1-1.4,3.9-3.7,4.9
		c-3.3,1.4-2.9,5.4-4.8,7.8C603.8,387.8,603.5,391.2,600.7,388.4z"/>
	<path class="st4207" d="M634.6,402.4c1-4.5-1.4-8.9-0.2-13.6c1-4.1,2.9-4.6,6.2-3.4c-0.5,6.5-1.5,12.8-6,18
		C634.6,403,634.6,402.7,634.6,402.4z"/>
	<path class="st4208" d="M607.6,401.4c2,0,4,0,6,0c-3.3,3.3-1.7,7.9-2.8,11.8c-1.2,4.1-3.2,5.5-7.2,4.1c-4.3-4.5-4.1-10-4-15.6
		c0-1.8,0-3.6,0-5.4c0-2,0-3.9,0.1-5.9c0.3,0,0.6,0,1-0.1c0.8,0.6,1.3,1.6,1.2,2.5C601.3,397.5,603.4,400.1,607.6,401.4z"/>
	<path class="st4209" d="M555.7,449.4c0,1,0,2,0,2.9c-2.6,0-2.9-2.1-3-3.8c-0.1-2.9-1-5.5-1.3-8.3c0-2.4-0.2-4.8-1.9-6.8
		c-1.1-1.3-1.1-3-0.6-4.6c1.3-2.6,3.9-4.6,3.5-8c0.4-1.4-0.1-3.5,2.4-3.4c0,8,0,16,0,24C556.1,443.9,555.6,446.7,555.7,449.4z"/>
	<path class="st4210" d="M554.8,417.4c-1.6,1-0.9,2.6-1.1,4c-2.8-0.4-2-2.8-2.5-4.5c-0.8-2.9-1.2-5.8-0.3-8.7
		c0.9-1.7,2.1-0.8,3.1-0.2c0.6,0.4,1.1,0.9,1.6,1.4c0,0,0,0,0,0c0,1.3,0,2.7,0,4C555.5,414.7,556.1,416.3,554.8,417.4z"/>
	<path class="st4211" d="M558.7,393.4c-0.2-3.1,0.9-5.7,3-8c0.2,3.2-2.6,5.6-2,8.9c-0.3,0.1-0.6,0.1-0.9,0.2
		C558.7,394.1,558.7,393.7,558.7,393.4z"/>
	<path class="st4211" d="M555.7,409.4c-0.7,0-1.3,0-2,0c-0.9-2.9-0.6-6-1.5-9c-0.6-4.1,0.4-8.2,0.2-12.3c0.5-1.6,0.5-3.8,3.3-2.7
		c0.4,3.4-1.1,7.1,2,10C556.1,399.9,555.2,404.5,555.7,409.4z"/>
	<path class="st4212" d="M706.5,325.4c0.4-2,1.1-3.9,3-5C709.8,322.9,708.6,324.5,706.5,325.4z"/>
	<path class="st4212" d="M706.5,327.5c-1,0.7-2,1.3-3.1,2c0.2-1.9,1.5-3.1,3-4C706.5,326.1,706.5,326.8,706.5,327.5z"/>
	<path class="st4213" d="M690.5,622.1c-2.2-1.5-2.1-3.7-2-6c-0.6-11.8,0-23.3,3.3-34.8c2.9-10.3,4-21.1,0.6-31.7
		c-0.2-0.4-0.2-0.8-0.2-1.3c0.8-0.7,1.8-1.1,2.3-2.1c0.7-0.3,1.3-0.7,2-1c1.4,1.6,1.2,3.1,0.2,4.7c1.6-2.3,0.3-5.4,1.8-7.7
		c1.4-1,0.9-3.5,3-4c-1.4,11.9-1,24-3.7,35.9c-0.5,2.4-0.4,5-0.2,7.4c0.3,7.5-1.4,14.7-3.2,21.9
		C692.8,609.6,692.8,616.1,690.5,622.1z"/>
	<path class="st4214" d="M681.6,542.2c-1.7-3-3.4-6-5.1-8.9c3.5,2.1,5.5,5.7,8.2,8.5c1.4,1.4,2.4,3.3,4.7,3.5
		c0.8,0.7,0.9,1.6,0.6,2.6c-0.2,0.4-0.6,0.7-1.1,0.9c-1,0.2-1.9-0.1-2.7-0.5c-0.6-0.3-1.2-0.7-1.7-1.1c-0.3-0.3-0.6-0.6-0.9-1
		c-0.7-1.1-1.5-2.1-2-3.3C681.7,542.6,681.6,542.4,681.6,542.2z"/>
	<path class="st635" d="M698.5,542.2c-0.3,2.7,0.8,5.6-2,8.8c0-2.5,0-4.2,0-5.8C697.2,544.2,697.9,543.2,698.5,542.2z"/>
	<path class="st635" d="M689.6,548.2c-0.1-1-0.1-2-0.2-3c1.7,0.6,3,2.8,5.1,1c0,1.3-0.7,2-2,2C691.5,549,690.6,550.5,689.6,548.2z"
		/>
	<path class="st4215" d="M680.6,546.2c-1.9-1.3,0.3-2.7,0-4c0.4,0,0.7,0,1.1-0.1c0,0,0,0,0,0c0.9,1.4,1.9,2.8,3.1,3.9l0,0
		C683.5,549.6,682,547.2,680.6,546.2z"/>
	<path class="st635" d="M692.5,549.2c2.2,0.8,2.9,2.9,2.9,4.8c0.1,10.6,0.3,21.1-3.3,31.3c-2.9,8.2-2.6,17-2.6,25.6
		c0,1.7,0.7,3.7-1,5.2c-2.3-2.2-2.7-5.3-4-8c1.1-1,1.1-2,0-3c-1.5-3-3.8-5.7-3-9.5c0.2-0.8,0.1-1.9-0.9-2c-3.7-0.4-2.9-3.2-3.1-5.5
		l0,0c-4.8-5.5-4.4-12.4-5-19c0-0.2,0-0.5,0-0.7c0.4-2,1.7-3.7,1.3-5.8c0.2-2.7,1.7-4.8,3-7c2.1-4.2,7-3.6,10.2-5.9
		c0.4-0.2,0.9-0.3,1.4-0.4C689.8,550,691.1,548.8,692.5,549.2z"/>
	<path class="st4216" d="M671.5,579.3c0.6,2,1.3,3.9,1.9,5.9c-0.4,0.2-0.8,0.3-1.3,0.1c-1.5-0.6-1.4-2.9-3.3-3
		c-0.8,0.7-1.3,1.5-2.1,2.1c-5.6,3.5-11.3,6.9-17.9,8.5c-1,0-1.9-0.5-2.7-1.2c-0.6-0.8-1.1-1.6-0.5-2.7c3.2-2.3,7.4-2.6,10.3-5.5
		c3.3-2.4,7.4-3.7,9.7-7.3c1.2-3.6,1.1-7.9,4.7-10.5c0.6,0,1,0.1,1.5,0.5c0.5,1.1,0.5,2.1-0.5,2.8c-0.3,0.7-0.6,1.3-0.9,2
		C668.4,574.1,670.6,576.6,671.5,579.3z"/>
	<path class="st4217" d="M672.5,585.2c0.3,0,0.6,0,0.9,0c5.8,14.1,11.8,28.3,16.1,43c-0.5,0.7-0.1,1.7-1,2.2c-0.4,0.1-0.8,0-1.2-0.2
		c-0.3-0.3-0.5-0.7-0.7-1.1c-0.5-1.4-1.2-2.8-1.7-4.2c-0.6-2.3-0.3-4.8-1.7-7c-0.2-0.4-0.4-0.8-0.5-1.2c-0.9-2.8-2.1-5.6-3.3-8.3
		c-1.6-5.5-4.2-10.6-6.4-15.9c-0.2-0.4-0.4-0.9-0.6-1.4C671.8,589.1,670.8,587.1,672.5,585.2z"/>
	<path class="st4218" d="M671.3,569.1c0.1-0.9,0.2-1.9,0.3-2.8c-0.6-4.2,1.1-7.8,3.5-11.2c0.5-0.3,0.9-0.4,1.5-0.4
		c0.8,0.2,1.5,0.6,1,1.6c-1,2.3-2.7,4.3-3,7c-0.1,2.2-1.3,4-2,6c0,0,0,0,0,0C672.1,569.1,671.7,569.1,671.3,569.1z"/>
	<path class="st4219" d="M688.5,630c0.1-0.7,0.2-1.5,1-1.8c0.4,1,0.9,2,1.3,3c0,1.6,0.6,2.9,1.8,4c0.1,1,0.2,2,0.3,2.9
		c-0.2,0.1-0.3,0.1-0.5,0.1c-0.4,0.2-0.8,0.2-1.2,0.1c-0.6-0.3-1-0.8-1.4-1.4c-0.7-1.2-1.1-2.4-1.4-3.7
		C688.2,632.1,687.8,631,688.5,630z"/>
	<path class="st4218" d="M671.5,579.3c-1.8-2.6-5-5-1-8.3C670.8,573.8,671.1,576.6,671.5,579.3z"/>
	<path class="st4213" d="M684.5,605.1c1,0.1,2,0.4,1.8,1.7c-0.1,1-1,1.1-1.8,1.3C684.6,607.1,684.6,606.1,684.5,605.1z"/>
	<path class="st4220" d="M691.4,638.1c0.3,0,0.7,0,1,0.1c0.1,0.7,0.3,1.3,0.4,2c-1.5,14.2-7.8,26.9-13.2,39.9
		c-2.3-3.5,1.2-6.5,0.7-9.9c2.2-7.7,6-14.8,8.4-22.4C690.1,644.7,689.5,641.1,691.4,638.1z"/>
	<path class="st4221" d="M631.7,539.4c0,2,0,3.9,0,5.9c1.2,2.5,0,5-0.2,7.5c-0.9,3.5-1.8,7.1-3.2,10.4c-2.3,5.5-0.9,9.8,3.8,13.3
		c3.5,2.7,6.7,5.8,10,8.8c0.5,0.6,0.8,1.3,0.4,2.1c-0.9,0.7-1.9,0.8-2.9,0.7c-7-2.8-11.8-8.3-15.8-14.3c-2.5-3.8-0.7-8.3,1.2-12.2
		c2.9-6.8,3-14.4,5.5-21.3C631,539.9,631.4,539.7,631.7,539.4z"/>
	<path class="st4222" d="M630.6,540.2c-1,7.5-1.4,15.1-5,22c-0.6,0.6-1,1.6-2.2,1c-0.3-0.7-0.4-1.3,0.4-1.8c2.6-6.8,4.5-13.7,5-21
		c0.2-0.2,0.4-0.3,0.7-0.3C629.9,540.2,630.2,540.2,630.6,540.2z"/>
	<path class="st4223" d="M630.5,553.2c-0.1-2.7,0.1-5.4,1.2-7.9c0.2,0,0.4,0,0.7,0c3.9-0.2,7.2-2.3,10.5-4.1
		c7.2-4,14.4-7.9,21.7-11.9c5.1,3.2,9.4,7,11.1,13.1c-0.7,0.4-1.7-0.2-2.4,0.5c-1,1.1-1.3,3.6-3.5,2.5c-1.7-0.8-1.4-2.8-1.5-4.5
		c-0.1-1.1,0.4-2.3-0.5-3.3c-1,0-0.9,0.8-0.9,1.4c-0.6,7.5-6,10.1-12.5,6.1c-1.2-0.8-2.3-2-3.8-2.2c-1-0.1-2.6-0.1-2.6,0.8
		c0.4,7.2-3.8,3.3-7,2.7c1.5,1.2,3.1,2,2.6,3.8c-0.4,1.7-2,2.1-3.5,2.2c-1.5,0.1-3-0.2-4.4-0.8c-3.3,0.2-1.1,3.9-3.2,4.9
		C630.5,556.2,630.4,554.8,630.5,553.2z"/>
	<path class="st4224" d="M675.5,542.2c-2.1-5.7-7.5-8.5-11-13c0.5-0.9,1.3-1,2.2-1c4.9,6,10.7,11.3,12.8,19.1
		c0.3,0.4,0.3,0.8,0.1,1.2c-2.2,1.7-2.2,4.8-4.1,6.6c-1.3,3.7-2.6,7.4-3.9,11.1c-0.4,0-0.7,0-1.1,0c-0.6-0.6-0.9-1.3-0.9-2.1
		c0.2-2.5,0.6-5,1.8-7.3c2-2.5,1.9-5.8,3.3-8.5C676.4,546.5,674.4,544.1,675.5,542.2z"/>
	<path class="st4225" d="M679.5,548.2c0-0.3,0.1-0.6,0.1-0.9c0.3-0.1,0.7-0.1,1-0.2c1.4-0.3,2.6,1,4,0.6c1.3,0.4,2.7,0.7,4,1.2
		c0.3,0.4,0.3,0.8-0.1,1.2c-0.3,0-0.6,0.1-0.9,0.1c-2.6,1-5.2,1.8-7.8,0.3C679,549.9,678.9,549.1,679.5,548.2z"/>
	<path class="st4126" d="M684.4,548.4c-1.3-0.4-2.6-0.8-3.9-1.2c0-0.3,0-0.6,0-0.9c1.4-0.3,2.8,1.7,4.2-0.2c0.6,0.4,1.1,0.8,1.7,1.2
		C686.6,549.2,685.4,548.6,684.4,548.4z"/>
	<path class="st4226" d="M549.7,428.4c0.2,2-0.4,3.9,2.1,5.5c2.1,1.3,0.6,4.3,0.9,6.5c-3.5,1.8-4.9-1.4-7-3c-0.8-1.5,0-4.2-3-4
		c-1.9-3.9-0.6-8-1-12c0.3,0,0.7,0,1,0C547.1,420.6,549.2,422.7,549.7,428.4z"/>
	<path class="st4227" d="M545.7,437.3c3.2-1,3.9,3.7,7,3c2,3.7-0.4,8.6,3,12c0,1.9,0.4,4-2,5c-2.7-1.9-5.1-3.9-5.1-7.7
		c0-1.1-0.6-2.2-0.9-3.3C545.8,443.6,544.7,440.7,545.7,437.3z"/>
	<path class="st4228" d="M549.7,428.4c-2-2.7-2.3-7.1-7-7c-1.5-6.7-8.3-10.8-9-18c1.3-1.2,2.8-1.3,4.3-0.9c3.2,3.5,7.6,6.1,8.9,11.2
		c0.8,3,3.6,2.1,5.7,2.6c0.2,1.7-0.7,3.6,1,5C554.2,424.7,552.4,426.8,549.7,428.4z"/>
	<path class="st4229" d="M538.7,403.4c-1.7,0-3.3,0-5,0c-1-2-1.8-4-4-5c-1.2-6.1-4.3-11.5-7-17c1.3,0,2.7,0,4,0
		c5.4,1.8,6.5,7.5,9.9,11.1c1.5,1.6,2.4,3.9,3.9,5.7c0.8,1.1,1.2,2.3,1,3.7C541.1,403.3,540.5,404.4,538.7,403.4z"/>
	<path class="st4230" d="M517.7,361.4c-3.6-0.8-2.3-4.6-3.7-6.8c-2.1-3.3-5.5-4.7-7.6-7.7c-1.2-1.7-1.9-3.4-1.6-5.5
		c0.7-0.8,1.7-1.1,2.7-1.2c4.7-0.2,6.6,3.9,9.6,6.3c0.4,0.4,0.5,0.9,0.8,1.2c0.8,0.8-2.5,0.4-0.3,1.7c5.2,3.1,7.6,8.1,8.2,14
		c-1,3,2,6.1,0,9.1c-0.7,0.3-1.4,0.3-2.1-0.3C522.8,368.2,520.4,364.7,517.7,361.4z"/>
	<path class="st4231" d="M523.7,372.4c0.7,0,1.3,0,2,0c2.5-0.6,3.9-0.6,4.9,2.8c1,3.4,5,5.1,7.6,7.6c0.5,0.5,0.9,1.1,1.1,1.8
		c0.4,3.7,0.2,7.4,2.1,10.8c1,1.7,0.1,2.7-1.8,2.9c-3.8-3.9-6.6-8.6-9.4-13.2c-1-1.7-3.2-1.9-3.6-3.8
		C525.2,378.6,523.6,375.8,523.7,372.4z"/>
	<path class="st4232" d="M507.8,341.4c-1,0-2,0-3,0c-2-2-3.5-4.8-7-4c-4.9-6.5-2.7-15.6-8-22c1-2.1,1.8-4.5,4.9-3.5
		c3.8,5.2,6.5,11,9.9,16.4C506.3,332.6,508.9,336.5,507.8,341.4z"/>
	<path class="st4233" d="M583.7,669c-2.3-1.3-4.7-2.7-7-4c-2.4-3.1-6.9-3.4-9-6.9c-0.3-0.5-0.2-0.9,0.2-1.2c3.7,0.6,6.8,2.3,9.8,4.4
		c3,2.1,6.2,3.9,10.1,3.1c0.8-0.2,1.8-0.4,2.4,0.2c6.1,5.2,13.5,1.9,20.2,2.9c2.7,0.4,4.3-1.7,5.3-4.3c1.4-3.4,4.8-4.1,5.8-1.2
		c1.7,4.8,5.7,5.9,9.1,8c1.1,1.1,1.9,2.6,3.4,3.3c1,0.6,2.2,0.9,2.8,2c-0.2,1.6-2.9,3.7,1.1,4.4c0.8,0.1,1.3,1.5,1,2.6
		c-0.4,1.4-1.5,1.7-2.7,1.8c-4.2,0.5-8.3,0.4-12.1-1.9c0.9,1.3,3.4,2.1,2.2,3.6c-1.4,1.8-3.2,0.1-4.7-0.8c-6.1,0-10.3-4.5-15.7-6.3
		c-4.6-1.5-8.9-3.8-13.3-5.7C589.6,671.8,587,669.7,583.7,669z"/>
	<path class="st4234" d="M621.6,685.1c1.4-0.1,2.7,1.3,4.3,0.7c-1-2.8-4.1-1.9-5.9-4.2c2.2-0.8,4.5-1.1,6.1,0
		c3.1,2.1,6.4,1.3,9.6,1.4c1,0.1,2.7,0.2,2.3-1.6c-0.3-1.2-1.3-2.6-2.9-1.7c-1.1,0.5-2.2,2.3-3.1,0.3c-0.8-1.9,1.5-1.6,2.3-2.4
		c0.9-0.7,1.5-1.7,2.3-2.5c0.8-1.2,2-1.9,3.3-2.3c5.3,3.8,8.4,9.6,13,14.2c0.5,0.5,0.3,1.3,0,1.9c-4.2,2.3,0.9,4.5,0.2,6.2
		c0.2-1.2-1.1-3.1,0.2-5c0.4-0.3,0.8-0.4,1.3-0.3c2.1,2.8,4.3,5.7,4.9,9.3c0,1.8-0.1,3.4-2.7,2.1c-2-1-3.9-2.4-6.3-2.2
		c-5.5-3.9-12-6.1-18-8.9c-0.9-0.7-1.5-2.1-3-1c-1.3-0.6-2.5-1.1-4-1C624.6,686.6,622.1,687.1,621.6,685.1z"/>
	<path class="st4233" d="M629.6,689c0.1-0.8,0.2-1.8,1.3-1.6c1.3,0.3,1.6,1.4,1.7,2.6C631.6,689.7,630.6,689.4,629.6,689z"/>
	<path class="st4235" d="M630.6,671.1c-0.7-0.5-1.3-1.2-2.1-1.3c-3.6-0.6-7.5-0.7-7.1-6.2c0.1-0.8-1-2.6-2.8-2.3
		c-1.6,0.3-2.1,1.5-2.1,3c0.2,3.9-2.9,4.4-5.3,4.6c-3.9,0.3-7.8,0-11.7-0.3c-1.5-0.1-2.9-0.5-4.3,0.3c-2.1,1.2-3.6,1.5-4-1.7
		c-0.1-1.4-1.8-2.5-3-1.6c-4,2.9-7.6-0.3-9.7-1.9c-3.5-2.7-7.8-3.6-11-6.5c-2.8-1-4.6-4.1-8-4c-0.3-0.3-0.7-0.7-1-1
		c0.7-2.7,1.3-5.4,4-7c1.1-0.7-0.3,3.9,2,0.9c2.2-2.9,4.5-1.9,5.9,0.6c1.9,3.4,4.8,4.3,8.2,4.6c6.7,0.6,13.6,2,19.2-3.5
		c0.9-0.9,2.6-1.7,3.7-1.1c4.4,2.5,9.3,1.4,13.9,2c2.5,3.6,5.2,6.5,9.2,8.9c3.6,2.1,8.6,5.4,7.7,11.5
		C632.2,670,631.7,670.8,630.6,671.1z"/>
	<path class="st4236" d="M559.7,653.1c4.6-2.5,6,1.3,8,4c0,0.3,0,0.7,0,1C564.4,657.4,561.8,655.6,559.7,653.1z"/>
	<path class="st4237" d="M659.6,699.1c-2.3-2.6-4.1-5.5-5.1-8.8c-0.3-0.4-0.3-0.8-0.1-1.3c2.2-0.6,2.8,1.3,3.9,2.4
		c2.1,2.7,3.9,5.7,6,8.3c0.4,0.8,1.1,1.6,0.2,2.5c0,0.3,0,0.6,0,0.9C662.8,701.8,661.2,700.5,659.6,699.1z"/>
	<path class="st4238" d="M681.5,670.1c0.5,3.6-2.1,6.5-2,10c-2.5,8.1-6.5,15.6-9.7,23.4c-0.7,1.7-2,0.4-3,0.7
		c-0.5-0.7-0.9-1.4-1.4-2c0.2-0.1,0.3-0.3,0.5-0.4c3.3-3.4,4-8,5.7-12.2c1.8-6.3,5.1-12,7.8-17.9
		C679.8,670.7,680.3,669.8,681.5,670.1z"/>
	<path class="st4239" d="M664.5,702.3c-0.3-0.8-0.6-1.5-1-2.3c-0.3-1-0.9-2-0.2-3c0.4-0.2,0.8-0.3,1.2-0.2c1.3,0.6,1.9,1.8,2.3,3.1
		c0.2,1-0.4,1.6-1.1,2.2c0,0-0.3,0.1-0.3,0.1C665.1,702.2,664.8,702.2,664.5,702.3z"/>
	<path class="st827" d="M605.6,591.2c0.6,0,1.3-0.1,1.9-0.1c1.7,0.2,3.4,0.1,4.8-0.8c3.3-2.2,6.3-4,7.7,1.9c0.5,2.3,3.2-0.9,4.9,1.7
		c4.3,6.5,4.3,13.3,3.3,20.6c-0.8,6.2-1.1,12.5-1.6,18.7c-1,2.9-0.1,7.2-5.3,7c-2-0.6-3-1.8-1.4-3.6c3.1-3.7,2.5-7.3,0.5-11.3
		c-2.2-4.4-1.1-9.4-1.8-14.2c-0.6-4.1-3.1-5.7-6.2-6.8c-2.8-1-5-3.6-8.9-1.1c-3.2,2.1-7.9,2.2-11.9,0c-1.2-1.1-2.7-2.3-1.8-4.2
		c0.7-1.4,2.2-1.2,3.5-1.2c4.7-0.1,7.3-4.3,11.3-5.7C605,591.8,605.3,591.5,605.6,591.2z"/>
	<path class="st4165" d="M591.7,585.2c-0.9-4,2.6-6.1,4.7-7.3c5.7-3.1,8.5-9.2,14.1-12.4c4.2-2.4,4.2-3.1,6.1,1.8
		c-0.1,0.6-0.1,1.8-0.3,1.9c-6.4,1.1-8.4,7.9-13.1,10.6C599.7,581.7,596.8,586.3,591.7,585.2z"/>
	<path class="st4240" d="M621.6,639.1c2,0.1,3.3-0.6,3-2.8c-0.2-1.8,0.5-2.7,2-3.2c0.7,0.3,1.3,0.7,2,1c0.4,5.7,1.9,11.5-2.2,16.5
		c-4.2-1.1-6.1-4.2-7.1-8.1C619.6,641.1,620.6,640.1,621.6,639.1z"/>
	<path class="st4241" d="M633.6,605.1c0-0.3-0.1-0.7,0-1c1.7-9.6-1.7-16.1-11-19.7c-2.6-1-4.6-3.8-3.9-7.3c0.4-0.3,0.9-0.3,1.3-0.1
		c4.8,2.7,8.7,6.5,12.9,10.1c5.8,5.1,3.7,11.2,2.3,17.2C635,605,634.3,605.3,633.6,605.1z"/>
	<path class="st4242" d="M633.6,605.1c1.7-6.3,3.9-12.6-2-18c0.7-0.7,1.5-0.8,2.4-0.7c1.8,0.5,3,1.7,3.6,3.5
		c1.3,9.9-1.4,19.4-2.1,29.2c-0.2,3.3-0.6,6.6-1.5,9.8c-0.4,1.4-0.3,3.1-2.4,3.2c-0.7-2.1-0.6-4.1,0-6.2c0.9-2-0.6-3.9,0-5.9
		C633.1,615.2,632.7,610.1,633.6,605.1L633.6,605.1z"/>
	<path class="st4243" d="M619.5,577.1c-0.3,0-0.5,0.1-0.8,0.1c-2.6-0.6-2.4-2.1-1.2-3.9c0.7-1,1.4-2.1,2.1-3.2
		c0.9-3,1.7-5.9,2.7-8.9c0.2-0.7,1.1-0.5,1.5,0.1c0,0.6,0,1.1,0,1.7c0.2,3.8-1.4,7.3-2.3,10.9C621,575.2,620.7,576.4,619.5,577.1z"
		/>
	<path class="st4244" d="M631.5,626c0.1,2.1,0.1,4.2,0.2,6.3c2,4.9,0.1,14.4-3.3,16.7c-0.8-5.1,0.1-10,1.2-15
		C630.1,631.4,630.2,628.5,631.5,626z"/>
	<path class="st4245" d="M629.6,634.1c-0.3,5-0.7,10-1,15c0.6,0.7,0.6,1.5,0,2.3c-1.3,0.4-2.7,1.2-2.8-1.2c2-1.4,1.6-3.7,1.7-5.5
		c0.1-3.6,0.5-7.1,1.1-10.6C628.9,634.1,629.3,634.1,629.6,634.1z"/>
	<path class="st4241" d="M633.6,605.1c-0.2,5.1,0.8,10.3-2,15C631.5,615,632.2,610,633.6,605.1z"/>
	<path class="st4246" d="M605.6,591.2c0,0.3,0,0.6,0,1c-6.9,4.2-14.4,5.4-22.4,4.5c-0.8-0.5-1.1-1.1-0.9-2c1.8-2.5,4.6-2.4,7.1-2.4
		c4.1-0.1,8.2-0.1,12.2-1C603,591.2,604.3,591.2,605.6,591.2z"/>
	<path class="st4247" d="M601.6,591.2c-5.3,2.8-11.4,1-16.8,2.9c-0.8,0.3-1.5,0.6-2.2,0.9c-1.2,0.7-1.4-0.4-1.8-1.1
		c0-0.8,0-1.5,0.8-1.9c4.3-1.3,8.8,0.5,13.1-0.9C597,591.2,599.3,591.2,601.6,591.2z"/>
	<path class="st4248" d="M594.7,591.2c-4.2,2-8.7,0.6-13.1,0.9c0-0.6,0-1.3,0.1-1.9c0.7,0,1.3,0.1,2,0.1
		C587.2,592.2,591,590.6,594.7,591.2z"/>
	<path class="st4249" d="M580.8,593.9c0.6,0.4,1.2,0.7,1.8,1.1c0.4,0.4,0.8,0.7,1.2,1.1c3.3,1.8,5.6,4.7,7.7,7.6
		c1.7,2.2,4.1,4.1,3,7.4c-1.5,1-2.4-0.1-3.3-1.1c-1.3-1.3-2.4-2.8-3.6-4.1c-4.4-4.6-4.4-4.6-8,0.2c-1.3,1-2,0-2.6-0.9
		c1.6-2.6,3-5.2,2.9-8.4C580.8,596,580.6,594.9,580.8,593.9z"/>
	<path class="st4250" d="M516.7,200.5c0-1.1,0-2.2-0.1-3.2c0.5-0.8,0.9-1.6,1.4-2.5c1.2-1,2.5-2.1,3.7-3.1c0.8,0.9,2.4-0.2,3,1.1
		c-0.4,0.6-0.7,1.2-1.1,1.8C521.9,197.2,520.5,200.2,516.7,200.5z"/>
	<path class="st4251" d="M512.7,253.6c-0.9-9.7-1.2-19.5-1-29.3c0-0.2,0.1-0.4,0-0.5c-4.6-6.7-0.6-11.9,3-17.1c-0.8-2.7,0.5-3.8,3-4
		c-0.1,1.4,0.3,2.6,1.1,3.7c1.2,1.8,0.5,3-1.5,2.9c-4.3-0.3-3.3,2.3-2.2,4.3c1.5,2.7,1.2,5.1,0.9,8c-0.5,6.6-1.7,13.8,0.3,19.7
		c1.9,5.4,2.5,12,7.3,16.3c-0.1,6.3,2,12.1,4,18c-0.7,2,1.6,5-2,6c-7.2-5.3-8.6-13.7-11-21.4C514,257.9,513.6,255.7,512.7,253.6z"/>
	<path class="st4252" d="M512.7,253.6c2,0.1,3,1.3,3,3.2c0.1,6.8,3.8,12.4,5.9,18.5c0.9,2.6,5.6,2.3,4.2,6.2c0,2.7,0,5.3,0,8
		c-3.2,0.6-1.6,3.2-2,5c-2.9,1.2-3.8-1.1-4.9-2.8c-1.2-1.9-1.7-4.3-3.1-6.2c1.4-6.7-1.7-12.4-4.6-17.7c-2.7-4.9-3.7-9.8-3.4-15.2
		C509.3,253.5,511,253.7,512.7,253.6z"/>
	<path class="st4253" d="M515.7,285.5c2.1,0.6,3.1,2.2,3.6,4.2c0.6,2.3,1.9,4.1,4.4,4.8c0.2,2.9,1.1,5.4,3,7.6
		c1.8,2.1,2.9,4.5,3.2,7.5c0.4,3.3,1.8,6.7,4.8,8.8c2.9,2.1,3.8,5.7,6.1,8.3c1.3,1.4-0.9,1.7-1.2,2.7c-0.3,0-0.7,0-1,0
		c-1.4-1.7-3.3-0.8-5-1c-3.6-2.4-4.8-6.6-7-9.9c-2.8-4.4-5.8-8.8-7.2-14c-1.2-3.6-1.8-7.3-4.1-10.5c-1.2-1.6-1.2-3.8,0.3-5.5
		C515.7,287.5,515.7,286.5,515.7,285.5z"/>
	<path class="st4254" d="M520.7,304.5c3.1,6.1,5.4,12.6,10.4,17.7c1.5,1.5,1.8,4.2,2.6,6.3c-1.1,5.2,1.1,9.7,3.2,14.2
		c0.4,0.8,0.5,1.8,0.8,2.8c-1.3,0-2.7,0-4,0c-2,1.2-3.8,0-4-1.4c-0.8-5.6-6-9.2-6.4-14.8c0-0.7-0.1,0.2-0.1-0.5
		c0.3-5.7,0.4-6.2-2.5-11.2c-2.1-3.7-2.8-7.6-3.4-11.7C518.2,304.4,519.4,304.5,520.7,304.5z"/>
	<path class="st4255" d="M524.7,328.5c-0.2,2.8,0.5,5.4,2.8,7.1c1.9,1.4,2.3,3,2.2,5.1c-0.1,2.7,1.2,4.4,4,4.8c-0.3,1.3-0.7,2.7-1,4
		c-4.4,1.3-7.2-3-11.1-3.2c-0.7-0.3-1.2-0.9-1.6-1.6c-1.8-7.3-3.8-14.5-8-21c-1.5-2.3-3-4.8-3.1-7.7c0.1-0.5,0.3-0.9,0.5-1.4
		c0.4-0.3,0.8-0.5,1.3-0.5c6.3,0.8,8.8,5.5,10.5,10.7C521.8,326.9,523.1,327.7,524.7,328.5z"/>
	<path class="st4256" d="M521.7,345.4c4.3-0.5,7.1,3.2,11,4c2.5,3.3,4.7,6.4,5.6,10.9c0.5,2.7,3.2,6.1,5.5,8.7
		c1.2,1.3,2,2.6,1.8,4.3c-1,1.1-2.3,1.2-3.7,1.3c-2.8,0.1-5.1-0.7-6.7-3c-0.7-0.7-1.4-1.4-2-2.1c-0.4-0.7-0.6-1.7-1.2-2.2
		c-2.8-2-5.8-3.2-3.7-8.1c1-2.4-2.8-5.7-4.8-8.3C522.4,349.4,520.2,348,521.7,345.4z"/>
	<path class="st4257" d="M541.7,373.4c1.3,0,2.7,0,4,0c5.4,0,4.7,5.3,7,8c1.8,0.7,3.4,1.6,3,4c-2,0.1-2,1.5-2,3
		c-2.6,1.8-4.3,0.8-5.5-1.7c-0.8-1.7-1.8-3.1-3.5-4C539.9,380.2,539.4,378.7,541.7,373.4z"/>
	<path class="st4258" d="M517.7,202.6c-1.6,0.9-2.9,2-3,4c-0.9-0.5-1-1.4-1.1-2.3c1-1.3,2.1-2.6,3.1-3.8c2.3-2,4.7-4,7-5.9
		C524.2,199,519.5,199.8,517.7,202.6z"/>
	<path class="st4259" d="M615.6,649.1c-4.7,0.5-9.4,2.2-13.7-1.5c-1-0.9-3.6,0.6-4.3,1.7c-3.4,4.8-8.3,3.8-12.8,3.7
		c-3.9-0.1-7.8-0.1-11.5-1.8c-1.3-0.6-2.2-0.9-2.8-2.3c-0.7-1.8-1.2-4.3-3.9-3.5c-2.8,0.8,0.2,3.3-1.3,4.7c-3.7-0.1-3.3-2.5-2.5-5
		c1.1-2.1,0.4-5.5,4-6c3.8-4.1,7.9-6.5,13.6-3.6c1.5,0.7,3.3,0.3,4.9,0.3c1.5,0,3.6-0.5,4.4,0.4c3.9,4.4,7.9,2.5,11.9,0.6
		c1.9,2.7,4.7,4.6,6.8,7.1c1.4,1.6,3.2,1.2,5,1.4C615.5,645.5,617.8,646.1,615.6,649.1z"/>
	<path class="st4260" d="M601.6,637.1c-1.5,0.1-2.2,1-3,2.2c-1.8,2.7-5.2,2.6-7-0.1c-1.2-1.8-18.9-4.7-19.7-2.9
		c-1.2,2.8-3.6,1.9-5.3,2.8c0.5-5.6,4.6-9.4,7-14c0.6-1.4,0.8,1.8,2.2,0c5.3-7.1,12.4-3.4,18.9-2.3c7.7,1.3,13,5.5,14.5,13.6
		c0,0,1.6-0.7,2.4,0.2c1.2,1.6,3.5,3.3,1,5.3c-2.6,2.1-4.5,0.1-6-2.1C605.5,637.8,604.7,635.4,601.6,637.1z"/>
	<path class="st4261" d="M611.6,637.1c-1.2-0.7-3.3,0.7-3.6-2.1c-0.3-2.7-3.3-4.1-4.5-6c-4.3-6.4-10.6-5.2-16.4-6
		c-0.8-0.1-10.5,2.1-10.9,2.8c-2.1,3.7-1.3-1.7-2.5-0.7c-0.8-4.6,1.5-8.4,3.6-12.1c1.2-2.2,2.2-4.4,2.4-6.9c1.9-7.1,1.8-7,7.5-2.3
		c2.7,2.3,4,6,7.6,7.3c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0.4,0.9,0.9,1.3,1.4c0.8,1.5,1.7,2.9,2.8,4.2c2,2.9,4.9,5.1,6.6,8.2
		c0.3,0.4,0.5,0.7,0.8,1.1c1.9,3.4,5.5,5.9,5.3,10.4C611.9,636.8,611.8,636.9,611.6,637.1z"/>
	<path class="st4262" d="M510.7,314.5c-0.3,0-0.7,0-1,0c-2.6,0.4-3.3-1.4-4.1-3.4c-1.6-4-1-8.2-1.6-12.3c-0.4-3.2-1.1-6-3.7-8.4
		c-3-2.7-1.9-6.4-1.6-9.9c0.1-1.4-0.5-2.9,1-4c4.1,1.7,5.8,4.9,6,9.2c0.2,3.4,1.5,6.3,4.9,7.8c1.7,1.7,1.8,4.2,3.1,6.2
		c1.1,2.6,2.1,5.3,2,8C515.7,310.9,515.5,314.5,510.7,314.5z"/>
	<path class="st4263" d="M498.8,280.5c1.7,1.6,0.8,3.8,1,5.7c0.2,1.7,0,3,2.5,4c2.1,0.8,2.9,4.3,3.1,7.3c0.4,4.8-0.1,9.7,1.9,14.5
		c0.7,1.5,2.2,1.3,2.5,2.6c0,0.3,0,0.7,0,1c-3.4,1.3-6.1-0.8-9.2-1.6c-1-0.9-1.8-2.1-1.8-3.4c0-4.6-1.5-8.8-3.1-13
		c-0.2-5.4,1.5-11-2-16C495.4,281.2,497.1,280.9,498.8,280.5z"/>
	<path class="st4264" d="M483.8,289.5c-0.8-4.4,1.4-8.9-0.9-13.2c-0.9-1.8,1.1-3.2,1.9-4.8c2.4,0,1.1,2,1.4,3
		c2,6.7,3.2,13.7,7.1,19.7c0.4,0.7-0.3,2.2-0.6,3.3c3.7,4.2,4.5,10,1.8,12.8c-1.7,0.2-3.1-0.3-3.5-2
		C489.2,301.8,486.6,295.6,483.8,289.5z"/>
	<path class="st4265" d="M493.8,309.5c1.4-0.1,2.6-1.3,1.8-2.3c-2.4-2.9-1.1-6.7-2.8-9.7c1,0,2,0,3,0c5.8,4,3,10.8,5,16
		c6.3,2.4,5.8,7.2,4.2,12.3c-0.1,1-0.3,2-1.2,2.7c-3.5-5-7.4-9.9-9-16C494,311.6,493,310.8,493.8,309.5z"/>
	<path class="st4266" d="M493.8,309.5c0.3,1,0.7,2,1,3c-2.9-1-2.7,3.1-5,3c-0.3-0.3-0.7-0.7-1-1c-4.6-4.6-1.3-12.1-6.2-16.8
		c-2-1.9-0.6-5.4-0.8-8.1c0.7,0,1.3,0,2,0c5.5,4.2,5.5,11.1,7.9,16.7C492.2,307.5,491.6,309.3,493.8,309.5z"/>
	<path class="st4267" d="M512.7,299.5c-1.9-1.6-2.1-3.7-2-6c-0.1-4.5-3.9-7.9-3.6-12.8c0.3-3.7-0.9-7.5-1.4-11.2
		c1.4,2.4,2.4,4.7,2.2,7.8c-0.2,3.4,3.2,5.5,5.1,7.9c0.8,1,1.4,2.5,2.7,3.3c-0.2,2.9,0.2,5.5,2.6,7.6c2.4,2.2,0.6,5.8,2.4,8.4
		c-0.7,1.2-1.9,1-3,1C514,305.2,515.3,300.8,512.7,299.5z"/>
	<path class="st4268" d="M639.6,380.4c-1.9,0.5-2.6,3.5-5.7,2.4c2.2-2.3,1.6-4.9,0.7-7.4c0-0.4,0-0.7,0-1.1
		C636.3,376.4,637.9,378.4,639.6,380.4z"/>
	<path class="st4269" d="M628.6,368.4c0,1.5,0.1,3-0.1,4.5c-0.3,2-1.7,3.5-3.4,3.9c-2,0.5-1.2-1.9-1.9-2.8c-1.7-2.1-3.3-4-6.5-3.6
		c-3.6,0.4-4.1-1.2-2-3.9c2-0.6,4-1.3,6.1-1.8C624.4,363.7,627.1,364.9,628.6,368.4z"/>
	<path class="st4270" d="M607.6,401.4c-7,0-7,0-7-7.1c0-1.3,0-2.6,0-3.9c0-0.7,0-1.3,0-2c3.9,0,3.5-3.6,4.6-5.7
		c1.3-2.3,1.1-5.4,5.1-5.6c2.1-0.1,0.6-3.5,2.8-4.6c1,0.5,2.3,1.3,3.7,2c1.3,0.7,2.1,1.7,1.4,3.3c-0.6,1.3-1.6,1.5-2.9,1
		c-1-0.4-2.4-0.5-2.5,0.7c-0.4,4.1-3.9,7.3-3.3,11.8C610.1,394.8,609.2,398.2,607.6,401.4z"/>
	<path class="st4271" d="M539.7,398.4c0.3-0.9,2.1-1.2,0.8-2.8c-2.8-3.3-2.4-7.3-1.8-11.2c4.4,0.3,5.3,4.9,6.9,7.4
		c2.3,3.5,5.8,5.4,8.1,8.6c0.3,3,1.7,6,0,9c-0.7-0.7-1.3-1.3-2-2c-4.8,0.1-8.1-2.6-11-6C540.4,400.4,540,399.4,539.7,398.4z"/>
	<path class="st4272" d="M540.7,401.4c3.7,2,7.3,4,11,6c0.2,3-0.7,6.1,1,9c-3.9,2-6.4,1-7-2.9c-0.2-1.3-0.4-3.1-1.6-3.4
		c-3.3-1.1-4-4.1-5.4-6.6C540,403.3,540.7,402.7,540.7,401.4z"/>
	<path class="st4273" d="M553.7,400.4c-2.1,1.1-2.1-1.4-2.7-1.6c-6.8-2.6-7.6-10.2-12.3-14.4c-0.3-0.3-0.7-0.7-1-1
		c-1.5-4.2-5.2-8.1-2-13c1.5,2.1,3.2,3.6,6,3c0,1.7,0,3.3,0,5c0,1.6,0,2.6,2.4,2.5c2.2,0,5,2.1,5,4.1c0.1,3.8,2.2,3.4,4.5,3.4
		C554.3,392.4,552.6,396.4,553.7,400.4z"/>
	<path class="st4274" d="M688.5,550.2c0-0.3,0-0.7-0.1-1c-0.3-0.2-0.4-0.4-0.3-0.6c0.1-0.2,0.2-0.3,0.3-0.3c0.4,0,0.7,0,1.1,0
		c1,1,2-0.4,2.9,0c0,0.3,0,0.7,0,1C691.3,549.8,690,550.4,688.5,550.2z"/>
	<path class="st4126" d="M684.7,546.1c-1.9-0.6-3.1-1.8-3.1-3.9C683.1,543.1,684.4,544.2,684.7,546.1z"/>
	<path class="st4275" d="M688.5,548.2c0,0.3,0,0.6-0.1,0.9c-1.4,0-2.9,0.4-4-0.8c0.7-0.3,1.3-0.7,2-1
		C687.1,547.6,687.8,547.9,688.5,548.2z"/>
	<path class="st4276" d="M679.5,550.2c2.7,0,5.4,0.1,8,0.1c-1,2.1-3.2,2.4-5,2.6c-2.4,0.3-3.7,1.7-5.1,3.3c-0.3-0.4-0.7-0.7-1-1.1
		C675.8,552.4,677.5,551.2,679.5,550.2z"/>
	<path class="st4127" d="M672.6,569.2c0.1-2.2,0.6-4.3,2-6C676.1,565.9,673.7,567.3,672.6,569.2z"/>
	<path class="st4127" d="M687.4,602.6c-0.1,0.7-0.4,1.4-1.2,1.2c-0.5-0.1-1.1-0.8-1.3-1.3c-0.2-0.7,0.5-1.2,1.2-1.1
		C686.9,601.4,687.3,601.8,687.4,602.6z"/>
	<path class="st4127" d="M677.6,588.2c0.1-0.9-0.3-2,0.9-2.5c0.2-0.1,0.8,0.4,0.9,0.8C679.5,587.8,678.6,588,677.6,588.2
		L677.6,588.2z"/>
	<path class="st4277" d="M645.8,589.4c0.3,0.6,0.6,1.3,0.9,1.9c2.9,5,0.4,10.1,0,15c-0.9,11.8-2.3,23.6-4.7,35.3
		c-0.3,1.5-0.3,3-1.9,3.8c-3.1,0.1-2.6-2.7-3.5-4.4c-1.2-5.2,1.1-10.2,0.7-15.4c0-0.6,0.1-1.1,0.3-1.7c1-9,1.9-18.1,3.5-27.1
		c0.4-2.3,1-4.5,2-6.6c0.5-0.7,1.1-1.2,2-1.4C645.3,589.1,645.6,589.2,645.8,589.4z"/>
	<path class="st4278" d="M672.5,585.2c0.3,2,0.7,4,1,6c-0.9,3.3-2.6,6.2-4.9,8.8c-2.5,2.8-4.1,2.8-6.1-0.2c-0.4-0.7-0.8-1.5-1.2-2.1
		c-3-4.1-3-4.1,1.4-6.4c-3.4,1.8-7,3.5-10.7,4.7c-1.4,0.4-2.7,1.5-4-0.1c-0.6-1.3-0.5-2.5,0.5-3.6c6.2-2.5,12.3-5.3,18-9
		c2.8,1.4,1.7-1,2-2.1c0.2-1.2,0.7-2,1.6-0.6C671.1,582,671.7,583.6,672.5,585.2z"/>
	<path class="st4279" d="M648.6,592.2c0,1.3,0,2.6,0,3.9c2.4,4.3-0.1,8.6,0.1,12.9c-0.7,10.5-1.8,21-3.9,31.4c-0.9,3-0.9,6.2-2,9.2
		c-1,3.5-2.6,6.8-2.6,10.6c0,1.2-0.2,2.4-1.8,2.4c-1.7-0.7-2-2.2-2.2-3.8c0.5-4.7,1-9.4,3.6-13.6c2.5-6.5,2.8-13.5,3.8-20.3
		c1.6-11.1,2.3-22.4,3.4-33.5C647.3,591.6,647.9,591.9,648.6,592.2z"/>
	<path class="st4280" d="M670.5,564.2c0,0.7,0,1.3,0,2c-2.1,3.4-2.8,7.3-3.8,11c-1.4-0.4-2.5,0.3-3.6,1c-1.5,0.9-2.9,2.1-4.7,2.7
		c-1,0.2-1.9,0.2-2.7-0.4c-0.7-1-0.2-1.7,0.4-2.5c1-0.9,2.2-1.6,3.3-2.4c2.4-2,5-3.6,6.6-6.4c1-1,1-2.4,1.5-3.5
		C668.2,564.3,668.9,563.1,670.5,564.2z"/>
	<path class="st4281" d="M662.5,577.5c1.4-0.6,2.6-4.1,4.3-0.3c-2.8,3.3-6.4,5.2-10.2,7.1c-0.4,0.3-0.8,0.2-1.2-0.2
		c-0.2-2.9,2.8-2.6,4.1-4.1C660.7,579.5,661.7,578.7,662.5,577.5z"/>
	<path class="st4277" d="M655.4,584.1c0.4,0.1,0.7,0.1,1.1,0.2c-2.9,3.1-6.9,3.9-10.7,5.1c0,0-0.3-0.3-0.3-0.3
		c0.3-2.8,3-3.1,4.6-4.6c0.8-0.5,1.6-1,2.6-1.1C653.7,583.2,654.7,583.3,655.4,584.1z"/>
	<path class="st4282" d="M668.6,581.2c1.1,1.1,2,2.7,0.7,3.7c-1,0.9-2.5,0.1-2.7-1.7C667.2,582.5,667.9,581.9,668.6,581.2z"/>
	<path class="st4283" d="M647.6,609.1c-0.6-4.4,1.1-8.6,1-13c3.7-2.9,9.2-2.1,12.4-6.1c0.8-1,2.4-1.2,3.6-0.2
		c0.8,0.7,1.1,1.7,0.8,2.7c-0.6,2.3-2.1,0.6-3.2,0.6c-0.8,0-1.8-0.2-2.1,0.8c-0.3,1,0.5,1.5,1.2,1.9c0.4,0.2,0.9,0.3,1.4,0.6
		c1.8,1,0.4,5.9,3.1,4.2c2.4-1.5,6-3.9,5.8-8c-0.1-1.6,0.4-2.2,2-1.5c0,0.3,0,0.6,0,1c2.2,3.9,1.9,8.4,3.1,12.6
		c0.7,2.3,1.5,3.4,3.8,3.4c0.9,3,2.9,5.7,3,9c-0.9,3.4-3,4.9-6.6,4.5c-2.5-0.3-4.9,0.7-7.4,0.8c-3.3,0.1-5.8-1-7-4.3
		c-3-8.1-3-8.1-10.6-5.4C650.4,611.6,649,610.4,647.6,609.1z"/>
	<path class="st4284" d="M651.6,612.1c2.2,0.2,1.9-2.7,4.1-3.1c4.5-0.7,7.2,1.2,7.9,6.4c0.7,5.2,2.4,6.5,7.6,5.5
		c2.6-0.5,5.2-1.3,7.8-0.5c3.2,1,2-3.7,4.6-3.3c0,0,0,0,0,0c1,2.6,1.2,5.4,2,8c1.3,1,1.8,2.4,2,4c-1,0.7-1.3,1.9-1.7,3
		c-0.5,1.4-1,3.2-2.8,3c-1.9-0.2-2.2-2.2-2.5-3.8c-0.2-1.2,0.4-2.2,0.4-3.4c-2.9,5.6-16.2,7.9-21.7,6c-2.1-0.7-3.4-2.6-4.6-4.4
		C651.5,624,650.3,618.3,651.6,612.1z"/>
	<path class="st4285" d="M655.6,629.1c3.4,5.6,9,4.7,13.6,3.6c3.9-0.9,9.1-1,10.8-6.4c0.2-0.7,1.1-1.3,2-0.8
		c1.3,0.7,0.7,1.5,0.2,2.4c-1.3,2.3-0.9,4.4,0.6,6.4c3.3-0.3-0.2-6.3,4.6-5.3c0,0.3,0.1,0.7,0.1,1c-1,6.4-4.3,10.4-10.9,12.4
		c-7.6,2.4-14.9,5.4-23.1,3.2c-2-1.4-2.6-3.6-3-5.8c-0.1-2.8,0.5-5.5,1.4-8.2C652.5,630.1,652.9,627.9,655.6,629.1z"/>
	<path class="st4286" d="M653.6,645.1c7.9,0.5,15.2-1.8,22.7-3.9c6.7-1.9,7.9-7,11.2-11.1c0.3,0,0.6,0,1-0.1c0.3,1,0.6,2,0.8,3
		c-1.5,5.5-5.2,9.7-8.2,14.4c-2.3-0.5-0.3,2-1.6,2.3c-5.6,6.1-12,10.3-20.7,9.2c-3.1-0.9-4.4-4.1-6.9-5.6
		C649.7,650,649.8,647.2,653.6,645.1z"/>
	<path class="st4278" d="M680.5,608.1c-2.2,1.9-4.9,1.9-4.7-1.1c0.2-4.1-3.6-7.4-1.5-11.6c0.4-0.8-0.5-2.2-0.7-3.3
		C676.9,597,678.6,602.7,680.5,608.1z"/>
	<path class="st4283" d="M685.5,625.1c-1-2.6-3.4-4.8-2-8C686.1,619.3,685.4,622.3,685.5,625.1z"/>
	<path class="st4287" d="M679.5,550.2c-0.7,1.8-3,2.7-3,5c-0.3,0-0.7,0-1.1,0c0.6-2.7,0.1-6.1,3.9-7
		C679.5,548.9,679.5,549.5,679.5,550.2z"/>
	<path class="st4288" d="M680.6,647.1c1.6-0.7,2-2,2-3.6c0-1.7,0.5-3.5,2-4.2c2.5-1.2,3.2-2.9,3-5.5c-0.1-1.3,1-0.9,1.8-0.7
		c0.3,1.3,0.7,2.7,1,4c0.1,1,0.2,2-0.5,3c-0.3,0.3-0.6,0.5-1,0.7c-1.4,1-1,2.7-1.4,4.1c-0.8,2.4-0.7,5.4-3.7,6.6
		C682.1,650.4,680.3,649.6,680.6,647.1z"/>
	<path class="st4289" d="M689.5,640.1c0-1.1-0.1-2.2,0.9-3c0.3,0.3,0.7,0.7,1,1c0.2,3.5-0.1,6.9-1.8,10c-0.4,0.3-0.8,0.4-1.3,0.1
		C686.9,645.3,688.5,642.7,689.5,640.1z"/>
	<path class="st4290" d="M688.5,648.1c0.4,0,0.7,0,1.1,0.1c-1.8,7.6-5.9,14.4-8.1,21.9c-3.2,6.6-6.5,13.1-9,20c-2.1,3.3-3,7.3-6,10
		c-0.7-1-1.3-2-2-3c0.5-3.8,2.6-6.9,4.2-10.2c1.1-1.3,2.5-2.5,2.6-4.5c0.2-1.1,0.6-2.2,1.5-3c2.4-2,3.1-4.7,3.6-7.6
		c0.3-1.7,1-3.1,2.1-4.3c1.6-1.2,1.8-3,1.9-4.8c0.5-2.6,1.2-5.1,3-7.1c1.6-1.7,2.5-3.8,3.1-6C686.9,648.8,687.2,647.8,688.5,648.1z"
		/>
	<path class="st4291" d="M630.5,553.2c0.7,1,1.4,2,2.1,3c1.6,1.4,2.8,3.3,4.8,4.3c3.2,1.6,3.3,4.7,3.8,7.7c0.6,2.2,2.6,1.6,4.1,2.1
		c1.5,0.5,2.9,1.3,2.8,3.1c-0.1,1.8-1.5,2.4-3,2.9c-1.9,0.5-3.9-0.3-6,0.1c1,3.3,3.4,5.3,5.6,7.5c0.2,0.4,0.3,0.9,0.2,1.3
		c-0.8,1.3-2.2,0.6-3.2,1c-5.1-3.8-9.6-8.1-14.1-12.5c-1.8-1.7-2.3-3.6-1.6-5.8C627.4,562.9,629,558,630.5,553.2z"/>
	<path class="st4292" d="M620.5,574c1.1-3.6,2.2-7.2,3.3-10.8c0.6-0.3,1.2-0.7,1.8-1c-4.3,8.8,0.8,14.6,6.8,19.7
		c2.2,1.9,4.2,4.3,7.1,5.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.4-0.1,0.4c-0.4,0.4-1,0.7-1.6,0.8c-0.6,0.1-1.2,0.1-1.7,0.1
		c-1-0.7-2-1.3-2.9-2c-4.4-2.5-7.8-6.4-11.9-9.3C620.4,577,619.7,575.6,620.5,574z"/>
	<path class="st4293" d="M641.6,586.1c0.7-1.1,1.9-0.9,2.9-0.9c1,0.4,1.5,1.1,1.2,2.2c-0.4,0.3-0.8,0.5-1.3,0.6
		c-0.7,0-1.4-0.2-1.9-0.8C642.3,586.8,642,586.4,641.6,586.1z"/>
	<path class="st4222" d="M642.6,587.2c0.7,0.1,1.3,0.2,2,0.2c-0.2,1.9-1.7,1.6-2.9,1.7c-0.8-0.1-1.5-0.4-2-1.1c0-0.3,0-0.6,0-0.8
		C640.6,587.2,641.6,587.2,642.6,587.2z"/>
	<path class="st4294" d="M639.6,569.2c0.2-3.8,0.2-7.3-4.6-8.5c-1.9-0.5-2.6-2.5-2.4-4.5c0-1.7-0.1-3.3,0.1-5c0.2-2.1,1.3-2.6,2.9-1
		c1.1,3.1,2.2,5.4,6.4,3.4c2-0.9,4.8-0.8,7.2-1.4c2.6-0.6,5.3-0.7,7.4,1.7c0.7,0.8,1.5,1.6,2.5,1.3c5.3-1.8,6.2,1.8,7.1,5.6
		c0.2,0.9,0.2,1.9,0.2,2.8c0.4,2.2,1.9,4.4,0.1,6.6c-1.7,2.7-3.8,5-7,6c-0.5-1.4-0.5-4-2.8-3.1c-2.9,1.2,1,3.4-0.2,5.1
		c-0.3,0.7-0.6,1.4-0.9,2.1c-0.2,0.1-0.4,0.3-0.6,0.5c-1,0.3-1.9,0.3-2.8-0.4c-1.6-2.4-4-4.4-3.4-7.9c0.4-2-1.4-1.9-2.6-2.1
		C644.1,569.9,641.7,570.7,639.6,569.2z"/>
	<path class="st4295" d="M665.6,561.2c-1.4-0.9-0.8-2.4-1.3-3.6c-0.7-1.4-1.2-2.8-3.2-1.4c-2.3,1.7-4,1.2-5.6-1.5
		c-1.7-2.9-5.3-2.2-7-0.9c-3.3,2.4-8-1.2-10.6,3.2c-0.4,0.7-2.1-0.4-2.2-1.8c-0.1-1.7,0-3.3-0.1-5c0.3,0.1,0.6,0.3,0.9,0.4
		c2.2,0.6,5.5,1.7,6.1-0.7c0.7-2.9-3-2.5-6.4-3.2c4.5-2.5,7.5-1.3,10.6,0.6c0.7-2.1-2.5-4.5,0.9-5.6c2.4-0.8,4.7-1.2,6.6,1.4
		c2.5,3.5,6.4,1.9,9.7,1.8c1.8,0,1.6-1.8,1.6-3.1c0-2.2,0-4.3,0-6.5c2.6,0.3,2.6,0.3,4.8,9.4c1.9-0.7,1.5-6.3,5.2-2.7c0.8,2,3,4,0,6
		c-2.4,2.5-1.9,6-3,9c-1.6,2.1-0.9,4.8-2,7c-3.6,0.5-0.6,5.4-4,6c0.3-2.1-0.4-4-1-6C666.2,563.2,668.2,562.2,665.6,561.2z"/>
	<path class="st4280" d="M672.5,557.2c0.1-3.3-0.6-6.8,3-9C674.5,551.2,674.7,554.6,672.5,557.2z"/>
	<path class="st4296" d="M521.7,345.4c1.5,5,6.3,7.9,8.2,12.7c1.1,2.7,1.4,4.2-2.7,4.9c3.1,2,7.8,1.5,6.5,6.3c-4.8,1.5-6.6,0.3-9-6
		c-0.8-5.1-2.7-9.5-7.1-12.5c-1.5-1-0.9-2.3-0.9-3.5c-1.1-1.5,0-2.2,1.1-2.9c0.9-0.4,1.9-0.7,2.9-0.1
		C521.1,344.8,521.4,345.1,521.7,345.4z"/>
	<path class="st4297" d="M518.7,345.4c-1.3,0-2,0.7-2,2c-3.6-1-4.3-6.5-9-6c-1.3-4.3-2.7-8.7-4-13c0-1,0-2,0-3
		c5.7,1.4,9,5.7,11.8,10.3C517.3,338.7,519.3,341.7,518.7,345.4z"/>
	<path class="st4298" d="M524.7,363.4c2.8,2.2,3.9,7.1,9,6c0.8,0,1.6,0.2,2,1c-1.3,4.6,2.9,8.4,2,13c-3.5-1.5-5.8-4.6-8.5-7.1
		c-1.1-1-1-3.6-3.4-3.9C525.9,369.4,523.4,366.6,524.7,363.4z"/>
	<path class="st4299" d="M601.6,637.1c1.4-3,2.9-4.2,4.8-0.4c0.4,0.7,0.7,1.5,1.1,2.2c1,1.5,1.7,3.6,4.1,2.6c3.3-1.4-0.9-2.9,0-4.4
		c0,0,0,0,0,0c3.1-0.8,4.3,1.6,5.8,3.5c5,8.4,12.4,15,17.2,23.6c1.8,2.9,5.5,4.7,5,8.9c-0.1,2-1.5,2-3,2c-1.1,0-2.3,0.2-3-1
		c-2.1-1.1-1-3.5-2-5c-0.1-3-5.4-11.1-8.5-11.5c-5.4-0.6-6.3-4.5-7.5-8.5c-0.8-3.1-1-3.5-3.4-2.6c-2.7,1-4.1,0.1-5.1-2.2
		C605.9,641.4,600.6,641.7,601.6,637.1z"/>
	<path class="st4234" d="M631.6,669.1c2.8,0.8,0.9,3.5,2,5c-2,0-2.3-1.7-3-3C630.9,670.4,631.3,669.7,631.6,669.1z"/>
	<path class="st4300" d="M639.6,673.1c-1.5-3-3.6-5.6-5.9-8.1c-0.9-0.8-0.5-1.8-0.5-2.7c0.9-0.9,1.8-0.4,2.6,0.1
		c0.6,0.4,1.1,0.9,1.6,1.5c4.9,6.7,10,13.3,15.1,19.8c1.2,1.5,2.8,2.9,2.1,5.3c-0.1,0.4-0.1,0.9-0.2,1.3c-0.3-0.1-0.6-0.2-0.9-0.2
		c-0.5-0.2-0.8-0.5-0.9-1.1C648.3,683.7,644,678.4,639.6,673.1z"/>
	<path class="st4301" d="M652.7,689c0.3,0.4,0.6,0.7,0.9,1.1c0,2.5,1.6,5.1-0.8,7.5c-0.9-2.2-1.8-4.3-2.6-6.5
		c-0.3-0.9-1.4-1.8-0.6-2.7C650.5,687.5,651.6,688.8,652.7,689z"/>
	<path class="st4233" d="M641.9,689.9c-2.9,1.6-4.7,1.2-6.9-0.5C637.4,688.9,639.2,688.8,641.9,689.9z"/>
	<path class="st4302" d="M654.6,688.9c-5.1-6.9-10.3-13.8-15.4-20.7c-1-1.3-1.8-2.7-2.7-4.1c-0.8-1.7-1.6-3.4,0.1-5.1
		c0.6,1,1.3,2,1.9,3c1.4,1.1,1.6,3,2.9,4.2c1.5,1.7,2.6,3.5,3.9,5.3c1.9,3.3,4.9,5.8,6.8,9.1c1.7,2.6,4,4.8,5.7,7.4
		c0.5,0.7,1,1.5,1.1,2.4c-0.1,0.8-0.6,1.2-1.3,1.3C656.6,690.9,655.6,689.9,654.6,688.9z"/>
	<path class="st4303" d="M657.6,691.9c0.3-0.3,0.6-0.6,0.9-0.8c2.7-0.4,3.1,2.2,4.5,3.5c0.5,0.8,1.1,1.6,0.4,2.5c-0.3,1,1.2,2,0,2.9
		C660.9,697.7,659.4,694.7,657.6,691.9z"/>
	<path class="st4304" d="M666.5,700.1c1.4-3.7,3.4-7,6-10c-0.3,4.3-3.6,7.5-4.2,11.7c-0.2,1.2-1.6,0.1-2.5,0.1
		C666,701.3,666.3,700.7,666.5,700.1z"/>
	<path class="st4305" d="M663.6,697.1c-0.3-0.7-0.7-1.4-1-2c-1.9-2.8-2.1-4.5,0.2-8c2.8-4.2,4.6-9.4,7.1-14.1
		c4.1-5.6,6.8-11.9,10.4-17.8c0.9-1.5,1.3-3.4,3.3-4.1c2.1-1.9,2.3-4.5,3-7c0.8-1.3,0.9-2.9,2-4c0.3,0,0.7,0,1,0c1,2.8-1.2,5.2-1,8
		c-0.8,0.4-1,1.2-1,2c-1.4,1.8-2.1,3.9-3,6c-2,1.9-2.3,4.5-3,7c-0.7,1.6-0.6,3.6-2,5c-1.3,1-1.8,2.5-2,4.1c-1.1,2.7-2.4,5.4-4,7.9
		c-1.2,0.7-1,1.9-1,3c-0.9,1.4-2.1,2.5-2.9,4c-1.6,3.4-2.4,7.2-5.1,10.1C664.2,697.1,663.9,697.1,663.6,697.1z"/>
	<path class="st4306" d="M594.7,611.1c-0.6-2.8-2.8-4.7-4-7.2c-0.7-1.2-0.7-1.9,1-1.8c4.7,1.7,9.2,1.4,13.3-1.4
		c1.1-0.7,1.5-0.2,2.4-0.1c4,0.5,7.3,2.9,10.6,4.7c3.4,1.9,1.4,6.3,2.3,9.6c1.1,4.1-0.2,8.4,2.8,12.6c1.3,1.8,1.8,7-2.5,9.4
		c-1.6,0.9-0.4,1.9,1,2.1c0,1.4,0,2.9-1.9,3c-1.2,0.5-1.7-0.3-2.4-1c-3.1-4.7-7.1-8.7-9.6-13.8c-3-6.5-7.4-11.8-12.7-16.5
		L594.7,611.1z"/>
	<path class="st4307" d="M591.7,602.1c-0.7,0.4-1,1-1,1.8c-2.3-2.6-4.6-5.2-6.9-7.8c7.4-0.5,14.8-1.4,21.8-3.9
		c-4.2,2.7-7.1,8-13.5,6.8C590,598.5,589.9,598.9,591.7,602.1z"/>
	<path class="st4308" d="M617.9,641c0.6,0.4,1.2,0.8,1.8,1.1c1.4,3.2,3.7,5.6,6.1,8c0.4,1.2,1.5,0.9,2.3,1c1.8,1.9,2.4,4.5,4.1,6.4
		c0.8,1.3,1.9,2.6,1.4,4.3c0,1.1,0,2.1,0,3.2c-5-8.5-11.6-15.8-17.1-23.9C617,640.7,617.4,640.6,617.9,641z"/>
	<path class="st4309" d="M620.5,574c1.6,4.4,6,6.1,9,9.1c1.4,1.4,3.2,2.3,4,4.1c-0.7,0-1.3,0-2,0c-4.1-3.3-8.6-6.1-12.1-10.1
		C619.8,576.1,620.2,575,620.5,574z"/>
	<path class="st4310" d="M631.5,658.2c-1.6-2.1-3.7-4-3.4-7c0.2-0.7,0.3-1.4,0.5-2.1c1.5-5.5,3-11,3.1-16.7
		c2.9-14.3,4.6-28.6,4.8-43.2c0.4-0.3,0.7-0.6,1.1-0.9c1.9,2.1,2.1,4.8,1.8,7.4c-0.6,4.2-0.3,8.5-0.4,12.7
		c0.4,5.9-2.5,11.4-2.3,17.3c-0.7,2-0.7,4.2-0.7,6.3c-0.1,3.8-3,7.3-1.5,11.3c-0.3,4.9-1.1,9.8-1.5,14.7
		C632.5,658.5,632.1,658.5,631.5,658.2z"/>
	<path class="st4311" d="M512.7,299.5c3.4,0.5,2.8,4.4,5,6c1,8.1,8.4,14.2,7,23c-2.3,0.2-4.1,1.5-4.8-2.9c-0.5-3.1-1.2-9.2-7.5-9
		c-1.2,0-1.7-0.9-1.8-2c3.8-2.1,4.7-5.3,3.4-9.4C513.5,303.3,513.2,301.4,512.7,299.5z"/>
	<path class="st4312" d="M518.7,345.4c-2.8-8.3-7.6-15.1-15-20c0-0.3-0.1-0.7,0-1c2.6-4.7,0.3-8-3-11c3.3-0.7,6,1.3,9,2
		c0.5,3.1,2.1,5.7,3.7,8.2c4,6.4,7,13.1,7.2,20.7C520.1,344.8,519.4,345.1,518.7,345.4z"/>
	<path class="st4313" d="M595,610.9c0.6-0.1,1.6-0.6,1.8-0.3c4.3,5.3,9.8,9.7,11.8,16.7c-1.3,0.3-2-0.4-2.7-1.3
		c-1.5-3.3-4-5.8-6.3-8.5c-1.1-1.6-2.3-3.2-3.7-4.5C595.5,612.3,595.3,611.6,595,610.9z"/>
	<path class="st4314" d="M605.8,625.9c0.9,0.4,1.8,0.9,2.7,1.3c2.7,4.9,7,8.6,9.3,13.8c-0.4,0-0.9,0.1-1.3,0.1c-1.4-1.7-2.8-3.3-5-4
		C611.2,632.6,606.9,630.1,605.8,625.9z"/>
	<path class="st4315" d="M599.8,617.1c2.8,2.4,5.3,5,6,8.9c-2.5-2.9-5.6-5.2-7.2-8.8C599,616.7,599.4,616.7,599.8,617.1z"/>
	<path class="st4316" d="M599.8,617.1c-0.4,0-0.8,0-1.1,0.1c-1.8-0.8-2.4-2.5-2.9-4.2C598,613.4,599.2,614.9,599.8,617.1z"/>
	<path class="st4317" d="M632.5,658c-1.4-5.1,0.4-10,1.1-14.9c-1.1-3.8,1.7-7.1,1.6-10.8c0-0.8,0-1.6,0.2-2.4
		c0.4-3.1,1.3-6.1,1.4-9.3c0-1.2,0-2.9,2.2-2.3c0.3,2,0.6,4-0.2,5.9c0,0.3,0,0.7,0,1c-1.1,9.2-3.1,18.4-2.9,27.8
		c0,1.9-0.5,3.6-1.7,5.1C633.5,658.4,633,658.4,632.5,658z"/>
	<path class="st4318" d="M633.6,658.1c0.4-10.4,2.6-20.6,4.1-30.8c0.1-0.7,0.6-1.4,1-2.2c-0.3,5.3-0.7,10.6-1,16
		c0.7,6.1,0.3,12.1-2.5,17.7C634.2,659.5,633.6,659.4,633.6,658.1z"/>
	<path class="st4318" d="M638.6,624.1c0-2,0-4-0.1-6.1c-1.1-7.5,0.6-14.8,1.1-22.2c0.8-2.2-0.1-5.3,3.3-6.1c0.3,0.1,0.5,0.2,0.8,0.4
		c-1.2,7.7-2.1,15.4-3.7,23C639.2,616.8,640.9,620.7,638.6,624.1z"/>
	<path class="st4319" d="M634.6,659.1c2.1-5.8,1.7-12.1,3-18c1.6,0.8,0.4,3.1,1.9,4c-0.2,4.8-1.8,9.4-2.9,14c0,1.7-0.1,3.4-0.1,5.1
		c-0.4-0.4-0.7-0.8-1.1-1.2C635.1,661.7,633.2,660.8,634.6,659.1z"/>
	<path class="st4320" d="M641.5,588.2c1.1-0.1,2.2,0.2,3.1-0.7c0.3-0.2,0.7-0.3,1-0.5c1.2-2.1,2.6-3.4,5.1-1.8
		c-1.7,1.3-3.4,2.6-5.1,3.9c-0.6,0.3-1.2,0.7-1.9,1c0,0-0.2-0.2-0.2-0.2C642.7,589.4,641.1,589.9,641.5,588.2z"/>
	<path class="st4321" d="M655.6,629.1c-4.2,2.6-2.7,7.3-4,11c-2.7,1-5.1,0.8-6.7-0.9c0,0.3,1.1,1.8-1.2,1.9c1.3-10.7,2.7-21.3,4-32
		c2.1-0.1,4.3-0.2,4,3C652.9,617.8,652.4,623.9,655.6,629.1z"/>
	<path class="st4322" d="M643.6,641.1c0.5-1,2-1.2,2-1.2c2.2-4.7,3.9,2.8,5.9,0.2c0.7,1.7,1.3,3.3,2,5c-1.3,2.5-3.2,5-1,8
		c-3.5,2.3-7.1,1.8-10.8,0.5c-1-1.1-0.9-2.3-0.2-3.5C642.3,647.1,642,643.9,643.6,641.1z"/>
	<path class="st4323" d="M641.6,650.1c0,1,0,2,0,3c1.5,4.8,1,9.4-1,13.9c-1.6-1.2-2.1-3-2-4.9C639,658,639.4,653.8,641.6,650.1z"/>
	<path class="st4324" d="M656.6,578.2c-0.4-2.2-5.8-3.7-0.9-6.5c1.7-1,2.5,0.3,4,1.1c2.9,1.7-0.4,2.3-0.1,3.4
		C659,577.5,658,578.2,656.6,578.2z"/>
	<path class="st4325" d="M655.4,584.1c-1.1,0-2.1,0.1-3.2,0.1c-0.2-2.5,2.1-2.7,3.4-3.9c0,0,0,0,0,0c1,0,2-0.1,2.9-0.1
		c0.3-0.4,0.7-0.4,1,0C658.9,582.3,656.3,582.3,655.4,584.1z"/>
	<path class="st4326" d="M659.6,580.2l-0.5,0c0,0-0.5,0-0.5,0c1.1-1.2,2.3-2.1,3.9-2.7C662.4,579.4,661.3,580.1,659.6,580.2z"/>
	<path class="st4327" d="M655.7,580.2c0,2.3-2.7,2.3-3.4,3.9c-0.5,0.3-1.1,0.6-1.6,1c-2.1-0.4-3.4,1.3-5.1,1.8
		c-0.3-0.6-0.6-1.2-1-1.8c0-0.3,0-0.7,0-1c2.4-1.9,4.6-4.1,8-4C653.6,580.2,654.6,580.2,655.7,580.2z"/>
	<path class="st4328" d="M658.6,658.1c7.2,0.1,13.6-2,18.3-7.8c0.7-0.8,1.6-1.2,2.6-1.2c1.4,1.4,1.2,3.1,0.2,4.5
		c-4,6-6.9,12.8-12.7,17.5c-2.2,1.8-4.5,3.3-7.3,3.8c-2.5-0.3-5-0.7-7.5-0.2c-2.3-0.3-5-0.2-5.4-3.2c-0.4-2.8,2.1-3.5,4-4.4
		c1.8-0.8,2.9-2,3.1-4.1C654.2,660.3,655.8,658.5,658.6,658.1z"/>
	<path class="st4329" d="M658.6,658.1c-1.7,2.3-3.9,4.1-3.3,7.7c0.3,1.6-2.8,1.8-4.2,2.9c-1.1,0.9-3.6,0.2-3.3,2.7
		c0.3,2.2,2.3,1.4,3.5,2c0.4,0.2,0.9,0.4,1.3,0.6c1.4,0.2,2.9,0.7,2.2,2.3c-0.7,1.9-2.8,1-4.3,1.2c-3-0.7-3.7-4-6-5.5
		c-1.3-1.7-2.6-3.4-4-5.1c-0.4-4.7,0.3-9.3,1-13.9c3.7,0,7.3,0,11,0C656.2,652.8,655.9,657.2,658.6,658.1z"/>
	<path class="st4330" d="M659.6,674.1c10.5-3.9,14.1-13.9,19.8-22.1c0.5-0.7,0.2-1.9,0.2-2.9c0.5-0.6-1-2.2,1-2c0.4,1.7,3.1,1.8,3,4
		c-3.5,6.6-6.9,13.2-10.5,19.7c-0.5,0.9-1.7,1.5-2.5,2.3c-1.8,2.5-3.5,5.1-4.6,8c-1.6,4.2-3.6,4.4-7.2,1.3
		C658.1,679.5,657.5,676.7,659.6,674.1z"/>
	<path class="st4330" d="M688.5,640.1c-0.1,1.6-0.7,3-2,4C686.6,642.5,686.3,640.7,688.5,640.1z"/>
	<path class="st4289" d="M673.6,680c0-3.3,2.3-5.5,4-7.9C676.9,675.1,676.8,678.4,673.6,680z"/>
	<path class="st4289" d="M684.5,656.1c0.1-2.5,0.7-4.6,3-6C687.1,652.4,686.2,654.4,684.5,656.1z"/>
	<path class="st4289" d="M679.5,668.1c0.5-1.7,0.7-3.6,2-5C681.5,665,681.9,667.1,679.5,668.1z"/>
	<path class="st4289" d="M669.6,687c0-2,1.4-3.1,2.9-4C672.5,685.1,671.2,686.1,669.6,687z"/>
	<path class="st4331" d="M652.6,580.2c-2.5,1.7-4.6,4.1-8,4c-4.5-0.6-4.9-5.4-7.6-7.8c-0.3-0.2-0.1-1.3,0.1-1.8
		c0.4-0.9,1.2-0.9,2.1-0.3c0.5,0.4,1.2,0.9,1.7,0.8c2.2-0.1,5.6,1.3,6.1-1.5c0.6-3.1-2.8-2.1-4.7-2.4c-1.3-0.2-2.1-0.9-2.6-2
		c2,0,4.1-0.4,6,0.1c2,0.5,8.4-2.7,4.3,4.3c-0.6,1,0.5,2.6,1.6,3.4C652.8,577.8,653.5,578.7,652.6,580.2z"/>
	<path class="st4332" d="M641.5,588.2c0.7,0.6,1.3,1.2,2,1.7c-3,0.7-1,3.9-2.7,5.3c-1.8,3.3-1,7.1-1.6,10.6c-0.2,1,0.5,2.5-1.5,2.4
		c-0.5-6.6,1.1-13.3,0-19.9c0.7-0.1,1.4-0.1,2.1-0.2C640.3,588.1,640.9,588.1,641.5,588.2z"/>
	<path class="st4280" d="M665.6,561.2c0.1-1,0.4-1.9,1.7-1.8c0.9,0.1,1.2,0.9,1.2,1.8c0,1.9-1,2.9-2.9,3
		C665.6,563.2,665.6,562.2,665.6,561.2z"/>
	<path class="st4333" d="M634.6,659.1c0.3,1.3,0.6,2.6,0.9,3.8c-0.6-0.4-1.2-0.7-1.8-1.1c-0.7-1.2-1.4-2.4-2.2-3.6
		c0.3-0.1,0.6-0.1,1-0.2c0.4,0,0.7,0.1,1.1,0.1C633.9,658.5,634.2,658.8,634.6,659.1z"/>
	<path class="st4334" d="M659.6,674.1c0,3,0,6,0,9c0.7,2.5,0.4,4.6-2,6c-2.3-2.4-4.8-4.7-6-8c-0.5-1.3-2.2-2.3-1-4
		c0.5-0.3,1.1-0.6,1.6-0.9c0.6-0.3,1.7,1,1.9-0.3c0.1-0.5-0.9-1.2-1.5-1.8C654.9,672.2,657.2,673,659.6,674.1z"/>
	<path class="st4335" d="M657.6,689c0.7-2,1.3-4,2-6c3.5,1.2,5.6,0.5,6.1-3.6c0.4-2.9,2.4-4.9,4.9-6.4c-1.1,5.3-4.5,9.7-6.5,14.5
		c-1.1,2.7-2.6,4.5-1.5,7.4c-1.3-1.3-2.7-2.7-4-4C658.2,690.4,657.9,689.7,657.6,689z"/>
	<path class="st4323" d="M650.6,677.1c0.3,1.3,0.6,2.7,1,4c-2.5-2.9-5.3-5.5-7-9C647.5,672.6,647.9,676.2,650.6,677.1z"/>
	<path class="st4336" d="M637.6,608.1c1.8-2,0.8-4.4,0.9-6.7c0.2-2.3-0.1-4.7,2.2-6.3c-0.7,7.6-1.4,15.3-2.2,22.9
		c-2,3.8-0.9,8.4-2.9,12.2c-0.8-1.4-0.7-2.7-0.1-4.1C634.4,619.9,638.5,614.3,637.6,608.1z"/>
	<path class="st4336" d="M635.6,632.1c-0.1,3.8-1.2,7.3-2.1,11c-3.1-4,1.4-7.2,1.1-11C634.9,631.7,635.3,631.7,635.6,632.1z"/>
	<path class="st4332" d="M635.6,632.1c-0.3,0-0.7,0-1,0c0.3-2-0.8-4.2,1-6c0,1.4,0,2.7,0.1,4.1C635.6,630.9,635.6,631.5,635.6,632.1
		z"/>
	<path class="st4323" d="M645.4,659c-0.2,0.6-0.4,1.4-1.2,1.3c-0.3-0.1-0.5-1-0.5-1.6c0-0.4,0.5-1,0.8-1
		C645.4,657.5,645.5,658.3,645.4,659z"/>
</g>
</svg>

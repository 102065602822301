<div class="settings-container">
    <p class="buttons-container">
        <button id="credentials" type="button" class="btn btn-outline-primary ms-2 setting-button"
            (click)="toggleContent('credentials')" aria-controls="collapse">
            Credenciales
        </button>
        <button id="integration" type="button" class="btn btn-outline-primary ms-2 setting-button"
            (click)="toggleContent('integration')" aria-controls="collapse">
            Integración
        </button>
    </p>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedCredentials">
        <div class="card">
            <div class="card-header">
                <h5>Credenciales</h5>
            </div>
            <div class="card-body">
                <div class="separation-line"></div>
                <div class="credentials-row">
                    <span class="credentials-title">General</span>
                    <span class="credentials-content">{{xauth}}</span>
                    <span class="credentials-action"><img src="assets/icons/refresh-icon.png" alt="refresh-icon"
                            class="refresh-icon"></span>
                </div>
                <div class="separation-line"></div>
                <!-- <div class="credentials-row">
                    <span class="credentials-title">Contactos</span>
                    <span class="credentials-content">asdasdasdadasda</span>
                    <span class="credentials-action"><img src="assets/icons/refresh-icon.png" alt="refresh-icon"
                            class="refresh-icon"></span>
                </div> -->
                <!-- <div class="separation-line"></div>
                <div class="credentials-row">
                    <span class="credentials-title">Tareas</span>
                    <span class="credentials-content"></span>
                    <span class="credentials-action"><img src="assets/icons/refresh-icon.png" alt="refresh-icon"
                            class="refresh-icon"></span>
                </div>
                <div class="separation-line"></div> -->
            </div>
        </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedIntegration">
        <div class="card">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3" *ngFor="let integration of getPaginatedItems()">
                    <div class="card user-card">
                        <div class="card-header card_menu_title">
                            <a class="template-name">
                                <h5 (click)="openIntegrationModal(integration)">{{integration.nombre}}</h5>
                            </a>
                        </div>
                        <div class="card-block template_card_block">
                            <div class="template-image-container" (click)="openIntegrationModal(integration)">
                                <figure *ngIf="integration?.imagen; else blockDefecto" class="html_prev">
                                    <img [src]='integration.imagen' class="img-fluid img_prev">
                                </figure>
                                <ng-template #blockDefecto>
                                    <figure class="html_prev">
                                        <img src="assets/img/image-not-found.png" class="img-fluid img_prev">
                                    </figure>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="integrations && integrations.length > 0" class="row paginator-container">
                <ul class="pagination">
                    <li class="page-item" [class.disabled]="currentPage === 1">
                        <a class="page-link" (click)="setPage(1)">Primera</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === 1">
                        <a class="page-link" (click)="setPage(currentPage - 1)">Anterior</a>
                    </li>
                    <li class="page-item" *ngFor="let page of visiblePages" [class.active]="currentPage === page">
                        <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === totalPages?.length">
                        <a class="page-link" (click)="setPage(currentPage + 1)">Siguiente</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === totalPages?.length">
                        <a class="page-link" (click)="setPage(totalPages.length)">Última</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<app-modal-animation [modalID]="'integrationModal'" [modalClass]="'md-effect-1'">
    <h3>Gestionar Integración</h3>
    <div>
        <div class="row" style="margin: 0 0.5em;">

            <ng-container *ngIf="selectedIntegration && selectedIntegration.id !== 'empresaXAssistant'">
                <div class="col-lg-12 template-input-container">
                    <div class="element-title">Integración con: </div>
                    <input [id]="'template-input'" class="template-input" type="text"
                        [ngModel]="selectedIntegration.nombre" disabled>
                </div>
                <div class="col-lg-12 template-input-container">
                    <div class="element-title">KEY Master: </div>
                    <input [id]="'template-input'" class="template-input" type="text"
                        [(ngModel)]="selectedIntegration.keyMaster">
                </div>
                <div class="col-lg-12 template-input-container">
                    <div class="element-title">KEY: </div>
                    <input [id]="'template-input'" class="template-input" type="text"
                        [(ngModel)]="selectedIntegration.key">
                </div>
            </ng-container>

            <ng-container *ngIf="selectedIntegration && selectedIntegration.id === 'empresaXAssistant'">
                <div class="col-lg-12 template-input-container">
                    <div class="element-title">Integración con: </div>
                    <input [id]="'template-input'" class="template-input" type="text"
                        [ngModel]="selectedIntegration.nombre" disabled>
                </div>
                <div class="col-lg-12 template-input-container">
                    <div class="element-title">Key IMS contact: </div>
                    <input [id]="'template-input'" class="template-input" type="text"
                        [(ngModel)]="selectedIntegration.keyIMSContact">
                </div>
                <div class="col-lg-12 template-input-container">
                    <div class="element-title">Key IMS general: </div>
                    <input [id]="'template-input'" class="template-input" type="text"
                        [(ngModel)]="selectedIntegration.keyIMSGeneral">
                </div>
            </ng-container>

        </div>

        <div class="flex-evenly" style="margin-top: 1em;">
            <button type="button" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#ff5252; width: 20%;' (click)="closeModal('integrationModal')">
                Cancelar
            </button>
            <button type="button" (click)="setKeys()" class="btn btn-inverse ripple light footer-btn"
                style='background-color:#056867; width: 20%; margin-right: 15px;'>
                Guardar
            </button>
        </div>

    </div>
    <!--<button (click)="closeMyModal($event)" aria-label="Close" class="md-close-btn"><i class="icofont icofont-ui-close"></i></button>-->
</app-modal-animation>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { SignupEmailComponent } from './signup-email/signup-email.component';
import { SignupCodeComponent } from './signup-code/signup-code.component';
import { SignupPasswordComponent } from './signup-password/signup-password.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { GuideComponent } from './guide/guide.component';
import { JobsComponent } from './jobs/jobs.component';
import { PlansComponent } from './plans/plans.component';
import { UshopsServicesComponent } from './ushops-services/ushops-services.component';
import { HelpComponent } from './help/help.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from './services/api.service'
import { WidgetsModule } from './widgets/widgets.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgencyComponent } from './agency/agency.component';
import { SellerComponent } from './seller/seller.component';
import { SharedModule } from './shared/shared.module';
import { BackofficeModule } from './backoffice/backoffice.module';
import { ChatbotConfigurationService } from './services/chatbot-configuration.service';
import { AuthService } from './services/auth.service';
import { MassMessagingService } from './services/mass-messaging.service';
import { WhatsappAdminUsersService } from './services/whatsapp-admin-users.service';
import { SocketService } from './services/socket.service';
import { SharedComponentsService } from './services/sharedComponents.service';
// import { ToastyModule } from 'ng2-toasty';
import { ToastrModule } from 'ngx-toastr';
import { WhatsappCampaignService } from './services/whatsapp-campaign.service';
import { ApiPagosService } from './services/api-pagos.service';
import { SignUpService } from './services/sing-up/sign-up.service';
import { DragulaService } from 'ng2-dragula';
import { ClientsService } from './services/clients.service';
import { KanbanService } from './services/kanban.service';
import { ProfileService } from './services/profile.service';
import { SocketIoService } from './services/socket-io.service';
import { SharedDataService } from './services/shared-data.service';
import { ScheduleService } from './services/schedule.service';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SignupEmailComponent,
    SignupCodeComponent,
    SignupPasswordComponent,
    AboutUsComponent,
    ContactComponent,
    GuideComponent,
    JobsComponent,
    PlansComponent,
    UshopsServicesComponent,
    HelpComponent,
    AgencyComponent,
    SellerComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    BackofficeModule,
    BrowserAnimationsModule,
    FormsModule,
    SharedModule,
    // @ts-ignore
    ToastrModule.forRoot()
    // ToastyModule.forRoot(),
  ],
  providers: [
    HttpClient,
    ApiService,
    AuthService,
    ChatbotConfigurationService,
    SocketService,
    SocketIoService,
    SharedComponentsService,
    SharedDataService,
    MassMessagingService,
    WhatsappAdminUsersService,
    WhatsappCampaignService,
    ApiPagosService,
    SignUpService,
    ScheduleService,
    KanbanService,
    DragulaService,
    ClientsService,
    ProfileService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

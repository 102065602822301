import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  // Utilizamos BehaviorSubject para almacenar y emitir datos.
  private dataSource = new BehaviorSubject<string>(''); // inicializa con un valor vacío
  currentData = this.dataSource.asObservable(); // Permite suscribirse a los cambios de datos

  constructor() { }

  // Método para cambiar los datos
  changeData(data: any) {
    this.dataSource.next(data); // Emite el nuevo valor
  }
}
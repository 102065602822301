import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataTransferService } from '../services/data-transfer.service';
import { ApiService } from '../services/api.service'

interface Alert {
  type: string;
  message: string;
}

const ALERTS: Alert[] = [{
    type: 'success',
    message: 'La solicitud ha sido enviada exitosamente, muy pronto nos contactaremos contigo',
}];

@Component({
  selector: 'app-signup-email',
  templateUrl: './signup-email.component.html',
  styleUrls: ['./signup-email.component.scss']
})
export class SignupEmailComponent implements OnInit {

  form: FormGroup;
  message: string;
  alerts: Alert[];
  restLoading = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private data: DataTransferService, private enviar: ApiService) {
    this.buildForm();
  }

  ngOnInit(): void {
  }

  private buildForm() {
    
    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required]],      
      apellido: ['', [Validators.required]],
      uname: ['', [Validators.required]],

      // cedula: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],
//      telefono: ['', [Validators.required, Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email]],
//      mensaje: ['', [Validators.required]]
    });
    
  }

  save(event: Event) {
    event.preventDefault();
    if(this.form.valid) {
    const value = this.form.value;
    console.group(value);

    // const body = {
    //   "key": "YnGZfn8wffwCyNhFJQM7",
    //   "tipo": "formulario",
    //   nombre: this.form.get('nombre').value,
    //   email: this.form.get('email').value,
    //   telefono: this.form.get('telefono').value,
    //   mensaje: this.form.get('mensaje').value,
    // }

    const body = {
      'registerCompany':'xpert-platform-jtsFasd52',
      // "tipo": "formulario",
      firstName: this.form.get('nombre').value,
      lastName: this.form.get('apellido').value,
      email: this.form.get('email').value,
      username: this.form.get('uname').value,
      // mensaje: this.form.get('mensaje').value,
    }


    this.restLoading = true;

    this.enviar.formularioregistro(body).subscribe(
      result => {
        this.restLoading = false;
        if (result.mensaje === 'cuenta ya validada') {
            console.log(result);
            this.reset();
            // this.snackBar.open('Ya se ha registrado esta cuenta', '×', { panelClass: 'error', verticalPosition: 'top', duration: 4000 });
        } else{
          console.log(result);
          document.querySelector('#register-success-modal').classList.add('md-show');
            // this.snackBar.open('Registro exitoso!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
            // this.snackBar.open('El código de verificacion ha sido enviado a tu enviado a tu email!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 7000 });
            // this.auth.setToken(result);
            // this.router.navigate(['/sign-up/verification']);
        }
      },
      error => {
        this.restLoading = false;
        // this.preloaderSignUp = false;
        alert('EL USUARIO YA EXISTE, elige otro usuario');
        // return;
      }
  );


    // this.enviar.formulariocontacto(body).subscribe(
    //   result => {
    //     this.reset();
    //   }, error => {
    //     alert('Ocurrió un evento inesperado. Intenta de nuevo')
    //   }
    // );

    // 


  } else {
    this.form.markAllAsTouched();
  }






    // event.preventDefault();
    //   if(this.form.valid) {
    //   const value = this.form.value;
    //   console.group(value);
    //   this.enviar.formularioregistro(value).subscribe(
    //     result => {
    //       alert('Te has registrado con éxito.\nPronto nos contactaremos contigo');
    //       this.router.navigate(['home']);
    //     }, error => {
    //       alert('Error, ingresa de nuevo');
    //     }
    //   );
    //   //this.data.currentData.subscribe(message => this.message = message);
    //   //this.data.changeData(value.email);
    // } else {
    //   this.form.markAllAsTouched();
    // }
  }


  reset() {
    this.alerts = Array.from(ALERTS);

    setTimeout(()=>{

    this.form.reset();

    }, 1500)


  }

  nextpage() {
    this.router.navigate(['signup-code']);
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  closeModal(name: string) {
    // $('.' + name).removeClass('active');
    document.querySelector('#' + name).classList.remove('md-show');
    setTimeout(()=>{

      return this.router.navigate(['/']);
  
      }, 200)
  }

}
